import { useCallback, useState } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";

const Password: React.FC<{
  register: Function;
  errors: any;
  label: string;
  name: string;
  required?: boolean;
  showDefaultPattern?: boolean;
}> = ({
  register,
  errors,
  label,
  name,
  required,
  showDefaultPattern = true,
}) => {
  const [showIcons, setShowIcons] = useState<boolean>(false);

  const showPasswordHandler = useCallback(() => {
    setShowIcons(!showIcons);
  }, [showIcons]);

  return (
    <div className="w-full">
      <div
        className={`relative flex shadow items-center justify-between transition-all w-full h-[52px] border ${
          errors[name] ? "border-[#FF647C]" : "border-[#E5E5E5]"
        }  rounded-[4px] ${
          errors[name]
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        }  font-normal`}
      >
        <input
          id={name}
          type={showIcons ? "text" : "password"}
          name={name}
          placeholder="Password"
          autoComplete="off"
          maxLength={75}
          className="text-ironside-gray peer px-3 h-[50px] w-full bg-transparent  placeholder-transparent focus:outline-none focus:border-cornflower-blue"
          {...register(name, {
            required: required ? "This is required field." : false,
            pattern: showDefaultPattern
              ? {
                  value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                  message: "Please enter a valid password.",
                }
              : undefined,
          })}
        />
        <label
          htmlFor={name}
          className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
            errors[name]
              ? "text-[#FF647C] text-[11px]"
              : "text-[#8f8f92] text-[11px]"
          }   transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
            errors[name]
              ? "peer-placeholder-shown:text-[#FF647C]"
              : "peer-placeholder-shown:text-[#8f8f92]"
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            errors[name]
              ? "peer-focus:text-[#FF647C]"
              : "peer-focus:text-cornflower-blue"
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
        <div
          className="w-4 h-4 mr-3 cursor-pointer text-[#E5E5E5]"
          onClick={showPasswordHandler}
        >
          {showIcons ? <HiEye /> : <HiEyeOff />}
        </div>
      </div>
      <div className="w-[300px] md:w-full">
        {errors[name] ? (
          <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
            {errors[name].message === "This is required field."
              ? errors[name].message
              : "Password must have 1 special character,1 uppercase, 1 numeric & minimum 8 characters."}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Password;
