import { FC, Fragment, useEffect, useState } from "react";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { MdCenterFocusStrong, MdDownloadForOffline } from "react-icons/md";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { FilePreview } from "components/FilePreviewModal/types";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";
import ImageComponent from "components/UI/UpdateUIAgry/Image";
import Modal from "components/forms/Modal/UpdatedModalAgry/Modal";

import { isUrl } from "global/helpers/utils";
import { detectMimeType } from "global/helpers/mimeType";
import { filePreview } from "global/helpers/Cache";
import { fileToBase64 } from "global/helpers/FileConverter";
import { cn } from "global/helpers/tailwind-utils";


interface Props {
	data: FilePreview;
}

async function getMimeType(url: string) {
	if (isUrl(url)) {
		try {
			const response = await fetch(url, { method: "HEAD" });

			if (response.ok) {
				const contentType = response.headers.get("Content-Type");
				return contentType;
			} else {
				console.error(
					`Failed to fetch URL: ${url}, Status: ${response.status}`,
				);
				return null;
			}
		} catch (error) {
			console.error(`Error fetching URL: ${url}`, error);
			return null;
		}
	}

	return null;
}

const FilePreviewModalUpdated: FC<Props> = ({ data }) => {
	const [urlMimeType, setURLMimeType] = useState<string | null>(null);
	const [file, setFile] = useState<string | null>(null);

	const downloadImage = async () => {
		if (file) {
			try {
				const response = await fetch(
					isUrl(file)
						? file
						: data?.mimeType
						  ? `data:${data?.mimeType};base64,${file}`
						  : `data:${detectMimeType(file)};base64,${file}`,
				);

				const blob = await response.blob();

				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = isUrl(file)
					? file
					: data?.name
					  ? data?.name
					  : `${
								data?.title ? data?.title : `Image ${new Date().toISOString()}`
						  }.${
								data?.mimeType?.split("/")[1] ||
								detectMimeType(file)?.split("/")[1] ||
								"jpeg"
						  }`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error("Error downloading image:", error);
			}
		}
	};

	const onClose = () => {
		filePreview(null);
	};

	useEffect(() => {
		if (typeof file === "string") {
			getMimeType(file)
				.then((mimeType) => {
					setURLMimeType(mimeType);
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		} else {
			if (data?.data) {
				if (typeof data?.data !== "string") {
					fileToBase64(data?.data, (result) => {
						if (result) {
							setFile(result);
						} else {
							setFile(null);
						}
					});
				} else {
					setFile(data?.data);
				}
			} else {
				setFile(null);
			}
		}
	}, [data?.data, file]);


	return (
		<Modal
			onClose={onClose}
		>
			<div className={"bg-white rounded-md"}>
				{/* <div className="p-5 flex justify-between items-center border-b gap-2 bg-secondary-container rounded-t-[inherit]">
					<p className="text-base text-on-background truncate flex-1 text-start max-w-xl">
						{data?.title ? data?.title : "File"}
					</p>
					<Button onPress={onClose} className="p-0" modifier="plain">
						<IoMdClose className="min-w-5 min-h-5" />
					</Button>
				</div> */}
				<div className="p-5 flex justify-between items-center border-b gap-2 bg-secondary-container rounded-t-[inherit]">
					<p className="text-base text-on-background truncate flex-1 text-start max-w-xl">
						{data?.title ? data?.title : "File"}
					</p>
					<div onClick={onClose} className="p-0 cursor-pointer">
						<IoMdClose className="min-w-5 min-h-5" />
					</div>
				</div>
				<div className="p-2 relative flex justify-center items-center">
					{file && (isUrl(file) || file?.length > 100) ? (
						<Fragment>
							{urlMimeType === "image/jpeg" ||
							urlMimeType === "image/png" ||
							urlMimeType === "image/gif" ||
							urlMimeType === "image/svg+xml" ||
							urlMimeType === "image/tiff" ||
							urlMimeType === "image/webp" ||
							data?.mimeType === "image/jpeg" ||
							data?.mimeType === "image/png" ||
							data?.mimeType === "image/gif" ||
							data?.mimeType === "image/svg+xml" ||
							data?.mimeType === "image/tiff" ||
							data?.mimeType === "image/webp" ||
							detectMimeType(file) === "image/jpeg" ||
							detectMimeType(file) === "image/png" ||
							detectMimeType(file) === "image/gif" ||
							detectMimeType(file) === "image/svg+xml" ||
							detectMimeType(file) === "image/tiff" ||
							detectMimeType(file) === "image/webp" ? (
								<TransformWrapper>
									{({ zoomIn, zoomOut, centerView, resetTransform }) => (
										<Fragment>
											<div className="absolute top-0 z-10 left-0 right-0 flex gap-5 flex-wrap justify-center items-center p-3">
												<ToolTip render={"Zoom In"} arrow>
													<BiZoomIn
														onClick={() => {
															zoomIn();
														}}
														className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-secondary hover:text-irish-green"
													/>
												</ToolTip>
												<ToolTip render={"Zoom Out"} arrow>
													<BiZoomOut
														onClick={() => {
															zoomOut();
														}}
														className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-secondary hover:text-irish-green"
													/>
												</ToolTip>
												<ToolTip render={"Center"} arrow>
													<MdCenterFocusStrong
														onClick={() => {
															centerView();
														}}
														className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-secondary hover:text-irish-green"
													/>
												</ToolTip>
												<ToolTip render={"Reset"} arrow>
													<GrPowerReset
														className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-secondary hover:text-irish-green"
														onClick={() => {
															resetTransform();
														}}
													/>
												</ToolTip>
												<ToolTip render={"Download"} arrow>
													<MdDownloadForOffline
														onClick={(e) => {
															e.stopPropagation();
															downloadImage();
														}}
														className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-secondary hover:text-irish-green"
													/>
												</ToolTip>
											</div>
											<TransformComponent contentClass="max-w-[80vw] min-w-max max-h-[80vh]">
												<ImageComponent
													src={
														isUrl(file)
															? file
															: data?.mimeType
															  ? `data:${data?.mimeType};base64,${file}`
															  : `data:${detectMimeType(file)};base64,${file}`
													}
													className="object-cover rounded-md shadow-md max-h-[calc(100vh-30vh)] min-h-[200px] min-w-[300px] overflow-hidden"
												/>
											</TransformComponent>
										</Fragment>
									)}
								</TransformWrapper>
							) : (
								<object
									className={cn(
										"rounded-[inherit] max-h-[80svh]",
										urlMimeType === "application/pdf" ||
											data?.mimeType === "application/pdf" ||
											detectMimeType(file) === "application/pdf"
											? "min-w-[80svw] min-h-[80svh]"
											: "max-w-[80vw] min-w-max max-h-[80vh]",
									)}
									data={
										isUrl(file)
											? file
											: file?.startsWith("data:")
											  ? file
											  : data?.mimeType
												  ? `data:${data?.mimeType};base64,${file}`
												  : `data:${detectMimeType(file)};base64,${file}`
									}
								>
									<a
										href={
											isUrl(file)
												? file
												: data?.mimeType
												  ? `data:${data?.mimeType};base64,${file}`
												  : `data:${detectMimeType(file)};base64,${file}`
										}
										download={
											file
												? isUrl(file)
												: data?.name
												  ? data?.name
												  : `${
															data?.title
																? data?.title
																: `Image ${new Date().toISOString()}`
													  }.${
															data?.mimeType?.split("/")[1] ||
															detectMimeType(file)?.split("/")[1] ||
															"jpeg"
													  }`
										}
										className="cursor-pointer"
										target="_blank"
										rel="noreferrer"
									>
										File missing. Click to download.
									</a>
								</object>
							)}
						</Fragment>
					) : (
						<p
							className={
								"min-w-[300px] min-h-[500px] max-w-full flex justify-center items-center"
							}
						>
							File missing.
						</p>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default FilePreviewModalUpdated;
