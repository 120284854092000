import { TypedDocumentNode, gql } from "@apollo/client";

import {
	PROJECTS_FRAGMENT,
	PROJECT_MEMBERS_FRAGMENT,
	PROJECT_VAULT_ENVIRONMENTS_FRAGMENT,
	PROJECT_VAULT_CREDENTIAL_TYPES_FRAGMENT,
	PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS_FRAGMENT,
	PROJECT_SPRINT_FRAGMENT,
	PROJECT_COLUMN_FRAGMENT,
	PROJECT_TASK_LABEL_FRAGMENT,
	PROJECT_TASK_FRAGMENT,
	PROJECT_TASK_COMMENT_FRAGMENT,
	PROJECT_WORK_LOG_FRAGMENT,
} from "modules/Project/services/fragments";
import {
	StartProjectWorkLogArgs,
	StartProjectWorkLogReturnType,
	StopProjectWorkLogArgs,
	StopProjectWorkLogReturnType,
} from "modules/Timer/WorkLogs/types";

export const CREATE_OR_UPDATE_PROJECT = gql`
  ${PROJECTS_FRAGMENT}
  mutation saveProject($project: ProjectInput) {
    saveProject(project: $project) {
      ...projectFields
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: Int!) {
    deleteProject(id: $id)
  }
`;

export const CREATE_OR_UPDATE_CLIENT_AVAILABILITY = gql`
  mutation savaProjectClientAvailability(
    $clientAvailabilityInput: ClientAvailabilityInput
  ) {
    savaProjectClientAvailability(
      clientAvailabilityInput: $clientAvailabilityInput
    ) {
      project {
        clientAvailability {
          id
          timeZone
          currentTime
          workHours {
            client {
              from
              to
            }
            local {
              from
              to
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CLIENT_AVAILABILITY = gql`
  mutation deleteProjectClientAvailability($id: Int!) {
    deleteProjectClientAvailability(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_ROLE = gql`
  mutation saveProjectRole($projectRole: ProjectRoleInput) {
    saveProjectRole(projectRole: $projectRole) {
      id
      isActive
      name
      description
    }
  }
`;

export const DELETE_PROJECT_ROLE = gql`
  mutation deleteProjectRole($id: Int!) {
    deleteProjectRole(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_MEMBER = gql`
  ${PROJECT_MEMBERS_FRAGMENT}

  mutation saveProjectMember($projectMember: ProjectMemberInput) {
    saveProjectMember(projectMember: $projectMember) {
      ...projectMemberFields
    }
  }
`;

export const DELETE_PROJECT_MEMBER = gql`
  mutation deleteProjectMember($id: Int!) {
    deleteProjectMember(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIALS = gql`
  mutation saveProjectVaultCredential(
    $projectVaultCredentialInput: ProjectVaultCredentialInput
  ) {
    saveProjectVaultCredential(
      projectVaultCredentialInput: $projectVaultCredentialInput
    ) {
      id
      environment {
        id
        name
      }
      type {
        id
        name
      }
      credentials {
        id
        credentialId
        field {
          id
          name
        }
        value
      }
    }
  }
`;

export const DELETE_PROJECT_VAULT_CREDENTIAL = gql`
  mutation deleteProjectVaultCredential($id: Int!) {
    deleteProjectVaultCredential(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_VAULT_ENVIRONMENT = gql`
  ${PROJECT_VAULT_ENVIRONMENTS_FRAGMENT}

  mutation saveProjectVaultEnvironment(
    $projectVaultEnvironmentInput: ProjectVaultEnvironmentInput
  ) {
    saveProjectVaultEnvironment(
      projectVaultEnvironmentInput: $projectVaultEnvironmentInput
    ) {
      ...vaultEnvironmentFields
    }
  }
`;

export const DELETE_PROJECT_VAULT_ENVIRONMENT = gql`
  mutation deleteProjectVaultEnvironment($id: Int!) {
    deleteProjectVaultEnvironment(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIAL_TYPES = gql`
  ${PROJECT_VAULT_CREDENTIAL_TYPES_FRAGMENT}

  mutation saveProjectVaultCredentialType(
    $projectVaultCredentialTypeInput: ProjectVaultCredentialTypeInput
  ) {
    saveProjectVaultCredentialType(
      projectVaultCredentialTypeInput: $projectVaultCredentialTypeInput
    ) {
      ...projectVaultCredentialTypeFields
    }
  }
`;

export const DELETE_PROJECT_VAULT_CREDENTIAL_TYPES = gql`
  mutation deleteProjectVaultCredentialType($id: Int!) {
    deleteProjectVaultCredentialType(id: $id)
  }
`;

export const CREATE_OR_UPDATE_VAULT_CREDENTIAL_TYPE_FIELD = gql`
  ${PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS_FRAGMENT}

  mutation saveProjectCredentialTypeField(
    $projectCredentialInput: ProjectCredentialInput
  ) {
    saveProjectCredentialTypeField(
      projectCredentialInput: $projectCredentialInput
    ) {
      ...projectVaultCredentialTypeFieldFields
    }
  }
`;

export const DELETE_PROJECT_VAULT_CREDENTIAL_TYPE_FIELD = gql`
  mutation deleteProjectCredentialTypeField($id: Int!) {
    deleteProjectCredentialTypeField(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_SPRINT = gql`
  ${PROJECT_SPRINT_FRAGMENT}

  mutation saveProjectSprint($projectSprint: ProjectSprintInput) {
    saveProjectSprint(projectSprint: $projectSprint) {
      ...projectSprintFields
    }
  }
`;

export const DELETE_PROJECT_SPRINT = gql`
  mutation deleteProjectSprint($id: Int!) {
    deleteProjectSprint(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_COLUMN = gql`
  ${PROJECT_COLUMN_FRAGMENT}

  mutation saveProjectColumn($projectColumnInput: ProjectColumnInput) {
    saveProjectColumn(projectColumnInput: $projectColumnInput) {
      ...projectColumnFields
    }
  }
`;

export const DELETE_PROJECT_COLUMN = gql`
  mutation deleteProjectColumn($id: Int!) {
    deleteProjectColumn(id: $id)
  }
`;

export const DELETE_GLOBAL_COLUMN = gql`
  mutation deleteColumn($id: Int!) {
    deleteColumn(id: $id)
  }
`;

export const CREATE_OR_UPDATE_PROJECT_TASK_LABEL = gql`
  ${PROJECT_TASK_LABEL_FRAGMENT}

  mutation createOrUpdateProjectTaskLabel(
    $projectTaskLabelInput: ProjectTaskLabelInput
  ) {
    saveProjectTaskLabel(projectTaskLabelInput: $projectTaskLabelInput) {
      ...projectTaskStatusFields
    }
  }
`;

export const CREATE_OR_UPDATE_PROJECT_LABEL = gql`
  mutation saveProjectLabel($projectLabelInput: ProjectLabelInput) {
    saveProjectLabel(projectLabelInput: $projectLabelInput) {
      id
      name
      description
    }
  }
`;

export const DELETE_PROJECT_TASK_LABEL = gql`
  mutation deleteProjectTaskLabel($id: Int!) {
    deleteProjectTaskLabel(id: $id)
  }
`;

export const DELETE_PROJECT_LABEL = gql`
  mutation deleteProjectLabel($id: Int!) {
    deleteProjectLabel(id: $id)
  }
`;

export const SAVE_PROJECT_TASK = gql`
  ${PROJECT_TASK_FRAGMENT}

  mutation saveProjectTask($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      ...projectTaskFields
    }
  }
`;

export const DELETE_PROJECT_TASK = gql`
  mutation deleteProjectTask($id: Int!) {
    deleteProjectTask(id: $id)
  }
`;

export const SET_PROJECT_TASK_POSITION = gql`
  mutation setProjectTaskPosition($id: Int!, $currentPosition: Int!) {
    setProjectTaskPosition(id: $id, currentPosition: $currentPosition) {
      dataCollection {
        id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_PROJECT_TASK_COMMENT = gql`
  ${PROJECT_TASK_COMMENT_FRAGMENT}

  mutation saveProjectTaskComment(
    $projectTaskCommentInput: ProjectTaskCommentInput
  ) {
    saveProjectTaskComment(projectTaskCommentInput: $projectTaskCommentInput) {
      ...taskCommentFields
    }
  }
`;

export const DELETE_PROJECT_TASK_COMMENT = gql`
  mutation deleteProjectTaskComment($id: Int!) {
    deleteProjectTaskComment(id: $id)
  }
`;

export const SET_COLUMN_POSITION = gql`
  mutation setColumnPosition($id: Int, $position: Int) {
    setColumnPosition(id: $id, position: $position) {
      id
    }
  }
`;

export const CREATE_OR_UPDATE_PROJECT_WORK_LOG = gql`
  ${PROJECT_WORK_LOG_FRAGMENT}

  mutation createProjectWorkLog(
    $createProjectWorkLogInput: CreateProjectWorkLogInput
    $type:String
    $isProfileImageNeeded: Boolean!
  ) {
    createProjectWorkLog(
      createProjectWorkLogInput: $createProjectWorkLogInput
    ) {
      ...worklogfields
    }
  }
`;

export const DELETE_PROJECT_WORK_LOG = gql`
  mutation deleteProjectWorkLog($id: Int!) {
    deleteProjectWorkLog(id: $id)
  }
`;

export const START_PROJECT_WORK_LOG: TypedDocumentNode<
	StartProjectWorkLogReturnType,
	StartProjectWorkLogArgs
> = gql`
  mutation startProjectWorkLog($projectWorkLogInput: ProjectWorkLogInput) {
    startProjectWorkLog(projectWorkLogInput: $projectWorkLogInput) {
      id
      description
      runningHours
      durationInSeconds
    }
  }
`;

export const STOP_PROJECT_WORK_LOG: TypedDocumentNode<
	StopProjectWorkLogReturnType,
	StopProjectWorkLogArgs
> = gql`
  mutation stopProjectWorkLog(
    $stopProjectWorkLogInput: StopProjectWorkLogInput
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    stopProjectWorkLog(stopProjectWorkLogInput: $stopProjectWorkLogInput) {
      id
      description
      startTime
      endTime
      createdAt
      durationInSeconds
      user {
        personalInformation {
          id
          name
        }
        userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
          documents {
            file
          }
          type
        }
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_TITLE = gql`
  mutation saveProjectTaskTitle($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      title
    }
  }
`;

export const SAVE_PROJECT_TASK_DESCRIPTION = gql`
  mutation saveProjectTaskDescription($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      description
    }
  }
`;

export const SAVE_PROJECT_TASK_FILE_ATTACHMENTS = gql`
  mutation saveProjectTaskFile($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      projectTaskAttachments {
        id
        filePath
        name
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_INCHARGE = gql`
  mutation saveProjectTaskIncharge($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      incharge {
        personalInformation {
          id
          name
        }
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_COLUMN = gql`
  mutation saveProjectTaskColumn($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      projectColumn {
        id
        name
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_SPRINT = gql`
  mutation saveProjectTaskSprint($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      sprint {
        id
        name
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_LABEL = gql`
  mutation saveProjectTaskLabel($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      projectTaskLabel {
        id
        name
      }
    }
  }
`;

export const SAVE_PROJECT_TASK_START_DATE = gql`
  mutation saveProjectTaskStartDate($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      startDate
    }
  }
`;

export const SAVE_PROJECT_TASK_DUE_DATE = gql`
  mutation saveProjectTaskDueDate($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      dueDate
    }
  }
`;

export const SAVE_PROJECT_TASK_BILLING_STATUS = gql`
  mutation saveProjectTaskBillingStatus($projectTaskInput: ProjectTaskInput) {
    saveProjectTask(projectTaskInput: $projectTaskInput) {
      id
      billingStatus
    }
  }
`;

export const SAVE_PROJECT_SETTINGS_COLUMN = gql`
  mutation saveColumn($columnInput: ColumnInput) {
    saveColumn(columnInput: $columnInput) {
      id
      name
      description
      colour
      position
    }
  }
`;

export const CHANGE_MAIN_COLUMN_POSITION = gql`
  mutation changeMainColumnPosition($id: Int, $position: Int) {
    changeMainColumnPosition(id: $id, position: $position) {
      id
      position
    }
  }
`;

export const ADD_PROJECT_TASK_NOTIFICATION_USER: TypedDocumentNode<
	string,
	{
		projectTaskId: number;
	}
> = gql`
  mutation addProjectTaskNotificationUser($projectTaskId: Int!) {
    addProjectTaskNotificationUser(projectTaskId: $projectTaskId)
  }
`;

export const REMOVE_PROJECT_TASK_NOTIFICATION_USER: TypedDocumentNode<
	string,
	{
		projectTaskId: number;
	}
> = gql`
  mutation removeProjectTaskNotificationUser($projectTaskId: Int!) {
    removeProjectTaskNotificationUser(projectTaskId: $projectTaskId)
  }
`;
