import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import { Modal, Number, Text, Name, Outlined, Filled } from "components/forms";
import Loading from "components/Loader/Loading";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";

import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import { BANK_NAMES } from "modules/Employee/services/queries";
import {
  CREATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from "modules/Employee/services/mutations";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import { IBankInformation } from "modules/Employee/types/personal";
import { mutationHandler } from "modules/Employee/model/mutation-handler";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  userBankDetails: IBankInformation;
  setShowModal: Function;
  refetchBankInformation: Function;
}

const EditBankInfo: FC<IProps> = ({
  userBankDetails,
  setShowModal,
  refetchBankInformation,
}) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const isUnderModeration = useIsUnderModeration();

  const { data: bankNames, loading: bankNameLoader } = useQuery(BANK_NAMES);

  const moderationFieldProps = {
    refetchQuery: refetchBankInformation,
  };

  const moderationObj = userBankDetails?.moderation || null;

  const [createUserBankAccount] = useMutation(CREATE_BANK_ACCOUNT);
  const [updateUserBankAccount] = useMutation(UPDATE_BANK_ACCOUNT);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<IBankInformation>();

  useEffect(() => {
    if (userBankDetails?.accountNumber) {
      setValue("accountNumber", userBankDetails?.accountNumber);
    }

    if (userBankDetails?.branch) {
      setValue("branch", userBankDetails?.branch);
    }

    if (userBankDetails?.accountHolderName) {
      setValue("accountHolderName", userBankDetails?.accountHolderName);
    }

    if (userBankDetails?.ifsc) {
      setValue("ifsc", userBankDetails?.ifsc);
    }

    if (userBankDetails?.swiftCode) {
      setValue("swiftCode", userBankDetails?.swiftCode);
    }
  }, [setValue, userBankDetails]);

  const modalCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
  };

  const mutationFunction = userBankDetails?.id
    ? updateUserBankAccount
    : createUserBankAccount;

  const mutationRefetchQuery =
    (!userBankDetails?.id && refetchBankInformation) || undefined;

  const userId =
    (+sessionStorage?.otherUserId && +sessionStorage?.otherUserId) || undefined;

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Bank Information")
    : createSuccessMessageWithAwaiting("Bank Information");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Bank Information")
    : updateSuccessMessageWithAwaiting("Bank Information");

  const actionSuccessMessage = userBankDetails?.id
    ? updateActionSuccessMessage
    : createActionSuccesMessage;

  const bankDetatilsId = userBankDetails?.id || undefined;

  const formSubmitHandler = (data: IBankInformation) => {
    const variables = {
      userId: userId,
      id: bankDetatilsId,
      bankName: data?.bankName,
      accountNumber: data.accountNumber,
      branch: data.branch || null,
      accountHolderName: data.accountHolderName,
      ifsc: (data.ifsc && data.ifsc?.toUpperCase()) || null,
      swiftCode: (data.swiftCode && data.swiftCode?.toUpperCase()) || null,
    };

    mutationHandler(
      mutationFunction,
      variables,
      inProgress,
      modalCloseHandler,
      setInProgress,
      actionSuccessMessage,
      mutationRefetchQuery
    );
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[40px] boder-hit-gray">
          <h2 className="edit-modal-heading">Bank Information</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {bankNameLoader ? (
            <Loading className="min-h-[539px] lg:min-h-[586px] rounded-b-[12px]" />
          ) : (
            <form onSubmit={handleSubmit(formSubmitHandler)}>
              <div className="pr-[35px] form-div lg:pr-[40px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.bankName}
                >
                  <AutoComplete
                    setValue={setValue}
                    options={bankNames ? bankNames?.getBankNames : []}
                    control={control}
                    label={"Bank Name *"}
                    name={"bankName"}
                    errors={errors}
                    required
                    defaultValue={userBankDetails?.bankName}
                    disabled={!!moderationObj?.bankName}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.accountNumber}
                >
                  <Number
                    register={register}
                    errors={errors}
                    label="Account Number *"
                    name="accountNumber"
                    defaultNumber={userBankDetails?.accountNumber?.toString()}
                    minimumLength={9}
                    maximumLength={18}
                    required={true}
                    disabled={!!moderationObj?.accountNumber}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.accountHolderName}
                >
                  <Name
                    register={register}
                    errors={errors}
                    label="Account Holder Name *"
                    name="accountHolderName"
                    required={true}
                    defaultName={userBankDetails?.accountHolderName}
                    disable={!!moderationObj?.accountHolderName}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.branch}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Branch"
                    name="branch"
                    defaultText={userBankDetails?.branch}
                    maximumLength={50}
                    disable={!!moderationObj?.branch}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.ifsc}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="IFSC Code"
                    name="ifsc"
                    defaultText={userBankDetails?.ifsc}
                    maximumLength={11}
                    disable={!!moderationObj?.ifsc}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.swiftCode}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Swift Code"
                    name="swiftCode"
                    defaultText={userBankDetails?.swiftCode}
                    maximumLength={11}
                    disable={!!moderationObj?.swiftCode}
                  />
                </ModerationField>
              </div>
              <div className="w-full border-t border-hit-gray" />
              <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[40px]">
                <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : userBankDetails?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditBankInfo;
