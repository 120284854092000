import { FC, ReactNode } from "react";
import { HiX } from "react-icons/hi";

import Loading from "components/Loader/Loading";
import { Outlined } from "components/App/Buttons";
import Filled from "components/App/Buttons/Filled";
import Modal from "components/UI/Modal";

interface IProps {
  children: ReactNode;
  title: string;
  loading?: boolean;
  handleSubmit: Function;
  onSubmit: (data: any) => void;
  outlineBtnName: string;
  filledBtnName: string;
  onClose: () => void;
  outlineBtnLoading?: boolean;
  filledBtnLoading?: boolean;
  onSubmitClick?: () => void;
  filledBtnDisable?: boolean;
  outlineBtnDisable?: boolean;
  classNameForChildren?: string;
}

const EditForm: FC<IProps> = ({
  children,
  title,
  loading,
  onSubmit,
  handleSubmit,
  outlineBtnName,
  filledBtnName,
  outlineBtnLoading,
  filledBtnLoading,
  onClose,
  onSubmitClick,
  filledBtnDisable,
  outlineBtnDisable,
  classNameForChildren = "",
}) => {
  return (
    <Modal>
      <div className="bg-white rounded-md w-[300px] sm:w-[350px] lg:w-[380px]">
        <div className="p-5 flex justify-between items-center border-b">
          <p className="text-base text-ironside-gray max-w-[300px] truncate">
            {title}
          </p>
          <span
            onClick={onClose}
            className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-white-smoke cursor-pointer text-warm-gray hover:text-warm-gray/70"
          >
            <HiX className="w-5 h-5" />
          </span>
        </div>
        {loading ? (
          <Loading className="min-h-[300px]" />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`px-5 pt-5 max-h-[500px] min-h-[50px] overflow-y-auto space-y-2 ${classNameForChildren}`}
            >
              {children}
            </div>
            <div className="flex justify-between gap-2 items-center p-5 border-t">
              <Outlined
                buttonName={outlineBtnName}
                buttonType="button"
                disabled={outlineBtnDisable}
                loading={outlineBtnLoading}
                onClick={onClose}
              />
              <Filled
                buttonName={filledBtnName}
                buttonType="submit"
                disabled={filledBtnDisable}
                onClick={onSubmitClick}
                loading={filledBtnLoading}
              />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default EditForm;
