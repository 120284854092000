import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";

interface IProps {
  request: any;
}

const TableDetailsGrid: FC<IProps> = ({ request }) => {
  const navigate = useNavigate();

  const project = request?.dates?.length > 0 && request?.dates[0]?.project;

  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {request?.id || "-"}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate">
        {project?.name || "-"}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {request?.requestedToUser?.personalInformation?.name || "-"}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.leaveDate}
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {request?.dates?.map((date: any) => date?.leaveDate)}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.session}
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {request?.dates?.map((date: any, index: number) => date?.session)}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                <div
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 rounded-[18px]`}
                >
                  {date?.status}
                </div>
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span>
            {request?.dates?.map((date: any, index: number) => {
              return (
                <span
                  key={index}
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 flex justify-center rounded-[18px] py-5 px-3`}
                >
                  {date?.status}
                </span>
              );
            })}
          </span>
        ) : (
          <span className="text-gray-400">Eg.Pending</span>
        )}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate whitespace-nowrap">
        <span
          className="grid place-content-center cursor-pointer hover:text-cornflower-blue hover:underline"
          onClick={() => {
            navigate(`${request?.id}`, {
              state: {
                viewPageDetails: request,
              },
            });
          }}
        >
          View
        </span>
      </td>
    </tr>
  );
};

export default TableDetailsGrid;
