import { makeVar } from "@apollo/client";
import { FilePreview } from "components/FilePreviewModal/types";

import { IToastedNotification } from "components/UI/ToastNotification/type";

import { IFilePreviewModal, IUser } from "global/types/type";

const userContextData = makeVar<{
  user: IUser | null;
  refetchData: any;
}>({
  user: null,
  refetchData: () => {},
});

const getAllowedAccess = makeVar({});

const getAllowedAccessUpdated = makeVar({});

const allowedAccessVar = makeVar({});

const userQueryProps = makeVar<any | null>(null);
const setBackNavigationURL = makeVar<string>("");
const setTitle = makeVar<string | { name: string; link: string }[]>("");

const toastNotify = makeVar<IToastedNotification[]>([]);

const setShowFileOpenModal = makeVar<IFilePreviewModal | null>(null);

const paginationDefaultCount = makeVar<number>(10);

const filePreview = makeVar<FilePreview | null>(null);


export {
  userContextData,
  getAllowedAccess,
  allowedAccessVar,
  userQueryProps,
  setBackNavigationURL,
  setTitle,
  toastNotify,
  getAllowedAccessUpdated,
  setShowFileOpenModal,
  paginationDefaultCount,
  filePreview
};
