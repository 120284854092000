import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { Filled } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import DataCard from "components/App/Card/DataCard";

import { Capitalize } from "global/helpers/Capitalize";
import { toastNotify, userQueryProps } from "global/helpers/Cache";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import {
  accessDeniedMessage,
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import EditFamily from "modules/Employee/Pages/Profile/Family/EditFamily";
import { GET_FAMILY_DETAILS } from "modules/Employee/services/queries";
import { DELETE_FAMILY_MEMBER } from "modules/Employee/services/mutations";
import { IFamilyMember } from "modules/Employee/types/family";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

const tableHeadings = [
  "Name",
  "Relation",
  "Date Of Birth",
  "Age",
  "Occupation",
  "Mobile",
  "Aadhaar",
  "Action",
];

const Family = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchFamilyDetails,
  } = useQuery(GET_FAMILY_DETAILS, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const { canCreate, canUpdate, canDelete } = useGetAllowedResources(
    "EmployeeFamilyInformation"
  );

  const isUnderModeration = useIsUnderModeration();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [familyDetails, setFamilyDetails] = useState<IFamilyMember[]>([]);

  useEffect(() => {
    setFamilyDetails(
      userDetails?.users?.dataCollection[0]?.userFamilyMembers || []
    );
  }, [userDetails?.users?.dataCollection]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [deleteUserFamilyMember] = useMutation(DELETE_FAMILY_MEMBER);

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Family detail")
    : deleteSuccessMessageWithAwaiting("Family detail");

  const deleteHandler = () => {
    deleteUserFamilyMember({
      variables: {
        id: editID,
      },
      update: (cache) => {
        const exFamilyMembers: any = cache.readQuery({
          query: GET_FAMILY_DETAILS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
        });

        const updatedFamilyMembers =
          exFamilyMembers?.users?.dataCollection[0]?.userFamilyMembers?.filter(
            (member: IFamilyMember) => +member.id !== editID
          );

        cache.writeQuery({
          query: GET_FAMILY_DETAILS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
          data: {
            users: {
              dataCollection: [
                {
                  ...exFamilyMembers?.users?.dataCollection[0],
                  userFamilyMembers: updatedFamilyMembers,
                },
              ],
            },
          },
        });
      },
    })
      .then(() => {
        refetchFamilyDetails();
        setDeleteVerification(false);
        setEditID(null);
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  const parentRef = useRef<HTMLDivElement>(null);

  const tableContentAddOrEditHandler = () => {
    setEditID(null);
    setShowModal(true);
  };

  const tableContentEditHandler = () => {
    if (canUpdate) {
      setShowModal(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const tableContentDeleteHandler = () => {
    if (canDelete) {
      setDeleteVerification(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
    setShowModal(false);
  };

  return (
    <div>
      <DataCard
        loading={loading}
        setShowModal={setShowModal}
        title="Family Details"
        addOrEditHandler={tableContentAddOrEditHandler}
        showPlusIcon={canCreate && familyDetails?.length === 0 ? false : true}
        resourceName={"EmployeeFamilyInformation"}
      >
        {canCreate && familyDetails?.length === 0 ? (
          <div className="flex justify-center items-center min-h-[355px] bg-white mt-[30px]">
            <Filled
              buttonName={"Add"}
              onClick={() => setShowModal(!showModal)}
            />
          </div>
        ) : (
          <Table
            loading={loading}
            noOfItems={familyDetails?.length}
            tableHeadings={tableHeadings}
            ref={parentRef}
            isCenterlastHeading={true}
            className="py-5"
          >
            {familyDetails?.map((familyDetail: IFamilyMember | any) => {
              const moderationObj = familyDetail?.moderation || null;
              const today = new Date().getTime();
              const birthdate = new Date(
                familyDetail.dateOfBirth?.replaceAll("-", " ")
              ).getTime();
              const d = Math.abs(today - birthdate);
              const days = Math.floor(d / (1000 * 60 * 60 * 24));
              const age = Math.floor(days / 365);
              const tableContent = [
                {
                  value: Capitalize(familyDetail.name),
                  moderationId: moderationObj?.name,
                },
                {
                  value: familyDetail.relationshipType,
                  moderationId: moderationObj?.relationshipType,
                },
                {
                  value: familyDetail.dateOfBirth,
                  moderationId: moderationObj?.dateOfBirth,
                },
                {
                  value: familyDetail.dateOfBirth && age,
                },
                {
                  value: familyDetail.occupation,
                  moderationId: moderationObj?.occupation,
                },
                {
                  value: familyDetail.mobile,
                  moderationId: moderationObj?.mobile,
                },
                {
                  value: familyDetail.aadhaar,
                  moderationId: moderationObj?.aadhaar,
                  file:
                    familyDetail?.documents &&
                    familyDetail?.documents[0]?.aadhaarFile,
                },
              ];

              return (
                <TableContent
                  key={familyDetail?.id}
                  id={familyDetail?.id}
                  tableContent={tableContent}
                  editAccess={canUpdate || canDelete}
                  onEdit={tableContentEditHandler}
                  showModal={showModal}
                  onDelete={tableContentDeleteHandler}
                  onMenuClick={() => setEditID(+familyDetail?.id)}
                  parentRef={parentRef}
                  refetchQuery={refetchFamilyDetails}
                />
              );
            })}
          </Table>
        )}
      </DataCard>

      {showModal && (
        <EditFamily
          familyDetails={familyDetails}
          setShowModal={setShowModal}
          editID={editID}
          setEditID={setEditID}
          inProgress={inProgress}
          setInProgress={setInProgress}
          refetchFamilyDetails={refetchFamilyDetails}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"Family Information"}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          onCancel={confirmModalCloseHandler}
        />
      )}
    </div>
  );
};

export default Family;
