import { Fragment, memo, useMemo, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FieldValues, Path, PathValue } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useFloating,
  hide,
} from "@floating-ui/react";

import {
  TAutoCompleteProps,
  TOptionType,
  ISearchFn,
} from "components/forms/UpdatedForm/types";

import {
  findArrayValueType,
  removeDuplicatesByKey,
} from "global/helpers/ArrayMethods";
import useUpdateEffect1 from "global/UpdatedHooks/hooks/useUpdateEffect1";

interface IProps<TForm> {
  value: PathValue<TForm, Path<TForm>>;
  onChangeHookForm: (...event: any[]) => void;
}

const ComboBox = <
  TForm extends FieldValues,
  TOptionObject extends {
    id: string | number;
    name: string;
    disabled?: boolean;
  }
>({
  errors,
  name,
  options: optionArray,
  value,
  multiple,
  onChangeHookForm,
  searchKey,
  label,
  disabled,
  loading,
  setValue,
  defaultValue,
  renderedOption,
  classForInput,
}: IProps<TForm> &
  Omit<
    TAutoCompleteProps<
      TForm,
      TOptionObject,
      keyof Omit<TOptionObject, "disabled">,
      TOptionType<TOptionObject> | ISearchFn<TOptionObject>
    >,
    | "className"
    | "classNameForError"
    | "control"
    | "hideError"
    | "onChange"
    | "required"
    | "shouldUnregister"
  >) => {
  const errorMessage = errors
    ? typeof errors === "string"
      ? errors
      : errors[name]?.message?.toString()
    : undefined;

  const [query, setQuery] = useState("");
  const [onChangeLoading, setOnChangeLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<TOptionType<TOptionObject>>(
    typeof optionArray === "function" ? [] : optionArray
  );

  useUpdateEffect1(() => {
    if (typeof optionArray !== "function") {
      setOptions(optionArray);
    }
  }, [optionArray]);

  const filteredOptions: TOptionType<TOptionObject> = useMemo(
    () =>
      query === ""
        ? options
        : findArrayValueType<string>(options, "string")
        ? options?.filter((option) =>
            option
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
          ) || []
        : findArrayValueType<number>(options, "number")
        ? options?.filter((option) =>
            option
              ?.toString()
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
          ) || []
        : searchKey && searchKey?.length > 0
        ? removeDuplicatesByKey(
            searchKey
              ?.map((key) => {
                return options?.filter((option) => {
                  return option?.[key]
                    ?.toString()
                    .toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(query.toLowerCase().replace(/\s+/g, ""));
                });
              })
              .flat(),
            "id"
          ) || []
        : options?.filter((option) => {
            return option?.name
              ?.toString()
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""));
          }),
    [options, query, searchKey]
  );

  const finalOptions =
    typeof optionArray === "function" ? options : filteredOptions;
  const { x, y, strategy, refs, middlewareData } = useFloating({
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ["bottom", "top"],
      }),
      hide({
        strategy: "referenceHidden",
      }),
    ],
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const isValue = multiple ? value?.length > 0 : value ? true : false;

  return (
    <Combobox
      value={multiple ? (Array.isArray(value) ? [...value] : []) : value}
      by={
        options?.length > 0
          ? typeof options[0] === "object"
            ? "id"
            : undefined
          : undefined
      }
      onChange={onChangeHookForm}
      disabled={disabled}
      multiple={multiple}
      defaultValue={
        defaultValue ? defaultValue : (null as PathValue<TForm, Path<TForm>>)
      }
    >
      {({ open, disabled }) => {
        return (
          <Fragment>
            <div
              ref={refs.setReference}
              className={`w-full rounded shadow ring-1 flex relative ${
                disabled
                  ? "ring-border-disabled bg-surface-disabled cursor-not-allowed"
                  : "bg-white"
              } ${
                errorMessage
                  ? "ring-valentine-red"
                  : "ring-[#e5e7eb] focus-within:ring-cornflower-blue"
              }`}
            >
              <div className="flex-1">
                {multiple && Array.isArray(value) && value?.length > 0 && (
                  <div className="flex flex-wrap pt-2.5 px-2 gap-3">
                    {value?.map(
                      (
                        result: string | number | TOptionObject,
                        index: number
                      ) => {
                        return (
                          <p
                            key={index}
                            className="flex gap-3 items-center text-xs whitespace-pre-wrap rounded-full bg-cornflower-blue/20 px-2 py-1"
                          >
                            <span className="text-cornflower-blue">
                              {typeof result === "object"
                                ? result?.name?.toString()
                                : result?.toString()}
                            </span>
                            {!disabled && (
                              <span className="w-5 h-5 rounded-full inline-flex hover:bg-white-smoke justify-center items-center">
                                <IoCloseSharp
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (typeof result === "object") {
                                      setValue(
                                        name,
                                        value?.filter(
                                          (option: TOptionObject) =>
                                            option?.id !== result?.id
                                        ) || []
                                      );
                                    } else {
                                      setValue(
                                        name,
                                        value?.filter(
                                          (option: string | number) =>
                                            option !== result
                                        ) || []
                                      );
                                    }
                                  }}
                                  className="w-4 h-4 cursor-pointer text-base text-cornflower-blue"
                                />
                              </span>
                            )}
                          </p>
                        );
                      }
                    )}
                  </div>
                )}
                <Combobox.Button className={`w-full `}>
                  <Combobox.Input
                    placeholder="Enter Something...."
                    className={`${
                      classForInput ? classForInput : ""
                    } w-full peer focus:outline-none rounded min-h-[45px] pl-3 text-sm ${
                      disabled
                        ? "bg-surface-disabled cursor-not-allowed"
                        : "bg-white"
                    } ${
                      isValue
                        ? ""
                        : errorMessage
                        ? "placeholder:text-transparent focus:placeholder:text-valentine-red"
                        : "placeholder:text-transparent focus:placeholder:text-ironside-gray/50"
                    }`}
                    displayValue={(
                      displayValue: string | number | TOptionObject
                    ) =>
                      multiple
                        ? ""
                        : displayValue
                        ? typeof displayValue === "object"
                          ? displayValue?.name
                          : displayValue?.toString()
                        : ""
                    }
                    autoComplete={"off"}
                    onChange={async (e) => {
                      if (typeof optionArray === "function") {
                        let a = e.target.value;
                        let b: string = "";
                        let timeout: NodeJS.Timeout;
                        await new Promise<void>((resolve) => {
                          setOnChangeLoading(true);
                          timeout = setTimeout(() => {
                            resolve();
                          }, 1000);
                        }).then(() => {
                          b = e.target?.value;
                          clearTimeout(timeout);
                        });
                        if (a === b) {
                          setOnChangeLoading(false);
                          setOptions(await optionArray(e));
                        }
                      } else {
                        setQuery(e?.target?.value);
                      }
                    }}
                    onClick={async () => {
                      if (!open && typeof optionArray === "function") {
                        setOptions(await optionArray());
                      }
                    }}
                  />
                  <Combobox.Label
                    htmlFor={name}
                    className={`transition-all duration-100 ease-in   ${
                      errorMessage
                        ? "text-valentine-red cursor-text"
                        : disabled
                        ? "text-text-disabled cursor-not-allowed"
                        : "text-warm-gray peer-focus-within:text-cornflower-blue cursor-text"
                    } inline-flex justify-center items-center absolute left-3 px-1 bg-white ${
                      isValue
                        ? "text-xxs -top-[6px]"
                        : "-top-[6px] text-xxs peer-placeholder-shown:top-1/4 peer-placeholder-shown:text-sm peer-focus:-top-[6px] peer-focus:text-xxs"
                    }`}
                  >
                    {label}
                  </Combobox.Label>
                </Combobox.Button>
              </div>
              <div className="flex justify-center items-center p-2">
                {!disabled && isValue && (
                  <IoMdClose
                    onClick={() => {
                      if (!disabled) {
                        setValue(name, null as PathValue<TForm, Path<TForm>>);
                      }
                    }}
                    className={`h-6 w-6 text-gray-400 p-1 cursor-pointer hover:bg-white-smoke rounded-full`}
                  />
                )}
                <Combobox.Button
                  className={`w-6 h-6 inline-flex justify-center items-center rounded-full ${
                    disabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:bg-white-smoke"
                  }`}
                >
                  <BsFillCaretDownFill
                    className={`h-3 w-3 text-gray-400 ${
                      open ? "rotate-180" : ""
                    }`}
                  />
                </Combobox.Button>
              </div>
            </div>
            <FloatingPortal>
              <Transition
                show={open}
                ref={refs.setFloating}
                as={"div"}
                appear
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  maxWidth: `${
                    refs.reference?.current?.getBoundingClientRect().width +
                    "px"
                  }`,
                  minWidth: `${
                    refs.reference?.current?.getBoundingClientRect().width +
                    "px"
                  }`,
                }}
                className={`${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                } max-h-[300px] overflow-y-auto shadow rounded border bg-white z-[100] p-2`}
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options as={"ul"} className={`space-y-1`}>
                  {loading || onChangeLoading ? (
                    <li className="w-full text-sm text-ironside-gray p-1.5">
                      Loading...
                    </li>
                  ) : finalOptions?.length === 0 ? (
                    <li className="w-full text-sm text-ironside-gray p-1.5">
                      Nothing Found.
                    </li>
                  ) : (
                    finalOptions?.map((option, index) =>
                      renderedOption ? (
                        <Combobox.Option
                          key={index}
                          value={option}
                          as={"li"}
                          disabled={
                            typeof option === "object"
                              ? option?.disabled
                              : false
                          }
                        >
                          {({ active, disabled, selected }) => {
                            return (
                              <Fragment>
                                {renderedOption(option, {
                                  active,
                                  disabled,
                                  selected,
                                })}
                              </Fragment>
                            );
                          }}
                        </Combobox.Option>
                      ) : (
                        <Combobox.Option
                          key={index}
                          value={option}
                          as={"li"}
                          disabled={
                            typeof option === "object"
                              ? option?.disabled
                              : false
                          }
                          className={({ active, disabled, selected }) =>
                            `p-2 text-sm rounded transition-all duration-100 ${
                              disabled
                                ? "cursor-default text-text-disabled bg-gray-400/20"
                                : active
                                ? "cursor-pointer text-white bg-cornflower-blue"
                                : "text-ironside-gray"
                            } ${selected ? "font-medium" : "font-normal"} `
                          }
                        >
                          {({ active, selected, disabled }) => (
                            <div className="flex justify-between items-center gap-1">
                              <span className="capitalize flex-1 truncate">
                                {typeof option === "object"
                                  ? option?.name?.toString()
                                  : option?.toString()}
                              </span>
                              <span
                                className={`${
                                  active && !disabled
                                    ? "text-white"
                                    : disabled
                                    ? "text-text-disabled"
                                    : "text-cornflower-blue"
                                } ${selected ? "visible" : "invisible"}`}
                              >
                                <BiCheck
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                          )}
                        </Combobox.Option>
                      )
                    )
                  )}
                </Combobox.Options>
              </Transition>
            </FloatingPortal>
          </Fragment>
        );
      }}
    </Combobox>
  );
};

export default memo(ComboBox);
