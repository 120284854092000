import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { HiCheck } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import OopsNoData from "components/UI/OopsNoData";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Loading from "components/Loader/Loading";
import UserDetails from "components/UserDetails/UserDetails";
import Tooltip from "components/UI/ToolTip";
import { Modal } from "components/forms";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";
import {
  IIdAndName,
  ILeaveRequestDates,
  ISetStateType,
} from "global/types/type";
import { toastNotify } from "global/helpers/Cache";

import TableDetailsGrid from "modules/Leave/Pages/Requests/TeamRequests/TableDetailsGrid";
import { IFilterLeaveRequestTypes } from "modules/Leave/types/leave";
import {
  UPDATE_CONSENT_REQUEST,
  UPDATE_LEAVE_REQUEST,
} from "modules/Leave/services/mutations";
import { FILTER_TEAM_LEAVE_REQUESTS } from "modules/Leave/services/queries";

const tableHeadings = [
  "ID",
  "Name",
  "Date",
  "Leave Type",
  "Session",
  "Status",
  "Is Team Member?",
  "Action",
  "Details",
];

const TeamRequestsDetails: FC<{
  teamMembersRequest: IFilterLeaveRequestTypes[];
  pageSize: number;
  currentPage: number;
  setPageSize: ISetStateType<number>;
  setCurrentPage: ISetStateType<number>;
  totalItems: number;
  loading: boolean;
  filterLeaveRequests: IFilterLeaveRequestTypes;
}> = ({
  teamMembersRequest,
  pageSize,
  currentPage,
  setPageSize,
  setCurrentPage,
  totalItems,
  loading,
  filterLeaveRequests,
}) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<number | undefined>();
  const onViewHandler = (request: IFilterLeaveRequestTypes) => {
    navigate(`${request?.id}`);
  };

  const [showModal, setShowModal] = useState(false);
  const { register, watch } = useForm();
  const reason = watch("reason");

  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const [updateConsentRequest, { loading: updateConsentLoading }] = useMutation(
    UPDATE_CONSENT_REQUEST,
    {
      refetchQueries: [FILTER_TEAM_LEAVE_REQUESTS],
    }
  );

  const [updateLeaveRequest, { loading: updateLeaveRequestLoading }] =
    useMutation(UPDATE_LEAVE_REQUEST, {
      refetchQueries: [FILTER_TEAM_LEAVE_REQUESTS],
    });

  const [modalTitle, setModalTitle] = useState("");
  const [editRequest, setEditRequest] = useState<{
    request: IFilterLeaveRequestTypes;
    date: ILeaveRequestDates;
  } | null>(null);

  const modalTitleHandler = () => {
    if (editRequest?.date?.session === "2 Hours") {
      setModalTitle("Permission");
    } else {
      setModalTitle("Leave Request");
    }
  };

  const confirmConsentHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    updateConsentRequest({
      variables: {
        leaveRequestId: +request?.id,
        leaveRequestDate: [
          {
            leaveRequestDateId: +date?.id,
            status: "Agreed",
          },
        ],
        isAllAgreed: true,
      },
    })
      .then((response: any) => {
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
        setEditRequest(null);
      })
      .catch((error: any) => {
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ]);
      });
  };

  const confirmHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    updateLeaveRequest({
      variables: {
        leaveRequestDate: [
          {
            leaveRequestDateId: +date?.id,
            status: "Approved",
          },
        ],
        leaveRequestId: +request?.id,
        userId: +localStorage?.id || +sessionId,
        isAllApprove: true,
      },
    })
      .then((response: any) => {
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
        setEditRequest(null);
      })
      .catch((error: any) => {
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ]);
      });
  };

  const consentDeclineHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    updateConsentRequest({
      variables: {
        leaveRequestId: +request?.id,
        leaveRequestDate: [
          {
            leaveRequestDateId: +date?.id,
            status: "Disagreed",
            declineMessage: reason,
          },
        ],
        isAllDisagreed: true,
      },
    })
      .then((response: any) => {
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
        setShowModal(false);
        setEditRequest(null);
      })
      .catch((error: any) => {
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ]);
      });
  };

  const declineHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    updateLeaveRequest({
      variables: {
        leaveRequestDate: [
          {
            leaveRequestDateId: +date?.id,
            status: "Declined",
          },
        ],
        leaveRequestId: +request?.id,
        userId: +localStorage?.id || +sessionId,
        declineMessage: reason,
        isAllDecline: true,
      },
    })
      .then((response: any) => {
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
        setShowModal(false);
        setEditRequest(null);
      })
      .catch((error: any) => {
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ]);
      });
  };

  const getDataModal = (date: ILeaveRequestDates, request: any) => {
    const userIdforRequest = +localStorage?.id || +sessionId;
    const isConsentRequest = request?.isConsentRequest;
    const consenterRequest =
      date?.leaveRequestDateConsents?.filter(
        (leave: { consentedByUser: { personalInformation: IIdAndName } }) =>
          leave?.consentedByUser?.personalInformation?.id === userIdforRequest
      ) || [];

    const isAgreedByAll = date?.leaveRequestDateConsents
      ?.map((date: { status: string }) => date?.status)
      ?.every((status: string) => status === "Agreed");

    const showLeaveActions =
      (isConsentRequest
        ? consenterRequest[0]?.status === "Pending"
        : date?.status === "Pending") && !date?.leaveType?.isSpecialLeave;

    const leaveActions = {
      lable: "Action",
      value: [
        {
          content: (
            <div className="flex">
              {(updateConsentLoading || updateLeaveRequestLoading) &&
              editRequest?.date?.id === date?.id &&
              !showModal ? (
                <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-cornflower-blue border-solid rounded-full animate-spin"></div>
              ) : (
                isAgreedByAll && (
                  <Tooltip
                    render={isConsentRequest ? "Agree" : "Approve"}
                    arrow
                    placement="top"
                  >
                    <HiCheck
                      className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
                      onClick={() => {
                        setEditRequest({
                          date,
                          request,
                        });
                        isConsentRequest
                          ? confirmConsentHandler(date, request)
                          : confirmHandler(date, request);
                      }}
                    />
                  </Tooltip>
                )
              )}
              <Tooltip
                render={isConsentRequest ? "Disagree" : "Decline"}
                arrow
                placement="top"
              >
                <MdOutlineClose
                  className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setEditRequest({
                      date,
                      request,
                    });
                    modalTitleHandler();
                  }}
                />
              </Tooltip>
            </div>
          ),
        },
      ],
    };

    const commonVariables = [
      {
        lable: "ID",
        value: [
          {
            content: request?.id,
          },
        ],
      },
      {
        lable: "Name",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() =>
                    setUserId(request?.requestedByUser?.personalInformation?.id)
                  }
                >
                  {request?.requestedByUser?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Date",
        value: [
          {
            content: date?.leaveDate,
          },
        ],
      },
      {
        lable: "Leave Type",
        value: [
          {
            content: date?.leaveType?.name,
          },
        ],
      },
      {
        lable: "Session",
        value: [
          {
            content: date?.session,
          },
        ],
      },
      {
        lable: "Status",
        value: [
          {
            content:
              isConsentRequest && date?.status !== "Withdrawn"
                ? consenterRequest[0]?.status
                : date?.status,
            color: getStatusColor(
              isConsentRequest ? consenterRequest[0]?.status : date?.status
            ),
          },
        ],
      },
      {
        lable: "Is Team Member?",
        value: [
          {
            content: request?.isConsentRequest ? "No" : "Yes",
          },
        ],
      },
    ];

    return showLeaveActions
      ? [...commonVariables, leaveActions]
      : commonVariables;
  };

  return (
    <Fragment>
      <div className="hidden md:block rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray md:mt-[5px]">
        <div className="flex flex-col bg-white justify-between min-h-[560px]">
          <Table
            noOfItems={filterLeaveRequests && teamMembersRequest?.length}
            currentPage={currentPage}
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={totalItems || 0}
            isCenterlastHeading={true}
            className="min-h-[590px]"
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: name === "Name" ? false : true,
              };
            })}
          >
            {teamMembersRequest?.map((request, index: number) => {
              return (
                <TableDetailsGrid
                  request={request}
                  key={index}
                  userId={userId}
                  setUserId={setUserId}
                />
              );
            })}
          </Table>
        </div>
      </div>
      <div className="md:hidden lg:px-[15px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] bg-white md:border-none border-light-gray md:mt-[30px]">
        <div className="flex flex-col bg-white">
          {loading ? (
            <Loading className="min-h-[400px]" />
          ) : teamMembersRequest?.length > 0 ? (
            <Fragment>
              <div className="w-full overflow-x-auto min-h-[412px]">
                {teamMembersRequest?.map(
                  (request: IFilterLeaveRequestTypes) => {
                    return request?.leaveRequestDates?.map(
                      (date, index: number) => {
                        return (
                          <SMDataCard
                            key={index}
                            title={`Request`}
                            loading={loading}
                            dataModal={getDataModal(date, request)}
                            onView={() => {
                              onViewHandler(request);
                            }}
                          />
                        );
                      }
                    );
                  }
                )}
              </div>
              <Pagination
                totalCount={totalItems ? totalItems : 0}
                noOfItem={teamMembersRequest?.length}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </Fragment>
          ) : (
            filterLeaveRequests && <OopsNoData />
          )}
        </div>
      </div>
      {showModal ? (
        <Modal>
          <div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
            <div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
              <h2 className="text-base text-ironside-gray">
                {modalTitle}
                {editRequest?.request?.isConsentRequest
                  ? "Disagree"
                  : "Decline"}
              </h2>
              <div
                className="edit-modal-close-icon-div"
                onClick={() => {
                  setShowModal(false);
                  setEditRequest(null);
                }}
              >
                <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
              </div>
            </div>
            <div className="w-full py-[20px] px-[20px]">
              <textarea
                className="w-full h-[200px] border border-gray-200 focus:outline-none p-4"
                placeholder="Type your reason here"
                {...register("reason")}
              />
            </div>
            <div className="w-full border-b" />
            <div className="flex items-center justify-center p-6">
              <button
                type="button"
                onClick={() => {
                  editRequest?.request?.isConsentRequest
                    ? consentDeclineHandler(
                        editRequest?.date,
                        editRequest?.request
                      )
                    : editRequest &&
                      declineHandler(editRequest?.date, editRequest?.request);
                }}
                className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
              >
                {updateConsentLoading || updateLeaveRequestLoading ? (
                  <div className="btn-spinner" />
                ) : editRequest?.request?.isConsentRequest ? (
                  "Disagree"
                ) : (
                  "Decline"
                )}
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default TeamRequestsDetails;
