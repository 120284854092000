import { FC } from "react";
import { HiChevronUp, HiClock, HiCog, HiFolder } from "react-icons/hi";
import { AiFillDashboard } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoCalendarSharp } from "react-icons/io5";
import { GiOrganigram } from "react-icons/gi";

import useWindowSize from "global/hooks/useWindowSize";
import { ReactComponent as CompanyIcon } from "global/assets/images/company.svg";

interface IProps {
  menu: any;
  title: string;
  showSubMenu: boolean;
  setShowSubMenu: Function;
  showMenu: any;
  activeLink: number | null;
  index: number;
  setShowMenu: Function;
}

const NavLinkDetails: FC<IProps> = ({
  menu,
  title,
  showSubMenu,
  setShowSubMenu,
  showMenu,
  activeLink,
  index,
  setShowMenu,
}) => {
  const sidebarIconClass = `flex-shrink-0 ${menu.iconStyle} ${
    showMenu ? "mr-[28px]" : "mr-[15px]"
  } ${
    menu?.subMenus?.length > 0
      ? "text-ironside-gray"
      : menu.name === title && activeLink === index
      ? "text-cornflower-blue"
      : "text-ironside-gray"
  }`;

  const [width] = useWindowSize();

  return (
    <div className="w-full overflow-hidden">
      <div
        className={`flex justify-between w-full items-center px-[32px] py-[16px] relative z-50 ${
          menu.name === title ? "bg-[#ECF1FE]" : "bg-white hover:bg-white-smoke"
        }`}
        onClick={() => {
          setShowSubMenu(!showSubMenu);
          if (
            showMenu &&
            width < 1280 &&
            menu.name !== "Leave" &&
            menu.name !== "Projects"
          ) {
            setShowMenu(false);
          }
        }}
      >
        <div className="flex w-full items-center">
          {(() => {
            switch (menu.iconName) {
              case "Dashboard":
                return <AiFillDashboard className={sidebarIconClass} />;
              case "ClockIcon":
                return <HiClock className={sidebarIconClass} />;
              case "UserIcon":
                return <HiFolder className={sidebarIconClass} />;
              case "CalendarIcon":
                return <IoCalendarSharp className={sidebarIconClass} />;
              case "CurrencyDollarIcon":
                return <RiMoneyDollarCircleFill className={sidebarIconClass} />;
              case "UserGroupIcon":
                return <FaUsers className={sidebarIconClass} />;
              case "BookOpenIcon":
                return <BiSupport className={sidebarIconClass} />;
              case "GalleryIcon":
                return <GiOrganigram className={sidebarIconClass} />;
              case "CogIcon":
                return <HiCog className={sidebarIconClass} />;
              case "CompanyIcon":
                return <CompanyIcon className={`${sidebarIconClass}`} />;
              default:
                return null;
            }
          })()}
          <div
            className={`text-[14px] flex flex-1 justify-between items-center leading-[20px] ${
              menu?.subMenus?.length > 0
                ? "text-ironside-gray"
                : menu.name === title && activeLink === index
                ? "text-cornflower-blue"
                : "text-ironside-gray"
            }`}
          >
            <span>{menu.name === "Company" ? "Companies" : menu?.name}</span>
            {menu?.subMenus?.length > 0 ? (
              <HiChevronUp
                className={`w-6 h-6 ${
                  showSubMenu ? "rotate-0" : "rotate-180"
                } transition-transform duration-700`}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavLinkDetails;
