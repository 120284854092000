import { FC, Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import NoDataFoundMessage from "components/UI/NoDataFoundMessage";
import AddButton from "components/forms/Button/AddButton";

import {
	paginationDefaultCount,
	setTitle,
	toastNotify,
} from "global/helpers/Cache";
import { FilterFormType } from "global/types/type";
import {
	useCustomSearchParams,
	useEffectOnce,
	useSearch,
} from "global/UpdatedHooks/hooks";
import usePreLoading from "global/hooks/usePreLoading";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import {
	ProjectGroupBug,
	ProjectGroupBugEditList,
	ProjectGroupBugFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";
import {
	DELETE_PROJECT_GROUP_BUG,
	FILTER_PROJECT_GROUP_BUGS,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/services";
import ProjectGroupBugFilter from "modules/Project/Pages/Projects/ProjectGroupBugs/helpers/ProjectGroupBugFilter";
import ProjectGroupBugTable from "modules/Project/Pages/Projects/ProjectGroupBugs/ProjectGroupBugList/ProjectGroupBugTable";
import ProjectGroupBugCard from "modules/Project/Pages/Projects/ProjectGroupBugs/ProjectGroupBugList/ProjectGroupBugCard";
import { filterSubmitValues } from "modules/Project/Pages/Projects/ProjectGroupBugs/helpers/utils";

interface Props {
	allowedResources: string[];
	projectGroup: ProjectGroup | null;
}

const ProjectGroupBugs: FC<Props> = ({ allowedResources, projectGroup }) => {
	const canCreate = allowedResources?.includes("CreateProjectGroupBug");
	const canUpdate = allowedResources?.includes("UpdateProjectGroupBug");
	const canDelete = allowedResources?.includes("DeleteProjectGroupBug");

	const [deleteProjectGroupBug, { loading: deleteProjectGroupBugLoading }] =
		useMutation(DELETE_PROJECT_GROUP_BUG);

	const [searchParams, setSearchParams] = useCustomSearchParams();

	const [editProjectGroupBug, setEditProjectGroupBug] =
		useState<ProjectGroupBugEditList | null>(null);

	const paginationDefaultValue = useReactiveVar(paginationDefaultCount);

	useEffectOnce(() => {
		setSearchParams({
			...searchParams,
			limit: `${paginationDefaultValue}`,
		});
	});

	const [filterValues, setFilterValues] = useState<
		ProjectGroupBugFilterFormType | null | undefined
	>(null);

	const { control, resetField, reset, setValue } = useForm<
		FilterFormType<ProjectGroupBugFilterFormType>
	>({
		defaultValues: {
			search: searchParams?.search || "",
			pageSize: paginationDefaultValue || 10,
			filter: {
				description: null,
				platform: null,
				priority: null,
				source: null,
				title: null,
			},
		},
		shouldUnregister: false,
	});

	const [pageSize, watchSearch] = useWatch({
		control,
		name: ["pageSize", "search"],
	});
	const search = useSearch(watchSearch || "");

	useEffect(() => {
		setTitle([
			{ name: "Project", link: "/projects/groups" },
			{ name: "Groups", link: "/projects/groups" },
			{
				name: projectGroup?.name || "",
				link: `/projects/${projectGroup?.id}/bugs`,
			},
			{ name: "Bugs", link: `/projects/${projectGroup?.id}/bugs` },
		]);
	}, [projectGroup?.id, projectGroup?.name]);

	const { groupId } = useParams();
	const navigate = useNavigate();

	const {
		data: filterProjectGroupBugs,
		loading,
		fetchMore,
		updateQuery,
	} = useQuery(FILTER_PROJECT_GROUP_BUGS, {
		fetchPolicy: "cache-and-network",
		skip: groupId ? false : true,
		variables: {
			pagination: {
				size: pageSize,
			},
			filters: groupId
				? {
						projectGroupId: {
							number: +groupId,
						},
						status: {
							isExactly: "pending",
						},
				  }
				: undefined,
			globalSearch: search || undefined,
		},
		notifyOnNetworkStatusChange: true,
	});

	const onEdit = (
		type: "create" | "update" | "delete",
		projectGroupBug?: ProjectGroupBug | null,
	) => {
		if (type === "create") {
			setEditProjectGroupBug({ type });
		} else if (projectGroupBug) {
			setEditProjectGroupBug({ type, projectGroupBug });
		}
	};

	const closeHandler = () => {
		setEditProjectGroupBug(null);
	};

	const projectGroupBugEdges =
		filterProjectGroupBugs?.filterProjectGroupBugs?.edges || [];

	const projectGroupBugsLength = projectGroupBugEdges?.length || 0;

	const totalCount =
		filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo
			?.totalNumberOfItems || 0;

	const addBtnHandler = () => {
		navigate(`/projects/${groupId}/bugs/new`);
	};

	const preLoading = usePreLoading({
		queryLoading: loading,
	});

	const onPageSizeChange: (pageSize: number) => void = (pageSize) => {
		resetField("search");
		fetchMore({
			variables: {
				pagination: {
					size: pageSize,
				},
				globalSearch: search || undefined,
				filters:
					filterValues && projectGroup?.id
						? {
								projectGroupId: {
									number: +projectGroup?.id,
								},
								...filterSubmitValues(filterValues),
						  }
						: projectGroup?.id
						  ? {
									projectGroupId: {
										number: +projectGroup?.id,
									},
									status: {
										isExactly: "pending",
									},
							  }
						  : undefined,
			},
			updateQuery: (_prev, { fetchMoreResult: { filterProjectGroupBugs } }) => {
				return { filterProjectGroupBugs };
			},
		}).catch((error) => {
			toastNotify(errorMessageNotify(error));
		});
	};

	const nextDisabled =
		filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo?.endCursor &&
		filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo?.hasNextPage
			? false
			: true;

	const onNextPageHandler = () => {
		fetchMore({
			variables: {
				filters:
					filterValues && projectGroup?.id
						? {
								projectGroupId: {
									number: +projectGroup?.id,
								},
								...filterSubmitValues(filterValues),
						  }
						: projectGroup?.id
						  ? {
									projectGroupId: {
										number: +projectGroup?.id,
									},
									status: {
										isExactly: "pending",
									},
							  }
						  : undefined,
				pagination: {
					size: pageSize,
					after:
						filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo?.endCursor,
				},
				globalSearch: search || undefined,
			},
			updateQuery: (_prev, { fetchMoreResult: { filterProjectGroupBugs } }) => {
				return { filterProjectGroupBugs };
			},
		}).catch((error) => {
			toastNotify(errorMessageNotify(error));
		});
	};

	const onPrevPageHandler = () => {
		fetchMore({
			variables: {
				pagination: {
					size: pageSize,
					before:
						filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo
							?.startCursor,
				},
				globalSearch: search || undefined,
				filters:
					filterValues && projectGroup?.id
						? {
								projectGroupId: {
									number: +projectGroup?.id,
								},
								...filterSubmitValues(filterValues),
						  }
						: projectGroup?.id
						  ? {
									projectGroupId: {
										number: +projectGroup?.id,
									},
									status: {
										isExactly: "pending",
									},
							  }
						  : undefined,
			},
			updateQuery: (_prev, { fetchMoreResult: { filterProjectGroupBugs } }) => {
				return { filterProjectGroupBugs };
			},
		}).catch((error) => {
			toastNotify(errorMessageNotify(error));
		});
	};

	const prevDisabled =
		filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo?.startCursor &&
		filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo?.hasPreviousPage
			? false
			: true;

	const onDelete = () => {
		if (
			editProjectGroupBug?.type === "delete" &&
			editProjectGroupBug?.projectGroupBug?.id
		) {
			deleteProjectGroupBug({
				variables: {
					id: editProjectGroupBug?.projectGroupBug?.id,
				},
			})
				.then(({ data }) => {
					if (data?.deleteProjectGroupBug) {
						closeHandler();
						toastNotify([
							{
								message: "Bug deleted successfully.",
								messageType: "success",
							},
						]);
						if (
							filterProjectGroupBugs?.filterProjectGroupBugs?.edges?.length ===
								1 &&
							filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo
								?.hasPreviousPage &&
							filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo
								?.startCursor
						) {
							fetchMore({
								variables: {
									pagination: {
										size: pageSize,
										before:
											filterProjectGroupBugs?.filterProjectGroupBugs?.pageInfo
												?.startCursor || undefined,
									},
									status: {
										isExactly: "pending",
									},
								},
								updateQuery: (
									_prev,
									{ fetchMoreResult: { filterProjectGroupBugs } },
								) => {
									return {
										filterProjectGroupBugs,
									};
								},
							}).catch((error) => {
								toastNotify(errorMessageNotify(error));
							});
							reset();
						} else {
							updateQuery(({ filterProjectGroupBugs }) => {
								return {
									filterProjectGroupBugs: filterProjectGroupBugs
										? {
												pageInfo: filterProjectGroupBugs?.pageInfo
													? {
															...filterProjectGroupBugs?.pageInfo,
															totalNumberOfItems: filterProjectGroupBugs
																?.pageInfo?.totalNumberOfItems
																? filterProjectGroupBugs?.pageInfo
																		?.totalNumberOfItems - 1
																: 0,
													  }
													: null,
												edges:
													filterProjectGroupBugs?.edges &&
													filterProjectGroupBugs?.edges?.length > 0
														? filterProjectGroupBugs?.edges?.filter(
																(edge) =>
																	edge?.node?.id !==
																	editProjectGroupBug?.projectGroupBug?.id,
														  ) || []
														: [],
												__typename: filterProjectGroupBugs?.__typename,
										  }
										: null,
								};
							});
						}
					} else {
						toastNotify([
							{
								message: "Something went wrong.",
								messageType: "error",
							},
						]);
					}
				})
				.catch((err) => toastNotify(errorMessageNotify(err)));
		}
	};

	const cardOrTableClickHandler = (bugId: number) => {
		navigate(`/projects/${groupId}/bugs/${bugId}`);
	};

	const tableAndCardProps = {
		canUpdate,
		canDelete,
		onEdit,
		cardOrTableClickHandler,
		nextDisabled,
		prevDisabled,
		control,
		totalCount,
		projectGroupBugEdges,
		onPageSizeChange,
		onNextPageHandler,
		onPrevPageHandler,
	};

	return (
		<Fragment>
			<ProjectGroupBugFilter
				control={control}
				setValue={setValue}
				search={search}
				projectGroupBugsLength={projectGroupBugsLength}
				canCreate={canCreate}
				addBtnHandler={addBtnHandler}
				pageSize={pageSize}
				fetchMore={fetchMore}
				filterValues={filterValues}
				setFilterValues={setFilterValues}
				projectGroupId={projectGroup?.id}
			/>
			{preLoading ? (
				<Loading className="min-h-[calc(100vh-25vh)]" />
			) : projectGroupBugsLength > 0 ? (
				<Fragment>
					<ProjectGroupBugCard {...tableAndCardProps} />
					<ProjectGroupBugTable {...tableAndCardProps} />
				</Fragment>
			) : (search?.length > 0 && projectGroupBugsLength === 0) || !canCreate ? (
				<NoDataFoundMessage message="Sorry, we couldn't find any bug." />
			) : (
				canCreate && <AddButton onPress={addBtnHandler} />
			)}
			{canDelete && editProjectGroupBug?.type === "delete" && (
				<ConfirmModal
					header="Project group bug"
					onCancel={closeHandler}
					onXIcon={closeHandler}
					onExecute={onDelete}
					loading={deleteProjectGroupBugLoading}
				/>
			)}
		</Fragment>
	);
};

export default ProjectGroupBugs;
