import { gql } from "@apollo/client";

export const PROJECT_GROUP_TASK_FRAGMENT = gql`
  fragment projectGroupTaskFields on ProjectGroupTask {
    id
    description
    platform
    priority
    projectGroup {
      id
      name
    }
    status
    title
    attachments {
      fileUrl
      id
    }
    projectBacklogs {
      dataCollection {
        project {
          id
          name
        }
      }
    }
  }
`;
