import { FC, useCallback, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { toastNotify } from "global/helpers/Cache";
import { IResourceAccess } from "global/types/acl";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";

import { ISkillDetail, ISkillsList } from "modules/Employee/types/skills";

interface IProps {
  userSkills: ISkillDetail[];
  setEditSkill: Function;
  userNonTechnicalSkills: string[];
  userNonTechnicalSkillsId: number[];
  userNonTechnicalSkillsList: ISkillsList[];
  setShowModal: Function;
  setDeleteVerification: Function;
  resourceAccess: IResourceAccess;
  userNonTechnicalSkillsModerationIds: number[];
  setChangedInformation: Function;
  setShowUnderReview: Function;
  parentRef: any;
}

const NonTechnicalSkills: FC<IProps> = ({
  userSkills,
  setEditSkill,
  userNonTechnicalSkills,
  userNonTechnicalSkillsId,
  userNonTechnicalSkillsList,
  setShowModal,
  setDeleteVerification,
  resourceAccess,
  userNonTechnicalSkillsModerationIds,
  setChangedInformation,
  setShowUnderReview,
  parentRef,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const skillTypesLength = userSkills?.filter(
    (userSkill: ISkillDetail) => userSkill?.skill?.type === "Non Technical"
  )?.length;

  const skillNames = userSkills
    ?.map((userSkill: ISkillDetail) =>
      userSkill?.skill?.type === "Non Technical" ? userSkill?.skill?.name : null
    )
    ?.filter((skillName: string | null) => skillName)
    ?.join(", ");

  const kebabIconClickHandler = () => {
    setEditSkill({
      type: "Non Technical",
      skills: {
        ids: userNonTechnicalSkillsId,
        names: userNonTechnicalSkills,
        list: userNonTechnicalSkillsList,
      },
    });
    setShowEditOption((prevState) => !prevState);
  };

  const kebabDeleteHandler = () => {
    if (resourceAccess?.canDelete) {
      setShowEditOption(false);
      setDeleteVerification(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };
  const kebabEditHandler = () => {
    if (resourceAccess?.canUpdate) {
      setShowEditOption(false);
      setShowModal(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const moderationPopUpHandler = (moderationIds: (number | null)[]) => {
    const filteredModerationIds = moderationIds?.filter(
      (moderationId: number | null) => moderationId
    );

    filteredModerationIds?.length > 0 && setShowUnderReview(true);

    setChangedInformation(filteredModerationIds);
  };
  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const parentEventRef = parentRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    parentEventRef && parentEventRef?.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [getPosition, parentRef]);

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };
  return (
    <div
      className={`${
        skillTypesLength > 0 ? "block" : "hidden"
      } grid grid-cols-[150px_auto] gap-2`}
    >
      <div className="flex justify-between text-sm text-warm-gray">
        <span>Non Technical</span>
        <span>:</span>
      </div>
      <div className="flex justify-between">
        <div className="flex-1">
          <p
            className={`${
              userNonTechnicalSkillsModerationIds?.length > 0 &&
              "bg-yellow-100 cursor-pointer"
            } text-sm text-ironside-gray`}
            onClick={() =>
              moderationPopUpHandler(userNonTechnicalSkillsModerationIds)
            }
          >
            {skillNames}
          </p>
        </div>
        {(resourceAccess?.canUpdate || resourceAccess?.canDelete) && (
          <div ref={editRef} className="relative w-min">
            <div className="w-min" ref={kebabIconRef}>
              <KebabIcons
                onClick={kebabIconClickHandler}
                className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                  showEditOption ? "bg-white-smoke" : null
                }`}
              />
            </div>
            {showEditOption && (
              <div className="fixed z-[80]" style={showEditOptionStyle}>
                {showEditOption && (
                  <KebabMenu
                    onDelete={kebabDeleteHandler}
                    onEdit={kebabEditHandler}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NonTechnicalSkills;
