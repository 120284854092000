import { useEffect, useState } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { CalendarField, Filled, Modal, Outlined } from "components/forms";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";
import ToggleSwitchWithLabel from "components/forms/UpdatedForm/ToggleSwitch/ToggleSwitchWithLabel";
import Select from "components/forms/UpdatedForm/Select";

import { toastNotify, userContextData } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";

import { CREATE_OR_UPDATE_PROJECT_SPRINT } from "modules/Project/services/mutations";
import {
  IProjectSprint,
  IProjectSprintForm,
} from "modules/Project/types/project";
import { SPRINTS } from "modules/Project/services/queries";

const EditSprint: React.FC<{
  editProjectSprint: IProjectSprint | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectSprints: Function;
  editProject: { id: number; name: string };
  allowedResourcesForProject: { [key: string]: boolean };
}> = ({
  editProjectSprint,
  setShowModal,
  loading,
  refetchProjectSprints,
  editProject,
  allowedResourcesForProject,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
  const createOrUpdateAccess =
    allowedResourcesForProject["SaveProjectSprint"] || isSuperAdmin;

  const [saveProjectSprint, { loading: saveProjectMemberLoading }] =
    useMutation(CREATE_OR_UPDATE_PROJECT_SPRINT, {
      refetchQueries: [SPRINTS],
    });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<IProjectSprintForm>();

  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [endDate, setEndDate] = useState<Date | null | undefined>();

  useEffect(() => {
    if (editProjectSprint?.startDate) {
      setStartDate(
        new Date(editProjectSprint?.startDate?.toString()?.replaceAll("-", " "))
      );
    }

    if (editProjectSprint?.endDate) {
      setEndDate(
        new Date(editProjectSprint?.endDate?.toString()?.replaceAll("-", " "))
      );
    }
  }, [editProjectSprint?.startDate, editProjectSprint?.endDate]);

  useEffect(() => {
    if (startDate) {
      setValue("startDate", startDate?.toString());
      clearErrors("startDate");
    }

    if (endDate) {
      setValue("endDate", endDate?.toString());
      clearErrors("endDate");
    }
  }, [startDate, endDate, setValue, clearErrors]);

  const createOrUpdateProjectSprint: SubmitHandler<IProjectSprintForm> = ({
    name,
    description,
    startDate,
    endDate,
    isCompleted,
    status,
  }) => {
    if (!saveProjectMemberLoading) {
      saveProjectSprint({
        variables: {
          projectSprint: {
            id: editProjectSprint?.id || undefined,
            project: editProject?.id,
            name,
            description,
            startDate: startDate && ViewDateFormat(startDate),
            endDate: endDate && ViewDateFormat(endDate),
            isCompleted,
            status,
          },
        },
      })
        .then((response) => {
          const projectSprint = response?.data?.saveProjectSprint?.name;
          setShowModal(false);
          refetchProjectSprints();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectSprint?.id ? editProjectSprint?.name : projectSprint
              } has been successfully ${
                editProjectSprint?.id ? "updated." : "added."
              }`,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Sprint</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
              setStartDate(null);
              setEndDate(null);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectSprint)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  setValue={setValue}
                  defaultValue={editProjectSprint?.name}
                  required
                />
                <TextArea
                  errors={errors}
                  label="Description"
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectSprint?.description}
                />
                <CalendarField
                  register={register}
                  errors={errors}
                  name="startDate"
                  date={startDate}
                  setDate={setStartDate}
                  className="h-[50px]"
                  label="Start Date *"
                  maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                  minDate={new Date()}
                  required
                />
                <CalendarField
                  register={register}
                  errors={errors}
                  name="endDate"
                  date={endDate}
                  setDate={setEndDate}
                  className="h-[50px]"
                  label="End Date *"
                  maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                  minDate={new Date()}
                  required
                />
                <Select
                  control={control}
                  errors={errors}
                  label="Status"
                  name="status"
                  options={["Active", "Upcoming", "Completed"]}
                  defaultValue={editProjectSprint?.status}
                  setValue={setValue}
                />
                <ToggleSwitchWithLabel
                  label="Is Completed"
                  register={register}
                  name="isCompleted"
                  defaultValue={editProjectSprint?.isCompleted}
                  setValue={setValue}
                  className="pb-5"
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  loading={saveProjectMemberLoading}
                  buttonName={editProjectSprint?.id ? "Update" : "Add"}
                  disabled={saveProjectMemberLoading || !createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditSprint;
