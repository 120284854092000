import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { BsDot } from "react-icons/bs";

import { ReactComponent as CheckIcon } from "global/assets/images/check-small.svg";

type checkboxProps = {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  onClick?: () => void;
  className?: string;
};

const CustomCheckbox: FC<checkboxProps> = ({
  isChecked,
  isIndeterminate,
  onClick,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        "w-[18px] h-[18px] cursor-pointer border-2 flex justify-center items-center rounded-sm",
        isChecked || isIndeterminate
          ? "bg-primary border-primary"
          : "border-on-surface-variant",
        className
      )}
    >
      {isChecked ? (
        <CheckIcon className={"w-6 h-6 min-w-[24px] min-h-[24px] text-white"} />
      ) : isIndeterminate ? (
        <BsDot className={"w-6 h-6 min-w-[24px] min-h-[24px] text-white"} />
      ) : null}
    </div>
  );
};

export default CustomCheckbox;
