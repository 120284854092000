import { TypedDocumentNode, gql } from "@apollo/client";

import {
  ClientsFilterInput,
  FilterClientsReturnType,
} from "modules/Organization/Pages/Clients/types";
import { CLIENT_FRAGMENT } from "modules/Organization/Pages/Clients/services/fragments";


 const GET_ALL_CLIENTS_DROP_DOWN_WITH_USER_TYPE = gql`
  query getAllClientsForDropDownWithUserType {
    getAllClientsForDropDown {
      personalInformation {
        id
        name
      }
      userType
    }
  }
`;

const FILTER_CLIENTS: TypedDocumentNode<
  FilterClientsReturnType,
  ClientsFilterInput
> = gql`
  ${CLIENT_FRAGMENT}
  query GetAllClients(
    $status: String
    $limit: Int
    $page: Int
    $search: String
    $isClientOfficialDateOfBirthNeed: Boolean = false
    $isClientPersonalEmailNeed: Boolean = false
    $isClientRoleNeed: Boolean = false
    $isClientUserTypeNeed: Boolean = false
  ) {
    getAllClients(
      status: $status
      limit: $limit
      page: $page
      search: $search
    ) {
      dataCollection {
        ...clientFragment
      }
      totalNumberOfItems
    }
  }
`;



export { FILTER_CLIENTS,GET_ALL_CLIENTS_DROP_DOWN_WITH_USER_TYPE };
