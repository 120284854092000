import React, { Fragment, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import OopsNoData from "components/UI/OopsNoData";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";

import { toastNotify } from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";

import { DELETE_AUTO_APPROVAL_TIME } from "modules/Leave/services/mutations";
import { GET_LEAVE_AUTO_APPROVE_TIMES_LIST } from "modules/Leave/services/queries";
import EditLeaveApproval from "modules/Leave/Pages/Settings/AutoApprovalTimeSetting/EditLeaveApproval";
import { ILeaveAutoApproval } from "modules/Leave/types/leave";

const tableHeadings = [
  { name: "Auto Approval Type", center: false },
  { name: "Hours", center: false },
];

const LeaveApproval: React.FC<{ allowedResources: string[] }> = ({
  allowedResources,
}) => {
  const createOrUpdateAccess = allowedResources?.includes(
    "SaveAutoApprovalTime"
  );

  const {
    data: getAutoApprovalTimes,
    loading,
    refetch,
  } = useQuery(GET_LEAVE_AUTO_APPROVE_TIMES_LIST);
  const [editAutoApprovalData, setEditAutoApprovalData] =
    useState<ILeaveAutoApproval | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(10);

  const parentRef = useRef<HTMLDivElement>(null);
  const deleteAccess = allowedResources?.includes("DeleteAutoApprovalTime");
  const [deleteVerification, setDeleteVerification] = useState<boolean>();
  const [deleteAutoApprovalTime] = useMutation(DELETE_AUTO_APPROVAL_TIME);

  const deleteHandler = () => {
    if (editAutoApprovalData) {
      deleteAutoApprovalTime({
        variables: {
          id: +editAutoApprovalData?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Leave Auto Approval has been deleted successfully.",
            },
          ]);
          refetch();
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ])
        );
    }
  };
  const plusIconClickHandler = () => {
    setShowModal(true);
    setEditAutoApprovalData(null);
  };
  const kebabMenuEditHandler = () => {
    if (createOrUpdateAccess) {
      setShowModal(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuClickHandler = (leaveAutoApproval: ILeaveAutoApproval) => {
    setEditAutoApprovalData(leaveAutoApproval);
  };

  return (
    <div>
      {getAutoApprovalTimes?.leaveAutoApprovalTime?.length === 0 &&
      createOrUpdateAccess ? (
        <div className="absolute top-[60%] left-[40%] md:top-[50%] md:left-[50%] ml-[-50px] mt-[-50px]">
          <button
            className="w-[180px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 leading-[20px] rounded-[4px]"
            onClick={() => {
              setShowModal(true);
              setEditAutoApprovalData(null);
            }}
          >
            Add Auto Approval Time
          </button>
        </div>
      ) : (
        getAutoApprovalTimes?.leaveAutoApprovalTime?.length === 0 && (
          <OopsNoData className="min-h-[70vh]" />
        )
      )}
      {getAutoApprovalTimes?.leaveAutoApprovalTime?.length > 0 && (
        <div className="hidden md:block overflow-x-auto min-h-[412px]">
          <Table
            createAccess={createOrUpdateAccess}
            noOfItems={getAutoApprovalTimes?.leaveAutoApprovalTime?.length}
            tableHeadingsObj={tableHeadings}
            className="py-5 min-h-[612px]"
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            ref={parentRef}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={
              getAutoApprovalTimes?.leaveAutoApprovalTime?.length || 0
            }
            onPlusIconClick={plusIconClickHandler}
          >
            {getAutoApprovalTimes?.leaveAutoApprovalTime?.map(
              (leaveAutoApproval: ILeaveAutoApproval) => {
                let tableContent = [
                  { value: leaveAutoApproval?.type },
                  {
                    value: leaveAutoApproval?.hours,
                  },
                ];
                return (
                  <TableContent
                    key={leaveAutoApproval?.id}
                    id={leaveAutoApproval?.id}
                    tableContent={tableContent}
                    parentRef={parentRef}
                    editAccess={createOrUpdateAccess && deleteAccess}
                    showModal={showModal || deleteVerification}
                    onEdit={kebabMenuEditHandler}
                    onMenuClick={() => {
                      kebabMenuClickHandler(leaveAutoApproval);
                    }}
                    onDelete={kebabMenuDeleteHandler}
                  />
                );
              }
            )}
          </Table>
        </div>
      )}
      <div className="md:hidden overflow-x-auto min-h-[412px] mt-[40px]">
        <div className="lg:mx-0 lg:px-[45px] bg-white m-[8px] mt-[40px]">
          {loading ? (
            <Loading className="min-h-[400px]" />
          ) : (
            <Fragment>
              <div>
                {getAutoApprovalTimes?.leaveAutoApprovalTime?.map(
                  (leaveAutoApproval: ILeaveAutoApproval, index: number) => {
                    return (
                      <SMDataCard
                        key={leaveAutoApproval?.id}
                        title={`Leave Auto Approval ${index + 1}`}
                        showModal={deleteVerification || showModal}
                        loading={loading}
                        dataModal={[
                          {
                            lable: "Auto Approval Type",
                            value: [
                              {
                                content: Capitalize(leaveAutoApproval?.type),
                              },
                            ],
                          },
                          {
                            lable: "Hours",
                            value: [
                              {
                                content: leaveAutoApproval?.hours,
                              },
                            ],
                          },
                        ]}
                        onKebabIcon={{
                          onclick: () => {
                            kebabMenuClickHandler(leaveAutoApproval);
                          },
                          kebabMenu: {
                            onEdit: kebabMenuEditHandler,
                            onDelete: kebabMenuDeleteHandler,
                          },
                        }}
                      />
                    );
                  }
                )}
              </div>
              {getAutoApprovalTimes?.leaveAutoApprovalTime?.length > 0 && (
                <Pagination
                  totalCount={
                    getAutoApprovalTimes?.leaveAutoApprovalTime?.length || 0
                  }
                  noOfItem={getAutoApprovalTimes?.leaveAutoApprovalTime?.length}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
      {showModal ? (
        <EditLeaveApproval
          editAutoApprovalData={editAutoApprovalData}
          setShowModal={setShowModal}
          refetch={refetch}
          createOrUpdateAccess={createOrUpdateAccess}
        />
      ) : null}
      {deleteVerification ? (
        <ConfirmModal
          header={"Leave Auto Approval"}
          onCancel={() => {
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={() => deleteHandler()}
        />
      ) : null}
    </div>
  );
};

export default LeaveApproval;
