import { ComponentPropsWithRef, ElementType, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type TProps<TElementType extends ElementType> = {
  as?: TElementType | "div";
  children: ReactNode;
} & Omit<ComponentPropsWithRef<TElementType>, "children">;

const Card = <TElementType extends ElementType>({
  as,
  children,
  className,
  ...rest
}: TProps<TElementType>) => {
  const Component = as || "div";

  return (
    <Component
      {...rest}
      className={twMerge("border rounded-md p-2 sm:p-4 bg-white", className)}
    >
      {children}
    </Component>
  );
};

export default Card;
