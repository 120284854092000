import { FC, useEffect } from "react";

import DateRangeSelectorField from "components/forms/DatePicker/DateRangeSelectorField";
import { leaveRequestsDatesType } from "modules/Leave/types/leave";

interface IProps {
  register: Function;
  errors: any;
  setRangeFocus: Function;
  workingDates: leaveRequestsDatesType[];
  setDateRange: Function;
  selectedDates: any[];
  clearErrors: any;
  officialHolidays: string[];
  isEditing?: boolean;
  disableSaturdaysSundaysAndHolidays?: boolean;
  isMinDateRequired?: boolean;
  isLeaveTypeRequired?: boolean;
  maxDate?: Date;
  watchEmergencyLeave?: boolean;
  watchLossOfPay?: boolean;
}

const LeaveDates: FC<IProps> = ({
  register,
  errors,
  setRangeFocus,
  workingDates,
  setDateRange,
  selectedDates,
  clearErrors,
  officialHolidays,
  isEditing,
  disableSaturdaysSundaysAndHolidays,
  isMinDateRequired = true,
  isLeaveTypeRequired,
  maxDate,
  watchEmergencyLeave,
  watchLossOfPay,
}) => {
  const currentHour = new Date().getHours();

  const newDateRange: [
    {
      startDate: Date | any;
      endDate: Date | any;
      key: string;
    }
  ] =
    workingDates?.length > 0
      ? [
          {
            startDate: new Date(workingDates[0]?.date),
            endDate: new Date(workingDates[workingDates?.length - 1]?.date),
            key: "selection",
          },
        ]
      : [
          {
            startDate:
              selectedDates?.length > 0 ? new Date(selectedDates[0]) : null,
            endDate:
              selectedDates?.length > 0
                ? new Date(selectedDates[selectedDates?.length - 1])
                : null,
            key: "selection",
          },
        ];

  useEffect(() => {
    if (workingDates?.length > 0) {
      clearErrors("leaveDates");
    }
  }, [clearErrors, workingDates]);

  return (
    <div
      className={`grid grid-cols-1 gap-4 items-center ${
        isLeaveTypeRequired
          ? "sm:grid-cols-[150px_400px]"
          : "sm:grid-cols-[150px_350px]"
      } `}
    >
      <p className="text-sm text-ironside-gray sm:pb-[21px]">Leave Dates</p>
      <DateRangeSelectorField
        register={register}
        errors={errors}
        name={"leaveDates"}
        label={"Leave Dates *"}
        setRangeFocus={setRangeFocus}
        dateRange={newDateRange}
        setDateRange={setDateRange}
        datesArray={workingDates}
        required={workingDates?.length > 0 ? false : true}
        minDate={
          isMinDateRequired
            ? currentHour > 24
              ? new Date(Date.now() + 5 * 60 * 60 * 1000)
              : watchEmergencyLeave || watchLossOfPay
              ? new Date(new Date()?.getFullYear() - 1, 0, 1)
              : new Date()
            : new Date(new Date()?.getFullYear() - 1, 1, 1)
        }
        maxDate={maxDate || new Date(new Date()?.getFullYear() + 1, 11, 31)}
        holidays={officialHolidays}
        isEditing={isEditing}
        disableSaturdaysSundaysAndHolidays={disableSaturdaysSundaysAndHolidays}
      />
    </div>
  );
};

export default LeaveDates;
