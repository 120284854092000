import {
  FC,
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaEdit } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { BiTrash } from "react-icons/bi";

import {
  IDataModal,
  IKebabIcon,
} from "components/App/UpdatedAgryApp/DataCard/types/data-card";
import CardContent from "components/App/UpdatedAgryApp/DataCard/CardContent";
import Card from "components/App/UpdatedAgryApp/Card/Card";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import OopsNoData from "components/UI/UpdateUIAgry/OopsNoData";
import KebabMenu from "components/UI/UpdateUIAgry/KebabMenu";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";

interface IProps {
  cardClass?: string;
  loading?: boolean;
  dataModal?: IDataModal[];
  showModal?: boolean;
  onPencilIcon?: { editAccess: boolean; onPencilIconClick?: () => void };
  onDeleteIcon?: { deleteAccess: boolean; onDeleteIconClick?: () => void };
  onPlusIconClick?: () => void;
  onView?: { editAccess: boolean; onViewClick?: () => void };
  onKebabIcon?: IKebabIcon;
  children?: ReactNode;
  otherOption?: ReactElement;
  cardClick?: () => void;
  classNameForRow?: string;
  classNameForHeadStart?: string;
  disableClickToViewToolTip?: boolean;
  classForULlist?: string;
  headContent?: ReactNode;
  classForSecondChild?: string;
  classForFirstChild?: string;
}

const DataCard: FC<IProps> = ({
  cardClass,
  loading,
  showModal,
  onPencilIcon,
  onDeleteIcon,
  onPlusIconClick,
  onKebabIcon,
  children,
  dataModal,
  onView,
  otherOption,
  cardClick,
  classNameForRow,
  classNameForHeadStart,
  disableClickToViewToolTip,
  classForULlist,
  headContent,
  classForSecondChild = "",
  classForFirstChild = "",
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event?.target as Node)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  useEffect(() => {
    if (showModal) {
      setShowEditOption(false);
    }
  }, [showModal]);

  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
    };
  }, [getPosition]);

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };

  const kebabIconClickHandler = () => {
    setShowEditOption((prevState: any) => !prevState);
    onKebabIcon?.onKebabOnclick && onKebabIcon?.onKebabOnclick();
  };

  return (
    <Card
      className={`${(cardClass && cardClass) || "min-h-max border"} ${
        dataModal &&
        dataModal?.filter((value) => {
          return value.value.some((value) => value.copyText);
        })?.length > 0
          ? "group"
          : ""
      } rounded shadow bg-white`}
    >
      {loading ? (
        <Loading className="min-h-[400px]" />
      ) : (
        <Fragment>
          {dataModal && dataModal?.length > 0 && (
            <div
              className={
                classNameForHeadStart
                  ? classNameForHeadStart
                  : "flex items-center justify-end"
              }
            >
              {headContent ? headContent : null}
              {onDeleteIcon?.deleteAccess && (
                <BiTrash
                  className="w-6 h-6 text-ironside-gray/70 cursor-pointer"
                  onClick={
                    onDeleteIcon?.onDeleteIconClick
                      ? onDeleteIcon?.onDeleteIconClick
                      : undefined
                  }
                />
              )}

              {onPencilIcon?.editAccess && (
                <FaEdit
                  className="w-5 h-5 cursor-pointer text-ironside-gray/70 mr-[15px] ml-[15px] md:ml-[30px] hover:text-irish-green"
                  onClick={
                    onPencilIcon?.onPencilIconClick
                      ? onPencilIcon.onPencilIconClick
                      : undefined
                  }
                />
              )}

              {onPlusIconClick && (
                <HiPlus
                  className="w-6 h-6 text-ironside-gray/70 cursor-pointer ml-[15px] md:ml-[30px]"
                  onClick={() => onPlusIconClick && onPlusIconClick()}
                />
              )}

              {onKebabIcon?.isShow && (
                <div ref={editRef} className="relative w-min pb-3">
                  <div className="w-min" ref={kebabIconRef}>
                    <KebabIcons
                      onClick={kebabIconClickHandler}
                      className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                        showEditOption ? "bg-white-smoke" : null
                      }`}
                    />
                  </div>

                  {showEditOption && (
                    <div className="fixed z-50" style={showEditOptionStyle}>
                      {onKebabIcon && (
                        <KebabMenu
                          onEdit={onKebabIcon.onEdit || undefined}
                          onDelete={onKebabIcon.onDelete || undefined}
                          otherOptions={onKebabIcon?.otherOptions || undefined}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {onView && (
                <span
                  className={`whitespace-nowrap text-sm px-3 ${
                    onView.editAccess
                      ? "text-irish-green hover:underline cursor-pointer"
                      : "text-gray-500"
                  } pb-5`}
                  onClick={
                    onView.editAccess
                      ? onView?.onViewClick && onView?.onViewClick
                      : undefined
                  }
                >
                  View
                </span>
              )}

              {otherOption && otherOption}
            </div>
          )}

          {children}

          {dataModal ? (
            dataModal?.length === 0 ? (
              <OopsNoData />
            ) : (
              <CardContent
                dataModel={dataModal}
                cardClick={cardClick}
                classNameForRow={classNameForRow}
                disableClickToViewToolTip={disableClickToViewToolTip}
                classForULlist={classForULlist}
                classForFirstChild={classForFirstChild}
                classForSecondChild={classForSecondChild}
              />
            )
          ) : null}
        </Fragment>
      )}
    </Card>
  );
};

export default DataCard;
