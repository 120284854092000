import { ComponentProps, FC, ReactNode, useRef } from "react";

import UpdateLoader from "components/Loader/UpdatedLoader";

import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useFocusRing,
} from "react-aria";
import { twMerge } from "tailwind-merge";

interface IProps
  extends Omit<
      ComponentProps<"button">,
      "onBlur" | "onFocus" | "onKeyDown" | "onKeyUp"
    >,
    Omit<AriaButtonProps<"button">, "isDisabled"> {
  variant?: "default" | "primary" | "destructive" | "monochrome";
  modifier?: "plain" | "outline";
  size?: "slim" | "medium" | "large";
  loading?: boolean;
  children: ReactNode;
}

const Button: FC<IProps> = ({
  children,
  size = "medium",
  variant = "default",
  disabled,
  modifier,
  loading,
  className = "",
  autoFocus,
  elementType,
  excludeFromTabOrder,
  href,
  id,
  onBlur,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onPress,
  onPressChange,
  onPressEnd,
  onPressStart,
  onPressUp,
  rel,
  target,
  type,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null!);
  const { buttonProps } = useButton(
    {
      autoFocus,
      elementType,
      excludeFromTabOrder,
      href,
      id,
      isDisabled: disabled || loading,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      onPressUp,
      rel,
      target,
      type,
    },
    ref
  );
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps, props)}
      disabled={disabled || loading}
      className={twMerge(
        `rounded-md outline-none ${
          variant === "default"
            ? "bg-white text-text shadow-button border border-gray-400"
            : ""
        } ${
          variant === "monochrome"
            ? "bg-white text-text border enabled:border-gray-400 "
            : ""
        } ${
          variant === "primary"
            ? disabled
              ? ""
              : "bg-cornflower-blue text-white enabled:hover:bg-royal-blue"
            : ""
        } ${
          variant === "destructive"
            ? disabled
              ? ""
              : "bg-red-500 enabled:hover:bg-valentine-red text-white"
            : ""
        } ${
          modifier === "outline"
            ? "bg-transparent border border-current shadow-[0_0_0_1px_currentColor]"
            : ""
        } ${
          modifier === "outline" && variant === "destructive"
            ? "text-valentine-red"
            : ""
        } ${
          modifier === "outline" && variant === "primary" ? "text-primary" : ""
        } ${
          modifier === "plain"
            ? "shadow-none bg-transparent border-none px-2 py-1 text-sm"
            : ""
        }  ${
          modifier === "plain" && variant === "destructive"
            ? "text-valentine-red"
            : ""
        } ${
          modifier === "plain" && variant === "primary" ? "text-primary" : ""
        } ${size === "slim" ? "text-sm" : ""} ${
          size === "medium" ? "text-sm min-h-[45px]" : ""
        } ${size === "large" ? "text-base" : ""} ${
          modifier !== "plain" && size === "slim" ? "px-3 py-[3px]" : ""
        } ${modifier !== "plain" && size === "medium" ? "px-4 py-2" : ""}  ${
          modifier !== "plain" && size === "large" ? "px-6 py-3" : ""
        } ${
          disabled
            ? variant === "destructive" || variant === "primary"
              ? "bg-surface-disabled  text-gray-400 cursor-not-allowed border border-gray-400"
              : "bg-surface-disabled  text-disabled cursor-not-allowed"
            : ""
        }  ${
          disabled && variant === "default" ? " border-border-disabled" : ""
        } ${className ? className : "min-w-[110px]  lg:min-w-[120px] px-2"} ${
          loading ? "cursor-not-allowed" : ""
        } ${
          isFocusVisible
            ? variant === "monochrome" || variant === "primary"
              ? "ring-2 ring-offset-2 ring-conbg-cornflower-blue"
              : variant === "destructive"
              ? "ring-2 ring-offset-2 ring-valentine-red"
              : "ring-2 ring-offset-2 ring-gray-400"
            : ""
        }`,
        className
      )}
    >
      {!loading && children}
      {loading && (
        <UpdateLoader
          smallLoading
          color={
            variant === "primary" || variant === "destructive"
              ? "white"
              : variant === "default" || variant === "monochrome"
              ? "green"
              : "green"
          }
        />
      )}
    </button>
  );
};

export default Button;
