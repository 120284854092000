import { FC, useState } from "react";
import { Control, UseFormReset, UseFormSetValue } from "react-hook-form";

import Filter from "components/FilterUpdated/Filter";
import { FilterOptions } from "components/FilterUpdated/types";

import { countNonEmptyKeys } from "global/helpers/removeUndefinedValuesFromObject";
import { FetchMoreType, FilterFormType } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  FilterProjectGroupCommonArgs,
  ProjectGroupFilterFormType,
  FilterProjectGroupReturnType,
  FilterProjectGroupArgs,
} from "modules/Project/Pages/Projects/ProjectGroups/types";
import { GET_PROJECT_GROUP_NAMES } from "modules/Project/Pages/Projects/ProjectGroups/services";
import { filterSubmitValues } from "modules/Project/Pages/Projects/ProjectGroups/helpers/filterSubmitValues";

interface Props {
  control: Control<FilterFormType<ProjectGroupFilterFormType>, any>;
  setValue: UseFormSetValue<FilterFormType<ProjectGroupFilterFormType>>;
  reset: UseFormReset<FilterFormType<ProjectGroupFilterFormType>>;
  search: string;
  projectGroupsLength: number;
  canCreate: boolean;
  addBtnHandler: () => void;
  fieldArgs: FilterProjectGroupCommonArgs;
  pageSize: number | undefined;
  fetchMore: FetchMoreType<
    FilterProjectGroupReturnType,
    FilterProjectGroupArgs
  >;
}

const ProjectGroupFilter: FC<Props> = ({
  control,
  setValue,
  search,
  projectGroupsLength,
  canCreate,
  addBtnHandler,
  fieldArgs,
  fetchMore,
  pageSize,
  reset,
}) => {
  const [filterValues, setFilterValues] = useState<
    ProjectGroupFilterFormType | null | undefined
  >(null);

  const hideGlobalSearchAddBtnAndFilterBtn =
    search?.length === 0 &&
    projectGroupsLength === 0 &&
    countNonEmptyKeys(filterValues) === 0;

  const onSearchChange = (page) => {
    fetchMore({
      variables: {
        globalSearch: search || null,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
        return {
          filterProjectGroups,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onClear = () => {
    reset();
    setFilterValues(null);
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
        return {
          filterProjectGroups,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onSubmit: (
    values: FilterFormType<ProjectGroupFilterFormType>
  ) => void = ({ filter }) => {
    if (filter) {
      fetchMore({
        variables: {
          filters: filter
            ? {
                ...filterSubmitValues(filter),
              }
            : undefined,
          pagination: {
            size: pageSize,
          },
          globalSearch: search || undefined,
          ...fieldArgs,
        },
        updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
          return {
            filterProjectGroups,
          };
        },
      }).catch((error) => {
        toastNotify(errorMessageNotify(error));
      });
    }
  };

  const filterOptions: FilterOptions<
    FilterFormType<ProjectGroupFilterFormType>
  > = [
    {
      name: "Name",
      fieldOption: {
        name: "filter.names",
        label: "Name",
        type: "string",
        query: GET_PROJECT_GROUP_NAMES,
        multiple: true,
      },
    },
    {
      name: "Description",
      fieldOption: {
        name: "filter.description",
        type: "textArea",
        label: "Description",
      },
    },
  ];

  return (
    <Filter
      control={control}
      filterValues={filterValues}
      setValue={setValue}
      setFilterValues={setFilterValues}
      options={filterOptions}
      globalSearchName="search"
      hideAddBtn={hideGlobalSearchAddBtnAndFilterBtn || !canCreate}
      hideFilterBtn={hideGlobalSearchAddBtnAndFilterBtn}
      hideGlobalSearch={hideGlobalSearchAddBtnAndFilterBtn}
      addBtnDisabled={!canCreate}
      addBtnClickHandler={addBtnHandler}
      onSearchChange={onSearchChange}
      onSubmit={onSubmit}
      onClear={onClear}
      className="mt-5"
    />
  );
};

export default ProjectGroupFilter;
