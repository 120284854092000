import { FC } from "react";

import {
	AnimateLayoutChanges,
	defaultAnimateLayoutChanges,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ISetStateType } from "global/types/type";

import { colors } from "modules/Project/helper";
import {
	IProjectTask,
	TBoardProjectColumn,
} from "modules/Project/types/project";
import Task from "modules/Project/Pages/Issues/Board/Task";

interface IProps {
	column: TBoardProjectColumn;
	index?: number;
	activeTask?: IProjectTask;
	overlay?: boolean;
	hasAllAccess?: boolean;
	hasDeleteAccess?: boolean;
	columns?: TBoardProjectColumn[];
	setColumns?: ISetStateType<TBoardProjectColumn[]>;
	disabledColumns?: boolean;
	hasAnyAccess?: boolean;
}
export const animateLayoutChanges: AnimateLayoutChanges = (args) =>
	defaultAnimateLayoutChanges({ ...args, wasDragging: true });
const Column: FC<IProps> = ({
	column,
	index,
	overlay,
	hasAllAccess,
	hasDeleteAccess,
	columns,
	setColumns,
	disabledColumns,
	hasAnyAccess,
}) => {
	const { id, name, projectTasks, colour } = column;

	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transition,
		transform,
	} = useSortable({
		id,
		data: {
			type: "container",
			column,
			currentIndex: index,
			id: +id?.split("-")[0],
		},
		animateLayoutChanges,
	});

	return (
		<div
			ref={setNodeRef}
			className={`min-w-[230px] max-w-[300px] flex-1 rounded border shadow-[0px_3px_6px_#A5A5A545] bg-white ${
				overlay ? "min-h-[calc(100vh-30vh)]" : ""
			} ${isDragging ? "opacity-40" : ""} ${
				colour === "Cyan-Blue"
					? "border-blue-200"
					: colour === "Green"
					  ? "border-green-300"
					  : colour === "Tealish-Blue"
						  ? "border-blue-200"
						  : colour === "Yellowish Orange"
							  ? "border-yellow-orange/40"
							  : "border-slate-300/30"
			}`}
			style={{
				transition,
				transform: CSS.Translate.toString(transform),
				backgroundColor: colour ? colors[`${colour}`] : "#f4f5f7",
			}}
		>
			<h6
				{...(disabledColumns ? undefined : listeners)}
				{...(disabledColumns ? undefined : attributes)}
				className={`text-sm capitalize  p-5 text-dark-jungle-green  border-b flex justify-between items-center gap-3 ${
					overlay
						? "cursor-grabbing rounded"
						: disabledColumns
						  ? "rounded-t-[inherit] cursor-default"
						  : "rounded-t-[inherit] cursor-grab"
				}  ${
					colour === "Cyan-Blue"
						? overlay
							? "outline-none ring-2 ring-blue-200 border-blue-200"
							: "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-200 border-blue-200"
						: colour === "Green"
						  ? overlay
								? "outline-none ring-2 ring-green-500 border-green-300"
								: "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-green-500 border-green-300"
						  : colour === "Tealish-Blue"
							  ? overlay
									? "outline-none ring-2 ring-blue-200 border-blue-200"
									: "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-200 border-blue-200"
							  : colour === "Yellowish Orange"
								  ? overlay
										? "outline-none ring-2 ring-yellow-orange/40 border-yellow-orange/40"
										: "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-yellow-orange/40 border-yellow-orange/40"
								  : overlay
									  ? "outline-none ring-2 ring-slate-300/30 border-slate-300/30"
									  : "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-300/30 border-slate-300/30"
				} `}
			>
				<span className="truncate w-full flex-1">{name}</span>
				{projectTasks?.length ? (
					<span className="text-xs text-santa-grey">
						{projectTasks?.length}
					</span>
				) : null}
			</h6>
			<ul
				className={`flex flex-col gap-5 p-3 min-h-[calc(100vh-36.5vh)] max-h-[calc(100vh-36.5vh)] overflow-y-auto rounded ${
					overlay ? "overflow-hidden" : " overflow-y-auto"
				}`}
			>
				<SortableContext
					items={projectTasks}
					strategy={verticalListSortingStrategy}
				>
					{projectTasks?.map(
						(task, index, arr) =>
							task && (
								<Task
									key={task?.id}
									task={task}
									index={index}
									columnId={id}
									hasAllAccess={hasAllAccess}
									hasDeleteAccess={hasDeleteAccess}
									columns={columns}
									setColumns={setColumns}
								/>
							),
					)}
				</SortableContext>
			</ul>
		</div>
	);
};

export default Column;
