import React from "react";
import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";
const SelectWithId: React.FC<{
  register: Function;
  name: string;
  errors: any;
  label: string;
  options: any;
  disable?: boolean;
  defaultSelectValue?: string | number | any;
  required?: boolean;
  onChange?: any;
  enableDefaultSelectOption?: boolean;
  isFieldArray?: boolean;
  className?: string;
}> = ({
  register,
  name,
  errors,
  label,
  options,
  disable,
  defaultSelectValue,
  required,
  onChange,
  enableDefaultSelectOption,
  isFieldArray,
  className,
}) => {
  const isError = isFieldArray ? errors !== undefined : errors[name];

  return (
    <div className={`w-full ${className ? className : ""}`}>
      <div
        className={`sm:block relative shadow flex items-center justify-between transition-all w-full border ${
          isError ? "border-[#FF647C]" : "border-[#E5E5E5]"
        } rounded-[4px] ${
          isError
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        } font-normal"`}
      >
        <select
          id={label}
          {...register(name, {
            required: required ? "This is required field." : false,
            onChange: onChange,
            valueAsNumber: true,
          })}
          defaultValue={defaultSelectValue ? defaultSelectValue : ""}
          disabled={disable ? disable : false}
          className="cursor-pointer text-ironside-gray text-sm peer pl-3 pr-[50px] h-[50px] w-full bg-transparent placeholder-transparent border-none focus:outline-none focus:border-cornflower-blue appearance-none"
        >
          <option value="" disabled={enableDefaultSelectOption ? false : true}>
            Select
          </option>
          {options?.map((option: any, index: number) => {
            return (
              <option key={index} value={+option?.id}>
                {option?.name}
              </option>
            );
          })}
        </select>
        <label
          htmlFor={label}
          className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
            isError ? " text-[#FF647C]" : "text-[#8f8f92]"
          }   transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
            isError
              ? "peer-placeholder-shown:text-[#FF647C]"
              : "peer-placeholder-shown:text-[#8f8f92]"
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            isError
              ? "peer-focus:text-[#FF647C]"
              : "peer-focus:text-cornflower-blue"
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
        <label
          htmlFor={label}
          className="absolute right-[21px] top-[22px] peer-focus-within:rotate-180 pointer-events-none"
        >
          <DownArrow className="w-[13px] h-[8px] text-[#bebebe] cursor-pointer" />
        </label>
      </div>
      <div className="w-full">
        {isError ? (
          <span className="peer-invalid:visible text-[#FF647C]  text-[12px] leading-[15px]">
            {isFieldArray && isError ? errors : errors[name].message}
          </span>
        ) : isError ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};
export default SelectWithId;
