import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";

import Button from "components/forms/Button/Button";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import {
	getAllowedAccess,
	toastNotify,
	userContextData,
} from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import { removeDuplicateObject } from "global/helpers/ArrayMethods";
import { ISetStateType } from "global/types/type";

import { DELETE_PROJECT_WORK_LOG } from "modules/Project/services/mutations";
import { PROJECT_WORK_LOGS } from "modules/Project/services/queries";
import { IProjectWorkLog } from "modules/Project/types/project";
import EditWorkLog from "modules/Project/Pages/Issues/IssueLogs/Activity/WorkLog/EditWorkLog";
import ActivityLoading from "modules/Project/Pages/Issues/IssueLogs/Activity/ActivityLoading";
import WorkLog from "modules/Project/Pages/Issues/IssueLogs/Activity/WorkLog/WorkLog";

interface IProps {
	allowedResourcesForProject: { [key: string]: boolean };
	runningTimerWorkLog: IProjectWorkLog | null;
	isAssignedToTheTask?: boolean;
	setRefetchWorkLog: ISetStateType<boolean>;
	hasAllWorkLogReadAccess: boolean;
}

const WorkLogs: FC<IProps> = ({
	allowedResourcesForProject,
	runningTimerWorkLog,
	isAssignedToTheTask,
	setRefetchWorkLog,
	hasAllWorkLogReadAccess,
}) => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const userDataList: any = useReactiveVar(userContextData);

	const canSaveProjectWorkLog =
		allowedResourcesForProject["SaveProjectWorkLog"];

	const canDeleteProjectWorkLog = allowedResourcesForProject["DeleteWorkLog"];

	const [deleteProjectWorkLog, { loading: deleteProjectWorkLogLoading }] =
		useMutation(DELETE_PROJECT_WORK_LOG);
	const [fetchWorkLogs, { refetch, loading }] = useLazyQuery(
		PROJECT_WORK_LOGS,
		{
			fetchPolicy: "network-only",
		},
	);

	const { issueId, id } = useParams();

	const [showModal, setShowModal] = useState<boolean>(false);
	const [editWorkLog, setEditWorkLog] = useState<IProjectWorkLog | null>(null);
	const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

	const defaultDateRangeStartDate = new Date();
	const defaultDateRangeEndDate = new Date();

	const [dateRange, setDateRange] = useState<any>([
		{
			startDate: defaultDateRangeStartDate,
			endDate: defaultDateRangeEndDate,
			key: "selection",
		},
	]);
	const [isDateChanged, setIsDateChanged] = useState(false);

	const selectedDates = getDateArray(
		dateRange[0]?.startDate,
		dateRange[0]?.endDate,
	);

	const fromDate = ViewDateFormat(dateRange[0]?.startDate?.toString());
	const toDate = ViewDateFormat(dateRange[0]?.endDate?.toString());
	const limit = 10;

	const [showMyLogs, setShowMyLogs] = useState(false);

	const useWorkLog: any = (
		pageNumber: number,
		isDateChanged: boolean,
		showMyLogs: boolean,
	) => {
		const [hasMore, setHasMore] = useState(false);
		const [workLogsList, setWorkLogs] = useState<any>([]);
		const [length, setLength] = useState<number>(0);
		const [totalNumberOfItems, setTotalNumberOfItems] = useState<
			number | undefined
		>();

		useEffect(() => {
			if (isDateChanged) {
				setWorkLogs([]);
				setPageNumber(1);
			}
		}, [isDateChanged]);

		useEffect(() => {
			if (showMyLogs) {
				setWorkLogs([]);
				setPageNumber(1);
			}
		}, [showMyLogs]);

		useEffect(() => {
			setLength(workLogsList?.length);
		}, [workLogsList]);

		useEffect(() => {
			if (issueId && id && !isNaN(+id) && !isNaN(+issueId) && !loading) {
				fetchWorkLogs({
					variables: {
						filters: {
							projectId: +id,
							projectTaskId: +issueId,
							userId: !hasAllWorkLogReadAccess
								? userDataList?.user?.id
								: showMyLogs
								  ? userDataList?.user?.id
								  : undefined,
							startTime: `${fromDate} 12:00 AM`,
							endTime: `${toDate} 11:59 PM`,
						},
						limit,
						page: pageNumber,
						type: canReadUserProfileImage ? "Profile" : undefined,
						isProfileImageNeeded: canReadUserProfileImage,
					},
				})
					.then((res) => {
						const workLogData =
							res?.data?.projectWorkLog?.dataCollection?.map(
								(notification: any) => notification,
							) || [];

						setWorkLogs((prevState: any) => {
							return removeDuplicateObject(
								[...prevState, workLogData].flat(),
								"id",
							);
						});
						setHasMore(length < res?.data?.projectWorkLog?.totalNumberOfItems);
						setTotalNumberOfItems(
							res?.data?.projectWorkLog?.totalNumberOfItems,
						);
					})
					.catch((error) => {
						if (error.name === "AbortError") {
							return;
						}
					})
					.finally(() => {
						setIsDateChanged(false);
					});
			}
		}, [pageNumber, length, isDateChanged, showMyLogs]);

		return {
			workLogsList,
			totalNumberOfItems,
			hasMore,
			fromDate,
			toDate,
		};
	};

	const [pageNumber, setPageNumber] = useState(1);

	const { workLogsList, totalNumberOfItems, hasMore } = useWorkLog(
		pageNumber,
		isDateChanged,
		showMyLogs,
	);

	const [workLogs, setWorkLogs] = useState<IProjectWorkLog[]>(workLogsList);

	useEffect(() => {
		setWorkLogs(workLogsList);
	}, [workLogsList]);

	useEffect(() => {
		if (runningTimerWorkLog) {
			setWorkLogs((prevLogs: IProjectWorkLog[]) => {
				const newLogs = [...prevLogs];
				newLogs.unshift(runningTimerWorkLog);
				return newLogs;
			});
		}
	}, [runningTimerWorkLog]);

	const observer: any = useRef();

	const lastWorkLogElementRef = useCallback(
		(node: any) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					hasMore &&
					pageNumber < Math.ceil(totalNumberOfItems / limit)
				) {
					setPageNumber((prevPageNumber) => prevPageNumber + 1);
				}
			});

			if (node) observer.current.observe(node);
		},
		[loading, totalNumberOfItems, pageNumber, hasMore],
	);

	const addButtonHandler = () => {
		setShowModal(true);
		setEditWorkLog(null);
	};

	const confirmModalCloseHandler = () => {
		setEditWorkLog(null);
		setDeleteVerification(false);
	};

	const deleteHandler = () => {
		if (editWorkLog?.id && !deleteProjectWorkLogLoading) {
			deleteProjectWorkLog({
				variables: {
					id: editWorkLog?.id,
				},
			})
				.then(() => {
					setRefetchWorkLog(true);
					setWorkLogs((prevLogs: IProjectWorkLog[]) => {
						const newWorkLogs = [...prevLogs];
						newWorkLogs?.splice(editWorkLog?.index, 1);
						return newWorkLogs;
					});
					refetch();
					toastNotify([
						{
							messageType: "success",
							message: "Work Log deleted successfully",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]);
				})
				.finally(() => {
					setDeleteVerification(false);
				});
		}
	};

	return (
		<div>
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
				{canSaveProjectWorkLog && isAssignedToTheTask && (
					<div className="flex items-center gap-3 mb-4">
						<Button
							size="slim"
							className="h-[42px] bg-titan-white border-none text-cornflower-blue"
							onClick={addButtonHandler}
						>
							Add Time
						</Button>
						{hasAllWorkLogReadAccess && (
							<Button
								size="slim"
								className={`h-[42px] ${
									showMyLogs ? "bg-[#505F79] text-white" : "bg-[#F4F5F7]"
								}`}
								onClick={() => setShowMyLogs((prev: boolean) => !prev)}
							>
								Only my logs
							</Button>
						)}
					</div>
				)}
				{isAssignedToTheTask && (
					<div className="-mb-2 pt-1 mr-5 max-w-[240px] cursor-pointer">
						<RangeSelector
							setRangeFocus={() => null}
							workingDates={selectedDates}
							setDateRange={setDateRange}
							selectedDates={selectedDates}
							label={"From and To Date"}
							minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
							maxDate={new Date()}
							setIsDateChanged={setIsDateChanged}
						/>
					</div>
				)}
			</div>

			<div
				className={`${
					workLogs?.length === 0
						? "overflow-x-hidden"
						: "max-h-[700px] overflow-y-auto"
				} `}
			>
				{workLogs?.length > 0 &&
					!loading &&
					workLogs?.map((workLog: IProjectWorkLog, index: number) =>
						workLogs?.length === index + 1 ? (
							<div key={workLog?.id} ref={lastWorkLogElementRef}>
								<WorkLog
									workLog={workLog}
									setShowModal={setShowModal}
									setEditWorkLog={setEditWorkLog}
									setDeleteVerification={setDeleteVerification}
									canSaveProjectWorkLog={canSaveProjectWorkLog}
									canDeleteProjectWorkLog={canDeleteProjectWorkLog}
									index={index}
								/>
							</div>
						) : (
							<div key={workLog?.id}>
								<WorkLog
									workLog={workLog}
									setShowModal={setShowModal}
									setEditWorkLog={setEditWorkLog}
									setDeleteVerification={setDeleteVerification}
									canSaveProjectWorkLog={canSaveProjectWorkLog}
									canDeleteProjectWorkLog={canDeleteProjectWorkLog}
									index={index}
								/>
							</div>
						),
					)}
			</div>
			{loading && <ActivityLoading />}
			{workLogs?.length <= 0 && !loading && (
				<div className="w-full h-[260px] flex justify-center items-center border">
					<p className="text-sm text-warm-gray">No work logs found.</p>
				</div>
			)}
			{showModal && (
				<EditWorkLog
					setShowModal={setShowModal}
					editWorkLog={editWorkLog}
					setEditWorkLog={setEditWorkLog}
					refetchWorkLogs={refetch}
					canSaveProjectWorkLog={canSaveProjectWorkLog}
					setWorkLogs={setWorkLogs}
					setRefetchWorkLog={setRefetchWorkLog}
				/>
			)}
			{deleteVerification && (
				<ConfirmModal
					header="Work log"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectWorkLogLoading}
				/>
			)}
		</div>
	);
};

export default WorkLogs;
