import { FC } from "react";
import { Control } from "react-hook-form";

import DataCard from "components/App/UpdatedAgryApp/DataCard/DataCard";
import PaginationUpdated from "components/Pagination/UpdatedPaginationAgry/PaginationUpdated";
import { IDataModal } from "components/App/UpdatedAgryApp/DataCard/types/data-card";

import { FilterFormType } from "global/types/type";

import {
	ProjectGroupBug,
	ProjectGroupBugEdge,
	ProjectGroupBugFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";

interface Props {
	projectGroupBugEdges: ProjectGroupBugEdge[];
	canUpdate: boolean;
	canDelete: boolean;
	onEdit: (
		type: "create" | "update" | "delete",
		projectGroupBug?: ProjectGroupBug | null,
	) => void;
	cardOrTableClickHandler: (bugId: number) => void;
	onPageSizeChange: (pageSize: number) => void;
	onNextPageHandler: () => void;
	onPrevPageHandler: () => void;
	nextDisabled: boolean;
	prevDisabled: boolean;
	control: Control<FilterFormType<ProjectGroupBugFilterFormType>, any>;
	totalCount: number;
}

const ProjectGroupBugCard: FC<Props> = ({
	projectGroupBugEdges,
	canDelete,
	canUpdate,
	onEdit,
	cardOrTableClickHandler,
	onPageSizeChange,
	onNextPageHandler,
	onPrevPageHandler,
	nextDisabled,
	prevDisabled,
	control,
	totalCount,
}) => {
	const deleteClickHandler: (edge: ProjectGroupBugEdge) => {
		deleteAccess: boolean;
		onDeleteIconClick?: (() => void) | undefined;
	} = (edge) => {
		return {
			deleteAccess: canDelete,
			onDeleteIconClick: () => {
				canDelete && onEdit("delete", edge?.node || undefined);
			},
		};
	};

	const getDataModal = (projectGroupBug: ProjectGroupBug): IDataModal[] => {
		const { title, platform, priority, source, status, description } =
			projectGroupBug;

		const projectNames =
			projectGroupBug?.projectBacklogs?.dataCollection &&
			projectGroupBug?.projectBacklogs?.dataCollection?.length > 0
				? projectGroupBug?.projectBacklogs?.dataCollection
						?.map((project) => project?.project?.name)
						?.filter((project) => project)
						?.join(", ")
				: "";

		return [
			{
				label: "Title",
				value: [
					{
						content: title,
					},
				],
			},
			{
				label: "Projects",
				value: [
					{
						content: projectNames,
					},
				],
			},
			{
				label: "Platform",
				value: [
					{
						content:
							platform && platform?.length > 0
								? platform?.map((platform) => platform)?.join(", ")
								: "-",
					},
				],
			},
			{
				label: "Priority",
				value: [
					{
						content: priority,
					},
				],
			},
			{
				label: "Source",
				value: [
					{
						content:
							source && source?.length > 0
								? source?.map((source) => source?.name)?.join(", ")
								: "-",
					},
				],
			},
			{
				label: "Status",
				value: [
					{
						content: status,
					},
				],
			},
			{
				label: "Description",
				value: [
					{
						content: description,
					},
				],
			},
		];
	};

	return (
		<div className="md:hidden mx-auto mt-6">
			<div className="min-h-[80vh] space-y-3">
				{projectGroupBugEdges?.map((edge, index) => {
					if (edge && edge?.node) {
						return (
							<DataCard
								key={index}
								cardClass="group border"
								dataModal={getDataModal(edge?.node)}
								classNameForRow={`grid grid-cols-[110px_auto] items-center gap-2 ${
									canUpdate ? "cursor-pointer" : "cursor-not-allowed"
								}`}
								cardClick={() => {
									edge?.node?.id && cardOrTableClickHandler(edge?.node?.id);
								}}
								onDeleteIcon={deleteClickHandler(edge)}
							/>
						);
					} else {
						return null;
					}
				})}
			</div>
			<PaginationUpdated
				onPageSizeChange={onPageSizeChange}
				nextDisabled={nextDisabled}
				onNextPageHandler={onNextPageHandler}
				onPrevPageHandler={onPrevPageHandler}
				prevDisabled={prevDisabled}
				control={control}
				pageSizeName={"pageSize"}
				totalCount={totalCount}
			/>
		</div>
	);
};

export default ProjectGroupBugCard;
