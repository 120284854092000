import { TypedDocumentNode, gql } from "@apollo/client";

import { PAGINATION_INFO } from "global/services";

import { COMPANY_FRAGMENT } from "modules/Company/services/fragments";
import {
  FilterCompaniesArgs,
  FilterCompaniesReturnType,
} from "modules/Company/types";

export const FILTER_COMPANIES: TypedDocumentNode<
  FilterCompaniesReturnType,
  FilterCompaniesArgs
> = gql`
  ${COMPANY_FRAGMENT}
  ${PAGINATION_INFO}
  query FilterCompanies(
    $filters: CompanyFilter
    $globalSearch: String
    $pagination: Pagination
    $isAddressNeed: Boolean = false
    $isAltIsdCode: Boolean = false
    $isAltMobileNeed: Boolean = false
    $isCINNeed: Boolean = false
    $isClosingTimeNeed: Boolean = false
    $isCompanyLogoNeed: Boolean = false
    $isCompanyManagersNeed: Boolean = false
    $isCompanyOwnersNeed: Boolean = false
    $isCompanyTypeNeed: Boolean = false
    $isCreatedAtNeed: Boolean = false
    $isEmailNeed: Boolean = false
    $isGstinNeed: Boolean = false
    $isCinVerifiedNeed: Boolean = false
    $isEmailVerifiedNeed: Boolean = false
    $isGstinVerifiedNeed: Boolean = false
    $isPanVerifiedNeed: Boolean = false
    $isIsdCodeNeed: Boolean = false
    $isJoinedAtNeed: Boolean = false
    $isLegalNameNeed: Boolean = false
    $isMobileNeed: Boolean = false
    $isOpeningTimeNeed: Boolean = false
    $isPanNeed: Boolean = false
    $isStatusNeed: Boolean = false
    $isUpdatedAtNeed: Boolean = false
  ) {
    filterCompanies(
      filters: $filters
      globalSearch: $globalSearch
      pagination: $pagination
    ) {
      edges {
        node {
          ...companyFields
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_NAMES = gql`
  ${PAGINATION_INFO}
  query getCompanyNames($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { name: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: name
          name
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_ADDRESSES = gql`
  ${PAGINATION_INFO}
  query getCompanyAddresses($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { address: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: address
          name: address
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_MOBILES = gql`
  ${PAGINATION_INFO}
  query getCompanyMobiles($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { mobile: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: mobile
          name: mobile
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_ALT_MOBILES = gql`
  ${PAGINATION_INFO}
  query getCompanyAltMobiles($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { altMobile: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: altMobile
          name: altMobile
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_EMAILS = gql`
  ${PAGINATION_INFO}
  query getCompanyEmails($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { email: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: email
          name: email
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_OWNERS = gql`
  ${PAGINATION_INFO}
  query getCompanyOwners($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { companyOwnerName: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          companyOwners {
            personalInformation {
              id: name
              name
            }
          }
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_MANAGERS = gql`
  ${PAGINATION_INFO}
  query getCompanyManagers($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { companyManagerName: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          companyManagers {
            personalInformation {
              id: name
              name
            }
          }
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_CIN = gql`
  ${PAGINATION_INFO}
  query getCompanyCin($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { cin: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: cin
          cin
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_GSTIN = gql`
  ${PAGINATION_INFO}
  query getCompanyGstin($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { gstin: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: gstin
          gstin
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_COMPANY_PAN = gql`
  ${PAGINATION_INFO}
  query getCompanyPan($search: String, $pagination: Pagination) {
    getDropDownField: filterCompanies(
      filters: { pan: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: pan
          pan
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;
