import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLazyQuery, useQuery } from "@apollo/client";

import { SearchField, Select } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import { DateFormat } from "global/helpers/DateFormatter";
import { paginationDefaultValue } from "global/helpers/StaticData";

import { GET_LEAVE_TYPES } from "modules/Employee/services/queries";
import { FILTER_ALL_LEAVE_REQUESTS } from "modules/Leave/services/queries";
import AllRequestsDetails from "modules/Leave/Pages/Requests/AllRequests/AllRequestsDetails";
import ViewPage from "modules/Leave/Pages/Requests/AllRequests/ViewPage";

const AllRequests = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const { data: leaveTypes } = useQuery(GET_LEAVE_TYPES);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const leaveOptions = leaveTypes?.leaveTypes?.dataCollection?.map(
    (leaveType: { id: number; name: string }) => {
      return {
        id: leaveType.id,
        name: leaveType.name,
      };
    }
  );

  const employee = watch("employee");
  const [search, setSearch] = useState(employee || "");

  const viewPageDetails: any = "";

  const defaultDateRangeStartDate = new Date();
  const defaultDateRangeEndDate = new Date(
    +`${new Date()?.getMonth() + 5}` > 12
      ? `${new Date()?.getFullYear() + 1}-${`${
          new Date()?.getMonth() + 5 - 12
        }`?.padStart(2, "0")}-01`
      : `${new Date()?.getFullYear()}-${new Date()?.getMonth() + 5}-01`
  );

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: defaultDateRangeStartDate,
      endDate: defaultDateRangeEndDate,
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const leaveType = watch("leaveType");
  const status = watch("status");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());
  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(employee?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [employee]);

  const [fetchAllLeaveRequests, { data: filterAllLeaveRequests, loading }] =
    useLazyQuery(FILTER_ALL_LEAVE_REQUESTS);

  useEffect(() => {
    fetchAllLeaveRequests({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        status: status ? status : undefined,
        from: fromDate ? fromDate : undefined,
        to: toDate ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [
    pageSize,
    currentPage,
    status,
    fromDate,
    toDate,
    leaveType,
    search,
    fetchAllLeaveRequests,
  ]);

  const [filteredRequest, setFilteredRequest] = useState([]);
  useEffect(() => {
    setFilteredRequest(
      filterAllLeaveRequests?.filterAllLeaveRequests?.dataCollection?.map(
        (request: any) => {
          return {
            createdAt: request.createdAt,
            reason: request?.reason,
            declineMessage: request?.declineMessage,
            status: request?.status,
            id: request.id,
            requestedByUser: request?.requestedByUser,
            requestedToUser: request?.requestedToUser,
            leaveRequestAttachments: request?.leaveRequestAttachments,
            isConsentRequest: request?.isConsentRequest,
            leaveRequestDates: request.leaveRequestDates?.map((data: any) => {
              return {
                id: data?.id,
                leaveDate: data?.leaveDate,
                session: data?.session,
                declineMessage: data?.declineMessage,
                status: data?.status,
                leaveType: {
                  id: data?.leaveType?.id,
                  name: data?.leaveType?.name,
                },
                startTime: data?.startTime,
                endTime: data?.endTime,
                leaveRequestDateConsents: data?.leaveRequestDateConsents,
              };
            }),
          };
        }
      )
    );
  }, [filterAllLeaveRequests]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] lg:grid-cols-[repeat(3,250px)] sm:gap-x-3 sm:gap-y-0 2xl:grid-cols-[repeat(5,216px)] justify-center items-center 2xl:justify-start">
                  <div className="pb-5">
                    <SearchField
                      register={register}
                      name="employee"
                      label="Employee"
                      onChange={() => {
                        setCurrentPage(1);
                      }}
                      setValue={setValue}
                    />
                  </div>
                  <SelectWithId
                    register={register}
                    name={"leaveType"}
                    errors={errors}
                    label={"Leave Type"}
                    options={leaveOptions}
                    enableDefaultSelectOption
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={[
                      "Approved",
                      "Declined",
                      "Partially Approved",
                      "Pending",
                      "Agreed",
                      "Disagreed",
                    ]}
                    label="Status"
                    name="status"
                    required={true}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                </div>
              </div>
              <AllRequestsDetails
                requestData={filteredRequest && filteredRequest}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={
                  filterAllLeaveRequests?.filterAllLeaveRequests
                    ?.totalNumberOfItems
                }
                loading={loading}
                filterAllLeaveRequests={filterAllLeaveRequests}
              />
            </div>
          }
        />
        <Route path={`:${viewPageDetails?.id}`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default AllRequests;
