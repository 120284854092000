import { FC } from "react";

import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";

const Select: FC<{
  register: Function;
  name: string;
  errors: any;
  label: string;
  options?: string[];
  disable?: boolean;
  defaultSelectValue?: string | number;
  required?: boolean;
  onChange?: Function;
  isFieldArray?: boolean;
  disableDefaultSelectOption?: boolean;
  optionsWithId?: { id: number; name: string }[] | [];
  className?: string;
}> = ({
  register,
  name,
  errors,
  label,
  options,
  disable,
  defaultSelectValue,
  required,
  onChange,
  isFieldArray,
  disableDefaultSelectOption,
  optionsWithId,
  className,
}) => {
  const isError = isFieldArray ? errors !== undefined : errors[name];

  return (
    <div className={`w-full ${className ? className : ""}`}>
      <div
        className={`sm:block shadow relative flex items-center justify-between transition-all w-full border ${
          isError ? "border-[#FF647C]" : "border-[#E5E5E5]"
        }  rounded-[4px] ${
          isError
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        } font-normal"`}
      >
        <select
          id={name}
          defaultValue={defaultSelectValue ? defaultSelectValue : ""}
          {...register(name, {
            required: required ? "This is required field." : false,
            onChange: onChange,
            valueAsNumber: optionsWithId ? true : false,
          })}
          disabled={disable ? disable : false}
          className="cursor-pointer text-ironside-gray peer text-sm pl-3 pr-[50px] h-[50px] w-full bg-transparent placeholder-transparent border-none focus:outline-none focus:border-cornflower-blue appearance-none"
        >
          <option value="" disabled={disableDefaultSelectOption}>
            Select
          </option>
          {options &&
            options?.map((option: string, index: number) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
          {optionsWithId &&
            optionsWithId?.length > 0 &&
            optionsWithId?.map((option: { id: number; name: string }) => (
              <option value={option?.id} key={option?.id}>
                {option?.name}
              </option>
            ))}
        </select>
        <label
          htmlFor={name}
          className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
            isError ? "text-[#FF647C]" : "text-[#8f8f92]"
          } transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
            isError
              ? "peer-placeholder-shown:text-[#FF647C]"
              : "peer-placeholder-shown:text-[#8f8f92]"
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            isError
              ? "peer-focus:text-[#FF647C]"
              : "peer-focus:text-cornflower-blue"
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
        <label
          htmlFor={name}
          className="absolute right-[21px] top-[22px] peer-focus-within:rotate-180 pointer-events-none"
        >
          <DownArrow className="w-[13px] h-[8px] text-[#bebebe] cursor-pointer" />
        </label>
      </div>
      <div className="w-full">
        {isError ? (
          <span className="peer-invalid:visible text-[#FF647C]  text-[12px] leading-[15px]">
            {isFieldArray && isError ? errors : errors[name].message}
          </span>
        ) : isError ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};
export default Select;
