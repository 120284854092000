import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import {
  CalendarField,
  Filled,
  Modal,
  Outlined,
  Text,
  TextArea,
} from "components/forms";

import { MdOutlineClose } from "react-icons/md";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  errorMessageNotify,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import { workExperienceFieldValidation } from "modules/Employee/Pages/Profile/Work/WorkExperience/WorkExperienceFieldValidation";
import { GET_WORK_EXPERIENCE } from "modules/Employee/services/queries";
import {
  CREATE_EMPLOYEE_WORK_EXPERIENCE,
  UPDATE_EMPLOYEE_WORK_EXPERIENCE,
} from "modules/Employee/services/mutations";
import { IWorkExperience } from "modules/Employee/types/work";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  workExperienceDetails: IWorkExperience[];
  editID: number | null;
  setEditID: Function;
  inProgress: boolean;
  setInProgress: Function;
  setShowModal: Function;
  setAlert: Function;
  expStartDate: string | Date | null;
  setExpStartDate: Function;
  expEndDate: string | Date | null;
  setExpEndDate: Function;
  checkbox: boolean;
  setCheckbox: Function;
  refetchWorkExperience: Function;
}

const EditWorkExperience: FC<IProps> = ({
  workExperienceDetails,
  editID,
  setEditID,
  inProgress,
  setInProgress,
  setShowModal,
  setAlert,
  expStartDate,
  setExpStartDate,
  expEndDate,
  setExpEndDate,
  checkbox,
  setCheckbox,
  refetchWorkExperience,
}) => {
  const workExperience = workExperienceDetails?.filter(
    (workExperience: IWorkExperience) => +workExperience?.id === editID
  )[0];

  const isUnderModeration = useIsUnderModeration();

  const [createUserWorkExperience] = useMutation(
    CREATE_EMPLOYEE_WORK_EXPERIENCE
  );

  const [updateUserWorkExperience] = useMutation(
    UPDATE_EMPLOYEE_WORK_EXPERIENCE
  );

  const moderationObj = workExperience?.moderation || null;

  const moderationFieldProps = {
    refetchQuery: refetchWorkExperience,
  };

  const {
    handleSubmit,
    register,
    reset,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IWorkExperience>();

  useEffect(() => {
    if (workExperience?.startDate) {
      setExpStartDate(
        new Date(workExperience?.startDate?.replaceAll("-", " "))
      );
    }

    if (workExperience?.endDate) {
      setExpEndDate(new Date(workExperience?.endDate));
    }
  }, [setExpEndDate, setExpStartDate, workExperience]);

  useEffect(() => {
    if (expStartDate) {
      setValue("expStartDate", expStartDate?.toString());
    }

    if (expEndDate) {
      setValue("expEndDate", expEndDate?.toString());
    }
  }, [expStartDate, expEndDate, setValue]);

  useEffect(() => {
    if (expStartDate) {
      clearErrors("expStartDate");
    }

    if (expEndDate) {
      clearErrors("expEndDate");
    }
  }, [clearErrors, expStartDate, expEndDate]);

  useEffect(() => {
    if (workExperience?.companyName) {
      setValue("companyName", workExperience?.companyName);
    }

    if (workExperience?.designation) {
      setValue("designation", workExperience?.designation);
    }

    if (workExperience?.employmentType) {
      setValue("employmentType", workExperience?.employmentType);
    }

    if (workExperience?.location) {
      setValue("location", workExperience?.location);
    }

    if (workExperience?.industry) {
      setValue("industry", workExperience?.industry);
    }

    if (workExperience?.additionalDetails) {
      setValue("additionalDetails", workExperience?.additionalDetails);
    }
  }, [setValue, workExperience]);

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Work experience")
    : createSuccessMessageWithAwaiting("Work experience");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Work experience")
    : updateSuccessMessageWithAwaiting("Work experience");

  const createHandler = (data: IWorkExperience) => {
    const validationStatus =
      ((checkbox || expStartDate) &&
        workExperienceFieldValidation(
          data,
          expStartDate,
          expEndDate,
          checkbox,
          setError
        )) ||
      true;

    if (validationStatus) {
      if (!inProgress) {
        createUserWorkExperience({
          variables: {
            userId: sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
            companyName: data.companyName?.trim(),
            designation: data.designation?.trim(),
            employmentType: data.employmentType || null,
            industry: data.industry || null,
            startDate: (expStartDate && ViewDateFormat(expStartDate)) || null,
            additionalDetails: data.additionalDetails
              ? data.additionalDetails
              : undefined,
            location: data.location || null,
            isCurrent: (expStartDate && checkbox) || null,
            endDate: checkbox ? undefined : ViewDateFormat(expEndDate),
          },
          update: (cache, { data: { createUserWorkExperience } }) => {
            const exWorkExperience: any = cache.readQuery({
              query: GET_WORK_EXPERIENCE,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            const updatedWorkExperience = [
              ...exWorkExperience?.users?.dataCollection[0]
                ?.userWorkExperiences,
              createUserWorkExperience,
            ];

            cache.writeQuery({
              query: GET_WORK_EXPERIENCE,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exWorkExperience?.users?.dataCollection[0],
                      userWorkExperiences: updatedWorkExperience,
                    },
                  ],
                },
              },
            });
          },
        })
          .then(() => {
            moduleCloseHandler();
            setAlert(createActionSuccesMessage);
          })
          .catch((error: { message: string }) => {
            setInProgress(false);
            setAlert(errorMessageNotify(error));
          });
        setInProgress(!inProgress);
      }
    }
  };

  const updateHandler = (data: IWorkExperience) => {
    const validationStatus =
      ((checkbox || expStartDate) &&
        workExperienceFieldValidation(
          data,
          expStartDate,
          expEndDate,
          checkbox,
          setError
        )) ||
      true;

    if (validationStatus) {
      if (!inProgress) {
        updateUserWorkExperience({
          variables: {
            id: workExperience?.id,
            designation: data.designation,
            companyName: data.companyName,
            employmentType: data.employmentType || null,
            industry: data.industry || null,
            startDate: (expStartDate && ViewDateFormat(expStartDate)) || null,
            additionalDetails: data.additionalDetails || null,
            location: data.location || null,
            isCurrent: (expStartDate && checkbox) || null,
            endDate: checkbox ? null : ViewDateFormat(expEndDate),
          },
          update: (cache, { data: { updateUserWorkExperience } }) => {
            const exWorkExperience: any = cache.readQuery({
              query: GET_WORK_EXPERIENCE,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            const updatedWorkExperience =
              exWorkExperience?.users?.dataCollection[0]?.userWorkExperiences?.map(
                (experience: IWorkExperience) => {
                  if (+experience?.id === +workExperience?.id) {
                    return {
                      ...experience,
                      designation: updateUserWorkExperience?.designation,
                      companyName: updateUserWorkExperience?.companyName,
                      employmentType: updateUserWorkExperience?.employmentType,
                      industry: updateUserWorkExperience?.industry,
                      startDate: updateUserWorkExperience?.startDate,
                      additionalDetails:
                        updateUserWorkExperience?.additionalDetails,
                      location: updateUserWorkExperience?.location,
                      isCurrent: updateUserWorkExperience?.isCurrent,
                      endDate: updateUserWorkExperience?.endDate,
                      moderation: updateUserWorkExperience?.moderation,
                    };
                  }
                  return experience;
                }
              );

            cache.writeQuery({
              query: GET_WORK_EXPERIENCE,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exWorkExperience?.users?.dataCollection[0],
                      userWorkExperiences: updatedWorkExperience,
                    },
                  ],
                },
              },
            });
          },
        })
          .then(() => {
            moduleCloseHandler();
            setAlert(updateActionSuccessMessage);
          })
          .catch((error: { message: string }) => {
            setInProgress(false);
            setAlert(errorMessageNotify(error));
          });
        setInProgress(!inProgress);
      }
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setEditID(null);
    setExpStartDate(null);
    setExpEndDate(null);
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] pr-[28px] lg:pl-[45px] lg:pr-[57px] boder-hit-gray">
          <div className="edit-modal-heading">Work Experience</div>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form
          onSubmit={
            workExperience?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
        >
          <div className="pr-[35px] form-div lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.companyName}
            >
              <Text
                register={register}
                errors={errors}
                label="Company Name *"
                name="companyName"
                defaultText={workExperience?.companyName}
                required={true}
                maximumLength={50}
                disable={!!moderationObj?.companyName}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.designation}
            >
              <Text
                register={register}
                errors={errors}
                label="Designation *"
                name="designation"
                defaultText={workExperience?.designation}
                required={true}
                maximumLength={35}
                disable={!!moderationObj?.designation}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.employmentType}
            >
              <Text
                register={register}
                errors={errors}
                label="Employment Type"
                name="employmentType"
                defaultText={workExperience?.employmentType}
                maximumLength={35}
                disable={!!moderationObj?.employmentType}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.location}
            >
              <Text
                register={register}
                errors={errors}
                label="Location"
                name="location"
                defaultText={workExperience?.location}
                disable={!!moderationObj?.location}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.industry}
            >
              <Text
                register={register}
                errors={errors}
                label="Industry"
                name="industry"
                defaultText={workExperience?.industry}
                disable={!!moderationObj?.industry}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.additionalDetails}
            >
              <TextArea
                register={register}
                errors={errors}
                label="Additional Details"
                name="additionalDetails"
                defaultText={workExperience?.additionalDetails}
                disabled={!!moderationObj?.additionalDetails}
              />
            </ModerationField>
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.startDate}
            >
              <CalendarField
                register={register}
                errors={errors}
                name="expStartDate"
                date={expStartDate}
                setDate={setExpStartDate}
                className="h-[50px]"
                label="Start Date"
                minDate={new Date(new Date()?.getFullYear() - 20, 0, 1)}
                maxDate={new Date(new Date()?.getFullYear(), 11, 31)}
                disabled={!!moderationObj?.startDate}
              />
            </ModerationField>

            {!checkbox && (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.endDate}
              >
                <CalendarField
                  register={register}
                  errors={errors}
                  name="expEndDate"
                  date={expEndDate}
                  setDate={setExpEndDate}
                  className="h-[50px]"
                  label="End Date *"
                  required={expStartDate && !checkbox ? true : false}
                  minDate={new Date(new Date()?.getFullYear() - 20, 0, 1)}
                  maxDate={new Date(new Date()?.getFullYear(), 11, 31)}
                  disabled={!!moderationObj?.endDate}
                />
              </ModerationField>
            )}

            <div className="flex items-center pb-[24px] pl-1">
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.isCurrent}
              >
                <span
                  className={
                    (moderationObj?.isCurrent &&
                      "flex whitespace-nowrap mr-5 lg:mr-14 mt-5") ||
                    ""
                  }
                >
                  <input
                    type="checkbox"
                    className="w-4 h-4 cursor-pointer"
                    id="is-current"
                    onChange={() => {
                      setCheckbox(!checkbox);
                    }}
                    checked={checkbox}
                    disabled={!!moderationObj?.isCurrent}
                  />
                  <label htmlFor="is-current" className="ml-[10px] text-sm">
                    I am currently working in this role
                  </label>
                </span>
              </ModerationField>
            </div>
          </div>
          <div className="w-full border-b border-hit-gray pt-[6px]" />
          <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
            <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : workExperience?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditWorkExperience;
