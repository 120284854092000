import { Route, Routes } from "react-router-dom";
import ChangePassword from "layouts/header/ProfilePic/ChangePassword";
import Account from "modules/User/Pages/Account";
import Profile from "modules/Employee/Pages/Profile/Profile";
import PrivateLayout from "layouts/PrivateLayout";
import Dashboard from "modules/Dashboard/Pages";
import ProjectsRouterControl from "modules/Project/Pages/ProjectsRouterControl";
import Payroll from "modules/Payroll/Pages";
import Team from "modules/Teams";
import Support from "modules/Support/Pages";
import Organization from "modules/Organization/Pages";
import Settings from "modules/Settings/Pages";
import Leave from "modules/Leave/Pages";
import "./App.css";
import ViewAllNotificationRouteControl from "layouts/header/TopLinks/Notification/Pages/ViewAllNotificationRouteControl";
import TimerRouteControl from "modules/Timer/TimerRouteControl";
import CompaniesRouteControl from "modules/Company/CompaniesRouteControl";

function App() {
	if ("serviceWorker" in navigator) {
		const firebaseConfig = encodeURIComponent(
			JSON.stringify({
				apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
				authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
				databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
				projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
				storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
				messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID}`,
				appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
				measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
			}),
		);

		navigator.serviceWorker
			.register(`/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`)
			.catch((err) => {
				console.error(err);
			});
	}

	return (
		<div className="3xl:w-[1900px] mx-auto">
			<Routes>
				<Route path="/*" element={<Account />} />
				<Route element={<PrivateLayout />}>
					<Route path="/profile/*" element={<Profile />} />
					<Route path="/dashboard/*" element={<Dashboard />} />
					<Route path="/timer/*" element={<TimerRouteControl />} />
					<Route path="/projects/*" element={<ProjectsRouterControl />} />
					<Route path="/leaves/*" element={<Leave />} />
					<Route path="/payroll/*" element={<Payroll />} />
					<Route path="/team/*" element={<Team />} />
					<Route path="/support/*" element={<Support />} />
					<Route path="/organization/*" element={<Organization />} />
					<Route path="/settings/*" element={<Settings />} />
					<Route path="/change-password/*" element={<ChangePassword />} />
				</Route>
				<Route
					path="/notification/*"
					element={<ViewAllNotificationRouteControl />}
				/>
				<Route path="/companies/*" element={<CompaniesRouteControl />} />
			</Routes>
		</div>
	);
}

export default App;
