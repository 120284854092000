import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loader/Loading";

import NotificationDetails from "layouts/header/TopLinks/Notification/Pages/NotificationDetails";
import { NotificationsType } from "layouts/header/TopLinks/TopLinks";

import { groupBy } from "global/helpers/ArrayMethods";
import { ViewDateFormat } from "global/helpers/DateFormatter";

interface IProps {
  loading: boolean;
  notifications: NotificationsType[];
  setShowNotification: Function;
  refetchUserNotifications: Function;
  showReadMessage: boolean;
  setShowReadMessage: Function;
}

const Notification: FC<IProps> = ({
  loading,
  notifications,
  setShowNotification,
  refetchUserNotifications,
  showReadMessage,
  setShowReadMessage,
}) => {
  const navigate = useNavigate();

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  const viewAllNotificationHandler = () => {
    setShowNotification(false);
    navigate("/notification/all");
  };

  const clickHandler = () => {
    setShowReadMessage(!showReadMessage);
  };

  return (
    <div>
      <div className="absolute w-0 h-0 border-l-[10px] border-l-transparent z-50 border-r-[10px] border-r-transparent border-b-[10px] border-b-white top-12 right-3 shadow-[0px_0px_45px_#C4C4C433]" />
      <div className="absolute w-[260px] md:w-[431px]  rounded-[4px_4px_0px_0px] bg-white top-14 lg:top-[58px] -right-20 shadow-[0px_2px_45px_#9B8B8B29]">
        <div className="flex justify-between items-center w-full h-[56px] shadow-[0px_6px_4px_#D5D5D529] p-[14px_29px_21px_20px]">
          <h2 className="text-base text-ironside-gray text-center">
            {showReadMessage ? "Read Messages" : "Unread Messages"}
          </h2>
          <button className="text-hit-gray text-xs" onClick={clickHandler}>
            {showReadMessage
              ? "Show all unread messages"
              : "Show all read messages"}
          </button>
        </div>
        {loading ? (
          <Loading className="min-h-[400px]" />
        ) : notifications?.length > 0 ? (
          <div className="p-[15px] md:p-[15px_30px_30px_20px] max-h-[400px] overflow-y-auto">
            {notifications &&
              Object?.entries(groupBy(notifications, "date"))?.map(
                ([key, value]: any, index: number) => {
                  return (
                    <div key={index}>
                      {value?.filter(
                        (messages: any) => messages?.isRead === showReadMessage
                      )?.length > 0 ? (
                        <div className={"border-b last:border-none"}>
                          <h2
                            className={`${
                              key === ViewDateFormat(new Date()?.toDateString())
                                ? ""
                                : "mt-[15px]"
                            } text-[12px] leading-[15px] text-[#8E8E8F] text-center md:text-left`}
                          >
                            {key === ViewDateFormat(new Date()?.toDateString())
                              ? "Today"
                              : key ===
                                ViewDateFormat(
                                  new Date(yesterday)?.toDateString()
                                )
                              ? "Yesterday"
                              : key}
                          </h2>
                          <ul className="my-[23px]">
                            {value?.map((messages: any) => {
                              if (messages?.isRead === showReadMessage)
                                return (
                                  <NotificationDetails
                                    key={messages?.id}
                                    messages={messages}
                                    showReadMessage={showReadMessage}
                                    refetchUserNotifications={
                                      refetchUserNotifications
                                    }
                                    setShowNotification={setShowNotification}
                                  />
                                );
                              return null;
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  );
                }
              )}
          </div>
        ) : (
          <p className="text-ironside-gray/75 text-center text-base min-h-[100px] lg:min-h-[300px] grid place-content-center">
            No Messages
          </p>
        )}
        <div className="w-full flex items-center justify-center my-[30px]">
          <button
            type="button"
            className="text-[14px] leading-[15px] text-cornflower-blue text-center"
            onClick={viewAllNotificationHandler}
          >
            View all notifications
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;
