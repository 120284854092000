import { useEffect, useRef, useState } from "react";
import { HiOutlineCalendar } from "react-icons/hi";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { ViewDateFormat } from "global/helpers/DateFormatter";

const CalendarField: React.FC<{
  register: Function;
  errors: any;
  name: string;
  date: Date | any;
  setDate: Function;
  className?: string;
  label?: string;
  required?: boolean | any;
  minDate?: Date | any;
  maxDate?: Date | any;
  disabled?: boolean;
  startDate?: string;
  setIsDateChanged?: Function | any;
  classForCalendar?: string;
  hideError?: boolean;
}> = ({
  register,
  errors,
  name,
  date,
  setDate,
  className,
  label,
  required,
  minDate,
  maxDate,
  disabled,
  startDate,
  setIsDateChanged,
  classForCalendar,
  hideError,
}) => {
  const calendarRef: any = useRef();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  useEffect(() => {
    const handler = (event: PointerEvent) => {
      if (!calendarRef.current?.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("pointerdown", handler);
    return () => {
      document.removeEventListener("pointerdown", handler);
    };
  }, [showCalendar]);

  return (
    <div
      className="relative flex items-center w-full flex-col"
      ref={calendarRef}
    >
      <div
        className={`${className} ${
          errors[name]
            ? "border-[#FF647C]"
            : showCalendar
            ? "border-cornflower-blue"
            : "border-[#E5E5E5]"
        } border rounded flex justify-between shadow items-center px-3 pt-3 pb-[13px] w-full ${
          disabled ? "" : "cursor-pointer"
        }`}
        onClick={() => (disabled ? disabled : setShowCalendar(!showCalendar))}
      >
        <p
          className={`${
            errors[name]
              ? "text-[#FF647C]"
              : showCalendar
              ? "text-cornflower-blue"
              : "text-[#8f8f92]"
          } ${
            date || showCalendar ? "-top-2 text-[11px]" : "px-0 text-[14px]"
          } absolute left-4 right-12`}
          onClick={() => (disabled ? disabled : setShowCalendar(!showCalendar))}
        >
          <span className="bg-white px-1 text-xs">
            {label ? label : "Date"}
          </span>
        </p>
        <div className="text-ironside-gray text-[14px] w-full">
          {ViewDateFormat(date)}
        </div>
        <div>
          <HiOutlineCalendar
            className={`${
              disabled ? "" : "cursor-pointer"
            } w-6 h-6 text-[#6391f4]`}
            onClick={() =>
              disabled ? disabled : setShowCalendar(!showCalendar)
            }
          />
          <input
            id={name}
            type="date"
            hidden
            defaultValue={date ? ViewDateFormat(date) : ""}
            {...register(name, {
              required: required && !date ? "This is required field." : false,
            })}
          />
        </div>
      </div>
      {showCalendar ? (
        <div
          className={`absolute  ${
            classForCalendar ? classForCalendar : "top-[40px]"
          } z-50 sm:-right-[2%] -right-[15%]`}
        >
          <Calendar
            onChange={(date: any) => {
              if (date) {
                setDate(date);
                setShowCalendar(!showCalendar);
                if (setIsDateChanged) {
                  setIsDateChanged(true);
                }
              }
            }}
            rangeColors={["#3d91ff"]}
            date={
              date ? date : startDate ? new Date(`${startDate}`) : undefined
            }
            className="rounded-md shadow-[0px_0px_10px_0px_rgba(161,161,161,1)]"
            minDate={minDate ? minDate : null}
            maxDate={maxDate ? maxDate : null}
            dateDisplayFormat={"dd-mmm-yyyy"}
          />
        </div>
      ) : null}
      {hideError ? null : (
        <div className="w-full">
          {errors[name] ? (
            <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
              {errors[name].message}
            </span>
          ) : errors[name] ? (
            <span className="invisible">Helper Text</span>
          ) : (
            <span className="invisible">Helper Text</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarField;
