import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query getRoles($limit: Int, $page: Int, $search: String, $id: Int) {
    roles(limit: $limit, page: $page, search: $search, id: $id) {
      dataCollection {
        id
        roleName
        description
        isActive
        isSuperAdmin
      }
      totalNumberOfItems
    }
  }
`;

export const GET_ALLOWED_TREE_RESOURCES = gql`
  query role($limit: Int, $page: Int, $search: String, $id: Int) {
    roles(limit: $limit, page: $page, search: $search, id: $id) {
      dataCollection {
        allowedResourceIds {
          resourceId {
            id
            title
            isAllowed
            childNodes {
              id
              title
              isAllowed
              childNodes {
                id
                title
                isAllowed
                childNodes {
                  id
                  title
                  isAllowed
                  childNodes {
                    id
                    title
                    isAllowed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTION_LOGS = gql`
  query getActionLogs($limit: Int, $page: Int, $search: String) {
    actionLogs(limit: $limit, page: $page, search: $search) {
      dataCollection {
        id
        uuid
        entityType
        entityId
        attributeCode
        previousValue
        updatedValue
        performedAt
        performedBy
        actionType
      }
      totalNumberOfItems
    }
  }
`;

export const ACL = gql`
  query acl($isMyRole: Boolean) {
    aclTree(isMyRole: $isMyRole) {
      resourceIds {
        resourceId {
          id
          title
          isAllowed
          childNodes {
            id
            title
            isAllowed
            childNodes {
              id
              title
              isAllowed
              childNodes {
                id
                title
                isAllowed
                childNodes {
                  id
                  title
                  isAllowed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ROLES = gql`
  query allRoles($limit: Int, $page: Int, $search: String, $id: Int) {
    roles(limit: $limit, page: $page, search: $search, id: $id) {
      dataCollection {
        id
        roleName
        description
        isActive
        isSuperAdmin
        allowedResourceIds {
          resourceId {
            id
            title
          }
        }
      }
      totalNumberOfItems
    }
  }
`;

export const ACTION_LOGS = gql`
  query actionLogs($limit: Int, $page: Int, $search: String) {
    actionLogs(limit: $limit, page: $page, search: $search) {
      dataCollection {
        id
        uuid
        entityType
        entityId
        attributeCode
        previousValue
        updatedValue
        performedAt
        performedBy
        actionType
      }
      totalNumberOfItems
    }
  }
`;
