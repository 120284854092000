import { FC, ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { twMerge } from "tailwind-merge";

import Button from "components/forms/Button/UpdatedButtonAgry/Button";

type Props = {
	title: { children: ReactNode; className?: string };
	className?: string;
	navigation?: {
		onClick: () => void;
		className?: string;
	};
};
const TitleAndBackNavigation: FC<Props> = ({
	title,
	navigation,
	className,
}) => {
	return (
		<div className={twMerge("flex gap-2 items-center min-h-[64px]", className)}>
			{navigation && (
				<Button
					modifier="plain"
					onPress={navigation?.onClick}
					className={twMerge(
						"w-[40px] h-[40px] sm:w-[48px] sm:h-[48px] flex justify-center items-center hover:bg-white-smoke hover:text-primary text-secondary",
						navigation?.className,
					)}
					isRounded
				>
					<HiArrowLeft className="w-6 h-6 rounded-full cursor-pointer" />
				</Button>
			)}
			{typeof title?.children === "string" ? (
				<h1
					className={twMerge(
						"text-[22px] leading-7 text-on-surface flex-1",
						navigation ? "" : "text-center",
						title?.className,
					)}
				>
					{title.children}
				</h1>
			) : (
				title?.children
			)}
		</div>
	);
};

export default TitleAndBackNavigation;
