import { ComponentPropsWithRef, FC, useRef } from "react";
import { mergeProps, useLocale, useRangeCalendar } from "react-aria";
import {
  RangeCalendarStateOptions,
  useRangeCalendarState,
} from "react-stately";
import { DateValue, createCalendar } from "@internationalized/date";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { twMerge } from "tailwind-merge";

import { CalendarButton } from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarButton";
import MonthDropdown from "components/forms/UpdatedFormAgry/Date/Calendar/MonthDropdown";
import { YearDropdown } from "components/forms/UpdatedFormAgry/Date/Calendar/YearDropdown";
import CalendarGrid from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarGrid";

type Props =
  | Omit<
      ComponentPropsWithRef<"div">,
      "children" | "defaultValue" | "onChange"
    > &
      Omit<RangeCalendarStateOptions<DateValue>, "locale" | "createCalendar">;

const RangeCalendar: FC<Props> = ({
  isDisabled,
  isReadOnly,
  maxValue,
  minValue,
  validationState,
  value,
  allowsNonContiguousRanges,
  autoFocus,
  defaultFocusedValue,
  defaultValue,
  errorMessage,
  focusedValue,
  isDateUnavailable,
  onChange,
  onFocusChange,
  visibleDuration,
  id,
  className,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    locale,
    createCalendar,
    isDisabled,
    isReadOnly,
    maxValue,
    minValue,
    validationState,
    value,
    allowsNonContiguousRanges,
    autoFocus,
    defaultFocusedValue,
    defaultValue,
    errorMessage,
    focusedValue,
    isDateUnavailable,
    onChange,
    onFocusChange,
    visibleDuration,
  });

  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    {
      isDisabled,
      isReadOnly,
      maxValue,
      minValue,
      validationState,
      value,
      allowsNonContiguousRanges,
      autoFocus,
      defaultFocusedValue,
      defaultValue,
      errorMessage,
      focusedValue,
      isDateUnavailable,
      onChange,
      onFocusChange,
      id,
    },
    state,
    ref
  );

  return (
    <div
      {...mergeProps(calendarProps, props)}
      ref={ref}
      className={twMerge(
        "inline-block p-3 min-w-[300px]",
        className ? className : ""
      )}
    >
      <div className="flex justify-between items-center pb-4">
        <CalendarButton {...prevButtonProps} className={"border"}>
          <MdOutlineKeyboardArrowLeft className="w-6 h-6" />
        </CalendarButton>
        <div className="flex gap-2">
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
        </div>
        <CalendarButton {...nextButtonProps} className={"border"}>
          <MdOutlineKeyboardArrowRight className="w-6 h-6" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
};

export default RangeCalendar;
