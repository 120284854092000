import { TypedDocumentNode, gql } from "@apollo/client";

import { ALL_PROJECT_WORK_LOG_FRAGMENT } from "modules/Timer/WorkLogs/services/fragments";
import {
  FilterAllProjectWorkLogsArgs,
  FilterAllProjectWorkLogsReturnType,
} from "modules/Timer/WorkLogs/types";

export const FILTER_ALL_PROJECT_WORK_LOGS: TypedDocumentNode<
  FilterAllProjectWorkLogsReturnType,
  FilterAllProjectWorkLogsArgs
> = gql`
  ${ALL_PROJECT_WORK_LOG_FRAGMENT}
  query AllProjectWorkLogs(
    $limit: Int
    $page: Int
    $startTime: String
    $endTime: String
    $isMyWorkLog: Boolean
  ) {
    filterAllProjectWorkLogs: allProjectWorkLogs(
      limit: $limit
      page: $page
      startTime: $startTime
      endTime: $endTime
      isMyWorkLog: $isMyWorkLog
    ) {
      dataCollection {
        ...allProjectWorkLogFields
      }
      totalNumberOfItems
    }
  }
`;
