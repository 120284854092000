import { ChangeEvent, useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { HiSearch, HiX } from "react-icons/hi";

const SearchField: React.FC<{
  inputFieldClassName?: string;
  searchIconClassName?: string;
  register: Function;
  name: string;
  label?: string;
  onChange?: Function;
  classNameForInput?: string;
  setValue: UseFormSetValue<FieldValues>;
}> = ({
  inputFieldClassName,
  searchIconClassName,
  register,
  name,
  label,
  onChange,
  classNameForInput,
  setValue,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <div
      className={
        "bg-white relative shadow flex items-center justify-between transition-all border border-[#E5E5E5] rounded focus-within:border-cornflower-blue font-normal"
      }
    >
      <label htmlFor={name} className="cursor-pointer pl-[20px]">
        <HiSearch
          className={`${searchIconClassName} w-[18px] h-[19px] text-hit-gray`}
        />
      </label>
      <input
        id={name}
        type="text"
        placeholder="Name"
        autoComplete="off"
        className={`${inputFieldClassName} text-ironside-gray peer pl-3 ${
          classNameForInput ? classNameForInput : "h-[50px]"
        } max-h-full w-full bg-transparent placeholder-transparent focus:outline-none focus:border-cornflower-blue`}
        {...register(name, {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e);
            setSearchValue(e.target.value);
          },
        })}
      />
      <label
        htmlFor={name}
        className={`absolute px-1 cursor-text bg-white ml-3 left-8 -top-2 text-ironside-gray/70 text-[11px] transition-all peer-placeholder-shown:text-sm font-normalpeer-placeholder-shown:text-ironside-gray/70  ${
          classNameForInput
            ? "peer-placeholder-shown:top-[11px]"
            : "peer-placeholder-shown:top-[16px]"
        }  peer-focus:-top-2 peer-focus:text-cornflower-blue  peer-focus:text-[11px] peer-focus:bg-white`}
      >
        {label ? label : "Search"}
      </label>
      {searchValue?.trim()?.length > 0 && (
        <HiX
          className="w-6 h-6 mr-5 cursor-pointer"
          onClick={() => {
            setValue(name, "");
            setSearchValue("");
          }}
        />
      )}
    </div>
  );
};

export default SearchField;
