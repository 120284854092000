import { Control, FieldPath, FieldValues } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import Select from "components/forms/UpdatedFormAgry/Select/Select";
import Button from "components/forms/Button/UpdatedButtonAgry/Button";

import { rowPerPageArray } from "global/helpers/StaticData";
import { useCustomSearchParams } from "global/UpdatedHooks/hooks";

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  onPageSizeChange: (pageSize: number) => void;
  classNameForSelect?: string;
  prevDisabled: boolean;
  nextDisabled: boolean;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  control: Control<TFieldValues>;
  pageSizeName: Name;
  totalCount: number;
  className?: string;
  hideTotalItems?: boolean;
}

const PaginationUpdated = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  onPageSizeChange,
  classNameForSelect,
  prevDisabled,
  nextDisabled,
  onNextPageHandler,
  onPrevPageHandler,
  control,
  pageSizeName,
  totalCount,
  className,
  hideTotalItems,
}: Props<TFieldValues, Name>) => {
  const [searchParams, setSearchParams] = useCustomSearchParams();

  return (
    <div
      className={twMerge(
        "flex justify-between py-5 gap-1 items-center",
        className
      )}
    >
      <div>
        <Select
          control={control as any}
          name={pageSizeName}
          options={rowPerPageArray as any}
          label="Showing items"
          className={twMerge(
            "bg-white sm:max-w-[120px] max-w-[110px] min-h-[45px] sm:min-w-[120px]",
            classNameForSelect
          )}
          onChange={(pageSize) => {
            setSearchParams({
              ...searchParams,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              pageSize: pageSize?.toString(),
            });
            onPageSizeChange(pageSize);
          }}
        />
      </div>
      {hideTotalItems ? null : (
        <p className="text-sm whitespace-pre-wrap sm:whitespace-nowrap text-center sm:text-left">
          Total items: {totalCount}
        </p>
      )}
      <div className="flex justify-center items-center">
        <div
          className={
            "flex border border-outline rounded divide-x divide-outline"
          }
        >
          <Button
            children={
              <span className="flex items-center gap-1">
                <BiChevronLeft className="w-5 h-5" />
                <p>Prev</p>
              </span>
            }
            modifier="plain"
            onPress={onPrevPageHandler}
            disabled={prevDisabled}
            className="rounded-none px-2 py-1.5 enabled:text-secondary text-sm focus:text-primary"
            size="small"
          />
          <Button
            children={
              <span className="flex items-center gap-1">
                <p>Next</p>
                <BiChevronRight className="w-5 h-5" />
              </span>
            }
            modifier="plain"
            onPress={onNextPageHandler}
            disabled={nextDisabled}
            className="rounded-none px-2 py-1.5 enabled:text-secondary text-sm focus:text-primary"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default PaginationUpdated;
