import { FC, useState } from "react";
import { TbCopy } from "react-icons/tb";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { LuAsterisk } from "react-icons/lu";

import ToolTip from "components/UI/ToolTip";

interface IProps {
  value: string | number;
}

const ShowVaultPassword: FC<IProps> = ({ value }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const [copyText, setCopyText] = useState(false);

  return (
    <div className="max-w-[250px] flex justify-between items-center gap-5">
      <div>
        {showPassword ? (
          <span>{value?.toString()}</span>
        ) : (
          new Array(value?.toString()?.length)
            .fill("*")
            ?.map(() => (
              <LuAsterisk className="inline-block text-ironside-gray w-3 h-3" />
            ))
        )}
      </div>
      {showPassword ? (
        <AiFillEyeInvisible
          onClick={showPasswordHandler}
          className="min-w-[20px] min-h-[20px] cursor-pointer"
        />
      ) : (
        <AiFillEye
          onClick={showPasswordHandler}
          className="min-w-[20px] min-h-[20px] cursor-pointer"
        />
      )}
      <ToolTip
        render={() => (copyText ? "Copied" : "Copy")}
        arrow
        className={"text-center bg-[#616161] text-white"}
      >
        <TbCopy
          className="min-w-[20px] min-h-[20px] cursor-pointer invisible group-hover:visible"
          onMouseEnter={() => {
            setCopyText(false);
          }}
          onClick={() => {
            setCopyText(true);
            navigator.clipboard.writeText(`${value}`);
          }}
        />
      </ToolTip>
    </div>
  );
};

export default ShowVaultPassword;
