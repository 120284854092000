import { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import NoDataFoundMessage from "components/UI/NoDataFoundMessage";
import AddButton from "components/forms/Button/AddButton";

import {
  paginationDefaultCount,
  setTitle,
  toastNotify,
} from "global/helpers/Cache";
import { FilterFormType } from "global/types/type";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
} from "global/UpdatedHooks/hooks";
import usePreLoading from "global/hooks/usePreLoading";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import useAllowedResources from "global/hooks/useAllowedResources";

import {
  FilterProjectGroupCommonArgs,
  ProjectGroup,
  ProjectGroupEditList,
  ProjectGroupFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";
import {
  DELETE_PROJECT_GROUP,
  FILTER_PROJECT_GROUPS,
} from "modules/Project/Pages/Projects/ProjectGroups/services";
import ProjectGroupFilter from "modules/Project/Pages/Projects/ProjectGroups/helpers/ProjectGroupFilter";
import ProjectGroupCard from "modules/Project/Pages/Projects/ProjectGroups/ProjectGroupList/ProjectGroupCard";
import ProjectGroupTable from "modules/Project/Pages/Projects/ProjectGroups/ProjectGroupList/ProjectGroupTable";
import EditProjectGroup from "modules/Project/Pages/Projects/ProjectGroups/EditProjectGroup";

const ProjectGroups = () => {
  const navigate = useNavigate();

  const allowedResources = useAllowedResources();

  const canReadProjectsOrGroupBugsOrTasks =
    allowedResources?.includes("MyProject") ||
    allowedResources?.includes("AllProject") ||
    allowedResources?.includes("ReadProjectBugGroup") ||
    allowedResources?.includes("ReadProjectGroupTask");
  const canCreate: boolean = allowedResources?.includes("CreateProjectGroup");
  const canUpdate: boolean = allowedResources?.includes("UpdateProjectGroup");
  const canDelete: boolean = allowedResources?.includes("DeleteProjectGroup");

  const fieldArgs: FilterProjectGroupCommonArgs = {
    isDescriptionNeed: true,
  };

  const [deleteProjectGroup, { loading: deleteProjectGroupLoading }] =
    useMutation(DELETE_PROJECT_GROUP);

  const [searchParams, setSearchParams] = useCustomSearchParams();

  const [editProjectGroup, setEditProjectGroup] =
    useState<ProjectGroupEditList | null>(null);

  const paginationDefaultValue = useReactiveVar(paginationDefaultCount);

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      limit: `${paginationDefaultValue}`,
    });
  });

  const { control, resetField, reset, setValue } = useForm<
    FilterFormType<ProjectGroupFilterFormType>
  >({
    defaultValues: {
      search: searchParams?.search || "",
      pageSize: paginationDefaultValue || 10,
      filter: { names: null, description: null },
    },
    shouldUnregister: false,
  });

  const [pageSize, watchSearch] = useWatch({
    control,
    name: ["pageSize", "search"],
  });
  const search = useSearch(watchSearch || "");

  useEffect(() => {
    setTitle([
      { name: "Project", link: "/projects/groups" },
      { name: "Groups", link: "/projects/groups" },
    ]);
  }, []);

  const {
    data: filterProjectGroups,
    loading,
    fetchMore,
    updateQuery,
  } = useQuery(FILTER_PROJECT_GROUPS, {
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: {
        size: pageSize,
      },
      globalSearch: search || undefined,
      ...fieldArgs,
    },
    notifyOnNetworkStatusChange: true,
  });

  const onEdit = (
    type: "create" | "update" | "delete",
    projectGroup?: ProjectGroup | null
  ) => {
    if (type === "create") {
      setEditProjectGroup({ type });
    } else if (projectGroup) {
      setEditProjectGroup({ type, projectGroup });
    }
  };

  const closeHandler = () => {
    setEditProjectGroup(null);
  };

  const projectGroupEdges =
    filterProjectGroups?.filterProjectGroups?.edges || [];

  const projectGroupsLength = projectGroupEdges?.length || 0;

  const totalCount =
    filterProjectGroups?.filterProjectGroups?.pageInfo?.totalNumberOfItems || 0;

  const addBtnHandler = () => {
    onEdit("create");
  };

  const preLoading = usePreLoading({
    queryLoading: loading,
  });

  const onPageSizeChange: (pageSize: number) => void = (pageSize) => {
    resetField("search");
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
        return { filterProjectGroups };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const nextDisabled =
    filterProjectGroups?.filterProjectGroups?.pageInfo?.endCursor &&
    filterProjectGroups?.filterProjectGroups?.pageInfo?.hasNextPage
      ? false
      : true;

  const onNextPageHandler = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
          after: filterProjectGroups?.filterProjectGroups?.pageInfo?.endCursor,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
        return { filterProjectGroups };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onPrevPageHandler = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
          before:
            filterProjectGroups?.filterProjectGroups?.pageInfo?.startCursor,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectGroups } }) => {
        return { filterProjectGroups };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const prevDisabled =
    filterProjectGroups?.filterProjectGroups?.pageInfo?.startCursor &&
    filterProjectGroups?.filterProjectGroups?.pageInfo?.hasPreviousPage
      ? false
      : true;

  const onDelete = () => {
    if (
      editProjectGroup?.type === "delete" &&
      editProjectGroup?.projectGroup?.id
    ) {
      deleteProjectGroup({
        variables: {
          id: editProjectGroup?.projectGroup?.id,
        },
      })
        .then(({ data }) => {
          if (data && data?.deleteProjectGroup) {
            closeHandler();
            toastNotify([
              {
                message: "Project group deleted successfully.",
                messageType: "success",
              },
            ]);
            if (
              filterProjectGroups?.filterProjectGroups?.edges?.length === 1 &&
              filterProjectGroups?.filterProjectGroups?.pageInfo
                ?.hasPreviousPage &&
              filterProjectGroups?.filterProjectGroups?.pageInfo?.startCursor
            ) {
              fetchMore({
                variables: {
                  pagination: {
                    size: pageSize,
                    before:
                      filterProjectGroups?.filterProjectGroups?.pageInfo
                        ?.startCursor || undefined,
                  },
                  ...fieldArgs,
                },
                updateQuery: (
                  prev,
                  { fetchMoreResult: { filterProjectGroups } }
                ) => {
                  return {
                    filterProjectGroups,
                  };
                },
              }).catch((error) => {
                toastNotify(errorMessageNotify(error));
              });
              reset();
            } else {
              updateQuery(({ filterProjectGroups }) => {
                return {
                  filterProjectGroups: filterProjectGroups
                    ? {
                        pageInfo: filterProjectGroups?.pageInfo
                          ? {
                              ...filterProjectGroups?.pageInfo,
                              totalNumberOfItems: filterProjectGroups?.pageInfo
                                ?.totalNumberOfItems
                                ? filterProjectGroups?.pageInfo
                                    ?.totalNumberOfItems - 1
                                : 0,
                            }
                          : null,
                        edges:
                          filterProjectGroups?.edges &&
                          filterProjectGroups?.edges?.length > 0
                            ? filterProjectGroups?.edges?.filter(
                                (edge) =>
                                  edge?.node?.id !==
                                  editProjectGroup?.projectGroup?.id
                              ) || []
                            : [],
                        __typename: filterProjectGroups?.__typename,
                      }
                    : null,
                };
              });
            }
          } else {
            toastNotify([
              {
                message: "Something went wrong.",
                messageType: "error",
              },
            ]);
          }
        })
        .catch((err) => toastNotify(err(err)));
    }
  };

  const cardOrTableClickHandler = (projectGroupId: number) => {
    if (projectGroupId) {
      navigate(`/projects/${projectGroupId}/my-projects`);
    }
  };

  const tableAndCardProps = {
    canUpdate,
    canDelete,
    onEdit,
    cardOrTableClickHandler,
    nextDisabled,
    prevDisabled,
    control,
    totalCount,
    projectGroupEdges,
    onPageSizeChange,
    onNextPageHandler,
    onPrevPageHandler,
    canReadProjectsOrGroupBugsOrTasks,
  };

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[82vh] bg-white">
      <div className="max-w-3xl mx-auto">
        <ProjectGroupFilter
          control={control}
          setValue={setValue}
          search={search}
          projectGroupsLength={projectGroupsLength}
          canCreate={canCreate}
          addBtnHandler={addBtnHandler}
          fieldArgs={fieldArgs}
          pageSize={pageSize}
          fetchMore={fetchMore}
          reset={reset}
        />
        {preLoading ? (
          <Loading className="min-h-[calc(100vh-25vh)]" />
        ) : projectGroupsLength > 0 ? (
          <Fragment>
            <ProjectGroupCard {...tableAndCardProps} />
            <ProjectGroupTable {...tableAndCardProps} />
          </Fragment>
        ) : (search?.length > 0 && projectGroupsLength === 0) || !canCreate ? (
          <NoDataFoundMessage message="Sorry, we couldn't find any project group." />
        ) : (
          canCreate && <AddButton onPress={addBtnHandler} />
        )}

        {(editProjectGroup?.type === "create" ||
          editProjectGroup?.type === "update") && (
          <EditProjectGroup
            onClose={closeHandler}
            updateQuery={updateQuery}
            projectGroup={
              editProjectGroup?.type === "update"
                ? editProjectGroup?.projectGroup
                : undefined
            }
            fieldArgs={fieldArgs}
          />
        )}

        {canDelete && editProjectGroup?.type === "delete" && (
          <ConfirmModal
            header="Project group"
            onCancel={closeHandler}
            onXIcon={closeHandler}
            onExecute={onDelete}
            loading={deleteProjectGroupLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectGroups;
