import DateRangeSelector from "components/forms/DatePicker/DateRangeSelector";

import { ISetStateType } from "global/types/type";

const RangeSelector: React.FC<{
  setRangeFocus: Function;
  workingDates: string[];
  setDateRange: Function;
  selectedDates: any[];
  label?: string;
  minDate?: Date | any;
  maxDate?: Date | any;
  setIsDateChanged?: ISetStateType<boolean>;
  classNameForRangeSelector?: string;
  classNameForDateRange?: string;
}> = ({
  setRangeFocus,
  workingDates,
  setDateRange,
  selectedDates,
  label,
  minDate,
  maxDate,
  setIsDateChanged,
  classNameForRangeSelector,
  classNameForDateRange,
}) => {
  const newDateRange: [
    {
      startDate: Date | any;
      endDate: Date | any;
      key: string;
    }
  ] =
    workingDates?.length > 0
      ? [
          {
            startDate: new Date(workingDates[0]),
            endDate: new Date(workingDates[workingDates?.length - 1]),
            key: "selection",
          },
        ]
      : [
          {
            startDate: selectedDates?.length > 0 && new Date(selectedDates[0]),
            endDate:
              selectedDates?.length > 0 &&
              new Date(selectedDates[selectedDates?.length - 1]),
            key: "selection",
          },
        ];

  return (
    <div
      className={`${
        classNameForRangeSelector
          ? classNameForRangeSelector
          : "flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-24 ml-1 mb-8"
      }`}
    >
      <DateRangeSelector
        setRangeFocus={setRangeFocus}
        dateRange={newDateRange}
        setDateRange={setDateRange}
        datesArray={workingDates}
        label={label}
        minDate={minDate ? minDate : null}
        maxDate={maxDate ? maxDate : null}
        setIsDateChanged={setIsDateChanged}
        classNameForDateRange={classNameForDateRange}
      />
    </div>
  );
};

export default RangeSelector;
