import { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";

import { Filled } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import OopsNoData from "components/UI/OopsNoData";
import Loading from "components/Loader/Loading";

import { setTitle, toastNotify, userContextData } from "global/helpers/Cache";

import { GET_ALL_PROJECT_VAULT_CREDENTIALS } from "modules/Project/services/queries";
import EditVault from "modules/Project/Pages/Vault/EditVault";
import { IProjectVault } from "modules/Project/types/project";
import { DELETE_PROJECT_VAULT_CREDENTIAL } from "modules/Project/services/mutations";
import VaultCredential from "modules/Project/Pages/Vault/VaultCredential";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
  allowedResourcesForProject: string[];
  allowedResources: string[];
  editProject: {
    id: number;
    name: string;
  };
  projectGroup?: ProjectGroup | null;
}

const Vault: FC<Props> = ({
  allowedResourcesForProject = [],
  allowedResources,
  editProject,
  projectGroup,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;

  const createOrUpdateAccess =
    allowedResourcesForProject?.includes("SaveProjectVaultCredential") ||
    allowedResources?.includes("SaveProjectVaultCredential") ||
    isSuperAdmin;

  const canDeleteVault =
    isSuperAdmin ||
    allowedResourcesForProject?.includes("DeleteProjectVaultCredential") ||
    allowedResources?.includes("DeleteProjectVaultCredential");

  const [editCredentialStatus, setEditCredentialStatus] =
    useState<boolean>(false);

  const { id, projectType } = useParams();

  useEffect(() => {
    if (editProject?.name) {
      setTitle(
        projectGroup?.id
          ? [
              {
                name: "Project",
                link: "/projects/groups",
              },
              { name: "Groups", link: "/projects/groups" },
              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/${projectType}`,
              },
              {
                name: `${editProject?.name}`,
                link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
              },
              {
                name: "Vault",
                link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/vault`,
              },
            ]
          : [
              { name: "Project", link: "/projects/projects/my-projects" },
              {
                name: `${editProject?.name}`,
                link: `/projects/projects/${projectType}/edit/${id}/general`,
              },
              {
                name: "Vault",
                link: `projects/projects/${projectType}/edit/${id}/vault`,
              },
            ]
      );
    }
  }, [
    editProject?.name,
    projectType,
    id,
    projectGroup?.id,
    projectGroup?.name,
  ]);

  const [
    deleteProjectVaultCredential,
    { loading: deleteProjectVaultCredentialLoading },
  ] = useMutation(DELETE_PROJECT_VAULT_CREDENTIAL);

  const [
    fetchAllProjectCredentials,
    {
      data: getAllProjectVaultCredentials,
      loading: projectVaultCredentialLoading,
      refetch: refetchProjectVaultCredentials,
    },
  ] = useLazyQuery<{
    projectVaultCredentials: IProjectVault[];
  }>(GET_ALL_PROJECT_VAULT_CREDENTIALS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (id) {
      fetchAllProjectCredentials({
        variables: {
          projectId: Number(id),
        },
      });
    }
  }, [id, fetchAllProjectCredentials, editCredentialStatus]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [projectVaultCredentialID, setProjectVaultCredentialID] = useState<
    number | null
  >(null);

  const [editProjectVaultCredential, setEditProjectVaultCredential] =
    useState<IProjectVault | null>(null);

  const vaultCredentialsList: IProjectVault[] =
    getAllProjectVaultCredentials?.projectVaultCredentials?.filter(
      (projectVault: any) =>
        projectVault?.environment?.type?.length > 0 && projectVault
    ) || [];

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteProjectVaultCredentialHandler = () => {
    if (projectVaultCredentialID) {
      deleteProjectVaultCredential({
        variables: {
          id: projectVaultCredentialID,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectVaultCredentials();
          setProjectVaultCredentialID(null);
          toastNotify([
            {
              messageType: "success",
              message: `Credential has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };

  return (
    <Fragment>
      <div className="min-h-[700px]">
        {vaultCredentialsList?.length > 0 && (
          <div className="flex justify-end py-5">
            <div className="">
              {createOrUpdateAccess ? (
                <Filled
                  className="whitespace-nowrap p-1 inline-block min-w-[220px]"
                  buttonName={"Add"}
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
        <div>
          {vaultCredentialsList?.length <= 0 &&
            createOrUpdateAccess &&
            !projectVaultCredentialLoading && (
              <div className="md:min-h-[650px] min-h-[440px]">
                <div
                  className={`${
                    vaultCredentialsList?.length <= 0 ? "" : ""
                  }flex justify-center items-center h-[500px]`}
                >
                  {createOrUpdateAccess ? (
                    <Filled
                      className="whitespace-nowrap p-1 inline-block min-w-[220px]"
                      buttonName={"Add"}
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            )}
          {projectVaultCredentialLoading ? (
            <Loading className="min-h-[70vh]" />
          ) : vaultCredentialsList?.length > 0 ? (
            vaultCredentialsList?.map((credential, index: number) => {
              return (
                <VaultCredential
                  key={index}
                  credential={credential}
                  canDeleteVault={canDeleteVault}
                  createOrUpdateAccess={createOrUpdateAccess}
                  setDeleteVerification={setDeleteVerification}
                  setEditProjectVaultCredential={setEditProjectVaultCredential}
                  setProjectVaultCredentialID={setProjectVaultCredentialID}
                  setShowModal={setShowModal}
                />
              );
            })
          ) : (
            !createOrUpdateAccess && <OopsNoData className="min-h-[70vh]" />
          )}
        </div>
      </div>
      {showModal && (
        <EditVault
          loading={projectVaultCredentialLoading}
          editProjectVaultCredential={editProjectVaultCredential}
          setShowModal={setShowModal}
          refetchProjectVaultCredentials={refetchProjectVaultCredentials}
          projectVaultCredentialID={projectVaultCredentialID}
          setEditProjectVaultCredential={setEditProjectVaultCredential}
          setProjectVaultCredentialID={setProjectVaultCredentialID}
          setEditCredentialStatus={setEditCredentialStatus}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Vault Credential"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteProjectVaultCredentialHandler}
          loading={deleteProjectVaultCredentialLoading}
        />
      )}
    </Fragment>
  );
};

export default Vault;
