import { FC } from "react";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";

import EditForm from "components/EditForm/UpdatedEditFormAgry/EditForm";
import {
  Input,
  TextArea,
} from "components/forms/UpdatedFormAgry";

import { toastNotify } from "global/helpers/Cache";
import {  UpdateQueryType } from "global/types/type";
import useAllowedResources from "global/hooks/useAllowedResources";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import { notEmpty } from "global/helpers/ArrayMethods";

import {
  FilterProjectGroupBugSourceArgs,
  FilterProjectGroupBugSourceReturnType,
  ProjectGroupBugSource,
  ProjectGroupBugSourceFieldArgs,
  ProjectGroupBugSourceFormType,
} from "modules/Project/Pages/ProjectGroupBugSource/types";
import {
  CREATE_PROJECT_GROUP_BUG_SOURCE,
  UPDATE_PROJECT_GROUP_BUG_SOURCE,
} from "modules/Project/Pages/ProjectGroupBugSource/services";
import ProjectGroupField from "modules/Project/Pages/Projects/ProjectGroups/helpers/ProjectGroupField";

interface Props {
  onClose: () => void;
  updateQuery: UpdateQueryType<
    FilterProjectGroupBugSourceReturnType,
    FilterProjectGroupBugSourceArgs
  >;
  projectGroupBugSource?: ProjectGroupBugSource | null;
  fieldArgs: ProjectGroupBugSourceFieldArgs
}

const EditProjectGroupBugSource: FC<Props> = ({
  onClose,
  updateQuery,
  projectGroupBugSource,
  fieldArgs
}) => {
  const allowedResources = useAllowedResources();

  const canCreate = allowedResources?.includes("CreateProjectBugSource");
  const canUpdate = allowedResources?.includes("UpdateProjectBugSource");

  const [createProjectBugSource, { loading: createProjectBugSourceLoading }] =
    useMutation(CREATE_PROJECT_GROUP_BUG_SOURCE);

  const [
    updateProjectGroupBugSource,
    { loading: updateProjectGroupBugSourceLoading },
  ] = useMutation(UPDATE_PROJECT_GROUP_BUG_SOURCE);


  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ProjectGroupBugSourceFormType>({
    defaultValues: {
      name: projectGroupBugSource?.name || null as unknown as string,
      description: projectGroupBugSource?.description,
      projectGroups: projectGroupBugSource?.projectGroup && projectGroupBugSource?.projectGroup?.map((projectGroup) => {
        if(projectGroup?.id) {
          return {
            id: projectGroup?.id,
            label: projectGroup?.name || "N/A"
          }
        } else {
          return null
        }
      }).filter(notEmpty)
    }
  });

  const submitHandler: SubmitHandler<ProjectGroupBugSourceFormType> = ({
    description,
    name,
    projectGroups,
  }) => {
    if (projectGroupBugSource?.id) {
      updateProjectGroupBugSource({
        variables: {
          projectBugSourceUpdateInput: {
            id: projectGroupBugSource?.id,
            description: description?.trim(),
            name,
            projectGroupIds: projectGroups&& projectGroups?.length > 0 ? projectGroups?.map((projectGroup) => projectGroup?.id ) : []
          },
           ...fieldArgs,
        },
      })
        .then(() => {
          onClose();
          toastNotify([
            {
              messageType: "success",
              message: "Bug source updated successfully",
            },
          ]);
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    } else {
      createProjectBugSource({
        variables: {
          projectBugSourceCreateInput: {
            name,
            description: description?.trim() || undefined,
            projectGroupIds: projectGroups && projectGroups?.length > 0 ? projectGroups?.map((projectGroup) => projectGroup?.id)  : undefined
          },
        },
      })
        .then(({ data }) => {
          if (data && data?.createProjectBugSource?.id) {
            const id = data?.createProjectBugSource?.id;
            onClose();
            toastNotify([
              {
                messageType: "success",
                message: "Bug source added successfully",
              },
            ]);
            updateQuery(({ filterProjectBugSource }) => {
              return {
                filterProjectBugSource: filterProjectBugSource
                  ? {
                      pageInfo: filterProjectBugSource?.pageInfo
                        ? {
                            ...filterProjectBugSource?.pageInfo,
                            totalNumberOfItems: filterProjectBugSource?.pageInfo
                              ?.totalNumberOfItems
                              ? filterProjectBugSource?.pageInfo
                                  ?.totalNumberOfItems + 1
                              : 1,
                          }
                        : null,
                      edges:
                        filterProjectBugSource?.edges &&
                        filterProjectBugSource?.edges?.length > 0
                          ? [
                              {
                                cursor: null,
                                node: {
                                  id,
                                  ...data?.createProjectBugSource,
                                },
                                __typename: null,
                              },
                              ...filterProjectBugSource.edges,
                            ]
                          : [
                              {
                                node: {
                                  id,
                                  ...data?.createProjectBugSource,
                                },
                                cursor: null,
                                __typename: null,
                              },
                            ],
                      __typename: filterProjectBugSource?.__typename,
                    }
                  : null,
              };
            });
          }
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    }
  };

  return (
    <EditForm
      title={"Bug source"}
      handleSubmit={handleSubmit}
      onSubmit={submitHandler}
      outlineBtnName={"Cancel"}
      filledBtnName={projectGroupBugSource?.id ? "Update" : "Add"}
      onClose={onClose}
      filledBtnLoading={
        createProjectBugSourceLoading || updateProjectGroupBugSourceLoading
      }
      filledBtnDisable={
        isValid
          ? projectGroupBugSource?.id
            ? !canUpdate
            : !canCreate
          : true
      }
    >
      <Input control={control} label="Name" name="name" required />
      <ProjectGroupField
      control={control}
      name="projectGroups"
      label="Project group"
      className="bg-white"
      multiple
      />
      <TextArea label="Description" name="description" control={control} />
    </EditForm>
  );
};

export default EditProjectGroupBugSource;
