import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useForm, useWatch } from "react-hook-form";

import UpdateRole from "./UpdateRole";
import Loading from "components/Loader/Loading";
import { SearchField, Select } from "components/forms";
import Pagination from "components/Pagination/Pagination";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";

import { paginationDefaultValue } from "global/helpers/StaticData";

import { GET_ALL_EMPLOYEES } from "modules/Employee/services/queries";
import OopsNoData from "components/UI/OopsNoData";
import UserDetails from "components/UserDetails/UserDetails";
import { ITableContent } from "components/App/Table/types/table";
import { FILTER_CLIENTS } from "modules/Organization/Pages/Clients/services/queries";

const tableHeadings = ["Name", "Email", "Role", "Action"];

interface IProps {
  allowedResources: string[];
}
const RoleAllocation: FC<IProps> = ({ allowedResources }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm<any>({
    defaultValues: {
      userType: "Employees",
    },
  });

  const updateAccess = allowedResources?.includes("UpdateUserRole");
  const [loadUserList, { data: allEmployees, loading, refetch: refetchUser }] =
    useLazyQuery(GET_ALL_EMPLOYEES, {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    });

  const [
    loadClientsList,
    { data: getAllClients, loading: clientsLoading, refetch: refetchClients },
  ] = useLazyQuery(FILTER_CLIENTS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [users, setUsers] = useState([]);

  const watchUserType = useWatch({
    control,
    name: "userType",
  });

  useEffect(() => {
    setUsers(
      watchUserType === "Employees"
        ? allEmployees?.getAllEmployees?.dataCollection
        : getAllClients?.getAllClients?.dataCollection
    );
  }, [allEmployees, getAllClients, watchUserType]);


  const [userId, setUserId] = useState<number | undefined>();
  const [editRole, setEditRole] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const searchEmployee = watch("employee");
  const [search, setSearch] = useState(searchEmployee || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(searchEmployee?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [searchEmployee]);

  useEffect(() => {
    if (watchUserType === "Employees") {
      loadUserList({
        variables: {
          status: "Active",
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
        },
      });
    } else if (watchUserType === "Clients") {
      loadClientsList({
        variables: {
          status: "Active",
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
          isClientRoleNeed: true,
          isClientPersonalEmailNeed: true,
        },
      });
    }
  }, [
    currentPage,
    pageSize,
    search,
    loadUserList,
    loadClientsList,
    watchUserType,
  ]);

  const parentRef = useRef<HTMLDivElement>(null);

  const kebabMenuClickHandler = (user: any) => {
    setShowModal(!showModal);
    setEditRole({
      userName: {
        id: user?.id,
        name: user?.personalInformation?.name,
      },
      defaultRole: user?.role?.id,
    });
  };

  const getDataModal = (user: any) => {
    return [
      {
        lable: "Name",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() => setUserId(user?.personalInformation?.id)}
                >
                  {user?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Email",
        value: [
          {
            content:
              user?.employeeDetail?.officialEmail ||
              user?.personalInformation?.personalEmail,
          },
        ],
      },
      {
        lable: "Role Name",
        value: [
          {
            content: user?.role?.roleName || "-",
          },
        ],
      },
    ];
  };
  return (
    <div
      className={
        "min-h-[699px] lg:mx-0 rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray"
      }
    >
      <div className="w-full flex items-center mt-5 gap-5">
        <div className="w-[300px]">
          <Select
            register={register}
            errors={errors}
            options={["Employees", "Clients"]}
            label="User type"
            name="userType"
            defaultSelectValue={"Employees"}
          />
        </div>
        <div className="mb-5">
          <SearchField
            register={register}
            name="employee"
            label={watchUserType === "Employees" ? "Employee" : "Client"}
            onChange={() => {
              setCurrentPage(1);
            }}
            setValue={setValue}
          />
        </div>
      </div>
      {loading || clientsLoading ? (
        <Loading className="min-h-[68vh]" />
      ) : (
        <Fragment>
          {users?.length > 0 ? (
            <div className="md:hidden mx-auto">
              <div className="w-full flex flex-col justify-center mt-5">
                {users?.map((user: any, index: number) => {
                  return (
                    <SMDataCard
                      key={user?.id}
                      title={`User ${index + 1}`}
                      showModal={showModal}
                      loading={loading}
                      dataModal={getDataModal(user)}
                      onPencilIconClick={() => {
                        kebabMenuClickHandler(user);
                      }}
                    />
                  );
                })}
              </div>
              <Pagination
                totalCount={
                  allEmployees?.getAllEmployees?.totalNumberOfItems || 0
                }
                noOfItem={users?.length}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          ) : (
            allEmployees &&
            users?.length === 0 && (
              <OopsNoData className="min-h-[68vh] md:hidden" />
            )
          )}
          <div className="hidden md:flex flex-col justify-between w-full min-h-[68vh]">
            <Table
              noOfItems={allEmployees && users?.length}
              tableHeadingsObj={tableHeadings?.map((name: string) => {
                return {
                  name,
                  center: name === "Name" || name === "Email" ? false : true,
                };
              })}
              currentPage={currentPage}
              isCenterlastHeading={true}
              ref={parentRef}
              loading={loading}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              totalCount={
                allEmployees?.getAllEmployees
                  ? allEmployees?.getAllEmployees?.totalNumberOfItems
                  : 0
              }
              className="py-5 min-h-[600px]"
            >
              {users?.map((user: any) => {
                let tableContent: ITableContent[] = [
                  {
                    value: (
                      <div className="group">
                        <p
                          onMouseEnter={() =>
                            setUserId(user?.personalInformation?.id)
                          }
                        >
                          {user?.personalInformation?.name}
                        </p>
                        <UserDetails userId={userId} />
                      </div>
                    ),
                    className: "w-[200px]",
                  },
                  {
                    value:
                      user?.employeeDetail?.officialEmail ||
                      user?.personalInformation?.personalEmail,
                    className: "w-[230px]",
                  },
                  { value: user?.role?.roleName, center: true },
                ];
                return (
                  <TableContent
                    id={user?.id}
                    key={user?.id}
                    tableContent={tableContent}
                    parentRef={parentRef}
                    onPencilIconClick={() => {
                      setShowModal(!showModal);
                      setEditRole({
                        userName: {
                          id: user?.id,
                          name: user?.personalInformation?.name,
                        },
                        defaultRole: user?.role?.id,
                      });
                    }}
                    editAccess={updateAccess}
                  />
                );
              })}
            </Table>
          </div>
        </Fragment>
      )}
      {showModal ? (
        <UpdateRole
          editRole={editRole}
          setEditRole={setEditRole}
          setShowModal={setShowModal}
          refetchUser={refetchUser}
          refetchClients={refetchClients}
          watchUserType={watchUserType}
        />
      ) : null}
    </div>
  );
};

export default RoleAllocation;
