import { ProjectGroupBugFilterFormType } from "modules/Project/Pages/Projects/ProjectGroupBugs/types";

export const companyTypes = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership",
  "Private Limited",
  "Public Limited, One-Person Company",
  "Joint-Venture",
  "Non-Government",
  "Organization",
  "Cooperatives",
];

export const companyStatuses = [
  "Active",
  "Inactive",
  "Blocked",
  "Suspended",
  "Under Review",
  "Declined",
];

export const filterSubmitValues = (filter: ProjectGroupBugFilterFormType) => {
  return {
    title:
      filter?.title && filter?.title?.length > 0
        ? {
            inArray: filter?.title?.map((title) => title?.id?.toString()),
          }
        : undefined,
    description: filter?.description?.trim()
      ? {
          contains: filter?.description,
        }
      : undefined,
    platform:
      filter?.platform && filter?.platform?.length > 0
        ? {
            inArray: filter?.platform?.map((platform) => platform),
          }
        : undefined,
    priority:
      filter?.priority && filter?.priority?.length > 0
        ? {
            inArray: filter?.priority?.map((priority) => priority),
          }
        : undefined,
    source:
      filter?.source && filter?.source?.length > 0
        ? {
            inArray: filter?.source?.map((source) => source?.id?.toString()),
          }
        : undefined,
    status:
      filter?.status && filter?.status?.length > 0
        ? {
            inArray: filter?.status?.map((status) => status),
          }
        : undefined,
  };
};
