import { FC, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { HiAnnotation, HiCheck } from "react-icons/hi";

import { SingleActionPopup } from "components/forms";
import Tooltip from "components/UI/ToolTip";

interface IProps {
  date: any;
  setShowModal: Function;
  setApproveVerification: Function;
  setApproveSingleDate: Function;
  setDeclineSingleDate: Function;
  isTeamLead: boolean;
}
const DatesDetails: FC<IProps> = ({
  date,
  setApproveVerification,
  setShowModal,
  setApproveSingleDate,
  setDeclineSingleDate,
  isTeamLead,
}) => {
  const [showDeclineMsg, setShowDeclineMsg] = useState<boolean>(false);

  return (
    <div className="flex text-sm text-ironside-gray items-center">
      <span className="min-w-[80px]">{date?.leaveDate || ""}</span>
      &nbsp;&nbsp;-&nbsp;&nbsp;
      <span className="min-w-[100px]">
        {date.session === "2 Hours"
          ? `${date.session || ""} (${date?.startTime || ""} - ${
              date?.endTime || ""
            })`
          : date.session || ""}
      </span>
      <span
        className={`${
          date?.status === "Pending"
            ? "text-yellow-orange"
            : date?.status === "Approved"
            ? "text-dark-mint"
            : date?.status === "Declined"
            ? "text-brink-pink"
            : date?.status === "Cancelled"
            ? "text-valentine-red"
            : date?.status === "Withdrawn"
            ? "text-cornflower-blue"
            : ""
        } min-w-[80px] ml-2`}
      >
        {date?.status || ""}
      </span>
      {isTeamLead && (
        <>
          {date?.status === "Pending" && !date?.leaveType?.isSpecialLeave && (
            <div className="flex">
              <Tooltip render="Approve" arrow placement="top">
                <div>
                  <HiCheck
                    className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
                    onClick={() => {
                      setShowModal(false);
                      setApproveVerification(true);
                      setApproveSingleDate({
                        id: +date?.id,
                        status: "Approved",
                      });
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip render="Decline" arrow placement="top">
                <div>
                  <MdOutlineClose
                    className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer ml-2"
                    onClick={() => {
                      setShowModal(true);
                      setDeclineSingleDate({
                        id: +date?.id,
                        status: "Declined",
                      });
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          )}
          {date?.status === "Declined" ? (
            <Tooltip render="Decline Message" arrow placement="top">
              <span>
                <HiAnnotation
                  onClick={() => {
                    setShowDeclineMsg(true);
                  }}
                  className="w-5 h-5 cursor-pointer text-brown-rust/70"
                />
              </span>
            </Tooltip>
          ) : null}
        </>
      )}
      {showDeclineMsg ? (
        <SingleActionPopup
          header={"Decline Message"}
          onExecute={() => setShowDeclineMsg(false)}
          message={date?.declineMessage ? date?.declineMessage : ""}
        />
      ) : null}
    </div>
  );
};

export default DatesDetails;
