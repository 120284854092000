import { HiArrowNarrowLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import Loading from "components/Loader/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import SMCardContent from "components/App/Card/SMCard/SMCardContent";
import { Filled, Outlined } from "components/forms";

import { Base64toObject } from "global/helpers/FileConverter";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import DatesDetails from "modules/Leave/Pages/Requests/MyRequest/DatesDetails";
import { FILTER_MY_LEAVE_REQUESTS } from "modules/Leave/services/queries";
import { WITHDRAW_APPLY_LEAVE } from "modules/Leave/services/mutations";
import { ILeaveRequestDates } from "global/types/type";

const ViewPage = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const LeaveResources = allowedResourcesList?.allowedResources || [];
  const updateAccess = LeaveResources?.includes("UpdateApplyLeave");

  const location = useLocation();

  const { loading, data: applyLeave } = useQuery(FILTER_MY_LEAVE_REQUESTS, {
    variables: {
      id: +location?.pathname?.split("/")[
        location?.pathname?.split("/")?.length - 1
      ],
    },
  });

  const [inProgress, setInProgress] = useState(false);
  const [withdrawVerification, setWithdrawVerification] =
    useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [withdrawSingleDate, setWithdrawSingleDate] = useState<{
    id: number | undefined;
    status: string | undefined;
  }>();

  const viewPageDetails =
    applyLeave?.filterMyLeaveRequests?.dataCollection &&
    applyLeave?.filterMyLeaveRequests?.dataCollection[0];

  const [withdrawApplyLeave] = useMutation(WITHDRAW_APPLY_LEAVE, {
    refetchQueries: [FILTER_MY_LEAVE_REQUESTS],
  });

  const navigate = useNavigate();
  const leaveStatuses = viewPageDetails?.leaveRequestDates?.map(
    (date: any) => date?.status
  );

  const flattenConsentRequests =
    viewPageDetails?.leaveRequestDates
      ?.map((leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents)
      ?.flat() || [];

  const consentStatusses = flattenConsentRequests?.map(
    (consent: { status: string }) => consent?.status
  );

  const hasPending = leaveStatuses?.includes("Pending");
  const showEdit =
    flattenConsentRequests?.length > 0
      ? consentStatusses?.every((status: string) => status === "Pending")
        ? true
        : false
      : hasPending
      ? true
      : false;

  const hasApproved = leaveStatuses?.includes("Approved");
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [modalTitle, setModalTitle] = useState("");

  const modalTitleHandler = () => {
    const sessions = viewPageDetails?.leaveRequestDates?.map(
      (leave: { session: string }) => leave?.session
    );

    const isAllSessionArePermissions = sessions?.every(
      (session: string) => session === "2 Hours"
    );

    if (isAllSessionArePermissions) {
      setModalTitle("Permission");
    } else {
      setModalTitle("Leave Request");
    }
  };

  const withdrawHandler = (id: number) => {
    const nonExpiredDates = dates?.filter((date: { leaveDate: string }) => {
      return new Date(date?.leaveDate?.replaceAll("-", " ")) >= today;
    });

    if (!inProgress) {
      withdrawApplyLeave({
        variables: {
          id: +id,
          leaveRequestDates: withdrawSingleDate?.id
            ? [withdrawSingleDate]
            : nonExpiredDates?.map((date: { id: number }) => {
                return {
                  id: +date?.id,
                  status: "Withdrawn",
                };
              }),
        },
      })
        .then((response) => {
          setWithdrawSingleDate({
            id: undefined,
            status: undefined,
          });
          setWithdrawVerification(false);
          setDeleteVerification(false);
          setInProgress(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your request has been withdrawn successfully.",
            },
          ]);
          const leaveDates =
            response?.data?.updateApplyLeave?.leaveRequestDates;
          if (
            leaveDates?.length < 1 ||
            leaveDates?.every(
              (leaveRequest: { status: string }) =>
                leaveRequest?.status === "Withdrawn"
            )
          ) {
            navigate("/leaves/requests/my-requests");
          }
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const createdAt = viewPageDetails?.createdAt || (
    <span className="text-gray-400">{"Eg. DD-MMM-YYYY"}</span>
  );
  const updatedAt = viewPageDetails?.updatedAt || createdAt;

  const sortFunction = (a: any, b: any) => {
    const dateA = new Date(a?.leaveDate)?.getTime();
    const dateB = new Date(b?.leaveDate)?.getTime();
    return dateA > dateB ? 1 : -1;
  };

  let dates: any = viewPageDetails?.leaveRequestDates;
  dates = dates?.slice()?.sort(sortFunction);

  const areAllLeavesSame = (arr: any) => {
    if (dates && dates?.length > 0 && dates[0]?.leaveType?.isSpecialLeave) {
      return false;
    }

    if (dates && dates?.length > 0) {
      for (let i = 1; i < arr?.length; i++) {
        if (arr[i]?.leaveType?.id !== dates[0]?.leaveType?.id) {
          return false;
        }
      }
    }

    return true;
  };

  const isLeaveNotExpired = dates
    ?.map((date: { leaveDate: string }) =>
      date?.leaveDate?.replaceAll("-", " ")
    )
    .some((date: string) => new Date(date) >= today);

  const findPendingAndApprovedDates = (dateArray: any[] | []) => {
    const pendingStatusDates =
      dateArray?.filter(
        (dateStatus: { status: string }) => dateStatus?.status === "Pending"
      ) || [];

    const approvedStatusDates =
      dateArray?.filter(
        (dateStatus: { status: string }) => dateStatus?.status === "Approved"
      ) || [];

    return { pendingStatusDates, approvedStatusDates };
  };

  const pendingAndApprovedDates = findPendingAndApprovedDates(dates);

  const withdrawBtnValidation =
    pendingAndApprovedDates?.approvedStatusDates?.length > 0;

  let values: {
    lable: string;
    hidden?: boolean | undefined;
    value: {
      content: string | Element;
      profileDetails?:
        | { profileImage?: string | undefined; name: string; email: string }
        | undefined;
      color?: string | undefined;
    }[];
  }[] =
    dates?.map((date: any, index: number) => {
      return {
        lable: `${
          !date?.leaveType?.isSpecialLeave
            ? "Date"
            : index === 0
            ? "From"
            : "To"
        }`,
        value: [
          {
            content: (
              <div>
                <DatesDetails
                  leaveRequestId={viewPageDetails?.id}
                  date={date}
                  reason={viewPageDetails?.reason}
                  leaveRequestAttachments={
                    viewPageDetails?.leaveRequestAttachments
                  }
                  setWithdrawVerification={setWithdrawVerification}
                  setWithdrawSingleDate={setWithdrawSingleDate}
                  deleteVerification={deleteVerification}
                  setDeleteVerification={setDeleteVerification}
                  modalTitle={modalTitle}
                  setModalTitle={setModalTitle}
                  requestedToUser={viewPageDetails?.requestedToUser}
                  showLeaveType={areAllLeavesSame(dates) ? false : true}
                />
              </div>
            ),
          },
        ],
      };
    }) || [];

  const getDataModal = () => [
    {
      lable: "ID",
      value: [
        {
          content: viewPageDetails?.id,
        },
      ],
    },
    {
      lable: "Created At",
      value: [
        {
          content: createdAt,
        },
      ],
    },
    {
      lable: "Updated At",
      value: [
        {
          content: updatedAt,
        },
      ],
    },
    {
      lable: "Type",
      hidden: areAllLeavesSame(dates) ? undefined : true,
      value: [
        {
          content: dates && dates?.length > 0 ? dates[0]?.leaveType?.name : "",
        },
      ],
    },
    ...values,
    {
      lable: "Reason",
      value: [
        {
          content: viewPageDetails?.reason,
        },
      ],
    },
    {
      lable: "Supporting Documents",
      hidden:
        viewPageDetails?.leaveRequestAttachments.length !== 0
          ? undefined
          : true,
      value: [
        {
          content: (
            <div className="flex">
              {applyLeave?.filterMyLeaveRequests?.dataCollection[0]?.leaveRequestAttachments?.map(
                (files: any, index: number) => {
                  return (
                    <div key={index} className="space-x-2">
                      <a
                        href={Base64toObject(files.filePath)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={filePdf} alt="PDF" className="w-[40px]" />
                        <p className="invisible overflow-hidden max-w-[40px]">
                          Helper text
                        </p>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Fragment>
      {loading ? (
        <Loading className="min-h-[68vh]" />
      ) : (
        <div className="border border-hit-gray rounded-[4px] mt-[20px] px-5 py-4 min-h-[68vh]">
          <SMCardContent dataModel={getDataModal()} />
          {updateAccess ? (
            viewPageDetails?.status !== "Cancelled" &&
            viewPageDetails?.status !== "Declined" ? (
              <div className="flex flex-wrap gap-4 pt-5 justify-center items-center">
                {updateAccess && hasPending ? (
                  <div>
                    {viewPageDetails?.status !== "Approved" &&
                    viewPageDetails?.status !== "Partially Approved" &&
                    !viewPageDetails?.leaveRequestDates[0]?.leaveType
                      ?.isSpecialLeave &&
                    showEdit ? (
                      <Outlined
                        buttonName="Edit"
                        onClick={() => {
                          navigate(
                            viewPageDetails &&
                              viewPageDetails?.leaveRequestDates[0]?.leaveType
                                ?.isSpecialLeave
                              ? "/leaves/apply/special"
                              : viewPageDetails?.leaveRequestDates[0]?.leaveType
                                  ?.isRestrictedHolidayLeave
                              ? "/leaves/apply/restricted"
                              : "/leaves/apply/regular",
                            {
                              state: {
                                id: viewPageDetails?.id,
                                dates:
                                  pendingAndApprovedDates?.pendingStatusDates,
                                reason: viewPageDetails?.reason,
                                leaveRequestAttachments:
                                  viewPageDetails?.leaveRequestAttachments,
                                isEditing: true,
                              },
                            }
                          );
                        }}
                      />
                    ) : null}
                  </div>
                ) : null}
                {withdrawBtnValidation && hasApproved && isLeaveNotExpired ? (
                  <Filled
                    onClick={() => {
                      setWithdrawVerification(true);
                      modalTitleHandler();
                    }}
                    buttonName={"Withdraw"}
                    buttonType={"submit"}
                    loading={inProgress}
                  />
                ) : (
                  hasPending && (
                    <Filled
                      onClick={() => {
                        setDeleteVerification(true);
                        modalTitleHandler();
                      }}
                      buttonName={"Delete"}
                      buttonType={"submit"}
                    />
                  )
                )}
              </div>
            ) : null
          ) : null}
          {withdrawVerification && (
            <ConfirmModal
              header={modalTitle}
              keyValue="withdraw"
              onCancel={() => {
                setInProgress(false);
                setWithdrawVerification(false);
                setWithdrawSingleDate({
                  id: undefined,
                  status: undefined,
                });
              }}
              onXIcon={() => {
                setInProgress(false);
                setWithdrawVerification(false);
                setWithdrawSingleDate({
                  id: undefined,
                  status: undefined,
                });
              }}
              onExecute={() => {
                withdrawHandler(viewPageDetails?.id);
              }}
              loading={inProgress}
            />
          )}
        </div>
      )}
      {deleteVerification && (
        <ConfirmModal
          header={modalTitle}
          keyValue="Delete"
          onCancel={() => {
            setDeleteVerification(false);
            setModalTitle("");
          }}
          onXIcon={() => {
            setDeleteVerification(false);
            setModalTitle("");
          }}
          onExecute={() => withdrawHandler(viewPageDetails?.id)}
          loading={inProgress}
        />
      )}
      <div className="flex justify-center items-center">
        <HiArrowNarrowLeft
          className="w-6 h-6 rounded-full shadow-[0px_0px_25px_5px_#AEAEAE29] text-cornflower-blue cursor-pointer"
          onClick={() => {
            setInProgress(false);
            navigate("/leaves/requests/my-requests");
          }}
        />
      </div>
    </Fragment>
  );
};

export default ViewPage;
