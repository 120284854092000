export const removeDuplicates = (inputArray: any) =>
  inputArray?.filter(
    (item: string, index: number) => inputArray?.indexOf(item) === index
  );

export const removeDuplicateObject = (array: any[], key: string) => {

  const filteredArray = array?.filter(notEmpty)

  if (filteredArray?.length > 0 && key) {
    return array.reduce((arr, item) => {
      const removed =
        key && arr?.filter((value: any) => value[key] !== item[key]);
      return [...removed, item];
    }, []);
    
  } else {
    return array;
  }
};

export const getArrayObjectDifference = (
  array1: any[] = [],
  array2: any[] = []
) => {
  return array1?.filter((object1) => {
    return !array2?.some((object2) => {
      return object1.id === object2.id;
    });
  });
};

export const groupBy = (objectArray: any, property: any) => {
  return objectArray?.reduce((value: any, obj: any) => {
    const key = obj[property];
    if (!value[key]) {
      value[key] = [];
    }
    value[key].push(obj);
    return value;
  }, {});
};
type Grouping<T> = { [key: string]: T[] };

export const groupByTypeScript = <T>(
  collection: T[],
  iteratee: keyof T | ((value: T) => string)
): Grouping<T> => {
  const result: Grouping<T> = {};

  for (const value of collection) {
    const key =
      typeof iteratee === "function" ? iteratee(value) : value[iteratee];
    if (!result[key as string]) {
      result[key as string] = [];
    }
    result[key as string].push(value);
  }

  return result;
};

export const findArrayValueType = <
  T extends
    | boolean
    | Function
    | number
    | string
    | undefined
    | object
    | bigint
    | symbol
>(
  value: any,
  type:
    | "boolean"
    | "function"
    | "number"
    | "string"
    | "undefined"
    | "object"
    | "bigint"
    | "symbol"
): value is T[] => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((item) => typeof item === type)
  );
};

export const removeDuplicatesByKey = <
  T extends { id: number | string },
  K extends keyof T
>(
  inputArray: T[],
  key: K
): T[] => {
  const arr = new Set();

  const array =
    inputArray?.filter((value: { id: number | string }) => value) || [];

  return array?.filter((item) => {
    const keyValue = item[key];
    return arr.has(keyValue) ? false : arr?.add(keyValue);
  });
};

export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined && value !== "";
};
