import { FC } from "react";
import { useLocation } from "react-router-dom";

import TabLink from "components/App/TabLink/TabLink";

import Projects from "modules/Project/Pages/Projects";

interface IProps {
	allowedResources: string[];
}

const MyProjectAllProjectRouteGroupBugTasksRouteControl: FC<IProps> = ({
	allowedResources,
}) => {
	const { pathname } = useLocation();
	const projectType = pathname?.split("/")[2];

	const tabLinks = [
		{
			name: "My projects",
			link: "/projects/projects/my-projects",
			allowedResourceIds: ["MyProject"],
		},
		{
			name: "All projects",
			link: "/projects/projects/all-projects",
			allowedResourceIds: ["AllProject"],
		},
		{
			name: "Trash",
			link: "/projects/projects/trash",
			allowedResourceIds: ["AllProject"],
		},
	];

	const routes = [
		{
			path: "my-projects",
			element: (
				<Projects
					allowedResources={allowedResources}
					isMyProject={projectType === "my-projects" ? true : false}
				/>
			),
		},
		{
			path: "all-projects",
			element: (
				<Projects allowedResources={allowedResources} isMyProject={false} />
			),
		},
		{
			path: "trash",
			element: (
				<Projects allowedResources={allowedResources} isMyProject={false} />
			),
		},
	];

	return (
		<div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh - 22vh)] bg-white">
			<TabLink tabLinks={tabLinks} routes={routes} />
		</div>
	);
};

export default MyProjectAllProjectRouteGroupBugTasksRouteControl;
