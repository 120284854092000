import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

const app = firebase?.initializeApp(firebaseConfig);

export const database = getDatabase(app);

const messaging = firebase.messaging();

const publicKey = `${process.env.REACT_APP_NOTIFICATION_PUBLIC_KEY}`;

export const getToken = async (setTokenFound: any) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    /*
    console.error("An error occurred while retrieving token. ", error);
    
    This is commented to hide the error from users when they don't grant access
    to notification. Uncomment this while debugging.

    console.error("An error occurred while retrieving token. ", error);

    */
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
