import { TypedDocumentNode, gql } from "@apollo/client";

import { PROJECT_GROUP_TASK_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroupTasks/services/fragments";
import {
	CreateProjectGroupTaskArgs,
	CreateProjectGroupTaskReturnType,
	DeleteProjectGroupTaskArgs,
	DeleteProjectGroupTaskReturnType,
	UpdateProjectGroupTaskArgs,
	UpdateProjectGroupTaskReturnType,
} from "modules/Project/Pages/Projects/ProjectGroupTasks/types";

export const CREATE_PROJECT_GROUP_TASK: TypedDocumentNode<
	CreateProjectGroupTaskReturnType,
	CreateProjectGroupTaskArgs
> = gql`
  ${PROJECT_GROUP_TASK_FRAGMENT}
  mutation createProjectGroupTask(
    $projectGroupTaskCreateInput: ProjectGroupTaskCreateInput
  ) {
    createProjectGroupTask(
      projectGroupTaskCreateInput: $projectGroupTaskCreateInput
    ) {
      ...projectGroupTaskFields
    }
  }
`;

export const UPDATE_PROJECT_GROUP_TASK: TypedDocumentNode<
	UpdateProjectGroupTaskReturnType,
	UpdateProjectGroupTaskArgs
> = gql`
  ${PROJECT_GROUP_TASK_FRAGMENT}
  mutation updateProjectGroupTask(
    $projectGroupTaskUpdateInput: ProjectGroupTaskUpdateInput
  ) {
    updateProjectGroupTask(
      projectGroupTaskUpdateInput: $projectGroupTaskUpdateInput
    ) {
      ...projectGroupTaskFields
    }
  }
`;

export const DELETE_PROJECT_GROUP_TASK: TypedDocumentNode<
	DeleteProjectGroupTaskReturnType,
	DeleteProjectGroupTaskArgs
> = gql`
  mutation deleteProjectGroupTask($id: Int!) {
    deleteProjectGroupTask(id: $id)
  }
`;
