import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Filled,
  Outlined,
  Select,
  Text,
  TextArea,
  ToggleSwitch,
} from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import { findAllowedACLIds } from "global/helpers/FindACLIds";

import { CREATE_ROLE, UPDATE_ROLE } from "modules/Settings/services/mutations";
import {
  ACL,
  GET_ALLOWED_TREE_RESOURCES,
} from "modules/Settings/services/queries";
import Loading from "components/Loader/Loading";

interface RoleType {
  id: number;
  roleName: string;
  description: string;
  isActive: boolean;
  allowedResourceIds: string[];
  roleResources: string;
}

interface childNodesTypes {
  id: string;
  title: string;
  isAllowed: boolean;
  childNodes: childNodesTypes[] | [];
}

const EditRole = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [inProgress, setInProgress] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<RoleType>();

  const [createRole] = useMutation(CREATE_ROLE);
  const [updateRole] = useMutation(UPDATE_ROLE);
  const { data: aclTree, loading: aclTreeLoader } = useQuery(ACL, {
    fetchPolicy: "no-cache",
  });

  const [
    loadroleItemQuery,
    {
      loading: roleItemQueryLoader,
      data: roleItemQuery,
      refetch: refetchRoleItemQuery,
      loading,
    },
  ] = useLazyQuery(GET_ALLOWED_TREE_RESOURCES, {
    fetchPolicy: "no-cache",
  });

  const resourcesAccessValue = watch("roleResources");

  let allIds: string[] = [];

  const findIds = (treeData: childNodesTypes[]) => {
    if (treeData?.length === 0) return null;
    treeData?.map((treeData) => {
      allIds.push(treeData?.id);
      if (treeData?.childNodes?.length > 0) {
        findIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  findIds(aclTree?.aclTree?.resourceIds?.resourceId || []);

  const createHandler = (data: RoleType) => {
    if (!inProgress) {
      createRole({
        variables: {
          roleName: data.roleName,
          description: data.description,
          isActive: data.isActive,
          allowedResourceIds: resourcesAccessValue === "All" && allIds,
        },
      })
        .then((response) => {
          const roleName = response?.data?.createRole?.roleName;
          setInProgress(false);
          navigate("/settings/role");
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                roleName ? `${roleName} role` : "Role"
              } has been created successfully.`,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  let allowedResourceIds = findAllowedACLIds(
    roleItemQuery?.roles?.dataCollection[0]?.allowedResourceIds?.resourceId ||
      []
  );

  let checkIsAllOrNor =
    allowedResourceIds?.sort()?.toString() === allIds?.sort()?.toString();

  useEffect(() => {
    if (checkIsAllOrNor) {
      setValue("roleResources", "All");
    } else {
      setValue("roleResources", "custom");
    }
    if (!state) {
      setValue("roleResources", "");
    }
  }, [setValue, state, checkIsAllOrNor]);

  const updateHandler = (data: RoleType) => {
    if (!inProgress) {
      updateRole({
        variables: {
          id: +state?.id,
          roleName: data.roleName ? data.roleName : undefined,
          description: data.description,
          isActive: data.isActive,
          allowedResourceIds: resourcesAccessValue === "All" && allIds,
        },
      })
        .then((response) => {
          const roleName = state?.roleName;
          setInProgress(false);
          navigate("/settings/role");
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                roleName ? `${roleName} role` : "Role"
              } has been updated successfully.`,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  useEffect(() => {
    if (state?.id) {
      loadroleItemQuery({
        variables: {
          id: +state?.id ? +state?.id : 0,
        },
      });
    }
  }, [state, loadroleItemQuery, refetchRoleItemQuery]);

  return (
    <div className="bg-white min-h-[760px]">
      <div className="md:flex md:justify-center flex-col">
        <div>
          {aclTreeLoader && roleItemQueryLoader ? (
            <Loading className="min-h-[70vh]" />
          ) : (
            <form
              onSubmit={
                state?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
              className="py-[45px] w-full md:flex md:justify-center overflow-x-auto"
            >
              <div className="w-[250px] md:w-[325px] mx-auto space-y-5">
                <Text
                  register={register}
                  name="roleName"
                  errors={errors}
                  label="Role Name *"
                  defaultText={state?.roleName}
                  required={true}
                  maximumLength={50}
                />
                <TextArea
                  register={register}
                  name="description"
                  errors={errors}
                  label="Description"
                  defaultText={state?.description}
                  maximumLength={50}
                />
                <Select
                  register={register}
                  errors={errors}
                  label={"Resources Access *"}
                  name={"roleResources"}
                  options={["All", "custom"]}
                  required={state?.id ? false : true}
                />
                <div className="flex justify-between pb-[10px]">
                  <p className="text-ironside-gray">Is Active</p>
                  <ToggleSwitch
                    register={register}
                    name="isActive"
                    label="Is Active"
                    defaultValue={state?.isActive}
                  />
                </div>
                <div className="button-div">
                  <Outlined
                    onClick={() => {
                      setInProgress(false);
                      reset();
                      navigate("/settings/role");
                    }}
                    buttonName={"Cancel"}
                  />
                  {resourcesAccessValue === "custom" ? (
                    <button
                      type="button"
                      className="w-[110px] lg:w-[120px] h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue rounded hover:shadow-[0_1px_2px_0_#366AD9]"
                      onClick={() => {
                        if (getValues("roleName") === "") {
                          setFocus("roleName");
                        } else {
                          if (state?.id) {
                            navigate("role-resources", {
                              state: {
                                id: state?.id,
                                roleName: getValues("roleName"),
                                description: getValues("description"),
                                aclTree:
                                  roleItemQuery?.roles?.dataCollection[0] &&
                                  roleItemQuery?.roles?.dataCollection[0]
                                    ?.allowedResourceIds?.resourceId,
                                isActive: getValues("isActive"),
                                loading: loading,
                              },
                            });
                          } else {
                            navigate("role-resources", {
                              state: {
                                roleName: getValues("roleName"),
                                description: getValues("description"),
                                aclTree:
                                  aclTree?.aclTree?.resourceIds?.resourceId ||
                                  [],
                                isActive: getValues("isActive"),
                              },
                            });
                          }
                        }
                      }}
                    >
                      Next
                    </button>
                  ) : (
                    <Filled
                      buttonName={
                        inProgress ? (
                          <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                        ) : state?.id ? (
                          "update"
                        ) : (
                          "Add"
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditRole;
