import { FC } from "react";
import { RxAvatar } from "react-icons/rx";

import { getFileExtension } from "global/helpers/getFileExtension";

interface IProps {
  profile: {
    personalInfromation: { id: number; name: string };
    userProfileImages: { documents: { file: string }[]; type: string }[];
  } | null;
}

const UserProfile: FC<IProps> = ({ profile }) => {
  if (!profile) {
    return null;
  }

  const { personalInfromation, userProfileImages } = profile;

  const filterProfileImage =
    userProfileImages?.filter(
      (user: { type: string }) => user?.type === "Profile"
    ) || [];

  const file =
    filterProfileImage?.length > 0 &&
    filterProfileImage[0]?.documents?.length > 0
      ? filterProfileImage[0]?.documents[0]?.file
      : "";
  const userName = personalInfromation?.name;

  return file?.length > 100 ? (
    <img
      className="max-w-[32px] max-h-[32px] rounded-[50%] object-fit"
      src={`data:image/${
        getFileExtension(file) === "svg" ? "svg+xml" : getFileExtension(file)
      };base64,${file}`}
      alt="No File"
    />
  ) : userName ? (
    <div className="w-8 h-8 rounded-[50%] bg-cornflower-blue text-white text-[20px] text-center">
      {userName[0]}
    </div>
  ) : (
    <RxAvatar className="w-[40px] h-[40px] bg-gray-400 text-white rounded-[50%] object-fit" />
  );
};

export default UserProfile;
