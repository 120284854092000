import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYEES = gql`
  query allEmployeesDetails($limit: Int, $page: Int, $search: String) {
    getAllEmployees(limit: $limit, page: $page, search: $search) {
      dataCollection {
        id
        personalInformation {
          name
          personalEmail
          actualDateOfBirth
          officialDateOfBirth
          gender
        }
        employeeDetail {
          officialEmail
        }
        role {
          id
          roleName
        }
        userProfileImages {
          documents {
            file
          }
          type
        }
      }
      totalNumberOfItems
    }
  }
`;

export const DEPARTMENTS = gql`
  query departmentsDetails($id: Int, $name: String, $limit: Int, $page: Int) {
    employeeDepartments(id: $id, name: $name, limit: $limit, page: $page) {
      dataCollection {
        id
        name
      }
      totalNumberOfItems
    }
  }
`;

export const EMPLOYEE_DESIGNATIONS = gql`
  query employeeDesignationsDetails(
    $id: Int
    $name: String
    $limit: Int
    $page: Int
  ) {
    employeeDesignations(id: $id, name: $name, limit: $limit, page: $page) {
      dataCollection {
        id
        name
        description
        employeeDepartment {
          id
          name
        }
        isUnderHiring
        isActive
        maxExpYears
        minExpYears
      }
      totalNumberOfItems
    }
  }
`;
