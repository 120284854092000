import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import { ToggleSwitchProps } from "components/forms/UpdatedFormAgry/ToggleSwitch/types";

const ToggleSwitch = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  defaultValue = false as PathValue<TFieldValues, Name>,
  disabled,
  shouldUnregister,
  onChange,
  loading,
  required,
  label,
  classForLabel,
  className,
}: ToggleSwitchProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends boolean | null | undefined
    ? {
        helperType?: never;
        invalidType?: never;
      }
    : {
        helperType?: undefined;
        invalidType: "Type must be boolean";
      })) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      rules={{
        onChange: (e: any) => {
          if (onChange) {
            onChange(e.target?.value);
          }
        },
        shouldUnregister,
        required: required ? "This is required field." : false,
      }}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => {
        const errorMessage = error?.message;

        return label ? (
          <div
            className={twMerge(
              "flex justify-between items-center py-2",
              className ? className : "w-full"
            )}
          >
            <label
              htmlFor={loading ? undefined : name}
              className={`select-none truncate ${
                classForLabel ? classForLabel : "text-ironside-gray text-sm"
              } ${
                disabled
                  ? "cursor-not-allowed text-outline text-opacity-[0.38]"
                  : loading
                  ? "cursor-default"
                  : "cursor-pointer"
              }`}
            >
              {required ? `${label} *` : label}
            </label>

            {loading ? (
              <div className="min-h-[32px] min-w-[53px] flex justify-center items-center">
                <Loading smallLoading />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="relative inline-block w-[53px] h-[32px] min-w-[52px] align-middle select-none transition duration-200 ease-in"
              >
                <input
                  type="checkbox"
                  id={name}
                  className={`peer checked:right-0 checked:border-primary peer absolute block w-[30px] h-[30px] rounded-full bg-white border-4 appearance-none  ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                  } ${
                    errorMessage
                      ? "outline-error checked:outline-error border border-error"
                      : "outline-ironside-gray checked:outline-primary"
                  }`}
                  onChange={(e) => {
                    e.stopPropagation();
                    onChange(
                      e.target?.checked as PathValue<TFieldValues, Name>
                    );
                  }}
                  checked={value}
                  {...rest}
                  disabled={disabled}
                />

                <label
                  htmlFor={name}
                  className={`peer-checked:bg-primary  block overflow-hidden h-[30px] rounded-full bg-[#E1E1E1] ${
                    disabled ? "cursor-not-allowed " : "cursor-pointer"
                  } ${errorMessage ? "bg-error" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
          </div>
        ) : loading ? (
          <div className="min-h-[32px] min-w-[53px] flex justify-center items-center">
            <Loading smallLoading />
          </div>
        ) : (
          <div
            className={twMerge(
              "relative inline-block w-[53px] h-[32px] align-middle select-none transition duration-200 ease-in",
              className
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <input
              type="checkbox"
              id={name}
              className={`peer checked:right-0 checked:border-primary peer absolute block w-[30px] h-[30px] rounded-full bg-white border-4 appearance-none  ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } ${
                errorMessage
                  ? "outline-error checked:outline-error border border-error"
                  : "outline-ironside-gray checked:outline-primary"
              }`}
              onChange={(e) => {
                onChange(e.target?.checked as PathValue<TFieldValues, Name>);
              }}
              checked={value}
              {...rest}
              disabled={disabled}
            />
            <label
              htmlFor={name}
              className={`peer-checked:bg-primary  block overflow-hidden h-[30px] rounded-full bg-[#E1E1E1] ${
                disabled ? "cursor-not-allowed" : "cursor-pointer"
              } ${errorMessage ? "bg-error" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        );
      }}
    />
  );
};
export default ToggleSwitch;
