import { Modal, Outlined, Filled, Select } from "components/forms";
import { MdOutlineClose } from "react-icons/md";
import React from "react";
import { useForm } from "react-hook-form";

interface FormType {
  status: string;
}

const FilterPopup: React.FC<{
  setShowModal: Function;
  filterlist: string | undefined;
  setFilterList: Function;
  setCurrentPage: Function;
}> = ({ setShowModal, filterlist, setFilterList, setCurrentPage }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>();
  const filterHandler = (data: any) => {
    setFilterList(data?.status);
    if (data.status) {
      setShowModal(false);
      setCurrentPage(1);
    }
  };
  return (
    <Modal>
      <div className="w-[350px] lg:w-[420px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">Filter</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              reset();
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form className="space-y-1" onSubmit={handleSubmit(filterHandler)}>
          <div className="form-div pr-[18px] lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar ">
            <Select
              register={register}
              errors={errors}
              options={["Approved", "Declined", "WithDraw", "Pending"]}
              name="status"
              label="Status"
              required={filterlist ? false : true}
              defaultSelectValue={filterlist ? filterlist : "Pending"}
            />
          </div>
          <div className="w-full border-b border-hit-gray" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined
              onClick={() => {
                reset();

                setShowModal(false);
              }}
              buttonName={"Cancel"}
            />
            <Filled buttonName={"Apply"} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FilterPopup;
