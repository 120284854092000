export const removedUndefinedValuesFromObject = (data: any) => {
  if (typeof data !== "object") {
    return data;
  }

  return Object?.keys(data)?.reduce((accumulator, key) => {
    const isObject =
      !Array.isArray(data[key]) &&
      typeof data[key] === "object" &&
      data[key] !== null;

    const value: any = isObject
      ? removedUndefinedValuesFromObject(data[key])
      : data[key];
    const isEmptyObject = isObject && !Object.keys(value).length;

    if (
      value === undefined ||
      value === "" ||
      value === null ||
      isEmptyObject
    ) {
      return accumulator;
    }
    return Object.assign(accumulator, { [key]: value });
  }, {});
};

export const countNonEmptyKeys = (obj: object | null | undefined) => {
  if (!obj) {
    return 0;
  } else {
    let count = 0;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (
          value !== undefined &&
          value !== null &&
          (typeof value !== "string" || value.trim() !== "") && // Check for non-empty strings
          (Array.isArray(value) ? value.length > 0 : true) && // Check for non-empty arrays
          (typeof value !== "number" || value >= 0) // Check for non-zero numbers
        ) {
          count++;
        }
      }
    }
    return count;
  }
};

type RemoveKey<T, K extends keyof T> = {
  [P in Exclude<keyof T, K>]: T[P];
};

export const removeKey = <T extends object, K extends keyof T = keyof T>(
  obj: T,
  key: K
): T extends object ? RemoveKey<T, K> : null => {
  if (typeof obj === "object") {
    const { [key]: removedKey, ...rest } = obj;
    return rest && Object.entries(rest)?.length > 0
      ? (rest as T extends object ? RemoveKey<T, K> : null)
      : (null as T extends object ? RemoveKey<T, K> : null);
  } else {
    return null as T extends object ? RemoveKey<T, K> : null;
  }
};
