import { TypedDocumentNode, gql } from "@apollo/client";

import {
  CreateProjectBugGroupArgs,
  CreateProjectGroupBugReturnType,
  DeleteProjectGroupBugArgs,
  DeleteProjectGroupBugReturnType,
  UpdateProjectBugGroupArgs,
  UpdateProjectGroupBugReturnType,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";
import { PROJECT_GROUP_BUG_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroupBugs/services/fragments";

export const CREATE_PROJECT_GROUP_BUG: TypedDocumentNode<
  CreateProjectGroupBugReturnType,
  CreateProjectBugGroupArgs
> = gql`
  ${PROJECT_GROUP_BUG_FRAGMENT}
  mutation createProjectGroupBug($input: CreateProjectGroupBug) {
    createProjectGroupBug(input: $input) {
      ...projectGroupBugFields
    }
  }
`;

export const UPDATE_PROJECT_GROUP_BUG: TypedDocumentNode<
  UpdateProjectGroupBugReturnType,
  UpdateProjectBugGroupArgs
> = gql`
  ${PROJECT_GROUP_BUG_FRAGMENT}
  mutation updateProjectGroupBug($input: UpdateProjectGroupBug) {
    updateProjectGroupBug(input: $input) {
      ...projectGroupBugFields
    }
  }
`;

export const DELETE_PROJECT_GROUP_BUG: TypedDocumentNode<
  DeleteProjectGroupBugReturnType,
  DeleteProjectGroupBugArgs
> = gql`
  mutation deleteProjectGroupBug($id: Int!) {
    deleteProjectGroupBug(id: $id)
  }
`;
