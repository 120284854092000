import { FC, Fragment, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { Base64toObject } from "global/helpers/FileConverter";
import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { toastNotify } from "global/helpers/Cache";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";

import { IIdProof } from "modules/Employee/types/personal";

interface IProps {
  setEditID: Function;
  idProof: IIdProof;
  setShowModal: Function;
  setDeleteVerification: Function;
  setChangedInformation: Function;
  setShowUnderReview: Function;
  parentRef: any;
}

const IdProofDetails: FC<IProps> = ({
  setEditID,
  idProof,
  setShowModal,
  setDeleteVerification,
  setChangedInformation,
  setShowUnderReview,
  parentRef,
}) => {
  const idProofModeration = idProof?.moderation;
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  const { canUpdate, canDelete } = useGetAllowedResources("EmployeeIdProof");

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const kebabIconRef = useRef<HTMLDivElement>(null);

  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [Right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = () => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  };

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const parentEventRef = parentRef?.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    parentEventRef && parentEventRef?.addEventListener("scroll", getPosition);
    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [parentRef]);

  const moderationPopUpHandler = (moderationId: number | null) => {
    moderationId && setShowUnderReview(true);
    setChangedInformation([moderationId]);
  };

  return (
    <Fragment>
      <span
        className={`${
          idProofModeration?.idProofType && "bg-yellow-100  cursor-pointer"
        } px-2 py-3 truncate font-medium text-[14px] leading-[17px] lg:leading-[20px] text-left text-[#8E8E8F]`}
        onClick={() => {
          moderationPopUpHandler(idProofModeration?.idProofType);
        }}
      >
        {idProof?.idProofType}
      </span>
      <span
        className={`${
          idProofModeration?.idProofValue && "bg-yellow-100 cursor-pointer"
        } px-2 py-3 text-ironside-gray text-sm`}
        onClick={() => {
          moderationPopUpHandler(idProofModeration?.idProofValue);
        }}
      >
        {idProof?.idProofValue}
      </span>
      <span
        className={`${
          idProofModeration?.filePath && "bg-yellow-100"
        } text-[14px] px-2 py-3 leading-[17px] lg:leading-[20px] flex justify-center`}
      >
        {idProof?.documents && idProof?.documents[0]?.file?.length <= 100 ? (
          <span className="text-gray-400 md:ml-8 xl:ml-0">No File</span>
        ) : (
          <a
            href={
              idProof?.documents && Base64toObject(idProof?.documents[0]?.file)
            }
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <LinkIcon className="w-[14px] h-[14px] md:ml-8 xl:ml-0 cursor-pointer text-cornflower-blue" />
          </a>
        )}
      </span>
      <span className="relative px-2 mr-2 py-3 font-medium text-[14px] leading-[17px] lg:leading-[20px] flex justify-end items-center">
        <div ref={editRef} className="w-min ">
          {(canUpdate || canDelete) && (
            <div className="w-min" ref={kebabIconRef}>
              <KebabIcons
                onClick={() => {
                  setEditID(+idProof?.id);
                  setShowEditOption((prevState) => !prevState);
                }}
                className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                  showEditOption ? "bg-white-smoke" : null
                }`}
              />
            </div>
          )}
          {showEditOption && (
            <div
              className="fixed z-[80]"
              style={{
                top: `${top}px`,
                left: `${left}px`,
                right: `${Right}px`,
              }}
            >
              <KebabMenu
                onDelete={() => {
                  if (canDelete) {
                    setDeleteVerification(true);
                    setShowEditOption(false);
                  } else {
                    toastNotify(accessDeniedMessage);
                  }
                }}
                onEdit={() => {
                  if (canUpdate) {
                    setShowModal(true);
                    setShowEditOption(false);
                  } else {
                    toastNotify(accessDeniedMessage);
                  }
                }}
              />
            </div>
          )}
        </div>
      </span>
    </Fragment>
  );
};

export default IdProofDetails;
