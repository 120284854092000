const Modal: React.FC<{ children?: React.ReactNode; onClick?: any }> = ({
  children,
  onClick,
}) => {
  return (
    <div className="fixed inset-0 bg-transparent z-[55] flex justify-center items-center p-2">
      <div className="fixed inset-0 bg-black/60 -z-20" onClick={onClick} />
      {children}
    </div>
  );
};

export default Modal;
