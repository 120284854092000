import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";

import { IFilterLeaveRequestTypes } from "modules/Leave/types/leave";

interface IProps {
  myRequest: IFilterLeaveRequestTypes;
}

const TableDetailsGrid: FC<IProps> = ({ myRequest }) => {
  const navigate = useNavigate();

  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {myRequest?.id}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap text-center">
        {myRequest?.leaveRequestDates?.length > 1 ? (
          myRequest?.leaveRequestDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.leaveDate}
              </span>
            );
          })
        ) : myRequest?.leaveRequestDates?.length === 1 ? (
          <span className="p-3">
            {myRequest?.leaveRequestDates?.map((date: any) => date?.leaveDate)}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {myRequest?.leaveRequestDates?.length > 1 ? (
          myRequest?.leaveRequestDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.leaveType?.name}
              </span>
            );
          })
        ) : myRequest?.leaveRequestDates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {myRequest?.leaveRequestDates?.map(
              (date: any, index: number) => date?.leaveType?.name
            )}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {myRequest?.leaveRequestDates?.length > 1 ? (
          myRequest?.leaveRequestDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.session}
              </span>
            );
          })
        ) : myRequest?.leaveRequestDates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {myRequest?.leaveRequestDates?.map(
              (date: any, index: number) => date?.session
            )}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {myRequest?.leaveRequestDates?.length > 1 ? (
          myRequest?.leaveRequestDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                <div
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 rounded-[18px]`}
                >
                  {date?.status}
                </div>
              </span>
            );
          })
        ) : myRequest?.leaveRequestDates?.length === 1 ? (
          <span>
            {myRequest?.leaveRequestDates?.map((date: any, index: number) => {
              return (
                <span
                  key={index}
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 rounded-[18px] flex justify-center py-5 px-3`}
                >
                  {date?.status}
                </span>
              );
            })}
          </span>
        ) : (
          <span className="text-gray-400">Eg.Pending</span>
        )}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate whitespace-nowrap">
        <span
          className="grid place-content-center cursor-pointer hover:text-cornflower-blue hover:underline"
          onClick={() => {
            navigate(`${myRequest?.id}`, {
              state: {
                viewPageDetails: myRequest,
              },
            });
          }}
        >
          View
        </span>
      </td>
    </tr>
  );
};

export default TableDetailsGrid;
