import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import Table from "components/App/UpdatedAppComponents/Table/Table";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
	toastNotify,
	setBackNavigationURL,
	userContextData,
	setTitle,
	getAllowedAccess,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
	useCustomSearchParams,
	useEffectOnce,
	useSearch,
	useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import avatar from "global/assets/images/dummy-avatar.svg";
import { ISortOrder } from "global/types/type";
import { getFileExtension } from "global/helpers/getFileExtension";

import { GET_ALL_PROJECT_MEMBERS } from "modules/Project/services/queries";
import { DELETE_PROJECT_MEMBER } from "modules/Project/services/mutations";
import { IProjectMembers } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import EditMember from "modules/Project/Pages/Members/EditMember";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
	allowedResourcesForProject: string[];
	isManager: boolean;
	editProject: { id: number; name: string };
	projectGroup?: ProjectGroup | null;
}

const tableCommonHeadings = [
	{ name: "Photo" },
	{ name: "Name", APIName: "memberName" },
	{ name: "Role", APIName: "roleName" },
	{ name: "Status", APIName: "status" },
];

const Members: FC<Props> = ({
	allowedResourcesForProject,
	isManager,
	editProject,
	projectGroup,
}) => {
	const userDataList: any = useReactiveVar(userContextData);
	const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const canCreateOrUpdate =
		isManager ||
		allowedResourcesForProject?.includes("SaveProjectMember") ||
		isSuperAdmin;

	const canDelete =
		allowedResourcesForProject?.includes("DeleteProjectMember") || isSuperAdmin;

	const tableHeadings = canDelete
		? [...tableCommonHeadings, { name: "Action" }]
		: tableCommonHeadings;

	const [searchParams, setSearchParams] = useCustomSearchParams();
	const { pathname, search: searchQuery } = useLocation();
	const { id, projectType } = useParams();

	const { register, watch, control, handleSubmit, setValue, reset } =
		useForm<IProjectFilterForm>({
			defaultValues: {
				search: searchParams?.search ? searchParams?.search : "",
			},
		});

	const [deleteProjectMember, { loading: deleteProjectMemberLoading }] =
		useMutation(DELETE_PROJECT_MEMBER);

	useEffect(() => {
		if (pathname && searchQuery) {
			setBackNavigationURL(pathname + searchQuery);
		}
	}, [pathname, searchQuery]);
	const search = useSearch(watch("search"));

	useEffect(() => {
		if (editProject?.name) {
			setTitle(
				projectGroup?.id
					? [
							{
								name: "Project",
								link: "/projects/groups",
							},
							{ name: "Groups", link: "/projects/groups" },
							{
								name: projectGroup?.name || "",
								link: `/projects/${projectGroup?.id}/${projectType}`,
							},
							{
								name: `${editProject?.name}`,
								link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
							},
							{
								name: "Members",
								link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/members`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: `${editProject?.name}`,
								link: `/projects/projects/${projectType}/edit/${id}/general`,
							},
							{
								name: "Members",
								link: `projects/projects/${projectType}/edit/${id}/members`,
							},
					  ],
			);
		}
	}, [
		editProject?.name,
		projectGroup?.id,
		projectGroup?.name,
		projectType,
		id,
	]);

	const [currentPage, setCurrentPage] = useState<number>(
		!isNaN(+searchParams?.page) ? +searchParams?.page : 1,
	);
	const [pageSize, setPageSize] = useState<number>(
		!isNaN(+searchParams?.limit)
			? +searchParams?.limit
			: paginationDefaultValue,
	);
	const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

	useEffectOnce(() => {
		setSearchParams({
			...searchParams,
			page: `${currentPage}`,
			limit: `${pageSize}`,
		});
	});

	useUpdateEffect(() => {
		if (searchParams?.page && !isNaN(+searchParams?.page)) {
			setCurrentPage(+searchParams?.page);
		}
		if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
			setPageSize(+searchParams?.limit);
		}
		setValue("search", searchParams?.search || "");
	}, [searchParams?.page, searchParams?.limit, searchParams?.search]);

	const [
		fetchAllProjectMembers,
		{ data: getAllProjectMembers, loading, refetch: refetchProjectMembers },
	] = useLazyQuery(GET_ALL_PROJECT_MEMBERS, {
		fetchPolicy: "cache-and-network",
	});

	const projectsMembersList =
		(getAllProjectMembers?.projectMembers?.dataCollection?.length > 0 &&
			getAllProjectMembers?.projectMembers?.dataCollection) ||
		[];
	const totalCount =
		getAllProjectMembers?.projectMembers?.totalNumberOfItems || 0;

	const noOfItems = getAllProjectMembers && projectsMembersList?.length;

	useEffect(() => {
		fetchAllProjectMembers({
			variables: {
				filters: {
					projectId: Number(id),
				},
				orderBy: sortOrder
					? {
							field: sortOrder?.field,
							orderType: sortOrder?.orderType,
					  }
					: undefined,
				limit: pageSize,
				page: currentPage,
				search: search || undefined,
				type: canReadUserProfileImage ? "Profile" : undefined,
				isProfileImageNeeded: canReadUserProfileImage,
			},
		});
	}, [
		currentPage,
		fetchAllProjectMembers,
		pageSize,
		search,
		id,
		sortOrder,
		canReadUserProfileImage,
	]);

	const [editProjectMember, setEditProjectMember] =
		useState<IProjectMembers | null>(null);
	const [showModal, setShowModal] = useState<boolean>(false);

	const [filterValues, setFilterValue] = useState<object | null>(null);

	const tableRef = useRef<HTMLDivElement>(null);

	const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

	const deleteHandler = () => {
		if (editProjectMember?.id) {
			deleteProjectMember({
				variables: {
					id: +editProjectMember?.id,
				},
			})
				.then(() => {
					const projectMemberName =
						editProjectMember?.user?.personalInformation?.name;
					setDeleteVerification(false);
					refetchProjectMembers();
					toastNotify([
						{
							messageType: "success",
							message: `${
								projectMemberName ? `${projectMemberName}` : "Member"
							} has been removed from this project successfully`,
						},
					]);
				})
				.catch((error) =>
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]),
				);
		}
	};

	const onEdit = (projectMember: IProjectMembers) => {
		if (canCreateOrUpdate) {
			setEditProjectMember(projectMember);
			setShowModal(true);
		}
	};

	const onDelete = (projectMember: IProjectMembers) => {
		setEditProjectMember(projectMember);
		setDeleteVerification(true);
	};

	const addBtnHandler = () => {
		setShowModal(true);
		setEditProjectMember(null);
	};

	const getProfileImage = (
		projectMember: IProjectMembers,
		centerProfile: boolean,
	) => {
		const filterProfileImage =
			(projectMember?.user?.userProfileImages &&
				projectMember?.user?.userProfileImages?.length > 0 &&
				projectMember?.user?.userProfileImages?.filter(
					(userProfileImage: any) => userProfileImage.type === "Profile",
				)[0]) ||
			null;

		const profileImage: any =
			filterProfileImage &&
			filterProfileImage?.documents &&
			filterProfileImage?.documents?.length > 0 &&
			filterProfileImage?.documents[0]?.file;

		return profileImage?.length > 100 ? (
			<div className={`${centerProfile ? "flex justify-center" : ""}`}>
				<img
					className="w-9 h-9 rounded-[4px] object-fill"
					src={`data:image/${
						getFileExtension(profileImage) === "svg"
							? "svg+xml"
							: getFileExtension(profileImage)
					};base64,${profileImage}`}
					alt="No File"
				/>
			</div>
		) : null;
	};
	const getDataModal = (projectMember: IProjectMembers): IDataModal[] => [
		{
			label: "Photo",
			value: [
				{
					content: getProfileImage(projectMember, false) || (
						<div className="w-[42px] h-[42px] rounded-full bg-ironside-gray/40 flex justify-center items-center lg:ml-[34px]">
							<div className="w-[38px] h-[38px] rounded-full flex justify-center items-center lg:p-[1px] bg-white">
								<img
									src={avatar}
									alt={"Avatar"}
									className="w-[35px] h-[35px] rounded-full"
								/>
							</div>
						</div>
					),
				},
			],
		},
		{
			label: "Name",
			value: [
				{
					content: Capitalize(projectMember?.user?.personalInformation?.name),
				},
			],
		},
		{
			label: "Role",
			value: [
				{
					content: projectMember?.projectRole?.name,
				},
			],
		},
		{
			label: "Status",
			value: [
				{
					content: projectMember?.status,
				},
			],
		},
	];
	const getTableContent = (projectMember: IProjectMembers): ITableContent[] => [
		{
			value: getProfileImage(projectMember, false) || (
				<div className="w-[42px] h-[42px] rounded-full bg-ironside-gray/40 flex justify-center items-center">
					<div className="w-[38px] h-[38px] rounded-full flex justify-center items-center lg:p-[1px] bg-white">
						<img
							src={avatar}
							alt={"Avatar"}
							className="w-[35px] h-[35px] rounded-full"
						/>
					</div>
				</div>
			),
		},
		{
			value: projectMember?.user?.personalInformation?.name,
		},
		{
			value: projectMember?.projectRole?.name,
		},
		{
			value: projectMember?.status,
		},
		{
			value: (
				<div className="flex justify-center">
					<ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
						<BiTrash
							className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
							onClick={(e) => {
								e.stopPropagation();
								onDelete(projectMember);
							}}
						/>
					</ToolTip>
				</div>
			),
			hideContent: !canDelete,
		},
	];

	const confirmModalCloseHandler = () => {
		setDeleteVerification(false);
	};
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const clearFilterHandler = () => {
		reset();
		setFilterValue(null);
	};
	const filterBtnShowHandler = () => {
		setShowFilter(false);
	};

	const filterFormOption: TFilterOption<IProjectFilterForm> = [];

	const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
		setFilterValue(getFilterFormValues(data, filterFormOption));
		filterBtnShowHandler();
	};
	const noDataFoundMessage = (
		<p
			children={notFoundMessage}
			className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
		/>
	);

	return (
		<div className="mt-5">
			<div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
				<Filter
					control={control}
					handleSubmit={handleSubmit}
					watch={watch}
					clearFilterHandler={clearFilterHandler}
					searchInputName="search"
					filterName="filter"
					options={filterFormOption}
					register={register}
					submitHandler={submitHandler}
					filterBtnShowHandler={filterBtnShowHandler}
					showFilter={showFilter}
					hideFilterBtn
					setValue={setValue}
					isCloseIconNeeded={true}
				/>

				{search?.length === 0 && noOfItems === 0
					? null
					: (canCreateOrUpdate && (
							<Filled buttonName="Add" onClick={addBtnHandler} />
					  )) ||
					  null}
			</div>

			{!loading ? (
				<Fragment>
					{noOfItems !== 0 ? (
						<Fragment>
							<div className="md:hidden mx-auto">
								<div className="min-h-[80vh] space-y-3">
									{projectsMembersList?.map(
										(projectMember: IProjectMembers) => {
											return (
												<DataCard
													key={projectMember?.id}
													dataModal={getDataModal(projectMember)}
													classForULlist={`${
														canCreateOrUpdate
															? "cursor-pointer"
															: "cursor-default"
													}`}
													cardClick={() => {
														canCreateOrUpdate && onEdit(projectMember);
													}}
													classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
													otherOption={
														(canDelete && (
															<div className="flex justify-center">
																<ToolTip
																	render={() => "Delete"}
																	arrow
																	className={
																		"text-center bg-[#616161] text-white"
																	}
																>
																	<BiTrash
																		className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
																		onClick={(e) => {
																			e.stopPropagation();
																			onDelete(projectMember);
																		}}
																	/>
																</ToolTip>
															</div>
														)) ||
														undefined
													}
												/>
											);
										},
									)}
								</div>
								<Pagination
									totalCount={totalCount}
									currentPage={currentPage}
									onPageChange={(page: number) => {
										setSearchParams({
											...searchParams,
											page: `${page}`,
										});
										setCurrentPage(page);
									}}
									onRowPerPageChange={(rowPerPage) => {
										setSearchParams({
											...searchParams,
											limit: `${rowPerPage}`,
										});
									}}
									pageSize={pageSize}
									setPageSize={setPageSize}
									siblingCount={1}
									noOfItem={noOfItems}
								/>
							</div>

							<div className="hidden md:block">
								<Table
									loading={loading}
									className="min-h-[80vh]"
									onPagination={{
										totalCount,
										currentPage: currentPage,
										setCurrentPage: setCurrentPage,
										pageSize: pageSize,
										setPageSize: setPageSize,
										onPageChange: (page) => {
											setSearchParams({
												...searchParams,
												page: `${page}`,
											});
										},
										onRowPerPageChange: (rowPerPage) => {
											setSearchParams({
												...searchParams,
												limit: `${rowPerPage}`,
											});
										},
										noOfItems,
									}}
									tableHeadings={{
										tableObject: tableHeadings?.map(
											(heading: { name: string; APIName?: string }) => {
												return {
													name: heading?.name,
													center: false,
													APIName: heading?.APIName,
												};
											},
										),
									}}
									ref={tableRef}
									isCenterLastHeading={canDelete ? true : false}
									sortOrder={sortOrder}
									setSortOrder={setSortOrder}
									isSortOrderNeeded
								>
									{projectsMembersList?.map(
										(projectMember: IProjectMembers) => {
											return (
												<TableContent
													key={projectMember?.id}
													tableRef={tableRef}
													classForTableRow={`${
														canCreateOrUpdate
															? "cursor-pointer hover:bg-white-smoke"
															: "cursor-default hover:bg-white-smoke"
													}`}
													tableRowClick={() => {
														canCreateOrUpdate && onEdit(projectMember);
													}}
													tableContent={getTableContent(projectMember)}
												/>
											);
										},
									)}
								</Table>
							</div>
						</Fragment>
					) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
						noDataFoundMessage
					) : canCreateOrUpdate ? (
						<div className="w-full h-[82vh] grid place-content-center">
							<Filled buttonName="Add" onClick={addBtnHandler} />
						</div>
					) : (
						noDataFoundMessage
					)}
				</Fragment>
			) : (
				<Loading className="min-h-[90vh]" />
			)}

			{showModal && (
				<EditMember
					refetchProjectMembers={refetchProjectMembers}
					editProjectMember={editProjectMember}
					setShowModal={setShowModal}
					loading={loading}
					editProject={editProject}
					allowedResourcesForProject={allowedResourcesForProject}
					isManager={isManager}
				/>
			)}
			{deleteVerification && (
				<ConfirmModal
					header="Project"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectMemberLoading}
				/>
			)}
		</div>
	);
};

export default Members;
