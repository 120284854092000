const iconCommonStyle = "w-[22px] h-[22px] mr-[20px]";

export const navData = [
  {
    link: "/dashboard",
    name: "Dashboard",
    iconName: "Dashboard",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/companies",
    name: "Company",
    iconName: "CompanyIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/timer",
    name: "Timer",
    iconName: "ClockIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/projects",
    name: "Projects",
    iconName: "UserIcon",
    iconStyle: iconCommonStyle,
    subMenus: [
      {
        subMenuName: "Projects",
        subMenuIDs:[],
        subMenuLink: "/projects/projects/my-projects",
        showTab:true,    
      },
      {
        subMenuName: "Groups",
        subMenuIDs: ["ReadMyProjectGroup", "ReadAllProjectGroup"],
        subMenuLink: "/projects/groups",
        showTab: true,
      },
      {
        subMenuName: "Settings",
        subMenuIDs: [
          "ReadProjectRole",
          "ReadProjectVaultEnvironment",
          "ReadProjectVaultCredentialType",
        ],
        subMenuLink: "/projects/settings/roles",
      },
    ],
  },
  {
    link: "/leaves",
    name: "Leave",
    iconName: "CalendarIcon",
    iconStyle: iconCommonStyle,
    subMenus: [
      {
        subMenuName: "Dashboard",
        subMenuIDs: ["ReadLeaveDashboard"],
        subMenuLink: "/leaves/dashboard",
      },
      {
        subMenuName: "Apply",
        subMenuIDs: ["ApplyLeave"],
        subMenuLink: "/leaves/apply/regular",
      },
      {
        subMenuName: "Requests",
        subMenuLink: "/leaves/requests/my-requests",
      },
      {
        subMenuName: "Transactions",
        subMenuLink: "/leaves/transactions/my-transactions",
      },
      {
        subMenuName: "Holidays",
        subMenuIDs: ["Holiday"],
        subMenuLink: "/leaves/holidays",
      },
      {
        subMenuName: "Absentees",
        subMenuLink: "/leaves/absentees",
      },
      {
        subMenuName: "Settings",
        subMenuIDs: ["LeaveType", "LeaveCredits"],
        subMenuLink: "/leaves/settings/leave-type",
      },
    ],
  },
  {
    link: "/payroll",
    name: "Payroll",
    iconName: "CurrencyDollarIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/team",
    name: "Teams",
    iconName: "UserGroupIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/support",
    name: "Support",
    iconName: "BookOpenIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/organization",
    name: "Organization",
    iconName: "GalleryIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
  {
    link: "/settings",
    name: "Settings",
    iconName: "CogIcon",
    iconStyle: iconCommonStyle,
    subMenus: [],
  },
];
