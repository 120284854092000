import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import { useMutation, useQuery } from "@apollo/client";

import { Email, Mobile, EditForm, Name, Select } from "components/forms";

import { languageList } from "global/helpers/StaticData";
import {
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import { IPersonalInformationForm } from "modules/Employee/types/personal";
import { UPDATE_PERSONAL_INFO } from "modules/Employee/services/mutations";
import { GET_PERSONAL_INFORMATION_DROPDOWN } from "modules/Employee/services/queries";
import { mutationHandler } from "modules/Employee/model/mutation-handler";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";
import { Company } from "modules/Company/types";

interface IProps {
  company: Company | null;
  setShowModal: Function;
}

const EditBasicInformation: FC<IProps> = ({ company, setShowModal }) => {
  const { data: dropdownList, loading: dropdownLoading } = useQuery(
    GET_PERSONAL_INFORMATION_DROPDOWN
  );

  const isUnderModeration = useIsUnderModeration();

  const bloodGroups = dropdownList?.getBloodGroupOptions || [];
  const genders = dropdownList?.getUserGenders || [];
  const maritalStatus = dropdownList?.getMaritalStatusOptions || [];

  const [inProgress, setInProgress] = useState<boolean>(false);

  const [updateUser] = useMutation(UPDATE_PERSONAL_INFO);

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<IPersonalInformationForm>();

  const useFormProps = {
    register: register,
    errors: errors,
  };

  const modalCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
  };

  const userId = sessionStorage?.otherUserId
    ? +sessionStorage?.otherUserId
    : undefined;

  const actionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Personal information")
    : updateSuccessMessageWithAwaiting("Personal information");

  const updateHandler = (data: IPersonalInformationForm) => {
    const variables = {
      id: userId,
      name: data.name.trim(),
      personalEmail: data.personalEmail,
      isdCode: data.country,
      mobile: data.mobile,
      gender: data.gender || null,
      bloodGroup: data.bloodGroup || null,
      maritalStatus: data.maritalStatus || null,
      languagesKnown: data?.languagesKnown,
    };

    mutationHandler(
      updateUser,
      variables,
      inProgress,
      modalCloseHandler,
      setInProgress,
      actionSuccessMessage
    );
  };

  return (
    <EditForm
      title={"Personal Information"}
      isFormLoading={dropdownLoading}
      modalCloseHandler={modalCloseHandler}
      handleSubmit={handleSubmit}
      updateHandler={updateHandler}
      inProgress={inProgress}
    >
      <Name {...useFormProps} label="Name *" name="name" required={true} />
      <Email
        {...useFormProps}
        label="Email *"
        name="personalEmail"
        required={true}
      />
      <Mobile {...useFormProps} required={true} label="Mobile *" />
      <AutoComplete
        setValue={setValue}
        options={languageList}
        control={control}
        label={"Languages Known"}
        name={"languagesKnown"}
        errors={errors}
        multiple
      />
      <Select
        {...useFormProps}
        label="Gender"
        options={genders}
        name="gender"
      />

      <Select
        {...useFormProps}
        label="Blood Group"
        options={bloodGroups}
        name="bloodGroup"
      />
      <Select
        {...useFormProps}
        label="Marital Status"
        options={maritalStatus}
        name="maritalStatus"
      />
    </EditForm>
  );
};

export default EditBasicInformation;
