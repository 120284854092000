import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { PROJECT_TASK_HISTORIES } from "modules/Project/services/queries";
import { removeDuplicateObject } from "global/helpers/ArrayMethods";
import { IProjectTaskHistory } from "modules/Project/types/project";
import History from "./History";
import ActivityLoading from "modules/Project/Pages/Issues/IssueLogs/Activity/ActivityLoading";
import { getAllowedAccess } from "global/helpers/Cache";

const Histories = () => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const [fetchTaskHistories, { loading }] = useLazyQuery(
		PROJECT_TASK_HISTORIES,
	);

	const { issueId } = useParams();

	const limit = 10;

	const useNotifcation: any = (pageNumber: number) => {
		const [hasMore, setHasMore] = useState(false);
		const [histories, setHistories] = useState<any>([]);
		const [length, setLength] = useState<number>(0);
		const [totalNumberOfItems, setTotalNumberOfItems] = useState<
			number | undefined
		>();

		useEffect(() => {
			setLength(histories?.length);
		}, [histories]);

		useEffect(() => {
			if (issueId && !isNaN(+issueId) && !loading) {
				fetchTaskHistories({
					variables: {
						filters: {
							projectTaskId: +issueId,
						},
						limit,
						page: pageNumber,
						type: canReadUserProfileImage ? "Profile" : undefined,
						isProfileImageNeeded: canReadUserProfileImage,
					},
				})
					.then((res) => {
						const historyData =
							res?.data?.projectTaskHistories?.dataCollection?.map(
								(notification: any) => notification,
							) || [];

						setHistories((prevState: any) => {
							return removeDuplicateObject(
								[...prevState, historyData].flat(),
								"id",
							);
						});
						setHasMore(
							length < res?.data?.projectTaskHistories?.totalNumberOfItems,
						);
						setTotalNumberOfItems(
							res?.data?.projectTaskHistories?.totalNumberOfItems,
						);
					})
					.catch((error) => {
						if (error.name === "AbortError") {
							return;
						}
					});
			}
		}, [pageNumber, length]);

		return {
			histories,
			totalNumberOfItems,
			hasMore,
		};
	};

	const [pageNumber, setPageNumber] = useState(1);

	const { histories, totalNumberOfItems, hasMore } = useNotifcation(pageNumber);

	const observer: any = useRef();

	const lastHistoryElementRef = useCallback(
		(node: any) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					hasMore &&
					pageNumber < Math.ceil(totalNumberOfItems / limit)
				) {
					setPageNumber((prevPageNumber) => prevPageNumber + 1);
				}
			});

			if (node) observer.current.observe(node);
		},
		[loading, totalNumberOfItems, pageNumber, hasMore],
	);

	return (
		<div
			className={`max-h-[700px] mt-6 ${
				histories?.length > 0 ? "overflow-y-auto" : "overflow-y-hidden"
			}`}
		>
			{histories.map((history: IProjectTaskHistory, index: number) => {
				if (histories.length === index + 1) {
					return (
						<div key={history?.id} ref={lastHistoryElementRef}>
							<History history={history} />
						</div>
					);
				} else {
					return (
						<div key={history?.id}>
							<History history={history} />
						</div>
					);
				}
			})}
			{loading && <ActivityLoading />}
			{histories?.length <= 0 && !loading && (
				<div className="w-full h-[250px] flex justify-center items-center border">
					<p className="text-sm text-warm-gray">No histories found.</p>
				</div>
			)}
		</div>
	);
};

export default Histories;
