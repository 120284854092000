import { FC } from "react";

import CardDataRow from "components/App/Card/CardDataRow";
import { IDataModel } from "components/App/Card/types/card";

interface IProps {
  dataModel: IDataModel[];
  setShowUnderReview: Function;
  setChangedInformation: Function;
  isHtmlElementValue?: boolean;
  className?: string;
  classNameForLabel?: string;
  classNameForDataRow?: string;
}

const CardContent: FC<IProps> = ({
  dataModel,
  setShowUnderReview,
  setChangedInformation,
  isHtmlElementValue,
  className,
  classNameForLabel,
  classNameForDataRow,
}) => {
  const moderationPopupClickHandler = (
    moderationId: number | null | undefined
  ) => {
    moderationId && setShowUnderReview(true);
    setChangedInformation([moderationId]);
  };

  return (
    <div className="card-content">
      <ul>
        {dataModel.map((row, index: number) => (
          <li
            key={index}
            className={row?.moderationId ? "bg-yellow-100 cursor-pointer" : ""}
            onClick={() => moderationPopupClickHandler(row?.moderationId)}
          >
            <CardDataRow
              label={row.label}
              value={row.value}
              row={row}
              isHtmlElementValue={isHtmlElementValue}
              classNameForLabel={classNameForLabel}
              classNameForDataRow={classNameForDataRow}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CardContent;
