import { FC, Fragment, useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useForm } from "react-hook-form";
import { HiCog } from "react-icons/hi";

import Modal from "components/UI/Modal";
import { Filled, Outlined, Select } from "components/forms";
import { DOTS, usePagination } from "components/Pagination/usepagination";

import { ReactComponent as SingleArrowRight } from "global/assets/images/single-arrow-right.svg";
import { ReactComponent as SingleArrowLeft } from "global/assets/images/single-arrow-left.svg";

const Pagination: FC<{
  totalCount: number;
  currentPage: number;
  setPageSize: Function;
  siblingCount: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
  noOfItem: number | undefined;
}> = ({
  totalCount,
  currentPage,
  setPageSize,
  siblingCount,
  pageSize,
  onPageChange,
  noOfItem,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<{ rowPerPage: string }>({
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue("rowPerPage", `${pageSize}`);
  }, [pageSize, setValue]);

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  let lastPage = paginationRange[paginationRange?.length - 1];

  let pageStartRange = pageSize * (currentPage - 1) + 1;

  let pageEndRange =
    currentPage === 1
      ? noOfItem || pageSize * currentPage
      : currentPage === lastPage
      ? totalCount
      : pageSize * currentPage;

  const onNext = () => {
    if (currentPage === lastPage) {
      return;
    } else {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const [showRowPerPageModal, setShowRowPerPageModal] = useState(false);

  const openModalHandler = () => {
    setShowRowPerPageModal(!showRowPerPageModal);
  };

  const modalCloseHandler = () => {
    setShowRowPerPageModal(false);
  };
  const paginationSubmitHandler = (paginationFilterFormData: {
    rowPerPage: string;
  }) => {
    setPageSize(+paginationFilterFormData?.rowPerPage);
    onPageChange(1);
    setTimeout(() => {
      setShowRowPerPageModal(false);
    }, 50);
  };
  return (
    <Fragment>
      <div className="bg-white p-[25px_0px_25px_0px] w-full grid lg:grid-cols-2 gap-2 md:gap-0 items-center">
        <div className="flex gap-2">
          <div className="w-full">
            <HiCog
              className="text-ironside-gray/70 cursor-pointer w-5 h-5  sm:w-6 sm:h-6"
              onClick={openModalHandler}
            />
          </div>
          <p className="text-xs sm:text-sm text-ironside-gray/60 text-center whitespace-nowrap">
            {pageStartRange} - {pageEndRange} of {totalCount}
          </p>
        </div>
        <div className="flex justify-center lg:justify-end">
          <ul className="flex gap-1 sm:gap-2">
            <li
              onClick={onPrevious}
              className={`pagination-item ${
                currentPage === 1 ? "disable" : ""
              }`}
            >
              <SingleArrowLeft className={"arrow"} />
            </li>
            {paginationRange.map((pageNumber: any, index: number) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={index} className="pagination-item dots">
                    &#8230;
                  </li>
                );
              }
              return (
                <li
                  onClick={() => {
                    onPageChange(pageNumber);
                  }}
                  key={index}
                  className={`pagination-item ${
                    currentPage === pageNumber ? "selected" : ""
                  }`}
                >
                  <span className="m-2">{pageNumber}</span>
                </li>
              );
            })}
            <li
              onClick={onNext}
              className={`pagination-item ${
                currentPage === lastPage ? "disable" : ""
              }`}
            >
              <SingleArrowRight className={"arrow"} />
            </li>
          </ul>
        </div>
      </div>
      {showRowPerPageModal && (
        <Modal>
          <div className="bg-white rounded-md min-w-[300px] sm:min-w-[400px]">
            <div className="flex justify-between items-center py-4 px-4 sm:px-6 border-b">
              <h2 className="edit-modal-heading">Pagination</h2>
              <MdOutlineClose
                className="w-6 h-6 hover:bg-white-smoke rounded-full text-ironside-gray/70 cursor-pointer"
                onClick={modalCloseHandler}
              />
            </div>
            <form
              onSubmit={handleSubmit(paginationSubmitHandler)}
              className="first:px-4 pt-5"
            >
              <div className="px-4 sm:px-6 sm:pt-5">
                <Select
                  errors={errors}
                  label={"Rows Per Page"}
                  options={["5", "10", "15", "25", "30"]}
                  register={register}
                  name="rowPerPage"
                  defaultSelectValue={`${pageSize}`}
                />
              </div>
              <div className="flex  justify-between p-4  sm:p-6 border-t sm:mt-6">
                <Outlined buttonName="Cancel" onClick={modalCloseHandler} />
                <Filled buttonType={"submit"} buttonName={"Apply"} />
              </div>
            </form>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default Pagination;
