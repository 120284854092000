import { FC } from "react";

import { FileDragAndDrop } from "components/forms";

import { toastNotify } from "global/helpers/Cache";

interface IProps {
  fileList: any;
  setFileList: Function;
  updatedFiles: { id: number; filePath: string }[];
  setUpdatedFiles: Function;
}
const LeaveFiles: FC<IProps> = ({
  fileList,
  setFileList,
  updatedFiles,
  setUpdatedFiles,
}) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px]">
      <p className="text-sm text-ironside-gray pt-5">Proofs</p>
      <FileDragAndDrop
        fileList={fileList}
        setFileList={setFileList}
        updatedFiles={updatedFiles}
        setUpdatedFiles={setUpdatedFiles}
        setAlert={toastNotify}
      />
    </div>
  );
};

export default LeaveFiles;
