import { useEffect, useState } from "react";
import { FieldPath, FieldValues, PathValue } from "react-hook-form";
import { FaRegFileAlt } from "react-icons/fa";
import { MdOutlinePictureAsPdf } from "react-icons/md";

import Button from "components/forms/Button/UpdatedButtonAgry/Button";
import ImageComponent from "components/UI/UpdateUIAgry/Image";

import { ReactComponent as CloseIcon } from "global/assets/images/close-circle.svg";
import { filePreview } from "global/helpers/Cache";

interface Props<
	TFieldValues extends FieldValues = FieldValues,
	Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
	file: File;
	onChange: (...event: any[]) => void;
	value: PathValue<TFieldValues, Name>;
	id: string | number;
}

const FileRender = <
	TFieldValues extends FieldValues = FieldValues,
	Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	file,
	onChange,
	value,
	id,
}: Props<TFieldValues, Name>) => {
	const [dataURL, setDataURL] = useState<string | null>(null);

	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setDataURL(
					typeof e?.target?.result === "string" ? e?.target?.result : null,
				);
			};
			reader.readAsDataURL(file);
		}
	}, []);

	return (
		<div className="relative">
			{dataURL &&
			(file?.type === "image/jpeg" ||
				file?.type === "image/png" ||
				file?.type === "image/gif" ||
				file?.type === "image/svg+xml" ||
				file?.type === "image/webp" ||
				file?.type === "image/bmp" ||
				file?.type === "image/x-icon") ? (
				<ImageComponent
					src={dataURL}
					alt={file?.name}
					className="w-full h-[100px] rounded border border-outline-variant object-cover cursor-pointer"
					onClick={() => {
						filePreview({
							data: dataURL,
							title: file?.name,
							mimeType: file?.type,
							name: file?.name,
						});
					}}
				/>
			) : file?.type === "application/pdf" ? (
				<div
					onClick={() => {
						if (dataURL) {
							filePreview({
								data: dataURL,
								title: file?.name,
								mimeType: file?.type,
								name: file?.name,
							});
						}
					}}
					className={`w-full h-[100px] rounded border border-outline-variant grid place-content-center ${
						dataURL ? "cursor-pointer" : ""
					}`}
				>
					<MdOutlinePictureAsPdf className="w-6 h-6 text-secondary" />
				</div>
			) : (
				<div className="w-full h-[100px] rounded border border-outline-variant grid place-content-center">
					<FaRegFileAlt className="w-6 h-6 text-secondary" />
				</div>
			)}
			<Button
				modifier="plain"
				className="p-0 absolute right-0 bottom-0 top-0 bg-background w-6 h-6 z-0 text-error rounded-full cursor-pointer flex items-center justify-center"
				onPress={() => {
					onChange(
						value &&
							Array?.isArray(value) &&
							value?.filter(
								(fileList: { id: number | string; file: File }) =>
									fileList?.id !== id,
							),
					);
				}}
			>
				<CloseIcon />
			</Button>
		</div>
	);
};

export default FileRender;
