import { useState, useRef, useEffect } from "react";
import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";
import Logout from "modules/User/Logout";
import userImage from "global/assets/images/dummy-user.png";
import avatar from "global/assets/images/dummy-avatar.svg";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "global/assets/images/logout-icon.svg";
import { ReactComponent as UserIcon } from "global/assets/images/user-icon.svg";
import { ReactComponent as PasswordLock } from "global/assets/images/passwordLock-icon.svg";
import { userContextData, getAllowedAccess } from "global/helpers/Cache";
import { useMutation, useReactiveVar } from "@apollo/client";
import { navData } from "layouts/nav/Sidebar/NavData";
import { REMOVE_DEVICE_CREDENTIAL } from "../TopLinks/Notification/services/mutations";
import { clearCookies, client } from "global/services/config";

export interface RoleAccessType {
  id: string;
  title: string;
  isAllowed: boolean;
  childNodes: any;
}

const ProfilePic = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResource = allowedResourcesList?.allowedResources;

  const userDataList: any = useReactiveVar(userContextData);
  const [showLogout, setShowLogout] = useState<boolean>(false);
  let profileRef: any = useRef();
  const [logoutStatus, setLogoutStatus] = useState(false);

  const [removeDeviceCredential] = useMutation(REMOVE_DEVICE_CREDENTIAL);

  const employeeProfileImage = userDataList?.user?.userProfileImages?.filter(
    (profileImage: any) => profileImage?.type === "Profile"
  )[0];

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!profileRef.current?.contains(event.target)) {
        setShowLogout(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showLogout]);

  const path = useLocation();
  let pathName = path.pathname;
  let title = "";

  if (pathName.slice(0, 8) === "/profile") {
    title = "My Profile";
  } else {
    navData.map((menuName) =>
      pathName?.includes(`${menuName?.link}`) ? (title = menuName.name) : null
    );
  }
  if (pathName === "/change-password") {
    title = "Change Password";
  }

  useEffect(() => {
    if (logoutStatus) {
      const sessionNotificationId: string | any = document.cookie
        .split("; ")
        .find((row) => row.startsWith("notificationId="))
        ?.split("=")[1];
      if (localStorage?.notificationId || sessionNotificationId) {
        removeDeviceCredential({
          variables: {
            id: +localStorage?.notificationId || +sessionNotificationId,
          },
        })
          .then(() => {
            window.location.replace("/");
            localStorage.clear();
            sessionStorage.clear();
            client.clearStore();
            clearCookies();
          })
          .catch((error) => {
            window.location.replace("/");
            localStorage.clear();
            sessionStorage.clear();
            client.clearStore();
            clearCookies();
            console.error("Error:", error?.message);
          });
      } else {
        Logout();
      }
    }
  }, [logoutStatus, removeDeviceCredential]);

  return (
    <div ref={profileRef}>
      <div className="flex relative">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setShowLogout((prevState) => !prevState)}
        >
          <div className="w-[42px] h-[42px] rounded-full bg-gradient-to-r from-green-400 to-blue-500 flex justify-center items-center ml-[18px] lg:ml-[34px]">
            <div className="w-[38px] h-[38px] rounded-full flex justify-center items-center lg:p-[1px] bg-white">
              <img
                src={
                  employeeProfileImage?.documents &&
                  employeeProfileImage?.documents[0]?.file?.length >= 100
                    ? `data:image/jpeg;base64,${employeeProfileImage?.documents[0]?.file}`
                    : avatar
                }
                alt={userImage}
                className="w-[35px] h-[35px] rounded-full"
              />
            </div>
          </div>
          <DownArrow className="w-[8px] h-[5px] text-[#BEBEBE] ml-[5px]" />
        </div>
      </div>
      {showLogout && (
        <div className="flex relative">
          <div
            className="absolute  w-0  h-0 border-l-[10px] border-l-transparent z-50
                        border-r-[10px] border-r-transparent border-b-[10px] border-b-white top-3 lg:top-3 right-6 shadow-[0px_0px_45px_#C4C4C433]"
          />
          <div className="absolute w-[260px]   pt-[27px]  rounded-md bg-white top-5 lg:top-[22px] right-1 shadow-[0px_0px_45px_#C4C4C433] truncate">
            <p className="text-[15px] md:leading-[21px] text-ironside-gray px-[25px] truncate">
              {userDataList?.user?.personalInformation?.name
                ? userDataList.user.personalInformation.name.trim()
                : "Ex: John Peter"}
            </p>
            <p className="text-[12px] leading-[15px] opacity-100 text-ironside-gray pt-[5px] pb-[20px] px-[25px] truncate">
              {userDataList?.user?.employeeDetail?.employeeDesignation}
            </p>
            <hr className="border-t border-[#ECECEC]" />
            {allowedResource?.includes("Profile") ? (
              title === "My Profile" && !sessionStorage?.otherUserId ? null : (
                <NavLink
                  onClick={() => {
                    if (sessionStorage?.otherUserId) {
                      window.location.reload();
                      sessionStorage?.removeItem("otherUserId");
                    }
                    setShowLogout(false);
                  }}
                  to="profile/personal"
                  className={`flex items-center py-[10px] mt-[10px] hover:bg-[#ECF1FE] px-[25px] group ${
                    title === "Change Password" ? "mb-[10px]" : "mb-[10px]"
                  }`}
                >
                  <UserIcon className="mr-[20px] w-[14px] h-[17px] text-[#8e8e8f] group-hover:text-cornflower-blue" />
                  <p className="text-[14px] leading-[20px] text-[#8E8E8F] group-hover:text-cornflower-blue">
                    Profile
                  </p>
                </NavLink>
              )
            ) : null}
            {title === "Change Password" ? null : (
              <NavLink
                to="/change-password"
                onClick={() => {
                  setShowLogout(false);
                }}
                className={`flex items-center py-[10px] mb-[10px] hover:bg-[#ECF1FE] px-[25px] group ${
                  title === "My Profile" ? "mt-[10px]" : null
                }`}
              >
                <PasswordLock className="mr-[20px] w-[14px] h-[17px] text-[#8e8e8f] group-hover:text-cornflower-blue" />

                <p className="text-[14px] leading-[20px] text-[#8E8E8F] whitespace-nowrap group-hover:text-cornflower-blue">
                  Change Password
                </p>
              </NavLink>
            )}

            <hr className="border-t border-[#ECECEC]" />

            <div
              className="flex items-center  my-[10px] py-[10px] cursor-pointer px-[25px] hover:bg-[#ECF1FE] group"
              onClick={() => setLogoutStatus(true)}
            >
              <LogoutIcon className="mr-[20px] ]w-[14px] h-[17px] text-[#8e8e8f] group-hover:text-cornflower-blue" />
              <button className="text-[14px] leading-[20px] text-[#8E8E8F] group-hover:text-cornflower-blue">
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePic;
