import { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import {
  Filled,
  Modal,
  Number,
  Outlined,
  Select,
  Text,
} from "components/forms";
import Loading from "components/Loader/Loading";

import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { IAddressForm, IAddress } from "modules/Employee/types/contacts";
import {
  COUNTRY_STATES,
  GET_CONTACT_ADDRESSES,
} from "modules/Employee/services/queries";
import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
} from "modules/Employee/services/mutations";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  setShowModal: Function;
  title: string;
  contact: IAddress | null;
  setContact: Function;
  addressTypes: string[] | [];
  refetchContatctAddresses: Function;
}

const EditAddress1: FC<IProps> = ({
  setShowModal,
  title,
  contact,
  setContact,
  addressTypes,
  refetchContatctAddresses,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IAddressForm>();
  const { data: countries, loading: countriesAndStatesLoading } =
    useQuery(COUNTRY_STATES);

  const [createUserAddress] = useMutation(CREATE_ADDRESS);
  const [updateUserAddress] = useMutation(UPDATE_ADDRESS);

  const isUnderModeration = useIsUnderModeration();

  const moderationFieldProps = {
    refetchQuery: refetchContatctAddresses,
  };

  const [inProgress, setInProgress] = useState(false);

  const [state, setState] = useState<{ id: number; name: string }[] | []>([]);

  const countryAndStateRef = useRef(true);

  const modalCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setContact(null);
  };

  const country = countries?.countries?.map(
    (country: { id: number; name: string }) => ({
      id: country?.id,
      name: country?.name,
    })
  );

  const countryChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    let states = countries?.countries?.filter(
      (country: { id: number; name: string }) =>
        +country.id === +event?.target?.value
    )[0]?.states;
    if (states) {
      setState(states);
    } else {
      setValue("state", "");
    }
  };

  const countryId =
    (contact &&
      country?.filter(
        (country: { id: number; name: string }) =>
          country?.name?.toLowerCase() === contact?.country?.toLowerCase()
      )[0]?.id) ||
    1;

  const stateList = countries?.countries?.filter(
    (country: { id: number; name: string }) => +country?.id === +countryId
  )[0]?.states;

  const stateId =
    contact &&
    stateList?.filter(
      (state: { id: number; name: string }) =>
        state?.name?.toLowerCase() === contact?.state?.toLowerCase()
    )[0]?.id;

  useEffect(() => {
    if (country && countryAndStateRef?.current) {
      setValue("country", countryId || +country[0]?.id);
      setState(
        countries?.countries?.filter(
          (country: { id: number; name: string }) => +country?.id === +countryId
        )[0]?.states
      );
      setTimeout(() => {
        setValue("state", +stateId || +1);
      }, 50);
      countryAndStateRef.current = false;
    }
  }, [
    countries?.countries,
    country,
    setValue,
    countryAndStateRef,
    countryId,
    stateId,
  ]);

  useEffect(() => {
    if (contact) {
      setTimeout(() => {
        setValue("addressLine1", contact.addressLine1);
        setValue("addressLine2", contact.addressLine2);
        setValue("addressLine3", contact.addressLine3);
        setValue("city", contact.city);
        setValue("pinCode", contact.pinCode);
        setValue("country", +countryId || "");
        setValue("state", +stateId || "");
        setValue("addressType", contact.type);
      }, 50);
    }
  }, [contact, setValue, countryId, stateId]);

  const addressSubmitHandler = (address: IAddressForm) => {
    if (!inProgress) {
      if (contact?.id) {
        setInProgress(true);
        updateUserAddress({
          variables: {
            id: contact?.id,
            addressLine1:
              address.addressLine1?.trim() === contact.addressLine1?.trim()
                ? undefined
                : address.addressLine1 || null,
            addressLine2:
              address.addressLine2?.trim() === contact.addressLine2?.trim()
                ? undefined
                : address.addressLine2 || null,
            addressLine3:
              address.addressLine3?.trim() === contact.addressLine3?.trim()
                ? undefined
                : address.addressLine3 || null,
            stateId: +address.state === +stateId ? undefined : +address.state,
            countryId:
              +address.country === +countryId ? undefined : +address.country,
            city:
              address.city?.trim() === contact.city?.trim()
                ? undefined
                : address.city || null,
            pinCode:
              address.pinCode?.trim() === contact.pinCode?.trim()
                ? undefined
                : address.pinCode || null,
            type:
              address.addressType?.trim() === contact.type?.trim()
                ? undefined
                : address.addressType,
          },
          update: (cache, { data: { updateUserAddress } }) => {
            const exAddresses: any = cache.readQuery({
              query: GET_CONTACT_ADDRESSES,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            const updatedUserAddress =
              exAddresses?.users?.dataCollection[0]?.userAddresses?.map(
                (address: IAddress) => {
                  if (address?.id === updateUserAddress?.id) {
                    return {
                      ...address,
                      addressLine1: updateUserAddress?.addressLine1,
                      addressLine2: updateUserAddress?.addressLine2,
                      addressLine3: updateUserAddress?.addressLine3,
                      city: updateUserAddress?.city,
                      country: updateUserAddress?.country,
                      pinCode: updateUserAddress?.pinCode,
                      state: updateUserAddress?.state,
                      type: updateUserAddress?.type,
                      moderation: updateUserAddress?.moderation,
                    };
                  }
                  return address;
                }
              );

            cache.writeQuery({
              query: GET_CONTACT_ADDRESSES,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exAddresses?.users?.dataCollection[0],
                      userAddresses: updatedUserAddress,
                    },
                  ],
                },
              },
            });
          },
        })
          .then((response) => {
            const addressType = response?.data?.updateUserAddress?.type;
            const updateActionSuccessMessage = isUnderModeration
              ? updateSuccessMessage(`${addressType} Address`)
              : updateSuccessMessageWithAwaiting(`${addressType} Address`);
            setInProgress(false);
            modalCloseHandler();
            toastNotify(updateActionSuccessMessage);
          })
          .catch((error) => {
            setInProgress(false);
            toastNotify(errorMessageNotify(error));
          });
      } else {
        setInProgress(true);
        createUserAddress({
          variables: {
            userId: sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
            addressLine1: address.addressLine1 || null,
            addressLine2: address.addressLine2 ? address.addressLine2 : null,
            addressLine3: address.addressLine3 ? address.addressLine3 : null,
            stateId: +address.state,
            countryId: +address.country,
            city: address.city || null,
            pinCode: address.pinCode || null,
            type: address.addressType,
          },
          update: (cache, { data: { createUserAddress } }) => {
            const exAddresses: any = cache.readQuery({
              query: GET_CONTACT_ADDRESSES,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            const updatedAddresses = [
              ...exAddresses?.users?.dataCollection[0]?.userAddresses,
              createUserAddress,
            ];

            cache.writeQuery({
              query: GET_CONTACT_ADDRESSES,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exAddresses?.users?.dataCollection[0],
                      userAddresses: updatedAddresses,
                    },
                  ],
                },
              },
            });
          },
        })
          .then((response) => {
            const addressType = response?.data?.createUserAddress?.type;
            const createActionSuccesMessage = isUnderModeration
              ? createSuccessMessage(`${addressType} address`)
              : createSuccessMessageWithAwaiting(`${addressType} address`);
            setInProgress(false);
            modalCloseHandler();
            toastNotify(createActionSuccesMessage);
          })
          .catch((error) => {
            setInProgress(false);
            toastNotify(errorMessageNotify(error));
          });
      }
    }
  };

  const moderationObj = contact?.moderation || null;

  return (
    <Modal>
      <div className="bg-white rounded-md w-[350px] sm:w-[400px] divide-y-[1px] divide-mercury">
        <Fragment>
          <div className="flex justify-between items-center p-5">
            <h5 className="text-ironside-gray text-base">{title}</h5>
            <MdOutlineClose
              className="text-ironside-gray/50 cursor-pointer w-5 h-5 rounded-full hover:bg-mercury"
              onClick={modalCloseHandler}
            />
          </div>
          {countriesAndStatesLoading ? (
            <Loading className="rounded-md w-[350px] sm:w-[400px] min-h-[500px]" />
          ) : (
            <form
              onSubmit={handleSubmit(addressSubmitHandler)}
              className="divide-y-[1px] divide-mercury"
            >
              <div className={`pt-5 max-h-[500px] px-10 overflow-y-auto`}>
                <Select
                  errors={errors}
                  label="Address Type *"
                  name="addressType"
                  register={register}
                  options={addressTypes}
                  required
                  disable={contact?.type ? true : false}
                />
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.addressLine1}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Address Line 1"
                    name="addressLine1"
                    maximumLength={50}
                    disable={!!moderationObj?.addressLine1}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.addressLine2}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Address Line 2"
                    name="addressLine2"
                    maximumLength={50}
                    disable={!!moderationObj?.addressLine2}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.addressLine3}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Address Line 3"
                    name="addressLine3"
                    maximumLength={50}
                    disable={!!moderationObj?.addressLine3}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.city}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="City"
                    name="city"
                    maximumLength={50}
                    disable={!!moderationObj?.city}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.pinCode}
                >
                  <Number
                    register={register}
                    errors={errors}
                    label="Pin Code"
                    name="pinCode"
                    minimumLength={6}
                    maximumLength={6}
                    disabled={!!moderationObj?.pinCode}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.country}
                >
                  <Select
                    errors={errors}
                    label="Country"
                    name="country"
                    register={register}
                    optionsWithId={country}
                    onChange={countryChangeHandler}
                    disable={!!moderationObj?.country}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.state}
                >
                  <Select
                    errors={errors}
                    label="State"
                    name="state"
                    register={register}
                    optionsWithId={state}
                    disable={!!moderationObj?.state}
                  />
                </ModerationField>
              </div>
              <div className="flex justify-between items-center p-5">
                <Outlined buttonName="Cancel" onClick={modalCloseHandler} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : contact?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                  buttonType="submit"
                  className={`${
                    inProgress
                      ? "cursor-not-allowed hover:shadow-none bg-bright-blue/70 hover:bg-bright-blue/70"
                      : "cursor-pointer"
                  }`}
                  disabled={inProgress}
                />
              </div>
            </form>
          )}
        </Fragment>
      </div>
    </Modal>
  );
};
export default EditAddress1;
