import { FC } from "react";

import { notFoundMessage } from "global/helpers/action-success-error-messages";

const OopsNoData: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={`flex justify-center items-center flex-col  w-full ${
        className ? className : "min-h-[400px]"
      }`}
    >
      <p
        children={notFoundMessage}
        className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
      />
    </div>
  );
};

export default OopsNoData;
