import { IToastedNotification } from "components/UI/ToastNotification/type";

import { toastNotify } from "global/helpers/Cache";
import {
  errorMessageNotify,
  updateSuccessMessage,
} from "global/helpers/action-success-error-messages";

export const mutationHandler = (
  mutationFunction: Function,
  variables: any,
  inProgress: boolean,
  modalCloseHandler: () => void,
  setInProgress: Function,
  actionSuccessMessage?: IToastedNotification[],
  refetchQuery?: Function | undefined
) => {
  if (!inProgress) {
    mutationFunction({
      variables: variables,
    })
      .then(() => {
        actionSuccessMessage &&
          toastNotify(
            (actionSuccessMessage && actionSuccessMessage) ||
              updateSuccessMessage
          );
        refetchQuery && refetchQuery();
        modalCloseHandler();
      })
      .catch((error: { message: any }) => {
        toastNotify(errorMessageNotify(error));
        modalCloseHandler();
      });
    setInProgress(!inProgress);
  }
};
