import { Tab } from "@headlessui/react";
import { useState } from "react";
import {
  Control,
  FieldValues,
  Form,
  FormSubmitHandler,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";

import { FilterOptions } from "components/FilterUpdated/types";
import FilterTab from "components/FilterUpdated/FilterTab";
import FilterTabPanel from "components/FilterUpdated/FilterTabPanel";
import Button from "components/forms/Button/UpdatedButtonAgry/Button";
import Modal from "components/forms/Modal/UpdatedModalAgry/Modal";

import { ISetStateType } from "global/types/type";
import { ReactComponent as CloseIcon } from "global/assets/images/close.svg";

interface Props<TFieldValues extends FieldValues = FieldValues> {
  showFilterPopupHandler: () => void;
  control: Control<TFieldValues>;
  options: FilterOptions<TFieldValues>;
  onSubmit: (values: TFieldValues) => void;
  onClear: () => void;
  setFilterValues: ISetStateType<TFieldValues["filter"] | null | undefined>;
  setValue: UseFormSetValue<TFieldValues>;
}

const FilterPopup = <TFieldValues extends FieldValues = FieldValues>({
  showFilterPopupHandler,
  control,
  onSubmit,
  options,
  onClear,
  setFilterValues,
  setValue,
}: Props<TFieldValues>) => {
  const [tabs, setTabs] = useState<number>(0);

  const filterFormSubmitHandler: FormSubmitHandler<TFieldValues> = ({
    data,
  }) => {
    setFilterValues(data?.filter || null);
    setValue(
      "search" as Path<TFieldValues>,
      "" as PathValue<TFieldValues, Path<TFieldValues>>
    );
    onSubmit(data);
    showFilterPopupHandler();
  };

  return (
    <Modal
      onClose={showFilterPopupHandler}
      classForParent="min-w-sm max-w-[60%] md:min-w-xl md:max-w-xl  mx-auto transition-all cursor-default z-[60]"
    >
      <Form
        control={control}
        onSubmit={
          filterFormSubmitHandler as TFieldValues extends FieldValues
            ? FormSubmitHandler<TFieldValues>
            : FormSubmitHandler<TFieldValues>
        }
      >
        <header className="flex justify-between bg-blue-200 rounded-t-xl py-4 px-6 w-full">
          <h3 className="text-[22px] font-normal text-on-surface">Filter</h3>
          <Button
            children={<CloseIcon className="text-on-surface-variant" />}
            modifier="plain"
            className="w-min"
            onPress={showFilterPopupHandler}
          />
        </header>
        <Tab.Group
          vertical={true}
          selectedIndex={tabs}
          onChange={setTabs}
          as={"div"}
          className="w-full grid grid-cols-[30%_70%] md:grid-cols-[200px_auto]"
        >
          <Tab.List
            as="ul"
            className="w-full h-full bg-surface-container-highest flex flex-col overflow-y-auto min-w-[100px] max-h-[500px]"
          >
            {options.map((option, index) => {
              return (
                <FilterTab
                  key={index}
                  option={option}
                  control={control}
                  setValue={setValue}
                />
              );
            })}
          </Tab.List>
          <Tab.Panels
            as="div"
            className="w-full h-full bg-surface-container-low max-h-[500px] min-h-[500px] overflow-y-auto"
          >
            {options.map((option, index) => {
              if (option?.isHide) {
                return null;
              } else {
                return (
                  <FilterTabPanel
                    control={control as any}
                    key={index}
                    option={option}
                  />
                );
              }
            })}
          </Tab.Panels>
        </Tab.Group>
        <div className="w-full flex gap-4 bg-surface-container p-4 rounded-b-xl">
          <Button
            children={"Clear"}
            modifier="outline"
            className="border-outline max-w-[150px] ml-auto"
            isRounded
            onPress={() => {
              showFilterPopupHandler();
              onClear();
              setFilterValues(null);
              setValue(
                "filter" as Path<TFieldValues>,
                null as PathValue<TFieldValues, Path<TFieldValues>>
              );
              setValue(
                "search" as Path<TFieldValues>,
                "" as PathValue<TFieldValues, Path<TFieldValues>>
              );
            }}
          />
          <Button
            children={"Apply"}
            className="max-w-[150px] mr-auto"
            isRounded
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

export default FilterPopup;
