import { ILeaveRequestDateConsent } from "global/types/type";
import { ConsentorActions } from "modules/Leave/types/leave";

export const filterConsentorActions = (
  leaveRequestDateConsents: ILeaveRequestDateConsent[]
) => {
  const consents: ConsentorActions = {
    pending: [],
    agreed: [],
    disagreed: [],
  };

  if (leaveRequestDateConsents?.length > 0) {
    for (const consent of leaveRequestDateConsents) {
      if (consent?.status === "Pending") {
        consents["pending"].push(
          consent?.consentedByUser?.personalInformation?.name
        );
      } else if (consent?.status === "Agreed") {
        consents["agreed"].push({
          name: consent?.consentedByUser?.personalInformation?.name,
          message: consent?.message,
          statusChangedAt: consent?.statusChangedAt,
        });
      } else if (consent?.status === "Disagreed") {
        consents["disagreed"].push({
          name: consent?.consentedByUser?.personalInformation?.name,
          reason: consent?.declinedReason,
          statusChangedAt: consent?.statusChangedAt,
        });
      }
    }
  }

  return consents;
};
