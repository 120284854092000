import { FC, useCallback, useEffect, useRef, useState } from "react";

import UserDetails from "components/UserDetails/UserDetails";

const SMCardDataRow: FC<{
  row: {
    lable: string;
    value: {
      content: string | Element | any;
      profileDetails?: {
        profileImage?: string;
        name: string;
        email: string;
      };
      color?: string;
    }[];
    hidden?: boolean;
  };
  parentRef: any;
}> = ({ row, parentRef }) => {
  const profileRef = useRef<HTMLDivElement>(null);
  const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );
  const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().left
  );

  const getPositionOfProfileName = useCallback(() => {
    setTopProfileRef(
      profileRef.current?.getBoundingClientRect().top &&
        profileRef.current?.getBoundingClientRect().top + 25
    );
    setleftProfileRef(
      profileRef.current?.getBoundingClientRect().left &&
        profileRef.current?.getBoundingClientRect().left
    );
  }, []);

  useEffect(() => {
    const eventRef = profileRef.current;
    const parentEventRef = parentRef?.current;
    window.addEventListener("resize", getPositionOfProfileName);
    window.addEventListener("scroll", getPositionOfProfileName);
    eventRef &&
      eventRef?.addEventListener("mouseenter", getPositionOfProfileName);
    parentEventRef &&
      parentEventRef?.addEventListener("scroll", getPositionOfProfileName);
    return () => {
      window.removeEventListener("resize", getPositionOfProfileName);
      window.removeEventListener("scroll", getPositionOfProfileName);
      eventRef &&
        eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPositionOfProfileName);
    };
  }, [getPositionOfProfileName, parentRef]);

  const profileRefStyle = {
    top: `${topProfileRef}px`,
    left: `${leftProfileRef}px`,
  };

  return (
    <div className={"grid grid-cols-[150px_auto] gap-2"}>
      <p className="flex justify-between whitespace-nowrap">
        <span className="text-warm-gray text-sm">{row.lable}</span>
        <span>:</span>
      </p>
      <div className="flex flex-col">
        {row?.value?.map((value, index) => {
          return (
            <div
              key={index}
              className="last:border-none pb-2 border-b w-fit whitespace-nowrap"
            >
              {typeof value.content === "string" ? (
                value?.profileDetails ? (
                  <div
                    className={`group ${
                      row?.value?.length > 1
                        ? "min-w-[150px] max-w-[500px] truncate"
                        : "w-min"
                    } `}
                    ref={profileRef}
                  >
                    <p
                      className={`text-sm text-ironside-gray ${
                        row.value?.length > 1
                          ? "min-w-[150px] max-w-[500px] truncate"
                          : "w-min"
                      } `}
                    >
                      {value?.content || "-"}
                    </p>
                    <div style={profileRefStyle}>
                      <UserDetails />
                    </div>
                  </div>
                ) : (
                  <p
                    className={`text-sm  ${
                      row.value?.length > 1
                        ? "min-w-[150px] max-w-[500px] truncate"
                        : "w-min"
                    } ${
                      value.color ? value.color : "text-ironside-gray"
                    } truncate`}
                    title={`${value?.content || "-"}`}
                  >
                    {value?.content || "-"}
                  </p>
                )
              ) : (
                value.content || "-"
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SMCardDataRow;
