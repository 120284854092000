import { IRoute } from "components/App/CustomRoutes/types/custom-routes";
import TabLink from "components/App/TabLink/TabLink";
import { ITabLink } from "components/App/TabLink/types/tab-links";

import AllProjectWorkLogs from "modules/Timer/WorkLogs/AllProjectWorkLog/AllProjectWorkLogs";
import MyProjectWorkLogs from "modules/Timer/WorkLogs/MyProjectWorkLogs/MyProjectWorkLogs";

const TimerRouteControl = () => {
  const tabLinks: ITabLink[] = [
    {
      name: "My logs",
      link: `/timer/my-logs`,
      allowedResourceIds: ["MyProject"],
    },
    // {
    //   name: "All logs",
    //   link: `/timer/all-logs`,
    //   allowedResourceIds: ["AllProject"],
    // },
  ];

  const routes: IRoute[] = [
    {
      path: "my-logs",
      element: <MyProjectWorkLogs />,
    },
    // {
    //   path: "all-logs",
    //   element: <AllProjectWorkLogs />,
    // },
  ];

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh - 22vh)] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default TimerRouteControl;
