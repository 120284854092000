import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import Loading from "components/Loader/Loading";
import OopsNoData from "components/UI/OopsNoData";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";

import { myCompensationTypes } from "modules/Leave/types/leave";
import TableDetailsGrid from "modules/Leave/Pages/Requests/MyCompOff/TableDetailsGrid";

const tableHeadings = [
  "ID",
  "Project",
  "Reported To",
  "Date",
  "Session",
  "Status",
  "Details",
];

interface IProps {
  requestData: any;
  pageSize: number;
  currentPage: number;
  setPageSize: Function;
  setCurrentPage: Function;
  totalItems: number;
  loading: boolean;
  filterMyCompensationRequests: myCompensationTypes;
}
const MyCompOffDetails: FC<IProps> = ({
  requestData,
  pageSize,
  currentPage,
  setPageSize,
  setCurrentPage,
  totalItems,
  loading,
  filterMyCompensationRequests,
}) => {
  const navigate = useNavigate();
  const onViewHandler = (request: any) => {
    navigate(`${request?.id}`, {
      state: {
        viewPageDetails: request,
      },
    });
  };
  const getDataModal = (
    date: any,
    requestId: number,
    requestToUser: { personalInformation: { id: number; name: string } }
  ) => {
    return [
      {
        lable: "ID",
        value: [
          {
            content: requestId,
          },
        ],
      },
      {
        lable: "Project",
        value: [
          {
            content: date?.project?.name,
          },
        ],
      },
      {
        lable: "Reported To",
        value: [
          {
            content: requestToUser?.personalInformation?.name,
          },
        ],
      },
      {
        lable: "Date",
        value: [
          {
            content: date?.leaveDate,
          },
        ],
      },
      {
        lable: "Session",
        value: [
          {
            content: date?.session,
          },
        ],
      },
      {
        lable: "Status",
        value: [
          {
            content: date?.status,
            color: getStatusColor(date?.status),
          },
        ],
      },
    ];
  };
  return (
    <Fragment>
      <div className="hidden md:block rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray md:mt-[5px]">
        <div className="flex flex-col bg-white justify-between md:min-h-[535px] lg:min-h-[560px]">
          <Table
            noOfItems={filterMyCompensationRequests && requestData?.length}
            currentPage={currentPage}
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={totalItems || 0}
            isCenterlastHeading={true}
            className="min-h-[590px]"
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: name === "Project" ? false : true,
              };
            })}
          >
            {requestData?.map((data: any, index: number) => {
              return <TableDetailsGrid request={data} key={index} />;
            })}
          </Table>
        </div>
      </div>
      <div className="md:hidden lg:px-[15px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] bg-white md:border-none border-light-gray md:mt-[30px]">
        <div className="flex flex-col bg-white">
          {loading ? (
            <Loading className="min-h-[400px]" />
          ) : (
            <div>
              {requestData?.length > 0 ? (
                <Fragment>
                  <div className="w-full overflow-x-auto min-h-[500px]">
                    {requestData?.map((request: any) => {
                      return request?.dates?.map((date: any, index: number) => {
                        return (
                          <SMDataCard
                            key={index}
                            title={`My Request ${index + 1}`}
                            loading={loading}
                            dataModal={getDataModal(
                              date,
                              request?.id,
                              request?.requestedToUser
                            )}
                            onView={() => {
                              onViewHandler(request);
                            }}
                          />
                        );
                      });
                    })}
                  </div>
                  <Pagination
                    totalCount={totalItems ? totalItems : 0}
                    currentPage={currentPage}
                    setPageSize={setPageSize}
                    siblingCount={1}
                    pageSize={pageSize}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    noOfItem={requestData?.length}
                  />
                </Fragment>
              ) : (
                filterMyCompensationRequests && <OopsNoData />
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MyCompOffDetails;
