export const Countries = [
  { code: "IN", label: "India", phone: "+91" },
  { code: "AD", label: "Andorra", phone: "+376" },
  { code: "AF", label: "Afghanistan", phone: "+93" },
  { code: "AG", label: "Antigua and Barbuda", phone: "+1-268" },
  { code: "AI", label: "Anguilla", phone: "+1-264" },
  { code: "AL", label: "Albania", phone: "+355" },
  { code: "AM", label: "Armenia", phone: "+374" },
  { code: "AO", label: "Angola", phone: "+244" },
  { code: "AQ", label: "Antarctica", phone: "+672" },
  { code: "AR", label: "Argentina", phone: "+54" },
  { code: "AS", label: "American Samoa", phone: "+1-684" },
  { code: "AT", label: "Austria", phone: "+43" },
  { code: "AU", label: "Australia", phone: "+61", suggested: true },
  { code: "AW", label: "Aruba", phone: "+297" },
  { code: "AX", label: "Alland Islands", phone: "+358" },
  { code: "AZ", label: "Azerbaijan", phone: "+994" },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "+387" },
  { code: "BB", label: "Barbados", phone: "+1-246" },
  { code: "BD", label: "Bangladesh", phone: "+880" },
  { code: "BE", label: "Belgium", phone: "+32" },
  { code: "BF", label: "Burkina Faso", phone: "+226" },
  { code: "BG", label: "Bulgaria", phone: "+359" },
  { code: "BH", label: "Bahrain", phone: "+973" },
  { code: "BI", label: "Burundi", phone: "+257" },
  { code: "BJ", label: "Benin", phone: "+229" },
  { code: "BL", label: "Saint Barthelemy", phone: "+590" },
  { code: "BM", label: "Bermuda", phone: "+1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "+673" },
  { code: "BO", label: "Bolivia", phone: "+591" },
  { code: "BR", label: "Brazil", phone: "+55" },
  { code: "BS", label: "Bahamas", phone: "+1-242" },
  { code: "BT", label: "Bhutan", phone: "+975" },
  { code: "BV", label: "Bouvet Island", phone: "+47" },
  { code: "BW", label: "Botswana", phone: "+267" },
  { code: "BY", label: "Belarus", phone: "+375" },
  { code: "BZ", label: "Belize", phone: "+501" },
  { code: "CA", label: "Canada", phone: "1", suggested: true },
  { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
  { code: "CD", label: "Congo", phone: "+243" },
  { code: "CF", label: "Central African Republic", phone: "+236" },
  { code: "CG", label: "Congo, Republic of the", phone: "+242" },
  { code: "CH", label: "Switzerland", phone: "+41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "+225" },
  { code: "CK", label: "Cook Islands", phone: "+682" },
  { code: "CL", label: "Chile", phone: "+56" },
  { code: "CM", label: "Cameroon", phone: "+237" },
  { code: "CN", label: "China", phone: "+86" },
  { code: "CO", label: "Colombia", phone: "+57" },
  { code: "CR", label: "Costa Rica", phone: "+506" },
  { code: "CU", label: "Cuba", phone: "+53" },
  { code: "CV", label: "Cape Verde", phone: "+238" },
  { code: "CW", label: "Curacao", phone: "+599" },
  { code: "CX", label: "Christmas Island", phone: "+61" },
  { code: "CY", label: "Cyprus", phone: "+357" },
  { code: "CZ", label: "Czech Republic", phone: "+420" },
  { code: "DE", label: "Germany", phone: "+49", suggested: true },
  { code: "DJ", label: "Djibouti", phone: "+253" },
  { code: "DK", label: "Denmark", phone: "+45" },
  { code: "DM", label: "Dominica", phone: "+1-767" },
  { code: "DO", label: "Dominican Republic", phone: "+1-809" },
  { code: "DZ", label: "Algeria", phone: "+213" },
  { code: "EC", label: "Ecuador", phone: "+593" },
  { code: "EE", label: "Estonia", phone: "+372" },
  { code: "EG", label: "Egypt", phone: "+20" },
  { code: "EH", label: "Western Sahara", phone: "+212" },
  { code: "ER", label: "Eritrea", phone: "+291" },
  { code: "ES", label: "Spain", phone: "+34" },
  { code: "ET", label: "Ethiopia", phone: "+251" },
  { code: "FI", label: "Finland", phone: "+358" },
  { code: "FJ", label: "Fiji", phone: "+679" },
  { code: "FK", label: "Falkland Islands (Malvinas)", phone: "+500" },
  { code: "FM", label: "Micronesia", phone: "+691" },
  { code: "FO", label: "Faroe Islands", phone: "+298" },
  { code: "FR", label: "France", phone: "+33", suggested: true },
  { code: "GA", label: "Gabon", phone: "+241" },
  { code: "GB", label: "United Kingdom", phone: "+44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  { code: "GS", label: "South Georgia", phone: "500" },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  { code: "HM", label: "Heard Island", phone: "672" },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
  { code: "IQ", label: "Iraq", phone: "964" },
  { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  { code: "JP", label: "Japan", phone: "81", suggested: true },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
  { code: "KP", label: "Korea", phone: "850" },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  { code: "MD", label: "Moldova, Republic of", phone: "373" },
  { code: "ME", label: "Montenegro", phone: "382" },
  { code: "MF", label: "Saint Martin (French part)", phone: "590" },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  { code: "MK", label: "Macedonia", phone: "389" },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  { code: "PS", label: "Palestine, State of", phone: "970" },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  { code: "ST", label: "Sao Tome and Principe", phone: "239" },
  { code: "SV", label: "El Salvador", phone: "503" },
  { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
  { code: "SY", label: "Syrian Arab Republic", phone: "963" },
  { code: "SZ", label: "Swaziland", phone: "268" },
  { code: "TC", label: "Turks", phone: "1-649" },
  { code: "TD", label: "Chad", phone: "235" },
  { code: "TF", label: "French ", phone: "262" },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
  { code: "TV", label: "Tuvalu", phone: "688" },
  { code: "TW", label: "Taiwan, Province of China", phone: "886" },
  { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  { code: "US", label: "United States", phone: "1", suggested: true },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
  { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784" },
  { code: "VE", label: "Venezuela", phone: "58" },
  { code: "VG", label: "British Virgin Islands", phone: "1-284" },
  { code: "VI", label: "US Virgin Islands", phone: "1-340" },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

export const countries = [
  { isdCode: "IN", country: "India", countryCode: 91 },
  { isdCode: "AD", country: "Andorra", countryCode: 376 },
  { isdCode: "AF", country: "Afghanistan", countryCode: 93 },
  { isdCode: "AG", country: "Antigua and Barbuda", countryCode: 1268 },
  { isdCode: "AI", country: "Anguilla", countryCode: 1264 },
  { isdCode: "AL", country: "Albania", countryCode: 355 },
  { isdCode: "AM", country: "Armenia", countryCode: 374 },
  { isdCode: "AO", country: "Angola", countryCode: 244 },
  { isdCode: "AQ", country: "Antarctica", countryCode: 672 },
  { isdCode: "AR", country: "Argentina", countryCode: 54 },
  { isdCode: "AS", country: "American Samoa", countryCode: 1684 },
  { isdCode: "AT", country: "Austria", countryCode: 43 },
  { isdCode: "AU", country: "Australia", countryCode: 61 },
  { isdCode: "AW", country: "Aruba", countryCode: 297 },
  { isdCode: "AX", country: "Alland Islands", countryCode: 358 },
  { isdCode: "AZ", country: "Azerbaijan", countryCode: 994 },
  { isdCode: "BA", country: "Bosnia and Herzegovina", countryCode: 387 },
  { isdCode: "BB", country: "Barbados", countryCode: 1246 },
  { isdCode: "BD", country: "Bangladesh", countryCode: 880 },
  { isdCode: "BE", country: "Belgium", countryCode: 32 },
  { isdCode: "BF", country: "Burkina Faso", countryCode: 226 },
  { isdCode: "BG", country: "Bulgaria", countryCode: 359 },
  { isdCode: "BH", country: "Bahrain", countryCode: 973 },
  { isdCode: "BI", country: "Burundi", countryCode: 257 },
  { isdCode: "BJ", country: "Benin", countryCode: 229 },
  { isdCode: "BL", country: "Saint Barthelemy", countryCode: 590 },
  { isdCode: "BM", country: "Bermuda", countryCode: 1441 },
  { isdCode: "BN", country: "Brunei Darussalam", countryCode: 673 },
  { isdCode: "BO", country: "Bolivia", countryCode: 591 },
  { isdCode: "BR", country: "Brazil", countryCode: 55 },
  { isdCode: "BS", country: "Bahamas", countryCode: 1242 },
  { isdCode: "BT", country: "Bhutan", countryCode: 975 },
  { isdCode: "BV", country: "Bouvet Island", countryCode: 47 },
  { isdCode: "BW", country: "Botswana", countryCode: 267 },
  { isdCode: "BY", country: "Belarus", countryCode: 375 },
  { isdCode: "BZ", country: "Belize", countryCode: 501 },
  { isdCode: "CA", country: "Canada", countryCode: 1 },
  { isdCode: "CC", country: "Cocos (Keeling) Islands", countryCode: 61 },
  { isdCode: "CD", country: "Congo", countryCode: 243 },
  { isdCode: "CF", country: "Central African Republic", countryCode: 236 },
  { isdCode: "CG", country: "Congo, Republic of the", countryCode: 242 },
  { isdCode: "CH", country: "Switzerland", countryCode: 41 },
  { isdCode: "CI", country: "Cote d'Ivoire", countryCode: 225 },
  { isdCode: "CK", country: "Cook Islands", countryCode: 682 },
  { isdCode: "CL", country: "Chile", countryCode: 56 },
  { isdCode: "CM", country: "Cameroon", countryCode: 237 },
  { isdCode: "CN", country: "China", countryCode: 86 },
  { isdCode: "CO", country: "Colombia", countryCode: 57 },
  { isdCode: "CR", country: "Costa Rica", countryCode: 506 },
  { isdCode: "CU", country: "Cuba", countryCode: 53 },
  { isdCode: "CV", country: "Cape Verde", countryCode: 238 },
  { isdCode: "CW", country: "Curacao", countryCode: 599 },
  { isdCode: "CX", country: "Christmas Island", countryCode: 61 },
  { isdCode: "CY", country: "Cyprus", countryCode: 357 },
  { isdCode: "CZ", country: "Czech Republic", countryCode: 420 },
  { isdCode: "DE", country: "Germany", countryCode: 49 },
  { isdCode: "DJ", country: "Djibouti", countryCode: 253 },
  { isdCode: "DK", country: "Denmark", countryCode: 45 },
  { isdCode: "DM", country: "Dominica", countryCode: 1767 },
  { isdCode: "DO", country: "Dominican Republic", countryCode: 1809 },
  { isdCode: "DZ", country: "Algeria", countryCode: 213 },
  { isdCode: "EC", country: "Ecuador", countryCode: 593 },
  { isdCode: "EE", country: "Estonia", countryCode: 372 },
  { isdCode: "EG", country: "Egypt", countryCode: 20 },
  { isdCode: "EH", country: "Western Sahara", countryCode: 212 },
  { isdCode: "ER", country: "Eritrea", countryCode: 291 },
  { isdCode: "ES", country: "Spain", countryCode: 34 },
  { isdCode: "ET", country: "Ethiopia", countryCode: 251 },
  { isdCode: "FI", country: "Finland", countryCode: 358 },
  { isdCode: "FJ", country: "Fiji", countryCode: 679 },
  { isdCode: "FK", country: "Falkland Islands (Malvinas)", countryCode: 500 },
  { isdCode: "FM", country: "Micronesia", countryCode: 691 },
  { isdCode: "FO", country: "Faroe Islands", countryCode: 298 },
  { isdCode: "FR", country: "France", countryCode: 33 },
  { isdCode: "GA", country: "Gabon", countryCode: 241 },
  { isdCode: "GB", country: "United Kingdom", countryCode: 44 },
  { isdCode: "GD", country: "Grenada", countryCode: 1473 },
  { isdCode: "GE", country: "Georgia", countryCode: 995 },
  { isdCode: "GF", country: "French Guiana", countryCode: 594 },
  { isdCode: "GG", country: "Guernsey", countryCode: 44 },
  { isdCode: "GH", country: "Ghana", countryCode: 233 },
  { isdCode: "GI", country: "Gibraltar", countryCode: 350 },
  { isdCode: "GL", country: "Greenland", countryCode: 299 },
  { isdCode: "GM", country: "Gambia", countryCode: 220 },
  { isdCode: "GN", country: "Guinea", countryCode: 224 },
  { isdCode: "GP", country: "Guadeloupe", countryCode: 590 },
  { isdCode: "GQ", country: "Equatorial Guinea", countryCode: 240 },
  { isdCode: "GR", country: "Greece", countryCode: 30 },
  { isdCode: "GS", country: "South Georgia", countryCode: 500 },
  { isdCode: "GT", country: "Guatemala", countryCode: 502 },
  { isdCode: "GU", country: "Guam", countryCode: 1671 },
  { isdCode: "GW", country: "Guinea-Bissau", countryCode: 245 },
  { isdCode: "GY", country: "Guyana", countryCode: 592 },
  { isdCode: "HK", country: "Hong Kong", countryCode: 852 },
  { isdCode: "HM", country: "Heard Island", countryCode: 672 },
  { isdCode: "HN", country: "Honduras", countryCode: 504 },
  { isdCode: "HR", country: "Croatia", countryCode: 385 },
  { isdCode: "HT", country: "Haiti", countryCode: 509 },
  { isdCode: "HU", country: "Hungary", countryCode: 36 },
  { isdCode: "ID", country: "Indonesia", countryCode: 62 },
  { isdCode: "IE", country: "Ireland", countryCode: 353 },
  { isdCode: "IL", country: "Israel", countryCode: 972 },
  { isdCode: "IM", country: "Isle of Man", countryCode: 44 },
  {
    isdCode: "IO",
    country: "British Indian Ocean Territory",
    countryCode: 246,
  },
  { isdCode: "IQ", country: "Iraq", countryCode: 964 },
  { isdCode: "IR", country: "Iran, Islamic Republic of", countryCode: 98 },
  { isdCode: "IS", country: "Iceland", countryCode: 354 },
  { isdCode: "IT", country: "Italy", countryCode: 39 },
  { isdCode: "JE", country: "Jersey", countryCode: 44 },
  { isdCode: "JM", country: "Jamaica", countryCode: 1876 },
  { isdCode: "JO", country: "Jordan", countryCode: 962 },
  { isdCode: "JP", country: "Japan", countryCode: 81 },
  { isdCode: "KE", country: "Kenya", countryCode: 254 },
  { isdCode: "KG", country: "Kyrgyzstan", countryCode: 996 },
  { isdCode: "KH", country: "Cambodia", countryCode: 855 },
  { isdCode: "KI", country: "Kiribati", countryCode: 686 },
  { isdCode: "KM", country: "Comoros", countryCode: 269 },
  { isdCode: "KN", country: "Saint Kitts and Nevis", countryCode: 1869 },
  { isdCode: "KP", country: "Korea", countryCode: 850 },
  { isdCode: "KR", country: "Korea, Republic of", countryCode: 82 },
  { isdCode: "KW", country: "Kuwait", countryCode: 965 },
  { isdCode: "KY", country: "Cayman Islands", countryCode: 1345 },
  { isdCode: "KZ", country: "Kazakhstan", countryCode: 7 },
  {
    isdCode: "LA",
    country: "Lao People's Democratic Republic",
    countryCode: 856,
  },
  { isdCode: "LB", country: "Lebanon", countryCode: 961 },
  { isdCode: "LC", country: "Saint Lucia", countryCode: 1758 },
  { isdCode: "LI", country: "Liechtenstein", countryCode: 423 },
  { isdCode: "LK", country: "Sri Lanka", countryCode: 94 },
  { isdCode: "LR", country: "Liberia", countryCode: 231 },
  { isdCode: "LS", country: "Lesotho", countryCode: 266 },
  { isdCode: "LT", country: "Lithuania", countryCode: 370 },
  { isdCode: "LU", country: "Luxembourg", countryCode: 352 },
  { isdCode: "LV", country: "Latvia", countryCode: 371 },
  { isdCode: "LY", country: "Libya", countryCode: 218 },
  { isdCode: "MA", country: "Morocco", countryCode: 212 },
  { isdCode: "MC", country: "Monaco", countryCode: 377 },
  { isdCode: "MD", country: "Moldova, Republic of", countryCode: 373 },
  { isdCode: "ME", country: "Montenegro", countryCode: 382 },
  { isdCode: "MF", country: "Saint Martin (French part)", countryCode: 590 },
  { isdCode: "MG", country: "Madagascar", countryCode: 261 },
  { isdCode: "MH", country: "Marshall Islands", countryCode: 692 },
  { isdCode: "MK", country: "Macedonia", countryCode: 389 },
  { isdCode: "ML", country: "Mali", countryCode: 223 },
  { isdCode: "MM", country: "Myanmar", countryCode: 95 },
  { isdCode: "MN", country: "Mongolia", countryCode: 976 },
  { isdCode: "MO", country: "Macao", countryCode: 853 },
  { isdCode: "MP", country: "Northern Mariana Islands", countryCode: 1670 },
  { isdCode: "MQ", country: "Martinique", countryCode: 596 },
  { isdCode: "MR", country: "Mauritania", countryCode: 222 },
  { isdCode: "MS", country: "Montserrat", countryCode: 1664 },
  { isdCode: "MT", country: "Malta", countryCode: 356 },
  { isdCode: "MU", country: "Mauritius", countryCode: 230 },
  { isdCode: "MV", country: "Maldives", countryCode: 960 },
  { isdCode: "MW", country: "Malawi", countryCode: 265 },
  { isdCode: "MX", country: "Mexico", countryCode: 52 },
  { isdCode: "MY", country: "Malaysia", countryCode: 60 },
  { isdCode: "MZ", country: "Mozambique", countryCode: 258 },
  { isdCode: "NA", country: "Namibia", countryCode: 264 },
  { isdCode: "NC", country: "New Caledonia", countryCode: 687 },
  { isdCode: "NE", country: "Niger", countryCode: 227 },
  { isdCode: "NF", country: "Norfolk Island", countryCode: 672 },
  { isdCode: "NG", country: "Nigeria", countryCode: 234 },
  { isdCode: "NI", country: "Nicaragua", countryCode: 505 },
  { isdCode: "NL", country: "Netherlands", countryCode: 31 },
  { isdCode: "NO", country: "Norway", countryCode: 47 },
  { isdCode: "NP", country: "Nepal", countryCode: 977 },
  { isdCode: "NR", country: "Nauru", countryCode: 674 },
  { isdCode: "NU", country: "Niue", countryCode: 683 },
  { isdCode: "NZ", country: "New Zealand", countryCode: 64 },
  { isdCode: "OM", country: "Oman", countryCode: 968 },
  { isdCode: "PA", country: "Panama", countryCode: 507 },
  { isdCode: "PE", country: "Peru", countryCode: 51 },
  { isdCode: "PF", country: "French Polynesia", countryCode: 689 },
  { isdCode: "PG", country: "Papua New Guinea", countryCode: 675 },
  { isdCode: "PH", country: "Philippines", countryCode: 63 },
  { isdCode: "PK", country: "Pakistan", countryCode: 92 },
  { isdCode: "PL", country: "Poland", countryCode: 48 },
  { isdCode: "PM", country: "Saint Pierre and Miquelon", countryCode: 508 },
  { isdCode: "PN", country: "Pitcairn", countryCode: 870 },
  { isdCode: "PR", country: "Puerto Rico", countryCode: 1 },
  { isdCode: "PS", country: "Palestine, State of", countryCode: 970 },
  { isdCode: "PT", country: "Portugal", countryCode: 351 },
  { isdCode: "PW", country: "Palau", countryCode: 680 },
  { isdCode: "PY", country: "Paraguay", countryCode: 595 },
  { isdCode: "QA", country: "Qatar", countryCode: 974 },
  { isdCode: "RE", country: "Reunion", countryCode: 262 },
  { isdCode: "RO", country: "Romania", countryCode: 40 },
  { isdCode: "RS", country: "Serbia", countryCode: 381 },
  { isdCode: "RU", country: "Russian Federation", countryCode: 7 },
  { isdCode: "RW", country: "Rwanda", countryCode: 250 },
  { isdCode: "SA", country: "Saudi Arabia", countryCode: 966 },
  { isdCode: "SB", country: "Solomon Islands", countryCode: 677 },
  { isdCode: "SC", country: "Seychelles", countryCode: 248 },
  { isdCode: "SD", country: "Sudan", countryCode: 249 },
  { isdCode: "SE", country: "Sweden", countryCode: 46 },
  { isdCode: "SG", country: "Singapore", countryCode: 65 },
  { isdCode: "SH", country: "Saint Helena", countryCode: 290 },
  { isdCode: "SI", country: "Slovenia", countryCode: 386 },
  { isdCode: "SJ", country: "Svalbard and Jan Mayen", countryCode: 47 },
  { isdCode: "SK", country: "Slovakia", countryCode: 421 },
  { isdCode: "SL", country: "Sierra Leone", countryCode: 232 },
  { isdCode: "SM", country: "San Marino", countryCode: 378 },
  { isdCode: "SN", country: "Senegal", countryCode: 221 },
  { isdCode: "SO", country: "Somalia", countryCode: 252 },
  { isdCode: "SR", country: "Suriname", countryCode: 597 },
  { isdCode: "SS", country: "South Sudan", countryCode: 211 },
  { isdCode: "ST", country: "Sao Tome and Principe", countryCode: 239 },
  { isdCode: "SV", country: "El Salvador", countryCode: 503 },
  { isdCode: "SX", country: "Sint Maarten (Dutch part)", countryCode: 1721 },
  { isdCode: "SY", country: "Syrian Arab Republic", countryCode: 963 },
  { isdCode: "SZ", country: "Swaziland", countryCode: 268 },
  { isdCode: "TC", country: "Turks", countryCode: 1649 },
  { isdCode: "TD", country: "Chad", countryCode: 235 },
  { isdCode: "TF", country: "French ", countryCode: 262 },
  { isdCode: "TG", country: "Togo", countryCode: 228 },
  { isdCode: "TH", country: "Thailand", countryCode: 66 },
  { isdCode: "TJ", country: "Tajikistan", countryCode: 992 },
  { isdCode: "TK", country: "Tokelau", countryCode: 690 },
  { isdCode: "TL", country: "Timor-Leste", countryCode: 670 },
  { isdCode: "TM", country: "Turkmenistan", countryCode: 993 },
  { isdCode: "TN", country: "Tunisia", countryCode: 216 },
  { isdCode: "TO", country: "Tonga", countryCode: 676 },
  { isdCode: "TR", country: "Turkey", countryCode: 90 },
  { isdCode: "TT", country: "Trinidad and Tobago", countryCode: 1868 },
  { isdCode: "TV", country: "Tuvalu", countryCode: 688 },
  { isdCode: "TW", country: "Taiwan, Province of China", countryCode: 886 },
  { isdCode: "TZ", country: "United Republic of Tanzania", countryCode: 255 },
  { isdCode: "UA", country: "Ukraine", countryCode: 380 },
  { isdCode: "UG", country: "Uganda", countryCode: 256 },
  { isdCode: "US", country: "United States", countryCode: 1 },
  { isdCode: "UY", country: "Uruguay", countryCode: 598 },
  { isdCode: "UZ", country: "Uzbekistan", countryCode: 998 },
  { isdCode: "VA", country: "Holy See (Vatican City State)", countryCode: 379 },
  {
    isdCode: "VC",
    country: "Saint Vincent and the Grenadines",
    countryCode: 1784,
  },
  { isdCode: "VE", country: "Venezuela", countryCode: 58 },
  { isdCode: "VG", country: "British Virgin Islands", countryCode: 1284 },
  { isdCode: "VI", country: "US Virgin Islands", countryCode: 1340 },
  { isdCode: "VN", country: "Vietnam", countryCode: 84 },
  { isdCode: "VU", country: "Vanuatu", countryCode: 678 },
  { isdCode: "WF", country: "Wallis and Futuna", countryCode: 681 },
  { isdCode: "WS", country: "Samoa", countryCode: 685 },
  { isdCode: "XK", country: "Kosovo", countryCode: 383 },
  { isdCode: "YE", country: "Yemen", countryCode: 967 },
  { isdCode: "YT", country: "Mayotte", countryCode: 262 },
  { isdCode: "ZA", country: "South Africa", countryCode: 27 },
  { isdCode: "ZM", country: "Zambia", countryCode: 260 },
  { isdCode: "ZW", country: "Zimbabwe", countryCode: 263 },
];
