import Row, { childNodesTypes } from "./Row";

const Tree: React.FC<{
  aclTree: {
    id: string;
    title: string;
    isAllowed: boolean;
    isIndeterminateStatus?: boolean;
    childNodes: childNodesTypes[] | [];
  }[];
  level: number;
  setChangedACLTree: Function;
  onClickHandler: Function;
  expandAll?: boolean;
}> = ({ aclTree, level, setChangedACLTree, onClickHandler, expandAll }) => {
  const items: childNodesTypes[] = aclTree;

  if (items?.length === 0) return null;

  return (
    <div>
      {items?.map((item) => (
        <Row
          item={item}
          level={level}
          key={item.id}
          setChangedACLTree={setChangedACLTree}
          onClickHandler={onClickHandler}
          expandAll={expandAll}
        >
          <Tree
            key={item?.id}
            aclTree={item?.childNodes}
            level={level + 1}
            setChangedACLTree={setChangedACLTree}
            onClickHandler={onClickHandler}
          />
        </Row>
      ))}
    </div>
  );
};

export default Tree;
