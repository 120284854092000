import { useLazyQuery, useQuery } from "@apollo/client";
import { SearchField, Select, ToggleSwitch } from "components/forms";
import { useForm } from "react-hook-form";
import TransactionDetails from "./AllTransactionsDetails";
import { useEffect, useState } from "react";
import ViewPage from "./ViewPage";
import SelectWithId from "components/forms/Select/SelectWithId";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { DateFormat } from "global/helpers/DateFormatter";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";
import { Route, Routes } from "react-router-dom";
import { paginationDefaultValue } from "global/helpers/StaticData";
import { GET_LEAVE_TYPES } from "modules/Employee/services/queries";
import { FILTER_ALL_LEAVE_TRANSACTION } from "modules/Leave/services/queries";

const AllTransactions = () => {
  const { data: leaveTypes } = useQuery(GET_LEAVE_TYPES);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const defaultDateRangeStartDate = new Date(
    `${new Date()?.getFullYear()}-${new Date()?.getMonth() + 1}-01`
  );

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: defaultDateRangeStartDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const leaveType = watch("leaveType");
  const transactionType = watch("transactionType");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());
  const viewPageDetails: any = "";
  const filterEmployee = watch("filterEmployee");
  const [search, setSearch] = useState(filterEmployee);
  const watchDateType = watch("dateType");
  const watchManual = watch("isManual");

  const [fetchLeaveTransactions, { data: filterLeaveTransaction, loading }] =
    useLazyQuery(FILTER_ALL_LEAVE_TRANSACTION, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(filterEmployee?.trim() || undefined);
    }, 300);

    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [filterEmployee]);

  useEffect(() => {
    fetchLeaveTransactions({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        transactionType: transactionType ? transactionType : undefined,
        from:
          fromDate && watchDateType !== "Created Date" ? fromDate : undefined,
        to: toDate && watchDateType !== "Created Date" ? toDate : undefined,
        createdFrom:
          fromDate && watchDateType === "Created Date" ? fromDate : undefined,
        createdTo:
          toDate && watchDateType === "Created Date" ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
        isManual: watchManual ? true : false,
      },
    });
  }, [
    pageSize,
    currentPage,
    transactionType,
    fromDate,
    toDate,
    leaveType,
    search,
    fetchLeaveTransactions,
    watchDateType,
    watchManual,
  ]);

  const [filteredTransaction, setFilteredTransaction] = useState([]);

  useEffect(() => {
    setFilteredTransaction(
      filterLeaveTransaction?.filterAllLeaveTransaction?.dataCollection.map(
        (transaction: any) => {
          return {
            employeeName: transaction?.user?.personalInformation?.name,
            employeeEmail: transaction?.user?.employeeDetail?.officialEmail,
            employeeDesignation:
              transaction?.user?.employeeDetail?.employeeDesignation,
            leaveType: transaction?.leaveType.name,
            createdAt: transaction?.createdAt,
            leaveDate: transaction?.isManual
              ? transaction?.addedLeaveDate
              : transaction?.leaveDate
              ? transaction?.leaveDate[0]?.leaveDate
              : "N/A",
            session: transaction?.leaveDate
              ? transaction?.leaveDate[0]?.session
              : "N/A",
            leaveDates: transaction?.leaveDate,
            transactionType: transaction?.transactionType,
            noOfDays: transaction?.noOfDays,
            id: transaction?.id,
            previousCount: transaction?.previousCount,
            currentCount: transaction?.currentCount,
            notes: transaction?.notes,
            userId: transaction?.user?.personalInformation?.id,
            addedLeaveDate: transaction?.addedLeaveDate,
            isManual: transaction?.isManual,
          };
        }
      )
    );
  }, [filterLeaveTransaction]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] lg:grid-cols-[repeat(3,250px)] sm:gap-x-3 sm:gap-y-0 2xl:grid-cols-[repeat(5,216px)] justify-center items-center 2xl:justify-start">
                  <div className="pb-6">
                    <SearchField
                      register={register}
                      name="filterEmployee"
                      label="Employee"
                      onChange={() => {
                        setCurrentPage(1);
                      }}
                      setValue={setValue}
                    />
                  </div>
                  <SelectWithId
                    register={register}
                    name={"leaveType"}
                    errors={errors}
                    label={"Leave Type"}
                    options={leaveTypes?.leaveTypes?.dataCollection || []}
                    enableDefaultSelectOption
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={["Credit", "Debit"]}
                    label="Transaction type"
                    name="transactionType"
                    required={true}
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={["Created Date", "Leave Date"]}
                    label="Date Type"
                    name="dateType"
                    required={true}
                    defaultSelectValue={"Leave Date"}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[216px]"}
                      classNameForDateRange="w-full min-w-[216px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center mb-8 px-[6px] mt-5">
                    <p className="text-sm text-ironside-gray">
                      {" "}
                      Is Manual Transaction?
                    </p>
                    <ToggleSwitch
                      label={"isManual"}
                      register={register}
                      name={"isManual"}
                    />
                  </div>
                </div>
              </div>
              <TransactionDetails
                leaveTransactionData={filteredTransaction}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                totalItems={
                  filterLeaveTransaction?.filterAllLeaveTransaction
                    ?.totalNumberOfItems
                }
                loading={loading}
                filterLeaveTransaction={filterLeaveTransaction}
              />
            </div>
          }
        />
        <Route path={`:${viewPageDetails?.id}`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default AllTransactions;
