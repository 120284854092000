import Input from "components/forms/UpdatedForm/Input";
import { Fragment } from "react";
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

interface IProps<TFilterForm extends FieldValues> {
  name: Path<TFilterForm>;
  register: UseFormRegister<TFilterForm>;
  dateBetween?: true;
  dateBetweenFromName?: Path<TFilterForm>;
  dateBetweenToName?: Path<TFilterForm>;
  setValue: UseFormSetValue<TFilterForm>;
}

const FilterFormDate = <TFilterForm extends Record<string, any>>({
  dateBetween,
  name,
  register,
  dateBetweenFromName,
  dateBetweenToName,
  setValue,
}: IProps<TFilterForm>) => {
  return (
    <div className="flex items-center gap-5 mt-5 flex-col">
      {dateBetween && dateBetweenFromName && dateBetweenToName ? (
        <Fragment>
          <Input
            name={dateBetweenFromName}
            register={register}
            inputType="date"
            hideError
            label="Date From"
            className="w-[200px] md:w-[300px]"
            shouldUnregister={false}
            setValue={setValue}
          />
          <Input
            name={dateBetweenToName}
            register={register}
            inputType="date"
            hideError
            label="Date To"
            className="w-[200px] md:w-[300px]"
            shouldUnregister={false}
            setValue={setValue}
          />
        </Fragment>
      ) : (
        <Input
          name={name}
          register={register}
          inputType="date"
          hideError
          label="Date"
          className="w-[200px] md:w-[300px]"
          shouldUnregister={false}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default FilterFormDate;
