import { FC, Fragment, useState } from "react";

import DataCard from "components/App/Card/DataCard";

import { makeDataModelArray } from "modules/Employee/helpers/make-data-model-array";
import { Company } from "modules/Company/types";
import EditBasicInformation from "modules/Company/Account/General/Basic/EditBasicInformation";

interface Props {
  company: Company | null;
}

const IdentificationDetails: FC<Props> = ({ company }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const companyModel = makeDataModelArray(company);

  return (
    <Fragment>
      <DataCard
        loading={false}
        resourceName="EmployeePersonalInformation"
        setShowModal={setShowModal}
        title="Identification details"
        dataModel={companyModel}
        showPencilIcon={true}
      />

      {showModal && (
        <EditBasicInformation company={company} setShowModal={setShowModal} />
      )}
    </Fragment>
  );
};

export default IdentificationDetails;
