import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Moderation from "../Pages/Moderation/Moderation";
import ViewPage from "../Pages/Moderation/ViewPage";

const ModerationRouteControl = () => {
  const { pathname } = useLocation();
  const [moderationId, setModerationId] = useState<string>("");

  return (
    <Routes>
      <Route
        path="/"
        element={<Moderation setModerationId={setModerationId} />}
      />
      {!isNaN(+pathname?.split("/")[3]) && (
        <Route
          path={`:${moderationId || +pathname?.split("/")[3]}`}
          element={<ViewPage />}
        />
      )}
    </Routes>
  );
};

export default ModerationRouteControl;
