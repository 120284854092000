import { FC } from "react";
import { getFileExtension } from "global/helpers/getFileExtension";
import { IProjectTaskHistory } from "modules/Project/types/project";
import { HiArrowRight } from "react-icons/hi";

interface IProps {
	history: IProjectTaskHistory;
}

const History: FC<IProps> = ({ history }) => {
	const getProfileImage = (history: IProjectTaskHistory) => {
		const filterProfileImage =
			(history?.performedByUser?.userProfileImages &&
				history?.performedByUser?.userProfileImages?.length > 0 &&
				history?.performedByUser?.userProfileImages?.filter(
					(userProfileImage: any) => userProfileImage.type === "Profile",
				)[0]) ||
			null;

		const profileImage: any =
			filterProfileImage?.documents &&
			filterProfileImage?.documents?.length > 0 &&
			filterProfileImage?.documents[0]?.file;

		return profileImage?.length > 100 ? (
			<img
				className="max-w-[32px] max-h-[32px] rounded-[50%] object-fit"
				src={`data:image/${
					getFileExtension(profileImage) === "svg"
						? "svg+xml"
						: getFileExtension(profileImage)
				};base64,${profileImage}`}
				alt="No File"
			/>
		) : (
			<div className="w-8 h-8 rounded-[50%] bg-cornflower-blue text-white text-[20px] text-center">
				{history?.performedByUser?.personalInformation &&
					history?.performedByUser?.personalInformation?.name[0]}
			</div>
		);
	};

	return (
		<div className="flex mb-5 gap-4">
			<div>{getProfileImage(history)}</div>
			<div>
				<span className="text-[14px] leading-[19px] text-[#626364] inline-block min-w-[120px] font-bold">
					{history?.performedByUser?.personalInformation?.name}
				</span>
				<span className="ml-2 inline-block mb-[9px] text-[12px] leading-[14px] text-[#A4A4A5]">
					{history?.createdAt}
				</span>
				<p className="mb-[9px] text-[14px] text-[#626364]">
					{history?.message}
				</p>
				<div className="flex items-center gap-2">
					<span className="bg-[#deebef] py-[2px] px-1 text-[#42526E]">
						{history?.previousValue || "None"}
					</span>
					<HiArrowRight className="min-w-[16px] h-4" />
					<span className="text-[#0747a6] bg-[#deebef] py-[2px] px-1">
						{history?.currentValue || "None"}
					</span>
				</div>
			</div>
		</div>
	);
};

export default History;
