import UserDetails from "components/UserDetails/UserDetails";
import { useCallback, useEffect, useRef, useState } from "react";

const MyTeamMembersDetails: React.FC<{
  className?: string;
  index: number;
  teamMembers: any;
  memberDetails: any;
  userId: number | undefined;
  setUserId: Function;
}> = ({ className, index, teamMembers, memberDetails, userId, setUserId }) => {
  const profileRef = useRef<HTMLDivElement>(null);
  const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );
  const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );

  const getPositionOfProfileName = useCallback(() => {
    setTopProfileRef(
      profileRef.current?.getBoundingClientRect().top &&
        profileRef.current?.getBoundingClientRect().top + 25
    );
    setleftProfileRef(
      profileRef.current?.getBoundingClientRect().left &&
        profileRef.current?.getBoundingClientRect().left
    );
  }, []);
  useEffect(() => {
    const eventRef = profileRef.current;
    window.addEventListener("resize", getPositionOfProfileName);
    window.addEventListener("scroll", getPositionOfProfileName);

    eventRef &&
      eventRef?.addEventListener("mouseenter", getPositionOfProfileName);

    return () => {
      window.removeEventListener("resize", getPositionOfProfileName);
      window.removeEventListener("scroll", getPositionOfProfileName);
      eventRef &&
        eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
    };
  }, [getPositionOfProfileName]);

  return (
    <div
      key={index}
      className={`flex ${
        index === teamMembers?.members?.length - 1 ? "border-none" : "border-b"
      } border-slate-100 py-3`}
    >
      <div className="group w-min" ref={profileRef}>
        <span
          className="py-1 md:whitespace-nowrap"
          onMouseEnter={() => setUserId(memberDetails?.id)}
        >
          {`${memberDetails?.personalInformation?.name} ${
            teamMembers?.teamLeadUser?.id === memberDetails?.id ? "(TL)" : ""
          }`}
        </span>
        <div
          style={{
            top: `${topProfileRef}px`,
            left: `${leftProfileRef}px`,
          }}
        >
          <UserDetails userId={userId} />
        </div>
      </div>
    </div>
  );
};

export default MyTeamMembersDetails;
