import { FC } from "react";
import { Control, UseFormSetValue } from "react-hook-form";

import Filter from "components/FilterUpdated/Filter";
import { FilterOptions } from "components/FilterUpdated/types";

import { countNonEmptyKeys } from "global/helpers/removeUndefinedValuesFromObject";
import {
	FetchMoreType,
	FilterFormType,
	ISetStateType,
} from "global/types/type";
import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import {
	bugAndTaskPlatforms,
	bugAndTaskPriorities,
} from "global/helpers/utils";

import {
	FilterProjectGroupBugArgs,
	FilterProjectGroupBugReturnType,
	ProjectGroupBugFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";
import {
	FILTER_PROJECT_GROUP_BUG_SOURCES,
	FILTER_PROJECT_GROUP_BUG_TITLES,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/services";
import { filterSubmitValues } from "modules/Project/Pages/Projects/ProjectGroupBugs/helpers/utils";

interface Props {
	control: Control<FilterFormType<ProjectGroupBugFilterFormType>, any>;
	setValue: UseFormSetValue<FilterFormType<ProjectGroupBugFilterFormType>>;
	search: string;
	projectGroupBugsLength: number;
	canCreate: boolean;
	addBtnHandler: () => void;
	pageSize: number | undefined;
	fetchMore: FetchMoreType<
		FilterProjectGroupBugReturnType,
		FilterProjectGroupBugArgs
	>;
	filterValues: ProjectGroupBugFilterFormType | null | undefined;
	setFilterValues: ISetStateType<
		ProjectGroupBugFilterFormType | null | undefined
	>;
	projectGroupId: number | undefined;
}

const ProjectGroupBugFilter: FC<Props> = ({
	control,
	setValue,
	search,
	projectGroupBugsLength,
	canCreate,
	addBtnHandler,
	fetchMore,
	pageSize,
	filterValues,
	setFilterValues,
	projectGroupId,
}) => {
	const hideGlobalSearchAddBtnAndFilterBtn =
		search?.length === 0 &&
		projectGroupBugsLength === 0 &&
		countNonEmptyKeys(filterValues) === 0;

	const onSearchChange = (_page) => {
		fetchMore({
			variables: {
				globalSearch: search || null,
			},
			updateQuery: (_prev, { fetchMoreResult: { filterProjectGroupBugs } }) => {
				return {
					filterProjectGroupBugs,
				};
			},
		}).catch((error) => {
			toastNotify(errorMessageNotify(error));
		});
	};

	const onClear = () => {
		fetchMore({
			variables: {
				pagination: {
					size: pageSize,
				},
				status: {
					isExactly: "pending",
				},
			},
			updateQuery: (_prev, { fetchMoreResult: { filterProjectGroupBugs } }) => {
				return {
					filterProjectGroupBugs,
				};
			},
		}).catch((error) => {
			toastNotify(errorMessageNotify(error));
		});
	};

	const onSubmit: (
		values: FilterFormType<ProjectGroupBugFilterFormType>,
	) => void = ({ filter }) => {
		if (filter) {
			fetchMore({
				variables: {
					filters:
						filter && projectGroupId
							? {
									projectGroupId: {
										number: +projectGroupId,
									},
									...filterSubmitValues(filter),
							  }
							: projectGroupId
							  ? {
										projectGroupId: {
											number: +projectGroupId,
										},
										status: {
											isExactly: "pending",
										},
								  }
							  : undefined,
					pagination: {
						size: pageSize,
					},
					globalSearch: search || undefined,
				},
				updateQuery: (
					_prev,
					{ fetchMoreResult: { filterProjectGroupBugs } },
				) => {
					return {
						filterProjectGroupBugs,
					};
				},
			}).catch((error) => {
				toastNotify(errorMessageNotify(error));
			});
		}
	};

	const filterOptions: FilterOptions<
		FilterFormType<ProjectGroupBugFilterFormType>
	> = [
		{
			name: "Title",
			fieldOption: {
				name: "filter.title",
				label: "Title",
				type: "string",
				query: FILTER_PROJECT_GROUP_BUG_TITLES,
				multiple: true,
			},
		},
		{
			name: "Description",
			fieldOption: {
				name: "filter.description",
				type: "textArea",
				label: "Description",
			},
		},
		{
			name: "Platform",
			fieldOption: {
				type: "checkbox",
				name: "filter.platform",
				options: bugAndTaskPlatforms,
				multiple: true,
			},
		},
		{
			name: "Priority",
			fieldOption: {
				type: "checkbox",
				name: "filter.priority",
				options: bugAndTaskPriorities,
				multiple: true,
			},
		},
		{
			name: "Source",
			fieldOption: {
				name: "filter.source",
				label: "Source",
				type: "string",
				query: FILTER_PROJECT_GROUP_BUG_SOURCES,
			},
		},
		{
			name: "Status",
			fieldOption: {
				name: "filter.status",
				type: "checkbox",
				multiple: true,
				options: ["Moved", "Abandoned", "Accepted", "Duplicate", "Pending"],
			},
		},
	];

	return (
		<Filter
			control={control}
			filterValues={filterValues}
			setValue={setValue}
			setFilterValues={setFilterValues}
			options={filterOptions}
			globalSearchName="search"
			hideAddBtn={hideGlobalSearchAddBtnAndFilterBtn || !canCreate}
			hideFilterBtn={hideGlobalSearchAddBtnAndFilterBtn}
			hideGlobalSearch={hideGlobalSearchAddBtnAndFilterBtn}
			addBtnDisabled={!canCreate}
			addBtnClickHandler={addBtnHandler}
			onSearchChange={onSearchChange}
			onSubmit={onSubmit}
			onClear={onClear}
			className="mt-5"
		/>
	);
};

export default ProjectGroupBugFilter;
