import { FC, useEffect, useState } from "react";
import { HiDocumentText, HiFolder, HiFolderOpen } from "react-icons/hi";

import { CustomeCheckbox } from "components/forms";

import { ReactComponent as ChevronRightIcon } from "global/assets/images/and-design-down-arrow.svg";

import { ReactNode } from "react";

export interface childNodesTypes {
  id: string;
  title: string;
  isAllowed: boolean;
  isIndeterminateStatus?: boolean;
  childNodes: childNodesTypes[] | [];
}
interface IProps {
  item: {
    id: string;
    title: string;
    isAllowed: boolean;
    isIndeterminateStatus?: boolean;
    childNodes: childNodesTypes[] | [];
  };
  level: number;
  setChangedACLTree: Function;
  onClickHandler: Function;
  expandAll?: boolean | any;
  children?: ReactNode;
}

const Row: FC<IProps> = ({
  item,
  level,
  children,
  setChangedACLTree,
  onClickHandler,
  expandAll,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (expandAll === undefined) {
      setIsCollapsed(true);
    } else if (expandAll === false) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(expandAll);
    }
  }, [setIsCollapsed, expandAll]);

  const entityIcon =
    item.childNodes?.length !== 0 ? (
      isCollapsed ? (
        <HiFolder className="w-4 h-4 text-cornflower-blue mx-1" />
      ) : (
        <HiFolderOpen className="w-4 h-4 text-cornflower-blue mx-1" />
      )
    ) : (
      <HiDocumentText className="w-4 h-4 text-cornflower-blue mx-1" />
    );

  const [isChecked, setIsChecked] = useState(() => item.isAllowed);

  useEffect(() => {
    setIsChecked(item.isAllowed);
  }, [item.isAllowed]);
  const changeHandler = () => {
    setIsChecked(!isChecked);
    onClickHandler();
    setChangedACLTree({ ...item, isAllowed: !isChecked });
  };

  return (
    <div key={item?.id}>
      <div
        style={{ paddingLeft: `${level * 20}px` }}
        className={`flex items-center w-min ${
          item?.childNodes?.length > 0 ? "" : "ml-[24px]"
        }`}
      >
        {item?.childNodes?.length > 0 ? (
          <span>
            <ChevronRightIcon
              className={`w-4 h-4 text-blue-900 cursor-pointer mx-1 ${
                isCollapsed ? "" : "-rotate-90"
              }`}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          </span>
        ) : null}
        <CustomeCheckbox
          isChecked={isChecked}
          isIndeterminate={item?.isIndeterminateStatus ? true : false}
          onClick={changeHandler}
        />
        {entityIcon}
        <span className="text-ironside-gray whitespace-nowrap">
          {item?.title}
        </span>
      </div>
      <div
        className={`${isCollapsed ? "block" : "hidden"} transition-all ml-5`}
      >
        {children}
      </div>
    </div>
  );
};

export default Row;
