import { EffectCallback, useEffect, useState } from "react";

const useEffectOnce = (effect: EffectCallback) => {
  const [runOnce, setRunOnce] = useState(true);
  useEffect(() => {
    if (runOnce) {
      effect();
      setRunOnce(false);
    }
  }, [effect, runOnce]);
};

export default useEffectOnce;
