import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { Filled, Modal, Outlined } from "components/forms";
import TextArea from "components/forms/UpdatedForm/TextArea";
import Input from "components/forms/UpdatedForm/Input";
import Select from "components/forms/UpdatedForm/Select";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import {
  IProjectColumn,
  IProjectColumnForm,
} from "modules/Project/types/project";
import {
  CHANGE_MAIN_COLUMN_POSITION,
  SAVE_PROJECT_SETTINGS_COLUMN,
} from "modules/Project/services/mutations";

const EditSettingsColumns: React.FC<{
  editProjectColumn: IProjectColumn | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectColumns: Function;
}> = ({ editProjectColumn, setShowModal, loading, refetchProjectColumns }) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const createOrUpdateAccess = allowedResources.includes("SaveColumn");

  const [saveColumn, { loading: saveColumnLoading }] = useMutation(
    SAVE_PROJECT_SETTINGS_COLUMN
  );

  const [changeMainColumnPosition, { loading: columnPositionLoading }] =
    useMutation(CHANGE_MAIN_COLUMN_POSITION);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IProjectColumnForm>();

  const createOrUpdateColumn: SubmitHandler<IProjectColumnForm> = ({
    name,
    description,
    colour,
    position,
  }) => {
    if (
      editProjectColumn?.id &&
      position !== editProjectColumn?.position &&
      !columnPositionLoading
    ) {
      changeMainColumnPosition({
        variables: {
          id: +editProjectColumn?.id,
          position,
        },
      });
    }
    if (!saveColumnLoading) {
      saveColumn({
        variables: {
          columnInput: {
            id: editProjectColumn?.id || undefined,
            name,
            description: editProjectColumn?.id
              ? description
              : description || undefined,
            colour,
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          refetchProjectColumns();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `Project label ${
                editProjectColumn?.id
                  ? editProjectColumn?.name
                  : response?.data?.saveProjectTaskStatus?.name
              } has been successfully ${
                editProjectColumn?.id ? "updated" : "added"
              } `,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Column</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateColumn)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name"
                  name="name"
                  setValue={setValue}
                  defaultValue={editProjectColumn?.name}
                  required
                />
                <TextArea
                  errors={errors}
                  label="Description"
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectColumn?.description}
                />
                {editProjectColumn?.id && (
                  <Input
                    register={register}
                    errors={errors}
                    label="Position "
                    name="position"
                    defaultValue={editProjectColumn?.position || ""}
                    setValue={setValue}
                    inputType="number"
                    fractionalValue
                    maxLength={7}
                    className="col-span-2 sm:col-span-1"
                    minimumNumber={1}
                  />
                )}
                <Select
                  control={control}
                  errors={errors}
                  label="Color"
                  name="colour"
                  options={[
                    "Cyan-Blue",
                    "Tealish-Blue",
                    "Yellowish Orange",
                    "Green",
                  ]}
                  defaultValue={editProjectColumn?.colour || "Cyan-Blue"}
                  setValue={setValue}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  loading={saveColumnLoading}
                  buttonName={editProjectColumn?.id ? "Update" : "Add"}
                  disabled={saveColumnLoading || !createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditSettingsColumns;
