import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { getLocalTimeZone, now, today } from "@internationalized/date";
import { DateRange, DateValue } from "react-aria";

import DatePicker from "components/forms/UpdatedFormAgry/Date/Date/DatePicker";
import { DateProps } from "components/forms/UpdatedFormAgry/Date/Date/types";
import DateRangePicker from "components/forms/UpdatedFormAgry/Date/Date/DateRangePicker";

const Date = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus,
  defaultOpen,
  defaultValue,
  description,
  granularity,
  isDateUnavailable,
  disabled,
  isOpen,
  isReadOnly,
  required,
  label,
  maxValue,
  minValue,
  onBlur,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onOpenChange,
  className,
  name,
  control,
  classNameForError,
  shouldUnregister,
  type = "date",
  onChange,
  hideError,
  shouldCloseOnSelect,
  shouldForceLeadingZeros,
  pageBehavior,
  allowsNonContiguousRanges,
  notClearable,
  isInvalid,
}: DateProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends
    | DateValue
    | DateRange
    | null
    | undefined
    ? {
        helperType?: undefined;
        invalidType?: undefined;
      }
    : {
        helperType?: undefined;
        invalidType: "Type must be DateValue | DateRange | null | undefined";
      })) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={
        defaultValue
          ? defaultValue
          : type === "date"
          ? (today(getLocalTimeZone()) as PathValue<TFieldValues, Name>)
          : type === "date-time"
          ? (now(getLocalTimeZone()) as PathValue<TFieldValues, Name>)
          : type === "date-range"
          ? ({
              start: today(getLocalTimeZone()),
              end: today(getLocalTimeZone()),
            } as PathValue<TFieldValues, Name>)
          : type === "date-time-range"
          ? ({
              start: now(getLocalTimeZone()),
              end: now(getLocalTimeZone()),
            } as PathValue<TFieldValues, Name>)
          : (null as PathValue<TFieldValues, Name>)
      }
      shouldUnregister={shouldUnregister}
      rules={{
        required:
          typeof required === "boolean" && required === true
            ? "This is required field."
            : required
            ? required
            : false,
        onChange: (e: any) => {
          if (onChange) {
            onChange(e.target?.value);
          }
        },
      }}
      render={({
        field: { value: hookFormValue, onChange: onChangeHookForm },
        fieldState: { error },
      }) => {
        return type === "date" || type === "date-time" ? (
          <DatePicker
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            defaultValue={hookFormValue}
            description={description}
            errorMessage={error?.message}
            granularity={granularity}
            isDateUnavailable={isDateUnavailable}
            disabled={disabled}
            isOpen={isOpen}
            isReadOnly={isReadOnly}
            isRequired={required ? true : false}
            label={label}
            maxValue={maxValue}
            minValue={minValue}
            onBlur={onBlur}
            onChangeHookForm={onChangeHookForm}
            onFocus={onFocus}
            onFocusChange={onFocusChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onOpenChange={onOpenChange}
            placeholderValue={
              type === "date"
                ? today(getLocalTimeZone())
                : now(getLocalTimeZone())
            }
            isInvalid={isInvalid}
            className={className}
            classNameForError={classNameForError}
            type={type}
            value={hookFormValue}
            hideError={hideError}
            shouldCloseOnSelect={shouldCloseOnSelect}
            pageBehavior={pageBehavior}
            shouldForceLeadingZeros={shouldForceLeadingZeros}
            notClearable={notClearable}
          />
        ) : (
          <DateRangePicker
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            defaultValue={hookFormValue}
            description={description}
            errorMessage={error?.message}
            granularity={granularity}
            isDateUnavailable={isDateUnavailable}
            disabled={disabled}
            isOpen={isOpen}
            isReadOnly={isReadOnly}
            isRequired={required ? true : false}
            label={label}
            maxValue={maxValue}
            minValue={minValue}
            onBlur={onBlur}
            onChangeHookForm={onChangeHookForm}
            onFocus={onFocus}
            onFocusChange={onFocusChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onOpenChange={onOpenChange}
            placeholderValue={
              type === "date-range"
                ? today(getLocalTimeZone())
                : type === "date-time-range"
                ? now(getLocalTimeZone())
                : undefined
            }
            isInvalid={isInvalid}
            className={className}
            classNameForError={classNameForError}
            type={type}
            value={hookFormValue}
            hideError={hideError}
            shouldCloseOnSelect={shouldCloseOnSelect}
            pageBehavior={pageBehavior}
            shouldForceLeadingZeros={shouldForceLeadingZeros}
            allowsNonContiguousRanges={allowsNonContiguousRanges}
            notClearable={notClearable}
          />
        );
      }}
    />
  );
};

export default Date;
