import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useMemo } from "react";

import { Filled, Modal, Outlined } from "components/forms";

import { toastNotify, userContextData } from "global/helpers/Cache";

import { CREATE_OR_UPDATE_PROJECT_MEMBER } from "modules/Project/services/mutations";
import {
  IProjectMembers,
  IProjectMemberForm,
} from "modules/Project/types/project";
import { GET_ALL_EMPLOYEES_DROP_DOWN_WITH_USER_TYPE } from "modules/Employee/services/queries";
import {
  GET_ALL_PROJECT_ROLES_FOR_DROP_DOWN,
  PROJECTS,
} from "modules/Project/services/queries";
import {  GET_ALL_CLIENTS_DROP_DOWN_WITH_USER_TYPE } from "modules/Organization/Pages/Clients/services/queries";
import { AutoComplete, Select } from "components/forms/UpdatedFormAgry";

const EditMember: React.FC<{
  editProjectMember: IProjectMembers | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectMembers: Function;
  editProject: { id: number; name: string };
  allowedResourcesForProject: string[];
  isManager: boolean;
}> = ({
  editProjectMember,
  setShowModal,
  loading,
  refetchProjectMembers,
  editProject,
  allowedResourcesForProject,
  isManager,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
  const createOrUpdateAccess =
    isManager ||
    allowedResourcesForProject?.includes("SaveProjectMember") ||
    isSuperAdmin;
  const { data: allEmployees } = useQuery(
    GET_ALL_EMPLOYEES_DROP_DOWN_WITH_USER_TYPE,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: getAllClients } = useQuery(GET_ALL_CLIENTS_DROP_DOWN_WITH_USER_TYPE, {
    fetchPolicy: "cache-and-network",
    variables: {
      isClientUserTypeNeed: true,
    },
  });

  const { data: getAllProjectRolesForDropDown } = useQuery(
    GET_ALL_PROJECT_ROLES_FOR_DROP_DOWN
  );

  const [saveProjectMember, { loading: saveProjectMemberLoading }] =
    useMutation(CREATE_OR_UPDATE_PROJECT_MEMBER, {
      refetchQueries: [PROJECTS],
    });

  const { reset, handleSubmit, control } = useForm<IProjectMemberForm>({
    defaultValues: {
      member:
        editProjectMember?.user?.personalInformation?.id &&
        editProjectMember?.user?.personalInformation?.name &&
        editProjectMember?.user?.userType
          ? {
              id: editProjectMember?.user?.personalInformation?.id,
              label: editProjectMember?.user?.personalInformation?.name,
              userType: editProjectMember?.user?.userType,
            }
          : (null as unknown as {
              id: number;
              label: string;
              userType: "Employee" | "Client";
            }),
      role:
        editProjectMember?.projectRole?.id &&
        editProjectMember?.projectRole?.name
          ? {
              id: editProjectMember?.projectRole?.id,
              label: editProjectMember?.projectRole?.name,
            }
          : (null as unknown as { id: number; label: string }),
    },
  });

  const watchMember = useWatch({
    control,
    name: "member",
  });

  const employeeLists = useMemo(
    () =>
      allEmployees?.getAllEmployeesForDropDown?.map(
        (employee: {
          personalInformation: { id: number; name: string };
          userType: string;
        }) => ({
          id: employee?.personalInformation?.id,
          label: employee?.personalInformation?.name,
          userType: employee?.userType,
        })
      ) || [],
    [allEmployees?.getAllEmployeesForDropDown]
  );

  // const clientsLists = useMemo(
  //   () =>
  //     getAllClients?.getAllClients?.dataCollection?.map(
  //       (client: {
  //         personalInformation: { id: number; name: string };
  //         userType: string;
  //       }) => ({
  //         id: client?.personalInformation?.id,
  //         label: client?.personalInformation?.name,
  //         userType: client?.userType,
  //       })
  //     ) || [],
  //   [getAllClients?.getAllClients?.dataCollection]
  // );

   const clientsLists = useMemo(
    () =>
      getAllClients?.getAllClientsForDropDown?.map(
        (employee: {
          personalInformation: { id: number; name: string };
          userType: string;
        }) => ({
          id: employee?.personalInformation?.id,
          label: employee?.personalInformation?.name,
          userType: employee?.userType,
        })
      ) || [],
    [getAllClients?.getAllClientsForDropDown]
  );

  const roleList =
    watchMember?.userType === "Client"
      ? getAllProjectRolesForDropDown?.projectRoles?.dataCollection
          ?.filter((role) => role?.name === "Client")
          ?.map((role) => ({
            id: role?.id,
            label: role?.name,
          })) || []
      : getAllProjectRolesForDropDown?.projectRoles?.dataCollection
          ?.filter((role) => role?.name !== "Client")
          ?.map((role) => ({
            id: role?.id,
            label: role?.name,
          })) || [];

  const createOrUpdateProjectMember: SubmitHandler<IProjectMemberForm> = ({
    role,
    member,
  }) => {
    if (!saveProjectMemberLoading) {
      saveProjectMember({
        variables: {
          projectMember: {
            id: editProjectMember?.id || undefined,
            project: editProject?.id,
            user: member?.id,
            projectRole: role?.id,
          },
        },
      })
        .then((response) => {
          const member =
            response?.data?.saveProjectMember?.user?.personalInformation?.name;
          setShowModal(false);
          refetchProjectMembers();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${member} has been successfully added in ${editProject?.name} as ${response?.data?.saveProjectMember?.projectRole?.name}`,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Member</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectMember)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <AutoComplete
                  control={control}
                  label="Member *"
                  name="member"
                  options={
                    clientsLists && clientsLists?.length > 0
                      ? [...employeeLists, ...clientsLists]
                      : employeeLists
                  }
                  className="bg-white"
                />
                <Select
                  control={control}
                  label="Role *"
                  name="role"
                  options={roleList}
                  className="bg-white"
                  disabled={!watchMember?.id}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  loading={saveProjectMemberLoading}
                  buttonName={editProjectMember?.id ? "Update" : "Add"}
                  disabled={saveProjectMemberLoading || !createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditMember;
