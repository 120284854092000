import { useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

const ViewPageBackArrowBtn = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center">
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => navigate("/settings/moderation")}
      />
    </div>
  );
};

export default ViewPageBackArrowBtn;
