import { useReactiveVar } from "@apollo/client";
import { getAllowedAccess, userContextData } from "global/helpers/Cache";
import { NavLink, Route, Routes } from "react-router-dom";
import MyTeamInfo from "./Pages/MyTeamInfo/MyTeamInfo";
import MyTeams from "./Pages/MyTeams/MyTeams";
import AllTeams from "./Pages/AllTeams/AllTeams";
import { useRef } from "react";

const Team = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const teamLeadAccess: any = useReactiveVar(userContextData);
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;
  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] bg-white">
      <div>
        <div
          ref={slider}
          onMouseDown={(e) => {
            isDown = true;
            startX = e?.pageX - slider.current?.offsetLeft;
            scrollLeft = slider?.current?.scrollLeft;
          }}
          onMouseLeave={() => {
            isDown = false;
          }}
          onMouseUp={() => {
            isDown = false;
          }}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider?.current?.offsetLeft;
            const walk = (x - startX) * 1;
            slider.current.scrollLeft = scrollLeft - walk;
          }}
          className="tab-nav"
        >
          <NavLink
            className={({ isActive }) =>
              `tab-nav-item ${isActive ? "active" : ""}`
            }
            to={"/team/my-team-info"}
          >
            My Team Info
          </NavLink>
          {teamLeadAccess?.user?.isTeamLead ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/team/my-teams"}
            >
              My Teams
            </NavLink>
          ) : null}
          {allowedResources?.includes("ReadAllEmployeeTeam") ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/team/all-teams"}
            >
              All Teams
            </NavLink>
          ) : null}
        </div>
        <Routes>
          <Route path="my-team-info" element={<MyTeamInfo />} />
          <Route path="my-teams" element={<MyTeams />} />
          <Route
            path="all-teams"
            element={<AllTeams allowedResources={allowedResources} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Team;
