import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import { Modal, Outlined, Select, Filled } from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { GET_SKILLS_DETAILS } from "modules/Employee/services/queries";
import {
  CREATE_SKILL,
  UPDATE_SKILL,
} from "modules/Employee/services/mutations";
import { IEditSkill, ISkillsList } from "modules/Employee/types/skills";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";

interface IProps {
  userSkillTypes: string[];
  setShowModal: Function;
  setEditSkill: Function;
  editSkill: IEditSkill | null;
  skillTypes: string[];
  technicalSkills: ISkillsList[];
  nonTechnicalSkills: ISkillsList[];
  loading: boolean;
  userTechnicalSkillsModerationIds: number[];
  userNonTechnicalSkillsModerationIds: number[];
  refetchSkillDetails: Function;
}

const EditSkillDetails: FC<IProps> = ({
  userSkillTypes,
  setShowModal,
  setEditSkill,
  editSkill,
  skillTypes,
  technicalSkills,
  nonTechnicalSkills,
  loading,
  userTechnicalSkillsModerationIds,
  userNonTechnicalSkillsModerationIds,
  refetchSkillDetails,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [watchedSkillType, setWatchedSkillType] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);

  const isTechnicalSkill =
    editSkill?.type === "Technical" || watchedSkillType === "Technical";

  const isNonTechnicalSkill =
    editSkill?.type === "Non Technical" || watchedSkillType === "Non Technical";

  const technicalSkillsList = technicalSkills?.filter((technicalSkill) => {
    return editSkill?.skills?.list?.find((skill: ISkillsList) => {
      if (technicalSkill?.id === skill?.id) {
        return technicalSkill;
      }
      return undefined;
    });
  });

  const nonTechnicalSkillsList = nonTechnicalSkills?.filter(
    (nonTechnicalSkill) => {
      return editSkill?.skills?.list?.find((skill: ISkillsList) => {
        if (nonTechnicalSkill?.id === skill?.id) return nonTechnicalSkill;
        return undefined;
      });
    }
  );

  const [skillList, setSkillList] = useState<ISkillsList[]>(
    isTechnicalSkill
      ? technicalSkillsList
      : isNonTechnicalSkill
      ? nonTechnicalSkillsList
      : []
  );

  useEffect(() => {
    setSkillList(
      editSkill?.type === "Technical" || watchedSkillType === "Technical"
        ? technicalSkills?.filter((technicalSkill) => {
            return editSkill?.skills?.list?.find((skill: ISkillsList) => {
              if (technicalSkill?.id === skill?.id) {
                return technicalSkill;
              }
              return undefined;
            });
          })
        : editSkill?.type === "Non Technical" ||
          watchedSkillType === "Non Technical"
        ? nonTechnicalSkills?.filter((nonTechnicalSkill) => {
            return editSkill?.skills?.list?.find((skill: ISkillsList) => {
              if (nonTechnicalSkill?.id === skill?.id) return nonTechnicalSkill;
              return undefined;
            });
          })
        : []
    );
  }, [
    editSkill?.skills?.list,
    editSkill?.type,
    nonTechnicalSkills,
    technicalSkills,
    watchedSkillType,
  ]);

  useEffect(() => {
    if (skillList?.length > 0) {
      setSkillListError(false);
    } else {
      setSkillListError(!skillList);
    }
  }, [skillList]);

  const [skillListError, setSkillListError] = useState<boolean>(false);

  const [createUserSkill] = useMutation(CREATE_SKILL);
  const [updateUserSkill] = useMutation(UPDATE_SKILL);

  const isUnderModeration = useIsUnderModeration();

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Skill")
    : createSuccessMessageWithAwaiting("Skill");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Skill")
    : updateSuccessMessageWithAwaiting("Skill");

  const createHandler = () => {
    if (!(skillList?.length > 0)) setSkillListError(true);
    else if (!inProgress) {
      createUserSkill({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          skillIds: skillList?.map((skill: { id: number }) => skill?.id),
        },

        update: (cache, { data: { createUserSkill } }) => {
          const exSkills: any = cache.readQuery({
            query: GET_SKILLS_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });
          cache.writeQuery({
            query: GET_SKILLS_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exSkills?.users?.dataCollection[0],
                    userSkills: createUserSkill,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: any) => {
    const previousSkillsList =
      editSkill?.skills?.list?.map((skill: { id: number }) => skill?.id) || [];
    const chosenSkills = data?.skills?.map(
      (skill: { id: number }) => skill?.id
    );

    const skillIdsToAdd = chosenSkills?.filter(
      (skillID: number) => !previousSkillsList?.includes(skillID)
    );

    const skillIdsToRemove = previousSkillsList?.filter(
      (skillID: number) => !chosenSkills?.includes(skillID)
    );

    if (!(skillList?.length > 0)) setSkillListError(true);
    else if (!inProgress) {
      const sessionId: string | any = document.cookie
        .split("; ")
        .find((row) => row.startsWith("id="))
        ?.split("=")[1];
      updateUserSkill({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : +localStorage?.id || +sessionId,
          skillIdsToAdd: skillIdsToAdd?.length > 0 ? skillIdsToAdd : undefined,
          skillIdsToRemove:
            skillIdsToRemove?.length > 0 ? skillIdsToRemove : undefined,
        },
        update: (cache, { data: { updateUserSkill } }) => {
          const exSkills: any = cache.readQuery({
            query: GET_SKILLS_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });
          cache.writeQuery({
            query: GET_SKILLS_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exSkills?.users?.dataCollection[0],
                    userSkills: updateUserSkill,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    setEditSkill(null);
    reset();
  };

  const moderationIds =
    (editSkill?.type === "Technical" && userTechnicalSkillsModerationIds) ||
    (editSkill?.type === "Non Technical" &&
      userNonTechnicalSkillsModerationIds) ||
    [];

  return (
    <Modal>
      <div className="w-[350px] lg:w-[420px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">Skills</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[276px] lg:min-h-[283px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              className="space-y-1"
              onSubmit={
                editSkill?.skills?.names
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="form-div pr-[18px] lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
                <Select
                  register={register}
                  errors={errors}
                  options={
                    editSkill?.type
                      ? skillTypes
                      : skillTypes?.filter(
                          (skillType: string) => skillType !== userSkillTypes[0]
                        )
                  }
                  name="skillType"
                  label="Select Skill Type *"
                  defaultSelectValue={editSkill?.type}
                  disable={!!editSkill?.type}
                  onChange={(event: any) => {
                    setWatchedSkillType(event.target.value);
                  }}
                  required={!editSkill?.type}
                />
                <ModerationField
                  refetchQuery={refetchSkillDetails}
                  moderationId={moderationIds && moderationIds[0]}
                >
                  <AutoComplete
                    setValue={setValue}
                    options={
                      isTechnicalSkill
                        ? technicalSkills
                        : isNonTechnicalSkill
                        ? nonTechnicalSkills
                        : []
                    }
                    onChange={(e) => {
                      setSkillList(e.target?.value);
                    }}
                    control={control}
                    multiple
                    label={"Skills *"}
                    name={"skills"}
                    required
                    errors={skillListError ? "This field is required." : errors}
                    defaultValue={
                      (skillList &&
                        skillList?.map((skill) => {
                          return {
                            id: skill?.id,
                            name: skill?.name,
                          };
                        })) ||
                      []
                    }
                    disabled={moderationIds?.length > 0}
                  />
                </ModerationField>
              </div>
              <div className="w-full border-b border-hit-gray" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : editSkill?.skills?.names ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditSkillDetails;
