import { Fragment, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import NoDataFoundMessage from "components/UI/NoDataFoundMessage";
import AddButton from "components/forms/Button/AddButton";

import { paginationDefaultCount, toastNotify } from "global/helpers/Cache";
import { FilterFormType } from "global/types/type";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
} from "global/UpdatedHooks/hooks";
import usePreLoading from "global/hooks/usePreLoading";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import useAllowedResources from "global/hooks/useAllowedResources";

import { DELETE_COMPANY, FILTER_COMPANIES } from "modules/Company/services";
import {
  Company,
  CompanyEditList,
  CompanyFilterForm,
  FilterCompaniesCommonArgs,
} from "modules/Company/types";
import CompanyCard from "modules/Company/CompanyList/CompanyCard";
import CompanyTable from "modules/Company/CompanyList/CompanyTable";
import AddCompany from "modules/Company/AddCompany";
import CompanyFilter from "modules/Company/helpers/CompanyFilter";

const Companies = () => {
  const navigate = useNavigate();

  const allowedResources = useAllowedResources();

  const canCreate = allowedResources?.includes("CreateCompany");
  const canUpdate = allowedResources?.includes("UpdateCompany");
  const canDelete = allowedResources?.includes("DeleteCompany");

  const fieldArgs: FilterCompaniesCommonArgs = {
    isCompanyTypeNeed: true,
    isStatusNeed: true,
    isOpeningTimeNeed: true,
    isClosingTimeNeed: true,
    isCompanyOwnersNeed: true,
  };

  const [deleteCompany, { loading: deleteCompanyLoading }] =
    useMutation(DELETE_COMPANY);

  const [searchParams, setSearchParams] = useCustomSearchParams();

  const [addOrDeleteCompany, setAddOrDeleteCompany] =
    useState<CompanyEditList | null>(null);

  const paginationDefaultValue = useReactiveVar(paginationDefaultCount);

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      limit: `${paginationDefaultValue}`,
    });
  });

  const { control, resetField, reset, setValue } = useForm<
    FilterFormType<CompanyFilterForm>
  >({
    defaultValues: {
      search: searchParams?.search || "",
      pageSize: paginationDefaultValue || 10,
      filter: {
        names: null,
        address: null,
        altMobile: null,
        cin: null,
        createdAt: null,
        email: null,
        gstin: null,
        isCinVerified: null,
        isEmailVerified: null,
        isGstinVerified: null,
        isPanVerified: null,
        joinedAt: null,
        managers: null,
        mobile: null,
        owners: null,
        pan: null,
        status: null,
        types: null,
        updateAt: null,
      },
    },
    shouldUnregister: false,
  });

  const [pageSize, watchSearch] = useWatch({
    control,
    name: ["pageSize", "search"],
  });
  const search = useSearch(watchSearch || "");

  const {
    data: filterCompanies,
    loading,
    fetchMore,
    updateQuery,
  } = useQuery(FILTER_COMPANIES, {
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: {
        size: pageSize,
      },
      globalSearch: search || undefined,
      ...fieldArgs,
    },
    notifyOnNetworkStatusChange: true,
  });

  const onEdit = (type: "create" | "delete", company?: Company | null) => {
    if (type === "create") {
      setAddOrDeleteCompany({ type });
    } else if (company) {
      setAddOrDeleteCompany({ type, company });
    }
  };

  const closeHandler = () => {
    setAddOrDeleteCompany(null);
  };

  const companyEdges = filterCompanies?.filterCompanies?.edges || [];

  const companiesLength = companyEdges?.length || 0;

  const totalCount =
    filterCompanies?.filterCompanies?.pageInfo?.totalNumberOfItems || 0;

  const addBtnHandler = () => {
    onEdit("create");
  };

  const preLoading = usePreLoading({
    queryLoading: loading,
  });

  const onPageSizeChange: (pageSize: number) => void = (pageSize) => {
    resetField("search");
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
        return { filterCompanies };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const nextDisabled =
    filterCompanies?.filterCompanies?.pageInfo?.endCursor &&
    filterCompanies?.filterCompanies?.pageInfo?.hasNextPage
      ? false
      : true;

  const onNextPageHandler = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
          after: filterCompanies?.filterCompanies?.pageInfo?.endCursor,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
        return { filterCompanies };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onPrevPageHandler = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
          before: filterCompanies?.filterCompanies?.pageInfo?.startCursor,
        },
        globalSearch: search || undefined,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
        return { filterCompanies };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const prevDisabled =
    filterCompanies?.filterCompanies?.pageInfo?.startCursor &&
    filterCompanies?.filterCompanies?.pageInfo?.hasPreviousPage
      ? false
      : true;

  const onDelete = () => {
    if (
      addOrDeleteCompany?.type === "delete" &&
      addOrDeleteCompany?.company?.id
    ) {
      deleteCompany({
        variables: {
          id: addOrDeleteCompany?.company?.id,
        },
      })
        .then(({ data }) => {
          if (data && data?.deleteCompany) {
            closeHandler();
            toastNotify([
              {
                message: "Company deleted successfully.",
                messageType: "success",
              },
            ]);
            if (
              filterCompanies?.filterCompanies?.edges?.length === 1 &&
              filterCompanies?.filterCompanies?.pageInfo?.hasPreviousPage &&
              filterCompanies?.filterCompanies?.pageInfo?.startCursor
            ) {
              fetchMore({
                variables: {
                  pagination: {
                    size: pageSize,
                    before:
                      filterCompanies?.filterCompanies?.pageInfo?.startCursor ||
                      undefined,
                  },
                  ...fieldArgs,
                },
                updateQuery: (
                  prev,
                  { fetchMoreResult: { filterCompanies } }
                ) => {
                  return {
                    filterCompanies,
                  };
                },
              }).catch((error) => {
                toastNotify(errorMessageNotify(error));
              });
              reset();
            } else {
              updateQuery(({ filterCompanies }) => {
                return {
                  filterCompanies: filterCompanies
                    ? {
                        pageInfo: filterCompanies?.pageInfo
                          ? {
                              ...filterCompanies?.pageInfo,
                              totalNumberOfItems: filterCompanies?.pageInfo
                                ?.totalNumberOfItems
                                ? filterCompanies?.pageInfo
                                    ?.totalNumberOfItems - 1
                                : 0,
                            }
                          : null,
                        edges:
                          filterCompanies?.edges &&
                          filterCompanies?.edges?.length > 0
                            ? filterCompanies?.edges?.filter(
                                (edge) =>
                                  edge?.node?.id !==
                                  addOrDeleteCompany?.company?.id
                              ) || []
                            : [],
                        __typename: filterCompanies?.__typename,
                      }
                    : null,
                };
              });
            }
          } else {
            toastNotify([
              {
                message: "Something went wrong.",
                messageType: "error",
              },
            ]);
          }
        })
        .catch((err) => toastNotify(err(err)));
    }
  };

  const cardOrTableClickHandler = (companyId: number) => {
    navigate(`/companies/${companyId}/general`);
  };

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[82vh] bg-white">
      <CompanyFilter
        control={control}
        setValue={setValue}
        search={search}
        companiesLength={companiesLength}
        canCreate={canCreate}
        addBtnHandler={addBtnHandler}
        fieldArgs={fieldArgs}
        pageSize={pageSize}
        fetchMore={fetchMore}
      />
      {preLoading ? (
        <Loading className="min-h-[calc(100vh-25vh)]" />
      ) : companiesLength > 0 ? (
        <Fragment>
          <CompanyCard
            canDelete={canDelete}
            canUpdate={canUpdate}
            cardOrTableClickHandler={cardOrTableClickHandler}
            companyEdges={companyEdges}
            control={control}
            nextDisabled={nextDisabled}
            onEdit={onEdit}
            onNextPageHandler={onNextPageHandler}
            onPrevPageHandler={onPrevPageHandler}
            onPageSizeChange={onPageSizeChange}
            prevDisabled={prevDisabled}
            totalCount={totalCount}
          />
          <CompanyTable
            canDelete={canDelete}
            canUpdate={canUpdate}
            onEdit={onEdit}
            nextDisabled={nextDisabled}
            prevDisabled={prevDisabled}
            control={control}
            totalCount={totalCount}
            cardOrTableClickHandler={cardOrTableClickHandler}
            companyEdges={companyEdges}
            onPageSizeChange={onPageSizeChange}
            onNextPageHandler={onNextPageHandler}
            onPrevPageHandler={onPrevPageHandler}
          />
        </Fragment>
      ) : (search?.length > 0 && companiesLength === 0) || !canCreate ? (
        <NoDataFoundMessage message="Sorry, we couldn't find any company." />
      ) : (
        canCreate && <AddButton onPress={addBtnHandler} />
      )}

      {addOrDeleteCompany?.type === "create" && (
        <AddCompany onClose={closeHandler} updateQuery={updateQuery} />
      )}

      {canDelete && addOrDeleteCompany?.type === "delete" && (
        <ConfirmModal
          header="Company"
          onCancel={closeHandler}
          onXIcon={closeHandler}
          onExecute={onDelete}
          loading={deleteCompanyLoading}
        />
      )}
    </div>
  );
};

export default Companies;
