import { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";

import Loading from "components/Loader/Loading";
import OopsNoData from "components/UI/OopsNoData";
import UserDetails from "components/UserDetails/UserDetails";
import DataCard from "components/App/Card/DataCard";

import { MY_TEAM_INFO } from "modules/Teams/services/queries";

const MyTeamInfo = () => {
  const { data: employeeTeamAndMember, loading } = useQuery(MY_TEAM_INFO, {
    variables: {
      isMyProfile: true,
    },
  });

  const [userId, setUserId] = useState<number | undefined>();

  const employeeTeamAndMembers: any = employeeTeamAndMember
    ? [employeeTeamAndMember?.users?.dataCollection[0]?.team]
    : [];

  const getTeamSummary = (teamMembers: any) => {
    return [
      {
        label: "Team Name",
        value: [
          {
            content: teamMembers?.name,
          },
        ],
      },
      {
        label: "Team Code",
        value: [
          {
            content: teamMembers?.code,
          },
        ],
      },
      {
        label: "Team Lead",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() => setUserId(teamMembers?.teamLeadUser?.id)}
                >
                  {teamMembers?.teamLeadUser?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
    ];
  };

  const getTeamMembers = (teamMembers: any) => {
    return [
      {
        label: "",
        className: "p-2 rounded even:bg-slate-400/20",
        value: teamMembers?.members?.map((teamMember: any) => {
          return {
            content: (
              <div className="group w-min">
                <p onMouseEnter={() => setUserId(teamMember?.id)}>
                  {teamMember?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          };
        }),
      },
    ];
  };
  return (
    <div>
      {loading ? (
        <Loading className="min-h-[78vh]" />
      ) : (
        <Fragment>
          {employeeTeamAndMembers?.length === 0 ||
          employeeTeamAndMember?.users?.dataCollection[0]?.team === null ? (
            <OopsNoData className="min-h-[78vh]" />
          ) : (
            <div className="md:min-h-[700px] min-h-[440px] pt-5">
              <div className="bg-white min-h-[440px] md:min-h-[675px]">
                {employeeTeamAndMembers?.map(
                  (teamMembers: any, index: number) => {
                    return (
                      <div
                        className="flex flex-col md:flex-row gap-5"
                        key={index}
                      >
                        <div>
                          <DataCard
                            loading={loading}
                            title="Team Info"
                            dataModel={getTeamSummary(teamMembers)}
                            isHtmlElementValue={true}
                            classNameForLabel="lg:max-w-[160px] pr-2"
                            classNameForDataCard="px-7 min-h-[350px] min-w-[300px] mt-0"
                            classNameForDataRow="h-auto"
                          />
                        </div>
                        <div>
                          <DataCard
                            loading={loading}
                            title="Team Members"
                            dataModel={getTeamMembers(teamMembers)}
                            isHtmlElementValue={true}
                            classNameForLabel="pr-2"
                            classNameForDataCard="px-7 min-h-[350px] min-w-[330px] mt-0"
                            classNameForDataRow="h-auto"
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MyTeamInfo;
