let signatures: any = {
  JVBERi0: "application/pdf",
  R0lGODdh: "image/gif",
  R0lGODlh: "image/gif",
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};
export const detectMimeType = (b64: string) => {
  for (const s in signatures) {
    if (b64?.indexOf(s) === 0) {
      return signatures[s];
    }
  }
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
};

export const dataURLtoBlobForFileUpload = (
  dataURL: string,
  mimeType?: string
) => {
  const bytes = atob(dataURL);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  const blob = new Blob([out], {
    type: mimeType
      ? mimeType
      : detectMimeType(dataURL) === "application/pdf"
      ? "application/pdf"
      : "image/png,image/jpg,image/jpeg",
  });
  return blob;
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
