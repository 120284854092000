import { clearCookies, client } from "global/services/config";

const Logout = () => {
  window.location.replace("/");
  localStorage.clear();
  sessionStorage.clear();
  client.clearStore();
  clearCookies();
};

export default Logout;
