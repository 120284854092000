import { Editor } from "@tiptap/react";
import Tooltip from "components/UI/ToolTip";
import { FC } from "react";

interface IProps {
  color: string;
  editor: Editor | null;
  colorModalHandler: () => void;
  colorName: string;
}

const ColorSelectBox: FC<IProps> = ({
  color,
  editor,
  colorModalHandler,
  colorName,
}) => {
  return (
    <Tooltip render={colorName || ""}>
      <button
        onClick={() => {
          if (editor) {
            editor.chain().focus().setColor(color).run();
            colorModalHandler();
          }
        }}
        className={"rounded-md border p-0.5"}
      >
        <span
          className="w-9 h-9 rounded-md block"
          style={{
            backgroundColor: color,
          }}
        />
      </button>
    </Tooltip>
  );
};

export default ColorSelectBox;
