import { FC, useState } from "react";
import { TbCopy } from "react-icons/tb";

import { IDataModal } from "components/App/UpdatedAgryApp/DataCard/types/data-card";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

interface Props {
  row: IDataModal;
  classNameForRow?: string;
  disableClickToViewToolTip?: boolean;
  classForSecondChild?: string;
  classForFirstChild?: string;
}

const CardRow: FC<Props> = ({
  row,
  classNameForRow,
  disableClickToViewToolTip,
  classForFirstChild = "",
  classForSecondChild = "",
}) => {
  const [copyEmail, setCopyEmail] = useState(false);

  const titleLengthStyle = row.value?.length > 1 ? "" : "";

  return (
    <div
      className={`${
        classNameForRow
          ? classNameForRow
          : "grid grid-cols-[120px_auto] items-center gap-1"
      } `}
    >
      <p
        className={`flex justify-between items-center whitespace-pre-wrap ${classForFirstChild}`}
      >
        <span className="text-warm-gray text-sm">{row.label}</span>
        <span>:</span>
      </p>
      <div className="flex flex-col truncate">
        {row?.value?.map((value, index) => {
          return (
            <div
              key={index}
              className={`last:border-none text-sm first:pt-[2px] border-b truncate ${classForSecondChild}`}
            >
              {typeof value?.content === "string" ||
              typeof value?.content === "number" ? (
                <div
                  className={`text-sm ${titleLengthStyle} ${
                    value.color ? value.color : "text-ironside-gray"
                  } truncate`}
                >
                  {value?.content ? (
                    <div className="flex gap-2">
                      <ToolTip
                        render={`${value?.content || "-"}`}
                        arrow
                        classNameForParent="truncate"
                        trigger={
                          !disableClickToViewToolTip ? ["click"] : ["hover"]
                        }
                        className={
                          "bg-storm-dust text-center text-xs text-white p-2"
                        }
                      >
                        {value?.content || "-"}
                      </ToolTip>
                      <div
                        className={
                          value?.copyText
                            ? "group-hover:visible invisible"
                            : "hidden"
                        }
                      >
                        <ToolTip
                          render={() => (copyEmail ? "Copied" : "Copy")}
                          arrow
                          placement="top"
                          className="bg-storm-dust text-white text-xs p-2"
                          trigger={["hover"]}
                        >
                          <TbCopy
                            className="w-5 h-5 sm:w-5.5 sm:h-5.5 cursor-pointer hover:text-dark-mint"
                            onMouseEnter={() => {
                              setCopyEmail(false);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCopyEmail(true);
                              navigator.clipboard.writeText(
                                `${value?.content}`
                              );
                            }}
                          />
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              ) : (
                value?.content || "-"
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardRow;
