import { Fragment, FC, useState, useEffect, useRef, ReactNode } from "react";
import { HiPlusSm } from "react-icons/hi";

import Card from "components/UI/Card";
import CardContent from "components/App/Card/CardContent";
import { IDataModel } from "components/App/Card/types/card";
import { KebabMenu } from "components/forms";
import Loading from "components/Loader/Loading";

import { ReactComponent as PencilIcon } from "global/assets/images/pencil-dark-icon.svg";
import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";

import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import { getUpdatedUnderReviews } from "modules/Employee/Pages/Profile/Moderation/under-review";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";

interface IProps {
  loading: boolean;
  setShowModal?: Function;
  title: string;
  dataModel?: IDataModel[];
  resourceName?: string;
  addOrEditHandler?: () => void;
  showEditDelete?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  showModal?: boolean;
  showPlusIcon?: boolean;
  refetchQuery?: Function;
  showPencilIcon?: boolean;
  children?: ReactNode;
  isHtmlElementValue?: boolean;
  className?: string;
  classNameForLabel?: string;
  classNameForDataCard?: string;
  classNameForDataRow?: string;
}

const DataCard: FC<IProps> = ({
  loading,
  setShowModal,
  title,
  dataModel,
  resourceName,
  children,
  addOrEditHandler,
  showEditDelete,
  onEdit,
  onDelete,
  showModal,
  showPlusIcon,
  refetchQuery,
  showPencilIcon,
  isHtmlElementValue,
  className,
  classNameForLabel,
  classNameForDataCard,
  classNameForDataRow,
}) => {
  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);

  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  const { canCreate, canUpdate } = useGetAllowedResources(resourceName || "");

  useEffect(() => {
    setChangedInformation(getUpdatedUnderReviews(dataModel));
  }, [dataModel]);

  const moderationProps = {
    setShowUnderReview: setShowUnderReview,
    setChangedInformation: setChangedInformation,
  };

  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  let editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const isKebabMenu = onEdit && onDelete;

  useEffect(() => {
    if (showModal) {
      setShowEditOption(false);
    }
  }, [showModal]);

  return (
    <Card className={classNameForDataCard ? classNameForDataCard : ""}>
      {loading ? (
        <Loading className="min-h-[400px]" />
      ) : (
        <Fragment>
          <div
            className={`card-header ${showEditDelete ? "justify-between" : ""}`}
          >
            <div className={`${canUpdate ? "" : "mr-5"} card-title`}>
              {title}
            </div>

            {showPencilIcon && canUpdate && (
              <PencilIcon
                className="w-[16px] h-[16px] text-ironside-gray/70 mr-[15px] ml-[15px] md:ml-[30px] cursor-pointer"
                onClick={() => {
                  setShowModal && setShowModal(true);
                  addOrEditHandler && addOrEditHandler();
                }}
              />
            )}

            {showPlusIcon && canCreate && (
              <HiPlusSm
                className="w-7 h-7 text-ironside-gray/70 cursor-pointer ml-[15px] md:ml-[30px]"
                onClick={() => {
                  setShowModal && setShowModal(true);
                  addOrEditHandler && addOrEditHandler();
                }}
              />
            )}

            {showEditDelete && (
              <div ref={editRef} className="relative w-min">
                <div className="w-min">
                  <KebabIcons
                    onClick={() => {
                      setShowEditOption((prevState: any) => !prevState);
                      addOrEditHandler && addOrEditHandler();
                    }}
                    className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                      showEditOption ? "bg-white-smoke" : ""
                    }`}
                  />
                </div>

                {showEditOption && (
                  <div className="absolute top-[35px] right-[115px] z-50">
                    {isKebabMenu && (
                      <KebabMenu
                        onEdit={onEdit || undefined}
                        onDelete={onDelete || undefined}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {children}
          {dataModel && (
            <CardContent
              dataModel={dataModel}
              {...moderationProps}
              className={className}
              isHtmlElementValue={isHtmlElementValue}
              classNameForLabel={classNameForLabel}
              classNameForDataRow={classNameForDataRow}
            />
          )}

          {showUnderReview && (
            <UnderReviewModal
              {...moderationProps}
              changedInformation={changedInformation}
              refetchQuery={refetchQuery}
            />
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default DataCard;
