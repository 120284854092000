import { FC, Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import { IRoute } from "components/App/CustomRoutes/types/custom-routes";

interface IProps {
  routes: IRoute[];
}

const CustomRoutes: FC<IProps> = ({ routes }) => {
  return (
    <Fragment>
      <Routes>
        {routes?.map((route: IRoute, index: number) => (
          <Route key={index} path={route?.path} element={route?.element} />
        ))}
      </Routes>
    </Fragment>
  );
};

export default CustomRoutes;
