import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { HiCheck } from "react-icons/hi";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import UserDetails from "components/UserDetails/UserDetails";
import Tooltip from "components/UI/ToolTip";
import { Modal } from "components/forms";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";
import { IIdAndName, ILeaveRequestDates } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";

import { IFilterLeaveRequestTypes } from "modules/Leave/types/leave";
import { FILTER_TEAM_LEAVE_REQUESTS } from "modules/Leave/services/queries";
import {
	UPDATE_CONSENT_REQUEST,
	UPDATE_LEAVE_REQUEST,
} from "modules/Leave/services/mutations";

interface Iprops {
	request: IFilterLeaveRequestTypes;
	userId: number | undefined;
	setUserId: Function;
}

const TableDetailsGrid: React.FC<Iprops> = ({ request, userId, setUserId }) => {
	const navigate = useNavigate();

	const profileRef = useRef<HTMLDivElement>(null);
	const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
		profileRef.current?.getBoundingClientRect().top,
	);
	const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
		profileRef.current?.getBoundingClientRect().top,
	);

	const getPositionOfProfileName = useCallback(() => {
		setTopProfileRef(
			profileRef.current?.getBoundingClientRect().top &&
				profileRef.current?.getBoundingClientRect().top + 25,
		);
		setleftProfileRef(
			profileRef.current?.getBoundingClientRect().left &&
				profileRef.current?.getBoundingClientRect().left,
		);
	}, []);

	useEffect(() => {
		const eventRef = profileRef.current;
		window.addEventListener("resize", getPositionOfProfileName);
		window.addEventListener("scroll", getPositionOfProfileName);

		eventRef &&
			eventRef?.addEventListener("mouseenter", getPositionOfProfileName);

		return () => {
			window.removeEventListener("resize", getPositionOfProfileName);
			window.removeEventListener("scroll", getPositionOfProfileName);
			eventRef &&
				eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
		};
	}, [getPositionOfProfileName]);

	const [showModal, setShowModal] = useState(false);
	const { register, watch } = useForm();

	const [updateConsentRequest, { loading: updateConsentLoading }] = useMutation(
		UPDATE_CONSENT_REQUEST,
		{
			refetchQueries: [FILTER_TEAM_LEAVE_REQUESTS],
		},
	);

	const [updateLeaveRequest, { loading: updateLeaveRequestLoading }] =
		useMutation(UPDATE_LEAVE_REQUEST, {
			refetchQueries: [FILTER_TEAM_LEAVE_REQUESTS],
		});

	const sessionId: string | any = document.cookie
		.split("; ")
		.find((row) => row.startsWith("id="))
		?.split("=")[1];

	const userIdforConsentFilter = +localStorage?.id || +sessionId;
	const reason = watch("reason");

	const consentorsLeaveStatusses =
		request?.leaveRequestDates?.map((date: ILeaveRequestDates) => {
			const filterConsentor: any = date?.leaveRequestDateConsents?.filter(
				(date: { consentedByUser: { personalInformation: { id: number } } }) =>
					date?.consentedByUser?.personalInformation?.id ===
					+userIdforConsentFilter,
			);
			return {
				id: +date?.id,
				status: filterConsentor?.length > 0 && filterConsentor[0]?.status,
			};
		}) || [];

	const flattenConsentRequests =
		request?.leaveRequestDates
			?.map((leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents)
			?.flat() || [];

	const isAllAgreed =
		flattenConsentRequests?.length > 0
			? flattenConsentRequests
					?.map((leave: { status: string }) => leave?.status)
					?.every((status: string) => status === "Agreed")
			: false;

	let showApproveOrAgreeOption = false;

	if (request?.isConsentRequest) {
		showApproveOrAgreeOption = true;
	} else if (
		!request.isConsentRequest &&
		flattenConsentRequests?.length > 0 &&
		isAllAgreed
	) {
		showApproveOrAgreeOption = true;
	} else if (
		!request.isConsentRequest &&
		flattenConsentRequests?.length === 0
	) {
		showApproveOrAgreeOption = true;
	} else {
		showApproveOrAgreeOption = false;
	}

	const hasPending = request?.isConsentRequest
		? consentorsLeaveStatusses?.some((request) => request?.status === "Pending")
		: request?.leaveRequestDates
				?.map((date: any) => date?.status)
				.includes("Pending");

	const [modalTitle, setModalTitle] = useState("");

	const modalTitleHandler = () => {
		const sessions = request?.leaveRequestDates?.map(
			(leave: { session: string }) => leave?.session,
		);

		const isAllSessionArePermissions = sessions?.every(
			(session: string) => session === "2 Hours",
		);

		if (isAllSessionArePermissions) {
			setModalTitle("Permission");
		} else {
			setModalTitle("Leave Request");
		}
	};

	const confirmConsentHandler = (request: IFilterLeaveRequestTypes) => {
		updateConsentRequest({
			variables: {
				leaveRequestId: +request?.id,
				leaveRequestDate: consentorsLeaveStatusses
					?.filter(
						(request: { status: string }) => request?.status === "Pending",
					)
					?.map((leave: { id: number }) => {
						return {
							leaveRequestDateId: +leave?.id,
							status: "Agreed",
						};
					}),
				isAllAgreed: true,
			},
		})
			.then((response: any) => {
				toastNotify([
					{
						messageType: "success",
						message: "Your modifications have been successfully updated.",
					},
				]);
			})
			.catch((error: any) => {
				toastNotify([
					{
						messageType: "error",
						message: error.message,
					},
				]);
			});
	};

	const confirmHandler = (request: IFilterLeaveRequestTypes) => {
		updateLeaveRequest({
			variables: {
				leaveRequestDate: request?.leaveRequestDates?.map(
					(date: { id: number }) => {
						return {
							leaveRequestDateId: +date?.id,
							status: "Approved",
						};
					},
				),
				leaveRequestId: +request?.id,
				userId: +localStorage?.id || +sessionId,
				isAllApprove: true,
			},
		})
			.then((response: any) => {
				toastNotify([
					{
						messageType: "success",
						message: "Your modifications have been successfully updated.",
					},
				]);
			})
			.catch((error: any) => {
				toastNotify([
					{
						messageType: "error",
						message: error.message,
					},
				]);
			});
	};

	const consentDeclineHandler = (request: IFilterLeaveRequestTypes) => {
		updateConsentRequest({
			variables: {
				leaveRequestId: +request?.id,
				leaveRequestDate: consentorsLeaveStatusses
					?.filter(
						(request: { status: string }) => request?.status === "Pending",
					)
					?.map((leave: { id: number }) => {
						return {
							leaveRequestDateId: +leave?.id,
							status: "Disagreed",
							declineMessage: reason,
						};
					}),
				isAllDisagreed: true,
			},
		})
			.then((response: any) => {
				toastNotify([
					{
						messageType: "success",
						message: "Your modifications have been successfully updated.",
					},
				]);
				setShowModal(false);
			})
			.catch((error: any) => {
				toastNotify([
					{
						messageType: "error",
						message: error.message,
					},
				]);
			});
	};

	const declineHandler = (request: IFilterLeaveRequestTypes) => {
		updateLeaveRequest({
			variables: {
				leaveRequestDate: request?.leaveRequestDates?.map(
					(date: { id: number }) => {
						return {
							leaveRequestDateId: +date?.id,
							status: "Declined",
						};
					},
				),
				leaveRequestId: +request?.id,
				userId: +localStorage?.id || +sessionId,
				declineMessage: reason,
				isAllDecline: true,
			},
		})
			.then((response: any) => {
				toastNotify([
					{
						messageType: "success",
						message: "Your modifications have been successfully updated.",
					},
				]);
				setShowModal(false);
			})
			.catch((error: any) => {
				toastNotify([
					{
						messageType: "error",
						message: error.message,
					},
				]);
			});
	};

	return (
		<tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
			<td className="py-5 px-3 max-w-[300px] truncate text-center">
				{request?.id}
			</td>
			<td className="py-5 px-4 first-line:truncate">
				<div className={"group w-[130px] inline-block"}>
					<span
						ref={profileRef}
						onMouseEnter={() =>
							setUserId(request?.requestedByUser?.personalInformation?.id)
						}
						className="whitespace-nowrap"
					>
						{request?.requestedByUser ? (
							request?.requestedByUser?.personalInformation?.name
						) : (
							<span className="text-gray-400">{"Eg.Person Name"}</span>
						)}
					</span>
					<div
						style={{
							top: `${topProfileRef}px`,
							left: `${leftProfileRef}px`,
						}}
					>
						<UserDetails userId={userId} />
					</div>
				</div>
			</td>
			<td className="max-w-[200px] truncate whitespace-nowrap text-center">
				{request?.leaveRequestDates?.length > 1 ? (
					request?.leaveRequestDates?.map((date: any, index: number) => {
						return (
							<span
								key={index}
								className="flex py-5 px-3 justify-center border-b border-white-smoke last:border-none"
							>
								{date?.leaveDate}
							</span>
						);
					})
				) : request?.leaveRequestDates?.length === 1 ? (
					<span className="p-3">
						{request?.leaveRequestDates?.map((date: any) => date?.leaveDate)}
					</span>
				) : null}
			</td>
			<td className="max-w-[200px] truncate whitespace-nowrap text-center">
				{request?.leaveRequestDates?.length > 1 ? (
					request?.leaveRequestDates?.map((date: any, index: number) => {
						return (
							<span
								key={index}
								className="flex py-5 justify-center px-3 border-b border-white-smoke last:border-none"
							>
								{date?.leaveType?.name}
							</span>
						);
					})
				) : request?.leaveRequestDates?.length === 1 ? (
					<span className="p-3">
						{request?.leaveRequestDates?.map(
							(date: any, index: number) => date?.leaveType?.name,
						)}
					</span>
				) : null}
			</td>
			<td className="max-w-[200px] truncate whitespace-nowrap text-center">
				{request?.leaveRequestDates?.length > 1 ? (
					request?.leaveRequestDates?.map((date: any, index: number) => {
						return (
							<span
								key={index}
								className="flex py-5 justify-center px-3 border-b border-white-smoke last:border-none"
							>
								{date?.session}
							</span>
						);
					})
				) : request?.leaveRequestDates?.length === 1 ? (
					<span className="p-3">
						{request?.leaveRequestDates?.map(
							(date: any, index: number) => date?.session,
						)}
					</span>
				) : null}
			</td>
			<td className="max-w-[200px] truncate whitespace-nowrap text-center">
				{request?.leaveRequestDates?.length > 1 ? (
					request?.leaveRequestDates?.map((date: any, index: number) => {
						const sessionId: string | any = document.cookie
							.split("; ")
							.find((row) => row.startsWith("id="))
							?.split("=")[1];
						const userId = +localStorage?.id || +sessionId;
						const consenterRequest =
							date?.leaveRequestDateConsents?.filter(
								(leave: {
									consentedByUser: { personalInformation: IIdAndName };
								}) =>
									leave?.consentedByUser?.personalInformation?.id === userId,
							) || [];

						return (
							<div
								key={index}
								className="flex py-5 justify-center px-3 border-b border-white-smoke last:border-none"
							>
								<div className="flex items-center gap-3">
									<div
										className={`${getStatusColor(
											request?.isConsentRequest
												? consenterRequest[0]?.status
												: date?.status,
										)} opacity-100 rounded-[18px]`}
									>
										{request?.isConsentRequest && date?.status !== "Withdrawn"
											? consenterRequest[0]?.status
											: date?.status}
									</div>
								</div>
							</div>
						);
					})
				) : request?.leaveRequestDates?.length === 1 ? (
					<span>
						{request?.leaveRequestDates?.map((date: any, index: number) => {
							const sessionId: string | any = document.cookie
								.split("; ")
								.find((row) => row.startsWith("id="))
								?.split("=")[1];
							const userId = +localStorage?.id || +sessionId;
							const consenterRequest =
								date?.leaveRequestDateConsents?.filter(
									(leave: {
										consentedByUser: { personalInformation: IIdAndName };
									}) =>
										leave?.consentedByUser?.personalInformation?.id === userId,
								) || [];
							return (
								<div className="flex justify-center items-center" key={index}>
									<div
										className={`${getStatusColor(
											request?.isConsentRequest
												? consenterRequest[0]?.status
												: date?.status,
										)} opacity-100 rounded-[18px] py-5 px-3`}
									>
										{request?.isConsentRequest && date?.status !== "Withdrawn"
											? consenterRequest[0]?.status
											: date?.status}
									</div>
								</div>
							);
						})}
					</span>
				) : (
					<span className="text-gray-400">Eg.Pending</span>
				)}
			</td>
			<td className="text-center">
				{request?.isConsentRequest ? "No" : "Yes"}
			</td>
			{hasPending ? (
				<td>
					<div className="flex items-center gap-3 py-1 flex-col">
						{(updateConsentLoading || updateLeaveRequestLoading) &&
						!showModal ? (
							<div className="w-5 h-5 border-4 border-t-transparent mx-auto border-cornflower-blue border-solid rounded-full animate-spin"></div>
						) : (
							showApproveOrAgreeOption && (
								<Tooltip
									render={request?.isConsentRequest ? "Agree" : "Approve"}
									arrow
									placement="top"
								>
									<HiCheck
										className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
										onClick={() => {
											request?.isConsentRequest
												? confirmConsentHandler(request)
												: confirmHandler(request);
										}}
									/>
								</Tooltip>
							)
						)}
						<Tooltip
							render={request?.isConsentRequest ? "Disagree" : "Decline"}
							arrow
							placement="top"
						>
							<MdOutlineClose
								className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer"
								onClick={() => {
									setShowModal(true);
									modalTitleHandler();
								}}
							/>
						</Tooltip>
					</div>
				</td>
			) : (
				<td className="text-center">-</td>
			)}
			<td className="py-5 px-3 max-w-[200px] truncate whitespace-nowrap text-center">
				<span
					className="grid place-content-center cursor-pointer hover:text-cornflower-blue hover:underline"
					onClick={() => {
						navigate(`${request?.id}`);
					}}
				>
					View
				</span>
			</td>
			{showModal ? (
				<td>
					<Modal>
						<div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
							<div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
								<h2 className="text-base text-ironside-gray">
									{modalTitle}{" "}
									{request?.isConsentRequest ? "Disagree" : "Decline"}
								</h2>
								<div
									className="edit-modal-close-icon-div"
									onClick={() => {
										setShowModal(false);
									}}
								>
									<MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
								</div>
							</div>

							<div className="w-full py-[20px] px-[20px]">
								<textarea
									className="w-full h-[200px] border border-gray-200 focus:outline-none p-4"
									placeholder="Type your reason here"
									{...register("reason")}
								/>
							</div>
							<div className="w-full border-b" />
							<div className="flex items-center justify-center p-6">
								<button
									type="button"
									onClick={() => {
										request?.isConsentRequest
											? consentDeclineHandler(request)
											: declineHandler(request);
									}}
									className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
								>
									{updateConsentLoading || updateLeaveRequestLoading ? (
										<div className="btn-spinner" />
									) : request?.isConsentRequest ? (
										"Disagree"
									) : (
										"Decline"
									)}
								</button>
							</div>
						</div>
					</Modal>
				</td>
			) : null}
		</tr>
	);
};

export default TableDetailsGrid;
