import { FC } from "react";

interface IProps {
  className?: string;
  smallLoading?: boolean;
  whiteColor?: boolean;
}

const Loading: FC<IProps> = ({ className, smallLoading, whiteColor }) => {
  return (
    <div
      className={`${
        className ? className : "min-h-full"
      } flex justify-center items-center rounded ${
        smallLoading ? "" : "bg-white"
      } `}
    >
      <div
        className={`${smallLoading ? "small-loading" : "loading"} ${
          whiteColor ? "white-color" : ""
        }`}
      />
    </div>
  );
};

export default Loading;
