import { FC, useCallback, useEffect, useRef, useState } from "react";
import { HiPencil, HiPlusSm } from "react-icons/hi";

import { KebabMenu, MeatBallsMenu } from "components/forms";
import Loading from "components/Loader/Loading";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import SMCardContent from "./SMCardContent";
import Card from "components/UI/Card";
import { ReactNode } from "react";

interface IProps {
  loading?: boolean;
  dataModal?: {
    lable: string;
    value: {
      content: string | Element | any;
      profileDetails?: {
        profileImage?: string;
        name: string;
        email: string;
      };
      color?: string;
    }[];
    hidden?: boolean;
  }[];
  title: string;
  showModal?: boolean;
  onPencilIconClick?: () => void;
  onPlusIconClick?: () => void;
  onView?: () => void;
  onKebabIcon?: {
    onclick: () => void;
    kebabMenu?: {
      onEdit?: () => void;
      onDelete?: () => void;
    };
    meatBallsMenu?: {
      onView?: () => void;
      onAccept?: () => void;
      onDecline?: () => void;
    };
  };
  children?: ReactNode;
}

const SMDataCard: FC<IProps> = ({
  loading,
  title,
  showModal,
  onPencilIconClick,
  onPlusIconClick,
  onKebabIcon,
  children,
  dataModal,
  onView,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  let editRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event?.target as Node)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  useEffect(() => {
    if (showModal) {
      setShowEditOption(false);
    }
  }, [showModal]);

  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );
  const isMeatBallsMenuShow = onKebabIcon?.meatBallsMenu;
  const getPosition = useCallback(() => {
    let leftSidePositon = isMeatBallsMenuShow ? 100 : 92;
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - leftSidePositon
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, [isMeatBallsMenuShow]);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
    };
  }, [getPosition]);

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };

  return (
    <Card className="min-h-max">
      {loading ? (
        <Loading className="min-w-[400px]" />
      ) : (
        <div className="py-5">
          <div className={"flex items-center justify-between"}>
            <h4 className={"card-title"}>{title}</h4>
            {onPencilIconClick && (
              <HiPencil
                className="w-5 h-5 cursor-pointer text-ironside-gray/70 mr-[15px] ml-[15px] md:ml-[30px]"
                onClick={() => {
                  onPencilIconClick && onPencilIconClick();
                }}
              />
            )}
            {onPlusIconClick && (
              <HiPlusSm
                className="w-6 h-6 text-ironside-gray/70 cursor-pointer ml-[15px] md:ml-[30px]"
                onClick={() => {
                  onPlusIconClick && onPlusIconClick();
                }}
              />
            )}
            {onKebabIcon && (
              <div ref={editRef} className="relative w-min">
                <div className="w-min" ref={kebabIconRef}>
                  <KebabIcons
                    onClick={() => {
                      setShowEditOption((prevState: any) => !prevState);
                      onKebabIcon?.onclick();
                    }}
                    className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                      showEditOption ? "bg-white-smoke" : null
                    }`}
                  />
                </div>
                {showEditOption && (
                  <div className="fixed z-[80]" style={showEditOptionStyle}>
                    {onKebabIcon.kebabMenu && (
                      <KebabMenu
                        onEdit={onKebabIcon.kebabMenu.onEdit || undefined}
                        onDelete={onKebabIcon.kebabMenu.onDelete || undefined}
                      />
                    )}
                    {onKebabIcon.meatBallsMenu && (
                      <MeatBallsMenu
                        onView={onKebabIcon.meatBallsMenu.onView || undefined}
                        onAccept={
                          onKebabIcon.meatBallsMenu.onAccept || undefined
                        }
                        onDecline={
                          onKebabIcon.meatBallsMenu.onDecline || undefined
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            )}
            {onView && (
              <span
                className="whitespace-nowrap text-sm text-gray-500 px-3 hover:text-cornflower-blue hover:underline cursor-pointer"
                onClick={onView}
              >
                View
              </span>
            )}
          </div>
          {children}
          {dataModal && <SMCardContent dataModel={dataModal} />}
        </div>
      )}
    </Card>
  );
};

export default SMDataCard;
