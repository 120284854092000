import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useEffect } from "react";

import { Filled, Modal, Outlined } from "components/forms";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import {
  IProjectVaultCredentialTypes,
  IProjectVaultCredentialTypesForm,
} from "modules/Project/types/project";
import { CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIAL_TYPES } from "modules/Project/services/mutations";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";

const EditVaultCredentialTypes: React.FC<{
  editProjectVaultCredentialType: IProjectVaultCredentialTypes | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectVaultCredentialTypes: Function;
}> = ({
  editProjectVaultCredentialType,
  setShowModal,
  loading,
  refetchProjectVaultCredentialTypes,
}) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const createOrUpdateAccess = allowedResources.includes(
    "SaveProjectVaultCredentialType"
  );
  const [
    saveProjectVaultCredentialType,
    { loading: projectVaultCredentialTypesLoading },
  ] = useMutation(CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIAL_TYPES);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProjectVaultCredentialTypesForm>();

  const createOrUpdateProjectVaultCredentialType: SubmitHandler<
    IProjectVaultCredentialTypesForm
  > = ({ name, description }) => {
    if (!projectVaultCredentialTypesLoading) {
      saveProjectVaultCredentialType({
        variables: {
          projectVaultCredentialTypeInput: {
            id: editProjectVaultCredentialType?.id || undefined,
            name,
            description: editProjectVaultCredentialType?.id
              ? description
              : description || undefined,
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          refetchProjectVaultCredentialTypes();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectVaultCredentialType?.id
                  ? editProjectVaultCredentialType?.name
                  : response?.data?.saveProjectVaultCredentialType?.name
              } credential type has been successfully ${
                editProjectVaultCredentialType?.id ? "updated" : "added"
              } `,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  useEffect(() => {
    if (editProjectVaultCredentialType) {
      setValue("name", editProjectVaultCredentialType?.name || "");
    }
  }, [editProjectVaultCredentialType, setValue]);

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Vault Credential Type</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(createOrUpdateProjectVaultCredentialType)}
            >
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  required
                  defaultValue={editProjectVaultCredentialType?.name}
                  setValue={setValue}
                />
                <TextArea
                  errors={errors}
                  label="Description "
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectVaultCredentialType?.description}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={
                    editProjectVaultCredentialType?.id ? "Update" : "Add"
                  }
                  loading={projectVaultCredentialTypesLoading}
                  disabled={!createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditVaultCredentialTypes;
