export const getFileExtension = (base64String: string) => {
  return base64String?.slice(((base64String?.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const downloadPDFUsingFunction = (
  base64String: any,
  name: string,
  mimeType: string
) => {
  const linkSource = `data:${mimeType};base64,${base64String}`;
  const downloadLink = document.createElement("a");
  const fileName = name;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
