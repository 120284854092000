import { useState } from "react";
import { useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Password } from "components/forms";

import { toastNotify } from "global/helpers/Cache";

import { PASSWORD_RESET } from "modules/User/services/mutations";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [resetPassword] = useMutation(PASSWORD_RESET);
  const [inProgress, setInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ newPassword: string }>();

  const onSubmit = (data: { newPassword: string }) => {
    if (!inProgress) {
      resetPassword({
        variables: {
          email: state?.email,
          confirmationCode: state?.confirmationCode,
          newPassword: data?.newPassword,
        },
      })
        .then((response) => {
          setInProgress(false);
          navigate("/reset-success");
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="form-design px-[42px] py-[30px]">
        <form
          className="flex justify-center items-center flex-col w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="text-2xl mb-[24px]">Reset Password</h3>
          <Password
            register={register}
            errors={errors}
            label="New Password"
            name="newPassword"
          />

          <button type="submit" className="button-design mt-[6px]">
            {inProgress ? (
              <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => {
          setInProgress(false);
          navigate("/otp");
        }}
      />
    </div>
  );
};
export default ResetPassword;
