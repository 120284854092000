import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import EditProjectGroupBug from "modules/Project/Pages/Projects/ProjectGroupBugs/EditProjectGroupBug";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
	projectGroup: ProjectGroup | null;
}

const EditProjectGroupBugsRouteControl: FC<Props> = ({ projectGroup }) => {
	return (
		<Routes>
			<Route
				path="/"
				element={<EditProjectGroupBug projectGroup={projectGroup} />}
			/>
		</Routes>
	);
};

export default EditProjectGroupBugsRouteControl;
