import { FC } from "react";

interface Props {
  message?: string;
}

const NoDataFoundMessage: FC<Props> = ({ message }) => {
  return (
    <p className="w-full min-h-[80vh] inline-flex  mb-2 rounded-md justify-center items-center text-sm text-ironside-gray">
      {message?.trim() || "Sorry, we couldn't find any results."}
    </p>
  );
};

export default NoDataFoundMessage;
