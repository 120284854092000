import Input from "components/forms/UpdatedForm/Input";
import { Fragment } from "react";
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

interface IProps<TFilterForm extends FieldValues> {
  max?: number;
  min?: number;
  register: UseFormRegister<TFilterForm>;
  numberBetween?: true;
  numberBetweenFromName?: Path<TFilterForm>;
  numberBetweenToName?: Path<TFilterForm>;
  name: Path<TFilterForm>;
  setValue: UseFormSetValue<TFilterForm>;
}

const FilterFormNumber = <TFilterForm extends Record<string, any>>({
  max,
  min,
  register,
  numberBetween,
  numberBetweenFromName,
  numberBetweenToName,
  name,
  setValue,
}: IProps<TFilterForm>) => {
  return (
    <div className="flex items-center gap-5 mt-5 flex-col">
      {numberBetween && numberBetweenFromName && numberBetweenToName ? (
        <Fragment>
          <Input
            name={numberBetweenFromName}
            register={register}
            inputType="number"
            hideError
            label="Number From"
            className="w-[200px] md:w-[300px]"
            maxLength={max}
            minLength={min}
            shouldUnregister={false}
            valueAsNumber={false}
            setValue={setValue}
          />
          <Input
            name={numberBetweenToName}
            register={register}
            inputType="number"
            hideError
            label="Number To"
            className="w-[200px] md:w-[300px]"
            shouldUnregister={false}
            valueAsNumber={false}
            setValue={setValue}
          />
        </Fragment>
      ) : (
        <Input
          name={name}
          register={register}
          inputType="date"
          hideError
          label="Date"
          className="w-[200px] md:w-[300px]"
          shouldUnregister={false}
          valueAsNumber={false}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default FilterFormNumber;
