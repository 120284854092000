import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { Filled, Outlined } from "components/forms";
import {
  getDateArray,
  prepareDateArray,
} from "components/forms/DatePicker/DateFiltering";
import TextArea from "components/forms/UpdatedForm/TextArea";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";

import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import { IIdAndName } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";

import { leaveRequestsDatesType } from "modules/Leave/types/leave";
import LeaveDates from "modules/Leave/Pages/Apply/LeaveDates";
import WorkingDates from "modules/Leave/Pages/Apply/WorkingDates";
import {
  GET_PROJECTS_FOR_COMPENSATION,
  REQUEST_COMPENSATION_LEAVE,
  UPDATE_APPLY_COMPENSATION_LEAVE,
} from "modules/Leave/services/mutations";
import { HR_ROLE_USER_FOR_DROP_DOWN } from "modules/Leave/services/queries";

const CompOffRequest = () => {
  const [requestCompensationLeave, { loading: compensationRequestLoading }] =
    useMutation(REQUEST_COMPENSATION_LEAVE);

  const [
    updateApplyCompensationLeave,
    { loading: updateApplyLeaveCompensationLoading },
  ] = useMutation(UPDATE_APPLY_COMPENSATION_LEAVE);

  const resourceAccess = useGetAllowedResources("ApplyLeave");
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: getProjectsForCompensation, loading } = useQuery(
    GET_PROJECTS_FOR_COMPENSATION,
    {
      variables: {
        filters: {
          isMyProject: true,
        },
      },
    }
  );

  const [fetchHRRoleUser, { data: hrRoleUsers, loading: hrRoleUserLoaing }] =
    useLazyQuery(HR_ROLE_USER_FOR_DROP_DOWN);

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
    clearErrors,
    watch,
    reset,
    control,
  } = useForm();

  const projectId = watch("project")?.id;
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const userId = localStorage?.id || +sessionId;

  const projectsList =
    getProjectsForCompensation?.projects?.dataCollection?.map(
      (project: IIdAndName) => {
        return {
          id: project?.id,
          name: project?.name,
        };
      }
    ) || [];

  const reportedToUsers = useMemo(
    () =>
      getProjectsForCompensation?.projects?.dataCollection
        ?.filter((project: { id: number }) => project?.id === projectId)
        ?.filter(
          (project: { manager: { personalInformation: IIdAndName } }) =>
            project?.manager?.personalInformation?.id !== userId
        )
        ?.map((project: { manager: { personalInformation: IIdAndName } }) => {
          return {
            id: project?.manager?.personalInformation?.id,
            name: project?.manager?.personalInformation?.name,
          };
        }) || [],
    [getProjectsForCompensation?.projects?.dataCollection, projectId, userId]
  );

  useEffect(() => {
    if (reportedToUsers?.length < 1 && !loading && projectId) {
      fetchHRRoleUser();
    }
  }, [fetchHRRoleUser, reportedToUsers, loading, projectId]);

  const hrUsers = hrRoleUsers?.getHRRoleUser?.map(
    (hr: { personalInformation: IIdAndName }) => {
      return {
        id: hr?.personalInformation?.id,
        name: hr?.personalInformation?.name,
      };
    }
  );

  const appliedDates =
    state?.id &&
    state?.dates?.map(
      (date: { leaveDate: string }) =>
        new Date(date?.leaveDate?.replaceAll("-", " "))
    );

  const minDate =
    appliedDates &&
    appliedDates.length > 0 &&
    appliedDates?.reduce((firstDate: Date, secondDate: Date) => {
      return firstDate < secondDate ? firstDate : secondDate;
    });

  const maxDate =
    appliedDates &&
    appliedDates.length > 0 &&
    appliedDates?.reduce((firstDate: Date, secondDate: Date) => {
      return firstDate > secondDate ? firstDate : secondDate;
    });

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: state?.id ? new Date(minDate) : null,
      endDate: state?.id ? new Date(maxDate) : null,
      key: "selection",
    },
  ]);

  const [rangeFocus, setRangeFocus] = useState<boolean>(false);

  const [selectedDates, setSelectedDates] = useState(
    getDateArray(dateRange[0]?.startDate, dateRange[0]?.endDate)
  );

  useEffect(() => {
    setSelectedDates(
      getDateArray(dateRange[0]?.startDate, dateRange[0]?.endDate)
    );
  }, [dateRange]);

  const editSessions = useMemo(
    () =>
      state?.dates
        ? state?.dates?.map((date: { session: string }) => date?.session)
        : [],
    [state]
  );

  const getLeaveDatesAndLeaveType = useCallback(
    (dates: string[]) => {
      return dates?.map((date: string, index: number) => {
        return {
          date,
          leaveType: 0,
          session: state?.dates ? editSessions[index] : "",
        };
      });
    },
    [editSessions, state]
  );

  const [workingDates, setWorkingDates] = useState<leaveRequestsDatesType[]>(
    getLeaveDatesAndLeaveType(selectedDates) || []
  );

  useEffect(() => {
    const workingDatesList = getLeaveDatesAndLeaveType(selectedDates);
    setWorkingDates(workingDatesList || []);
  }, [selectedDates, getLeaveDatesAndLeaveType]);

  useEffect(() => {
    if (rangeFocus && dateRange[0]?.startDate && dateRange[0]?.endDate) {
      setDupWorkingDates(workingDates);
    }
  }, [dateRange, rangeFocus, workingDates, getLeaveDatesAndLeaveType]);

  const [dupWorkingDates, setDupWorkingDates] = useState<
    leaveRequestsDatesType[]
  >(
    state?.id
      ? getLeaveDatesAndLeaveType(prepareDateArray(appliedDates))
      : workingDates
  );

  useEffect(() => {
    if (dupWorkingDates) {
      unregister("leaveRequests");
    }
  }, [dupWorkingDates, unregister]);

  const requestCompensationLeaveHandler = (data: any) => {
    if (!compensationRequestLoading) {
      requestCompensationLeave({
        variables: {
          compensationLeaveInput: {
            requestedToUser: data?.requestedToUser?.id,
            leaveRequestDates: data?.leaveRequests?.map(
              (leaveRequest: { leaveDate: string; session: string }) => {
                return {
                  ...leaveRequest,
                  projectId,
                };
              }
            ),
            reason: data?.reason,
          },
        },
      })
        .then(() => {
          navigate("/leaves/requests/my-comp-off");
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  const updateApplyCompensationLeaveHandler = (data: any) => {
    if (!updateApplyLeaveCompensationLoading) {
      updateApplyCompensationLeave({
        variables: {
          updateApplyCompensationLeaveInput: {
            id: +state?.id,
            requestedToUser: data?.requestedToUser?.id,
            leaveRequestDates: data?.leaveRequests?.map(
              (leaveRequest: { leaveDate: string; session: string }) => {
                return {
                  ...leaveRequest,
                  projectId: +projectId?.id,
                };
              }
            ),
            reason: data?.reason,
          },
        },
      })
        .then(() => {
          navigate("/leaves/requests/my-comp-off");
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <div className="mx-auto py-[45px] px-3 sm:px-0">
      <form
        onSubmit={handleSubmit(
          state?.id
            ? updateApplyCompensationLeaveHandler
            : requestCompensationLeaveHandler
        )}
        className="space-y-2"
      >
        <LeaveDates
          register={register}
          errors={errors}
          setRangeFocus={setRangeFocus}
          workingDates={dupWorkingDates}
          setDateRange={setDateRange}
          selectedDates={selectedDates}
          clearErrors={clearErrors}
          officialHolidays={[]}
          isEditing={state?.isEditing}
          disableSaturdaysSundaysAndHolidays={false}
          isMinDateRequired={false}
          maxDate={new Date()}
        />
        {dupWorkingDates?.map(
          (
            date: { date: string; leaveType: number; session: string },
            index: number
          ) => {
            return (
              <WorkingDates
                key={index}
                index={index}
                register={register}
                errors={errors}
                setValue={setValue}
                date={date?.date}
                dupWorkingDates={dupWorkingDates}
                setDupWorkingDates={setDupWorkingDates}
                editWorkingDates={state}
                dateRange={dateRange}
                session={date?.session}
              />
            );
          }
        )}
        <div
          className={`grid grid-cols-1 gap-4 sm:grid-cols-[150px_350px] items-center`}
        >
          <span className="text-sm text-ironside-gray sm:pb-[21px]">
            Projects
          </span>
          <AutoComplete
            control={control}
            label="Projects *"
            name={"project"}
            options={projectsList}
            errors={errors}
            setValue={setValue}
            defaultValue={
              state?.dates && {
                id: state?.dates?.length > 0 && state?.dates[0]?.project?.id,
                name:
                  state?.dates?.length > 0 && state?.dates[0]?.project?.name,
              }
            }
            required
          />
        </div>
        <div
          className={`grid grid-cols-1 gap-4 sm:grid-cols-[150px_350px] items-center`}
        >
          <span className="text-sm text-ironside-gray sm:pb-[21px]">
            Reporting Manager
          </span>
          <AutoComplete
            control={control}
            label="Reporting Manager *"
            name={"requestedToUser"}
            options={
              reportedToUsers?.length > 0 ? reportedToUsers : hrUsers || []
            }
            errors={errors}
            setValue={setValue}
            defaultValue={
              state?.requestToUser && {
                id: state?.requestToUser?.personalInformation?.id,
                name: state?.requestToUser?.personalInformation?.name,
              }
            }
            loading={loading || hrRoleUserLoaing}
            required
          />
        </div>
        <div
          className={`grid grid-cols-1 gap-4 sm:grid-cols-[150px_350px] items-center`}
        >
          <span className="text-sm text-ironside-gray mt-0 sm:pb-[21px]">
            Reason
          </span>
          <TextArea
            errors={errors}
            label="Reason *"
            name="reason"
            register={register}
            setValue={setValue}
            defaultValue={state?.reason}
            required={true}
          />
        </div>
        {(resourceAccess?.canCreate || resourceAccess?.canUpdate) && (
          <div className="w-full flex justify-center pt-3 space-x-3 sm:block sm:ml-[165px]">
            {!state && (
              <Outlined
                buttonName="Reset"
                onClick={() => {
                  setWorkingDates([]);
                  reset();
                }}
              />
            )}
            <Filled
              buttonType="submit"
              buttonName={"Submit"}
              loading={
                state?.id
                  ? updateApplyLeaveCompensationLoading
                  : compensationRequestLoading
              }
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default CompOffRequest;
