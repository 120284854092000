import { useReactiveVar } from "@apollo/client";

import TabLink from "components/App/TabLink/TabLink";

import { getAllowedAccess } from "global/helpers/Cache";

import LeaveApproval from "modules/Leave/Pages/Settings/AutoApprovalTimeSetting/LeaveApproval";
import Credit from "modules/Leave/Pages/Settings/Credit/Credit";
import LeaveType from "modules/Leave/Pages/Settings/LeaveType/LeaveType";

const SettingsRouteControl = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const tabLinks = [
    {
      name: "Leave Types",
      link: "/leaves/settings/leave-type",
      allowedResourceIds: [],
      showTab:
        allowedResources?.includes("LeaveType") &&
        allowedResources?.includes("ReadLeaveType")
          ? true
          : false,
    },
    {
      name: "Credit / Debit",
      link: "/leaves/settings/credit-debit",
      allowedResourceIds: [],
      showTab:
        allowedResources?.includes("LeaveCredits") &&
        allowedResources?.includes("CreateLeaveCredits")
          ? true
          : false,
    },
    {
      name: "Auto Approval",
      link: "/leaves/settings/auto-approval",
      allowedResourceIds: ["ReadAutoApprovalTime"],
    },
  ];

  const routes = [
    {
      path: "leave-type",
      element: <LeaveType allowedResources={allowedResources} />,
    },
    {
      path: "credit-debit",
      element: <Credit allowedResources={allowedResources} />,
    },
    {
      path: "auto-approval",
      element: <LeaveApproval allowedResources={allowedResources} />,
    },
  ];

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[800px] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default SettingsRouteControl;
