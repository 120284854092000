import { removeDuplicates } from "./ArrayMethods";

export const isAllowedResource = (
  allowedResources: string[],
  resourceIds: string[]
) => {
  const resourceArray = resourceIds?.map(
    (resouceId: string) => allowedResources?.includes(resouceId) || null
  );

  const resourceIdStatus = removeDuplicates(resourceArray)?.filter(
    (resource: string) => resource
  )[0];

  return !!resourceIdStatus;
};

export const testJSON = (strJson: any) => {
  try {
    const parsed = JSON.parse(strJson);
    if (parsed && typeof parsed === "object") {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};
