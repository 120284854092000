import { FC, Fragment, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { TbInfoCircle } from "react-icons/tb";

import { SingleActionPopup } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Tooltip from "components/UI/ToolTip";

import { ReactComponent as PencilIcon } from "global/assets/images/pencil-dark-icon.svg";
import { toastNotify } from "global/helpers/Cache";
import { ISetStateType } from "global/types/type";

import { WITHDRAW_APPLY_LEAVE } from "modules/Leave/services/mutations";
import { FILTER_MY_LEAVE_REQUESTS } from "modules/Leave/services/queries";
import { filterConsentorActions } from "modules/Leave/helper";
import LeaveInfo from "modules/Leave/Pages/Requests/LeaveInfo";

interface IProps {
  leaveRequestId: number;
  date: any;
  reason: any;
  leaveRequestAttachments: any[];
  setWithdrawVerification: Function;
  setWithdrawSingleDate: Function;
  deleteVerification: boolean;
  setDeleteVerification: Function;
  modalTitle: string;
  setModalTitle: ISetStateType<string>;
  requestedToUser: {
    personalInformation: {
      id: number;
      name: string;
    };
  };
  showLeaveType: boolean;
}

const DatesDetails: FC<IProps> = ({
  leaveRequestId,
  date,
  reason,
  leaveRequestAttachments,
  setWithdrawVerification,
  setWithdrawSingleDate,
  setModalTitle,
  modalTitle,
  requestedToUser,
  showLeaveType,
}) => {
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const leaveAttachment = date?.leaveType?.isSupportingDocNeeded
    ? leaveRequestAttachments
    : [];

  const [withdrawApplyLeave] = useMutation(WITHDRAW_APPLY_LEAVE, {
    refetchQueries: [FILTER_MY_LEAVE_REQUESTS],
  });

  const isConsentRequest =
    date?.leaveRequestDateConsents?.length > 0 ? true : false;

  const consentStatusses = date?.leaveRequestDateConsents?.map(
    (consent: { status: string }) => consent?.status
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const withdrawHandler = () => {
    if (!inProgress) {
      withdrawApplyLeave({
        variables: {
          id: +leaveRequestId,
          leaveRequestDates: [{ id: +date?.id, status: "Withdrawn" }],
        },
      })
        .then((response) => {
          setInProgress(false);
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your request has been withdrawn successfully.",
            },
          ]);
          if (response?.data?.updateApplyLeave?.leaveRequestDates?.length < 1) {
            navigate("/leaves/requests/my-requests", {});
          }
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const showEdit = isConsentRequest
    ? consentStatusses?.every((status: string) => status === "Pending")
      ? true
      : false
    : date?.status === "Pending"
    ? true
    : false;

  return (
    <div className="flex text-sm text-ironside-gray items-center">
      <span className="min-w-[80px]">{date?.leaveDate || ""}</span>
      &nbsp;&nbsp;-&nbsp;&nbsp;
      <span className="min-w-[100px]">
        {date.session === "2 Hours"
          ? showLeaveType
            ? `${date.session || ""} (${date?.startTime || ""} - ${
                date?.endTime || ""
              }) - ${date?.leaveType?.name || ""}`
            : `${date.session || ""} (${date?.startTime || ""} - ${
                date?.endTime || ""
              })`
          : showLeaveType
          ? `${date?.session || ""} - ${date?.leaveType?.name} `
          : date.session || ""}
      </span>
      <span
        className={`${
          date?.status === "Pending"
            ? "text-yellow-orange"
            : date?.status === "Approved"
            ? "text-dark-mint"
            : date?.status === "Declined"
            ? "text-brink-pink"
            : date?.status === "Cancelled"
            ? "text-valentine-red"
            : date?.status === "Withdrawn"
            ? "text-cornflower-blue"
            : ""
        } min-w-[80px] ml-2`}
      >
        {date?.status || ""}
      </span>
      {date?.status === "Approved" &&
        new Date(date?.leaveDate?.replaceAll("-", " ")) >= today && (
          <Tooltip render="Withdraw" arrow placement="top">
            <span>
              <MdOutlineClose
                className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer ml-2"
                onClick={() => {
                  setWithdrawVerification(true);
                  setModalTitle(
                    date?.session === "2 Hours" ? "Permission" : "Leave Request"
                  );
                  setWithdrawSingleDate({
                    id: +date?.id,
                    status: "Withdrawn",
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
      {date?.status === "Pending" && !date?.leaveType?.isSpecialLeave ? (
        <Fragment>
          {showEdit && (
            <Tooltip render="Edit" arrow placement="top">
              <span
                className="w-7 h-7 hover:bg-titan-white text-cornflower-blue rounded-full cursor-pointer flex justify-center items-center"
                onClick={() => {
                  navigate("/leaves/apply/regular", {
                    state: {
                      id: +leaveRequestId,
                      dates: [date],
                      reason: reason,
                      leaveRequestAttachments: leaveAttachment,
                      isEditing: true,
                    },
                  });
                }}
              >
                <PencilIcon className="w-4 h-5" />
              </span>
            </Tooltip>
          )}
          <Tooltip render="Delete" arrow placement="top">
            <span
              className="w-7 h-7 hover:bg-pale-pink text-valentine-red rounded-full cursor-pointer flex justify-center items-center"
              onClick={() => {
                setModalTitle(
                  date?.session === "2 Hours" ? "Permission" : "Leave Request"
                );
                setDeleteVerification(true);
              }}
            >
              {inProgress ? (
                <div className="btn-spinner" />
              ) : (
                <HiTrash className="w-5 h-4" />
              )}
            </span>
          </Tooltip>
        </Fragment>
      ) : null}
      {(date?.status === "Approved" ||
        date?.status === "Declined" ||
        date?.leaveRequestDateConsents?.length > 0) && (
        <Tooltip render="Leave Info" arrow placement="top">
          <span>
            <TbInfoCircle
              onClick={() => {
                setShowInfo(true);
              }}
              className="w-5 h-5 mt-1 cursor-pointer text-brown-rust/70 ml-2"
            />
          </span>
        </Tooltip>
      )}
      {deleteVerification && (
        <ConfirmModal
          header={modalTitle}
          keyValue="Delete"
          onCancel={() => {
            setDeleteVerification(false);
            setModalTitle("");
          }}
          onXIcon={() => {
            setDeleteVerification(false);
            setModalTitle("");
          }}
          onExecute={withdrawHandler}
          loading={inProgress}
        />
      )}
      {showInfo &&
      (date?.status === "Approved" ||
        date?.status === "Declined" ||
        date?.leaveRequestDateConsents?.length > 0) ? (
        <SingleActionPopup
          header={"Leave Info"}
          onExecute={() => setShowInfo((prevState) => !prevState)}
          customContent={
            <LeaveInfo
              teamLeadUserName={requestedToUser?.personalInformation?.name}
              declineMessage={date?.declineMessage}
              consents={filterConsentorActions(date?.leaveRequestDateConsents)}
              teamLeadLeaveActionStatus={date?.status}
              hasConsents={date?.leaveRequestDateConsents?.length}
              message={date?.message}
              statusChangedAt={date?.statusChangedAt}
            />
          }
        />
      ) : null}
    </div>
  );
};

export default DatesDetails;
