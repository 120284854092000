import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Otp } from "components/forms";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useMutation } from "@apollo/client";
import { toastNotify } from "global/helpers/Cache";
import { OTP_REQUEST, OTP_VERIFICATION } from "../services/mutations";

interface TimeDataType {
  minutes: number;
  seconds: number;
}

const OTP = () => {
  const [userVerify] = useMutation(OTP_VERIFICATION);
  const [forgotPassword] = useMutation(OTP_REQUEST);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [inProgress, setInProgress] = useState(false);
  const [verifyInProgress, setVerifyInProgress] = useState(false);

  const { handleSubmit } = useForm<{ otp: number }>();

  const [show, setshow] = useState<boolean>(false);
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);

  let minutes = 0o0;
  let seconds = 30;
  const [time, setTime] = React.useState<TimeDataType>({ minutes, seconds });

  const redirect = () => {
    setshow(true);
  };

  const tick = () => {
    if (time.minutes === 0 && time.seconds === 0) redirect();
    else if (time.seconds === 0) {
      setTime({ minutes: time.minutes - 1, seconds: 59 });
    } else if (time.seconds === 0) {
      setTime({ minutes: time.minutes - 1, seconds: 59 });
    } else {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const onSubmit = () => {
    if (!verifyInProgress && otp?.length > 5) {
      userVerify({
        variables: {
          email: state?.email,
          otp: +otp,
        },
      })
        .then((response) => {
          setVerifyInProgress(false);
          navigate("/reset-password", {
            state: {
              email: state?.email,
              confirmationCode: response?.data?.userVerify?.confirmationCode,
            },
          });
          toastNotify([
            {
              messageType: "success",
              message: "The OTP has been successfully submitted.",
            },
          ]);
        })
        .catch((error) => {
          setVerifyInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setVerifyInProgress(!verifyInProgress);
    }
  };

  const otpResendHandler = () => {
    if (!inProgress) {
      forgotPassword({
        variables: {
          email: state?.email,
        },
      })
        .then((response) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "success",
              message: "The OTP is successfully resent.",
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="form-design h-[394px] flex items-center flex-col py-[34px] px-[42px]">
        <h3 className="heading mb-[18px]">Enter OTP</h3>
        <p className="text-center mb-[18px] text-ironside-gray">
          We have sent you access code to{" "}
          <span className="text-warm-gray">{state?.email}</span>
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <Otp value={otp} valueLength={6} onChange={onChange} />
          <button
            className={`button-design mt-[4px] ${
              otp?.length < 5 ? "cursor-not-allowed" : ""
            } `}
          >
            {verifyInProgress ? <div className="btn-spinner" /> : "Verify"}
          </button>
        </form>
        <div className="flex justify-center mt-[18px] text-ironside-gray">
          Didn't Receive the OTP?
        </div>
        <div className="flex px-1 pt-3">
          <p
            onClick={() => {
              if (show) {
                otpResendHandler();
              }
            }}
          >
            <span
              className={`${
                show
                  ? "text-cornflower-blue cursor-pointer"
                  : "text-gray-400 cursor-progress"
              }`}
            >
              {inProgress ? (
                <div className="ml-1 w-5 h-5 border-4 border-t-transparent mx-auto border-cornflower-blue border-solid rounded-full animate-spin" />
              ) : (
                "Resend Code"
              )}
            </span>
            {!show && (
              <span className="text-red-600 ml-1 text-[11px] leading-[18px]">{`(${time?.seconds
                ?.toString()
                ?.padStart(2, "0")} Sec)`}</span>
            )}
          </p>
        </div>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => {
          setVerifyInProgress(false);
          setInProgress(false);
          navigate("/forgot-password");
        }}
      />
    </div>
  );
};

export default OTP;
