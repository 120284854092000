import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
	toastNotify,
	setBackNavigationURL,
	setTitle,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
	useCustomSearchParams,
	useEffectOnce,
	useSearch,
	useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import {
	ProjectGroupTask,
	ProjectGroupTasksFilterForm,
} from "modules/Project/Pages/Projects/ProjectGroupTasks/types";
import {
	DELETE_PROJECT_GROUP_TASK,
	FILTER_PROJECT_GROUP_TASKS,
} from "modules/Project/Pages/Projects/ProjectGroupTasks/services";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
	allowedResources: string[];
	projectGroup?: ProjectGroup | null;
}

const tableCommonHeadings = [
	{
		name: "Title",
		APIName: "title",
	},
	{
		name: "Projects",
	},
	{
		name: "Platform",
		APIName: "platform",
	},
	{
		name: "Priority",
		APIName: "priority",
	},
	{
		name: "Status",
		APIName: "status",
	},
	{
		name: "Description",
		APIName: "description",
	},
];

const ProjectGroupTasks: FC<Props> = ({ allowedResources, projectGroup }) => {
	const canCreate = allowedResources?.includes("CreateProjectGroupTask");
	const canUpdate = allowedResources?.includes("UpdateProjectGroupTask");
	const canDelete = allowedResources?.includes("DeleteProjectGroupTask");

	const tableHeadings = canDelete
		? [...tableCommonHeadings, { name: "Action" }]
		: tableCommonHeadings;

	const [searchParams, setSearchParams] = useCustomSearchParams();
	const { pathname, search: searchQuery } = useLocation();

	const { register, watch, control, handleSubmit, setValue, reset } =
		useForm<ProjectGroupTasksFilterForm>({
			defaultValues: {
				search: searchParams?.search ? searchParams?.search : "",
			},
		});

	const [deleteProjectGroupTask, { loading: deleteProjectGroupTaskLoading }] =
		useMutation(DELETE_PROJECT_GROUP_TASK, {
			refetchQueries: [FILTER_PROJECT_GROUP_TASKS],
		});

	useEffect(() => {
		if (pathname && searchQuery) {
			setBackNavigationURL(pathname + searchQuery);
		}
	}, [pathname, searchQuery]);
	const search = useSearch(watch("search"));
	const { groupId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		setTitle([
			{ name: "Project", link: "/projects/groups" },
			{ name: "Groups", link: "/projects/groups" },
			{
				name: projectGroup?.name || "",
				link: `/projects/${projectGroup?.id}/tasks`,
			},
			{ name: "Tasks", link: `/projects/${projectGroup?.id}/tasks` },
		]);
	}, [projectGroup?.id, projectGroup?.name]);

	const [currentPage, setCurrentPage] = useState<number>(
		!Number.isNaN(+searchParams?.page) ? +searchParams?.page : 1,
	);
	const [pageSize, setPageSize] = useState<number>(
		!Number.isNaN(+searchParams?.limit)
			? +searchParams?.limit
			: paginationDefaultValue,
	);

	useEffectOnce(() => {
		setSearchParams({
			...searchParams,
			page: `${currentPage}`,
			limit: `${pageSize}`,
		});
	});

	useUpdateEffect(() => {
		if (searchParams?.page && !Number.isNaN(+searchParams?.page)) {
			setCurrentPage(+searchParams?.page);
		}
		if (searchParams?.limit && !Number.isNaN(+searchParams?.limit)) {
			setPageSize(+searchParams?.limit);
		}
		setValue("search", searchParams?.search || "");
	}, [searchParams?.page, searchParams?.limit, searchParams?.search]);

	const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

	const {
		data: filterProjectGroupTasks,
		loading,
		refetch,
	} = useQuery(FILTER_PROJECT_GROUP_TASKS, {
		nextFetchPolicy: "no-cache",
		fetchPolicy: "network-only",
		variables: {
			filters: {
				projectGroupId: groupId ? +groupId : undefined,
				status: "pending",
			},
			search: search || undefined,
			orderBy: sortOrder
				? {
						field: sortOrder?.field,
						orderType: sortOrder?.orderType,
				  }
				: undefined,
			page: currentPage,
			limit: pageSize,
		},
	});

	const projectGroupTasksList =
		filterProjectGroupTasks?.filterProjectGroupTasks?.dataCollection || [];
	const totalCount =
		filterProjectGroupTasks?.filterProjectGroupTasks?.totalNumberOfItems || 0;

	const noOfItems = projectGroupTasksList?.length || 0;

	const [editProjectGroupTask, setEditProjectGroupTask] =
		useState<ProjectGroupTask | null>(null);
	const [filterValues, setFilterValue] = useState<object | null>(null);

	const tableRef = useRef<HTMLDivElement>(null);

	const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

	const deleteHandler = () => {
		if (editProjectGroupTask?.id) {
			deleteProjectGroupTask({
				variables: {
					id: +editProjectGroupTask?.id,
				},
			})
				.then(() => {
					refetch();
					setDeleteVerification(false);
					toastNotify([
						{
							messageType: "success",
							message: "Project group task deleted successfully.",
						},
					]);
				})
				.catch((error) =>
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]),
				);
		}
	};

	const onCardClick = (projectGroupTaskId: number) => {
		if (canUpdate) {
			navigate(`/projects/${groupId}/tasks/${projectGroupTaskId}`);
		}
	};

	const onDelete = (projectGroupTask: ProjectGroupTask) => {
		setEditProjectGroupTask(projectGroupTask);
		setDeleteVerification(true);
	};

	const addBtnHandler = () => {
		setEditProjectGroupTask(null);
		navigate(`/projects/${groupId}/tasks/new`);
	};

	const getDataModal = (projectGroupTask: ProjectGroupTask): IDataModal[] => {
		const projectNames =
			projectGroupTask?.projectBacklogs?.dataCollection &&
			projectGroupTask?.projectBacklogs?.dataCollection?.length > 0
				? projectGroupTask?.projectBacklogs?.dataCollection
						?.map((project) => project?.project?.name)
						?.filter((project) => project)
						?.join(", ")
				: "";
		return [
			{
				label: "Title",
				value: [
					{
						content: projectGroupTask?.title
							? Capitalize(projectGroupTask?.title)
							: "",
					},
				],
			},
			{
				label: "Projects",
				value: [
					{
						content: projectNames,
					},
				],
			},
			{
				label: "Platform",
				value: [
					{
						content:
							projectGroupTask?.platform &&
							projectGroupTask?.platform?.length > 0
								? projectGroupTask?.platform
										?.map((platform) => platform)
										?.join(", ")
								: "-",
					},
				],
			},
			{
				label: "Priority",
				value: [
					{
						content: projectGroupTask?.priority,
					},
				],
			},
			{
				label: "Status",
				value: [
					{
						content: projectGroupTask?.status,
					},
				],
			},
			{
				label: "Description",
				value: [
					{
						content: projectGroupTask?.description,
					},
				],
			},
		];
	};

	const getTableContent = (
		projectGroupTask: ProjectGroupTask,
	): ITableContent[] => {
		const projectNames =
			projectGroupTask?.projectBacklogs?.dataCollection &&
			projectGroupTask?.projectBacklogs?.dataCollection?.length > 0
				? projectGroupTask?.projectBacklogs?.dataCollection
						?.map((project) => project?.project?.name)
						?.filter((project) => project)
						?.join(", ")
				: "";
		return [
			{ value: projectGroupTask?.title, className: "max-w-[200px] truncate" },
			{ value: projectNames },
			{
				value:
					projectGroupTask?.platform && projectGroupTask?.platform?.length > 0
						? projectGroupTask?.platform
								?.map((platform) => platform)
								?.join(", ")
						: "-",
			},
			{
				value: projectGroupTask?.priority,
			},
			{
				value: projectGroupTask?.status,
			},
			{
				value: projectGroupTask?.description,
				className: "max-w-[300px] truncate",
			},
			{
				value: (
					<div className="flex justify-center">
						<ToolTip
							render={() => "Delete"}
							arrow
							classNameForParent="truncate"
						>
							<BiTrash
								className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									onDelete(projectGroupTask);
								}}
							/>
						</ToolTip>
					</div>
				),
				hideContent: !canDelete,
			},
		];
	};

	const confirmModalCloseHandler = () => {
		setDeleteVerification(false);
	};
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const clearFilterHandler = () => {
		reset();
		setFilterValue(null);
	};
	const filterBtnShowHandler = () => {
		setShowFilter(false);
	};

	const filterFormOption: TFilterOption<ProjectGroupTasksFilterForm> = [];

	const submitHandler: SubmitHandler<ProjectGroupTasksFilterForm> = (data) => {
		setFilterValue(getFilterFormValues(data, filterFormOption));
		filterBtnShowHandler();
	};
	const noDataFoundMessage = (
		<p
			children={"Sorry we couldn't found any groups."}
			className="w-full min-h-[80vh] inline-flex border mb-2 rounded-md shadow justify-center items-center text-sm text-ironside-gray"
		/>
	);

	return (
		<div className="mt-5">
			<div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
				<Filter
					control={control}
					handleSubmit={handleSubmit}
					watch={watch}
					clearFilterHandler={clearFilterHandler}
					searchInputName="search"
					filterName="filter"
					options={filterFormOption}
					register={register}
					submitHandler={submitHandler}
					filterBtnShowHandler={filterBtnShowHandler}
					showFilter={showFilter}
					setValue={setValue}
					isCloseIconNeeded={true}
					hideFilterBtn
					hideSearchBtn={search?.length === 0 && noOfItems === 0}
				/>
				{search?.length === 0 && noOfItems === 0
					? null
					: (canCreate && (
							<Filled buttonName="Add" onClick={addBtnHandler} />
					  )) ||
					  null}
			</div>
			{!loading ? (
				<Fragment>
					{noOfItems !== 0 ? (
						<Fragment>
							<div className="md:hidden mx-auto">
								<div className="min-h-[80vh] space-y-3">
									{projectGroupTasksList?.map((projectGroupTask) => {
										return (
											<DataCard
												key={projectGroupTask?.id}
												dataModal={getDataModal(projectGroupTask)}
												classForULlist={`${
													canUpdate ? "cursor-pointer" : "cursor-default"
												}`}
												cardClick={() => {
													canUpdate &&
														projectGroupTask?.id &&
														onCardClick(projectGroupTask?.id);
												}}
												classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
												disableClickToViewToolTip={true}
												otherOption={
													(canDelete && (
														<div className="flex justify-center">
															<ToolTip
																render={() => "Delete"}
																arrow
																className={
																	"text-center bg-[#616161] text-white"
																}
															>
																<BiTrash
																	className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
																	onClick={(e) => {
																		e.stopPropagation();
																		onDelete(projectGroupTask);
																	}}
																/>
															</ToolTip>
														</div>
													)) ||
													undefined
												}
											/>
										);
									})}
								</div>
								<Pagination
									totalCount={totalCount}
									currentPage={currentPage}
									onPageChange={(page: number) => {
										setSearchParams({
											...searchParams,
											page: `${page}`,
										});
										setCurrentPage(page);
									}}
									onRowPerPageChange={(rowPerPage) => {
										setSearchParams({
											...searchParams,
											limit: `${rowPerPage}`,
										});
									}}
									pageSize={pageSize}
									setPageSize={setPageSize}
									siblingCount={1}
									noOfItem={noOfItems}
								/>
							</div>

							<div className="hidden md:block ">
								<Table
									loading={loading}
									className="min-h-[80vh]"
									onPagination={{
										totalCount,
										currentPage: currentPage,
										setCurrentPage: setCurrentPage,
										pageSize: pageSize,
										setPageSize: setPageSize,
										onPageChange: (page) => {
											setSearchParams({
												...searchParams,
												page: `${page}`,
											});
										},
										onRowPerPageChange: (rowPerPage) => {
											setSearchParams({
												...searchParams,
												limit: `${rowPerPage}`,
											});
										},
										noOfItems,
									}}
									tableHeadings={{
										tableObject: tableHeadings?.map(
											(heading: { name: string; APIName?: string }) => {
												return {
													name: heading?.name,
													center: heading?.name === "Action" ? true : false,
													APIName: heading?.APIName,
												};
											},
										),
									}}
									ref={tableRef}
									isCenterLastHeading={false}
									isSortOrderNeeded
									setSortOrder={setSortOrder}
									sortOrder={sortOrder}
								>
									{projectGroupTasksList?.map((projectGroupTask) => {
										return (
											<TableContent
												key={projectGroupTask?.id}
												tableRef={tableRef}
												classForTableRow={`${
													canUpdate
														? "cursor-pointer hover:bg-white-smoke"
														: "cursor-default hover:bg-white-smoke"
												}`}
												tableRowClick={() => {
													canUpdate &&
														projectGroupTask?.id &&
														onCardClick(projectGroupTask?.id);
												}}
												tableContent={getTableContent(projectGroupTask)}
											/>
										);
									})}
								</Table>
							</div>
						</Fragment>
					) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
						noDataFoundMessage
					) : canCreate ? (
						<div className="w-full h-[82vh] grid place-content-center">
							<Filled buttonName="Add" onClick={addBtnHandler} />
						</div>
					) : (
						noDataFoundMessage
					)}
				</Fragment>
			) : (
				<Loading className="min-h-[90vh]" />
			)}
			{deleteVerification && (
				<ConfirmModal
					header="Project group task"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectGroupTaskLoading}
					register={register}
				/>
			)}
		</div>
	);
};

export default ProjectGroupTasks;
