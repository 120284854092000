import { FC } from "react";
import { useReactiveVar } from "@apollo/client";

import UserProfile from "components/UserDetails/UserProfile";

import { ISetStateType } from "global/types/type";
import { userContextData } from "global/helpers/Cache";

import { IProjectTaskComment } from "modules/Project/types/project";
import TipTap from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/TipTap/TipTap";

interface IProps {
  editComment: IProjectTaskComment | null;
  refetchComments: Function;
  setEditComment: ISetStateType<IProjectTaskComment | null>;
  setComments: ISetStateType<IProjectTaskComment[]>;
  showComment: boolean;
  setShowComment: ISetStateType<boolean>;
  membersList: { id: number; name: string }[] | [];
}

const CommentForm: FC<IProps> = ({
  editComment,
  refetchComments,
  setEditComment,
  setComments,
  showComment,
  setShowComment,
  membersList,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const profileDetails = userDataList?.user || null;

  return (
    <>
      {!showComment && !editComment?.id && (
        <div className="flex items-center gap-3 pb-4 pt-2">
          {profileDetails && (
            <UserProfile
              profile={{
                personalInfromation: profileDetails.personalInformation,
                userProfileImages: profileDetails.userProfileImages,
              }}
            />
          )}
          <p
            className="pl-3 border w-full py-2 cursor-pointer"
            onClick={() => setShowComment(true)}
          >
            Add a comment...
          </p>
        </div>
      )}
      {(showComment || !!editComment?.id) && (
        <div className="mb-8">
          <div className="flex gap-4">
            {profileDetails && (
              <UserProfile
                profile={{
                  personalInfromation: profileDetails.personalInformation,
                  userProfileImages: profileDetails.userProfileImages,
                }}
              />
            )}
            <TipTap
              editComment={editComment}
              setShowComment={setShowComment}
              setEditComment={setEditComment}
              setComments={setComments}
              refetchComments={refetchComments}
              membersList={membersList}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CommentForm;
