import { FC, useCallback, useEffect, useRef, useState } from "react";

import { MeatBallsMenu } from "components/forms";
import { KebabMenu } from "components/forms";
import UserDetails from "components/UserDetails/UserDetails";
import { ITableContent } from "components/App/Table/types/table";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";

import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import { HiPencil } from "react-icons/hi";

interface IProps {
  tableContent: ITableContent[];
  isChecked?: boolean;
  editAccess?: boolean;
  id: number;
  checkBoxChangeHandler?: () => void;
  onAccept?: () => void;
  onDecline?: () => void;
  onMenuClick?: (id: number) => void;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onViewMeatBallsMenu?: () => void;
  showModal?: boolean;
  parentRef: any;
  onPencilIconClick?: () => void;
  refetchQuery?: Function;
}

const TableContent: FC<IProps> = ({
  tableContent,
  editAccess,
  isChecked,
  id,
  checkBoxChangeHandler,
  onAccept,
  onDecline,
  onMenuClick,
  onView,
  onEdit,
  onDelete,
  onViewMeatBallsMenu,
  showModal,
  parentRef,
  onPencilIconClick,
  refetchQuery,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<
    (number | null)[]
  >([]);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const isMeatBallsMenuShow = onViewMeatBallsMenu && onAccept && onDecline;
  const isKebabMenu = onEdit || onDelete;
  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    let leftSidePositon = isMeatBallsMenuShow ? 100 : 90;
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - leftSidePositon
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, [isMeatBallsMenuShow]);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const parentEventRef = parentRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    parentEventRef && parentEventRef?.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [getPosition, parentRef]);

  useEffect(() => {
    if (showModal) {
      setShowEditOption(false);
    }
  }, [showModal]);

  const profileRef = useRef<HTMLDivElement>(null);
  const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );
  const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );

  const getPositionOfProfileName = useCallback(() => {
    setTopProfileRef(
      profileRef.current?.getBoundingClientRect().top &&
        profileRef.current?.getBoundingClientRect().top + 25
    );
    setleftProfileRef(
      profileRef.current?.getBoundingClientRect().left &&
        profileRef.current?.getBoundingClientRect().left
    );
  }, []);

  useEffect(() => {
    const eventRef = profileRef.current;
    window.addEventListener("resize", getPositionOfProfileName);
    window.addEventListener("scroll", getPositionOfProfileName);

    eventRef &&
      eventRef?.addEventListener("mouseenter", getPositionOfProfileName);

    return () => {
      window.removeEventListener("resize", getPositionOfProfileName);
      window.removeEventListener("scroll", getPositionOfProfileName);
      eventRef &&
        eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
    };
  }, [getPositionOfProfileName]);

  const profileDetailsDivStyle = {
    top: `${topProfileRef}px`,
    left: `${leftProfileRef}px`,
  };

  const tableContentFile = (file: any) => {
    if (file?.length > 100) {
      return (
        <a href={Base64toObject(file)} target="_blank" rel="noreferrer">
          <LinkIcon className="w-[14px] h-[14px] cursor-pointer text-cornflower-blue" />
        </a>
      );
    } else {
      return "No File";
    }
  };

  const kebabIconClickHandler = () => {
    setShowEditOption((prevState: any) => !prevState);
    onMenuClick && id && onMenuClick(id);
  };

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };

  const moderationProps = {
    setChangedInformation: setChangedInformation,
    setShowUnderReview: setShowUnderReview,
  };

  const moderationPopUpHandler = (moderationId: number | null) => {
    moderationId && setShowUnderReview(true);
    setChangedInformation([moderationId]);
  };

  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      {checkBoxChangeHandler && (
        <td className="py-5 px-3">
          <div className="flex items-center w-full justify-center">
            <input
              type="checkbox"
              className="h-4 w-4 border border-light-gray accent-bright-blue cursor-pointer"
              onChange={checkBoxChangeHandler}
              checked={isChecked}
            />
          </div>
        </td>
      )}

      {tableContent?.map((tableContent, index) => {
        return (
          <td
            key={index}
            className={`py-5 px-3 ${
              tableContent?.className || "max-w-xs truncate"
            } ${tableContent?.color || ""} ${
              tableContent?.center ? "text-center" : ""
            } ${
              tableContent?.moderationId ? "bg-yellow-100 cursor-pointer" : ""
            }`}
            onClick={() => {
              !tableContent?.file &&
                tableContent?.moderationId &&
                moderationPopUpHandler(tableContent?.moderationId);
            }}
          >
            {tableContent?.profileDetails ? (
              <div className={"group max-w-xs truncate"} ref={profileRef}>
                <span className="max-w-xs truncate">{tableContent?.value}</span>
                <div
                  style={profileDetailsDivStyle}
                  className="max-w-xs truncate"
                >
                  <UserDetails />
                </div>
              </div>
            ) : (
              <div
                title={`${
                  tableContent?.value?.type
                    ? "File"
                    : tableContent?.value || "-"
                }`}
                className={`${
                  tableContent?.value?.type ? "" : "inline-block w-min"
                } ${tableContent?.className || "max-w-xs truncate"}`}
              >
                {(tableContent?.file ? (
                  <div className="flex items-center gap-3 w-min max-w-xs truncate">
                    <span className="max-w-xs truncate">
                      {tableContent?.value}
                    </span>
                    <span>{tableContentFile(tableContent?.file)}</span>
                  </div>
                ) : (
                  tableContent?.value
                )) || "-"}
              </div>
            )}
          </td>
        );
      })}

      {editAccess && (isMeatBallsMenuShow || isKebabMenu) && (
        <td className="relative py-5 px-3">
          <div className="w-full flex justify-center items-center">
            <div ref={editRef} className="w-min">
              {editAccess && (
                <div ref={kebabIconRef}>
                  <KebabIcons
                    onClick={kebabIconClickHandler}
                    className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke rotate-90 ${
                      showEditOption ? "bg-white-smoke" : null
                    }`}
                  />
                </div>
              )}

              {showEditOption && (
                <div className="fixed z-[80]" style={showEditOptionStyle}>
                  {isMeatBallsMenuShow && (
                    <MeatBallsMenu
                      onView={onViewMeatBallsMenu}
                      onAccept={onAccept}
                      onDecline={onDecline}
                    />
                  )}

                  {isKebabMenu && (
                    <KebabMenu
                      onEdit={onEdit || undefined}
                      onDelete={onDelete || undefined}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
      )}

      {onView && (
        <td className="py-5 px-3 text-ironside-gray whitespace-nowrap">
          <span
            className="cursor-pointer hover:text-cornflower-blue hover:underline"
            onClick={onView}
          >
            View
          </span>
        </td>
      )}

      {editAccess && onPencilIconClick && (
        <td className="py-5 px-3 whitespace-nowrap">
          <div className="flex justify-center items-center">
            <HiPencil
              className="w-5 h-5 cursor-pointer text-ironside-gray/70"
              onClick={() => {
                onPencilIconClick && onPencilIconClick();
              }}
            />
          </div>
        </td>
      )}

      {showUnderReview && (
        <td>
          <UnderReviewModal
            {...moderationProps}
            changedInformation={changedInformation}
            refetchQuery={refetchQuery}
          />
        </td>
      )}
    </tr>
  );
};

export default TableContent;
