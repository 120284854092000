import { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import NoDataFoundMessage from "components/UI/NoDataFoundMessage";
import AddButton from "components/forms/Button/AddButton";

import {
  getAllowedAccess,
  paginationDefaultCount,
  setTitle,
  toastNotify,
} from "global/helpers/Cache";
import { FilterFormType } from "global/types/type";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
} from "global/UpdatedHooks/hooks";
import usePreLoading from "global/hooks/usePreLoading";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  DELETE_PROJECT_GROUP_BUG_SOURCE,
  FILTER_PROJECT_BUG_SOURCE,
} from "modules/Project/Pages/ProjectGroupBugSource/services";
import {
  ProjectGroupBugSource,
  ProjectGroupBugSourceEdge,
  ProjectGroupBugSourceEditList,
  ProjectGroupBugSourceFieldArgs,
  ProjectGroupBugSourceFilterFormType,
} from "modules/Project/Pages/ProjectGroupBugSource/types";
import { filterSubmitValues } from "modules/Project/Pages/ProjectGroupBugSource/helpers/utils";
import ProjectBugSourceFilter from "modules/Project/Pages/ProjectGroupBugSource/helpers/ProjectBugSourceFilter";
import EditProjectGroupBugSource from "modules/Project/Pages/ProjectGroupBugSource/EditProjectGroupBugSource";
import ProjectGroupBugSourceCard from "modules/Project/Pages/ProjectGroupBugSource/ProjectGroupBugList/ProjectGroupBugSourceCard";
import ProjectGroupBugSourceTable from "modules/Project/Pages/ProjectGroupBugSource/ProjectGroupBugList/ProjectGroupBugSourceTable";

const fieldArgs: ProjectGroupBugSourceFieldArgs = {
      isProjectGroupBugSourceDescriptionNeed: true,
      isProjectGroupBugSourceProjectGroupNeed: true,
}

const ProjectBugSources = () => {
   const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const canCreate = allowedResources?.includes("CreateProjectBugSource");
  const canUpdate = allowedResources?.includes("UpdateProjectBugSource");
  const canDelete = allowedResources?.includes("DeleteProjectBugSource");

  const [deleteProjectBugSource, { loading: deleteProjectBugSourceLoading }] =
    useMutation(DELETE_PROJECT_GROUP_BUG_SOURCE);

  const [searchParams, setSearchParams] = useCustomSearchParams();

  const [editProjectBugSource, setEditProjectBugSource] =
    useState<ProjectGroupBugSourceEditList | null>(null);

  const paginationDefaultValue = useReactiveVar(paginationDefaultCount);

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      limit: `${paginationDefaultValue}`,
    });
  });

  const [filterValues, setFilterValues] = useState<
    ProjectGroupBugSourceFilterFormType | null | undefined
  >(null);

  const { control, resetField, reset, setValue } = useForm<
    FilterFormType<ProjectGroupBugSourceFilterFormType>
  >({
    defaultValues: {
      search: searchParams?.search || "",
      pageSize: paginationDefaultValue || 10,
      filter: {
        description: null,
        names: null,
        projectGroupNames: null,
      },
    },
    shouldUnregister: false,
  });

  const [pageSize, watchSearch] = useWatch({
    control,
    name: ["pageSize", "search"],
  });
  const search = useSearch(watchSearch || "");

  useEffect(() => {
    setTitle([
      { name: "Project", link: "/projects/projects/my-projects" },
      {
        name: "Bug source",
        link: "/projects/settings/bug-source",
      },
    ]);
  }, []);

  const {
    data: filterProjectBugSource,
    loading,
    fetchMore,
    updateQuery,
  } = useQuery(FILTER_PROJECT_BUG_SOURCE, {
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: {
        size: pageSize,
      },
      globalSearch: search || undefined,
      ...fieldArgs,
    },
    notifyOnNetworkStatusChange: true,
  });

  const onEdit = (
    type: "create" | "update" | "delete",
    projectGroupBugSource?: ProjectGroupBugSource | null
  ) => {
    if (type === "create") {
      setEditProjectBugSource({ type });
    } else if (projectGroupBugSource) {
      setEditProjectBugSource({ type, projectGroupBugSource });
    }
  };

  const closeHandler = () => {
    setEditProjectBugSource(null);
  };

  const projectGroupBugSourceEdges =
    filterProjectBugSource?.filterProjectBugSource?.edges || [];

  const projectGroupBugSourceLength = projectGroupBugSourceEdges?.length || 0;

  const totalCount =
    filterProjectBugSource?.filterProjectBugSource?.pageInfo
      ?.totalNumberOfItems || 0;

  const addBtnHandler = () => {
    onEdit("create");
  };

  const preLoading = usePreLoading({
    queryLoading: loading,
  });

  const onPageSizeChange: (pageSize: number) => void = (pageSize) => {
    resetField("search");
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
        globalSearch: search || undefined,
        filters: filterValues
          ? {
              ...filterSubmitValues(filterValues),
            }
          : undefined,
            ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectBugSource } }) => {
        return { filterProjectBugSource };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const nextDisabled =
    filterProjectBugSource?.filterProjectBugSource?.pageInfo?.endCursor &&
    filterProjectBugSource?.filterProjectBugSource?.pageInfo?.hasNextPage
      ? false
      : true;

  const onNextPageHandler = () => {
    fetchMore({
      variables: {
        filters: filterValues
          ? {
              ...filterSubmitValues(filterValues),
            }
          : undefined,
        pagination: {
          size: pageSize,
          after:
            filterProjectBugSource?.filterProjectBugSource?.pageInfo?.endCursor,
        },
        globalSearch: search || undefined,
          ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectBugSource } }) => {
        return { filterProjectBugSource };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onPrevPageHandler = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
          before:
            filterProjectBugSource?.filterProjectBugSource?.pageInfo
              ?.startCursor,
        },
        globalSearch: search || undefined,
        filters: filterValues
          ? {
              ...filterSubmitValues(filterValues),
            }
          : undefined,
            ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectBugSource } }) => {
        return { filterProjectBugSource };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const prevDisabled =
    filterProjectBugSource?.filterProjectBugSource?.pageInfo?.startCursor &&
    filterProjectBugSource?.filterProjectBugSource?.pageInfo?.hasPreviousPage
      ? false
      : true;

  const onDelete = () => {
    if (
      editProjectBugSource?.type === "delete" &&
      editProjectBugSource?.projectGroupBugSource?.id
    ) {
      deleteProjectBugSource({
        variables: {
          id: editProjectBugSource?.projectGroupBugSource?.id,
        },
      })
        .then(({ data }) => {
          if (data && data?.deleteProjectBugSource) {
            closeHandler();
            toastNotify([
              {
                message: "Bug source deleted successfully.",
                messageType: "success",
              },
            ]);
            if (
              filterProjectBugSource?.filterProjectBugSource?.edges?.length ===
                1 &&
              filterProjectBugSource?.filterProjectBugSource?.pageInfo
                ?.hasPreviousPage &&
              filterProjectBugSource?.filterProjectBugSource?.pageInfo
                ?.startCursor
            ) {
              fetchMore({
                variables: {
                  pagination: {
                    size: pageSize,
                    before:
                      filterProjectBugSource?.filterProjectBugSource?.pageInfo
                        ?.startCursor || undefined,
                  },
                 ...fieldArgs,
                },
                updateQuery: (
                  prev,
                  { fetchMoreResult: { filterProjectBugSource } }
                ) => {
                  return {
                    filterProjectBugSource,
                  };
                },
              }).catch((error) => {
                toastNotify(errorMessageNotify(error));
              });
              reset();
            } else {
              updateQuery(({ filterProjectBugSource }) => {
                return {
                  filterProjectBugSource: filterProjectBugSource
                    ? {
                        pageInfo: filterProjectBugSource?.pageInfo
                          ? {
                              ...filterProjectBugSource?.pageInfo,
                              totalNumberOfItems: filterProjectBugSource
                                ?.pageInfo?.totalNumberOfItems
                                ? filterProjectBugSource?.pageInfo
                                    ?.totalNumberOfItems - 1
                                : 0,
                            }
                          : null,
                        edges:
                          filterProjectBugSource?.edges &&
                          filterProjectBugSource?.edges?.length > 0
                            ? filterProjectBugSource?.edges?.filter(
                                (edge) =>
                                  edge?.node?.id !==
                                  editProjectBugSource?.projectGroupBugSource
                                    ?.id
                              ) || []
                            : [],
                        __typename: filterProjectBugSource?.__typename,
                      }
                    : null,
                };
              });
            }
          } else {
            toastNotify([
              {
                message: "Something went wrong.",
                messageType: "error",
              },
            ]);
          }
        })
        .catch((err) => toastNotify(errorMessageNotify(err)));
    }
  };

  const cardOrTableClickHandler = (edge: ProjectGroupBugSourceEdge) => {
    if (edge?.node?.id && canUpdate) {
      onEdit("update", edge?.node);
    }
  };

  const tableAndCardProps = {
    canUpdate,
    canDelete,
    onEdit,
    cardOrTableClickHandler,
    nextDisabled,
    prevDisabled,
    control,
    totalCount,
    projectGroupBugSourceEdges,
    onPageSizeChange,
    onNextPageHandler,
    onPrevPageHandler,
  };

  return (
    <Fragment>
      <ProjectBugSourceFilter
        control={control}
        setValue={setValue}
        search={search}
        projectGroupBugSourceLength={projectGroupBugSourceLength}
        canCreate={canCreate}
        addBtnHandler={addBtnHandler}
        pageSize={pageSize}
        fetchMore={fetchMore}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        fieldArgs={fieldArgs}
      />
      {preLoading ? (
        <Loading className="min-h-[calc(100vh-25vh)]" />
      ) : projectGroupBugSourceLength > 0 ? (
        <Fragment>
          <ProjectGroupBugSourceCard {...tableAndCardProps} />
          <ProjectGroupBugSourceTable {...tableAndCardProps} />
        </Fragment>
      ) : (search?.length > 0 && projectGroupBugSourceLength === 0) ||
        !canCreate ? (
        <NoDataFoundMessage message="Sorry, we couldn't find any bug source." />
      ) : (
        canCreate && <AddButton onPress={addBtnHandler} />
      )}

      {(editProjectBugSource?.type === "create" ||
        editProjectBugSource?.type === "update") && (
        <EditProjectGroupBugSource
          onClose={closeHandler}
          updateQuery={updateQuery}
          projectGroupBugSource={
            editProjectBugSource?.type === "update"
              ? editProjectBugSource?.projectGroupBugSource
              : undefined
          }
        fieldArgs={fieldArgs}
        />
      )}
      {canDelete && editProjectBugSource?.type === "delete" && (
        <ConfirmModal
          header="Project group bug source"
          onCancel={closeHandler}
          onXIcon={closeHandler}
          onExecute={onDelete}
          loading={deleteProjectBugSourceLoading}
        />
      )}
    </Fragment>
  );
};

export default ProjectBugSources;
