import { useReactiveVar } from "@apollo/client";
import { getAllowedAccess } from "global/helpers/Cache";
import { useRef } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Department from "./Department/Department";
import Designation from "./Designation/Designation";
import Users from "./Users/Users";
import Clients from "./Clients/Pages/Clients";

const Organization = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;
  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] bg-white">
      <div>
        <div
          ref={slider}
          onMouseDown={(e) => {
            isDown = true;
            startX = e?.pageX - slider.current?.offsetLeft;
            scrollLeft = slider?.current?.scrollLeft;
          }}
          onMouseLeave={() => {
            isDown = false;
          }}
          onMouseUp={() => {
            isDown = false;
          }}
          onMouseMove={(e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider?.current?.offsetLeft;
            const walk = (x - startX) * 1;
            slider.current.scrollLeft = scrollLeft - walk;
          }}
          className="tab-nav"
        >
          {allowedResources?.includes("Users") &&
          allowedResources?.includes("ReadAllEmployees") ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/organization/users"}
            >
              Employees
            </NavLink>
          ) : null}
           {allowedResources?.includes("Users") &&
          allowedResources?.includes("ReadAllEmployees") ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/organization/clients"}
            >
              Clients
            </NavLink>
          ) : null}
          {allowedResources?.includes("Departments") &&
          allowedResources?.includes("ReadEmployeeDepartment") ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/organization/departments"}
            >
              Departments
            </NavLink>
          ) : null}
          {allowedResources?.includes("Designations") &&
          allowedResources?.includes("ReadDesignation") ? (
            <NavLink
              className={({ isActive }) =>
                `tab-nav-item ${isActive ? "active" : ""}`
              }
              to={"/organization/designations"}
            >
              Designations
            </NavLink>
          ) : null}
        </div>
        <Routes>
          <Route
            path="users"
            element={<Users allowedResources={allowedResources} />}
          />
           <Route
            path="clients"
            element={<Clients allowedResources={allowedResources} />}
          />
          <Route
            path="departments"
            element={<Department allowedResources={allowedResources} />}
          />
          <Route
            path="designations"
            element={<Designation allowedResources={allowedResources} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default Organization;
