export const colors = {
  "Cyan-Blue": "#ECF3FA",
  "Tealish-Blue": "#F8FAFF",
  "Yellowish Orange": "#FAF1E1",
  Green: "#DCF5DE",
};

export const saveProjectTaskACLS = [
  "SaveProjectTaskTitle",
  "SaveProjectTaskInCharge",
  "SaveProjectTaskLabel",
  "SaveProjectTaskColumn",
  "SaveProjectTaskDescription",
  "SaveProjectTaskSprint",
  "SaveProjectTaskPriority",
  "SaveProjectTaskStartDate",
  "AddProjectTaskApprovedHour",
  "AddProjectTaskAttachment",
  "SaveProjectTaskDueDate",
  "SaveProjectTaskIsBillable",
  "SaveProjectTaskBillingStatus",
  "SaveProjectTaskBillingHours",
];

export const readTasksACL = [
  "ReadProjectTaskInCharge",
  "ReadProjectTaskSprint",
  "ReadProjectTaskLabel",
  "ReadProjectTaskStartDate",
  "ReadProjectTaskDueDate",
  "ReadProjectTaskBillingStatus",
];

export const checkHasAllAccess = (
  ACLArray: string[],
  allowedResources: { [key: string]: boolean }
) => {
  const ACLList: any[] = [];

  for (const ACL of ACLArray) {
    ACLList.push(allowedResources[ACL]);
  }

  const hasAllAccess = ACLList?.every((ACL: boolean) => ACL === true);

  return hasAllAccess;
};

export const checkHasAnyAccess = (
  ACLArray: string[],
  allowedResources: { [key: string]: boolean }
) => {
  const ACLList: any[] = [];

  for (const ACL of ACLArray) {
    ACLList.push(allowedResources[ACL]);
  }

  const hasAnyAccess = ACLList?.some((ACL: boolean) => ACL === true);

  return hasAnyAccess;
};
