import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";

import { Filled, Outlined, UnderReviewBtn } from "components/forms";
import Tree from "./Tree";
import { childNodesTypes } from "./Row";

import { toastNotify } from "global/helpers/Cache";

import { CREATE_OR_UPDATE_PROJECT_ROLE } from "modules/Project/services/mutations";

const ProjectRoleResources = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const [inProgress, setInProgress] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const [saveProjectRole] = useMutation(CREATE_OR_UPDATE_PROJECT_ROLE);

  const [projectACLTree, setProjectACLTree] = useState<childNodesTypes[]>(
    state?.aclTree
  );

  const [changedProjectACLTree, setChangedProjectACLTree] =
    useState<childNodesTypes>();

  const changeIsAllowed = (aclTree: childNodesTypes[]) => {
    if (aclTree?.length === 0) return [];
    let checkbox = false;
    if (changedProjectACLTree) {
      checkbox = changedProjectACLTree.isAllowed;
    }
    return aclTree?.map((treeDetail) => {
      treeDetail.isAllowed = checkbox;
      treeDetail.isIndeterminateStatus = false;
      if (treeDetail?.childNodes?.length > 0) {
        changeIsAllowed(treeDetail?.childNodes);
      }
      return treeDetail;
    });
  };

  const setACLTreeStructure = (aclTree: childNodesTypes[]) => {
    let checkbox = false;
    if (changedProjectACLTree) {
      checkbox = changedProjectACLTree.isAllowed;
    }
    return aclTree?.map((treeDetail) => {
      if (changedProjectACLTree?.id === treeDetail.id) {
        treeDetail.isAllowed = checkbox;
        treeDetail.childNodes = changeIsAllowed(treeDetail?.childNodes);
      }
      if (treeDetail?.childNodes?.length > 0) {
        setACLTreeStructure(treeDetail?.childNodes);
      }
      return treeDetail;
    });
  };

  setACLTreeStructure(projectACLTree);

  const setCheckBoxValueChildNode = useCallback((aclTree: any) => {
    if (aclTree?.length === 0) return [];

    return aclTree?.map((treeDetail: childNodesTypes, index: number) => {
      if (treeDetail?.childNodes?.length > 0) {
        const isAllowedArray: boolean[] = [];
        const getAllChildrenAllowedStatus = (treeData: childNodesTypes[]) => {
          treeData?.map((treeData) => {
            isAllowedArray.push(treeData?.isAllowed);
            if (treeData?.childNodes?.length > 0) {
              getAllChildrenAllowedStatus(treeData?.childNodes);
            }
            return null;
          });
        };

        getAllChildrenAllowedStatus([aclTree[index]]);
        if (isAllowedArray?.every((isAllowed: boolean) => isAllowed)) {
          return {
            ...treeDetail,
            isAllowed: true,
            isIndeterminateStatus: false,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        } else if (
          treeDetail?.childNodes?.some(
            (childNode: any) => childNode?.isAllowed === true
          )
        ) {
          return {
            ...treeDetail,
            isAllowed: true,
            isIndeterminateStatus: true,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        } else {
          return {
            ...treeDetail,
            isAllowed: false,
            isIndeterminateStatus: false,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        }
      }
      return treeDetail;
    });
  }, []);

  const setCheckBoxValue = useCallback(
    (aclTree: childNodesTypes[]) => {
      return aclTree?.map((treeDetail: childNodesTypes, index: number) => {
        if (treeDetail?.childNodes?.length > 0) {
          const isAllowedArray: boolean[] = [];
          const getAllChildrenAllowedStatus = (treeData: childNodesTypes[]) => {
            treeData?.map((treeData) => {
              isAllowedArray.push(treeData?.isAllowed);
              if (treeData?.childNodes?.length > 0) {
                getAllChildrenAllowedStatus(treeData?.childNodes);
              }
              return null;
            });
          };

          getAllChildrenAllowedStatus([aclTree[index]]);

          if (isAllowedArray?.every((isAllowed: boolean) => isAllowed)) {
            return {
              ...treeDetail,
              isIndeterminateStatus: false,
              isAllowed: true,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          } else if (
            treeDetail?.childNodes?.some(
              (childNode: any) => childNode?.isAllowed === true
            )
          ) {
            return {
              ...treeDetail,
              isAllowed: true,
              isIndeterminateStatus: true,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          } else {
            return {
              ...treeDetail,
              isAllowed: false,
              isIndeterminateStatus: false,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          }
        } else {
        }
        return treeDetail;
      });
    },
    [setCheckBoxValueChildNode]
  );

  const setAclTreeRef = useRef(true);

  const onClickHandler = () => {
    setProjectACLTree(setACLTreeStructure(projectACLTree));
    setAclTreeRef.current = true;
  };
  useEffect(() => {
    if (setAclTreeRef.current) {
      setProjectACLTree(
        setCheckBoxValue(setCheckBoxValue(setCheckBoxValue(projectACLTree)))
      );
      setAclTreeRef.current = false;
    }
  }, [projectACLTree, setCheckBoxValue, setAclTreeRef]);

  let allowedResourceIds: string[] = [];

  const getAllowedResourceIds = (treeData: childNodesTypes[]) => {
    treeData?.map((treeData) => {
      if (treeData.isAllowed) {
        allowedResourceIds.push(treeData?.id);
      }
      if (treeData?.childNodes?.length > 0) {
        getAllowedResourceIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  getAllowedResourceIds(projectACLTree);

  const saveProjectRoleHandler = () => {
    if (!inProgress) {
      saveProjectRole({
        variables: {
          projectRole: {
            id: state?.id || undefined,
            name: state?.name,
            description: state?.description || "",
            isActive: state?.isActive,
            resourceIds: allowedResourceIds,
          },
        },
      })
        .then((response) => {
          setInProgress(false);
          navigate("/projects/settings/roles");
          toastNotify([
            {
              messageType: "success",
              message: "Your new entry has been successfully created.",
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div
      className={` ${
        state?.loading ? "flex justify-center items-center" : ""
      } min-h-[600px] overflow-x-auto py-[30px]`}
    >
      {state?.loading ? (
        <div className="loader-design" />
      ) : (
        <React.Fragment>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-16">
            <p className="text-ironside-gray flex justify-between h-min md:w-[136.52px] md:text-right">
              <span>Resources</span>
              <span className="block md:hidden">
                {expandAll ? (
                  <UnderReviewBtn
                    onClick={() => setExpandAll(false)}
                    buttonName={"Collapse All"}
                  />
                ) : (
                  <UnderReviewBtn
                    onClick={() => setExpandAll(true)}
                    buttonName={"Expand All"}
                  />
                )}
              </span>
            </p>
            <div className="320:w-[270px] md:min-w-[300px] lg:min-w-[415px]">
              <Tree
                aclTree={projectACLTree}
                level={0}
                setChangedACLTree={setChangedProjectACLTree}
                onClickHandler={onClickHandler}
                expandAll={expandAll}
              />
            </div>
            <div className="hidden md:block flex-col text-right right-0">
              {expandAll ? (
                <UnderReviewBtn
                  onClick={() => setExpandAll(false)}
                  buttonName={"Collapse All"}
                />
              ) : (
                <UnderReviewBtn
                  onClick={() => setExpandAll(true)}
                  buttonName={"Expand All"}
                />
              )}
            </div>
          </div>
          <div className="320:w-[250px] md:w-[400px] button-div md:ml-16">
            <Outlined
              onClick={() => {
                setInProgress(false);
                navigate("/projects/settings/roles");
              }}
              buttonName={"Cancel"}
            />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : state?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
              onClick={saveProjectRoleHandler}
            />
          </div>
          <HiArrowNarrowLeft
            className="back-arrow-icon mx-auto"
            onClick={() => {
              navigate("/projects/settings/roles/edit-role", {
                state: state,
              });
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default ProjectRoleResources;
