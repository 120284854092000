import {
  GET_MY_COMPENSATION_LEAVE_FRAGMENT,
  GET_TEAM_OR_ALL_COMPENSATION_FRAGMENT,
} from "modules/Leave/services/fragments";
import { gql } from "@apollo/client";

export const LEAVE_TYPES = gql`
  query leaveTypesDetails($id: Int, $name: String, $limit: Int, $page: Int) {
    leaveTypes(id: $id, name: $name, limit: $limit, page: $page) {
      dataCollection {
        id
        name
        isSupportingDocNeeded
        isSpecialLeave
        isRestrictedHolidayLeave
        noOfRestrictedHolidays
        availedNoOfRestrictedHolidays
        leaveSettlementType
        company {
          id
          name
        }
      }
      totalNumberOfItems
    }
  }
`;

export const LEAVE_DASHBOARD = gql`
  query leaveDashboard($userId: Int!) {
    leaveDashboard(userId: $userId) {
      id
      leaveType {
        name
        id
        isSupportingDocNeeded
        isSpecialLeave
        isRestrictedHolidayLeave
      }
      total
      availed
      remaining
      year
    }
  }
`;

export const FILTER_MY_LEAVE_REQUESTS = gql`
  query filterMyLeaveRequests(
    $id: Int
    $leaveType: Int
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    filterMyLeaveRequests(
      id: $id
      leaveType: $leaveType
      status: $status
      to: $to
      from: $from
      page: $page
      limit: $limit
    ) {
      dataCollection {
        id
        createdAt
        updatedAt
        reason
        declineMessage
        isConsentRequest
        requestedToUser {
          personalInformation {
            id
            name
          }
        }
        leaveRequestDates {
          id
          leaveDate
          session
          status
          statusChangedAt
          cancelledAt
          startTime
          declineMessage
          message
          endTime
          isLossOfPay
          leaveType {
            id
            name
            isSupportingDocNeeded
            isSpecialLeave
            isRestrictedHolidayLeave
          }
          leaveRequestDateConsents {
            status
            declinedReason
            message
            statusChangedAt
            consentedByUser {
              personalInformation {
                id
                name
              }
            }
          }
        }
        leaveRequestAttachments {
          id
          filePath
        }
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_TEAM_LEAVE_REQUESTS = gql`
  query filterTeamMemberLeaveRequests(
    $id: Int
    $userIds: [Int]
    $leaveType: Int
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
    $name: String
  ) {
    filterTeamMemberLeaveRequests(
      id: $id
      userIds: $userIds
      leaveType: $leaveType
      status: $status
      to: $to
      from: $from
      page: $page
      limit: $limit
      name: $name
    ) {
      dataCollection {
        id
        createdAt
        updatedAt
        reason
        declineMessage
        isConsentRequest
        requestedToUser {
          personalInformation {
            id
            name
          }
        }
        requestedByUser {
          personalInformation {
            id
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeId
            employeeDesignation
          }
        }
        leaveRequestDates {
          id
          leaveDate
          session
          status
          startTime
          endTime
          declineMessage
          message
          statusChangedAt
          leaveType {
            name
            id
            isSupportingDocNeeded
            isSpecialLeave
          }
          leaveRequestDateConsents {
            status
            declinedReason
            message
            statusChangedAt
            consentedByUser {
              personalInformation {
                id
                name
              }
            }
          }
        }
        leaveRequestAttachments {
          id
          filePath
        }
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_ALL_LEAVE_REQUESTS = gql`
  query filterAllLeaveRequests(
    $id: Int
    $userIds: [Int]
    $leaveType: Int
    $status: [String]
    $name: String
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    filterAllLeaveRequests(
      id: $id
      userIds: $userIds
      leaveType: $leaveType
      name: $name
      status: $status
      to: $to
      from: $from
      page: $page
      limit: $limit
    ) {
      dataCollection {
        id
        createdAt
        updatedAt
        reason
        declineMessage
        isConsentRequest
        requestedToUser {
          personalInformation {
            id
            name
          }
        }
        requestedByUser {
          id
          personalInformation {
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeId
            employeeDesignation
          }
        }
        leaveRequestDates {
          id
          leaveDate
          session
          status
          statusChangedAt
          declineMessage
          message
          startTime
          endTime
          leaveType {
            name
            id
            uuid
            isSupportingDocNeeded
          }
          leaveRequestDateConsents {
            status
            declinedReason
            message
            statusChangedAt
            consentedByUser {
              personalInformation {
                id
                name
              }
            }
          }
        }
        leaveRequestAttachments {
          id
          filePath
        }
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_ALL_LEAVE_TRANSACTION = gql`
  query filterAllLeaveTransaction(
    $userIds: [Int]
    $leaveType: Int
    $transactionType: String
    $from: String
    $to: String
    $page: Int
    $limit: Int
    $name: String
    $createdFrom: String
    $createdTo: String
    $isManual: Boolean
  ) {
    filterAllLeaveTransaction(
      userIds: $userIds
      leaveType: $leaveType
      transactionType: $transactionType
      to: $to
      from: $from
      page: $page
      limit: $limit
      name: $name
      createdFrom: $createdFrom
      createdTo: $createdTo
      isManual: $isManual
    ) {
      dataCollection {
        id
        leaveType {
          id
          name
        }
        leaveDate {
          leaveDate
          session
        }
        addedLeaveDate
        user {
          id
          personalInformation {
            id
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeDesignation
          }
        }
        isManual
        addedLeaveDate
        transactionType
        createdAt
        noOfDays
        previousCount
        currentCount
        notes
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_MY_LEAVE_TRANSACTION = gql`
  query filterMyLeaveTransaction(
    $id: Int
    $leaveType: Int
    $transactionType: String
    $from: String
    $to: String
    $page: Int
    $limit: Int
    $createdFrom: String
    $createdTo: String
    $isManual: Boolean
  ) {
    filterMyLeaveTransaction(
      id: $id
      leaveType: $leaveType
      transactionType: $transactionType
      to: $to
      from: $from
      page: $page
      limit: $limit
      createdFrom: $createdFrom
      createdTo: $createdTo
      isManual: $isManual
    ) {
      dataCollection {
        id
        uuid
        leaveType {
          id
          name
        }
        leaveDate {
          leaveDate
          session
        }
        addedLeaveDate
        isManual
        transactionType
        createdAt
        noOfDays
        previousCount
        currentCount
        notes
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_TEAM_LEAVE_TRANSACTION = gql`
  query filterTeamMemberLeaveTransaction(
    $userIds: [Int]
    $leaveType: Int
    $transactionType: String
    $from: String
    $to: String
    $page: Int
    $limit: Int
    $name: String
    $createdFrom: String
    $createdTo: String
    $isManual: Boolean
  ) {
    filterTeamMemberLeaveTransaction(
      userIds: $userIds
      leaveType: $leaveType
      transactionType: $transactionType
      to: $to
      from: $from
      page: $page
      limit: $limit
      name: $name
      createdFrom: $createdFrom
      createdTo: $createdTo
      isManual: $isManual
    ) {
      dataCollection {
        id
        leaveType {
          id
          name
        }
        leaveDate {
          leaveDate
          session
        }
        addedLeaveDate
        user {
          personalInformation {
            id
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeDesignation
          }
        }
        transactionType
        isManual
        addedLeaveDate
        createdAt
        noOfDays
        previousCount
        currentCount
        notes
      }
      totalNumberOfItems
    }
  }
`;

export const HOLIDAYS_COLLECTION = gql`
  query holidays(
    $id: Int
    $holidayMonth: Int
    $holidayYear: Int
    $reason: String
    $limit: Int
    $page: Int
    $type: String
  ) {
    holidays(
      id: $id
      holidayMonth: $holidayMonth
      holidayYear: $holidayYear
      reason: $reason
      limit: $limit
      page: $page
      type: $type
    ) {
      dataCollection {
        id
        holidayDate
        holidayMonth
        holidayYear
        reason
        weekday
        type
        company {
          id
          name
        }
      }
      totalNumberOfItems
    }
  }
`;

export const ABSENTEES_QUERY = gql`
  query absentees(
    $limit: Int
    $page: Int
    $from: String
    $to: String
    $name: String
    $leaveType: Int
  ) {
    absentees(
      limit: $limit
      page: $page
      from: $from
      to: $to
      name: $name
      leaveType: $leaveType
    ) {
      dataCollection {
        id
        leaveRequest {
          id
          requestedByUser {
            id
            personalInformation {
              id
              name
              personalEmail
            }
            employeeDetail {
              id
              employeeId
              officialEmail
              employeeDesignation
            }
          }
        }
        leaveDate
        session
        startTime
        endTime
      }
      totalNumberOfItems
    }
  }
`;

export const GET_NOTIFICATION_IDS = gql`
  query notificationsIDs($isRead: Boolean, $search: String) {
    notifications(isRead: $isRead, search: $search) {
      dataCollection {
        id
        link
        message
        isRead
      }
    }
  }
`;

export const FILTER_MY_COMPENSATION = gql`
  ${GET_MY_COMPENSATION_LEAVE_FRAGMENT}

  query filterMyCompensationLeave(
    $id: Int
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    filterMyCompensationLeave(
      id: $id
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      dataCollection {
        ...myCompensationFields
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_TEAM_COMPENSATION = gql`
  ${GET_TEAM_OR_ALL_COMPENSATION_FRAGMENT}

  query filterTeamCompensationLeaveRequest(
    $id: Int
    $status: [String]
    $from: String
    $to: String
    $name: String
    $limit: Int
    $page: Int
  ) {
    filterTeamCompensationLeaveRequest(
      id: $id
      status: $status
      from: $from
      to: $to
      name: $name
      limit: $limit
      page: $page
    ) {
      dataCollection {
        ...teamOrAllCompensationFields
      }
      totalNumberOfItems
    }
  }
`;

export const FILTER_ALL_COMPENSATION = gql`
  ${GET_TEAM_OR_ALL_COMPENSATION_FRAGMENT}

  query filterAllCompensationLeaveRequest(
    $id: Int
    $status: [String]
    $from: String
    $to: String
    $name: String
    $limit: Int
    $page: Int
  ) {
    filterAllCompensationLeaveRequest(
      id: $id
      status: $status
      from: $from
      to: $to
      name: $name
      limit: $limit
      page: $page
    ) {
      dataCollection {
        ...teamOrAllCompensationFields
      }
      totalNumberOfItems
    }
  }
`;

export const HR_ROLE_USER_FOR_DROP_DOWN = gql`
  query getHRRoleUser {
    getHRRoleUser {
      personalInformation {
        id
        name
      }
    }
  }
`;

export const GET_AVAILED_RESTRICTED_LEAVES = gql`
  query availedRestrictedLeaves($leaveType: Int, $status: [String]) {
    filterMyLeaveRequests(leaveType: $leaveType, status: $status) {
      dataCollection {
        reason
      }
      totalNumberOfItems
    }
  }
`;

export const GET_LEAVE_AUTO_APPROVE_TIMES_LIST = gql`
  query getLeaveAutoApprovalTime {
    leaveAutoApprovalTime {
      id
      type
      hours
    }
  }
`;

export const GET_LOSS_OF_PAY = gql`
  query getLossOfPay {
    getLossOfPay {
      id
      name
    }
  }
`;
