const Email: React.FC<{
  register: Function;
  name: string;
  errors: any;
  label: string;
  defaultEmail?: string;
  disable?: boolean;
  required?: boolean;
}> = ({ register, name, errors, label, defaultEmail, disable, required }) => {
  return (
    <div className="w-full">
      <div
        className={`relative flex items-center shadow justify-between w-full h-[52px] border ${
          errors[name] ? "border-[#FF647C]" : "border-[#E5E5E5]"
        }  rounded-[4px] ${
          errors[name]
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        } font-normal`}
      >
        <input
          id="email"
          type="text"
          placeholder="Email"
          defaultValue={defaultEmail}
          disabled={disable}
          autoComplete="off"
          maxLength={64}
          className="text-ironside-gray h-[50px] w-full bg-transparent placeholder-transparent focus:outline-none focus:border-cornflower-blue peer px-3"
          {...register(name, {
            required: required ? "This is required field." : false,
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/,
              message: "Please enter a valid email.",
            },
          })}
        />
        <label
          htmlFor="email"
          className={`absolute px-1 font-medium cursor-text bg-white ml-3 left-0 -top-2 ${
            errors[name]
              ? "text-[#FF647C] text-[11px]"
              : "text-[#8f8f92] text-[11px]"
          }   transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
            errors[name]
              ? "peer-placeholder-shown:text-[#FF647C]"
              : "peer-placeholder-shown:text-[#8f8f92]"
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            errors[name]
              ? "peer-focus:text-[#FF647C] "
              : "peer-focus:text-cornflower-blue "
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
      </div>
      <div className="w-full">
        {errors[name] ? (
          <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Email;
