import { FC, useState } from "react";
import { useMutation } from "@apollo/client";

import Modal from "components/forms/Modal/Modal";
import RoundedOutlined from "components/forms/Button/RoundedOutlined";
import RoundedFilled from "components/forms/Button/RoundedFilled";
import Loader from "components/Loader/Loader";
import { Filled, Outlined } from "components/forms";

import { MdOutlineClose } from "react-icons/md";
import fileDefault from "global/assets/images/file-blank-solid-240.png";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { Base64toObject } from "global/helpers/FileConverter";
import { toastNotify } from "global/helpers/Cache";
import useAllowedResources from "global/hooks/useAllowedResources";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { UPDATE_PROFILE_MODERATION_STATUS } from "modules/Employee/services/mutations";
import { IModerationObj } from "modules/Employee/types/moderation";
import DeclinePage from "modules/Settings/Pages/Moderation/DeclinePage";

interface IProps {
  loading: boolean;
  modalTitle: string;
  onXIcon?: () => void;
  onCancel?: () => void;
  moderationObj: IModerationObj;
  setShowPopUp: Function;
  refetchQuery?: Function;
}

const ModerationPopUp: FC<IProps> = ({
  loading,
  modalTitle,
  onXIcon,
  onCancel,
  moderationObj,
  setShowPopUp,
  refetchQuery,
}) => {
  const allowedResources = useAllowedResources();

  const [showDeclinePage, setShowDeclinePage] = useState<boolean>(false);

  const [updateProfileModeration] = useMutation(
    UPDATE_PROFILE_MODERATION_STATUS
  );

  const onWidhDrawHandler = (status: string) => {
    updateProfileModeration({
      variables: {
        ids: [moderationObj?.id],
        status: status || undefined,
      },
    })
      .then(() => {
        setShowPopUp(false);
        refetchQuery && refetchQuery();
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  return (
    <Modal>
      <div className="bg-white rounded-[12px] shadow-[0px_-3px_6px_#00000029] w-[320px] lg:w-[450px]">
        <div className="flex justify-between py-[20px] lg:py-[25px] px-[26px] border-b border-hit-gray">
          <div className="text-base text-ironside-gray">{modalTitle}</div>

          <div className="edit-modal-close-icon-div" onClick={onXIcon}>
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>

        {loading ? (
          <Loader className="min-h-[209.5px] lg:min-h-[215.5px] min-w-[308px] lg:min-w-[450px]" />
        ) : (
          <div className="text-[#212224] text-sm py-[25px] lg:py-[30px] mx-[6px] lg:px-[25px] flex justify-center lg:justify-start">
            <div className="space-y-3">
              <div className="text-[#a0522d]">{moderationObj?.label}</div>
              <p className="py-2 flex space-x-3">
                <span className="text-ironside-gray">Previous Value :</span>
                <span className="text-ironside-gray">
                  {moderationObj?.label === "File path" ||
                  moderationObj?.label === "Aadhaar file" ? (
                    moderationObj?.previousValue?.length <= 100 ? (
                      <img
                        src={fileDefault}
                        alt="No File"
                        className="w-[25px]"
                      />
                    ) : (
                      <a
                        href={Base64toObject(moderationObj?.previousValue)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={filePdf} alt="PDF" className="w-[25px]" />
                      </a>
                    )
                  ) : (
                    moderationObj?.previousValue
                  )}
                </span>
              </p>
              <p className="py-2 flex space-x-3">
                <span className="text-ironside-gray">Updated Value :</span>
                <span className="text-ironside-gray">
                  {moderationObj?.label === "File path" ||
                  moderationObj?.label === "Aadhaar file" ? (
                    moderationObj?.updatedValue?.length <= 100 ? (
                      moderationObj?.updatedValue
                    ) : (
                      <a
                        href={Base64toObject(moderationObj?.updatedValue)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={filePdf} alt="PDF" className="w-[25px]" />
                      </a>
                    )
                  ) : (
                    moderationObj?.updatedValue
                  )}
                </span>
              </p>
              <p className="text-ironside-gray/70">
                If you want to edit this field, please click the withdraw
                button.
              </p>
            </div>
          </div>
        )}

        {allowedResources?.includes("CanApproveUserProfileModeration") ? (
          <div className="flex justify-between px-[26px] pt-[25px] pb-[27px] space-x-[33px] border-t border-hit-gray">
            <Outlined
              buttonName="Decline"
              onClick={() => setShowDeclinePage(true)}
            />
            <Filled
              buttonType="button"
              buttonName={"Approve"}
              onClick={() => onWidhDrawHandler("approved")}
            />
          </div>
        ) : (
          <div className="flex justify-between px-[26px] pt-[25px] pb-[27px] space-x-[33px] border-t border-hit-gray">
            <RoundedOutlined
              buttonName="Cancel"
              onClick={onCancel}
              isConfirmModal={true}
            />
            <RoundedFilled
              buttonName={"Withdraw"}
              onClick={() => onWidhDrawHandler("withdraw")}
              isConfirmModal={true}
            />
          </div>
        )}

        {showDeclinePage && (
          <DeclinePage
            setShowDeclinePage={setShowDeclinePage}
            setShowUnderReview={setShowPopUp}
            employeeId={[+moderationObj?.id]}
            updateProfileModeration={updateProfileModeration}
            refetchData={refetchQuery}
          />
        )}
      </div>
    </Modal>
  );
};

export default ModerationPopUp;
