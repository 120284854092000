import { Fragment, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import Loading from "components/Loader/Loading";

import { userContextData } from "global/helpers/Cache";
import { ViewDateFormat, formatTime } from "global/helpers/DateFormatter";

import { FILTER_ALL_PROJECT_WORK_LOGS } from "modules/Timer/WorkLogs/services";
import { IProjectWorkLog } from "modules/Project/types/project";
import MyProjectWorkLogMain from "modules/Timer/WorkLogs/MyProjectWorkLogs/MyProjectWorkLogMain";
import MyProjectWorkLogHeader from "modules/Timer/WorkLogs/MyProjectWorkLogs/MyProjectWorkLogHeader";
import { PROJECTS_FOR_MY_LOGS } from "modules/Project/services/queries";

const MyProjectWorkLogs = () => {
  const loggedInUser = useReactiveVar(userContextData);

  function getCurrentWeekDates() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    // Calculate the difference between the current day and Monday
    const diff = currentDay - 1;
    startDate.setDate(currentDate.getDate() - diff);
    endDate.setDate(currentDate.getDate() + (5 - diff));

    // Format the dates to string (e.g., "YYYY-MM-DD")
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  const weekDates = getCurrentWeekDates();

  const {
    data: filterAllProjectWorkLogs,
    loading: allProjectWorkLogsLoading,
    refetch: refetchWorkLogs,
  } = useQuery(FILTER_ALL_PROJECT_WORK_LOGS, {
    variables: {
      isMyWorkLog: true,
      startTime: ViewDateFormat(weekDates?.startDate)
        ? `${ViewDateFormat(weekDates?.startDate)} 12:00 AM`
        : undefined,
      endTime: ViewDateFormat(weekDates?.endDate)
        ? `${ViewDateFormat(weekDates?.endDate)} 11:59 PM`
        : undefined,
    },
    fetchPolicy: "network-only",
  });

  const workLogs =
    filterAllProjectWorkLogs?.filterAllProjectWorkLogs?.dataCollection || [];

  const totalTimeInSeconds =
    workLogs?.reduce((acc, workLog) => acc + workLog?.durationInSeconds, 0) ||
    0;

  const groupedWorkLogs = workLogs?.reduce((acc, log) => {
    const date = log?.startTime.slice(0, log?.createdAt.indexOf(")") + 1);

    acc[date] = acc[date] || [];
    acc[date]?.push(log);

    return acc;
  }, {} as Record<string, IProjectWorkLog[]>);

  const {
    data: filterProjects,
    loading: projectsLoading,
    fetchMore: fetchMoreProjects,
  } = useQuery(PROJECTS_FOR_MY_LOGS, {
    skip: loggedInUser?.user?.id ? false : true,
    variables: {
      filters: {
        isMyProject: true,
      },
      tasksFilters2: loggedInUser?.user?.id
        ? {
            assigneeUserId: [+loggedInUser?.user?.id],
          }
        : undefined,
      taskPage: 1,
      taskLimit: 30,
    },
    fetchPolicy: "network-only",
  });

  const projects = filterProjects?.projects?.dataCollection || [];

  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((+currentDate - +startDate) / (24 * 60 * 60 * 1000));

  const weekNumber = Math.ceil(days / 7);

  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="min-h-[76vh]">
      <MyProjectWorkLogHeader
        projects={projects}
        loading={projectsLoading}
        refetchWorkLogs={refetchWorkLogs}
        fetchMoreProjects={fetchMoreProjects}
      />
      {allProjectWorkLogsLoading ? (
        <Loading className="min-h-[76vh]" />
      ) : workLogs?.length > 0 ? (
        <Fragment>
          <div className="pt-[25px] min-h-[555px] overflow-x-auto">
            <div className="grid grid-cols-1 gap-6 mb-10">
              <div className="flex justify-between text-[#333] text-sm font-bold">
                <span>Week {weekNumber || ""}</span>
                <span>{`This week : ${formatTime(totalTimeInSeconds)}`}</span>
              </div>
              {groupedWorkLogs &&
                Object?.entries(groupedWorkLogs)?.map(
                  ([date, dateWiseWorkLogs]: any, index: number) => {
                    return (
                      <MyProjectWorkLogMain
                        key={index}
                        date={date}
                        dateWiseWorkLogs={dateWiseWorkLogs}
                        projects={projects}
                        refetchWorkLogs={refetchWorkLogs}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        fetchMoreProjects={fetchMoreProjects}
                        projectsLoading={projectsLoading}
                      />
                    );
                  }
                )}
            </div>
          </div>
        </Fragment>
      ) : (
        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
          No work logs
        </p>
      )}
    </div>
  );
};

export default MyProjectWorkLogs;
