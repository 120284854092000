import { FC } from "react";
import { CalendarDate, getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid, useLocale } from "react-aria";
import { RangeCalendarState, CalendarState } from "react-stately";

import CalendarCell from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarCell";

interface IProps {
  state: RangeCalendarState | CalendarState;
  endDate?: CalendarDate;
  startDate?: CalendarDate;
}

const CalendarGrid: FC<IProps> = ({ state, endDate, startDate }) => {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      endDate,
      startDate,
    },
    state
  );

  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-gray-600 cursor-default">
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center text-xs p-1" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CalendarGrid;
