import { useReactiveVar } from "@apollo/client";
import { userContextData } from "global/helpers/Cache";

import TeamTransactions from "./TeamTransactions/TeamTransactions";
import TabLink from "components/App/TabLink/TabLink";
import { ITabLink } from "components/App/TabLink/types/tab-links";

import AllTransactions from "modules/Leave/Pages/Transactions/AllTransactions/AllTransactions";
import MyTransactions from "modules/Leave/Pages/Transactions/MyTransactions/MyTransactions";

const TransactionsRouteControl = () => {
  const teamLeadAccess: any = useReactiveVar(userContextData);

  const tabLinks: ITabLink[] = [
    {
      name: "My Transactions",
      link: "/leaves/transactions/my-transactions",
      allowedResourceIds: [],
      showTab: true,
    },
    {
      name: "Team Transactions",
      link: "/leaves/transactions/team-transactions",
      allowedResourceIds: [],
      showTab: teamLeadAccess?.user?.isTeamLead ? true : false,
    },
    {
      name: "All Transactions",
      link: "/leaves/transactions/all-transactions",
      allowedResourceIds: ["ReadAllLeaveTransactions"],
    },
  ];

  const routes = [
    {
      path: "my-transactions/*",
      element: <MyTransactions />,
    },
    {
      path: "team-transactions/*",
      element: <TeamTransactions />,
    },
    {
      path: "all-transactions/*",
      element: <AllTransactions />,
    },
  ];

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[800px] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default TransactionsRouteControl;
