import { z } from "zod";

export const isUrl = (data: string | undefined | null) => {
  if (data) {
    try {
      z.string().url().parse(data);
      return true;
    } catch {
      return false;
    }
  } else {
    return false;
  }
};

export const getFileExtensionWithUrl = (url: string): string | null => {
  const match = url.match(/\.([a-zA-Z0-9]+)(\?|$)/);
  return match ? match[1].toLowerCase() : null;
};

export const imageUrlToBase64AndFile = async (
  url: string,
  fileName?: string | null
): Promise<{
  base64String: string;
  file: File;
} | null> => {
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
    });

    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }

    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise<{
      base64String: string;
      file: File;
    }>((resolve, reject) => {
      reader.onloadend = () => {
        if (
          typeof reader.result === "string" &&
          reader?.result?.length > 100 &&
          blob
        ) {
          resolve({
            base64String: reader.result,
            file: new File(
              [blob],
              fileName ||
                `Image${new Date().toISOString()}.${
                  getFileExtensionWithUrl(url) || "jpeg"
                }`
            ),
          });
        } else {
          reject(new Error("Failed to convert image to base64"));
        }
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const imageURLAsBase64 = async (url: string | undefined | null) => {
  const blobToFile = (blob: Blob, fileName: string): File | null => {
    if (blob) {
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    }
    return null;
  };

  if (url && isUrl(url)) {
    const response = await fetch(url);
    const blob = await response?.blob();
    const file = blobToFile(blob, url);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => reader?.result;
    return {
      blob,
      url,
      file,
      base64String: reader?.result as string | null,
    };
  } else {
    return null;
  }
};

export const yesOrNoOption = ["Yes", "No"];

export const bugAndTaskPlatforms = ["App", "Web"];

export const bugAndTaskPriorities = ["Critical", "Low", "High", "Medium"];
