import { FC, Fragment } from "react";

import { ConsentorActions } from "modules/Leave/types/leave";
import { FaRegClock } from "react-icons/fa";
import Tooltip from "components/UI/ToolTip";

interface IProps {
  consents: ConsentorActions;
  declineMessage: string;
  teamLeadUserName: string;
  teamLeadLeaveActionStatus: string;
  hasConsents: number | any;
  message: string;
  isTeamLead?: boolean;
  statusChangedAt: boolean;
}

const LeaveInfo: FC<IProps> = ({
  consents,
  declineMessage,
  teamLeadUserName,
  teamLeadLeaveActionStatus,
  hasConsents,
  message,
  isTeamLead,
  statusChangedAt,
}) => {
  const { pending, agreed, disagreed } = consents;

  return (
    <div className="overflow-x-auto pl-2">
      {hasConsents > 0 && (
        <div className="flex px-5 py-3 gap-2">
          <p className="min-w-[170px] flex justify-between font-medium">
            Pending PL approvals <span>:</span>
          </p>
          <div className="whitespace-pre-wrap">
            {pending?.length > 0 ? (
              pending?.map((consentor: string, index: number) => (
                <span className="pl-1" key={index}>{`${
                  pending?.length - 1 === index
                    ? `${consentor}`
                    : `${consentor},`
                }`}</span>
              ))
            ) : (
              <span className="pl-1">None</span>
            )}
          </div>
        </div>
      )}

      {hasConsents > 0 && (
        <div className="flex px-5 py-3">
          <p className="min-w-[170px] font-medium flex justify-between">
            Agreed PLs<span>:</span>
          </p>
          <div className="flex flex-col pl-2">
            {agreed?.length > 0 ? (
              agreed?.map(
                (
                  consentor: {
                    name: string;
                    message: string;
                    statusChangedAt: string;
                  },
                  index: number
                ) => (
                  <div className="mb-2 last:mb-0">
                    <div className="flex gap-2 items-center" key={index}>
                      <span className="text-sm">{consentor?.name}</span>
                      {consentor?.statusChangedAt && (
                        <Tooltip
                          render={`On ${consentor?.statusChangedAt}`}
                          arrow
                        >
                          <FaRegClock className="w-4 h-4 text-brown-rust/70 mt-[2px]" />
                        </Tooltip>
                      )}
                    </div>
                    {consentor?.message && (
                      <span>{`(${
                        consentor?.message?.slice(-1) === "."
                          ? `${consentor?.message?.slice(0, -1)}`
                          : `${consentor?.message}`
                      })`}</span>
                    )}
                  </div>
                )
              )
            ) : (
              <span className="pl-1">-</span>
            )}
          </div>
        </div>
      )}

      {hasConsents > 0 && (
        <div className="flex px-5 py-3">
          <p className="min-w-[170px] font-medium flex justify-between">
            Disagreed PLs<span>:</span>
          </p>
          <div className="flex flex-col pl-2 gap-2">
            {disagreed?.length > 0 ? (
              disagreed?.map(
                (
                  consentor: {
                    name: string;
                    reason: string;
                    statusChangedAt: string;
                  },
                  index: number
                ) => (
                  <div className="mb-2 last:mb-0">
                    <div className="flex gap-2 items-center" key={index}>
                      <span className="text-sm">{consentor?.name}</span>
                      {consentor?.statusChangedAt && (
                        <Tooltip
                          render={`On ${consentor?.statusChangedAt}`}
                          arrow
                        >
                          <FaRegClock className="w-4 h-4 text-brown-rust/70 mt-[2px]" />
                        </Tooltip>
                      )}
                    </div>
                    <span>{`(${
                      consentor?.reason?.slice(-1) === "."
                        ? `${consentor?.reason?.slice(0, -1)}`
                        : `${consentor?.reason}`
                    })`}</span>
                  </div>
                )
              )
            ) : (
              <span className="pl-1">None</span>
            )}
          </div>
        </div>
      )}

      {teamLeadLeaveActionStatus === "Pending" && isTeamLead === false && (
        <div className="flex first-line:font-normal px-5 py-3">
          <p className="min-w-[170px] flex font-medium justify-between">
            Pending TL Approval<span>:</span>
          </p>
          <p className="px-3">{teamLeadUserName}</p>
        </div>
      )}

      {declineMessage && teamLeadLeaveActionStatus === "Declined" && (
        <div className="flex px-5 py-3">
          <p className="min-w-[170px] font-medium flex justify-between">
            Declined by<span>:</span>
          </p>
          <div className="flex flex-col pl-2">
            <Fragment>
              <div className="flex gap-2 items-center">
                <span className="text-sm">{teamLeadUserName}</span>
                {statusChangedAt && (
                  <Tooltip render={`On ${statusChangedAt}`} arrow>
                    <FaRegClock className="w-4 h-4 text-brown-rust/70 mt-[2px]" />
                  </Tooltip>
                )}
              </div>
              {declineMessage && (
                <span>{`(${
                  declineMessage?.slice(-1) === "."
                    ? `${declineMessage?.slice(0, -1)}`
                    : `${declineMessage}`
                })`}</span>
              )}
            </Fragment>
          </div>
        </div>
      )}

      {teamLeadLeaveActionStatus === "Approved" && (
        <div className="px-5 py-3">
          <div className="flex">
            <p className="min-w-[170px] flex font-medium justify-between">
              Approved by<span>:</span>
            </p>
            <p className="px-2 flex flex-col">
              <div className="flex gap-2">
                <span>{teamLeadUserName}</span>
                {statusChangedAt && (
                  <Tooltip render={`On ${statusChangedAt}`} arrow>
                    <FaRegClock className="w-4 h-4 text-brown-rust/70 mt-[2px]" />
                  </Tooltip>
                )}
              </div>
              {message && (
                <span>{`${
                  !message?.includes("to avail")
                    ? `(${
                        message?.slice(-1) === "."
                          ? `${message?.slice(0, -1)}`
                          : `${message}`
                      })`
                    : ""
                }`}</span>
              )}
            </p>
          </div>
          {message?.includes("to avail") && (
            <div className="mt-3 flex gap-1 items-center">
              <span className="pt-[5px]">*</span>
              <span>{`${
                message
                  ? `${
                      message?.slice(-1) === "."
                        ? `${message?.slice(0, -1)}`
                        : `${message}`
                    }`
                  : ""
              }`}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LeaveInfo;
