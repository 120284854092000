import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import ProjectBacklogs from "modules/Project/Pages/Backlogs/ProjectBacklogs";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import EditProjectBackLogRouteControl from "modules/Project/Pages/Backlogs/EditProjectBackLogRouteControl";

interface Props {
	projectGroup?: ProjectGroup | null;
	editProject: { id: number; name: string };
}

const ProjectBacklogsRouteControl: FC<Props> = ({
	projectGroup,
	editProject,
}) => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<ProjectBacklogs
						editProject={editProject}
						projectGroup={projectGroup}
					/>
				}
			/>
			<Route
				path=":backlogId/*"
				element={
					<EditProjectBackLogRouteControl
						editProject={editProject}
						projectGroup={projectGroup}
					/>
				}
			/>
		</Routes>
	);
};

export default ProjectBacklogsRouteControl;
