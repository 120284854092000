const Name: React.FC<{
  register: Function;
  name: string;
  errors: any;
  label: string;
  defaultName?: string;
  disable?: boolean;
  required?: boolean;
}> = ({ register, name, errors, label, defaultName, disable, required }) => {
  return (
    <div className="w-full">
      <div
        className={`relative flex items-center shadow justify-between w-full h-[52px] border ${
          errors[name] ? "border-[#FF647C]" : "border-[#E5E5E5]"
        }  rounded-[4px] ${
          errors[name]
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        } font-normal`}
      >
        <input
          id={name}
          type="text"
          placeholder="Name"
          defaultValue={defaultName}
          disabled={disable}
          autoComplete="off"
          maxLength={50}
          className="text-ironside-gray h-[50px] w-full bg-transparent  placeholder-transparent focus:outline-none focus:border-cornflower-blue peer px-3"
          {...register(name, {
            minLength: {
              value: 2,
              message: "Minimum Length should be 2.",
            },
            required: required ? "This is required field." : false,
            pattern: {
              value: /^[a-zA-Z]+$/ && /^[a-zA-Z ]{2,}$/,
              message: "Please use only Alphabets and White Spaces.",
            },
          })}
        />

        <label
          htmlFor={name}
          className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
            errors[name]
              ? "text-[#FF647C] text-[11px]"
              : "text-[#8f8f92] text-[11px]"
          }   transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
            errors[name]
              ? "peer-placeholder-shown:text-[#FF647C] "
              : "peer-placeholder-shown:text-[#8f8f92] "
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            errors[name]
              ? "peer-focus:text-[#FF647C] "
              : "peer-focus:text-cornflower-blue "
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
      </div>
      <div className="w-full">
        {errors[name] ? (
          <span className="peer-invalid:visible text-[#FF647C]  text-[12px] leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Name;
