interface ChildNodeType {
  id: string;
  title: string;
  isSuperAdmin: boolean;
  isAllowed: boolean;
  childNodes: ChildNodeType[] | [];
}

export const findAllowedACLIds = (aclTreeArray: ChildNodeType[]) => {
  const allowedIdsArray: string[] = [];

  const findAllowedIds = (aclTreeArray: any[]) => {
    if (aclTreeArray?.length === 0) return [];
    aclTreeArray?.map((treeData: ChildNodeType) => {
      if (treeData?.isAllowed) {
        allowedIdsArray.push(treeData?.id);
      }
      if (treeData?.childNodes?.length > 0) {
        findAllowedIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  findAllowedIds(aclTreeArray);

  return allowedIdsArray;
};

export const findAllowedACLIdsUpdated = (aclTreeArray: ChildNodeType[]) => {
  const allowedIds: { [key: string]: boolean } = {};

  const findAllowedIds = (aclTreeArray: any[]) => {
    if (aclTreeArray?.length === 0) return {};
    aclTreeArray?.map((treeData: ChildNodeType) => {
      allowedIds[treeData?.id] = treeData?.isAllowed;
      if (treeData?.childNodes?.length > 0) {
        findAllowedIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  findAllowedIds(aclTreeArray);

  return allowedIds;
};
