import { TypedDocumentNode, gql } from "@apollo/client";

import { FilterProjectMembersArgs, FilterProjectMembersReturnType } from "modules/Project/Pages/Members/types";

const FILTER_PROJECT_MEMBERS: TypedDocumentNode<FilterProjectMembersReturnType, FilterProjectMembersArgs> = gql `
query FilterProjectMembers($filters: ProjectMemberFilterInputs, $globalSearch: String, $pagination: Pagination, $orderBy: OrderByField) {
  filterProjectMembers(filters: $filters, globalSearch: $globalSearch, pagination: $pagination, orderBy: $orderBy) {
    edges {
      node {
        id
        user {
          personalInformation {
            id
            name
          }
        }
      }
    }
  }
}
`

export { FILTER_PROJECT_MEMBERS }