import { Fragment } from "react";
import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { AutoCompleteProps } from "components/forms/UpdatedFormAgry/AutoComplete/types";
import { OptionType } from "components/forms/UpdatedFormAgry/types";
import ComboBox from "components/forms/UpdatedFormAgry/AutoComplete/ComboBox";

const AutoComplete = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  label,
  control,
  disabled,
  multiple,
  className = "",
  shouldUnregister = false,
  options,
  onChange,
  required,
  loading,
  hideError,
  classNameForError = "",
  defaultValue,
  renderedOption,
  onInputChange,
  searchKeys,
  validate,
  value,
  observe,
  placeholder,
  helperText,
  notClearable,
  classNameForComboBox,
}: AutoCompleteProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends
    | OptionType
    | OptionType[]
    | null
    | undefined
    ? {
        helperType?: undefined;
      }
    : {
        invalidType: "Type must be string | number | ({ id: number| string, label: string} & {[key: string]: any }) | string[] | number[] | ({ id: number| string, label: string} & {[key: string]: any })[]";
      })) => {
  return (
    <div className={twMerge("w-full bg-inherit", className)}>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        rules={{
          required:
            typeof required === "boolean" && required === true
              ? "This is required field."
              : required,
          shouldUnregister,
          onChange: (e: any) => {
            if (onChange) {
              onChange(e?.target?.value);
            }
          },
          validate,
          value,
        }}
        defaultValue={
          defaultValue
            ? defaultValue
            : multiple
            ? ([] as PathValue<TFieldValues, Name>)
            : (null as PathValue<TFieldValues, Name>)
        }
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          const errorMessage = error?.message;

          return (
            <Fragment>
              <ComboBox
                disabled={disabled}
                value={value}
                onChange={onChange}
                name={name}
                options={options}
                label={label}
                defaultValue={defaultValue}
                errorMessage={errorMessage}
                loading={loading}
                multiple={multiple}
                renderedOption={renderedOption}
                onInputChange={onInputChange}
                searchKeys={searchKeys}
                observe={observe}
                placeholder={placeholder}
                notClearable={notClearable}
                classNameForComboBox={classNameForComboBox}
              />
              {hideError ? null : (
                <div
                  className={twMerge(
                    "w-[inherit] h-min px-4",
                    classNameForError
                  )}
                >
                  <span
                    className={twMerge(
                      "text-xs",
                      errorMessage
                        ? "visible text-error"
                        : helperText
                        ? "visible text-secondary"
                        : "invisible"
                    )}
                  >
                    {errorMessage ? errorMessage : helperText || "Helper Text"}
                  </span>
                </div>
              )}
            </Fragment>
          );
        }}
      />
    </div>
  );
};

export default AutoComplete;
