import {
  ChangeEvent,
  forwardRef,
  Fragment,
  PropsWithChildren,
  ReactNode,
} from "react";
import { HiPlusSm } from "react-icons/hi";

import Loading from "components/Loader/Loading";
import Pagination from "components/Pagination/Pagination";
import OopsNoData from "components/UI/OopsNoData";

interface IProps {
  children: ReactNode;
  selectAllCheckBox?: boolean;
  selectAllCheckBoxHandler?: Function;
  isCheckBoxNeed?: boolean;
  tableHeadings?: string[];
  tableHeadingsObj?: { name: string; center: boolean }[];
  onPlusIconClick?: () => void;
  createAccess?: boolean;
  className?: string;
  totalCount?: number;
  currentPage?: number;
  setPageSize?: Function;
  pageSize?: number;
  setCurrentPage?: Function;
  noOfItems: number;
  loading?: boolean;
  isCenterlastHeading?: boolean;
  onPageChange?: () => void;
}

const Table = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  (
    {
      children,
      selectAllCheckBox,
      selectAllCheckBoxHandler,
      isCheckBoxNeed,
      tableHeadings,
      onPlusIconClick,
      createAccess,
      className = "",

      totalCount,
      currentPage,
      setPageSize,
      pageSize,
      setCurrentPage,
      noOfItems,
      loading,
      isCenterlastHeading,
      onPageChange,
      tableHeadingsObj,
    },
    ref
  ) => {
    const isPaginationNeed =
      noOfItems !== 0 &&
      noOfItems !== undefined &&
      currentPage &&
      setPageSize &&
      pageSize &&
      setCurrentPage;

    const checkBoxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      selectAllCheckBoxHandler &&
        selectAllCheckBoxHandler(event?.target?.checked);
    };

    const pageChangeHandler = (page: number) => {
      setCurrentPage && setCurrentPage(page);
      onPageChange && onPageChange();
    };

    return (
      <div className="flex flex-col w-full justify-between">
        {loading ? (
          <Loading className="min-h-[70vh]" />
        ) : noOfItems === 0 ? (
          <OopsNoData className="min-h-[70vh]" />
        ) : (
          <Fragment>
            <div
              className={`w-full overflow-x-auto overflow-y-hidden ${
                className ? className : "min-h-[200px]"
              }`}
              ref={ref}
            >
              <table className="w-full">
                <thead className="bg-white-smoke">
                  <tr>
                    {isCheckBoxNeed && (
                      <th className="py-2 px-5 bg-blue-50">
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            className="h-4 w-4 text-indigo-600 border accent-bright-blue border-light-gray rounded-[2px] cursor-pointer"
                            onChange={checkBoxChangeHandler}
                            checked={selectAllCheckBox}
                          />
                        </div>
                      </th>
                    )}
                    {tableHeadings &&
                      noOfItems > 0 &&
                      tableHeadings?.map((tableHeading, index) => {
                        return (
                          <th
                            key={index}
                            className={`p-3 text-left text-sm bg-[#ECF1FE] text-ironside-gray font-normal whitespace-nowrap ${
                              isCenterlastHeading && "last:text-center"
                            }`}
                          >
                            {tableHeading}
                          </th>
                        );
                      })}
                    {tableHeadingsObj &&
                      noOfItems > 0 &&
                      tableHeadingsObj?.map((tableHeading, index) => (
                        <th
                          key={index}
                          className={`font-extrabold p-3 py-5 ${
                            tableHeading?.center ? "text-center" : "text-left"
                          } text-sm bg-[#ECF1FE] text-ironside-gray whitespace-nowrap ${
                            isCenterlastHeading && "last:text-center"
                          }`}
                        >
                          {tableHeading?.name}
                        </th>
                      ))}
                    {createAccess ? (
                      <th className="py-2 px-3 text-left text-base bg-[#ECF1FE] text-ironside-gray font-normal whitespace-nowrap">
                        <div className="w-full flex justify-center items-center">
                          <div className="w-full flex justify-center items-center">
                            <HiPlusSm
                              className="w-6 h-8 text-ironside-gray/70 font-bold cursor-pointer"
                              onClick={() => {
                                onPlusIconClick && onPlusIconClick();
                              }}
                            />
                          </div>
                        </div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>{children}</tbody>
              </table>
            </div>

            {isPaginationNeed && (
              <Pagination
                totalCount={totalCount ? totalCount : 0}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => pageChangeHandler(page)}
                noOfItem={noOfItems}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
);

export default Table;
