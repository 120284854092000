import { ChangeEvent, useEffect, useState } from "react";
import {
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

interface IProps<TForm extends FieldValues> {
  label: string;
  register: UseFormRegister<TForm>;
  name: FieldPath<TForm>;
  defaultValue?: PathValue<TForm, Path<TForm>>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  errors?: FieldErrorsImpl<TForm>;
  setValue: UseFormSetValue<TForm>;
}

const ToggleSwitch = <TForm extends Record<string, string | number | any>>({
  label,
  register,
  name,
  defaultValue,
  onChange,
  disabled,
  errors,
  setValue,
}: IProps<TForm>) => {
  const errorMessage = errors ? errors[name]?.message?.toString() : undefined;
  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  return (
    <div className="relative inline-block min-w-[67px] h-[30px] align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        id={label && name}
        className={`toggle-checkbox peer absolute block w-[30px] h-[30px] rounded-full bg-white border-4 appearance-none cursor-pointer ${
          checkBox ? "outline-ironside-gray" : "outline-cornflower-blue"
        } ${errorMessage ? "outline-valentine-red" : ""} `}
        {...register(name, {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setCheckBox(e?.target?.checked);
            if (onChange) {
              onChange(e);
            }
          },
        })}
        disabled={disabled}
        defaultChecked={defaultValue ? defaultValue : false}
      />
      <label
        htmlFor={name}
        className="toggle-label block overflow-hidden h-[30px] rounded-full bg-[#E1E1E1] cursor-pointer "
      />
    </div>
  );
};
export default ToggleSwitch;
