import { ChangeEvent, FC, useEffect, useState } from "react";
import { HiClock, HiTrash } from "react-icons/hi";
import { useLazyQuery } from "@apollo/client";

import { Select } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import Timer from "components/forms/Time/Timer";

import { DateFormat, ViewDateFormat } from "global/helpers/DateFormatter";
import { ISetStateType } from "global/types/type";
import { GET_CURRENT_TIME } from "global/services";

import { leaveRequestsDatesType } from "modules/Leave/types/leave";
import { GET_LOSS_OF_PAY } from "modules/Leave/services/queries";

interface IProps {
  index: number;
  register: Function;
  errors: any;
  setValue: Function;
  leaveDashboard?: any;
  date: string;
  dupWorkingDates: leaveRequestsDatesType[];
  setDupWorkingDates: Function;
  editWorkingDates: any;
  dateRange: any;
  session: string;
  isLeaveTypeRequired?: boolean;
  watchEmergencyLeave?: boolean;
  setPermissionTime?: ISetStateType<string | null>;
  isDefaultSessionRequired?: boolean;
  watchLossOfPay?: boolean;
  lopSession?: string;
  editLOP?: any;
}

const WorkingDates: FC<IProps> = ({
  index,
  register,
  errors,
  setValue,
  leaveDashboard,
  date,
  dupWorkingDates,
  setDupWorkingDates,
  editWorkingDates,
  dateRange,
  session,
  isLeaveTypeRequired,
  watchEmergencyLeave,
  setPermissionTime,
  isDefaultSessionRequired,
  watchLossOfPay,
  lopSession,
  editLOP,
}) => {
  const [fetchCurrentTime, { data: time }] = useLazyQuery(GET_CURRENT_TIME, {
    fetchPolicy: "network-only",
  });

  const [fetchLossOfPay, { data: lossOfPay }] = useLazyQuery(GET_LOSS_OF_PAY);

  useEffect(() => {
    if (isLeaveTypeRequired && watchLossOfPay) {
      fetchLossOfPay();
    }
  }, [fetchLossOfPay, isLeaveTypeRequired, watchLossOfPay]);

  const editDetails =
    editWorkingDates &&
    editWorkingDates?.dates?.filter((filterData: any) =>
      new Date(filterData?.leaveDate?.replaceAll("-", " "))
        ?.toString()
        ?.substring(0, 15) === date
        ? filterData
        : null
    )[0];

  const [selectedLeaveType, setSelectedLeaveType] = useState<number>(
    editWorkingDates?.leaveTypeId
      ? editWorkingDates?.leaveTypeId
      : +editDetails?.leaveType?.id
      ? +editDetails?.leaveType?.id
      : ""
  );

  const [selectedDuration, setSelectedDuration] = useState<string>(
    editDetails?.session ? editDetails?.session : lopSession ? lopSession : ""
  );

  const workHour = 9;
  const workMinutes = 29;
  const secondHalfStartHour = 14;
  const secondHalfStartMinutes = 14;
  const currentHour = new Date(time?.getCurrentTime).getHours();
  const currentMinutes = new Date(time?.getCurrentTime).getMinutes();

  const [selectedTime, setSelectedTime] = useState<string>(
    editWorkingDates?.id && selectedDuration === "2 Hours"
      ? editDetails?.startTime
      : editLOP && lopSession === "2 Hours"
      ? editLOP?.startTime
      : null
  );

  useEffect(() => {
    if (lopSession) {
      setValue(`leaveRequests.${index}.session`, lopSession);
    } else if (isDefaultSessionRequired) {
      setValue(`leaveRequests.${index}.session`, "Full Day");
    }
  }, [isDefaultSessionRequired, setValue, index, lopSession]);

  useEffect(() => {
    if (
      dupWorkingDates?.length === 1 &&
      session === "2 Hours" &&
      setPermissionTime
    ) {
      setPermissionTime(selectedTime);
    }
  }, [selectedTime, dupWorkingDates, session, setPermissionTime]);

  let leaveSessions;

  if (new Date(DateFormat(date)) > new Date()) {
    leaveSessions = ["2 Hours", "First Half", "Second Half", "Full Day"];
  } else if (
    date === new Date().toString().slice(0, 15) &&
    (+secondHalfStartHour < currentHour ||
      (currentHour === secondHalfStartHour &&
        secondHalfStartMinutes < currentMinutes)) &&
    isLeaveTypeRequired &&
    !watchEmergencyLeave &&
    !watchLossOfPay
  ) {
    leaveSessions = ["2 Hours"];
  } else if (
    date === new Date().toString().slice(0, 15) &&
    (+workHour < currentHour ||
      (currentHour === +workHour && workMinutes < currentMinutes)) &&
    isLeaveTypeRequired &&
    !watchEmergencyLeave &&
    !watchLossOfPay
  ) {
    leaveSessions = ["2 Hours", "Second Half"];
  } else {
    leaveSessions = ["2 Hours", "First Half", "Second Half", "Full Day"];
  }

  useEffect(() => {
    if (selectedDuration === "2 Hours") {
      setValue(`leaveRequests.${index}.startTime`, selectedTime);
    } else {
      setValue(
        `leaveRequests.${index}.startTime`,
        editWorkingDates?.id ? null : undefined
      );
    }
  }, [
    editWorkingDates?.id,
    index,
    selectedDuration,
    selectedTime,
    setValue,
    lopSession,
  ]);

  let leaveOptions;

  if (isLeaveTypeRequired) {
    leaveOptions = leaveDashboard
      ?.map(
        (leaves: {
          leaveType: any;
          id: number;
          name: string;
          year: number;
        }) => {
          if (
            !leaves?.leaveType?.isSpecialLeave &&
            !leaves?.leaveType?.isRestrictedHolidayLeave &&
            leaves?.year === new Date()?.getFullYear()
          ) {
            return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
          } else {
            return null;
          }
        }
      )
      ?.filter((leaveType: { id: number; name: string }) => leaveType);
  }

  leaveOptions = watchEmergencyLeave
    ? leaveOptions?.filter(
        (leave: { name: string }) => leave?.name === "Emergency Leave"
      )
    : watchLossOfPay
    ? [{ id: lossOfPay?.getLossOfPay?.id, name: lossOfPay?.getLossOfPay?.name }]
    : leaveOptions?.filter(
        (leave: { name: string }) => leave?.name !== "Emergency Leave"
      );

  useEffect(() => {
    if (isLeaveTypeRequired) {
      setValue(
        `leaveRequests.${index}.leaveType`,
        selectedLeaveType ? +selectedLeaveType : ""
      );
    }
  }, [index, selectedLeaveType, setValue, isLeaveTypeRequired]);

  if (isLeaveTypeRequired) {
    setValue(
      `leaveRequests.${index}.leaveType`,
      selectedLeaveType ? +selectedLeaveType : ""
    );
  }

  const isEditing = editWorkingDates ? true : false;

  /***  Setting leave type when creating leave  start ***/

  useEffect(() => {
    if (isLeaveTypeRequired && !isEditing) {
      const casualLeave = leaveDashboard?.find(
        (leave: { leaveType: { name: string } }) =>
          leave?.leaveType?.name === "Casual Leave"
      );
      const emergencyLeave = leaveDashboard?.find(
        (leave: { leaveType: { name: string } }) =>
          leave?.leaveType?.name === "Emergency Leave"
      );
      const lossOfPay = leaveDashboard?.find(
        (leave: { leaveType: { name: string } }) =>
          leave?.leaveType?.name === "Loss Of Pay"
      );

      if (!watchEmergencyLeave && !watchLossOfPay) {
        setSelectedLeaveType(casualLeave?.leaveType?.id || 0);
      } else if (watchEmergencyLeave) {
        setSelectedLeaveType(emergencyLeave?.leaveType?.id || 0);
      } else if (watchLossOfPay) {
        setSelectedLeaveType(lossOfPay?.leaveType?.id || 0);
      }
    }
  }, [
    dateRange,
    isEditing,
    isLeaveTypeRequired,
    leaveDashboard,
    watchEmergencyLeave,
    watchLossOfPay,
  ]);

  /***  Setting leave type when creating leave  end ***/

  useEffect(() => {
    if (date && editDetails?.id) {
      setValue(`leaveRequests.${index}.id`, +editDetails?.id);
    } else {
      setValue(`leaveRequests.${index}.id`, undefined);
    }
  }, [date, editDetails?.id, index, setValue]);

  if (date && editDetails?.id) {
    setValue(`leaveRequests.${index}.id`, +editDetails?.id);
  }

  useEffect(() => {
    if (isLeaveTypeRequired && time) {
      setValue(`leaveRequests.${index}.session`, session);
    }
  }, [dupWorkingDates, index, session, setValue, isLeaveTypeRequired, time]);

  let endHour = selectedTime && selectedTime?.slice(0, 2);

  let endMinute = selectedTime && selectedTime?.slice(3, 5);

  let endMeridian = selectedTime && selectedTime?.slice(6, 8);

  if (selectedTime) {
    if (+selectedTime?.slice(0, 2) > 9) {
      endHour =
        +selectedTime?.slice(0, 2) + 2 === 13
          ? "01"
          : +selectedTime?.slice(0, 2) + 2 === 14
          ? "02"
          : (+selectedTime?.slice(0, 2) + 2)?.toString();
      endMeridian =
        +selectedTime?.slice(0, 2) < 12
          ? selectedTime?.slice(6, 8) === "PM"
            ? "AM"
            : "PM"
          : selectedTime?.slice(6, 8);
    } else {
      endHour =
        +selectedTime?.slice(0, 2) + 2 < 10
          ? "0" + (+selectedTime?.slice(0, 2) + 2)
          : (+selectedTime?.slice(0, 2) + 2)?.toString();
      endMeridian = selectedTime?.slice(6, 8);
    }
  }

  useEffect(() => {
    if (selectedDuration === "2 Hours" || lopSession === "2 Hours") {
      setValue(
        `leaveRequests.${index}.endTime`,
        `${endHour}:${endMinute} ${endMeridian}`
      );
    } else {
      setValue(
        `leaveRequests.${index}.endTime`,
        editWorkingDates?.id ? null : undefined
      );
    }
  }, [
    selectedTime,
    endHour,
    endMinute,
    endMeridian,
    selectedDuration,
    setValue,
    index,
    editWorkingDates?.id,
    lopSession,
  ]);

  const updateLeaveTypesHandler = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const leaveDates = [...dupWorkingDates];
    leaveDates[index]["leaveType"] = +event?.target?.value;
    setDupWorkingDates(leaveDates);
  };

  const sessionHandler = (
    event: ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const leaveDates = [...dupWorkingDates];
    leaveDates[index]["session"] = event?.target?.value;
    setDupWorkingDates(leaveDates);
  };

  useEffect(() => {
    if (isLeaveTypeRequired) {
      fetchCurrentTime();
    }
  }, [fetchCurrentTime, isLeaveTypeRequired, selectedTime]);

  return (
    <div className="w-full mt-1">
      <input
        key={`dates${index}`}
        type="text"
        hidden
        value={ViewDateFormat(date)}
        {...register(`leaveRequests.${index}.leaveDate`)}
        className="focus:outline-none focus:border-none w-[110px]"
      />
      {date && editDetails?.id ? (
        <input
          key={`id${index}`}
          type="number"
          value={editDetails?.id ? +editDetails?.id : ""}
          hidden
          {...register(`leaveRequests.${index}.id`)}
          className="focus:outline-none focus:border-none w-[110px]"
        />
      ) : null}

      <div
        className={`grid grid-cols-1 gap-4 ${
          isLeaveTypeRequired
            ? "sm:grid-cols-[150px_400px]"
            : "sm:grid-cols-[150px_350px]"
        } `}
      >
        <div className="flex flex-row sm:flex-col justify-between items-center sm:items-start">
          <div className="rounded flex items-center justify-center sm:py-0 sm:pt-4 border py-1 px-2 sm:px-0 sm:border-none sm:block">
            <span
              className={
                "whitespace-nowrap min-w-[87px] text-center text-sm sm:text-left"
              }
            >
              {ViewDateFormat(date)}
            </span>
          </div>
          <div className="flex items-center gap-2 sm:hidden">
            <HiTrash
              className="w-[20px] h-[25px] text-red-400 cursor-pointer"
              onClick={() =>
                setDupWorkingDates(
                  dupWorkingDates?.filter(
                    (data: leaveRequestsDatesType) => data?.date !== date
                  )
                )
              }
            />
          </div>
        </div>
        <div className="relative">
          <div
            className={`grid grid-cols-1 ${
              isLeaveTypeRequired ? "sm:grid-cols-2" : "sm:grid-cols-1"
            }  gap-3`}
          >
            {isLeaveTypeRequired && (
              <SelectWithId
                errors={
                  errors?.leaveRequests?.[index]?.leaveType?.message
                    ? errors?.leaveRequests?.[index]?.leaveType?.message
                    : undefined
                }
                label="Leave Type *"
                name={`leaveRequests.${index}.leaveType`}
                defaultSelectValue={selectedLeaveType ? +selectedLeaveType : ""}
                options={leaveOptions}
                register={register}
                onChange={(event: any) => {
                  setSelectedLeaveType(+event.target.value);
                  updateLeaveTypesHandler(event, index);
                }}
                required={true}
                isFieldArray={true}
                enableDefaultSelectOption
              />
            )}
            <Select
              register={register}
              errors={
                errors?.leaveRequests?.[index]?.session?.message
                  ? errors?.leaveRequests?.[index]?.session?.message
                  : undefined
              }
              label="Session *"
              options={leaveSessions}
              defaultSelectValue={selectedDuration ? selectedDuration : ""}
              name={`leaveRequests.${index}.session`}
              onChange={(event: any) => {
                setSelectedDuration(event.target.value);
                sessionHandler(event, index);
              }}
              required={true}
              isFieldArray={true}
            />
          </div>
          {session === "2 Hours" ? (
            <div className="mb-7 flex justify-between lg:absolute top-0 -right-72 gap-4">
              <Timer
                register={register}
                name={`leaveRequests.${index}.startTime`}
                setValue={setValue}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
              />
              {session === "2 Hours" ? (
                <input
                  key={`times${index}`}
                  type="text"
                  value={`${endHour}:${endMinute} ${endMeridian}`}
                  hidden
                  {...register(`leaveRequests.${index}.endTime`)}
                  className="focus:outline-none focus:border-none w-[110px]"
                />
              ) : null}

              <div className="flex cursor-not-allowed justify-center items-center border border-[#E5E5E5] bg-gray-200 rounded-[6px] w-32 pr-1 h-[52px] relative">
                <div className="w-24 h-12 flex justify-center items-center peer text-gray-500">
                  <div>
                    <HiClock className="w-5 h-5 text-cornflower-blue" />
                  </div>
                  <div className="ml-1 flex">
                    {endHour}
                    <span className="ml-1"> :</span>
                    <span className="ml-1 text-gray-500 ">{endMinute}</span>
                    <span className="ml-1">:</span>
                    <span className="ml-1 text-gray-500">{endMeridian}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {editWorkingDates?.id || lopSession ? null : (
            <div
              className={`hidden sm:block absolute top-3 -right-8 ${
                session === "2 Hours" ? "lg:-right-[320px]" : ""
              } `}
            >
              <HiTrash
                className="w-[20px] h-[25px] text-gray-300  hover:text-red-400 cursor-pointer"
                onClick={() =>
                  setDupWorkingDates(
                    dupWorkingDates?.filter(
                      (data: leaveRequestsDatesType) => data?.date !== date
                    )
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkingDates;
