import { FC, Fragment } from "react";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { useDateFormatter } from "react-aria";
import { BiCheck } from "react-icons/bi";
import { CalendarState, RangeCalendarState } from "react-stately";
import { debounce } from "lodash";

interface Props {
  state: CalendarState | RangeCalendarState;
}
const MonthDropdown: FC<Props> = ({ state }) => {
  const months: string[] = [];
  const formatter = useDateFormatter({
    month: "long",
    timeZone: state.timeZone,
  });

  const numMonths = state.focusedDate.calendar.getMonthsInYear(
    state.focusedDate
  );

  const startDate = state?.minValue;
  const endDate = state?.maxValue;

  if (startDate && endDate && startDate?.year === endDate?.year) {
    if (startDate?.month === endDate?.month) {
      const date = state?.focusedDate.set({ month: startDate?.month });
      months.push(formatter.format(date.toDate(state.timeZone)));
    } else {
      for (let i = startDate?.month; i <= endDate?.month; i++) {
        const date = state?.focusedDate.set({ month: i });
        months.push(formatter.format(date.toDate(state.timeZone)));
      }
    }
  } else {
    for (let i = 1; i <= numMonths; i++) {
      const date = state?.focusedDate.set({ month: i });
      months.push(formatter.format(date.toDate(state.timeZone)));
    }
  }

  const { x, y, strategy, refs, middlewareData } =
    useFloating<HTMLButtonElement>({
      middleware: [
        offset(10),
        flip({
          fallbackPlacements: ["bottom", "top"],
        }),
        hide({
          strategy: "referenceHidden",
        }),
        shift({
          padding: 20,
        }),
        size({
          apply: debounce(({ availableHeight, elements }) => {
            Object.assign(elements?.floating?.style, {
              width: "max-content",
              "max-width": "100vw",
              maxHeight: `${availableHeight}px`,
            });
          }, 1),
        }),
      ],
      placement: "bottom",
      whileElementsMounted: autoUpdate,
    });

  return (
    <Listbox
      value={formatter.format(state.focusedDate.toDate(state.timeZone))}
      onChange={(month) => {
        const monthIndex =
          months.findIndex((monthFilter) => monthFilter === month) + 1;
        const date = state?.focusedDate?.set({ month: monthIndex });
        state.setFocusedDate(date);
      }}
      disabled={state?.isDisabled}
    >
      {({ open, value }) => {
        return (
          <Fragment>
            <Listbox.Button
              ref={refs?.setReference}
              className={`bg-background appearance-none cursor-pointer text-xs  p-1 rounded-md ${
                open
                  ? "outline-none ring-2 ring-primary"
                  : "focus:outline-none focus:ring-2 focus:ring-primary"
              }`}
            >
              {value}
            </Listbox.Button>
            <FloatingPortal>
              <Transition
                show={open}
                ref={refs?.setFloating}
                style={{
                  position: strategy,
                  zIndex: 66,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                as={"div"}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={`${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                } shadow border rounded focus:outline-none min-w-[200px] bg-background`}
              >
                <Listbox.Options
                  className={`relative max-h-60 py-2 pl-2 overflow-auto bg-inherit text-base shadow-lg focus:outline-none rounded`}
                >
                  {months?.map((option, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative text-sm select-none p-2 flex justify-between rounded cursor-pointer ${
                            active
                              ? "bg-primary text-white"
                              : "text-ironside-gray"
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => {
                          return (
                            <Fragment>
                              <span
                                className={`block truncate text-sm ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {option}
                              </span>
                              {selected && (
                                <BiCheck
                                  className={`h-5 w-5 min-w-[20px] ${
                                    active ? "text-white" : "text-primary"
                                  }`}
                                />
                              )}
                            </Fragment>
                          );
                        }}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </FloatingPortal>
          </Fragment>
        );
      }}
    </Listbox>
  );
};
export default MonthDropdown;
