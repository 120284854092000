import { ComponentPropsWithRef, FC } from "react";
import { twMerge } from "tailwind-merge";

interface Props extends ComponentPropsWithRef<"div"> {
  smallLoading?: boolean;
  color?: "red" | "green" | "white" | "tertiary";
  classForLoading?: string;
  hideLoading?: boolean;
}

const Loading: FC<Props> = ({
  className,
  smallLoading = false,
  color = "green",
  classForLoading,
  hideLoading,
  ...props
}) => {
  return (
    <div
      className={twMerge("flex justify-center items-center", className)}
      {...props}
    >
      {hideLoading ? null : (
        <span
          className={twMerge(
            "border-4 rounded-full animate-spin mx-auto",
            `${smallLoading ? "w-5 h-5" : "w-10 h-10"}`,
            `${
              color === "red"
                ? "border-error"
                : color === "green"
                ? "border-cornflower-blue"
                : color === "tertiary"
                ? "border-tertiary"
                : "border-white"
            }`,
            "border-t-transparent",
            classForLoading
          )}
        />
      )}
    </div>
  );
};

export default Loading;
