import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { Transition } from "@headlessui/react";
import { Editor } from "@tiptap/react";
import React, { FC, useState } from "react";
import { TbTextColor } from "react-icons/tb";
import ColorSelectBox from "./ColorSelectBox";

interface IProps {
  editor: Editor | null;
}

const ColorSelection: FC<IProps> = ({ editor }) => {
  const colors: { name: string; color: string }[] = [
    { name: "Dark gray", color: "#172B4D" },
    { name: "Dark blue", color: "#0747A6" },
    { name: "Dark teal", color: "#008DA6" },
    { name: "Dark green", color: "#006644" },
    { name: "Orange", color: "#FF991F" },
    { name: "Dark red", color: "#BF2600" },
    { name: "Dark purple", color: "#403294" },
    { name: "Light gray", color: "#97A0AF" },
    { name: "Blue", color: "#4C9AFF" },
    { name: "Teal", color: "#00B8D9" },
    { name: "Green", color: "#36B37E" },
    { name: "Yellow", color: "#FFC400" },
    { name: "Red", color: "#FF5630" },
    { name: "Purple", color: "#6554C0" },
    { name: "White", color: "#FFFFFF" },
    { name: "Light blue", color: "#B3D4FF" },
    { name: "Light teal", color: "#B3F5FF" },
    { name: "Light green", color: "#ABF5D1" },
    { name: "Light yellow", color: "#FFF0B3" },
    { name: "Light red", color: "#FFBDAD" },
    { name: "Light purple", color: "#EAE6FF" },
  ];

  const [showColorSelect, setShowColorSelect] = useState(false);
  const { x, y, strategy, refs, middlewareData } = useFloating({
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ["bottom-start", "top-start"],
      }),
      hide({
        strategy: "referenceHidden",
      }),
      shift({
        padding: 20,
      }),
    ],
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const colorModalHandler = () => {
    setShowColorSelect((prevState) => !prevState);
  };

  return (
    <div>
      <span ref={refs.setReference}>
        <TbTextColor
          className={`w-6 h-6 hover:bg-white-smoke cursor-pointer`}
          onClick={colorModalHandler}
        />
      </span>
      <FloatingPortal>
        <Transition
          show={showColorSelect}
          ref={refs.setFloating}
          as={"div"}
          appear
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          className={`${
            middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
          } rounded-md bg-white p-2 grid grid-cols-4 gap-2 border min-w-[250px] max-h-[420px]`}
        >
          {colors?.map((color, index) => (
            <ColorSelectBox
              editor={editor}
              key={color?.name}
              color={color?.color}
              colorModalHandler={colorModalHandler}
              colorName={color?.name}
            />
          ))}
        </Transition>
      </FloatingPortal>
    </div>
  );
};

export default ColorSelection;
