import { ComponentPropsWithRef, FC, ReactNode, useRef } from "react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

type Props = {
  classForParent?: string;
  onClose: ((value: boolean) => void) | undefined;
  children: ReactNode;
} & MotionProps &
  Omit<ComponentPropsWithRef<"div">, "onClick" | "children">;

const Modal: FC<Props> = ({
  children,
  className = "",
  classForParent = "",
  onClose,
  exit,
  initial,
  animate,
  ...rest
}) => {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  return (
    <AnimatePresence>
      <Dialog
        open={true}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
        as="div"
        {...rest}
        className={twMerge(
          "fixed inset-0 flex justify-center items-center z-[55] h-full w-full bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40",
          onClose ? "cursor-pointer" : "",
          className
        )}
        onClick={(e) => {
          e.stopPropagation();
          onClose && onClose(false);
        }}
        initialFocus={initialFocusRef}
      >
        <motion.div
          initial={initial ? initial : { opacity: 0 }}
          animate={
            animate
              ? animate
              : {
                  opacity: 1,
                  transition: {
                    type: "spring",
                  },
                }
          }
          exit={
            exit
              ? exit
              : {
                  opacity: 0,
                  transition: {
                    delay: 0.6,
                  },
                }
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={classForParent ? classForParent : "cursor-default z-[60]"}
        >
          <input type="hidden" name="" ref={initialFocusRef} />
          {children}
        </motion.div>
      </Dialog>
    </AnimatePresence>
  );
};

export default Modal;
