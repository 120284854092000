import { format, parse } from "date-fns";

type TimeFormat =
  | "hh:mm:ss a"
  | "hh:m:s a"
  | "hh:mm a"
  | "HH:mm:ss"
  | "H:m:s"
  | "HH:mm"
  | "h:mm:ss a"
  | "h:mm:s a"
  | "h:m:ss a"
  | "h:mm a"
  | "h:m a"
  | "h:m:s a";

const supportedFormats = [
  "yyyy-MM-dd hh:mm:ss a",
  "yyyy-MM-dd hh:mm a",
  "yyyy-MM-dd hh a",
  "yyyy-MM-dd a",
  "yyyy-MM a",
  "yyyy a",
  "yyyy-MM-dd HH:mm:ss",
  "yyyy-MM-dd HH:mm",
  "yyyy-MM-dd HH",
  "yyyy-MM-dd",
  "yyyy-MM",
  "yyyy",
  "H:mm:ss",
  "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  "hh:mm a",
  "hh:mm:ss a",
  "hh:m:s a",
  "hh:mm a",
  "HH:mm:ss",
  "H:m:s",
  "HH:mm",
  "h:mm:ss a",
  "h:mm:s a",
  "h:m:ss a",
  "h:mm a",
  "h:m a",
  "h:m:s a",
];

const desiredOutputFormats = [
  "MMM dd yyyy 'at' hh:mm:ss a",
  "MMM dd yyyy 'at' hh:mm a",
  "MMM dd yyyy 'at' hh a",
  "MMM dd yyyy 'at' a",
  "MMM yyyy 'at' a",
  "yyyy 'at' a",
  "MMM dd yyyy 'at' HH:mm:ss",
  "MMM dd yyyy 'at' HH:mm",
  "MMM dd yyyy 'at' HH",
  "MMM dd, yyyy",
  "MMM yyyy",
  "yyyy",
  "H:mm:ss",
  "MMM dd yyyy 'at' HH:mm:ss",
  "hh:mm a",
  "hh:mm:ss a",
  "hh:m:s a",
  "hh:mm a",
  "HH:mm:ss",
  "H:m:s",
  "HH:mm",
  "h:mm:ss a",
  "h:mm:s a",
  "h:m:ss a",
  "h:mm a",
  "h:m a",
  "h:m:s a",
];

export const formatTimeWithAMAndPM = (
  inputTime: string | null | undefined,
  outputFormat: TimeFormat
) => {
  if (inputTime) {
    const inputFormats = [
      "hh:mm:ss a",
      "hh:m:s a",
      "hh:mm a",
      "HH:mm:ss",
      "H:m:s",
      "HH:mm",
      "h:mm:ss a",
      "h:mm:s a",
      "h:m:ss a",
      "h:mm a",
      "h:m a",
      "h:m:s a",
    ];

    const normalizedTime = inputTime.trim();

    let parsedTime: Date | null = null;

    for (const formatString of inputFormats) {
      parsedTime = parse(normalizedTime, formatString, new Date());
      if (parsedTime.toString() !== "Invalid Date") {
        break;
      }
    }

    if (parsedTime === null) {
      console.error("Invalid input time format");
      return null;
    }

    const formattedTime = format(parsedTime, outputFormat);

    return formattedTime;
  } else {
    return inputTime;
  }
};

export const formatDate = (inputDateString: string | undefined | null) => {
  if (inputDateString) {
    for (let i = 0; i < supportedFormats.length; i++) {
      try {
        const parsedDate = parse(
          inputDateString,
          supportedFormats[i],
          new Date()
        );
        const formattedDate = format(parsedDate, desiredOutputFormats[i]);
        return formattedDate;
      } catch (error) {
        // If parsing fails, move to the next format
        continue;
      }
    }

    return undefined;
  } else {
    return undefined;
  }
};
