import { FC, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { HiOutlineCalendar } from "react-icons/hi";

import {
  DateMonthFormatter,
  DateMonthYearFormatter,
} from "global/helpers/DateFormatter";
import { ISetStateType } from "global/types/type";

interface IProps {
  setRangeFocus: Function;
  dateRange: [
    {
      startDate: Date;
      endDate: Date;
      key: string;
    }
  ];
  setDateRange: Function;
  datesArray: string[];
  label?: string;
  disabledDates?: any[];
  scrollOption?: boolean;
  minDate?: Date | any;
  maxDate?: Date | any;
  setIsDateChanged?: ISetStateType<boolean>;
  classNameForDateRange?: string;
}
const DateRangeSelector: FC<IProps> = ({
  setRangeFocus,
  dateRange,
  setDateRange,
  datesArray,
  label,
  disabledDates,
  scrollOption,
  minDate,
  maxDate,
  setIsDateChanged,
  classNameForDateRange,
}) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const calendarRef: any = useRef();
  const [dateSelectionCount, setDateSelectionCount] = useState(0);

  useEffect(() => {
    if (datesArray?.length > 1) {
      setShowCalendar(false);
    }
  }, [datesArray]);

  useEffect(() => {
    if (!showCalendar) {
      setDateSelectionCount(0);
    }
  }, [showCalendar]);

  useEffect(() => {
    if (dateSelectionCount > 1) {
      setShowCalendar(false);
    }
  }, [dateSelectionCount]);

  useEffect(() => {
    const handler = (event: PointerEvent) => {
      if (!calendarRef.current?.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("pointerdown", handler);
    return () => {
      document.removeEventListener("pointerdown", handler);
    };
  }, [showCalendar]);

  const tempRange: any = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];

  return (
    <div
      className={`${
        classNameForDateRange
          ? classNameForDateRange
          : `w-full min-w-[260px] sm:w-[385px] shadow md:w-[440px] border rounded-[4px] flex items-center justify-between px-2 h-[52px] relative ${
              datesArray?.length > 0 ? "justify-between" : "justify-end"
            }`
      }`}
    >
      <div className="relative">
        {datesArray?.length === 1 || datesArray?.length === 0 ? (
          <input
            id="date"
            className={`text-sm w-full outline-none ${
              datesArray?.length === 0 ? "invisible" : "block"
            }`}
            value={
              datesArray?.length === 0
                ? "HelperText"
                : DateMonthYearFormatter(dateRange[0]?.startDate?.toString())
            }
            onChange={() => null}
          />
        ) : (
          <input
            id="date"
            className="outline-none w-full text-sm"
            value={
              +dateRange[0]?.startDate?.getFullYear() ===
              +dateRange[0]?.endDate?.getFullYear()
                ? DateMonthFormatter(dateRange[0]?.startDate?.toString()) +
                  " - " +
                  DateMonthYearFormatter(dateRange[0]?.endDate?.toString())
                : `${DateMonthYearFormatter(
                    dateRange[0]?.startDate?.toString()
                  )} - ${DateMonthYearFormatter(
                    dateRange[0]?.endDate?.toString()
                  )}`
            }
            onChange={() => null}
          />
        )}
        <label
          htmlFor="date"
          className={`absolute -top-6 sm:-top-5 left-1 bg-white px-[4px] text-[11px] font-normal text-[#8f8f92]`}
        >
          {label ? label : "Date"}
        </label>
      </div>
      <div
        ref={calendarRef}
        className={`absolute right-2 text-cornflower-blue`}
      >
        <HiOutlineCalendar
          onClick={() => setShowCalendar(!showCalendar)}
          className="w-6 h-6"
        />
        {showCalendar && (
          <div className="absolute z-20 top-10 -left-[280px] sm:-left-[300px]">
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => {
                setDateRange([item.selection]);
                setDateSelectionCount((prevCount: number) => prevCount + 1);
                if (setIsDateChanged) {
                  setIsDateChanged(true);
                }
              }}
              moveRangeOnFirstSelection={false}
              ranges={dateRange[0]?.startDate ? dateRange : tempRange}
              onRangeFocusChange={() => setRangeFocus(true)}
              disabledDates={disabledDates}
              scroll={{ enabled: scrollOption ? scrollOption : false }}
              minDate={minDate ? minDate : null}
              maxDate={maxDate ? maxDate : null}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeSelector;
