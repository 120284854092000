import { Fragment, useRef, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { Filled } from "components/forms";
import DataCard from "components/App/Card/DataCard";

import useAllowedResources from "global/hooks/useAllowedResources";
import { toastNotify, userQueryProps } from "global/helpers/Cache";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import EditIdProof from "modules/Employee/Pages/Profile/Personal/IdProof/EditIdProof";
import IdProofDetails from "modules/Employee/Pages/Profile/Personal/IdProof/IdProofDetails";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import { GET_ID_PROOFS } from "modules/Employee/services/queries";
import { DELETE_ID_PROOF } from "modules/Employee/services/mutations";
import { IIdProof } from "modules/Employee/types/personal";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

const IdProof = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchIDProofs,
  } = useQuery(GET_ID_PROOFS, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const idProofs =
    (userDetails?.users?.dataCollection &&
      userDetails?.users?.dataCollection[0]?.userIdProofs) ||
    [];

  const isUnderModeration = useIsUnderModeration();
  const allowedResources = useAllowedResources();
  const canCreate = allowedResources?.includes("CreateEmployeeIdProof");

  const [inProgress, setInProgress] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [deleteUserIdProof] = useMutation(DELETE_ID_PROOF);

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Id proof")
    : deleteSuccessMessageWithAwaiting("Id proof");

  const deleteHandler = () => {
    deleteUserIdProof({
      variables: {
        id: editID,
      },
      update: (cache) => {
        const existingIdProof: any = cache.readQuery({
          query: GET_ID_PROOFS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
        });

        const updatedIdProofs =
          existingIdProof?.users?.dataCollection[0]?.userIdProofs?.filter(
            (idProof: IIdProof) => idProof?.id !== editID
          );

        cache.writeQuery({
          query: GET_ID_PROOFS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
          data: {
            users: {
              dataCollection: [
                {
                  ...existingIdProof?.users?.dataCollection[0],
                  userIdProofs: updatedIdProofs,
                },
              ],
            },
          },
        });
      },
    })
      .then(() => {
        setEditID(null);
        setDeleteVerification(false);
        toastNotify(deleteActionSuccessMessage);
        refetchIDProofs();
      })
      .catch((error) => {
        toastNotify(errorMessageNotify(error));
      });
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  const moderationProps = {
    setChangedInformation: setChangedInformation,
    setShowUnderReview: setShowUnderReview,
  };

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <DataCard
        loading={loading}
        title="ID Proof"
        showPlusIcon={
          canCreate && idProofs?.length !== 4 && idProofs?.length !== 0
            ? true
            : false
        }
        resourceName={"EmployeeIdProof"}
        addOrEditHandler={() => {
          setEditID(null);
        }}
        setShowModal={setShowModal}
      >
        {canCreate && idProofs?.length === 0 ? (
          <div className="w-full min-h-[300px] grid place-content-center">
            <Filled
              buttonName={"Add"}
              onClick={() => {
                setEditID(null);
                setShowModal(true);
              }}
            />
          </div>
        ) : (
          <div className="w-full overflow-x-auto py-[20px]" ref={parentRef}>
            <ul className="space-y-2 min-w-[390px]">
              {idProofs?.map((idProof: IIdProof) => {
                return (
                  <li key={idProof?.id} className="grid grid-cols-4">
                    <IdProofDetails
                      setEditID={setEditID}
                      setShowModal={setShowModal}
                      idProof={idProof}
                      setDeleteVerification={setDeleteVerification}
                      parentRef={parentRef}
                      {...moderationProps}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </DataCard>
      {showModal && (
        <EditIdProof
          idProofs={idProofs}
          setShowModal={setShowModal}
          editID={editID}
          setEditID={setEditID}
          inProgress={inProgress}
          setInProgress={setInProgress}
          refetchIDProofs={refetchIDProofs}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"ID Proof Details"}
          onCancel={() => {
            setDeleteVerification(false);
            setEditID(null);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
            setEditID(null);
          }}
          onExecute={deleteHandler}
        />
      )}

      {showUnderReview && (
        <UnderReviewModal
          {...moderationProps}
          changedInformation={changedInformation}
          refetchQuery={refetchIDProofs}
        />
      )}
    </Fragment>
  );
};

export default IdProof;
