import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loader/Loading";

import MessageDetails from "layouts/header/TopLinks/Notification/Pages/ViewAllMessages/MessageDetails";
import { USER_NOTIFICATIONS_COLLECTION_QUERY } from "layouts/header/TopLinks/Notification/services/queries";
import {
  MessagesType,
  NotificationsType,
} from "layouts/header/TopLinks/TopLinks";

import { groupBy } from "global/helpers/ArrayMethods";
import { ViewDateFormat } from "global/helpers/DateFormatter";

export interface MessageType {
  id: number;
  date: string;
  message: string;
  isRead: boolean;
  time: string;
  link: string;
  triggeredByUser: {
    userProfileImages: {
      documents: { file: string }[] | any;
      type: "Profile" | "Cover";
    }[];
    personalInformation: {
      id: number;
      name: string;
      personalEmail: string;
    };
  };
}

const AllNotificationMessages = () => {
  const [
    fetchAllMessages,
    { data: allMsg, loading, refetch: refetchAllMsg },
  ] = useLazyQuery(USER_NOTIFICATIONS_COLLECTION_QUERY);

  const allNotifications: NotificationsType[] = allMsg?.notifications?.dataCollection?.map(
    (message: MessagesType) => {
      return {
        id: message?.id,
        message: message?.message,
        date: message?.createdAt?.slice(0, 11),
        time: message?.createdAt?.slice(-8),
        isRead: message?.isRead,
        link: message?.link,
        triggeredByUser: message?.triggeredByUser,
      };
    }
  );

  const notifications: MessageType[] = allNotifications;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  useEffect(() => {
    if (pageSize && currentPage) {
      fetchAllMessages({
        variables: {
          limit: pageSize,
          page: currentPage,
        },
      });
    }
  }, [pageSize, currentPage, fetchAllMessages]);

  return (
    <div className="card-outer min-h-[76vh]">
      {loading ? (
        <Loading className="min-h-[76vh]" />
      ) : notifications?.length > 0 ? (
        <div>
          <div className="pt-[25px] min-h-[555px] overflow-x-auto">
            {notifications &&
              Object?.entries(groupBy(notifications, "date"))?.map(
                ([key, value]: any, index: number) => {
                  return (
                    <div key={index}>
                      {value?.length > 0 ? (
                        <div className="pt-5">
                          <p className="text-sm text-ironside-gray/70 py-1 px-3 rounded border w-min whitespace-nowrap">
                            {key === ViewDateFormat(new Date()?.toDateString())
                              ? "Today"
                              : key ===
                                ViewDateFormat(
                                  new Date(yesterday)?.toDateString()
                                )
                              ? "Yesterday"
                              : key}
                          </p>
                          <div className="pt-3">
                            {value?.map((mapData: MessageType) => {
                              return (
                                <MessageDetails
                                  key={mapData?.id}
                                  message={mapData}
                                  refetchMsg={refetchAllMsg}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
                          No Messages
                        </p>
                      )}
                    </div>
                  );
                }
              )}
          </div>
          <Pagination
            totalCount={allMsg?.notifications?.totalNumberOfItems || 0}
            noOfItem={notifications?.length}
            currentPage={currentPage}
            setPageSize={setPageSize}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
          No Messages
        </p>
      )}
    </div>
  );
};

export default AllNotificationMessages;
