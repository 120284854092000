import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useMutation } from "@apollo/client";

import { Email } from "components/forms";

import { toastNotify } from "global/helpers/Cache";

import { OTP_REQUEST } from "modules/User/services/mutations";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword] = useMutation(OTP_REQUEST);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<{ email: string }>();

  const onSubmit = (data: { email: string }) => {
    if (data.email === "") {
      setError("email", {
        type: "manual",
        message: "This is required field.",
      });
    } else if (!loading) {
      forgotPassword({
        variables: {
          email: data?.email,
        },
      })
        .then((response) => {
          navigate("/otp", {
            state: {
              email: data?.email,
            },
          });
          toastNotify([
            {
              messageType: "success",
              message: "The OTP has been successfully sent to your e-mail.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
          setLoading(false);
        });
      setLoading(!loading);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="form-design flex items-center justify-center flex-col py-[35px] md:px-[42px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col justify-center items-center">
            <Email
              register={register}
              errors={errors}
              label="Email *"
              name="email"
            />
            <p className="text-center text-[14px] leading-[23px] text-[#8f8f92] mb-[24px] px-3">
              A one-time password (OTP) will be sent to your <br />
              registered email address for verification.
            </p>
          </div>
          <div className="flex justify-center">
            <button type="submit" className="button-design">
              {loading ? (
                <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
              ) : (
                <span>Send OTP</span>
              )}
            </button>
          </div>
        </form>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default ForgetPassword;
