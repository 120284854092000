import { FC } from "react";
import { FaRegClock } from "react-icons/fa";

import { getFileExtension } from "global/helpers/getFileExtension";
import { secondsToHoursConverterWithSeconds } from "global/helpers/DateFormatter";

import { IProjectWorkLog } from "modules/Project/types/project";

interface IProps {
  workLog: IProjectWorkLog;
  setShowModal: Function;
  setEditWorkLog: Function;
  setDeleteVerification: Function;
  canSaveProjectWorkLog: boolean;
  canDeleteProjectWorkLog: boolean;
  index: number;
}

const WorkLog: FC<IProps> = ({
  workLog,
  setShowModal,
  setEditWorkLog,
  setDeleteVerification,
  canSaveProjectWorkLog,
  canDeleteProjectWorkLog,
  index,
}) => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const [durationHours, durationInMinutes, durationInSeconds] =
    workLog?.durationInSeconds
      ? secondsToHoursConverterWithSeconds(workLog?.durationInSeconds)?.split(
          ":"
        )
      : ["00:00:00"];

  const getProfileImage = (workLog: IProjectWorkLog) => {
    const filterProfileImage =
      (workLog?.user?.userProfileImages &&
        workLog?.user?.userProfileImages?.length > 0 &&
        workLog?.user?.userProfileImages?.filter(
          (userProfileImage: any) => userProfileImage.type === "Profile"
        )[0]) ||
      null;

    const profileImage: any =
      filterProfileImage &&
      filterProfileImage?.documents &&
      filterProfileImage?.documents?.length > 0 &&
      filterProfileImage?.documents[0]?.file;

    return profileImage?.length > 100 ? (
      <img
        className="max-w-[32px] max-h-[32px] rounded-[50%] object-fill"
        src={`data:image/${
          getFileExtension(profileImage) === "svg"
            ? "svg+xml"
            : getFileExtension(profileImage)
        };base64,${profileImage}`}
        alt="No File"
      />
    ) : (
      <div className="w-8 h-8 rounded-[50%] bg-cornflower-blue text-white text-[20px] text-center">
        {workLog?.user?.personalInformation &&
          workLog?.user?.personalInformation?.name[0]}
      </div>
    );
  };

  const onDelete = (workLog: IProjectWorkLog, index: number) => {
    setEditWorkLog({ ...workLog, index });
    setDeleteVerification(true);
  };

  return (
    <div className="flex mb-5 gap-4 bg" key={workLog?.id}>
      <div>{getProfileImage(workLog)}</div>
      <div>
        <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-20">
          <p className="flex flex-col gap-2 sm:min-w-[290px] sm:flex-row sm:gap-5 sm:items-center">
            <span className="text-[14px] leading-[19px] text-[#626364] min-w-[120px] font-bold">
              {workLog?.user?.personalInformation?.name}
            </span>
            <span className="text-[12px] leading-[14px] text-[#A4A4A5]">
              {workLog?.createdAt?.slice(0, -9)}
            </span>
          </p>
          <p className="flex gap-2 items-center">
            <FaRegClock className="w-4 h-4 text-[#A4A4A5]" />
            {workLog?.durationInSeconds && (
              <span className="text-[12px] leading-[14px] text-[#626364]">{`${
                +durationHours > 0 ? `${durationHours}h` : ""
              } ${durationInMinutes}m ${durationInSeconds}s`}</span>
            )}
          </p>
        </div>
        <p className="mt-3 sm:mt-2 mb-[9px] text-[14px] text-[#626364]">
          {workLog?.description}
        </p>
        {(+localStorage?.id === workLog?.user?.personalInformation?.id ||
          +sessionId === workLog?.user?.personalInformation?.id) && (
          <>
            {canSaveProjectWorkLog && (
              <span
                className="text-[14px] text-[#8993A4] hover:underline cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setEditWorkLog({ ...workLog, index });
                }}
              >
                Edit
              </span>
            )}
            {canDeleteProjectWorkLog && (
              <span
                className="ml-3 text-[14px] text-[#8993A4] hover:underline cursor-pointer"
                onClick={() => {
                  onDelete(workLog, index);
                }}
              >
                Delete
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WorkLog;
