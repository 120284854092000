import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import { KebabMenu } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { TeamsType } from "./EditAllTeams";
import { toastNotify } from "global/helpers/Cache";
import MyTeamMembersDetails from "../MyTeamInfo/MyTeamMembersDetails";
import UserDetails from "components/UserDetails/UserDetails";
import { DELETE_TEAM } from "modules/Teams/services/mutations";
import { ALL_TEAMS } from "modules/Teams/services/queries";
import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";

const AllTeamsDetailsGrid: FC<{
  allowedResources: string[];
  teamMembers: any;
  setShowModal: Function;
  editAllTeamsDetails: any;
  setEditAllTeamsDetails: Function;
  refetchTeams: Function;
  parentRef: any;
  userId: number | undefined;
  setUserId: Function;
}> = ({
  allowedResources,
  teamMembers,
  setShowModal,
  editAllTeamsDetails,
  setEditAllTeamsDetails,
  refetchTeams,
  parentRef,
  userId,
  setUserId,
}) => {
  const updateAccess = allowedResources?.includes("UpdateEmployeeTeam");
  const deleteAccess = allowedResources?.includes("DeleteEmployeeTeam");

  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [deleteEmployeeTeam] = useMutation(DELETE_TEAM);

  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: PointerEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("pointerdown", handler);
    return () => {
      document.removeEventListener("pointerdown", handler);
    };
  }, [showEditOption]);

  const deleteHandler = () => {
    deleteEmployeeTeam({
      variables: {
        id: editAllTeamsDetails?.id,
      },
      update: (cache, { data: { deleteEmployeeTeam } }) => {
        const exTeams: {
          employeeTeams: TeamsType[] | any;
        } | null = cache.readQuery({
          query: ALL_TEAMS,
        });

        const updatedTeams = exTeams?.employeeTeams?.filter(
          (teamDetails: TeamsType) =>
            +teamDetails?.id !== +editAllTeamsDetails?.id
        );

        cache.writeQuery({
          query: ALL_TEAMS,
          data: {
            employeeTeams: updatedTeams,
          },
        });
      },
    })
      .then(() => {
        const teamName = editAllTeamsDetails?.name;
        setDeleteVerification(false);
        setEditAllTeamsDetails(null);
        toastNotify([
          {
            messageType: "success",
            message: `${
              teamName ? `${teamName} team` : "Team"
            } has been deleted successfully .`,
          },
        ]);
        refetchTeams();
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };

  const kebabIconRef = useRef<HTMLDivElement>(null);

  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );
  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const parentEventRef = parentRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    parentEventRef && parentEventRef?.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [getPosition, parentRef]);
  const profileRef = useRef<HTMLDivElement>(null);
  const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );
  const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );

  const getPositionOfProfileName = useCallback(() => {
    setTopProfileRef(
      profileRef.current?.getBoundingClientRect().top &&
        profileRef.current?.getBoundingClientRect().top + 25
    );
    setleftProfileRef(
      profileRef.current?.getBoundingClientRect().left &&
        profileRef.current?.getBoundingClientRect().left
    );
  }, []);
  useEffect(() => {
    const eventRef = profileRef.current;
    window.addEventListener("resize", getPositionOfProfileName);
    window.addEventListener("scroll", getPositionOfProfileName);

    eventRef &&
      eventRef?.addEventListener("mouseenter", getPositionOfProfileName);

    return () => {
      window.removeEventListener("resize", getPositionOfProfileName);
      window.removeEventListener("scroll", getPositionOfProfileName);
      eventRef &&
        eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
    };
  }, [getPositionOfProfileName]);
  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate">{teamMembers?.name}</td>
      <td className="py-5 px-3 max-w-[200px] truncate">{teamMembers?.code}</td>
      <td className="px-3 max-w-[200px] truncate">
        <div className="group w-min" ref={profileRef}>
          <span
            className="py-1 md:whitespace-nowrap"
            onMouseEnter={() => setUserId(teamMembers?.teamLeadUser?.id)}
          >
            {teamMembers?.teamLeadUser?.personalInformation?.name}
          </span>
          <div
            style={{
              top: `${topProfileRef}px`,
              left: `${leftProfileRef}px`,
            }}
          >
            <UserDetails userId={userId} />
          </div>
        </div>
      </td>
      <td className="px-3 max-w-[200px] truncate">
        {teamMembers?.members?.map((memberDetail: any, index: number) => (
          <MyTeamMembersDetails
            key={index}
            index={index}
            teamMembers={teamMembers}
            memberDetails={memberDetail}
            userId={userId}
            setUserId={setUserId}
          />
        ))}
      </td>
      {updateAccess || deleteAccess ? (
        <td className="relative px-3">
          <div className="w-full flex justify-center items-center">
            <div ref={editRef} className="w-min">
              <div ref={kebabIconRef}>
                <KebabIcons
                  onClick={() => {
                    setShowEditOption((prevState: any) => !prevState);
                    setEditAllTeamsDetails(teamMembers);
                  }}
                  className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke rotate-90 ${
                    showEditOption ? "bg-white-smoke" : null
                  }`}
                />
              </div>

              {showEditOption ? (
                <div
                  className="fixed z-[80]"
                  style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    right: `${right}px`,
                  }}
                >
                  <KebabMenu
                    onEdit={() => {
                      if (updateAccess) {
                        setShowModal(true);
                        setShowEditOption(false);
                      } else {
                        toastNotify([
                          {
                            messageType: "error",
                            message: "Access denied.",
                          },
                        ]);
                      }
                    }}
                    onDelete={() => {
                      if (deleteAccess) {
                        setDeleteVerification(true);
                        setShowEditOption(false);
                      } else {
                        toastNotify([
                          {
                            messageType: "error",
                            message: "Access denied.",
                          },
                        ]);
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {deleteVerification ? (
            <ConfirmModal
              header={"Teams"}
              onCancel={() => {
                setDeleteVerification(false);
              }}
              onXIcon={() => {
                setDeleteVerification(false);
              }}
              onExecute={() => deleteHandler()}
            />
          ) : null}
        </td>
      ) : null}
    </tr>
  );
};

export default AllTeamsDetailsGrid;
