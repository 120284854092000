import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoPlay } from "react-icons/io5";
import { HiPencil } from "react-icons/hi";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { FiSave } from "react-icons/fi";
import { LuTimer } from "react-icons/lu";

import { CalendarField } from "components/forms";
import AutoComplete from "components/forms/UpdatedFormAgry/AutoComplete/AutoComplete";
import Input from "components/forms/UpdatedFormAgry/Input/Input";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";
import Time from "components/forms/UpdatedFormAgry/Time/Time";
import { FaStopCircle } from "react-icons/fa";
import { getLocalTimeZone, now } from "@internationalized/date";

import { FetchMoreType, RefetchQueryType } from "global/types/type";
import { useEffectOnce, useStopWatch } from "global/UpdatedHooks/hooks";
import { ViewDateFormat, formatTime } from "global/helpers/DateFormatter";
import { convertHoursToTwelveHour } from "global/helpers/TimeFormat";
import {
	getAllowedAccess,
	toastNotify,
	userContextData,
} from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
	FilterProjectsArgs,
	FilterProjectsReturnType,
	IProject,
} from "modules/Project/types/project";
import {
	FilterAllProjectWorkLogsArgs,
	FilterAllProjectWorkLogsReturnType,
	ProjectWorkLogTimerForm,
} from "modules/Timer/WorkLogs/types";
import {
	CREATE_OR_UPDATE_PROJECT_WORK_LOG,
	START_PROJECT_WORK_LOG,
	STOP_PROJECT_WORK_LOG,
} from "modules/Project/services/mutations";
import { RUNNING_PROJECT_WORK_LOG } from "modules/Project/services/queries";

interface Props {
	projects: IProject[];
	loading?: boolean;
	refetchWorkLogs: RefetchQueryType<
		FilterAllProjectWorkLogsReturnType,
		FilterAllProjectWorkLogsArgs
	>;
	fetchMoreProjects: FetchMoreType<
		FilterProjectsReturnType,
		FilterProjectsArgs
	>;
}

const MyProjectWorkLogHeader: FC<Props> = ({
	projects,
	loading,
	refetchWorkLogs,
	fetchMoreProjects,
}) => {
	const loggedInUser = useReactiveVar(userContextData);
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const [
		fetchRunningProjectWorkLog,
		{
			data: runningProjectLog,
			refetch: refetchRunningLog,
			updateQuery: updateRunningLog,
		},
	] = useLazyQuery(RUNNING_PROJECT_WORK_LOG, {
		fetchPolicy: "network-only",
	});

	const [startProjectWorkLog, { loading: startProjectWorkLogLoading }] =
		useMutation(START_PROJECT_WORK_LOG);
	const [stopProjectWorkLog, { loading: stopProjectWorkLogLoading }] =
		useMutation(STOP_PROJECT_WORK_LOG);
	const [createProjectWorkLog, { loading: createProjectWorkLogLoading }] =
		useMutation(CREATE_OR_UPDATE_PROJECT_WORK_LOG);

	const currentDate = new Date();
	const minDate = new Date(
		currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1),
	)
		.toISOString()
		.slice(0, 10);

	const [workLogDate, setWorkLogDate] = useState<Date | null | undefined>(
		new Date(),
	);

	const [showManualEntry, setShowManualEntry] = useState(false);

	const showManualEntryHandler = () => {
		setShowManualEntry((prev) => !prev);
	};

	const {
		control,
		setValue,
		register,
		formState: { errors },
		resetField,
		reset,
	} = useForm<ProjectWorkLogTimerForm>({
		defaultValues: {
			startTime: now(getLocalTimeZone()),
			endTime: now(getLocalTimeZone()),
		},
	});

	useEffect(() => {
		if (runningProjectLog?.runningProjectWorkLog) {
			setValue(
				"description",
				runningProjectLog?.runningProjectWorkLog?.description,
			);
			setValue(
				"project",
				runningProjectLog?.runningProjectWorkLog?.projectTask?.project?.id &&
					runningProjectLog?.runningProjectWorkLog?.projectTask?.project?.name
					? {
							id: runningProjectLog?.runningProjectWorkLog?.projectTask?.project
								?.id,
							label:
								runningProjectLog?.runningProjectWorkLog?.projectTask?.project
									?.name,
					  }
					: undefined,
			);
			setValue(
				"task",
				runningProjectLog?.runningProjectWorkLog?.projectTask?.id &&
					runningProjectLog?.runningProjectWorkLog?.projectTask?.title
					? {
							id: runningProjectLog?.runningProjectWorkLog?.projectTask?.id,
							label:
								runningProjectLog?.runningProjectWorkLog?.projectTask?.title,
					  }
					: (null as unknown as { id: number; label: string }),
			);
		} else {
			reset();
		}
	}, [setValue, runningProjectLog?.runningProjectWorkLog, reset]);

	const [
		watchProject,
		watchProjectTask,
		watchDescription,
		watchStartTime,
		watchEndTime,
	] = useWatch({
		control,
		name: ["project", "task", "description", "startTime", "endTime"],
	});

	const disableStartButton =
		!watchProject || !watchProjectTask || !watchDescription;

	const projectsList =
		projects?.reduce((acc: { id: number; label: string }[], project) => {
			if (project?.id && project?.name) {
				acc.push({ id: project?.id, label: project?.name });
				return acc;
			}
			return acc;
		}, []) || [];

	const selectedProjectTasks = useMemo(
		() =>
			projects?.filter((project) => project?.id === watchProject?.id)[0]?.tasks
				?.dataCollection || [],
		[projects, watchProject?.id],
	);

	const projectTaskList = useMemo(
		() =>
			selectedProjectTasks?.reduce(
				(acc: { id: number; label: string }[], task) => {
					if (task?.id && task?.title) {
						acc.push({ id: task?.id, label: task?.title });
						return acc;
					}
					return acc;
				},
				[],
			) || [],
		[selectedProjectTasks],
	);

	const [projectTaskOptions, setProjectTaskOptions] = useState(projectTaskList);

	useEffect(() => {
		setProjectTaskOptions(projectTaskList);
	}, [projectTaskList]);

	const { timer, handleStart, setTimer, handleReset } = useStopWatch();

	useEffectOnce(() => {
		fetchRunningProjectWorkLog()
			.then((response) => {
				const runningHours =
					response?.data?.runningProjectWorkLog?.runningHours;

				if (runningHours) {
					const [hours, minutes, seconds] = runningHours?.split(":");
					const timeInSeconds = Math.trunc(
						+hours * 60 * 60 + +minutes * 60 + +seconds,
					);
					setTimer(timeInSeconds);
					handleStart();
				}
			})
			.catch((error) => {
				if (error.name === "AbortError") return;

				toastNotify([
					{
						messageType: "error",
						message: error.message,
					},
				]);
			});
	});

	const startWorkLogHandler = () => {
		if (watchProjectTask?.id && watchDescription)
			startProjectWorkLog({
				variables: {
					projectWorkLogInput: {
						projectTaskId: +watchProjectTask?.id,
						description: watchDescription,
					},
				},
			})
				.then(() => {
					refetchRunningLog();
					handleStart();
				})
				.catch((error) => {
					toastNotify(errorMessageNotify(error));
				});
	};

	const stopWorkLogHandler = () => {
		if (
			+runningProjectLog?.runningProjectWorkLog?.id &&
			runningProjectLog?.runningProjectWorkLog?.description
		)
			stopProjectWorkLog({
				variables: {
					stopProjectWorkLogInput: {
						id: +runningProjectLog?.runningProjectWorkLog?.id,
						description: runningProjectLog?.runningProjectWorkLog?.description,
					},
					isProfileImageNeeded: canReadUserProfileImage,
				},
			})
				.then(({ data }) => {
					updateRunningLog(() => {
						return {
							runningProjectLog: {
								...runningProjectLog?.runningProjectWorkLog,
								endTime: data?.stopProjectWorkLog?.endTime,
							},
						};
					});
					refetchWorkLogs();
					refetchRunningLog();
					handleReset();
					resetField("project");
					resetField("description");
					resetField("task");
				})
				.catch((error) => {
					toastNotify(errorMessageNotify(error));
				});
	};

	const submitHandler = () => {
		if (!createProjectWorkLogLoading && watchProjectTask?.id) {
			const logDate =
				(workLogDate && ViewDateFormat(new Date(workLogDate))) || "";

			const startTime =
				watchStartTime !== null && watchStartTime !== undefined
					? `${convertHoursToTwelveHour(watchStartTime?.hour)}:${
							watchStartTime?.minute < 9
								? `0${watchStartTime?.minute}`
								: watchStartTime?.minute
					  } ${watchStartTime?.hour > 11 ? "PM" : "AM"}`
					: null;

			const endTime =
				watchEndTime !== null && watchEndTime !== undefined
					? `${convertHoursToTwelveHour(watchEndTime?.hour)}:${
							watchEndTime?.minute < 9
								? `0${watchEndTime?.minute}`
								: watchEndTime?.minute
					  } ${watchEndTime?.hour > 11 ? "PM" : "AM"}`
					: null;

			createProjectWorkLog({
				variables: {
					createProjectWorkLogInput: {
						userId: loggedInUser?.user?.id,
						projectTaskId: +watchProjectTask?.id,
						startTime: `${logDate} ${startTime}`,
						endTime: `${logDate} ${endTime}`,
						description: watchDescription,
					},
					isProfileImageNeeded: false,
				},
			})
				.then(() => {
					refetchWorkLogs();
					toastNotify([
						{
							messageType: "success",
							message: "Work log updated successfully",
						},
					]);
					resetField("project");
					resetField("description");
					resetField("task");
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				});
		}
	};

	return (
		<form className="flex gap-4 lg:gap-5 items-center flex-wrap lg:flex-nowrap mt-5">
			<AutoComplete
				control={control}
				name="project"
				options={projectsList}
				label="Select project"
				className="bg-white md:max-w-[300px]"
				classNameForComboBox="min-h-[42px]"
				hideError
				onChange={() => {
					resetField("task");
				}}
			/>
			<AutoComplete
				control={control}
				name="task"
				options={projectTaskOptions}
				label="Select task"
				className="bg-white md:max-w-[400px]"
				classNameForComboBox="min-h-[42px]"
				hideError
				onInputChange={(value) => {
					if (
						!loading &&
						fetchMoreProjects &&
						watchProject?.id &&
						loggedInUser?.user?.id
					) {
						fetchMoreProjects({
							variables: {
								filters: {
									isMyProject: true,
									id: watchProject?.id,
								},
								taskPage: 1,
								taskLimit: 30,
								tasksFilters2: {
									assigneeUserId: [+loggedInUser?.user?.id],
									title: value || undefined,
								},
							},
						})
							.then(({ data }) => {
								const project =
									data?.projects && data?.projects?.dataCollection?.length > 0
										? data?.projects?.dataCollection[0]
										: null;

								const projectTasks =
									project?.tasks && project?.tasks?.dataCollection?.length > 0
										? project?.tasks?.dataCollection
										: [];

								const projectTaskOptions = projectTasks?.reduce(
									(acc: { id: number; label: string }[], projectTask) => {
										if (projectTask?.id && projectTask?.title) {
											acc?.push({
												id: projectTask?.id,
												label: projectTask?.title,
											});
											return acc;
										}
										return acc;
									},
									[],
								);

								setProjectTaskOptions(projectTaskOptions);
							})
							.catch((error) => {
								toastNotify(errorMessageNotify(error));
							});
					}
				}}
				loading={loading}
			/>
			<Input
				control={control}
				name="description"
				label={
					showManualEntry ? "What have you done?" : "What are you working on?"
				}
				className="md:max-w-[450px]"
				classNameForInputParentDiv="min-h-[42px]"
				hideError
			/>
			<div
				className={`flex items-center flex-wrap sm:flex-nowrap ${
					showManualEntry ? "gap-4" : "gap-10"
				} justify-between`}
			>
				{showManualEntry ? (
					<Fragment>
						<Time
							control={control}
							label="From"
							name="startTime"
							hideError
							classForTimeField="min-h-[42px]"
						/>
						<CalendarField
							register={register}
							errors={errors}
							name="workLogDate"
							date={workLogDate}
							setDate={setWorkLogDate}
							className="h-[42px] min-w-[200px] sm:max-w-[200px] shadow-none"
							label="Start Date *"
							maxDate={new Date()}
							minDate={new Date(minDate)}
							hideError
						/>
						<Time
							control={control}
							label="To"
							name="endTime"
							hideError
							classForTimeField="min-h-[42px]"
						/>
					</Fragment>
				) : (
					<span className="text-[#333] font-bold text-base whitespace-nowrap">
						{formatTime(timer)}
					</span>
				)}
				<span className="flex gap-2 items-center">
					{runningProjectLog?.runningProjectWorkLog ? (
						<Fragment>
							{stopProjectWorkLogLoading ? (
								<div className="w-5 h-5 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
							) : (
								<ToolTip
									as={"span"}
									render={"Stop"}
									arrow
									trigger={["focus", "hover"]}
									classNameForParent="flex items-center justify-center px-2 py-1"
									onParentClick={() => {
										stopWorkLogHandler();
									}}
								>
									<FaStopCircle className="w-6 h-6 text-red-700 cursor-pointer" />
								</ToolTip>
							)}
							<ToolTip
								as={"span"}
								render={"Manual"}
								arrow
								trigger={["focus", "hover"]}
							>
								<HiPencil className="w-6 h-6 cursor-pointer" />
							</ToolTip>
						</Fragment>
					) : (
						<div
							className={`flex ${
								showManualEntry ? "gap-1" : "gap-3"
							} items-center`}
						>
							{showManualEntry ? (
								<Fragment>
									{createProjectWorkLogLoading ? (
										<div className="w-5 h-5 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
									) : (
										<ToolTip
											as={"span"}
											render={"Save"}
											arrow
											trigger={["focus", "hover"]}
											classNameForParent={`flex items-center justify-center px-2 py-1 ${
												disableStartButton
													? "cursor-not-allowed"
													: "cursor-pointer"
											}`}
											onParentClick={() => {
												if (!disableStartButton) {
													submitHandler();
												}
											}}
										>
											<FiSave className="w-6 h-6" />
										</ToolTip>
									)}
									<ToolTip
										as={"span"}
										render={"Timer"}
										arrow
										trigger={["focus", "hover"]}
										onParentClick={showManualEntryHandler}
									>
										<LuTimer className="w-6 h-6 cursor-pointer" />
									</ToolTip>
								</Fragment>
							) : (
								<Fragment>
									{startProjectWorkLogLoading ? (
										<div className="w-5 h-5 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
									) : (
										<ToolTip
											as={"span"}
											render={"Start"}
											arrow
											trigger={["focus", "hover"]}
											classNameForParent={`border flex items-center justify-center px-2 py-1 ${
												disableStartButton
													? "cursor-not-allowed"
													: "cursor-pointer"
											}`}
											onParentClick={() => {
												if (!disableStartButton) {
													startWorkLogHandler();
												}
											}}
										>
											<IoPlay className="w-6 h-6" />
										</ToolTip>
									)}
									<ToolTip
										as={"span"}
										render={"Manual"}
										arrow
										trigger={["focus", "hover"]}
										onParentClick={showManualEntryHandler}
									>
										<HiPencil className="w-6 h-6 cursor-pointer" />
									</ToolTip>
								</Fragment>
							)}
						</div>
					)}
				</span>
			</div>
		</form>
	);
};

export default MyProjectWorkLogHeader;
