import { forwardRef, Fragment, PropsWithChildren, ReactNode } from "react";
import { HiPlus } from "react-icons/hi";

import {
  IOnCheckBox,
  IOnPencilIcon,
  IOnPlusIcon,
  ITableHeadings,
  OnCursorPagination,
} from "components/App/UpdatedAgryApp/Table/types/Table";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import OopsNoData from "components/UI/UpdateUIAgry/OopsNoData";
import PaginationUpdated from "components/Pagination/UpdatedPaginationAgry/PaginationUpdated";

interface Props {
  onCheckBox?: IOnCheckBox;
  tableHeadings: ITableHeadings;
  onPlusIcon?: IOnPlusIcon;
  onPencilIcon?: IOnPencilIcon;
  onPagination: OnCursorPagination;
  className?: string;
  loading?: boolean;
  isCenterLastHeading?: boolean;
  children?: ReactNode;
}

const TableWithCursorPagination = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Props>
>(
  (
    {
      children,
      onCheckBox,
      tableHeadings,
      onPlusIcon,
      className,
      loading,
      isCenterLastHeading,
      onPagination,
    },
    ref
  ) => {
    return (
      <Fragment>
        {loading ? (
          <Loading className="min-h-[70vh]" />
        ) : (
          <div
            className={`flex flex-col w-full justify-between ${
              className ? className : "min-h-[200px]"
            }`}
          >
            <div
              className={`w-full overflow-x-auto overflow-y-hidden`}
              ref={ref}
            >
              <table className="w-full">
                <thead className="bg-white-smoke">
                  <tr>
                    {onCheckBox?.isShow && (
                      <th className="py-2 px-5 bg-[#e1f1e8]">
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            className="h-4 w-4 text-irish-green border accent-irish-green bordergray rounded-[2px] cursor-pointer"
                            onChange={onCheckBox.onChange}
                            checked={onCheckBox.isChecked}
                          />
                        </div>
                      </th>
                    )}
                    {tableHeadings.tableString &&
                      tableHeadings.tableString?.map((tableHeading, index) => {
                        return (
                          <th
                            key={index}
                            className={`p-3 py-5 text-left text-sm bg-blue-50 text-ironside-gray font-extrabold whitespace-nowrap ${
                              isCenterLastHeading && "last:text-center"
                            }`}
                          >
                            {tableHeading}
                          </th>
                        );
                      })}
                    {tableHeadings.tableObject &&
                      tableHeadings.tableObject?.map((tableHeading, index) =>
                        tableHeading?.hideHeading ? null : (
                          <th
                            key={index}
                            className={`p-3 ${
                              tableHeading?.center ? "text-center" : "text-left"
                            } text-sm bg-[#e1f1e8] text-ironside-gray font-normal whitespace-nowrap ${
                              isCenterLastHeading && "last:text-center"
                            }`}
                          >
                            {tableHeading?.name}
                          </th>
                        )
                      )}
                    {onPlusIcon?.isShow ? (
                      <th className="py-2 px-3 text-left text-sm bg-[#e1f1e8] text-ironside-gray font-normal whitespace-nowrap">
                        <div className="w-full flex justify-center items-center">
                          <div className="w-full flex justify-center items-center">
                            <HiPlus
                              className="w-6 h-8 text-ironside-gray/70 font-bold cursor-pointer"
                              onClick={() => {
                                onPlusIcon.onPlusIconClick &&
                                  onPlusIcon.onPlusIconClick();
                              }}
                            />
                          </div>
                        </div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {onPagination && onPagination.totalCount === 0 ? (
                    <tr>
                      <td
                        colSpan={
                          (tableHeadings.tableString &&
                            tableHeadings.tableString?.length + 1) ||
                          (tableHeadings.tableObject &&
                            tableHeadings.tableObject?.length + 1) ||
                          30
                        }
                      >
                        <OopsNoData className="min-h-[70vh]" />
                      </td>
                    </tr>
                  ) : (
                    children
                  )}
                </tbody>
              </table>
            </div>
            {onPagination && onPagination.totalCount !== 0 && (
              <PaginationUpdated
                onPageSizeChange={onPagination.onPageSizeChange}
                nextDisabled={onPagination.nextDisabled}
                onNextPageHandler={onPagination.onNextPageHandler}
                onPrevPageHandler={onPagination.onPrevPageHandler}
                prevDisabled={onPagination.prevDisabled}
                control={onPagination.control}
                pageSizeName={onPagination.pageSizeName}
                totalCount={onPagination.totalCount}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  }
);

export default TableWithCursorPagination;
