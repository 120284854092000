import { FC, ReactNode } from "react";
import { UseFormHandleSubmit } from "react-hook-form";

import Outlined from "components/forms/Button/Outlined";
import Filled from "components/forms/Button/Filled";
import Loading from "components/Loader/Loading";

import { MdOutlineClose } from "react-icons/md";

interface IProps {
  title: string;
  isFormLoading: boolean;
  modalCloseHandler: () => void;
  handleSubmit: UseFormHandleSubmit<any>;
  updateHandler: (data: any) => void;
  inProgress: boolean;
  children?: ReactNode;
}

const EditForm: FC<IProps> = ({
  title,
  isFormLoading,
  modalCloseHandler,
  handleSubmit,
  updateHandler,
  children,
  inProgress,
}) => {
  return (
    <div className="fixed inset-0 bg-transparent z-[60] flex justify-center items-center p-2">
      <div className="fixed inset-0 bg-black/60 -z-20" />
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] pr-[28px] lg:pl-[45px] lg:pr-[57px] boder-hit-gray">
          <div className="edit-modal-heading">{title}</div>
          <div
            className="edit-modal-close-icon-div"
            onClick={modalCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {isFormLoading ? (
            <Loading className="min-h-[539px] lg:min-h-[639px] rounded-b-[12px]" />
          ) : (
            <form onSubmit={handleSubmit(updateHandler)}>
              <div className="pr-[35px] form-div lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
                {children}
              </div>
              <div className="w-full border-t border-hit-gray pt-[6px]" />
              <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
                <Outlined onClick={modalCloseHandler} buttonName={"Cancel"} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : (
                      "Update"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditForm;
