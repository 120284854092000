import { FC, HtmlHTMLAttributes } from "react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { Dialog } from "@headlessui/react";

type TProps = { classForParent?: string } & MotionProps &
  HtmlHTMLAttributes<HTMLDivElement>;

const Modal: FC<TProps> = ({
  children,
  className = "",
  classForParent = "",
  exit,
  initial,
  animate,
  ...rest
}) => {
  return (
    <AnimatePresence>
      <Dialog
        open={true}
        onClose={() => {}}
        as="div"
        {...rest}
        className={`${
          className
            ? className
            : "fixed inset-0 flex justify-center items-center z-[55] h-full w-full bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40 cursor-pointer"
        }`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              type: "spring",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.6,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={classForParent ? classForParent : "cursor-default"}
        >
          {children}
        </motion.div>
      </Dialog>
    </AnimatePresence>
  );
};

export default Modal;
