import { FC, useState, ReactNode, useEffect } from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useLazyQuery } from "@apollo/client";

import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import ModerationPopUp from "modules/Employee/Pages/Profile/Moderation/ModerationPopUp";
import { GET_PROFILE_MODERATION } from "modules/Employee/services/queries";
import { IModerationObj } from "modules/Employee/types/moderation";

interface IProps {
  children: ReactNode;
  moderationId: number | null | undefined;
  refetchQuery?: Function;
}

const ModerationField: FC<IProps> = ({
  children,
  moderationId,
  refetchQuery,
}) => {
  const [showPopup, setShowPopUp] = useState<boolean>(false);

  const [fetchModeration, { data, loading }] = useLazyQuery(
    GET_PROFILE_MODERATION
  );

  useEffect(() => {
    if (showPopup && moderationId) {
      fetchModeration({
        variables: {
          ids: moderationId && [moderationId],
        },
      }).catch((error) => toastNotify(errorMessageNotify(error)));
    }
  }, [fetchModeration, moderationId, showPopup]);

  const moderationObj: IModerationObj =
    (data?.userProfileModerations?.dataCollection &&
      data?.userProfileModerations?.dataCollection[0]) ||
    null;

  const moderationPopUpCloseHandler = () => {
    setShowPopUp(false);
  };

  return (
    <div>
      {(moderationId && (
        <div className="flex flex-row relative">
          {children}
          <div className="absolute -right-7 top-4 hover:text-[#6391f4]">
            <HiQuestionMarkCircle
              className="w-6 h-6"
              onClick={() => {
                setShowPopUp(true);
              }}
            />
          </div>

          {showPopup && (
            <ModerationPopUp
              loading={loading}
              modalTitle={"Under Moderation"}
              onCancel={moderationPopUpCloseHandler}
              onXIcon={moderationPopUpCloseHandler}
              moderationObj={moderationObj}
              setShowPopUp={setShowPopUp}
              refetchQuery={refetchQuery}
            />
          )}
        </div>
      )) ||
        children}
    </div>
  );
};

export default ModerationField;
