import { FC, useCallback, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { ViewDateFormat } from "global/helpers/DateFormatter";
import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { currentDate } from "global/helpers/StaticData";
import { toastNotify } from "global/helpers/Cache";
import { IResourceAccess } from "global/types/acl";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";

import { IWorkExperience } from "modules/Employee/types/work";

interface IProps {
  employeeWorkExperience: IWorkExperience;
  setEditID: Function;
  setCheckbox: Function;
  setShowModal: Function;
  setDeleteVerification: Function;
  workExperienceDetails: IWorkExperience[];
  index: number;
  resourceAccess: IResourceAccess;
  setShowUnderReview: Function;
  setChangedInformation: Function;
}

const WorkExperienceDetails: FC<IProps> = ({
  employeeWorkExperience,
  setEditID,
  setCheckbox,
  setShowModal,
  setDeleteVerification,
  workExperienceDetails,
  index,
  resourceAccess,
  setShowUnderReview,
  setChangedInformation,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const startDate = employeeWorkExperience?.startDate;
  const endDate = employeeWorkExperience?.endDate;

  const monthCalculation = (dateFrom: Date, dateTo: Date) => {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  const noOfYear =
    monthCalculation(
      new Date(startDate),
      new Date(employeeWorkExperience?.isCurrent ? new Date() : endDate)
    ) / 12;
  const noOfMonth = Math.round((noOfYear - Math.floor(noOfYear)) * 12);

  const kebabIconClickHandler = () => {
    setShowEditOption(!showEditOption);
    setEditID(+employeeWorkExperience?.id);
    setCheckbox(employeeWorkExperience?.isCurrent);
  };

  const kebabMenuDeleteHandler = () => {
    if (resourceAccess?.canDelete) {
      setShowEditOption(false);
      setDeleteVerification(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const kebabMenuEditHandler = () => {
    if (resourceAccess?.canUpdate) {
      setShowEditOption(false);
      setShowModal(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const moderationObj = employeeWorkExperience?.moderation;

  const moderationPopUpHandler = (moderationIds: (number | null)[]) => {
    const filteredModerationIds = moderationIds?.filter(
      (moderationId: number | null) => moderationId
    );

    filteredModerationIds?.length > 0 && setShowUnderReview(true);

    setChangedInformation(filteredModerationIds);
  };

  const industry = employeeWorkExperience?.industry
    ? employeeWorkExperience?.industry
    : "Eg.Information Technology";

  const companyNameAndEmploymentType = `${
    employeeWorkExperience?.companyName
      ? employeeWorkExperience?.companyName
      : "Eg.OX Softwares Pvt Ltd"
  } - ${
    employeeWorkExperience?.employmentType
      ? employeeWorkExperience?.employmentType
      : "Eg.Regular"
  }`;

  const experienceStartAndEndDate = `${
    employeeWorkExperience?.startDate
      ? ViewDateFormat(employeeWorkExperience?.startDate)
          ?.slice(3)
          ?.split("-")
          ?.join(" ")
      : "Eg.March 2019"
  } - ${
    employeeWorkExperience?.endDate && !employeeWorkExperience?.isCurrent
      ? ViewDateFormat(employeeWorkExperience?.endDate)
          ?.slice(3)
          ?.split("-")
          ?.join(" ")
      : employeeWorkExperience?.isCurrent
      ? "Eg.Present"
      : "Eg.March 2021"
  }, ${
    employeeWorkExperience?.isCurrent
      ? `${
          currentDate?.getFullYear() -
          employeeWorkExperience?.startDate?.slice(7, 11)
        } yrs ${noOfMonth === 12 ? 0 : noOfMonth} mos`
      : `${
          noOfMonth === 12 ? Math.floor(noOfYear) + 1 : Math.floor(noOfYear)
        } yrs ${noOfMonth === 12 ? 0 : noOfMonth} mos`
  }`;

  const location = `${
    employeeWorkExperience?.location
      ? employeeWorkExperience?.location
      : "Eg.Palani, TamilNadu, India"
  }`;
  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;

    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
    };
  }, [getPosition]);

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };

  return (
    <div className={"grid grid-cols-1 gap-2 pt-5 first:pt-0"}>
      <div className="flex justify-between">
        <p
          className={`${
            moderationObj?.designation && "bg-yellow-100 cursor-pointer"
          } text-sm text-ironside-gray`}
          onClick={() => moderationPopUpHandler([moderationObj?.designation])}
        >
          {employeeWorkExperience?.designation || "Eg.Full Stack Developer"}
        </p>

        <div ref={editRef} className="w-min ml-2">
          {(resourceAccess?.canUpdate || resourceAccess?.canDelete) && (
            <div ref={kebabIconRef}>
              <KebabIcons
                onClick={kebabIconClickHandler}
                className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                  showEditOption ? "bg-white-smoke" : null
                }`}
              />
            </div>
          )}
          {showEditOption && (
            <div className="fixed z-[80]" style={showEditOptionStyle}>
              <KebabMenu
                onEdit={kebabMenuEditHandler}
                onDelete={kebabMenuDeleteHandler}
              />
            </div>
          )}
        </div>
      </div>
      <p
        className={`${
          moderationObj?.industry && "bg-yellow-100 cursor-pointer"
        } text-xs text-ironside-gray`}
        onClick={() => moderationPopUpHandler([moderationObj?.industry])}
      >
        {industry}
      </p>
      <p
        className={`${
          (moderationObj?.companyName || moderationObj?.employmentType) &&
          "bg-yellow-100 cursor-pointer"
        } text-xs text-ironside-gray`}
        onClick={() =>
          moderationPopUpHandler([
            moderationObj?.companyName,
            moderationObj?.employmentType,
          ])
        }
      >
        {companyNameAndEmploymentType}
      </p>

      <p
        className={`${
          (moderationObj?.startDate ||
            moderationObj?.endDate ||
            moderationObj?.isCurrent) &&
          "bg-yellow-100 cursor-pointer"
        } text-xs text-ironside-gray`}
        onClick={() =>
          moderationPopUpHandler([
            moderationObj?.startDate,
            moderationObj?.endDate,
            moderationObj?.isCurrent,
          ])
        }
      >
        {experienceStartAndEndDate}
      </p>

      {employeeWorkExperience?.additionalDetails && (
        <p className="text-xs text-ironside-gray">
          {employeeWorkExperience?.additionalDetails}
        </p>
      )}
      <p
        className={`${
          moderationObj?.location && "bg-yellow-100 cursor-pointer"
        } text-xs text-ironside-gray`}
        onClick={() => moderationPopUpHandler([moderationObj?.location])}
      >
        {location}
      </p>

      {index === workExperienceDetails?.length - 1 ? null : (
        <div className="border-b pt-[29px]" />
      )}
    </div>
  );
};

export default WorkExperienceDetails;
