import { gql } from "@apollo/client";

export const PROJECT_GROUP_BUG_SOURCE_FRAGMENT = gql`
  fragment projectBugSourceFields on ProjectBugSource {
    id
    name
    description @include (if: $isProjectGroupBugSourceDescriptionNeed)
    projectGroup @include (if: $isProjectGroupBugSourceProjectGroupNeed) {
      id
      name
    }
  }
`;
