import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";
import { useState } from "react";

import ComboBox from "components/forms/UpdatedForm/AutoComplete/ComboBox";
import {
  TAutoCompleteProps,
  TOptionType,
  ISearchFn,
} from "components/forms/UpdatedForm/types";

import useUpdateEffect1 from "global/UpdatedHooks/hooks/useUpdateEffect1";

const AutoComplete = <
  TForm extends FieldValues,
  TOptionObject extends {
    id: string | number;
    name: string;
    disabled?: boolean;
  }
>({
  name,
  label,
  control,
  disabled,
  multiple,
  className = "",
  shouldUnregister = false,
  options,
  onChange,
  required,
  loading,
  hideError,
  classNameForError = "",
  defaultValue,
  errors,
  setValue,
  searchKey,
  renderedOption,
  classForInput,
}: TAutoCompleteProps<
  TForm,
  TOptionObject,
  keyof Omit<TOptionObject, "disabled">,
  TOptionType<TOptionObject> | ISearchFn<TOptionObject>
>) => {
  const errorMessage = errors
    ? typeof errors === "string"
      ? errors
      : errors[name]?.message?.toString()
    : undefined;

  const [hasValueSet, setHasValueSet] = useState<boolean>(true);

  useUpdateEffect1(() => {
    if (
      Array.isArray(defaultValue) &&
      defaultValue?.length > 0 &&
      hasValueSet
    ) {
      setValue(name, defaultValue);
      setHasValueSet(false);
    } else if (defaultValue && hasValueSet && !Array.isArray(defaultValue)) {
      setValue(name, defaultValue);
      setHasValueSet(false);
    }
  }, [defaultValue, hasValueSet]);

  return (
    <div className={`w-full text-ironside-gray ${className}`}>
      <Controller
        name={name}
        control={control}
        shouldUnregister={shouldUnregister}
        rules={{
          required:
            typeof required === "boolean" && required === true
              ? "This is required field."
              : required
              ? required
              : false,
          shouldUnregister,
          onChange,
        }}
        defaultValue={
          defaultValue ? defaultValue : (null as PathValue<TForm, Path<TForm>>)
        }
        render={({ field: { value, onChange: onChangeHookForm } }) => {
          return (
            <ComboBox
              disabled={disabled}
              value={value}
              onChangeHookForm={onChangeHookForm}
              name={name}
              options={options}
              label={label}
              setValue={setValue as UseFormSetValue<FieldValues>}
              defaultValue={defaultValue}
              errors={errors}
              loading={loading}
              multiple={multiple}
              searchKey={searchKey as any}
              renderedOption={renderedOption as any}
              classForInput={classForInput}
            />
          );
        }}
      />

      {hideError ? null : (
        <div className={`w-[inherit] h-min ${classNameForError}`}>
          <span
            className={`text-valentine-red text-xs ${
              errorMessage ? "visible" : "invisible"
            } `}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
