import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";

import { Email, Mobile, Password, Name } from "components/forms/";

import { toastNotify } from "global/helpers/Cache";

import { CREATE_USER } from "modules/User/services/mutations";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

interface SignUpDataType {
  name: string;
  personalEmail: string;
  isdCode: string;
  mobile: string;
  isActive: boolean;
  password: string;
  country: number;
}

const Signup = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [createUser, { loading }] = useMutation(CREATE_USER);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignUpDataType>();

  const onSubmit = (data: SignUpDataType) => {
    const isActive = true;
    if (!loading) {
      createUser({
        variables: {
          name: data.name.trim(),
          personalEmail: data.personalEmail,
          isdCode: data.country,
          mobile: data.mobile,
          isActive: isActive,
          password: data.password,
          userType: state?.selectUser ? "Employee" : "Client",
        },
      })
        .then(() => {
          navigate("/");
          toastNotify([
            {
              messageType: "success",
              message:
                "Congratulation, your account has been successfully created.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify(errorMessageNotify(error));
        });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div>
        <div className="md:bg-white md:shadow-[0px_0px_34px_#9BBCFF29] flex items-center flex-col rounded-lg py-10">
          <h2 className="heading">Create New Account</h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-2 p-10"
          >
            <Name
              register={register}
              errors={errors}
              label={"Name *"}
              name="name"
              required={true}
            />
            <Email
              register={register}
              errors={errors}
              label={"Email *"}
              name="personalEmail"
              required={true}
            />
            <Mobile
              register={register}
              errors={errors}
              required={true}
              label={"Mobile *"}
            />
            <Password
              register={register}
              errors={errors}
              label={"Password *"}
              name="password"
            />

            <div className="">
              <button className="button-design">
                {loading ? <div className="btn-spinner" /> : "Sign Up"}
              </button>
            </div>
          </form>
          <p className="text-black/70 text-sm">
            Already have an account?{" "}
            <Link to={"/"} className="text-cornflower-blue">
              Sign In
            </Link>
          </p>
        </div>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon "
        onClick={() => navigate("/signup-as")}
      />
    </div>
  );
};

export default Signup;
