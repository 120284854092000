import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Filled from "components/forms/Button/Filled";
import Pagination from "components/Pagination/Pagination";
import { SearchField } from "components/forms";
import Loading from "components/Loader/Loading";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import ConfirmModal from "components/forms/Modal/ConfirmModal";

import { toastNotify } from "global/helpers/Cache";
import { paginationDefaultValue } from "global/helpers/StaticData";
import { Capitalize } from "global/helpers/Capitalize";

import { IAllowedResources } from "modules/Organization/types/organization";
import { DELETE_DESIGNATION } from "modules/Organization/services/mutations";
import EditEmployeeDesignation from "modules/Organization/Pages/Designation/EditEmployeeDesignation";
import {
  DEPARTMENTS,
  EMPLOYEE_DESIGNATIONS,
} from "modules/Organization/services/queries";

const tableHeadings = [
  "Name",
  "Employee Department",
  "Is UnderHiring?",
  "Is Active?",
  "Max Experience",
  "Min Experience",
];

const Designation: FC<IAllowedResources> = ({ allowedResources }) => {
  const createAccess = allowedResources?.includes("CreateDesignation");
  const updateAccess = allowedResources?.includes("UpdateDesignation");
  const deleteAccess = allowedResources?.includes("DeleteDesignation");

  const [deleteLeaveType] = useMutation(DELETE_DESIGNATION);

  const [
    fetchEmployeeDepartments,
    { data: employeeDepartments, loading: departmentLoader },
  ] = useLazyQuery(DEPARTMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchDesignation, { data: employeeDesignations, loading, refetch }] =
    useLazyQuery(EMPLOYEE_DESIGNATIONS, {
      fetchPolicy: "cache-and-network",
    });

  const designationList =
    employeeDesignations?.employeeDesignations?.dataCollection || [];

  const { register, watch, setValue } = useForm();

  const designation = watch("designation");

  const [employeeDesignation, setEmployeeDesignation] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const [search, setSearch] = useState(designation || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(designation?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [designation]);

  useEffect(() => {
    fetchDesignation({
      variables: {
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [currentPage, pageSize, search, fetchDesignation]);

  useEffect(() => {
    if (showModal) {
      fetchEmployeeDepartments();
    }
  }, [fetchEmployeeDepartments, showModal]);

  const parentRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const deleteHandler = () => {
    if (employeeDesignation?.id) {
      deleteLeaveType({
        variables: {
          id: +employeeDesignation?.id,
        },
      })
        .then(() => {
          const designationName = employeeDesignation?.name;
          setDeleteVerification(false);
          refetch();
          toastNotify([
            {
              messageType: "success",
              message: `${
                designationName
                  ? `${designationName} designation`
                  : "Designation"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const kebabMenuEditHandler = () => {
    if (updateAccess) {
      setShowModal(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };

  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };

  const plusIconClickHandler = () => {
    setShowModal(true);
    setEmployeeDesignation(null);
  };
  const kebabMenuClickHandler = (employeeDesignation: any) => {
    setEmployeeDesignation(employeeDesignation);
  };
  const getDataModal = (employeeDesignation: any) => [
    {
      lable: "Name",
      value: [
        {
          content: Capitalize(employeeDesignation?.name),
        },
      ],
    },
    {
      lable: "Employee Department",
      value: [
        {
          content: employeeDesignation?.employeeDepartment?.name
            ? employeeDesignation?.employeeDepartment?.name
            : "Department",
        },
      ],
    },
    {
      lable: "Is UnderHiring?",
      value: [
        {
          content: employeeDesignation?.isUnderHiring ? "Yes" : "No",
        },
      ],
    },
    {
      lable: "Is Active?",
      value: [
        {
          content: employeeDesignation?.isActive ? "Yes" : "No",
        },
      ],
    },
    {
      lable: "Maximum Experience",
      value: [
        {
          content: employeeDesignation?.maxExpYears,
        },
      ],
    },
    {
      lable: "Minimum Experience",
      value: [
        {
          content: employeeDesignation?.minExpYears,
        },
      ],
    },
  ];
  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  return (
    <div>
      <div className="flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row md:pb-0 pt-5">
        <SearchField
          register={register}
          name="designation"
          label="Designation"
          onChange={() => {
            setCurrentPage(1);
          }}
          setValue={setValue}
        />
      </div>
      {loading ? (
        <Loading className="min-h-[70vh]" />
      ) : (
        <Fragment>
          {employeeDesignations &&
          designationList?.length === 0 &&
          !showModal &&
          search?.length <= 0 ? (
            <div className="md:min-h-[694px] min-h-[440px]">
              <div className="flex justify-center items-center h-[500px]">
                {createAccess ? (
                  <Filled
                    buttonName={"Add"}
                    onClick={() => {
                      setShowModal(!showModal);
                      setEmployeeDesignation(null);
                    }}
                  />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="md:min-h-[694px] min-h-[440px]">
              <div className="hidden md:block overflow-x-auto">
                <Table
                  loading={loading}
                  noOfItems={employeeDesignations && designationList?.length}
                  tableHeadingsObj={tableHeadings?.map((name: string) => {
                    return {
                      name,
                      center: name === "Name" ? false : true,
                    };
                  })}
                  ref={parentRef}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPlusIconClick={plusIconClickHandler}
                  className="py-5 min-h-[600px]"
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  totalCount={
                    employeeDesignations?.employeeDesignations
                      ? employeeDesignations?.employeeDesignations
                          ?.totalNumberOfItems
                      : 0
                  }
                  createAccess={createAccess}
                >
                  {designationList?.map((employeeDesignation: any) => {
                    let tableContent = [
                      { value: employeeDesignation?.name },
                      {
                        value: employeeDesignation?.employeeDepartment?.name,
                        center: true,
                      },
                      {
                        value: employeeDesignation?.isUnderHiring
                          ? "Yes"
                          : "No",
                        center: true,
                      },
                      {
                        value: employeeDesignation?.isActive ? "Yes" : "No",
                        center: true,
                      },
                      { value: employeeDesignation?.maxExpYears, center: true },
                      { value: employeeDesignation?.minExpYears, center: true },
                    ];
                    return (
                      <TableContent
                        key={employeeDesignation?.id}
                        id={employeeDesignation?.id}
                        tableContent={tableContent}
                        editAccess={updateAccess || deleteAccess}
                        onEdit={kebabMenuEditHandler}
                        showModal={showModal || deleteVerification}
                        onDelete={kebabMenuDeleteHandler}
                        onMenuClick={() => {
                          kebabMenuClickHandler(employeeDesignation);
                        }}
                        parentRef={parentRef}
                      />
                    );
                  })}
                </Table>
              </div>
              <div className="md:hidden">
                <div className="rounded-lg mt-[20px] w-full">
                  <div className="w-full flex justify-center">
                    {createAccess && search?.length <= 0 ? (
                      <button
                        className="w-[150px] lg:w-[120px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded"
                        onClick={() => {
                          setShowModal(true);
                          setEmployeeDesignation(null);
                        }}
                      >
                        Add
                      </button>
                    ) : null}
                  </div>
                  <div className="lg:px-[45px] bg-white mt-[20px] md:mt-[30px]">
                    {designationList?.map(
                      (employeeDesignation: any, index: number) => {
                        return (
                          <SMDataCard
                            key={employeeDesignation?.id}
                            title={`Designation ${index + 1}`}
                            showModal={deleteVerification || showModal}
                            loading={loading}
                            dataModal={getDataModal(employeeDesignation)}
                            onKebabIcon={{
                              onclick: () => {
                                kebabMenuClickHandler(employeeDesignation);
                              },
                              kebabMenu: {
                                onEdit: kebabMenuEditHandler,
                                onDelete: kebabMenuDeleteHandler,
                              },
                            }}
                          />
                        );
                      }
                    )}
                    <Pagination
                      totalCount={
                        employeeDesignations?.employeeDesignations
                          ?.totalNumberOfItems || 0
                      }
                      currentPage={currentPage}
                      setPageSize={setPageSize}
                      siblingCount={1}
                      pageSize={pageSize}
                      onPageChange={(page: number) => setCurrentPage(page)}
                      noOfItem={designationList?.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
      {showModal ? (
        <EditEmployeeDesignation
          refetch={refetch}
          employeeDepartments={employeeDepartments}
          employeeDesignation={employeeDesignation}
          setShowModal={setShowModal}
          loading={departmentLoader}
          inProgress={inProgress}
          setInProgress={setInProgress}
        />
      ) : null}
      {deleteVerification ? (
        <ConfirmModal
          header={"Designation"}
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
        />
      ) : null}
    </div>
  );
};

export default Designation;
