import { gql } from "@apollo/client";

export const PROJECT_GROUP_BUG_FRAGMENT = gql`
  fragment projectGroupBugFields on ProjectGroupBug {
    description
    id
    platform
    priority
    status
    source {
      id
      name
    }
    title
    attachments {
      fileUrl
      id
    }
    projectBacklogs {
      dataCollection {
        project {
          id
          name
        }
      }
    }
  }
`;
