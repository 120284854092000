import { FC, useEffect, useMemo } from "react";
import {
	useLazyQuery,
	useMutation,
	useQuery,
	useReactiveVar,
} from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Input from "components/forms/UpdatedForm/Input";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import Select from "components/forms/UpdatedForm/Select";
import { Filled, Outlined } from "components/App/Buttons";
import ToggleSwitchWithLabel from "components/forms/UpdatedForm/ToggleSwitch/ToggleSwitchWithLabel";

import { IIdAndName } from "global/types/type";
import { setTitle, toastNotify, userContextData } from "global/helpers/Cache";

import { CREATE_OR_UPDATE_PROJECT } from "modules/Project/services/mutations";
import { IProject, IProjectForm } from "modules/Project/types/project";
import { GET_ALL_EMPLOYEES_DROP_DOWN } from "modules/Employee/services/queries";
import {
	PROJECT_LABELS,
	PROJECT_SETTINGS_COLUMNS,
} from "modules/Project/services/queries";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import ProjectGroupField from "modules/Project/Pages/Projects/ProjectGroups/helpers/ProjectGroupField";
import { FILTER_PROJECT_MEMBERS } from "modules/Project/Pages/Members/services/queries";
import { notEmpty, removeDuplicateObject } from "global/helpers/ArrayMethods";

const ProjectsGeneral: FC<{
	editProject?: IProject | undefined;
	allowedResources: string[];
	projectGroup?: ProjectGroup | null;
}> = ({ editProject, allowedResources, projectGroup }) => {
	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm<IProjectForm>();

	const userDataList: any = useReactiveVar(userContextData);
	const isManager = editProject?.manager?.id === userDataList?.user?.id;

	const [fetchLabels, { data: getProjectLabels }] =
		useLazyQuery(PROJECT_LABELS);

	const [fetchProjectColumns, { data: getProjectColumns }] = useLazyQuery(
		PROJECT_SETTINGS_COLUMNS,
	);

	useEffect(() => {
		if (!editProject?.id) {
			fetchLabels();
		}
	}, [editProject?.id, fetchLabels]);

	useEffect(() => {
		if (editProject?.projectGroup?.id && editProject?.projectGroup?.name) {
			setValue("projectGroup", {
				id: +editProject?.projectGroup?.id,
				label: editProject?.projectGroup?.name,
			});
		}
	}, [
		editProject?.projectGroup?.id,
		editProject?.projectGroup?.name,
		setValue,
	]);

	useEffect(() => {
		if (!editProject?.id) {
			fetchProjectColumns();
		}
	}, [editProject?.id, fetchProjectColumns]);

	const projectColumnsList = getProjectColumns?.columns?.dataCollection || [];

	const projectLabelsList =
		getProjectLabels?.projectLabels?.dataCollection || [];

	const navigate = useNavigate();
	const canCreateAndUpdateProject = allowedResources.includes("SaveProject");

	const { pathname } = useLocation();
	const isMyProjectPathName = pathname?.split("/")[3];
	const { id, groupId } = useParams();

	useEffect(() => {
		if (editProject?.name) {
			setTitle(
				groupId
					? [
							{ name: "Project", link: "/projects/groups" },
							{ name: "Groups", link: "/projects/groups" },
							{
								name: projectGroup?.name || "",
								link: `/projects/${projectGroup?.id}/${isMyProjectPathName}`,
							},
							{
								name: editProject?.name || "",
								link: `/projects/${projectGroup?.id}/${isMyProjectPathName}/edit/${id}/general`,
							},
							{
								name: "General",
								link: `/projects/${projectGroup?.id}/${isMyProjectPathName}/edit/${id}/general`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: editProject?.name || "",
								link: `/projects/projects/${isMyProjectPathName}/edit/${id}/general`,
							},
							{
								name: "General",
								link: `/projects/projects/${isMyProjectPathName}/edit/${id}/general`,
							},
					  ],
			);
		} else {
			setTitle(
				groupId
					? [
							{ name: "Project", link: "/projects/groups" },
							{
								name: projectGroup?.name || "",
								link: `/projects/${projectGroup?.id}/${isMyProjectPathName}`,
							},
							{
								name: "General",
								link: `/projects/${projectGroup?.id}/${isMyProjectPathName}/edit/${id}/general`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: "General",
								link: `/projects/projects/${isMyProjectPathName}/edit/${id}/general`,
							},
					  ],
			);
		}
	}, [
		editProject?.name,
		id,
		isMyProjectPathName,
		projectGroup?.id,
		projectGroup?.name,
		groupId,
	]);

	const [saveProject, { loading: projectLoading }] = useMutation(
		CREATE_OR_UPDATE_PROJECT,
	);

	const { data: allEmployees } = useQuery(GET_ALL_EMPLOYEES_DROP_DOWN, {
		fetchPolicy: "cache-and-network",
	});

	const { data: filterProjectMembers } = useQuery(FILTER_PROJECT_MEMBERS, {
		fetchPolicy: "cache-and-network",
		variables: {
			filters: {
				projectId: {
					number: Number(id),
				},
			},
		},
	});

	const employeeOptions: IIdAndName[] = useMemo(
		() =>
			allEmployees?.getAllEmployeesForDropDown?.map(
				(employee: { personalInformation: { id: number; name: string } }) =>
					employee?.personalInformation,
			) || [],
		[allEmployees?.getAllEmployeesForDropDown],
	);

	const projectMemberOptions = useMemo(
		() =>
			filterProjectMembers?.filterProjectMembers?.edges &&
			filterProjectMembers?.filterProjectMembers?.edges?.length > 0
				? filterProjectMembers?.filterProjectMembers?.edges
						?.map((member) =>
							member?.node?.user?.personalInformation?.id &&
							member?.node?.user?.personalInformation?.name
								? {
										id: member?.node?.user?.personalInformation?.id,
										name: member?.node?.user?.personalInformation?.name,
								  }
								: null,
						)
						?.filter(notEmpty) || []
				: [],
		[filterProjectMembers?.filterProjectMembers?.edges],
	);

	const nonProjectMemberOption =
		employeeOptions?.filter(
			(employee) =>
				employee?.id === editProject?.manager?.personalInformation?.id,
		) || [];

	const createOrUpdateProjectHandler: SubmitHandler<IProjectForm> = ({
		name,
		projectKey,
		category,
		status,
		isArchived,
		manager,
		label,
		column,
		isTrashed,
		projectGroup,
	}) => {
		if (!projectLoading) {
			saveProject({
				variables: {
					project: {
						id: editProject?.id || undefined,
						name,
						projectKey,
						manager: manager?.id,
						category,
						status,
						isArchived,
						labelIds: label?.map((label: { id: number }) => label?.id),
						columnIds: column?.map((column: { id: number }) => column?.id),
						isTrashed: isTrashed ? false : undefined,
						projectGroupId:
							groupId && id === "new"
								? +groupId
								: projectGroup?.id
								  ? +projectGroup?.id
								  : null,
					},
				},
			})
				.then((response) => {
					const projectName = response?.data?.saveProject?.name;
					if (editProject?.id) {
						navigate(
							`/projects/${
								groupId ? `${groupId}` : "projects"
							}/${isMyProjectPathName}/edit/${
								response?.data?.saveProject?.id
							}/general`,
						);
					} else {
						navigate(
							`/projects/${
								groupId ? `${groupId}` : "projects"
							}/${isMyProjectPathName}`,
						);
					}
					toastNotify([
						{
							messageType: "success",
							message: `${
								projectName ? `${projectName} project` : "Project"
							} has been ${
								editProject?.id ? "updated" : "created"
							} successfully.`,
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]);
				});
		}
	};

	return (
		<form
			onSubmit={handleSubmit(createOrUpdateProjectHandler)}
			className="mt-5 flex justify-center min-h-[75vh]"
		>
			<div className="max-w-[500px] grid grid-cols-1 h-min gap-20">
				<div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-5 gap-1 h-min">
					<Input
						register={register}
						errors={errors}
						label="Name *"
						name="name"
						required
						defaultValue={editProject?.name || ""}
						setValue={setValue}
					/>
					<Input
						register={register}
						errors={errors}
						label="Project key *"
						name="projectKey"
						required
						defaultValue={editProject?.projectKey}
						setValue={setValue}
						pattern={{
							value: /^[a-zA-Z0-9]+$/,
							message: "Only alphanumeric characters are allowed",
						}}
					/>
					<AutoComplete
						control={control}
						label="Manager *"
						name={"manager"}
						options={
							editProject?.id
								? removeDuplicateObject(
										[...projectMemberOptions, ...nonProjectMemberOption],
										"id",
								  )
								: employeeOptions
						}
						errors={errors}
						setValue={setValue}
						defaultValue={
							editProject && {
								id: editProject?.manager?.personalInformation?.id,
								name: editProject?.manager?.personalInformation?.name,
							}
						}
						required
					/>
					<Select
						control={control}
						errors={errors}
						label="Category *"
						name="category"
						options={[
							"Client Projects",
							"Client Products",
							"OX Projects",
							"OX Products",
							"M2 Products",
							"M2 Themes",
							"M2 Extensions",
						]}
						defaultValue={editProject?.category}
						setValue={setValue}
						required
					/>
					<Select
						control={control}
						errors={errors}
						label="Status *"
						name="status"
						options={[
							"Under Discussion",
							"In Progress",
							"On Hold",
							"Completed",
							"Rejected",
						]}
						defaultValue={editProject?.status}
						required
						setValue={setValue}
					/>
					{!groupId && (
						<ProjectGroupField
							control={control}
							name="projectGroup"
							label="Group"
							className="bg-white"
						/>
					)}
					{editProject?.id && isMyProjectPathName !== "trash" && (
						<ToggleSwitchWithLabel
							label={"Archive"}
							register={register}
							name={"isArchived"}
							defaultValue={editProject?.isArchived}
							setValue={setValue}
							className="h-min pb-5 sm:pb-0"
						/>
					)}
					{editProject?.id && isMyProjectPathName === "trash" && (
						<ToggleSwitchWithLabel
							label={"Move to projects"}
							register={register}
							name={"isTrashed"}
							defaultValue={editProject?.isArchived}
							setValue={setValue}
							className="h-min pb-5 sm:pb-0"
						/>
					)}
					{!editProject?.id && (
						<>
							<AutoComplete
								control={control}
								label="Label"
								name={"label"}
								options={projectLabelsList}
								errors={errors}
								setValue={setValue}
								defaultValue={projectLabelsList?.map(
									(label: { name: string; id: number }) => {
										return {
											id: label?.id,
											name: label?.name,
										};
									},
								)}
								multiple
							/>
							<AutoComplete
								control={control}
								label="Column"
								name={"column"}
								options={projectColumnsList}
								errors={errors}
								setValue={setValue}
								defaultValue={projectColumnsList?.map(
									(label: { name: string; id: number }) => {
										return {
											id: label?.id,
											name: label?.name,
										};
									},
								)}
								multiple
							/>
						</>
					)}
				</div>
				<div className="flex justify-between mb-4">
					<Outlined
						buttonName={"Cancel"}
						onClick={() =>
							navigate(
								`/projects/${
									groupId ? `${groupId}` : "projects"
								}/${isMyProjectPathName}`,
							)
						}
					/>
					<Filled
						disabled={!canCreateAndUpdateProject && !isManager}
						buttonName={"Submit"}
						loading={projectLoading}
					/>
				</div>
			</div>
		</form>
	);
};

export default ProjectsGeneral;
