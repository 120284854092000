import { useState } from "react";
import { useMutation } from "@apollo/client";

import { ReactComponent as PencilWhiteIcon } from "global/assets/images/pencil-white-icon.svg";
import { ReactComponent as EmailIcon } from "global/assets/images/email-icon.svg";
import { ReactComponent as BirthdayIcon } from "global/assets/images/birthday-icon.svg";
import { ReactComponent as ReadTickIcon } from "global/assets/images/read-tick-icon.svg";
import { ReactComponent as UnReadTickIcon } from "global/assets/images/unread-tick-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";

import { UPDATE_NOTIFICATION_STATUS } from "../../services/mutations";
import { MessageType } from "./AllMessages";
import { Link } from "react-router-dom";

const MessageDetails: React.FC<{
	message: MessageType;
	refetchMsg: Function;
}> = ({ message, refetchMsg }) => {
	const [inProgress, setInProgress] = useState(false);
	const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);
	const clickHandler = () => {
		if (!inProgress) {
			updateNotificationStatus({
				variables: {
					id: [+message.id],
					isRead: !message.isRead,
				},
			})
				.then(() => {
					setInProgress(false);
					refetchMsg();
				})
				.catch((error) => {
					setInProgress(false);
					console.error(error?.message);
				});
			setInProgress(!inProgress);
		}
	};

	const profileImage =
		message?.triggeredByUser?.userProfileImages?.filter(
			(userProfileImage: any) => userProfileImage?.type === "Profile",
		)[0] || null;

	const notificationReadHandler = () => {
		updateNotificationStatus({
			variables: {
				id: +message.id ? [+message.id] : [0],
				isRead: true,
			},
		});
	};

	return (
		<div className="flex flex-row border-b gap-3 sm:items-center justify-between mt-[23px] pb-1">
			<div className="flex">
				<div
					className={`min-w-[32px] h-[32px] rounded-full flex justify-center items-center ${
						message.message?.includes("email") ? "bg-[#f16865]" : null
					} ${message.message?.includes("updated") ? "bg-[#43c7a2]" : null} ${
						message.message?.includes("email") ||
						message.message?.includes("updated")
							? null
							: "bg-[#92baff]"
					}`}
				>
					{(() => {
						if (profileImage?.documents) {
							if (profileImage?.documents[0]?.file?.length > 100) {
								return (
									<img
										src={Base64toObject(profileImage?.documents[0]?.file)}
										className="w-8 h-8 rounded-full text-[12px]"
										alt={`${message?.triggeredByUser?.personalInformation?.name}`}
									/>
								);
							}
						} else {
							if (message.message?.includes("updated")) {
								return <PencilWhiteIcon />;
							}
							if (message.message?.includes("email")) {
								return <EmailIcon />;
							}
							if (message.message?.includes("Birthday")) {
								return <BirthdayIcon />;
							}
							if (message.message?.includes(""))
								return (
									<span className="text-[12px] leading-[15px] text-white font-semibold">
										OPTA
									</span>
								);
						}
					})()}
				</div>
				<div className="ml-[15px] md:ml-[22px] w-full space-y-[10px]">
					<p
						className={`${
							message?.link ? "hover:underline" : ""
						} text-sm text-ironside-gray`}
						onClick={() => {
							!message?.isRead && notificationReadHandler();
						}}
					>
						<Link
							to={message?.link}
							className={
								message?.link ? "cursor-pointer text-cornflower-blue" : ""
							}
						>
							{message?.message}
						</Link>
					</p>
				</div>
			</div>
			<div className="flex items-center gap-2">
				<p className="truncate text-[10px] leading-[14px] md:text-[12px] md:leading-[15px] text-[#BBBBBB] space-x-[15px]">
					<span>{message.time}</span>
				</p>
				{inProgress ? (
					<div className="w-7 h-7 flex justify-center items-center">
						<span className="block w-4 h-4 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
					</div>
				) : message?.isRead ? (
					<div
						onClick={() => {
							setInProgress(!inProgress);
							clickHandler();
						}}
						className="w-7 h-7 flex cursor-pointer hover:border hover:bg-gray-100 hover:rounded-full justify-center items-center"
					>
						<ReadTickIcon className="cursor-pointer" />
					</div>
				) : (
					<div
						onClick={() => {
							setInProgress(!inProgress);
							clickHandler();
						}}
						className="w-7 h-7 flex cursor-pointer hover:border hover:bg-gray-100 hover:rounded-full justify-center items-center"
					>
						<UnReadTickIcon className="cursor-pointer" />
					</div>
				)}
			</div>
		</div>
	);
};

export default MessageDetails;
