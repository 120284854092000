import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { toastNotify } from "global/helpers/Cache";

import { Input, Select, Time } from "components/forms/UpdatedFormAgry";
import EditForm from "components/EditForm/UpdatedEditFormAgry/EditForm";

import { UpdateQueryType } from "global/types/type";
import useAllowedResources from "global/hooks/useAllowedResources";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import { convertHoursToTwelveHour } from "global/helpers/TimeFormat";

import {
  CompanyFormType,
  FilterCompaniesArgs,
  FilterCompaniesReturnType,
} from "modules/Company/types";
import { CREATE_COMPANY } from "modules/Company/services";
import { companyStatuses, companyTypes } from "modules/Company/helpers/utils";

interface Props {
  onClose: () => void;
  updateQuery: UpdateQueryType<FilterCompaniesReturnType, FilterCompaniesArgs>;
}

const AddCompany: FC<Props> = ({ onClose, updateQuery }) => {
  const allowedResources = useAllowedResources();
  const canCreate = allowedResources?.includes("CreateCompany");

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CompanyFormType>({
    defaultValues: {
      openingTime: null,
      closingTime: null,
    },
  });

  const [createCompany, { loading: createCompanyLoading }] =
    useMutation(CREATE_COMPANY);

  const submitHandler: SubmitHandler<CompanyFormType> = ({
    name,
    companyType,
    status,
    closingTime,
    companyOwners,
    openingTime,
  }) => {
    const openingTimeFormatted =
      openingTime !== null && openingTime !== undefined
        ? `${convertHoursToTwelveHour(openingTime?.hour)}:${
            openingTime?.minute < 9
              ? `0${openingTime?.minute}`
              : openingTime?.minute
          } ${openingTime?.hour > 11 ? "PM" : "AM"}`
        : null;

    const closingTimeFormatted =
      closingTime !== null && closingTime !== undefined
        ? `${convertHoursToTwelveHour(closingTime?.hour)}:${
            closingTime?.minute < 9
              ? `0${closingTime?.minute}`
              : closingTime?.minute
          } ${closingTime?.hour > 11 ? "PM" : "AM"}`
        : null;

    if (!createCompanyLoading) {
      createCompany({
        variables: {
          name: name?.trim() || null,
          companyType,
          status,
          openingTime: openingTimeFormatted || undefined,
          closingTime: closingTimeFormatted || undefined,
          companyOwnerIds:
            companyOwners && companyOwners?.length > 0
              ? companyOwners?.map((owner) => owner?.id)
              : undefined,
          isCompanyTypeNeed: true,
          isStatusNeed: true,
          isOpeningTimeNeed: true,
          isClosingTimeNeed: true,
          isCompanyOwnersNeed: true,
        },
      })
        .then(({ data }) => {
          if (data && data?.createCompany && data?.createCompany?.id) {
            const id = data?.createCompany?.id;
            onClose();
            toastNotify([
              {
                message: "Company added successfully.",
                messageType: "success",
              },
            ]);
            updateQuery(({ filterCompanies }) => {
              return {
                filterCompanies: filterCompanies
                  ? {
                      pageInfo: filterCompanies?.pageInfo
                        ? {
                            ...filterCompanies?.pageInfo,
                            totalNumberOfItems: filterCompanies?.pageInfo
                              ?.totalNumberOfItems
                              ? filterCompanies?.pageInfo?.totalNumberOfItems +
                                1
                              : 1,
                          }
                        : null,
                      edges:
                        filterCompanies?.edges &&
                        filterCompanies?.edges?.length > 0
                          ? [
                              {
                                node: {
                                  ...data?.createCompany,
                                  id,
                                },
                              },
                              ...filterCompanies.edges,
                            ]
                          : [
                              {
                                node: {
                                  ...data?.createCompany,
                                  id,
                                },
                              },
                            ],
                      __typename: filterCompanies?.__typename,
                    }
                  : null,
              };
            });
          } else {
            toastNotify([
              {
                message: "Something went wrong.",
                messageType: "error",
              },
            ]);
          }
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    }
  };

  return (
    <EditForm
      title={"Company"}
      handleSubmit={handleSubmit}
      onSubmit={submitHandler}
      outlineBtnName={"Cancel"}
      filledBtnName="Add"
      onClose={onClose}
      filledBtnLoading={createCompanyLoading}
      filledBtnDisable={isValid ? !canCreate : true}
    >
      <Input name="name" control={control} label="Name *" required />
      <Select
        name="companyType"
        control={control}
        options={companyTypes}
        label="Company type *"
      />
      <Select
        name="status"
        control={control}
        options={companyStatuses}
        label="Status *"
      />
      <Time control={control} label="Opening time" name="openingTime" />
      <Time control={control} label="Closing time" name="closingTime" />
    </EditForm>
  );
};

export default AddCompany;
