import { ReactComponent as OXlogo } from "global/assets/images/ox-logo.svg";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <OXlogo
      className="w-[45px] h-[24px] lg:w-[74px] lg:h-[28px] ml-[15px] lg:ml-[30px] cursor-pointer"
      onClick={() => {
        navigate("/profile/personal");
      }}
    />
  );
};

export default Logo;
