import { useState, FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import TextArea from "components/forms/UpdatedForm/TextArea";
import { Filled, Outlined } from "components/forms";

import { SAVE_PROJECT_TASK_DESCRIPTION } from "modules/Project/services/mutations";
import { toastNotify } from "global/helpers/Cache";

interface IProps {
	taskDescription: string;
	canSaveDescription: boolean;
}

const TaskDescription: FC<IProps> = ({
	taskDescription,
	canSaveDescription,
}) => {
	const [
		saveProjectTaskDescription,
		{ loading: projectTaskDescriptionLoading },
	] = useMutation(SAVE_PROJECT_TASK_DESCRIPTION);

	const {
		register: registerTaskDescription,
		setValue: setValueTaskDescription,
		formState: { errors: errorsTaskDescription },
		handleSubmit: handleSubmittaskDescription,
		setError: setTaskDescriptionError,
	} = useForm<{ description: string }>();

	const { id, issueId } = useParams();

	const [showDescription, setShowDescription] = useState<boolean>(false);
	const [description, setDescription] = useState(taskDescription);

	useEffect(() => {
		setDescription(taskDescription);
	}, [taskDescription]);

	const taskDescriptionHandler = (data: { description: string }) => {
		if (data?.description?.trim()?.length === 0) {
			setTaskDescriptionError("description", {
				type: "custom",
				message: "Task Description should not be empty",
			});
			return;
		}

		if (
			!projectTaskDescriptionLoading &&
			id &&
			!isNaN(+id) &&
			issueId &&
			!isNaN(+issueId)
		) {
			saveProjectTaskDescription({
				variables: {
					projectTaskInput: {
						id: +issueId,
						projectId: +id,
						description: data?.description,
					},
				},
			})
				.then((res) => {
					setDescription(res?.data?.saveProjectTask?.description);
					setShowDescription(false);
					toastNotify([
						{
							messageType: "success",
							message: "Description is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				});
		}
	};

	return (
		<>
			<p className="font-medium mb-[2px] text-[14px] leading-[17px] text-[#612224]">
				Description
			</p>
			<div>
				{!showDescription && (
					<div
						className={`col-span-2 ${
							canSaveDescription ? "hover:bg-[#f2f2f2]" : ""
						} ${
							canSaveDescription ? "cursor-pointer " : ""
						} py-2 mb-10 relative right-3`}
						onClick={() => {
							if (canSaveDescription) setShowDescription(true);
						}}
					>
						<p className="ml-3">
							{description ||
								`${
									canSaveDescription ? "Add a description..." : "No description"
								}`}
						</p>
					</div>
				)}
				{showDescription && canSaveDescription && (
					<form onSubmit={handleSubmittaskDescription(taskDescriptionHandler)}>
						<TextArea
							errors={errorsTaskDescription}
							label="Description"
							name="description"
							register={registerTaskDescription}
							setValue={setValueTaskDescription}
							defaultValue={description}
							className="mt-5 pb-0 col-span-2"
						/>
						<div className="flex gap-3 items-center mb-6">
							<Outlined
								buttonName="Cancel"
								onClick={() => setShowDescription(false)}
							/>
							<Filled
								buttonName="Save"
								loading={projectTaskDescriptionLoading}
								disabled={projectTaskDescriptionLoading}
							/>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default TaskDescription;
