import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import {
  Filled,
  Modal,
  Outlined,
  Select,
  Text,
  TextArea,
  FileDragAndDrop,
} from "components/forms";

import {
  currentDate,
  degreeTypes,
  months,
  schoolBoards,
} from "global/helpers/StaticData";
import { MdOutlineClose } from "react-icons/md";
import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";
import { fileToBase64 } from "global/helpers/FileConverter";
import { getArrayObjectDifference } from "global/helpers/ArrayMethods";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  CREATE_ACADEMIC_DETAILS,
  UPDATE_ACADEMIC_DETAILS,
} from "modules/Employee/services/mutations";
import {
  IFileProperties,
  IAcademicCertificate,
  IAcademicDetail,
  IAcademicDetailsForm,
} from "modules/Employee/types/academic";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  academicDetails: IAcademicDetail[];
  editID: number | null;
  setEditID: Function;
  setShowModal: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetchAcademicDetails: Function;
}

const EditEducationDetails: FC<IProps> = ({
  academicDetails,
  editID,
  setEditID,
  setShowModal,
  inProgress,
  setInProgress,
  refetchAcademicDetails,
}) => {
  const isUnderModeration = useIsUnderModeration();

  const editAcademicDetails = academicDetails?.filter(
    (academicDetatil: IAcademicDetail) => academicDetatil?.id === editID
  )[0];

  const moderationObj = editAcademicDetails?.moderation;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm<IAcademicDetailsForm>();

  useEffect(() => {
    if (editAcademicDetails?.department) {
      setValue("department", editAcademicDetails?.department);
    }
    if (editAcademicDetails?.instituteName) {
      setValue("instituteName", editAcademicDetails?.instituteName);
    }
    if (editAcademicDetails?.affiliatedTo) {
      setValue("board", editAcademicDetails?.affiliatedTo);
    }
    if (editAcademicDetails?.affiliatedTo) {
      setValue("affiliatedTo", editAcademicDetails?.affiliatedTo);
    }
    if (editAcademicDetails?.startMonth) {
      setValue("startMonth", editAcademicDetails?.startMonth);
    }
    if (editAcademicDetails?.startYear) {
      setValue("startYear", editAcademicDetails?.startYear);
    }
    if (editAcademicDetails?.endMonth) {
      setValue("endMonth", editAcademicDetails?.endMonth);
    }
    if (editAcademicDetails?.endYear) {
      setValue("endYear", editAcademicDetails?.endYear);
    }
    if (editAcademicDetails?.percentage) {
      setValue("percentage", editAcademicDetails?.percentage);
    }
    if (editAcademicDetails?.additionalDetails) {
      setValue("additionalDetails", editAcademicDetails?.additionalDetails);
    }
  }, [editAcademicDetails, setValue]);

  const [fileList, setFileList] = useState<IFileProperties[]>([]);
  const [updatedFiles, setUpdatedFiles] = useState<IAcademicCertificate[]>([]);
  const [checkbox, setCheckbox] = useState<boolean>(
    editAcademicDetails?.isCurrentlyPursuing
      ? editAcademicDetails?.isCurrentlyPursuing
      : false
  );
  const [selectedValue, setSelectedValue] = useState<string>(
    editAcademicDetails?.degreeType ? editAcademicDetails?.degreeType : ""
  );
  const [submittedFiles, setSubmittedFiles] = useState<IAcademicCertificate[]>(
    []
  );

  const [createUserAcademicDetail] = useMutation(CREATE_ACADEMIC_DETAILS);
  const [updateUserAcademicDetail] = useMutation(UPDATE_ACADEMIC_DETAILS);

  useEffect(() => {
    setSubmittedFiles(
      editAcademicDetails?.userAcademicCertificates?.map(
        (certificate: IAcademicCertificate) => {
          return {
            id: +certificate?.id,
            filePath: certificate?.filePath,
            certificateName: certificate?.certificateName,
          };
        }
      ) || []
    );
  }, [editAcademicDetails?.userAcademicCertificates]);

  const currentFiles: string[] = [];

  fileList?.map((fileDetails: { size: number }, index: number) => {
    if (fileDetails?.size < 2e6)
      fileToBase64(fileDetails, (err: any, result: string) => {
        if (result) {
          currentFiles[index] = result;
        }
      });
    return <></>;
  });

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Education detail")
    : createSuccessMessageWithAwaiting("Education detail");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Education detail")
    : updateSuccessMessageWithAwaiting("Education detail");

  useEffect(() => {
    setUpdatedFiles(submittedFiles);
  }, [editAcademicDetails?.userAcademicCertificates?.length, submittedFiles]);

  const createHandler = (data: any) => {
    const affiliatedTo: string =
      data.degreeType === "Secondary" || data.degreeType === "Higher Secondary"
        ? data.board
        : data.affiliatedTo;

    if (!inProgress) {
      createUserAcademicDetail({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          instituteName: data.instituteName || null,
          affiliatedTo: affiliatedTo || null,
          degreeType: data.degreeType || null,
          department: data.department || null,
          startMonth: data.startMonth || null,
          startYear: +data.startYear || null,
          isCurrentlyPursuing: checkbox || null,
          additionalDetails: data.additionalDetails || null,
          endMonth: checkbox ? undefined : data.endMonth || null,
          endYear: checkbox ? undefined : +data.endYear || null,
          percentage: checkbox ? undefined : +data.percentage || null,
          userAcademicCertificates:
            currentFiles?.length > 0
              ? currentFiles?.map((file: string, index: number) => {
                  return {
                    certificateName: data[`certificateName${index + 1}`],
                    filePath: file,
                  };
                })
              : undefined,
        },
      })
        .then(() => {
          refetchAcademicDetails();
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: any) => {
    const removedMembers = getArrayObjectDifference(
      submittedFiles,
      updatedFiles
    )?.map((file: { id: number }) => file?.id);

    const affiliatedTo: string =
      editAcademicDetails?.degreeType === "Secondary" ||
      editAcademicDetails?.degreeType === "Higher Secondary" ||
      data.degreeType === "Secondary" ||
      data.degreeType === "Higher Secondary"
        ? data.board
        : data.affiliatedTo;

    if (data.startYear && data.endYear && +data.startYear >= +data.endYear) {
      setError("endYear", {
        type: "manual",
        message: "Please enter the valid end year.",
      });
    } else if (!checkbox && data.percentage > 99.99) {
      setError("percentage", {
        type: "manual",
        message: "Please enter the valid percentage.",
      });
    } else {
      if (!inProgress) {
        updateUserAcademicDetail({
          variables: {
            id: +editAcademicDetails?.id,
            instituteName: data.instituteName,
            affiliatedTo: affiliatedTo,
            degreeType: editAcademicDetails?.degreeType,
            department: data.department,
            startMonth: data.startMonth || null,
            startYear: +data.startYear || null,
            isCurrentlyPursuing: checkbox,
            additionalDetails: data.additionalDetails,
            endMonth: checkbox ? null : data.endMonth || null,
            endYear: checkbox ? null : +data.endYear,
            percentage: checkbox ? null : +data.percentage,
            userAcademicCertificates:
              currentFiles?.length > 0
                ? currentFiles?.map((file: string) => {
                    return {
                      certificateName:
                        data[`certificateName${updatedFiles?.length + 1}`],
                      filePath: file,
                    };
                  })
                : undefined,
            userAcademicCertificateIdsToDelete:
              removedMembers?.length > 0 ? removedMembers : [],
          },
        })
          .then(() => {
            refetchAcademicDetails();
            moduleCloseHandler();
            toastNotify(updateActionSuccessMessage);
          })
          .catch((error) => {
            setInProgress(false);
            toastNotify(errorMessageNotify(error));
          });
        setInProgress(!inProgress);
      }
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setEditID(null);
  };

  const yearData: string[] = [];

  const tempFullYear = currentDate.getFullYear();
  for (let i = tempFullYear; i >= 1980; i--) {
    yearData.push(i.toString());
  }

  const watchedValue = watch("degreeType");

  useEffect(() => {
    setSelectedValue(watchedValue);
  }, [watchedValue]);

  const certificateNameArray: any = [];

  for (
    let i = updatedFiles?.length + 1;
    i <= updatedFiles?.length + fileList?.length;
    i++
  ) {
    certificateNameArray?.push(
      <Text
        key={i}
        register={register}
        errors={errors}
        label={`Certificate Name ${i} *`}
        name={`certificateName${i}`}
        required={true}
        maximumLength={50}
      />
    );
  }

  const existingCertificateNameArray: any = [];

  for (let i = 1; i <= updatedFiles?.length; i++) {
    existingCertificateNameArray?.push(
      <Text
        key={i}
        register={register}
        errors={errors}
        label={`Certificate Name ${i} *`}
        name={`existingCertificateName${i}`}
        defaultText={
          updatedFiles?.length
            ? updatedFiles[i - 1]?.certificateName
            : undefined
        }
        disable={true}
        maximumLength={50}
      />
    );
  }

  const moderationFieldProps = {
    refetchQuery: refetchAcademicDetails,
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] pr-[28px] lg:pl-[45px] lg:pr-[57px] boder-hit-gray">
          <h2 className="edit-modal-heading">Educational Details</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form
          onSubmit={
            editAcademicDetails?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
        >
          <div className="pr-[35px] form-div lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.degreeType}
            >
              <div className="w-full">
                <div
                  className={`sm:block relative flex shadow items-center justify-between transition-all w-full h-[52px] border ${
                    errors.degreeType ? "border-[#FF647C]" : "border-[#E5E5E5]"
                  }  rounded-[4px] ${
                    errors.degreeType
                      ? "focus-within:border-[#FF647C]"
                      : "focus-within:border-cornflower-blue"
                  }   font-normal"`}
                >
                  <select
                    id="degree-type"
                    className="peer px-3 h-[52px] w-full bg-transparent placeholder-transparent border-none focus:outline-none focus:border-cornflower-blue appearance-none relative"
                    defaultValue={
                      editAcademicDetails?.degreeType
                        ? editAcademicDetails?.degreeType
                        : ""
                    }
                    {...register("degreeType", {
                      required: editAcademicDetails?.degreeType
                        ? false
                        : "This is required field.",
                    })}
                    disabled={
                      !!editAcademicDetails?.degreeType ||
                      !!moderationObj?.degreeType
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {degreeTypes?.map((degree: string, index: number) => {
                      return (
                        <option
                          key={index}
                          value={degree}
                          className={
                            academicDetails?.filter(
                              (academic: IAcademicDetail) =>
                                academic?.degreeType === degree
                            )[0]?.degreeType === degree
                              ? "hidden"
                              : "block"
                          }
                        >
                          {degree}
                        </option>
                      );
                    })}
                  </select>
                  <label
                    htmlFor="degree-type"
                    className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
                      errors.degreeType ? " text-[#FF647C]" : "text-[#8f8f92]"
                    }   transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal  ${
                      errors.degreeType
                        ? "peer-placeholder-shown:text-[#FF647C]"
                        : "peer-placeholder-shown:text-[#8f8f92]"
                    } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
                      errors.degreeType
                        ? "peer-focus:text-[#FF647C]"
                        : "peer-focus:text-cornflower-blue"
                    } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
                  >
                    {"Degree Type *"}
                  </label>
                  <label
                    htmlFor="degree-type"
                    className="absolute right-[21px] top-[22px] peer-focus-within:rotate-180 cursor-pointer"
                  >
                    <DownArrow className="w-[13px] h-[8px] text-[#bebebe] cursor-pointer" />
                  </label>
                </div>
                <div className="w-full">
                  {errors.degreeType ? (
                    <span className="peer-invalid:visible text-[#FF647C]  text-[12px] leading-[15px]">
                      {errors.degreeType.message}
                    </span>
                  ) : errors.degreeType ? (
                    <span className="invisible">Helper Text</span>
                  ) : (
                    <span className="invisible">Helper Text</span>
                  )}
                </div>
              </div>{" "}
            </ModerationField>
            {selectedValue === "Secondary" ||
            (selectedValue === undefined &&
              editAcademicDetails?.degreeType === "Secondary") ? null : (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.department}
              >
                <Text
                  register={register}
                  errors={errors}
                  label="Department"
                  name="department"
                  defaultText={editAcademicDetails?.department}
                  minimumLength={2}
                  maximumLength={35}
                  disable={!!moderationObj?.department}
                />
              </ModerationField>
            )}
            <ModerationField
              {...moderationFieldProps}
              moderationId={moderationObj?.instituteName}
            >
              <Text
                register={register}
                errors={errors}
                label="Institute Name"
                name="instituteName"
                defaultText={editAcademicDetails?.instituteName}
                maximumLength={75}
                disable={!!moderationObj?.instituteName}
              />
            </ModerationField>
            {selectedValue === "Secondary" ||
            selectedValue === "Higher Secondary" ||
            (selectedValue === undefined &&
              editAcademicDetails?.degreeType === "Secondary") ||
            (selectedValue === undefined &&
              editAcademicDetails?.degreeType === "Higher Secondary") ? (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.affiliatedTo}
              >
                <Select
                  register={register}
                  errors={errors}
                  label="Board *"
                  options={schoolBoards}
                  name="board"
                  required={true}
                  defaultSelectValue={editAcademicDetails?.affiliatedTo}
                  disable={!!moderationObj?.affiliatedTo}
                />
              </ModerationField>
            ) : (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.affiliatedTo}
              >
                <Text
                  register={register}
                  errors={errors}
                  label="Affiliated To"
                  name="affiliatedTo"
                  defaultText={editAcademicDetails?.affiliatedTo}
                  maximumLength={35}
                  disable={!!moderationObj?.affiliatedTo}
                />
              </ModerationField>
            )}
            {selectedValue === "" ||
            selectedValue === "Secondary" ||
            selectedValue === "Higher Secondary" ||
            (selectedValue === undefined &&
              editAcademicDetails?.degreeType === "Secondary") ||
            (selectedValue === undefined &&
              editAcademicDetails?.degreeType === "Higher Secondary") ? null : (
              <div className={"flex items-center pb-[24px] ml-1"}>
                <input
                  type="checkbox"
                  className="w-4 h-4 cursor-pointer"
                  id="currently-pursuing"
                  onChange={() => {
                    setCheckbox(!checkbox);
                  }}
                  checked={checkbox}
                />
                <label
                  htmlFor="currently-pursuing"
                  className="ml-[10px] text-sm text-[#615d5d]"
                >
                  I am Currently Pursuing
                </label>
              </div>
            )}
            <div className={"w-full flex justify-between space-x-3"}>
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.startMonth}
              >
                <Select
                  register={register}
                  name={"startMonth"}
                  errors={errors}
                  label="Start Month"
                  options={months}
                  defaultSelectValue={editAcademicDetails?.startMonth}
                  disable={!!moderationObj?.startMonth}
                />
              </ModerationField>
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.startYear}
              >
                <div
                  className={`${moderationObj?.startMonth ? "ml-2" : "w-full"}`}
                >
                  <Select
                    register={register}
                    name={"startYear"}
                    errors={errors}
                    label="Start Year"
                    options={yearData}
                    defaultSelectValue={editAcademicDetails?.startYear}
                    disable={!!moderationObj?.startYear}
                  />
                </div>
              </ModerationField>
            </div>
            {!checkbox ? (
              <div className="w-full space-y-[6px]">
                <div
                  className={`${
                    moderationObj?.endMonth ? "space-x-3" : ""
                  } w-full flex justify-between `}
                >
                  <ModerationField
                    {...moderationFieldProps}
                    moderationId={moderationObj?.endMonth}
                  >
                    <Select
                      register={register}
                      name="endMonth"
                      errors={errors}
                      label="End Month"
                      options={months}
                      defaultSelectValue={editAcademicDetails?.endMonth}
                      disable={!!moderationObj?.endMonth}
                    />
                  </ModerationField>
                  <ModerationField
                    {...moderationFieldProps}
                    moderationId={moderationObj?.endYear}
                  >
                    <Select
                      register={register}
                      name={"endYear"}
                      errors={errors}
                      label="End Year"
                      options={yearData}
                      defaultSelectValue={editAcademicDetails?.endYear}
                      disable={!!moderationObj?.endYear}
                    />
                  </ModerationField>
                </div>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.percentage}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Percentage"
                    name="percentage"
                    defaultText={editAcademicDetails?.percentage?.toString()}
                    maximumLength={5}
                    minimumLength={1}
                    regex={/^[0-9]+(.[0-9]{1,2})?$/}
                    disable={!!moderationObj?.percentage}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.additionalDetails}
                >
                  <TextArea
                    register={register}
                    name="additionalDetails"
                    errors={errors}
                    label="Additional Details"
                    defaultText={editAcademicDetails?.additionalDetails}
                    maximumLength={50}
                    disabled={!!moderationObj?.additionalDetails}
                  />
                </ModerationField>

                {existingCertificateNameArray}

                {certificateNameArray}

                <FileDragAndDrop
                  className="w-[290px] lg:w-[320px] pb-3"
                  fileList={fileList}
                  setFileList={setFileList}
                  updatedFiles={updatedFiles?.map((file) => {
                    return {
                      id: file?.id,
                      filePath: file?.filePath,
                    };
                  })}
                  setUpdatedFiles={setUpdatedFiles}
                  setAlert={toastNotify}
                />
              </div>
            ) : null}
          </div>
          <div className="w-full border-b border-hit-gray pt-[6px]" />
          <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
            <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : editAcademicDetails?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditEducationDetails;
