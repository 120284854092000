import TabLink from "components/App/TabLink/TabLink";

import Personal from "modules/Employee/Pages/Profile/Personal/Personal";
import Contact from "modules/Employee/Pages/Profile/Contact/Contact";
import Education from "modules/Employee/Pages/Profile/Education/Education";
import Skills from "modules/Employee/Pages/Profile/Skills/Skills";
import Family from "modules/Employee/Pages/Profile/Family/Family";
import ProfileHeader from "modules/Employee/Pages/Profile/ProfileHeader/ProfileHeader";
import Work from "modules/Employee/Pages/Profile/Work";

const Profile = () => {
  const tabLinks = [
    {
      name: "Personal",
      link: "/profile/personal",
      allowedResourceIds: [
        "EmployeePersonalInfo",
        "EmployeeBankInfo",
        "EmployeeIdProofInfo",
      ],
    },
    {
      name: "Contact",
      link: "/profile/contact",
      allowedResourceIds: ["EmployeeAddress", "EmployeeEmergencyContactInfo"],
    },
    {
      name: "Education",
      link: "/profile/education",
      allowedResourceIds: ["EmployeeAcademicInfo"],
    },
    {
      name: "Skills",
      link: "/profile/skills",
      allowedResourceIds: ["EmployeeSkillInfo"],
    },
    {
      name: "Family",
      link: "/profile/family",
      allowedResourceIds: ["EmployeeFamilyInfo"],
    },
    {
      name: "Work",
      link: "/profile/work",
      allowedResourceIds: ["EmployeeWorkInfo", "EmployeeWorkExperienceInfo"],
    },
  ];

  const routes = [
    {
      path: "personal",
      element: <Personal />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "education",
      element: <Education />,
    },
    {
      path: "skills",
      element: <Skills />,
    },
    {
      path: "family",
      element: <Family />,
    },
    {
      path: "work",
      element: <Work />,
    },
  ];

  return (
    <div>
      <ProfileHeader />
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default Profile;
