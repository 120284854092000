import { useMemo } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { TypedDocumentNode, useQuery } from "@apollo/client";

import {
  FilterQueryArgs,
  FilterQueryReturnType,
} from "components/FilterUpdated/types";

import { toastNotify } from "global/helpers/Cache";
import { notEmpty, removeDuplicatesByKey } from "global/helpers/ArrayMethods";
import { AutoCompleteProps } from "components/forms/UpdatedFormAgry/AutoComplete/types";
import { AutoComplete } from "components/forms/UpdatedFormAgry";
import useInView from "global/hooks/useInView";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  AutoCompleteProps<TFieldValues, Name>,
  | "options"
  | "loading"
  | "onInputChange"
  | "renderedOption"
  | "searchKeys"
  | "validate"
  | "value"
  | "observe"
  | "defaultValue"
  | "required"
  | "classNameForError"
  | "className"
  | "disabled"
  | "helperText"
  | "hideError"
  | "shouldUnregister"
  | "onChange"
> & {
  filter?: Record<string, any> | null;
  query: TypedDocumentNode<FilterQueryReturnType, FilterQueryArgs>;
  optionReturnType?: (node: any) =>
    | {
        id: number | string;
        label: string;
      }
    | {
        id: number | string;
        label: string;
      }[]
    | null
    | undefined;
};

const DropDownField = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  filter,
  label,
  multiple,
  placeholder,
  query,
  optionReturnType,
  notClearable,
}: Props<TFieldValues, Name>) => {
  const { data, loading, fetchMore, error } = useQuery(query, {
    variables: {
      filter:
        filter && Object.entries(filter)?.length > 0
          ? {
              ...filter,
            }
          : undefined,
      pagination: {
        size: 30,
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { observe } = useInView({
    onEnter: () => {
      if (
        !loading &&
        data?.getDropDownField &&
        "dataCollection" in data.getDropDownField &&
        data?.getDropDownField?.dataCollection?.pageInfo?.hasNextPage &&
        data?.getDropDownField?.dataCollection?.pageInfo?.endCursor
      ) {
        fetchMore({
          variables: {
            pagination: {
              after: data?.getDropDownField.dataCollection?.pageInfo?.endCursor,
              size: 30,
            },
            filter:
              filter && Object.entries(filter)?.length > 0
                ? {
                    ...filter,
                  }
                : undefined,
          },
          updateQuery: (prev, { fetchMoreResult: { getDropDownField } }) => {
            if (!getDropDownField) return prev;
            return {
              getDropDownField:
                getDropDownField &&
                "dataCollection" in getDropDownField &&
                getDropDownField?.dataCollection?.edges &&
                getDropDownField?.dataCollection?.edges?.length > 0 &&
                prev?.getDropDownField
                  ? prev &&
                    prev?.getDropDownField &&
                    "dataCollection" in prev.getDropDownField &&
                    prev?.getDropDownField?.dataCollection?.edges &&
                    prev?.getDropDownField?.dataCollection?.edges?.length > 0
                    ? {
                        edges: [
                          ...prev.getDropDownField.dataCollection.edges,
                          ...getDropDownField.dataCollection.edges,
                        ],
                        pageInfo: getDropDownField?.dataCollection?.pageInfo,
                      }
                    : {
                        edges: getDropDownField?.dataCollection?.edges,
                        pageInfo: getDropDownField?.dataCollection?.pageInfo,
                      }
                  : prev?.getDropDownField,
            };
          },
        }).catch((err) => {
          toastNotify(errorMessageNotify(err));
        });
      } else if (
        !loading &&
        data?.getDropDownField &&
        "edges" in data.getDropDownField &&
        "pageInfo" in data.getDropDownField &&
        data?.getDropDownField?.pageInfo?.hasNextPage &&
        data?.getDropDownField?.pageInfo?.endCursor
      ) {
        fetchMore({
          variables: {
            pagination: {
              after: data?.getDropDownField?.pageInfo?.endCursor,
              size: 30,
            },
            filter:
              filter && Object.entries(filter)?.length > 0
                ? {
                    ...filter,
                  }
                : undefined,
          },
          updateQuery: (prev, { fetchMoreResult: { getDropDownField } }) => {
            if (!getDropDownField) return prev;
            return {
              getDropDownField:
                getDropDownField &&
                "edges" in getDropDownField &&
                "pageInfo" in getDropDownField &&
                getDropDownField?.edges &&
                getDropDownField?.edges?.length > 0 &&
                prev?.getDropDownField
                  ? prev?.getDropDownField &&
                    "edges" in prev.getDropDownField &&
                    "pageInfo" in prev.getDropDownField &&
                    prev?.getDropDownField?.edges &&
                    prev?.getDropDownField?.edges?.length > 0
                    ? {
                        edges: [
                          ...prev.getDropDownField.edges,
                          ...getDropDownField.edges,
                        ],
                        pageInfo: getDropDownField?.pageInfo,
                      }
                    : {
                        edges: getDropDownField?.edges,
                        pageInfo: getDropDownField?.pageInfo,
                      }
                  : prev?.getDropDownField,
            };
          },
        }).catch((err) => {
          toastNotify(errorMessageNotify(err));
        });
      }
    },
  });
  const options = useMemo(() => {
    return error?.message
      ? []
      : data?.getDropDownField &&
        "edges" in data.getDropDownField &&
        data?.getDropDownField?.edges &&
        data?.getDropDownField?.edges?.length > 0
      ? removeDuplicatesByKey(
          data?.getDropDownField?.edges
            ?.map((edges) => {
              if (edges?.node) {
                if (optionReturnType) {
                  return optionReturnType(edges?.node)
                    ? optionReturnType(edges?.node)
                    : null;
                } else if (edges?.node?.id && edges?.node?.name) {
                  return {
                    id: edges?.node?.id,
                    label: edges?.node?.name,
                  };
                }
              } else {
                return null;
              }
            })
            ?.flat(20)
            .filter(notEmpty),
          "id"
        )
      : data?.getDropDownField &&
        "dataCollection" in data.getDropDownField &&
        data?.getDropDownField?.dataCollection &&
        data?.getDropDownField?.dataCollection?.edges &&
        data?.getDropDownField?.dataCollection?.edges?.length > 0
      ? removeDuplicatesByKey(
          data?.getDropDownField?.dataCollection?.edges
            ?.map((edges) => {
              if (edges?.node) {
                if (optionReturnType) {
                  return optionReturnType(edges?.node)
                    ? optionReturnType(edges?.node)
                    : null;
                } else if (edges?.node?.id && edges?.node?.name) {
                  return {
                    id: edges?.node?.id,
                    label: edges?.node?.name,
                  };
                }
              } else {
                return null;
              }
            })
            .flat(20)
            .filter(notEmpty),
          "id"
        )
      : [];
  }, [data?.getDropDownField, error?.message, optionReturnType]);

  return (
    <AutoComplete
      control={control as any}
      name={name}
      options={options as any}
      label={label}
      loading={loading}
      observe={observe}
      placeholder={placeholder}
      multiple={multiple}
      onInputChange={(value) => {
        if (!loading)
          fetchMore({
            variables: {
              pagination: {
                size: 30,
              },
              search: value || null,
            },
            updateQuery: (prev, { fetchMoreResult: { getDropDownField } }) => {
              return {
                getDropDownField,
              };
            },
          }).catch((err) => {
            toastNotify(errorMessageNotify(err));
          });
      }}
      className={"bg-surface-container-low w-full"}
      notClearable={notClearable}
    />
  );
};

export default DropDownField;
