import { gql } from "@apollo/client";

export const PROJECTS_FRAGMENT = gql`
  fragment projectFields on Project {
    id
    name
    status
    projectKey
    isArchived
    manager {
      id
      personalInformation {
        id
        name
      }
    }
    category
    client {
      personalInformation {
        id
        name
      }
    }
    clientAvailability {
      id
      country
      timeZone
      currentTime
      workHours {
        client {
          from
          to
        }
        local {
          from
          to
        }
      }
    }
    clientTimeZone
    projectGroup {
      id
      name
    }
  }
`;

export const PROJECT_ROLES_FRAGMENT = gql`
  fragment projectRoleFields on ProjectRole {
    id
    isActive
    name
    description
    allowedResources {
      resourceId {
        id
        title
        isAllowed
        childNodes {
          id
          title
          isAllowed
          childNodes {
            id
            title
            isAllowed
            childNodes {
              id
              title
              isAllowed
              childNodes {
                id
                title
                isAllowed
              }
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_MEMBERS_FRAGMENT = gql`
  fragment projectMemberFields on ProjectMember {
    id
    user {
      personalInformation {
        id
        name
      }
      employeeDetail {
        status
      }
      userProfileImages {
        documents {
          file
        }
        type
      }
    }
    project {
      name
    }
    projectRole {
      id
      name
    }
  }
`;

export const PROJECT_VAULT_CREDENTIAL_FRAGMENT = gql`
  fragment projectVaultCredentialFields on ProjectVault {
    environment {
      id
      name
      type {
        id
        name
        credentials {
          credentialId
          id
          field {
            id
            name
            fieldType
          }
          value
          extension
          mimeType
          fileName
        }
      }
    }
  }
`;

export const PROJECT_VAULT_ENVIRONMENTS_FRAGMENT = gql`
  fragment vaultEnvironmentFields on ProjectVaultEnvironment {
    id
    name
    description
  }
`;

export const PROJECT_VAULT_CREDENTIAL_TYPES_FRAGMENT = gql`
  fragment projectVaultCredentialTypeFields on ProjectVaultCredentialType {
    id
    name
    description
  }
`;

export const PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS_FRAGMENT = gql`
  fragment projectVaultCredentialTypeFieldFields on ProjectCredentialTypeField {
    id
    name
    fieldType
    type {
      id
      name
    }
  }
`;

export const PROJECT_SPRINT_FRAGMENT = gql`
  fragment projectSprintFields on ProjectSprint {
    id
    name
    startDate
    endDate
    description
    isCompleted
    status
  }
`;

export const PROJECT_COLUMN_FRAGMENT = gql`
  fragment projectColumnFields on ProjectColumn {
    id
    name
    description
    colour
  }
`;

export const PROJECT_TASK_LABEL_FRAGMENT = gql`
  fragment projectTaskStatusFields on ProjectTaskLabel {
    id
    name
    description
  }
`;

export const PROJECT_TASK_FRAGMENT = gql`
  fragment projectTaskFields on ProjectTask {
    id
    isComesUnderBacklog
    project {
      id
      name
      projectKey
    }
    projectColumn {
      id
      name
    }
    sprint {
      id
      name
    }
    incharge {
      personalInformation {
        id
        name
      }
    }
    projectTaskLabel {
      id
      name
    }
    projectTaskApprovedHours {
      id
      projectRole {
        id
        name
      }
      assigneeUser {
        user {
          personalInformation {
            id
            name
          }
        }
        projectRole {
          id
          name
        }
      }
      actualApprovedHours
      allocatedApprovedHours
    }
    projectTaskAttachments {
      id
      filePath
      name
      mimeType
    }
    title
    description
    priority
    startDate
    dueDate
    isBillable
    billableHours
    billingStatus
    totalApprovedHours
    position
  }
`;

export const PROJECT_TASK_FRAGMENT_UPDATED = gql`
  fragment projectTaskFragment on ProjectTask {
    id
    title
    projectTaskAttachments
      @include(if: $isProjectTaskProjectTaskAttachmentsNeed) {
      id
      filePath
      name
      mimeType
    }
    description @include(if: $isProjectTaskDescriptionNeed)
    priority @include(if: $isProjectTaskPriorityNeed)
  }
`;

export const PROJECT_TASK_COMMENT_FRAGMENT = gql`
  fragment taskCommentFields on ProjectTaskComment {
    id
    message
    createdAt
    createdByUser {
      personalInformation {
        id
        name
      }
      userProfileImages {
        documents {
          file
        }
        type
      }
    }
    projectTaskCommentAttachments {
      id
      filePath
      name
      mimeType
      extension
    }
  }
`;

export const PROJECT_WORK_LOG_FRAGMENT = gql`
  fragment worklogfields on ProjectWorkLog {
    id
    description
    startTime
    endTime
    createdAt
    durationInSeconds
    user {
      personalInformation {
        id
        name
      }
      userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
        documents {
          file
        }
        type
      }
    }
    projectTask {
      projectTaskApprovedHours {
        allocatedApprovedHours
        assigneeUser {
          id
        }
      }
    }
  }
`;

export const RUNNING_PROJECT_WORK_LOG_FRAGMENT = gql`
  fragment runningProjectWorkLogFields on ProjectWorkLog {
    id
    description
    startTime
    endTime
    durationInSeconds
    durationInHours
    runningHours
    createdAt
    updatedAt
    projectTask {
      id
      title
      project {
        id
        name
      }
    }
  }
`;

export const PROJECT_TASK_HISTORY_FRAGMENT = gql`
  fragment taskHistoryFields on ProjectTaskHistory {
    id
    createdAt
    previousValue
    currentValue
    message
    performedByUser {
      personalInformation {
        id
        name
      }
      userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
        documents {
          file
        }
        type
      }
    }
  }
`;

export const PROJECT_TASK_ATTACHEMENT_FRAGMENT = gql`
  fragment projectTaskAttachmentFields on ProjectTaskAttachment {
    id
    name
    filePath
    extension
    mimeType
    createdAt
    createdBy {
      id
    }
  }
`;

export const WORK_LOG_TOTAL_HOURS_FRAGMENT = gql`
  fragment workLogFields on ProjectWorkLog {
    durationInSeconds
    description
  }
`;
