import { IToastedNotification } from "components/UI/ToastNotification/type";

export const accessDeniedMessage: IToastedNotification[] = [
  {
    messageType: "error",
    message: "Access denied.",
  },
];

export const createSuccessMessage = (
  addingFieldName: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${addingFieldName} has been added successfully.`,
  },
];

export const updateSuccessMessage = (
  editingFieldName: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${editingFieldName} has been updated successfully.`,
  },
];

export const deleteSuccessMessage = (
  deletingField: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${deletingField} has been deleted successfully.`,
  },
];

export const createSuccessMessageWithAwaiting = (
  addingFieldName: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${addingFieldName} has been added successfully. Awaiting approval.`,
  },
];

export const updateSuccessMessageWithAwaiting = (
  editingFieldName: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${editingFieldName} has been updated successfully. Awaiting approval.`,
  },
];

export const deleteSuccessMessageWithAwaiting = (
  deletingField: string
): IToastedNotification[] => [
  {
    messageType: "success",
    message: `${deletingField} has been deleted successfully. Awaiting approval.`,
  },
];

export const errorMessageNotify = (error: {
  message: any;
  name?: string;
}): IToastedNotification[] => {
  if (error?.name !== "AbortError") {
    return [
      {
        messageType: "error",
        message: error?.message,
      },
    ];
  } else {
    return [];
  }
};

export const notFoundMessage = "Sorry we couldn't found any results.";
