import { gql } from "@apollo/client";

const CLIENT_FRAGMENT = gql`
fragment clientFragment on User {   
    id
    personalInformation {
        id
        name
        gender
        officialDateOfBirth  @include(if: $isClientOfficialDateOfBirthNeed)
        personalEmail @include(if: $isClientPersonalEmailNeed)
    }
    role @include(if: $isClientRoleNeed) {
        id
        roleName
    }
    userType @include(if: $isClientUserTypeNeed)
}
`

export { CLIENT_FRAGMENT  }