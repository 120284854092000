import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import AddOrEditProjectBacklog from "modules/Project/Pages/Backlogs/AddOrEditProjectBacklog";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
	projectGroup?: ProjectGroup | null;
	editProject: { id: number; name: string };
}

const EditProjectBackLogRouteControl: FC<Props> = ({
	editProject,
	projectGroup,
}) => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<AddOrEditProjectBacklog
						editProject={editProject}
						projectGroup={projectGroup}
					/>
				}
			/>
		</Routes>
	);
};

export default EditProjectBackLogRouteControl;
