import {
	FC,
	useCallback,
	useEffect,
	useRef,
	useState,
	MouseEvent,
} from "react";
import { HiPencil } from "react-icons/hi";
import { TbCopy } from "react-icons/tb";

import { TTableContent } from "components/App/UpdatedAgryApp/Table/types/Table";
import {
	IOnCheckBox,
	IOnKebabIcon,
	IOnPencilIcon,
	IOnView,
} from "components/App/UpdatedAgryApp/Table/types/TableContent";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";
import KebabMenu from "components/UI/UpdateUIAgry/KebabMenu";

import { ReactComponent as KebabIcon } from "global/assets/images/Kebab-Icon.svg";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";

interface Props {
	tableContent: TTableContent[];
	onCheckBox?: IOnCheckBox;
	onKebabIcon?: IOnKebabIcon;
	onPencilIcon?: IOnPencilIcon;
	onView?: IOnView;
	showModal?: boolean;
	tableRef: any;
	tableRowClick?: (event: MouseEvent<HTMLTableRowElement>) => void;
	classForTableRow?: string;
}

const TableContent: FC<Props> = ({
	tableContent,
	onCheckBox,
	onView,
	showModal,
	tableRef,
	onKebabIcon,
	onPencilIcon,
	tableRowClick,
	classForTableRow,
}) => {
	const [showEditOption, setShowEditOption] = useState<boolean>(false);
	const editRef = useRef<HTMLDivElement>(null!);
	useEffect(() => {
		const handler = (event: any) => {
			if (!editRef.current?.contains(event.target)) {
				setShowEditOption(false);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [showEditOption]);

	const kebabIconRef = useRef<HTMLDivElement>(null);
	const [top, setTop] = useState<number | undefined>(
		kebabIconRef.current?.getBoundingClientRect().top,
	);
	const [left, setLeft] = useState<number | undefined>(
		kebabIconRef.current?.getBoundingClientRect().left,
	);
	const [right, setRight] = useState<number | undefined>(
		kebabIconRef.current?.getBoundingClientRect().right,
	);

	const getPosition = useCallback(() => {
		setTop(
			kebabIconRef.current?.getBoundingClientRect().top &&
				kebabIconRef.current?.getBoundingClientRect().top + 32,
		);
		setLeft(
			kebabIconRef.current?.getBoundingClientRect().left &&
				kebabIconRef.current?.getBoundingClientRect().left - 92,
		);
		setRight(kebabIconRef.current?.getBoundingClientRect().right);
	}, []);

	useEffect(() => {
		const eventRef = kebabIconRef.current;
		const parentEventRef = tableRef.current;
		window.addEventListener("resize", getPosition);
		window.addEventListener("scroll", getPosition);
		eventRef?.addEventListener("click", getPosition);
		parentEventRef?.addEventListener("scroll", getPosition);

		return () => {
			window.removeEventListener("resize", getPosition);
			window.removeEventListener("scroll", getPosition);
			eventRef?.removeEventListener("click", getPosition);
			parentEventRef?.removeEventListener("scroll", getPosition);
		};
	}, [getPosition, tableRef]);

	useEffect(() => {
		if (showModal) {
			setShowEditOption(false);
		}
	}, [showModal]);

	const tableContentFile = (file: any) => {
		if (file?.length > 100) {
			return (
				<a href={Base64toObject(file)} target="_blank" rel="noreferrer">
					<LinkIcon className="w-[14px] h-[14px] cursor-pointer text-irish-green" />
				</a>
			);
		} else {
			return "No File";
		}
	};

	const kebabIconClickHandler = (e: MouseEvent<HTMLOrSVGElement>) => {
		onKebabIcon?.onKebabIconClick?.(e);
		setShowEditOption((prevState: any) => !prevState);
	};

	const showEditOptionStyle = {
		top: `${top}px`,
		left: `${left}px`,
		right: `${right}px`,
	};

	const [copyText, setCopyText] = useState(false);

	return (
		<tr
			className={`${
				tableRowClick
					? classForTableRow
						? classForTableRow
						: "cursor-pointer hover:bg-white-smoke"
					: classForTableRow
					  ? classForTableRow
					  : "hover:bg-white-smoke"
			} group border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20`}
			onClick={tableRowClick && tableRowClick}
		>
			{onCheckBox && (
				<td className="py-5 px-3">
					<div className="flex items-center w-full justify-center">
						<input
							type="checkbox"
							className="h-4 w-4 text-irish-green accent-irish-green cursor-pointer"
							onChange={onCheckBox.onChange}
							checked={onCheckBox.isChecked}
						/>
					</div>
				</td>
			)}
			{tableContent?.map((tableContent, index) => {
				return tableContent?.hideContent ? null : (
					<td
						key={index}
						className={`${tableContent?.color || ""} ${
							tableContent?.center ? "text-center" : ""
						} p-0`}
					>
						<div
							className={`${tableContent?.value ? "" : "inline-block w-min"} ${
								tableContent?.classNameForDiv
									? tableContent?.classNameForDiv
									: "py-5 px-3"
							}`}
						>
							{tableContent?.file ? (
								<div className="flex items-center gap-3 w-min max-w-xs truncate">
									<span className="max-w-xs truncate">
										{tableContent?.value}
									</span>
									<span>{tableContentFile(tableContent?.file)}</span>
								</div>
							) : (
								<div
									className={`${
										tableContent.className ? tableContent.className : "truncate"
									} ${tableContent?.value ? "" : "px-3"} ${
										tableContent?.center ? "text-center" : ""
									} `}
								>
									{typeof tableContent?.value === "string" ||
									typeof tableContent?.value === "number" ? (
										<div
											className={`flex gap-3 max-w-[250px] truncate ${
												tableContent?.center ? "justify-center" : ""
											}`}
										>
											<ToolTip
												render={tableContent?.value?.toString() || "-"}
												arrow
												classNameForParent="w-min max-w-xs truncate"
												className="bg-storm-dust text-xs text-white text-center p-2"
											>
												{tableContent?.value?.toString() || "-"}
											</ToolTip>
											<div
												className={
													tableContent.copyText
														? "group-hover:visible invisible"
														: "hidden"
												}
											>
												<ToolTip
													render={copyText ? "Copied" : "Copy"}
													arrow
													placement="top"
													className="bg-storm-dust text-white text-xs p-2"
													trigger={["hover"]}
												>
													<TbCopy
														className="w-5 h-5 sm:w-5.5 sm:h-5.5 cursor-pointer hover:text-dark-mint"
														onMouseEnter={() => {
															setCopyText(false);
														}}
														onClick={(e) => {
															e.stopPropagation();
															setCopyText(true);
															navigator.clipboard.writeText(
																`${tableContent?.value}`,
															);
														}}
													/>
												</ToolTip>
											</div>
										</div>
									) : tableContent?.value ? (
										tableContent?.value
									) : (
										"-"
									)}
								</div>
							)}
						</div>
					</td>
				);
			})}
			{onKebabIcon?.editAccess && (
				<td className="relative py-5 px-3">
					<div className="w-full flex justify-center items-center">
						<div ref={editRef} className="w-min">
							{onKebabIcon.editAccess && (
								<div ref={kebabIconRef}>
									<KebabIcon
										onClick={kebabIconClickHandler}
										className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke rotate-90 ${
											showEditOption ? "bg-white-smoke" : null
										}`}
									/>
								</div>
							)}
							{showEditOption && (
								<div className="fixed z-50" style={showEditOptionStyle}>
									<KebabMenu
										onEdit={onKebabIcon.onEdit || undefined}
										onDelete={onKebabIcon.onDelete || undefined}
										otherOptions={onKebabIcon?.otherOptions}
									/>
								</div>
							)}
						</div>
					</div>
				</td>
			)}
			{onView?.editAccess && (
				<td className="py-5 px-3 text-center text-ironside-gray whitespace-nowrap">
					<span
						className={`whitespace-nowrap text-sm px-3 ${
							onView?.editAccess
								? "text-irish-green hover:underline cursor-pointer"
								: "text-gray-500"
						}`}
						onClick={
							onView.editAccess
								? onView?.onViewClick && onView?.onViewClick
								: undefined
						}
					>
						View
					</span>
				</td>
			)}
			{onPencilIcon?.editAccess && (
				<td className="py-5 px-3 whitespace-nowrap">
					<div className="flex justify-center items-center">
						<HiPencil
							className="w-5 h-5 cursor-pointer text-ironside-gray/70"
							onClick={() => {
								onPencilIcon?.onPencilIconClick?.();
							}}
						/>
					</div>
				</td>
			)}
		</tr>
	);
};

export default TableContent;
