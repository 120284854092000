import { DateFormat } from "global/helpers/DateFormatter";
import { currentDate } from "global/helpers/StaticData";

export const workExperienceFieldValidation = (
  validation: any,
  expStartDate: string | Date | null,
  expEndDate: string | Date | null,
  checkbox: boolean,
  setError: Function
) => {
  const validationStartYear =
    expStartDate && +DateFormat(expStartDate?.toString())?.slice(0, 4);
  const validationEndYear = expEndDate
    ? +DateFormat(expEndDate?.toString())?.slice(0, 4)
    : "";
  const validationStartMonth =
    expStartDate && +DateFormat(expStartDate?.toString())?.slice(5, 7);
  const validationEndMonth = expEndDate
    ? +DateFormat(expEndDate?.toString())?.slice(5, 7)
    : "";
  const validationStartDate =
    expStartDate && +DateFormat(expStartDate?.toString())?.slice(8, 11);
  const validationEndDate = expEndDate
    ? +DateFormat(expEndDate?.toString())?.slice(8, 11)
    : "";
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const dateValidation = () => {
    if (!checkbox) {
      if (validation?.expStartDate && validation?.expEndDate) {
        if (validationStartYear && validationStartYear > validationEndYear) {
          setError("expStartDate", {
            type: "custom",
            message: "Please enter the valid start date.",
          });
          setError("expEndDate", {
            type: "custom",
            message: "Please enter the valid end date.",
          });
          return false;
        } else if (
          validationStartYear &&
          validationStartYear < validationEndYear
        ) {
          return true;
        } else if (validationStartYear === validationEndYear) {
          if (
            validationStartMonth &&
            validationStartMonth > validationEndMonth
          ) {
            setError("expStartDate", {
              type: "custom",
              message: "Please enter the valid start date.",
            });
            setError("expEndDate", {
              type: "custom",
              message: "Please enter the valid end date.",
            });
            return false;
          } else {
            if (
              validationStartDate &&
              validationStartDate <= validationEndDate
            ) {
              return true;
            } else {
              setError("expStartDate", {
                type: "custom",
                message: "Please enter the valid start date.",
              });
              setError("expEndDate", {
                type: "custom",
                message: "Please enter the valid end date.",
              });
              return false;
            }
          }
        } else if (
          validationStartYear &&
          validationStartYear < validationEndYear
        ) {
          return true;
        } else if (validationStartMonth === validationEndMonth) {
          if (validationStartDate && validationStartDate > validationEndDate) {
            setError("expStartDate", {
              type: "custom",
              message: "Please enter the valid start date.",
            });
            setError("expEndDate", {
              type: "custom",
              message: "Please enter the valid end date.",
            });
            return false;
          } else if (
            validationStartDate &&
            validationStartDate < validationEndDate
          ) {
            return true;
          } else if (validationStartDate === validationEndDate) {
            setError("expStartDate", {
              type: "custom",
              message: "Please enter the valid start date.",
            });
            setError("expEndDate", {
              type: "custom",
              message: "Please enter the valid end date.",
            });
            return false;
          }
        }
      } else {
        return true;
      }
    } else if (validationStartYear && validationStartYear <= currentYear) {
      if (validationStartMonth === currentMonth) {
        if (validationStartDate && validationStartDate <= currentDay) {
          return true;
        } else {
          setError("expStartDate", {
            type: "custom",
            message: "Please enter the valid start date.",
          });
          return false;
        }
      } else {
        return true;
      }
    } else {
      setError("expStartDate", {
        type: "custom",
        message: "Please enter the valid start date.",
      });
      return false;
    }
  };
  dateValidation();
  return dateValidation();
};
