import { useLazyQuery, useQuery } from "@apollo/client";
import { Select, ToggleSwitch } from "components/forms";
import { useForm } from "react-hook-form";
import TransactionDetails from "./MyTransactionsDetails";
import { useEffect, useState } from "react";
import ViewPage from "./ViewPage";
import SelectWithId from "components/forms/Select/SelectWithId";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { DateFormat } from "global/helpers/DateFormatter";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";
import { Route, Routes } from "react-router-dom";
import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  FILTER_MY_LEAVE_TRANSACTION,
  LEAVE_DASHBOARD,
} from "modules/Leave/services/queries";

export interface TransactionDetailsType {
  leaveType: string | any;
  transactionType: string;
  createdAt: any;
  prevCount: undefined | string;
  noOfDays: number;
  currentCount: string;
}

const MyTransactions = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const { data: leaveDashboard } = useQuery(LEAVE_DASHBOARD, {
    variables: {
      userId: +localStorage?.id || +sessionId,
    },
  });

  const leaveOptions = leaveDashboard?.leaveDashboard
    ?.map(
      (leaves: { leaveType: any; id: number; name: string; year: number }) => {
        if (leaves?.year === new Date()?.getFullYear()) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else if (leaves?.leaveType?.isSpecialLeave) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else {
          return null;
        }
      }
    )
    ?.filter((leaveType: { id: number; name: string }) => leaveType);

  const defaultDateRangeStartDate = new Date(
    `${new Date()?.getFullYear()}-${new Date()?.getMonth() + 1}-01`
  );

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: defaultDateRangeStartDate,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const leaveType = watch("leaveType");
  const transactionType = watch("transactionType");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());
  const watchDateType = watch("dateType");
  const watchManual = watch("isManual");

  const viewPageDetails: any = "";
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const [fetchLeaveTransactions, { data: filterLeaveTransaction, loading }] =
    useLazyQuery(FILTER_MY_LEAVE_TRANSACTION, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    fetchLeaveTransactions({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        transactionType: transactionType ? transactionType : undefined,
        from:
          fromDate && watchDateType !== "Created Date" ? fromDate : undefined,
        to: toDate && watchDateType !== "Created Date" ? toDate : undefined,
        createdFrom:
          fromDate && watchDateType === "Created Date" ? fromDate : undefined,
        createdTo:
          toDate && watchDateType === "Created Date" ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
        isManual: watchManual ? true : false,
      },
    });
  }, [
    pageSize,
    currentPage,
    transactionType,
    fromDate,
    toDate,
    leaveType,
    fetchLeaveTransactions,
    watchDateType,
    watchManual,
  ]);

  const [filteredTransaction, setFilteredTransaction] = useState([]);
  useEffect(() => {
    setFilteredTransaction(
      filterLeaveTransaction?.filterMyLeaveTransaction?.dataCollection?.map(
        (transaction: any) => {
          return {
            leaveType: transaction?.leaveType.name,
            createdAt: transaction?.createdAt,
            leaveDate: transaction?.isManual
              ? transaction?.addedLeaveDate
              : transaction?.leaveDate
              ? transaction?.leaveDate[0]?.leaveDate
              : "N/A",
            leaveDates: transaction?.leaveDate,
            session: transaction?.leaveDate
              ? transaction?.leaveDate[0]?.session
              : "N/A",
            transactionType: transaction?.transactionType,
            noOfDays: transaction?.noOfDays,
            id: transaction?.id,
            previousCount: transaction?.previousCount,
            currentCount: transaction?.currentCount,
            notes: transaction?.notes,
            addedLeaveDate: transaction?.addedLeaveDate,
            isManual: transaction?.isManual,
          };
        }
      )
    );
  }, [filterLeaveTransaction]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] sm:gap-5 xl:grid-cols-[repeat(4,220px)] 2xl:grid-cols-[repeat(4,250px)] justify-center items-center xl:justify-start">
                  <SelectWithId
                    register={register}
                    name={"leaveType"}
                    errors={errors}
                    label={"Leave Type"}
                    options={leaveOptions}
                    enableDefaultSelectOption
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={["Credit", "Debit"]}
                    label="Transaction type"
                    name="transactionType"
                    required={true}
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={["Created Date", "Leave Date"]}
                    label="Date Type"
                    name="dateType"
                    defaultSelectValue={"Leave Date"}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                  <div className="px-[6px] grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center mb-8">
                    <p className="text-sm text-ironside-gray">
                      Is Manual Transaction?
                    </p>
                    <ToggleSwitch
                      label={"isManual"}
                      register={register}
                      name={"isManual"}
                    />
                  </div>
                </div>
              </div>
              <TransactionDetails
                leaveTransactionData={filteredTransaction}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                totalItems={
                  filterLeaveTransaction?.filterMyLeaveTransaction
                    ?.totalNumberOfItems
                }
                loading={loading}
                filterLeaveTransaction={filterLeaveTransaction}
              />
            </div>
          }
        />
        <Route path={`:${viewPageDetails?.id}`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default MyTransactions;
