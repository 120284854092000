import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

import SMCardContent from "components/App/Card/SMCard/SMCardContent";

const ViewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const viewPageDetails = location?.state?.viewPageDetails;
  let createdAt = viewPageDetails?.createdAt || (
    <span className="text-gray-400">{"Eg.DD-MMM-YYYY"}</span>
  );
  const cancelNavigationHandler = () => {
    navigate("/leaves/transactions/all-transactions");
  };
  const profileDetails = {
    profileImage: viewPageDetails?.employeeProfile,
    name: viewPageDetails?.employeeName,
    email: viewPageDetails?.employeeEmail,
    designation: viewPageDetails?.employeeDesignation,
  };

  const dataModal = [
    {
      lable: "ID",
      value: [
        {
          content: viewPageDetails?.id,
        },
      ],
    },
    {
      lable: "Employee Name",
      value: [
        {
          content: viewPageDetails?.employeeName,
          profileDetails: profileDetails,
        },
      ],
    },
    {
      lable: "Created At",
      value: [
        {
          content: createdAt,
        },
      ],
    },
    {
      lable: "Leave Date",
      value: [
        {
          content: `${
            viewPageDetails?.leaveDates &&
            viewPageDetails?.leaveDates?.length > 1
              ? `${viewPageDetails?.leaveDates[0]?.leaveDate} - ${viewPageDetails?.leaveDates[1]?.leaveDate}` ||
                "N/A"
              : viewPageDetails?.leaveDates?.length === 1
              ? viewPageDetails?.leaveDate || "N/A"
              : ""
          }`,
        },
      ],
    },
    {
      lable: "Session",
      value: [
        {
          content: `${
            viewPageDetails?.leaveDates &&
            viewPageDetails?.leaveDates?.length > 1
              ? `${viewPageDetails?.leaveDates[0]?.session} - ${
                  viewPageDetails?.leaveDates[1]?.session || "N/A"
                }`
              : viewPageDetails?.leaveDates?.length === 1
              ? viewPageDetails?.session || "N/A"
              : ""
          }`,
        },
      ],
    },
    {
      lable: "Leave Type",
      value: [
        {
          content: viewPageDetails?.leaveType,
        },
      ],
    },
    {
      lable: "Transaction Type",
      value: [
        {
          content: viewPageDetails?.transactionType,
        },
      ],
    },
    {
      lable: "No Of days",
      value: [
        {
          content: viewPageDetails?.noOfDays,
        },
      ],
    },
    {
      lable: "Previous Count",
      value: [
        {
          content: viewPageDetails?.previousCount,
        },
      ],
    },
    {
      lable: "Current Count",
      value: [
        {
          content: viewPageDetails?.currentCount,
        },
      ],
    },
    {
      lable: "Notes",
      value: [
        {
          content: viewPageDetails?.notes,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <div className="border border-hit-gray rounded mt-5 px-5 py-4 min-h-[68vh]">
        <SMCardContent dataModel={dataModal} />
      </div>
      <div className="flex justify-center items-center">
        <HiArrowNarrowLeft
          className="back-arrow-icon"
          onClick={cancelNavigationHandler}
        />
      </div>
    </Fragment>
  );
};

export default ViewPage;
