import { Tab } from "@headlessui/react";
import { Control, FieldValues } from "react-hook-form";

import DropDownField from "components/FilterUpdated/DropDownField";
import { FilterOption } from "components/FilterUpdated/types";
import CheckBoxField from "components/FilterUpdated/CheckBoxField";
import { Input, TextArea } from "components/forms/UpdatedFormAgry";
import Date from "components/forms/UpdatedFormAgry/Date/Date/Date";

interface Props<TFieldValues extends FieldValues = FieldValues> {
  option: {
    name: string;
    fieldOption: FilterOption<TFieldValues>;
  };
  control: Control<TFieldValues>;
}

const FilterTabPanel = <TFieldValues extends FieldValues = FieldValues>({
  control,
  option,
}: Props<TFieldValues>) => {
  return (
    <Tab.Panel
      as={"div"}
      className={"px-4 py-5 truncate min-w-[270px] sm:min-w-[300px] max-w-lg"}
    >
      {option.fieldOption?.type === "string" ? (
        <DropDownField
          control={control}
          name={option.fieldOption.name}
          query={option.fieldOption.query}
          filter={option.fieldOption?.filter}
          label={option.fieldOption.label}
          multiple={option.fieldOption.multiple}
          placeholder={option.fieldOption.placeholder}
          optionReturnType={option.fieldOption.optionReturnType}
        />
      ) : option.fieldOption?.type === "checkbox" ? (
        <CheckBoxField
          control={control as any}
          name={option?.fieldOption?.name}
          multiple={option?.fieldOption?.multiple ? true : false}
          optionOrQuery={
            Array.isArray(option?.fieldOption?.options)
              ? {
                  options: option?.fieldOption?.options,
                }
              : {
                  query: option?.fieldOption?.options,
                }
          }
        />
      ) : option.fieldOption?.type === "date" ? (
        <Date
          control={control}
          label={option.fieldOption.label}
          name={option.fieldOption.name}
          minValue={option.fieldOption?.min || undefined}
          maxValue={option.fieldOption?.max || undefined}
          type={option.fieldOption?.field || "date"}
          className={"bg-surface-container-low w-full"}
          shouldUnregister={false}
        />
      ) : option.fieldOption?.type === "number" ? (
        "nameFrom" in option.fieldOption ? (
          <div className={"bg-surface-container-low w-full grid grid-cols-1"}>
            <Input
              control={control}
              name={option.fieldOption.nameFrom}
              label={option.fieldOption.labelFrom}
              className="bg-surface-container-low"
              type="number"
              shouldUnregister={false}
            />
            <Input
              control={control}
              name={option.fieldOption.nameTo}
              label={option.fieldOption.labelTo}
              className={"bg-surface-container-low"}
              type="number"
              shouldUnregister={false}
            />
          </div>
        ) : (
          <Input
            control={control}
            name={option.fieldOption.name}
            label={option.fieldOption.label}
            className={"bg-surface-container-low w-full"}
            type="number"
            shouldUnregister={false}
          />
        )
      ) : option.fieldOption.type === "textArea" ? (
        <TextArea
          className="bg-surface-container-low w-full"
          label={option.fieldOption.label}
          control={control}
          name={option.fieldOption.name}
          shouldUnregister={false}
        />
      ) : null}
    </Tab.Panel>
  );
};

export default FilterTabPanel;
