import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { Modal, Outlined, Filled } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";

import { toastNotify } from "global/helpers/Cache";

import { ROLES } from "modules/Employee/services/queries";
import { UPDATE_USER_ROLE } from "modules/Settings/services/mutations";

interface FormType {
  userId: number;
  roleId: number;
}
const UpdateRole: React.FC<{
  setShowModal: Function;
  setEditRole: Function;
  editRole: {
    userName: { id: number; name: string };
    roles: { id: number; name: string }[];
    defaultRole: number;
  };
  refetchUser: Function;
  refetchClients: Function;
  watchUserType: string;
}> = ({
  setShowModal,
  setEditRole,
  editRole,
  refetchUser,
  refetchClients,
  watchUserType,
}) => {
  const { data: roles, loading: rolesLoader } = useQuery(ROLES);

  const rolesOptions =
    roles?.roles?.dataCollection && roles?.roles?.dataCollection?.length > 0
      ? watchUserType === "Employees"
        ? roles?.roles?.dataCollection?.filter(
            (role) => role?.name !== "Client"
          )
        : roles?.roles?.dataCollection?.filter(
            (role) => role?.name === "Client"
          )
      : [];

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>();

  const [inProgress, setInProgress] = useState(false);

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);

  const assignRoleHandler = (data: FormType) => {
    if (!inProgress) {
      updateUserRole({
        variables: {
          userId: +data.userId,
          roleId: +data.roleId,
        },
      })
        .then((response) => {
          setInProgress(false);
          reset();
          if (watchUserType === "Employees") {
            refetchUser();
          } else {
            refetchClients();
          }
          toastNotify([
            {
              messageType: "success",
              message: response?.data?.updateUserRole,
            },
          ]);
          setShowModal(false);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  useEffect(() => {
    if (editRole?.defaultRole && roles) {
      setValue("roleId", editRole?.defaultRole);
    }
  }, [setValue, editRole?.defaultRole, roles]);

  return (
    <Modal>
      <div className="w-[350px] lg:w-[420px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
        {rolesLoader ? (
          <div className="min-h-[335px] lg:min-h-[351px] flex justify-center items-center">
            <div className="loader-design" />
          </div>
        ) : (
          <div>
            <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
              <h2 className="edit-modal-heading">Role Allocation</h2>
              <div
                className="edit-modal-close-icon-div"
                onClick={() => {
                  setInProgress(false);
                  reset();
                  setShowModal(false);
                  setEditRole(null);
                }}
              >
                <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
              </div>
            </div>
            <form
              className="space-y-1"
              onSubmit={handleSubmit(assignRoleHandler)}
            >
              <div className="form-div pr-[18px] lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar ">
                <SelectWithId
                  register={register}
                  errors={errors}
                  options={[editRole?.userName]}
                  name="userId"
                  label={watchUserType === "Employees" ? "User *" : "Client *"}
                  defaultSelectValue={editRole?.userName?.id}
                />
                <SelectWithId
                  register={register}
                  errors={errors}
                  options={rolesOptions}
                  name="roleId"
                  label="Role *"
                  required={true}
                />
              </div>
              <div className="w-full border-b border-hit-gray" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setInProgress(false);
                    reset();
                    setShowModal(false);
                    setEditRole(null);
                  }}
                  buttonName={"Cancel"}
                />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : editRole.defaultRole ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpdateRole;
