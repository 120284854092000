import { ReactNode } from "react";
import { FC } from "react";

interface IProps {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}
const Card: FC<IProps> = ({ children, className, onClick }) => {
  return (
    <div
      className={`lg:mx-0 px-[20px] rounded shadow-[0px_0px_45px_#C4C4C433] bg-white border-[0.5px] md:border-none border-light-gray m-[8px] overflow-x-auto ${
        className ? className : "min-h-[400px] lg:px-[45px] md:mt-[30px]"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
