import { FC } from "react";

import { Filled, Outlined } from "components/forms";

interface IProps {
  declineHandler: () => void;
  approveHandler: () => void;
}

const ViewPageButtons: FC<IProps> = ({ declineHandler, approveHandler }) => {
  return (
    <div className="flex justify-center items-center space-x-5 mb-5">
      <Outlined buttonName={"Decline"} onClick={declineHandler} />
      <Filled buttonName={"Approve"} onClick={approveHandler} />
    </div>
  );
};

export default ViewPageButtons;
