import { ReactComponent as MenuIcon } from "global/assets/images/menu-icon.svg";

const HamburgerMenu: React.FC<{
  mobileNav: any;
  setHamburgerMenuClicked: Function;
}> = ({ mobileNav, setHamburgerMenuClicked }) => {
  return (
    <MenuIcon
      className="ml-[16px] lg:ml-[30px] w-[21px] h-[14px] lg:w-[27px] lg:h-[18px] cursor-pointer"
      onClick={() => {
        mobileNav();
        setHamburgerMenuClicked(true);
      }}
    />
  );
};

export default HamburgerMenu;
