import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";
import {
  Group,
  Label,
  NumberField,
  Input as NumberInput,
} from "react-aria-components";
import { InputProps } from "./types";

const Input = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  type,
  className = "",
  name,
  control,
  defaultValue,
  disabled,
  label,
  onChange,
  required,
  maximumNumber,
  minimumNumber,
  maxLength,
  minLength,
  pattern,
  placeholder,
  hideLabel,
  hideError = false,
  tabIndex,
  disablePasswordAutoComplete = false,
  shouldUnregister = true,
  classNameForError = "",
  classForInput,
  step,
  formatOptions,
  classNameForInputParentDiv,
}: InputProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends string | number | null | undefined
    ? {
        helperType?: undefined;
        invalidType?: undefined;
      }
    : {
        helperType?: undefined;
        invalidType: "Type must be string | number";
      })) => {
  const inputFieldType = type
    ? type === "email"
      ? "text"
      : type === "aadhaar"
      ? "text"
      : type
    : "text";

  const inputFieldPattern =
    type === "email"
      ? {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/,
          message: "Please enter a valid email.",
        }
      : type === "aadhaar"
      ? {
          value: /^\d{4}\d{4}\d{4}$/,
          message: "Please enter a valid aadhaar number.",
        }
      : pattern;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      rules={{
        required: required
          ? typeof required === "boolean"
            ? "This is required field."
            : required
          : undefined,
        onChange: (e) => {
          if (onChange) {
            if (type === "number" || type === "aadhaar") {
              onChange(
                e?.target?.value
                  ? (+e?.target?.value as PathValue<TFieldValues, Name>)
                  : (null as PathValue<TFieldValues, Name>)
              );
            } else {
              onChange(e?.target?.value);
            }
          }
        },
        max: maximumNumber
          ? type === "number"
            ? {
                message: `Maximum number should be ${maximumNumber}`,
                value: maximumNumber,
              }
            : undefined
          : undefined,
        min: minimumNumber
          ? type === "number"
            ? {
                value: minimumNumber,
                message: `Minimum number should be ${minimumNumber}`,
              }
            : undefined
          : undefined,
        maxLength:
          type === "aadhaar"
            ? {
                value: 12,
                message: `Maximum Length should be 12`,
              }
            : maxLength
            ? {
                value: maxLength,
                message: `Maximum Length should be ${maxLength}`,
              }
            : undefined,
        minLength:
          type === "aadhaar"
            ? {
                value: 12,
                message: `Maximum Length should be 12`,
              }
            : minLength
            ? {
                value: minLength,
                message: `Minimum Length should be ${minLength}`,
              }
            : undefined,
        pattern: type === "number" ? undefined : inputFieldPattern,
      }}
      render={({
        field: { value, onChange, ...restProps },
        fieldState: { error },
      }) => {
        const errorMessage = error?.message;

        return (
          <div className={twMerge("w-full bg-white", className)}>
            {type === "number" || type === "aadhaar" ? (
              <NumberField
                value={value ? value : value === 0 ? 0 : NaN}
                onChange={(value) => {
                  onChange(isNaN(value) ? null : value);
                }}
                minValue={minimumNumber}
                maxValue={maximumNumber}
                aria-labelledby={placeholder || label}
                aria-label={placeholder || label}
                step={step}
                {...restProps}
                formatOptions={{
                  ...formatOptions,
                  useGrouping:
                    formatOptions && formatOptions?.useGrouping
                      ? formatOptions?.useGrouping
                      : false,
                }}
                className={twMerge(
                  "w-full relative border rounded flex justify-center items-center min-h-[45px]",
                  disabled
                    ? "cursor-not-allowed bg-surface-disabled border-disabled/50"
                    : errorMessage
                    ? "bg-inherit border-error focus-within:border-error"
                    : "bg-inherit border focus-within:border-cornflower-blue",
                  classNameForInputParentDiv
                )}
                isDisabled={disabled}
                isRequired={
                  required
                    ? typeof required === "boolean"
                      ? required
                      : typeof required === "string"
                      ? required?.length > 0
                        ? true
                        : false
                      : required?.value
                    : undefined
                }
              >
                <Group
                  className={
                    "w-full relative flex justify-center items-center peer bg-inherit rounded-[inherit]"
                  }
                >
                  <NumberInput
                    className={twMerge(
                      "w-full rounded-[inherit] peer appearance-none focus:outline-none bg-inherit px-4 py-2",
                      disabled ? "cursor-not-allowed text-disabled" : "",
                      label ? "placeholder-transparent" : "",
                      classForInput
                    )}
                    placeholder={placeholder || "Enter..."}
                    id={name}
                  />
                  {!hideLabel && label && (
                    <Label
                      htmlFor={name}
                      className={twMerge(
                        "px-0.5 bg-inherit absolute max-w-[calc(100%-10%)] truncate -top-[9px] left-3.5 text-xs peer-placeholder-shown:top-[9px] peer-placeholder-shown:text-sm transition-all duration-150 peer-focus:-top-[9px] peer-focus:text-xs",
                        disabled
                          ? "text-disabled cursor-not-allowed"
                          : errorMessage
                          ? "text-error peer-focus:text-error cursor-text"
                          : "peer-focus:text-cornflower-blue text-warm-gray/70 cursor-text"
                      )}
                    >
                      {label}
                    </Label>
                  )}
                </Group>
              </NumberField>
            ) : (
              <div
                className={twMerge(
                  "w-full relative border rounded flex justify-center items-center min-h-[45px]",
                  disabled
                    ? "cursor-not-allowed bg-surface-disabled border-disabled/50"
                    : errorMessage
                    ? "bg-inherit border-error focus-within:border-error"
                    : "bg-inherit  focus-within:border-cornflower-blue",
                  classNameForInputParentDiv
                )}
              >
                <input
                  {...restProps}
                  onChange={(e) => {
                    onChange(e?.target?.value as PathValue<TFieldValues, Name>);
                  }}
                  value={value || ""}
                  id={name}
                  type={inputFieldType}
                  placeholder={placeholder || "Enter..."}
                  autoComplete={
                    disablePasswordAutoComplete ? "new-password" : "off"
                  }
                  tabIndex={tabIndex ? -1 : 0}
                  min={minimumNumber}
                  disabled={disabled}
                  inputMode={
                    inputFieldType === "number" ? "decimal" : undefined
                  }
                  step={inputFieldType === "number" ? "any" : undefined}
                  maxLength={maxLength ? maxLength : undefined}
                  className={twMerge(
                    "w-full rounded-[inherit] peer appearance-none focus:outline-none bg-inherit px-4 py-2",
                    disabled ? "cursor-not-allowed text-disabled" : "",
                    label ? "placeholder-transparent" : "",
                    classForInput
                  )}
                />
                {!hideLabel && label && (
                  <label
                    htmlFor={name}
                    className={twMerge(
                      "px-0.5 bg-inherit absolute max-w-[calc(100%-10%)] text-warm-gray/70 truncate -top-2.5 left-3.5 text-xs peer-placeholder-shown:top-[9px] peer-placeholder-shown:text-sm transition-all duration-150 peer-focus:-top-[9px] peer-focus:text-xs",
                      disabled
                        ? "text-disabled cursor-not-allowed"
                        : errorMessage
                        ? "text-error peer-focus:text-error cursor-text"
                        : "peer-focus:text-cornflower-blue text-warm-gray/70 cursor-text"
                    )}
                  >
                    {label}
                  </label>
                )}
              </div>
            )}

            {hideError ? null : (
              <div className={`w-[inherit] px-4 ${classNameForError}`}>
                <span
                  className={`text-error text-xs ${
                    errorMessage ? "visible" : "invisible"
                  }`}
                >
                  {errorMessage ? errorMessage : "Helper Text"}
                </span>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default Input;
