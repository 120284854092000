import { FC, Fragment, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { HiAnnotation, HiTrash } from "react-icons/hi";

import { SingleActionPopup } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Tooltip from "components/UI/ToolTip";

import { ReactComponent as PencilIcon } from "global/assets/images/pencil-dark-icon.svg";
import { toastNotify } from "global/helpers/Cache";

import { UPDATE_APPLY_COMPENSATION_LEAVE } from "modules/Leave/services/mutations";
import { FILTER_MY_COMPENSATION } from "modules/Leave/services/queries";

interface IProps {
  leaveRequestId: number;
  date: any;
  reason: any;
  setWithdrawVerification: Function;
  setWithdrawSingleDate: Function;
  deleteVerification: boolean;
  setDeleteVerification: Function;
  requestToUser: { personalInformation: { id: number; name: string } };
}
const DatesDetails: FC<IProps> = ({
  leaveRequestId,
  date,
  reason,
  setWithdrawVerification,
  setWithdrawSingleDate,
  requestToUser,
}) => {
  const navigate = useNavigate();

  const [showDeclineMsg, setShowDeclineMsg] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [
    updateApplyCompensationLeave,
    { loading: updateApplyCompensationLeaveLoading },
  ] = useMutation(UPDATE_APPLY_COMPENSATION_LEAVE, {
    refetchQueries: [FILTER_MY_COMPENSATION],
  });

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const withdrawHandler = () => {
    if (!updateApplyCompensationLeaveLoading) {
      updateApplyCompensationLeave({
        variables: {
          updateApplyCompensationLeaveInput: {
            id: +leaveRequestId,
            leaveRequestDates: [{ id: +date?.id, status: "Withdrawn" }],
          },
        },
      })
        .then((response) => {
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your request has been withdrawn successfully.",
            },
          ]);
          if (response?.data?.updateApplyLeave?.leaveRequestDates?.length < 1) {
            navigate("/leaves/requests/my-comp-offs");
          }
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <div className="flex text-sm text-ironside-gray items-center">
      <span className="min-w-[80px]">{date?.leaveDate || ""}</span>
      &nbsp;&nbsp;-&nbsp;&nbsp;
      <span className="min-w-[100px]">
        {date.session === "2 Hours"
          ? `${date.session || ""} (${date?.startTime || ""} - ${
              date?.endTime || ""
            })`
          : date.session || ""}
      </span>
      <span
        className={`${
          date?.status === "Pending"
            ? "text-yellow-orange"
            : date?.status === "Approved"
            ? "text-dark-mint"
            : date?.status === "Declined"
            ? "text-brink-pink"
            : date?.status === "Cancelled"
            ? "text-valentine-red"
            : date?.status === "Withdrawn"
            ? "text-cornflower-blue"
            : ""
        } min-w-[80px] ml-2`}
      >
        {date?.status || ""}
      </span>
      {date?.status === "Approved" &&
        new Date(date?.leaveDate?.replaceAll("-", " ")) >= today && (
          <Tooltip render="Withdraw" arrow placement="top">
            <span>
              <MdOutlineClose
                className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer ml-2"
                onClick={() => {
                  setWithdrawVerification(true);
                  setWithdrawSingleDate({
                    id: +date?.id,
                    status: "Withdrawn",
                  });
                }}
              />
            </span>
          </Tooltip>
        )}
      {date?.status === "Declined" ? (
        <Tooltip render="Decline Message" arrow placement="top">
          <span>
            <HiAnnotation
              onClick={() => {
                setShowDeclineMsg(true);
              }}
              className="w-5 h-5 cursor-pointer text-brown-rust/70"
            />
          </span>
        </Tooltip>
      ) : null}
      {date?.status === "Pending" && !date?.leaveType?.isSpecialLeave ? (
        <Fragment>
          <Tooltip render="Edit" arrow placement="top">
            <span
              className="w-7 h-7 hover:bg-titan-white text-cornflower-blue rounded-full cursor-pointer flex justify-center items-center"
              onClick={() => {
                navigate("/leaves/apply/comp-off", {
                  state: {
                    id: +leaveRequestId,
                    dates: [date],
                    reason: reason,
                    isEditing: true,
                    requestToUser,
                  },
                });
              }}
            >
              <PencilIcon className="w-4 h-5" />
            </span>
          </Tooltip>
          <Tooltip render="Delete" arrow placement="top">
            <span
              className="w-7 h-7 hover:bg-pale-pink text-valentine-red rounded-full cursor-pointer flex justify-center items-center"
              onClick={() => setDeleteVerification(true)}
            >
              {updateApplyCompensationLeaveLoading ? (
                <div className="btn-spinner" />
              ) : (
                <HiTrash className="w-5 h-4" />
              )}
            </span>
          </Tooltip>
        </Fragment>
      ) : null}
      {deleteVerification && (
        <ConfirmModal
          header="Comp Off Request"
          keyValue="Delete"
          onCancel={() => {
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={withdrawHandler}
          loading={updateApplyCompensationLeaveLoading}
        />
      )}
      {showDeclineMsg ? (
        <SingleActionPopup
          header={"Decline Message"}
          onExecute={() => setShowDeclineMsg(false)}
          message={date?.declineMessage ? date?.declineMessage : ""}
        />
      ) : null}
    </div>
  );
};

export default DatesDetails;
