import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { HiCheck } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";

import UserDetails from "components/UserDetails/UserDetails";
import Tooltip from "components/UI/ToolTip";
import { Modal } from "components/forms";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";
import { toastNotify } from "global/helpers/Cache";

import { UPDATE_COMPENSATION_LEAVE_STATUS } from "modules/Leave/services/mutations";
import { FILTER_TEAM_COMPENSATION } from "modules/Leave/services/queries";

interface Iprops {
  request: any;
  userId: number | undefined;
  setUserId: Function;
}

const TableDetailsGrid: React.FC<Iprops> = ({ request, userId, setUserId }) => {
  const navigate = useNavigate();
  const project = request?.dates?.length > 0 && request?.dates[0]?.project;

  const profileRef = useRef<HTMLDivElement>(null);
  const [topProfileRef, setTopProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );
  const [leftProfileRef, setleftProfileRef] = useState<number | undefined>(
    profileRef.current?.getBoundingClientRect().top
  );

  const getPositionOfProfileName = useCallback(() => {
    setTopProfileRef(
      profileRef.current?.getBoundingClientRect().top &&
        profileRef.current?.getBoundingClientRect().top + 25
    );
    setleftProfileRef(
      profileRef.current?.getBoundingClientRect().left &&
        profileRef.current?.getBoundingClientRect().left
    );
  }, []);

  useEffect(() => {
    const eventRef = profileRef.current;

    window.addEventListener("resize", getPositionOfProfileName);
    window.addEventListener("scroll", getPositionOfProfileName);

    eventRef &&
      eventRef?.addEventListener("mouseenter", getPositionOfProfileName);

    return () => {
      window.removeEventListener("resize", getPositionOfProfileName);
      window.removeEventListener("scroll", getPositionOfProfileName);
      eventRef &&
        eventRef?.removeEventListener("mouseenter", getPositionOfProfileName);
    };
  }, [getPositionOfProfileName]);

  const [
    updateCompensationLeaveStatus,
    { loading: updateCompensationLeaveStatusLoading },
  ] = useMutation(UPDATE_COMPENSATION_LEAVE_STATUS, {
    refetchQueries: [FILTER_TEAM_COMPENSATION],
  });

  const [showModal, setShowModal] = useState(false);
  const { register, watch } = useForm();
  const reason = watch("reason");

  const hasPending = request?.dates
    ?.map((date: any) => date?.status)
    .includes("Pending");

  const confirmHandler = (request: any) => {
    if (!updateCompensationLeaveStatusLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            leaveRequestDates: request?.dates?.map((date: { id: number }) => {
              return {
                id: date?.id,
                status: "Approved",
              };
            }),
            id: +request?.id,
          },
        },
      })
        .then((response: any) => {
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/team-comp-off");
          }
        })
        .catch((error: any) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const declineHandler = (request: any) => {
    if (!updateCompensationLeaveStatusLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            id: +request?.id,
            leaveRequestDates: request?.dates?.map((date: { id: number }) => {
              return {
                id: +date?.id,
                status: "Declined",
                declineMessage: reason,
              };
            }),
          },
        },
      })
        .then((response) => {
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          setShowModal(false);
          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/team-comp-off");
          }
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {request?.id}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate">
        <div className={`group w-min`} ref={profileRef}>
          <span
            onMouseEnter={() =>
              setUserId(request?.requestedByUser?.personalInformation?.id)
            }
          >
            {request?.requestedBy ? (
              request?.requestedBy?.personalInformation?.name
            ) : (
              <span className="text-gray-400">{"Eg.Person Name"}</span>
            )}
          </span>
          <div
            style={{
              top: `${topProfileRef}px`,
              left: `${leftProfileRef}px`,
            }}
          >
            <UserDetails userId={userId} />
          </div>
        </div>
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate">
        {project?.name || "-"}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.leaveDate}
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {request?.dates?.map((date: any) => date?.leaveDate)}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.session}
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {request?.dates?.map((date: any) => date?.session)}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {request?.dates?.length > 1 ? (
          request?.dates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                <div
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 rounded-[18px]`}
                >
                  {date?.status}
                </div>
              </span>
            );
          })
        ) : request?.dates?.length === 1 ? (
          <span>
            {request?.dates?.map((date: any, index: number) => {
              return (
                <span
                  key={index}
                  className={`${getStatusColor(
                    date?.status
                  )} opacity-100 rounded-[18px] flex justify-center py-5 px-3`}
                >
                  {date?.status}
                </span>
              );
            })}
          </span>
        ) : (
          <span className="text-gray-400">Eg.Pending</span>
        )}
      </td>
      <td>
        {hasPending ? (
          <div>
            {updateCompensationLeaveStatusLoading ? (
              <div className="w-5 h-5 border-4 border-t-transparent ml-2 border-cornflower-blue border-solid rounded-full animate-spin"></div>
            ) : (
              <div className="flex flex-col items-center gap-3 py-1">
                <Tooltip
                  render={request?.isConsentRequest ? "Agree" : "Approve"}
                  arrow
                  placement="top"
                >
                  <HiCheck
                    className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
                    onClick={() => {
                      confirmHandler(request);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  render={request?.isConsentRequest ? "Disagree" : "Decline"}
                  arrow
                  placement="top"
                >
                  <MdOutlineClose
                    className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink cursor-pointer"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <p className="text-center">-</p>
        )}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate whitespace-nowrap">
        <span
          className="grid place-content-center cursor-pointer hover:text-cornflower-blue hover:underline"
          onClick={() => {
            navigate(`${request?.id}`);
          }}
        >
          View
        </span>
      </td>
      {showModal ? (
        <td>
          <Modal>
            <div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
              <div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
                <h2 className="text-base text-ironside-gray">
                  Comp Off Request Decline
                </h2>
                <div
                  className="edit-modal-close-icon-div"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
                </div>
              </div>

              <div className="w-full py-[20px] px-[20px]">
                <textarea
                  className="w-full h-[200px] resize-none border border-gray-200 focus:outline-none p-4"
                  placeholder="Type your reason here"
                  {...register("reason")}
                />
              </div>
              <div className="w-full border-b" />
              <div className="flex items-center justify-center p-6">
                <button
                  type="button"
                  onClick={() => {
                    declineHandler(request);
                  }}
                  className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
                >
                  {updateCompensationLeaveStatusLoading ? (
                    <div className="btn-spinner" />
                  ) : (
                    "Decline"
                  )}
                </button>
              </div>
            </div>
          </Modal>
        </td>
      ) : null}
    </tr>
  );
};

export default TableDetailsGrid;
