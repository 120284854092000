import { twMerge } from "tailwind-merge";
import { ComponentPropsWithRef, FC, useEffect, useState } from "react";

type Props = Omit<ComponentPropsWithRef<"img">, "src"> & {
  src: string;
  loadingComponentProps?: ComponentPropsWithRef<"div">;
  errorComponentProps?: ComponentPropsWithRef<"div"> & { errorText?: string };
};

const ImageComponent: FC<Props> = (props) => {
  const [imageLoading, setImageLoading] = useState(true);

  const [imageError, setImageError] = useState(false);

  const imageLoadingHandler = () => {
    setImageLoading(false);
  };

  const imageErrorHandler = () => {
    setImageError(true);
    setImageLoading(false);
  };

  useEffect(() => {
    const img = new Image();
    img.src = props?.src;

    img.onload = () => {
      setImageLoading(false);
    };

    img.onerror = () => {
      setImageError(true);
      setImageLoading(false);
    };
  }, [props?.src]);

  return imageLoading ? (
    <div
      className={twMerge(
        "w-full h-full border rounded-xl flex justify-center items-center overflow-hidden relative before:absolute before:inset-0 before:-translate-x-full before:animate-shimmer before:border-t before:border-slate-200 before:bg-gradient-to-r before:from-transparent before:via-slate-200 before:to-transparent",
        props?.loadingComponentProps?.className || props?.className || ""
      )}
    />
  ) : imageError ? (
    <div
      className={twMerge(
        "w-full h-full rounded-xl flex justify-center items-center",
        props?.errorComponentProps?.className
      )}
    >
      {props?.errorComponentProps?.errorText || "Error..."}
    </div>
  ) : (
    <img
      onLoad={imageLoadingHandler}
      onError={imageErrorHandler}
      className={twMerge("w-full h-full object-cover", props?.className || "")}
      loading={props?.loading || "lazy"}
      {...props}
    />
  );
};

export default ImageComponent;
