import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
} from "react-hook-form";
import {
  CalendarDateTime,
  Time as TimeType,
  ZonedDateTime,
} from "@internationalized/date";

import { TimeProps } from "components/forms/UpdatedFormAgry/Time/types";
import TimeField from "components/forms/UpdatedFormAgry/Date/Date/TimeField";

const Time = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus,
  defaultValue,
  description,
  granularity,
  isDisabled,
  isReadOnly,
  isRequired,
  label,
  maxValue,
  minValue,
  onBlur,
  onChange,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  placeholderValue,
  className,
  name,
  control,
  required,
  hideError,
  shouldUnregister,
  classNameForError,
  classForTimeField,
  ...props
}: TimeProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends
    | CalendarDateTime
    | ZonedDateTime
    | TimeType
    | null
    | undefined
    ? {
        helperType?: never;
        invalidType?: never;
      }
    : {
        helperType?: undefined;
        invalidType: "Type must be CalendarDateTime | ZonedDateTime  | Time | null | undefined";
      })) => {
  return (
    <Controller
      name={name}
      render={({
        field: { value, onChange: onChangeHookForm },
        fieldState: { error },
      }) => {
        const errorMessage = error?.message;
        return (
          <TimeField
            autoFocus={autoFocus}
            description={description}
            errorMessage={errorMessage}
            granularity={granularity}
            hourCycle={12}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            label={label}
            maxValue={maxValue}
            minValue={minValue}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onFocusChange={onFocusChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            placeholderValue={placeholderValue}
            value={value}
            className={className}
            onChangeHookForm={onChangeHookForm}
            hideError={hideError}
            classNameForError={classNameForError}
            classForTimeField={classForTimeField}
            {...props}
          />
        );
      }}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue as PathValue<TFieldValues, Name>}
      rules={{
        required:
          typeof required === "boolean" && required === true
            ? "This is required field."
            : required
            ? required
            : false,
        onChange: (e: any) => {
          if (onChange) {
            onChange(e.target?.value);
          }
        },
      }}
    />
  );
};

export default Time;
