import {
  CSSProperties,
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  useRef,
} from "react";
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useFocusRing,
} from "react-aria";
import { twMerge } from "tailwind-merge";

interface IProps
  extends Omit<
      ComponentPropsWithoutRef<"button">,
      | "onBlur"
      | "onFocus"
      | "onKeyDown"
      | "onKeyUp"
      | "target"
      | "type"
      | "rel"
      | "autoFocus"
      | "children"
      | "className"
      | "disabled"
      | "style"
      | "onClick"
    >,
    Omit<AriaButtonProps<"button">, "children"> {
  className?:
    | string
    | ((data: { isFocusVisible: boolean; isPressed: boolean }) => string);
  style?:
    | CSSProperties
    | ((data: {
        isFocusVisible: boolean;
        isPressed: boolean;
      }) => CSSProperties);
  children: ReactNode;
}

export const CalendarButton: FC<IProps> = ({
  autoFocus,
  children,
  elementType,
  excludeFromTabOrder,
  href,
  id,
  isDisabled,
  onBlur,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onPress,
  onPressChange,
  onPressEnd,
  onPressStart,
  onPressUp,
  rel,
  target,
  type,
  className,
  style,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null!);

  const { buttonProps, isPressed } = useButton(
    {
      autoFocus,
      elementType,
      excludeFromTabOrder,
      href,
      id,
      isDisabled,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      onPressUp,
      rel,
      target,
      type,
    },
    buttonRef
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <button
      ref={buttonRef}
      {...mergeProps(buttonProps, focusProps, props)}
      style={
        style
          ? typeof style === "function"
            ? style({ isFocusVisible, isPressed })
            : style
          : undefined
      }
      className={twMerge(
        `p-1 rounded-full outline-none ${
          isDisabled
            ? "cursor-not-allowed bg-surface-disabled  text-disabled"
            : "cursor-pointer"
        }  ${isFocusVisible ? "ring-2 ring-offset-2 ring-primary" : ""}`,
        className
          ? typeof className === "function"
            ? className({ isFocusVisible, isPressed })
            : className
          : ""
      )}
    >
      {children}
    </button>
  );
};
