import { FC, useRef } from "react";
import { MdOutlineClose } from "react-icons/md";

import Tooltip from "components/UI/ToolTip";

import fileDefault from "global/assets/images/file-blank-solid-240.png";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import fileDoc from "global/assets/images/free-doc-icon-1513-thumb.png";
import { ReactComponent as FileUpload } from "global/assets/images/IconAwesome-file-upload.svg";
import { removeDuplicateObject } from "global/helpers/ArrayMethods";
import { Base64toObject } from "global/helpers/FileConverter";

interface IProps {
  className?: string;
  fileList: any;
  setFileList: Function;
  updatedFiles: {
    id: number;
    filePath: string;
    mimeType?: string | undefined;
    name?: string;
  }[];
  setUpdatedFiles: Function;
  otherFiles?: {
    id: number;
    filePath: string;
    mimeType?: string | undefined;
    name?: string;
  }[];
  setOtherFiles?: Function;
  setAlert?: any;
  supportedMimeType?: string;
  hasDeleteAcess?: boolean;
  isFileSupportMessageNeeded?: boolean;
  isFileLimitNeed?: boolean;
}
const FileDragAndDrop: FC<IProps> = ({
  className,
  fileList,
  setFileList,
  updatedFiles,
  setUpdatedFiles,
  setAlert,
  supportedMimeType = "application/pdf",
  hasDeleteAcess = true,
  isFileSupportMessageNeeded = true,
  otherFiles,
  setOtherFiles,
  isFileLimitNeed = true,
}) => {
  const ImageConfig: any = {
    default: fileDefault,
    pdf: filePdf,
  };
  const wrapperRef: any = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (event: any) => {
    if (event.target.files[0]?.size > 2e6 && isFileLimitNeed) {
      return setAlert
        ? setAlert([
            {
              messageType: "Error",
              message: "Maximum file size limit is 2MB.",
            },
          ])
        : alert("Maximum file size limit is 2MB.");
    } else if (!event.target.validity.valid) {
      return setAlert([
        {
          messageType: "Error",
          message: "Please upload a valid file.",
        },
      ]);
    } else {
      const newFile = event.target.files[0];
      if (newFile) {
        const updatedList = [...fileList, newFile];
        setFileList(removeDuplicateObject(updatedList, "name"));
      }
    }
  };

  const fileRemove = (file: any) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
  };

  return (
    <div className={`w-full ${className ? className : ""}`}>
      <label
        htmlFor="file-upload"
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className="block rounded-md border-2 border-dashed bg-tropical-blue/20 relative cursor-pointer"
      >
        <div className="flex justify-center items-center p-3 gap-3">
          <FileUpload />
          <p className="text-sm text-ironside-gray">
            Drag & drop your files here or browse
          </p>
        </div>
        <input
          onChange={(event: any) => {
            onFileDrop(event);
          }}
          accept={supportedMimeType}
          type="file"
          id="file-upload"
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
      </label>
      {isFileSupportMessageNeeded && (
        <div className="text-gray-700 text-xs pt-[4px] mt-2">
          Supported Formats: .pdf, Max Limit: 2 MB
        </div>
      )}
      {fileList.length > 0 ? (
        <div className="space-y-5">
          {fileList.map((item: any, index: number) => (
            <div
              key={index}
              className="flex justify-between items-center gap-1 rounded bg-titan-white p-3 first:mt-3"
            >
              <img
                className="w-10 h-10"
                src={
                  ImageConfig[item?.type?.split("/")[1]] ||
                  ImageConfig["default"]
                }
                alt="upload File"
              />
              <p className="flex-1 truncate text-sm">{item.name}</p>

              <MdOutlineClose
                className="w-6 h-6 cursor-pointer text-cornflower-blue"
                onClick={() => fileRemove(item)}
              />
            </div>
          ))}
        </div>
      ) : null}
      {(updatedFiles?.length > 0 || (otherFiles && otherFiles?.length > 0)) &&
      !isFileSupportMessageNeeded ? (
        <div className="flex flex-wrap mt-5">
          {updatedFiles?.length > 0 &&
            updatedFiles?.map((files: any, index: number) => {
              return (
                <div key={index} className="flex relative">
                  {files?.filePath?.length > 100 ? (
                    <a
                      href={Base64toObject(files?.filePath)}
                      target="_blank"
                      rel="noreferrer"
                      className="flex justify-center items-center"
                    >
                      {isFileSupportMessageNeeded ? (
                        <div className="flex">
                          <img src={filePdf} alt="PDF" className="w-12" />
                        </div>
                      ) : !isFileSupportMessageNeeded &&
                        !files?.mimeType?.includes("video") ? (
                        <Tooltip render={`${files?.name || ""}`} arrow>
                          <div className="flex">
                            <img src={fileDoc} alt="PDF" className="w-12" />
                          </div>
                        </Tooltip>
                      ) : (
                        <video controls className="pt-2 w-full">
                          <source
                            type={files?.mimeType}
                            src={`data:${files?.mimeType};base64,${files?.filePath}`}
                          />
                        </video>
                      )}
                    </a>
                  ) : (
                    <div className="flex">
                      <img src={fileDefault} alt="No File" className="w-12" />
                    </div>
                  )}
                  {hasDeleteAcess && (
                    <MdOutlineClose
                      onClick={() => {
                        setUpdatedFiles(
                          updatedFiles?.filter(
                            (data: any) => data?.id !== files?.id
                          )
                        );
                      }}
                      className="w-4 h-4 rounded-full absolute top-0 right-0 border bg-white cursor-pointer"
                    />
                  )}
                </div>
              );
            })}
          {otherFiles &&
            otherFiles?.length > 0 &&
            otherFiles?.map((files: any, index: number) => {
              return (
                <div key={index} className="flex relative">
                  {files?.filePath?.length > 100 ? (
                    <a
                      href={Base64toObject(files?.filePath)}
                      target="_blank"
                      rel="noreferrer"
                      className="flex justify-center items-center"
                    >
                      {isFileSupportMessageNeeded ? (
                        <div className="flex">
                          <img src={filePdf} alt="PDF" className="w-12" />
                        </div>
                      ) : !isFileSupportMessageNeeded &&
                        !files?.mimeType?.includes("video") ? (
                        <Tooltip render={`${files?.name || ""}`} arrow>
                          <div className="flex">
                            <img src={fileDoc} alt="PDF" className="w-12" />
                          </div>
                        </Tooltip>
                      ) : (
                        <video controls className="pt-2 w-full">
                          <source
                            type={files?.mimeType}
                            src={`data:${files?.mimeType};base64,${files?.filePath}`}
                          />
                        </video>
                      )}
                    </a>
                  ) : (
                    <div className="flex">
                      <img src={fileDefault} alt="No File" className="w-12" />
                    </div>
                  )}
                  <MdOutlineClose
                    onClick={() => {
                      if (setOtherFiles) {
                        setOtherFiles(
                          otherFiles?.filter(
                            (data: any) => data?.id !== files?.id
                          )
                        );
                      }
                    }}
                    className="w-4 h-4 rounded-full absolute top-0 right-0 border bg-white cursor-pointer"
                  />
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default FileDragAndDrop;
