import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { HiCheck } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import OopsNoData from "components/UI/OopsNoData";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Loading from "components/Loader/Loading";
import UserDetails from "components/UserDetails/UserDetails";
import Tooltip from "components/UI/ToolTip";
import { Modal } from "components/forms";

import { getStatusColor } from "global/helpers/LeaveModuleStatusHelper";
import { toastNotify } from "global/helpers/Cache";
import { ILeaveRequestDates } from "global/types/type";

import TableDetailsGrid from "modules/Leave/Pages/Requests/TeamCompOff/TableDetailsGrid";
import {
  IFilterLeaveRequestTypes,
  teamOrAllCompensationTypes,
} from "modules/Leave/types/leave";
import { FILTER_TEAM_COMPENSATION } from "modules/Leave/services/queries";
import { UPDATE_COMPENSATION_LEAVE_STATUS } from "modules/Leave/services/mutations";

const tableHeadings = [
  "ID",
  "Name",
  "Project",
  "Date",
  "Session",
  "Status",
  "Action",
  "Details",
];

const TeamCompOffDetails: FC<{
  requestData: any;
  pageSize: any;
  currentPage: any;
  setPageSize: any;
  setCurrentPage: any;
  totalItems: number;
  loading: boolean;
  filterTeamCompensationRequests: teamOrAllCompensationTypes;
}> = ({
  requestData,
  pageSize,
  currentPage,
  setPageSize,
  setCurrentPage,
  totalItems,
  loading,
  filterTeamCompensationRequests,
}) => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState<number | undefined>();

  const onViewHandler = (request: any) => {
    navigate(`${request?.id}`);
  };

  const [
    updateCompensationLeaveStatus,
    { loading: updateCompensationLeaveStatusLoading },
  ] = useMutation(UPDATE_COMPENSATION_LEAVE_STATUS, {
    refetchQueries: [FILTER_TEAM_COMPENSATION],
  });

  const [editRequest, setEditRequest] = useState<{
    request: IFilterLeaveRequestTypes;
    date: ILeaveRequestDates;
  } | null>(null);

  const [showModal, setShowModal] = useState(false);
  const { register, watch } = useForm();
  const reason = watch("reason");

  const confirmHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    if (!updateCompensationLeaveStatusLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            leaveRequestDates: [{ id: date?.id, status: "Approved" }],
            id: +request?.id,
          },
        },
      })
        .then((response: any) => {
          setEditRequest(null);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/team-comp-off");
          }
        })
        .catch((error: any) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const declineHandler = (
    date: ILeaveRequestDates,
    request: IFilterLeaveRequestTypes
  ) => {
    if (!updateCompensationLeaveStatusLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            id: +request?.id,
            leaveRequestDates: [
              { id: +date?.id, status: "Declined", declineMessage: reason },
            ],
          },
        },
      })
        .then((response) => {
          setEditRequest(null);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          setShowModal(false);
          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/team-comp-off");
          }
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const getDataModal = (date: any, request: any) => {
    const leaveActions = {
      lable: "Action",
      value: [
        {
          content: (
            <div className="flex">
              {updateCompensationLeaveStatusLoading &&
              editRequest?.date?.id === date?.id ? (
                <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-cornflower-blue border-solid rounded-full animate-spin"></div>
              ) : (
                <Tooltip render="Approve" arrow placement="top">
                  <HiCheck
                    className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
                    onClick={() => {
                      setEditRequest({
                        date,
                        request,
                      });
                      confirmHandler(date, request);
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip render={"Decline"} arrow placement="top">
                <MdOutlineClose
                  className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer ml-2"
                  onClick={() => {
                    setShowModal(true);
                    setEditRequest({
                      date,
                      request,
                    });
                  }}
                />
              </Tooltip>
            </div>
          ),
        },
      ],
    };

    const commonVariables = [
      {
        lable: "ID",
        value: [
          {
            content: request?.id,
          },
        ],
      },
      {
        lable: "Name",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() =>
                    setUserId(request?.requestedByUser?.personalInformation?.id)
                  }
                >
                  {request?.requestedByUser?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Project",
        value: [
          {
            content: date?.project?.name,
          },
        ],
      },
      {
        lable: "Date",
        value: [
          {
            content: date?.leaveDate,
          },
        ],
      },
      {
        lable: "Session",
        value: [
          {
            content: date?.session,
          },
        ],
      },
      {
        lable: "Status",
        value: [
          {
            content: date?.status,
            color: getStatusColor(date?.status),
          },
        ],
      },
    ];

    return date?.status === "Pending"
      ? [...commonVariables, leaveActions]
      : commonVariables;
  };

  return (
    <Fragment>
      <div className="hidden md:block rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray md:mt-[5px]">
        <div className="flex flex-col bg-white justify-between min-h-[560px]">
          <Table
            noOfItems={filterTeamCompensationRequests && requestData?.length}
            currentPage={currentPage}
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={totalItems || 0}
            isCenterlastHeading={true}
            className="min-h-[590px]"
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: name === "Name" || name === "Project" ? false : true,
              };
            })}
          >
            {requestData?.map((data: any, index: number) => {
              return (
                <TableDetailsGrid
                  request={data}
                  key={index}
                  userId={userId}
                  setUserId={setUserId}
                />
              );
            })}
          </Table>
        </div>
      </div>
      <div className="md:hidden lg:px-[15px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] bg-white md:border-none border-light-gray md:mt-[30px]">
        <div className="flex flex-col bg-white">
          {loading ? (
            <Loading className="min-h-[400px]" />
          ) : requestData?.length > 0 ? (
            <Fragment>
              <div className="w-full overflow-x-auto min-h-[412px]">
                {requestData?.map((request: any) => {
                  return request?.dates?.map((date: any, index: number) => {
                    return (
                      <SMDataCard
                        key={index}
                        title={`Request`}
                        loading={loading}
                        dataModal={getDataModal(date, request)}
                        onView={() => {
                          onViewHandler(request);
                        }}
                      />
                    );
                  });
                })}
              </div>
              <Pagination
                totalCount={totalItems ? totalItems : 0}
                noOfItem={requestData?.length}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </Fragment>
          ) : (
            filterTeamCompensationRequests && <OopsNoData />
          )}
        </div>
      </div>
      {showModal ? (
        <Modal>
          <div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
            <div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
              <h2 className="text-base text-ironside-gray">
                Comp Off Request Decline
              </h2>
              <div
                className="edit-modal-close-icon-div"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
              </div>
            </div>

            <div className="w-full py-[20px] px-[20px]">
              <textarea
                className="w-full h-[200px] resize-none border border-gray-200 focus:outline-none p-4"
                placeholder="Type your reason here"
                {...register("reason")}
              />
            </div>
            <div className="w-full border-b" />
            <div className="flex items-center justify-center p-6">
              <button
                type="button"
                onClick={() => {
                  if (editRequest) {
                    declineHandler(editRequest?.date, editRequest?.request);
                  }
                }}
                className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
              >
                {updateCompensationLeaveStatusLoading ? (
                  <div className="btn-spinner" />
                ) : (
                  "Decline"
                )}
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </Fragment>
  );
};

export default TeamCompOffDetails;
