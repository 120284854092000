import { MdOutlineClose } from "react-icons/md";
import { FieldPath, FieldValues, UseFormRegister } from "react-hook-form";

import RoundedOutlined from "components/forms/Button/RoundedOutlined";
import RoundedFilled from "components/forms/Button/RoundedFilled";
import { Modal } from "components/forms/index";

import { Capitalize } from "global/helpers/Capitalize";

interface IProps<TForm extends FieldValues> {
  header: string;
  onXIcon?: any;
  onCancel?: () => void;
  onExecute?: () => void;
  keyValue?: string;
  btnKeyValue?: string;
  loading?: boolean;
  customMessage?: string;
  showPermanentDelete?: boolean;
  register?: UseFormRegister<TForm>;
  name?: FieldPath<TForm>;
}
const ConfirmModal = <TForm extends FieldValues>({
  header,
  onXIcon,
  onCancel,
  onExecute,
  keyValue,
  btnKeyValue,
  loading,
  customMessage,
  showPermanentDelete,
  register,
  name,
}: IProps<TForm>) => {
  return (
    <Modal>
      <div className="bg-white rounded-[12px] shadow-[0px_-3px_6px_#00000029] w-[320px] lg:w-[563px]">
        <div className="flex justify-between py-[20px] lg:py-[25px] px-[26px] border-b border-hit-gray">
          <div className="text-base text-ironside-gray">{header}</div>
          <div className="edit-modal-close-icon-div" onClick={onXIcon}>
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div className="text-sm text-warm-gray py-[25px] lg:py-[30px] mx-auto pl-7 lg:pl-[25px] flex flex-col gap-3 justify-center lg:justify-start">
          {`Are you sure you want to ${
            keyValue ? keyValue : customMessage ? customMessage : "delete"
          }?`}
          {showPermanentDelete && register && name && (
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                {...register(name)}
                className="cursor-pointer"
                id={name}
              />
              <label
                htmlFor={name}
                id={name}
                className="cursor-pointer select-none"
              >
                Delete permanently
              </label>
            </div>
          )}
        </div>
        <div className="flex justify-center lg:justify-end px-[26px] pt-[25px] pb-[27px] space-x-[33px] border-t border-hit-gray">
          <RoundedOutlined
            buttonName="Cancel"
            onClick={onCancel}
            isConfirmModal={true}
          />
          <RoundedFilled
            buttonName={
              btnKeyValue
                ? Capitalize(btnKeyValue)
                : keyValue
                ? Capitalize(keyValue)
                : "Delete"
            }
            onClick={onExecute}
            isConfirmModal={true}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
