import { useRef } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import AllNotificationMessages from "./ViewAllMessages/AllMessages";
import ReadMessages from "./ViewAllMessages/ReadMessages";
import UnreadMessages from "./ViewAllMessages/UnreadMessages";

const ViewAllNotification = () => {
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;
  return (
    <div className="flex flex-col w-full min-h-[785px]">
      <div
        ref={slider}
        onMouseDown={(e) => {
          isDown = true;
          startX = e?.pageX - slider.current?.offsetLeft;
          scrollLeft = slider?.current?.scrollLeft;
        }}
        onMouseLeave={() => {
          isDown = false;
        }}
        onMouseUp={() => {
          isDown = false;
        }}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider?.current?.offsetLeft;
          const walk = (x - startX) * 1;
          slider.current.scrollLeft = scrollLeft - walk;
        }}
        className="tab-nav"
      >
        <NavLink
          className={(navData) =>
            navData.isActive
              ? "profile-navlink  profile-navlink-active"
              : "profile-navlink  profile-navlink-nonactive"
          }
          to={"/notification/all"}
        >
          All
        </NavLink>
        <NavLink
          className={(navData) =>
            navData.isActive
              ? "profile-navlink  profile-navlink-active"
              : "profile-navlink  profile-navlink-nonactive"
          }
          to={"/notification/unread"}
        >
          Unread
        </NavLink>
        <NavLink
          className={(navData) =>
            navData.isActive
              ? "profile-navlink  profile-navlink-active"
              : "profile-navlink  profile-navlink-nonactive"
          }
          to={"/notification/read"}
        >
          Read
        </NavLink>
      </div>
      <Routes>
        <Route path="all" element={<AllNotificationMessages />} />
        <Route path="unread" element={<UnreadMessages />} />
        <Route path="read" element={<ReadMessages />} />
      </Routes>
    </div>
  );
};

export default ViewAllNotification;
