const Toggleswitch: React.FC<{
  label: string;
  register: Function;
  name: string;
  defaultValue?: boolean;
}> = ({ label, register, name, defaultValue }) => {
  return (
    <div className="relative inline-block w-[67px] h-[30px] mr-2 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        id={label}
        className="toggle-checkbox absolute block w-[30px] h-[30px] rounded-full bg-white border-4 appearance-none cursor-pointer"
        {...register(name)}
        defaultChecked={defaultValue ? defaultValue : false}
      />
      <label
        htmlFor={label}
        className="toggle-label block overflow-hidden h-[30px] rounded-full bg-[#E1E1E1] cursor-pointer"
      />
    </div>
  );
};
export default Toggleswitch;
