import { useMutation } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Filled,
  TextArea,
  CalendarField,
  Outlined,
  ToggleSwitch,
} from "components/forms";

import Modal from "components/forms/Modal/Modal";
import { datehash } from "components/forms/DatePicker/DateFiltering";

import { toastNotify } from "global/helpers/Cache";

import {
  CREATE_HOLIDAY,
  UPDATE_HOLIDAY,
} from "modules/Leave/services/mutations";
import { HOLIDAYS_COLLECTION } from "modules/Leave/services/queries";
import { IDAndName } from "global/types/type";
import CompanyField from "modules/Company/helpers/CompanyField";

export interface HolidayType {
  holidayDate: number;
  holidayMonth: number;
  holidayYear: number;
  reason: string;
  id: number;
  isOptional: boolean;
  isRestrictedHolidayLeave: boolean;
  companies?: IDAndName<number, "label">[];
}

const EditHoliday: React.FC<{
  showModal: any;
  holiday: any;
  setDate: any;
  setList: any;
  setShowModal: any;
  list: any;
  date: any;
  inProgress: boolean;
  setInProgress: Function;
  refetchHolidays: Function;
}> = ({
  setDate,
  setList,
  setShowModal,
  showModal,
  list,
  date,
  holiday,
  inProgress,
  setInProgress,
  refetchHolidays,
}) => {
  const [createHoliday] = useMutation(CREATE_HOLIDAY);
  const [updateHoliday] = useMutation(UPDATE_HOLIDAY);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    if (date) {
      setValue("date", date);
    }

    if (list?.company && list?.company?.length > 0) {
      setValue(
        "companies",
        list?.company?.map((company) => ({
          id: company?.id,
          label: company?.name,
        }))
      );
    }
  }, [date, setValue, list]);

  useEffect(() => {
    if (date) {
      clearErrors("date");
    }
  }, [clearErrors, date]);

  const createHandler: SubmitHandler<any> = (holidayData: HolidayType) => {
    const holidayDate = [holidayData.holidayDate];
    const dateISO = holidayDate;
    const arrIterator: any = dateISO.entries();
    let year;
    let month;
    let date;

    for (let x of arrIterator) {
      let y = x[1].split("-");
      year = +y[2];
      month = +datehash[y[0]];
      date = +y[1];
    }
    if (!inProgress) {
      createHoliday({
        variables: {
          holidayDate: date,
          holidayMonth: month,
          holidayYear: year,
          reason: holidayData?.reason,
          type: holidayData?.isRestrictedHolidayLeave
            ? "Restricted"
            : undefined,
          companyIds:
            holidayData?.companies && holidayData?.companies?.length > 0
              ? holidayData?.companies?.map((company) => company?.id)
              : undefined,
        },
      })
        .then(() => {
          setInProgress(false);
          setShowModal(!showModal);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: "Holiday has been created successfully.",
            },
          ]);
          refetchHolidays();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler: SubmitHandler<any> = (holidayData: any) => {
    const holidayDate = [holidayData.holidayDate];
    const dateISO = holidayDate;
    const arrIterator: any = dateISO.entries();
    let year;
    let month;
    let date;

    for (let x of arrIterator) {
      let y = x[1]?.split("-");
      year = +y[2];
      month = +datehash[y[0]];
      date = +y[1];
    }
    if (!inProgress) {
      updateHoliday({
        variables: {
          id: list ? +list.id : undefined,
          holidayDate: date,
          holidayMonth: month,
          holidayYear: year,
          reason: holidayData.reason,
          type: holidayData?.isRestrictedHolidayLeave
            ? "Restricted"
            : "Regular",
          companyIds:
            holidayData?.companies && holidayData?.companies?.length > 0
              ? holidayData?.companies?.map((company) => company?.id)
              : [],
        },
        update: (cache, { data: { updateHoliday } }) => {
          const exHolidays: {
            holidays: HolidayType[] | any;
          } | null = cache.readQuery({
            query: HOLIDAYS_COLLECTION,
          });

          const updatedHolidays = exHolidays?.holidays?.dataCollection?.map(
            (holiday: HolidayType) => {
              if (holiday?.id === +list.id) {
                return {
                  ...holiday,
                  id: updateHoliday?.id,
                  holidayDate: updateHoliday?.holidayDate,
                  holidayMonth: updateHoliday?.holidayMonth,
                  holidayYear: updateHoliday?.holidayYear,
                  reason: updateHoliday?.reason,
                  isOptional: updateHoliday?.isOptional,
                };
              }
              return holiday;
            }
          );

          cache.writeQuery({
            query: HOLIDAYS_COLLECTION,
            data: {
              holidays: { dataCollection: updatedHolidays },
            },
          });
        },
      })
        .then(() => {
          setInProgress(false);
          setShowModal(!showModal);
          reset();
          setDate(new Date());
          setList(null);
          toastNotify([
            {
              messageType: "success",
              message: "Holiday has been updated successfully.",
            },
          ]);
          refetchHolidays();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  useEffect(() => {
    if (date) {
      setValue(
        "holidayDate",
        date?.toString()?.substring(4, 15)?.replaceAll(" ", "-")
      );
    }
  }, [date, setValue]);

  return (
    <Modal>
      <div className="min-w-[300px] sm:min-w-[400px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
        <div className="edit-leave-modal-header-div px-[28px] sm:px-[45px] rounded">
          <h2 className="edit-modal-heading">Holiday</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setInProgress(false);
              setShowModal(false);
              setDate(new Date());
              setList(null);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <form onSubmit={handleSubmit(list ? updateHandler : createHandler)}>
          <div className="flex flex-col justify-center items-center w-full px-5 pt-5 space-y-[6px]">
            <CalendarField
              register={register}
              errors={errors}
              name={"date"}
              label={"Date *"}
              date={date}
              setDate={setDate}
              required={true}
              minDate={new Date(new Date()?.getFullYear(), 0, 1)}
              maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
            />
            <CompanyField
              control={control}
              name="companies"
              label="Companies"
              className="bg-white"
              multiple
            />
            <div className="w-full">
              <TextArea
                register={register}
                errors={errors}
                name={"reason"}
                label={"Reason *"}
                defaultText={holiday?.reason}
                required={true}
              />
              <div className="px-2 pb-3 grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center">
                <p className="text-sm text-ironside-gray">
                  Is Restricted Holiday ?
                </p>
                <ToggleSwitch
                  label={"Is restricted holiday"}
                  register={register}
                  name={"isRestrictedHolidayLeave"}
                  defaultValue={holiday?.type === "Restricted" ? true : false}
                />
              </div>
            </div>
            <input type="text" {...register("holidayDate")} hidden />
          </div>
          <div className="w-full border-b" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined
              buttonName="Cancel"
              onClick={() => {
                setInProgress(false);
                setShowModal(false);
                setDate(new Date());
                setList(null);
              }}
            />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : list?.id ? (
                  "Update"
                ) : (
                  "Create"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditHoliday;
