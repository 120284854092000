import { useQuery, useReactiveVar } from "@apollo/client";

import { userQueryProps } from "global/helpers/Cache";

import SkillsDetails from "modules/Employee/Pages/Profile/Skills/SkillsDetails/SkillsDetails";
import Certifications from "modules/Employee/Pages/Profile/Skills/Certificates/Certifications";
import { GET_SKILLS_DETAILS } from "modules/Employee/services/queries";

const Skills = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchSkillDetails,
  } = useQuery(GET_SKILLS_DETAILS, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const userSkills =
    (userDetails?.users?.dataCollection &&
      userDetails?.users?.dataCollection[0]?.userSkills) ||
    [];

  return (
    <div className="relative">
      <div className="md:grid md:grid-cols-2 gap-x-3 lg:gap-x-5">
        <SkillsDetails
          loading={loading}
          userSkills={userSkills}
          refetchSkillDetails={refetchSkillDetails}
        />

        <Certifications
          loading={loading}
          userSkills={userSkills}
          refetchSkillDetails={refetchSkillDetails}
        />
      </div>
    </div>
  );
};
export default Skills;
