import { forwardRef, HtmlHTMLAttributes } from "react";

interface IProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  smallLoading?: boolean;
  color?: "red" | "green" | "white";
}

const UpdateLoader = forwardRef<HTMLDivElement, IProps>(
  ({ className, smallLoading, color = "green", ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={`${
          className ? className : "min-h-full"
        } flex justify-center items-center rounded ${
          smallLoading ? "" : "bg-white"
        }`}
        {...rest}
      >
        <span
          className={`${
            smallLoading
              ? "w-5 h-5 border-4 border-t-transparent mx-auto rounded-full animate-spin"
              : "loading"
          } border-t-transparent ${
            color === "red"
              ? "border-x-valentine-red border-b-valentine-red"
              : color === "green"
              ? "border-x-confborder-b-cornflower-blue border-b-cornflower-blue"
              : color === "white"
              ? "border-x-white border-b-white"
              : "border-x-confborder-b-cornflower-blue border-b-cornflower-blue"
          }`}
        />
      </div>
    );
  }
);

export default UpdateLoader;
