import { ProjectGroupFilterFormType } from "modules/Project/Pages/Projects/ProjectGroups/types";

export const filterSubmitValues = (filter: ProjectGroupFilterFormType) => {
  return {
    name:
      filter?.names && filter?.names?.length > 0
        ? {
            inArray: filter?.names?.map((name) => name?.id?.toString()),
          }
        : undefined,
    description: filter?.description?.trim()
      ? { contains: filter?.description?.trim() }
      : undefined,
  };
};
