import { FC } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
	closestCorners,
	defaultDropAnimationSideEffects,
	DndContext,
	DragOverlay,
	DropAnimation,
	KeyboardSensor,
	MeasuringStrategy,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { useParams } from "react-router-dom";
import {
	arrayMove,
	horizontalListSortingStrategy,
	SortableContext,
} from "@dnd-kit/sortable";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { toastNotify } from "global/helpers/Cache";

import { coordinateGetter } from "modules/Project/Pages/Issues/Board/coordinatesGetters";
import Column from "modules/Project/Pages/Issues/Board/Column";
import {
	TBoardType,
	TBoardProjectColumn,
	IProjectTask,
	IProjectColumn,
} from "modules/Project/types/project";
import { COLUMNS_AND_TASKS } from "modules/Project/services/queries";
import {
	SAVE_PROJECT_TASK,
	SET_COLUMN_POSITION,
	SET_PROJECT_TASK_POSITION,
} from "modules/Project/services/mutations";
import Task from "modules/Project/Pages/Issues/Board/Task";
import Loading from "components/Loader/Loading";

interface IProps {
	hasDeleteAccess: boolean;
	hasAllAccess: boolean;
	search: string | undefined;
	disabledColumns?: boolean;
	watchSprint: number;
	hasAnyAccess: boolean;
	filterAssignee: number[] | null;
}

const dropAnimation: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: "0.5",
			},
		},
	}),
};

const Board: FC<IProps> = ({
	hasAllAccess,
	hasDeleteAccess,
	hasAnyAccess,
	search,
	disabledColumns,
	watchSprint,
	filterAssignee,
}) => {
	const [saveProjectTask] = useMutation(SAVE_PROJECT_TASK);
	const [setProjectTaskPosition] = useMutation<
		{},
		{ id: number; currentPosition: number }
	>(SET_PROJECT_TASK_POSITION);
	const [setColumnPosition] = useMutation<{}, { id: number; position: number }>(
		SET_COLUMN_POSITION,
	);

	const [columns, setColumns] = useState<TBoardProjectColumn[]>([]);
	const [activeValue, setActiveValue] = useState<{
		id: number;
		type: TBoardType;
		column?: TBoardProjectColumn;
		task?: IProjectTask;
	} | null>(null);

	const [clonedColumns, setClonedColumns] = useState<
		TBoardProjectColumn[] | null
	>(null);

	const recentlyMovedToNewContainer = useRef(false);
	const { id } = useParams();

	const [fetchColumns, { loading }] = useLazyQuery<
		{
			projectColumns: { dataCollection: IProjectColumn[] };
		},
		{
			filters: {
				projectId: number;
			};
			search?: string | undefined;
			sprintId?: number[];
			assigneeUserIds?: number[];
			isComesUnderBacklog: boolean;
		}
	>(COLUMNS_AND_TASKS, {
		fetchPolicy: "cache-and-network",
	});

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchColumns({
				variables: {
					filters: {
						projectId: +id,
					},
					search: search || undefined,
					sprintId: watchSprint ? [watchSprint] : undefined,
					assigneeUserIds:
						filterAssignee && filterAssignee?.length > 0
							? filterAssignee
							: undefined,
					isComesUnderBacklog: false,
				},
			})
				.then((res) => {
					if (res.data?.projectColumns?.dataCollection) {
						setColumns(
							res.data?.projectColumns?.dataCollection?.map((column) => ({
								id: `${column?.id}-${column?.name}`,
								colour: column?.colour || null,
								description: column?.description || null,
								position: column?.position,
								project: column?.project,
								name: column?.name,
								projectTasks: column?.projectTasks || [],
							})),
						);
					}
				})
				.catch((error) => {
					if (error.name === "AbortError") return;
				});
		}
	}, [fetchColumns, id, search, watchSprint, filterAssignee]);

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 2,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				distance: 2,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter,
		}),
	);

	const onDragCancel = () => {
		if (clonedColumns) {
			setColumns(clonedColumns);
		}

		setActiveValue(null);
		setClonedColumns(null);
	};

	const findContainer = (id: string | number, type: TBoardType) => {
		let result: TBoardProjectColumn | null = null;

		if (type === "container") {
			result = columns.find((column) => column?.id === id) || null;
		} else if (type === "task") {
			result =
				columns.find((column) => {
					if (column?.projectTasks && column?.projectTasks?.length > 0) {
						return column?.projectTasks.find((task) => {
							return task?.id === id;
						});
					}
					return result;
				}) || null;
		}

		return result;
	};

	const findClonedContainer = (id: string | number, type: TBoardType) => {
		let result: TBoardProjectColumn | null = null;
		if (clonedColumns) {
			if (type === "container") {
				result = clonedColumns.find((column) => column?.id === id) || null;
			} else if (type === "task") {
				result =
					clonedColumns.find((column) => {
						if (column?.projectTasks && column?.projectTasks?.length > 0) {
							return column?.projectTasks.find((task) => {
								return task?.id === id;
							});
						}
						return result;
					}) || null;
			}
		}

		return result;
	};

	return columns?.length > 0 ? (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCorners}
			measuring={{
				droppable: {
					strategy: MeasuringStrategy.Always,
				},
			}}
			onDragStart={({ active }) => {
				setActiveValue({
					id: +active?.id,
					type: active?.data?.current?.type,
					task: active?.data?.current?.task,
					column: active?.data?.current?.column,
				});
				setClonedColumns(columns);
			}}
			onDragOver={({ active, over }) => {
				const overId = over?.id;
				if (overId === null) {
					setActiveValue(null);
					return;
				}
				const activeContainer = findContainer(
					active?.id,
					active?.data?.current?.type,
				);
				const overContainer = findContainer(overId!, over?.data?.current?.type);

				if (!overContainer || !activeContainer) {
					return;
				}

				if (activeContainer !== overContainer) {
					setColumns((columns) => {
						const activeItems = columns?.filter(
							(column) => column?.id === activeContainer?.id,
						)[0]?.projectTasks;
						const overItems = columns?.filter(
							(column) => column?.id === overContainer?.id,
						)[0]?.projectTasks;
						const overIndex = overItems.indexOf(
							overItems?.filter((task) => task?.id === overId)[0],
						);
						const activeIndex = activeItems.indexOf(
							activeItems?.filter((task) => task?.id === active?.id)[0],
						);

						let newIndex: number;

						if (typeof overId === "string") {
							newIndex = overItems.length + 1;
						} else {
							const isBelowOverItem =
								over &&
								active.rect.current.translated &&
								active.rect.current.translated.top >
									over.rect.top + over.rect.height;

							const modifier = isBelowOverItem ? 1 : 0;

							newIndex =
								overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
						}

						recentlyMovedToNewContainer.current = true;

						return columns?.map((column) => {
							if (activeContainer?.id === column?.id) {
								return {
									...column,
									projectTasks: activeItems?.filter(
										(task) => task?.id !== active?.id,
									),
								};
							}
							if (overContainer?.id === column?.id) {
								return {
									...column,
									projectTasks: [
										...overItems?.slice(0, newIndex),
										activeItems[activeIndex],
										...overItems?.slice(newIndex, overItems.length),
									],
								};
							}
							return column;
						});
					});
				}
			}}
			onDragEnd={({ active, over }) => {
				// column change
				if (
					active?.id &&
					active.data?.current?.type === "container" &&
					over?.id
				) {
					const activeIndex = active?.data?.current?.currentIndex;
					const overIndex = over?.data?.current?.currentIndex as number;
					if (activeIndex !== overIndex) {
						setColumns((columns) => {
							return arrayMove(columns, activeIndex, overIndex);
						});
						setColumnPosition({
							variables: {
								id: active?.data?.current?.id,
								position: +over?.data?.current?.currentIndex,
							},
						}).catch((err) => {
							toastNotify([
								{
									messageType: "error",
									message: err.message,
								},
							]);
							onDragCancel();
						});
						return;
					}
				}
				const activeContainer = findContainer(
					active?.id,
					active?.data?.current?.type,
				);

				const overId = over?.id;

				if (overId === null) {
					setActiveValue(null);
					return;
				}

				const overContainer = findContainer(overId!, over?.data?.current?.type);

				if (
					overContainer?.id === activeContainer?.id &&
					active?.data?.current?.type === "task"
				) {
					const activeIndex = active?.data?.current?.currentIndex;
					const overIndex = over?.data?.current?.currentIndex;

					// task change inside the same column
					if (activeIndex !== overIndex) {
						setColumns((columns) => {
							return columns?.map((column) => {
								if (overContainer?.id === column?.id) {
									return {
										...column,
										projectTasks: arrayMove(
											column?.projectTasks,
											activeIndex,
											overIndex,
										),
									};
								}
								return column;
							});
						});
						setProjectTaskPosition({
							variables: {
								id: +active?.id,
								currentPosition: overIndex,
							},
						}).catch((err) => {
							toastNotify([
								{
									messageType: "error",
									message: err.message,
								},
							]);
							onDragCancel();
						});
					}
				}
				if (!overContainer || !activeContainer) {
					return;
				}
				if (!activeValue) return;

				const activeClonedContainer = findClonedContainer(
					activeValue?.id,
					activeValue?.type,
				);

				if (activeValue?.id && activeClonedContainer !== overContainer) {
					saveProjectTask({
						variables: {
							projectTaskInput: {
								id: +activeValue?.id,
								projectColumnId: +overContainer?.id?.split("-")[0],
								position: +over?.data?.current?.currentIndex,
							},
						},
					}).catch((err) => {
						onDragCancel();
						toastNotify([
							{
								messageType: "error",
								message: err.message,
							},
						]);
					});
				}
				setActiveValue(null);
				setClonedColumns(null);
			}}
			onDragCancel={onDragCancel}
		>
			<div className="flex flex-1 gap-5 p-3 w-full overflow-x-auto">
				<SortableContext
					items={columns}
					strategy={horizontalListSortingStrategy}
				>
					{columns?.map((column, index) => (
						<Column
							key={column?.id}
							column={column}
							index={index}
							activeTask={activeValue?.task}
							hasAllAccess={hasAllAccess}
							hasDeleteAccess={hasDeleteAccess}
							columns={columns}
							setColumns={setColumns}
							disabledColumns={disabledColumns}
							hasAnyAccess={hasAnyAccess}
						/>
					))}
				</SortableContext>
				{createPortal(
					<DragOverlay dropAnimation={dropAnimation}>
						{activeValue ? (
							activeValue?.column ? (
								<Column column={activeValue?.column} overlay />
							) : activeValue?.task ? (
								<Task task={activeValue?.task} overlay />
							) : null
						) : null}
					</DragOverlay>,
					document.body,
				)}
			</div>
		</DndContext>
	) : loading ? (
		<Loading className="min-h-[600px]" />
	) : (
		<div className="w-full h-[68vh] flex mx-3 justify-center items-center border">
			<p className="text-sm text-warm-gray">No Columns found.</p>
		</div>
	);
};

export default Board;
