import { FC, useCallback, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";
import { toastNotify } from "global/helpers/Cache";
import { IResourceAccess } from "global/types/acl";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";

import { ISkillCertificate, ISkillDetail } from "modules/Employee/types/skills";

interface IProps {
  userSkillCertificate: ISkillCertificate;
  userSkill: ISkillDetail;
  setDeleteVerification: Function;
  setEditCertificate: Function;
  setShowModal: Function;
  resourceAccess: IResourceAccess;
  setChangedInformation: Function;
  setShowUnderReview: Function;
  parentRef: any;
}

const CertificateDetails: FC<IProps> = ({
  userSkillCertificate,
  userSkill,
  setDeleteVerification,
  setEditCertificate,
  setShowModal,
  resourceAccess,
  setChangedInformation,
  setShowUnderReview,
  parentRef,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event?.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const skillCertificate =
    userSkillCertificate?.documents &&
    userSkillCertificate?.documents[0]?.file?.length > 100 ? (
      <a
        href={Base64toObject(userSkillCertificate?.documents[0]?.file)}
        target="_blank"
        rel="noreferrer"
        className="mt-1"
      >
        <LinkIcon className="w-[14px] h-[14px] cursor-pointer text-[#428AF4]" />
      </a>
    ) : (
      <span className="text-gray-400 ml-10 text-sm">No File</span>
    );

  const kebabIconClickHandler = () => {
    setEditCertificate({
      userSkill: userSkill,
      userSkillCertificate: userSkillCertificate,
    });
    setShowEditOption((prevState) => !prevState);
  };

  const kebabDeleteHandler = () => {
    if (resourceAccess?.canDelete) {
      setDeleteVerification(true);
      setShowEditOption(false);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const kebabEditHandler = () => {
    if (resourceAccess?.canUpdate) {
      setShowEditOption(false);
      setShowModal(true);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const moderationPopUpHandler = (moderationIds: (number | null)[]) => {
    const filteredModerationIds = moderationIds?.filter(
      (moderationId: number | null) => moderationId
    );

    filteredModerationIds?.length > 0 && setShowUnderReview(true);

    setChangedInformation(filteredModerationIds);
  };

  const moderationObj = userSkillCertificate?.moderation;
  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const parentEventRef = parentRef.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    parentEventRef && parentEventRef?.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      parentEventRef &&
        parentEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [getPosition, parentRef]);

  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };

  return (
    <div className="flex justify-between items-center gap-2">
      <div
        className={`flex justify-between flex-1 ${
          (moderationObj?.certificateName || moderationObj?.filePath) &&
          "bg-yellow-100"
        } py-2 px-1`}
      >
        <p
          className={`whitespace-nowrap ${
            !userSkillCertificate?.certificateName && "text-gray-300"
          } ${
            (moderationObj?.certificateName || moderationObj?.filePath) &&
            "cursor-pointer"
          } flex-1 text-ironside-gray text-sm truncate`}
          onClick={() => {
            moderationPopUpHandler([
              moderationObj?.certificateName,
              moderationObj?.filePath,
            ]);
          }}
        >
          {userSkillCertificate?.certificateName || "Eg. Certificate Name"}
        </p>
        {skillCertificate}
      </div>
      {(resourceAccess?.canUpdate || resourceAccess?.canDelete) && (
        <div ref={editRef} className="relative w-min">
          <div className="w-min" ref={kebabIconRef}>
            <KebabIcons
              onClick={kebabIconClickHandler}
              className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                showEditOption ? "bg-white-smoke" : null
              }`}
            />
          </div>
          {showEditOption && (
            <div className="fixed z-[80]" style={showEditOptionStyle}>
              {showEditOption && (
                <KebabMenu
                  onDelete={kebabDeleteHandler}
                  onEdit={kebabEditHandler}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CertificateDetails;
