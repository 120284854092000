import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { FieldPath, FieldValues } from "react-hook-form";

import { AutoComplete } from "components/forms/UpdatedFormAgry";
import { AutoCompleteProps } from "components/forms/UpdatedFormAgry/AutoComplete/types";

import { toastNotify } from "global/helpers/Cache";
import useInView from "global/hooks/useInView";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import { notEmpty } from "global/helpers/ArrayMethods";

import { FilterCompaniesArgs } from "modules/Company/types";
import { FILTER_COMPANIES } from "modules/Company/services";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  AutoCompleteProps<TFieldValues, Name>,
  | "options"
  | "loading"
  | "onInputChange"
  | "renderedOption"
  | "searchKeys"
  | "validate"
  | "value"
  | "observe"
> & {
  filter?: Omit<FilterCompaniesArgs["filters"], "globalSearch">;
};

const CompanyField = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  className,
  classNameForError,
  disabled,
  helperText,
  hideError,
  label,
  multiple,
  onChange,
  placeholder,
  required,
  shouldUnregister,
  filter,
}: Props<TFieldValues, Name>) => {
  const { data, loading, error, fetchMore } = useQuery(FILTER_COMPANIES, {
    fetchPolicy: "network-only",
    variables: {
      filters:
        filter && Object.entries(filter)?.length > 0
          ? {
              ...filter,
            }
          : undefined,
      pagination: {
        size: 30,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: disabled ? true : false,
  });

  const { observe } = useInView({
    onEnter: () => {
      if (
        !loading &&
        data?.filterCompanies?.pageInfo?.hasNextPage &&
        data?.filterCompanies?.pageInfo?.endCursor
      ) {
        fetchMore({
          variables: {
            pagination: {
              after: data?.filterCompanies?.pageInfo?.endCursor,
              size: 30,
            },
            filter:
              filter && Object.entries(filter)?.length > 0
                ? {
                    ...filter,
                  }
                : undefined,
          },
          updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
            if (!filterCompanies) return prev;
            return {
              filterCompanies:
                filterCompanies &&
                filterCompanies?.edges &&
                filterCompanies?.edges?.length > 0 &&
                prev?.filterCompanies
                  ? prev?.filterCompanies?.edges &&
                    prev?.filterCompanies?.edges?.length > 0
                    ? {
                        edges: [
                          ...prev.filterCompanies.edges,
                          ...filterCompanies.edges,
                        ],
                        pageInfo: filterCompanies?.pageInfo,
                      }
                    : {
                        edges: filterCompanies?.edges,
                        pageInfo: filterCompanies?.pageInfo,
                      }
                  : prev.filterCompanies,
            };
          },
        }).catch((err) => {
          toastNotify(errorMessageNotify(err));
        });
      }
    },
  });

  const options = useMemo(() => {
    return error?.message
      ? []
      : data?.filterCompanies?.edges && data?.filterCompanies?.edges?.length > 0
      ? data?.filterCompanies?.edges
          ?.map((edges) => {
            if (edges?.node?.id && edges?.node?.name) {
              return {
                id: edges?.node?.id,
                label: edges?.node?.name,
              };
            } else {
              return null;
            }
          })
          .filter(notEmpty)
      : [];
  }, [data?.filterCompanies?.edges, error?.message]);

  return (
    <AutoComplete
      control={control}
      name={name}
      options={options as any}
      className={className}
      classNameForError={classNameForError}
      disabled={disabled}
      helperText={helperText}
      hideError={hideError}
      label={label}
      loading={loading}
      multiple={multiple}
      observe={observe}
      onChange={onChange}
      onInputChange={(value) => {
        if (!loading)
          fetchMore({
            variables: {
              pagination: {
                size: 30,
              },
              filter:
                filter && Object.entries(filter)?.length > 0
                  ? {
                      ...filter,
                    }
                  : undefined,
              globalSearch: value || null,
            },
            updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
              return {
                filterCompanies,
              };
            },
          }).catch((err) => {
            toastNotify(errorMessageNotify(err));
          });
      }}
      invalidType={"Helper Text" as any}
      placeholder={placeholder}
      required={required}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default CompanyField;
