import { Outlet, useLocation } from "react-router-dom";

import { ReactComponent as OXLogo } from "global/assets/images/logo.svg";

const PublicLayout = () => {
  const location = useLocation();

  return (
    <div
      className={`${
        location.pathname === "/signup"
          ? ""
          : location.pathname === "/"
          ? "xl:h-screen"
          : "lg:h-screen"
      } flex justify-center items-center mx-auto bg-white xl:bg-[#F7FAFC]`}
    >
      <div className="flex justify-center items-center flex-col mb-7">
        <div className="md:mb-7 mt-[50px]">
          <OXLogo className="w-[120px] h-[100px]" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default PublicLayout;
