import { useLazyQuery, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Select } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import { paginationDefaultValue } from "global/helpers/StaticData";
import { DateFormat } from "global/helpers/DateFormatter";

import {
  FILTER_MY_LEAVE_REQUESTS,
  LEAVE_DASHBOARD,
} from "modules/Leave/services/queries";
import RequestDetails from "modules/Leave/Pages/Requests/MyRequest/RequestDetails";
import ViewPage from "modules/Leave/Pages/Requests/MyRequest/ViewPage";

export interface RequestDetailsType {
  createdAt: string | any;
  updatedAt: string;
  dates: any;
  declineMessage: undefined | string;
  id: number;
  reason: string;
  status:
    | "Pending"
    | "Approved"
    | "Declined"
    | "Partially Approved"
    | "Cancelled";
}

const MyRequest = () => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const { data: leaveDashboard } = useQuery(LEAVE_DASHBOARD, {
    variables: {
      userId: +localStorage?.id || +sessionId,
    },
  });

  const leaveOptions = leaveDashboard?.leaveDashboard
    ?.map(
      (leaves: { leaveType: any; id: number; name: string; year: number }) => {
        if (leaves?.year === new Date()?.getFullYear()) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else if (leaves?.leaveType?.isSpecialLeave) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else {
          return null;
        }
      }
    )
    ?.filter((leaveType: { id: number; name: string }) => leaveType);

  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const defaultDateRangeStartDate = new Date();
  const defaultDateRangeEndDate = new Date(
    +`${new Date()?.getMonth() + 5}` > 12
      ? `${new Date()?.getFullYear() + 1}-${`${
          new Date()?.getMonth() + 5 - 12
        }`.padStart(2, "0")}-01`
      : `${new Date()?.getFullYear()}-${new Date()?.getMonth() + 5}-01`
  );

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: defaultDateRangeStartDate,
      endDate: defaultDateRangeEndDate,
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const leaveType = watch("leaveType");
  const status = watch("status");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());

  const [fetchLeaveRequests, { data: filterLeaveRequests, loading }] =
    useLazyQuery(FILTER_MY_LEAVE_REQUESTS, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    fetchLeaveRequests({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        status: status ? status : undefined,
        from: fromDate ? fromDate : undefined,
        to: toDate ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
      },
    });
  }, [
    pageSize,
    currentPage,
    status,
    fromDate,
    toDate,
    leaveType,
    fetchLeaveRequests,
  ]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] sm:gap-5 xl:grid-cols-[repeat(4,220px)] 2xl:grid-cols-[repeat(4,250px)] justify-center items-center xl:justify-start">
                  <SelectWithId
                    register={register}
                    name={"leaveType"}
                    errors={errors}
                    label={"Leave Type"}
                    options={leaveOptions}
                    enableDefaultSelectOption
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={[
                      "Approved",
                      "Declined",
                      "Partially Approved",
                      "Pending",
                    ]}
                    label="Status"
                    name="status"
                    required={true}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                </div>
              </div>
              <RequestDetails
                myRequests={
                  filterLeaveRequests?.filterMyLeaveRequests?.dataCollection
                }
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={
                  filterLeaveRequests?.filterMyLeaveRequests?.totalNumberOfItems
                }
                loading={loading}
                filterLeaveRequests={filterLeaveRequests}
              />
            </div>
          }
        />
        <Route path={`:id`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default MyRequest;
