import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import ProjectGroupBugs from "modules/Project/Pages/Projects/ProjectGroupBugs/ProjectGroupBugs";
import EditProjectGroupBugsRouteControl from "modules/Project/Pages/Projects/ProjectGroupBugs/EditProjectGroupBugsRouteControl";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
	allowedResources: string[];
	projectGroup: ProjectGroup | null;
}

const ProjectGroupBugsRouteControl: FC<Props> = ({
	allowedResources,
	projectGroup,
}) => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<ProjectGroupBugs
						allowedResources={allowedResources}
						projectGroup={projectGroup}
					/>
				}
			/>
			<Route
				path=":projectGroupBugId/*"
				element={
					<EditProjectGroupBugsRouteControl projectGroup={projectGroup} />
				}
			/>
		</Routes>
	);
};

export default ProjectGroupBugsRouteControl;
