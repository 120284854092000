import { FC } from "react";
import { useForm } from "react-hook-form";
import { Editor } from "@tiptap/react";
import { RiBold } from "react-icons/ri";
import { AiOutlineItalic, AiOutlineStrikethrough } from "react-icons/ai";
import { RxListBullet } from "react-icons/rx";
import { BsListOl, BsChatRightQuote } from "react-icons/bs";
import { FiCode } from "react-icons/fi";
import { CiUndo, CiRedo } from "react-icons/ci";

import Select from "components/forms/UpdatedForm/Select";

import ColorSelection from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/TipTap/ColorSelection";
import AddCommentFile from "./AddCommentFile";
import Tooltip from "components/UI/ToolTip";

interface IProps {
  editor: Editor;
  setFileList: Function;
}

const MenuBar: FC<IProps> = ({ editor, setFileList }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFontStyle = watch("fontStyle");

  const fontStyle: { [key: string]: number } = {
    "Heading 1": 1,
    "Heading 2": 2,
    "Heading 3": 3,
    "Heading 4": 4,
    "Heading 5": 5,
    "Heading 6": 6,
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-3 p-3 items-center border-b-0 bg-[#f6f6f6]">
      <Select
        control={control}
        errors={errors}
        name="fontStyle"
        options={[
          "Normal Text",
          "Heading 1",
          "Heading 2",
          "Heading 3",
          "Heading 4",
          "Heading 5",
          "Heading 6",
        ]}
        defaultValue={"Normal Text"}
        className={`w-[160px] ${
          editor?.isActive("heading", {
            level: fontStyle[watchFontStyle],
          })
            ? "is-active"
            : ""
        }`}
        setValue={setValue}
        hideError
        onChange={(e) => {
          if (e.target?.value !== "Normal Text") {
            editor
              .chain()
              .focus()
              .toggleHeading({
                level: +e.target?.value?.slice(-1) as any,
              })
              .run();
          } else {
            editor.chain().focus().setParagraph().run();
          }
        }}
      />
      <Tooltip render={"Bold (Ctrl+B)"} arrow>
        <RiBold
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("bold") ? "is-active bg-[#505f79] text-white" : ""
          }`}
        />
      </Tooltip>

      <Tooltip render={"Italic (Ctrl+I)"} arrow>
        <AiOutlineItalic
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("italic") ? "is-active bg-[#505f79] text-white" : ""
          }`}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
      </Tooltip>
      <Tooltip render={"Strike"} arrow>
        <AiOutlineStrikethrough
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("strike") ? "is-active bg-[#505f79] text-white" : ""
          }`}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
      </Tooltip>
      <Tooltip render={"Bullet List (Ctrl+Shift+8)"} arrow>
        <RxListBullet
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("bulletList")
              ? "is-active bg-[#505f79] text-white"
              : ""
          }`}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
      </Tooltip>
      <Tooltip render={"Numbered List (Ctrl+Shift+7)"} arrow>
        <BsListOl
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("orderedList")
              ? "is-active bg-[#505f79] text-white"
              : ""
          }`}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
      </Tooltip>
      <Tooltip render={"Code Snippet ```"} arrow>
        <FiCode
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("codeBlock")
              ? "is-active bg-[#505f79] text-white"
              : ""
          }`}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        />
      </Tooltip>
      <Tooltip render={"Quote"} arrow>
        <BsChatRightQuote
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("blockquote")
              ? "is-active bg-[#505f79] text-white"
              : ""
          }`}
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        />
      </Tooltip>
      <Tooltip render={"Undo (Ctrl+Z)"} arrow>
        <CiUndo
          className={`min-w-[22px] min-h-[22px] cursor-pointer ${
            editor.isActive("undo") ? "is-active bg-[#505f79] text-white" : ""
          }`}
          onClick={() => editor.chain().focus().undo().run()}
        />
      </Tooltip>
      <Tooltip render={"Redo (Ctrl+Shift+Z)"} arrow>
        <CiRedo
          className={"min-w-[22px] min-h-[22px] cursor-pointer"}
          onClick={() => editor.chain().focus().redo().run()}
        />
      </Tooltip>
      <Tooltip render={"Text Color"} arrow>
        <ColorSelection editor={editor} />
      </Tooltip>
      <Tooltip render={"Add File"} arrow>
        <AddCommentFile setFileList={setFileList} />
      </Tooltip>
    </div>
  );
};

export default MenuBar;
