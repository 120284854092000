import { FC } from "react";

import CardRow from "components/App/UpdatedAppComponents/DataCard/CardRow";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";

interface IProps {
  dataModel: IDataModal[];
  cardClick?: () => void;
  classNameForRow?: string;
  disableClickToViewToolTip?: boolean;
  classForULlist?: string;
  classForSecondChild?: string;
  classForFirstChild?: string;
}

const CardContent: FC<IProps> = ({
  dataModel,
  cardClick,
  classNameForRow,
  disableClickToViewToolTip,
  classForULlist = "",
  classForFirstChild = "",
  classForSecondChild = "",
}) => {
  return (
    <ul
      className={`pt-2 overflow-x-auto space-y-2  ${
        cardClick
          ? `${classForULlist ? classForULlist : "cursor-pointer"}`
          : classForULlist
      }`}
      onClick={() => {
        cardClick && cardClick();
      }}
    >
      {dataModel.map((row, index: number) => {
        if (row?.hidden) {
          return null;
        }

        return (
          <li key={index} className="even:bg-[#FAFAFB] rounded p-2 truncate">
            <CardRow
              row={row}
              classNameForRow={classNameForRow}
              disableClickToViewToolTip={disableClickToViewToolTip}
              classForFirstChild={classForFirstChild}
              classForSecondChild={classForSecondChild}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default CardContent;
