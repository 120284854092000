import { debounce } from "lodash";
import { twMerge } from "tailwind-merge";
import { ElementRef, useRef } from "react";
import { FocusScope, useDateRangePicker } from "react-aria";
import { DateRangePickerState, useDateRangePickerState } from "react-stately";
import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  getLocalTimeZone,
  now,
  today,
} from "@internationalized/date";
import { FieldPath, FieldValues, PathValue } from "react-hook-form";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { IoMdClose } from "react-icons/io";
import { Transition } from "@headlessui/react";

import { DateProps } from "components/forms/UpdatedFormAgry/Date/Date/types";
import DateField from "components/forms/UpdatedFormAgry/Date/Date/DateField";
import { CalendarButton } from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarButton";
import TimeField from "components/forms/UpdatedFormAgry/Date/Date/TimeField";
import RangeCalendar from "components/forms/UpdatedFormAgry/Date/Calendar/RangeCalendar";

import { ReactComponent as CalendarIcon } from "global/assets/images/calendar_month.svg";

const DateRangePicker = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus,
  defaultOpen,
  defaultValue,
  description,
  errorMessage,
  granularity,
  isDateUnavailable,
  disabled,
  isOpen,
  isReadOnly,
  isRequired,
  label,
  maxValue,
  minValue,
  onBlur,
  onChangeHookForm,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onOpenChange,
  placeholderValue,
  validationState,
  className,
  classNameForError,
  hideError,
  allowsNonContiguousRanges,
  type = "date-range",
  value,
  pageBehavior,
  shouldCloseOnSelect,
  shouldForceLeadingZeros,
  notClearable,
}: Omit<
  DateProps<TFieldValues, Name>,
  | "name"
  | "control"
  | "shouldUnregister"
  | "maxGranularity"
  | "type"
  | "required"
  | "onChange"
> & {
  onChangeHookForm: (...event: any[]) => void;
  placeholderValue?: CalendarDate | CalendarDateTime | ZonedDateTime;
  type?: "date-range" | "date-time-range";
  allowsNonContiguousRanges?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  value: PathValue<TFieldValues, Name>;
}) => {
  const ref = useRef<ElementRef<"div">>(null);
  const parentRef = useRef<ElementRef<"div">>(null);

  const state = useDateRangePickerState({
    autoFocus,
    defaultOpen,
    defaultValue: defaultValue
      ? defaultValue
      : type === "date-range"
      ? {
          end: today(getLocalTimeZone()),
          start: today(getLocalTimeZone()),
        }
      : {
          start: now(getLocalTimeZone()),
          end: now(getLocalTimeZone()),
        },
    description,
    errorMessage,
    granularity: granularity
      ? granularity
      : type === "date-time-range"
      ? "second"
      : undefined,
    hideTimeZone: true,
    hourCycle: 12,
    isDateUnavailable,
    isDisabled: disabled,
    isOpen,
    isReadOnly,
    isRequired,
    label,
    maxValue,
    minValue,
    onBlur,
    onChange: onChangeHookForm,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
    onOpenChange,
    placeholderValue,
    value: value as DateRangePickerState["value"],
    validationState,
    pageBehavior,
    shouldCloseOnSelect,
    shouldForceLeadingZeros,
  });

  const {
    groupProps,
    labelProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(
    {
      autoFocus,
      defaultOpen,
      defaultValue: defaultValue
        ? defaultValue
        : type === "date-range"
        ? {
            start: today(getLocalTimeZone()),
            end: today(getLocalTimeZone()),
          }
        : {
            start: now(getLocalTimeZone()),
            end: now(getLocalTimeZone()),
          },
      description,
      errorMessage,
      granularity: granularity
        ? granularity
        : type === "date-time-range"
        ? "second"
        : undefined,
      hideTimeZone: true,
      hourCycle: 12,
      isDateUnavailable,
      isDisabled: disabled,
      isOpen,
      isReadOnly,
      isRequired,
      label,
      maxValue,
      minValue,
      onBlur,
      onChange: onChangeHookForm,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onOpenChange,
      placeholderValue,
      validationState,
      allowsNonContiguousRanges,
      "aria-label": label,
      "aria-labelledby": label,
      value: value as DateRangePickerState["value"],
      pageBehavior,
      shouldForceLeadingZeros,
    },
    state,
    ref
  );

  const { x, y, strategy, refs, middlewareData, context } =
    useFloating<HTMLDivElement>({
      middleware: [
        offset(10),
        flip({
          fallbackPlacements: ["bottom", "top"],
        }),
        hide({
          strategy: "referenceHidden",
        }),
        shift({
          padding: 20,
        }),
        size({
          apply: debounce(({ availableHeight, elements }) => {
            Object.assign(elements?.floating?.style, {
              width: "max-content",
              "max-width": "100vw",
              maxHeight: `${availableHeight}px`,
            });
          }, 1),
        }),
      ],
      placement: "bottom-end",
      whileElementsMounted: autoUpdate,
    });

  const reference = useMergeRefs([ref, refs.setReference]);

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: dialogProps.role });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    dismiss,
    click,
    role,
  ]);

  return (
    <div
      className={twMerge(
        " inline-flex flex-col w-full bg-background",
        disabled ? "cursor-not-allowed" : "",
        className
      )}
      ref={parentRef}
    >
      <div
        {...groupProps}
        ref={reference}
        {...getReferenceProps()}
        className={twMerge(
          "relative flex peer border bg-inherit border-on-surface-variant rounded shadow justify-between items-center min-h-[45px] p-2 px-4 active:outline-none outline-none focus:outline-none focus-within:outline-none",
          state?.isOpen
            ? errorMessage
              ? "border-error"
              : disabled
              ? "bg-surface-disabled text-disabled cursor-not-allowed"
              : "border-primary"
            : errorMessage
            ? "border-error"
            : disabled
            ? "bg-surface-disabled text-disabled cursor-not-allowed"
            : "focus-within:border-primary"
        )}
      >
        <div
          className={`flex-1 flex h-full items-center  ${
            type === "date-time-range" ? "flex-wrap gap-2" : ""
          }`}
        >
          <DateField {...startFieldProps} label={`Start ${label}`} />
          {type === "date-range" && (
            <span aria-hidden="true" className="px-2">
              –
            </span>
          )}
          <DateField {...endFieldProps} label={`End ${label}`} />
        </div>
        <div className="flex gap-1">
          {value && !disabled && !notClearable && (
            <IoMdClose
              onClick={() => {
                if (!disabled) {
                  onChangeHookForm(null);
                }
              }}
              className={
                "h-6 w-6 p-0.5 cursor-pointer text-ironside-gray bg-inherit rounded-full hover:text-primary"
              }
            />
          )}
          <CalendarButton
            {...buttonProps}
            onPress={() => {
              state?.setOpen(!state?.isOpen);
            }}
            className={"p-0.5 group"}
            isDisabled={disabled}
          >
            <CalendarIcon
              className={`w-5 h-5 ${
                errorMessage
                  ? "text-error"
                  : disabled
                  ? "text-disabled cursor-not-allowed"
                  : "group-hover:text-primary text-ironside-gray group-focus:text-primary"
              }`}
            />
          </CalendarButton>
        </div>

        {label && (
          <label
            {...labelProps}
            style={{
              maxWidth: parentRef?.current
                ? (
                    parentRef?.current?.getBoundingClientRect()?.width - 20
                  )?.toString() + "px"
                : undefined,
            }}
            className={twMerge(
              "absolute -top-2.5 left-4 bg-inherit px-0.5 text-xs truncate cursor-default text-on-surface-variant max-w-[50%] select-none",
              state?.isOpen
                ? errorMessage
                  ? "text-error"
                  : disabled
                  ? "text-outline text-opacity-[0.38]"
                  : "text-primary"
                : errorMessage
                ? "text-error"
                : disabled
                ? "text-outline text-opacity-[0.38]"
                : "text-on-surface-variant"
            )}
          >
            {label}
          </label>
        )}
      </div>
      {hideError ? null : (
        <div className={`w-[inherit] h-min px-4 ${classNameForError}`}>
          <span
            className={`text-error text-xs ${
              errorMessage ? "visible" : "invisible"
            } `}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
      <FloatingPortal>
        <Transition
          show={state?.isOpen}
          ref={refs?.setFloating}
          {...getFloatingProps()}
          style={{
            position: strategy,
            zIndex: 66,
            top: y ?? 0,
            left: x ?? 0,
          }}
          as={"div"}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={`${
            middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
          } bg-background border rounded-md shadow-md`}
          onKeyDown={(e) => {
            if (e.key === "Escape") state.close();
          }}
        >
          <FocusScope autoFocus restoreFocus contain>
            <div
              className="fixed inset-0 z-[100]"
              onClick={() => state?.close()}
            />
            <RangeCalendar
              {...calendarProps}
              className="relative z-[101] rounded-[inherit]"
            />
            {type === "date-time-range" && (
              <div className="pb-3 px-3 grid gap-3">
                <TimeField
                  label="Start time"
                  value={state.timeRange?.start || null}
                  onChange={(v) => state.setTime("start", v)}
                  hideError
                  className="mt-2 z-[101]"
                  granularity={
                    state?.granularity as
                      | "hour"
                      | "minute"
                      | "second"
                      | undefined
                  }
                  hourCycle={12}
                />
                <TimeField
                  label="End time"
                  value={state.timeRange?.end || null}
                  onChange={(v) => state.setTime("end", v)}
                  hideError
                  className="mt-2 z-[101]"
                  hourCycle={12}
                  granularity={
                    state?.granularity as
                      | "hour"
                      | "minute"
                      | "second"
                      | undefined
                  }
                />
              </div>
            )}
          </FocusScope>
        </Transition>
      </FloatingPortal>
    </div>
  );
};

export default DateRangePicker;
