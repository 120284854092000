import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import { useMutation, useQuery } from "@apollo/client";

import {
  CalendarField,
  Email,
  Mobile,
  EditForm,
  Name,
  Select,
} from "components/forms";

import { languageList } from "global/helpers/StaticData";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import {
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import {
  IPersonalInformation,
  IPersonalInformationForm,
} from "modules/Employee/types/personal";
import { UPDATE_PERSONAL_INFO } from "modules/Employee/services/mutations";
import { GET_PERSONAL_INFORMATION_DROPDOWN } from "modules/Employee/services/queries";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import { mutationHandler } from "modules/Employee/model/mutation-handler";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  personalInformation: IPersonalInformation;
  setShowModal: Function;
  refetchPersonalInformation: Function;
}

const EditPersonalInformation: FC<IProps> = ({
  personalInformation,
  setShowModal,
  refetchPersonalInformation,
}) => {
  const { data: dropdownList, loading: dropdownLoading } = useQuery(
    GET_PERSONAL_INFORMATION_DROPDOWN
  );

  const isUnderModeration = useIsUnderModeration();

  const bloodGroups = dropdownList?.getBloodGroupOptions || [];
  const genders = dropdownList?.getUserGenders || [];
  const maritalStatus = dropdownList?.getMaritalStatusOptions || [];

  const moderationObj = personalInformation?.moderation || null;

  const moderationFieldProps = {
    refetchQuery: refetchPersonalInformation,
  };

  const [inProgress, setInProgress] = useState<boolean>(false);

  const [updateUser] = useMutation(UPDATE_PERSONAL_INFO);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<IPersonalInformationForm>();

  const watchMaritalStatus = watch("maritalStatus");

  const weddingFieldValidation = watchMaritalStatus === "Married";

  const useFormProps = {
    register: register,
    errors: errors,
  };

  const [officialDateOfBirth, setOfficialDateOfBirth] = useState<
    Date | null | undefined
  >(
    personalInformation?.officialDateOfBirth
      ? new Date(personalInformation?.officialDateOfBirth?.replaceAll("-", " "))
      : undefined
  );
  const [actualDateOfBirth, setActualDateOfBirth] = useState<
    Date | null | undefined
  >(
    personalInformation?.actualDateOfBirth
      ? new Date(personalInformation?.actualDateOfBirth?.replaceAll("-", " "))
      : undefined
  );

  const [weddingDate, setWeddingDate] = useState<Date | null | undefined>(
    personalInformation?.weddingDate
      ? new Date(personalInformation?.weddingDate?.replaceAll("-", " "))
      : undefined
  );

  useEffect(() => {
    if (personalInformation?.languagesKnown) {
      setValue("languagesKnown", personalInformation?.languagesKnown);
    }
    if (personalInformation?.name) {
      setValue("name", personalInformation?.name);
    }

    if (personalInformation?.personalEmail) {
      setValue("personalEmail", personalInformation?.personalEmail);
    }

    if (personalInformation?.mobile) {
      setValue("mobile", personalInformation?.mobile);
    }

    if (dropdownList?.getUserGenders) {
      setValue(
        "gender",
        personalInformation?.gender ? personalInformation?.gender : ""
      );
    }

    if (dropdownList?.getBloodGroupOptions) {
      setValue(
        "bloodGroup",
        personalInformation?.bloodGroup ? personalInformation?.bloodGroup : ""
      );
    }

    if (dropdownList?.getMaritalStatusOptions) {
      setValue(
        "maritalStatus",
        personalInformation?.maritalStatus
          ? personalInformation?.maritalStatus
          : ""
      );
    }
  }, [
    personalInformation,
    dropdownList?.getBloodGroupOptions,
    dropdownList?.getUserGenders,
    dropdownList?.getMaritalStatusOptions,
    setValue,
    clearErrors,
  ]);

  useEffect(() => {
    if (officialDateOfBirth) {
      setValue("officialDateOfBirth", officialDateOfBirth?.toString());
      clearErrors("officialDateOfBirth");
    }

    if (actualDateOfBirth) {
      setValue("actualDateOfBirth", actualDateOfBirth?.toString());
      clearErrors("actualDateOfBirth");
    }

    if (weddingDate) {
      setValue("weddingDate", weddingDate?.toString());
      clearErrors("weddingDate");
    }
  }, [
    clearErrors,
    officialDateOfBirth,
    actualDateOfBirth,
    weddingDate,
    setValue,
  ]);

  const modalCloseHandler = () => {
    setInProgress(false);
    setOfficialDateOfBirth(null);
    setShowModal(false);
  };

  const userId = sessionStorage?.otherUserId
    ? +sessionStorage?.otherUserId
    : undefined;

  const actionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Personal information")
    : updateSuccessMessageWithAwaiting("Personal information");

  const updateHandler = (data: IPersonalInformationForm) => {
    const variables = {
      id: userId,
      name: data.name.trim(),
      personalEmail: data.personalEmail,
      isdCode: data.country,
      mobile: data.mobile,
      officialDateOfBirth:
        (officialDateOfBirth && ViewDateFormat(officialDateOfBirth)) || null,
      actualDateOfBirth:
        (actualDateOfBirth && ViewDateFormat(actualDateOfBirth)) || null,
      gender: data.gender || null,
      bloodGroup: data.bloodGroup || null,
      maritalStatus: data.maritalStatus || null,
      weddingDate:
        (weddingFieldValidation &&
          weddingDate &&
          ViewDateFormat(weddingDate)) ||
        null,
      languagesKnown: data?.languagesKnown,
    };

    mutationHandler(
      updateUser,
      variables,
      inProgress,
      modalCloseHandler,
      setInProgress,
      actionSuccessMessage
    );
  };

  return (
    <EditForm
      title={"Personal Information"}
      isFormLoading={dropdownLoading}
      modalCloseHandler={modalCloseHandler}
      handleSubmit={handleSubmit}
      updateHandler={updateHandler}
      inProgress={inProgress}
    >
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.name}
      >
        <Name
          {...useFormProps}
          label="Name *"
          name="name"
          defaultName={personalInformation?.name}
          required={true}
          disable={!!moderationObj?.name}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.personalEmail}
      >
        <Email
          {...useFormProps}
          label="Email *"
          name="personalEmail"
          defaultEmail={personalInformation?.personalEmail}
          required={true}
          disable={!!moderationObj?.personalEmail}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.mobile}
      >
        <Mobile
          {...useFormProps}
          defaultMobile={personalInformation?.mobile}
          defaultCountryCode={personalInformation?.isdCode}
          required={true}
          label="Mobile *"
          disabled={!!moderationObj?.mobile}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.actualDateOfBirth}
      >
        <CalendarField
          {...useFormProps}
          name="actualDateOfBirth"
          date={actualDateOfBirth}
          setDate={setActualDateOfBirth}
          className="h-[50px]"
          label="Actual Date Of Birth"
          minDate={new Date(1970, 0, 1)}
          maxDate={new Date(new Date()?.getFullYear() - 17, 11, 31)}
          startDate={`${+new Date().getFullYear() - 17}-${
            +new Date().getMonth() + 1
          }-${new Date().getDate()}`}
          disabled={!!moderationObj?.actualDateOfBirth}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.officialDateOfBirth}
      >
        <CalendarField
          {...useFormProps}
          name="officialDateOfBirth"
          date={officialDateOfBirth}
          setDate={setOfficialDateOfBirth}
          className="h-[50px]"
          label="Official Date Of Birth"
          minDate={new Date(1970, 0, 1)}
          maxDate={new Date(new Date()?.getFullYear() - 17, 11, 31)}
          startDate={`${+new Date().getFullYear() - 17}-${
            +new Date().getMonth() + 1
          }-${new Date().getDate()}`}
          disabled={!!moderationObj?.officialDateOfBirth}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.languagesKnown}
      >
        <AutoComplete
          setValue={setValue}
          options={languageList}
          control={control}
          label={"Languages Known"}
          name={"languagesKnown"}
          errors={errors}
          multiple
          defaultValue={
            personalInformation?.languagesKnown
              ?.filter((language: string) => language)
              ?.map((language) => language) || []
          }
          disabled={!!moderationObj?.languagesKnown}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.gender}
      >
        <Select
          {...useFormProps}
          label="Gender"
          options={genders}
          name="gender"
          defaultSelectValue={personalInformation?.gender}
          disable={!!moderationObj?.gender}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.bloodGroup}
      >
        <Select
          {...useFormProps}
          label="Blood Group"
          options={bloodGroups}
          name="bloodGroup"
          defaultSelectValue={personalInformation?.bloodGroup}
          disable={!!moderationObj?.bloodGroup}
        />
      </ModerationField>
      <ModerationField
        {...moderationFieldProps}
        moderationId={moderationObj?.maritalStatus}
      >
        <Select
          {...useFormProps}
          label="Marital Status"
          options={maritalStatus}
          name="maritalStatus"
          defaultSelectValue={personalInformation?.maritalStatus}
          disable={!!moderationObj?.maritalStatus}
        />
      </ModerationField>

      {(weddingFieldValidation || moderationObj?.weddingDate) && (
        <ModerationField
          {...moderationFieldProps}
          moderationId={moderationObj?.weddingDate}
        >
          <CalendarField
            {...useFormProps}
            name="weddingDate"
            date={weddingDate}
            setDate={setWeddingDate}
            className="h-[50px]"
            label="Wedding Date"
            disabled={!!moderationObj?.weddingDate}
            minDate={new Date(1950, 0, 1)}
            maxDate={
              new Date(new Date()?.getFullYear(), new Date()?.getMonth(), 31)
            }
          />
        </ModerationField>
      )}
    </EditForm>
  );
};

export default EditPersonalInformation;
