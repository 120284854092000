import React from "react";

const Footer = () => {
  return (
    <div className="bg-slate-100">
      <p className="text-center text-[12px] text-slate-500 p-2">
        Copyright &copy; {new Date().getFullYear()} OX SoftwareS. All rights
        reserved.
      </p>
    </div>
  );
};

export default Footer;
