import { useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { Transition } from "@headlessui/react";
import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import { IIdAndName } from "global/types/type";
import { PROJECT_MEMBERS_PROFILES } from "modules/Project/services/queries";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import MentionItem from "./MentionItem";
import { getAllowedAccess } from "global/helpers/Cache";

interface MentionListProps extends SuggestionProps {}
interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void;
}
export default forwardRef<MentionListActions, MentionListProps>(
  ({ clientRect, command, query, decorationNode, editor }, ref) => {
    const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
    const allowedResources = allowedResourcesList?.allowedResources || [];
    const canReadUserProfileImage = allowedResources?.includes(
      "ReadUserProfileImage"
    );

    const referenceEl = useMemo(
      () => (clientRect ? { getBoundingClientRect: clientRect } : null),
      [clientRect]
    );

    const { x, y, strategy, refs, middlewareData } = useFloating({
      middleware: [
        offset(10),
        flip({
          fallbackPlacements: ["bottom-start", "top-start"],
        }),
        hide({
          strategy: "referenceHidden",
        }),
        shift({
          padding: 20,
        }),
      ],
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
    });

    useEffect(() => {
      if (referenceEl) {
        refs.setReference(decorationNode);
      }
    }, [decorationNode, referenceEl, refs]);
    const { id } = useParams();
    const [fetchProjectMembers, { data: getprojectMembers }] = useLazyQuery(
      PROJECT_MEMBERS_PROFILES
    );
    useEffect(() => {
      if (id && !isNaN(+id)) {
        fetchProjectMembers({
          variables: {
            filters: {
              projectId: +id,
            },
            type: canReadUserProfileImage ? "Profile" : undefined,
            isProfileImageNeeded: canReadUserProfileImage,
          },
        }).catch((error) => {
          if (error.name === "AbortError") return;
        });
      }
    }, [id, fetchProjectMembers, canReadUserProfileImage]);

    const membersList: IIdAndName[] =
      getprojectMembers?.projectMembers?.dataCollection?.map(
        (member: { user: { personalInformation: IIdAndName } }) => {
          return {
            id: member?.user?.personalInformation?.id,
            name: member?.user?.personalInformation?.name,
          };
        }
      ) || [];

    const handleCommand = (index: number) => {
      const selectedPerson = membersList[index];
      command({ id: selectedPerson?.id, label: selectedPerson?.name });
    };

    const [hoverIndex, setHoverIndex] = useState(0);
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        const { key } = event;

        if (key === "ArrowUp") {
          setHoverIndex((prev) => {
            const beforeIndex = prev - 1;
            return beforeIndex >= 0 ? beforeIndex : 0;
          });
          return true;
        }

        if (key === "ArrowDown") {
          setHoverIndex((prev) => {
            const afterIndex = prev + 1;
            const peopleCount = membersList.length - 1 ?? 0;
            return afterIndex < peopleCount ? afterIndex : peopleCount;
          });
          return true;
        }

        if (key === "Enter") {
          handleCommand(hoverIndex);
          return true;
        }

        return false;
      },
    }));

    return (
      <FloatingPortal>
        <Transition
          show={true}
          ref={refs.setFloating}
          as={"div"}
          appear
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          className={`${
            middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
          } w-[200px] rounded-md bg-white p-2 flex flex-col gap-1 border max-h-[300px]`}
        >
          {membersList?.map((member, index) => (
            <MentionItem
              key={member.id}
              isActive={index === hoverIndex}
              onMouseEnter={() => setHoverIndex(index)}
              onClick={() => handleCommand(index)}
            >
              {member.name}
            </MentionItem>
          ))}
        </Transition>
      </FloatingPortal>
    );
  }
);
