import { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

import { NotificationsType } from "layouts/header/TopLinks/TopLinks";
import { UPDATE_NOTIFICATION_STATUS } from "layouts/header/TopLinks/Notification/services/mutations";

import { ReactComponent as PencilWhiteIcon } from "global/assets/images/pencil-white-icon.svg";
import { ReactComponent as EmailIcon } from "global/assets/images/email-icon.svg";
import { ReactComponent as BirthdayIcon } from "global/assets/images/birthday-icon.svg";
import { ReactComponent as UnReadTickIcon } from "global/assets/images/unread-tick-icon.svg";
import { ReactComponent as ReadTickIcon } from "global/assets/images/read-tick-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";
import { Link } from "react-router-dom";
import { testJSON } from "global/helpers/AllowedResourceHelper";
import { BsDot } from "react-icons/bs";

interface IProps {
	messages: NotificationsType;
	showReadMessage: boolean;
	refetchUserNotifications: Function;
	setShowNotification: Function;
}

const NotificationDetails: FC<IProps> = ({
	messages,
	showReadMessage,
	refetchUserNotifications,
	setShowNotification,
}) => {
	const [inProgress, setInProgress] = useState(false);
	const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);
	const [isClicked, setIsClicked] = useState<boolean>(false);

	const clickHandler = () => {
		if (!inProgress) {
			updateNotificationStatus({
				variables: {
					id: +messages.id ? [+messages.id] : [0],
					isRead: !messages.isRead,
				},
			})
				.then(() => {
					setInProgress(false);
					refetchUserNotifications();
				})
				.catch((error) => {
					setInProgress(false);
					console.error(error?.message);
				});
			setInProgress(!inProgress);
		}
	};

	const notificationReadHandler = () => {
		updateNotificationStatus({
			variables: {
				id: +messages.id ? [+messages.id] : [0],
				isRead: true,
			},
		});
	};

	const profileImage =
		messages?.triggeredByUser?.userProfileImages?.filter(
			(userProfileImage: any) => userProfileImage?.type === "Profile",
		)[0] || null;

	useEffect(() => {
		if (isClicked) {
			setShowNotification(false);
		}
	}, [isClicked, setShowNotification]);

	const additionalInformation = testJSON(messages?.additionalInformation)
		? JSON?.parse(messages?.additionalInformation)
		: null;

	return (
		<li className="flex items-center justify-between mt-[23px]">
			<div className="flex">
				<div
					className={`min-w-[32px] h-[32px] rounded-full flex justify-center items-center ${
						messages.message?.includes("email") ? "bg-[#f16865]" : null
					} ${messages.message?.includes("updated") ? "bg-[#43c7a2]" : null} ${
						messages.message?.includes("email") ||
						messages.message?.includes("updated")
							? null
							: "bg-[#92baff]"
					}`}
				>
					{(() => {
						if (profileImage?.documents) {
							if (profileImage?.documents[0]?.file?.length > 100) {
								return (
									<img
										src={Base64toObject(profileImage?.documents[0]?.file)}
										className="w-8 h-8 rounded-full text-[12px]"
										alt={`${messages?.triggeredByUser?.personalInformation?.name}`}
									/>
								);
							}
						} else {
							if (messages.message?.includes("updated")) {
								return <PencilWhiteIcon />;
							}
							if (messages.message?.includes("email")) {
								return <EmailIcon />;
							}
							if (messages.message?.includes("Birthday")) {
								return <BirthdayIcon />;
							}
							if (messages.message?.includes(""))
								return (
									<span className="text-[12px] leading-[15px] text-white font-semibold">
										OPTA
									</span>
								);
						}
					})()}
				</div>
				<div
					className="ml-[15px] md:ml-[22px] max-w-[150px] md:max-w-[200px] lg:w-full space-y-[10px]"
					onClick={() => setIsClicked(true)}
				>
					<p
						className={`${
							messages?.link ? "hover:underline" : ""
						} text-sm text-ironside-gray`}
						onClick={() => {
							!messages?.isRead && notificationReadHandler();
						}}
					>
						<Link
							to={messages?.link}
							className={
								messages?.link ? "cursor-pointer text-cornflower-blue" : ""
							}
						>
							{messages?.message}
						</Link>
					</p>
					{messages?.additionalInformation && (
						<>
							<p
								className={`${
									messages?.link ? "hover:underline" : ""
								} text-sm text-ironside-gray`}
								onClick={() => {
									!messages?.isRead && notificationReadHandler();
								}}
							>
								<Link
									to={messages?.link}
									className={
										messages?.link ? "cursor-pointer text-[#622214]" : ""
									}
								>
									{additionalInformation?.taskName}
								</Link>
							</p>
							<p
								className={`${
									messages?.link ? "" : ""
								} text-sm text-ironside-gray`}
							>
								<Link
									to={messages?.link}
									className={
										messages?.link ? " flex items-center cursor-pointer" : ""
									}
								>
									<span className="hover:underline">
										{additionalInformation?.taskId}
									</span>
									<BsDot className="w-4 h-5 text-[#626364]" />
									<span className="hover:underline">
										{additionalInformation?.columnName}
									</span>
								</Link>
							</p>
						</>
					)}
					<p className="truncate text-[10px] leading-[14px] md:text-[12px] md:leading-[15px] text-[#BBBBBB] space-x-[15px]">
						<span>{messages.date}</span>
						<span>{messages.time}</span>
					</p>
				</div>
			</div>
			{inProgress ? (
				<div className="w-7 h-7 flex justify-center items-center">
					<span className="block w-4 h-4 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
				</div>
			) : showReadMessage ? (
				<div
					onClick={() => {
						setInProgress(!inProgress);
						clickHandler();
					}}
					className="w-7 h-7 flex cursor-pointer hover:border hover:bg-gray-100 hover:rounded-full justify-center items-center"
				>
					<ReadTickIcon className="cursor-pointer" />
				</div>
			) : (
				<div
					onClick={() => {
						setInProgress(!inProgress);
						clickHandler();
					}}
					className="w-7 h-7 flex cursor-pointer hover:border hover:bg-gray-100 hover:rounded-full justify-center items-center"
				>
					<UnReadTickIcon className="cursor-pointer" />
				</div>
			)}
		</li>
	);
};

export default NotificationDetails;
