import { TypedDocumentNode, gql } from "@apollo/client";

import { PROJECT_GROUP_TASK_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroupTasks/services/fragments";
import {
  FilterProjectGroupTasksArgs,
  FilterProjectGroupTasksReturnType,
} from "modules/Project/Pages/Projects/ProjectGroupTasks/types";

export const FILTER_PROJECT_GROUP_TASKS: TypedDocumentNode<
  FilterProjectGroupTasksReturnType,
  FilterProjectGroupTasksArgs
> = gql`
  ${PROJECT_GROUP_TASK_FRAGMENT}
  query FilterProjectGroupTasks(
    $filters: ProjectGroupTaskFilterInput
    $search: String
    $limit: Int
    $page: Int
    $orderBy: OrderBy
  ) {
    filterProjectGroupTasks: filterProjectGroupTask(
      filters: $filters
      search: $search
      limit: $limit
      page: $page
      orderBy: $orderBy
    ) {
      dataCollection {
        ...projectGroupTaskFields
      }
      totalNumberOfItems
    }
  }
`;
