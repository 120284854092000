import { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Loading from "components/Loader/Loading";
import OopsNoData from "components/UI/OopsNoData";

import { paginationDefaultValue } from "global/helpers/StaticData";

import { MY_TEAMS } from "modules/Teams/services/queries";
import MyTeamsDetailsGrid from "modules/Teams/Pages/MyTeams/MyTeamsDetailsGrid";
import UserDetails from "components/UserDetails/UserDetails";

const MyTeams = () => {
  const [fetchMyTeams, { data: employeeTeamAndMember, loading }] = useLazyQuery(
    MY_TEAMS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [userId, setUserId] = useState<number | undefined>();

  const employeeTeamAndMembers: any =
    employeeTeamAndMember?.users?.dataCollection &&
    employeeTeamAndMember?.users?.dataCollection[0]?.myTeams?.dataCollection
      ? employeeTeamAndMember?.users?.dataCollection[0]?.myTeams?.dataCollection
      : employeeTeamAndMember && [];

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  useEffect(() => {
    fetchMyTeams({
      variables: {
        isMyProfile: true,
        limit: pageSize,
        page: currentPage,
      },
    });
  }, [currentPage, fetchMyTeams, pageSize]);
  const parentRef = useRef<HTMLDivElement>(null);
  const getDataModal = (teamMembers: any) => [
    {
      lable: "Team Name",
      value: [
        {
          content: teamMembers?.name,
        },
      ],
    },
    {
      lable: "Team Code",
      value: [
        {
          content: teamMembers?.code,
        },
      ],
    },
    {
      lable: "Team Members",
      value: teamMembers.members?.map((teamMember: any) => {
        return {
          content: (
            <div className="group">
              <p onMouseEnter={() => setUserId(teamMember?.id)}>
                {teamMember?.personalInformation?.name}
              </p>
              <UserDetails userId={userId} />
            </div>
          ),
        };
      }),
    },
  ];
  return (
    <div>
      {loading ? (
        <Loading className="min-h-[78vh]" />
      ) : (
        <Fragment>
          {employeeTeamAndMembers && employeeTeamAndMembers?.length === 0 ? (
            employeeTeamAndMember && <OopsNoData className="min-h-[78vh]" />
          ) : (
            <div className="md:min-h-[610px] min-h-[440px]">
              <div className="hidden md:block">
                <Table
                  noOfItems={
                    employeeTeamAndMember && employeeTeamAndMembers?.length
                  }
                  tableHeadingsObj={[
                    { name: "Team Name", center: false },
                    { name: "Team Code", center: false },
                    { name: "Team Members", center: false },
                  ]}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  loading={loading}
                  pageSize={pageSize}
                  ref={parentRef}
                  setPageSize={setPageSize}
                  className="min-h-[645px] lg:min-h-[661px] py-5"
                  totalCount={
                    (employeeTeamAndMember?.users?.dataCollection &&
                      employeeTeamAndMember?.users?.dataCollection[0]?.myTeams
                        ?.totalNumberOfItems) ||
                    0
                  }
                >
                  {employeeTeamAndMembers?.map((teamMembers: any) => {
                    return (
                      <MyTeamsDetailsGrid
                        key={teamMembers?.id}
                        teamMembers={teamMembers}
                        userId={userId}
                        setUserId={setUserId}
                      />
                    );
                  })}
                </Table>
              </div>
              <div className="md:hidden">
                <div className="bg-white mt-[20px] md:mt-[30px]">
                  {employeeTeamAndMembers?.map(
                    (teamMembers: any, index: number) => {
                      return (
                        <SMDataCard
                          key={teamMembers?.id}
                          title={`Team Members ${index + 1}`}
                          loading={loading}
                          dataModal={getDataModal(teamMembers)}
                        />
                      );
                    }
                  )}
                  <Pagination
                    totalCount={
                      employeeTeamAndMember?.users?.dataCollection &&
                      employeeTeamAndMember?.users?.dataCollection[0]?.myTeams
                        ? employeeTeamAndMember?.users?.dataCollection[0]
                            ?.myTeams?.totalNumberOfItems
                        : 0
                    }
                    currentPage={currentPage}
                    setPageSize={setPageSize}
                    siblingCount={1}
                    pageSize={pageSize}
                    onPageChange={(page: number) => setCurrentPage(page)}
                    noOfItem={employeeTeamAndMembers?.length}
                  />
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default MyTeams;
