import { FC, useCallback, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { Base64toObject } from "global/helpers/FileConverter";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { toastNotify } from "global/helpers/Cache";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";
import { IResourceAccess } from "global/types/acl";

import {
  IAcademicCertificate,
  IAcademicDetail,
} from "modules/Employee/types/academic";

interface IProps {
  academicDetail: IAcademicDetail;
  index: number;
  setEditID: Function;
  setDeleteVerification: Function;
  setShowModal: Function;
  academicDetails: IAcademicDetail[];
  resourceAccess: IResourceAccess;
  setShowUnderReview: Function;
  setChangedInformation: Function;
}

const EducationDetails: FC<IProps> = ({
  academicDetail,
  index,
  setEditID,
  setDeleteVerification,
  setShowModal,
  academicDetails,
  resourceAccess,
  setShowUnderReview,
  setChangedInformation,
}) => {
  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const academicCertificates = academicDetail?.userAcademicCertificates?.map(
    (certificate: IAcademicCertificate, index: number) => {
      if (certificate?.filePath?.length > 100) {
        return (
          <a
            key={index}
            href={Base64toObject(certificate?.filePath)}
            target="_blank"
            rel="noreferrer"
            className="pl-2"
          >
            <LinkIcon className="w-[14px] h-[14px] cursor-pointer text-cornflower-blue" />
          </a>
        );
      } else {
        return (
          <span key={index} className="text-gray-400 ml-2">
            No File
          </span>
        );
      }
    }
  );

  const degreeTypeAndPercentage =
    academicDetail?.degreeType &&
    `${academicDetail?.degreeType}${
      academicDetail?.department ? `, ${academicDetail?.department}` : ""
    }${academicDetail?.percentage ? `, ${academicDetail?.percentage}%` : ""}`;

  const academicYear =
    academicDetail?.startMonth &&
    `${academicDetail?.startMonth?.slice(0, 3)} ${
      academicDetail?.startYear
    } - ${
      academicDetail?.endMonth
        ? academicDetail?.endMonth?.slice(0, 3)
        : "Present"
    } ${academicDetail?.endMonth ? academicDetail?.endYear : ""}`;

  const kebabIconClickHandler = () => {
    setShowEditOption((prevState) => !prevState);
    setEditID(+academicDetail?.id);
  };

  const kebabMenuDeleteHandler = () => {
    if (resourceAccess?.canDelete) {
      setDeleteVerification(true);
      setShowEditOption(false);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const kebabMenuEditHandler = () => {
    if (resourceAccess?.canUpdate) {
      setShowModal(true);
      setShowEditOption(false);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const moderationObj = academicDetail?.moderation;

  const moderationPopUpHandler = (moderationIds: (number | null)[]) => {
    const filteredModerationIds = moderationIds?.filter(
      (moderationId: number | null) => moderationId
    );

    filteredModerationIds?.length > 0 && setShowUnderReview(true);

    setChangedInformation(filteredModerationIds);
  };

  const academicYearModerationFields =
    moderationObj?.startMonth ||
    moderationObj?.startYear ||
    moderationObj?.endMonth ||
    moderationObj?.endYear;
  const kebabIconRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = useCallback(() => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  }, []);

  useEffect(() => {
    const eventRef = kebabIconRef.current;

    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
    };
  }, [getPosition]);
  const showEditOptionStyle = {
    top: `${top}px`,
    left: `${left}px`,
    right: `${right}px`,
  };
  return (
    <div
      className={`w-full flex flex-col gap-1 ${
        index === 0 ? "lg:pt-[25px]" : "pt-[25px]"
      } space-y-1`}
    >
      <div>
        <div className="w-full flex justify-between items-center">
          <p
            className={`${
              moderationObj?.instituteName && "bg-yellow-100"
            } text-sm text-ironside-gray cursor-pointer`}
            onClick={() =>
              moderationPopUpHandler([moderationObj?.instituteName])
            }
          >
            {academicDetail?.instituteName || "Eg.XYZ Institute"}
          </p>

          <div className="flex items-center text-xs text-ironside-gray relative">
            {!academicDetail?.isCurrentlyPursuing && academicCertificates}

            {academicDetail?.id && (
              <div className="w-full flex justify-center items-center">
                <div ref={editRef} className="w-min ml-2">
                  {(resourceAccess?.canUpdate || resourceAccess?.canDelete) && (
                    <div ref={kebabIconRef}>
                      <KebabIcons
                        onClick={kebabIconClickHandler}
                        className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke ${
                          showEditOption ? "bg-white-smoke" : null
                        }`}
                      />
                    </div>
                  )}
                  {showEditOption && (
                    <div className="fixed z-[80]" style={showEditOptionStyle}>
                      <KebabMenu
                        onEdit={kebabMenuEditHandler}
                        onDelete={kebabMenuDeleteHandler}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${
          moderationObj?.affiliatedTo && "bg-yellow-100"
        } text-xs text-ironside-gray`}
        onClick={() => moderationPopUpHandler([moderationObj?.affiliatedTo])}
      >
        {academicDetail?.affiliatedTo || "Eg.Anna University"}
      </div>

      <div
        className={`${
          (moderationObj?.degreeType ||
            moderationObj?.department ||
            moderationObj?.percentage) &&
          "bg-yellow-100"
        } text-xs text-ironside-gray`}
        onClick={() =>
          moderationPopUpHandler([
            moderationObj?.degreeType,
            moderationObj?.department,
            moderationObj?.percentage,
          ])
        }
      >
        {degreeTypeAndPercentage || "Eg.BE - 99%"}
      </div>

      {academicDetail?.additionalDetails && (
        <div
          className={`${
            moderationObj?.additionalDetails && "bg-yellow-100"
          } text-xs text-ironside-gray`}
          onClick={() =>
            moderationPopUpHandler([moderationObj?.additionalDetails])
          }
        >
          <pre>{academicDetail?.additionalDetails}</pre>
        </div>
      )}

      <div
        className={`${
          academicYearModerationFields && "bg-yellow-100"
        } text-xs text-ironside-gray`}
        onClick={() =>
          moderationPopUpHandler([
            moderationObj?.startMonth,
            moderationObj?.startYear,
            moderationObj?.endMonth,
            moderationObj?.endYear,
          ])
        }
      >
        {academicYear || "Eg.Jun 2016 - Apr 2020"}
      </div>

      {!(index === academicDetails?.length - 1) && (
        <div className="border-b pt-[20px]" />
      )}
    </div>
  );
};

export default EducationDetails;
