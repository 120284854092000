import UserDetails from "components/UserDetails/UserDetails";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const TeamTransactionDetailsGrid: FC<{
  leaveTransaction: any;
  userId: number | undefined;
  setUserId: Function;
}> = ({ leaveTransaction, userId, setUserId }) => {
  const navigate = useNavigate();

  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.id}
      </td>
      <td>
        <div className="group">
          <p
            className="pl-2"
            onMouseEnter={() => setUserId(leaveTransaction.userId)}
          >
            {leaveTransaction?.employeeName}
          </p>
          <UserDetails userId={userId} />
        </div>
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {leaveTransaction?.leaveDates?.length > 1 ? (
          leaveTransaction?.leaveDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.leaveDate || "N/A"}
              </span>
            );
          })
        ) : leaveTransaction?.leaveDates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {leaveTransaction?.leaveDate || "N/A"}
          </span>
        ) : null}
      </td>
      <td className="max-w-[200px] truncate whitespace-nowrap">
        {leaveTransaction?.leaveDates?.length > 1 ? (
          leaveTransaction?.leaveDates?.map((date: any, index: number) => {
            return (
              <span
                key={index}
                className="flex justify-center py-5 px-3 border-b border-white-smoke last:border-none"
              >
                {date?.session || "N/A"}
              </span>
            );
          })
        ) : leaveTransaction?.leaveDates?.length === 1 ? (
          <span className="p-3 flex justify-center">
            {leaveTransaction?.leaveDates?.map(
              (date: any) => date?.session || "N/A"
            )}
          </span>
        ) : null}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.leaveType}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.transactionType}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.noOfDays}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.previousCount}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate text-center">
        {leaveTransaction?.currentCount}
      </td>
      <td className="py-5 px-3 max-w-[200px] truncate whitespace-nowrap">
        <span
          className="grid place-content-center cursor-pointer hover:text-cornflower-blue hover:underline"
          onClick={() => {
            navigate(`${leaveTransaction?.id}`, {
              state: {
                viewPageDetails: leaveTransaction,
              },
            });
          }}
        >
          View
        </span>
      </td>
    </tr>
  );
};

export default TeamTransactionDetailsGrid;
