import { FC } from "react";

interface IProps {
  label: string;
  value: string;
  row: {
    label: string;
    className?: string;
    value: {
      content: string | Element | any;
    }[];
  };
  className?: string;
  isHtmlElementValue?: boolean;
  classNameForLabel?: string;
  classNameForDataRow?: string;
}

const CardDataRow: FC<IProps> = ({
  label,
  value,
  className = "",
  isHtmlElementValue,
  row,
  classNameForLabel,
  classNameForDataRow,
}) => {
  return (
    <div
      className={`${
        classNameForDataRow ? `${classNameForDataRow}` : "items-center h-10"
      } row`}
    >
      {label && (
        <p className={`left ${classNameForLabel}`}>
          <span className="min-w-[142px]">{label}</span> <span>:</span>
        </p>
      )}
      {!isHtmlElementValue ? (
        <p className="right">
          {Array.isArray(value) ? value.join(", ") : value}
        </p>
      ) : (
        <div className="flex flex-col w-full">
          {row?.value?.map((value, index) => {
            return (
              <div
                key={index}
                className={`whitespace-nowrap ${
                  row?.className ? row?.className : "py-[14px] first:pt-[1px]"
                }`}
              >
                {value.content || ""}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardDataRow;
