import { FC, Fragment } from "react";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { CalendarDate, parseDate, today } from "@internationalized/date";
import { useDateFormatter } from "react-aria";
import { BiCheck } from "react-icons/bi";
import { CalendarState, RangeCalendarState } from "react-stately";
import { debounce } from "lodash";

interface Props {
  state: CalendarState | RangeCalendarState;
}

export const YearDropdown: FC<Props> = ({ state }) => {
  let years: { date: CalendarDate; formatted: string }[] = [];
  const formatter = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });

  // Format 60 years on each side of the current year according
  // to the current locale and calendar system.

  const startYear = state?.minValue?.year;

  const endYear = state?.maxValue?.year;

  if (startYear && endYear) {
    if (startYear === endYear) {
      years = [
        {
          date: state?.focusedDate,
          formatted: startYear?.toString(),
        },
      ];
    } else {
      for (let year = startYear; year <= endYear; year++) {
        const date = parseDate(
          new Date(
            new Date().setFullYear(
              year,
              state.focusedDate?.month,
              state?.focusedDate?.day
            )
          )
            .toISOString()
            ?.slice(0, 10)
        );
        years.push({
          date,
          formatted: year?.toString(),
        });
      }
    }
  } else if (state?.maxValue && !state?.minValue) {
    for (
      let year = state?.maxValue?.year - 100;
      year <= state?.maxValue?.year;
      year++
    ) {
      const date = parseDate(
        new Date(
          new Date().setFullYear(
            year,
            state?.focusedDate?.month,
            state?.focusedDate?.day
          )
        )
          .toISOString()
          ?.slice(0, 10)
      );
      years.push({
        date,
        formatted: year?.toString(),
      });
    }
  } else if (state?.minValue && !state?.maxValue) {
    for (
      let year = state?.minValue?.year;
      year <= today(state.timeZone).year + 60;
      year++
    ) {
      const date = parseDate(
        new Date(
          new Date().setFullYear(
            year,
            state?.focusedDate.month,
            state?.focusedDate.day
          )
        )
          .toISOString()
          ?.slice(0, 10)
      );
      years.push({
        date,
        formatted: year?.toString(),
      });
    }
  } else {
    for (let i = -100; i <= 100; i++) {
      const date = today(state?.timeZone).add({ years: i });
      years.push({
        date,
        formatted: formatter.format(date.toDate(state.timeZone)),
      });
    }
  }

  const { x, y, strategy, refs, middlewareData } =
    useFloating<HTMLButtonElement>({
      middleware: [
        offset(10),
        flip({
          fallbackPlacements: ["bottom", "top"],
        }),
        hide({
          strategy: "referenceHidden",
        }),
        shift({
          padding: 20,
        }),
        size({
          apply: debounce(({ availableHeight, elements }) => {
            Object.assign(elements?.floating?.style, {
              width: "max-content",
              "max-width": "100vw",
              maxHeight: `${availableHeight}px`,
            });
          }, 1),
        }),
      ],
      placement: "bottom",
      whileElementsMounted: autoUpdate,
    });
  return (
    <Listbox
      value={{
        date: state?.focusedDate,
        formatted: formatter.format(state.focusedDate.toDate(state.timeZone)),
      }}
      onChange={(year) => {
        state.setFocusedDate(year?.date);
      }}
      by={"formatted"}
      disabled={state?.isDisabled}
    >
      {({ open, value }) => {
        return (
          <Fragment>
            <Listbox.Button
              ref={refs?.setReference}
              className={`bg-background appearance-none cursor-pointer text-xs  p-1 rounded-md ${
                open
                  ? "outline-none ring-2 ring-primary"
                  : "focus:outline-none focus:ring-2 focus:ring-primary"
              }`}
            >
              {value?.formatted}
            </Listbox.Button>
            <FloatingPortal>
              <Transition
                show={open}
                ref={refs?.setFloating}
                style={{
                  position: strategy,
                  zIndex: 66,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                as={"div"}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={`${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                } shadow border rounded focus:outline-none min-w-[200px] bg-background`}
              >
                <Listbox.Options
                  className={`relative max-h-60 py-2 pl-2 overflow-auto bg-inherit text-base shadow-lg focus:outline-none rounded`}
                >
                  {years?.map((option, index) => {
                    return (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative text-sm select-none p-2 flex justify-between  rounded cursor-pointer ${
                            active
                              ? "bg-primary text-white"
                              : "text-ironside-gray"
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => {
                          return (
                            <Fragment>
                              <span
                                className={`block truncate text-sm ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {option?.formatted}
                              </span>
                              {selected && (
                                <BiCheck
                                  className={`h-5 w-5 min-w-[20px] ${
                                    active ? "text-white" : "text-primary"
                                  }`}
                                />
                              )}
                            </Fragment>
                          );
                        }}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </FloatingPortal>
          </Fragment>
        );
      }}
    </Listbox>
  );
};
