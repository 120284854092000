import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { Filled, Modal, Outlined } from "components/forms";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";
import Select from "components/forms/UpdatedForm/Select";

import { toastNotify, userContextData } from "global/helpers/Cache";

import { CREATE_OR_UPDATE_PROJECT_COLUMN } from "modules/Project/services/mutations";
import {
  IProjectColumn,
  IProjectColumnForm,
} from "modules/Project/types/project";
import { COLUMNS } from "modules/Project/services/queries";

const EditColumn: React.FC<{
  editProjectColumn: IProjectColumn | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectColumns: Function;
  editProject: { id: number; name: string };
  allowedResourcesForProject: { [key: string]: boolean };
}> = ({
  editProjectColumn,
  setShowModal,
  loading,
  refetchProjectColumns,
  editProject,
  allowedResourcesForProject = {},
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
  const createOrUpdateAccess =
    allowedResourcesForProject["SaveProjectColumn"] || isSuperAdmin;

  const [saveProjectColumn, { loading: saveProjectColumnLoading }] =
    useMutation(CREATE_OR_UPDATE_PROJECT_COLUMN, {
      refetchQueries: [COLUMNS],
    });

  const {
    reset,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    control,
  } = useForm<IProjectColumnForm>();

  const createOrUpdateProjectColumn: SubmitHandler<IProjectColumnForm> = ({
    name,
    description,
    colour,
  }) => {
    if (!saveProjectColumnLoading) {
      saveProjectColumn({
        variables: {
          projectColumnInput: {
            id: editProjectColumn?.id || undefined,
            projectId: editProject?.id,
            name,
            description,
            colour,
          },
        },
      })
        .then((response) => {
          const projectColumn = response?.data?.saveProjectColumn?.name;
          setShowModal(false);
          refetchProjectColumns();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectColumn?.id ? editProjectColumn?.name : projectColumn
              } has been successfully ${
                editProjectColumn?.id ? "updated." : "added."
              }`,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Column</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectColumn)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name"
                  name="name"
                  setValue={setValue}
                  defaultValue={editProjectColumn?.name}
                  required
                />
                <TextArea
                  errors={errors}
                  label="Description"
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectColumn?.description}
                />
                <Select
                  control={control}
                  errors={errors}
                  label="Color"
                  name="colour"
                  options={[
                    "Cyan-Blue",
                    "Tealish-Blue",
                    "Yellowish Orange",
                    "Green",
                  ]}
                  defaultValue={editProjectColumn?.colour || "Cyan-Blue"}
                  setValue={setValue}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  loading={saveProjectColumnLoading}
                  buttonName={editProjectColumn?.id ? "Update" : "Add"}
                  disabled={saveProjectColumnLoading || !createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditColumn;
