import { FC, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HiPencil, HiTrash } from "react-icons/hi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useReactiveVar } from "@apollo/client";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineNotificationsOff } from "react-icons/md";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Tooltip from "components/UI/ToolTip";

import { toastNotify } from "global/helpers/Cache";
import { ISetStateType } from "global/types/type";
import { userContextData } from "global/helpers/Cache";

import {
	IProjectTask,
	TBoardProjectColumn,
	TBoardType,
} from "modules/Project/types/project";
import { animateLayoutChanges } from "modules/Project/Pages/Issues/Board/Column";
import {
	ADD_PROJECT_TASK_NOTIFICATION_USER,
	DELETE_PROJECT_TASK,
	REMOVE_PROJECT_TASK_NOTIFICATION_USER,
} from "modules/Project/services/mutations";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

interface IProps {
	task: IProjectTask;
	index?: number;
	columnId?: string;
	overlay?: boolean;
	hasAllAccess?: boolean;
	hasDeleteAccess?: boolean;
	columns?: TBoardProjectColumn[];
	setColumns?: ISetStateType<TBoardProjectColumn[]>;
}

const Task: FC<IProps> = ({
	task,
	index,
	columnId,
	overlay,
	hasAllAccess,
	hasDeleteAccess,
	columns,
	setColumns,
}) => {
	const [deleteProjectTask, { loading: deleteProjectTaskLoading }] =
		useMutation(DELETE_PROJECT_TASK);

	const [
		addProjectTaskNotificationUser,
		{ loading: addProjectTaskNotificationUserLoading },
	] = useMutation(ADD_PROJECT_TASK_NOTIFICATION_USER);

	const [
		removeProjectTaskNotificationUser,
		{ loading: removeProjectTaskNotificationUserLoading },
	] = useMutation(REMOVE_PROJECT_TASK_NOTIFICATION_USER);

	const {
		id: taskId,
		title,
		projectTaskLabel,
		project,
		dueDate,
		keyId,
		isNotifyUser,
	} = task;

	const {
		attributes,
		listeners,
		setNodeRef,
		transition,
		transform,
		isDragging,
	} = useSortable({
		id: taskId,
		data: {
			type: "task",
			task,
			currentIndex: index,
			columnId,
		},

		animateLayoutChanges,
	});

	const { id, groupId } = useParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const userDataList: any = useReactiveVar(userContextData);
	const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
	const isManager =
		task?.incharge?.personalInformation?.id === userDataList?.user?.id;

	const projectType = pathname?.split("/")[3];

	const confirmModalCloseHandler = () => {
		setDeleteVerification(false);
	};

	const [deleteTaskId, setDeleteTaskId] = useState<number | null>(null);
	const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
	const [sendNotifications, setSendNotifications] = useState<boolean>(
		isNotifyUser ? true : false,
	);

	const taskNotificationHandler = () => {
		setSendNotifications((prev) => !prev);
	};

	const editBtnHandler = (issueId: number) => {
		navigate(
			`/projects/${
				groupId ? `${groupId}` : "projects"
			}/${projectType}/edit/${id}/issues/${issueId}`,
		);
	};

	const onDelete = (issueId: number) => {
		setDeleteTaskId(issueId);
		setDeleteVerification(true);
	};

	const findContainer = (id: string | number, type: TBoardType) => {
		let result: TBoardProjectColumn | null = null;
		if (columns) {
			if (type === "container") {
				result = columns.find((column) => column?.id === id) || null;
			} else if (type === "task") {
				result =
					columns.find((column) => {
						if (column?.projectTasks && column?.projectTasks?.length > 0) {
							return column?.projectTasks.find((task) => {
								return task?.id === id;
							});
						}
						return result;
					}) || null;
			}
		}

		return result;
	};

	const notificationOffHandler = (taskId: number) => {
		if (!removeProjectTaskNotificationUserLoading && taskId) {
			removeProjectTaskNotificationUser({
				variables: {
					projectTaskId: +taskId,
				},
			})
				.then(() => {
					taskNotificationHandler();
				})
				.catch((error) => {
					toastNotify(errorMessageNotify(error));
				});
		}
	};

	const notificationOnHandler = (taskId: number) => {
		if (!addProjectTaskNotificationUserLoading && taskId) {
			addProjectTaskNotificationUser({
				variables: {
					projectTaskId: +taskId,
				},
			})
				.then(() => {
					taskNotificationHandler();
				})
				.catch((error) => {
					toastNotify(errorMessageNotify(error));
				});
		}
	};

	const deleteHandler = () => {
		if (deleteTaskId) {
			deleteProjectTask({
				variables: {
					id: deleteTaskId,
				},
			})
				.then(() => {
					const activeContainer = findContainer(deleteTaskId, "task");

					setColumns?.((columns) => {
						return columns?.map((column) => {
							if (activeContainer?.id === column?.id) {
								return {
									...column,
									projectTasks: activeContainer?.projectTasks?.filter(
										(task) => task?.id !== deleteTaskId,
									),
								};
							}

							return column;
						});
					});
					setDeleteVerification(false);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: `${error.message}`,
						},
					]);
				});
		}
	};

	const cardClickHandler = (issueId: number) => {
		navigate(
			`/projects/${
				groupId ? `${groupId}` : "projects"
			}/${projectType}/edit/${id}/issue-details/${issueId}`,
		);
	};

	return (
		<>
			<li
				{...listeners}
				{...attributes}
				ref={setNodeRef}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					cardClickHandler(taskId);
				}}
				style={{ transition, transform: CSS.Translate.toString(transform) }}
				className={`rounded px-3 py-2 shadow-[0px_3px_6px_#A5A5A545] flex flex-col gap-3 bg-white group ${
					overlay
						? "outline-none ring-2 ring-blue-500 cursor-grabbing"
						: isDragging
						  ? "cursor-grabbing opacity-50 focus-visible:outline-none"
						  : "cursor-grab focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#60a5fa] focus-visible:shadow-[0px_3px_6px_#bfdbfe]"
				} 
        `}
			>
				<div className={"w-full flex justify-between items-center gap-1 h-7"}>
					<span className="truncate capitalize  text-santa-grey text-xs test flex-1">
						<Tooltip
							render={`${project?.projectKey}${keyId ? ` -${keyId}` : ""}`}
							arrow
							trigger={["hover"]}
							classNameForParent="w-min truncate"
						>
							{`${project?.projectKey}${keyId ? ` -${keyId}` : ""}`}
						</Tooltip>
					</span>
					{sendNotifications ? (
						<IoMdNotificationsOutline
							className="w-6 h-7 rounded p-1 hover:bg-white-smoke invisible group-hover:visible"
							onClick={(e) => {
								e.stopPropagation();
								notificationOffHandler(taskId);
							}}
						/>
					) : (
						<MdOutlineNotificationsOff
							className="w-6 h-7 rounded p-1 hover:bg-white-smoke invisible group-hover:visible"
							onClick={(e) => {
								e.stopPropagation();
								notificationOnHandler(taskId);
							}}
						/>
					)}
					{(hasAllAccess || isSuperAdmin || isManager) && (
						<Tooltip render="Edit" arrow trigger={["hover"]}>
							<HiPencil
								className="w-6 h-7 rounded p-1 hover:bg-white-smoke invisible group-hover:visible"
								onClick={(e) => {
									e.stopPropagation();
									editBtnHandler(taskId);
								}}
							/>
						</Tooltip>
					)}
					{hasDeleteAccess && (
						<Tooltip render="Delete" arrow trigger={["hover"]}>
							<HiTrash
								className="w-6 h-7 rounded p-1 hover:bg-white-smoke invisible group-hover:visible"
								onClick={(e) => {
									e.stopPropagation();
									onDelete(taskId);
								}}
							/>
						</Tooltip>
					)}
					{projectTaskLabel?.name && (
						<Tooltip
							render={projectTaskLabel?.name || ""}
							arrow
							trigger={["hover"]}
						>
							<span className="p-1 block text-dark-jungle-green text-xxs bg-titan-white rounded-sm max-w-[55px] truncate">
								{projectTaskLabel?.name}
							</span>
						</Tooltip>
					)}
				</div>
				<p className="text-dark-jungle-green text-xs break-words">{title}</p>
				{dueDate && (
					<Tooltip
						render={dueDate?.slice(0, 18) || ""}
						arrow
						trigger={["hover"]}
						hideToolTip={!dueDate ? true : false}
					>
						<p className="border-t text-santa-grey py-3 text-xs">
							Due Date: {dueDate?.slice(0, 18)}
						</p>
					</Tooltip>
				)}
			</li>
			{deleteVerification && (
				<ConfirmModal
					header="Project Task"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectTaskLoading}
				/>
			)}
		</>
	);
};

export default Task;
