import { Fragment, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import DataCard from "components/App/Card/DataCard";

import { userQueryProps } from "global/helpers/Cache";

import EditPersonalInformation from "modules/Employee/Pages/Profile/Personal/PersonalInformation/EditPersonalInformation";
import * as Types from "modules/Employee/types/personal";
import { makeDataModelArray } from "modules/Employee/helpers/make-data-model-array";
import { GET_PERSONAL_INFORMATION } from "modules/Employee/services/queries";

const PersonalInformation = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchPersonalInformation,
  } = useQuery(GET_PERSONAL_INFORMATION, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const personalInformation: Types.IPersonalInformation =
    (userDetails?.users?.dataCollection &&
      userDetails?.users?.dataCollection[0]?.personalInformation) ||
    null;

  const [showModal, setShowModal] = useState<boolean>(false);

  const removeInformationModelItems =
    personalInformation?.maritalStatus === "Married"
      ? ["id", "isdCode", "moderation", "__typename"]
      : ["id", "isdCode", "weddingDate", "moderation", "__typename"];

  const personalInformationModel = makeDataModelArray(
    personalInformation,
    removeInformationModelItems
  );

  return (
    <Fragment>
      <DataCard
        loading={loading}
        resourceName="EmployeePersonalInformation"
        setShowModal={setShowModal}
        title="Personal Information"
        dataModel={personalInformationModel}
        refetchQuery={refetchPersonalInformation}
        showPencilIcon={true}
      />

      {showModal && (
        <EditPersonalInformation
          personalInformation={personalInformation}
          setShowModal={setShowModal}
          refetchPersonalInformation={refetchPersonalInformation}
        />
      )}
    </Fragment>
  );
};

export default PersonalInformation;
