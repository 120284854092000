import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CalendarField,
  FileDragAndDrop,
  Filled,
  Select,
} from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import { fileToBase64 } from "global/helpers/FileConverter";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";

import { CREATE_APPLY_LEAVE } from "modules/Leave/services/mutations";
import { LEAVE_DASHBOARD } from "modules/Leave/services/queries";
import SelectWithId from "components/forms/Select/SelectWithId";

interface propsType {
  leaveType: any;
  leaveDashboardLoader: boolean;
}

const Special: React.FC<propsType> = ({ leaveDashboardLoader }) => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm();

  const { data: leaveDashboard } = useQuery(LEAVE_DASHBOARD, {
    variables: {
      userId: +localStorage?.id || +sessionId,
    },
  });

  const leaveTypeId = watch("leaveType");

  const leaveOptions = useMemo(
    () =>
      leaveDashboard?.leaveDashboard
        ?.map(
          (leaves: {
            leaveType: any;
            id: number;
            name: string;
            year: number;
          }) => {
            if (leaves?.leaveType?.isSpecialLeave) {
              return {
                id: leaves?.leaveType?.id,
                name: leaves?.leaveType?.name,
              };
            } else {
              return null;
            }
          }
        )
        ?.filter((leaveType: { id: number; name: string }) => leaveType) || [],
    [leaveDashboard?.leaveDashboard]
  );

  const leaveType = useMemo(
    () =>
      leaveDashboard?.leaveDashboard
        ?.map(
          (leaves: {
            leaveType: any;
            id: number;
            name: string;
            year: number;
            isSpecialLeave: boolean;
            isSupportingDocNeeded: boolean;
          }) => {
            if (leaves?.leaveType?.isSpecialLeave) {
              return {
                id: leaves?.leaveType?.id,
                name: leaves?.leaveType?.name,
                isSpecialLeave: leaves?.leaveType?.isSpecialLeave,
                isSupportingDocNeeded: leaves?.leaveType?.isSupportingDocNeeded,
              };
            } else {
              return null;
            }
          }
        )
        ?.filter((leaveType: { id: number; name: string }) => leaveType)
        ?.filter(
          (leaveType: { id: number }) => leaveType?.id === +leaveTypeId
        ) || [],
    [leaveDashboard?.leaveDashboard, leaveTypeId]
  );

  const hasLeaveType = leaveType?.length > 0;

  const resourceAccess = useGetAllowedResources("ApplyLeave");
  const navigate = useNavigate();
  const { state } = useLocation();

  const [fileList, setFileList] = useState<any>([]);
  const [updatedFiles, setUpdatedFiles] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [submittedFiles, setSubmittedFiles] = useState([]);

  useEffect(() => {
    setSubmittedFiles(
      state?.leaveRequestAttachments?.map((files: any) => {
        return { id: files?.id, filePath: files?.filePath };
      }) || []
    );
  }, [state?.leaveRequestAttachments]);

  useEffect(() => {
    setUpdatedFiles(submittedFiles);
  }, [state?.id, submittedFiles]);

  const [createApplyLeave] = useMutation(CREATE_APPLY_LEAVE);

  useEffect(() => {
    if (state?.leaveTypeId) {
      setValue("leaveType", state?.leaveTypeId);
    } else {
      setValue("leaveType", leaveOptions?.length > 0 && leaveOptions[0]?.id);
    }
  }, [setValue, leaveOptions, state?.leaveTypeId]);

  useEffect(() => {
    if (startDate) {
      setValue("startDate", startDate);
    }
    if (endDate) {
      setValue("endDate", endDate);
    }
  }, [endDate, setValue, startDate, state?.dates]);

  useEffect(() => {
    if (startDate) {
      clearErrors("startDate");
    }
    if (endDate) {
      clearErrors("endDate");
    }
  }, [clearErrors, endDate, startDate]);

  const currentFiles: any = [];

  fileList?.map((fileDetails: any, index: number) => {
    if (fileDetails?.size < 2e6)
      fileToBase64(fileDetails, (err: any, result: any) => {
        if (result) {
          currentFiles[index] = result;
        }
      });
    return <></>;
  });

  const createHandler = (data: any) => {
    if (
      hasLeaveType &&
      leaveType[0]?.isSupportingDocNeeded &&
      currentFiles &&
      currentFiles?.length === 0
    ) {
      toastNotify([
        {
          messageType: "error",
          message: "Please upload your proofs.",
        },
      ]);
    } else if (!btnLoading) {
      createApplyLeave({
        variables: {
          leaveRequestDates: [
            {
              leaveType: hasLeaveType && leaveType[0]?.id,
              leaveDate: ViewDateFormat(data?.startDate),
              session: data?.startSession,
            },
            {
              leaveType: hasLeaveType && leaveType[0]?.id,
              leaveDate: ViewDateFormat(data?.endDate),
              session: data?.endSession,
            },
          ],
          leaveRequestAttachments:
            currentFiles?.length > 0
              ? currentFiles?.map((file: string) => {
                  return { filePath: file };
                })
              : undefined,
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "Your request for leave was successfully submitted.",
            },
          ]);
          navigate("/leaves/requests/my-requests");
        })
        .catch((error) => {
          setBtnLoading(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setBtnLoading(!btnLoading);
    }
  };

  return (
    <div className="mx-auto py-[40px] px-3 sm:px-0">
      {leaveDashboardLoader ? (
        <div className="min-h-[500px] flex justify-center items-center">
          <div className="loader-design" />
        </div>
      ) : leaveOptions?.length === 0 ? (
        <div className="w-full h-[68vh] flex justify-center items-center border">
          <p className="text-sm text-warm-gray">
            You are not assigned to any special leave. Please contact HR.
          </p>
        </div>
      ) : (
        <div>
          <form onSubmit={handleSubmit(createHandler)}>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">
                Leave Type
              </p>
              <SelectWithId
                register={register}
                name={"leaveType"}
                errors={errors}
                label={"Leave Type"}
                options={leaveOptions}
                defaultSelectValue={
                  leaveOptions?.length > 0 && leaveOptions[0]?.id
                }
              />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">
                Start Date
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <CalendarField
                  register={register}
                  errors={errors}
                  name="startDate"
                  date={startDate}
                  setDate={setStartDate}
                  label="Start Date *"
                  required={true}
                  maxDate={new Date(new Date()?.getFullYear() + 2, 11, 31)}
                  minDate={new Date()}
                />
                <Select
                  register={register}
                  errors={errors}
                  label="Session *"
                  options={["Second Half", "Full Day"]}
                  defaultSelectValue={""}
                  name="startSession"
                  required={true}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">
                End Date
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <CalendarField
                  register={register}
                  errors={errors}
                  name="endDate"
                  date={endDate}
                  setDate={setEndDate}
                  label="End Date *"
                  required={true}
                  maxDate={new Date(new Date()?.getFullYear() + 2, 11, 31)}
                  minDate={new Date()}
                />
                <Select
                  register={register}
                  errors={errors}
                  label="Session *"
                  options={["First Half", "Full Day"]}
                  defaultSelectValue={""}
                  name="endSession"
                  required={true}
                />
              </div>
            </div>
            {(state?.id &&
              state?.dates &&
              state?.dates[0]?.leaveType?.isSupportingDocNeeded) ||
            (hasLeaveType && leaveType[0]?.isSupportingDocNeeded) ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
                <p className="text-sm text-ironside-gray sm:pb-[21px]">
                  Proofs
                </p>
                <FileDragAndDrop
                  fileList={fileList}
                  setFileList={setFileList}
                  updatedFiles={updatedFiles}
                  setUpdatedFiles={setUpdatedFiles}
                  setAlert={toastNotify}
                />
              </div>
            ) : null}
            {(resourceAccess?.canCreate || resourceAccess?.canUpdate) && (
              <Filled
                className={"mt-5 sm:ml-[166px]"}
                buttonType="submit"
                buttonName={"Submit"}
                loading={btnLoading}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Special;
