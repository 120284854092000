import { FC } from "react";
import { Control, UseFormSetValue } from "react-hook-form";

import Filter from "components/FilterUpdated/Filter";
import { FilterOptions } from "components/FilterUpdated/types";

import { countNonEmptyKeys } from "global/helpers/removeUndefinedValuesFromObject";
import {
  FetchMoreType,
  FilterFormType,
  ISetStateType,
} from "global/types/type";
import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import { notEmpty } from "global/helpers/ArrayMethods";

import {
  FilterProjectGroupBugSourceArgs,
  FilterProjectGroupBugSourceReturnType,
  ProjectGroupBugSource,
  ProjectGroupBugSourceFieldArgs,
  ProjectGroupBugSourceFilterFormType,
} from "modules/Project/Pages/ProjectGroupBugSource/types";
import { filterSubmitValues } from "modules/Project/Pages/ProjectGroupBugSource/helpers/utils";
import {
  FILTER_PROJECT_BUG_SOURCE_NAMES,
  FILTER_PROJECT_BUG_SOURCE_PROJECT_GROUP_NAMES,
} from "modules/Project/Pages/ProjectGroupBugSource/services";

interface Props {
  control: Control<FilterFormType<ProjectGroupBugSourceFilterFormType>, any>;
  setValue: UseFormSetValue<
    FilterFormType<ProjectGroupBugSourceFilterFormType>
  >;
  search: string;
  projectGroupBugSourceLength: number;
  canCreate: boolean;
  addBtnHandler: () => void;
  pageSize: number | undefined;
  fetchMore: FetchMoreType<
    FilterProjectGroupBugSourceReturnType,
    FilterProjectGroupBugSourceArgs
  >;
  filterValues: ProjectGroupBugSourceFilterFormType | null | undefined;
  setFilterValues: ISetStateType<
    ProjectGroupBugSourceFilterFormType | null | undefined
  >;
  fieldArgs: ProjectGroupBugSourceFieldArgs
}

const ProjectBugSourceFilter: FC<Props> = ({
  control,
  setValue,
  search,
  projectGroupBugSourceLength,
  canCreate,
  addBtnHandler,
  fetchMore,
  pageSize,
  filterValues,
  setFilterValues,
  fieldArgs
}) => {
  const hideGlobalSearchAddBtnAndFilterBtn =
    search?.length === 0 &&
    projectGroupBugSourceLength === 0 &&
    countNonEmptyKeys(filterValues) === 0;

  const onSearchChange = (page) => {
    fetchMore({
      variables: {
        globalSearch: search || null,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectBugSource } }) => {
        return {
          filterProjectBugSource,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onClear = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
         ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterProjectBugSource } }) => {
        return {
          filterProjectBugSource,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onSubmit: (
    values: FilterFormType<ProjectGroupBugSourceFilterFormType>
  ) => void = ({ filter }) => {
    if (filter) {
      fetchMore({
        variables: {
          filters: filter
            ? {
                ...filterSubmitValues(filter),
              }
            : undefined,
          pagination: {
            size: pageSize,
          },
          globalSearch: search || undefined,
           ...fieldArgs,
        },
        updateQuery: (
          prev,
          { fetchMoreResult: { filterProjectBugSource } }
        ) => {
          return {
            filterProjectBugSource,
          };
        },
      }).catch((error) => {
        toastNotify(errorMessageNotify(error));
      });
    }
  };

  const filterOptions: FilterOptions<
    FilterFormType<ProjectGroupBugSourceFilterFormType>
  > = [
    {
      name: "Name",
      fieldOption: {
        name: "filter.names",
        label: "Name",
        type: "string",
        query: FILTER_PROJECT_BUG_SOURCE_NAMES,
        multiple: true,
      },
    },
    {
      name: "Description",
      fieldOption: {
        name: "filter.description",
        type: "textArea",
        label: "Description",
      },
    },
    {
      name: "Project group",
      fieldOption: {
        type: "string",
        name: "filter.projectGroupNames",
        label: "Project group",
        query: FILTER_PROJECT_BUG_SOURCE_PROJECT_GROUP_NAMES,
        multiple: true,
        optionReturnType: (node) => {
					const projectBugSource = node as ProjectGroupBugSource;
					if (
						projectBugSource?.projectGroup &&
						projectBugSource?.projectGroup?.length > 0
					) {
						return (
							projectBugSource?.projectGroup?.map((projectGroup) => {
								if (projectGroup?.name) {
									return {
										id: projectGroup?.name,
										label: projectGroup?.name,
									};
								} else {
									return null;
								}
							}) || []
						)
							.filter(notEmpty)
							.flat(3);
					} else {
						return null;
					}
				},
      },
    },
  ];

  return (
    <Filter
      control={control}
      filterValues={filterValues}
      setValue={setValue}
      setFilterValues={setFilterValues}
      options={filterOptions}
      globalSearchName="search"
      hideAddBtn={hideGlobalSearchAddBtnAndFilterBtn || !canCreate}
      hideFilterBtn={hideGlobalSearchAddBtnAndFilterBtn}
      hideGlobalSearch={hideGlobalSearchAddBtnAndFilterBtn}
      addBtnDisabled={!canCreate}
      addBtnClickHandler={addBtnHandler}
      onSearchChange={onSearchChange}
      onSubmit={onSubmit}
      onClear={onClear}
      className="mt-5"
    />
  );
};

export default ProjectBugSourceFilter;
