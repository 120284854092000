import { FC, useRef } from "react";

import SMCardDataRow from "./SMCardDataRow";

interface IProps {
  dataModel: {
    lable: string;
    value: {
      content: string | Element | any;
      profileDetails?: {
        profileImage?: string;
        name: string;
        email: string;
        designation?: string;
      };
      color?: string;
    }[];
    hidden?: boolean;
  }[];
}

const SMCardContent: FC<IProps> = ({ dataModel }) => {
  const parentRef = useRef<HTMLUListElement>(null);

  return (
    <ul className="pt-2 overflow-x-auto space-y-2" ref={parentRef}>
      {dataModel.map((row, index: number) => {
        if (row?.hidden) {
          return null;
        }
        return (
          <li key={index}>
            <SMCardDataRow row={row} parentRef={parentRef} />
          </li>
        );
      })}
    </ul>
  );
};

export default SMCardContent;
