import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import ConfirmModal from "components/forms/Modal/ConfirmModal";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";
import { removeDuplicateObject } from "global/helpers/ArrayMethods";
import { IIdAndName } from "global/types/type";

import {
	PROJECT_MEMBERS_PROFILES,
	PROJECT_TASK_COMMENTS,
} from "modules/Project/services/queries";
import { DELETE_PROJECT_TASK_COMMENT } from "modules/Project/services/mutations";
import {
	IProjectTaskComment,
	IProjectTaskCommentForm,
} from "modules/Project/types/project";
import CommentForm from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/CommentForm";
import Comment from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/Comment";
import ActivityLoading from "modules/Project/Pages/Issues/IssueLogs/Activity/ActivityLoading";

const Comments = () => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const [fetchComments, { refetch: refetchComments, loading }] = useLazyQuery(
		PROJECT_TASK_COMMENTS,
	);

	const [fetchProjectMembers, { data: getprojectMembers }] = useLazyQuery(
		PROJECT_MEMBERS_PROFILES,
	);

	const [
		deleteProjectTaskComment,
		{ loading: deleteProjectTaskCommentLoading },
	] = useMutation(DELETE_PROJECT_TASK_COMMENT);

	const { setFocus } = useForm<IProjectTaskCommentForm>();

	const { id, issueId } = useParams();

	const [editComment, setEditComment] = useState<IProjectTaskComment | null>(
		null,
	);
	const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
	const [deleteComment, setDeleteComment] = useState<
		{ id: number; index: number } | undefined
	>();

	useEffect(() => {
		if (id && !isNaN(+id)) {
			fetchProjectMembers({
				variables: {
					filters: {
						projectId: +id,
					},
					type: canReadUserProfileImage ? "Profile" : undefined,
					isProfileImageNeeded: canReadUserProfileImage,
				},
			}).catch((error) => {
				if (error.name === "AbortError") return;
			});
		}
	}, [id, fetchProjectMembers, canReadUserProfileImage]);

	const membersList =
		getprojectMembers?.projectMembers?.dataCollection?.map(
			(member: { user: { personalInformation: IIdAndName } }) => {
				return {
					id: member?.user?.personalInformation?.id,
					name: member?.user?.personalInformation?.name,
				};
			},
		) || [];

	useEffect(() => {
		if (editComment?.message) {
			setFocus("message");
		}
	}, [editComment?.message, setFocus]);

	const confirmModalCloseHandler = () => {
		setDeleteVerification(false);
		setEditComment(null);
	};

	const limit = 10;

	const useNotifcation: any = (pageNumber: number) => {
		const [hasMore, setHasMore] = useState(false);
		const [commentsList, setCommentsList] = useState<any>([]);
		const [length, setLength] = useState<number>(0);
		const [totalNumberOfItems, setTotalNumberOfItems] = useState<
			number | undefined
		>();

		useEffect(() => {
			setLength(commentsList?.length);
		}, [commentsList]);

		useEffect(() => {
			if (issueId && !isNaN(+issueId) && !loading) {
				fetchComments({
					variables: {
						filters: {
							projectTaskId: +issueId,
						},
						limit,
						page: pageNumber,
						type: canReadUserProfileImage ? "Profile" : undefined,
						isProfileImageNeeded: canReadUserProfileImage,
					},
				})
					.then((res) => {
						if (res?.data) {
							setCommentsList((prevState: any) => {
								const commentData =
									res?.data?.projectTaskComments?.dataCollection?.map(
										(notification: any) => notification,
									);

								return removeDuplicateObject(
									[...prevState, commentData].flat(),
									"id",
								);
							});
							setHasMore(
								length < res?.data?.projectTaskComments?.totalNumberOfItems,
							);
							setTotalNumberOfItems(
								res?.data?.projectTaskComments?.totalNumberOfItems,
							);
						}
					})
					.catch((error) => {
						if (error.name === "AbortError") {
							return;
						}
					});
			}
		}, [pageNumber, length]);

		return {
			commentsList,
			totalNumberOfItems,
			hasMore,
		};
	};

	const [pageNumber, setPageNumber] = useState(1);

	const { commentsList, totalNumberOfItems, hasMore } =
		useNotifcation(pageNumber);

	const [comments, setComments] = useState<IProjectTaskComment[]>(commentsList);
	const [showComment, setShowComment] = useState<boolean>(false);

	useEffect(() => {
		setComments(commentsList);
	}, [commentsList]);

	const observer: any = useRef();

	const lastCommentElementRef = useCallback(
		(node: any) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					hasMore &&
					pageNumber < Math.ceil(totalNumberOfItems / limit)
				) {
					setPageNumber((prevPageNumber) => prevPageNumber + 1);
				}
			});

			if (node) observer.current.observe(node);
		},
		[loading, totalNumberOfItems, pageNumber, hasMore],
	);

	const deleteHandler = () => {
		if (deleteComment && !deleteProjectTaskCommentLoading) {
			deleteProjectTaskComment({
				variables: {
					id: deleteComment?.id,
				},
			})
				.then((res) => {
					setComments((prevComments: IProjectTaskComment[]) => {
						const newComments = [...prevComments];
						newComments.splice(deleteComment?.index, 1);
						return newComments;
					});
					toastNotify([
						{
							messageType: "success",
							message: `Comment deleted successfully`,
						},
					]);
					setEditComment(null);
					refetchComments();
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: `${error?.message}`,
						},
					]);
				})
				.finally(() => {
					setDeleteVerification(false);
				});
		}
	};

	return (
		<div
			className={` ${
				comments?.length === 0
					? "overflow-y-hidden"
					: "overflow-y-auto max-h-[700px]"
			}`}
		>
			{!editComment?.id && (
				<CommentForm
					key={editComment?.id}
					editComment={editComment}
					setEditComment={setEditComment}
					refetchComments={refetchComments}
					setComments={setComments}
					showComment={showComment}
					setShowComment={setShowComment}
					membersList={membersList}
				/>
			)}

			{comments?.map((comment: IProjectTaskComment, index: number) =>
				editComment?.id !== comment?.id ? (
					comments?.length === index + 1 ? (
						<div
							key={comment?.id}
							ref={lastCommentElementRef}
							className="mb-10"
						>
							<Comment
								comment={comment}
								setEditComment={setEditComment}
								setDeleteComment={setDeleteComment}
								setDeleteVerification={setDeleteVerification}
								index={index}
								membersList={membersList}
							/>
						</div>
					) : (
						<div key={comment?.id} className="mb-7">
							<Comment
								comment={comment}
								setEditComment={setEditComment}
								setDeleteComment={setDeleteComment}
								setDeleteVerification={setDeleteVerification}
								index={index}
								membersList={membersList}
							/>
						</div>
					)
				) : (
					<CommentForm
						key={comment?.id}
						editComment={editComment}
						setEditComment={setEditComment}
						refetchComments={refetchComments}
						setComments={setComments}
						showComment={showComment}
						setShowComment={setShowComment}
						membersList={membersList}
					/>
				),
			)}
			{loading && <ActivityLoading />}
			{comments?.length <= 0 && !showComment && !loading && (
				<div className="w-full h-[200px] mt-1 flex justify-center items-center border">
					<p className="text-sm text-warm-gray">No comments found.</p>
				</div>
			)}
			{deleteVerification && (
				<ConfirmModal
					header="Comment"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectTaskCommentLoading}
				/>
			)}
		</div>
	);
};

export default Comments;
