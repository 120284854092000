import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import {
  CalendarField,
  Email,
  Select,
  Time,
  Text,
  Outlined,
  Filled,
  Modal,
  ToggleSwitch,
} from "components/forms";
import Loading from "components/Loader/Loading";

import { MdOutlineClose } from "react-icons/md";
import {
  RailwayTimeFormat,
  TwelveHoursFormat,
} from "global/helpers/TimeFormat";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { workInfoFieldValidation } from "modules/Employee/Pages/Profile/Work/WorkInformation/WorkInfoFieldValidation";
import {
  GET_DESIGNATIONS,
  GET_WORK_LOCATIONS,
  SOURCE_OF_HIRE,
  GET_WORK_INFORMATION,
} from "modules/Employee/services/queries";
import {
  CREATE_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE_DETAILS,
} from "modules/Employee/services/mutations";
import {
  IWorkInformation,
  IWorkInformationForm,
} from "modules/Employee/types/work";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  employeeDetails: IWorkInformation;
  setShowModal: Function;
}

const EditWorkInfo: FC<IProps> = ({ employeeDetails, setShowModal }) => {
  const { loading: desigLoader, data: employeeDesignationList } = useQuery(
    GET_DESIGNATIONS,
    { fetchPolicy: "cache-and-network" }
  );

  const { loading: workLocationLoader, data: empworkLocationList } =
    useQuery(GET_WORK_LOCATIONS);

  const { loading: sorceLoader, data: employeeSourceOfHireList } =
    useQuery(SOURCE_OF_HIRE);

  const loading = desigLoader && workLocationLoader && sorceLoader;

  const employeeDesignations =
    (!desigLoader &&
      employeeDesignationList?.employeeDesignations?.dataCollection?.map(
        (designation: { name: string }) => designation?.name
      )) ||
    [];

  const empWorkLocations =
    (!workLocationLoader &&
      empworkLocationList?.workLocations?.dataCollection?.map(
        (workLocation: { name: string }) => workLocation?.name
      )) ||
    [];

  const employeeSourceOfHire =
    (!sorceLoader && employeeSourceOfHireList?.getAvailableSourceOfHiring) ||
    [];

  const [createEmployeeDetail] = useMutation(CREATE_EMPLOYEE_DETAILS);

  const [updateEmployeeDetail] = useMutation(UPDATE_EMPLOYEE_DETAILS);

  const [inProgress, setInProgress] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<IWorkInformationForm>();

  const [empJoiningDate, setEmpJoiningDate] = useState<
    Date | null | undefined
  >();
  const [empProbationStartDate, setEmpProbationStartDate] = useState<
    Date | null | undefined
  >();
  const [empProbationEndDate, setEmpProbationEndDate] = useState<
    Date | null | undefined
  >();
  const [relievingDate, setRelievingDate] = useState<Date | null | undefined>();

  const employeeStausArray = ["Active", "Dismissed", "Abscond", "Probation"];

  if (employeeDetails?.id) {
    employeeStausArray.push("Relieved");
  }

  useEffect(() => {
    if (employeeDetails?.officialEmail) {
      setValue("officialEmail", employeeDetails?.officialEmail);
    }

    if (employeeDesignationList) {
      setTimeout(() => {
        setValue("employeeDesignation", employeeDetails?.employeeDesignation);
      }, 300);
    }

    if (empworkLocationList) {
      setValue("workLocation", employeeDetails?.workLocation);
    }

    if (employeeDetails?.employmentType) {
      setValue("employmentType", employeeDetails?.employmentType);
    }

    if (employeeDetails?.joiningDate) {
      setEmpJoiningDate(
        new Date(employeeDetails?.joiningDate?.replaceAll("-", " "))
      );
    }

    if (employeeDetails?.isFlexibleTiming) {
      setValue("isFlexibleTiming", employeeDetails?.isFlexibleTiming);
    }

    if (employeeDetails?.workStartTime) {
      setValue(
        "workStartTime",
        RailwayTimeFormat(employeeDetails?.workStartTime)
      );
    }

    if (employeeDetails?.workEndTime) {
      setValue("workEndTime", RailwayTimeFormat(employeeDetails?.workEndTime));
    }

    if (employeeDetails?.probationStartDate) {
      setEmpProbationStartDate(
        new Date(employeeDetails?.probationStartDate?.replaceAll("-", " "))
      );
    }

    if (employeeDetails?.probationEndDate) {
      setEmpProbationEndDate(
        new Date(employeeDetails?.probationEndDate?.replaceAll("-", " "))
      );
    }

    if (employeeDetails?.relievingDate) {
      setRelievingDate(
        new Date(employeeDetails?.relievingDate?.replaceAll("-", " "))
      );
    }

    if (employeeDetails?.productivityRatio) {
      setValue("productivityRatio", employeeDetails?.productivityRatio);
    }

    if (employeeDetails?.status) {
      setValue("employeeStatus", employeeDetails?.status);
    }

    if (employeeSourceOfHireList) {
      setValue("sourceOfHire", employeeDetails?.sourceOfHire);
    }

    if (employeeDetails?.canAutoApproveLeaves) {
      setValue("canAutoApproveLeaves", employeeDetails?.canAutoApproveLeaves);
    }
  }, [
    employeeDetails,
    employeeDesignationList,
    empworkLocationList,
    employeeSourceOfHireList,
    setValue,
  ]);

  useEffect(() => {
    if (empJoiningDate) {
      setValue("empJoiningDate", empJoiningDate?.toString());
      clearErrors("empJoiningDate");
    }

    if (empProbationStartDate) {
      setValue("empProbationStartDate", empProbationStartDate?.toString());
      clearErrors("empProbationStartDate");
    }

    if (empProbationEndDate) {
      setValue("empProbationEndDate", empProbationEndDate?.toString());
      clearErrors("empProbationEndDate");
    }

    if (relievingDate) {
      setValue("relievingDate", relievingDate?.toString());
      clearErrors("relievingDate");
    }
  }, [
    empJoiningDate,
    empProbationStartDate,
    empProbationEndDate,
    relievingDate,
    setValue,
    clearErrors,
  ]);

  const isUnderModeration = useIsUnderModeration();

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Work information")
    : createSuccessMessageWithAwaiting("Work information");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Work information")
    : updateSuccessMessageWithAwaiting("Work information");

  const createHandler = (data: IWorkInformationForm) => {
    const validationStatus = workInfoFieldValidation(
      data,
      empJoiningDate,
      empProbationStartDate,
      empProbationEndDate,
      setError,
      toastNotify
    );

    if (validationStatus) {
      if (!inProgress) {
        const sessionId: string | any = document.cookie
          .split("; ")
          .find((row) => row.startsWith("id="))
          ?.split("=")[1];
        createEmployeeDetail({
          variables: {
            userId: sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : +localStorage?.id || +sessionId,
            officialEmail: data.officialEmail,
            employeeDesignation: data.employeeDesignation,
            workLocation: data?.workLocation || null,
            joiningDate: empJoiningDate && ViewDateFormat(empJoiningDate),
            employmentType: data.employmentType,
            isFlexibleTiming: data?.isFlexibleTiming,
            workStartTime: TwelveHoursFormat(data.workStartTime),
            workEndTime: TwelveHoursFormat(data.workEndTime),
            probationStartDate:
              empProbationStartDate && ViewDateFormat(empProbationStartDate),
            probationEndDate:
              empProbationEndDate && ViewDateFormat(empProbationEndDate),
            productivityRatio: parseFloat(data.productivityRatio),
            status: data.employeeStatus,
            sourceOfHire: data.sourceOfHire,
            canAutoApproveLeaves: data.canAutoApproveLeaves,
          },
          update: (cache, { data: { createEmployeeDetail } }) => {
            const exEmployeeDetails: any = cache.readQuery({
              query: GET_WORK_INFORMATION,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            cache.writeQuery({
              query: GET_WORK_INFORMATION,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exEmployeeDetails?.users?.dataCollection[0],
                      employeeDetail: createEmployeeDetail,
                    },
                  ],
                },
              },
            });
          },
        })
          .then(() => {
            moduleCloseHandler();
            toastNotify(createActionSuccesMessage);
          })
          .catch((error: { message: string }) => {
            setInProgress(false);
            toastNotify(errorMessageNotify(error));
          });
        setInProgress(!inProgress);
      }
    }
  };

  const updateHandler = (data: IWorkInformationForm) => {
    const validationStatus = workInfoFieldValidation(
      data,
      empJoiningDate,
      empProbationStartDate,
      empProbationEndDate,
      setError,
      toastNotify
    );

    if (validationStatus) {
      if (!inProgress) {
        updateEmployeeDetail({
          variables: {
            id: +employeeDetails?.id,
            officialEmail: data.officialEmail,
            employeeDesignation: data.employeeDesignation,
            workLocation: data?.workLocation || null,
            joiningDate: empJoiningDate && ViewDateFormat(empJoiningDate),
            employmentType: data.employmentType,
            isFlexibleTiming: data.isFlexibleTiming,
            workStartTime: TwelveHoursFormat(data.workStartTime),
            workEndTime: TwelveHoursFormat(data.workEndTime),
            probationStartDate:
              empProbationStartDate && ViewDateFormat(empProbationStartDate),
            probationEndDate:
              empProbationEndDate && ViewDateFormat(empProbationEndDate),
            productivityRatio: parseFloat(
              data.productivityRatio
                ? data.productivityRatio
                : employeeDetails?.productivityRatio
            ),
            status: data.employeeStatus,
            sourceOfHire: data.sourceOfHire,
            canAutoApproveLeaves: data.canAutoApproveLeaves,
            relievingDate:
              relievingDate && watch("employeeStatus") === "Relieved"
                ? ViewDateFormat(relievingDate)
                : null,
          },
          update: (cache, { data: { updateEmployeeDetail } }) => {
            const exEmployeeDetails: any = cache.readQuery({
              query: GET_WORK_INFORMATION,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
            });

            cache.writeQuery({
              query: GET_WORK_INFORMATION,
              variables: {
                isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
                id: +sessionStorage?.otherUserId
                  ? +sessionStorage?.otherUserId
                  : undefined,
              },
              data: {
                users: {
                  dataCollection: [
                    {
                      ...exEmployeeDetails?.users?.dataCollection[0],
                      employeeDetail: updateEmployeeDetail,
                    },
                  ],
                },
              },
            });
          },
        })
          .then(() => {
            moduleCloseHandler();
            toastNotify(updateActionSuccessMessage);
          })
          .catch((error: { message: string }) => {
            setInProgress(false);
            toastNotify(errorMessageNotify(error));
          });
        setInProgress(!inProgress);
      }
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setEmpJoiningDate(null);
    setEmpProbationStartDate(null);
    setEmpProbationEndDate(null);
    setRelievingDate(null);
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] pr-[28px] lg:pl-[45px] lg:pr-[57px] boder-hit-gray">
          <h2 className="edit-modal-heading">Work Information</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <Loading className="min-h-[539px] lg:min-h-[639px] rounded-b-[12px]" />
          ) : (
            <form
              onSubmit={
                employeeDetails?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="pr-[35px] form-div lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
                <Email
                  register={register}
                  errors={errors}
                  label="Official Email Address *"
                  name="officialEmail"
                  defaultEmail={employeeDetails?.officialEmail}
                  required={true}
                />
                <Select
                  register={register}
                  name={"employeeDesignation"}
                  errors={errors}
                  label={"Designation or Title *"}
                  options={employeeDesignations}
                  required={true}
                  defaultSelectValue={
                    employeeDetails?.employeeDesignation
                      ? employeeDetails?.employeeDesignation
                      : ""
                  }
                />
                <Select
                  register={register}
                  errors={errors}
                  label="Work Location"
                  name="workLocation"
                  options={empWorkLocations}
                  defaultSelectValue={employeeDetails?.workLocation}
                />
                <CalendarField
                  register={register}
                  errors={errors}
                  name="empJoiningDate"
                  date={empJoiningDate}
                  setDate={setEmpJoiningDate}
                  className="h-[50px]"
                  label="Joining Date *"
                  required={true}
                  minDate={new Date(2014, 0, 1)}
                  maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                />
                <Select
                  register={register}
                  errors={errors}
                  label="Employment Type *"
                  name="employmentType"
                  options={["PartTime", "FullTime"]}
                  defaultSelectValue={employeeDetails?.employmentType}
                  required={true}
                />
                <div className="w-full flex md:h-[70px] justify-between items-center pb-[30px]">
                  <p className="md:w-[200px] flex justify-between text-form-gray whitespace-nowrap ml-1">
                    Is Flexible Timing?
                  </p>
                  <div className="md:w-[250px] mt-6 ml-8 md:ml-14 flex justify-end mb-5">
                    <ToggleSwitch
                      label={"Is Flexible Timing?"}
                      register={register}
                      name={"isFlexibleTiming"}
                      defaultValue={employeeDetails?.isFlexibleTiming}
                    />
                  </div>
                </div>
                <Time
                  register={register}
                  name="workStartTime"
                  errors={errors}
                  label="Work Start time *"
                  defaultTime={RailwayTimeFormat(
                    employeeDetails?.workStartTime
                  )}
                  required={true}
                />
                <Time
                  register={register}
                  name="workEndTime"
                  errors={errors}
                  label="Work End Time *"
                  defaultTime={RailwayTimeFormat(employeeDetails?.workEndTime)}
                  required={true}
                />
                <CalendarField
                  register={register}
                  errors={errors}
                  name="empProbationStartDate"
                  date={empProbationStartDate}
                  setDate={setEmpProbationStartDate}
                  className="h-[50px]"
                  label="Probation Start Date *"
                  required={true}
                  minDate={new Date(2014, 0, 1)}
                  maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                />
                <CalendarField
                  register={register}
                  errors={errors}
                  name="empProbationEndDate"
                  date={empProbationEndDate}
                  setDate={setEmpProbationEndDate}
                  className="h-[50px]"
                  label="Probation End Date *"
                  required={true}
                  minDate={new Date(2014, 0, 1)}
                  maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                />
                <Text
                  register={register}
                  errors={errors}
                  name="productivityRatio"
                  label="Productivity Ratio *"
                  defaultText={employeeDetails?.productivityRatio}
                  maximumLength={6}
                  minimumLength={1}
                  regex={/^[0-9]+(.[0-9]{1,2})?$/}
                  required={true}
                />
                <Select
                  register={register}
                  name="sourceOfHire"
                  errors={errors}
                  label="Source Of Hire *"
                  options={employeeSourceOfHire}
                  required={true}
                  defaultSelectValue={
                    employeeDetails?.sourceOfHire
                      ? employeeDetails?.sourceOfHire
                      : ""
                  }
                />
                <Select
                  register={register}
                  name="employeeStatus"
                  errors={errors}
                  label="Employee Status *"
                  options={employeeStausArray}
                  defaultSelectValue={employeeDetails?.status}
                  required={true}
                />
                {watch("employeeStatus") === "Relieved" &&
                  employeeDetails?.id && (
                    <CalendarField
                      register={register}
                      errors={errors}
                      name="relievingDate"
                      required={relievingDate ? false : true}
                      date={relievingDate}
                      setDate={setRelievingDate}
                      className="h-[50px]"
                      label="Relieving Date *"
                      minDate={
                        //Calculation to add 1 day after the prohibation end date
                        new Date(
                          new Date(
                            employeeDetails?.probationEndDate?.replaceAll(
                              "-",
                              " "
                            )
                          )?.getTime() +
                            1 * 24 * 60 * 60 * 1000
                        )
                      }
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                    />
                  )}
                <div className="w-full flex md:h-[70px] justify-between items-center pb-[30px]">
                  <p className="md:w-[200px] flex justify-between text-form-gray whitespace-nowrap ml-1">
                    Can Auto Approve Leaves?
                  </p>
                  <div className="md:w-[250px] mt-6 ml-8 md:ml-14 flex justify-end mb-5">
                    <ToggleSwitch
                      label={"Can Auto Approve Leaves?"}
                      register={register}
                      name={"canAutoApproveLeaves"}
                      defaultValue={employeeDetails?.canAutoApproveLeaves}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full border-b border-hit-gray pt-[6px]" />
              <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
                <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : employeeDetails?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditWorkInfo;
