import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loader/Loading";

import { MessageType } from "layouts/header/TopLinks/Notification/Pages/ViewAllMessages/AllMessages";
import MessageDetails from "layouts/header/TopLinks/Notification/Pages/ViewAllMessages/MessageDetails";
import {
  MessagesType,
  NotificationsType,
} from "layouts/header/TopLinks/TopLinks";
import { USER_NOTIFICATIONS_COLLECTION_QUERY } from "layouts/header/TopLinks/Notification/services/queries";

import { groupBy } from "global/helpers/ArrayMethods";
import { ViewDateFormat } from "global/helpers/DateFormatter";

const UnreadMessages = () => {
  const [
    fetchUnReadMessage,
    { loading, data: unReadMsg, refetch: refetchUnReadMsg },
  ] = useLazyQuery(USER_NOTIFICATIONS_COLLECTION_QUERY);

  const unReadNotifications: NotificationsType[] = unReadMsg?.notifications?.dataCollection?.map(
    (message: MessagesType) => {
      return {
        id: message?.id,
        message: message?.message,
        date: message?.createdAt?.slice(0, 11),
        time: message?.createdAt?.slice(-8),
        isRead: message?.isRead,
        link: message?.link,
        triggeredByUser: message?.triggeredByUser,
      };
    }
  );

  const notifications: {
    message: string;
    date: string;
    time: string;
    isRead: boolean;
  }[] = unReadNotifications;

  const [showReadMessage, setShowReadMessage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  useEffect(() => {
    setShowReadMessage(false);
    refetchUnReadMsg();
  }, [refetchUnReadMsg, showReadMessage]);
  useEffect(() => {
    if (pageSize && currentPage) {
      fetchUnReadMessage({
        variables: {
          limit: pageSize,
          page: currentPage,
          isRead: false,
        },
      });
    }
  }, [pageSize, currentPage, fetchUnReadMessage]);

  const unreadNotificationsLength = notifications?.filter(
    (notification: { isRead: boolean }) => !notification.isRead
  )?.length;

  return (
    <div className="card-outer min-h-[76vh]">
      {loading ? (
        <Loading className="min-h-[76vh]" />
      ) : unreadNotificationsLength > 0 ? (
        <div>
          <div className="pt-[25px] min-h-[555px] overflow-x-auto">
            {notifications &&
              Object?.entries(groupBy(notifications, "date"))?.map(
                ([key, value]: any, index: number) => {
                  return (
                    <div key={index}>
                      {value?.length > 0 ? (
                        <div className="pt-5">
                          <p className="text-sm text-ironside-gray/70 py-1 px-3 rounded border w-min whitespace-nowrap">
                            {key === ViewDateFormat(new Date()?.toDateString())
                              ? "Today"
                              : key ===
                                ViewDateFormat(
                                  new Date(yesterday)?.toDateString()
                                )
                              ? "Yesterday"
                              : key}
                          </p>
                          <div className="pt-3">
                            {value?.map(
                              (mapData: MessageType, index: number) => {
                                if (mapData?.isRead === showReadMessage)
                                  return (
                                    <MessageDetails
                                      key={mapData?.id}
                                      message={mapData}
                                      refetchMsg={refetchUnReadMsg}
                                    />
                                  );
                                return null;
                              }
                            )}
                          </div>
                        </div>
                      ) : (
                        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
                          No Messages
                        </p>
                      )}
                    </div>
                  );
                }
              )}
          </div>
          <Pagination
            totalCount={unReadMsg?.notifications?.totalNumberOfItems || 0}
            currentPage={currentPage}
            setPageSize={setPageSize}
            siblingCount={1}
            noOfItem={notifications?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
          No Messages
        </p>
      )}
    </div>
  );
};

export default UnreadMessages;
