import { gql } from "@apollo/client";

export const CREATE_TEAM = gql`
  mutation createEmployeeTeam(
    $name: String!
    $code: String!
    $teamLeadUser: Int!
    $memberIds: [Int!]
  ) {
    createEmployeeTeam(
      name: $name
      code: $code
      teamLeadUser: $teamLeadUser
      memberIds: $memberIds
    ) {
      id
      name
      code
      teamLeadUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          officialEmail
        }
        userProfileImages {
          documents {
            file
          }
          type
        }
      }
      members {
        id
        personalInformation {
          name
        }
        employeeDetail {
          officialEmail
        }
        userProfileImages {
          documents {
            file
          }
          type
        }
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateEmployeeTeam(
    $id: Int!
    $name: String
    $code: String
    $teamLeadUser: Int
    $memberIdsToAdd: [Int!]
    $memberIdsToRemove: [Int!]
  ) {
    updateEmployeeTeam(
      id: $id
      name: $name
      code: $code
      teamLeadUser: $teamLeadUser
      memberIdsToAdd: $memberIdsToAdd
      memberIdsToRemove: $memberIdsToRemove
    ) {
      id
      name
      code
      teamLeadUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      members {
        id
        personalInformation {
          name
          personalEmail
        }
        employeeDetail {
          employeeId
          officialEmail
        }
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteEmployeeTeam($id: Int!) {
    deleteEmployeeTeam(id: $id)
  }
`;
