import { DateFormat } from "global/helpers/DateFormatter";

import { IWorkInformationForm } from "modules/Employee/types/work";

export const workInfoFieldValidation = (
  validation: IWorkInformationForm,
  empJoiningDate: Date | null | undefined,
  empProbationStartDate: Date | null | undefined,
  empProbationEndDate: Date | null | undefined,
  setError: Function,
  setAlert: Function
) => {
  let startYear = +DateFormat(empProbationStartDate?.toString())?.slice(0, 4);
  let endYear = +DateFormat(empProbationEndDate?.toString())?.slice(0, 4);
  let startMonth = +DateFormat(empProbationStartDate?.toString())?.slice(5, 7);
  let endMonth = +DateFormat(empProbationEndDate?.toString())?.slice(5, 7);
  let startDate = +DateFormat(empProbationStartDate?.toString())?.slice(8, 11);
  let endDate = +DateFormat(empProbationEndDate?.toString())?.slice(8, 11);

  let timeValidation = () => {
    if (validation?.workStartTime && validation?.workEndTime) {
      if (
        validation?.workStartTime?.slice(0, 2) >
        validation?.workEndTime?.slice(0, 2)
      ) {
        setError("workEndTime", {
          type: "manual",
          message: "Please enter the valid end time.",
        });
        setError("workEndTime", {
          type: "manual",
          message: "Please enter the valid end time.",
        });
        return false;
      } else if (
        validation?.workStartTime?.slice(0, 2) <
        validation?.workEndTime?.slice(0, 2)
      ) {
        return true;
      } else if (
        validation?.workStartTime?.slice(0, 2) ===
        validation?.workEndTime?.slice(0, 2)
      ) {
        if (
          validation?.workStartTime?.slice(3, 5) >
          validation?.workEndTime?.slice(3, 5)
        ) {
          setError("workEndTime", {
            type: "manual",
            message: "Please enter the valid end time.",
          });
          setError("workEndTime", {
            type: "manual",
            message: "Please enter the valid end time.",
          });
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  let dateValidation = () => {
    if (
      DateFormat(empProbationStartDate?.toString()) &&
      DateFormat(empProbationEndDate?.toString())
    ) {
      if (startYear > endYear) {
        setError("empProbationStartDate", {
          type: "manual",
          message: "Please enter the valid probation start date.",
        });
        setError("empProbationEndDate", {
          type: "manual",
          message: "Please enter the valid probation end date.",
        });
        return false;
      } else if (startYear < endYear) {
        return true;
      } else if (startYear === endYear) {
        if (startMonth > endMonth) {
          setError("empProbationStartDate", {
            type: "manual",
            message: "Please enter the valid probation start date.",
          });
          setError("empProbationEndDate", {
            type: "manual",
            message: "Please enter the valid probation end date.",
          });
          return false;
        } else if (startMonth < endMonth) {
          return true;
        } else if (startMonth === endMonth) {
          if (startDate > endDate) {
            setError("empProbationStartDate", {
              type: "manual",
              message: "Please enter the valid probation start date.",
            });
            setError("empProbationEndDate", {
              type: "manual",
              message: "Please enter the valid probation end date.",
            });
            return false;
          } else if (startDate < endDate) {
            return true;
          } else if (startDate === endDate) {
            setError("empProbationStartDate", {
              type: "manual",
              message: "Please enter the valid probation start date.",
            });
            setError("empProbationEndDate", {
              type: "manual",
              message: "Please enter the valid probation end date.",
            });
            return false;
          }
        }
      }
    } else {
      return true;
    }
  };

  let productivityValidation = () => {
    if (+validation?.productivityRatio > 999.99) {
      setError("productivityRatio", {
        type: "manual",
        message: "Please enter the valid number.",
      });
      return false;
    } else {
      return true;
    }
  };

  let probationValidation = () => {
    if (
      DateFormat(empJoiningDate?.toString()) !==
      DateFormat(empProbationStartDate?.toString())
    ) {
      setError("empJoiningDate", {
        type: "manual",
        message: "Please enter the valid joining date.",
      });
      setError("empProbationStartDate", {
        type: "manual",
        message: "Please enter the valid probation start date.",
      });
      setAlert([
        {
          messageType: "Error",
          message: "Joining date should be same as Probation start date.",
        },
      ]);
      return false;
    } else {
      return true;
    }
  };

  timeValidation();
  dateValidation();
  productivityValidation();
  probationValidation();
  return (
    timeValidation() &&
    dateValidation() &&
    productivityValidation() &&
    probationValidation()
  );
};
