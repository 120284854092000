import { Button, FileTrigger } from "react-aria-components";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { ReactHookFormOnChangeEvent } from "components/forms/UpdatedFormAgry/types";
import FileRender from "components/forms/UpdatedFormAgry/File/FileRender";

import {ReactComponent as UploadIcon} from "global/assets/images/upload.svg";
import { toastNotify } from "global/helpers/Cache";
import { cn } from "global/helpers/tailwind-utils";

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: Name;
  control: Control<TFieldValues>;
  disabled?: boolean;
  onChange?: ReactHookFormOnChangeEvent<TFieldValues, Name>;
  className?: string;
  acceptedFileTypes?: string[];
  shouldUnregister?: boolean;
  allowsMultiple?: boolean;
}

const FileTriggerComponent = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  acceptedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
  ],
  className,
  disabled,
  onChange,
  shouldUnregister,
  allowsMultiple,
}: Props<TFieldValues, Name>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      rules={{
        onChange: (e: any) => {
          if (onChange) {
            onChange(e.target?.value);
          }
        },
      }}
      render={({ field: { value, onChange } }) => {
        const fileList = value as unknown as {
          id: string | number;
          file: File;
        }[];

        return (
          <div
            className={cn(
              "w-full bg-background flex flex-col gap-4",
              className
            )}
          >
            <label
              htmlFor={name}
              className="text-base font-medium text-on-background"
            >
              Upload files
            </label>
            {fileList?.length > 0 && (
              <div className="grid grid-cols-2 gap-4">
                {fileList?.map((file, index) => {
                  return (
                    <FileRender
                      value={value}
                      onChange={onChange}
                      key={index}
                      file={file.file}
                      id={file?.id}
                    />
                  );
                })}
              </div>
            )}
            <FileTrigger
              onSelect={(e) => {
                if (e) {
                  if (allowsMultiple) {
                    if (
                      Array.from(e).some((e) =>
                        acceptedFileTypes.includes(e.type)
                      )
                    ) {
                      toastNotify([
                        {
                          message: "Unsupported file format",
                          messageType: "error",
                        },
                      ]);
                    } else if (e) {
                      onChange(
                        value
                          ? [
                              ...value,
                              ...Array.from(e).map((e, index) => ({
                                id: `${index}-${(
                                  value?.length + 1
                                )?.toString()}`,
                                file: e,
                              })),
                            ]
                          : [
                              ...Array.from(e).map((e, index) => ({
                                id: `${index}-1`,
                                file: e,
                              })),
                            ]
                      );
                    } else {
                      onChange(value);
                    }
                  } else {
                    const file = Array.from(e)[0];

                    if (!acceptedFileTypes?.includes(file?.type)) {
                      toastNotify([
                        {
                          message: "Unsupported file format",
                          messageType: "error",
                        },
                      ]);
                    } else if (e) {
                      onChange(
                        value
                          ? [
                              ...value,
                              ...Array.from(e).map((e, index) => ({
                                id: `${index}-${(
                                  value?.length + 1
                                )?.toString()}`,
                                file: e,
                              })),
                            ]
                          : [
                              ...Array.from(e).map((e, index) => ({
                                id: `${index}-1`,
                                file: e,
                              })),
                            ]
                      );
                    } else {
                      onChange(value);
                    }
                  }
                }
              }}
              acceptedFileTypes={acceptedFileTypes}
              allowsMultiple={true}
            >
              <Button
                className={
                  "border border-outline border-dashed rounded p-4 flex w-full items-center justify-center gap-2 outline-offset-4 outline-red-500"
                }
              >
                <p className="text-sm text-on-background">Browse file</p>
                <UploadIcon className="min-w-4 min-h-4 text-secondary" />
              </Button>
            </FileTrigger>
          </div>
        );
      }}
    />
  );
};

export default FileTriggerComponent;
