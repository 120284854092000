import { ComponentPropsWithoutRef, ElementRef, FC, useRef } from "react";
import { TimeValue, useLocale, useTimeField } from "react-aria";
import { TimeFieldStateOptions, useTimeFieldState } from "react-stately";
import { twMerge } from "tailwind-merge";
import { getLocalTimeZone, now } from "@internationalized/date";

import DateSegment from "components/forms/UpdatedFormAgry/Date/Date/DateSegment";

type Props = Omit<
  TimeFieldStateOptions<TimeValue>,
  "locale" | "label" | "errorMessage" | "hideTimeZone"
> &
  Omit<
    ComponentPropsWithoutRef<"div">,
    "children" | "onChange" | "defaultValue"
  > & {
    onChangeHookForm?: (...event: any[]) => void;
    hideError?: boolean;
    classNameForError?: string;
    label?: string;
    errorMessage?: string;
    classForTimeField?: string;
  };

const TimeField: FC<Props> = ({
  autoFocus,
  defaultValue,
  description,
  errorMessage,
  granularity,
  hourCycle = 12,
  isDisabled,
  isReadOnly,
  isRequired,
  label,
  maxValue,
  minValue,
  onBlur,
  onChange,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  placeholderValue,
  value,
  className,
  onChangeHookForm,
  hideError,
  classNameForError,
  shouldForceLeadingZeros,
  classForTimeField,
  ...props
}) => {
  const parentRef = useRef<ElementRef<"div">>(null);
  const ref = useRef<ElementRef<"div">>(null);

  const { locale } = useLocale();

  const state = useTimeFieldState({
    locale,
    autoFocus,
    defaultValue: defaultValue || now(getLocalTimeZone()),
    description,
    errorMessage,
    granularity,
    hideTimeZone: true,
    hourCycle,
    isDisabled,
    isReadOnly,
    isRequired,
    label,
    maxValue,
    minValue,
    onBlur,
    onChange: onChangeHookForm ? onChangeHookForm : onChange,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
    placeholderValue,
    value,
    shouldForceLeadingZeros,
  });

  const { labelProps, fieldProps } = useTimeField(
    {
      autoFocus,
      defaultValue: defaultValue || now(getLocalTimeZone()),
      description,
      errorMessage,
      granularity,
      hideTimeZone: true,
      hourCycle,
      isDisabled,
      isReadOnly,
      isRequired,
      label,
      maxValue,
      minValue,
      onBlur,
      onChange,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      placeholderValue,
      value,
      shouldForceLeadingZeros,
    },
    state,
    ref
  );
  const yearRef = useRef<ElementRef<"div">>(null);

  const errorValue = errorMessage
    ? errorMessage
    : state.validationState === "invalid"
    ? "Invalid date."
    : undefined;
  return (
    <div
      {...props}
      className={twMerge(
        "flex items-start relative w-full bg-white flex-col",
        className || ""
      )}
      ref={parentRef}
    >
      <div
        {...fieldProps}
        ref={ref}
        className={twMerge(
          "peer w-full bg-inherit min-h-[45px] border flex items-center transition-colors focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue rounded text-sm text-ironside-gray outline-none py-2 px-4  appearance-none",
          errorValue ? "text-error" : "",
          classForTimeField,
          isDisabled ? "border-outline border-opacity-[0.38]" : ""
        )}
      >
        <div className="flex peer">
          {state?.segments.map((segment, i) => {
            return segment?.text?.toString().startsWith("G") ? null : (
              <DateSegment
                key={i}
                segment={segment}
                state={state}
                yearRef={yearRef}
              />
            );
          })}
        </div>
        {label && (
          <label
            {...labelProps}
            style={{
              maxWidth: parentRef?.current
                ? (
                    parentRef?.current?.getBoundingClientRect()?.width - 20
                  )?.toString() + "px"
                : undefined,
            }}
            className={twMerge(
              "absolute -top-2 left-3.5 bg-inherit px-1 text-xs truncate cursor-default text-on-surface-variant max-w-[50%] select-none",
              errorMessage
                ? "text-error"
                : isDisabled
                ? "text-outline text-opacity-[0.38]"
                : "text-cornflower-blue peer-focus-within:text-primary",
              errorMessage
                ? "text-error"
                : isDisabled
                ? "text-outline text-opacity-[0.38]"
                : "text-warm-gray/70 peer-focus-within:text-cornflower-blue"
            )}
          >
            {label}
          </label>
        )}
      </div>

      {hideError ? null : (
        <div className={`w-[inherit] h-min ${classNameForError}`}>
          <span
            className={`text-error text-xs ${
              errorValue ? "visible" : "invisible"
            } `}
          >
            {errorValue ? errorValue : "Helper Text"}
          </span>
        </div>
      )}
    </div>
  );
};

export default TimeField;
