import { FC, useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";

import Filter from "components/FilterUpdated/Filter";
import { FilterOptions } from "components/FilterUpdated/types";

import { countNonEmptyKeys } from "global/helpers/removeUndefinedValuesFromObject";
import { FetchMoreType, FilterFormType } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import { yesOrNoOption } from "global/helpers/utils";

import {
  GET_COMPANY_ADDRESSES,
  GET_COMPANY_CIN,
  GET_COMPANY_EMAILS,
  GET_COMPANY_GSTIN,
  GET_COMPANY_MANAGERS,
  GET_COMPANY_NAMES,
  GET_COMPANY_OWNERS,
  GET_COMPANY_PAN,
} from "modules/Company/services";
import {
  CompanyFilterForm,
  FilterCompaniesArgs,
  FilterCompaniesCommonArgs,
  FilterCompaniesReturnType,
} from "modules/Company/types";
import {
  companyStatuses,
  companyTypes,
  filterSubmitValues,
} from "modules/Company/helpers/utils";

interface Props {
  control: Control<FilterFormType<CompanyFilterForm>, any>;
  setValue: UseFormSetValue<FilterFormType<CompanyFilterForm>>;
  search: string;
  companiesLength: number;
  canCreate: boolean;
  addBtnHandler: () => void;
  fieldArgs: FilterCompaniesCommonArgs;
  pageSize: number | undefined;
  fetchMore: FetchMoreType<FilterCompaniesReturnType, FilterCompaniesArgs>;
}

const CompanyFilter: FC<Props> = ({
  control,
  setValue,
  search,
  companiesLength,
  canCreate,
  addBtnHandler,
  fieldArgs,
  fetchMore,
  pageSize,
}) => {
  const [filterValues, setFilterValues] = useState<
    CompanyFilterForm | null | undefined
  >(null);

  const hideGlobalSearchAddBtnAndFilterBtn =
    search?.length === 0 &&
    companiesLength === 0 &&
    countNonEmptyKeys(filterValues) === 0;

  const onSearchChange = (page) => {
    fetchMore({
      variables: {
        globalSearch: search || null,
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
        return {
          filterCompanies,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onClear = () => {
    fetchMore({
      variables: {
        pagination: {
          size: pageSize,
        },
        ...fieldArgs,
      },
      updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
        return {
          filterCompanies,
        };
      },
    }).catch((error) => {
      toastNotify(errorMessageNotify(error));
    });
  };

  const onSubmit: (values: FilterFormType<CompanyFilterForm>) => void = ({
    filter,
  }) => {
    if (filter) {
      fetchMore({
        variables: {
          filters: filter
            ? {
                ...filterSubmitValues(filter),
              }
            : undefined,
          pagination: {
            size: pageSize,
          },
          globalSearch: search || undefined,
          ...fieldArgs,
        },
        updateQuery: (prev, { fetchMoreResult: { filterCompanies } }) => {
          return {
            filterCompanies,
          };
        },
      }).catch((error) => {
        toastNotify(errorMessageNotify(error));
      });
    }
  };

  const filterOptions: FilterOptions<FilterFormType<CompanyFilterForm>> = [
    {
      name: "Name",
      fieldOption: {
        name: "filter.names",
        label: "Name",
        type: "string",
        query: GET_COMPANY_NAMES,
        multiple: true,
      },
    },
    {
      name: "Type",
      fieldOption: {
        name: "filter.types",
        type: "checkbox",
        options: companyTypes,
        multiple: true,
      },
    },
    {
      name: "Status",
      fieldOption: {
        type: "checkbox",
        name: "filter.status",
        options: companyStatuses,
        multiple: true,
      },
    },
    {
      name: "Address",
      fieldOption: {
        name: "filter.address",
        label: "Address",
        type: "string",
        query: GET_COMPANY_ADDRESSES,
      },
    },
    {
      name: "Mobile",
      fieldOption: {
        name: "filter.mobile",
        label: "Mobile",
        type: "string",
        query: GET_COMPANY_ADDRESSES,
      },
    },
    {
      name: "Alt mobile",
      fieldOption: {
        name: "filter.altMobile",
        label: "Alt mobile",
        type: "string",
        query: GET_COMPANY_ADDRESSES,
      },
    },
    {
      name: "Email",
      fieldOption: {
        name: "filter.email",
        label: "Email",
        type: "string",
        query: GET_COMPANY_EMAILS,
      },
    },
    {
      name: "Owners",
      fieldOption: {
        type: "string",
        label: "Owners",
        name: "filter.owners",
        query: GET_COMPANY_OWNERS,
      },
    },
    {
      name: "Managers",
      fieldOption: {
        type: "string",
        label: "Owners",
        name: "filter.managers",
        query: GET_COMPANY_MANAGERS,
      },
    },
    {
      name: "CIN",
      fieldOption: {
        type: "string",
        label: "CIN",
        name: "filter.cin",
        query: GET_COMPANY_CIN,
      },
    },
    {
      name: "GSTIN",
      fieldOption: {
        type: "string",
        label: "GSTIN",
        name: "filter.gstin",
        query: GET_COMPANY_GSTIN,
      },
    },
    {
      name: "PAN",
      fieldOption: {
        type: "string",
        label: "PAN",
        name: "filter.pan",
        query: GET_COMPANY_PAN,
      },
    },
    {
      name: "Is CIN verified?",
      fieldOption: {
        type: "checkbox",
        name: "filter.isCinVerified",
        options: yesOrNoOption,
      },
    },
    {
      name: "Is GSTIN verified?",
      fieldOption: {
        type: "checkbox",
        name: "filter.isGstinVerified",
        options: yesOrNoOption,
      },
    },
    {
      name: "Is PAN verified?",
      fieldOption: {
        type: "checkbox",
        name: "filter.isPanVerified",
        options: yesOrNoOption,
      },
    },
  ];

  return (
    <Filter
      control={control}
      filterValues={filterValues}
      setValue={setValue}
      setFilterValues={setFilterValues}
      options={filterOptions}
      globalSearchName="search"
      hideAddBtn={hideGlobalSearchAddBtnAndFilterBtn || !canCreate}
      hideFilterBtn={hideGlobalSearchAddBtnAndFilterBtn}
      hideGlobalSearch={hideGlobalSearchAddBtnAndFilterBtn}
      addBtnDisabled={!canCreate}
      addBtnClickHandler={addBtnHandler}
      onSearchChange={onSearchChange}
      onSubmit={onSubmit}
      onClear={onClear}
      className="mt-5"
    />
  );
};

export default CompanyFilter;
