import { ReactComponent as TrashIcon } from "global/assets/images/trash-icon.svg";
import { ReactComponent as PencilIcon } from "global/assets/images/pencil-dark-icon.svg";
import { FC } from "react";

interface IProps {
  onEdit?: () => void | undefined;
  onDelete?: () => void | undefined;
}

const KebabMenu: FC<IProps> = ({ onEdit, onDelete }) => {
  return (
    <div className="absolute flex flex-col drop-shadow-[0px_0px_10px_#ABABAB29] rounded-[4px] bg-white after:content-[''] after:absolute after:-ml-[10px] after:border-[10px] after:-top-5 after:left-[90%] after:border-[transparent_transparent_white_transparent]">
      {onEdit && (
        <div
          className="cursor-pointer py-[6px] pl-[27px] pr-[28px] flex group items-center hover:bg-white-smoke mt-[12px]"
          onClick={onEdit}
        >
          <PencilIcon className="w-[14px] h-[14px] text-[#A4A4A5] cursor-pointer" />
          <span className="pl-[8px] text-[14px] leading-[17px] text-ironside-gray">
            Edit
          </span>
        </div>
      )}
      {onDelete && (
        <div
          className="cursor-pointer py-[6px] pl-[27px] pr-[28px] flex group items-center hover:bg-white-smoke mb-[12px]"
          onClick={onDelete}
        >
          <TrashIcon className="w-[13px] h-[18px] text-[#A4A4A5] cursor-pointer" />
          <span className="pl-[8px] text-[14px] leading-[17px] text-ironside-gray">
            Delete
          </span>
        </div>
      )}
    </div>
  );
};

export default KebabMenu;
