import { TypedDocumentNode, gql } from "@apollo/client";

import { PAGINATION_INFO } from "global/services";

import {
  FilterProjectGroupBugSourceArgs,
  FilterProjectGroupBugSourceReturnType,
} from "modules/Project/Pages/ProjectGroupBugSource/types";
import { PROJECT_GROUP_BUG_SOURCE_FRAGMENT } from "modules/Project/Pages/ProjectGroupBugSource/services/fragments";

export const FILTER_PROJECT_BUG_SOURCE: TypedDocumentNode<
  FilterProjectGroupBugSourceReturnType,
  FilterProjectGroupBugSourceArgs
> = gql`
  ${PROJECT_GROUP_BUG_SOURCE_FRAGMENT}
  ${PAGINATION_INFO}
  query FilterProjectBugSource(
    $filters: ProjectBugSourceFilterInput
    $globalSearch: String
    $pagination: Pagination
    $orderBy: OrderByField
    $isProjectGroupBugSourceDescriptionNeed: Boolean = false
    $isProjectGroupBugSourceProjectGroupNeed: Boolean = false
  ) {
    filterProjectBugSource(
      filters: $filters
      globalSearch: $globalSearch
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...projectBugSourceFields
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const FILTER_PROJECT_BUG_SOURCE_NAMES = gql`
  ${PAGINATION_INFO}
  query getProjectBugSourceNames($search: String, $pagination: Pagination) {
    getDropDownField: filterProjectBugSource(
      filters: { name: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: name
          name
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const FILTER_PROJECT_BUG_SOURCE_PROJECT_GROUP_NAMES = gql`
  ${PAGINATION_INFO}
  query getProjectBugSourceProjectGroupNames($search: String, $pagination: Pagination) {
    getDropDownField: filterProjectBugSource(
      filters: { projectGroupName: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          projectGroup {
            id: name
            name
          }
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;
