import { ReactNode } from "react";
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import UpdatedDataCard from "components/App/DataCard/UpdatedDataCard";
import Modal from "components/forms/Modal/UpdatedModalAgry/Modal";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import Button from "components/forms/Button/UpdatedButtonAgry/Button";

interface Props<Form extends FieldValues> {
  children: ReactNode;
  title: string;
  loading?: boolean;
  handleSubmit: UseFormHandleSubmit<Form>;
  onSubmit: SubmitHandler<Form>;
  outlineBtnName: string;
  filledBtnName: string;
  onClose?: () => void;
  outlineBtnLoading?: boolean;
  filledBtnLoading?: boolean;
  filledBtnDisable?: boolean;
  outlineBtnDisable?: boolean;
  classNameForDataCardChildren?: string;
  modalClassName?: string;
  hideOutLineButton?: boolean;
  hideFilledButton?: boolean;
  classNameForDataCard?: string;
  classNameForDataCardTitle?: string;
  outlineBtnModifier?: "outline" | "filled" | "plain";
  filledBtnModifier?: "outline" | "filled" | "plain";
  classForButton?: string;
  outlineBtnClassName?: string;
  filledBtnClassName?: string;
  outlineBtnVariant?: "primary" | "destructive" | "tertiary" | undefined;
}

const EditForm = <Form extends FieldValues>({
  children,
  title,
  loading,
  onSubmit,
  handleSubmit,
  outlineBtnName,
  filledBtnName,
  outlineBtnLoading,
  filledBtnLoading,
  onClose,
  filledBtnDisable,
  outlineBtnDisable,
  classNameForDataCardChildren = "",
  modalClassName,
  hideOutLineButton,
  hideFilledButton,
  classNameForDataCard,
  classNameForDataCardTitle,
  outlineBtnModifier,
  filledBtnModifier,
  classForButton,
  outlineBtnClassName,
  filledBtnClassName,
  outlineBtnVariant,
}: Props<Form>) => {
  return (
    <Modal
      onClose={outlineBtnLoading ? undefined : onClose}
      className={modalClassName ? modalClassName : undefined}
    >
      <UpdatedDataCard
        className={twMerge(
          "p-6 rounded-xl max-w-sm min-w-[340px] sm:w-[380px]",
          classNameForDataCard
        )}
        title={title}
        classNameForTitle={twMerge("text-2xl", classNameForDataCardTitle)}
      >
        {loading ? (
          <Loading className="min-h-[300px]" />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={twMerge(
                "max-h-[500px] min-h-[50px] overflow-y-auto space-y-2 py-5",
                classNameForDataCardChildren
              )}
            >
              {children}
            </div>
            {(!hideFilledButton || !hideOutLineButton) && (
              <div
                className={twMerge(
                  "flex justify-between gap-4 items-center",
                  classForButton
                )}
              >
                {!hideOutLineButton && (
                  <Button
                    children={outlineBtnName}
                    type="button"
                    disabled={outlineBtnLoading || outlineBtnDisable}
                    loading={outlineBtnLoading}
                    onPress={onClose}
                    variant={outlineBtnVariant ? outlineBtnVariant : "primary"}
                    modifier={
                      outlineBtnModifier ? outlineBtnModifier : "outline"
                    }
                    className={outlineBtnClassName}
                    isRounded
                  />
                )}
                {!hideFilledButton && (
                  <Button
                    children={filledBtnName}
                    type="submit"
                    disabled={filledBtnDisable}
                    loading={filledBtnLoading}
                    variant="primary"
                    isRounded
                    className={filledBtnClassName}
                    modifier={filledBtnModifier ? filledBtnModifier : "filled"}
                  />
                )}
              </div>
            )}
          </form>
        )}
      </UpdatedDataCard>
    </Modal>
  );
};

export default EditForm;
