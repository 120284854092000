import { useLazyQuery, useQuery } from "@apollo/client";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";
import SelectWithId from "components/forms/Select/SelectWithId";
import { DateFormat } from "global/helpers/DateFormatter";
import { currentDate, paginationDefaultValue } from "global/helpers/StaticData";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AbsenteesDetails from "./AbsenteesDetails";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { SearchField } from "components/forms";
import {
  ABSENTEES_QUERY,
  LEAVE_DASHBOARD,
} from "modules/Leave/services/queries";

const Absentees = () => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const { data: leaveDashboard } = useQuery(LEAVE_DASHBOARD, {
    variables: {
      userId: +localStorage?.id || +sessionId,
    },
  });

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const leaveOptions = leaveDashboard?.leaveDashboard
    ?.map(
      (leaves: { leaveType: any; id: number; name: string; year: number }) => {
        if (leaves?.year === new Date()?.getFullYear()) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else if (leaves?.leaveType?.isSpecialLeave) {
          return { id: leaves?.leaveType?.id, name: leaves?.leaveType?.name };
        } else {
          return null;
        }
      }
    )
    ?.filter((leaveType: { id: number; name: string }) => leaveType);

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const filterEmployee = watch("filterEmployee");
  const [search, setSearch] = useState(filterEmployee);
  const leaveType = watch("session");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());

  const [fetchLeaveRequests, { data: filterLeaveRequests, loading }] =
    useLazyQuery(ABSENTEES_QUERY);

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(filterEmployee?.trim());
    }, 300);

    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [filterEmployee]);

  useEffect(() => {
    fetchLeaveRequests({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        from: fromDate ? fromDate : currentDate,
        to: toDate ? toDate : currentDate,
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [
    pageSize,
    currentPage,
    fromDate,
    toDate,
    leaveType,
    search,
    fetchLeaveRequests,
  ]);

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] md:p-2 lg:px-[23px] bg-white py-[10px] overflow-hidden min-h-[600px] md:min-h-[760px] lg:min-h-[824px]">
      <div className="mt-8">
        <div className="grid grid-cols-[repeat(1,260px)] gap-2 sm:grid-cols-[repeat(2,260px)] sm:gap-5 xl:grid-cols-[repeat(4,260px)] 2xl:grid-cols-[repeat(4,250px)] justify-center items-center xl:justify-start">
          <div className="pb-6">
            <SearchField
              register={register}
              name="filterEmployee"
              label="Employee"
              onChange={() => {
                setCurrentPage(1);
              }}
              setValue={setValue}
            />
          </div>
          <SelectWithId
            register={register}
            name={"session"}
            errors={errors}
            label={"Leave Type"}
            options={leaveOptions}
            enableDefaultSelectOption={true}
          />
          <div className="-mb-2 -ml-1">
            <RangeSelector
              setRangeFocus={() => null}
              workingDates={selectedDates}
              setDateRange={setDateRange}
              selectedDates={selectedDates}
              label={"From and To Date"}
              minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
              maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
            />
          </div>
        </div>
      </div>
      <AbsenteesDetails
        leaveTransactionData={filterLeaveRequests?.absentees?.dataCollection}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalItems={filterLeaveRequests?.absentees?.totalNumberOfItems}
        loading={loading}
      />
    </div>
  );
};

export default Absentees;
