type checkboxProps = {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  onClick?: () => void;
};

const CustomeCheckbox: React.FC<checkboxProps> = ({
  isChecked,
  isIndeterminate,
  onClick = () => {},
}) => {
  return (
    <span
      className={`w-[14px] h-[14px] inline-block bg-white text-white text-center text-[14px] relative border-[0.5px] border-gray-400 rounded-[2px] leading-[14px] ml-[5px] after:content-[''] ${
        isChecked ? "is-checked" : ""
      } ${isChecked ? isIndeterminate && "is-indeterminate" : ""}`}
      onClick={onClick}
    />
  );
};

export default CustomeCheckbox;
