import { useQuery } from "@apollo/client";

import Loading from "components/Loader/Loading";
import TabLink from "components/App/TabLink/TabLink";

import { LEAVE_DASHBOARD } from "modules/Leave/services/queries";
import CompOffRequest from "modules/Leave/Pages/Apply/CompOffRequest/CompOffRequest";
import Regular from "modules/Leave/Pages/Apply/Regular/Regular";
import Special from "modules/Leave/Pages/Apply/Special/Special";
import Restricted from "./Restricted/Restricted";

const ApplyRouteControl = () => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const { data: leaveDashboard, loading: leaveDashboardLoader } = useQuery(
    LEAVE_DASHBOARD,
    {
      variables: {
        userId: +localStorage?.id || +sessionId,
      },
    }
  );

  const restrictedHolidayType = leaveDashboard?.leaveDashboard
    ?.filter(
      (leaveDashboard: any) =>
        leaveDashboard?.leaveType?.isRestrictedHolidayLeave
    )
    ?.map(
      (restrictedLeave: {
        remaining: number;
        leaveType: {
          id: number;
          isRestrictedHolidayLeave: boolean;
          name: string;
        };
        year: number;
      }) => {
        return {
          remaining: restrictedLeave?.remaining,
          leaveType: {
            id: restrictedLeave?.leaveType?.id,
            isRestrictedLeave:
              restrictedLeave?.leaveType?.isRestrictedHolidayLeave,
            name: restrictedLeave?.leaveType?.name,
          },
          year: restrictedLeave?.year,
        };
      }
    );

  const specialLeaveType =
    !leaveDashboardLoader &&
    leaveDashboard &&
    leaveDashboard?.leaveDashboard?.filter(
      (leaveDashboard: any) => leaveDashboard?.leaveType?.isSpecialLeave
    )[0]?.leaveType;

  const regularLeaveType =
    !leaveDashboardLoader && leaveDashboard && leaveDashboard?.leaveDashboard;

  const tabLinks = [
    {
      name: "Regular",
      link: "/leaves/apply/regular",
      allowedResourceIds: ["ApplyLeave", "ReadApplyLeave"],
    },
    {
      name: "Special",
      link: "/leaves/apply/special",
      allowedResourceIds: ["ApplyLeave", "ReadApplyLeave"],
    },
    {
      name: "Comp Off Request",
      link: "/leaves/apply/comp-off",
      allowedResourceIds: ["ApplyLeave", "ReadApplyLeave"],
    },
    {
      name: "Restricted Holiday",
      link: "/leaves/apply/restricted",
      allowedResourceIds: ["ApplyLeave", "ReadApplyLeave"],
    },
  ];

  const routes = [
    {
      path: "regular",
      element: (
        <Regular
          leaveDashboard={regularLeaveType}
          leaveDashboardLoader={leaveDashboardLoader}
        />
      ),
    },
    {
      path: "special",
      element: (
        <Special
          leaveType={specialLeaveType}
          leaveDashboardLoader={leaveDashboardLoader}
        />
      ),
    },
    {
      path: "comp-off",
      element: <CompOffRequest />,
    },
    {
      path: "restricted",
      element: (
        <Restricted
          leaveType={restrictedHolidayType}
          leaveDashboardLoader={leaveDashboardLoader}
        />
      ),
    },
  ];

  return leaveDashboardLoader ? (
    <Loading className="min-h-[calc(100vh-22px)]" />
  ) : (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[800px] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default ApplyRouteControl;
