import { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  getAllowedAccessUpdated,
  toastNotify,
  setBackNavigationURL,
  setTitle,
} from "global/helpers/Cache";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import { PROJECT_SETTINGS_COLUMNS } from "modules/Project/services/queries";
import { DELETE_GLOBAL_COLUMN } from "modules/Project/services/mutations";
import { IProjectColumn } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import EditSettingsColumns from "modules/Project/Pages/SettingsColumns/EditSettingsColumns";

const tableCommonHeadings = [
  { name: "Name", APIName: "name" },
  { name: "Description", APIName: "description" },
  { name: "Colour" },
];

const SettingsColumns = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccessUpdated);
  const allowedResources = allowedResourcesList || {};

  const canCreateOrUpdate = allowedResources["SaveColumn"];
  const canDelete = allowedResources["DeleteColumn"];
  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [deleteProjectColumn, { loading: deleteProjectColumnLoading }] =
    useMutation(DELETE_GLOBAL_COLUMN);

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    setTitle([
      { name: "Project", link: "/projects/projects/my-projects" },
      {
        name: "Column",
        link: "/projects/settings/columns",
      },
    ]);
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const [
    fetchColumns,
    { data: getProjectColumns, loading, refetch: refetchProjectColumns },
  ] = useLazyQuery(PROJECT_SETTINGS_COLUMNS);

  useEffect(() => {
    fetchColumns({
      variables: {
        search: search || undefined,
        orderBy: sortOrder
          ? {
              field: sortOrder?.field,
              orderType: sortOrder?.orderType,
            }
          : undefined,
      },
    });
  }, [fetchColumns, search, sortOrder]);

  const projectColumnsList = getProjectColumns?.columns?.dataCollection || [];

  const totalCount = projectColumnsList?.length || 0;

  const noOfItems = getProjectColumns && projectColumnsList?.length;

  const [editProjectColumn, setEditProjectColumn] =
    useState<IProjectColumn | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectColumn?.id) {
      deleteProjectColumn({
        variables: {
          id: +editProjectColumn?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectColumns();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectColumn?.name
                  ? `Project column ${editProjectColumn?.name}`
                  : "Project column"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (projectColumn: IProjectColumn) => {
    if (canCreateOrUpdate) {
      setEditProjectColumn(projectColumn);
      setShowModal(true);
    }
  };

  const onDelete = (projectColumn: IProjectColumn) => {
    setEditProjectColumn(projectColumn);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectColumn(null);
  };

  const getDataModal = (projectColumn: IProjectColumn): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: projectColumn?.name,
        },
      ],
    },
    {
      label: "Description",
      value: [
        {
          content: projectColumn?.description,
        },
      ],
    },
    {
      label: "Colour",
      value: [
        {
          content: projectColumn?.colour,
        },
      ],
    },
  ];

  const getTableContent = (projectColumn: IProjectColumn): ITableContent[] => [
    {
      value: projectColumn?.name,
    },
    {
      value: projectColumn?.description,
    },
    {
      value: projectColumn?.colour,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(projectColumn);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };

  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />

        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreateOrUpdate && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectColumnsList?.map((projectColumn: IProjectColumn) => {
                    return (
                      <DataCard
                        key={projectColumn?.id}
                        dataModal={getDataModal(projectColumn)}
                        classForULlist={`${
                          canCreateOrUpdate
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                        cardClick={() => {
                          canCreateOrUpdate && onEdit(projectColumn);
                        }}
                        classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                        otherOption={
                          (canDelete && (
                            <div className="flex justify-center">
                              <ToolTip
                                render={() => "Delete"}
                                arrow
                                className={
                                  "text-center bg-[#616161] text-white"
                                }
                              >
                                <BiTrash
                                  className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(projectColumn);
                                  }}
                                />
                              </ToolTip>
                            </div>
                          )) ||
                          undefined
                        }
                      />
                    );
                  })}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  isSortOrderNeeded
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                >
                  {projectColumnsList?.map((projectLabel: IProjectColumn) => {
                    return (
                      <TableContent
                        key={projectLabel?.id}
                        tableRef={tableRef}
                        classForTableRow={`${
                          canCreateOrUpdate
                            ? "cursor-pointer hover:bg-white-smoke"
                            : "cursor-default hover:bg-white-smoke"
                        }`}
                        tableRowClick={() => {
                          canCreateOrUpdate && onEdit(projectLabel);
                        }}
                        tableContent={getTableContent(projectLabel)}
                      />
                    );
                  })}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditSettingsColumns
          refetchProjectColumns={refetchProjectColumns}
          editProjectColumn={editProjectColumn}
          setShowModal={setShowModal}
          loading={loading}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Column"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectColumnLoading}
        />
      )}
    </div>
  );
};

export default SettingsColumns;
