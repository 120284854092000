import useAllowedResources from "global/hooks/useAllowedResources";

import WorkExperience from "modules/Employee/Pages/Profile/Work/WorkExperience/WorkExperience";
import WorkInformation from "modules/Employee/Pages/Profile/Work/WorkInformation/WorkInformation";

const Work = () => {
  const allowedResources = useAllowedResources();

  return (
    <div className="md:grid md:grid-cols-2 gap-x-5">
      {allowedResources?.includes("EmployeeWorkInfo") && <WorkInformation />}

      {allowedResources?.includes("EmployeeWorkExperienceInfo") && (
        <WorkExperience />
      )}
    </div>
  );
};

export default Work;
