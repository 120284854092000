import { useEffect, useMemo, useState } from "react";
import { HiArrowNarrowLeft, HiLink } from "react-icons/hi";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Loading from "components/Loader/Loading";
import Button from "components/forms/Button/Button";
import Tooltip from "components/UI/ToolTip";

import { findAllowedACLIdsUpdated } from "global/helpers/FindACLIds";
import {
	getAllowedAccess,
	setTitle,
	userContextData,
} from "global/helpers/Cache";

import Comments from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/Comments";
import WorkLog from "modules/Project/Pages/Issues/IssueLogs/Activity/WorkLog/WorkLogs";
import {
	ISSUE_LOG_PROJECT_TASK,
	PROJECT_ACL,
	PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES,
} from "modules/Project/services/queries";
import Histories from "modules/Project/Pages/Issues/IssueLogs/Activity/History/Histories";
import Title from "modules/Project/Pages/Issues/IssueLogs/ProjectTaskForms/Title";
import RunningLog from "modules/Project/Pages/Issues/IssueLogs/ProjectTaskForms/RunningLog";
import TaskDescription from "modules/Project/Pages/Issues/IssueLogs/ProjectTaskForms/TaskDescription";
import AddTaskFileAttachment from "modules/Project/Pages/Issues/IssueLogs/ProjectTaskForms/AddTaskFileAttachment";
import TaskDetails from "modules/Project/Pages/Issues/IssueLogs/ProjectTaskForms/TaskDetails";
import FileAttachments from "modules/Project/Pages/Issues/IssueLogs/FileAttachments/FileAttachments";
import {
	IProjectMembersList,
	IProjectWorkLog,
} from "modules/Project/types/project";
import { checkHasAnyAccess, readTasksACL } from "modules/Project/helper";

const IssueLogs = () => {
	const userDataList: any = useReactiveVar(userContextData);
	const userId = userDataList?.user?.id;
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);
	const canReadUserEmployeeDetails = allowedResources?.includes(
		"ReadEmployeeWorkInformation",
	);

	const [fetchTask, { data: task, loading }] = useLazyQuery(
		ISSUE_LOG_PROJECT_TASK,
	);

	const [
		loadProjectRoleItemQuery,
		{ data: projectRoleItemQuery, loading: ACLLoading },
	] = useLazyQuery(PROJECT_ACL, {
		fetchPolicy: "no-cache",
	});

	const [fetchProjectMembers, { data: getprojectMembers }] = useLazyQuery(
		PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES,
	);

	const allowedResourcesForProject =
		findAllowedACLIdsUpdated(
			projectRoleItemQuery?.projectAclTree?.resourceIds?.resourceId || [],
		) || {};

	const canSaveProjectAttachments =
		allowedResourcesForProject?.AddProjectTaskAttachment;

	const canReadProjectWorkLog =
		allowedResourcesForProject?.ReadProjectWorkLog ||
		allowedResourcesForProject?.ReadAllProjectWorkLog;

	const canSaveProjectWorkLog = allowedResourcesForProject?.SaveProjectWorkLog;

	const hasAnyTaskDetailReadAccess = checkHasAnyAccess(
		readTasksACL,
		allowedResourcesForProject,
	);

	const hasAllWorkLogReadAccess =
		allowedResourcesForProject?.ReadAllProjectWorkLog;

	const { id, issueId, groupId } = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const projectType = pathname?.split("/")[3];

	const project = task?.projects?.dataCollection || [];
	const projectTask =
		project?.length &&
		project[0]?.tasks?.dataCollection?.length &&
		project[0]?.tasks?.dataCollection[0];

	const assigneeUser = projectTask?.projectTaskApprovedHours?.filter(
		(task: { assigneeUser: { user: { id: number } } }) =>
			task?.assigneeUser?.user?.id === userId,
	);

	const taskActivityArray = canReadProjectWorkLog
		? ["Comments", "Work log", "History"]
		: ["Comments", "History"];

	const allocatedApprovedHours =
		assigneeUser?.length > 0 ? assigneeUser[0]?.allocatedApprovedHours : 0;

	useEffect(() => {
		if (projectTask?.project?.name) {
			setTitle(
				groupId
					? [
							{
								name: "Project",
								link: "/projects/groups",
							},
							{ name: "Groups", link: "/projects/groups" },
							{
								name: `${projectTask?.project?.name}`,
								link: `/projects/${groupId}/${projectType}/edit/${id}/general`,
							},
							{
								name: "Issues",
								link: `projects/${groupId}/${projectType}/edit/${id}/issues`,
							},
							{
								name: `${projectTask?.title}`,
								link: `projects/${groupId}/${projectType}/edit/${id}/issue-details/${issueId}`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: `${projectTask?.project?.name}`,
								link: `/projects/projects/${projectType}/edit/${id}/general`,
							},
							{
								name: "Issues",
								link: `projects/projects/${projectType}/edit/${id}/issues`,
							},
							{
								name: `${projectTask?.title}`,
								link: `projects/projects/${projectType}/edit/${id}/issue-details/${issueId}`,
							},
					  ],
			);
		}
	}, [
		projectTask?.project?.name,
		projectType,
		id,
		projectTask?.title,
		issueId,
		groupId,
	]);

	const [activity, setActivity] = useState<string>("Comments");
	const [copyURL, setCopyURL] = useState(false);

	const [refetchFileAttachments, setRefetchFileAttachments] = useState(false);
	const [refetchWorkLog, setRefetchWorkLog] = useState(false);
	const [runningTimerWorkLog, setTimerRunningWorkLog] =
		useState<IProjectWorkLog | null>(null);

	const projectMembersList: IProjectMembersList[] = useMemo(
		() => getprojectMembers?.projectMembers?.dataCollection || [],
		[getprojectMembers?.projectMembers?.dataCollection],
	);

	useEffect(() => {
		if (id && issueId && !Number.isNaN(+issueId) && !Number.isNaN(+id)) {
			fetchTask({
				variables: {
					filters: {
						id: +id,
						isMyProject: projectType === "my-projects" ? true : undefined,
					},
					projectTaskFilter: {
						id: +issueId,
					},
				},
			});
		}
	}, [fetchTask, issueId, id, projectType]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			loadProjectRoleItemQuery({
				variables: {
					projectId: id ? +id : 0,
				},
			});
		}
	}, [id, loadProjectRoleItemQuery]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchProjectMembers({
				variables: {
					filters: {
						projectId: +id,
					},
					type: canReadUserProfileImage ? "Profile" : undefined,
					isProfileImageNeeded: canReadUserProfileImage,
					isEmployeeDetailsNeeded: canReadUserEmployeeDetails,
				},
			}).catch((error) => {
				if (error.name === "AbortError") return;
			});
		}
	}, [
		id,
		fetchProjectMembers,
		canReadUserProfileImage,
		canReadUserEmployeeDetails,
	]);

	return loading && ACLLoading ? (
		<Loading className="min-h-[800px]" />
	) : (
		<>
			<div className="mb-4 p-8 min-h-[900px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] py-5 bg-white">
				<div className="flex justify-between items-center mb-3">
					<div className="flex flex-col sm:items-center sm:flex-row gap-3">
						<div className="flex sm:justify-center sm:items-center pr-1">
							<Tooltip render="Back" arrow>
								<HiArrowNarrowLeft
									className="w-6 h-6 rounded-full  text-cornflower-blue cursor-pointer"
									onClick={() => {
										navigate(
											`/projects/${
												groupId ? `${groupId}` : "projects"
											}/${projectType}/edit/${id}/issues`,
										);
									}}
								/>
							</Tooltip>
						</div>
						<Tooltip
							render={`${projectTask?.project?.projectKey}${
								projectTask?.keyId ? ` -${projectTask?.keyId}` : ""
							}`}
							arrow
							trigger={["hover"]}
						>
							<span className="text-[14px] leading-[17px] text-[#A4A4A5] bg-[#ecf1fe] px-1 py-[3px]">
								{`${projectTask?.project?.projectKey}${
									projectTask?.keyId ? ` -${projectTask?.keyId}` : ""
								}`}
							</span>
						</Tooltip>
						<Tooltip
							render={`${copyURL ? "Copied" : "Copy Link"}`}
							arrow
							trigger={["hover"]}
						>
							<HiLink
								onClick={() => {
									setCopyURL(true);
									navigator.clipboard.writeText(window.location.href);
								}}
								onMouseEnter={() => {
									setCopyURL(false);
								}}
								className="cursor-pointer"
							/>
						</Tooltip>
						{projectTask?.createdAt && (
							<span className="text-[12px] leading-[14px]  text-[#A4A4A5]">
								Created on {projectTask?.createdAt}
							</span>
						)}
					</div>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-7 gap-10">
					<div className="lg:col-span-4 flex flex-col">
						<Title
							projectTasktitle={projectTask?.title}
							canSaveTitle={allowedResourcesForProject?.SaveProjectTaskTitle}
						/>
						{canSaveProjectAttachments && (
							<AddTaskFileAttachment
								setRefetchFileAttachments={setRefetchFileAttachments}
							/>
						)}
						{allowedResourcesForProject?.ReadProjectTaskDescription && (
							<TaskDescription
								taskDescription={projectTask?.description}
								canSaveDescription={
									allowedResourcesForProject?.SaveProjectTaskDescription
								}
							/>
						)}
						<FileAttachments
							canDeleteProjectTaskAttachment={
								allowedResourcesForProject?.RemoveProjectTaskAttachment
							}
							refetchFileAttachments={refetchFileAttachments}
							setRefetchFileAttachments={setRefetchFileAttachments}
						/>
						<div className="sm:row-start-3 sm:row-end-4 md:col-span-4 flex-1">
							<p className="text-[14px] leading-[17px] font-bold text-[#626364] mb-2">
								Activity
							</p>
							<div className="flex items-center gap-3 mb-5">
								<span className="text-[14px] hidden sm:block leading-[17px] text-[#626364] mr-[6px]">
									Show:
								</span>
								{taskActivityArray.map(
									(taskActivity: string, index: number) => (
										<Button
											key={index}
											size="slim"
											className={`whitespace-nowrap ${
												activity === taskActivity
													? "bg-[#505F79] text-white"
													: "bg-[#F4F5F7]"
											}`}
											onClick={() => setActivity(taskActivity)}
										>
											{taskActivity}
										</Button>
									),
								)}
							</div>

							{activity === "Comments" && <Comments />}
							{activity === "Work log" && (
								<WorkLog
									allowedResourcesForProject={allowedResourcesForProject}
									runningTimerWorkLog={runningTimerWorkLog}
									isAssignedToTheTask={assigneeUser?.length > 0 ? true : false}
									setRefetchWorkLog={setRefetchWorkLog}
									hasAllWorkLogReadAccess={hasAllWorkLogReadAccess}
								/>
							)}
							{activity === "History" && <Histories />}
						</div>
					</div>
					<div className="lg:col-span-3 space-y-10">
						{canSaveProjectWorkLog && (
							<RunningLog
								startDate={projectTask?.startDate}
								allocatedApprovedHours={allocatedApprovedHours}
								setTimerRunningWorkLog={setTimerRunningWorkLog}
								isAssignedToTheTask={assigneeUser?.length > 0 ? true : false}
								refetchWorkLog={refetchWorkLog}
								projectTaskApprovedHours={projectTask?.projectTaskApprovedHours}
								projectMembersList={projectMembersList}
								hasAllWorkLogReadAccess={hasAllWorkLogReadAccess}
								taskLoading={loading}
							/>
						)}
						{hasAnyTaskDetailReadAccess && (
							<TaskDetails
								projectTask={projectTask}
								allowedResourcesForProject={allowedResourcesForProject}
								projectMembersList={projectMembersList}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default IssueLogs;
