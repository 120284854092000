import { FC } from "react";

interface IProps {
  buttonName: string;
  onClick?: () => void;
}

const Outlined: FC<IProps> = ({ buttonName, onClick }) => {
  return (
    <button
      type="button"
      className="w-[110px] lg:w-[120px] h-[42px] bg-white text-sm text-ironside-gray rounded border hover:border-cornflower-blue hover:text-cornflower-blue"
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default Outlined;
