import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { useDateSegment } from "react-aria";
import { DateFieldState, DateSegment as DateSegmentProps } from "react-stately";

import { months } from "components/forms/UpdatedFormAgry/Date/Date/helper";

interface Props {
  segment: DateSegmentProps;
  state: DateFieldState;
  yearRef: MutableRefObject<HTMLDivElement | null>;
}

const DateSegment: FC<Props> = ({ segment, state, yearRef }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  const [monthTypingValue, setMonthTypingValue] = useState("");

  useEffect(() => {
    if (monthTypingValue === "mar") {
      yearRef.current?.focus();
      setMonthTypingValue("");
    } else if (monthTypingValue === "may") {
      yearRef.current?.focus();
      setMonthTypingValue("");
    }
  }, [monthTypingValue, yearRef]);

  const { onKeyDown, ...restSegmentProps } = segmentProps;

  return (
    <div
      {...restSegmentProps}
      ref={(htmlDivElement) => {
        ref.current = htmlDivElement;
        yearRef.current = htmlDivElement;
      }}
      style={{
        ...segmentProps.style,
        minWidth: segment.maxValue
          ? segment.maxValue != null && segment.maxValue !== undefined
            ? `${segment?.maxValue?.toString()?.length}ch`
            : undefined
          : undefined,
      }}
      onKeyDown={(e) => {
        if (e.key === "Backspace") {
          if (
            ((segment.type === "hour" ||
              segment.type === "minute" ||
              segment.type === "second") &&
              segment.text &&
              +segment.text < 10) ||
            segment.type === "dayPeriod"
          ) {
            e.preventDefault();
            return;
          }
        }

        onKeyDown && onKeyDown(e);
        if (segment?.type === "month") {
          if (e.key?.toLowerCase() === "a" && monthTypingValue === "") {
            setMonthTypingValue("a");
            state.setSegment("month", 4);
          } else if (e.key.toLowerCase() === "p" && monthTypingValue === "a") {
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key.toLowerCase() === "u" && monthTypingValue === "a") {
            state.setSegment("month", 8);
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key?.toLowerCase() === "m" && monthTypingValue === "") {
            setMonthTypingValue("m");
            state.setSegment("month", 3);
          } else if (e.key?.toLowerCase() === "a" && monthTypingValue === "m") {
            setMonthTypingValue("ma");
          } else if (
            e.key?.toLowerCase() === "r" &&
            monthTypingValue === "ma"
          ) {
            state.setSegment("month", 3);
            setMonthTypingValue("mar");
          } else if (
            e.key?.toLowerCase() === "y" &&
            monthTypingValue === "ma"
          ) {
            state.setSegment("month", 5);
            setMonthTypingValue("may");
          } else if (e.key?.toLowerCase() === "a" && monthTypingValue === "j") {
            setMonthTypingValue("");
            state.setSegment("month", 1);
            yearRef.current?.focus();
          } else if (e.key?.toLowerCase() === "u" && monthTypingValue === "j") {
            setMonthTypingValue("ju");
            state.setSegment("month", 6);
          } else if (
            e.key?.toLowerCase() === "n" &&
            monthTypingValue === "ju"
          ) {
            setMonthTypingValue("");
            state.setSegment("month", 6);
            yearRef.current?.focus();
          } else if (
            e.key?.toLowerCase() === "l" &&
            monthTypingValue === "ju"
          ) {
            setMonthTypingValue("");
            state.setSegment("month", 7);
            yearRef.current?.focus();
          } else if (e.key?.toLowerCase() === "j") {
            setMonthTypingValue("j");
            state.setSegment("month", 1);
          } else if (e.key.toLowerCase() === "d") {
            state.setSegment("month", 12);
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key.toLowerCase() === "n") {
            state.setSegment("month", 11);
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key?.toLowerCase() === "f") {
            state.setSegment("month", 2);
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key.toLowerCase() === "o") {
            state.setSegment("month", 10);
            yearRef.current?.focus();
            setMonthTypingValue("");
          } else if (e.key.toLowerCase() === "s") {
            state.setSegment("month", 9);
            yearRef.current?.focus();
            setMonthTypingValue("");
          }
        }
      }}
      className={`${
        segment.text === ", "
          ? "pr-1"
          : segment.text === "AM" || segment.text === "PM"
          ? "px-0.5"
          : segment.type === "hour" ||
            segment.type === "minute" ||
            segment.type === "second"
          ? "px-px"
          : ""
      } tabular-nums font-sans text-right outline-none rounded-sm focus:bg-primary focus:text-white group text-base select-none selection:bg-primary ${
        state?.isDisabled
          ? "cursor-not-allowed text-outline text-opacity-[0.38]"
          : segment.type !== "literal"
          ? "cursor-pointer"
          : ""
      }`}
    >
      {segment?.isPlaceholder ? (
        <span
          aria-hidden="true"
          className="block w-full text-center text-gray-500 group-focus:text-white select-none"
          style={{
            visibility: segment?.isPlaceholder ? undefined : "hidden",
            height: segment.isPlaceholder ? "" : 0,
            pointerEvents: "none",
          }}
        >
          {segment?.placeholder
            ? segment?.type === "month"
              ? "mmm"
              : segment?.placeholder
            : segment?.placeholder}
        </span>
      ) : null}
      {segment.isPlaceholder
        ? " "
        : segment.text === "/"
        ? "-"
        : segment?.type === "month"
        ? months[+segment?.text - 1]
        : !isNaN(+segment?.text)
        ? +segment?.text < 10 && +segment?.text !== 0
          ? `0${segment?.text}`?.toString()?.length === 4
            ? segment?.text
            : `0${segment?.text}`?.toString()?.length === 3
            ? segment?.text
            : `0${segment?.text}`
          : segment?.text
        : segment?.text}
    </div>
  );
};

export default DateSegment;
