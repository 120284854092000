import { FC } from "react";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { RxAvatar } from "react-icons/rx";

import { IProjectIssueForm } from "modules/Project/types/project";
import AssigneeForm from "modules/Project/Pages/EditIssues/AssigneeForm";

interface IProps {
  register: UseFormRegister<IProjectIssueForm>;
  fields: FieldArrayWithId<
    IProjectIssueForm,
    "projectTaskApprovedHours",
    "id"
  >[];
  append: UseFieldArrayAppend<IProjectIssueForm, "projectTaskApprovedHours">;
  errors: FieldErrors<IProjectIssueForm>;
  setValue: UseFormSetValue<IProjectIssueForm>;
  control: Control<IProjectIssueForm, any>;
  remove: UseFieldArrayRemove;
}

const AddAssignees: FC<IProps> = ({
  control,
  register,
  errors,
  setValue,
  append,
  fields,
  remove,
}) => {
  const projectTaskApprovedHours = useWatch({
    control,
    name: "projectTaskApprovedHours",
  });

  return (
    <>
      {fields?.length === 0 && (
        <div
          className="cursor-pointer h-min mb-8 flex w-[160px] items-center gap-1 px-2 py-2 bg-[#f6f6f6]"
          onClick={() => {
            append({
              assigneeUserId: null,
              actualApprovedHours: null,
              allocatedApprovedHours: null,
              projectRoleId: null,
            });
          }}
        >
          <RxAvatar className="w-[40px] h-[40px] bg-gray-400 text-white rounded-[50%] object-fit" />
          <span className="text-cornflower-blue">Add assignees</span>
        </div>
      )}
      <div className="col-span-2 grid gap-5">
        {fields?.map((item, index) => {
          return (
            <AssigneeForm
              key={item?.id}
              control={control}
              register={register}
              setValue={setValue}
              append={append}
              fields={fields}
              errors={errors}
              remove={remove}
              item={item}
              index={index}
              projectTaskApprovedHour={projectTaskApprovedHours[index]}
            />
          );
        })}
        {fields?.length > 0 && (
          <div
            className="cursor-pointer h-min mb-8 flex w-[160px] items-center gap-1 px-2 py-2 bg-[#f6f6f6]"
            onClick={() => {
              append({
                assigneeUserId: null,
                actualApprovedHours: null,
                allocatedApprovedHours: null,
                projectRoleId: null,
              });
            }}
          >
            <RxAvatar className="w-[40px] h-[40px] bg-gray-400 text-white rounded-[50%] object-fit" />
            <span className="text-cornflower-blue">Add assignee</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddAssignees;
