import { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Input from "components/forms/UpdatedForm/Input";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import Select from "components/forms/UpdatedForm/Select";
import { Filled, Outlined } from "components/App/Buttons";
import Loading from "components/Loader/Loading";
import TextArea from "components/forms/UpdatedForm/TextArea";
import { CalendarField, FileDragAndDrop } from "components/forms";
import { ToggleSwitch } from "components/forms/UpdatedFormAgry";

import { IIdAndName } from "global/types/type";
import { getAllowedAccess, setTitle, toastNotify } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import {
	fileToBase64,
	getFileExtensionUsingName,
} from "global/helpers/FileConverter";
import { findAllowedACLIdsUpdated } from "global/helpers/FindACLIds";

import {
	IProjectIssueForm,
	IProjectMembersList,
	IProjectTask,
} from "modules/Project/types/project";
import {
	COLUMNS_FOR_DROP_DOWN,
	GET_PROJECT_MANAGER,
	PROJECT_ACL,
	PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES,
	SPRINTS_FOR_DROP_DOWN,
	TASK,
	TASK_LABEL_FOR_DROP_DOWN,
} from "modules/Project/services/queries";
import { SAVE_PROJECT_TASK } from "modules/Project/services/mutations";
import AddAssignees from "modules/Project/Pages/EditIssues/AddAssignees";

const EditIssues = () => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);
	const canReadUserEmployeeDetails = allowedResources?.includes(
		"ReadEmployeeWorkInformation",
	);
	const { id, issueId, groupId, backlogId } = useParams();
	const { pathname } = useLocation();

	const [
		loadProjectRoleItemQuery,
		{ data: projectRoleItemQuery, loading: ACLLoading },
	] = useLazyQuery(PROJECT_ACL, {
		fetchPolicy: "no-cache",
	});

	const [saveProjectTask, { loading: saveProjectTaskLoading }] =
		useMutation(SAVE_PROJECT_TASK);

	const [fetchTask, { data: task, loading }] = useLazyQuery(TASK, {
		fetchPolicy: "network-only",
	});

	const [fetchProjectMembers, { data: getProjectMembers }] = useLazyQuery(
		PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES,
	);

	const [fetchSprints, { data: allSprints, loading: sprintsLoading }] =
		useLazyQuery(SPRINTS_FOR_DROP_DOWN, {
			fetchPolicy: "cache-and-network",
		});

	const [fetchTaskLabel, { data: allTaskLabel, loading: taskLabelLoading }] =
		useLazyQuery(TASK_LABEL_FOR_DROP_DOWN, {
			fetchPolicy: "cache-and-network",
		});

	const [fetchColumns, { data: allColumns, loading: columnsLoading }] =
		useLazyQuery(COLUMNS_FOR_DROP_DOWN, {
			fetchPolicy: "cache-and-network",
		});

	const [fetchManger, { data: getProjectManager, loading: managerLoading }] =
		useLazyQuery(GET_PROJECT_MANAGER);

	const projectType = pathname?.split("/")[3];

	const allowedResourcesForProject =
		findAllowedACLIdsUpdated(
			projectRoleItemQuery?.projectAclTree?.resourceIds?.resourceId || [],
		) || {};

	const hasFileDeleteAccess =
		allowedResourcesForProject?.RemoveProjectTaskAttachment;

	const {
		register,
		handleSubmit,
		setValue,
		control,
		clearErrors,
		watch,
		formState: { errors },
	} = useForm<IProjectIssueForm>({});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "projectTaskApprovedHours",
	});

	const navigate = useNavigate();

	const createOrUpdateAccess = allowedResourcesForProject?.SaveProjectTask;
	const canSaveTitle = allowedResourcesForProject?.SaveProjectTaskTitle;
	const canSaveSprint = allowedResourcesForProject?.SaveProjectTaskSprint;
	const canSaveInCharge = allowedResourcesForProject?.SaveProjectTaskInCharge;
	const canSavePriority = allowedResourcesForProject?.SaveProjectTaskPriority;

	const canSaveStartDate = allowedResourcesForProject?.SaveProjectTaskStartDate;

	const canSaveDueDate = allowedResourcesForProject?.SaveProjectTaskDueDate;
	const canSaveTaskLabel = allowedResourcesForProject?.SaveProjectTaskLabel;
	const canSaveColumn = allowedResourcesForProject?.SaveProjectTaskColumn;

	const canSaveAttachments =
		allowedResourcesForProject?.SaveProjectTaskAttachments;

	const canSaveDescription =
		allowedResourcesForProject?.SaveProjectTaskDescription;

	const canSaveApprovedHours =
		allowedResourcesForProject?.SaveProjectTaskTotalApprovedHours;

	const canSaveIsBillable =
		allowedResourcesForProject?.SaveProjectTaskIsBillable;

	const canSaveBillingHours =
		allowedResourcesForProject?.SaveProjectTaskBillingHours;

	const canSaveBillingStatus =
		allowedResourcesForProject?.SaveProjectTaskBillingStatus;

	const watchIsBillable = watch("isBillable");

	useEffect(() => {
		const projectTaskApprovedHours =
			task?.projectTasks?.dataCollection?.length > 0 &&
			task?.projectTasks?.dataCollection[0]?.projectTaskApprovedHours?.map(
				(approvedHour: {
					id: number;
					actualApprovedHours: number;
					allocatedApprovedHours: number;
					assigneeUser: {
						projectRole: { id: number };
						user: { personalInformation: { id: number } };
					};
				}) => {
					return {
						id: approvedHour?.id,
						actualApprovedHours: approvedHour?.actualApprovedHours,
						allocatedApprovedHours: approvedHour?.allocatedApprovedHours,
						projectRoleId: approvedHour?.assigneeUser?.projectRole?.id,
						assigneeUserId:
							approvedHour?.assigneeUser?.user?.personalInformation?.id,
					};
				},
			);
		setValue("projectTaskApprovedHours", projectTaskApprovedHours);
	}, [task?.projectTasks?.dataCollection, setValue]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			loadProjectRoleItemQuery({
				variables: {
					projectId: id ? +id : 0,
				},
			});
		}
	}, [id, loadProjectRoleItemQuery]);

	useEffect(() => {
		if (
			(issueId && Number.isFinite(+issueId)) ||
			(backlogId && Number.isFinite(+backlogId))
		) {
			fetchTask({
				variables: {
					filters: {
						id: issueId ? +issueId : backlogId ? +backlogId : undefined,
					},
				},
			});
		}
	}, [fetchTask, issueId, backlogId]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchSprints({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchSprints, id]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchTaskLabel({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchTaskLabel, id]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchColumns({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchColumns, id]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchManger({
				variables: {
					filters: {
						id: +id,
					},
				},
			});
		}
	}, [fetchManger, id]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchProjectMembers({
				variables: {
					filters: {
						projectId: +id,
					},
					type: canReadUserProfileImage ? "Profile" : undefined,
					isProfileImageNeeded: canReadUserProfileImage,
					isEmployeeDetailsNeeded: canReadUserEmployeeDetails,
				},
			}).catch((error) => {
				if (error.name === "AbortError") return;
			});
		}
	}, [
		id,
		fetchProjectMembers,
		canReadUserProfileImage,
		canReadUserEmployeeDetails,
	]);

	const editTask: IProjectTask =
		task?.projectTasks?.dataCollection?.length > 0
			? task?.projectTasks?.dataCollection[0]
			: null;

	useEffect(() => {
		if (editTask?.project?.name && editTask?.title) {
			setTitle(
				groupId
					? [
							{ name: "Project", link: "/projects/groups" },
							{ name: "Groups", link: "/projects/groups" },
							{
								name: `${editTask?.project?.name}`,
								link: `/projects/${groupId}/${projectType}/edit/${id}/general`,
							},
							{
								name: "Issues",
								link: `projects/${groupId}/${projectType}/edit/${id}/issues`,
							},
							{
								name: `${editTask?.title}`,
								link: `projects/${groupId}/${projectType}/edit/${id}/issues/${issueId}`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: `${editTask?.project?.name}`,
								link: `/projects/projects/${projectType}/edit/${id}/general`,
							},
							{
								name: "Issues",
								link: `projects/projects/${projectType}/edit/${id}/issues`,
							},
							{
								name: `${editTask?.title}`,
								link: `projects/projects/${projectType}/edit/${id}/issues/${issueId}`,
							},
					  ],
			);
		}
	}, [
		editTask?.project?.name,
		editTask?.title,
		projectType,
		id,
		issueId,
		groupId,
	]);

	const projectMembersList =
		getProjectMembers?.projectMembers?.dataCollection?.map(
			(member: IProjectMembersList) => ({
				id: member?.user?.personalInformation?.id,
				name: member?.user?.personalInformation?.name,
			}),
		) || [];

	const sprintsList: { id: number; name: string }[] = useMemo(
		() =>
			allSprints?.projectSprints?.dataCollection?.map(
				(projectSprint: IIdAndName) => {
					return {
						id: projectSprint?.id,
						name: projectSprint?.name,
					};
				},
			) || [],
		[allSprints?.projectSprints?.dataCollection],
	);

	const columnNamesLowerCased: { id: number; name: string }[] = [];

	const columnList: IIdAndName[] =
		allColumns?.projectColumns?.dataCollection?.map(
			(projectSprint: IIdAndName) => {
				if (projectSprint?.name?.toLowerCase() === "to do") {
					columnNamesLowerCased?.push({
						id: projectSprint?.id,
						name: projectSprint?.name,
					});
				}

				return {
					id: projectSprint?.id,
					name: projectSprint?.name,
				};
			},
		);

	const taskStatusList: IIdAndName[] =
		allTaskLabel?.projectTaskLabel?.dataCollection?.map(
			(projectSprint: IIdAndName) => {
				return {
					id: projectSprint?.id,
					name: projectSprint?.name,
				};
			},
		);

	const [dueDate, setDueDate] = useState<Date | null | undefined>();
	const [startDate, setStartDate] = useState<Date | null | undefined>();

	useEffect(() => {
		if (editTask?.dueDate) {
			setDueDate(new Date(editTask?.dueDate?.replaceAll("-", " ")));
		}
		if (editTask?.startDate) {
			setStartDate(new Date(editTask?.startDate?.replaceAll("-", " ")));
		}
	}, [editTask?.dueDate, editTask?.startDate]);

	useEffect(() => {
		if (dueDate) {
			setValue("dueDate", dueDate?.toString());
			clearErrors("dueDate");
		}

		if (startDate) {
			setValue("startDate", startDate?.toString());
			clearErrors("startDate");
		}
	}, [dueDate, setValue, clearErrors, startDate]);

	const [fileList, setFileList] = useState<any>([]);
	const [updatedFiles, setUpdatedFiles] = useState<any>([]);

	const editFiles = useMemo(
		() =>
			task?.projectTasks?.dataCollection?.length > 0 &&
			task?.projectTasks?.dataCollection[0]?.projectTaskAttachments?.map(
				(file: {
					id: number;
					filePath: string;
					mimeType: string;
					name: string;
				}) => {
					return {
						id: file?.id,
						filePath: file?.filePath,
						mimeType: file?.mimeType,
						name: file?.name,
					};
				},
			),
		[task?.projectTasks?.dataCollection],
	);

	useEffect(() => {
		setUpdatedFiles(editFiles);
	}, [editFiles]);

	const currentFiles: any = [];

	fileList?.map((fileDetails: any, index: number) => {
		if (fileDetails?.size < 2e6)
			fileToBase64(fileDetails, (_err: any, result: any) => {
				if (result) {
					currentFiles[index] = {
						filePath: result?.split(";base64,")[1],
						name: fileDetails?.name,
						extension:
							getFileExtensionUsingName(fileDetails?.name || "")?.length > 0
								? `.${getFileExtensionUsingName(fileDetails?.name || "")}`
								: undefined,
						mimeType: fileDetails?.type
							? fileDetails?.type?.toString()?.length > 0
								? fileDetails?.type
								: "text/plain"
							: "text/plain",
					};
				}
			});
		return <></>;
	});

	useEffect(() => {
		const editTask =
			task?.projectTasks?.dataCollection?.length > 0 &&
			task?.projectTasks?.dataCollection[0];
		setValue(
			"incharge",
			editTask
				? {
						id: editTask?.incharge?.personalInformation?.id,
						name: editTask?.incharge?.personalInformation?.name,
				  }
				: issueId === "new" &&
					  getProjectManager?.projects?.dataCollection?.length > 0
				  ? {
							id: getProjectManager?.projects?.dataCollection[0]?.manager
								?.personalInformation?.id,
							name: getProjectManager?.projects?.dataCollection[0]?.manager
								?.personalInformation?.name,
					  }
				  : null,
		);
	}, [
		task?.projectTasks?.dataCollection,
		setValue,
		getProjectManager?.projects?.dataCollection,
		issueId,
	]);

	const projectManager =
		getProjectManager?.projects?.dataCollection?.length > 0 &&
		getProjectManager?.projects?.dataCollection[0]?.manager?.personalInformation
			?.id &&
		getProjectManager?.projects?.dataCollection[0]?.manager?.personalInformation
			?.name
			? {
					id: getProjectManager?.projects?.dataCollection[0]?.manager
						?.personalInformation?.id,
					name: getProjectManager?.projects?.dataCollection[0]?.manager
						?.personalInformation?.name,
			  }
			: null;

	const createOrUpdateProjectHandler: SubmitHandler<IProjectIssueForm> = ({
		projectColumnId,
		projectSprint,
		assigneeUserId,
		incharge,
		projectTaskLabelId,
		title,
		description,
		priority,
		totalApprovedHours,
		isBillable,
		billableHours,
		billingStatus,
		projectTaskApprovedHours,
	}) => {
		const editFilesIds = !Number.isNaN(Number(issueId))
			? updatedFiles?.map((file: { id: number }) => file?.id)
			: [];

		const removedFiles = !Number.isNaN(Number(issueId))
			? editFiles?.filter(
					(file: { id: number }) => !editFilesIds.includes(file?.id),
			  ) || []
			: [];

		const existingProjectTaskApprovedHoursIDs =
			editTask?.projectTaskApprovedHours?.map(
				(taskApprovedHour: { id: number }) => taskApprovedHour?.id,
			);

		const projectTaskApprovedHoursIDs =
			(projectTaskApprovedHours &&
				projectTaskApprovedHours?.length > 0 &&
				projectTaskApprovedHours?.map(
					(taskApprovedHour: { id?: number }) => taskApprovedHour?.id,
				)) ||
			[];

		const actualApprovedHoursTotal = projectTaskApprovedHours
			? projectTaskApprovedHours
					?.map(
						(taskApprovedHour: { actualApprovedHours?: number | null }) =>
							taskApprovedHour?.actualApprovedHours || 0,
					)
					?.reduce((acc: number, hour: number) => acc + hour, 0)
			: 0;

		const removedTaskApprovedHourIDs =
			existingProjectTaskApprovedHoursIDs?.filter(
				(id: number) => !projectTaskApprovedHoursIDs?.includes(id),
			);

		const currentFilesList =
			currentFiles?.length > 0
				? currentFiles?.map(
						(file: {
							filePath: string;
							name: string;
							extension: string;
							mimeType: string;
						}) => {
							return {
								filePath: file?.filePath,
								name: file?.name,
								extension: file?.extension,
								mimeType: file?.mimeType,
							};
						},
				  ) || []
				: [];

		if (actualApprovedHoursTotal > totalApprovedHours) {
			toastNotify([
				{
					messageType: "error",
					message:
						"Approved hours should not be greater than total approved hours",
				},
			]);

			return;
		}

		if (
			id &&
			!Number.isNaN(+id) &&
			!saveProjectTaskLoading &&
			(issueId || backlogId)
		) {
			saveProjectTask({
				variables: {
					projectTaskInput: {
						id: issueId ? +issueId : backlogId ? +backlogId : undefined,
						projectId: +id,
						projectColumnId,
						projectSprintId: projectSprint?.id,
						assigneeUserId,
						inchargeId: incharge?.id,
						projectTaskLabelId,
						title,
						description,
						priority,
						startDate: startDate && ViewDateFormat(startDate),
						dueDate: dueDate && ViewDateFormat(dueDate),
						totalApprovedHours,
						isBillable,
						billableHours,
						billingStatus,
						projectTaskAttachments:
							currentFilesList?.length > 0 ? currentFilesList : undefined,
						projectTaskApprovedHours: projectTaskApprovedHours || undefined,
						projectTaskApprovedHoursToRemove:
							removedTaskApprovedHourIDs?.length > 0
								? removedTaskApprovedHourIDs
								: undefined,
						projectTaskAttachmentToRemove:
							removedFiles?.length > 0
								? removedFiles?.map((file: { id: number }) => file?.id)
								: undefined,
					},
				},
			})
				.then((response) => {
					const projectTitleName = response?.data?.saveProjectTask?.title;
					navigate(
						`/projects/${
							groupId ? `${groupId}` : "projects"
						}/${projectType}/edit/${id}/backlogs`,
					);
					setFileList([]);
					toastNotify([
						{
							messageType: "success",
							message: `${
								projectTitleName ? `${projectTitleName}` : "Title"
							} has been ${
								issueId === "new" ? "created" : "updated"
							} successfully.`,
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]);
				});
		}
	};

	return loading ||
		ACLLoading ||
		sprintsLoading ||
		taskLabelLoading ||
		managerLoading ||
		columnsLoading ? (
		<Loading className="min-h-[800px]" />
	) : (
		<form
			onSubmit={handleSubmit(createOrUpdateProjectHandler)}
			className="flex justify-center min-h-[800px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] py-5 bg-white"
		>
			<div className="lg:max-w-[970px] grid grid-cols-1 h-min gap-20 mt-3">
				<div
					className={`grid grid-cols-1 ${
						canSaveApprovedHours ? "lg:grid-cols-2 " : ""
					} gap-3 h-min`}
				>
					<div className="grid grid-cols-2 gap-3">
						{canSaveTitle && (
							<Input
								register={register}
								errors={errors}
								label="Title *"
								name="title"
								required
								defaultValue={editTask?.title || ""}
								setValue={setValue}
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{canSaveSprint && (
							<AutoComplete
								control={control}
								label="Sprints"
								name={"projectSprint"}
								options={sprintsList}
								errors={errors}
								setValue={setValue}
								defaultValue={
									editTask && {
										id: editTask?.sprint?.id,
										name: editTask?.sprint?.name,
									}
								}
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{canSaveInCharge && (
							<AutoComplete
								control={control}
								label="Incharge"
								name={"incharge"}
								options={
									projectManager
										? [projectManager, ...projectMembersList]
										: projectMembersList
								}
								errors={errors}
								setValue={setValue}
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{canSavePriority && (
							<Select
								control={control}
								errors={errors}
								label="Priority *"
								name="priority"
								options={["Critical", "High", "Medium", "Low"]}
								defaultValue={editTask?.priority}
								setValue={setValue}
								required
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{canSaveStartDate && (
							<div className="col-span-2 sm:col-span-1">
								<CalendarField
									register={register}
									errors={errors}
									name="startDate"
									date={startDate}
									setDate={setStartDate}
									className="h-[50px]"
									label="Start date"
									maxDate={
										dueDate || new Date(new Date()?.getFullYear() + 1, 11, 31)
									}
									minDate={new Date(new Date()?.getFullYear(), 0, 1)}
								/>
							</div>
						)}
						{canSaveDueDate && (
							<div className="col-span-2 sm:col-span-1">
								<CalendarField
									register={register}
									errors={errors}
									name="dueDate"
									date={dueDate}
									setDate={setDueDate}
									className="h-[50px]"
									label="Due date"
									maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
									minDate={
										startDate || new Date(new Date()?.getFullYear(), 0, 1)
									}
								/>
							</div>
						)}
						{canSaveTaskLabel && (
							<Select
								control={control}
								errors={errors}
								label="Task label"
								name="projectTaskLabelId"
								options={taskStatusList}
								defaultValue={editTask?.projectTaskLabel?.id}
								setValue={setValue}
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{canSaveColumn && (
							<Select
								control={control}
								errors={errors}
								label="Column *"
								name="projectColumnId"
								options={columnList}
								defaultValue={
									editTask
										? editTask?.projectColumn?.id
										: columnNamesLowerCased?.length > 0
										  ? columnNamesLowerCased[0]?.id
										  : ""
								}
								setValue={setValue}
								required
								className="col-span-2 sm:col-span-1"
							/>
						)}
						{(canSaveAttachments || hasFileDeleteAccess) && (
							<div className="col-span-2 mb-5">
								<p className="pb-2">Attachments</p>
								<FileDragAndDrop
									fileList={fileList}
									setFileList={setFileList}
									updatedFiles={updatedFiles}
									setUpdatedFiles={setUpdatedFiles}
									setAlert={toastNotify}
									hasDeleteAcess={hasFileDeleteAccess}
									isFileSupportMessageNeeded={false}
									supportedMimeType=""
									isFileLimitNeed={false}
								/>
							</div>
						)}
						{canSaveDescription && (
							<TextArea
								errors={errors}
								label="Description"
								name="description"
								register={register}
								setValue={setValue}
								className="col-span-2"
								defaultValue={editTask?.description || ""}
							/>
						)}
						{canSaveIsBillable && (
							<ToggleSwitch
								control={control}
								label="Is billable?"
								name="isBillable"
								defaultValue={editTask?.isBillable ? true : false}
								className="pb-5 pl-1 sm:pl-0 flex gap-3 justify-start col-span-2"
							/>
						)}
						{watchIsBillable && (
							<>
								{canSaveBillingHours && (
									<Input
										register={register}
										errors={errors}
										label="Billable Hours"
										name="billableHours"
										required
										defaultValue={editTask?.billableHours || ""}
										setValue={setValue}
										inputType="number"
										fractionalValue
									/>
								)}
								{canSaveBillingStatus && (
									<Select
										control={control}
										errors={errors}
										label="Billing Status"
										name="billingStatus"
										options={[
											"Pending",
											"In Upwork Escrow",
											"Logged in Upwork",
											"Invoiced",
											"Paid",
											"Adjusted",
											"Refunded",
										]}
										defaultValue={editTask?.billingStatus}
										setValue={setValue}
										required
									/>
								)}
							</>
						)}
					</div>
					{canSaveApprovedHours && (
						<div className="lg:ml-8">
							{canSaveApprovedHours && (
								<Input
									register={register}
									errors={errors}
									label="Total approved hours"
									name="totalApprovedHours"
									defaultValue={editTask?.totalApprovedHours || ""}
									setValue={setValue}
									inputType="number"
									fractionalValue
									maxLength={7}
								/>
							)}
							<AddAssignees
								control={control}
								register={register}
								setValue={setValue}
								append={append}
								fields={fields}
								remove={remove}
								errors={errors}
							/>
						</div>
					)}
					<div className="flex justify-between">
						<Outlined
							buttonName={"Cancel"}
							onClick={() =>
								navigate(
									`/projects/${
										groupId ? `${groupId}` : "projects"
									}/${projectType}/edit/${id}/backlogs`,
								)
							}
						/>
						{createOrUpdateAccess && (
							<Filled buttonName={"Submit"} loading={saveProjectTaskLoading} />
						)}
					</div>
				</div>
			</div>
		</form>
	);
};

export default EditIssues;
