import { ProjectGroupBugSourceFilterFormType } from "modules/Project/Pages/ProjectGroupBugSource/types";

export const filterSubmitValues = (
  filter: ProjectGroupBugSourceFilterFormType
) => {
  return {
    name:
      filter?.names && filter?.names?.length > 0
        ? {
            inArray: filter?.names?.map((name) => name?.id?.toString()),
          }
        : undefined,
    description: filter?.description?.trim()
      ? {
          contains: filter?.description,
        }
      : undefined,
    projectGroupName:
      filter?.projectGroupNames && filter?.projectGroupNames?.length > 0
        ? {
            inArray: filter?.projectGroupNames?.map((projectGroupName) =>
              projectGroupName?.id?.toString()
            ),
          }
        : undefined,
  };
};
