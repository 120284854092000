import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { Filled, Modal, Outlined } from "components/forms";
import TextArea from "components/forms/UpdatedForm/TextArea";
import Input from "components/forms/UpdatedForm/Input";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import {
  IProjectTaskLabel,
  IProjectTaskLabelForm,
} from "modules/Project/types/project";
import { CREATE_OR_UPDATE_PROJECT_LABEL } from "modules/Project/services/mutations";

const EditSettingsTaskLabel: React.FC<{
  editProjectLabel: IProjectTaskLabel | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectLabels: Function;
}> = ({ editProjectLabel, setShowModal, loading, refetchProjectLabels }) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const createOrUpdateAccess = allowedResources.includes("SaveProjectLabel");

  const [saveProjectLabel, { loading: projectLabelLoading }] = useMutation(
    CREATE_OR_UPDATE_PROJECT_LABEL
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProjectTaskLabelForm>();

  const createOrUpdateProjectTaskStatus: SubmitHandler<
    IProjectTaskLabelForm
  > = ({ name, description }) => {
    if (!projectLabelLoading) {
      saveProjectLabel({
        variables: {
          projectLabelInput: {
            id: editProjectLabel?.id || undefined,
            name,
            description: editProjectLabel?.id
              ? description
              : description || undefined,
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          refetchProjectLabels();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `Project label ${
                editProjectLabel?.id
                  ? editProjectLabel?.name
                  : response?.data?.saveProjectTaskStatus?.name
              } has been successfully ${
                editProjectLabel?.id ? "updated" : "added"
              } `,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Task Label</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectTaskStatus)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  required
                  defaultValue={editProjectLabel?.name}
                  setValue={setValue}
                />
                <TextArea
                  errors={errors}
                  label="Description "
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectLabel?.description}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={editProjectLabel?.id ? "Update" : "Add"}
                  loading={projectLabelLoading}
                  disabled={!createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditSettingsTaskLabel;
