import { ComponentPropsWithRef, FC, useRef } from "react";
import { mergeProps, useCalendar, useLocale } from "react-aria";
import { CalendarStateOptions, useCalendarState } from "react-stately";
import { createCalendar } from "@internationalized/date";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { twMerge } from "tailwind-merge";

import { CalendarButton } from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarButton";
import MonthDropdown from "components/forms/UpdatedFormAgry/Date/Calendar/MonthDropdown";
import { YearDropdown } from "components/forms/UpdatedFormAgry/Date/Calendar/YearDropdown";
import CalendarGrid from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarGrid";

type Props =
  | Omit<
      ComponentPropsWithRef<"div">,
      "children" | "defaultValue" | "onChange"
    > &
      Omit<CalendarStateOptions, "locale" | "createCalendar">;

const Calendar: FC<Props> = ({
  autoFocus,
  defaultFocusedValue,
  defaultValue,
  errorMessage,
  focusedValue,
  isDateUnavailable,
  isDisabled,
  isReadOnly,
  maxValue,
  minValue,
  onChange,
  onFocusChange,
  validationState,
  value,
  visibleDuration,
  selectionAlignment,
  id,
  className,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { locale } = useLocale();
  const state = useCalendarState({
    locale,
    createCalendar,
    autoFocus,
    defaultFocusedValue,
    defaultValue,
    errorMessage,
    focusedValue,
    isDateUnavailable,
    isDisabled,
    isReadOnly,
    maxValue,
    minValue,
    onChange,
    value,
    onFocusChange,
    validationState,
    visibleDuration,
    selectionAlignment,
  });

  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    {
      autoFocus,
      defaultFocusedValue,
      errorMessage,
      focusedValue,
      isDateUnavailable,
      isDisabled,
      isReadOnly,
      maxValue,
      minValue,
      onChange,
      value,
      defaultValue,
      onFocusChange,
      validationState,
      id,
    },
    state
  );

  return (
    <div
      {...mergeProps(calendarProps, props)}
      ref={ref}
      className={twMerge(
        "inline-block p-3 min-w-[300px] bg-background",
        className ? className : ""
      )}
    >
      <div className="flex justify-between items-center pb-4 bg-inherit">
        <CalendarButton {...prevButtonProps} className={"border"}>
          <MdOutlineKeyboardArrowLeft className="w-6 h-6" />
        </CalendarButton>
        <div className="flex gap-2">
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
        </div>
        <CalendarButton {...nextButtonProps} className={"border"}>
          <MdOutlineKeyboardArrowRight className="w-6 h-6" />
        </CalendarButton>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
};

export default Calendar;
