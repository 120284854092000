import { HiArrowNarrowLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import Loading from "components/Loader/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import SMCardContent from "components/App/Card/SMCard/SMCardContent";
import { Filled, Outlined } from "components/forms";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import DatesDetails from "modules/Leave/Pages/Requests/MyCompOff/DatesDetails";
import { FILTER_MY_COMPENSATION } from "modules/Leave/services/queries";
import { UPDATE_APPLY_COMPENSATION_LEAVE } from "modules/Leave/services/mutations";

const MyCompOffViewPage = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const LeaveResources = allowedResourcesList?.allowedResources || [];
  const updateAccess = LeaveResources?.includes("UpdateApplyLeave");

  const location = useLocation();

  const { loading, data: myCompOff } = useQuery(FILTER_MY_COMPENSATION, {
    variables: {
      id: +location?.pathname?.split("/")[
        location?.pathname?.split("/")?.length - 1
      ],
    },
  });

  const [withdrawVerification, setWithdrawVerification] =
    useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [withdrawSingleDate, setWithdrawSingleDate] = useState<{
    id: number | undefined;
    status: string | undefined;
  }>();

  const viewPageDetails =
    myCompOff?.filterMyCompensationLeave?.dataCollection?.length > 0 &&
    myCompOff?.filterMyCompensationLeave?.dataCollection[0];

  const [
    updateApplyCompensationLeave,
    { loading: updateApplyCompensationLeaveLoading },
  ] = useMutation(UPDATE_APPLY_COMPENSATION_LEAVE, {
    refetchQueries: [FILTER_MY_COMPENSATION],
  });

  const navigate = useNavigate();
  const leaveStatuses = viewPageDetails?.compensationLeaveDates?.map(
    (date: any) => date?.status
  );

  const hasPending = leaveStatuses?.includes("Pending");

  const withdrawHandler = (id: number) => {
    if (!updateApplyCompensationLeaveLoading) {
      updateApplyCompensationLeave({
        variables: {
          updateApplyCompensationLeaveInput: {
            id: +id,
            leaveRequestDates: withdrawSingleDate?.id
              ? [withdrawSingleDate]
              : dates?.map((date: { id: number }) => {
                  return {
                    id: +date?.id,
                    status: "Withdrawn",
                  };
                }),
          },
        },
      })
        .then((response) => {
          setWithdrawSingleDate({
            id: undefined,
            status: undefined,
          });
          setWithdrawVerification(false);
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your request has been withdrawn successfully.",
            },
          ]);
          const leaveDates =
            response?.data?.updateApplyLeave?.leaveRequestDates;
          if (
            leaveDates?.length < 1 ||
            leaveDates?.every(
              (leaveRequest: { status: string }) =>
                leaveRequest?.status === "Withdrawn"
            )
          ) {
            navigate("/leaves/requests/my-comp-offs");
          }
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  const createdAt = viewPageDetails?.createdAt || (
    <span className="text-gray-400">{"Eg. DD-MMM-YYYY"}</span>
  );
  const updatedAt = viewPageDetails?.updatedAt || createdAt;

  const sortFunction = (a: any, b: any) => {
    const dateA = new Date(a?.leaveDate)?.getTime();
    const dateB = new Date(b?.leaveDate)?.getTime();
    return dateA > dateB ? 1 : -1;
  };

  let dates: any = viewPageDetails?.compensationLeaveDates;
  dates = dates?.slice()?.sort(sortFunction);

  const findPendingAndApprovedDates = (dateArray: any[] | []) => {
    const pendingStatusDates =
      dateArray?.filter(
        (dateStatus: { status: string }) => dateStatus?.status === "Pending"
      ) || [];

    const approvedStatusDates =
      dateArray?.filter(
        (dateStatus: { status: string }) => dateStatus?.status === "Approved"
      ) || [];

    return { pendingStatusDates, approvedStatusDates };
  };

  const pendingAndApprovedDates = findPendingAndApprovedDates(dates);

  let values: {
    lable: string;
    hidden?: boolean | undefined;
    value: {
      content: string | Element;
      profileDetails?:
        | { profileImage?: string | undefined; name: string; email: string }
        | undefined;
      color?: string | undefined;
    }[];
  }[] =
    dates?.map((date: any, index: number) => {
      return {
        lable: `${
          !date?.leaveType?.isSpecialLeave
            ? "Date"
            : index === 0
            ? "From"
            : "To"
        }`,
        value: [
          {
            content: (
              <div>
                <DatesDetails
                  leaveRequestId={viewPageDetails?.id}
                  date={date}
                  requestToUser={viewPageDetails?.requestedToUser}
                  reason={viewPageDetails?.reason}
                  setWithdrawVerification={setWithdrawVerification}
                  setWithdrawSingleDate={setWithdrawSingleDate}
                  deleteVerification={deleteVerification}
                  setDeleteVerification={setDeleteVerification}
                />
              </div>
            ),
          },
        ],
      };
    }) || [];

  const getDataModal = () => [
    {
      lable: "ID",
      value: [
        {
          content: viewPageDetails?.id,
        },
      ],
    },
    {
      lable: "Created At",
      value: [
        {
          content: createdAt,
        },
      ],
    },
    {
      lable: "Updated At",
      value: [
        {
          content: updatedAt,
        },
      ],
    },
    {
      lable: "Project",
      value: [
        {
          content:
            viewPageDetails?.compensationLeaveDates?.length > 0 &&
            viewPageDetails?.compensationLeaveDates[0]?.project?.name,
        },
      ],
    },
    {
      lable: "Reported To",
      value: [
        {
          content: viewPageDetails?.requestedToUser?.personalInformation?.name,
        },
      ],
    },
    ...values,
    {
      lable: "Reason",
      value: [
        {
          content: viewPageDetails?.reason,
        },
      ],
    },
  ];

  return (
    <Fragment>
      {loading ? (
        <Loading className="min-h-[68vh]" />
      ) : (
        <div className="border border-hit-gray rounded-[4px] mt-[20px] px-5 py-4 min-h-[68vh]">
          <SMCardContent dataModel={getDataModal()} />
          {updateAccess ? (
            viewPageDetails?.status !== "Cancelled" &&
            viewPageDetails?.status !== "Declined" ? (
              <div className="flex flex-wrap gap-4 pt-5 justify-center items-center">
                {updateAccess && hasPending ? (
                  <div>
                    {viewPageDetails?.status !== "Approved" &&
                    viewPageDetails?.status !== "Partially Approved" ? (
                      <Outlined
                        buttonName="Edit"
                        onClick={() => {
                          navigate("/leaves/apply/comp-off", {
                            state: {
                              id: viewPageDetails?.id,
                              dates:
                                pendingAndApprovedDates?.pendingStatusDates,
                              reason: viewPageDetails?.reason,
                              isEditing: true,
                              requestToUser: viewPageDetails?.requestedToUser,
                            },
                          });
                        }}
                      />
                    ) : null}
                  </div>
                ) : null}
                {hasPending && (
                  <Filled
                    onClick={() => {
                      setDeleteVerification(true);
                    }}
                    buttonName={"Withdraw"}
                    buttonType={"submit"}
                  />
                )}
              </div>
            ) : null
          ) : null}
          {withdrawVerification && (
            <ConfirmModal
              header="Comp Off Request"
              keyValue="withdraw"
              onCancel={() => {
                setWithdrawVerification(false);
                setWithdrawSingleDate({
                  id: undefined,
                  status: undefined,
                });
              }}
              onXIcon={() => {
                setWithdrawVerification(false);
                setWithdrawSingleDate({
                  id: undefined,
                  status: undefined,
                });
              }}
              onExecute={() => {
                withdrawHandler(viewPageDetails?.id);
              }}
              loading={updateApplyCompensationLeaveLoading}
            />
          )}
        </div>
      )}
      {deleteVerification && (
        <ConfirmModal
          header="Comp Off Request"
          keyValue="Withdraw"
          onCancel={() => {
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={() => withdrawHandler(viewPageDetails?.id)}
          loading={updateApplyCompensationLeaveLoading}
        />
      )}
      <div className="flex justify-center items-center">
        <HiArrowNarrowLeft
          className="back-arrow-icon"
          onClick={() => {
            navigate("/leaves/requests/my-comp-off");
          }}
        />
      </div>
    </Fragment>
  );
};

export default MyCompOffViewPage;
