import { useReactiveVar } from "@apollo/client";

import { allowedAccessVar } from "global/helpers/Cache";

const useGetAllowedResources = (resourceName: string) => {
  const allowedResources: any = useReactiveVar(allowedAccessVar);
  const canRead = allowedResources?.includes(`Read${resourceName}`);
  const canCreate = allowedResources?.includes(`Create${resourceName}`);
  const canUpdate = allowedResources?.includes(`Update${resourceName}`);
  const canDelete = allowedResources?.includes(`Delete${resourceName}`);
  return { canRead, canCreate, canUpdate, canDelete };
};

export default useGetAllowedResources;
