import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import { getAllowedAccess } from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import useWindowSize from "global/hooks/useWindowSize";

import { navData } from "layouts/nav/Sidebar/NavData";
import { ResourcesType } from "layouts/PrivateLayout";
import MenuDetails from "layouts/nav/Sidebar/MenuDetails";

const Sidebar: React.FC<{
	showMenu: boolean;
	setShowMenu: Function;
}> = ({ showMenu, setShowMenu }) => {
	const allowedAccess: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedAccess?.allowedResources || [];

	const [activeLink, setActiveLink] = useState<number | null>(null);

	const path = useLocation();
	const pathName = path.pathname;
	let title = "";

	useEffect(() => {
		if (pathName.slice(0, 8) === "/profile") {
			setActiveLink(null);
		}
	}, [pathName]);

	switch (
		`/${pathName?.split("/")?.filter((filterData: string) => filterData)[0]}`
	) {
		case "/profile":
			title = "My Profile";
			break;

		case "/change-password":
			title = "Change Password";
			break;

		case "/notification":
			title = "Notification";
			break;

		case "/leaves":
			const pathNamesArrayLeaves = pathName?.split("/");
			title = `Leave / ${Capitalize(
				pathNamesArrayLeaves?.filter((filterData: string) => filterData)[1],
			)}`;
			break;

		case "/projects":
			const pathNamesArrayForProjects = pathName?.split("/");
			title = `Projects / ${Capitalize(
				pathNamesArrayForProjects?.filter(
					(filterData: string) => filterData,
				)[1] === "my-projects" ||
					pathNamesArrayForProjects?.filter(
						(filterData: string) => filterData,
					)[1] === "all-projects"
					? "Projects"
					: pathNamesArrayForProjects?.filter(
							(filterData: string) => filterData,
					  )[1],
			)}`;

			break;

		default:
			navData?.map((menuName) =>
				pathName?.includes(`${menuName?.link}`)
					? (title = menuName.name)
					: null,
			);
			break;
	}

	const checkAllowedResources: (id: string) => ResourcesType = (id: string) => {
		const allowedResource =
			allowedResources?.filter(
				(resource: string) => resource?.toLowerCase() === id?.toLowerCase(),
			)[0] || "";

		return allowedResource;
	};

	const [, height] = useWindowSize();

	return (
		<div
			className={`${
				showMenu ? "w-[250px] xl:w-[80px] xl:hover:w-[250px]" : "w-[250px]"
			} ${
				height > 750 ? "h-[90vh]" : "h-[87vh]"
			} group bg-white lg:shadow-[0px_0px_45px_#C4C4C433] group transition-all duration-700 overflow-y-auto`}
		>
			<nav className="flex flex-col text-sm group">
				{navData.map((menu, index) => {
					let navLink: string | null = "";

					switch (menu.link) {
						case "/settings": {
							if (allowedResources?.includes("Role")) {
								navLink = "/settings/role";
							} else if (allowedResources?.includes("RoleAllocation")) {
								navLink = "/settings/role-allocation";
							} else if (allowedResources?.includes("UserProfileModeration")) {
								navLink = "/settings/moderation";
							} else if (allowedResources?.includes("ActionLog")) {
								navLink = "/settings/action-logs";
							}
							break;
						}
						case "/leaves": {
							navLink = null;
							break;
						}
						case "/team": {
							navLink = "/team/my-team-info";
							break;
						}
						case "/organization": {
							if (allowedResources?.includes("Organization")) {
								if (allowedResources?.includes("Users")) {
									navLink = "/organization/users";
								} else if (allowedResources?.includes("Departments")) {
									navLink = "/organization/departments";
								} else if (allowedResources?.includes("Designations")) {
									navLink = "/organization/designations";
								}
							}
							break;
						}
						case "/projects": {
							navLink = null;
							break;
						}

						case "/timer": {
							if (allowedResources?.includes("Timer")) {
								if (allowedResources?.includes("ReadProjectWorkLog")) {
									navLink = "/timer/my-logs";
								} else if (
									allowedResources?.includes("ReadAllProjectWorkLog")
								) {
									navLink = "/timer/all-logs";
								}
							}
							break;
						}

						case "/companies": {
							if (allowedResources?.includes("Company")) {
								if (allowedResources?.includes("ReadCompany")) {
									navLink = "/companies";
								}
							}
							break;
						}

						default: {
							navLink = menu.link;
							break;
						}
					}

					if (checkAllowedResources(menu?.name)) {
						return (
							<MenuDetails
								key={menu.link}
								index={index}
								menu={menu}
								navLink={navLink}
								title={title}
								showMenu={showMenu}
								resources={allowedResources}
								activeLink={activeLink}
								setActiveLink={setActiveLink}
								setShowMenu={setShowMenu}
							/>
						);
					} else {
						return null;
					}
				})}
			</nav>
		</div>
	);
};

export default Sidebar;
