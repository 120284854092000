import React from "react";

import reportWebVitals from "reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import ToastedNotificationProvider from "components/UI/ToastNotification/ToastedNotificationProvider";

import { client } from "global/services/config";

import "index.css";
import App from "App";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ToastedNotificationProvider>
          <App />
        </ToastedNotificationProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
