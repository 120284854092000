import { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  getAllowedAccess,
  toastNotify,
  setBackNavigationURL,
  setTitle,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import { GET_ALL_PROJECT_VAULT_ENVIRONMENTS } from "modules/Project/services/queries";
import { DELETE_PROJECT_VAULT_ENVIRONMENT } from "modules/Project/services/mutations";
import { IProjectVaultEnvironment } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import EditVaultEnvironment from "./EditVaultEnvironment";

const tableCommonHeadings = [
  { name: "Name", APIName: "name" },
  { name: "Description", APIName: "description" },
];

const VaultEnvironments = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const canRead = allowedResources.includes("ReadProjectVaultEnvironment");
  const canCreateOrUpdate = allowedResources.includes(
    "SaveProjectVaultEnvironment"
  );
  const canDelete = allowedResources.includes("DeleteProjectVaultEnvironment");
  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [
    deleteProjectVaultEnvironment,
    { loading: deleteProjectVaultEnvironmentLoading },
  ] = useMutation(DELETE_PROJECT_VAULT_ENVIRONMENT);

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    setTitle([
      { name: "Project", link: "/projects/projects/my-projects" },
      {
        name: "Vault Environments",
        link: "/projects/settings/vault-environments",
      },
    ]);
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const [
    fetchAllProjectVaultEnvironments,
    {
      data: getAllProjectVaultEnvironments,
      loading,
      refetch: refetchProjectVaultEnvironments,
    },
  ] = useLazyQuery(GET_ALL_PROJECT_VAULT_ENVIRONMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const vaultEnivironmentsLists =
    getAllProjectVaultEnvironments?.projectVaultEnvironment || [];

  const totalCount = vaultEnivironmentsLists?.length || 0;

  const noOfItems =
    getAllProjectVaultEnvironments && vaultEnivironmentsLists?.length;

  useEffect(() => {
    fetchAllProjectVaultEnvironments({
      variables: {
        limit: pageSize,
        page: currentPage,
        search: search || undefined,
        orderBy: sortOrder
          ? {
              field: sortOrder?.field,
              orderType: sortOrder?.orderType,
            }
          : undefined,
      },
    });
  }, [
    currentPage,
    fetchAllProjectVaultEnvironments,
    pageSize,
    search,
    sortOrder,
  ]);

  const [editProjectVaultEnvironment, setEditProjectVaultEnvironment] =
    useState<IProjectVaultEnvironment | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectVaultEnvironment?.id) {
      deleteProjectVaultEnvironment({
        variables: {
          id: +editProjectVaultEnvironment?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectVaultEnvironments();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectVaultEnvironment?.name
                  ? `Environment ${editProjectVaultEnvironment?.name}`
                  : "Environment"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (vaultEnvironment: IProjectVaultEnvironment) => {
    if (canCreateOrUpdate) {
      setEditProjectVaultEnvironment(vaultEnvironment);
      setShowModal(true);
    }
  };

  const onDelete = (vaultEnvironment: IProjectVaultEnvironment) => {
    setEditProjectVaultEnvironment(vaultEnvironment);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectVaultEnvironment(null);
  };

  const getDataModal = (
    vaultEnvironment: IProjectVaultEnvironment
  ): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: Capitalize(vaultEnvironment?.name),
        },
      ],
    },
    {
      label: "Description",
      value: [
        {
          content: vaultEnvironment?.description,
        },
      ],
    },
  ];

  const getTableContent = (
    vaultEnvironment: IProjectVaultEnvironment
  ): ITableContent[] => [
    {
      value: vaultEnvironment?.name,
    },
    {
      value: vaultEnvironment?.description,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(vaultEnvironment);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />

        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreateOrUpdate && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {vaultEnivironmentsLists?.map(
                    (vaultEnvironment: IProjectVaultEnvironment) => {
                      return (
                        <DataCard
                          key={vaultEnvironment?.id}
                          dataModal={getDataModal(vaultEnvironment)}
                          classForULlist={`${
                            canCreateOrUpdate
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          cardClick={() => {
                            canCreateOrUpdate && onEdit(vaultEnvironment);
                          }}
                          classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                          otherOption={
                            (canDelete && (
                              <div className="flex justify-center">
                                <ToolTip
                                  render={() => "Delete"}
                                  arrow
                                  className={
                                    "text-center bg-[#616161] text-white"
                                  }
                                >
                                  <BiTrash
                                    className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDelete(vaultEnvironment);
                                    }}
                                  />
                                </ToolTip>
                              </div>
                            )) ||
                            undefined
                          }
                        />
                      );
                    }
                  )}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  isSortOrderNeeded
                >
                  {vaultEnivironmentsLists?.map(
                    (vaultEnvironment: IProjectVaultEnvironment) => {
                      return (
                        <TableContent
                          key={vaultEnvironment?.id}
                          tableRef={tableRef}
                          classForTableRow={`${
                            canRead
                              ? "cursor-pointer hover:bg-white-smoke"
                              : "cursor-default hover:bg-white-smoke"
                          }`}
                          tableRowClick={() => {
                            canRead && onEdit(vaultEnvironment);
                          }}
                          tableContent={getTableContent(vaultEnvironment)}
                        />
                      );
                    }
                  )}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditVaultEnvironment
          refetchProjectVaultEnvironments={refetchProjectVaultEnvironments}
          editProjectVaultEnvironment={editProjectVaultEnvironment}
          setShowModal={setShowModal}
          loading={loading}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Project"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectVaultEnvironmentLoading}
        />
      )}
    </div>
  );
};

export default VaultEnvironments;
