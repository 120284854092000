import { TFilterOption } from "components/Filter/types";

import { removedUndefinedValuesFromObject } from "global/helpers/removeUndefinedValuesFromObject";

export const getFilterFormValues = <
  T extends { filter: object; search: string }
>(
  data: T,
  options: TFilterOption<T>
): object => {
  let filteredObj = {
    globalSearch: data?.search?.trim() || undefined,
  };

  if (data?.filter) {
    Object.entries(data?.filter).map(([key, value]) => {
      const option = options?.filter(
        (option) => option?.id?.slice(7) === key
      )[0];

      if (option?.type === "boolean") {
        if (value !== null && value !== undefined)
          Object.assign(filteredObj, { [key]: value });
      }
      if (option?.type === "number") {
        const numberObject = option?.numberBetween
          ? {
              between: {
                from:
                  value?.from && !isNaN(+value?.from)
                    ? +value?.from
                    : undefined,
                to: value?.to && !isNaN(+value?.to) ? +value?.to : undefined,
              },
            }
          : {
              number: value && !isNaN(+value) ? +value : undefined,
            };
        Object.assign(filteredObj, {
          [key]: numberObject,
        });
      }

      if (option?.type === "date") {
        const dateObject = option.dateBetween
          ? {
              between: {
                from: value?.from ? value?.from : undefined,
                to: value?.to ? value?.to : undefined,
              },
            }
          : {
              date: value ? value : undefined,
            };
        Object.assign(filteredObj, {
          [key]: dateObject,
        });
      }
      if (option?.type === "string") {
        const stringObject = option?.multiple
          ? {
              inArray: Array.isArray(value)
                ? value?.length > 0
                  ? value
                  : undefined
                : undefined,
            }
          : option?.directString
          ? value
          : {
              contains: value ? value : undefined,
            };
        Object.assign(filteredObj, {
          [key]: stringObject,
        });
      }
      return null;
    });
  }
  return removedUndefinedValuesFromObject(filteredObj);
};
