import { FC, useRef, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import DataCard from "components/App/Card/DataCard";
import Table from "components/App/Table/Table";
import { Filled } from "components/forms";

import { toastNotify, userQueryProps } from "global/helpers/Cache";
import { IResourceAccess } from "global/types/acl";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import EmergencyContactDetails from "modules/Employee/Pages/Profile/Contact/EmergencyContact/EmergencyContactDetails";
import EditEmergencyContact from "modules/Employee/Pages/Profile/Contact/EmergencyContact/EditEmergencyContact";
import { GET_EMERGENCY_CONTACTS } from "modules/Employee/services/queries";
import {
  DELETE_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT_POSITION,
} from "modules/Employee/services/mutations";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import { IEmergencyContact } from "modules/Employee/types/contacts";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  resourceAccess: IResourceAccess;
}

const tableHeadings = ["Name", "Relation", "Mobile", "Action"];

const EmergencyContact: FC<IProps> = ({ resourceAccess }) => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchEmergencyContatcts,
  } = useQuery(GET_EMERGENCY_CONTACTS, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const emergencyContact: IEmergencyContact[] =
    userDetails?.users?.dataCollection[0]?.userEmergencyContacts || [];

  const isUnderModeration = useIsUnderModeration();

  const [inProgress, setInProgress] = useState(false);

  const sortedEmergencyContact = emergencyContact
    ? [...emergencyContact]?.sort(
        (
          contactFirstItem: IEmergencyContact,
          contactSecondItem: IEmergencyContact
        ) => {
          return contactFirstItem.position - contactSecondItem.position;
        }
      )
    : null;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);

  const [updateEmergencyContactPosition] = useMutation(
    UPDATE_EMERGENCY_CONTACT_POSITION
  );
  const [deleteUserEmergencyContact] = useMutation(DELETE_EMERGENCY_CONTACT);

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Emergency contact")
    : deleteSuccessMessageWithAwaiting("Emergency contact");

  const deleteHandler = () => {
    deleteUserEmergencyContact({
      variables: {
        id: editID,
      },
      update: (cache) => {
        const exEmergencyContact: any = cache.readQuery({
          query: GET_EMERGENCY_CONTACTS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
        });

        const updatedEmergencyContacts =
          exEmergencyContact?.users?.dataCollection[0]?.userEmergencyContacts?.filter(
            (emergencyContact: IEmergencyContact) =>
              emergencyContact?.id !== editID
          );

        cache.writeQuery({
          query: GET_EMERGENCY_CONTACTS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
          data: {
            users: {
              dataCollection: [
                {
                  ...exEmergencyContact?.users?.dataCollection[0],
                  userEmergencyContacts: updatedEmergencyContacts,
                },
              ],
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteVerification(false);
        refetchEmergencyContatcts();
        setEditID(null);
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  let sortedArray = sortedEmergencyContact || [];

  const draggingItem = useRef<any>();
  const dragOverItem = useRef<any>();

  const handleDragStart = (position: number) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (position: number) => {
    dragOverItem.current = position;
    const listCopy = sortedArray ? [...sortedArray] : [];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1);
    listCopy.splice(dragOverItem.current, 0, draggingItemContent);
    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    sortedArray = listCopy;
  };

  const handleDragEnd = (position: number) => {
    const changedItemID =
      sortedEmergencyContact && sortedEmergencyContact[position]?.id;

    const changedItemPosition =
      sortedArray &&
      sortedArray
        ?.map((sortedArrayItem: { id: number | null }) => sortedArrayItem?.id)
        ?.indexOf(changedItemID) + 1;

    updateEmergencyContactPosition({
      variables: {
        id: changedItemID,
        position: changedItemPosition,
      },
    });
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };

  const addOrEditHandler = () => {
    setEditID(null);
    setShowModal(true);
  };

  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <div className="emergency-contact-position">
      <DataCard
        loading={loading}
        setShowModal={setShowModal}
        title="Emergency Contacts"
        resourceName="EmployeeEmergencyContactInformation"
        addOrEditHandler={addOrEditHandler}
        showPlusIcon={sortedArray?.length === 0 ? false : true}
      >
        {sortedArray?.length === 0 ? (
          <div className="w-full min-h-[300px] grid place-content-center">
            <Filled
              buttonName={"Add"}
              buttonType="button"
              onClick={addOrEditHandler}
            />
          </div>
        ) : (
          <Table
            tableHeadings={tableHeadings}
            noOfItems={sortedArray?.length}
            className="py-5"
            isCenterlastHeading={true}
            ref={tableRef}
            loading={loading}
          >
            {sortedArray?.map(
              (emergencyContactInfoData: IEmergencyContact, index: number) => {
                return (
                  <tr
                    className="hover:bg-white-smoke border-b last:border-none border-white-smoke text-sm cursor-pointer"
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={(event) => event.preventDefault()}
                    onDragEnter={() => handleDragEnter(index)}
                    onDragEnd={() => handleDragEnd(index)}
                    key={emergencyContactInfoData?.id}
                    draggable
                  >
                    <EmergencyContactDetails
                      key={emergencyContactInfoData?.id}
                      emergencyContactInfoData={emergencyContactInfoData}
                      setDeleteVerification={setDeleteVerification}
                      setEditID={setEditID}
                      setShowModal={setShowModal}
                      resourceAccess={resourceAccess}
                      setChangedInformation={setChangedInformation}
                      setShowUnderReview={setShowUnderReview}
                      tableRef={tableRef}
                    />
                  </tr>
                );
              }
            )}
          </Table>
        )}
      </DataCard>

      {showModal && (
        <EditEmergencyContact
          sortedEmergencyContact={sortedEmergencyContact || []}
          setShowModal={setShowModal}
          editID={editID}
          setEditID={setEditID}
          inProgress={inProgress}
          setInProgress={setInProgress}
          refetchEmergencyContatcts={refetchEmergencyContatcts}
        />
      )}

      {showUnderReview && (
        <UnderReviewModal
          setShowUnderReview={setShowUnderReview}
          changedInformation={changedInformation}
          refetchQuery={refetchEmergencyContatcts}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Emergency Contact"
          onExecute={deleteHandler}
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
        />
      )}
    </div>
  );
};

export default EmergencyContact;
