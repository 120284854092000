import { useEffect, useState, FC, Fragment } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { HiDownload, HiTrash } from "react-icons/hi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";

import { toastNotify, userContextData } from "global/helpers/Cache";
import { downloadPDFUsingFunction } from "global/helpers/getFileExtension";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";
import { ISetStateType } from "global/types/type";

import {
  PROJECT_TASK_ATTACHMENTS,
  PROJECT_TASK_ATTACHMENT_LENGTH,
} from "modules/Project/services/queries";
import { SAVE_PROJECT_TASK } from "modules/Project/services/mutations";

interface IProps {
  canDeleteProjectTaskAttachment: boolean;
  refetchFileAttachments: boolean;
  setRefetchFileAttachments: ISetStateType<boolean>;
}

const FileAttachments: FC<IProps> = ({
  canDeleteProjectTaskAttachment,
  refetchFileAttachments,
  setRefetchFileAttachments,
}) => {
  const [
    fetchProjectTaskAttachments,
    { data: fileAttachments, refetch, loading },
  ] = useLazyQuery(PROJECT_TASK_ATTACHMENTS, {
    fetchPolicy: "cache-and-network",
  });

  const [fetchProjectTaskAttachmentsLength, { data: fileAttachmentsLength }] =
    useLazyQuery(PROJECT_TASK_ATTACHMENT_LENGTH);

  const [saveProjectTask, { loading: projectTaskLoading }] =
    useMutation(SAVE_PROJECT_TASK);

  const { id, issueId } = useParams();
  const [showAttachment, setShowAttachment] = useState(false);
  const [deleteVerification, setDeleteVerification] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState<number | null>(null);
  const userDataList: any = useReactiveVar(userContextData);
  const { pathname } = useLocation();
  const isMyProjectOrAllProjectPathName = pathname?.split("/")[3];

  const [projectTaskAttachmentsLength, setProjectTaskAttachmentsLength] =
    useState<number>(0);

  useEffect(() => {
    setProjectTaskAttachmentsLength(
      fileAttachmentsLength?.projects?.dataCollection &&
        fileAttachmentsLength?.projects?.dataCollection[0]?.tasks
          ?.dataCollection &&
        fileAttachmentsLength?.projects?.dataCollection[0]?.tasks
          ?.dataCollection[0]?.projectTaskAttachments?.length
    );
  }, [fileAttachmentsLength?.projects?.dataCollection]);

  useEffect(() => {
    if (
      id &&
      issueId &&
      !isNaN(+issueId) &&
      !isNaN(+id) &&
      (showAttachment || refetchFileAttachments)
    ) {
      fetchProjectTaskAttachments({
        variables: {
          filters: {
            id: +id,
            isMyProject:
              isMyProjectOrAllProjectPathName === "my-projects"
                ? true
                : undefined,
          },
          taskFilter: {
            id: +issueId,
          },
        },
      }).then((res) => {
        setProjectTaskAttachmentsLength(
          res?.data?.projects?.dataCollection?.length > 0 &&
            res?.data?.projects?.dataCollection[0]?.tasks?.dataCollection
              ?.length > 0 &&
            res?.data?.projects?.dataCollection[0]?.tasks?.dataCollection[0]
              ?.projectTaskAttachments?.length
        );
        setRefetchFileAttachments(false);
      });
    }
  }, [
    id,
    issueId,
    fetchProjectTaskAttachments,
    showAttachment,
    refetchFileAttachments,
    setRefetchFileAttachments,
    isMyProjectOrAllProjectPathName,
  ]);

  useEffect(() => {
    if (id && issueId && !isNaN(+issueId) && !isNaN(+id)) {
      fetchProjectTaskAttachmentsLength({
        variables: {
          filters: {
            id: +id,
            isMyProject: true,
          },
          taskFilter: {
            id: +issueId,
          },
        },
      });
    }
  }, [id, issueId, fetchProjectTaskAttachmentsLength, refetchFileAttachments]);

  const projectTaskAttachments =
    fileAttachments?.projects?.dataCollection &&
    fileAttachments?.projects?.dataCollection[0]?.tasks?.dataCollection &&
    fileAttachments?.projects?.dataCollection[0]?.tasks?.dataCollection[0]
      ?.projectTaskAttachments;

  const onDelete = (id: number) => {
    setDeleteVerification(true);
    setDeleteFileId(id);
  };
  const confirmModalCloseHandler = () => {
    setDeleteFileId(null);
    setDeleteVerification(false);
  };

  const removeFileHandler = () => {
    if (
      !projectTaskLoading &&
      issueId &&
      id &&
      !isNaN(+issueId) &&
      !isNaN(+id)
    ) {
      saveProjectTask({
        variables: {
          projectTaskInput: {
            id: +issueId || undefined,
            projectId: +id,
            projectTaskAttachmentToRemove: [deleteFileId],
          },
        },
      })
        .then((res) => {
          setProjectTaskAttachmentsLength(
            res?.data?.saveProjectTask?.projectTaskAttachments?.length
          );
          refetch();
          toastNotify([
            {
              messageType: "success",
              message: "File deleted successfully.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        })
        .finally(() => {
          setDeleteVerification(false);
          setDeleteFileId(null);
        });
    }
  };

  return (
    <>
      <p
        className={`font-medium w-max bg  ${
          showAttachment && projectTaskAttachmentsLength ? "mb-4" : "mb-10"
        } ${
          projectTaskAttachmentsLength ? "cursor-pointer" : ""
        }  && text-[14px] leading-[17px] text-[#612224]`}
        onClick={() => {
          if (projectTaskAttachmentsLength > 0) {
            setShowAttachment((prevState) => !prevState);
          }
        }}
      >
        {projectTaskAttachmentsLength >= 0 &&
          `Attachments (${projectTaskAttachmentsLength})`}
      </p>
      {loading && (
        <div className="w-full h-[100px]">
          <div className="animate-pulse px-[10px] py-[6px] flex space-x-4">
            <div className="flex-1 space-y-[18px] py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAttachment && projectTaskAttachments?.length > 0 && !loading && (
        <div className="overflow-x-auto mb-12">
          <table className="text-[#5e6c84]">
            <thead className="">
              <tr className="border-b-[2.5px] border-[#f2f2f2]">
                <th className="min-w-[400px] text-left">Name</th>
                <th className="min-w-[200px] text-left">Date added</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projectTaskAttachments?.map(
                (attachment: {
                  id: number;
                  name: string;
                  filePath: string;
                  mimeType: string;
                  createdAt: string;
                  createdBy: { id: number };
                }) => (
                  <Fragment key={attachment?.id}>
                    <tr className="text-[#626364]">
                      <td className="pb-1 first:pt-2">
                        <div>
                          <p>{attachment?.name}</p>
                        </div>
                      </td>
                      <td className="pb-1 first:pt-2">
                        {attachment?.createdAt || ""}
                      </td>
                      <td className="pb-1 first:pt-2">
                        {attachment?.filePath &&
                        attachment?.filePath?.length <= 100 ? (
                          <span className="text-gray-400 md:ml-8 xl:ml-0">
                            No File
                          </span>
                        ) : !attachment?.mimeType?.includes("video") ? (
                          <a
                            href={
                              attachment?.filePath &&
                              Base64toObject(attachment?.filePath)
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center"
                          >
                            <LinkIcon className="w-[14px] h-[14px] md:ml-8 xl:ml-0 cursor-pointer text-cornflower-blue" />
                          </a>
                        ) : (
                          <LinkIcon className="w-[14px] h-[14px] md:ml-8 xl:ml-0 cursor-not-allowed text-cornflower-blue" />
                        )}
                      </td>
                      <td className="pb-1 first:pt-2 cursor-pointer">
                        <HiDownload
                          className="w-4 h-4 ml-3"
                          onClick={() => {
                            downloadPDFUsingFunction(
                              attachment?.filePath,
                              attachment?.name,
                              attachment?.mimeType
                            );
                          }}
                        />
                      </td>
                      {(canDeleteProjectTaskAttachment ||
                        userDataList?.user?.id ===
                          attachment?.createdBy?.id) && (
                        <td className="pb-1 first:pt-2 cursor-pointer">
                          <HiTrash
                            className="w-4 h-4 ml-3"
                            onClick={() => {
                              onDelete(attachment?.id);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        {attachment?.mimeType?.includes("video") && (
                          <video controls className="pt-2 w-full">
                            <source
                              type={attachment?.mimeType}
                              src={`data:${attachment?.mimeType};base64,${attachment?.filePath}`}
                            />
                          </video>
                        )}
                      </td>
                    </tr>
                  </Fragment>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
      {deleteVerification && (
        <ConfirmModal
          header="Attachment"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={removeFileHandler}
          loading={projectTaskLoading}
        />
      )}
    </>
  );
};

export default FileAttachments;
