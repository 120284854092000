import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { SearchField } from "components/forms";
import { paginationDefaultValue } from "global/helpers/StaticData";
import { GET_ALL_EMPLOYEES } from "modules/Employee/services/queries";
import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import { IAllowedResources } from "modules/Organization/types/organization";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { toastNotify } from "global/helpers/Cache";
import { useNavigate } from "react-router-dom";
import { DELETE_USER } from "modules/User/services/mutations";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Loading from "components/Loader/Loading";
import OopsNoData from "components/UI/OopsNoData";
import UserDetails from "components/UserDetails/UserDetails";
import { ITableContent } from "components/App/Table/types/table";

const tableHeadings = [
  "Name",
  "Employee ID",
  "Gender",
  "DOB",
  "Official Email",
  "Designation",
  "Action",
];

const Users: FC<IAllowedResources> = ({ allowedResources }) => {
  const [fetchUsers, { data: users, loading, refetch: refetchUserList }] =
    useLazyQuery(GET_ALL_EMPLOYEES, {
      fetchPolicy: "cache-and-network",
    });

    
  const [deleteUser] = useMutation(DELETE_USER);

  const updateAccess = allowedResources?.includes("UpdateDesignation");
  const deleteAccess = allowedResources?.includes("DeleteDesignation");

  const { register, watch, setValue } = useForm();

  const [userIdForProfileHover, setUserIdForProfileHover] = useState<
    number | undefined
  >();
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setUserList(users?.getAllEmployees?.dataCollection);
  }, [users]);

  const employee = watch("employee");
  const [search, setSearch] = useState(employee || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(employee?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [employee]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  useEffect(() => {
    fetchUsers({
      variables: {
        limit: pageSize,
        page: currentPage,
        search: search || undefined,
      },
    });
  }, [currentPage, pageSize, search, fetchUsers]);

  const [userId, setUserId] = useState<string | number>("");
  const parentRef = useRef<HTMLDivElement>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>();
  const navigate = useNavigate();

  const kebabMenuEditHandler = () => {
    sessionStorage.otherUserId = +userId;
    navigate("/profile/personal");
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const deleteHandler = () => {
    deleteUser({
      variables: {
        id: +userId,
      },
    })
      .then((response) => {
        setDeleteVerification(false);
        refetchUserList();
        toastNotify([
          {
            messageType: "success",
            message: "You have successfully deleted your record.",
          },
        ]);
      })
      .catch((error) => {
        toastNotify([
          {
            messageType: "error",
            message: error?.message,
          },
        ]);
      });
  };
  const kebabMenuClickHandler = (user: any) => {
    setUserId(user?.id);
  };

  const getDataModal = (user: any) => {
    return [
      {
        lable: "Name",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() =>
                    setUserIdForProfileHover(user?.personalInformation?.id)
                  }
                >
                  {user?.personalInformation?.name}
                </p>
                <UserDetails userId={userIdForProfileHover} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Employee ID",
        value: [
          {
            content: user?.employeeDetail?.employeeId,
          },
        ],
      },
      {
        lable: "Gender",
        value: [
          {
            content: user?.personalInformation?.gender,
          },
        ],
      },
      {
        lable: "Date of Birth",
        value: [
          {
            content: user?.personalInformation?.officialDateOfBirth,
          },
        ],
      },
      {
        lable: "Official Email",
        value: [
          {
            content: user?.employeeDetail?.personalEmail,
          },
        ],
      },
      {
        lable: "Designation",
        value: [
          {
            content: user?.employeeDetail?.employeeDesignation,
          },
        ],
      },
    ];
  };


  return (
    <Fragment>
      <div>
        <div className="flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row md:pb-0 pt-5">
          <SearchField
            register={register}
            name="employee"
            label="Employee"
            onChange={() => {
              setCurrentPage(1);
            }}
            setValue={setValue}
          />
        </div>
        <div>
          {loading ? (
            <Loading className="min-h-[70vh]" />
          ) : (
            <div>
              <div className="hidden md:block">
                <Table
                  loading={loading}
                  noOfItems={users && userList?.length}
                  tableHeadingsObj={tableHeadings?.map((name: string) => {
                    return {
                      name,
                      center:
                        name === "Name" || name === "Official Email"
                          ? false
                          : true,
                    };
                  })}
                  ref={parentRef}
                  isCenterlastHeading={true}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  className="py-5"
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  totalCount={
                    users?.getAllEmployees?.totalNumberOfItems
                      ? users?.getAllEmployees?.totalNumberOfItems
                      : 0
                  }
                >
                  {userList?.map((user: any) => {
                    let tableContent: ITableContent[] = [
                      {
                        value: (
                          <div className="group">
                            <p
                              onMouseEnter={() =>
                                setUserIdForProfileHover(
                                  user?.personalInformation?.id
                                )
                              }
                            >
                              {user?.personalInformation?.name}
                            </p>
                            <UserDetails userId={userIdForProfileHover} />
                          </div>
                        ),
                      },
                      { value: user?.employeeDetail?.employeeId, center: true },
                      {
                        value: user?.personalInformation?.gender,
                        center: true,
                      },
                      {
                        value: user?.personalInformation?.officialDateOfBirth,
                        center: true,
                      },
                      {
                        value: user?.employeeDetail?.officialEmail,
                        className: "w-[230px]",
                      },
                      {
                        value: user?.employeeDetail?.employeeDesignation,
                        center: true,
                      },
                    ];
                    return (
                      <TableContent
                        key={user?.id}
                        id={user?.id}
                        tableContent={tableContent}
                        editAccess={updateAccess || deleteAccess}
                        onEdit={kebabMenuEditHandler}
                        showModal={deleteVerification}
                        onDelete={kebabMenuDeleteHandler}
                        onMenuClick={() => {
                          kebabMenuClickHandler(user);
                        }}
                        parentRef={parentRef}
                      />
                    );
                  })}
                </Table>
              </div>
              <div className="md:hidden min-h-[500px] mx-auto">
                <div className="w-full flex flex-col justify-center mt-5">
                  {userList?.length > 0 ? (
                    <div>
                      {userList?.map((user: any, index: any) => {
                        return (
                          <SMDataCard
                            key={user?.id}
                            title={`User ${index + 1}`}
                            showModal={deleteVerification}
                            loading={loading}
                            dataModal={getDataModal(user)}
                            onKebabIcon={{
                              onclick: () => {
                                kebabMenuClickHandler(user);
                              },
                              kebabMenu: {
                                onEdit: kebabMenuEditHandler,
                                onDelete: kebabMenuDeleteHandler,
                              },
                            }}
                          />
                        );
                      })}
                      <Pagination
                        totalCount={
                          users?.getAllEmployees?.totalNumberOfItems
                            ? users?.getAllEmployees?.totalNumberOfItems
                            : 0
                        }
                        currentPage={currentPage}
                        setPageSize={setPageSize}
                        siblingCount={1}
                        pageSize={pageSize}
                        onPageChange={(page: number) => setCurrentPage(page)}
                        noOfItem={userList?.length}
                      />
                    </div>
                  ) : (
                    users && <OopsNoData className="min-h-[70vh]" />
                  )}
                </div>
              </div>
            </div>
          )}
          {deleteVerification ? (
            <ConfirmModal
              header={"User"}
              onCancel={() => {
                setDeleteVerification(false);
              }}
              onXIcon={() => {
                setDeleteVerification(false);
              }}
              onExecute={deleteHandler}
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
