import { createCalendar } from "@internationalized/date";
import { FC, useRef } from "react";
import { DateValue, useDateField, useLocale } from "react-aria";
import { DateFieldStateOptions, useDateFieldState } from "react-stately";

import DateSegment from "components/forms/UpdatedFormAgry/Date/Date/DateSegment";

interface Props
  extends Omit<
    DateFieldStateOptions<DateValue>,
    "createCalendar" | "locale" | "defaultValue" | "label"
  > {
  label?: string;
}

const DateField: FC<Props> = ({
  autoFocus,
  defaultOpen,
  description,
  errorMessage,
  granularity,
  hideTimeZone,
  hourCycle,
  isDateUnavailable,
  isDisabled,
  isOpen,
  isReadOnly,
  isRequired,
  maxGranularity,
  maxValue,
  minValue,
  onBlur,
  onChange,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onOpenChange,
  placeholderValue,
  value,
  label,
}) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { locale } = useLocale();

  const state = useDateFieldState({
    locale,
    createCalendar,
    autoFocus,
    defaultOpen,
    description,
    errorMessage,
    granularity,
    hideTimeZone,
    hourCycle,
    isDateUnavailable,
    isDisabled,
    isOpen,
    isReadOnly,
    isRequired,
    label,
    maxGranularity,
    maxValue,
    minValue,
    onBlur,
    onChange,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
    onOpenChange,
    placeholderValue,
    value,
  });

  const { fieldProps } = useDateField(
    {
      autoFocus,
      defaultOpen,
      description,
      errorMessage,
      granularity,
      hideTimeZone,
      hourCycle,
      isDateUnavailable,
      isDisabled,
      isOpen,
      isReadOnly,
      isRequired,
      label,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onOpenChange,
      "aria-label": label,
      "aria-labelledby": label,
    },
    state,
    ref
  );

  const yearRef = useRef<HTMLDivElement | null>(null);

  const segmentsArray =
    state?.segments[0]?.type === "day"
      ? state?.segments
      : [
          state?.segments[2],
          state?.segments[1],
          state?.segments[0],
          ...state.segments.filter((segment, i) => {
            if (i === 1 || i === 2 || i === 0) {
              return null;
            } else {
              return segment;
            }
          }),
        ];

  return (
    <div
      {...fieldProps}
      aria-label={label}
      aria-labelledby={label}
      ref={ref}
      className="flex outline-none peer"
    >
      {segmentsArray?.map((segment, i) => {
        return segment?.text?.toString().startsWith("G") ? null : (
          <DateSegment
            key={i}
            segment={segment}
            state={state}
            yearRef={yearRef}
          />
        );
      })}
    </div>
  );
};

export default DateField;
