import { gql } from "@apollo/client";

export const CREATE_LEAVE_TYPE = gql`
  mutation createLeaveType(
    $name: String!
    $isSupportingDocNeeded: Boolean
    $isSpecialLeave: Boolean
    $isRestrictedHolidayLeave: Boolean
    $noOfRestrictedHolidays: Int
    $availedNoOfRestrictedHolidays: Int
    $leaveSettlementType: String
    $companyIds: [Int]
  ) {
    createLeaveType(
      name: $name
      isSupportingDocNeeded: $isSupportingDocNeeded
      isSpecialLeave: $isSpecialLeave
      isRestrictedHolidayLeave: $isRestrictedHolidayLeave
      noOfRestrictedHolidays: $noOfRestrictedHolidays
      availedNoOfRestrictedHolidays: $availedNoOfRestrictedHolidays
      leaveSettlementType: $leaveSettlementType
      companyIds: $companyIds
    ) {
      id
      name
      isSupportingDocNeeded
      isSpecialLeave
      leaveSettlementType
    }
  }
`;

export const UPDATE_LEAVE_TYPE = gql`
  mutation updateLeaveType(
    $id: Int!
    $name: String
    $isSupportingDocNeeded: Boolean
    $isSpecialLeave: Boolean
    $isRestrictedHolidayLeave: Boolean
    $noOfRestrictedHolidays: Int
    $availedNoOfRestrictedHolidays: Int
    $leaveSettlementType: String
    $companyIds: [Int]
  ) {
    updateLeaveType(
      id: $id
      name: $name
      isSupportingDocNeeded: $isSupportingDocNeeded
      isSpecialLeave: $isSpecialLeave
      isRestrictedHolidayLeave: $isRestrictedHolidayLeave
      noOfRestrictedHolidays: $noOfRestrictedHolidays
      availedNoOfRestrictedHolidays: $availedNoOfRestrictedHolidays
      leaveSettlementType: $leaveSettlementType
      companyIds: $companyIds
    ) {
      id
      name
      isSupportingDocNeeded
      isSpecialLeave
      isRestrictedHolidayLeave
      leaveSettlementType
    }
  }
`;

export const DELETE_LEAVE_TYPE = gql`
  mutation deleteLeaveType($id: Int!) {
    deleteLeaveType(id: $id)
  }
`;

export const LEAVE_CREDITS_MUTATION = gql`
  mutation createLeaveCredits(
    $leaveTypeId: Int!
    $transactionType: String!
    $userIds: [Int]
    $designationId: Int
    $teamId: Int
    $year: Int
    $noOfLeaves: Float
    $notes: String!
    $numberOfTimes: Int
    $restrictedTimePeriod: Int
    $date: String
    $companyId: Int
  ) {
    createLeaveCredits(
      leaveTypeId: $leaveTypeId
      transactionType: $transactionType
      userIds: $userIds
      designationId: $designationId
      teamId: $teamId
      noOfLeaves: $noOfLeaves
      notes: $notes
      year: $year
      numberOfTimes: $numberOfTimes
      restrictedTimePeriod: $restrictedTimePeriod
      date: $date
      companyId: $companyId
    ) {
      message
    }
  }
`;

export const WITHDRAW_APPLY_LEAVE = gql`
  mutation withdrawApplyLeave(
    $id: Int!
    $leaveRequestDates: [updateLeaveRequestDate]
    $reason: String
    $leaveRequestAttachments: [updateLeaveRequestAttachments]
  ) {
    updateApplyLeave(
      id: $id
      leaveRequestDates: $leaveRequestDates
      reason: $reason
      leaveRequestAttachments: $leaveRequestAttachments
    ) {
      id
      createdAt
      updatedAt
      reason
      declineMessage
      requestedToUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      requestedByUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      leaveRequestDates {
        id
        uuid
        leaveDate
        session
        status
        statusChangedAt
        cancelledAt
        startTime
        endTime
        declineMessage
        leaveType {
          name
          id
          uuid
          isSupportingDocNeeded
        }
        leaveRequestDateConsents {
          id
          uuid
          status
          statusChangedAt
          consentedByUser {
            id
            uuid
            employeeDetail {
              employeeId
            }
            userProfileImages {
              id
              documents {
                file
              }
              type
            }
          }
          declinedReason
        }
      }
      leaveRequestAttachments {
        id
        filePath
      }
    }
  }
`;

export const UPDATE_LEAVE_REQUEST = gql`
  mutation updateLeaveRequest(
    $leaveRequestId: Int!
    $userId: Int!
    $declineMessage: String
    $leaveRequestDate: [leaveRequestDateStatusChange]
    $isAllApprove: Boolean
    $isAllDecline: Boolean
  ) {
    updateLeaveRequest(
      leaveRequestId: $leaveRequestId
      userId: $userId
      declineMessage: $declineMessage
      leaveRequestDate: $leaveRequestDate
      isAllApprove: $isAllApprove
      isAllDecline: $isAllDecline
    ) {
      id
      createdAt
      updatedAt
      reason
      declineMessage
      requestedToUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      requestedByUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      leaveRequestDates {
        id
        uuid
        leaveDate
        session
        status
        statusChangedAt
        cancelledAt
        startTime
        endTime
        declineMessage
        leaveType {
          name
          id
          uuid
          isSupportingDocNeeded
        }
        leaveRequestDateConsents {
          id
          uuid
          status
          statusChangedAt
          consentedByUser {
            id
            uuid
            employeeDetail {
              employeeId
            }
          }
          declinedReason
        }
      }
      leaveRequestAttachments {
        id
        filePath
      }
    }
  }
`;

export const UPDATE_CONSENT_REQUEST = gql`
  mutation updateLeaveRequestConsent(
    $leaveRequestId: Int!
    $leaveRequestDate: [LeaveRequestDateConsentInput]
    $isAllAgreed: Boolean
    $isAllDisagreed: Boolean
  ) {
    updateLeaveRequestConsent(
      leaveRequestId: $leaveRequestId
      leaveRequestDate: $leaveRequestDate
      isAllAgreed: $isAllAgreed
      isAllDisagreed: $isAllDisagreed
    ) {
      id
      createdAt
      updatedAt
      reason
      declineMessage
      requestedToUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      requestedByUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      leaveRequestDates {
        id
        uuid
        leaveDate
        session
        status
        statusChangedAt
        cancelledAt
        startTime
        endTime
        declineMessage
        leaveType {
          name
          id
          uuid
          isSupportingDocNeeded
        }
        leaveRequestDateConsents {
          id
          uuid
          status
          statusChangedAt
          consentedByUser {
            id
            uuid
            employeeDetail {
              employeeId
            }
            personalInformation {
              id
              name
            }
          }
          declinedReason
        }
      }
      leaveRequestAttachments {
        id
        filePath
      }
    }
  }
`;

export const CREATE_APPLY_LEAVE = gql`
  mutation createApplyLeave(
    $leaveRequestDates: [createLeaveRequestDate]!
    $reason: String
    $leaveRequestAttachments: [createLeaveRequestAttachments]
  ) {
    createApplyLeave(
      leaveRequestDates: $leaveRequestDates
      reason: $reason
      leaveRequestAttachments: $leaveRequestAttachments
    ) {
      id
      createdAt
      updatedAt
      requestedByUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      requestedToUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      reason
      leaveRequestDates {
        id
        uuid
        leaveDate
        session
        status
        statusChangedAt
        cancelledAt
        startTime
        endTime
        leaveType {
          name
          id
          isSupportingDocNeeded
        }
        leaveRequestDateConsents {
          id
          status
          statusChangedAt
          consentedByUser {
            id
            employeeDetail {
              employeeId
            }
          }
          declinedReason
        }
      }
      leaveRequestAttachments {
        id
        filePath
      }
    }
  }
`;

export const UPDATE_APPLY_LEAVE = gql`
  mutation updateApplyLeave(
    $id: Int!
    $leaveRequestDates: [updateLeaveRequestDate]
    $reason: String
    $leaveRequestAttachments: [updateLeaveRequestAttachments]
  ) {
    updateApplyLeave(
      id: $id
      leaveRequestDates: $leaveRequestDates
      reason: $reason
      leaveRequestAttachments: $leaveRequestAttachments
    ) {
      id
      createdAt
      updatedAt
      reason
      declineMessage
      requestedToUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      requestedByUser {
        id
        personalInformation {
          name
        }
        employeeDetail {
          employeeId
        }
      }
      leaveRequestDates {
        id
        leaveDate
        session
        status
        statusChangedAt
        cancelledAt
        startTime
        endTime
        declineMessage
        leaveType {
          name
          id
          isSupportingDocNeeded
        }
        leaveRequestDateConsents {
          id
          status
          statusChangedAt
          consentedByUser {
            id
            employeeDetail {
              employeeId
            }
            userProfileImages {
              id
              documents {
                file
              }
              type
            }
          }
          declinedReason
        }
      }
      leaveRequestAttachments {
        id
        filePath
      }
    }
  }
`;

export const CREATE_HOLIDAY = gql`
  mutation createHoliday(
    $holidayDate: Int!
    $holidayMonth: Int!
    $holidayYear: Int!
    $reason: String!
    $isOptional: Boolean
    $type: String
    $companyIds: [Int]
  ) {
    createHoliday(
      holidayDate: $holidayDate
      holidayMonth: $holidayMonth
      holidayYear: $holidayYear
      reason: $reason
      isOptional: $isOptional
      type: $type
      companyIds: $companyIds
    ) {
      id
      uuid
      holidayDate
      holidayMonth
      holidayYear
      isOptional
      reason
    }
  }
`;

export const UPDATE_HOLIDAY = gql`
  mutation updateHoliday(
    $id: Int!
    $holidayDate: Int
    $holidayMonth: Int
    $holidayYear: Int
    $reason: String
    $isOptional: Boolean
    $type: String
    $companyIds: [Int]
  ) {
    updateHoliday(
      id: $id
      holidayDate: $holidayDate
      holidayMonth: $holidayMonth
      holidayYear: $holidayYear
      reason: $reason
      isOptional: $isOptional
      type: $type
      companyIds: $companyIds
    ) {
      id
      uuid
      holidayDate
      holidayMonth
      holidayYear
      isOptional
      reason
    }
  }
`;

export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($id: Int!) {
    deleteHoliday(id: $id)
  }
`;

export const REQUEST_COMPENSATION_LEAVE = gql`
  mutation requestCompensationLeave(
    $compensationLeaveInput: CompensationLeaveInput
  ) {
    requestCompensationLeave(compensationLeaveInput: $compensationLeaveInput) {
      id
      createdAt
      updatedAt
      reason
      requestedToUser {
        personalInformation {
          id
          name
        }
      }
      compensationLeaveDates {
        id
        leaveDate
        session
        status
        startTime
        endTime
        project {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_APPLY_COMPENSATION_LEAVE = gql`
  mutation updateApplyCompensationLeave(
    $updateApplyCompensationLeaveInput: UpdateApplyCompensationLeaveInput
  ) {
    updateApplyCompensationLeave(
      updateApplyCompensationLeaveInput: $updateApplyCompensationLeaveInput
    ) {
      id
    }
  }
`;

export const UPDATE_COMPENSATION_LEAVE_STATUS = gql`
  mutation updateCompensationLeaveStatus(
    $updateCompensationLeaveDateInput: UpdateCompensationLeaveDateInput
  ) {
    updateCompensationLeaveStatus(
      updateCompensationLeaveDateInput: $updateCompensationLeaveDateInput
    ) {
      id
      compensationLeaveDates {
        id
        leaveDate
        session
        declineMessage
        status
        startTime
        endTime
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECTS_FOR_COMPENSATION = gql`
  query projects($filters: ProjectFilter) {
    projects(filters: $filters) {
      dataCollection {
        id
        name
        manager {
          personalInformation {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_AUTO_APPROVAL_TIME = gql`
  mutation deleteLeaveAutoApproveTime($id: Int!) {
    deleteLeaveAutoApproveTime(id: $id)
  }
`;

export const CREATE_OR_UPDATE_LEAVE_AUTO_APPROVAL = gql`
  mutation saveLeaveAutoApprovalTime($id: Int, $type: String, $hours: Float) {
    saveLeaveAutoApprovalTime(id: $id, type: $type, hours: $hours)
  }
`;

export const GET_LEAVE_APPROVAL_TIMING_TYPE = gql`
  query leaveAutoApprovalTimingType {
    leaveAutoApprovalTimingType
  }
`;
