import { RE_DIGIT } from "global/helpers/StaticData";
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

const OtpInput: FC<Props> = ({ value, valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split(""); // Splitting the otp state value into array
    const items: Array<string> = [];

    /******** Storing the values to the array only if ther characters are number********/

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    /*************  ****************/

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target: HTMLInputElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target: HTMLInputElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value?.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    setTimeout(() => {
      setIsPasting(false);
    }, 2000);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " "; // To prevent the order changing

    const newValue =
      value.substring(0, idx) + targetValue + value.substring(idx + 1); //  "123456" (0,1 + 5 + 3,4,5,6) replacing the third value

    onChange(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    focusToNextInput(target);
  };

  const inputKeyDown = (e: React.KeyboardEvent) => {
    setIsPasting(false);
    const { key } = e;
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  const [isPasting, setIsPasting] = useState<boolean>(false);

  useEffect(() => {
    const valueItemsIndex =
      valueItems?.indexOf("") >= 0 ? +valueItems?.indexOf("") : valueLength - 1;
    if (isPasting) {
      document.getElementById(`${valueItemsIndex}`)?.focus();
    }
  }, [valueItems, valueLength, isPasting]);

  const firstInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex w-ful max-w-[360px] gap-[10px] mb-3">
      {valueItems?.map((digit, idx) => (
        <input
          key={idx}
          ref={firstInputRef}
          id={`${idx}`}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={6}
          className="text-ironside-gray font-bold text-[16px] peer border rounded-[5px] text-center border-[#ccc] px-3 h-[50px] w-full bg-transparent  placeholder-transparent focus:outline-none focus:border-cornflower-blue"
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputKeyDown}
          onFocus={inputOnFocus}
          onPaste={() => setIsPasting(true)}
          onPasteCapture={() => setIsPasting(false)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
