import { ReactComponent as BellIcon } from "global/assets/images/notification-icon.svg";
import { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { USER_NOTIFICATIONS_COLLECTION_QUERY } from "./Notification/services/queries";
import Notification from "./Notification/Pages/Notification";
import { onValue, ref } from "firebase/database";
import { database } from "firebase-config";
import { getMessaging, onMessage } from "firebase/messaging";

export interface NotificationsType {
	id: number;
	message: string;
	link: string;
	date: string;
	time: string;
	isRead: boolean;
	additionalInformation: string;
	targetUser: {
		personalInformation: {
			id: number;
			name: string;
			personalEmail: string;
		};
		userProfileImages: {
			documents: { file: string }[];
			type: "Profile" | "Cover";
		}[];
	};
	triggeredByUser: {
		userProfileImages: {
			documents: { file: string }[] | any;
			type: "Profile" | "Cover";
		}[];
		personalInformation: {
			id: number;
			name: string;
			personalEmail: string;
		};
	};
}

export interface MessagesType {
	id: number;
	message: string;
	createdAt: string;
	isRead: boolean;
	link: string;
	additionalInformation: string;
	targetUser: {
		personalInformation: {
			id: number;
			name: string;
			personalEmail: string;
		};
		userProfileImages: {
			documents: { file: string }[];
			type: "Profile" | "Cover";
		}[];
	};
	triggeredByUser: {
		userProfileImages: {
			documents: { file: string }[];
			type: "Profile" | "Cover";
		}[];
		personalInformation: {
			id: number;
			name: string;
			personalEmail: string;
		};
	};
}

const TopLinks = () => {
	const [unReadCount, setUnReadCount] = useState<number>(0);
	const starCountRef = ref(
		database,
		`${process.env.REACT_APP_NOTIFICATION_DB}` +
			(localStorage.id ||
				document.cookie
					.split("; ")
					.find((row) => row.startsWith("id="))
					?.split("=")[1]),
	);

	useEffect(() => {
		if (starCountRef) {
			onValue(starCountRef, (snapshot: any) => {
				setUnReadCount(snapshot.val()?.unRead);
			});
		}
	}, [starCountRef]);

	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [showReadMessage, setShowReadMessage] = useState<boolean>(false);

	const [
		fetchNotifications,
		{ loading, data: messageList, refetch: refetchUserNotifications },
	] = useLazyQuery(USER_NOTIFICATIONS_COLLECTION_QUERY, {
		fetchPolicy: "network-only",
	});

	const [messageId, setMessageId] = useState<string>("");

	const messaging = getMessaging();

	onMessage(messaging, (payload) => {
		setMessageId(payload?.messageId);
	});

	useEffect(() => {
		if (showNotification) {
			fetchNotifications({
				variables: {
					limit: 5,
					page: 1,
					isRead: showReadMessage ? true : false,
				},
			});
		}
	}, [
		showNotification,
		fetchNotifications,
		refetchUserNotifications,
		showReadMessage,
		messageId,
	]);

	const notifications: NotificationsType[] =
		messageList &&
		messageList?.notifications?.dataCollection?.map((message: MessagesType) => {
			return {
				id: message?.id,
				message: message?.message,
				date: message?.createdAt?.slice(0, 11),
				time: message?.createdAt?.slice(-8),
				isRead: message?.isRead,
				link: message?.link,
				targetUser: message?.targetUser,
				triggeredByUser: message?.triggeredByUser,
				additionalInformation: message?.additionalInformation,
			};
		});

	const notificationCount = unReadCount;

	let notificationRef: any = useRef();

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (!notificationRef.current.contains(event.target)) {
				setShowNotification(false);
			}
		};
		document.addEventListener("mousedown", handler);
		return () => {
			document.removeEventListener("mousedown", handler);
		};
	}, [showNotification]);

	return (
		<div className="flex items-center">
			<div className="relative" ref={notificationRef}>
				<div
					className="cursor-pointer"
					onClick={() => setShowNotification((prevState) => !prevState)}
				>
					<BellIcon className="w-10 h-8" />

					{notificationCount > 0 && (
						<div className="absolute -top-1 right-0 text-white w-5 h-5 rounded-full bg-brink-pink text-[11px] grid place-content-center">
							{notificationCount > 99 ? `99+` : notificationCount}
						</div>
					)}
				</div>

				{showNotification && (
					<Notification
						loading={loading}
						notifications={notifications}
						setShowNotification={setShowNotification}
						refetchUserNotifications={refetchUserNotifications}
						showReadMessage={showReadMessage}
						setShowReadMessage={setShowReadMessage}
					/>
				)}
			</div>
		</div>
	);
};
export default TopLinks;
