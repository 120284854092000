import { gql } from "@apollo/client";

export const ALL_PROJECT_WORK_LOG_FRAGMENT = gql`
  fragment allProjectWorkLogFields on ProjectWorkLog {
    id
    createdAt
    startTime
    endTime
    durationInSeconds
    description
    projectTask {
      id
      title
      project {
        id
        name
      }
    }
  }
`;
