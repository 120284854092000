import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import { Filled, Modal, Outlined } from "components/forms";
import Input from "components/forms/UpdatedForm/Input";
import Select from "components/forms/UpdatedForm/Select";

import { toastNotify } from "global/helpers/Cache";
import { ISetStateType } from "global/types/type";

import {
  CREATE_OR_UPDATE_LEAVE_AUTO_APPROVAL,
  GET_LEAVE_APPROVAL_TIMING_TYPE,
} from "modules/Leave/services/mutations";
import { ILeaveAutoApproval } from "modules/Leave/types/leave";

export interface ILeaveAutoApprovalTypeForm {
  type: string;
  hours: number;
}

interface IProps {
  editAutoApprovalData: ILeaveAutoApproval | null;
  setShowModal: ISetStateType<boolean>;
  refetch: Function;
  createOrUpdateAccess: boolean;
}

const EditLeavetype: FC<IProps> = ({
  editAutoApprovalData,
  setShowModal,
  refetch,
  createOrUpdateAccess,
}) => {
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ILeaveAutoApprovalTypeForm>();

  const [
    saveLeaveAutoApprovalTime,
    { loading: saveLeaveAutoApprovalTimeLoding },
  ] = useMutation(CREATE_OR_UPDATE_LEAVE_AUTO_APPROVAL);

  const {
    data: getLeaveAutoApprovalTimingType,
    loading: leaveAutoApprovalTimingTypeLoading,
  } = useQuery(GET_LEAVE_APPROVAL_TIMING_TYPE);

  const leaveApprovalTypes =
    getLeaveAutoApprovalTimingType?.leaveAutoApprovalTimingType || [];

  const createOrUpdateLeaveAutoApproval: SubmitHandler<
    ILeaveAutoApprovalTypeForm
  > = ({ type, hours }) => {
    if (!saveLeaveAutoApprovalTimeLoding) {
      saveLeaveAutoApprovalTime({
        variables: {
          id: editAutoApprovalData?.id || undefined,
          type,
          hours,
        },
      })
        .then((response) => {
          setShowModal(false);
          refetch();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications are successfully added.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Auto Approval</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(createOrUpdateLeaveAutoApproval)}>
            <div className="form-div pr-[18px] lg:pr-[45px]">
              <Select
                control={control}
                errors={errors}
                label="Type *"
                name="type"
                options={leaveApprovalTypes}
                defaultValue={editAutoApprovalData?.type}
                setValue={setValue}
                loading={leaveAutoApprovalTimingTypeLoading}
                required
              />
              <Input
                register={register}
                errors={errors}
                label="Hours *"
                name="hours"
                required
                defaultValue={editAutoApprovalData?.hours || ""}
                setValue={setValue}
                inputType="number"
                maxLength={7}
              />
            </div>
            <div className="w-full border-b" />
            <div className="button-div px-[18px] lg:px-[45px]">
              <Outlined
                onClick={() => {
                  setShowModal(false);
                }}
                buttonName="Cancel"
              />
              <Filled
                loading={saveLeaveAutoApprovalTimeLoding}
                buttonName={editAutoApprovalData?.id ? "Update" : "Add"}
                disabled={
                  saveLeaveAutoApprovalTimeLoding || !createOrUpdateAccess
                }
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default EditLeavetype;
