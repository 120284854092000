interface Props {
  buttonName: string;
  isConfirmModal: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
}

const RoundedFilled: React.FC<Props> = ({
  buttonName,
  isConfirmModal,
  onClick,
  loading,
}) => {
  return (
    <button
      type={"button"}
      className={`${
        isConfirmModal ? "w-[97px] h-[32px]" : "w-[152px] h-[42px]"
      } text-[14px] bg-cornflower-blue text-sm text-white hover:bg-bright-blue/80 leading-[20px] rounded-[16px] border border-cornflower-blue hover:shadow-lg`}
      onClick={onClick}
    >
      {loading ? <div className="btn-spinner" /> : buttonName}
    </button>
  );
};

export default RoundedFilled;
