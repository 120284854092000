import { FC, Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import {
  Select,
  Number,
  Filled,
  TextArea,
  CalendarField,
} from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import Loading from "components/Loader/Loading";

import { currentDate } from "global/helpers/StaticData";
import { toastNotify } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";

import { LEAVE_CREDITS_MUTATION } from "modules/Leave/services/mutations";
import {
  GET_ALL_EMPLOYEES_DROP_DOWN,
  GET_ALL_TEAMS,
  GET_DESIGNATIONS,
  GET_LEAVE_TYPES,
} from "modules/Employee/services/queries";
import CompanyField from "modules/Company/helpers/CompanyField";

interface IProps {
  allowedResources: string[];
}

const Credit: FC<IProps> = ({ allowedResources }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [inProgress, setInProgress] = useState(false);

  const createAccess = allowedResources?.includes("CreateLeaveCredits");

  const { data: employeeDesignations, loading: designationLoader } = useQuery(
    GET_DESIGNATIONS,
    { fetchPolicy: "cache-and-network" }
  );

  const { data: allEmployees, loading: allEmployeesLoader } = useQuery(
    GET_ALL_EMPLOYEES_DROP_DOWN,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: employeeTeams, loading: teamsLoader } = useQuery(
    GET_ALL_TEAMS,
    { fetchPolicy: "cache-and-network" }
  );

  const { data: leaveTypes, loading: leaveTypesLoader } = useQuery(
    GET_LEAVE_TYPES,
    { fetchPolicy: "cache-and-network" }
  );

  const [createLeaveCredits] = useMutation(LEAVE_CREDITS_MUTATION);

  useEffect(() => {
    if (
      !designationLoader &&
      !allEmployeesLoader &&
      !teamsLoader &&
      !leaveTypesLoader
    ) {
      setLoading(false);
    }
  }, [
    allEmployeesLoader,
    designationLoader,
    leaveTypesLoader,
    teamsLoader,
    loading,
  ]);

  const designationLists =
    (employeeDesignations &&
      employeeDesignations?.employeeDesignations?.dataCollection) ||
    [];

  const teamLists = employeeTeams?.employeeTeams?.dataCollection || [];

  const employeeLists =
    allEmployees?.getAllEmployeesForDropDown?.map(
      (employee: { personalInformation: { id: number; name: string } }) =>
        employee?.personalInformation
    ) || [];

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const to = watch("creditDebitTo");
  const watchLeaveType = watch("leaveType");
  const [leaveDate, setLeaveDate] = useState<any>();

  const watchTransactionType = watch("transactionType");
  const watchUserIDs = watch("userIds");

  const leaveOptions =
    (leaveTypes && leaveTypes?.leaveTypes?.dataCollection) || [];

  const specialLeaves =
    leaveTypes?.leaveTypes?.dataCollection
      ?.filter((leave: { isSpecialLeave: boolean }) => leave?.isSpecialLeave)
      ?.map((leave: { id: number }) => leave?.id) || [];

  const restrictedLeaves =
    leaveTypes?.leaveTypes?.dataCollection
      ?.filter(
        (leave: { isRestrictedHolidayLeave: boolean }) =>
          leave?.isRestrictedHolidayLeave
      )
      ?.map((leave: { id: number }) => leave?.id) || [];

  const isSpecialLeave = specialLeaves?.includes(+watchLeaveType);
  const isRestrictedLeave = restrictedLeaves?.includes(+watchLeaveType);

  useEffect(() => {
    const chosenRestrictedLeave =
      leaveTypes?.leaveTypes?.dataCollection?.filter(
        (leave: { id: number; isRestrictedHolidayLeave: boolean }) =>
          leave?.isRestrictedHolidayLeave && leave?.id === +watchLeaveType
      ) || [];

    if (isRestrictedLeave && chosenRestrictedLeave?.length > 0) {
      setValue(
        "noOfLeaves",
        chosenRestrictedLeave[0]?.availedNoOfRestrictedHolidays
      );
    } else {
      setValue("noOfLeaves", "");
    }
  }, [
    isRestrictedLeave,
    watchLeaveType,
    leaveTypes?.leaveTypes?.dataCollection,
    setValue,
  ]);

  const createHandler = (data: any) => {
    if (!inProgress) {
      createLeaveCredits({
        variables: {
          leaveTypeId: +data?.leaveType,
          transactionType: data.transactionType,
          designationId:
            to === "Designation" ? data?.designationId?.id : undefined,
          userIds:
            to === "Specific Employees"
              ? data?.userIds?.map((user: { id: number }) => user?.id)
              : undefined,
          teamId: to === "Team" ? data?.teamId?.id : undefined,
          year: +data.leaveYear ? +data.leaveYear : undefined,
          maximumAllowedLeaves: +data?.maxAllowedLeaves
            ? +data?.maxAllowedLeaves
            : undefined,
          numberOfTimes: +data?.noOfTimesAllowed
            ? +data?.noOfTimesAllowed
            : undefined,
          restrictedTimePeriod: +data?.restrictedTimePeriod
            ? +data?.restrictedTimePeriod
            : undefined,
          noOfLeaves: data.noOfLeaves ? parseFloat(data.noOfLeaves) : undefined,
          notes: data.notes || "",
          date: leaveDate ? ViewDateFormat(leaveDate) : undefined,
          companyId: data?.company?.id,
        },
      })
        .then((response) => {
          setInProgress(false);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: response.data.createLeaveCredits.message,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const yearData: string[] = [];
  const tempFullYear = currentDate?.getFullYear();

  for (let init = tempFullYear; init <= tempFullYear + 1; init++) {
    yearData.push(init?.toString());
  }

  return (
    <Fragment>
      {loading ? (
        <Loading className="min-h-[500px] sm:min-h-[700px]" />
      ) : (
        <div className="px-4 md:px-0">
          <form onSubmit={handleSubmit(createHandler)}>
            <div className="py-5 grid place-content-center sm:place-content-start gap-2">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                  Leave Type
                </p>
                <div className="min-w-[220px] max-w-[270px]">
                  <SelectWithId
                    errors={errors}
                    label="Leave Type *"
                    name="leaveType"
                    options={leaveOptions}
                    register={register}
                    required={true}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                  Transaction Type
                </p>
                <div className="min-w-[220px] max-w-[270px]">
                  <Select
                    register={register}
                    errors={errors}
                    label="Transaction Type *"
                    options={["Credit", "Debit"]}
                    name="transactionType"
                    required={true}
                  />
                </div>
              </div>
              {!isSpecialLeave ? (
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                  <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                    Year
                  </p>
                  <div className="min-w-[220px] max-w-[270px]">
                    <Select
                      register={register}
                      errors={errors}
                      label="Year *"
                      options={yearData}
                      name="leaveYear"
                      required={true}
                    />
                  </div>
                </div>
              ) : null}
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                  Company
                </p>
                <div className="min-w-[220px] max-w-[270px]">
                  <CompanyField
                    control={control}
                    name="company"
                    label="Company"
                    className="bg-white"
                  />
                </div>
              </div>
              {isSpecialLeave ? (
                <div>
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                    <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                      No Of Times Allowed
                    </p>
                    <div className="min-w-[220px] max-w-[270px]">
                      <Number
                        register={register}
                        errors={errors}
                        label="No Of Times Allowed"
                        name="noOfTimesAllowed"
                        maximumLength={1}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                    <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                      Restricted Time Period
                    </p>
                    <div className="min-w-[220px] max-w-[270px]">
                      <Number
                        register={register}
                        errors={errors}
                        label="Restricted Time Period"
                        name="restrictedTimePeriod"
                        maximumLength={3}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                  Transaction To
                </p>
                <div
                  className={`grid grid-cols-1 gap-4 md:grid-cols-[270px_auto]`}
                >
                  <div className="min-w-[220px] max-w-[270px]">
                    <Select
                      register={register}
                      name="creditDebitTo"
                      errors={errors}
                      label="Transaction To *"
                      options={["Designation", "Team", "Specific Employees"]}
                      required={true}
                    />
                  </div>
                  <div
                    className={`${
                      to === "Designation" ||
                      to === "Team" ||
                      to === "Specific Employees"
                        ? "block"
                        : "hidden"
                    }`}
                  >
                    {to === "Designation" ? (
                      <div className="min-w-[220px] max-w-[268px] max-h-[50px]">
                        <AutoComplete
                          setValue={setValue}
                          options={designationLists ? designationLists : []}
                          control={control}
                          label={"Designation"}
                          name={"designationId"}
                          errors={errors}
                          classForInput="min-h-[50px]"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "Team" ? (
                      <div className="min-w-[220px] max-w-[300px]">
                        <AutoComplete
                          setValue={setValue}
                          options={teamLists ? teamLists : []}
                          control={control}
                          label={"Team"}
                          name={"teamId"}
                          errors={errors}
                          classForInput="min-h-[50px]"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "Specific Employees" ? (
                      <div className="min-w-[220px] max-w-[300px]">
                        <AutoComplete
                          setValue={setValue}
                          options={employeeLists ? employeeLists : []}
                          control={control}
                          label={"Employees"}
                          name={"userIds"}
                          errors={errors}
                          multiple
                          classForInput="min-h-[50px]"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {to === "Specific Employees" &&
                watchUserIDs?.length > 0 &&
                watchTransactionType === "Debit" && (
                  <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                    <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                      Leave Date
                    </p>
                    <div className="min-w-[220px] max-w-[270px]">
                      <CalendarField
                        register={register}
                        errors={errors}
                        name="leaveDate"
                        date={leaveDate}
                        setDate={setLeaveDate}
                        label="Leave Date"
                        maxDate={
                          new Date(new Date()?.getFullYear() + 2, 11, 31)
                        }
                        minDate={
                          new Date(new Date()?.getFullYear() - 1, 11, 31)
                        }
                      />
                    </div>
                  </div>
                )}
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-[14px]">
                  No Of Leaves
                </p>
                <div className="min-w-[220px] max-w-[270px]">
                  <Number
                    register={register}
                    errors={errors}
                    label="No Of Leaves *"
                    name="noOfLeaves"
                    required={true}
                    maximumLength={5}
                    isSpecialCharacterneeded={true}
                    disabled={isRestrictedLeave}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="hidden sm:block text-sm text-ironside-gray mt-12">
                  Notes
                </p>
                <div className="min-w-[220px] max-w-[270px]">
                  <TextArea
                    register={register}
                    name={"notes"}
                    errors={errors}
                    label={"Notes"}
                  />
                </div>
              </div>
              {createAccess ? (
                <div className="flex justify-center items-center sm:block sm:ml-[156px]">
                  <Filled
                    buttonName={
                      inProgress ? <div className="btn-spinner" /> : "Submit"
                    }
                  />
                </div>
              ) : null}
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export default Credit;
