import { useEffect } from "react";

import { TTextAreaProps } from "components/forms/UpdatedForm/types";

const TextArea = <TForm extends Record<string, string | number | any>>({
  errors,
  className = "",
  name,
  register,
  defaultValue,
  maxLength,
  minLength,
  required,
  onChange,
  pattern,
  disabled,
  label,
  hideLabel,
  hideError,
  classNameForError = "",
  shouldUnregister = true,
  setValue,
}: TTextAreaProps<TForm>) => {
  const errorMessage = errors
    ? typeof errors === "string"
      ? errors
      : errors[name]?.message?.toString()
    : undefined;

  useEffect(() => {
    if (defaultValue && setValue) setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <div className={`${className} w-full relative`}>
      <textarea
        className={`w-full peer min-h-[45px] max-h-full rounded shadow text-sm focus:outline-none p-3 border resize-none ${
          errorMessage ? "border-valentine-red" : "focus:border-cornflower-blue"
        } ${
          disabled ? "text-warm-gray/60" : "text-ironside-gray"
        } placeholder-transparent`}
        id={name}
        placeholder="Enter something..."
        maxLength={maxLength || undefined}
        minLength={minLength || undefined}
        rows={5}
        cols={5}
        {...register(name, {
          required:
            typeof required === "boolean" && required === true
              ? "This is required field."
              : required
              ? required
              : false,
          onChange: onChange,
          shouldUnregister,
          maxLength: maxLength
            ? {
                value: maxLength,
                message: `Maximum characters should be ${maxLength}.`,
              }
            : undefined,
          minLength: minLength
            ? {
                value: minLength,
                message: `Minimum characters should be ${minLength}.`,
              }
            : undefined,
          pattern,
          disabled: disabled ? true : false,
        })}
      />
      {hideLabel ? null : (
        <label
          htmlFor={name}
          className={`absolute whitespace-nowrap peer-placeholder-shown:top-3.5 bg-white peer-focus:w-min peer-placeholder-shown:w-[93%] peer-placeholder-shown:pb-1 placeholder:pr-4 pointer-events-none cursor-text peer-placeholder-shown:text-sm peer-focus:text-xxs  peer-focus:-top-1.5 text-xxs -top-1.5 transition-all duration-100 ease-in left-3  px-1 ${
            errorMessage
              ? "text-valentine-red peer-focus:text-valentine-red"
              : "text-ironside-gray/70 peer-focus:text-cornflower-blue"
          } ${disabled ? "cursor-not-allowed text-warm-gray/40" : ""}`}
        >
          {label}
        </label>
      )}
      {hideError ? null : (
        <div className={`w-[inherit] h-min ${classNameForError}`}>
          <span
            className={`text-valentine-red text-xs ${
              errorMessage ? "visible" : "invisible"
            }`}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
    </div>
  );
};

export default TextArea;
