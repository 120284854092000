import { gql } from "@apollo/client";

import {
	PERSONAL_INFORMATION_FRAGMENT,
	BANK_ACCOUNT_FRAGMENT,
	ID_PROOF_FRAGMENT,
	ADDRESS_FRAGMENT,
	EMERGENCY_CONTACT_FRAGMENT,
	PROFILE_MODERATION_FRAGMENT,
	ACADEMIC_FRAGMENT,
	SKILL_FRAGMENT,
	FAMILY_MEMBER_FRAGMENT,
	WORK_INFORMATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	PROFILE_IMAGE_FRAGMENT,
} from "modules/Employee/services/fragments";

export const GET_USER_DETAILS = gql`
  query getUserDetails($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        role {
          id
          roleName
          isSuperAdmin
        }
        personalInformation {
          id
          name
          personalEmail
          isdCode
          mobile
        }
        employeeDetail {
          id
          employeeDesignation
        }
        userProfileImages {
          id
          documents {
            file
          }
          type
        }
        isTeamLead
        isProjectManager
        canApproveCompensationLeave
        userType
      }
    }
  }
`;

export const GET_USER_PROFILE_IMAGE = gql`
  query getUserDetailsWithImage($id: Int) {
    users(id: $id) {
      dataCollection {
        personalInformation {
          id
          name
        }
        employeeDetail {
          officialEmail
          employeeDesignation
        }
        userProfileImages {
          documents {
            file
          }
          type
        }
      }
    }
  }
`;

export const GET_USER_PROFILES_WITH_PROFILE_PICTURES = gql`
  query getUserProfiles(
    $id: Int
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    users(id: $id) {
      dataCollection {
        personalInformation {
          id
          name
        }
        userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
          documents {
            file
          }
          type
        }
      }
    }
  }
`;

export const GET_PERSONAL_INFORMATION = gql`
  ${PERSONAL_INFORMATION_FRAGMENT}

  query getPersonalInformation($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        personalInformation {
          ...PersonalInformationFields
        }
      }
    }
  }
`;

export const GET_BANK_INFORMATION = gql`
  ${BANK_ACCOUNT_FRAGMENT}

  query getBankInformation($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userBankAccount {
          ...BankAccountFields
        }
      }
    }
  }
`;

export const GET_ID_PROOFS = gql`
  ${ID_PROOF_FRAGMENT}

  query getIDProofs($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userIdProofs {
          ...IdProofFields
        }
      }
    }
  }
`;

export const GET_CONTACT_ADDRESSES = gql`
  ${ADDRESS_FRAGMENT}

  query getContactAddresses($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userAddresses {
          ...addressFields
        }
      }
    }
  }
`;

export const GET_EMERGENCY_CONTACTS = gql`
  ${EMERGENCY_CONTACT_FRAGMENT}

  query getEmergencyContacts($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userEmergencyContacts {
          ...emergencyContactFields
        }
      }
    }
  }
`;

export const GET_ACADEMIC_DETAILS = gql`
  ${ACADEMIC_FRAGMENT}

  query getAcademicDetails($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userAcademicDetails {
          ...academicDetailFields
        }
      }
    }
  }
`;

export const GET_SKILLS_DETAILS = gql`
  ${SKILL_FRAGMENT}

  query getSkillsDetails($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userSkills {
          ...skillFields
        }
      }
    }
  }
`;

export const GET_FAMILY_DETAILS = gql`
  ${FAMILY_MEMBER_FRAGMENT}

  query familyDetails($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userFamilyMembers {
          ...familyMemberFields
        }
      }
    }
  }
`;

export const GET_WORK_INFORMATION = gql`
  ${WORK_INFORMATION_FRAGMENT}

  query getWorkInformation($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        employeeDetail {
          ...workInformationFields
        }
      }
    }
  }
`;

export const GET_WORK_EXPERIENCE = gql`
  ${WORK_EXPERIENCE_FRAGMENT}

  query getWorkExperience($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        userWorkExperiences {
          ...workExperienceFields
        }
      }
    }
  }
`;

export const GET_PROFILE_HEADER_DETAILS = gql`
  ${PROFILE_IMAGE_FRAGMENT}

  query getProfileHeaderDetails($id: Int, $isMyProfile: Boolean) {
    users(id: $id, isMyProfile: $isMyProfile) {
      dataCollection {
        id
        personalInformation {
          id
          name
          personalEmail
          isdCode
          mobile
        }
        employeeDetail {
          id
          officialEmail
          workLocation
          employeeDesignation
        }
        userProfileImages {
          ...profileImageFields
        }
        progressBar
      }
    }
  }
`;

export const GET_PROFILE_MODERATION = gql`
  ${PROFILE_MODERATION_FRAGMENT}

  query getProfileModerations($ids: [Int]) {
    userProfileModerations(ids: $ids) {
      dataCollection {
        ...moderationFields
      }
    }
  }
`;

export const GET_PERSONAL_INFORMATION_DROPDOWN = gql`
  query getPersonalInformationDropdown {
    getUserGenders
    getBloodGroupOptions
    getMaritalStatusOptions
  }
`;

export const SKILLS = gql`
  query skills {
    skills {
      id
      name
      type
    }
  }
`;

export const BANK_NAMES = gql`
  query getBankNames {
    getBankNames
  }
`;

export const EMERGENCY_CONTACT_RELATIONSHIP = gql`
  query getEmergencyContactRelationships {
    getEmergencyContactRelationships
  }
`;

export const GET_DESIGNATIONS = gql`
  query getDesignations {
    employeeDesignations {
      dataCollection {
        id
        name
      }
    }
  }
`;

export const COUNTRY_STATES = gql`
  query countries {
    countries {
      id
      name
      states {
        id
        name
      }
    }
  }
`;

export const SOURCE_OF_HIRE = gql`
  query getAvailableSourceOfHiring {
    getAvailableSourceOfHiring
  }
`;

export const FAMILY_MEMBERS_RELATIONSHIPS = gql`
  query getFamilyMembersRelationships {
    getFamilyMembersRelationships
  }
`;

export const USER_PROFILE_MODERATIONS = gql`
  query userProfileModerations(
    $ids: [Int]
    $limit: Int
    $page: Int
    $status: String
    $search: String
  ) {
    userProfileModerations(
      ids: $ids
      limit: $limit
      page: $page
      status: $status
      search: $search
    ) {
      dataCollection {
        id
        uuid
        updatedAt
        section
        field
        previousValue
        updatedValue
        status
        declineReason
        action
        removedEntry
        moderatedByUser {
          id
          personalInformation {
            id
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeDesignation
          }
        }
      }
      totalNumberOfItems
    }
  }
`;

export const ROLES = gql`
  query roles {
    roles {
      dataCollection {
        id
        name: roleName
        description
      }
      totalNumberOfItems
    }
  }
`;

export const GET_WORK_LOCATIONS = gql`
  query getWorkLocations {
    workLocations {
      dataCollection {
        name
      }
    }
  }
`;

export const GET_LEAVE_TYPES = gql`
  query getLeaveTypes {
    leaveTypes {
      dataCollection {
        id
        name
        isSpecialLeave
        isRestrictedHolidayLeave
        availedNoOfRestrictedHolidays
        availedNoOfRestrictedHolidays
      }
    }
  }
`;

export const GET_ALL_EMPLOYEES = gql`
  query getAllEmployees(
    $limit: Int
    $page: Int
    $search: String
    $status: String
  ) {
    getAllEmployees(
      limit: $limit
      page: $page
      search: $search
      status: $status
    ) {
      dataCollection {
        id
        personalInformation {
          id
          name
          personalEmail
          actualDateOfBirth
          officialDateOfBirth
          gender
        }
        employeeDetail {
          id
          employeeId
          officialEmail
          employeeDesignation
        }
        role {
          id
          roleName
        }
      }
      totalNumberOfItems
    }
  }
`;

export const GET_ALL_TEAMS = gql`
  query getAllTeams {
    employeeTeams {
      dataCollection {
        id
        name
      }
    }
  }
`;

export const GET_ALL_EMPLOYEES_DROP_DOWN = gql`
  query getAllEmployeesForDropDown {
    getAllEmployeesForDropDown {
      personalInformation {
        id
        name
      }
    }
  }
`;

export const GET_ALL_EMPLOYEES_DROP_DOWN_WITH_USER_TYPE = gql`
  query getAllEmployeesForDropDownWithUserType {
    getAllEmployeesForDropDown {
      personalInformation {
        id
        name
      }
      userType
    }
  }
`;
