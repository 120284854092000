import useAllowedResources from "global/hooks/useAllowedResources";

import PersonalInformation from "modules/Employee/Pages/Profile/Personal/PersonalInformation/PersonalInformation";
import BankInformation from "modules/Employee/Pages/Profile/Personal/BankInformation/BankInformation";
import IdProof from "modules/Employee/Pages/Profile/Personal/IdProof/IdProof";

const Personal = () => {
  const allowedResources = useAllowedResources();

  return (
    <div className="relative">
      <div className="md:grid md:grid-cols-2 gap-x-3 lg:gap-x-5">
        {allowedResources?.includes("ReadEmployeePersonalInformation") && (
          <PersonalInformation />
        )}

        {allowedResources?.includes("ReadEmployeeBankInformation") && (
          <BankInformation />
        )}

        {allowedResources?.includes("ReadEmployeeIdProof") && <IdProof />}
      </div>
    </div>
  );
};
export default Personal;
