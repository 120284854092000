import { Fragment } from "react";
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { TFilterOption } from "components/Filter/types";
import FilterFormBoolean from "components/Filter/FilterForm/FilterFormBoolean";
import FilterFormDate from "components/Filter/FilterForm/FilterFormDate";
import FilterFormNumber from "components/Filter/FilterForm/FilterFormNumber";
import FilterFormString from "components/Filter/FilterForm/FilterFormString";

interface IProps<TFilterForm extends FieldValues> {
  selectedTab: TFilterOption<TFilterForm>[0];
  control: Control<TFilterForm>;
  register: UseFormRegister<TFilterForm>;
  setValue: UseFormSetValue<TFilterForm>;
}

const FilterForm = <TFilterForm extends Record<string, any>>({
  selectedTab,
  control,
  register,
  setValue,
}: IProps<TFilterForm>) => {
  return (
    <Fragment>
      {selectedTab?.type === "boolean" && (
        <FilterFormBoolean
          control={control}
          name={selectedTab?.id}
          defaultValue={selectedTab?.defaultValue}
        />
      )}
      {selectedTab?.type === "date" && (
        <FilterFormDate
          dateBetween={selectedTab?.dateBetween}
          name={selectedTab?.id}
          register={register}
          dateBetweenFromName={
            selectedTab?.dateBetween && selectedTab?.dateBetweenFromName
          }
          dateBetweenToName={
            selectedTab?.dateBetween && selectedTab?.dateBetweenToName
          }
          setValue={setValue}
        />
      )}
      {selectedTab?.type === "number" && (
        <FilterFormNumber
          numberBetween={selectedTab?.numberBetween}
          max={selectedTab?.numberBetween && selectedTab?.max}
          min={selectedTab?.numberBetween && selectedTab?.min}
          name={selectedTab?.id}
          register={register}
          numberBetweenFromName={
            selectedTab?.numberBetween && selectedTab?.numberBetweenFromName
          }
          numberBetweenToName={
            selectedTab?.numberBetween && selectedTab?.numberBetweenToName
          }
          setValue={setValue}
        />
      )}
      {selectedTab?.type === "string" && (
        <FilterFormString
          options={selectedTab?.options}
          multiple={selectedTab?.multiple}
          control={control}
          label={selectedTab?.name}
          name={selectedTab?.id}
          loading={selectedTab?.loading}
          setValue={setValue}
        />
      )}
    </Fragment>
  );
};

export default FilterForm;
