import { FC, Fragment, useState } from "react";
import { useMutation } from "@apollo/client";

import DataCard from "components/App/Card/DataCard";
import ConfirmModal from "components/forms/Modal/ConfirmModal";

import { IResourceAccess } from "global/types/acl";
import { toastNotify } from "global/helpers/Cache";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import EditAddress from "modules/Employee/Pages/Profile/Contact/Address/EditAddress";
import { DELETE_ADDRESS } from "modules/Employee/services/mutations";
import { makeDataModelArray } from "modules/Employee/helpers/make-data-model-array";
import { IAddress } from "modules/Employee/types/contacts";
import { GET_CONTACT_ADDRESSES } from "modules/Employee/services/queries";

import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  resourceAccess: IResourceAccess;
  loading: boolean;
  addresses: IAddress[];
  contact: IAddress | null;
  setContact: Function;
  showModal: boolean;
  setShowModal: Function;
  refetchContatctAddresses: Function;
}

const Address: FC<IProps> = ({
  resourceAccess,
  loading,
  addresses,
  contact,
  setContact,
  showModal,
  setShowModal,
  refetchContatctAddresses,
}) => {
  const [deleteUserAddress] = useMutation(DELETE_ADDRESS);
  const isUnderModeration = useIsUnderModeration();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const removeAddressModelItems: string[] = [
    "id",
    "type",
    "__typename",
    "moderation",
    "order",
  ];

  const addressTypes = contact?.id
    ? ["Permanent", "Current", "Postal"]
    : ["Permanent", "Current", "Postal"]
        .map((addressType) => {
          const temp = addresses?.find(
            (address) => address?.type === addressType
          );
          return temp ? "" : addressType;
        })
        ?.filter((addressType) => addressType !== "");

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Address")
    : deleteSuccessMessageWithAwaiting("Address");

  const deleteHandler = () => {
    if (contact?.id) {
      deleteUserAddress({
        variables: {
          id: contact?.id,
        },
        update: (cache) => {
          const exisitingUserDetail: any = cache.readQuery({
            query: GET_CONTACT_ADDRESSES,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });
          const updatedAddresses =
            exisitingUserDetail?.users?.dataCollection[0]?.userAddresses?.filter(
              (address: IAddress) => +address?.id !== +contact.id
            );

          cache.writeQuery({
            query: GET_CONTACT_ADDRESSES,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exisitingUserDetail?.users?.dataCollection[0],
                    userAddresses: updatedAddresses,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          setShowConfirmModal(false);
          toastNotify(deleteActionSuccessMessage);
          refetchContatctAddresses();
        })
        .catch((error) => {
          setShowConfirmModal(false);
          toastNotify(errorMessageNotify(error));
        });
    }
  };

  const confirmModalCloseHandler = () => {
    setShowConfirmModal(false);
  };

  return (
    <Fragment>
      {addresses?.map((address) => {
        return (
          <DataCard
            key={address?.id}
            loading={loading}
            showEditDelete={
              resourceAccess?.canUpdate || resourceAccess?.canDelete
            }
            setShowModal={setShowModal}
            title={`${address?.type} Address`}
            dataModel={makeDataModelArray(address, removeAddressModelItems)}
            addOrEditHandler={() => {
              setContact(address);
            }}
            onEdit={() => {
              setShowModal(true);
            }}
            showModal={showConfirmModal || showModal}
            onDelete={() => {
              setShowConfirmModal(true);
            }}
            refetchQuery={refetchContatctAddresses}
          />
        );
      })}

      {showModal && (
        <EditAddress
          setShowModal={setShowModal}
          title={contact ? "Edit Address" : "Add Address"}
          contact={contact}
          setContact={setContact}
          addressTypes={addressTypes || []}
          refetchContatctAddresses={refetchContatctAddresses}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          header={`${contact?.type} Address`}
          onCancel={confirmModalCloseHandler}
          onExecute={deleteHandler}
          onXIcon={confirmModalCloseHandler}
        />
      )}
    </Fragment>
  );
};

export default Address;
