interface Props {
  className?: string;
  buttonName: string;
  onClick?: () => void;
}

const UnderReviewBtn: React.FC<Props> = ({
  className,
  buttonName,
  onClick,
}) => {
  return (
    <button
      className={`${className} w-[100px] h-[25px] rounded-full border border-[#E5E5E5] hover:border-cornflower-blue text-ironside-gray text-[12px] hover:text-cornflower-blue`}
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default UnderReviewBtn;
