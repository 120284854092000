import { FC } from "react";
import { Control } from "react-hook-form";

import DataCard from "components/App/UpdatedAgryApp/DataCard/DataCard";
import PaginationUpdated from "components/Pagination/UpdatedPaginationAgry/PaginationUpdated";
import { IDataModal } from "components/App/UpdatedAgryApp/DataCard/types/data-card";

import { formatDate } from "global/helpers/date-methods";
import { FilterFormType } from "global/types/type";

import { Company, CompanyEdge, CompanyFilterForm } from "modules/Company/types";

interface Props {
  companyEdges: CompanyEdge[];
  canUpdate: boolean;
  canDelete: boolean;
  onEdit: (type: "create" | "delete", company?: Company | null) => void;
  cardOrTableClickHandler: (companyId: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  control: Control<FilterFormType<CompanyFilterForm>, any>;
  totalCount: number;
}

const CompanyCard: FC<Props> = ({
  companyEdges,
  canDelete,
  canUpdate,
  onEdit,
  cardOrTableClickHandler,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  nextDisabled,
  prevDisabled,
  control,
  totalCount,
}) => {
  const deleteClickHandler: (edge: CompanyEdge) => {
    deleteAccess: boolean;
    onDeleteIconClick?: (() => void) | undefined;
  } = (edge) => {
    return {
      deleteAccess: canDelete,
      onDeleteIconClick: () => {
        if (canDelete) {
          onEdit("delete", edge?.node || undefined);
        }
      },
    };
  };

  const getDataModal = (company: Company): IDataModal[] => {
    const {
      name,
      companyType,
      status,
      openingTime,
      closingTime,
      companyOwners,
    } = company;
    return [
      {
        label: "Name",
        value: [
          {
            content: name,
          },
        ],
      },
      {
        label: "Type",
        value: [
          {
            content: companyType,
          },
        ],
      },
      {
        label: "Status",
        value: [
          {
            content: status,
          },
        ],
      },
      {
        label: "Opening time",
        value: [
          {
            content: openingTime ? formatDate(openingTime) : "",
          },
        ],
      },
      {
        label: "Closing time",
        value: [
          {
            content: closingTime ? formatDate(closingTime) : "",
          },
        ],
      },
      {
        label: "Owners",
        value: [
          {
            content:
              companyOwners && companyOwners?.length > 0
                ? companyOwners
                    ?.map((owner) => owner?.personalInformation?.name)
                    ?.join(", ")
                : "-",
          },
        ],
      },
    ];
  };

  return (
    <div className="md:hidden mx-auto mt-6">
      <div className="min-h-[80vh] space-y-3">
        {companyEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <DataCard
                key={index}
                cardClass="group border"
                dataModal={getDataModal(edge?.node)}
                classNameForRow={`grid grid-cols-[110px_auto] items-center gap-2 ${
                  canUpdate ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                cardClick={() => {
                  if (edge?.node?.id) {
                    cardOrTableClickHandler(edge?.node?.id);
                  }
                }}
                onDeleteIcon={deleteClickHandler(edge)}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <PaginationUpdated
        onPageSizeChange={onPageSizeChange}
        nextDisabled={nextDisabled}
        onNextPageHandler={onNextPageHandler}
        onPrevPageHandler={onPrevPageHandler}
        prevDisabled={prevDisabled}
        control={control}
        pageSizeName={"pageSize"}
        totalCount={totalCount}
      />
    </div>
  );
};

export default CompanyCard;
