export const countNonNullKeys = (
  obj: { [key: string]: any } | null | undefined
) => {
  if (obj === null || obj === undefined) {
    return 0;
  }

  const length = Object.keys(obj).filter((key) => {
    const value = obj[key];
    if (typeof value === "string") {
      return value.trim() !== "";
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return value !== undefined && value !== null;
    }
  })?.length;

  return length > 0 ? length : 0;
};
