import { gql } from "@apollo/client";

export const USER_NOTIFICATIONS_COLLECTION_QUERY = gql`
  query notifications($ids: [Int], $isRead: Boolean, $limit: Int, $page: Int) {
    notifications(ids: $ids, isRead: $isRead, limit: $limit, page: $page) {
      dataCollection {
        id
        uuid
        message
        link
        isRead
        readAt
        createdAt
        additionalInformation
        triggeredByUser {
          id
          uuid
          personalInformation {
            id
            name
            personalEmail
          }

          userProfileImages {
            id
            documents {
              file
            }
            type
          }
        }
      }
      totalNumberOfItems
    }
  }
`;
