import { FC, useState } from "react";
import { HiCheck } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { TbInfoCircle } from "react-icons/tb";

import { SingleActionPopup } from "components/forms";
import Tooltip from "components/UI/ToolTip";

import { IIdAndName, ISetStateType } from "global/types/type";

import LeaveInfo from "modules/Leave/Pages/Requests/LeaveInfo";
import { filterConsentorActions } from "modules/Leave/helper";

interface IProps {
  date: any;
  setShowModal: Function;
  setApproveVerification: Function;
  setApproveSingleDate: Function;
  setDeclineSingleDate: Function;
  setModalTitle: ISetStateType<string>;
  isConsentRequest: boolean;
  requestedToUser: {
    personalInformation: {
      id: number;
      name: string;
    };
  };
  showLeaveType: boolean;
}
const DatesDetails: FC<IProps> = ({
  setApproveVerification,
  setShowModal,
  setApproveSingleDate,
  setDeclineSingleDate,
  setModalTitle,
  isConsentRequest,
  requestedToUser,
  date,
  showLeaveType,
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];
  const userId = +localStorage?.id || +sessionId;
  const consenterRequest =
    date?.leaveRequestDateConsents?.filter(
      (leave: { consentedByUser: { personalInformation: IIdAndName } }) =>
        leave?.consentedByUser?.personalInformation?.id === userId
    ) || [];
  const status =
    isConsentRequest && date?.status !== "Withdrawn"
      ? consenterRequest[0]?.status
      : date?.status;

  const isAgreedByAll = date?.leaveRequestDateConsents
    ?.map((date: { status: string }) => date?.status)
    ?.every((status: string) => status === "Agreed");

  const isTeamLead =
    requestedToUser?.personalInformation?.id === +sessionId ||
    requestedToUser?.personalInformation?.id === +localStorage?.id;

  return (
    <div className="flex text-sm text-ironside-gray items-center">
      <span className="min-w-[80px]">{date?.leaveDate || ""}</span>
      &nbsp;&nbsp;-&nbsp;&nbsp;
      <span className="min-w-[100px]">
        {date.session === "2 Hours"
          ? showLeaveType
            ? `${date.session || ""} (${date?.startTime || ""} - ${
                date?.endTime || ""
              }) - ${date?.leaveType?.name || ""}`
            : `${date.session || ""} (${date?.startTime || ""} - ${
                date?.endTime || ""
              })`
          : showLeaveType
          ? `${date?.session || ""} - ${date?.leaveType?.name} `
          : date.session || ""}
      </span>
      <span
        className={`${
          status === "Pending"
            ? "text-yellow-orange"
            : status === "Approved"
            ? "text-dark-mint"
            : status === "Declined"
            ? "text-brink-pink"
            : status === "Cancelled"
            ? "text-valentine-red"
            : status === "Withdrawn"
            ? "text-cornflower-blue"
            : "text-cornflower-blue"
        } min-w-[80px] ml-2`}
      >
        {status || ""}
      </span>
      {(isConsentRequest
        ? consenterRequest[0]?.status === "Pending"
        : date?.status === "Pending" && isAgreedByAll) &&
        !date?.leaveType?.isSpecialLeave && (
          <div className="flex">
            <Tooltip
              render={isConsentRequest ? "Agree" : "Approve"}
              arrow
              placement="top"
            >
              <HiCheck
                className="inline-block w-6 h-6 p-[2px] text-cornflower-blue rounded-full hover:bg-titan-white  cursor-pointer"
                onClick={() => {
                  setShowModal(false);
                  setApproveVerification(true);
                  setModalTitle(
                    date?.session === "2 Hours" ? "Permission" : "Leave Request"
                  );
                  setApproveSingleDate({
                    leaveRequestDateId: +date?.id,
                    status: isConsentRequest ? "Agreed" : "Approved",
                  });
                }}
              />
            </Tooltip>
            <Tooltip
              render={isConsentRequest ? "Disagree" : "Decline"}
              arrow
              placement="top"
            >
              <MdOutlineClose
                className="inline-block w-6 h-6 p-[2px] text-valentine-red rounded-full hover:bg-pale-pink  cursor-pointer ml-2"
                onClick={() => {
                  setShowModal(true);
                  setModalTitle(
                    date?.session === "2 Hours" ? "Permission" : "Leave Request"
                  );
                  setDeclineSingleDate({
                    leaveRequestDateId: +date?.id,
                    status: isConsentRequest ? "Disagreed" : "Declined",
                  });
                }}
              />
            </Tooltip>
          </div>
        )}
      {(date?.status === "Approved" ||
        date?.status === "Declined" ||
        date?.leaveRequestDateConsents?.length > 0) && (
        <Tooltip render="Info" arrow placement="top">
          <span>
            <TbInfoCircle
              onClick={() => {
                setShowInfo(true);
              }}
              className="w-5 h-5 cursor-pointer text-brown-rust/70 ml-2"
            />
          </span>
        </Tooltip>
      )}
      {showInfo ? (
        <SingleActionPopup
          header={"Leave Info"}
          onExecute={() => setShowInfo(false)}
          customContent={
            date?.status === "Approved" ||
            date?.status === "Declined" ||
            date?.leaveRequestDateConsents?.length > 0 ? (
              <LeaveInfo
                teamLeadUserName={requestedToUser?.personalInformation?.name}
                declineMessage={date?.declineMessage}
                consents={filterConsentorActions(
                  date?.leaveRequestDateConsents
                )}
                teamLeadLeaveActionStatus={date?.status}
                hasConsents={date?.leaveRequestDateConsents?.length}
                message={date?.message}
                isTeamLead={isTeamLead}
                statusChangedAt={date?.statusChangedAt}
              />
            ) : undefined
          }
        />
      ) : null}
    </div>
  );
};

export default DatesDetails;
