import { MdOutlineClose } from "react-icons/md";
import { RoundedFilled } from "components/forms";
import { ReactNode } from "react";
import Modal from "components/UI/UpdatedModal";

interface Props {
  header: string;
  onExecute: () => void;
  message?: string;
  buttonName?: string;
  hideButton?: boolean;
  customContent?: ReactNode;
}

const SingleActionPopup: React.FC<Props> = ({
  header,
  onExecute,
  message,
  buttonName,
  hideButton,
  customContent,
}) => {
  return (
    <Modal>
      <div className="bg-white rounded-[12px] shadow-[0px_-3px_6px_#00000029] w-[340px] lg:w-[580px]">
        <div className="flex justify-between py-[20px] lg:py-[25px] px-[26px] border-b border-hit-gray">
          <h3 className="text-base text-ironside-gray">{header}</h3>
          <MdOutlineClose
            onClick={onExecute}
            className="text-ironside-gray cursor-pointer w-[18px] h-[18px]"
          />
        </div>
        {message ? (
          <p className="px-3 text-sm text-black/60 py-[25px] lg:py-[30px] mx-auto lg:pl-[25px] flex justify-center lg:justify-start">
            {message}
          </p>
        ) : customContent ? (
          customContent
        ) : null}

        {!hideButton && (
          <div className="flex justify-center lg:justify-end px-[26px] pt-[25px] pb-[27px] space-x-[33px] border-t border-hit-gray">
            <RoundedFilled
              buttonName={buttonName ? buttonName : "OK"}
              onClick={onExecute}
              isConfirmModal={true}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SingleActionPopup;
