import { FC } from "react";

import Select from "components/forms/Select/Countrycode";

interface IProps {
  register: Function;
  errors: any;
  label?: string;
  name?: string;
  defaultMobile?: number;
  defaultCountryCode?: string;
  disabled?: boolean;
  required?: boolean;
}

const Mobile: FC<IProps> = ({
  register,
  errors,
  label,
  name,
  defaultMobile,
  defaultCountryCode,
  disabled,
  required,
}) => {
  return (
    <div className="flex w-full">
      <Select register={register} defaultCountryCode={defaultCountryCode} />
      <div className="w-full">
        <div
          className={`relative  flex items-center shadow justify-between transition-all w-full h-[52px] border ${
            errors[name ? name : "mobile"]
              ? "border-[#FF647C]"
              : "border-[#E5E5E5]"
          }  rounded-[4px] ${
            errors[name ? name : "mobile"]
              ? "focus-within:border-[#FF647C]"
              : "focus-within:border-cornflower-blue"
          } font-normal`}
        >
          <input
            id={name ? name : "mobile"}
            type="text"
            placeholder="00000 00000"
            defaultValue={defaultMobile}
            disabled={disabled}
            autoComplete="off"
            maxLength={10}
            onKeyDown={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            className={
              "text-ironside-gray peer px-3 pl-14 h-[50px] w-full bg-transparent placeholder-transparent focus:outline-none focus:border-cornflower-blue"
            }
            {...register(name ? name : "mobile", {
              required: required ? "This is required field." : false,
              pattern: {
                value: /^(0|91)?[6-9][0-9]{9}$/,
                message: "Please enter a valid mobile number.",
              },
            })}
          />
          <label
            htmlFor={name ? name : "mobile"}
            className={`absolute px-1 cursor-text bg-white ml-14 left-0 -top-2 ${
              errors[name ? name : "mobile"]
                ? "text-[#FF647C] text-[11px]"
                : "text-[#8f8f92] text-[11px]"
            } transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal ${
              errors[name ? name : "mobile"]
                ? "peer-placeholder-shown:text-[#FF647C]"
                : "peer-placeholder-shown:text-[#8f8f92]"
            } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
              errors[name ? name : "mobile"]
                ? "peer-focus:text-[#FF647C]"
                : "peer-focus:text-cornflower-blue"
            } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
          >
            {label ? label : "Mobile"}
          </label>
        </div>
        <div className="w-full">
          {errors[name ? name : "mobile"] ? (
            <span className="text-[#FF647C] text-[12px] leading-[15px] ">
              {errors[name ? name : "mobile"]?.message}{" "}
            </span>
          ) : errors[name ? name : "mobile"] ? (
            <span className="invisible">Helper Text</span>
          ) : (
            <span className="invisible">Helper Text</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mobile;
