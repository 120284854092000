import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { Filled, Modal, Outlined, Select, Text } from "components/forms";
import FileUpload from "components/forms/File/FileUpload";

import { MdOutlineClose } from "react-icons/md";
import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";
import { Base64toObject } from "global/helpers/FileConverter";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { GET_SKILLS_DETAILS } from "modules/Employee/services/queries";
import {
  CREATE_SKILL_CERTIFICATE,
  UPDATE_SKILL_CERTIFICATE,
} from "modules/Employee/services/mutations";
import {
  IEditSkillCertifcate,
  ISkillsList,
} from "modules/Employee/types/skills";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  editCertificate: IEditSkillCertifcate | null;
  setEditCertificate: Function;
  userSkillTypes: any;
  userTechnicalSkillsList: any;
  userNonTechnicalSkillsList: any;
  setShowModal: Function;
  refetchSkillDetails: Function;
}

const EditCertificates: FC<IProps> = ({
  editCertificate,
  setEditCertificate,
  userSkillTypes,
  userTechnicalSkillsList,
  userNonTechnicalSkillsList,
  setShowModal,
  refetchSkillDetails,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [watchedSkillType, setWatchedSkillType] = useState<string>(
    editCertificate?.userSkill?.skill?.type
      ? editCertificate?.userSkill?.skill?.type
      : ""
  );
  const [file, setFile] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<any>(null);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    if (
      editCertificate?.userSkillCertificate?.documents &&
      editCertificate?.userSkillCertificate?.documents[0]?.file
    ) {
      setFile(editCertificate?.userSkillCertificate?.documents[0]?.file);
    }
  }, [editCertificate]);

  useEffect(() => {
    if (file) {
      setValue("certificate", file);
    }
  }, [file, setValue]);

  useEffect(() => {
    if (file) {
      clearErrors("certificate");
    }
  }, [clearErrors, file]);

  const [createUserSkillCertificate] = useMutation(CREATE_SKILL_CERTIFICATE, {
    refetchQueries: [GET_SKILLS_DETAILS],
  });

  const [updateUserSkillCertificate] = useMutation(UPDATE_SKILL_CERTIFICATE, {
    refetchQueries: [GET_SKILLS_DETAILS],
  });

  const isUnderModeration = useIsUnderModeration();

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Certificate")
    : createSuccessMessageWithAwaiting("Certificate");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Certificate")
    : updateSuccessMessageWithAwaiting("Certificate");

  const createHandler = (data: any) => {
    if (!inProgress) {
      createUserSkillCertificate({
        variables: {
          userSkillId: +data.selectSkill,
          certificateName: data.certificateName,
          documents:
            file && file?.length > 0
              ? [
                  {
                    filePath: file,
                  },
                ]
              : null,
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: any) => {
    if (!inProgress) {
      updateUserSkillCertificate({
        variables: {
          id:
            editCertificate?.userSkillCertificate &&
            +editCertificate?.userSkillCertificate?.id,
          userSkillId:
            editCertificate?.userSkill && +editCertificate?.userSkill?.id,
          certificateName: data.certificateName,
          documents: [
            {
              filePath: fileDetails?.name
                ? file
                : `data:application/pdf;base64,${editCertificate?.userSkillCertificate?.documents[0]?.file}`,
            },
          ],
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    reset();
    setShowModal(false);
    setEditCertificate(null);
    setFile(null);
  };

  const selectedOptions =
    watchedSkillType === "Technical"
      ? userTechnicalSkillsList
      : watchedSkillType === "Non Technical"
      ? userNonTechnicalSkillsList
      : [];

  const moderationObj = editCertificate?.userSkillCertificate?.moderation;

  const userSkillTypesOption =
    userSkillTypes?.filter((skillTypes: string[]) => skillTypes) || [];

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">Certifications</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form
          onSubmit={
            editCertificate?.userSkill?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
          className="space-y-1"
        >
          <div className="form-div pr-[18px] lg:pr-[45px]">
            <Select
              register={register}
              errors={errors}
              options={userSkillTypesOption}
              name="skillType"
              label="Select Skill Type *"
              defaultSelectValue={
                editCertificate?.userSkill?.skill?.type
                  ? editCertificate?.userSkill?.skill?.type
                  : ""
              }
              disable={!!editCertificate?.userSkill?.skill?.type}
              onChange={(event: any) => {
                setWatchedSkillType(event.target.value);
              }}
              required={!editCertificate?.userSkill?.skill?.type}
            />
            <div className="w-full">
              <div
                className={`sm:block relative flex items-center justify-between transition-all w-full h-[52px] border ${
                  errors.selectSkill ? "border-[#FF647C]" : "border-[#E5E5E5]"
                } rounded-[4px] ${
                  errors.selectSkill
                    ? "focus-within:border-[#FF647C]"
                    : "focus-within:border-cornflower-blue"
                } font-normal"`}
              >
                <select
                  id="select-skill"
                  className="peer px-3 h-[50px] w-full bg-transparent placeholder-transparent border-none focus:outline-none focus:border-cornflower-blue appearance-none"
                  defaultValue={
                    editCertificate?.userSkill?.skill?.name
                      ? editCertificate?.userSkill?.skill?.name
                      : ""
                  }
                  disabled={!!editCertificate?.userSkill?.skill?.name}
                  {...register("selectSkill", {
                    required: editCertificate?.userSkill?.skill?.name
                      ? false
                      : "This filed is required.",
                  })}
                >
                  <option value={""} disabled>
                    Select
                  </option>
                  {selectedOptions?.map((option: ISkillsList) => {
                    return (
                      <option key={option?.id} value={option?.id}>
                        {option?.name}
                      </option>
                    );
                  })}
                </select>
                <label
                  htmlFor="select-skill"
                  className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
                    errors.selectSkill ? "text-[#FF647C]" : "text-[#8f8f92]"
                  } transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal ${
                    errors.selectSkill
                      ? "peer-placeholder-shown:text-[#FF647C]"
                      : "peer-placeholder-shown:text-[#8f8f92]"
                  } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
                    errors.selectSkill
                      ? "peer-focus:text-[#FF647C]"
                      : "peer-focus:text-cornflower-blue"
                  } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
                >
                  {"Select Skill *"}
                </label>
                <label
                  htmlFor="select-skill"
                  className="absolute right-[21px] top-[22px] peer-focus-within:rotate-180 cursor-pointer"
                >
                  <DownArrow className="w-[13px] h-[8px] text-[#bebebe] cursor-pointer" />
                </label>
              </div>
              <div className="w-full">
                {errors.selectSkill ? (
                  <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
                    {`${errors?.selectSkill?.message}`}
                  </span>
                ) : errors.selectSkill ? (
                  <span className="invisible">Helper Text</span>
                ) : (
                  <span className="invisible">Helper Text</span>
                )}
              </div>
            </div>
            <ModerationField
              moderationId={moderationObj?.certificateName}
              refetchQuery={refetchSkillDetails}
            >
              <Text
                register={register}
                errors={errors}
                label="Certificate Name *"
                name="certificateName"
                maximumLength={75}
                required={true}
                defaultText={
                  editCertificate?.userSkillCertificate?.certificateName
                }
                disable={!!moderationObj?.certificateName}
              />
            </ModerationField>
            <div className="relative">
              {fileDetails?.name ? (
                ""
              ) : editCertificate?.userSkillCertificate?.documents &&
                editCertificate?.userSkillCertificate?.documents[0]?.file
                  ?.length > 100 ? (
                <div className="mt-[15px] ml-[12px] absolute z-10">
                  <a
                    href={Base64toObject(
                      editCertificate?.userSkillCertificate?.documents[0]?.file
                    )}
                    target="_blank"
                    rel="noreferrer"
                    className="text-cornflower-blue cursor-pointer hover:underline"
                  >
                    View File
                  </a>
                </div>
              ) : (
                <div className="mt-[15px] ml-[12px] absolute">
                  <span className="text-cornflower-blue cursor-pointer">
                    {editCertificate === null ? "" : "No File"}
                  </span>
                </div>
              )}
              <ModerationField
                moderationId={moderationObj?.filePath}
                refetchQuery={refetchSkillDetails}
              >
                <FileUpload
                  register={register}
                  errors={errors}
                  name="certificate"
                  labelName="Certificate"
                  file={file || ""}
                  setFile={setFile}
                  fileDetails={fileDetails}
                  setFileDetails={setFileDetails}
                  defaultFileValue={
                    editCertificate?.userSkillCertificate?.documents &&
                    editCertificate?.userSkillCertificate?.documents[0]?.file
                      ? editCertificate?.userSkillCertificate?.documents[0]
                          ?.file
                      : null
                  }
                  disabled={!!moderationObj?.filePath}
                />
              </ModerationField>
            </div>
          </div>
          <div className="w-full border-b border-hit-gray pt-[4px]" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined buttonName="Cancel" onClick={moduleCloseHandler} />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : editCertificate?.userSkill?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditCertificates;
