import { Filled, Modal, Outlined } from "components/forms";
import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import SMCardContent from "components/App/Card/SMCard/SMCardContent";

import { toastNotify } from "global/helpers/Cache";
import { ILeaveRequestDates } from "global/types/type";

import { FILTER_ALL_COMPENSATION } from "modules/Leave/services/queries";
import { UPDATE_COMPENSATION_LEAVE_STATUS } from "modules/Leave/services/mutations";
import DatesDetails from "modules/Leave/Pages/Requests/AllCompOffs/DatesDetails";

const ViewPage = () => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const location: any = useLocation();
  const navigate = useNavigate();

  const [
    fetchAllCompensation,
    { data: filterAllCompensation, loading: filterTeamCompensationLoading },
  ] = useLazyQuery(FILTER_ALL_COMPENSATION, {
    fetchPolicy: "cache-and-network",
  });

  const compensationRequestId =
    +location?.pathname?.split("/")[location?.pathname?.split("/")?.length - 1];
  const viewPageDetails =
    filterAllCompensation?.filterAllCompensationLeaveRequest?.dataCollection
      .length > 0 &&
    filterAllCompensation?.filterAllCompensationLeaveRequest?.dataCollection[0];
  const requestedToUser = viewPageDetails?.requestedToUser?.personalInformation;
  const userId = +localStorage?.id || +sessionId;
  const flattenConsentRequests =
    viewPageDetails?.leaveRequestDates
      ?.map((leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents)
      ?.flat() || [];

  const consentUserIds =
    flattenConsentRequests?.map(
      (leave: { consentedByUser: { personalInformation: { id: number } } }) =>
        leave?.consentedByUser?.personalInformation?.id
    ) || [];

  const isTeamLead = viewPageDetails?.isConsentRequest
    ? consentUserIds?.includes(+userId)
    : requestedToUser?.id === +sessionId ||
      requestedToUser?.id === +localStorage?.id;

  useEffect(() => {
    if (compensationRequestId && !isNaN(compensationRequestId)) {
      fetchAllCompensation({
        variables: {
          id: +compensationRequestId,
        },
      });
    }
  }, [compensationRequestId, fetchAllCompensation]);

  const [approveVerification, setApproveVerification] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditOption, setShowEditOption] = useState<boolean>(false);

  const [declineSingleDate, setDeclineSingleDate] = useState<{
    id: number | undefined;
    status: string | undefined;
  }>();

  const [approveSingleDate, setApproveSingleDate] = useState<{
    id: number | undefined;
    status: string | undefined;
  }>();

  let editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("pointerdown", handler);
    return () => {
      document.removeEventListener("pointerdown", handler);
    };
  }, [showEditOption]);

  const [updateCompensationLeaveStatus] = useMutation(
    UPDATE_COMPENSATION_LEAVE_STATUS,
    {
      refetchQueries: [FILTER_ALL_COMPENSATION],
    }
  );

  const { register, watch } = useForm();
  const reason = watch("reason");

  const sortFunction = (a: any, b: any) => {
    const dateA = new Date(a?.leaveDate)?.getTime();
    const dateB = new Date(b?.leaveDate)?.getTime();
    return dateA > dateB ? 1 : -1;
  };

  let dates: any = viewPageDetails?.compensationLeaveDates;
  dates = dates?.slice()?.sort(sortFunction);

  const hasPendingStatus = viewPageDetails?.compensationLeaveDates
    ?.map((date: any) => date?.status)
    .includes("Pending");

  const confirmHandler = (id: number) => {
    if (!filterTeamCompensationLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            leaveRequestDates: approveSingleDate?.id
              ? [approveSingleDate]
              : dates?.map((date: { id: number }) => {
                  return {
                    id: date?.id,
                    status: "Approved",
                  };
                }),
            id: +id,
          },
        },
      })
        .then((response: any) => {
          setApproveSingleDate({
            id: undefined,
            status: undefined,
          });
          setApproveVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-comp-off");
          }
        })
        .catch((error: any) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const declineHandler = (id: number) => {
    if (!filterTeamCompensationLoading) {
      updateCompensationLeaveStatus({
        variables: {
          updateCompensationLeaveDateInput: {
            id: +id,
            leaveRequestDates: declineSingleDate?.id
              ? [{ ...declineSingleDate, declineMessage: reason }]
              : dates?.map((date: { id: number }) => {
                  return {
                    id: +date?.id,
                    status: "Declined",
                    declineMessage: reason,
                  };
                }),
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);

          const leaveDatesStatusses =
            response?.data?.updateCompensationLeaveStatus?.compensationLeaveDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-comp-off");
          }
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const createdAt = viewPageDetails?.createdAt || (
    <span className="text-gray-400">{"Eg.YYYY-MM-DD"}</span>
  );
  const updatedAt = viewPageDetails?.updatedAt || createdAt;

  const userProfileImage =
    viewPageDetails?.requestedByUser?.userProfileImages?.filter(
      (userProfileImage: any) => userProfileImage.type === "Profile"
    )[0] || null;

  const profileImage =
    userProfileImage?.documents && userProfileImage?.documents[0]?.file;

  const profileDetails = {
    profileImage: profileImage,
    name: viewPageDetails?.requestedByUser?.personalInformation?.name,
    email: viewPageDetails?.requestedByUser?.employeeDetail?.officialEmail,
    designation:
      viewPageDetails?.requestedByUser?.employeeDetail?.employeeDesignation,
  };

  let values: {
    lable: string;
    hidden?: boolean | undefined;
    value: {
      content: string | Element;
      profileDetails?:
        | {
            profileImage?: string | undefined;
            name: string;
            email: string;
            designation: string;
          }
        | undefined;
      color?: string | undefined;
    }[];
  }[] =
    dates?.map((date: any) => {
      return {
        lable: "Date",
        value: [
          {
            content: (
              <div>
                <DatesDetails
                  date={date}
                  setApproveSingleDate={setApproveSingleDate}
                  setApproveVerification={setApproveVerification}
                  setDeclineSingleDate={setDeclineSingleDate}
                  setShowModal={setShowModal}
                  isTeamLead={isTeamLead}
                />
              </div>
            ),
          },
        ],
      };
    }) || [];

  const getDataModal = () => [
    {
      lable: "ID",

      value: [
        {
          content: viewPageDetails?.id,
        },
      ],
    },
    {
      lable: "Created At",
      value: [
        {
          content: createdAt,
        },
      ],
    },
    {
      lable: "Updated At",
      value: [
        {
          content: updatedAt,
        },
      ],
    },
    {
      lable: "Requested By",
      value: [
        {
          content: viewPageDetails?.requestedByUser?.personalInformation?.name,
          profileDetails: profileDetails,
        },
      ],
    },
    {
      lable: "Project",
      value: [
        {
          content:
            viewPageDetails?.compensationLeaveDates?.length > 0 &&
            viewPageDetails?.compensationLeaveDates[0]?.project?.name,
        },
      ],
    },
    ...values,
    {
      lable: "Reason",
      value: [
        {
          content: viewPageDetails?.reason,
        },
      ],
    },
  ];

  return (
    <Fragment>
      <div className="border border-hit-gray rounded-[4px] mt-[20px] px-5 py-4 min-h-[68vh]">
        <SMCardContent dataModel={getDataModal()} />
        {isTeamLead && (
          <>
            {hasPendingStatus ? (
              <div className="flex flex-wrap gap-4 pt-5 justify-center items-center">
                <Outlined
                  buttonName="Decline"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
                <Filled
                  buttonName={"Approve"}
                  onClick={() => {
                    setShowModal(false);
                    setApproveVerification(true);
                  }}
                  buttonType={"button"}
                />
              </div>
            ) : null}
            {showModal ? (
              <Modal>
                <div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
                  <div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
                    <h2 className="text-base text-ironside-gray">
                      Comp Off Request Decline
                    </h2>
                    <div
                      className="edit-modal-close-icon-div"
                      onClick={() => {
                        setShowModal(false);
                        setDeclineSingleDate({
                          id: undefined,
                          status: undefined,
                        });
                      }}
                    >
                      <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
                    </div>
                  </div>

                  <div className="w-full py-[20px] px-[20px]">
                    <textarea
                      className="w-full h-[200px] resize-none border border-gray-200 focus:outline-none p-4"
                      placeholder="Type your reason here"
                      {...register("reason")}
                    />
                  </div>
                  <div className="w-full border-b" />
                  <div className="flex items-center justify-center p-6">
                    <button
                      type="button"
                      onClick={() => {
                        declineHandler(viewPageDetails?.id);
                      }}
                      className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
                    >
                      {filterTeamCompensationLoading ? (
                        <div className="btn-spinner" />
                      ) : (
                        "Decline"
                      )}
                    </button>
                  </div>
                </div>
              </Modal>
            ) : null}
            {approveVerification ? (
              <ConfirmModal
                header="Comp Off Request"
                keyValue="approve"
                onCancel={() => {
                  setApproveVerification(false);
                  setApproveSingleDate({
                    id: undefined,
                    status: undefined,
                  });
                }}
                onXIcon={() => {
                  setApproveVerification(false);
                  setApproveSingleDate({
                    id: undefined,
                    status: undefined,
                  });
                }}
                onExecute={() => confirmHandler(viewPageDetails?.id)}
                loading={filterTeamCompensationLoading}
              />
            ) : null}
          </>
        )}
      </div>
      <div className="flex justify-center items-center">
        <HiArrowNarrowLeft
          className="back-arrow-icon"
          onClick={() => {
            navigate("/leaves/requests/all-comp-off");
          }}
        />
      </div>
    </Fragment>
  );
};

export default ViewPage;
