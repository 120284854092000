const Text: React.FC<{
  register: Function;
  name: string;
  errors: any;
  label: string;
  regex?: any;
  defaultText?: string;
  disable?: boolean;
  required?: boolean;
  minimumLength?: number;
  maximumLength?: number;
  className?: string;
  classNameForParentDiv?: string;
  inputType?: "text" | "url";
}> = ({
  register,
  name,
  errors,
  label,
  defaultText,
  disable,
  required,
  maximumLength,
  minimumLength,
  regex,
  className,
  classNameForParentDiv,
  inputType = "text",
}) => {
  return (
    <div
      className={`${classNameForParentDiv ? classNameForParentDiv : "w-full"}`}
    >
      <div
        className={`w-full ${
          className ? className : ""
        } relative shadow flex items-center justify-between transition-all  h-[52px] border ${
          errors[name] ? "border-[#FF647C]" : "border-[#E5E5E5]"
        }  rounded-[4px] ${
          errors[name]
            ? "focus-within:border-[#FF647C]"
            : "focus-within:border-cornflower-blue"
        } font-normal`}
      >
        <input
          id={name}
          type={inputType}
          placeholder="Name"
          defaultValue={defaultText}
          disabled={disable}
          autoComplete="off"
          maxLength={maximumLength ? maximumLength : null}
          className="text-ironside-gray peer px-3 h-[50px] w-full bg-transparent placeholder-transparent focus:outline-none focus:border-cornflower-blue"
          {...register(name, {
            required: required ? "This is required field." : false,
            minLength: {
              value: minimumLength ? minimumLength : 2,
              message: `Minimum length ${minimumLength ? minimumLength : 2}.`,
            },
            pattern: {
              value: regex ? regex : null,
              message: "Please enter a valid input value.",
            },
          })}
        />
        <label
          htmlFor={name}
          className={`absolute px-1 cursor-text bg-white ml-3 left-0 -top-2 ${
            errors[name]
              ? "text-[#FF647C] text-[11px]"
              : "text-[#8f8f92] text-[11px]"
          } transition-all peer-placeholder-shown:text-[14px] peer-placeholder-shown:leading-[17px] font-normal ${
            errors[name]
              ? "peer-placeholder-shown:text-[#FF647C] "
              : "peer-placeholder-shown:text-[#8f8f92] "
          } peer-placeholder-shown:top-4 peer-focus:-top-2 ${
            errors[name]
              ? "peer-focus:text-[#FF647C] "
              : "peer-focus:text-cornflower-blue "
          } peer-focus:text-[11px] peer-focus:bg-white text-[11px] leading-[17px]`}
        >
          {label}
        </label>
      </div>
      <div className="w-full">
        {errors[name] ? (
          <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default Text;
