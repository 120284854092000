import { ChangeEvent, useState, useCallback, FC } from "react";

import { Countries } from "global/helpers/Countries";
import { ReactComponent as DownArrow } from "global/assets/images/down-arrow.svg";

const Countrycode: FC<{
  register: Function;
  defaultCountryCode?: string;
}> = ({ register, defaultCountryCode }) => {
  const [countryCode, setCountryCode] = useState<string>(
    defaultCountryCode ? defaultCountryCode : "+91"
  );

  const showCountryCode = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(e.target.value);
  }, []);

  return (
    <div className="mx-auto">
      <div className="flex items-center w-[88px] relative">
        <select
          id="country-code"
          className="text-ironside-gray shadow w-[65px] text-sm h-[50px] rounded-[4px] bg-transparent border border-[#E5E5E5] focus:outline-none focus:border-cornflower-blue text-[14px] leading-[17px] pl-[20px] appearance-none peer pr-[5px]"
          defaultValue={defaultCountryCode}
          {...register("country")}
          onChange={showCountryCode}
        >
          {Countries.map(
            (data: { code: string; phone: string; label: string }) => (
              <option key={data.code} value={data.phone} className="ml-2">
                {data.code} &nbsp;&nbsp; - &nbsp;&nbsp; {data.label}
              </option>
            )
          )}
        </select>
        <label
          htmlFor={"country-code"}
          className="absolute right-[28px] top-[22px] peer-focus-within:rotate-180 pointer-events-none bg-white"
        >
          <DownArrow className="w-[13px] h-[8px] text-[#bebebe] cursor-pointer" />
        </label>
        <div className="text-ironside-gray translate-x-9 translate-y-[2px]">
          {countryCode}
        </div>
      </div>
    </div>
  );
};
export default Countrycode;
