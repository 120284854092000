import { FC } from "react";

interface Props {
  className?: string;
  buttonName?: string | any;
  onClick?: () => void;
  buttonType?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  value?: {
    icon: any;
  };
}

const Filled: FC<Props> = ({
  className,
  buttonName,
  onClick,
  buttonType,
  disabled,
  loading,
  value,
}) => {
  return (
    <button
      type={buttonType || "submit"}
      className={`${
        className ? className : ""
      } w-[110px] lg:w-[120px] h-[42px] text-sm  text-white rounded ${
        loading ? "cursor-not-allowed bg-cornflower-blue" : ""
      } ${
        disabled
          ? "cursor-not-allowed bg-bright-blue/50"
          : loading
          ? "cursor-not-allowed"
          : " hover:bg-bright-blue/80 bg-cornflower-blue"
      }`}
      onClick={onClick}
      disabled={disabled || loading ? true : false}
    >
      {loading ? (
        <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
      ) : value?.icon ? (
        value.icon
      ) : (
        buttonName
      )}
    </button>
  );
};

export default Filled;
