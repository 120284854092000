import { createHttpLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "global/helpers/apollo-upload-client/createUploadLink";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path }) => {
      console.error(`[GraphQL Error]: Message: ${message}, Path: ${path}`);
    });

  if (networkError) {
    if (networkError.message.slice(-3) === "401") {
      console.error(`[Network Error]: Message: ${networkError.message}`);
    } else {
      console.error(`[Network Error]: Message: ${networkError.message}`);
    }
  }
});

export const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

export const clearCookies = () => {
  document.cookie.split(";").forEach(function (cookies) {
    document.cookie = cookies
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const authLink = setContext((_, { headers }) => {
  const sessionToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
  return {
    headers: {
      ...headers,
      authorization:
        localStorage.token || sessionToken
          ? `Bearer ${localStorage.token || sessionToken}`
          : null,
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: process.env.REACT_APP_BASE_URL,
  }).concat(errorLink),
});
