import { FC } from "react";

import { Company } from "modules/Company/types";
import BasicInformation from "modules/Company/Account/General/Basic/BasicInformation";
import ContactInformation from "modules/Company/Account/General/Contact/ContactInformation";
import IdentificationDetails from "./IdentificationDetails/Basic/IdentificationDetails";

interface Props {
  company: Company | null;
}

const General: FC<Props> = ({ company }) => {
  const basicCompanyDetails = {
    name: company?.name,
    legalName: company?.legalName,
    status: company?.status,
    companyType: company?.companyType,
    companyOwners: company?.companyOwners,
    companyManagers: company?.companyManagers,
    openingTime: company?.openingTime,
    closingTime: company?.closingTime,
  };

  const contactCompanyDetails = {
    address: company?.address,
    mobile: company?.isdCode
      ? `+${company?.isdCode} ${company?.mobile}`
      : company?.mobile,
    altMobile: company?.altIsdCode
      ? `+${company?.altIsdCode} ${company?.altMobile}`
      : company?.altMobile,
    email: company?.email,
  };

  const identificationDetails = {
    pan: company?.pan,
    cin: company?.cin,
    gstin: company?.gstin,
    isEmailVerified: company?.isEmailVerified,
    isPanVerified: company?.isPanVerified,
    isCinVerified: company?.isCinVerified,
    isGstinVerified: company?.isGstinVerified,
  };

  return (
    <div className="grid md:grid-cols-2 md:gap-4">
      <BasicInformation company={basicCompanyDetails} />
      <ContactInformation company={contactCompanyDetails} />
      <IdentificationDetails company={identificationDetails} />
    </div>
  );
};

export default General;
