import { ReactComponent as UploadIcon } from "global/assets/images/upload-icon.svg";
import { fileToBase64 } from "global/helpers/FileConverter";
import { toastNotify } from "global/helpers/Cache";

const FileUpload: React.FC<{
  register: Function;
  name: string;
  errors: any;
  className?: string;
  file: string;
  setFile: Function;
  fileDetails: any;
  setFileDetails: Function;
  labelName?: string;
  required?: boolean;
  supportFormat?: string;
  maxLimit?: string;
  defaultFileValue?: string | any;
  disabled?: boolean;
  supportedMimeType?: string;
  isFileSupportMessageNeeded?: boolean;
  isSizeLimitNeeded?: boolean;
}> = ({
  register,
  errors,
  name,
  className,
  file,
  setFile,
  fileDetails,
  setFileDetails,
  labelName,
  required,
  supportFormat,
  maxLimit,
  defaultFileValue,
  disabled,
  supportedMimeType = "application/pdf",
  isFileSupportMessageNeeded = true,
  isSizeLimitNeeded = true,
}) => {
  const onUploadFileChange = (event: any) => {
    if (event?.target?.files[0]?.size > 2e6 && isSizeLimitNeeded) {
      return toastNotify([
        {
          messageType: "error",
          message: "Maximum file size limit is 2 MB.",
        },
      ]);
    } else if (!event?.target?.validity?.valid) {
      return toastNotify([
        {
          messageType: "error",
          message: "Please upload a valid file.",
        },
      ]);
    } else {
      fileToBase64(event.target.files[0], (err: any, result: any) => {
        if (result) {
          setFile(result);
          setFileDetails(event.target.files[0]);
        }
      });
    }
  };

  return (
    <div className="relative flex flex-col w-full">
      <div
        className={`${
          errors[name] ? "border-[#ff647c]" : "border-[#E5E5E5]"
        } ${className} shadow border w-full rounded-md h-[50px]`}
      >
        <div className="flex items-center justify-between">
          <label
            className={`${errors[name] ? "text-[#ff647c]" : "text-[#8f8f92]"} ${
              fileDetails?.name || defaultFileValue
                ? "-top-2 text-[11px]"
                : "text-[14px] cursor-pointer"
            } whitespace-nowrap absolute left-3 tracking-wide right-11`}
            htmlFor={name}
          >
            <span className="bg-white px-1">{labelName}</span>
          </label>
          <input
            type="file"
            id={name}
            accept={supportedMimeType}
            className="hidden"
            {...register(name, {
              required: required ? "This is required field." : false,
              onChange: (event: any) => {
                onUploadFileChange(event);
              },
            })}
            disabled={disabled}
          />
          <div className="flex items-center w-full pl-[10px] px-2 h-[48px] truncate">
            {fileDetails?.name}
          </div>
          <label
            htmlFor={name}
            className={`${
              disabled ? "" : "cursor-pointer"
            } mr-2 bg-white hover:bg-[#ECF1FE] min-w-[40px] rounded-full h-10 flex justify-center items-center`}
          >
            <UploadIcon className="w-[22px] h-[22px] text-[#3f51b5]" />
          </label>
        </div>
      </div>
      <div>
        {errors[name] ? (
          <div className="w-full">
            {errors[name] ? (
              <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
                {errors[name].message}
              </span>
            ) : errors[name] ? (
              <span className="invisible">Helper Text</span>
            ) : (
              <span className="invisible">Helper Text</span>
            )}
          </div>
        ) : (
          isFileSupportMessageNeeded && (
            <div className="text-[#8F8F92] text-xs py-[5px] pl-[5px]">
              {`Supported Formats: ${
                supportFormat ? supportFormat : ".pdf"
              }, Max Limit: ${maxLimit ? maxLimit : "2 MB"}`}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FileUpload;
