import React, { FC, useEffect, useState } from "react";
interface IProps {
  radius: number;
  strokeWidth: number;
  progress: number;
  filledColor: string;
  unfilledColor: string;
  content: string;
}

const CircleProgress: FC<IProps> = ({
  radius,
  strokeWidth,
  progress,
  filledColor,
  unfilledColor,
  content,
}) => {
  const [circumference, setCircumference] = useState(0);

  useEffect(() => {
    const calcCircumference = () => 2 * Math.PI * radius;
    setCircumference(calcCircumference());
  }, [radius]);

  const normalizedRadius = radius - strokeWidth / 2;
  const strokeDashoffset =
    ((100 - progress) / 100) * (circumference + strokeWidth);

  return (
    <svg className="circle-progress" height={radius * 2} width={radius * 2}>
      <circle
        className="circle-progress-bar"
        stroke={unfilledColor}
        strokeWidth={strokeWidth}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className="circle-progress-indicator"
        stroke={filledColor}
        strokeWidth={strokeWidth}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform={`rotate(-90 ${radius} ${radius})`}
      />
      <text
        className="circle-progress-text"
        x={radius}
        y={radius}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="black"
        fontSize="15"
      >
        <tspan x={radius} dy="-0.5em" fontWeight="bold">
          {content}
        </tspan>
        <tspan x={radius} dy="1.8em" fontSize="12" fill="#A4A4A5">
          Total Hours
        </tspan>
      </text>
    </svg>
  );
};

export default CircleProgress;
