import { useReactiveVar } from "@apollo/client";

import TabLink from "components/App/TabLink/TabLink";

import { userContextData } from "global/helpers/Cache";

import AllRequests from "modules/Leave/Pages/Requests/AllRequests/AllRequests";
import MyRequest from "modules/Leave/Pages/Requests/MyRequest/MyRequest";
import TeamRequests from "modules/Leave/Pages/Requests/TeamRequests/TeamRequests";
import AllCompOffs from "modules/Leave/Pages/Requests/AllCompOffs/AllCompOffs";
import MyCompOff from "modules/Leave/Pages/Requests/MyCompOff/MyCompOff";
import TeamCompOff from "modules/Leave/Pages/Requests/TeamCompOff/TeamCompOff";

const RequestRouteControl = () => {
  const leaveAccess: any = useReactiveVar(userContextData);

  const tabLinks = [
    {
      name: "My Requests",
      link: "/leaves/requests/my-requests",
      allowedResourceIds: [],
      showTab: true,
    },
    {
      name: "Team Requests",
      link: "/leaves/requests/team-requests",
      allowedResourceIds: [],
      showTab:
        leaveAccess?.user?.isTeamLead || leaveAccess?.user?.isProjectManager
          ? true
          : false,
    },
    {
      name: " All Requests",
      link: "/leaves/requests/all-requests",
      allowedResourceIds: ["ReadAllLeaveRequests"],
    },
    {
      name: " My Comp Off",
      link: "/leaves/requests/my-comp-off",
      allowedResourceIds: [],
      showTab: true,
    },
    {
      name: "Team Comp Off",
      link: "/leaves/requests/team-comp-off",
      allowedResourceIds: [],
      showTab: leaveAccess?.user?.canApproveCompensationLeave ? true : false,
    },
    {
      name: "All Comp Off",
      link: "/leaves/requests/all-comp-off",
      allowedResourceIds: ["ReadAllCompensationLeaveRequest"],
    },
  ];

  const routes = [
    {
      path: "my-requests/*",
      element: <MyRequest />,
    },
    {
      path: "team-requests/*",
      element: <TeamRequests />,
    },
    {
      path: "all-requests/*",
      element: <AllRequests />,
    },
    {
      path: "my-comp-off/*",
      element: <MyCompOff />,
    },
    {
      path: "team-comp-off/*",
      element: <TeamCompOff />,
    },
    {
      path: "all-comp-off/*",
      element: <AllCompOffs />,
    },
  ];

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[800px] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default RequestRouteControl;
