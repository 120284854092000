import { AiOutlineExclamationCircle } from "react-icons/ai";

const Dashboard = () => {
  return (
    <div className="w-full h-[760px] flex-col flex justify-center items-center">
      <AiOutlineExclamationCircle className="w-[70px] h-[70px] text-teal-300" />
      <h1 className="text-[32px] font-bold text-blue-400">
        Under Construction
      </h1>
    </div>
  );
};

export default Dashboard;
