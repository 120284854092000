import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import {
  Aadhaar,
  Mobile,
  Name,
  Text,
  Select,
  Modal,
  Outlined,
  Filled,
  CalendarField,
} from "components/forms";
import FileUpload from "components/forms/File/FileUpload";

import { MdOutlineClose } from "react-icons/md";
import { currentDate } from "global/helpers/StaticData";
import { Base64toObject } from "global/helpers/FileConverter";
import { DateFormat, ViewDateFormat } from "global/helpers/DateFormatter";
import { removeDuplicates } from "global/helpers/ArrayMethods";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  GET_FAMILY_DETAILS,
  FAMILY_MEMBERS_RELATIONSHIPS,
} from "modules/Employee/services/queries";
import {
  CREATE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER,
} from "modules/Employee/services/mutations";
import {
  IFamilyMember,
  IFamilyMemberForm,
} from "modules/Employee/types/family";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import { IFileProperties } from "modules/Employee/types/academic";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  familyDetails: IFamilyMember[];
  editID: number | null;
  setEditID: Function;
  setShowModal: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetchFamilyDetails: Function;
}

const EditFamily: FC<IProps> = ({
  familyDetails,
  editID,
  setEditID,
  setShowModal,
  inProgress,
  setInProgress,
  refetchFamilyDetails,
}) => {
  const { data: familyMembersRelationships, loading: relationshipsLoader } =
    useQuery(FAMILY_MEMBERS_RELATIONSHIPS);

  const editFamilyDetails: IFamilyMember | any = familyDetails?.filter(
    (familyDetail: IFamilyMember) => +familyDetail?.id === editID
  )[0];

  const moderationObj = editFamilyDetails?.moderation || null;

  const moderationFieldProps = {
    refetchQuery: refetchFamilyDetails,
  };

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IFamilyMemberForm>();

  const [file, setFile] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<IFileProperties | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<any>();

  const hasAadharfile =
    editFamilyDetails?.documents && editFamilyDetails?.documents?.length > 0;

  useEffect(() => {
    if (editFamilyDetails?.dateOfBirth) {
      setDateOfBirth(
        editFamilyDetails?.dateOfBirth &&
          new Date(editFamilyDetails?.dateOfBirth?.replaceAll("-", " "))
      );
    }

    if (hasAadharfile) {
      setFile(editFamilyDetails?.documents[0]?.aadhaarFile);
    }
  }, [hasAadharfile, editFamilyDetails]);

  useEffect(() => {
    if (dateOfBirth) {
      setValue("dateOfBirth", dateOfBirth);
    }

    if (file) {
      setValue("aadhaarFile", file);
    }
  }, [dateOfBirth, file, setValue]);

  useEffect(() => {
    if (dateOfBirth) {
      clearErrors("dateOfBirth");
    }

    if (file) {
      clearErrors("aadhaarFile");
    }
  }, [clearErrors, dateOfBirth, file]);

  const [createUserFamilyMember] = useMutation(CREATE_FAMILY_MEMBER);
  const [updateUserFamilyMember] = useMutation(UPDATE_FAMILY_MEMBER);

  const isUnderModeration = useIsUnderModeration();

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Family detail")
    : createSuccessMessageWithAwaiting("Family detail");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Family detail")
    : updateSuccessMessageWithAwaiting("Family detail");

  const createHandler = (data: IFamilyMemberForm) => {
    const dateOfBirthYear: any =
      dateOfBirth && DateFormat(dateOfBirth?.toString())?.slice(0, 4);

    if (
      dateOfBirthYear < currentDate.getFullYear() - 100 ||
      currentDate.getFullYear() -
        (data.relationshipType === "Child" ||
        data.relationshipType === "Sibling"
          ? 1
          : 19) <
        dateOfBirthYear
    ) {
      setError("dateOfBirth", {
        type: "custom",
        message: "Please enter the valid date of birth.",
      });
    } else if (!inProgress) {
      createUserFamilyMember({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          name: data.name?.trim(),
          relationshipType: data.relationshipType,
          dateOfBirth: ViewDateFormat(dateOfBirth?.toString()),
          occupation: data.occupation ? data.occupation : undefined,
          isdCode: (data.mobile && data.country) || null,
          mobile: (data.mobile && data.mobile?.toString()) || null,
          aadhaar: (data.aadhaar && data.aadhaar?.toString()) || null,
          documents: (file && [{ aadhaarFile: file }]) || null,
        },
        update: (cache, { data: { createUserFamilyMember } }) => {
          const exFamilyMembers: any = cache.readQuery({
            query: GET_FAMILY_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });

          const updatedFamilyMembers = [
            ...exFamilyMembers?.users?.dataCollection[0]?.userFamilyMembers,
            createUserFamilyMember,
          ];

          cache.writeQuery({
            query: GET_FAMILY_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exFamilyMembers?.users?.dataCollection[0],
                    userFamilyMembers: updatedFamilyMembers,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: IFamilyMemberForm) => {
    const dateOfBirthYear: any =
      dateOfBirth && DateFormat(dateOfBirth?.toString())?.slice(0, 4);

    if (
      dateOfBirthYear < currentDate.getFullYear() - 100 ||
      currentDate.getFullYear() -
        (editFamilyDetails?.relationshipType === "Child" ||
        editFamilyDetails?.relationshipType === "Sibling"
          ? 1
          : 19) <
        dateOfBirthYear
    ) {
      setError("dateOfBirth", {
        type: "custom",
        message: "Please enter the valid date of birth.",
      });
    } else if (!inProgress) {
      updateUserFamilyMember({
        variables: {
          id: editFamilyDetails?.id,
          name: data.name?.trim(),
          relationshipType: editFamilyDetails?.relationshipType,
          dateOfBirth: ViewDateFormat(dateOfBirth?.toString()),
          occupation: (data.occupation && data.occupation) || null,
          isdCode: (data.mobile && data.country) || null,
          mobile: (data.mobile && data.mobile?.toString()) || null,
          aadhaar: (data.aadhaar && data.aadhaar?.toString()) || null,
          documents: fileDetails?.name
            ? [
                {
                  aadhaarFile: file,
                },
              ]
            : undefined,
        },
        update: (cache, { data: { updateUserFamilyMember } }) => {
          const exFamilyMembers: any = cache.readQuery({
            query: GET_FAMILY_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });

          const updatedFamilyMembers =
            exFamilyMembers?.users?.dataCollection[0]?.userFamilyMembers?.map(
              (member: IFamilyMember) => {
                if (+member.id === +editFamilyDetails?.id) {
                  return {
                    ...member,
                    name: updateUserFamilyMember?.name,
                    isdCode: updateUserFamilyMember?.isdCode,
                    relationshipType: updateUserFamilyMember?.relationshipType,
                    dateOfBirth: updateUserFamilyMember?.dateOfBirth,
                    occupation: updateUserFamilyMember?.occupation,
                    mobile: updateUserFamilyMember?.mobile,
                    aadhaar: updateUserFamilyMember?.aadhaar,
                    documents: updateUserFamilyMember?.documents,
                    moderation: updateUserFamilyMember?.moderation,
                  };
                }
                return member;
              }
            );

          cache.writeQuery({
            query: GET_FAMILY_DETAILS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exFamilyMembers?.users?.dataCollection[0],
                    userFamilyMembers: updatedFamilyMembers,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setDateOfBirth(null);
    setFile(null);
    setEditID(null);
  };

  const familyRelation = removeDuplicates(
    familyDetails?.map(
      (family: { relationshipType: string }) => family?.relationshipType
    )
  );

  const filteredRelationship =
    familyMembersRelationships?.getFamilyMembersRelationships?.filter(
      (relations: string) => !familyRelation?.includes(relations)
    );

  useEffect(() => {
    if (familyMembersRelationships) {
      setValue(
        "relationshipType",
        editFamilyDetails?.relationshipType
          ? editFamilyDetails?.relationshipType
          : ""
      );
    }

    if (editFamilyDetails?.name) {
      setValue("name", editFamilyDetails?.name);
    }

    if (editFamilyDetails?.occupation) {
      setValue("occupation", editFamilyDetails?.occupation);
    }

    if (editFamilyDetails?.mobile) {
      setValue("mobile", +editFamilyDetails?.mobile);
    }

    if (editFamilyDetails?.aadhaar) {
      setValue("aadhaar", +editFamilyDetails?.aadhaar);
    }
  }, [editFamilyDetails, familyMembersRelationships, setValue]);

  const relationShipTypeOptions = editFamilyDetails?.relationshipType
    ? familyMembersRelationships?.getFamilyMembersRelationships
    : filteredRelationship;

  const aadhaarFile =
    editFamilyDetails?.documents &&
    editFamilyDetails?.documents[0]?.aadhaarFile?.length >= 100 ? (
      <div className="mt-[15px] ml-[12px] absolute z-10">
        <a
          href={Base64toObject(editFamilyDetails?.documents[0]?.aadhaarFile)}
          target="_blank"
          rel="noreferrer"
          className="text-cornflower-blue cursor-pointer hover:underline"
        >
          View File
        </a>
      </div>
    ) : (
      <div className="mt-[15px] ml-[12px] absolute">
        <span className="text-cornflower-blue">
          {hasAadharfile &&
          editFamilyDetails?.documents[0]?.aadhaarFile === null
            ? ""
            : "No File"}
        </span>
      </div>
    );

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div pl-[18px] pr-[28px] lg:pl-[45px] lg:pr-[57px] boder-hit-gray">
          <h2 className="edit-modal-heading">Family Information</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {!editFamilyDetails?.id && relationshipsLoader ? (
            <div className="min-h-[539px] lg:min-h-[639px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              onSubmit={
                editFamilyDetails?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="pr-[35px] form-div lg:pr-[45px] max-h-[450px] md:max-h-[500px] lg:max-h-[550px] overflow-y-auto scrollbar">
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.name}
                >
                  <Name
                    register={register}
                    errors={errors}
                    label="Name *"
                    name="name"
                    required={true}
                    defaultName={editFamilyDetails?.name}
                    disable={!!moderationObj?.name}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.relationshipType}
                >
                  <Select
                    register={register}
                    name="relationshipType"
                    errors={errors}
                    label="Relationship Type *"
                    options={relationShipTypeOptions}
                    required={!editFamilyDetails?.relationshipType}
                    defaultSelectValue={editFamilyDetails?.relationshipType}
                    disable={
                      !!editFamilyDetails?.relationshipType ||
                      !!moderationObj?.relationshipType
                    }
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.dateOfBirth}
                >
                  <CalendarField
                    register={register}
                    errors={errors}
                    name="dateOfBirth"
                    date={dateOfBirth}
                    setDate={setDateOfBirth}
                    className="h-[50px]"
                    label="Date Of Birth"
                    minDate={new Date(1950, 0, 1)}
                    maxDate={new Date(new Date()?.getFullYear() - 1, 11, 31)}
                    startDate={`${+new Date().getFullYear() - 1}-${
                      +new Date().getMonth() + 1
                    }-${new Date().getDate()}`}
                    disabled={!!moderationObj?.dateOfBirth}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.occupation}
                >
                  <Text
                    register={register}
                    errors={errors}
                    label="Occupation"
                    name="occupation"
                    defaultText={editFamilyDetails?.occupation}
                    maximumLength={35}
                    disable={!!moderationObj?.occupation}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.mobile}
                >
                  <Mobile
                    register={register}
                    errors={errors}
                    defaultMobile={+editFamilyDetails?.mobile || undefined}
                    defaultCountryCode={editFamilyDetails?.isdCode}
                    label={"Mobile"}
                    disabled={!!moderationObj?.mobile}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.aadhaar}
                >
                  <Aadhaar
                    register={register}
                    errors={errors}
                    label="Aadhaar"
                    name="aadhaar"
                    defaultAadhaar={editFamilyDetails?.aadhaar}
                    disabled={!!moderationObj?.aadhaar}
                  />
                </ModerationField>
                <div className="relative">
                  {fileDetails?.name ? "" : aadhaarFile}

                  <ModerationField
                    {...moderationFieldProps}
                    moderationId={moderationObj?.aadhaarFile}
                  >
                    <FileUpload
                      register={register}
                      errors={errors}
                      name="aadhaarFile"
                      labelName="Aadhaar File"
                      file={file || ""}
                      setFile={setFile}
                      fileDetails={fileDetails}
                      setFileDetails={setFileDetails}
                      defaultFileValue={
                        (hasAadharfile &&
                          editFamilyDetails?.documents[0]?.aadhaarFile) ||
                        null
                      }
                      disabled={!!moderationObj?.aadhaarFile}
                    />
                  </ModerationField>
                </div>
              </div>
              <div className="w-full border-b pt-[6px]" />
              <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
                <Outlined onClick={moduleCloseHandler} buttonName={"Cancel"} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : editFamilyDetails?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditFamily;
