import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Modal, Outlined } from "components/forms";
import { Filled, TextArea } from "components/forms";

import { MdOutlineClose } from "react-icons/md";
import { toastNotify } from "global/helpers/Cache";
import { FC } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_NOTIFICATION_STATUS } from "layouts/header/TopLinks/Notification/services/mutations";

interface DeclineMessge {
  declineMessage: string;
}
const DeclinePage: FC<{
  setShowDeclinePage: Function;
  setShowUnderReview?: any;
  updateProfileModeration: Function;
  employeeId?: any;
  refetchData?: Function;
  refetchUserProfileModeration?: Function;
  navPagestring?: string;
  unReadNotificationID?: number;
}> = ({
  setShowDeclinePage,
  setShowUnderReview,
  updateProfileModeration,
  employeeId,
  refetchData,
  refetchUserProfileModeration,
  navPagestring,
  unReadNotificationID,
}) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DeclineMessge>();

  const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const onsubmit = (declineMessage: DeclineMessge) => {
    if (updateProfileModeration) {
      updateProfileModeration({
        variables: {
          ids: employeeId,
          status: "declined",
          declineReason: declineMessage.declineMessage,
        },
      })
        .then(() => {
          if (unReadNotificationID) {
            updateNotificationStatus({
              variables: {
                id: [unReadNotificationID],
                isRead: true,
              },
            });
          }
          navPagestring && navigate(navPagestring);
          setShowDeclinePage(false);
          if (refetchData) refetchData();
          if (refetchUserProfileModeration) refetchUserProfileModeration();
          if (setShowUnderReview) setShowUnderReview(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
        })
        .catch((error: { message: any }) =>
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ])
        );
    }
  };
  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">Decline Reason</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => setShowDeclinePage(false)}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="form-div pr-[18px] lg:pr-[45px] min-h-[150px]">
            <TextArea
              register={register}
              errors={errors}
              label={"Decline Reason"}
              name={"declineMessage"}
              required={true}
            />
          </div>
          <div className="w-full border-t border-hit-gray mt-[6px]" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined
              onClick={() => {
                setShowDeclinePage(false);
              }}
              buttonName={"Cancel"}
            />
            <Filled buttonName={"Decline"} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DeclinePage;
