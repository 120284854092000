import { gql } from "@apollo/client";

export const COMPANY_FRAGMENT = gql`
  fragment companyFields on Company {
    name
    legalName @include(if: $isLegalNameNeed)
    status @include(if: $isStatusNeed)
    companyType @include(if: $isCompanyTypeNeed)
    companyOwners @include(if: $isCompanyOwnersNeed) {
      personalInformation {
        id
        name
      }
    }
    companyManagers @include(if: $isCompanyManagersNeed) {
      personalInformation {
        id
        name
      }
    }
    openingTime @include(if: $isOpeningTimeNeed)
    closingTime @include(if: $isClosingTimeNeed)
    address @include(if: $isAddressNeed)
    altIsdCode @include(if: $isAltIsdCode)
    altMobile @include(if: $isAltMobileNeed)
    cin @include(if: $isCINNeed)
    companyLogo @include(if: $isCompanyLogoNeed)
    createdAt @include(if: $isCreatedAtNeed)
    email @include(if: $isEmailNeed)
    gstin @include(if: $isGstinNeed)
    id
    isCinVerified @include(if: $isCinVerifiedNeed)
    isEmailVerified @include(if: $isEmailVerifiedNeed)
    isGstinVerified @include(if: $isGstinVerifiedNeed)
    isPanVerified @include(if: $isPanVerifiedNeed)
    isdCode @include(if: $isIsdCodeNeed)
    joinedAt @include(if: $isJoinedAtNeed)
    mobile @include(if: $isMobileNeed)
    pan @include(if: $isPanNeed)
    updatedAt @include(if: $isUpdatedAtNeed)
  }
`;
