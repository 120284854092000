import { IModeration } from "modules/Employee/types/moderation";

const getUnderReviews = (cardData: any) => {
  const underReviews: any = [];

  cardData &&
    cardData?.moderation?.details?.map((moderationDetails: IModeration) => {
      if (moderationDetails?.moderationId) {
        underReviews?.push(...underReviews, {
          moderationId: moderationDetails?.moderationId,
          fieldName: moderationDetails?.fieldName,
          previousValue: cardData?.[moderationDetails?.propertyName],
          updatedValue: moderationDetails?.updatedValue,
        });
      }
      return null;
    });
  return underReviews;
};

export const getUpdatedUnderReviews = (cardData: any) => {
  const underReviews: any = getUnderReviews(cardData);

  return (
    underReviews?.filter(
      (value: { moderationId: any }, index: any, array: any[]) =>
        array?.findIndex(
          (value2) => value2?.moderationId === value?.moderationId
        ) === index
    ) || []
  ); // Return empty array if underReviews is false
};
