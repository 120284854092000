import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import TabLink from "components/App/TabLink/TabLink";
import Loading from "components/Loader/Loading";

import Projects from "modules/Project/Pages/Projects";
import { FILTER_PROJECT_GROUPS } from "modules/Project/Pages/Projects/ProjectGroups/services";
import ProjectGroupBugsRouteControl from "modules/Project/Pages/Projects/ProjectGroupBugs/ProjectGroupBugsRouteControl";
import ProjectGroupTasksRouteControl from "modules/Project/Pages/Projects/ProjectGroupTasks/ProjectGroupTasksRouteControl";

interface IProps {
	allowedResources: string[];
}

const MyProjectAllProjectRouteGroupBugTasksRouteControlForProjectGroup: FC<
	IProps
> = ({ allowedResources }) => {
	const { groupId } = useParams();
	const { pathname } = useLocation();
	const projectType = pathname?.split("/")[3];

	const { data: filterProjectGroups, loading } = useQuery(
		FILTER_PROJECT_GROUPS,
		{
			variables: {
				filters: groupId
					? {
							id: {
								number: +groupId,
							},
					  }
					: undefined,
			},
		},
	);

	const projectGroup =
		filterProjectGroups?.filterProjectGroups?.edges &&
		filterProjectGroups?.filterProjectGroups?.edges?.length > 0 &&
		filterProjectGroups?.filterProjectGroups?.edges[0]?.node
			? filterProjectGroups?.filterProjectGroups?.edges[0]?.node
			: null;

	const tabLinks = [
		{
			name: "My projects",
			link: `/projects/${groupId}/my-projects`,
			allowedResourceIds: ["MyProject"],
		},
		{
			name: "All projects",
			link: `/projects/${groupId}/all-projects`,
			allowedResourceIds: ["AllProject"],
		},
		{
			name: "Trash",
			link: `/projects/${groupId}/trash`,
			allowedResourceIds: ["AllProject"],
		},
		{
			name: "Bugs",
			link: `/projects/${groupId}/bugs`,
			allowedResourceIds: ["ReadProjectGroupBug"],
		},
		{
			name: "Tasks",
			link: `/projects/${groupId}/tasks`,
			allowedResourceIds: ["ReadProjectGroupTask"],
		},
	];

	const routes = [
		{
			path: "my-projects",
			element: (
				<Projects
					allowedResources={allowedResources}
					isMyProject={projectType === "my-projects" ? true : false}
					projectGroup={projectGroup}
				/>
			),
		},
		{
			path: "all-projects",
			element: (
				<Projects
					allowedResources={allowedResources}
					isMyProject={false}
					projectGroup={projectGroup}
				/>
			),
		},
		{
			path: "trash",
			element: (
				<Projects
					allowedResources={allowedResources}
					isMyProject={false}
					projectGroup={projectGroup}
				/>
			),
		},
		{
			path: "bugs/*",
			element: (
				<ProjectGroupBugsRouteControl
					allowedResources={allowedResources}
					projectGroup={projectGroup}
				/>
			),
		},
		{
			path: "tasks/*",
			element: (
				<ProjectGroupTasksRouteControl
					allowedResources={allowedResources}
					projectGroup={projectGroup}
				/>
			),
		},
	];

	return loading ? (
		<Loading className="min-h-[calc(100vh-22px)]" />
	) : (
		<div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh - 22vh)] bg-white">
			<TabLink tabLinks={tabLinks} routes={routes} />
		</div>
	);
};

export default MyProjectAllProjectRouteGroupBugTasksRouteControlForProjectGroup;
