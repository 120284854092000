import { FC } from "react";
import { HiViewList } from "react-icons/hi";

import { ReactComponent as AcceptIcon } from "global/assets/images/accept-icon.svg";
import { ReactComponent as DeclineIcon } from "global/assets/images/decline-icon.svg";

interface IProps {
  onView?: () => void | undefined;
  onAccept?: () => void | undefined;
  onDecline?: () => void | undefined;
}

const MeatBallsMenu: FC<IProps> = ({ onView, onAccept, onDecline }) => {
  return (
    <div className="absolute flex flex-col drop-shadow-[0px_0px_10px_#ABABAB29] rounded-[4px] bg-white after:content-[''] after:absolute after:-ml-[10px] after:border-[10px] after:-top-5 after:left-[90%] after:border-[transparent_transparent_white_transparent] py-[12px]">
      {onView && (
        <div
          className="cursor-pointer py-[6px] pl-[27px] pr-[28px] flex group items-center hover:bg-blue-200"
          onClick={onView}
        >
          <HiViewList className="w-[14px] h-[14px] text-cornflower-blue cursor-pointer" />
          <span className="pl-[8px] text-[14px] leading-[17px] text-cornflower-blue">
            View
          </span>
        </div>
      )}
      {onAccept && (
        <div
          className="cursor-pointer py-[6px] pl-[27px] pr-[28px] flex group items-center hover:bg-[#D8FFE8]"
          onClick={onAccept}
        >
          <AcceptIcon className="w-[14px] h-[14px] text-[#3BC171] cursor-pointer" />
          <span className="pl-[8px] text-[14px] leading-[17px] text-[#3BC171]">
            Accept
          </span>
        </div>
      )}
      {onDecline && (
        <div
          className="cursor-pointer py-[6px] pl-[27px] pr-[28px] flex group items-center hover:bg-[#FFD9E0]"
          onClick={onDecline}
        >
          <DeclineIcon className="w-[14px] h-[14px] text-[#ee554f] cursor-pointer" />
          <span className="pl-[8px] text-[14px] leading-[17px] text-[#ee554f]">
            Decline
          </span>
        </div>
      )}
    </div>
  );
};

export default MeatBallsMenu;
