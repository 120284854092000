import { getTweleveHourTime } from "global/helpers/DateFormatter";
import { useEffect, useState } from "react";
import { HiClock } from "react-icons/hi";

const Timer: React.FC<{
  register: Function;
  name: string;
  setValue: Function;
  selectedTime: string;
  setSelectedTime: Function;
}> = ({ register, name, setValue, selectedTime, setSelectedTime }) => {
  const currentHour = new Date().getHours();

  const [hour, setHour] = useState<string>(
    selectedTime?.slice(0, 2)
      ? selectedTime?.slice(0, 2)
      : `${
          currentHour <= 12 && currentHour !== 0
            ? currentHour?.toString().padStart(2, "0")
            : getTweleveHourTime(new Date().getHours().toString())
                ?.toString()
                .padStart(2, "0")
        }`
  );

  const [minute, setMinute] = useState<string>(
    selectedTime?.slice(3, 5)
      ? selectedTime?.slice(3, 5)
      : new Date().getMinutes().toString().padStart(2, "0")
  );
  const [meridian, setMeridian] = useState<string>(
    selectedTime?.slice(6, 8)
      ? selectedTime?.slice(6, 8)
      : new Date().getHours() > 11
      ? "PM"
      : "AM"
  );

  const elements: any[] = [];
  for (let index = 0; index < 60; index++) {
    if (index <= 9) {
      elements.push("0" + index);
    } else {
      elements.push(index);
    }
  }

  useEffect(() => {
    setValue(name, `${hour}:${minute} ${meridian}`);
  }, [setValue, name, hour, minute, meridian]);

  useEffect(() => {
    setSelectedTime(`${hour}:${minute} ${meridian}`);
  }, [hour, minute, meridian, setSelectedTime]);

  return (
    <div className="flex justify-center items-center rounded-[6px] shadow border border-[#E5E5E5] w-32 pr-1 h-[52px] relative">
      <HiClock className="w-5 h-5 ml-1 cursor-pointer text-cornflower-blue" />
      <div className="w-24 flex justify-center items-center">
        <input
          key={name}
          type="text"
          hidden
          value={`${hour}:${minute} ${meridian}`}
          {...register(name)}
          className="focus:outline-none focus:border-none w-[110px]"
        />
        <select
          className="time-picker-select"
          defaultValue={hour ? hour : "--"}
          onChange={(event: any) => {
            setHour(event.target.value);
          }}
        >
          <option value="--" disabled>
            --
          </option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <span>:</span>
        <select
          className="time-picker-select"
          defaultValue={minute ? minute : "--"}
          onChange={(event: any) => setMinute(event.target.value)}
        >
          <option value="--" disabled>
            --
          </option>
          {elements.map((element: any) => (
            <option key={element} value={element}>
              {element}
            </option>
          ))}
        </select>
        <span>:</span>
        <select
          className="time-picker-select"
          defaultValue={meridian ? meridian : "--"}
          onChange={(event: any) => setMeridian(event.target.value)}
        >
          <option value="--" disabled>
            --
          </option>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
};

export default Timer;
