import { detectMimeType } from "./mimeType";
export const fileToBase64 = (file: any, cb: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

export const Base64toObject = (data: string) => {
  if (data === null || data === undefined || data?.length <= 100) return "";
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  const blob = new Blob([out], {
    type: detectMimeType(data) ? detectMimeType(data) : "text/plain",
  });
  return URL.createObjectURL(blob);
};

export const getFileExtensionUsingName = (filepath: string) => {
  return filepath?.slice(((filepath?.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const dataURLtoBlobForFileUpload = (dataURL: string) => {
  const bytes = atob(dataURL);
  let length = bytes.length;
  const out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  const blob = new Blob([out], {
    type:
      detectMimeType(dataURL) === "application/pdf"
        ? "application/pdf"
        : "image/png,image/jpg,image/jpeg",
  });
  return blob;
};

export const dataURLtoBlobForCaptureCamera = (
  dataURI: string,
  type?: string | null
) => {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], {
    type: type || "image/jpeg",
  });
  return blob;
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
};
