import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import Card from "components/UI/Card";
import { Filled } from "components/forms";

import { userQueryProps } from "global/helpers/Cache";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";

import EmergencyContact from "modules/Employee/Pages/Profile/Contact/EmergencyContact/EmergencyContact";
import { GET_CONTACT_ADDRESSES } from "modules/Employee/services/queries";
import { IAddress } from "modules/Employee/types/contacts";
import Address from "modules/Employee/Pages/Profile/Contact/Address/Address";
import Loading from "components/Loader/Loading";

const Contact = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchContatctAddresses,
  } = useQuery(GET_CONTACT_ADDRESSES, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const addressResourceAccess = useGetAllowedResources("EmployeeAddress");
  const emergencyContactResourceAccess = useGetAllowedResources(
    "EmployeeEmergencyContactInformation"
  );

  const [showModal, setShowModal] = useState(false);
  const [contact, setContact] = useState<IAddress | null>(null);
  const [addresses, setAddresses] = useState<IAddress[] | []>([]);

  const addAddress = () => {
    setShowModal(!showModal);
    setContact(null);
  };

  useEffect(() => {
    if (userDetails?.users?.dataCollection[0]?.userAddresses) {
      setAddresses(
        userDetails?.users?.dataCollection[0]?.userAddresses
          ?.map((userAddress: IAddress) => {
            if (userAddress.type === "Permanent") {
              return { ...userAddress, order: 1 };
            }
            if (userAddress.type === "Current") {
              return { ...userAddress, order: 2 };
            }
            if (userAddress.type === "Postal") {
              return { ...userAddress, order: 3 };
            }
            return null;
          })
          .sort(
            (
              firstUserAddress: { order: number },
              secondUserAddress: { order: number }
            ) => firstUserAddress?.order - secondUserAddress?.order
          )
      );
    }
  }, [userDetails?.users?.dataCollection]);

  return (
    <div className="grid md:grid-cols-2 gap-x-3 lg:gap-x-5">
      {addressResourceAccess?.canRead && (
        <Address
          resourceAccess={addressResourceAccess}
          loading={loading}
          addresses={addresses}
          contact={contact}
          setContact={setContact}
          showModal={showModal}
          setShowModal={setShowModal}
          refetchContatctAddresses={refetchContatctAddresses}
        />
      )}
      {emergencyContactResourceAccess?.canRead && (
        <EmergencyContact resourceAccess={emergencyContactResourceAccess} />
      )}
      {addresses?.length < 3 && (
        <Card>
          <div className="w-full min-h-[390px] grid place-content-center">
            {loading ? (
              <Loading />
            ) : (
              userDetails && (
                <Filled
                  buttonName={"Add Address"}
                  buttonType="button"
                  onClick={addAddress}
                />
              )
            )}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Contact;
