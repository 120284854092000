import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";

import TabLink from "components/App/TabLink/TabLink";
import Loading from "components/Loader/Loading";

import { userContextData } from "global/helpers/Cache";

import { GET_PROJECT } from "modules/Project/services/queries";
import ProjectsGeneral from "modules/Project/Pages/General/ProjectsGeneral";
import Members from "modules/Project/Pages/Members/Members";
import Vault from "modules/Project/Pages/Vault/Vault";
import Sprints from "modules/Project/Pages/Sprints/Sprints";
import Columns from "modules/Project/Pages/Columns/Columns";
import Issues from "modules/Project/Pages/Issues/Issues";
import TaskLabel from "modules/Project/Pages/TaskLabel/TaskLabel";
import { FILTER_PROJECT_GROUPS } from "modules/Project/Pages/Projects/ProjectGroups/services";
import ProjectBacklogsRouteControl from "modules/Project/Pages/Backlogs/ProjectBacklogsRouteControl";

const ProjectsGeneralAndVaultRouterControlForProjectGroups: FC<{
	loadProjectRoleItemQuery: Function;
	allowedResourcesForProject: string[];
	allowedResources: string[];
	allowedResourcesForProjectUpdated: { [key: string]: boolean };
}> = ({
	loadProjectRoleItemQuery,
	allowedResourcesForProject = [],
	allowedResources,
	allowedResourcesForProjectUpdated,
}) => {
	const { id, groupId, projectType } = useParams();

	const [fetchProject, { data, loading: projectLoading }] = useLazyQuery(
		GET_PROJECT,
		{
			fetchPolicy: "network-only",
		},
	);

	const { data: filterProjectGroups, loading: projectGroupLoading } = useQuery(
		FILTER_PROJECT_GROUPS,
		{
			variables: {
				filters: groupId
					? {
							id: {
								number: +groupId,
							},
					  }
					: undefined,
			},
		},
	);

	useEffect(() => {
		if (id && !isNaN(+id)) {
			if (projectType === "my-projects") {
				fetchProject({
					variables: {
						filters: {
							id: +id,
							isMyProject: true,
						},
					},
				});
			} else if (projectType === "all-projects") {
				fetchProject({
					variables: {
						filters: {
							id: +id,
						},
					},
				});
			} else {
				fetchProject({
					variables: {
						filters: {
							id: +id,
							isTrashed: true,
						},
					},
				});
			}
		}
	}, [id, fetchProject, projectType]);

	useEffect(() => {
		if (id && !isNaN(+id)) {
			loadProjectRoleItemQuery({
				variables: {
					projectId: id ? +id : 0,
				},
			});
		}
	}, [id, loadProjectRoleItemQuery]);

	const editProject =
		data?.projects?.dataCollection?.length > 0
			? data?.projects?.dataCollection[0]
			: undefined;

	const projectIDandName = {
		id: editProject?.id,
		name: editProject?.name,
	};

	const projectGroup =
		filterProjectGroups?.filterProjectGroups?.edges &&
		filterProjectGroups?.filterProjectGroups?.edges?.length > 0 &&
		filterProjectGroups?.filterProjectGroups?.edges[0]?.node
			? filterProjectGroups?.filterProjectGroups?.edges[0]?.node
			: null;

	const userDataList: any = useReactiveVar(userContextData);
	const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
	const isManager = editProject?.manager?.id === userDataList?.user?.id;

	const tabLinks =
		id === "new"
			? [
					{
						name: "General",
						link: `/projects/${groupId}/${projectType}/edit/${id}/general`,
						allowedResourceIds: ["ReadProject"],
						showTab: isSuperAdmin ? true : false,
					},
			  ]
			: [
					{
						name: "General",
						link: `/projects/${groupId}/${projectType}/edit/${id}/general`,
						allowedResourceIds: ["ReadProject"],
						showTab: isSuperAdmin ? true : false,
					},
					{
						name: "Board",
						link: `/projects/${groupId}/${projectType}/edit/${id}/issues`,
						allowedResourceIds: [],
						showTab: true,
					},
					{
						name: "Members",
						link: `/projects/${groupId}/${projectType}/edit/${id}/members`,
						allowedResourceIds: ["ReadProjectMember"],
						showTab:
							allowedResourcesForProject?.includes("ReadProjectMember") ||
							isManager ||
							isSuperAdmin
								? true
								: false,
					},
					{
						name: "Vault",
						link: `/projects/${groupId}/${projectType}/edit/${id}/vault`,
						allowedResourceIds: [
							"ReadProjectVaultCredential",
							"ProjectVaultCredential",
						],
						showTab:
							allowedResourcesForProject?.includes(
								"ReadProjectVaultCredential",
							) ||
							allowedResources?.includes("ProjectVaultCredential") ||
							isSuperAdmin
								? true
								: false,
					},
					{
						name: "Task label",
						link: `/projects/${groupId}/${projectType}/edit/${id}/task-label`,
						allowedResourceIds: [],
						showTab: isSuperAdmin ? true : false || isManager ? true : false,
					},
					{
						name: "Sprints",
						link: `/projects/${groupId}/${projectType}/edit/${id}/sprints`,
						allowedResourceIds: ["SaveProjectSprint", "DeleteProjectSprint"],
						showTab:
							allowedResourcesForProject?.includes("SaveProjectSprint") ||
							allowedResourcesForProject?.includes("DeleteProjectSprint") ||
							isSuperAdmin ||
							isManager
								? true
								: false,
					},
					{
						name: "Columns",
						link: `/projects/${groupId}/${projectType}/edit/${id}/columns`,
						allowedResourceIds: ["SaveProjectColumn", "DeleteProjectColumn"],
						showTab:
							allowedResourcesForProject?.includes("SaveProjectColumn") ||
							allowedResourcesForProject?.includes("DeleteProjectColumn") ||
							isSuperAdmin ||
							isManager
								? true
								: false,
					},
					{
						name: "Backlogs",
						link: `/projects/${groupId}/${projectType}/edit/${id}/backlogs`,
						allowedResourceIds: ["ReadProjectBacklog"],
					},
			  ];

	const routes =
		id === "new"
			? [
					{
						path: "general",
						element: (
							<ProjectsGeneral
								allowedResources={allowedResources}
								projectGroup={projectGroup}
							/>
						),
					},
			  ]
			: [
					{
						path: "general",
						element: (
							<ProjectsGeneral
								editProject={editProject}
								allowedResources={allowedResources}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "issues",
						element: (
							<Issues
								allowedResourcesForProject={allowedResourcesForProjectUpdated}
								editProject={projectIDandName}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "members",
						element: (
							<Members
								editProject={projectIDandName}
								allowedResourcesForProject={allowedResourcesForProject}
								isManager={isManager}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "vault",
						element: (
							<Vault
								editProject={projectIDandName}
								allowedResourcesForProject={allowedResourcesForProject}
								allowedResources={allowedResources}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "task-label",
						element: (
							<TaskLabel
								editProject={projectIDandName}
								allowedResourcesForProject={allowedResourcesForProject}
								isManager={isManager}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "sprints",
						element: (
							<Sprints
								editProject={projectIDandName}
								allowedResourcesForProject={allowedResourcesForProjectUpdated}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "columns",
						element: (
							<Columns
								editProject={projectIDandName}
								allowedResourcesForProject={allowedResourcesForProjectUpdated}
								projectGroup={projectGroup}
							/>
						),
					},
					{
						path: "backlogs/*",
						element: (
							<ProjectBacklogsRouteControl
								editProject={projectIDandName}
								projectGroup={projectGroup}
							/>
						),
					},
			  ];

	return projectLoading || projectGroupLoading ? (
		<Loading className="min-h-[calc(100vh-22px)]" />
	) : (
		<div className="rounded-md shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh-13vh)] bg-white">
			<TabLink tabLinks={tabLinks} routes={routes} />
		</div>
	);
};

export default ProjectsGeneralAndVaultRouterControlForProjectGroups;
