import { useEffect, useState } from "react";
import { Filled, Modal, Outlined, Text } from "components/forms";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";

import SelectWithId from "components/forms/Select/SelectWithId";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import Loading from "components/Loader/Loading";

import { getArrayObjectDifference } from "global/helpers/ArrayMethods";
import { toastNotify } from "global/helpers/Cache";
import { GET_ALL_EMPLOYEES_DROP_DOWN } from "modules/Employee/services/queries";
import {
  ALL_TEAMS,
  GET_NON_TEAM_MEMBERS_EMPLOYEES_QUERY,
} from "modules/Teams/services/queries";
import { CREATE_TEAM, UPDATE_TEAM } from "modules/Teams/services/mutations";
import { EmployeesListType } from "modules/Teams/types/teams";
import { MdOutlineClose } from "react-icons/md";

interface teamMembers {
  teamName: string;
  teamCode: string;
  teamLead: any;
  teamMembers: any;
}

type MemberDetailsType = {
  id: number;
  name: string;
  personalEmail: string;
  employeeDetail: {
    employeeId: string;
    officialEmail: string;
  };
};
type TeamLeadDetailsType = {
  id: number;
  name: string;
  employeeDetail: {
    employeeId: string;
  };
};
export interface TeamsType {
  id: number;
  name: string;
  code: string;
  members: MemberDetailsType[];
  teamLeadUser: TeamLeadDetailsType;
}

const EditAllTeams: React.FC<{
  editAllTeamsDetails: any;
  setShowModal: Function;
  setEditAllTeamsDetails: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetchTeams: Function;
}> = ({
  setShowModal,
  editAllTeamsDetails,
  setEditAllTeamsDetails,
  inProgress,
  setInProgress,
  refetchTeams,
}) => {
  const { data: allEmployees, loading: allEmployeesLoader } = useQuery(
    GET_ALL_EMPLOYEES_DROP_DOWN,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<teamMembers>();

  const {
    data: getNonTeamMemberEmployees,
    loading: nonTeamMemberEmployeesLoader,
  } = useQuery(GET_NON_TEAM_MEMBERS_EMPLOYEES_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [createEmployeeTeam] = useMutation(CREATE_TEAM);

  const [updateEmployeeTeam] = useMutation(UPDATE_TEAM);

  const employeesOption =
    allEmployees?.getAllEmployeesForDropDown?.map(
      (employee: { personalInformation: { id: number; name: string } }) =>
        employee?.personalInformation
    ) || [];

  const [nonTeamMembers, setNonTeamMembers] = useState<any[]>([]);
  let teamLeadId = +watch("teamLead");
  useEffect(() => {
    setNonTeamMembers(
      getNonTeamMemberEmployees
        ? !isNaN(teamLeadId)
          ? getNonTeamMemberEmployees?.getNonTeamMemberEmployees
              ?.map((employeeDetails: EmployeesListType) => {
                return {
                  id: employeeDetails?.id,
                  name: employeeDetails?.personalInformation?.name,
                };
              })
              ?.filter((teamMember: any) => teamMember?.id !== teamLeadId)
          : getNonTeamMemberEmployees?.getNonTeamMemberEmployees?.map(
              (employeeDetails: EmployeesListType) => {
                return {
                  id: employeeDetails?.id,
                  name: employeeDetails?.personalInformation?.name,
                };
              }
            )
        : []
    );
  }, [getNonTeamMemberEmployees, teamLeadId]);

  const [selectedTeamMembers, setSelectedTeamMembers] = useState<any[]>(
    editAllTeamsDetails?.members?.map((teamMember: EmployeesListType) => {
      return {
        id: teamMember?.id,
        name: teamMember?.personalInformation?.name,
      };
    })
  );

  useEffect(() => {
    setSelectedTeamMembers(
      editAllTeamsDetails?.members?.map((teamMember: EmployeesListType) => {
        return {
          id: teamMember?.id,
          name: teamMember?.personalInformation?.name,
        };
      })
    );
  }, [editAllTeamsDetails]);

  const [teamMembersDropdown, setTeamMembersDropdown] =
    useState<any[]>(nonTeamMembers);

  useEffect(() => {
    if (editAllTeamsDetails?.id) {
      setTeamMembersDropdown(
        [...nonTeamMembers, ...selectedTeamMembers]
          ?.filter(
            (value, index, array) =>
              array?.findIndex((value2) => value2.id === value.id) === index
          )
          ?.filter((teamMember: any) => teamMember?.id !== +teamLeadId)
      );
    } else {
      setTeamMembersDropdown(
        nonTeamMembers?.filter(
          (teamMember: any) => teamMember?.id !== +teamLeadId
        )
          ? nonTeamMembers?.filter(
              (teamMember: any) => teamMember?.id !== +teamLeadId
            )
          : []
      );
    }
  }, [
    editAllTeamsDetails?.id,
    nonTeamMembers,
    selectedTeamMembers,
    teamLeadId,
  ]);

  const [teamMembersList, setTeamMembersList] =
    useState<any>(selectedTeamMembers);

  useEffect(() => {
    setTeamMembersList(
      selectedTeamMembers?.filter(
        (teamMember: any) => teamMember?.id !== +teamLeadId
      )
    );
  }, [selectedTeamMembers, teamLeadId]);

  const createHandler = (data: teamMembers) => {
    if (!inProgress) {
      createEmployeeTeam({
        variables: {
          name: data?.teamName,
          code: data?.teamCode,
          teamLeadUser: +data?.teamLead ? +data?.teamLead : undefined,
          memberIds:
            data?.teamMembers?.length > 0
              ? data?.teamMembers?.map(
                  (employee: { id: number }) => employee?.id
                )
              : undefined,
        },
      })
        .then((response) => {
          const teamName = response?.data?.createEmployeeTeam?.name;
          setInProgress(false);
          setShowModal(false);
          setEditAllTeamsDetails(null);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                teamName ? `${teamName} team` : "Team"
              } has been created successfully.`,
            },
          ]);
          refetchTeams();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: teamMembers) => {
    const existingTeamMembers = editAllTeamsDetails?.members?.map(
      (member: { id: number }) => member?.id
    );

    const chosenTeamMembers = data?.teamMembers?.map(
      (member: { id: number }) => member?.id
    );

    const newTeamMembers = chosenTeamMembers?.filter(
      (memberID: number) => !existingTeamMembers?.includes(memberID)
    );

    const removedTeamMembers = existingTeamMembers?.filter(
      (memberID: number) => !chosenTeamMembers?.includes(memberID)
    );

    if (!inProgress) {
      updateEmployeeTeam({
        variables: {
          id: editAllTeamsDetails?.id,
          name: data?.teamName ? data?.teamName : editAllTeamsDetails?.name,
          code: data?.teamCode ? data?.teamCode : editAllTeamsDetails?.code,
          teamLeadUser: +data?.teamLead ? +data?.teamLead : null,
          memberIdsToAdd:
            newTeamMembers?.length > 0
              ? newTeamMembers?.map((employeeID: number) => employeeID)
              : [],
          memberIdsToRemove:
            removedTeamMembers?.length > 0
              ? removedTeamMembers?.map((employeeID: number) => employeeID)
              : [],
        },
        update: (cache, { data: { updateEmployeeTeam } }) => {
          const exTeams: {
            employeeTeams: TeamsType[] | any;
          } | null = cache.readQuery({
            query: ALL_TEAMS,
          });

          const updatedTeams = exTeams?.employeeTeams?.map(
            (teamDetails: TeamsType) => {
              if (+teamDetails?.id === +editAllTeamsDetails?.id) {
                return {
                  ...teamDetails,
                  code: updateEmployeeTeam?.code,
                  members: updateEmployeeTeam?.members,
                  name: updateEmployeeTeam?.name,
                  teamLeadUser: updateEmployeeTeam?.teamLeadUser,
                };
              }
              return teamDetails;
            }
          );

          cache.writeQuery({
            query: ALL_TEAMS,
            data: {
              employeeTeams: updatedTeams,
            },
          });
        },
      })
        .then((response) => {
          const teamName = editAllTeamsDetails?.name;
          setInProgress(false);
          setShowModal(false);
          setEditAllTeamsDetails(null);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                teamName ? `${teamName} team` : "Team"
              } has been updated successfully.`,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  useEffect(() => {
    if (allEmployees && editAllTeamsDetails?.teamLeadUser?.id) {
      setValue(
        "teamLead",
        editAllTeamsDetails?.teamLeadUser?.id
          ? editAllTeamsDetails?.teamLeadUser?.id
          : ""
      );
    }
  }, [editAllTeamsDetails?.teamLeadUser?.id, allEmployees, setValue]);

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-leave-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Team Members</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setInProgress(false);
              setEditAllTeamsDetails(null);
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {allEmployeesLoader && nonTeamMemberEmployeesLoader ? (
            <Loading className="min-h-[51vh]" />
          ) : (
            <form
              onSubmit={
                editAllTeamsDetails?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="form-div pr-[18px] lg:pr-[45px] ">
                <Text
                  register={register}
                  name={"teamName"}
                  errors={errors}
                  label={"Team Name *"}
                  required={true}
                  defaultText={
                    editAllTeamsDetails ? editAllTeamsDetails?.name : ""
                  }
                />
                <Text
                  register={register}
                  name={"teamCode"}
                  errors={errors}
                  label={"Team Code *"}
                  required={true}
                  defaultText={
                    editAllTeamsDetails ? editAllTeamsDetails?.code : ""
                  }
                />
                <SelectWithId
                  register={register}
                  name={"teamLead"}
                  errors={errors}
                  label={"Team Lead"}
                  options={
                    getArrayObjectDifference(
                      employeesOption,
                      teamMembersList
                    ) || employeesOption
                  }
                  enableDefaultSelectOption={true}
                />
                <div className="w-full mb-[10px]">
                  <AutoComplete
                    setValue={setValue}
                    options={
                      editAllTeamsDetails?.id
                        ? teamMembersDropdown
                        : nonTeamMembers
                        ? nonTeamMembers
                        : []
                    }
                    control={control}
                    label={"Team Members"}
                    name={"teamMembers"}
                    errors={errors}
                    multiple
                    defaultValue={
                      editAllTeamsDetails?.members?.map(
                        (member: {
                          id: number;
                          personalInformation: { name: string };
                        }) => {
                          return {
                            id: member?.id,
                            name: member?.personalInformation?.name,
                          };
                        }
                      ) || []
                    }
                  />
                </div>
              </div>
              <div className="w-full border-b p-4" />
              <div className="button-div pl-[18px] lg:pl-[45px] pr-[28px] lg:pr-[57px]">
                <Outlined
                  buttonName={"Cancel"}
                  onClick={() => {
                    setInProgress(false);
                    setEditAllTeamsDetails(null);
                    setShowModal(false);
                  }}
                />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : editAllTeamsDetails?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditAllTeams;
