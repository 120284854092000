import { HiArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Group from "global/assets/images/group-icon.png";

const ResetSuccess = () => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center">
      <div className="form-design px-[42px] py-[80px]">
        <img
          src={Group}
          alt="Password Reset Successfully"
          className="w-[125px] h-[97px] mb-[40px]"
        />
        <div className="mb-[28px]">
          <h3 className="text-2xl text-center text-ironside-gray">
            Password Reset
          </h3>
          <p className="text-center text-base text-warm-gray">
            Your Password has been successfully reset.
          </p>
        </div>

        <button type="submit" className="button-design" onClick={clickHandler}>
          Continue Login
        </button>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => navigate("/reset-password")}
      />
    </div>
  );
};

export default ResetSuccess;
