import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { Filled, Outlined, ToggleSwitch } from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import { findAllowedACLIds } from "global/helpers/FindACLIds";

import Loading from "components/Loader/Loading";
import {
  GET_ALL_PROJECT_ROLES,
  PROJECT_ACL,
} from "modules/Project/services/queries";
import { CREATE_OR_UPDATE_PROJECT_ROLE } from "modules/Project/services/mutations";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";
import Select from "components/forms/UpdatedForm/Select";

interface RoleType {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  allowedResourceIds: string[];
  roleResources: string;
}

interface childNodesTypes {
  id: string;
  title: string;
  isAllowed: boolean;
  childNodes: childNodesTypes[] | [];
}

const EditProjectRole = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<RoleType>();

  const [saveProjectRole, { loading: saveProjectRoleLoading }] = useMutation(
    CREATE_OR_UPDATE_PROJECT_ROLE
  );

  const { data: projectACLTree, loading: projectACLTreeLoader } = useQuery(
    PROJECT_ACL,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [
    loadProjectRoleItemQuery,
    {
      loading: projectRoleItemQueryLoader,
      data: projectRoleItemQuery,
      refetch: refetchProjectRoleItemQuery,
      loading,
    },
  ] = useLazyQuery(GET_ALL_PROJECT_ROLES, {
    fetchPolicy: "no-cache",
  });

  const resourcesAccessValue = watch("roleResources");
  const watchName = watch("name") || null;
  const watchDescription = watch("description") || null;
  const watchIsActive = watch("isActive");

  let allIds: string[] = [];

  const findIds = (treeData: childNodesTypes[]) => {
    if (treeData?.length === 0) return null;
    treeData?.map((treeData) => {
      allIds.push(treeData?.id);
      if (treeData?.childNodes?.length > 0) {
        findIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  findIds(projectACLTree?.projectAclTree?.resourceIds?.resourceId || []);

  const createOrUpdateRoleHandler = (data: RoleType) => {
    if (!saveProjectRoleLoading) {
      saveProjectRole({
        variables: {
          projectRole: {
            id: state?.id || undefined,
            name: data?.name,
            description: data.description,
            isActive: data.isActive,
            resourceIds: resourcesAccessValue === "All" && allIds,
          },
        },
      })
        .then((response) => {
          const roleName = response?.data?.createProjectRole?.name;
          navigate("/projects/settings/roles");
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                roleName ? `${roleName} role` : "Role"
              } has been created successfully.`,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  let allowedResourceIds = findAllowedACLIds(
    projectRoleItemQuery?.projectRoles?.dataCollection[0]?.allowedResources
      ?.resourceId || []
  );

  let checkIsAllOrNor =
    allowedResourceIds?.sort()?.toString() === allIds?.sort()?.toString();

  useEffect(() => {
    if (checkIsAllOrNor) {
      setValue("roleResources", "All");
    } else {
      setValue("roleResources", "custom");
    }
    if (!state) {
      setValue("roleResources", "");
    }
  }, [setValue, state, checkIsAllOrNor]);

  useEffect(() => {
    if (state?.id) {
      loadProjectRoleItemQuery({
        variables: {
          filters: {
            id: +state?.id ? +state?.id : 0,
          },
        },
      });
    }
  }, [state?.id, loadProjectRoleItemQuery, refetchProjectRoleItemQuery]);

  return (
    <div className="bg-white min-h-[760px]">
      <div className="md:flex md:justify-center flex-col">
        <div>
          {projectACLTreeLoader && projectRoleItemQueryLoader ? (
            <Loading className="min-h-[70vh]" />
          ) : (
            <form
              onSubmit={handleSubmit(createOrUpdateRoleHandler)}
              className="py-[45px] w-full md:flex md:justify-center overflow-x-auto"
            >
              <div className="w-[250px] md:w-[325px] mx-auto space-y-5">
                <Input
                  register={register}
                  errors={errors}
                  label="Role Name *"
                  name="name"
                  required
                  defaultValue={state?.name || ""}
                  setValue={setValue}
                  maxLength={50}
                />
                <TextArea
                  errors={errors}
                  label="Description "
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={state?.description || ""}
                />
                <Select
                  control={control}
                  errors={errors}
                  label="Resources Access *"
                  name="roleResources"
                  options={["All", "custom"]}
                  setValue={setValue}
                  required
                />
                <div className="flex justify-between pb-[10px]">
                  <p className="text-ironside-gray">Is Active</p>
                  <ToggleSwitch
                    register={register}
                    name="isActive"
                    label="Is Active"
                    defaultValue={state?.isActive}
                  />
                </div>
                <div className="button-div">
                  <Outlined
                    onClick={() => {
                      reset();
                      navigate(`/projects/settings/roles`);
                    }}
                    buttonName={"Cancel"}
                  />
                  {resourcesAccessValue === "custom" ? (
                    <Filled
                      onClick={() => {
                        if (watchName === "") {
                          setFocus("name");
                        } else {
                          if (state?.id) {
                            navigate("role-resources", {
                              state: {
                                id: state?.id,
                                name: watchName,
                                description: watchDescription,
                                aclTree:
                                  projectRoleItemQuery?.projectRoles
                                    ?.dataCollection[0] &&
                                  projectRoleItemQuery?.projectRoles
                                    ?.dataCollection[0]?.allowedResources
                                    ?.resourceId,
                                isActive: watchIsActive,
                                loading: loading,
                              },
                            });
                          } else {
                            navigate("role-resources", {
                              state: {
                                name: watchName,
                                description: watchDescription,
                                aclTree:
                                  projectACLTree?.projectAclTree?.resourceIds
                                    ?.resourceId || [],
                                isActive: watchIsActive,
                              },
                            });
                          }
                        }
                      }}
                      buttonName={"Next"}
                      loading={saveProjectRoleLoading}
                      buttonType="button"
                    />
                  ) : (
                    <Filled
                      buttonName={state?.id ? "update" : "Add"}
                      loading={saveProjectRoleLoading}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProjectRole;
