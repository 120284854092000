import { FC, Fragment, useEffect } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import ListItem from "@tiptap/extension-list-item";
import TextStyle, { TextStyleOptions } from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import { EditorContent, ReactRenderer, useEditor } from "@tiptap/react";

import {
  downloadPDFUsingFunction,
  getFileExtension,
} from "global/helpers/getFileExtension";
import { ISetStateType } from "global/types/type";
import { ReactComponent as LinkIcon } from "global/assets/images/link-icon.svg";
import { Base64toObject } from "global/helpers/FileConverter";

import { IProjectTaskComment } from "modules/Project/types/project";
import MentionList from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/TipTap/MentionList";

interface IProps {
  comment: IProjectTaskComment;
  setEditComment: Function;
  setDeleteComment: ISetStateType<{ id: number; index: number } | undefined>;
  setDeleteVerification: Function;
  index: number;
  membersList: { id: number; name: string }[] | [];
}

const Comment: FC<IProps> = ({
  comment,
  setEditComment,
  setDeleteComment,
  setDeleteVerification,
  index,
  membersList,
}) => {
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({
        types: [ListItem.name],
      } as unknown as TextStyleOptions),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Mention.configure({
        HTMLAttributes: { class: "mentionNode" },
        suggestion: {
          items: ({ query }) => {
            return membersList
              .filter((item: { id: number; name: string }) =>
                item?.name?.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let component: any;
            return {
              onStart: (props: any) => {
                component = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                if (!props.clientRect) {
                  return;
                }
              },

              onUpdate(props: any) {
                component.updateProps(props);

                if (!props.clientRect) {
                  return;
                }
              },

              onKeyDown(props: any) {
                if (props.event.key === "Escape") {
                  return true;
                }

                return component.ref?.onKeyDown(props);
              },

              onExit() {
                // popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
  });
  useEffect(() => {
    if (comment && comment?.message) {
      editor?.commands.setContent(JSON.parse(comment?.message));
      editor?.setEditable(false);
    }
  }, [comment, editor]);

  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const getProfileImage = (comment: IProjectTaskComment) => {
    const filterProfileImage =
      (comment?.createdByUser?.userProfileImages &&
        comment?.createdByUser?.userProfileImages?.length > 0 &&
        comment?.createdByUser?.userProfileImages?.filter(
          (userProfileImage: any) => userProfileImage.type === "Profile"
        )[0]) ||
      null;

    const profileImage: any =
      filterProfileImage &&
      filterProfileImage?.documents &&
      filterProfileImage?.documents?.length > 0 &&
      filterProfileImage?.documents[0]?.file;

    return profileImage?.length > 100 ? (
      <img
        className="max-w-[32px] max-h-[32px] rounded-[50%] object-fit"
        src={`data:image/${
          getFileExtension(profileImage) === "svg"
            ? "svg+xml"
            : getFileExtension(profileImage)
        };base64,${profileImage}`}
        alt="No File"
      />
    ) : (
      <div className="w-8 h-8 rounded-[50%] bg-cornflower-blue text-white text-[20px] text-center">
        {comment?.createdByUser?.personalInformation &&
          comment?.createdByUser?.personalInformation?.name[0]}
      </div>
    );
  };

  const editHandler = (comment: IProjectTaskComment, index: number) => {
    setEditComment({ ...comment, index });
  };

  const onDelete = (id: number, index: number) => {
    setDeleteComment({
      id,
      index,
    });
    setDeleteVerification(true);
  };

  return (
    <div className="flex mt-4 gap-4" key={comment?.id}>
      <div>{getProfileImage(comment)}</div>
      <div>
        <span className="text-[14px] leading-[19px] text-[#626364] inline-block min-w-[120px] font-bold">
          {comment?.createdByUser?.personalInformation?.name}
        </span>
        <span className="ml-0 sm:ml-2 inline-block mb-[9px] text-[12px] leading-[14px] text-[#A4A4A5]">
          {comment?.createdAt}
        </span>
        <div className=" text-[14px] text-[#626364]">
          <EditorContent editor={editor} style={{ padding: 0 }} />
        </div>
        <div className="flex flex-wrap gap-3 mb-4">
          {comment?.projectTaskCommentAttachments?.length > 0 &&
            comment?.projectTaskCommentAttachments?.map(
              (attachment: {
                id: number;
                filePath: string;
                mimeType: string;
                extension: string;
                name: string;
              }) => {
                return (
                  <div key={attachment?.id} className="mt-2">
                    {attachment?.mimeType?.includes("image") ? (
                      <Fragment>
                        <img
                          className="object-fit max-w-[300px] max-h-[300px]"
                          src={`data:image/${
                            attachment?.mimeType === "svg"
                              ? "svg+xml"
                              : attachment?.mimeType
                          };base64,${attachment?.filePath}`}
                          alt="No File"
                        />
                        <div className="flex mt-2 gap-2">
                          <a
                            href={
                              attachment?.filePath &&
                              Base64toObject(attachment?.filePath)
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center"
                          >
                            <LinkIcon className="w-[14px] h-[14px] md:ml-8 xl:ml-0 cursor-pointer text-cornflower-blue" />
                          </a>
                          <HiDownload
                            className="w-6 h-6 cursor-pointer text-cornflower-blue"
                            onClick={() => {
                              downloadPDFUsingFunction(
                                attachment?.filePath,
                                attachment?.name,
                                attachment?.mimeType
                              );
                            }}
                          />
                        </div>
                      </Fragment>
                    ) : attachment?.mimeType === "application/pdf" ? (
                      <div>
                        <object
                          className={`${
                            attachment?.mimeType === "application/pdf"
                              ? "w-full h-full"
                              : "w-[300px] h-[500px] sm:w-[500px] sm:h-[600px] md:w-[600px] lg:w-[700px] 2xl:w-[800px]"
                          }  rounded-md transition-all duration-300 image-pdf-modal`}
                          data={`data:application/pdf;base64,${attachment?.filePath}`}
                        >
                          <div className="w-full h-full flex justify-center items-center">
                            <a
                              href={`data:application/pdf;base64,${attachment?.filePath}`}
                              download={`data:application/pdf;base64,${attachment?.filePath}`}
                              className="cursor-pointer"
                            >
                              {}
                            </a>
                          </div>
                        </object>
                        <div className="flex mt-2 gap-2">
                          <a
                            href={
                              attachment?.filePath &&
                              Base64toObject(attachment?.filePath)
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center"
                          >
                            <LinkIcon className="w-[14px] h-[14px] md:ml-8 xl:ml-0 cursor-pointer text-cornflower-blue" />
                          </a>
                          <HiDownload
                            className="w-6 h-6 cursor-pointer text-cornflower-blue"
                            onClick={() => {
                              downloadPDFUsingFunction(
                                attachment?.filePath,
                                attachment?.name,
                                attachment?.mimeType
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : attachment?.mimeType?.includes("video") ? (
                      <Fragment>
                        <video controls className="pt-2 w-full">
                          <source
                            type={attachment?.mimeType}
                            src={`data:${attachment?.mimeType};base64,${attachment?.filePath}`}
                          />
                        </video>
                      </Fragment>
                    ) : (
                      <div className="border shadow-md max-w-[250px] ">
                        <p className="w-[250px] h-[100px] bg-[#f3f4f6] flex items-center justify-center">
                          <AiOutlineFile className="w-6" />
                        </p>
                        <div className="flex justify-between items-center p-2">
                          <p className="bg-white font-medium text-black truncate">
                            {attachment?.name}
                          </p>
                          <HiDownload
                            className="min-w-[24px] min-h-[24px] cursor-pointer text-cornflower-blue"
                            onClick={() => {
                              downloadPDFUsingFunction(
                                attachment?.filePath,
                                attachment?.name,
                                attachment?.mimeType
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            )}
        </div>
        {(+localStorage?.id ===
          comment?.createdByUser?.personalInformation?.id ||
          +sessionId === comment?.createdByUser?.personalInformation?.id) && (
          <>
            <span
              className="text-[14px] text-[#8993A4] hover:underline cursor-pointer"
              onClick={() => {
                editHandler(comment, index);
              }}
            >
              Edit
            </span>
            <span
              className="ml-3 text-[14px] text-[#8993A4] hover:underline cursor-pointer"
              onClick={() => {
                onDelete(comment?.id, index);
              }}
            >
              Delete
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default Comment;
