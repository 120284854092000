import { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { CalendarField, Filled, Modal, Outlined } from "components/forms";
import Timer from "components/forms/Time/Timer";
import TextArea from "components/forms/UpdatedForm/TextArea";

import { ViewDateFormat } from "global/helpers/DateFormatter";
import { toastNotify } from "global/helpers/Cache";
import { ISetStateType } from "global/types/type";

import { CREATE_OR_UPDATE_PROJECT_WORK_LOG } from "modules/Project/services/mutations";
import {
	IProjectWorkLog,
	IProjectWorkLogForm,
} from "modules/Project/types/project";
import { convertHoursToTwelveHour } from "global/helpers/TimeFormat";

interface IProps {
	setShowModal: Function;
	editWorkLog: IProjectWorkLog | null;
	setEditWorkLog: Function;
	refetchWorkLogs: Function;
	canSaveProjectWorkLog: boolean;
	setWorkLogs: ISetStateType<IProjectWorkLog[]>;
	setRefetchWorkLog: ISetStateType<boolean>;
}

const EditWorkLog: FC<IProps> = ({
	setShowModal,
	editWorkLog,
	setEditWorkLog,
	refetchWorkLogs,
	canSaveProjectWorkLog,
	setWorkLogs,
	setRefetchWorkLog,
}) => {
	const sessionId: string | any = document.cookie
		.split("; ")
		.find((row) => row.startsWith("id="))
		?.split("=")[1];

	const [createProjectWorkLog, { loading }] = useMutation(
		CREATE_OR_UPDATE_PROJECT_WORK_LOG,
	);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<IProjectWorkLogForm>();

	const { issueId } = useParams();

	let currentDate = new Date();
	let minDate = new Date(
		currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1),
	)
		.toISOString()
		.slice(0, 10);

	const [workLogDate, setWorkLogDate] = useState<Date | null | undefined>(
		new Date(),
	);
	const currentHour = currentDate?.getHours();

	const [selectedStartTime, setSelectedStartTime] = useState<string>(
		editWorkLog?.startTime
			? `${editWorkLog?.startTime?.slice(
					18,
					23,
			  )} ${editWorkLog?.startTime?.slice(-2)}`
			: `${convertHoursToTwelveHour(currentHour)}:${currentDate
					?.getMinutes()
					?.toString()
					?.padStart(2, "0")}:${currentHour < 12 ? "AM" : "PM"}`,
	);
	const [selectedEndTime, setSelectedEndTime] = useState<string>(
		editWorkLog?.endTime
			? `${editWorkLog?.endTime?.slice(18, 23)} ${editWorkLog?.endTime?.slice(
					-2,
			  )}`
			: `${convertHoursToTwelveHour(currentHour)}:${currentDate
					?.getMinutes()
					?.toString()
					?.padStart(2, "0")}:${currentHour < 12 ? "AM" : "PM"}`,
	);

	useEffect(() => {
		if (editWorkLog?.startTime) {
			setWorkLogDate(
				new Date(editWorkLog?.startTime?.slice(0, 18)?.replaceAll("-", " ")),
			);
		}
	}, [editWorkLog?.startTime]);

	const createOrUpdateProjectWorkLog: SubmitHandler<IProjectWorkLogForm> = ({
		startTime,
		endTime,
		description,
	}) => {
		const logDate =
			(workLogDate && ViewDateFormat(new Date(workLogDate))) || "";

		if (issueId && !Number.isNaN(+issueId)) {
			createProjectWorkLog({
				variables: {
					createProjectWorkLogInput: {
						id: editWorkLog?.id || undefined,
						userId: +localStorage?.id || +sessionId,
						projectTaskId: +issueId,
						startTime: `${logDate} ${startTime}`,
						endTime: `${logDate} ${endTime}`,
						description,
					},
					isProfileImageNeeded: false,
				},
			})
				.then((response) => {
					setRefetchWorkLog(true);
					if (!editWorkLog?.id) {
						setWorkLogs((prevLogs: IProjectWorkLog[]) => {
							const newWorkLogs = [...prevLogs];
							newWorkLogs.unshift(response?.data?.createProjectWorkLog);
							return newWorkLogs;
						});
					}

					if (editWorkLog?.id) {
						setWorkLogs((prevLogs: IProjectWorkLog[]) => {
							const newWorkLogs = [...prevLogs];
							const { index } = editWorkLog;
							newWorkLogs[index] = response?.data?.createProjectWorkLog;
							return newWorkLogs;
						});
					}

					refetchWorkLogs();
					setShowModal(false);
					toastNotify([
						{
							messageType: "success",
							message: `Work Log ${
								editWorkLog?.id ? "Updated" : "Added"
							} successfully.`,
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				});
		}
	};

	return (
		<Modal>
			<div className="edit-modal-div">
				<div className="edit-modal-header-div px-[18px] lg:px-[45px]">
					<h2 className="edit-leave-modal-heading">Add Time</h2>
					<div
						className="edit-modal-close-icon-div"
						onClick={() => {
							setShowModal(false);
							setEditWorkLog(null);
						}}
					>
						<MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
					</div>
				</div>
				<div>
					<form onSubmit={handleSubmit(createOrUpdateProjectWorkLog)}>
						<div className="form-div pr-[18px] lg:pr-[45px]">
							<CalendarField
								register={register}
								errors={errors}
								name="workLogDate"
								date={workLogDate}
								setDate={setWorkLogDate}
								className="h-[50px]"
								label="Start Date *"
								maxDate={new Date()}
								minDate={new Date(minDate)}
							/>
							<div className="flex justify-between pb-5">
								<Timer
									register={register}
									name="startTime"
									setValue={setValue}
									selectedTime={selectedStartTime}
									setSelectedTime={setSelectedStartTime}
								/>
								<Timer
									register={register}
									name="endTime"
									setValue={setValue}
									selectedTime={selectedEndTime}
									setSelectedTime={setSelectedEndTime}
								/>
							</div>
							<TextArea
								errors={errors}
								label="Description"
								name="description"
								register={register}
								setValue={setValue}
								defaultValue={editWorkLog?.description}
								required
								className=""
							/>
						</div>
						<div className="w-full border-b" />
						<div className="button-div px-[18px] lg:px-[45px]">
							<Outlined
								onClick={() => {
									setShowModal(false);
									setEditWorkLog(null);
								}}
								buttonName="Cancel"
							/>
							<Filled
								loading={loading}
								buttonName={editWorkLog?.id ? "Update" : "Add"}
								disabled={loading || !canSaveProjectWorkLog}
							/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default EditWorkLog;
