import { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import Loading from "components/Loader/Loading";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import OopsNoData from "components/UI/OopsNoData";
import MyTransactionDetailsGrid from "./MyTransactionDetailsGrid";

const tableHeadings = [
  "ID",
  "Leave Date",
  "Session",
  "Leave Type",
  "Trans Type",
  "No Of Days",
  "Prev Count",
  "Current Count",
  "Details",
];

const MyTransactionsDetails: React.FC<{
  leaveTransactionData: any;
  setPageSize: any;
  setCurrentPage: any;
  pageSize: any;
  currentPage: any;
  totalItems: any;
  loading: boolean;
  filterLeaveTransaction: any;
}> = ({
  leaveTransactionData,
  currentPage,
  pageSize,
  setPageSize,
  setCurrentPage,
  totalItems,
  loading,
  filterLeaveTransaction,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const onViewHandler = (leaveTransaction: any) => {
    navigate(`${leaveTransaction?.id}`, {
      state: {
        viewPageDetails: leaveTransaction,
      },
    });
  };
  const getDataModal = (leaveTransaction: any) => {
    return [
      {
        lable: "ID",
        value: [
          {
            content: leaveTransaction?.id,
          },
        ],
      },
      {
        lable: "Leave Date",
        value: [
          {
            content: leaveTransaction?.isManual
              ? leaveTransaction?.addedLeaveDate
              : leaveTransaction?.leaveDate
              ? leaveTransaction?.leaveDate
              : "",
          },
        ],
      },
      {
        lable: "Session",
        value: [
          {
            content: leaveTransaction?.session,
          },
        ],
      },
      {
        lable: "Leave Type",
        value: [
          {
            content: leaveTransaction?.leaveType,
          },
        ],
      },
      {
        lable: "Trans Type",
        value: [
          {
            content: leaveTransaction?.transactionType,
          },
        ],
      },
      {
        lable: "Number Of Days",
        value: [
          {
            content: leaveTransaction?.noOfDays,
          },
        ],
      },
      {
        lable: "Previous Count",
        value: [
          {
            content: leaveTransaction?.previousCount,
          },
        ],
      },
      {
        lable: "Current Count",
        value: [
          {
            content: leaveTransaction?.currentCount,
          },
        ],
      },
      {
        lable: "Notes",
        value: [
          {
            content: leaveTransaction?.notes,
          },
        ],
      },
    ];
  };
  return (
    <Fragment>
      <div className="hidden md:block rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray md:mt-[5px]">
        <div className="flex flex-col bg-white justify-between min-h-[560px]">
          <Table
            noOfItems={filterLeaveTransaction && leaveTransactionData?.length}
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={totalItems ? totalItems : 0}
            currentPage={currentPage}
            ref={parentRef}
            className="min-h-[595px]"
            isCenterlastHeading
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: true,
              };
            })}
          >
            {leaveTransactionData?.map(
              (leaveTransaction: any, index: number) => {
                return (
                  <MyTransactionDetailsGrid
                    leaveTransaction={leaveTransaction}
                    key={index}
                  />
                );
              }
            )}
          </Table>
        </div>
      </div>
      <div className="md:hidden lg:px-[15px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] bg-white md:border-none border-light-gray md:mt-[30px]">
        <div className="flex flex-col bg-white">
          {loading ? (
            <Loading className="min-h-[595px]" />
          ) : leaveTransactionData?.length > 0 ? (
            <div className="w-full overflow-x-auto min-h-[412px]">
              {leaveTransactionData?.map(
                (leaveTransaction: any, index: number) => {
                  return (
                    <SMDataCard
                      key={leaveTransaction?.id}
                      title={`Leave Transaction ${index + 1}`}
                      loading={loading}
                      dataModal={getDataModal(leaveTransaction)}
                      onView={() => {
                        onViewHandler(leaveTransaction);
                      }}
                    />
                  );
                }
              )}
              <Pagination
                totalCount={totalItems ? totalItems : 0}
                noOfItem={leaveTransactionData?.length}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </div>
          ) : (
            filterLeaveTransaction && <OopsNoData />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MyTransactionsDetails;
