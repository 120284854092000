import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import DeclinePage from "./DeclinePage";
import { SearchField } from "components/forms";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import UserDetails from "components/UserDetails/UserDetails";

import {
  getAllowedAccess,
  toastNotify,
  userContextData,
} from "global/helpers/Cache";
import { ReactComponent as FilterIcon } from "global/assets/images/filter-icon.svg";
import { paginationDefaultValue } from "global/helpers/StaticData";
import { Capitalize } from "global/helpers/Capitalize";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { getFileExtension } from "global/helpers/getFileExtension";
import { Base64toObject } from "global/helpers/FileConverter";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { USER_PROFILE_MODERATIONS } from "modules/Employee/services/queries";
import FilterPopup from "modules/Settings/Pages/Moderation/FilterPopup";
import { UPDATE_MULTIPLE_USER_PROFILE_MODERATIONS } from "modules/Settings/services/mutations";
import { ITableContent } from "components/App/Table/types/table";

export interface userProfileModerationType {
  id: number;
  moderatedByUser: {
    id: number;
    personalInformation: {
      id: number;
      name: string;
      personalEmail: string;
    };
    userProfileImages: {
      id: number;
      type: string;
      documents: { file: null | undefined | string }[];
    }[];
  };
  updatedAt: string;
  section: string;
  field: string;
  fieldName: string;
  previousValue: any;
  updatedValue: any;
  status: string;
  declineReason: string;
  isChecked: boolean;
  action: string;
}

const tableHeadings = [
  "Employee Name",
  "Updated at",
  "Section",
  "Field",
  "Type",
  "Previous Value",
  "New Value",
  "Status",
  "Action",
];

interface IProps {
  setModerationId: Function;
}

const Moderation: FC<IProps> = ({ setModerationId }) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const editAccess = allowedResources?.includes(
    "CanApproveUserProfileModeration"
  );

  const employeeDetails: any = useReactiveVar(userContextData);

  const [
    loadUserProfileModeration,
    { data: userProfileModeration, refetch, loading },
  ] = useLazyQuery(USER_PROFILE_MODERATIONS, {
    fetchPolicy: "cache-and-network",
  });

  const [updateProfileModeration] = useMutation(
    UPDATE_MULTIPLE_USER_PROFILE_MODERATIONS
  );

  const [userId, setUserId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [filterlist, setFilterList] = useState<string | undefined>("Pending");
  const [showDeclinePage, setShowDeclinePage] = useState<boolean>(false);
  const [selectEmployeeAll, setSelectEmployeeAll] = useState<boolean>(false);
  const [selectEmployee, setSelectEmployee] = useState<number | undefined>();
  const [selectedLists, setSelectedLists] = useState<
    userProfileModerationType[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const [employeeList, setEmployeelist] = useState<userProfileModerationType[]>(
    []
  );

  const { register, watch, setValue } = useForm();

  const employee = watch("employee");
  const [search, setSearch] = useState(employee || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(employee);
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [employee]);

  const approveUserModerationHandler = () => {
    if (selectedLists.length !== 0) {
      updateProfileModeration({
        variables: {
          ids: selectedLists?.map((employee) => employee.id),
          status: "approved",
        },
      })
        .then(() => {
          setCurrentPage(1);
          refetch();
          setSelectedLists([]);
          setSelectEmployeeAll(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    }
  };

  useEffect(() => {
    setEmployeelist(
      userProfileModeration?.userProfileModerations?.dataCollection?.map(
        (employeeList: userProfileModerationType) => {
          return { ...employeeList, isChecked: false };
        }
      )
    );
  }, [userProfileModeration, refetch]);

  useEffect(() => {
    let allChecked = true;
    employeeList?.forEach((data) => {
      if (data?.isChecked === false) {
        allChecked = false;
      }
    });
    if (allChecked) {
      setSelectEmployeeAll(true);
    } else {
      setSelectEmployeeAll(false);
    }
  }, [employeeList]);

  useEffect(() => {
    setSelectedLists(employeeList?.filter((data) => data.isChecked === true));
  }, [employeeList]);

  const toggleCheck = (inputName: string | number) => {
    setEmployeelist((prevState) =>
      prevState?.map((data) =>
        data.id === inputName
          ? Object.assign({}, data, { isChecked: !data.isChecked })
          : data
      )
    );
  };

  const selectAll = (value: boolean) => {
    setSelectEmployeeAll(value);
    setEmployeelist((prevState) =>
      prevState.map((data) => {
        return { ...data, isChecked: value };
      })
    );
  };

  useEffect(() => {
    loadUserProfileModeration({
      variables: {
        limit: pageSize,
        page: currentPage,
        status: filterlist ? filterlist : undefined,
        search: search === "" ? undefined : search,
      },
    });
  }, [currentPage, pageSize, loadUserProfileModeration, search, filterlist]);

  const viewHandler = (id: any, employeeList: any) => {
    setModerationId(id);
    navigate(`${id}`, { state: employeeList });
  };

  const acceptHandler = (id: number) => {
    updateProfileModeration({
      variables: {
        ids: id,
        status: "approved",
      },
    })
      .then(() => {
        refetch();
        setSelectedLists([]);
        setSelectEmployee(undefined);
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
      })
      .catch((error: { message: any }) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };

  const declineHandler = () => {
    setShowDeclinePage(true);
  };

  const navigate = useNavigate();

  const parentRef = useRef<HTMLDivElement>(null);

  const getPreviousOrUpdatedValue = (
    isFile: boolean,
    isProfileImageFile: boolean,
    value: string
  ) => {
    if (isFile) {
      return (
        value?.length > 100 && (
          <span className="flex justify-center items-center w-[80px] h-[50px]">
            <a href={Base64toObject(value)} target="_blank" rel="noreferrer">
              <img src={filePdf} alt="PDF" className="w-[30px] h-[30px]" />
            </a>
          </span>
        )
      );
    } else if (isProfileImageFile) {
      return (
        (value?.length > 100 && (
          <img
            className="w-[80px] h-[50px] object-fill"
            src={`data:image/${
              getFileExtension(value) === "svg"
                ? "svg+xml"
                : getFileExtension(value)
            };base64,${value}`}
            alt="No File"
          />
        )) ||
        value
      );
    }
  };

  return (
    <div
      className={`min-h-[699px] lg:mx-0 rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray`}
    >
      <Fragment>
        <div className="w-full bg-white flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row py-[25px] px-2">
          <div className="flex lg:space-x-4 flex-col lg:flex-row items-center">
            <div className="flex space-x-4 items-center">
              <SearchField
                register={register}
                name="employee"
                label="Employee"
                onChange={() => {
                  setCurrentPage(1);
                }}
                classNameForInput="h-[42px]"
                setValue={setValue}
              />
              <div className="shadow">
                <button
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                  type="button"
                  className="w-[115px] h-[42px] text-sm rounded border border-[#E5E5E5] group flex space-x-1 items-center justify-center hover:bg-[#ECF1FE] hover:border-[#CFDDFC]"
                >
                  <span className="text-ironside-gray group-hover:text-cornflower-blue">
                    Filter
                  </span>
                  <FilterIcon className="text-[#8E8E8F] group-hover:text-cornflower-blue" />
                </button>
              </div>
            </div>
            <div
              className={`${
                filterlist ? "flex" : "hidden"
              } shadow mt-[16px] lg:mt-0`}
            >
              <p className="h-[42px] px-2 text-sm flex justify-center items-center space-x-2 rounded-[4px] border border-hit-gray/60 bg-white-smoke">
                <span className="text-ironside-gray">{filterlist}</span>
                <span
                  className="flex justify-center items-center w-4 h-4 rounded-full bg-hit-gray cursor-pointer"
                  onClick={() => {
                    setFilterList(undefined);
                    setCurrentPage(1);
                  }}
                >
                  <MdOutlineClose className="w-[8px] h-[8px] text-white" />
                </span>
              </p>
            </div>
          </div>
          {editAccess ? (
            <div className="space-x-[21px] text-sm w-full md:w-auto flex md:block justify-between md:justify-center">
              <button
                type="button"
                className={`w-[100px] lg:w-[120px] h-[42px] bg-white text-[#626364] rounded-[4px] border ${
                  selectedLists?.length !== 0 &&
                  selectedLists?.every((value) => value.status === "pending")
                    ? "cursor-pointer hover:border-cornflower-blue hover:text-cornflower-blue"
                    : "cursor-not-allowed border border-gray-300 text-[#a3abb7]"
                }`}
                onClick={() => {
                  if (
                    selectedLists?.length !== 0 &&
                    selectedLists?.every((value) => value.status === "pending")
                  ) {
                    setShowDeclinePage(true);
                  }
                }}
              >
                Decline
              </button>
              <button
                type="submit"
                className={`w-[100px] lg:w-[120px] h-[42px] rounded-[4px] ${
                  selectedLists?.length !== 0 &&
                  selectedLists?.every((value) => value.status === "pending")
                    ? "cursor-pointer bg-cornflower-blue text-white hover:bg-bright-blue/80 hover:shadow-[0_1px_2px_0_#366AD9]"
                    : "cursor-not-allowed border border-gray-300 text-[#a3abb7] bg-[#e0e0e0]"
                }`}
                onClick={() => {
                  if (
                    selectedLists?.length !== 0 &&
                    selectedLists?.every((value) => value.status === "pending")
                  ) {
                    approveUserModerationHandler();
                  }
                }}
              >
                Accept
              </button>
            </div>
          ) : null}
        </div>
        <div className="w-full overflow-x-auto">
          <Table
            loading={loading}
            noOfItems={userProfileModeration && employeeList?.length}
            className={"min-h-[610px]"}
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: name === "Employee Name" ? false : true,
              };
            })}
            currentPage={currentPage}
            pageSize={pageSize}
            selectAllCheckBox={selectEmployeeAll}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            isCheckBoxNeed={true}
            selectAllCheckBoxHandler={(checked: boolean) => selectAll(checked)}
            totalCount={
              userProfileModeration?.userProfileModerations
                ? userProfileModeration?.userProfileModerations
                    ?.totalNumberOfItems
                : 0
            }
            isCenterlastHeading={true}
            ref={parentRef}
          >
            {employeeList?.map((employeeList: userProfileModerationType) => {
              const isProfileImageFile =
                employeeList?.action === "Delete"
                  ? employeeList?.section === "User profile images"
                  : employeeList?.section === "User profile images" &&
                    employeeList?.field === "File path";

              const isFile =
                !isProfileImageFile &&
                (employeeList?.field === "File path" ||
                  employeeList?.field === "Aadhaar file");

              const previousValue =
                (isProfileImageFile || isFile) &&
                getPreviousOrUpdatedValue(
                  isFile,
                  isProfileImageFile,
                  employeeList?.previousValue
                );

              const updatedValue =
                (isProfileImageFile || isFile) &&
                getPreviousOrUpdatedValue(
                  isFile,
                  isProfileImageFile,
                  employeeList?.updatedValue
                );

              const fileOrImagePreviousValue =
                (isProfileImageFile || isFile) && previousValue;

              const fielOrImageUpdatedValue =
                (isProfileImageFile || isFile) && updatedValue;

              const tableContent: ITableContent[] = [
                {
                  value: (
                    <div className="group">
                      <p
                        onMouseEnter={() =>
                          setUserId(
                            employeeList?.moderatedByUser?.personalInformation
                              ?.id
                          )
                        }
                      >
                        {
                          employeeList?.moderatedByUser?.personalInformation
                            ?.name
                        }
                      </p>
                      <UserDetails userId={userId} />
                    </div>
                  ),
                },
                {
                  value: employeeList?.updatedAt,
                  className: "min-w-[260px]",
                  center: true,
                },
                {
                  value: employeeList?.section,
                  center: true,
                },
                {
                  value: employeeList?.field,
                  center: true,
                },
                {
                  value: employeeList?.action,
                  center: true,
                },
                {
                  value:
                    fileOrImagePreviousValue || employeeList?.previousValue,
                  center: true,
                },
                {
                  value: fielOrImageUpdatedValue || employeeList?.updatedValue,
                  center: true,
                },
                {
                  value: Capitalize(employeeList.status),
                  color: `${
                    employeeList?.status === "pending"
                      ? "text-[#FFB100]"
                      : employeeList?.status === "approved"
                      ? "text-[#3BC171]"
                      : employeeList?.status === "withdraw"
                      ? "text-cornflower-blue"
                      : employeeList?.status === "declined"
                      ? "text-[#FF647C]"
                      : ""
                  }`,
                  center: true,
                },
              ];

              return (
                <TableContent
                  key={employeeList?.id}
                  id={employeeList?.id}
                  tableContent={tableContent}
                  editAccess={editAccess}
                  checkBoxChangeHandler={() => {
                    toggleCheck(employeeList?.id);
                  }}
                  isChecked={employeeList?.isChecked}
                  onMenuClick={(id: number) => {
                    setSelectEmployee(id);
                  }}
                  onViewMeatBallsMenu={() => {
                    viewHandler(employeeList?.id, employeeList);
                  }}
                  onAccept={() => {
                    acceptHandler(employeeList?.id);
                  }}
                  onDecline={declineHandler}
                  showModal={showDeclinePage}
                  parentRef={parentRef}
                />
              );
            })}
          </Table>
        </div>
        {showModal ? (
          <FilterPopup
            setShowModal={setShowModal}
            setFilterList={setFilterList}
            filterlist={filterlist}
            setCurrentPage={setCurrentPage}
          />
        ) : null}
        {showDeclinePage ? (
          <DeclinePage
            setShowDeclinePage={setShowDeclinePage}
            updateProfileModeration={updateProfileModeration}
            employeeId={
              selectEmployee
                ? [selectEmployee]
                : selectedLists?.map((employee) => employee.id)
            }
            refetchData={employeeDetails?.refetchData}
            refetchUserProfileModeration={refetch}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default Moderation;
