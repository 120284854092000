import { FC } from "react";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";

import EditForm from "components/EditForm/UpdatedEditFormAgry/EditForm";
import { Input, TextArea } from "components/forms/UpdatedFormAgry";

import { toastNotify } from "global/helpers/Cache";
import { UpdateQueryType } from "global/types/type";
import useAllowedResources from "global/hooks/useAllowedResources";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  FilterProjectGroupArgs,
  FilterProjectGroupCommonArgs,
  FilterProjectGroupReturnType,
  ProjectGroup,
  ProjectGroupFormType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";
import {
  CREATE_PROJECT_GROUP,
  UPDATE_PROJECT_GROUP,
} from "modules/Project/Pages/Projects/ProjectGroups/services";

interface Props {
  projectGroup?: ProjectGroup;
  onClose: () => void;
  updateQuery: UpdateQueryType<
    FilterProjectGroupReturnType,
    FilterProjectGroupArgs
  >;
  fieldArgs: FilterProjectGroupCommonArgs;
}

const EditProjectGroup: FC<Props> = ({
  projectGroup,
  onClose,
  updateQuery,
  fieldArgs,
}) => {
  const allowedResources = useAllowedResources();
  const canCreate = allowedResources?.includes("CreateProjectGroup");
  const canUpdate = allowedResources?.includes("UpdateProjectGroup");

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ProjectGroupFormType>({
    defaultValues: {
      name: projectGroup?.name || (null as unknown as string),
      description: projectGroup?.description || undefined,
    },
  });

  const [createProjectGroup, { loading: createProjectGroupLoading }] =
    useMutation(CREATE_PROJECT_GROUP);

  const [updateProjectGroup, { loading: updateProjectGroupLoading }] =
    useMutation(UPDATE_PROJECT_GROUP);

  const submitHandler: SubmitHandler<ProjectGroupFormType> = ({
    name,
    description,
  }) => {
    if (projectGroup?.id) {
      updateProjectGroup({
        variables: {
          projectGroupUpdateInput: {
            name,
            id: projectGroup?.id,
            description: description?.trim(),
          },
          ...fieldArgs,
        },
      })
        .then(() => {
          onClose();
          toastNotify([
            {
              message: "Project group updated successfully.",
              messageType: "success",
            },
          ]);
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    } else {
      createProjectGroup({
        variables: {
          projectGroupCreateInput: {
            name: name?.trim(),
            description: description?.trim() || undefined,
          },
          ...fieldArgs,
        },
      })
        .then(({ data }) => {
          if (data && data?.createProjectGroup) {
            onClose();
            toastNotify([
              {
                message: "Project group created successfully.",
                messageType: "success",
              },
            ]);
            updateQuery(({ filterProjectGroups }) => {
              return {
                filterProjectGroups: filterProjectGroups
                  ? {
                      pageInfo: filterProjectGroups?.pageInfo
                        ? {
                            ...filterProjectGroups?.pageInfo,
                            totalNumberOfItems: filterProjectGroups?.pageInfo
                              ?.totalNumberOfItems
                              ? filterProjectGroups?.pageInfo
                                  ?.totalNumberOfItems + 1
                              : 1,
                          }
                        : null,
                      edges:
                        filterProjectGroups?.edges &&
                        filterProjectGroups?.edges?.length > 0
                          ? [
                              {
                                cursor: null,
                                node: {
                                  ...data?.createProjectGroup,
                                },
                                __typename: null,
                              },
                              ...filterProjectGroups.edges,
                            ]
                          : [
                              {
                                node: {
                                  ...data?.createProjectGroup,
                                },
                                cursor: null,
                                __typename: null,
                              },
                            ],
                      __typename: filterProjectGroups?.__typename,
                    }
                  : null,
              };
            });
          }
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    }
  };

  return (
    <EditForm
      title={"Project group"}
      handleSubmit={handleSubmit}
      onSubmit={submitHandler}
      outlineBtnName={"Cancel"}
      filledBtnName={projectGroup?.id ? "Update" : "Add"}
      onClose={onClose}
      filledBtnLoading={createProjectGroupLoading || updateProjectGroupLoading}
      filledBtnDisable={
        isValid ? (projectGroup?.id ? !canUpdate : !canCreate) : true
      }
    >
      <Input
        control={control}
        name="name"
        label="Name"
        required
        className="bg-white"
        maxLength={275}
      />
      <TextArea control={control} name="description" label="Description" />
    </EditForm>
  );
};

export default EditProjectGroup;
