import { Fragment, useCallback, useState } from "react";
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Tab } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import { TFilterOption } from "components/Filter/types";
import FilterForm from "components/Filter/FilterForm/FilterForm";
import { useWindowSize } from "global/UpdatedHooks/hooks";

interface IProps<TFilterMain extends FieldValues> {
  options: TFilterOption<TFilterMain>;
  control: Control<TFilterMain>;
  register: UseFormRegister<TFilterMain>;
  watch: UseFormWatch<TFilterMain>;
  setValue: UseFormSetValue<TFilterMain>;
}

const FilterMain = <TFilterMain extends Record<string, any>>({
  options,
  control,
  register,
  watch,
  setValue,
}: IProps<TFilterMain>) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { width } = useWindowSize();

  const filterCount = useCallback((count: number) => {
    return (
      <span className="ml-2 md:absolute top-2.5 right-2 w-4 h-4 text-xs inline-flex justify-center items-center rounded-full bg-valentine-red text-white">
        {count > 9 ? "9+" : count}
      </span>
    );
  }, []);
  const stringCount = useCallback(
    (option: TFilterOption<TFilterMain>[0]) => {
      return option?.type === "string"
        ? option?.multiple
          ? Array.isArray(watch(option?.id)) && watch(option?.id)?.length > 0
            ? filterCount(watch(option?.id)?.length)
            : null
          : watch(option?.id)
          ? filterCount(1)
          : null
        : null;
    },
    [filterCount, watch]
  );
  const booleanCount = useCallback(
    (option: TFilterOption<TFilterMain>[0]) => {
      return option?.type === "boolean"
        ? watch(option?.id) !== null && watch(option?.id) !== undefined
          ? filterCount(1)
          : null
        : null;
    },
    [filterCount, watch]
  );
  const dateCount = useCallback(
    (option: TFilterOption<TFilterMain>[0]) => {
      return option?.type === "date"
        ? option.dateBetween
          ? watch(option?.dateBetweenFromName) &&
            watch(option?.dateBetweenFromName) !== ""
            ? filterCount(1)
            : watch(option?.dateBetweenToName) &&
              watch(option.dateBetweenToName) !== ""
            ? filterCount(1)
            : null
          : watch(option?.id)
          ? filterCount(1)
          : null
        : null;
    },
    [filterCount, watch]
  );
  const numberCount = useCallback(
    (option: TFilterOption<TFilterMain>[0]) => {
      return option?.type === "number"
        ? watch(option?.id)
          ? option?.numberBetween
            ? watch(option?.numberBetweenFromName) &&
              !isNaN(+watch(option?.numberBetweenFromName))
              ? filterCount(1)
              : watch(option?.numberBetweenToName) &&
                !isNaN(+watch(option?.numberBetweenToName))
              ? filterCount(1)
              : null
            : watch(option?.id) && !isNaN(+watch(option?.id))
            ? filterCount(1)
            : null
          : null
        : null;
    },
    [filterCount, watch]
  );

  return (
    <AnimatePresence initial={false}>
      <Tab.Group
        vertical={width < 768 ? false : true}
        defaultIndex={0}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      >
        <div className="h-[500px] md:grid md:grid-cols-[150px_auto] lg:grid-cols-[200px_auto] overflow-x-hidden">
          <Tab.List
            as="ul"
            className={`flex h-min md:block md:h-full overflow-x-scroll px-2 scroll-smooth md:sticky top-0 left-0 right-0 ${
              width < 768
                ? "scrollbar-hide border-b"
                : "overflow-y-scroll pl-4 border-r"
            }`}
          >
            {options?.map((option, index) =>
              option.isShow ? null : (
                <Tab
                  key={index}
                  as="li"
                  className={({ selected }) =>
                    `px-4 text-ironside-gray/80 py-2 w-min relative rounded mt-2 whitespace-nowrap text-sm rounded-b-none md:rounded-b cursor-pointer md:w-full ${
                      selected && "bg-[#eee] text-cornflower-blue"
                    } md:hover:bg-[#eee] md:hover:rounded-b md:hover:text-cornflower-blue transition-all duration-500 outline-none focus:outline focus:outline-cornflower-blue focus:border-none group`
                  }
                  onClick={() => setSelectedTab(index)}
                >
                  {({ selected }) => {
                    return (
                      <Fragment>
                        {option?.name}
                        {selected && (
                          <motion.span
                            className={`${
                              width < 768 ? "" : "group-focus:invisible"
                            }   absolute transition-all duration-500 right-0 bottom-0 left-0 h-0.5 bg-cornflower-blue`}
                            layoutId={width < 768 ? "underline" : undefined}
                          />
                        )}
                        {stringCount(option)}
                        {booleanCount(option)}
                        {dateCount(option)}
                        {numberCount(option)}
                      </Fragment>
                    );
                  }}
                </Tab>
              )
            )}
          </Tab.List>
          <Tab.Panels className={"overflow-y-auto"}>
            <motion.div
              key={selectedTab ? selectedTab : "empty"}
              initial={{ x: 700, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -700, opacity: 0 }}
              transition={{ duration: 0.6 }}
              className="w-full h-[calc(100%-45px)]"
            >
              {options.map((option, index) => (
                <Tab.Panel
                  key={index}
                  className="focus:outline-none overflow-y-auto"
                >
                  <FilterForm
                    selectedTab={option}
                    control={control}
                    register={register}
                    setValue={setValue}
                  />
                </Tab.Panel>
              ))}
            </motion.div>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </AnimatePresence>
  );
};

export default FilterMain;
