import { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  getAllowedAccess,
  toastNotify,
  setBackNavigationURL,
  setTitle,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ReactComponent as LeftArrow } from "global/assets/images/left-arrow-icon.svg";
import { ISortOrder } from "global/types/type";

import { GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS } from "modules/Project/services/queries";
import { DELETE_PROJECT_VAULT_CREDENTIAL_TYPE_FIELD } from "modules/Project/services/mutations";
import { IProjectVaultCredentialTypeFields } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import EditVaultTypesInputFields from "./EditVaulCredentialTypeFields";

const tableCommonHeadings = [
  { name: "Name", APIName: "name" },
  { name: "Type", APIName: "fieldType" },
];

const VaultCredentialTypeFields = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const canRead = allowedResources.includes("ReadProjectCredentialTypeField");
  const canCreateOrUpdate = allowedResources?.includes(
    "SaveProjectVaultCredentialType"
  );

  const canDelete = allowedResources?.includes(
    "DeleteProjectVaultCredentialType"
  );

  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery, state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [
    deleteProjectVaultCredentialTypeField,
    { loading: deleteProjectVaultCredentialTypeFieldLoading },
  ] = useMutation(DELETE_PROJECT_VAULT_CREDENTIAL_TYPE_FIELD);

  const [
    fetchAllProjectVaultCredentialTypeFields,
    {
      data: getAllProjectVaultCredentialTypeFields,
      loading,
      refetch: refetchProjectVaultCredentialTypeFields,
    },
  ] = useLazyQuery(GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    if (state?.name) {
      setTitle([
        { name: "Project", link: "/projects/my-projects" },
        {
          name: "Vault Credential Type",
          link: "/projects/settings/vault-types",
        },
        {
          name: `${state?.name}`,
          link: `/projects/edit/${id}/vault-types-input`,
        },
      ]);
    }
  }, [state?.name, id]);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const projectVaultCredentialTypeFieldsList =
    getAllProjectVaultCredentialTypeFields?.projectCredentialTypeField;

  const totalCount = projectVaultCredentialTypeFieldsList?.length || 0;

  const noOfItems =
    getAllProjectVaultCredentialTypeFields &&
    projectVaultCredentialTypeFieldsList?.length;

  useEffect(() => {
    if (id && !isNaN(+id)) {
      fetchAllProjectVaultCredentialTypeFields({
        variables: {
          type: Number(id),
          orderBy: sortOrder
            ? {
                field: sortOrder?.field,
                orderType: sortOrder?.orderType,
              }
            : undefined,
        },
      });
    }
  }, [
    currentPage,
    fetchAllProjectVaultCredentialTypeFields,
    pageSize,
    search,
    id,
    sortOrder,
  ]);

  const [
    editProjectVaultCredentialTypeField,
    setEditProjectVaultCredentialTypeField,
  ] = useState<IProjectVaultCredentialTypeFields | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectVaultCredentialTypeField?.id) {
      deleteProjectVaultCredentialTypeField({
        variables: {
          id: +editProjectVaultCredentialTypeField?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectVaultCredentialTypeFields();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectVaultCredentialTypeField?.name
                  ? `${editProjectVaultCredentialTypeField?.name} credential type field`
                  : "Credential Type field"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (
    projectVaultCredentialTypeField: IProjectVaultCredentialTypeFields
  ) => {
    if (canCreateOrUpdate) {
      setEditProjectVaultCredentialTypeField(projectVaultCredentialTypeField);
      setShowModal(true);
    }
  };

  const onDelete = (
    projectVaultCredentialTypeField: IProjectVaultCredentialTypeFields
  ) => {
    setEditProjectVaultCredentialTypeField(projectVaultCredentialTypeField);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectVaultCredentialTypeField(null);
  };

  const getDataModal = (
    projectVaultCredentialTypeField: IProjectVaultCredentialTypeFields
  ): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: Capitalize(projectVaultCredentialTypeField?.name),
        },
      ],
    },
    {
      label: "Type",
      value: [
        {
          content: projectVaultCredentialTypeField?.fieldType,
        },
      ],
    },
  ];

  const getTableContent = (
    projectVaultCredentialTypeField: IProjectVaultCredentialTypeFields
  ): ITableContent[] => [
    {
      value: projectVaultCredentialTypeField?.name,
    },
    {
      value: projectVaultCredentialTypeField?.fieldType,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(projectVaultCredentialTypeField);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[800px] bg-white">
      <div className="flex justify-between">
        <div className="pt-5 w-[300px] space-y-2">
          <LeftArrow
            className="w-8 h-8 text-cornflower-blue pl-1 cursor-pointer block "
            onClick={() => {
              navigate("/projects/settings/vault-types");
            }}
          />
        </div>
        <div className="flex mt-4 gap-5 flex-wrap justify-center md:justify-end pb-5">
          <Filter
            control={control}
            handleSubmit={handleSubmit}
            watch={watch}
            clearFilterHandler={clearFilterHandler}
            searchInputName="search"
            filterName="filter"
            options={filterFormOption}
            register={register}
            submitHandler={submitHandler}
            filterBtnShowHandler={filterBtnShowHandler}
            showFilter={showFilter}
            hideFilterBtn
            setValue={setValue}
            isCloseIconNeeded={true}
          />
          {search?.length === 0 && noOfItems === 0
            ? null
            : (canCreateOrUpdate && (
                <Filled buttonName="Add" onClick={addBtnHandler} />
              )) ||
              null}
        </div>
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectVaultCredentialTypeFieldsList?.map(
                    (
                      projectVaultCredentialTypeField: IProjectVaultCredentialTypeFields
                    ) => {
                      return (
                        <DataCard
                          key={projectVaultCredentialTypeField?.id}
                          dataModal={getDataModal(
                            projectVaultCredentialTypeField
                          )}
                          classForULlist={`${
                            canCreateOrUpdate
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          cardClick={() => {
                            canCreateOrUpdate &&
                              onEdit(projectVaultCredentialTypeField);
                          }}
                          classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                          otherOption={
                            (canDelete && (
                              <div className="flex justify-center">
                                <ToolTip
                                  render={() => "Delete"}
                                  arrow
                                  className={
                                    "text-center bg-[#616161] text-white"
                                  }
                                >
                                  <BiTrash
                                    className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDelete(projectVaultCredentialTypeField);
                                    }}
                                  />
                                </ToolTip>
                              </div>
                            )) ||
                            undefined
                          }
                        />
                      );
                    }
                  )}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  isSortOrderNeeded
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                >
                  {projectVaultCredentialTypeFieldsList?.map(
                    (
                      projectVaultEnvironment: IProjectVaultCredentialTypeFields
                    ) => {
                      return (
                        <TableContent
                          key={projectVaultEnvironment?.id}
                          tableRef={tableRef}
                          classForTableRow={`${
                            canRead
                              ? "cursor-pointer hover:bg-white-smoke"
                              : "cursor-default hover:bg-white-smoke"
                          }`}
                          tableRowClick={() => {
                            canRead && onEdit(projectVaultEnvironment);
                          }}
                          tableContent={getTableContent(
                            projectVaultEnvironment
                          )}
                        />
                      );
                    }
                  )}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditVaultTypesInputFields
          refetchProjectVaultCredentialTypeFields={
            refetchProjectVaultCredentialTypeFields
          }
          editProjectVaultCredentialTypeField={
            editProjectVaultCredentialTypeField
          }
          setShowModal={setShowModal}
          loading={loading}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Project"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectVaultCredentialTypeFieldLoading}
        />
      )}
    </div>
  );
};

export default VaultCredentialTypeFields;
