import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import Card from "../Card/UpdatedCardAgry/Card";

interface Props {
  className?: string;
  title?: ReactNode;
  classNameForTitle?: string;
  children?: ReactNode;
  addOrEditContent?: ReactNode;
  onClick?: () => void;
  classNameForTitleParent?: string;
  onCardClick?: () => void;
}

const UpdatedDataCard: FC<Props> = ({
  className,
  title,
  classNameForTitle,
  addOrEditContent,
  children,
  onClick,
  classNameForTitleParent,
  onCardClick,
}) => {
  return (
    <Card
      as={"div"}
      className={twMerge(
        `p-4 flex flex-col rounded-xl ${
          title || addOrEditContent ? "gap-4" : "gap-0"
        } w-full bg-white`,
        className
      )}
      onClick={() => {
        onCardClick && onCardClick();
      }}
    >
      <div
        className={twMerge(
          "flex items-center justify-between gap-3",
          classNameForTitleParent
        )}
        onClick={onClick}
      >
        {typeof title === "string" ? (
          <p
            className={twMerge(
              "text-on-surface truncate text-base font-medium",
              classNameForTitle
            )}
          >
            {title}
          </p>
        ) : (
          <div
            className={twMerge(
              "text-on-surface text-base truncate font-medium",
              classNameForTitle
            )}
          >
            {title}
          </div>
        )}
        {addOrEditContent}
      </div>
      {children}
    </Card>
  );
};

export default UpdatedDataCard;
