import { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import {
  Aadhaar,
  Filled,
  Modal,
  Outlined,
  Select,
  Text,
} from "components/forms";
import FileUpload from "components/forms/File/FileUpload";

import { Capitalize } from "global/helpers/Capitalize";
import { MdOutlineClose } from "react-icons/md";
import { Base64toObject } from "global/helpers/FileConverter";
import { idProofTypes } from "global/helpers/StaticData";
import { removeDuplicates } from "global/helpers/ArrayMethods";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import { GET_ID_PROOFS } from "modules/Employee/services/queries";
import {
  CREATE_ID_PROOF,
  UPDATE_ID_PROOF,
} from "modules/Employee/services/mutations";
import { IIdProof, IIdProofForm } from "modules/Employee/types/personal";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  idProofs: IIdProof[];
  setShowModal: Function;
  editID: number | null;
  setEditID: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetchIDProofs: Function;
}

const EditIdProof: FC<IProps> = ({
  idProofs,
  setShowModal,
  editID,
  setEditID,
  inProgress,
  setInProgress,
  refetchIDProofs,
}) => {
  const isUnderModeration = useIsUnderModeration();

  const editIdProof = idProofs?.filter(
    (idProof: IIdProof) => idProof?.id === editID
  )[0];

  const moderationFieldProps = {
    refetchQuery: refetchIDProofs,
  };

  const moderationObj = editIdProof?.moderation || null;

  const [selectedValue, setSelectedValue] = useState<string | null>("");
  const [file, setFile] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<{ name: string } | null>(null);

  const [createUserIdProof] = useMutation(CREATE_ID_PROOF);
  const [updateUserIdProof] = useMutation(UPDATE_ID_PROOF);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IIdProofForm>();

  useEffect(() => {
    if (editIdProof?.documents && editIdProof?.documents[0]?.file) {
      setFile(editIdProof?.documents[0]?.file);
    }
  }, [editIdProof]);

  useEffect(() => {
    if (editIdProof?.idProofType === "Aadhaar") {
      setValue("aadhaarIdProofValue", editIdProof?.idProofValue);
    } else {
      setValue("idProofValue", editIdProof?.idProofValue);
    }
  }, [editIdProof, setValue]);

  useEffect(() => {
    if (file) {
      setValue("idProofFile", file);
      clearErrors("idProofFile");
    }
  }, [clearErrors, file, setValue]);

  const createHandler = (data: IIdProofForm) => {
    const idProofValue =
      selectedValue === "Aadhaar"
        ? data.aadhaarIdProofValue
        : data.idProofValue && data.idProofValue?.toUpperCase();

    if (!inProgress) {
      createUserIdProof({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          idProofType: data.idProofType || null,
          idProofValue: idProofValue || null,
          documents: file
            ? [
                {
                  filePath: file,
                },
              ]
            : null,
        },
        update: (cache, { data: { createUserIdProof } }) => {
          const exIdProofs: any = cache.readQuery({
            query: GET_ID_PROOFS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });

          const updatedIdProofs = [
            ...exIdProofs?.users?.dataCollection[0]?.userIdProofs,
            createUserIdProof,
          ];

          cache.writeQuery({
            query: GET_ID_PROOFS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exIdProofs?.users?.dataCollection[0],
                    userIdProofs: updatedIdProofs,
                  },
                ],
              },
            },
          });
        },
      })
        .then((response) => {
          const idType = response.data?.createUserIdProof?.idProofType;
          const createActionSuccesMessage = isUnderModeration
            ? createSuccessMessage(`${idType}`)
            : createSuccessMessageWithAwaiting(`${idType}`);
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: IIdProofForm) => {
    const idProofValue =
      selectedValue === "Aadhaar" ||
      editIdProof?.idProofType?.toLowerCase() === "aadhaar"
        ? data.aadhaarIdProofValue
        : data.idProofValue && data.idProofValue?.toUpperCase();

    const idProof = fileDetails?.name
      ? [
          {
            filePath: file,
          },
        ]
      : null;

    if (!inProgress) {
      updateUserIdProof({
        variables: {
          id: editIdProof?.id,
          idProofValue: idProofValue,
          documents: idProof || undefined,
        },
      })
        .then((response) => {
          const idType = response.data?.updateUserIdProof?.idProofType;
          const updateActionSuccessMessage = isUnderModeration
            ? updateSuccessMessage(`${idType}`)
            : updateSuccessMessageWithAwaiting(`${idType}`);
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setFile(null);
    setEditID(null);
  };

  const removedDuplicateList = removeDuplicates(
    idProofs?.map((idProof: IIdProof) =>
      idProof?.idProofType === "aadhaar"
        ? Capitalize(idProof?.idProofType)
        : idProof?.idProofType
    )
  );

  const filteredIdProofType = idProofTypes?.filter(
    (relations: string) => !removedDuplicateList?.includes(relations)
  );

  let maximumLength: number | undefined;
  let idProofRegex: RegExp | null = null;
  switch (selectedValue ? selectedValue : editIdProof?.idProofType) {
    case "PAN": {
      maximumLength = 10;
      idProofRegex = /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/;
      break;
    }
    case "Passport": {
      maximumLength = 12;
      idProofRegex = /[a-zA-Z]{1}[0-9]{7}/;
      break;
    }
    case "Driving Licence": {
      maximumLength = 17;
      break;
    }
    default: {
      maximumLength = 35;
      break;
    }
  }

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">ID Proof</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <form
          onSubmit={
            editIdProof?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
          className="space-y-1"
        >
          <div className="pr-[35px] form-div lg:pr-[45px]">
            <Select
              register={register}
              name="idProofType"
              errors={errors}
              label="Select ID Proof *"
              options={
                editIdProof?.idProofType ? idProofTypes : filteredIdProofType
              }
              defaultSelectValue={
                editIdProof?.idProofType ? editIdProof?.idProofType : ""
              }
              disable={!!editIdProof?.idProofType}
              required={!editIdProof?.idProofType}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSelectedValue(event.target.value);
              }}
            />
            {editIdProof?.idProofType === "aadhaar" ||
            editIdProof?.idProofType === "Aadhaar" ||
            selectedValue === "Aadhaar" ? (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.idProofValue}
              >
                <Aadhaar
                  register={register}
                  errors={errors}
                  label={
                    editIdProof?.idProofType === "aadhaar" ||
                    editIdProof?.idProofType === "Aadhaar"
                      ? "Aadhaar *"
                      : selectedValue
                      ? `${selectedValue} *`
                      : ""
                  }
                  name="aadhaarIdProofValue"
                  defaultAadhaar={+editIdProof?.idProofValue || undefined}
                  required={true}
                  disabled={!!moderationObj?.idProofValue}
                />
              </ModerationField>
            ) : (
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.idProofValue}
              >
                <Text
                  register={register}
                  errors={errors}
                  label={
                    editIdProof?.idProofType
                      ? `${editIdProof?.idProofType} *`
                      : selectedValue
                      ? `${selectedValue} *`
                      : ""
                  }
                  name={"idProofValue"}
                  defaultText={editIdProof?.idProofValue}
                  required={true}
                  maximumLength={maximumLength}
                  minimumLength={6}
                  regex={idProofRegex ? idProofRegex : null}
                  disable={!!moderationObj?.idProofValue}
                />
              </ModerationField>
            )}
            <div className="pb-[6px] relative sh">
              {fileDetails?.name ? (
                ""
              ) : editIdProof === null ||
                (editIdProof?.documents &&
                  editIdProof?.documents[0]?.file?.length <= 100) ? (
                <div className="mt-[15px] ml-[12px] absolute">
                  <span className="text-cornflower-blue">
                    {editIdProof === null ? "" : "No File"}
                  </span>
                </div>
              ) : editIdProof?.documents &&
                editIdProof?.documents[0]?.file?.length > 100 ? (
                <div className="mt-[15px] ml-[12px] absolute z-10">
                  <a
                    href={Base64toObject(editIdProof?.documents[0]?.file)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-cornflower-blue cursor-pointer hover:underline"
                  >
                    View File
                  </a>
                </div>
              ) : (
                ""
              )}
              <ModerationField
                {...moderationFieldProps}
                moderationId={moderationObj?.filePath}
              >
                <FileUpload
                  register={register}
                  errors={errors}
                  name="idProofFile"
                  labelName={`${
                    editIdProof?.idProofType
                      ? editIdProof?.idProofType
                      : selectedValue
                  } File`}
                  file={file || ""}
                  setFile={setFile}
                  fileDetails={fileDetails}
                  setFileDetails={setFileDetails}
                  defaultFileValue={
                    editIdProof?.documents && editIdProof?.documents[0]?.file
                      ? editIdProof?.documents[0]?.file
                      : null
                  }
                  disabled={!!moderationObj?.filePath}
                />
              </ModerationField>
            </div>
          </div>
          <div className="w-full border-b border-hit-gray" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined onClick={moduleCloseHandler} buttonName="Cancel" />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : editIdProof?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditIdProof;
