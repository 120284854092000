import { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Filled, Outlined } from "components/forms";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import TitleAndBackNavigation from "components/UI/TitleAndBackNavigation";
import {
	AutoComplete,
	Input,
	Select,
	TextArea,
} from "components/forms/UpdatedFormAgry";
import FileTriggerComponent from "components/forms/UpdatedFormAgry/File/FileTrigger";

import { toastNotify } from "global/helpers/Cache";
import {
	bugAndTaskPlatforms,
	bugAndTaskPriorities,
	imageURLAsBase64,
} from "global/helpers/utils";
import useAllowedResources from "global/hooks/useAllowedResources";
import { notEmpty } from "global/helpers/ArrayMethods";

import { ProjectGroupTaskFormType } from "modules/Project/Pages/Projects/ProjectGroupTasks/types";
import {
	CREATE_PROJECT_GROUP_TASK,
	FILTER_PROJECT_GROUP_TASKS,
	UPDATE_PROJECT_GROUP_TASK,
} from "modules/Project/Pages/Projects/ProjectGroupTasks/services";
import { PROJECTS_FOR_DROP_DOWN } from "modules/Project/services/queries";
import { IProject } from "modules/Project/types/project";

const EditProjectGroupTask = () => {
	const allowedResources = useAllowedResources();

	const canCreate = allowedResources?.includes("CreateProjectGroupTask");
	const canUpdate = allowedResources?.includes("UpdateProjectGroupTask");

	const { groupId, projectGroupTaskId } = useParams();
	const navigate = useNavigate();

	const canReadAllProjects = allowedResources?.includes("AllProject");
	const canReadMyProject = allowedResources?.includes("MyProject");

	const [createProjectGroupTask, { loading: createProjectGroupTaskLoading }] =
		useMutation(CREATE_PROJECT_GROUP_TASK, {
			refetchQueries: [FILTER_PROJECT_GROUP_TASKS],
		});

	const [updateProjectGroupTask, { loading: updateProjectGroupTaskLoading }] =
		useMutation(UPDATE_PROJECT_GROUP_TASK, {
			refetchQueries: [FILTER_PROJECT_GROUP_TASKS],
		});

	const [fetchProjectGroupTasks, { loading }] = useLazyQuery(
		FILTER_PROJECT_GROUP_TASKS,
		{
			fetchPolicy: "network-only",
		},
	);

	const [imageLoading, setImageLoading] = useState(true);

	const {
		reset,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ProjectGroupTaskFormType>({
		defaultValues: async () => {
			const projectGroupTask =
				projectGroupTaskId && Number.isFinite(+projectGroupTaskId)
					? await fetchProjectGroupTasks({
							variables: {
								filters: {
									id: +projectGroupTaskId,
								},
							},
					  }).then(({ data }) =>
							data?.filterProjectGroupTasks?.dataCollection &&
							data?.filterProjectGroupTasks?.dataCollection?.length > 0
								? data?.filterProjectGroupTasks?.dataCollection[0]
								: undefined,
					  )
					: undefined;

			const projects =
				projectGroupTask?.projectBacklogs?.dataCollection &&
				projectGroupTask?.projectBacklogs?.dataCollection?.length > 0
					? projectGroupTask?.projectBacklogs?.dataCollection?.reduce(
							(acc: { id: number; label: string }[], projectGroupBug) => {
								if (
									projectGroupBug?.project?.id &&
									projectGroupBug?.project?.name
								) {
									acc.push({
										id: projectGroupBug?.project?.id,
										label: projectGroupBug?.project?.name,
									});
									return acc;
								}
								return acc;
							},
							[],
					  )
					: [];

			const filePromises =
				projectGroupTask?.attachments &&
				projectGroupTask?.attachments?.length > 0
					? projectGroupTask?.attachments?.map(async (item) => {
							const file = item?.fileUrl
								? await imageURLAsBase64(item?.fileUrl)
								: null;
							return {
								id: item?.id,
								file: file?.file,
							};
					  })
					: [];
			const existingFiles = await Promise.all(filePromises);

			setImageLoading(false);

			return {
				title: projectGroupTask?.title || (null as unknown as string),
				platform: projectGroupTask?.platform || undefined,
				priority: projectGroupTask?.priority || undefined,
				status: projectGroupTask?.status || undefined,
				description: projectGroupTask?.description || undefined,
				projects,
				attachments: existingFiles,
			};
		},
	});

	const [watchStatus, watchProjects] = useWatch({
		control,
		name: ["status", "projects"],
	});

	const { data: filterProjects, loading: filterProjectsLoading } = useQuery(
		PROJECTS_FOR_DROP_DOWN,
		{
			skip:
				watchStatus !== "Moved" ||
				!projectGroupTaskId ||
				(!canReadAllProjects && !canReadMyProject) ||
				(groupId && !Number.isFinite(+groupId))
					? true
					: false,
			variables: {
				filters: groupId
					? {
							projectGroupId: +groupId,
					  }
					: undefined,
			},
		},
	);

	const projects: { id: number; label: string }[] =
		filterProjects?.projects?.dataCollection?.reduce(
			(acc: { id: number; label: string }[], project: IProject) => {
				if (project?.id && project?.name) {
					acc.push({ id: project?.id, label: project?.name });
					return acc;
				}
				return acc;
			},
			[],
		) || [];

	const projectGroupTaskSubmitHandler: SubmitHandler<ProjectGroupTaskFormType> =
		({
			title,
			description,
			priority,
			platform,
			status,
			projects,
			attachments,
		}) => {
			if (
				projectGroupTaskId &&
				Number.isFinite(+projectGroupTaskId) &&
				!updateProjectGroupTaskLoading &&
				groupId
			) {
				updateProjectGroupTask({
					variables: {
						projectGroupTaskUpdateInput: {
							id: +projectGroupTaskId,
							title: title?.trim(),
							description: description?.trim(),
							platform: platform || [],
							priority: priority || "",
							projectGroupId: +groupId,
							status: status || "",
							projectIds:
								projects && projects?.length > 0
									? projects?.map((project) => project?.id)
									: [],
							attachments:
								attachments && attachments?.length > 0
									? attachments
											?.map((attachment) => {
												if (attachment?.file) {
													return attachment?.file;
												} else {
													return null;
												}
											})
											.filter(notEmpty)
									: [],
						},
					},
				})
					.then(() => {
						navigate(`/projects/${groupId}/tasks`);
						reset();
						toastNotify([
							{
								messageType: "success",
								message: "Project group task updated successfully.",
							},
						]);
					})
					.catch((error) => {
						toastNotify([
							{
								messageType: "error",
								message: error?.message,
							},
						]);
					});
			} else if (!createProjectGroupTaskLoading && groupId) {
				createProjectGroupTask({
					variables: {
						projectGroupTaskCreateInput: {
							title,
							description: description?.trim() || undefined,
							platform: platform || undefined,
							priority: priority || undefined,
							projectGroupId: +groupId,
							status: status || undefined,
							attachments:
								attachments && attachments?.length > 0
									? attachments
											?.map((attachment) => {
												if (attachment?.file) {
													return attachment?.file;
												} else {
													return null;
												}
											})
											.filter(notEmpty)
									: undefined,
						},
					},
				})
					.then((_response) => {
						navigate(`/projects/${groupId}/tasks`);
						reset();
						toastNotify([
							{
								messageType: "success",
								message: "Project group task created successfully.",
							},
						]);
					})
					.catch((error) => {
						toastNotify([
							{
								messageType: "error",
								message: error?.message,
							},
						]);
					});
			}
		};

	return loading || imageLoading ? (
		<Loading className="min-h-[700px]" />
	) : (
		<div className="flex justify-center min-h-[75vh]">
			<form onSubmit={handleSubmit(projectGroupTaskSubmitHandler)}>
				<TitleAndBackNavigation
					title={{
						children: `${
							projectGroupTaskId && Number.isFinite(+projectGroupTaskId)
								? "Edit task"
								: "Add task"
						}`,
					}}
					navigation={{
						onClick: () => {
							navigate(`/projects/${groupId}/tasks`);
						},
					}}
					className="text-sm mb-3"
				/>
				<div className=" min-w-[330px] sm:min-w-[370px] flex flex-col h-min">
					<Input control={control} label="Title *" name="title" required />
					<div className="">
						<Select
							control={control}
							label="Priority *"
							name="priority"
							options={bugAndTaskPriorities}
							className="min-h-[45px]"
						/>
					</div>
					<div className="">
						<Select
							control={control}
							label="Platform"
							name="platform"
							options={bugAndTaskPlatforms}
							multiple
							className="min-h-[45px]"
						/>
					</div>
					<div className="">
						{projectGroupTaskId && Number.isFinite(+projectGroupTaskId) && (
							<Select
								control={control}
								label="Status"
								name="status"
								options={[
									"Moved",
									"Abandoned",
									"Accepted",
									"Duplicate",
									"Pending",
								]}
								className="bg-white"
							/>
						)}
					</div>
					{projectGroupTaskId && watchStatus === "Moved" && (
						<AutoComplete
							control={control}
							label="Projects *"
							name="projects"
							options={projects}
							multiple
							loading={filterProjectsLoading}
							className="bg-white col-span-2"
						/>
					)}
					<div className="col-span-2">
						<TextArea
							label="Description"
							name="description"
							control={control}
						/>
					</div>
					<FileTriggerComponent
						control={control}
						name="attachments"
						className="bg-white pb-6 col-span-2"
					/>
					<div className="flex justify-between sm:col-span-2 mb-3">
						<Outlined
							buttonName={"Cancel"}
							onClick={() => navigate(`/projects/${groupId}/bugs`)}
						/>
						<Filled
							disabled={
								(watchStatus?.toLowerCase() === "moved" &&
									watchProjects?.length === 0) ||
								isValid
									? projectGroupTaskId && Number.isFinite(+projectGroupTaskId)
										? !canUpdate
										: !canCreate
									: true
							}
							buttonName={
								projectGroupTaskId && Number.isFinite(+projectGroupTaskId)
									? "Update"
									: "Add"
							}
							loading={
								createProjectGroupTaskLoading || updateProjectGroupTaskLoading
							}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};
export default EditProjectGroupTask;
