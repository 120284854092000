import { useLazyQuery, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { SearchField, Select } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import { DateFormat } from "global/helpers/DateFormatter";
import { paginationDefaultValue } from "global/helpers/StaticData";
import { FILTER_TEAM_LEAVE_REQUESTS } from "modules/Leave/services/queries";
import TeamRequestsDetails from "modules/Leave/Pages/Requests/TeamRequests/TeamRequestsDetails";
import ViewPage from "modules/Leave/Pages/Requests/TeamRequests/ViewPage";
import { GET_LEAVE_TYPES } from "modules/Employee/services/queries";

const TeamRequests = () => {
  const { data: leaveTypes } = useQuery(GET_LEAVE_TYPES);

  const leaveOptions = leaveTypes?.leaveTypes?.dataCollection?.map(
    (leaveType: { id: number; name: string }) => {
      return {
        id: leaveType.id,
        name: leaveType.name,
      };
    }
  );

  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate && dateRange[0]?.startDate,
    dateRange[0]?.endDate && dateRange[0]?.endDate
  );

  const filterEmployee = watch("filterEmployee");
  const leaveType = watch("leaveType");
  const filterStatus = watch("status");
  const fromDate =
    dateRange[0]?.startDate && DateFormat(dateRange[0]?.startDate?.toString());
  const toDate =
    dateRange[0]?.endDate && DateFormat(dateRange[0]?.endDate?.toString());

  const [search, setSearch] = useState(filterEmployee);
  const [status, setStatus] = useState("Pending");

  useEffect(() => {
    setStatus(filterStatus);
  }, [filterStatus]);

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(filterEmployee?.trim() || undefined);
    }, 300);

    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [filterEmployee]);

  const [fetchLeaveRequests, { data: filterTeamLeaveRequests, loading }] =
    useLazyQuery(FILTER_TEAM_LEAVE_REQUESTS, {
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    fetchLeaveRequests({
      variables: {
        leaveType: +leaveType ? +leaveType : undefined,
        status: status ? status : undefined,
        from: fromDate ? fromDate : undefined,
        to: toDate ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
        name: search ? search : undefined,
      },
    });
  }, [
    pageSize,
    currentPage,
    status,
    fromDate,
    toDate,
    leaveType,
    search,
    fetchLeaveRequests,
  ]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] lg:grid-cols-[repeat(3,250px)] sm:gap-x-3 sm:gap-y-0 2xl:grid-cols-[repeat(5,216px)] justify-center items-center 2xl:justify-start">
                  <div className="pb-5">
                    <SearchField
                      register={register}
                      name="filterEmployee"
                      label="Employee"
                      onChange={() => {
                        setCurrentPage(1);
                      }}
                      setValue={setValue}
                    />
                  </div>
                  <SelectWithId
                    register={register}
                    name={"leaveType"}
                    errors={errors}
                    label={"Leave Type"}
                    options={leaveOptions}
                    enableDefaultSelectOption
                  />
                  <Select
                    register={register}
                    errors={errors}
                    options={[
                      "Approved",
                      "Declined",
                      "Partially Approved",
                      "Pending",
                      "Agreed",
                      "Disagreed",
                    ]}
                    label="Status"
                    name="status"
                    required={true}
                    defaultSelectValue={"Pending"}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                </div>
              </div>
              <TeamRequestsDetails
                teamMembersRequest={
                  filterTeamLeaveRequests?.filterTeamMemberLeaveRequests
                    ?.dataCollection || []
                }
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={
                  filterTeamLeaveRequests?.filterTeamMemberLeaveRequests
                    ?.totalNumberOfItems
                }
                loading={loading}
                filterLeaveRequests={filterTeamLeaveRequests}
              />
            </div>
          }
        />
        <Route path={`:id`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default TeamRequests;
