import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import UserDetails from "components/UserDetails/UserDetails";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import ToggleSwitchWithLabel from "components/forms/UpdatedForm/ToggleSwitch/ToggleSwitchWithLabel";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  toastNotify,
  setBackNavigationURL,
  setTitle,
} from "global/helpers/Cache";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import { PROJECTS } from "modules/Project/services/queries";
import {
  CREATE_OR_UPDATE_PROJECT,
  DELETE_PROJECT,
} from "modules/Project/services/mutations";
import { IProject } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

const tableCommonHeadings = [
  {
    name: "Name",
    APIName: "name",
  },
  {
    name: "Key",
    APIName: "projectKey",
  },
  {
    name: "Manager",
    APIName: "manager",
  },
  {
    name: "Category",
    APIName: "category",
  },
  {
    name: "Status",
    APIName: "status",
  },
  {
    name: "Time zone",
  },
];

const Projects: FC<{
  allowedResources: string[];
  isMyProject: boolean;
  projectGroup?: ProjectGroup | null;
}> = ({ allowedResources, isMyProject, projectGroup }) => {
  const canCreate = allowedResources?.includes("SaveProject");
  const canRead = allowedResources?.includes("ReadProject");
  const canDelete = allowedResources?.includes("DeleteProject");

  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const { groupId } = useParams();

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const projectType = pathname?.split("/")[3];

  const navigate = useNavigate();

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [deleteProject, { loading: deleteProjectLoading }] = useMutation(
    DELETE_PROJECT,
    {
      refetchQueries: [PROJECTS],
    }
  );

  const [saveProject, { loading: projectLoading }] = useMutation(
    CREATE_OR_UPDATE_PROJECT,
    {
      refetchQueries: [PROJECTS],
    }
  );

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    setTitle(
      groupId
        ? pathname === `/projects/${projectGroup?.id}/my-projects`
          ? [
              { name: "Project", link: "/projects/groups" },
              { name: "Groups", link: "/projects/groups" },
              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/my-projects`,
              },
              {
                name: "My Projects",
                link: `/projects/${projectGroup?.id}/my-projects`,
              },
            ]
          : pathname === `/projects/${projectGroup?.id}/all-projects`
          ? [
              { name: "Project", link: "/projects/groups" },
              { name: "Groups", link: "/projects/groups" },
              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/all-projects`,
              },
              {
                name: "All Projects",
                link: `/projects/${projectGroup?.id}/all-projects`,
              },
            ]
          : [
              { name: "Project", link: "/projects/groups" },
              { name: "Groups", link: "/projects/groups" },
              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/trash`,
              },
              { name: "Trash", link: `/projects/${groupId}/trash` },
            ]
        : // Project without group
        pathname === `/projects/projects/my-projects`
        ? [
            { name: "Project", link: "/projects/projects/my-projects" },
            {
              name: "My Projects",
              link: `/projects/projects/my-projects`,
            },
          ]
        : pathname === `/projects/projects/all-projects`
        ? [
            { name: "Project", link: "/projects/projects/my-projects" },
            {
              name: "All Projects",
              link: `/projects/projects/all-projects`,
            },
          ]
        : [
            { name: "Project", link: "/projects/projects/my-projects" },
            { name: "Trash", link: `/projects/projects/trash` },
          ]
    );
  }, [pathname, groupId, projectGroup?.name, projectGroup?.id]);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const [fetchMyProjects, { data: projects, loading, refetch }] = useLazyQuery(
    PROJECTS,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "network-only",
    }
  );

  const projectsList = projects?.projects?.dataCollection || [];
  const totalCount = projects?.projects?.totalNumberOfItems || 0;

  const noOfItems = projectsList?.length || 0;

  const [userId, setUserId] = useState<number | undefined>();
  const [editProject, setEditProject] = useState<IProject | null>(null);
  const [filterValues, setFilterValue] = useState<object | null>(null);
  const watchArchive = watch("isArchived");
  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 100);
  }, [refetch, projectType]);

  useEffect(() => {
    setValue("isArchived", false);
  }, [projectType, setValue]);

  useEffect(() => {
    if (projectType) {
      setSortOrder(null);
    }
  }, [projectType]);

  useEffect(() => {
    if (projectType === "my-projects") {
      fetchMyProjects({
        variables: {
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
          orderBy: sortOrder
            ? {
                field: sortOrder?.field,
                orderType: sortOrder?.orderType,
              }
            : undefined,
          filters: {
            isMyProject: true,
            isArchived: watchArchive ? true : false,
            projectGroupId: groupId ? +groupId : undefined,
          },
        },
      });
    } else if (projectType === "all-projects") {
      fetchMyProjects({
        variables: {
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
          orderBy: sortOrder
            ? {
                field: sortOrder?.field,
                orderType: sortOrder?.orderType,
              }
            : undefined,
          filters: {
            isArchived: watchArchive ? true : false,
            projectGroupId: groupId ? +groupId : undefined,
          },
        },
      });
    } else {
      fetchMyProjects({
        variables: {
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
          orderBy: sortOrder
            ? {
                field: sortOrder?.field,
                orderType: sortOrder?.orderType,
              }
            : undefined,
          filters: {
            isTrashed: true,
            projectGroupId: groupId ? +groupId : undefined,
          },
        },
      });
    }
  }, [
    currentPage,
    fetchMyProjects,
    isMyProject,
    pageSize,
    search,
    projectType,
    refetch,
    watchArchive,
    sortOrder,
    groupId,
  ]);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const deletePermanently = watch("isTrashed");

  const trashHandler = () => {
    if (!projectLoading) {
      saveProject({
        variables: {
          project: {
            id: editProject?.id,
            isTrashed: !deletePermanently ? true : false,
          },
        },
      })
        .then(() => {
          const projectName = editProject?.name;
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: `${
                projectName ? `${projectName} project` : "Project"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  const deleteHandler = () => {
    if (editProject?.id) {
      deleteProject({
        variables: {
          id: +editProject?.id,
          isTrashed: watch,
        },
      })
        .then(() => {
          const projectName = editProject?.name;
          setDeleteVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: `${
                projectName ? `${projectName} project` : "Project"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (project: IProject) => {
    navigate(
      `/projects/${groupId ? `${groupId}` : "projects"}/${projectType}/edit/${
        project?.id
      }/general`
    );
  };

  const onDelete = (project: IProject) => {
    setEditProject(project);
    setDeleteVerification(true);
  };


  const addBtnHandler = () => {
    navigate(groupId ?  `/projects/${groupId}/${projectType}/edit/new/general` :  `/projects/projects/${projectType}/edit/new/general`);
    setEditProject(null);
  };

  const getDataModal = (project: IProject): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: project?.name,
        },
      ],
    },
    {
      label: "Key",
      value: [
        {
          content: project?.projectKey,
        },
      ],
    },
    {
      label: "Manager",
      value: [
        {
          content: (
            <div className="group">
              <p
                onMouseEnter={() =>
                  setUserId(project?.manager?.personalInformation?.id)
                }
              >
                {project?.manager?.personalInformation?.name}
              </p>
              <UserDetails userId={userId} />
            </div>
          ),
        },
      ],
    },
    {
      label: "Category",
      value: [
        {
          content: project?.category,
        },
      ],
    },
    {
      label: "Status",
      value: [
        {
          content: project?.status,
        },
      ],
    },
    {
      label: "Time Zone",
      value: [
        {
          content: project?.clientTimeZone,
        },
      ],
    },
  ];
  const getTableContent = (project: IProject): ITableContent[] => [
    { value: project?.name },
    {
      value: project?.projectKey,
    },
    {
      value: (
        <div className="group">
          <p
            onMouseEnter={() =>
              setUserId(project?.manager?.personalInformation?.id)
            }
          >
            {project?.manager?.personalInformation?.name}
          </p>
          <UserDetails userId={userId} />
        </div>
      ),
    },
    {
      value: project?.category,
      center: true,
    },
    { value: project?.status, center: true },
    {
      value: project?.clientTimeZone,
      center: true,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(project);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        {projectType !== "trash" && (
          <ToggleSwitchWithLabel
            label={"Is archived?"}
            register={register}
            name={"isArchived"}
            defaultValue={editProject?.isArchived}
            setValue={setValue}
            className="h-min sm:pb-0 flex gap-3"
          />
        )}
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />
        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreate && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectsList?.map((project: IProject) => {
                    return (
                      <DataCard
                        key={project?.id}
                        dataModal={getDataModal(project)}
                        classForULlist={`${
                          canRead ? "cursor-pointer" : "cursor-default"
                        }`}
                        cardClick={() => {
                          canRead && onEdit(project);
                        }}
                        classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                        disableClickToViewToolTip={true}
                        otherOption={
                          (canDelete && (
                            <div className="flex justify-center">
                              <ToolTip
                                render={() => "Delete"}
                                arrow
                                className={
                                  "text-center bg-[#616161] text-white"
                                }
                              >
                                <BiTrash
                                  className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(project);
                                  }}
                                />
                              </ToolTip>
                            </div>
                          )) ||
                          undefined
                        }
                      />
                    );
                  })}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center:
                            heading?.name === "Name" ||
                            heading?.name === "Manager" ||
                            heading?.name === "Key"
                              ? false
                              : true,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  isSortOrderNeeded
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                >
                  {projectsList?.map((project: IProject) => {
                    return (
                      <TableContent
                        key={project?.id}
                        tableRef={tableRef}
                        classForTableRow={`${
                          canRead
                            ? "cursor-pointer hover:bg-white-smoke"
                            : "cursor-default hover:bg-white-smoke"
                        }`}
                        tableRowClick={() => {
                          canRead && onEdit(project);
                        }}
                        tableContent={getTableContent(project)}
                      />
                    );
                  })}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreate && projectType !== "trash" ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}
      {deleteVerification && (
        <ConfirmModal
          header="Project"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={
            deletePermanently || projectType === "trash"
              ? deleteHandler
              : trashHandler
          }
          loading={deleteProjectLoading || projectLoading}
          customMessage={
            projectType === "trash"
              ? "delete this project forever"
              : "delete this project"
          }
          showPermanentDelete={projectType === "trash" ? false : true}
          register={register}
          name={"isTrashed"}
        />
      )}
    </div>
  );
};

export default Projects;
