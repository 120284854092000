import { ChangeEvent, FC, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";

import Select from "components/forms/UpdatedForm/Select";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import { CalendarField } from "components/forms";
import CustomRenderUserImage from "components/forms/UpdatedForm/AutoComplete/CustomRenderUserImage";
import Tooltip from "components/UI/ToolTip";

import { IIdAndName, IUser, IUserProfileImage } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import { getFileExtension } from "global/helpers/getFileExtension";

import {
	COLUMNS_FOR_DROP_DOWN,
	GET_PROJECT_MANAGER,
	SPRINTS_FOR_DROP_DOWN,
	TASK_LABEL_FOR_DROP_DOWN,
} from "modules/Project/services/queries";
import {
	IProjectMembersList,
	IProjectTask,
} from "modules/Project/types/project";
import {
	SAVE_PROJECT_TASK_BILLING_STATUS,
	SAVE_PROJECT_TASK_COLUMN,
	SAVE_PROJECT_TASK_DUE_DATE,
	SAVE_PROJECT_TASK_INCHARGE,
	SAVE_PROJECT_TASK_LABEL,
	SAVE_PROJECT_TASK_SPRINT,
	SAVE_PROJECT_TASK_START_DATE,
} from "modules/Project/services/mutations";

interface IProps {
	projectTask: IProjectTask;
	allowedResourcesForProject: { [key: string]: boolean };
	projectMembersList: IProjectMembersList[];
}

const TaskDetails: FC<IProps> = ({
	projectTask,
	allowedResourcesForProject,
	projectMembersList,
}) => {
	const canReadColumn = allowedResourcesForProject?.ReadProjectTaskColumn;
	const canReadIncharge = allowedResourcesForProject?.ReadProjectTaskInCharge;
	const canReadSprint = allowedResourcesForProject?.ReadProjectTaskSprint;
	const canReadTaskLabel = allowedResourcesForProject?.ReadProjectTaskLabel;
	const canReadStartDate = allowedResourcesForProject?.ReadProjectTaskStartDate;
	const canReadDueDate = allowedResourcesForProject?.ReadProjectTaskDueDate;
	const canReadBillingStatus =
		allowedResourcesForProject?.ReadProjectTaskBillingStatus;
	const canReadProjectPriority =
		allowedResourcesForProject?.ReadProjectTaskPriority;

	const canChangeColumnPosition =
		allowedResourcesForProject?.ChangeColumnPosition;
	const canSaveIncharge = allowedResourcesForProject?.SaveProjectTaskInCharge;
	const canSaveSprint = allowedResourcesForProject?.SaveProjectTaskSprint;
	const canSaveTaskLabel = allowedResourcesForProject?.SaveProjectTaskStartDate;
	const canSaveStartDate = allowedResourcesForProject?.SaveProjectTaskStartDate;
	const canSaveDueDate = allowedResourcesForProject?.SaveProjectTaskDueDate;
	const canSaveBillingStatus =
		allowedResourcesForProject?.SaveProjectTaskBillingStatus;
	const hasAnySaveAccess =
		canSaveIncharge ||
		canSaveSprint ||
		canSaveTaskLabel ||
		canSaveStartDate ||
		canSaveDueDate ||
		canSaveBillingStatus;
	const canSaveProjectTaskPriority =
		allowedResourcesForProject?.SaveProjectTaskPriority;

	const [saveProjectTaskIncharge] = useMutation(SAVE_PROJECT_TASK_INCHARGE);
	const [saveProjectTaskColumn] = useMutation(SAVE_PROJECT_TASK_COLUMN);
	const [saveProjectTaskSprint] = useMutation(SAVE_PROJECT_TASK_SPRINT);
	const [saveProjectTaskLabel] = useMutation(SAVE_PROJECT_TASK_LABEL);
	const [saveProjectTaskStartDate] = useMutation(SAVE_PROJECT_TASK_START_DATE);
	const [saveProjectTaskDueDate] = useMutation(SAVE_PROJECT_TASK_DUE_DATE);
	const [saveProjectTaskBillingStatus] = useMutation(
		SAVE_PROJECT_TASK_BILLING_STATUS,
	);

	const { id, issueId } = useParams();

	const [fetchSprints, { data: allSprints }] = useLazyQuery(
		SPRINTS_FOR_DROP_DOWN,
	);

	const [fetchColumns, { data: allColumns }] = useLazyQuery(
		COLUMNS_FOR_DROP_DOWN,
	);

	const [fetchTaskLabel, { data: allTaskStatus }] = useLazyQuery(
		TASK_LABEL_FOR_DROP_DOWN,
	);

	const { data: getProjectManager } = useQuery(GET_PROJECT_MANAGER);

	const {
		control,
		formState: { errors },
		setValue,
		register,
		clearErrors,
	} = useForm();

	const [projectTaskLoading, setProjectTaskLoading] = useState<{
		columnLoading: boolean;
		inChargeLoading: boolean;
		sprintLoading: boolean;
		labelLoading: boolean;
		startDateLoading: boolean;
		dueDateLoading: boolean;
		billingStatusLoading: boolean;
		assigneeLoading: boolean;
		priorityLoading: boolean;
	}>({
		columnLoading: false,
		inChargeLoading: false,
		sprintLoading: false,
		labelLoading: false,
		startDateLoading: false,
		dueDateLoading: false,
		billingStatusLoading: false,
		assigneeLoading: false,
		priorityLoading: false,
	});

	const [dueDate, setDueDate] = useState<Date | null | undefined>();
	const [startDate, setStartDate] = useState<Date | null | undefined>();
	const [isStartDateChanged, setIsStartDateChanged] = useState<boolean>(false);
	const [isDueDateChanged, setIsDueDateChanged] = useState<boolean>(false);

	const hasIdAndIssueId =
		id && !Number.isNaN(+id) && issueId && !Number.isNaN(+issueId);
	const commonVariables = hasIdAndIssueId && { id: +issueId, projectId: +id };

	useEffect(() => {
		if (projectTask?.dueDate) {
			setDueDate(new Date(projectTask?.dueDate?.replaceAll("-", " ")));
		}
		if (projectTask?.startDate) {
			setStartDate(new Date(projectTask?.startDate?.replaceAll("-", " ")));
		}
	}, [projectTask?.dueDate, projectTask?.startDate]);

	useEffect(() => {
		if (dueDate) {
			setValue("dueDate", dueDate?.toString());
			clearErrors("dueDate");
		}

		if (startDate) {
			setValue("startDate", startDate?.toString());
			clearErrors("startDate");
		}
	}, [dueDate, setValue, clearErrors, startDate]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchTaskLabel({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchTaskLabel, id]);

	useEffect(() => {
		if (
			id &&
			!Number.isNaN(+id) &&
			allowedResourcesForProject?.ReadProjectSprint
		) {
			fetchSprints({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchSprints, id, allowedResourcesForProject]);

	useEffect(() => {
		if (
			id &&
			!Number.isNaN(+id) &&
			allowedResourcesForProject?.ChangeColumnPosition &&
			allowedResourcesForProject?.ReadProjectColumn
		) {
			fetchColumns({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchColumns, id, allowedResourcesForProject]);

	const taskStatusList: IIdAndName[] =
		allTaskStatus?.projectTaskLabel?.dataCollection?.map(
			(projectSprint: IIdAndName) => {
				return {
					id: projectSprint?.id,
					name: projectSprint?.name,
				};
			},
		);

	const sprintsList: { id: number; name: string }[] = useMemo(
		() =>
			allSprints?.projectSprints?.dataCollection?.map(
				(projectSprint: IIdAndName) => {
					return {
						id: projectSprint?.id,
						name: projectSprint?.name,
					};
				},
			) || [],
		[allSprints?.projectSprints?.dataCollection],
	);

	const columnList: IIdAndName[] =
		allColumns?.projectColumns?.dataCollection?.map(
			(projectSprint: IIdAndName) => {
				return {
					id: projectSprint?.id,
					name: projectSprint?.name,
				};
			},
		);

	const inchargeList = projectMembersList?.map((member: { user: IUser }) => {
		return {
			id: member?.user?.personalInformation?.id,
			name: member?.user?.personalInformation?.name,
			image:
				member?.user?.userProfileImages?.length > 0 &&
				member?.user?.userProfileImages[0]?.documents?.length > 0
					? member?.user?.userProfileImages[0]?.documents[0]?.file
					: "",
		};
	});

	const projectManager =
		getProjectManager?.projects?.dataCollection?.length > 0 &&
		getProjectManager?.projects?.dataCollection[0]?.manager?.personalInformation
			?.id &&
		getProjectManager?.projects?.dataCollection[0]?.manager?.personalInformation
			?.name
			? {
					id: getProjectManager?.projects?.dataCollection[0]?.manager
						?.personalInformation?.id,
					name: getProjectManager?.projects?.dataCollection[0]?.manager
						?.personalInformation?.name,
			  }
			: null;

	const assigneeUserIds = projectTask?.projectTaskApprovedHours?.map(
		(task) => task?.assigneeUser?.user?.id,
	);

	const assigneeUserIdsWithProfile = projectMembersList?.filter((member) =>
		assigneeUserIds?.includes(member?.user?.personalInformation?.id),
	);

	useEffect(() => {
		if (
			!projectTaskLoading?.startDateLoading &&
			id &&
			!Number.isNaN(+id) &&
			issueId &&
			!Number.isNaN(+issueId) &&
			isStartDateChanged
		) {
			saveProjectTaskStartDate({
				variables: {
					projectTaskInput: {
						id: +issueId,
						projectId: +id,
						startDate: startDate && ViewDateFormat(startDate),
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Start date is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							startDateLoading: false,
						};
					});
				});
		}
	}, [
		projectTaskLoading?.startDateLoading,
		isStartDateChanged,
		id,
		issueId,
		startDate,
		saveProjectTaskStartDate,
	]);

	useEffect(() => {
		if (
			!projectTaskLoading?.dueDateLoading &&
			id &&
			!Number.isNaN(+id) &&
			issueId &&
			!Number.isNaN(+issueId) &&
			isDueDateChanged
		) {
			saveProjectTaskDueDate({
				variables: {
					projectTaskInput: {
						id: +issueId,
						projectId: +id,
						dueDate: dueDate && ViewDateFormat(dueDate),
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Due date is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							dueDateLoading: false,
						};
					});
				});
		}
	}, [
		projectTaskLoading?.dueDateLoading,
		isDueDateChanged,
		id,
		issueId,
		dueDate,
		saveProjectTaskDueDate,
	]);

	const projectInchargeChangeHandler = (e: ChangeEvent<any>) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				inChargeLoading: true,
			};
		});
		if (!projectTaskLoading?.inChargeLoading && hasIdAndIssueId) {
			saveProjectTaskIncharge({
				variables: {
					projectTaskInput: {
						...commonVariables,
						inchargeId: +e.target.value?.id,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Incharge changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							inChargeLoading: false,
						};
					});
				});
		}
	};

	const projectColumnChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				columnLoading: true,
			};
		});
		if (!projectTaskLoading?.columnLoading && hasIdAndIssueId) {
			saveProjectTaskColumn({
				variables: {
					projectTaskInput: {
						...commonVariables,
						projectColumnId: +e.target.value,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Task column is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							columnLoading: false,
						};
					});
				});
		}
	};

	const projectSprintChangeHandler = (e: ChangeEvent<any>) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				columnLoading: true,
			};
		});
		if (!projectTaskLoading?.sprintLoading && hasIdAndIssueId) {
			saveProjectTaskSprint({
				variables: {
					projectTaskInput: {
						...commonVariables,
						projectSprintId: +e.target.value?.id,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Sprint is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							columnLoading: false,
						};
					});
				});
		}
	};

	const projectLabelChangeHandler = (e: ChangeEvent<any>) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				labelLoading: true,
			};
		});

		if (!projectTaskLoading?.labelLoading && hasIdAndIssueId) {
			saveProjectTaskLabel({
				variables: {
					projectTaskInput: {
						...commonVariables,
						projectTaskLabelId: +e.target.value,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Task Label is changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							labelLoading: false,
						};
					});
				});
		}
	};

	const projecPriorityChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				priorityLoading: true,
			};
		});
		if (!projectTaskLoading?.priorityLoading && hasIdAndIssueId) {
			saveProjectTaskBillingStatus({
				variables: {
					projectTaskInput: {
						...commonVariables,
						priority: e.target.value,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Priority changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							priorityLoading: false,
						};
					});
				});
		}
	};

	const projectBillingStatusChangeHandler = (
		e: ChangeEvent<HTMLSelectElement>,
	) => {
		setProjectTaskLoading((prevLoadings) => {
			return {
				...prevLoadings,
				billingStatusLoading: true,
			};
		});
		if (!projectTaskLoading?.billingStatusLoading && hasIdAndIssueId) {
			saveProjectTaskBillingStatus({
				variables: {
					projectTaskInput: {
						...commonVariables,
						billingStatus: +e.target.value,
					},
				},
			})
				.then(() => {
					toastNotify([
						{
							messageType: "success",
							message: "Billing status changed successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				})
				.finally(() => {
					setProjectTaskLoading((prevLoadings) => {
						return {
							...prevLoadings,
							billingStatusLoading: false,
						};
					});
				});
		}
	};

	return (
		<div className="grid gap-5 h-min">
			{canChangeColumnPosition && (
				<Select
					control={control}
					errors={errors}
					label="Column"
					name="column"
					options={columnList}
					className="max-w-[250px]"
					defaultValue={projectTask?.projectColumn?.id}
					setValue={setValue}
					onChange={projectColumnChangeHandler}
					loading={projectTaskLoading?.columnLoading}
					hideError
				/>
			)}
			<div className="border w-full">
				<div className="border-b flex justify-between p-3 items-center">
					<span className="text-[#612224] font-medium">Details</span>
				</div>
				<div className="grid p-5 gap-6">
					{canReadColumn &&
						!canChangeColumnPosition &&
						projectTask?.projectColumn?.name && (
							<div className="grid gap-2 lg:grid-cols-[150px_auto]">
								<span className="text-[#612224] font-medium">Column</span>
								<span>{projectTask?.projectColumn?.name}</span>
							</div>
						)}

					<div className="grid gap-2 lg:grid-cols-[150px_auto] items-center">
						<span className="text-[#612224] font-medium">Assignees</span>
						{assigneeUserIdsWithProfile?.length > 0 ? (
							<div className="flex">
								{assigneeUserIdsWithProfile?.map(
									(
										projectMember: {
											user: {
												personalInformation: IIdAndName;
												userProfileImages: IUserProfileImage[];
											};
										},
										index: number,
									) => {
										const profileImage =
											(projectMember?.user?.userProfileImages?.length > 0 &&
												projectMember?.user?.userProfileImages[0]?.documents
													?.length > 0 &&
												projectMember?.user?.userProfileImages[0]?.documents[0]
													?.file) ||
											"";
										return (
											<div
												key={index}
												className="hover:-translate-y-2 transition-all duration-200 mr-[-16px] h-min max-w-[200px]"
											>
												<Tooltip
													render={
														projectMember?.user?.personalInformation?.name || ""
													}
													arrow
													placement={"bottom"}
													trigger={["hover"]}
												>
													{profileImage?.length > 100 ? (
														<>
															<img
																className="min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px] rounded-[50%] object-fit"
																src={`data:image/${
																	getFileExtension(profileImage) === "svg"
																		? "svg+xml"
																		: getFileExtension(profileImage)
																};base64,${profileImage}`}
																alt="No File"
															/>
														</>
													) : (
														<RxAvatar className="w-[36px] h-[36px] bg-gray-400 text-white rounded-[50%] object-fit" />
													)}
												</Tooltip>
											</div>
										);
									},
								)}
							</div>
						) : (
							<span>None</span>
						)}
					</div>

					{canReadIncharge && (
						<div className="grid gap-2 lg:grid-cols-[150px_auto] items-center">
							<span className="text-[#612224] font-medium">Incharge</span>
							{canSaveIncharge ? (
								<AutoComplete
									control={control}
									label="Incharge "
									name={"incharge"}
									options={
										projectManager
											? [projectManager, ...inchargeList]
											: inchargeList
									}
									errors={errors}
									setValue={setValue}
									hideError
									defaultValue={
										projectTask?.incharge && {
											id: projectTask?.incharge?.personalInformation?.id,
											name: projectTask?.incharge?.personalInformation?.name,
										}
									}
									className="max-w-full"
									renderedOption={(option, comboBoxOption) => {
										return (
											<CustomRenderUserImage
												comboBoxOption={comboBoxOption}
												option={option}
											/>
										);
									}}
									onChange={projectInchargeChangeHandler}
								/>
							) : (
								<span className={`${hasAnySaveAccess ? "ml-0" : ""}`}>
									{projectTask?.incharge?.personalInformation?.name}
								</span>
							)}
						</div>
					)}

					{canReadProjectPriority && projectTask?.priority && (
						<div className="grid gap-2 lg:grid-cols-[150px_auto] items-center">
							<span className="text-[#612224] font-medium">Priority</span>
							{canSaveProjectTaskPriority ? (
								<Select
									control={control}
									errors={errors}
									label="Priority"
									name="priority"
									hideError
									options={["High", "Medium", "Low"]}
									defaultValue={projectTask?.priority}
									setValue={setValue}
									onChange={projecPriorityChangeHandler}
									className="max-w-full"
								/>
							) : (
								<span>
									{projectTask?.priority === 1
										? "High"
										: projectTask?.priority === 2
										  ? "Medium"
										  : "Low"}
								</span>
							)}
						</div>
					)}
					{canReadSprint &&
						projectTask?.sprint?.id &&
						projectTask?.sprint?.name && (
							<div
								className={"grid gap-2 lg:grid-cols-[150px_auto] items-center"}
							>
								<span className="text-[#612224] font-medium">Sprint</span>
								{canSaveSprint ? (
									<AutoComplete
										control={control}
										label="Sprints"
										name={"projectSprint"}
										options={sprintsList}
										errors={errors}
										setValue={setValue}
										hideError
										onChange={projectSprintChangeHandler}
										defaultValue={
											projectTask?.sprint && {
												id: projectTask?.sprint?.id,
												name: projectTask?.sprint?.name,
											}
										}
										className="max-w-full"
									/>
								) : (
									<span>{projectTask?.sprint?.name}</span>
								)}
							</div>
						)}
					{canReadTaskLabel && projectTask?.projectTaskLabel?.name && (
						<div
							className={"grid gap-2 lg:grid-cols-[150px_auto] items-center"}
						>
							<span className="text-[#612224] font-medium">Label</span>
							{canSaveTaskLabel ? (
								<Select
									control={control}
									errors={errors}
									label="Task label"
									name="projectTaskLabelId"
									hideError
									options={taskStatusList}
									defaultValue={projectTask?.projectTaskLabel?.id}
									setValue={setValue}
									className="max-w-full truncate pt-1"
									onChange={projectLabelChangeHandler}
								/>
							) : (
								<span>{projectTask?.projectTaskLabel?.name}</span>
							)}
						</div>
					)}
					{canReadStartDate && startDate && (
						<div
							className={"grid gap-2 lg:grid-cols-[150px_auto] items-center"}
						>
							<span className="text-[#612224] font-medium">Start Date</span>
							{canSaveStartDate ? (
								<div className="">
									<CalendarField
										register={register}
										errors={errors}
										name="startDate"
										date={startDate}
										setDate={setStartDate}
										label="Start Date"
										maxDate={
											dueDate || new Date(new Date()?.getFullYear() + 1, 11, 31)
										}
										minDate={new Date(new Date()?.getFullYear(), 0, 1)}
										setIsDateChanged={setIsStartDateChanged}
										classForCalendar="top-[-100px] left-0 sm:left-[10px]"
										hideError
									/>
								</div>
							) : (
								<span>{startDate && ViewDateFormat(startDate)}</span>
							)}
						</div>
					)}
					{canReadDueDate && dueDate && (
						<div
							className={"grid gap-2 lg:grid-cols-[150px_auto] items-center"}
						>
							<span className="text-[#612224] font-medium">Due Date</span>
							{canSaveDueDate ? (
								<div className="">
									<CalendarField
										register={register}
										errors={errors}
										name="dueDate"
										date={dueDate}
										setDate={setDueDate}
										className="h-[50px]"
										label="Due Date"
										maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
										minDate={
											startDate || new Date(new Date()?.getFullYear(), 0, 1)
										}
										setIsDateChanged={setIsDueDateChanged}
										classForCalendar="top-[-150px] left-0 sm:left-[10px]"
										hideError
									/>
								</div>
							) : (
								<span>{dueDate && ViewDateFormat(dueDate)}</span>
							)}
						</div>
					)}
					{canReadBillingStatus && projectTask?.billingStatus && (
						<div
							className={"grid gap-2 lg:grid-cols-[150px_auto] items-center"}
						>
							<span className="text-[#612224] font-medium">Billing Status</span>
							{canSaveBillingStatus ? (
								<Select
									hideError
									control={control}
									errors={errors}
									label="Billing Status"
									name="billingStatus"
									options={[
										"Pending",
										"In Upwork Escrow",
										"Logged in Upwork",
										"Invoiced",
										"Paid",
										"Adjusted",
										"Refunded",
									]}
									defaultValue={projectTask?.billingStatus}
									setValue={setValue}
									className="max-w-full"
									onChange={projectBillingStatusChangeHandler}
									loading={projectTaskLoading?.billingStatusLoading}
								/>
							) : (
								<span>{projectTask?.billingStatus}</span>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TaskDetails;
