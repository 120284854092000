import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  toastNotify,
  setBackNavigationURL,
  userContextData,
  setTitle,
} from "global/helpers/Cache";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import { SPRINTS } from "modules/Project/services/queries";
import { DELETE_PROJECT_SPRINT } from "modules/Project/services/mutations";
import { IProjectSprint } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import EditSprint from "modules/Project/Pages/Sprints/EditSprint";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
  allowedResourcesForProject: { [key: string]: boolean };
  editProject: { id: number; name: string };
  projectGroup?: ProjectGroup | null;
}

const tableCommonHeadings = [
  {
    name: "Name",
    APIName: "name",
  },
  {
    name: "Description",
    APIName: "description",
  },
  {
    name: "Start Date",
    APIName: "startDate",
  },
  {
    name: "End Date",
    APIName: "endDate",
  },
  {
    name: "Status",
    APIName: "status",
  },
];

const Sprints: FC<Props> = ({
  allowedResourcesForProject = {},
  editProject,
  projectGroup,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;

  const canCreateOrUpdate =
    allowedResourcesForProject["SaveProjectSprint"] || isSuperAdmin;

  const canDelete =
    allowedResourcesForProject["DeleteProjectSprint"] || isSuperAdmin;

  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const { id, projectType } = useParams();

  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [deleteProjectSprint, { loading: deleteProjectSprintLoading }] =
    useMutation(DELETE_PROJECT_SPRINT);

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    if (editProject?.name) {
      setTitle(
        projectGroup?.id
          ? [
              {
                name: "Project",
                link: "/projects/groups",
              },
              { name: "Groups", link: "/projects/groups" },
              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/${projectType}`,
              },
              {
                name: `${editProject?.name}`,
                link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
              },
              {
                name: "Sprint",
                link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/sprints`,
              },
            ]
          : [
              { name: "Project", link: "/projects/projects/my-projects" },
              {
                name: `${editProject?.name}`,
                link: `/projects/projects/${projectType}/edit/${id}/general`,
              },
              {
                name: "Sprint",
                link: `projects/projects/${projectType}/edit/${id}/sprints`,
              },
            ]
      );
    }
  }, [
    editProject?.name,
    projectType,
    projectGroup?.id,
    projectGroup?.name,
    id,
  ]);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);
  const isMyProjectOrAllProjectPathName = pathname?.split("/")[3];

  const [
    fetchAllProjectSprints,
    { data: getAllProjectSprints, loading, refetch: refetchProjectSprints },
  ] = useLazyQuery(SPRINTS, {
    fetchPolicy: "cache-and-network",
  });

  const hasSprints = getAllProjectSprints?.projects?.dataCollection?.length > 0;
  const sprints =
    hasSprints && getAllProjectSprints?.projects?.dataCollection[0]?.sprints;

  const projectSprintsList = (hasSprints && sprints?.dataCollection) || [];

  const totalCount = (hasSprints && sprints?.totalNumberOfItems) || 0;

  const noOfItems = getAllProjectSprints && projectSprintsList?.length;

  useEffect(() => {
    fetchAllProjectSprints({
      variables: {
        filters: {
          id: editProject?.id,
          isMyProject: projectType === "my-projects" ? true : undefined,
        },
        orderBy: sortOrder
          ? {
              field: sortOrder?.field,
              orderType: sortOrder?.orderType,
            }
          : undefined,
        limit: pageSize,
        page: currentPage,
        search: search || undefined,
      },
    });
  }, [
    currentPage,
    fetchAllProjectSprints,
    pageSize,
    search,
    id,
    isMyProjectOrAllProjectPathName,
    editProject?.id,
    projectType,
    sortOrder,
  ]);

  const [editProjectSprint, setEditProjectSprint] =
    useState<IProjectSprint | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectSprint?.id) {
      deleteProjectSprint({
        variables: {
          id: +editProjectSprint?.id,
        },
      })
        .then(() => {
          const projectSprintName = editProjectSprint?.name;
          setDeleteVerification(false);
          refetchProjectSprints();
          toastNotify([
            {
              messageType: "success",
              message: `${
                projectSprintName ? `Sprint ${projectSprintName}` : "Sprint"
              } has been removed from ${editProject?.name} successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (projectSprint: IProjectSprint) => {
    if (canCreateOrUpdate) {
      setEditProjectSprint(projectSprint);
      setShowModal(true);
    }
  };

  const onDelete = (projectSprint: IProjectSprint) => {
    setEditProjectSprint(projectSprint);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectSprint(null);
  };

  const getDataModal = (projectSprint: IProjectSprint): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: projectSprint?.name,
        },
      ],
    },
    {
      label: "Description",
      value: [
        {
          content: projectSprint?.description,
        },
      ],
    },
    {
      label: "Start Date",
      value: [
        {
          content: projectSprint?.startDate,
        },
      ],
    },
    {
      label: "End Date",
      value: [
        {
          content: projectSprint?.endDate,
        },
      ],
    },
    {
      label: "Status",
      value: [
        {
          content: projectSprint?.status,
        },
      ],
    },
  ];
  const getTableContent = (projectSprint: IProjectSprint): ITableContent[] => [
    {
      value: projectSprint?.name,
    },
    {
      value: projectSprint?.description,
    },
    {
      value: projectSprint?.startDate,
    },
    {
      value: projectSprint?.endDate,
    },
    {
      value: projectSprint?.status,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(projectSprint);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[69vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />

        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreateOrUpdate && !loading && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectSprintsList?.map((projectSprint: IProjectSprint) => {
                    return (
                      <DataCard
                        key={projectSprint?.id}
                        dataModal={getDataModal(projectSprint)}
                        classForULlist={`${
                          canCreateOrUpdate
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                        cardClick={() => {
                          canCreateOrUpdate && onEdit(projectSprint);
                        }}
                        classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                        otherOption={
                          (canDelete && (
                            <div className="flex justify-center">
                              <ToolTip
                                render={() => "Delete"}
                                arrow
                                className={
                                  "text-center bg-[#616161] text-white"
                                }
                              >
                                <BiTrash
                                  className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete(projectSprint);
                                  }}
                                />
                              </ToolTip>
                            </div>
                          )) ||
                          undefined
                        }
                      />
                    );
                  })}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  isSortOrderNeeded
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                >
                  {projectSprintsList?.map((projectSprint: IProjectSprint) => {
                    return (
                      <TableContent
                        key={projectSprint?.id}
                        tableRef={tableRef}
                        classForTableRow={`${
                          canCreateOrUpdate
                            ? "cursor-pointer hover:bg-white-smoke"
                            : "cursor-default hover:bg-white-smoke"
                        }`}
                        tableRowClick={() => {
                          canCreateOrUpdate && onEdit(projectSprint);
                        }}
                        tableContent={getTableContent(projectSprint)}
                      />
                    );
                  })}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditSprint
          refetchProjectSprints={refetchProjectSprints}
          editProjectSprint={editProjectSprint}
          setShowModal={setShowModal}
          loading={loading}
          editProject={editProject}
          allowedResourcesForProject={allowedResourcesForProject}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Sprint"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectSprintLoading}
        />
      )}
    </div>
  );
};

export default Sprints;
