import { FC, ReactNode } from "react";

interface IProps {
  className?: string;
  children?: ReactNode;
}

const Card: FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={`w-full h-full shadow-[0px_0px_45px_#C4C4C429] rounded-md p-5 ${
        className ? className : "min-h-[50px]"
      } bg-white`}
    >
      {children}
    </div>
  );
};

export default Card;
