const dateFunction = (input: string) => {
  const dd = input?.slice(8, 10);
  const mmm = input?.slice(4, 7);
  const yyyy = input?.slice(11, 15);
  return { mmm, dd, yyyy };
};

// *** mmm dd yyyy ***
export const DateMonthYearFormatter = (str: string) => {
  const output = dateFunction(str?.toString());
  return str ? `${output?.dd} ${output?.mmm} ${output?.yyyy}` : "";
};

export const DateMonthFormatter = (str: string) => {
  const output = dateFunction(str?.toString());
  return str ? `${output?.dd} ${output?.mmm}` : "";
};

//---------------------

// **** yyyy-mm-dd *** input: Thu Apr 20 2022
export const DateFormat = (str: any) => {
  const strArray = str && str?.split(" ");
  const months: any = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const formatedDate =
    strArray[3] + "-" + months[strArray[1]] + "-" + strArray[2];

  return formatedDate;
};

// **** dd-mmm-yyyy *** input: new Date()
export const ViewDateFormat = (inputValue: string | Date | null) => {
  if (inputValue) {
    const inputDateSubString = new Date(inputValue)
      ?.toString()
      ?.substring(4, 15);
    const splitedInputDate = inputDateSubString?.split(" ");
    const viewDateFormat = `${splitedInputDate[1]}-${splitedInputDate[0]}-${splitedInputDate[2]}`;

    return viewDateFormat;
  }
};

export const formatTime = (timer: number) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = Math.floor(timer / 60);
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

//Pass month string - Jan, Feb
export const getMonthNumber = (month: string) => {
  const months: { [key: string]: string } = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  return months[month];
};

export const getRailwayTimeHour = (hour: string) => {
  const hours: { [key: string]: number } = {
    "01": 13,
    "02": 14,
    "03": 15,
    "04": 16,
    "05": 17,
    "06": 18,
    "07": 19,
    "08": 20,
    "09": 21,
    "10": 22,
    "11": 23,
    "12": 12,
  };

  return hours[hour];
};

export const getTweleveHourTime = (hour: string) => {
  const hours: { [key: string]: number } = {
    "13": 1,
    "14": 2,
    "15": 3,
    "16": 4,
    "17": 5,
    "18": 6,
    "19": 7,
    "20": 8,
    "21": 9,
    "22": 10,
    "23": 11,
    "0": 12,
  };

  return hours[hour];
};

export const secondsToHoursConverterWithSeconds = (seconds: number) => {
  if (seconds) {
    seconds = Number(seconds);
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    const hDisplay = h > 0 ? (h >= 10 ? h : `0${h}`) : "00";
    const mDisplay = m > 0 ? (m >= 10 ? m : `0${m}`) : "00";
    const sDisplay = s > 0 ? (s >= 10 ? s : `0${s}`) : "00";

    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  } else {
    return `00:00:00`;
  }
};
