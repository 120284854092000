import { FC, Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { HiCog } from "react-icons/hi";

import EditForm from "components/EditForm/EditForm";
import Select from "components/forms/UpdatedForm/Select";
import { DOTS, usePagination } from "components/App/Pagination/usePagination";

import { ReactComponent as SingleArrowRight } from "global/assets/images/single-arrow-right.svg";
import { ReactComponent as SingleArrowLeft } from "global/assets/images/single-arrow-left.svg";

interface IProps {
  totalCount: number;
  currentPage: number;
  setPageSize: Function;
  siblingCount: number;
  pageSize: number;
  onPageChange: (pageNumber: number) => void;
  noOfItem: number;
  onRowPerPageChange?: (rowPerPage: number) => void;
}

const Pagination: FC<IProps> = ({
  totalCount,
  currentPage,
  setPageSize,
  siblingCount,
  pageSize,
  onPageChange,
  noOfItem,
  onRowPerPageChange,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<{ rowPerPage: string }>({
    shouldUnregister: true,
    defaultValues: {
      rowPerPage: `${pageSize}`,
    },
  });

  useEffect(() => {
    setValue("rowPerPage", `${pageSize}`);
  }, [pageSize, setValue]);

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  let lastPage = paginationRange[paginationRange?.length - 1];

  let pageStartRange = pageSize * (currentPage - 1) + 1;

  let pageEndRange =
    currentPage === 1
      ? noOfItem || pageSize * currentPage
      : currentPage === lastPage
      ? totalCount
      : pageSize * currentPage;

  const onNext = () => {
    if (currentPage === lastPage) {
      return;
    } else {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
    }
  };

  const [showRowPerPageModal, setShowRowPerPageModal] = useState(false);

  const openModalHandler = () => {
    setShowRowPerPageModal(!showRowPerPageModal);
  };

  const modalCloseHandler = () => {
    setShowRowPerPageModal(false);
  };
  const paginationSubmitHandler: SubmitHandler<{ rowPerPage: string }> = ({
    rowPerPage,
  }) => {
    setPageSize(+rowPerPage);
    onPageChange(1);
    setShowRowPerPageModal(false);
    onRowPerPageChange && onRowPerPageChange(+rowPerPage);
  };

  return (
    <Fragment>
      <div className="bg-white p-[25px_0px_25px_0px] w-full grid lg:grid-cols-2 gap-2 md:gap-0 items-center rounded-b-md px-2">
        <div className="flex gap-2">
          <div className="w-full">
            <HiCog
              className="text-ironside-gray/70 cursor-pointer w-5 h-5  sm:w-6 sm:h-6"
              onClick={openModalHandler}
            />
          </div>
          <p className="text-xs sm:text-sm text-ironside-gray/60 text-center whitespace-nowrap">
            {pageStartRange} - {pageEndRange} of {totalCount}
          </p>
        </div>
        <div className="flex justify-center lg:justify-end">
          <ul className="flex gap-1 sm:gap-2">
            <li
              onClick={onPrevious}
              className={`pagination-item ${
                currentPage === 1 ? "disable" : "group"
              }`}
            >
              <SingleArrowLeft className={"arrow"} />
            </li>
            {paginationRange.map((pageNumber: any, index: number) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={index} className="pagination-item dots">
                    &#8230;
                  </li>
                );
              }
              return (
                <li
                  onClick={() => {
                    onPageChange(pageNumber);
                  }}
                  key={index}
                  className={`pagination-item ${
                    currentPage === pageNumber ? "selected" : ""
                  }`}
                >
                  <span className="m-2">{pageNumber}</span>
                </li>
              );
            })}
            <li
              onClick={onNext}
              className={`pagination-item ${
                currentPage === lastPage ? "disable" : "group"
              }`}
            >
              <SingleArrowRight className={"arrow"} />
            </li>
          </ul>
        </div>
      </div>
      {showRowPerPageModal && (
        <EditForm
          handleSubmit={handleSubmit}
          onClose={modalCloseHandler}
          onSubmit={paginationSubmitHandler}
          title={"Pagination"}
          children={
            <Select
              name="rowPerPage"
              control={control}
              options={["5", "10", "15", "25", "30"]}
              required
              label="Rows per page"
              setValue={setValue}
              errors={errors}
            />
          }
          filledBtnName={"Apply"}
          outlineBtnName={"Cancel"}
        />
      )}
    </Fragment>
  );
};

export default Pagination;
