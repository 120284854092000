import { Fragment, useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { HiMail } from "react-icons/hi";

import { UnderReviewBtn } from "components/forms";
import Loading from "components/Loader/Loading";

import erpsystem from "global/assets/images/erp-system.png";
import avatar from "global/assets/images/dummy-avatar.svg";
import { ReactComponent as PhoneIcon } from "global/assets/images/phone-icon.svg";
import { ReactComponent as CameraIcon } from "global/assets/images/camera-icon.svg";
import { allowedAccessVar, userQueryProps } from "global/helpers/Cache";

import { GET_PROFILE_HEADER_DETAILS } from "modules/Employee/services/queries";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import ProfileImage from "modules/Employee/Pages/Profile/ProfileHeader/ProfileImage";
import CoverImage from "modules/Employee/Pages/Profile/ProfileHeader/CoverImage";
import {
  IProfileOrCoverImage,
  IUserDetails,
} from "modules/Employee/types/ProfileHeader";

const ProfileHeader = () => {
  const queryProps = useReactiveVar(userQueryProps);
  const {
    loading,
    data: profileHeaderDetails,
    refetch: refetchProfileHeaderDetails,
  } = useQuery(GET_PROFILE_HEADER_DETAILS, {
    ...queryProps,
  });

  const employeeProfileDetails = profileHeaderDetails?.users?.dataCollection[0];
  const refetchEmployeeProfileDetails = refetchProfileHeaderDetails;
  const [showProfileUnderReview, setShowProfileUnderReview] =
    useState<boolean>(false);
  const [showCoverUnderReview, setShowCoverUnderReview] =
    useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [editCoverImage, setEditCoverImage] = useState<boolean>(false);
  const [userImage, setUserImage] = useState<string>();
  const [coverImage, setCoverImage] = useState<string>(erpsystem);
  const [userDetails, setuserDetails] = useState<IUserDetails>({
    image: "",
    coverImage: "",
    designation: "",
    location: "",
    profileStrength: 0,
  });
  const [changedProfileInformation, setChangeProfileInformation] = useState<{
    id: number | null;
    fileType: string;
  } | null>(null);

  const [changedCoverInformation, setChangeCoverInformation] = useState<{
    id: number | null;
    fileType: string;
  } | null>(null);

  const showModal = () => {
    setEditCoverImage(false);
    setEditProfile(false);
  };

  const setProfilePicture = (profilePicture: string) => {
    setUserImage(profilePicture);
  };

  const setCoverPicture = (coverPicture: string) => {
    setCoverImage(coverPicture);
  };

  const employeeProfileImage =
    employeeProfileDetails?.userProfileImages?.filter(
      (profileImage: IProfileOrCoverImage) => profileImage?.type === "Profile"
    )[0];

  const employeeCoverImage = employeeProfileDetails?.userProfileImages?.filter(
    (profileImage: IProfileOrCoverImage) => profileImage?.type === "Cover"
  )[0];

  useEffect(() => {
    setuserDetails({
      image:
        employeeProfileImage?.documents &&
        employeeProfileImage?.documents[0]?.file?.length > 100
          ? `data:image/jpeg;base64,${employeeProfileImage?.documents[0]?.file}`
          : avatar,
      coverImage:
        employeeCoverImage?.documents &&
        employeeCoverImage?.documents[0]?.file?.length > 100
          ? `data:image/jpeg;base64,${employeeCoverImage?.documents[0]?.file}`
          : null,
      designation: employeeProfileDetails?.employeeDetail?.employeeDesignation
        ? employeeProfileDetails?.employeeDetail?.employeeDesignation
        : "",
      location: employeeProfileDetails?.employeeDetail?.workLocation
        ? employeeProfileDetails?.employeeDetail?.workLocation
        : "",
      profileStrength: Math.floor(
        +employeeProfileDetails?.progressBar
          ? +employeeProfileDetails?.progressBar
          : 0
      ),
    });
  }, [
    userImage,
    coverImage,
    employeeProfileImage,
    employeeCoverImage,
    employeeProfileDetails?.progressBar,
    employeeProfileDetails?.employeeDetail?.employeeDesignation,
    employeeProfileDetails?.employeeDetail?.workLocation,
  ]);

  const progressnumber = userDetails?.profileStrength;
  const progress = 215 - 215 * (progressnumber / 100);

  useEffect(() => {
    if (employeeProfileImage?.moderation?.filePath) {
      setChangeProfileInformation({
        id: employeeProfileImage?.moderation?.filePath,
        fileType: "image",
      });
    }
    if (employeeCoverImage?.moderation?.filePath) {
      setChangeCoverInformation({
        id: employeeCoverImage?.moderation?.filePath,
        fileType: "image",
      });
    }
  }, [
    employeeCoverImage?.moderation?.filePath,
    employeeProfileImage?.moderation?.filePath,
  ]);

  const underReviewModerationProps = {
    refetchQuery: refetchProfileHeaderDetails,
  };

  const allowedResources: any = useReactiveVar(allowedAccessVar);
  const canAddProfileImage = allowedResources?.includes("CreateUserProfileImage")

  return (
    <div className="w-full md:h-[400px] md:shadow-[0px_0px_45px_#C4C4C429] relative">
      {loading ? (
        <Loading className="min-h-[250px]" />
      ) : (
        <Fragment>
          {!employeeCoverImage?.moderation?.filePath && canAddProfileImage && (
            <div
              onClick={() => setEditCoverImage(!editCoverImage)}
              className="cursor-pointer absolute w-[30px] h-[30px] md:w-[42px] md:h-[42px] rounded-full top-5 right-5 bg-white flex justify-center items-center"
            >
              <CameraIcon className="md:w-[40px] md:h-[40px] text-ironside-gray/70" />
            </div>
          )}

          {employeeCoverImage?.moderation?.filePath && (
            <div className="absolute left-[3%] top-[5%] sm:left-[83%] sm:top-[35%] md:left-[84%] md:top-[38%] lg:left-[88%] lg:top-[44%] xl:left-[89%]">
              <UnderReviewBtn
                className="bg-white rounded-[11px] w-[90px] sm:w-[100px] md:w-[110px] md:h-[28px]"
                buttonName={"Under Review"}
                onClick={() => {
                  setShowCoverUnderReview(!showCoverUnderReview);
                  setChangeCoverInformation({
                    id: employeeCoverImage?.moderation?.filePath,
                    fileType: "image",
                  });
                }}
              />
            </div>
          )}

          {editCoverImage && (
            <CoverImage
              employeeCoverImage={employeeCoverImage}
              refetchEmployeeCoverImage={refetchEmployeeProfileDetails}
              setCoverPicture={setCoverPicture}
              showModal={showModal}
              coverImg={userDetails?.coverImage}
            />
          )}

          {showCoverUnderReview && (
            <UnderReviewModal
              {...underReviewModerationProps}
              setShowUnderReview={setShowCoverUnderReview}
              changedInformationWithType={changedCoverInformation}
            />
          )}

          {userDetails?.coverImage ? (
            <img
              src={userDetails?.coverImage}
              alt="coverImage"
              className="w-full h-[193px] lg:h-[215px] object-center"
            />
          ) :  (
            <div className="w-full h-[193px] lg:h-[215px] flex justify-center items-center bg-[#E5E5E5]">
              {canAddProfileImage && <p>Add Cover Photo</p>}
            </div>
          )}

          {editProfile && (
            <ProfileImage
              employeeProfileImage={employeeProfileImage}
              refetchEmployeeProfileImage={refetchEmployeeProfileDetails}
              showModal={showModal}
              setProfilePicture={setProfilePicture}
              userImg={userDetails?.image}
            />
          )}

          <div className="w-full md:w-auto h-[138px] rounded-full md:h-[188px] absolute top-[123px] md:top-[82px] flex justify-center md:block md:left-[48px]">
            <div className="relative">
              <img
                src={userDetails?.image}
                alt="userImage"
                className="w-[138px] h-[138px] md:w-[188px] md:h-[185px] rounded-full object-fill bg-white p-2"
              />

              {employeeProfileImage?.moderation?.filePath && (
                <div className="absolute bottom-[9%] md:bottom-[19%] left-[15%] md:left-[23%] mb-[3rem]">
                  <UnderReviewBtn
                    className="bg-white rounded-[11px] md:h-[25px]"
                    buttonName={"Under Review"}
                    onClick={() => {
                      setShowProfileUnderReview(!showProfileUnderReview);
                      setChangeProfileInformation({
                        id: employeeProfileImage?.moderation?.filePath,
                        fileType: "image",
                      });
                    }}
                  />
                </div>
              )}

              {!employeeProfileImage?.moderation?.filePath && canAddProfileImage && (
                <CameraIcon
                  onClick={() => setEditProfile(!editProfile)}
                  className="cursor-pointer absolute text-[#8E8E8F] bottom-0 right-3 md:right-7 bg-white rounded-full"
                />
              )}

              {showProfileUnderReview && (
                <UnderReviewModal
                  {...underReviewModerationProps}
                  setShowUnderReview={setShowProfileUnderReview}
                  changedInformationWithType={changedProfileInformation}
                />
              )}
            </div>
          </div>
          <div className="w-full md:pl-[48px]  mt-[74px] md:pr-[53px] flex flex-col items-center md:flex-row md:justify-between md:items-end gap-2">
            <div className="text-center md:text-left text-ironside-gray">
              <p className="text-base truncate max-w-[350px]">
                {employeeProfileDetails?.personalInformation?.name
                  ? employeeProfileDetails?.personalInformation?.name.trim()
                  : "Eg.John Peter"}
              </p>
              {userDetails?.designation && (
                <p className="text-sm truncate max-w-[350px]">
                  {userDetails?.designation}
                </p>
              )}
              {userDetails?.location && (
                <p className="text-xs truncate max-w-[350px]">
                  {userDetails?.location}
                </p>
              )}
            </div>
            <div className="space-y-[10px]">
              <p className="flex space-x-[10px]">
                <HiMail className="w-[20px] h-[20px] text-warm-gray" />
                <span className="text-[14px] leading-[17px] text-ironside-gray max-w-[200px] truncate">
                  {employeeProfileDetails?.employeeDetail?.officialEmail
                    ? employeeProfileDetails?.employeeDetail?.officialEmail
                    : employeeProfileDetails?.personalInformation?.personalEmail
                    ? employeeProfileDetails?.personalInformation?.personalEmail
                    : "mailto:eg.example@example.com"}
                </span>
              </p>
              <p className="flex space-x-2">
                <PhoneIcon className="text-ironside-gray" />
                <span className="text-[14px] leading-[17px] text-ironside-gray">
                  {employeeProfileDetails?.personalInformation?.mobile
                    ? `${employeeProfileDetails?.personalInformation?.isdCode} - ${employeeProfileDetails?.personalInformation?.mobile}`
                    : "Eg.+91 - 98765 43210"}
                </span>
              </p>
            </div>
            <div>
              <p className="text-[14px] leading-[20px] text-ironside-gray hidden lg:block">
                Profile completeness
              </p>
              <div className="hidden lg:flex items-center">
                <div className="relative w-[339px] h-[10px] bg-[#999999] rounded-full">
                  <div
                    style={{ width: userDetails?.profileStrength + "%" }}
                    className={`absolute bg-[#1FC7A2] top-0 left-0 bottom-0 rounded-full transition-all ease-linear duration-1000`}
                  />
                </div>
                <p className="ml-[13px] text-[14px] leading-[21px] text-ironside-gray">
                  {userDetails?.profileStrength}%
                </p>
              </div>

              <div className="skill lg:hidden">
                <div className="outer">
                  <div className="inner flex items-center justify-center flex-col">
                    <p className="text-[11px] leading-[14px] text-ironside-gray">
                      {userDetails?.profileStrength}%
                    </p>
                    <p className="text-[7px] leading-[9px] text-ironside-gray">
                      Profile
                    </p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  width="80px"
                  height="80px"
                  className="circularbar-svg"
                >
                  <defs>
                    <linearGradient id="gradient-color">
                      <stop offset="100%" stopColor="#4182F4" />
                    </linearGradient>
                  </defs>
                  <circle
                    cx="39"
                    cy="39"
                    r="35"
                    strokeLinecap="round"
                    style={{ strokeDashoffset: `${progress}` }}
                    className={
                      "[fill:none] [stroke:url(#gradient-color)] [stroke-width:7.5px] [stroke-dasharray:215] animate-[anim_2s_linear_reverse]"
                    }
                  />
                </svg>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ProfileHeader;
