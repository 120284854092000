import { gql } from "@apollo/client";

export const MY_TEAM_INFO = gql`
  query myTeamInfoDetails($isMyProfile: Boolean) {
    users(isMyProfile: $isMyProfile) {
      dataCollection {
        team {
          name
          code
          teamLeadUser {
            id
            personalInformation {
              name
            }
            employeeDetail {
              officialEmail
              employeeDesignation
            }
            userProfileImages {
              documents {
                file
              }
              type
            }
          }
          members {
            id
            personalInformation {
              name
              personalEmail
            }
            employeeDetail {
              officialEmail
              employeeDesignation
            }
          }
        }
      }
    }
  }
`;

export const MY_TEAMS = gql`
  query myTeams($isMyProfile: Boolean, $limit: Int, $page: Int) {
    users(isMyProfile: $isMyProfile) {
      dataCollection {
        myTeams(limit: $limit, page: $page) {
          dataCollection {
            id
            name
            code
            teamLeadUser {
              id
            }
            members {
              id
              personalInformation {
                name
                personalEmail
              }
              employeeDetail {
                officialEmail
                employeeDesignation
              }
            }
          }
          totalNumberOfItems
        }
      }
    }
  }
`;

export const ALL_TEAMS = gql`
  query employeeTeams($id: Int, $name: String, $limit: Int, $page: Int) {
    employeeTeams(id: $id, name: $name, limit: $limit, page: $page) {
      dataCollection {
        id
        name
        code
        teamLeadUser {
          id
          personalInformation {
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeDesignation
          }
        }
        members {
          id
          personalInformation {
            name
            personalEmail
          }
          employeeDetail {
            officialEmail
            employeeDesignation
          }
          userProfileImages {
            documents {
              file
            }
            type
          }
        }
      }
      totalNumberOfItems
    }
  }
`;

export const GET_NON_TEAM_MEMBERS_EMPLOYEES_QUERY = gql`
  query getNonTeamMemberEmployees {
    getNonTeamMemberEmployees {
      id
      personalInformation {
        name
        personalEmail
      }
    }
  }
`;
