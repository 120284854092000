import { gql } from "@apollo/client";

export const UPDATE_NOTIFICATION_STATUS = gql`
  mutation updateNotificationStatus($id: [Int]!, $isRead: Boolean!) {
    updateNotificationStatus(id: $id, isRead: $isRead) {
      id
      message
      link
      isRead
      readAt
      createdAt
    }
  }
`;

export const CREATE_DEVICE_CREDENTIAL = gql`
  mutation createDeviceCredential($userId: Int!, $token: String!) {
    createDeviceCredential(userId: $userId, token: $token) {
      id
    }
  }
`;

export const REMOVE_DEVICE_CREDENTIAL = gql`
  mutation removeDeviceCredential($id: Int!) {
    removeDeviceCredential(id: $id)
  }
`;
