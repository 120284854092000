import { FC, useCallback, useRef, useState } from "react";
import Cropper from "react-cropper";
import Webcam from "react-webcam";
import { useMutation } from "@apollo/client";
import "cropperjs/dist/cropper.css";
import { HiCamera, HiTrash } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";

import { ReactComponent as PencilIcon } from "global/assets/images/pencil-dark-icon.svg";
import erpsystem from "global/assets/images/erp-system.png";
import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  CREATE_PROFILE_AND_COVER_IMAGE,
  UPDATE_PROFILE_AND_COVER_IMAGE,
  DELETE_PROFILE_AND_COVER_IMAGE,
} from "modules/Employee/services/mutations";
import { IProfileOrCoverImage } from "modules/Employee/types/ProfileHeader";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  showModal: () => void;
  setCoverPicture: Function;
  coverImg: string | null | undefined;
  employeeCoverImage: IProfileOrCoverImage;
  refetchEmployeeCoverImage: Function;
}

const CoverImage: FC<IProps> = ({
  showModal,
  setCoverPicture,
  coverImg,
  employeeCoverImage,
  refetchEmployeeCoverImage,
}) => {
  const inputRef: any = useRef();
  const cropperRef: any = useRef<HTMLImageElement>();
  const webcamRef: any = useRef(null);
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [deleteProfile, setDeleteProfile] = useState<boolean>(false);
  const [image, setImage] = useState<string | null | undefined>(null);
  const [captureImage, setCaptureImage] = useState<boolean>(false);
  const [cropData, setCropData] = useState(coverImg);
  const [cropper, setCropper] = useState<any>();
  const [inProgress, setInProgress] = useState(false);
  const isUnderModeration = useIsUnderModeration();

  const videoConstraints = {
    facingMode: "user",
  };

  const [createUserCoverImage] = useMutation(CREATE_PROFILE_AND_COVER_IMAGE);
  const [updateUserCoverImage] = useMutation(UPDATE_PROFILE_AND_COVER_IMAGE);
  const [removeUserCoverImage] = useMutation(DELETE_PROFILE_AND_COVER_IMAGE);

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef, setImage]);

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Cover image")
    : createSuccessMessageWithAwaiting("Cover image");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Cover image")
    : updateSuccessMessageWithAwaiting("Cover image");

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Cover image")
    : deleteSuccessMessageWithAwaiting("Cover image");

  const getCropData = () => {
    if (employeeCoverImage?.id && !inProgress) {
      updateUserCoverImage({
        variables: {
          id: +employeeCoverImage?.id,
          documents: [
            {
              file: cropper?.getCroppedCanvas()?.toDataURL("image/jpeg"),
            },
          ],
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
          showModal();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    } else if (typeof cropper !== "undefined" && !inProgress) {
      createUserCoverImage({
        variables: {
          userId: +sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          type: "Cover",
          documents: [
            {
              file: cropper?.getCroppedCanvas()?.toDataURL("image/jpeg"),
            },
          ],
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
          showModal();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    refetchEmployeeCoverImage();
    setCropData(cropper?.getCroppedCanvas().toDataURL("image/jpeg"));
    setCoverPicture(cropper?.getCroppedCanvas().toDataURL("image/jpeg"));
  };

  const EditProfilePicture = () => {
    setImage(cropData);
    setCropData(undefined);
  };

  const deleteCoverPicture = () => {
    if (!inProgress) {
      removeUserCoverImage({
        variables: {
          id: +employeeCoverImage?.id,
        },
      })
        .then(() => {
          setInProgress(false);
          showModal();
          refetchEmployeeCoverImage();
          setCoverPicture(erpsystem);
          toastNotify(deleteActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div className="fixed inset-0 bg-transparent z-50 flex justify-center items-center md:items-start p-2">
      <div className="fixed inset-0 bg-black/40 -z-20" onClick={showModal} />
      <div className="w-full  md:w-[500px] md:mt-[80px] lg:w-[700px]   shadow-[0px_-3px_6px_#00000029] rounded-[12px]  bg-white ">
        <div className="w-full flex justify-between items-center p-3 px-4">
          <div className="flex items-end">
            <h2 className="text-base text-ironside-gray">
              {cropData ? "Profile Photo" : image ? "Edit Photo" : "Add Photo"}
            </h2>
            {cropData ? null : (
              <span className="text-[#8E8E8F] text-[11px] leading-[17px]">
                &nbsp;&nbsp;(1200 x 220)
              </span>
            )}
          </div>
          <MdOutlineClose
            className="text-[#686868] cursor-pointer w-3 h-3"
            onClick={showModal}
          />
        </div>

        <div className="w-full ">
          <div className="flex w-full justify-center items-center bg-[#E5E5E5]">
            {cropData ? (
              <div className="my-[40px]">
                <img
                  src={cropData}
                  alt="Cover"
                  className="w-full lg:w-[650px] h-[220px] bg-white"
                />
              </div>
            ) : image ? (
              <Cropper
                style={{ height: "260", width: "100%" }}
                initialAspectRatio={5}
                aspectRatio={5}
                src={image}
                viewMode={1}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={false}
                className="w-full h-[254px] image"
                ref={cropperRef}
                dragMode={"none"}
                cropBoxResizable={false}
              />
            ) : captureImage ? (
              <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                audio={false}
                className="w-full h-[254px]"
                videoConstraints={videoConstraints}
              />
            ) : (
              <div className="w-full lg:w-[650px] h-[150px] bg-[#E5E5E5] grid place-items-center my-[40px]">
                <p className="text-sm">Add Cover Photo</p>
              </div>
            )}
          </div>
          {cropData ? (
            <div className="flex justify-between items-end px-[25px] py-[25px]">
              <div className="flex flex-row items-end gap-3">
                <div
                  onClick={EditProfilePicture}
                  className="flex justify-center items-center flex-col cursor-pointer"
                >
                  <PencilIcon className="text-ironside-gray/80 w-4 h-4" />
                  <button className="text-ironside-gray text-sm mt-[12px]">
                    Edit
                  </button>
                </div>
                <div
                  onClick={() => setCropData(undefined)}
                  className="flex justify-center items-center flex-col cursor-pointer"
                >
                  <HiCamera className="text-ironside-gray/80 w-6 h-6" />
                  <button className="text-ironside-gray text-sm mt-2">
                    Add Photo
                  </button>
                </div>
              </div>
              <div
                onClick={() => setDeleteProfile(!deleteProfile)}
                className="flex justify-center items-center flex-col cursor-pointer"
              >
                <HiTrash className="text-ironside-gray/80 w-[22px] h-[22px]" />
                <button className="text-ironside-gray text-sm mt-2">
                  Delete
                </button>
              </div>
              {deleteProfile && (
                <div className="fixed inset-0 flex justify-center items-center md:items-start p-2 shadow-[0px_-3px_6px_#00000029] ">
                  <div className="relative z-50 bg-white h-min rounded-[12px] w-full md:w-[450px] lg:w-[560px] md:mt-[150px]">
                    <div className="w-full flex justify-between items-center  px-5 py-3 border-b border-hit-gray">
                      <h2 className="text-base text-ironside-gray">
                        Delete Profile Photo
                      </h2>
                      <MdOutlineClose
                        className="text-[#686868] cursor-pointer w-3 h-3"
                        onClick={() => setDeleteProfile(false)}
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-sm my-[23px] ml-[30px] w-[250px] lg:w-[300px]">
                        Are You sure? Having a profile picture helps others
                        recognize you
                      </p>
                    </div>

                    <div className="flex justify-center lg:justify-end space-x-[25px] px-[30px] py-[25px] border-t border-hit-gray">
                      <button
                        onClick={() => setDeleteProfile(false)}
                        className="px-[24px] py-[3px] md:py-[6px] text-sm border-2 border-cornflower-blue rounded-full text-cornflower-blue"
                      >
                        Cancel
                      </button>

                      <button
                        onClick={deleteCoverPicture}
                        className="px-[24px] py-[3px] md:py-[6px] text-sm rounded-full bg-cornflower-blue text-white"
                      >
                        {inProgress ? (
                          <div className="w-[45px] ml-[28px] sm:ml-0">
                            <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                          </div>
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  </div>
                  <div
                    className="fixed inset-0 bg-black/40 z-40"
                    onClick={() => setDeleteProfile(false)}
                  />
                </div>
              )}
            </div>
          ) : image ? (
            <div>
              <div className="flex border-b-2 border-hit-gray w-full pt-[25px]">
                <div
                  onClick={() => cropperRef.current.cropper.rotate(90)}
                  className="flex flex-col justify-center items-center group cursor-pointer translate-y-[2px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 23.476"
                    className="fill-ironside-gray group-hover:fill-cornflower-blue"
                  >
                    <path
                      id="Path_3241"
                      data-name="Path 3241"
                      d="M110.584,21.479l-3.647-.818h0a1.174,1.174,0,0,0-1.44.974l-.517,3.486h0a1.174,1.174,0,0,0,.99,1.334,1.232,1.232,0,0,0,.172,0,1.174,1.174,0,0,0,1.174-1l.117-.783a9.384,9.384,0,1,1-8.2-4.82,1.174,1.174,0,1,0,0-2.348,11.737,11.737,0,1,0,10.326,6.155l.5.114a1.176,1.176,0,0,0,.52-2.293Z"
                      transform="translate(-87.499 -17.504)"
                    />
                  </svg>
                  <button className="mt-[7px] border-b-2 px-[24px] pb-[12px] lg:pb-[23px] group-hover:border-cornflower-blue border-hit-gray text-sm text-ironside-gray group-hover:text-cornflower-blue">
                    Rotate
                  </button>
                </div>
              </div>
              <div className="flex sm:flex-row justify-between">
                <div className="px-[10px] md:px-[30px] py-[25px] whitespace-nowrap">
                  <button
                    onClick={() => {
                      setImage(undefined);
                      setCropData(undefined);
                      setCaptureImage(true);
                    }}
                    className="px-[24px] py-[3px] sm:py-[6px] border-2 border-cornflower-blue text-sm rounded-full text-cornflower-blue"
                  >
                    Use Camera
                  </button>
                </div>
                <div className="flex justify-end px-[10px] md:px-[30px] py-[25px] whitespace-nowrap">
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={onChange}
                    className="hidden"
                  />
                  <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row lg:space-y-0 lg:space-x-[25px]">
                    <button
                      onClick={triggerFileSelectPopup}
                      className="px-[24px] py-[3px] sm:py-[6px] border-2 text-sm border-cornflower-blue rounded-full text-cornflower-blue"
                    >
                      Upload Photo
                    </button>
                    <button
                      onClick={getCropData}
                      className="px-[24px] py-[3px] sm:py-[6px] sm:ml-3 text-sm rounded-full bg-cornflower-blue text-white min-h-[28px] md:mt-0 lg:h-auto"
                    >
                      {inProgress ? (
                        <div className="w-[34.5px] ml-[28px] sm:ml-0">
                          <div className="btn-spinner" />
                        </div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center lg:justify-end space-x-[25px] px-[30px] py-[25px]">
              {captureImage ? (
                <button
                  onClick={capture}
                  className="px-[24px] py-[3px] md:py-[6px] border-2 border-cornflower-blue text-sm rounded-full text-cornflower-blue"
                >
                  capture
                </button>
              ) : (
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={onChange}
                    className="hidden"
                  />
                  <div className="flex flex-col lg:flex-row space-y-[20px] lg:space-y-0 lg:space-x-[25px]">
                    <button
                      onClick={() => setCaptureImage(!captureImage)}
                      className="px-[24px] py-[3px] md:py-[6px] text-[14px] border-2 border-cornflower-blue text-sm rounded-full text-cornflower-blue"
                    >
                      Use Camera
                    </button>
                    <button
                      onClick={triggerFileSelectPopup}
                      className="px-[24px] py-[3px] md:py-[6px] text-[14px]  text-sm rounded-full bg-cornflower-blue text-white h-[28px] lg:h-auto"
                    >
                      Upload Photo
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoverImage;
