import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";
import { Listbox, Transition } from "@headlessui/react";
import { ElementRef, Fragment, useRef } from "react";
import { Button as ReactAriaButton } from "react-aria-components";
import { FieldPath, FieldValues, PathValue } from "react-hook-form";
import { BiCheck } from "react-icons/bi";
import { BsFillCaretDownFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";

import { ListBoxProps } from "components/forms/UpdatedFormAgry/Select/types";
import Button from "components/forms/Button/UpdatedButtonAgry/Button";
import SupportedTextAndErrorMessage from "components/forms/SupportedTextAndErrorMessage";

import { cn } from "global/helpers/tailwind-utils";

const ListBox = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  value,
  onChange,
  multiple,
  disabled,
  name,
  options,
  className,
  loading,
  nullable,
  observe,
  renderedOption,
  supportedTextAndErrorMessage,
  error,
}: ListBoxProps<TFieldValues, Name>) => {
  const { x, y, strategy, refs, middlewareData } = useFloating({
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ["bottom", "top"],
      }),
      hide({
        strategy: "referenceHidden",
      }),
      shift({
        padding: 20,
      }),
      size({
        apply: ({ elements }) => {
          Object.assign(elements?.floating?.style, {
            width: "max-content",
            "max-width": "100vw",
          });
        },
      }),
    ],
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const overflowRef = useRef<ElementRef<"ul">>(null);

  const isValue = multiple
    ? value && Array.isArray(value) && value?.length > 0
    : value
    ? true
    : false;

  return (
    <Listbox
      disabled={disabled}
      by={
        options && options?.length > 0 && typeof options[0] === "object"
          ? "id"
          : undefined
      }
      multiple={multiple ? (true as any) : (false as any)}
      name={name}
      value={multiple ? (value && Array.isArray(value) ? value : []) : value}
      onChange={onChange}
      as={Fragment}
    >
      {({ open }) => {
        return (
          <Fragment>
            <div
              ref={refs.setReference}
              className={cn(
                "w-full bg-white relative min-h-[56px] text-base rounded border flex items-center justify-between gap-1 group px-4",
                disabled
                  ? "border-opacity-[0.38]"
                  : "has-[:focus]:border-primary",
                error?.message ? "border-error has-[:focus]:border-error" : "",
                open ? "border-primary" : "",
                className
              )}
            >
              <div className="flex flex-col bg-inherit rounded-[inherit] flex-1 truncate group peer">
                {multiple &&
                  value &&
                  Array.isArray(value) &&
                  value?.length > 0 && (
                    <div
                      className={`flex flex-wrap pt-4 px-4 pl-0 gap-3 max-h-[250px] overflow-y-auto items-start w-full rounded-[inherit] ${
                        disabled ? "pb-4" : ""
                      }`}
                    >
                      {value?.map((result) => {
                        return (
                          <p
                            key={
                              typeof result === "object" ? result?.id : result
                            }
                            className="flex gap-3 items-center text-xs whitespace-pre-wrap rounded-full bg-primary bg-opacity-[0.12] px-2 py-1 max-w-[125px] sm:max-w-full"
                          >
                            <span className="text-primary max-w-[50px] min-w-[50px] md:max-w-[120px] truncate">
                              {typeof result === "object"
                                ? result?.label?.toString()
                                : result?.toString()}
                            </span>
                            {nullable
                              ? null
                              : !disabled && (
                                  <Button
                                    modifier="plain"
                                    size="small"
                                    className="p-0 ring-offset-0"
                                    onPress={() => {
                                      onChange(
                                        value?.filter((option) => {
                                          if (
                                            typeof option === "object" &&
                                            typeof result === "object"
                                          ) {
                                            return option?.id !== result.id;
                                          } else {
                                            return (
                                              option.toString() !==
                                              result?.toString()
                                            );
                                          }
                                        }) || []
                                      );
                                    }}
                                  >
                                    <IoCloseSharp className="w-6 h-6 p-0.5 cursor-pointer rounded-full text-base text-cornflower-blue hover:bg-primary hover:bg-opacity-[0.12] hover:text-primary" />
                                  </Button>
                                )}
                          </p>
                        );
                      })}
                    </div>
                  )}
                <Listbox.Button
                  className={cn(
                    "text-left truncate bg-inherit text-sm rounded-[inherit] peer h-10 w-full focus:outline-none focus:text-primary",
                    disabled
                      ? multiple
                        ? "text-opacity-[0.38] hidden"
                        : "text-opacity-[0.38]"
                      : "",
                    error?.message ? "text-error focus:text-error" : "",
                    multiple
                      ? isValue
                        ? "placeholder-on-surface-variant"
                        : "placeholder-transparent focus:placeholder-inherit"
                      : "placeholder-transparent focus:placeholder-inherit"
                  )}
                >
                  {loading
                    ? "Loading..."
                    : value
                    ? value
                      ? Array.isArray(value)
                        ? "Select"
                        : typeof value !== "string" && typeof value !== "number"
                        ? value?.label?.toString()
                        : value
                        ? value?.toString()
                        : "Select"
                      : "Select"
                    : "Select"}
                </Listbox.Button>
              </div>
              <Listbox.Label
                htmlFor={name}
                className={cn(
                  "transition-all max-w-[85%] bg-inherit truncate absolute left-3 -top-2 text-warm-gray/70 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-on-surface-variant peer-placeholder-shown:top-3.5 peer-focus:-top-2 peer-focus:text-primary peer-focus:text-xs px-1",
                  isValue ? "!-top-2 !text-xs" : "",
                  disabled
                    ? "text-on-surface peer-placeholder-shown:text-on-surface peer-placeholder-shown:text-opacity-[0.38] text-opacity-[0.38]"
                    : "",
                  error?.message
                    ? "text-error peer-focus:text-error peer-placeholder-shown:text-error"
                    : open
                    ? "text-primary"
                    : "peer-focus-visible:text-primary peer-focus-within:text-primary group-focus-within:text-primary group-focus-visible:text-primary"
                )}
              >
                {label}
              </Listbox.Label>
              {!disabled && (
                <div className="flex justify-center items-center py-2 gap-1 bg-inherit rounded-[inherit]">
                  {nullable && isValue && (
                    <ReactAriaButton
                      slot={null}
                      className={({ isFocusVisible }) =>
                        cn(
                          "h-min w-min outline-none rounded-full text-secondary",
                          isFocusVisible
                            ? "ring-2 ring-primary text-primary"
                            : ""
                        )
                      }
                      onPress={() => {
                        if (!disabled) {
                          onChange(multiple ? [] : null);
                        }
                      }}
                    >
                      <IoMdClose className={"h-6 w-6 min-w-6 min-h-6"} />
                    </ReactAriaButton>
                  )}
                  <Listbox.Button
                    className={
                      "w-6 h-6 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full text-secondary outline-none focus:ring-2 focus:ring-primary focus:text-primary"
                    }
                  >
                    <BsFillCaretDownFill
                      className={`h-6 w-6 p-1.5 ${
                        disabled
                          ? "text-outline text-opacity-[0.38]"
                          : "text-gray-400"
                      }  ${open ? "rotate-180" : ""}`}
                    />
                  </Listbox.Button>
                </div>
              )}
            </div>
            <SupportedTextAndErrorMessage
              error={error}
              supportedTextAndErrorMessage={supportedTextAndErrorMessage}
            />
            <FloatingPortal>
              <Transition
                as={"div"}
                afterLeave={() => {
                  observe?.(null);
                }}
                ref={refs?.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 60,
                  maxWidth: `${
                    refs?.reference?.current?.getBoundingClientRect()?.width
                      ? `${
                          refs?.reference?.current?.getBoundingClientRect()
                            ?.width
                        }px`
                      : "300px"
                  }`,
                  minWidth: `${
                    refs?.reference?.current?.getBoundingClientRect()?.width
                      ? `${
                          refs?.reference?.current?.getBoundingClientRect()
                            ?.width
                        }px`
                      : "300px"
                  }`,
                }}
                className={`${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                } max-h-[300px] overflow-y-auto shadow rounded border bg-white  ${
                  overflowRef?.current
                    ? overflowRef?.current?.getBoundingClientRect()?.height >
                      300
                      ? "py-1 pl-2"
                      : "p-2"
                    : "p-2"
                }`}
              >
                <Listbox.Options ref={overflowRef} className={"outline-none"}>
                  {!loading && options?.length === 0 ? (
                    <li className="w-full text-sm text-secondary py-1">
                      Nothing found.
                    </li>
                  ) : (
                    options.map((option) => (
                      <Listbox.Option
                        key={typeof option === "object" ? option?.id : option}
                        value={option}
                        className={({ active, disabled, selected }) =>
                          cn(
                            "p-2 text-sm rounded transition-all duration-100",
                            disabled
                              ? "cursor-default text-disabled bg-gray-400/20"
                              : active
                              ? "cursor-pointer bg-primary text-white"
                              : "text-secondary",
                            selected ? "font-medium" : "font-normal"
                          )
                        }
                      >
                        {({ active, disabled, selected }) => {
                          return renderedOption ? (
                            (renderedOption(
                              option as PathValue<TFieldValues, Name>,
                              {
                                active,
                                disabled,
                                selected,
                              }
                            ) as any)
                          ) : (
                            <div className="flex justify-between items-center gap-1">
                              <span className="capitalize flex-1 truncate">
                                {typeof option === "object"
                                  ? option?.label?.toString()
                                  : option?.toString()}
                              </span>
                              {selected && (
                                <BiCheck
                                  className={`h-5 w-5 ${
                                    active && !disabled
                                      ? "text-white"
                                      : disabled
                                      ? "text-on-surface text-opacity-[0.38]"
                                      : "text-primary"
                                  }`}
                                />
                              )}
                            </div>
                          );
                        }}
                      </Listbox.Option>
                    ))
                  )}

                  {loading && (
                    <div className="grid gap-3">
                      {Array.from({ length: 3 })?.map((_value, index) => (
                        <div key={index} className="w-full h-[40px]">
                          <span className="block bg-gray-300 w-full h-full rounded animate-pulse" />
                        </div>
                      ))}
                    </div>
                  )}
                  {observe && !loading && (
                    <div
                      className="w-full h-0 invisible"
                      ref={(ref) => observe(ref)}
                    >
                      <span className="block bg-gray-300 w-full h-full rounded animate-pulse" />
                    </div>
                  )}
                </Listbox.Options>
              </Transition>
            </FloatingPortal>
          </Fragment>
        );
      }}
    </Listbox>
  );
};

export default ListBox;
