import {
  Control,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { IoClose } from "react-icons/io5";

import FilterMain from "components/Filter/FilterMain";
import { TFilterOption } from "components/Filter/types";
import Modal from "components/UI/Modal";
import { Filled, Outlined } from "components/App/Buttons";

import { removedUndefinedValuesFromObject } from "global/helpers/removeUndefinedValuesFromObject";

interface IProps<TFilterPopUp extends FieldValues> {
  filterBtnShowHandler: () => void;
  options: TFilterOption<TFilterPopUp>;
  control: Control<TFilterPopUp>;
  register: UseFormRegister<TFilterPopUp>;
  watch: UseFormWatch<TFilterPopUp>;
  clearFilterHandler: () => void;
  filterName: Path<TFilterPopUp>;
  setValue: UseFormSetValue<TFilterPopUp>;
}

const FilterPopUp = <TFilterPopUp extends Record<string, any>>({
  filterBtnShowHandler,
  options,
  control,
  register,
  watch,
  clearFilterHandler,
  filterName,
  setValue,
}: IProps<TFilterPopUp>) => {
  const watchValue =
    typeof watch(filterName) !== "undefined" ? watch(filterName) : {};

  const totalCount = Object.keys(
    removedUndefinedValuesFromObject(watchValue)
  )?.length;
  return (
    <Modal>
      <section className="bg-white rounded-md w-[320px] md:w-[450px] lg:w-[650px] xl:w-[750px]">
        <header className="flex justify-between border-b p-5">
          <h3 className="text-lg">Filter</h3>
          <button
            onClick={filterBtnShowHandler}
            type="button"
            className="focus:ring-2 focus:ring-offset-1 focus:ring-cornflower-blue focus:outline-none rounded-full w-5 h-5"
          >
            <IoClose className="w-5 h-5" />
          </button>
        </header>
        <FilterMain
          options={options}
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
        />
        <div className="flex justify-between sm:justify-end gap-5 border-t p-5">
          {totalCount === 0 ? null : (
            <Outlined
              buttonName={"Clear"}
              buttonType="button"
              onClick={clearFilterHandler}
            />
          )}
          <Filled buttonName={"Done"} buttonType="submit" />
        </div>
      </section>
    </Modal>
  );
};

export default FilterPopUp;
