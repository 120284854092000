import { TypedDocumentNode, gql } from "@apollo/client";

import { PAGINATION_INFO } from "global/services";

import {
	FilterProjectGroupBugArgs,
	FilterProjectGroupBugReturnType,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";
import { PROJECT_GROUP_BUG_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroupBugs/services/fragments";

export const FILTER_PROJECT_GROUP_BUGS: TypedDocumentNode<
	FilterProjectGroupBugReturnType,
	FilterProjectGroupBugArgs
> = gql`
  ${PROJECT_GROUP_BUG_FRAGMENT}
  ${PAGINATION_INFO}
  query FilterProjectGroupBugs(
    $filters: ProjectGroupBugFilterInput
    $globalSearch: String
    $pagination: Pagination
    $orderBy: OrderByField
  ) {
    filterProjectGroupBugs: projectGroupBugs(
      filters: $filters
      globalSearch: $globalSearch
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...projectGroupBugFields
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const FILTER_PROJECT_GROUP_BUG_TITLES = gql`
  ${PAGINATION_INFO}
  query getProjectGroupBugTitles($search: String, $pagination: Pagination) {
    getDropDownField: projectGroupBugs(
      filters: { title: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: title
          name: title
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const FILTER_PROJECT_GROUP_BUG_SOURCES = gql`
  ${PAGINATION_INFO}
  query getProjectGroupBugSources($search: String, $pagination: Pagination) {
    getDropDownField: projectGroupBugs(
      filters: { title: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          source {
            id: name
            name
          }
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;
