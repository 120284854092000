import { Base64toObject } from "global/helpers/FileConverter";
import React, { FC } from "react";
import { BiCheck } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
interface IProps {
  option: any;
  comboBoxOption: { active: boolean; selected: boolean; disabled: boolean };
}
const CustomRenderUserImage: FC<IProps> = ({ comboBoxOption, option }) => {
  const { active, disabled, selected } = comboBoxOption;
  return (
    <div
      className={`text-sm rounded transition-all duration-100 flex justify-center items-center ${
        disabled
          ? "cursor-default text-text-disabled bg-gray-400/20"
          : active
          ? "cursor-pointer text-white bg-cornflower-blue"
          : "text-ironside-gray"
      } ${selected ? "font-medium" : "font-normal"}`}
    >
      <div className="flex-1 flex gap-3 truncate items-center p-1">
        {option?.image &&
        option?.image?.length > 100 &&
        Base64toObject(option?.image) ? (
          <img
            src={Base64toObject(option?.image)}
            alt={option?.name}
            className="min-w-[40px] min-h-[40px] max-h-[40px] max-w-[40px] text-ironside-gray/50 border p-0.5 rounded-full shadow-md bg-white"
          />
        ) : (
          <FaUserCircle className="min-w-[40px] min-h-[40px] max-h-[40px] max-w-[40px] text-ironside-gray/50 border p-0.5 rounded-full shadow-md bg-white" />
        )}
        <div className="flex flex-col gap-1">
          <span className="capitalize text-sm truncate block max-w-[150px]">
            {option?.name}
          </span>
          {option?.mobile && (
            <span className="capitalize text-xs truncate block max-w-[150px]">
              {option?.mobile}
            </span>
          )}
          {option?.nickName && (
            <span className="capitalize text-xxs truncate block max-w-[150px]">
              {option?.nickName}
            </span>
          )}
        </div>
      </div>
      <span
        className={`${
          active && !disabled
            ? "text-white"
            : disabled
            ? "text-text-disabled"
            : "text-cornflower-blue"
        } ${
          selected ? "visible" : "invisible"
        } w-10 h-6 inline-flex justify-center items-center`}
      >
        <BiCheck className="h-5 w-5" aria-hidden="true" />
      </span>
    </div>
  );
};

export default CustomRenderUserImage;
