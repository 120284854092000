import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Filled, Outlined, ToggleSwitch } from "components/forms";
import {
  getDateArray,
  prepareDateArray,
} from "components/forms/DatePicker/DateFiltering";
import Loading from "components/Loader/Loading";
import TextArea from "components/forms/UpdatedForm/TextArea";

import { toastNotify } from "global/helpers/Cache";
import { fileToBase64 } from "global/helpers/FileConverter";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import { getRailwayTimeHour } from "global/helpers/DateFormatter";
import { GET_CURRENT_TIME } from "global/services";

import {
  CREATE_APPLY_LEAVE,
  UPDATE_APPLY_LEAVE,
} from "modules/Leave/services/mutations";
import {
  FILTER_MY_LEAVE_REQUESTS,
  HOLIDAYS_COLLECTION,
} from "modules/Leave/services/queries";
import { leaveRequestsDatesType } from "modules/Leave/types/leave";
import LeaveDates from "modules/Leave/Pages/Apply/LeaveDates";
import LeaveFiles from "modules/Leave/Pages/Apply/Regular/LeaveFiles";
import WorkingDates from "modules/Leave/Pages/Apply/WorkingDates";

interface propsType {
  leaveDashboard: any;
  leaveDashboardLoader: boolean;
}

const Regular: React.FC<propsType> = ({
  leaveDashboard,
  leaveDashboardLoader,
}) => {
  const resourceAccess = useGetAllowedResources("ApplyLeave");
  const [loading, setLoading] = useState<boolean>(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { data: holidays, loading: holidayLoader } = useQuery(
    HOLIDAYS_COLLECTION,
    {
      variables: {
        type: "Regular",
      },
    }
  );

  useEffect(() => {
    if (!holidayLoader && !leaveDashboardLoader) {
      setLoading(false);
    }
  }, [holidayLoader, holidays, leaveDashboard, leaveDashboardLoader, loading]);

  const [fetchAppliedLeaves, { data: applyLeave }] = useLazyQuery(
    FILTER_MY_LEAVE_REQUESTS,
    {
      variables: {
        id: state?.id,
      },
    }
  );

  useEffect(() => {
    if (state?.id) {
      fetchAppliedLeaves();
    }
  }, [state?.id, fetchAppliedLeaves]);

  const appliedDates =
    state?.id &&
    state?.dates?.map(
      (date: { leaveDate: string }) =>
        new Date(date?.leaveDate?.replaceAll("-", " "))
    );

  const [submittedFiles, setSubmittedFiles] = useState([]);

  useEffect(() => {
    setSubmittedFiles(
      state?.leaveRequestAttachments?.map((files: any) => {
        return { id: files?.id, filePath: files?.filePath };
      }) || []
    );
  }, [state?.leaveRequestAttachments]);

  const minDate =
    appliedDates &&
    appliedDates.length > 0 &&
    appliedDates?.reduce((firstDate: Date, secondDate: Date) => {
      return firstDate < secondDate ? firstDate : secondDate;
    });

  const maxDate =
    appliedDates &&
    appliedDates.length > 0 &&
    appliedDates?.reduce((firstDate: Date, secondDate: Date) => {
      return firstDate > secondDate ? firstDate : secondDate;
    });

  const [createApplyLeave] = useMutation(CREATE_APPLY_LEAVE);
  const [updateApplyLeave] = useMutation(UPDATE_APPLY_LEAVE);

  const [officialHolidays, setOfficialHolidays] = useState<string[]>(
    prepareDateArray(
      holidays?.holidays?.dataCollection?.map(
        (holidayDates: any) =>
          `${holidayDates?.holidayYear}-${holidayDates?.holidayMonth}-${holidayDates?.holidayDate}`
      )
    ) || []
  );

  useEffect(() => {
    setOfficialHolidays(
      prepareDateArray(
        holidays?.holidays?.dataCollection?.map(
          (holidayDates: any) =>
            `${holidayDates?.holidayYear}-${holidayDates?.holidayMonth}-${holidayDates?.holidayDate}`
        )
      ) || []
    );
  }, [holidays?.holidays?.dataCollection]);

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
    clearErrors,
    reset,
    watch,
  } = useForm();

  const watchEmergencyLeave = watch("isEmergencyLeave");
  const watchLossOfPay = watch("lossOfPay");

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: state?.id ? new Date(minDate) : null,
      endDate: state?.id ? new Date(maxDate) : null,
      key: "selection",
    },
  ]);

  const [fileList, setFileList] = useState<any>([]);
  const [updatedFiles, setUpdatedFiles] = useState<any>([]);
  const [rangeFocus, setRangeFocus] = useState<boolean>(false);

  useEffect(() => {
    setUpdatedFiles(submittedFiles);
  }, [submittedFiles]);

  const [selectedDates, setSelectedDates] = useState(
    getDateArray(dateRange[0]?.startDate, dateRange[0]?.endDate)
  );

  useEffect(() => {
    setSelectedDates(
      getDateArray(dateRange[0]?.startDate, dateRange[0]?.endDate)
    );
  }, [dateRange]);

  const getWorkingDates = (
    selectedDates: string[],
    officialHolidays: string[]
  ) => {
    const holidaysFilteredList =
      selectedDates &&
      selectedDates?.filter((date: string) => {
        return officialHolidays?.indexOf(date) < 0;
      });

    const workingDates =
      holidaysFilteredList &&
      holidaysFilteredList?.filter((date: string) => {
        if (date?.indexOf("Sat") > -1 || date?.indexOf("Sun") > -1) {
          return null;
        } else {
          return date;
        }
      });
    return workingDates;
  };

  const editSessions = useMemo(
    () =>
      state?.dates
        ? state?.dates?.map((date: { session: string }) => date?.session)
        : [],
    [state]
  );

  const getLeaveDatesAndLeaveType = useCallback(
    (dates: string[]) => {
      return dates?.map((date: string, index: number) => {
        return {
          date,
          leaveType: 0,
          session: state?.dates
            ? editSessions[index]
            : new Date(date) > new Date()
            ? "Full Day"
            : "",
        };
      });
    },
    [editSessions, state]
  );

  const [workingDates, setWorkingDates] = useState<leaveRequestsDatesType[]>(
    getLeaveDatesAndLeaveType(
      getWorkingDates(selectedDates, officialHolidays)
    ) || []
  );

  useEffect(() => {
    const workingDatesList = getLeaveDatesAndLeaveType(
      getWorkingDates(selectedDates, officialHolidays)
    );

    setWorkingDates(workingDatesList || []);
  }, [officialHolidays, selectedDates, getLeaveDatesAndLeaveType]);

  useEffect(() => {
    if (rangeFocus && dateRange[0]?.startDate && dateRange[0]?.endDate) {
      setDupWorkingDates(workingDates);
    }
  }, [dateRange, rangeFocus, workingDates, getLeaveDatesAndLeaveType]);

  const [dupWorkingDates, setDupWorkingDates] = useState<
    leaveRequestsDatesType[]
  >(
    state?.id
      ? getLeaveDatesAndLeaveType(prepareDateArray(appliedDates))
      : workingDates
  );

  useEffect(() => {
    if (dupWorkingDates) {
      unregister("leaveRequests");
    }
  }, [dupWorkingDates, unregister]);

  const docNeededLeaves = leaveDashboard
    ? leaveDashboard
        ?.map((dashBoard: any) => dashBoard?.leaveType)
        ?.reduce(
          (result: any, { id, isSupportingDocNeeded }: any) =>
            isSupportingDocNeeded ? result.push(id) && result : result,
          []
        )
    : [];

  const currentFiles: any = [];

  fileList?.map((fileDetails: any, index: number) => {
    if (fileDetails?.size < 2e6)
      fileToBase64(fileDetails, (err: any, result: any) => {
        if (result) {
          currentFiles[index] = result;
        }
      });
    return <></>;
  });

  useEffect(() => {
    if (!state?.id) {
      setDupWorkingDates([]);
    }
  }, [watchEmergencyLeave, state?.id]);

  useEffect(() => {
    if (!state?.id) {
      setDupWorkingDates([]);
    }
  }, [watchLossOfPay, state?.id]);

  const selectedLeaveTypesArray = dupWorkingDates?.map(
    (date: leaveRequestsDatesType) => date?.leaveType
  );

  const filterEmergencyLeave = leaveDashboard?.filter(
    (leave: { leaveType: { name: string }; year: number }) =>
      leave?.leaveType?.name === "Emergency Leave" &&
      leave?.year === new Date().getFullYear()
  );

  const emergencyLeaveCount =
    filterEmergencyLeave?.length > 0 && filterEmergencyLeave[0];

  /****  Handling LOP   ****/

  const watchLOP = watch("lop");

  const [showLOP, setShowLOP] = useState(false);
  const [permissionTime, setPermissionTime] = useState<string | null>(null);

  const [fetchCurrentTime, { data: time }] = useLazyQuery(GET_CURRENT_TIME, {
    fetchPolicy: "network-only",
  });

  const date = dupWorkingDates?.length === 1 ? dupWorkingDates[0]?.date : "";
  const session =
    dupWorkingDates?.length === 1 ? dupWorkingDates[0]?.session : "";

  useEffect(() => {
    fetchCurrentTime();
  }, [fetchCurrentTime, permissionTime]);

  useEffect(() => {
    if (!state?.id && showLOP) {
      setValue("lop", true);
    } else if (state?.id) {
      setValue(
        "lop",
        state?.dates?.length > 0 && state?.dates[0]?.isLossOfPay ? true : false
      );
    }

    if (state?.id) {
      setValue(
        "isEmergencyLeave",
        state?.dates?.length > 0 &&
          state?.dates[0]?.leaveType?.name === "Emergency Leave"
          ? true
          : false
      );
    }

    if (
      state?.name === "Emergency Leave" &&
      emergencyLeaveCount?.remaining > 0
    ) {
      setValue("isEmergencyLeave", true);
    }

    if (state?.id) {
      setValue(
        "lossOfPay",
        state?.dates?.length > 0 &&
          state?.dates[0]?.leaveType?.name === "Loss Of Pay"
          ? true
          : false
      );
    }
  }, [setValue, state, emergencyLeaveCount?.remaining, showLOP]);

  useEffect(() => {
    if (
      !watchEmergencyLeave &&
      !watchLossOfPay &&
      dupWorkingDates?.length === 1
    ) {
      const currentDate = new Date(time?.getCurrentTime);
      const appliedDate = new Date(date);
      const twoDaysBeforeCurrentDate = new Date(time?.getCurrentTime);
      twoDaysBeforeCurrentDate?.setDate(currentDate.getDate() - 1);
      const twoDaysBeforeAppliedDate = new Date(date);
      twoDaysBeforeAppliedDate.setDate(appliedDate?.getDate() - 2);

      const oneWeekBeforeAppliedDate = new Date(date);
      oneWeekBeforeAppliedDate?.setDate(
        oneWeekBeforeAppliedDate?.getDate() - 7
      );

      const lastWeek = getDateArray(oneWeekBeforeAppliedDate, new Date(date));
      const lastWeekExcludingHolidays =
        lastWeek?.length > 0 ? getWorkingDates(lastWeek, officialHolidays) : [];
      const lastWorkingDate =
        lastWeekExcludingHolidays?.length > 0 &&
        new Date(
          lastWeekExcludingHolidays[lastWeekExcludingHolidays?.length - 2]
        );

      if (
        dupWorkingDates?.length === 1 &&
        session &&
        session !== "2 Hours" &&
        new Date() > lastWorkingDate
      ) {
        setShowLOP(true);
      }
    }
    return () => setShowLOP(false);
  }, [
    session,
    date,
    dupWorkingDates,
    time?.getCurrentTime,
    watchEmergencyLeave,
    watchLossOfPay,
    officialHolidays,
  ]);

  useEffect(() => {
    if (!watchEmergencyLeave && !watchLossOfPay && session === "2 Hours") {
      const currentDate = new Date(time?.getCurrentTime);
      const oneDayAfterCurrentDate = new Date(time?.getCurrentTime);
      const twoDaysAfterCurrentDate = new Date(time?.getCurrentTime);
      oneDayAfterCurrentDate?.setDate(currentDate?.getDate() + 1);
      twoDaysAfterCurrentDate?.setDate(currentDate?.getDate() + 2);

      const currentTimeInMilliSeconds = currentDate.getTime();
      const threeHoursAfterCurrentTimeInMilliSeconds =
        currentTimeInMilliSeconds + 3 * 60 * 60 * 1000;
      const permissionDate = new Date(date);
      permissionDate?.setHours(
        permissionTime && permissionTime?.slice(-2) !== "PM"
          ? +permissionTime?.slice(0, 2)
          : permissionTime && permissionTime?.slice(-2) === "PM"
          ? getRailwayTimeHour(permissionTime?.slice(0, 2))
          : 0
      );
      permissionDate?.setMinutes(
        permissionTime ? +permissionTime?.slice(3, 5) : 0
      );
      permissionDate?.setSeconds(0);
      const permissionTimeInMilliSeconds = permissionDate?.getTime();
      const isAfterFourPM = currentDate?.getHours() > 15;
      const currentDateWorkEndTime = new Date(time?.getCurrentTime);
      currentDateWorkEndTime?.setHours(19);
      currentDateWorkEndTime?.setMinutes(0);
      currentDateWorkEndTime?.setSeconds(0);

      const currentDateWorkEndTimeInMS = currentDateWorkEndTime?.getTime();

      const balanceTimeInMS =
        currentDateWorkEndTimeInMS - currentTimeInMilliSeconds > 0
          ? currentDateWorkEndTimeInMS - currentTimeInMilliSeconds
          : 0;

      if (
        dupWorkingDates?.length === 1 &&
        session &&
        session === "2 Hours" &&
        permissionTimeInMilliSeconds <
          threeHoursAfterCurrentTimeInMilliSeconds &&
        new Date(date) < currentDate
      ) {
        setShowLOP(true);
      } else if (
        dupWorkingDates?.length === 1 &&
        session &&
        session === "2 Hours" &&
        new Date(date) > currentDate &&
        isAfterFourPM
      ) {
        const selectedDate = new Date(date);
        const workStartTimeDate = new Date(date);
        workStartTimeDate?.setHours(9);
        workStartTimeDate?.setMinutes(30);
        selectedDate?.setHours(
          permissionTime && permissionTime?.slice(-2) !== "PM"
            ? +permissionTime?.slice(0, 2)
            : permissionTime && permissionTime?.slice(-2) === "PM"
            ? getRailwayTimeHour(permissionTime?.slice(0, 2))
            : 0
        );
        selectedDate?.setMinutes(
          permissionTime ? +permissionTime?.slice(3, 5) : 0
        );
        const selectedDateTimeInMS = selectedDate?.getTime();
        const totalTimeInMS =
          balanceTimeInMS +
          (selectedDateTimeInMS - workStartTimeDate?.getTime());
        const threeHoursInMS = 3 * 60 * 60 * 1000;
        if (
          totalTimeInMS < threeHoursInMS &&
          new Date(date) < oneDayAfterCurrentDate
        ) {
          setShowLOP(true);
        }
      }
    }

    return () => setShowLOP(false);
  }, [
    session,
    date,
    dupWorkingDates,
    time?.getCurrentTime,
    watchEmergencyLeave,
    permissionTime,
    watchLossOfPay,
  ]);

  useEffect(() => {
    if (!watchEmergencyLeave && !watchLossOfPay) {
      const currentDate = new Date(time?.getCurrentTime);

      const firstAppliedDate = new Date(
        dupWorkingDates?.length > 0 ? dupWorkingDates[0]?.date : ""
      );

      const fiveDaysAfterCurrentDate = new Date(time?.getCurrentTime);
      fiveDaysAfterCurrentDate?.setDate(currentDate.getDate() + 5);

      const tenDaysAfterCurrentDate = new Date(time?.getCurrentTime);
      tenDaysAfterCurrentDate?.setDate(currentDate?.getDate() + 10);

      const fifteenDaysAfterCurrentDate = new Date(time?.getCurrentTime);
      fifteenDaysAfterCurrentDate?.setDate(currentDate?.getDate() + 15);

      if (
        dupWorkingDates?.length === 2 &&
        firstAppliedDate < fiveDaysAfterCurrentDate
      ) {
        setShowLOP(true);
      }

      if (
        dupWorkingDates?.length >= 3 &&
        dupWorkingDates?.length <= 6 &&
        firstAppliedDate < tenDaysAfterCurrentDate
      ) {
        setShowLOP(true);
      }

      if (
        dupWorkingDates?.length > 6 &&
        firstAppliedDate < fifteenDaysAfterCurrentDate
      ) {
        setShowLOP(true);
      }
    }
    return () => setShowLOP(false);
  }, [
    session,
    date,
    dupWorkingDates,
    time?.getCurrentTime,
    watchEmergencyLeave,
    watchLossOfPay,
  ]);

  /****  LOP Handling end  ****/

  useEffect(() => {
    if (!showLOP) {
      setValue("lop", false);
    }
  }, [showLOP, setValue]);

  const createHandler = (data: any) => {
    const includesProofLeaveType = data?.leaveRequests
      ?.map((leaveRequest: { leaveType: number }) => leaveRequest.leaveType)
      ?.some((leaveType: number) => docNeededLeaves?.includes(leaveType));

    const isProofRequired =
      includesProofLeaveType && fileList?.length < 1 ? true : false;

    if (!btnLoading && !isProofRequired) {
      createApplyLeave({
        variables: {
          leaveRequestDates: data?.leaveRequests?.map(
            (leave: {
              leaveDate: string;
              leaveType: number;
              session: string;
              startTime?: string;
              endTime?: string;
            }) => {
              return {
                leaveDate: leave?.leaveDate,
                leaveType: leave?.leaveType,
                session: leave?.session,
                startTime: leave?.startTime || undefined,
                endTime: leave?.endTime || undefined,
                isLossOfPay: watchLOP ? true : false,
              };
            }
          ),
          reason: data.reason,
          leaveRequestAttachments:
            currentFiles?.length > 0
              ? currentFiles?.map((file: string) => {
                  return { filePath: file };
                })
              : undefined,
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "Your request for leave was successfully submitted.",
            },
          ]);
          navigate("/leaves/requests/my-requests");
        })
        .catch((error) => {
          setBtnLoading(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setBtnLoading(!loading);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Please attach proof to proceed.",
        },
      ]);
    }
  };

  const updateHandler = (data: any) => {
    const commonFiles: any = [
      ...updatedFiles?.map((file: any) => {
        return { id: file?.id };
      }),
      ...currentFiles?.map((file: string) => {
        return { filePath: file };
      }),
    ];

    const includesProofLeaveType = data?.leaveRequests
      ?.map((leaveRequest: { leaveType: number }) => leaveRequest.leaveType)
      ?.some((leaveType: number) => docNeededLeaves?.includes(leaveType));

    const isProofRequired =
      includesProofLeaveType && commonFiles?.length < 1 ? true : false;

    if (!btnLoading && !isProofRequired) {
      updateApplyLeave({
        variables: {
          id: state?.id,
          leaveRequestDates: data?.leaveRequests?.map(
            (leave: {
              id: number;
              leaveDate: string;
              leaveType: number;
              session: string;
              startTime?: string;
              endTime?: string;
            }) => {
              return {
                id: leave?.id,
                leaveDate: leave?.leaveDate,
                leaveType: leave?.leaveType,
                session: leave?.session,
                startTime: leave?.startTime || undefined,
                endTime: leave?.endTime || undefined,
                isLossOfPay: watchLOP ? true : false,
              };
            }
          ),
          reason: data.reason,
          leaveRequestAttachments: includesProofLeaveType
            ? commonFiles
            : undefined,
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "Your leave dates have been updated successfully.",
            },
          ]);
          navigate("/leaves/requests/my-requests");
        })
        .catch((error) => {
          setBtnLoading(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setBtnLoading(!loading);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Please attach proof to proceed.",
        },
      ]);
    }
  };

  return (
    <div className="mx-auto py-[45px] px-3 sm:px-0">
      {loading ? (
        <Loading className="min-h-[500px]" />
      ) : leaveDashboard &&
        leaveDashboard?.filter(
          (dashBoard: any) => dashBoard?.leaveType?.isSpecialLeave === false
        )?.length === 0 ? (
        <div className="w-full h-[68vh] flex justify-center items-center border">
          <p className="text-sm text-warm-gray">
            You are not assigned to any leave. Please contact HR.
          </p>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(state?.id ? updateHandler : createHandler)}
          className="space-y-2"
        >
          {emergencyLeaveCount?.remaining > 0 && (
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center mb-8">
              <p className="text-sm text-ironside-gray">Is Emergency Leave?</p>
              <ToggleSwitch
                label={"toggleDocuments"}
                register={register}
                name={"isEmergencyLeave"}
              />
            </div>
          )}
          {emergencyLeaveCount?.remaining < 0.25 && (
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center mb-8">
              <p className="text-sm text-ironside-gray">Loss Of Pay</p>
              <ToggleSwitch
                label={"toggleDocuments"}
                register={register}
                name={"lossOfPay"}
              />
            </div>
          )}
          <LeaveDates
            register={register}
            errors={errors}
            setRangeFocus={setRangeFocus}
            workingDates={dupWorkingDates}
            setDateRange={setDateRange}
            selectedDates={selectedDates}
            clearErrors={clearErrors}
            officialHolidays={officialHolidays}
            isEditing={state?.isEditing}
            isLeaveTypeRequired={true}
            watchEmergencyLeave={watchEmergencyLeave}
            watchLossOfPay={watchLossOfPay}
          />
          {dupWorkingDates?.map(
            (
              date: { date: string; leaveType: number; session: string },
              index: number
            ) => {
              return (
                <WorkingDates
                  key={index}
                  index={index}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  leaveDashboard={leaveDashboard}
                  date={date?.date}
                  dupWorkingDates={dupWorkingDates}
                  setDupWorkingDates={setDupWorkingDates}
                  editWorkingDates={state}
                  dateRange={dateRange}
                  session={date?.session}
                  isLeaveTypeRequired={true}
                  watchEmergencyLeave={watchEmergencyLeave}
                  setPermissionTime={setPermissionTime}
                  watchLossOfPay={watchLossOfPay}
                />
              );
            }
          )}
          <div
            className={`grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center`}
          >
            <span className="text-sm text-ironside-gray mt-0 sm:pb-[21px]">
              Reason
            </span>
            <TextArea
              errors={errors}
              label="Reason *"
              name="reason"
              register={register}
              setValue={setValue}
              defaultValue={state?.reason}
              required={true}
            />
          </div>
          {showLOP && (
            <div className="flex items-center">
              <input
                id="lop"
                type="checkbox"
                className="sm:ml-[166px] cursor-pointer"
                {...register("lop")}
              />
              <label htmlFor="lop" id="lop" className="ml-2 cursor-pointer">
                I want to avail the leave as LOP if the request gets auto
                declined.
              </label>
            </div>
          )}
          {selectedLeaveTypesArray?.some((leaveType: number) =>
            docNeededLeaves?.includes(leaveType)
          ) ||
          (applyLeave?.filterMyLeaveRequests?.dataCollection[0] &&
            applyLeave?.filterMyLeaveRequests?.dataCollection[0]
              ?.leaveRequestAttachments?.length > 0) ? (
            <LeaveFiles
              fileList={fileList}
              setFileList={setFileList}
              updatedFiles={updatedFiles}
              setUpdatedFiles={setUpdatedFiles}
            />
          ) : null}
          {(resourceAccess?.canCreate || resourceAccess?.canUpdate) && (
            <div className="w-full flex justify-center pt-5 space-x-3 sm:block sm:ml-[165px]">
              <div className="flex gap-3">
                {!state && (
                  <Outlined
                    buttonName="Reset"
                    onClick={() => {
                      setWorkingDates([]);
                      reset();
                    }}
                  />
                )}
                <Filled
                  buttonType="submit"
                  buttonName={"Submit"}
                  loading={btnLoading}
                />
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default Regular;
