export const getStatusColor = (status: string) => {
  switch (status) {
    case "Pending":
      return "text-yellow-orange";

    case "Approved":
      return "text-dark-mint";

    case "Declined":
      return "text-brink-pink";

    case "Cancelled":
      return "text-valentine-red";

    case "Partially Approved":
      return "text-[#3BC171]";

    default:
      return "text-cornflower-blue";
  }
};
