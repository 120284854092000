import { gql } from "@apollo/client";

export const CREATE_DEPARTMENT = gql`
  mutation createEmployeeDepartment($name: String!) {
    createEmployeeDepartment(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateEmployeeDepartment($id: Int!, $name: String) {
    updateEmployeeDepartment(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteEmployeeDepartment($id: Int!) {
    deleteEmployeeDepartment(id: $id)
  }
`;

export const CREATE_DESIGNATION = gql`
  mutation createDesignation(
    $name: String!
    $employeeDepartment: Int!
    $description: String
    $minExpYears: Int!
    $maxExpYears: Int!
    $isUnderHiring: Boolean
    $isActive: Boolean
  ) {
    createDesignation(
      name: $name
      employeeDepartment: $employeeDepartment
      description: $description
      minExpYears: $minExpYears
      maxExpYears: $maxExpYears
      isUnderHiring: $isUnderHiring
      isActive: $isActive
    ) {
      id
      name
      employeeDepartment {
        id
        name
      }
      description
      isUnderHiring
      isActive
      maxExpYears
      minExpYears
    }
  }
`;

export const UPDATE_DESIGNATION = gql`
  mutation updateDesignation(
    $id: Int!
    $name: String
    $employeeDepartment: Int
    $description: String
    $minExpYears: Int
    $maxExpYears: Int!
    $isUnderHiring: Boolean
    $isActive: Boolean
  ) {
    updateDesignation(
      id: $id
      name: $name
      employeeDepartment: $employeeDepartment
      description: $description
      minExpYears: $minExpYears
      maxExpYears: $maxExpYears
      isUnderHiring: $isUnderHiring
      isActive: $isActive
    ) {
      id
      name
      employeeDepartment {
        id
        name
      }
      description
      isUnderHiring
      isActive
      maxExpYears
      minExpYears
    }
  }
`;

export const DELETE_DESIGNATION = gql`
  mutation deleteDesignation($id: Int!) {
    deleteDesignation(id: $id)
  }
`;
