import { FC } from "react";
import { PressEvent } from "react-aria";

import Button from "components/forms/Button/UpdatedButtonAgry/Button";
import { twMerge } from "tailwind-merge";

interface Props {
  onPress?: ((e: PressEvent) => void) | undefined;
  className?: string;
  btnClassName?: string;
}

const AddButton: FC<Props> = ({ onPress, className, btnClassName }) => {
  return (
    <div
      className={twMerge(
        `w-full min-h-[calc(100vh-25vh)] grid place-content-center`,
        className
      )}
    >
      <Button
        children="Add"
        onPress={onPress}
        className={twMerge(`min-w-[90px]`, btnClassName)}
      />
    </div>
  );
};

export default AddButton;
