import { useEffect, useRef, useState } from "react";
import { HiOutlineCalendar } from "react-icons/hi";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { getSaturdays } from "components/forms/DatePicker/DateFiltering";

import { removeDuplicates } from "global/helpers/ArrayMethods";
import {
  DateMonthFormatter,
  DateMonthYearFormatter,
} from "global/helpers/DateFormatter";

import { leaveRequestsDatesType } from "modules/Leave/types/leave";

const DateRangeSelectorField: React.FC<{
  register: Function;
  errors: any;
  name: string;
  className?: string;
  label?: string;
  required?: boolean | any;
  setRangeFocus: Function;
  dateRange: [
    {
      startDate: Date;
      endDate: Date;
      key: string;
    }
  ];
  setDateRange: Function;
  datesArray: leaveRequestsDatesType[];
  disabledDates?: Date[];
  scrollOption?: boolean;
  minDate?: Date | any;
  maxDate?: Date | any;
  holidays?: any[];
  isEditing?: boolean;
  disableSaturdaysSundaysAndHolidays?: boolean;
}> = ({
  register,
  errors,
  name,
  className,
  label,
  required,
  setRangeFocus,
  dateRange,
  setDateRange,
  datesArray,
  disabledDates,
  scrollOption,
  minDate,
  maxDate,
  holidays,
  isEditing,
  disableSaturdaysSundaysAndHolidays = true,
}) => {
  const calendarRef: any = useRef();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [dateSelectionCount, setDateSelectionCount] = useState(0);

  useEffect(() => {
    if (datesArray?.length > 1) {
      setShowCalendar(false);
    }
  }, [datesArray]);

  useEffect(() => {
    if (!showCalendar) {
      setDateSelectionCount(0);
    }
  }, [showCalendar]);

  useEffect(() => {
    if (dateSelectionCount > 1) {
      setShowCalendar(false);
    }
  }, [dateSelectionCount]);

  useEffect(() => {
    const handler = (event: PointerEvent) => {
      if (!calendarRef.current?.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("pointerdown", handler);
    return () => {
      document.removeEventListener("pointerdown", handler);
    };
  }, [showCalendar]);

  const tempRange: any = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];

  const customDayContent = (day: any) => {
    const holidaysList =
      (holidays &&
        holidays?.map((holiday: string) => new Date(holiday)?.toString())) ||
      [];

    const weekOffSaturdays = getSaturdays(
      new Date(day)?.getFullYear(),
      new Date(day)?.getMonth()
    );

    const weekOffAndHolidays: any[] = [
      ...holidaysList,
      ...weekOffSaturdays?.map((weekOffDay: any) => weekOffDay?.toString()),
    ];

    if (new Date(day)?.getDay() === 0) {
      weekOffAndHolidays.push(...weekOffAndHolidays, new Date(day)?.toString());
    }

    removeDuplicates(weekOffAndHolidays);

    const showDate = new Date(day)?.getDate();

    return (
      <div>
        <span
          className={
            weekOffAndHolidays &&
            weekOffAndHolidays?.filter(
              (weekOffAndHoliday: string) =>
                weekOffAndHoliday === day?.toString()
            )?.length > 0
              ? "!text-[#d5dce0]"
              : ""
          }
        >
          {showDate}
        </span>
      </div>
    );
  };

  return (
    <div
      className="relative flex items-center w-full flex-col"
      ref={calendarRef}
    >
      <div
        onClick={() => {
          if (!isEditing) {
            setShowCalendar(!showCalendar);
          }
        }}
        className={`${className} ${
          errors[name]
            ? "text-brink-pink"
            : showCalendar
            ? "border-cornflower-blue"
            : "border-mercury"
        } border rounded-md flex justify-between items-center p-3 shadow w-full ${
          !isEditing ? "cursor-pointer" : ""
        }`}
      >
        <p
          className={`${
            errors[name]
              ? "text-brink-pink"
              : showCalendar
              ? "text-cornflower-blue"
              : "text-warm-gray"
          } ${
            datesArray?.length > 0 || showCalendar
              ? "-top-2 text-[11px]"
              : "px-0 text-[14px]"
          } absolute left-4 right-12`}
        >
          <span className="bg-white">{label ? label : "Dates"}</span>
        </p>
        <div className="text-ironside-gray text-sm w-full">
          {datesArray?.length === 0
            ? ""
            : datesArray?.length === 1
            ? DateMonthYearFormatter(dateRange[0]?.startDate?.toString())
            : +dateRange[0]?.startDate?.getFullYear() ===
              +dateRange[0]?.endDate?.getFullYear()
            ? DateMonthFormatter(dateRange[0]?.startDate?.toString()) +
              " - " +
              DateMonthYearFormatter(dateRange[0]?.endDate?.toString())
            : `${DateMonthYearFormatter(
                dateRange[0]?.startDate?.toString()
              )} - ${DateMonthYearFormatter(
                dateRange[0]?.endDate?.toString()
              )}`}
        </div>
        {!isEditing && (
          <div>
            <HiOutlineCalendar className="w-6 h-6 cursor-pointer text-cornflower-blue" />
            <input
              id={name}
              type="date"
              hidden
              {...register(name, {
                required: required ? "This is required field." : false,
              })}
            />
          </div>
        )}
      </div>
      {showCalendar ? (
        <div className="absolute top-[58px] z-50 -right-[2%] border shadow-[0px_0px_10px_0px_rgba(161,161,161,1)]">
          <DateRange
            showDateDisplay={false}
            onChange={(item: any) => {
              setDateRange([item.selection]);
              setDateSelectionCount((prevCount: number) => prevCount + 1);
            }}
            moveRangeOnFirstSelection={false}
            ranges={dateRange[0]?.startDate ? dateRange : tempRange}
            onRangeFocusChange={() => {
              setRangeFocus(true);
            }}
            disabledDates={disabledDates}
            scroll={{ enabled: scrollOption ? scrollOption : false }}
            minDate={minDate ? minDate : null}
            maxDate={maxDate ? maxDate : null}
            dayContentRenderer={
              disableSaturdaysSundaysAndHolidays ? customDayContent : undefined
            }
          />
        </div>
      ) : null}
      <div className="w-full">
        {errors[name] ? (
          <span className="peer-invalid:visible text-[#FF647C] text-[12px] leading-[15px]">
            {errors[name].message}
          </span>
        ) : errors[name] ? (
          <span className="invisible">Helper Text</span>
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </div>
    </div>
  );
};

export default DateRangeSelectorField;
