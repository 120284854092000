import { ElementRef, FC, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { mergeProps, useButton, useFocusRing } from "react-aria";

import { ButtonProps } from "components/forms/Button/UpdatedButtonAgry/types";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";

const Button: FC<ButtonProps> = ({
  variant = "primary",
  modifier = "filled",
  size = "medium",
  isRounded,
  loading,
  children,
  disabled,
  autoFocus,
  elementType,
  excludeFromTabOrder,
  href,
  id,
  onBlur,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onPressChange,
  onPressEnd,
  onPressStart,
  onPressUp,
  onPress,
  rel,
  target,
  type,
  className,
  loadingColor,
  ...props
}) => {
  const buttonRef = useRef<ElementRef<"button">>(null);

  const { buttonProps } = useButton(
    {
      autoFocus,
      elementType,
      excludeFromTabOrder,
      href,
      id,
      isDisabled: disabled || loading,
      onBlur,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      onPressUp,
      rel,
      target,
      type,
    },
    buttonRef
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  let buttonClass = "";

  switch (variant) {
    case "primary":
      switch (modifier) {
        case "filled":
          buttonClass = `${
            disabled
              ? "bg-on-surface/10 text-on-surface/30"
              : `bg-cornflower-blue text-white enabled:hover:bg-cornflower-blue/80 ${
                  isFocusVisible ? "ring-2 ring-primary ring-offset-4" : ""
                }`
          }`;
          break;
        case "outline":
          buttonClass = `border ${
            disabled
              ? "text-on-surface/30 border-outline/40"
              : `text-cornflower-blue border-cornflower-blue ${
                  isFocusVisible
                    ? "ring-2 ring-primary ring-offset-4 border-cornflower-blue"
                    : ""
                }`
          }`;
          break;
        case "plain":
          buttonClass = disabled
            ? "text-on-surface/30"
            : `text-cornflower-blue ${
                isFocusVisible ? "ring-2 ring-primary ring-offset-4" : ""
              }`;
          break;
      }
      break;
    case "destructive":
      switch (modifier) {
        case "filled":
          buttonClass = `${
            disabled
              ? "bg-on-surface/10 text-on-surface/30"
              : `bg-error text-white enabled:hover:bg-error/80 ${
                  isFocusVisible ? "ring-2 ring-error ring-offset-4" : ""
                }`
          }`;
          break;
        case "outline":
          buttonClass = `border ${
            disabled
              ? "text-on-surface/30 border-outline/40"
              : `text-error border-error ${
                  isFocusVisible
                    ? "ring-2 ring-error ring-offset-4 border-error"
                    : ""
                }`
          }`;
          break;
        case "plain":
          buttonClass = disabled
            ? "text-on-surface/30"
            : `text-error ${
                isFocusVisible ? "ring-2 ring-error ring-offset-4" : ""
              }`;
          break;
      }
      break;
    case "tertiary":
      switch (modifier) {
        case "filled":
          buttonClass = `${
            disabled
              ? "bg-on-surface/10 text-on-surface/30"
              : `bg-tertiary text-white enabled:hover:bg-tertiary/80 ${
                  isFocusVisible ? "ring-2 ring-tertiary ring-offset-4" : ""
                }`
          }`;
          break;
        case "outline":
          buttonClass = `border ${
            disabled
              ? "text-on-surface/30 border-outline/40"
              : `text-tertiary border-tertiary ${
                  isFocusVisible
                    ? "ring-2 ring-tertiary ring-offset-4 border-tertiary"
                    : ""
                }`
          }`;
          break;
        case "plain":
          buttonClass = disabled
            ? "text-on-surface/30"
            : `text-tertiary ${
                isFocusVisible ? "ring-2 ring-tertiary ring-offset-4" : ""
              }`;
          break;
      }
      break;
  }

  return (
    <button
      {...mergeProps(buttonProps, focusProps, props)}
      disabled={disabled || loading}
      className={twMerge(
        "focus:outline-none w-full transition-colors duration-75",
        disabled
          ? "cursor-not-allowed"
          : loading
          ? "cursor-progress"
          : "cursor-pointer",
        size === "small"
          ? modifier === "plain"
            ? "text-xs"
            : "text-xs px-2 py-0.5"
          : size === "medium"
          ? modifier === "plain"
            ? "text-sm"
            : "text-sm py-2 px-4"
          : modifier === "plain"
          ? "text-base"
          : "text-base py-2.5 px-4",
        isRounded ? "rounded-full" : "rounded-lg",
        buttonClass,
        className
      )}
    >
      {loading ? (
        <Loading
          smallLoading
          color={
            loadingColor
              ? loadingColor
              : modifier === "plain" || modifier === "outline"
              ? variant === "destructive"
                ? "red"
                : variant === "primary"
                ? "green"
                : "tertiary"
              : "white"
          }
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
