import { forwardRef, Fragment, PropsWithChildren, ReactNode } from "react";
import { HiPlusSm } from "react-icons/hi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import Loading from "components/Loader/Loading";
import {
  IOnCheckBox,
  IOnPagination,
  IOnPlusIcon,
  ITableHeadings,
  IOnPencilIcon,
} from "components/App/UpdatedAppComponents/Table/types/table";
import OopsNoData from "components/UI/OopsNoData";
import Pagination from "components/App/Pagination/Pagination";
import { ISetStateType, ISortOrder } from "global/types/type";

interface IProps {
  onCheckBox?: IOnCheckBox;
  tableHeadings: ITableHeadings;
  onPlusIcon?: IOnPlusIcon;
  onPencilIcon?: IOnPencilIcon;
  onPagination: IOnPagination;
  className?: string;
  loading?: boolean;
  isCenterLastHeading?: boolean;
  children?: ReactNode;
  isSortOrderNeeded?: boolean;
  setSortOrder?: ISetStateType<ISortOrder | null>;
  sortOrder?: ISortOrder | null;
}

const Table = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  (
    {
      children,
      onCheckBox,
      tableHeadings,
      onPlusIcon,
      className,
      loading,
      isCenterLastHeading,
      onPagination,
      isSortOrderNeeded = false,
      setSortOrder,
      sortOrder,
    },
    ref
  ) => {
    const pageChangeHandler = (page: number) => {
      onPagination.setCurrentPage(page);
      onPagination.onPageChange && onPagination.onPageChange(page);
    };

    return (
      <Fragment>
        {loading ? (
          <Loading className="min-h-[70vh]" />
        ) : (
          <div
            className={`flex flex-col w-full justify-between ${
              className ? className : "min-h-[200px]"
            }`}
          >
            <div
              className={`w-full overflow-x-auto overflow-y-hidden`}
              ref={ref}
            >
              <table className="w-full">
                <thead className="bg-white-smoke">
                  <tr>
                    {onCheckBox?.isShow && (
                      <th className="py-2 px-5 bg-blue-50">
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            className="h-4 w-4 text-cornflower-blue border accent-cornflower-blue border-light-gray rounded-[2px] cursor-pointer"
                            onChange={onCheckBox.onChange}
                            checked={onCheckBox.isChecked}
                          />
                        </div>
                      </th>
                    )}
                    {tableHeadings.tableString &&
                      tableHeadings.tableString?.map((tableHeading, index) => {
                        return (
                          <th
                            key={index}
                            className={`p-3 text-left text-sm bg-blue-50 text-ironside-gray font-extrabold py-5 whitespace-nowrap  ${
                              isCenterLastHeading && "last:text-center"
                            }`}
                          >
                            {tableHeading}
                          </th>
                        );
                      })}
                    {tableHeadings.tableObject &&
                      tableHeadings.tableObject?.map((tableHeading, index) =>
                        tableHeading?.hideHeading ? null : isSortOrderNeeded &&
                          tableHeading?.APIName ? (
                          <th
                            key={index}
                            className={`p-3 font-extrabold py-5 ${
                              tableHeading?.center ? "text-center" : "text-left"
                            } text-sm bg-blue-50 text-ironside-gray whitespace-nowrap ${
                              isCenterLastHeading && "last:text-center"
                            }`}
                          >
                            <div
                              className={`flex items-center cursor-pointer ${
                                tableHeading?.center ? "justify-center" : ""
                              } gap-1`}
                              onClick={() => {
                                if (setSortOrder && tableHeading?.APIName) {
                                  setSortOrder((prevState) => ({
                                    field: tableHeading?.APIName as string,
                                    name: tableHeading?.name,
                                    orderType:
                                      prevState === null
                                        ? "ASC"
                                        : prevState?.orderType === "ASC" &&
                                          prevState?.name === tableHeading?.name
                                        ? "DESC"
                                        : prevState?.orderType === "DESC" &&
                                          prevState?.name === tableHeading?.name
                                        ? "ASC"
                                        : "ASC",
                                  }));
                                }
                              }}
                            >
                              <span
                                className={`${
                                  sortOrder?.name === tableHeading?.name
                                    ? "text-cornflower-blue"
                                    : ""
                                }`}
                              >
                                {tableHeading?.name}
                              </span>
                              <div className="relative mb-2">
                                <IoMdArrowDropup
                                  className={`cursor-pointer w-4 h-4 ${
                                    sortOrder?.orderType === "ASC" &&
                                    sortOrder?.name === tableHeading?.name
                                      ? "text-cornflower-blue"
                                      : ""
                                  }`}
                                />
                                <IoMdArrowDropdown
                                  className={`absolute cursor-pointer top-2 w-4 h-4 ${
                                    sortOrder?.orderType === "DESC" &&
                                    sortOrder?.name === tableHeading?.name
                                      ? "text-cornflower-blue"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </th>
                        ) : (
                          <th
                            key={index}
                            className={`p-3 font-extrabold py-5 ${
                              tableHeading?.center ? "text-center" : "text-left"
                            } text-sm bg-blue-50 text-ironside-gray whitespace-nowrap ${
                              isCenterLastHeading && "last:text-center"
                            }`}
                          >
                            {tableHeading?.name}
                          </th>
                        )
                      )}
                    {onPlusIcon?.isShow ? (
                      <th className="py-2 px-3 text-left text-sm bg-blue-50 text-ironside-gray font-normal whitespace-nowrap">
                        <div className="w-full flex justify-center items-center">
                          <div className="w-full flex justify-center items-center">
                            <HiPlusSm
                              className="w-6 h-8 text-ironside-gray/70 font-bold cursor-pointer"
                              onClick={() => {
                                onPlusIcon.onPlusIconClick &&
                                  onPlusIcon.onPlusIconClick();
                              }}
                            />
                          </div>
                        </div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {onPagination && onPagination.noOfItems === 0 ? (
                    <tr>
                      <td
                        colSpan={
                          (tableHeadings.tableString &&
                            tableHeadings.tableString?.length + 1) ||
                          (tableHeadings.tableObject &&
                            tableHeadings.tableObject?.length + 1) ||
                          30
                        }
                      >
                        <OopsNoData className="min-h-[70vh]" />
                      </td>
                    </tr>
                  ) : (
                    children
                  )}
                </tbody>
              </table>
            </div>
            {onPagination && onPagination.noOfItems !== 0 && (
              <Pagination
                totalCount={onPagination.totalCount || 0}
                currentPage={onPagination.currentPage}
                setPageSize={onPagination.setPageSize}
                siblingCount={1}
                pageSize={onPagination.pageSize}
                onPageChange={(page: number) => pageChangeHandler(page)}
                noOfItem={onPagination.noOfItems || 0}
                onRowPerPageChange={onPagination?.onRowPerPageChange}
              />
            )}
          </div>
        )}
      </Fragment>
    );
  }
);

export default Table;
