import { ChangeEvent, Fragment, ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";
import { AiOutlineSearch } from "react-icons/ai";
import { twMerge } from "tailwind-merge";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";

import { FilterOptions } from "components/FilterUpdated/types";
import FilterPopup from "components/FilterUpdated/FilterPopup";
import { countNonNullKeys } from "components/FilterUpdated/helpers";
import Button from "components/forms/Button/UpdatedButtonAgry/Button";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";

import { ISetStateType } from "global/types/type";
import { ReactComponent as ArrowDropDown } from "global/assets/images/and-design-down-arrow.svg";
import { removeKey } from "global/helpers/removeUndefinedValuesFromObject";
import { useCustomSearchParams, useSearch } from "global/UpdatedHooks/hooks";
import useUpdateEffect1 from "global/UpdatedHooks/hooks/useUpdateEffect1";

interface Props<TFieldValues extends FieldValues = FieldValues> {
  className?: string;
  control: Control<TFieldValues>;
  hideGlobalSearch?: boolean;
  globalSearchName: FieldPath<TFieldValues>;
  hideFilterBtn?: boolean;
  hideAddBtn?: boolean;
  addBtnClickHandler?: () => void;
  addBtnDisabled?: boolean;
  onSearchChange?: (search: string | undefined) => void;
  searchLoading?: boolean;
  options: FilterOptions<TFieldValues>;
  beforeChildren?: ReactNode;
  afterChildren?: ReactNode;
  filterValues: TFieldValues["filter"] | null | undefined;
  onSubmit: (values: TFieldValues) => void;
  onClear: () => void;
  setFilterValues: ISetStateType<TFieldValues["filter"] | null | undefined>;
  setValue: UseFormSetValue<TFieldValues>;
  addBtnName?: string;
  showAddBtnAsAPlainText?: boolean;
  classNameForAddBtn?: string;
  filterBtnLoading?: boolean;
  filterBtnDisabled?: boolean;
  addBtnLoading?: boolean;
  searchInputBoxDisabled?: boolean;
}

const Filter = <TFieldValues extends FieldValues = FieldValues>({
  className,
  control,
  hideGlobalSearch,
  globalSearchName,
  addBtnClickHandler,
  addBtnDisabled,
  onSearchChange,
  searchLoading,
  hideAddBtn,
  hideFilterBtn,
  options,
  afterChildren,
  beforeChildren,
  filterValues,
  onSubmit,
  onClear,
  setFilterValues,
  setValue,
  addBtnName,
  showAddBtnAsAPlainText,
  classNameForAddBtn,
  addBtnLoading,
  filterBtnDisabled,
  filterBtnLoading,
  searchInputBoxDisabled,
}: Props<TFieldValues>) => {
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useCustomSearchParams();

  const showFilterPopupHandler = () => {
    if (setValue) {
      setValue(
        "filter" as Path<TFieldValues>,
        filterValues as PathValue<TFieldValues, Path<TFieldValues>>
      );
    }
    setShowFilterPopup((prevShowFilterPopup) => !prevShowFilterPopup);
  };

  const totalCount = countNonNullKeys(filterValues);

  const [searchItem, setSearchItem] = useState("");
  const searchValue = useSearch(searchItem || "");

  useUpdateEffect1(() => {
    onSearchChange && onSearchChange(searchValue);
  }, [searchValue]);

  const inputOnChange: (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => void = (event, onChange) => {
    onChange(event?.target.value || null);
    setSearchItem(event?.target?.value?.trim() || "");
    setSearchParams(
      event?.target?.value &&
        event?.target?.value?.trim() &&
        event?.target?.value?.trim()?.length > 0
        ? {
            ...searchParams,
            search: event?.target?.value?.trim(),
          }
        : searchParams?.search
        ? removeKey<typeof searchParams>(searchParams, "search")
          ? removeKey<typeof searchParams>(searchParams, "search")
          : {}
        : searchParams
    );
  };

  const addButtonDisable = addBtnDisabled ? true : false;

  return !hideGlobalSearch || !hideFilterBtn || !hideAddBtn ? (
    <div
      className={twMerge(
        "w-full flex flex-col gap-4 sm:flex-row items-center sm:justify-end",
        className
      )}
    >
      {beforeChildren}
      {hideGlobalSearch ? null : (
        <Controller
          name={globalSearchName}
          control={control}
          disabled={searchInputBoxDisabled}
          render={({ field: { onChange, name, value, ...rest } }) => {
            return (
              <div
                className={`flex items-center rounded-full  max-w-xs pr-1 ${
                  searchInputBoxDisabled
                    ? "bg-outline bg-opacity-[0.38] cursor-not-allowed"
                    : "bg-outline-variant"
                }`}
              >
                <label htmlFor={name} className="p-2 relative">
                  <AiOutlineSearch className="w-6 h-6" />
                  {/* {value && value?.trim()?.length > 0 && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="cursor-pointer w-5 h-5 absolute bottom-3 left-48"
                    >
                      <MdClose className="w-6 h-6 " />
                    </span>
                  )} */}
                </label>
                <input
                  id={name}
                  {...rest}
                  onChange={(e) => {
                    inputOnChange(e, onChange);
                  }}
                  placeholder="Search"
                  autoComplete="off"
                  className="w-full bg-outline-variant h-10 rounded-r-[inherit] focus:outline-none placeholder-secondary disabled:text-outline text-opacity-[0.38]"
                />
                <Loading
                  smallLoading
                  className={searchLoading ? "visible" : "invisible"}
                />
              </div>
            );
          }}
        />
      )}

      {!hideFilterBtn || !hideAddBtn ? (
        <div
          className={`flex gap-4 min-w-full sm:min-w-min items-center ${
            !hideFilterBtn && !hideAddBtn ? "justify-between" : "justify-center"
          }`}
        >
          {hideFilterBtn ? null : (
            <Button
              children={
                <Fragment>
                  <div
                    className={
                      "flex items-center justify-between text-on-background py-1"
                    }
                  >
                    <p className="text-xs">{"Filter"}</p>
                    <ArrowDropDown className="text-on-background" />
                  </div>
                  {totalCount && totalCount > 0 ? (
                    <span className="absolute -top-1 -left-1 text-white bg-error p-px text-[11px] min-w-[22px] min-h-[22px] rounded-full">
                      {totalCount}
                    </span>
                  ) : null}
                </Fragment>
              }
              className={
                "min-h-0 w-[88px] pl-4 pr-2 py-[7px] border-outline relative disabled:border-opacity-[0.38]"
              }
              isRounded
              variant="primary"
              modifier="outline"
              onPress={showFilterPopupHandler}
              loading={filterBtnLoading}
              disabled={filterBtnDisabled}
            />
          )}

          {hideAddBtn ? null : (
            <Button
              isRounded
              className={twMerge("w-min", classNameForAddBtn)}
              onPress={addBtnClickHandler}
              disabled={addButtonDisable}
              children={
                showAddBtnAsAPlainText ? (
                  addBtnName || "Add"
                ) : (
                  <div className="flex gap-2 py-0.5">
                    <HiPlus className="min-w-[18px] min-h-[18px]" />
                    <span className="pr-2">{addBtnName || "Add"}</span>
                  </div>
                )
              }
              loading={addBtnLoading}
            />
          )}
        </div>
      ) : null}

      {showFilterPopup ? (
        <FilterPopup
          control={control}
          onClear={onClear}
          onSubmit={onSubmit}
          options={options}
          showFilterPopupHandler={showFilterPopupHandler}
          setFilterValues={setFilterValues}
          setValue={setValue}
        />
      ) : null}
      {afterChildren}
    </div>
  ) : null;
};

export default Filter;
