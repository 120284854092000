import { FC, useEffect } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaPlay, FaStop } from "react-icons/fa";
import { useParams } from "react-router-dom";

import TextArea from "components/forms/UpdatedForm/TextArea";
import { Filled } from "components/forms";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import CustomRenderUserImage from "components/forms/UpdatedForm/AutoComplete/CustomRenderUserImage";
import CircleProgress from "components/UI/CircleProgress";

import {
  getAllowedAccess,
  toastNotify,
  userContextData,
} from "global/helpers/Cache";
import { useEffectOnce, useStopWatch } from "global/UpdatedHooks/hooks";
import { formatTime } from "global/helpers/DateFormatter";
import { ISetStateType } from "global/types/type";
import { secondsToHoursConverterWithSeconds } from "global/helpers/DateFormatter";

import {
  PROJECT_WORK_LOG_HOURS,
  RUNNING_PROJECT_WORK_LOG,
} from "modules/Project/services/queries";
import {
  START_PROJECT_WORK_LOG,
  STOP_PROJECT_WORK_LOG,
} from "modules/Project/services/mutations";
import {
  IProjectMembersList,
  IProjectTaskApprovedHours,
  IProjectWorkLog,
} from "modules/Project/types/project";
import ActivityLoading from "modules/Project/Pages/Issues/IssueLogs/Activity/ActivityLoading";

interface IProps {
  startDate: string;
  allocatedApprovedHours: number;
  setTimerRunningWorkLog: ISetStateType<IProjectWorkLog | null>;
  isAssignedToTheTask?: boolean;
  refetchWorkLog: boolean;
  projectTaskApprovedHours: IProjectTaskApprovedHours[];
  projectMembersList: IProjectMembersList[];
  hasAllWorkLogReadAccess: boolean;
  taskLoading: boolean;
}

const RunningLog: FC<IProps> = ({
  startDate,
  allocatedApprovedHours,
  setTimerRunningWorkLog,
  isAssignedToTheTask,
  refetchWorkLog,
  projectTaskApprovedHours,
  projectMembersList,
  hasAllWorkLogReadAccess,
  taskLoading,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const userId = userDataList?.user?.id;
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const canReadUserProfileImage = allowedResources?.includes(
    "ReadUserProfileImage"
  );

  const [startProjectWorkLog, { loading: startProjectWorkLogLoading }] =
    useMutation(START_PROJECT_WORK_LOG);

  const [stopProjectWorkLog, { loading: stopProjectWorkLogLoading }] =
    useMutation(STOP_PROJECT_WORK_LOG);

  const [
    fetchrunningProjectWorkLog,
    { data: getRunningProjectWorkLog, refetch: refetchRunningProjectWorkLog },
  ] = useLazyQuery(RUNNING_PROJECT_WORK_LOG, {
    fetchPolicy: "network-only",
  });

  const [fetchWorkLogTotalHours, { data: workLogHours, refetch, loading }] =
    useLazyQuery(PROJECT_WORK_LOG_HOURS, {
      fetchPolicy: "network-only",
    });

  const [fetchLastWorkLog, { data: lastWorkLog, loading: lastWorkLogLoading }] =
    useLazyQuery(PROJECT_WORK_LOG_HOURS, {
      fetchPolicy: "network-only",
    });

  const { id, issueId } = useParams();
  const {
    register: registerRunningLog,
    setValue: setValueRunningLog,
    handleSubmit: handleSubmitRunningLog,
    formState: { errors: runningLogErrors },
    watch: watchRunningLog,
    control: runningLogControl,
  } = useForm<{
    workLogDescription: string;
    assignees: {
      id: number;
      name: string;
      image: string;
    };
  }>();
  const watchWorkLogDescription = watchRunningLog("workLogDescription");
  const watchAssignees = watchRunningLog("assignees");

  const assigneeUserIds =
    projectTaskApprovedHours?.length > 0
      ? projectTaskApprovedHours?.map((task) => task?.assigneeUser?.user?.id)
      : [];

  const assigneeUserIdsWithProfile = projectMembersList?.filter((member) =>
    assigneeUserIds?.includes(member?.user?.personalInformation?.id)
  );

  const assigneeUserList =
    assigneeUserIdsWithProfile?.length > 0
      ? assigneeUserIdsWithProfile?.map((assigneeUser) => {
          return {
            id: assigneeUser?.user?.personalInformation?.id,
            name: assigneeUser?.user?.personalInformation?.name,
            image:
              assigneeUser?.user?.userProfileImages?.length > 0
                ? assigneeUser?.user?.userProfileImages[0]?.documents[0]?.file
                : "",
          };
        }) || []
      : [];

  useEffect(() => {
    if (id && issueId && !isNaN(+id) && !isNaN(+issueId)) {
      fetchWorkLogTotalHours({
        variables: {
          filters: {
            projectId: +id,
            projectTaskId: +issueId,
            userId: !watchAssignees
              ? userId
              : watchAssignees?.id
              ? watchAssignees?.id
              : undefined,
          },
        },
      });
    }
  }, [fetchWorkLogTotalHours, id, issueId, userId, watchAssignees]);

  useEffect(() => {
    if (id && issueId && !isNaN(+id) && !isNaN(+issueId)) {
      fetchLastWorkLog({
        variables: {
          filters: {
            projectId: +id,
            projectTaskId: +issueId,
            userId,
          },
          limit: 1,
        },
      });
    }
  }, [id, issueId, userId, fetchLastWorkLog]);

  useEffect(() => {
    if (refetchWorkLog) {
      refetch();
    }
  }, [refetchWorkLog, refetch]);

  const chosenUser = projectTaskApprovedHours?.filter(
    (task) => task?.assigneeUser?.user?.id === watchAssignees?.id
  );

  /* Total Hours Calculation Start */

  const allAllocatedApprovedHours =
    projectTaskApprovedHours?.length > 0
      ? projectTaskApprovedHours?.map((task) => {
          const allocatedApprovedHour = task?.allocatedApprovedHours
            ?.toString()
            ?.split(".");
          return {
            hour: +allocatedApprovedHour[0] || 0,
            minutes: +allocatedApprovedHour[1] * 0.6 || 0,
          };
        })
      : [];

  const allAllocatedApprovedTimeInHours = allAllocatedApprovedHours?.reduce(
    (acc, time) => acc + time?.hour,
    0
  );

  const allAllocatedApprovedTimeInMinutes = allAllocatedApprovedHours?.reduce(
    (acc, time) => acc + time?.minutes,
    0
  );

  const allAllocatedApprovedTimeInSeconds = allAllocatedApprovedTimeInMinutes
    ? +allAllocatedApprovedTimeInHours * 60 * 60 +
      +`${allAllocatedApprovedTimeInMinutes}`?.padEnd(2, "0") * 0.6 * 60
    : +allAllocatedApprovedTimeInHours * 60 * 60;

  const filterdAllocatedApprovedHours = !watchAssignees
    ? allocatedApprovedHours
    : chosenUser?.length > 0
    ? chosenUser[0]?.allocatedApprovedHours
    : "0.0";

  const [allocatedApprovedTimeinHours, allocatedApprovedTimeinMinutes] =
    (filterdAllocatedApprovedHours &&
      filterdAllocatedApprovedHours?.toString().split(".")) ||
    [];

  const totalAllocatedApprovedHoursInseconds = allocatedApprovedTimeinMinutes
    ? +allocatedApprovedTimeinHours * 60 * 60 +
      +allocatedApprovedTimeinMinutes?.padEnd(2, "0") * 0.6 * 60
    : +allocatedApprovedTimeinHours * 60 * 60;

  const totalHours =
    !watchAssignees || watchAssignees?.name !== "All"
      ? secondsToHoursConverterWithSeconds(
          +totalAllocatedApprovedHoursInseconds
        )
      : secondsToHoursConverterWithSeconds(+allAllocatedApprovedTimeInSeconds);

  /* Total Hours Calculation End */

  /* Work Log Hours and remaining hours Calculation Start */
  const workLogTotalHoursInSeconds =
    workLogHours?.projectWorkLog?.dataCollection?.reduce(
      (acc: number, curr: { durationInSeconds: number }) =>
        acc + curr?.durationInSeconds,
      0
    ) || 0;

  const remainingHours =
    watchAssignees?.name !== "All"
      ? workLogTotalHoursInSeconds > totalAllocatedApprovedHoursInseconds
        ? "00:00:00"
        : formatTime(
            Math.trunc(
              totalAllocatedApprovedHoursInseconds -
                workLogTotalHoursInSeconds || 0
            )
          )
      : watchAssignees?.name === "All"
      ? workLogTotalHoursInSeconds < allAllocatedApprovedTimeInSeconds
        ? formatTime(
            Math.trunc(
              allAllocatedApprovedTimeInSeconds - workLogTotalHoursInSeconds ||
                0
            )
          )
        : "00:00:00"
      : "00:00:00";

  const percentage =
    watchAssignees?.name === "All"
      ? (workLogTotalHoursInSeconds / allAllocatedApprovedTimeInSeconds) * 100
      : totalAllocatedApprovedHoursInseconds
      ? (workLogTotalHoursInSeconds / totalAllocatedApprovedHoursInseconds) *
        100
      : 1;

  /* Work Log Hours and remaining hours Calculation End */

  const disableTimer =
    !isAssignedToTheTask ||
    watchWorkLogDescription?.trim()?.length === 0 ||
    (assigneeUserIds?.length > 1
      ? hasAllWorkLogReadAccess && watchAssignees?.id !== userId
      : false);

  const runningProjectWorkLog = getRunningProjectWorkLog?.runningProjectWorkLog;

  const { timer, handleStart, setTimer, handleReset, isActive } =
    useStopWatch();

  useEffectOnce(() => {
    if (issueId && !isNaN(+issueId)) {
      fetchrunningProjectWorkLog({
        variables: {
          projectTaskId: +issueId,
        },
      })
        .then((response) => {
          const runningHours =
            response?.data?.runningProjectWorkLog?.runningHours;

          if (runningHours) {
            const [hours, minutes, seconds] = runningHours?.split(":");
            const timeInSeconds = Math.trunc(
              +hours * 60 * 60 + +minutes * 60 + +seconds
            );
            setTimer(timeInSeconds);
            handleStart();
          }
        })
        .catch((error) => {
          if (error.name === "AbortError") return;

          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  });

  const startProjectWorkLogHandler: SubmitHandler<{
    workLogDescription: string;
  }> = ({ workLogDescription }) => {
    if (
      issueId &&
      !isNaN(+issueId) &&
      !startProjectWorkLogLoading &&
      watchWorkLogDescription?.trim()?.length > 0
    ) {
      startProjectWorkLog({
        variables: {
          projectWorkLogInput: {
            projectTaskId: +issueId,
            description: workLogDescription,
          },
        },
      })
        .then(() => {
          refetchRunningProjectWorkLog();
          handleStart();
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const stopProjectWorkLogHandler: SubmitHandler<{
    workLogDescription: string;
  }> = ({ workLogDescription }) => {
    if (
      issueId &&
      !isNaN(+issueId) &&
      !stopProjectWorkLogLoading &&
      runningProjectWorkLog?.id
    ) {
      stopProjectWorkLog({
        variables: {
          stopProjectWorkLogInput: {
            id: runningProjectWorkLog?.id,
            description: workLogDescription,
          },
          isProfileImageNeeded: canReadUserProfileImage,
        },
      })
        .then((response) => {
          refetch();
          refetchRunningProjectWorkLog();
          handleReset();
          response?.data?.stopProjectWorkLog &&
            setTimerRunningWorkLog(response?.data?.stopProjectWorkLog);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  return (
    <div className="space-y-5">
      {hasAllWorkLogReadAccess && assigneeUserList?.length > 1 && (
        <AutoComplete
          control={runningLogControl}
          label="Assignees"
          name={"assignees"}
          options={[{ id: 0, name: "All", image: "" }, ...assigneeUserList]}
          errors={runningLogErrors}
          setValue={setValueRunningLog}
          hideError
          className="max-w-[250px]"
          renderedOption={(option, comboBoxOption) => {
            return (
              <CustomRenderUserImage
                comboBoxOption={comboBoxOption}
                option={option}
              />
            );
          }}
          defaultValue={{ id: 0, name: "All", image: "" }}
          loading={taskLoading}
        />
      )}

      <div className="border w-full">
        <div className="border-b flex justify-between p-3 items-center">
          <span>Time Tracking</span>
          {assigneeUserIds?.length > 0 && !loading ? (
            <span className="text-[12px] leading-[14px] text-[#A4A4A5]">
              {`Remaining Hours ${
                remainingHours ? remainingHours?.replaceAll(" ", "") : ""
              }`}
            </span>
          ) : (
            loading && (
              <div className="w-[200px]">
                <div className="animate-pulse px-[26px] flex space-x-4">
                  <div className="flex-1 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3"></div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <div className="p-5 space-y-6">
          {loading || lastWorkLogLoading ? (
            <ActivityLoading isImageAnimationNeeded={false} />
          ) : (
            <div className="flex justify-between items-center">
              <div className="space-y-2">
                <p className="text-[12px] leading-[14px] text-[#A4A4A5]">
                  Logged Hours
                </p>
                <p className="text-[#212224] text-[20px] leading-[30px] font-bold">
                  {(workLogTotalHoursInSeconds &&
                    formatTime(workLogTotalHoursInSeconds)?.replaceAll(
                      " ",
                      ""
                    )) ||
                    "00:00:00"}
                </p>
                <Filled
                  value={{
                    icon: (
                      <div className="flex justify-center items-center gap-2">
                        {runningProjectWorkLog ? (
                          <FaStop className="w-4 h-4 text-white" />
                        ) : (
                          <FaPlay className="w-3 h-3 text-white" />
                        )}
                        <p className="tracking-wide">
                          {isActive ? formatTime(timer) : "Start"}
                        </p>
                      </div>
                    ),
                  }}
                  loading={
                    startProjectWorkLogLoading || stopProjectWorkLogLoading
                  }
                  disabled={disableTimer}
                  onClick={handleSubmitRunningLog(
                    runningProjectWorkLog
                      ? stopProjectWorkLogHandler
                      : startProjectWorkLogHandler
                  )}
                />
              </div>
              <CircleProgress
                progress={
                  percentage < 95
                    ? percentage
                    : percentage > 95 && percentage < 100
                    ? percentage - 5
                    : percentage > 100
                    ? 100
                    : 1
                }
                filledColor="#428AF4"
                strokeWidth={5}
                unfilledColor="#ECF1FE"
                radius={50}
                content={totalHours || "00:00:00"}
              />
            </div>
          )}
          {lastWorkLogLoading ? (
            <div className="w-full">
              <div className="animate-pulse px-[26px] flex space-x-4">
                <div className="flex-1 space-y-[18px] py-1">
                  <div className="h-2 bg-slate-200 rounded"></div>
                  <div className="space-y-3"></div>
                </div>
              </div>
            </div>
          ) : (
            <form
              onSubmit={handleSubmitRunningLog(
                runningProjectWorkLog
                  ? stopProjectWorkLogHandler
                  : startProjectWorkLogHandler
              )}
            >
              <TextArea
                errors={runningLogErrors}
                label="Working on *"
                name="workLogDescription"
                register={registerRunningLog}
                setValue={setValueRunningLog}
                className="h-[70px] mb-7"
                defaultValue={
                  runningProjectWorkLog?.description ||
                  lastWorkLog?.projectWorkLog?.dataCollection?.length > 0
                    ? lastWorkLog?.projectWorkLog?.dataCollection[0]
                        ?.description
                    : ""
                }
              />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default RunningLog;
