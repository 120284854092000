import { FC } from "react";
import { Control } from "react-hook-form";

import DataCard from "components/App/UpdatedAgryApp/DataCard/DataCard";
import PaginationUpdated from "components/Pagination/UpdatedPaginationAgry/PaginationUpdated";
import { IDataModal } from "components/App/UpdatedAgryApp/DataCard/types/data-card";

import { FilterFormType } from "global/types/type";

import { ProjectGroupBugSource, ProjectGroupBugSourceEdge, ProjectGroupBugSourceFilterFormType } from "modules/Project/Pages/ProjectGroupBugSource/types";


interface Props {
  projectGroupBugSourceEdges: ProjectGroupBugSourceEdge[];
  canUpdate: boolean;
  canDelete: boolean;
  onEdit: (
    type: "create" | "update" | "delete",
    projectGroupBugSource?: ProjectGroupBugSource | null
  ) => void;
  cardOrTableClickHandler: (edge: ProjectGroupBugSourceEdge) => void;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  control: Control<FilterFormType<ProjectGroupBugSourceFilterFormType>, any>;
  totalCount: number;
}

const ProjectGroupBugSourceCard: FC<Props> = ({
  projectGroupBugSourceEdges,
  canDelete,
  canUpdate,
  onEdit,
  cardOrTableClickHandler,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  nextDisabled,
  prevDisabled,
  control,
  totalCount,
}) => {
  const deleteClickHandler: (edge: ProjectGroupBugSourceEdge) => {
    deleteAccess: boolean;
    onDeleteIconClick?: (() => void) | undefined;
  } = (edge) => {
    return {
      deleteAccess: canDelete,
      onDeleteIconClick: () => {
        canDelete && onEdit("delete", edge?.node || undefined);
      },
    };
  };

  const getDataModal = (projectGroupBugSource: ProjectGroupBugSource): IDataModal[] => {
    const { name, description, projectGroup } =
      projectGroupBugSource;

    return [
      {
        label: "Name",
        value: [
          {
            content: name,
          },
        ],
      },
      {
        label: "Project group",
        value: [
          {
            content: projectGroup && projectGroup?.length > 0 ? projectGroup?.map((projectGroup) => projectGroup?.name)?.join(", ") : "-",
          },
        ],
      },
      {
        label: "Description",
        value: [
          {
            content: description,
          },
        ],
      },
    ];
  };

  return (
    <div className="md:hidden mx-auto mt-6">
      <div className="min-h-[80vh] space-y-3">
        {projectGroupBugSourceEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <DataCard
                key={index}
                cardClass="group border"
                dataModal={getDataModal(edge?.node)}
                classNameForRow={`grid grid-cols-[110px_auto] items-center gap-2 ${
                  canUpdate ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                cardClick={() => {
                  cardOrTableClickHandler(edge);
                }}
                onDeleteIcon={deleteClickHandler(edge)}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <PaginationUpdated
        onPageSizeChange={onPageSizeChange}
        nextDisabled={nextDisabled}
        onNextPageHandler={onNextPageHandler}
        onPrevPageHandler={onPrevPageHandler}
        prevDisabled={prevDisabled}
        control={control}
        pageSizeName={"pageSize"}
        totalCount={totalCount}
      />
    </div>
  );
};

export default ProjectGroupBugSourceCard;
