import { FC, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { HiCheck } from "react-icons/hi";

import { Filled, Modal, Outlined } from "components/forms";
import Loader from "components/Loader/Loader";

import { ReactComponent as DeclineIcon } from "global/assets/images/decline-icon.svg";
import { MdOutlineClose } from "react-icons/md";
import { toastNotify } from "global/helpers/Cache";
import useAllowedResources from "global/hooks/useAllowedResources";
import fileDefault from "global/assets/images/file-blank-solid-240.png";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { Base64toObject } from "global/helpers/FileConverter";
import avatar from "global/assets/images/dummy-avatar.svg";
import { getFileExtension } from "global/helpers/getFileExtension";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import DeclinePage from "modules/Settings/Pages/Moderation/DeclinePage";
import { GET_PROFILE_MODERATION } from "modules/Employee/services/queries";
import { IModerationObj } from "modules/Employee/types/moderation";
import { UPDATE_PROFILE_MODERATION_STATUS } from "modules/Employee/services/mutations";

interface IProps {
  setShowUnderReview: Function;
  changedInformation?: (number | null)[];
  changedInformationWithType?: { id: number | null; fileType: string } | null;
  refetchQuery?: Function;
}

const UnderReviewModal: FC<IProps> = ({
  setShowUnderReview,
  changedInformation,
  changedInformationWithType,
  refetchQuery,
}) => {
  const allowedResources = useAllowedResources();

  const [fetchModeration, { data: moderations, loading }] = useLazyQuery(
    GET_PROFILE_MODERATION
  );

  useEffect(() => {
    if (changedInformationWithType) {
      fetchModeration({
        variables: {
          ids: [changedInformationWithType?.id],
        },
      });
    }

    if (changedInformation && changedInformation?.length > 0) {
      fetchModeration({
        variables: {
          ids: changedInformation,
        },
      });
    }
  }, [changedInformation, changedInformationWithType, fetchModeration]);

  const moderationArray: IModerationObj[] =
    moderations?.userProfileModerations?.dataCollection || [];

  const [showDeclinePage, setShowDeclinePage] = useState<boolean>(false);

  const [updateProfileModeration] = useMutation(
    UPDATE_PROFILE_MODERATION_STATUS
  );

  const [selectEmployee, setSelectEmployee] = useState<number>();

  const approveUserModerationHandler = (
    id?: number | undefined,
    withDraw?: string
  ) => {
    if (changedInformation?.length !== 0) {
      updateProfileModeration({
        variables: {
          ids: id
            ? [id]
            : moderationArray?.map(
                (moderation: IModerationObj) => moderation?.id
              ),
          status: withDraw ? "withdraw" : "approved",
        },
      })
        .then(() => {
          setShowUnderReview(false);
          refetchQuery && refetchQuery();
          changedInformation = [];
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
        })
        .catch((error) => toastNotify(errorMessageNotify(error)));
    }
  };

  const modalCloseHandler = () => {
    setShowUnderReview(false);
    changedInformation = [];
    changedInformationWithType = null;
  };

  return (
    <Modal>
      <div className="edit-modal-div overflow-hidden">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <div className="edit-modal-heading">Under Review</div>
          <div
            className="edit-modal-close-icon-div"
            onClick={modalCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>

        {loading ? (
          <Loader className="min-h-[169px] lg:min-h-[169px]" />
        ) : (
          <div className="max-h-[350px] lg:w-full overflow-x-auto">
            <ul>
              {moderationArray?.map(
                (moderation: IModerationObj, index: number) => {
                  return (
                    <li
                      className={`mb-[32px] ${index === 0 ? "mt-[22px]" : ""}`}
                      key={index}
                    >
                      <div className="px-[18px] lg:px-[45px] pb-3">
                        <div className="flex justify-between">
                          <p className="text-[#a0522d] text-[15px]">
                            {moderation?.label}
                          </p>
                          {allowedResources?.includes(
                            "CanApproveUserProfileModeration"
                          )
                            ? null
                            : moderationArray?.length > 1 && (
                                <button
                                  onClick={() => {
                                    approveUserModerationHandler(
                                      moderation?.id,
                                      "withdraw"
                                    );
                                  }}
                                  className="w-[90px] h-[25px] rounded-full border border-[#E9E7E7] text-ironside-gray text-sm bg-[#F8FAFD] hover:text-cornflower-blue hover:border-cornflower-blue"
                                >
                                  Withdraw
                                </button>
                              )}
                        </div>
                        <p className="flex mt-[15px] text-sm">
                          <span className="whitespace-nowrap text-ironside-gray">
                            Previous value
                          </span>
                          <span className="ml-5 text-ironside-gray">:</span>
                          <span className="whitespace-nowrap ml-[22px] text-ironside-gray">
                            {changedInformationWithType &&
                            changedInformationWithType?.fileType === "image" &&
                            moderation?.previousValue?.length <= 100 ? (
                              <img src={avatar} alt="No File" />
                            ) : (
                              changedInformationWithType && (
                                <img
                                  className="max-w-[150px] max-h-[150px]"
                                  src={`data:image/${
                                    getFileExtension(
                                      moderation?.previousValue
                                    ) === "svg"
                                      ? "svg+xml"
                                      : getFileExtension(
                                          moderation?.previousValue
                                        )
                                  };base64,${moderation?.previousValue}`}
                                  alt="No File"
                                />
                              )
                            )}

                            {changedInformation &&
                            changedInformation?.length > 0 &&
                            moderation?.label === "File path" ? (
                              moderation?.previousValue?.length <= 100 ? (
                                <img
                                  src={fileDefault}
                                  alt="No File"
                                  className="w-[25px]"
                                />
                              ) : (
                                <a
                                  href={Base64toObject(
                                    moderation?.previousValue
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={filePdf}
                                    alt="PDF"
                                    className="w-[25px]"
                                  />
                                </a>
                              )
                            ) : (
                              !changedInformationWithType &&
                              moderation?.previousValue
                            )}
                          </span>
                        </p>
                        <p className="flex mt-[15px] text-sm">
                          <span className="whitespace-nowrap text-ironside-gray">
                            New value
                          </span>
                          <span className="ml-12 text-ironside-gray">:</span>
                          <span className="whitespace-nowrap ml-[22px] text-ironside-gray">
                            {changedInformationWithType &&
                            changedInformationWithType?.fileType === "image" &&
                            moderation?.updatedValue?.length <= 100 ? (
                              <img src={avatar} alt="No File" />
                            ) : (
                              changedInformationWithType && (
                                <img
                                  className="max-w-[150px] max-h-[150px]"
                                  src={`data:image/${
                                    getFileExtension(
                                      moderation?.updatedValue
                                    ) === "svg"
                                      ? "svg+xml"
                                      : getFileExtension(
                                          moderation?.updatedValue
                                        )
                                  }};base64,${moderation?.updatedValue}`}
                                  alt="No File"
                                />
                              )
                            )}
                            {changedInformation &&
                            changedInformation?.length > 0 &&
                            moderation?.label === "File path" ? (
                              moderation?.updatedValue?.length <= 100 ? (
                                <img
                                  src={fileDefault}
                                  alt="No File"
                                  className="w-[25px]"
                                />
                              ) : (
                                <a
                                  href={Base64toObject(
                                    moderation?.updatedValue
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={filePdf}
                                    alt="PDF"
                                    className="w-[25px]"
                                  />
                                </a>
                              )
                            ) : (
                              !changedInformationWithType &&
                              moderation?.updatedValue
                            )}
                          </span>
                        </p>
                        <div
                          className={`mt-[15px] space-x-8 ${
                            allowedResources?.includes(
                              "CanApproveUserProfileModeration"
                            )
                              ? "flex"
                              : "hidden"
                          }`}
                        >
                          <button
                            onClick={() => {
                              setShowDeclinePage(true);
                              setSelectEmployee(moderation?.id);
                            }}
                            className="w-[100px] md:w-[110px] h-[32px] rounded-full border border-[#FF647C] bg-[#FFD9E0] text-ironside-gray flex justify-center items-center"
                          >
                            <DeclineIcon className="text-[#ee554f] mr-2" />
                            <span className="text-[#ee554f] text-sm">
                              Decline
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              approveUserModerationHandler(moderation?.id);
                            }}
                            className="w-[100px] md:w-[110px] h-[32px] rounded-full border border-[#3BC171] bg-[#D8FFE8] text-ironside-gray flex justify-center items-center"
                          >
                            <HiCheck className="text-[#3BC171] w-4 h-4 mr-2" />
                            <span className="text-[#3BC171] text-sm">
                              Accept
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        )}
        <div className="w-full border-t border-hit-gray pt-[6px]" />
        {allowedResources?.includes("CanApproveUserProfileModeration") ? (
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined
              onClick={() => {
                setShowDeclinePage(true);
              }}
              buttonName={"Decline"}
            />
            <Filled
              buttonName={"Accept"}
              onClick={() => {
                approveUserModerationHandler();
              }}
            />
          </div>
        ) : (
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined onClick={modalCloseHandler} buttonName={"Cancel"} />
            <Filled
              className="w-[135px] lg:w-[135px]"
              buttonName={
                loading || moderationArray?.length === 1
                  ? "Withdraw"
                  : "Withdraw All"
              }
              onClick={() => {
                approveUserModerationHandler(undefined, "withdraw");
                setShowUnderReview(false);
              }}
            />
          </div>
        )}

        {showDeclinePage && (
          <DeclinePage
            setShowDeclinePage={setShowDeclinePage}
            setShowUnderReview={setShowUnderReview}
            employeeId={
              selectEmployee
                ? [selectEmployee]
                : moderationArray?.map(
                    (moderation: IModerationObj) => moderation?.id
                  )
            }
            updateProfileModeration={updateProfileModeration}
            refetchData={refetchQuery}
          />
        )}
      </div>
    </Modal>
  );
};

export default UnderReviewModal;
