import { Fragment } from "react";
import { MdClose } from "react-icons/md";
import {
  Control,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import FilterPopUp from "components/Filter/FilterPopUp";
import { TFilterOption } from "components/Filter/types";
import Input from "components/forms/UpdatedForm/Input";

import { removedUndefinedValuesFromObject } from "global/helpers/removeUndefinedValuesFromObject";

interface IProps<FilterType extends FieldValues> {
  searchInputName: Path<FilterType>;
  className?: string;
  options: TFilterOption<FilterType>;
  control: Control<FilterType>;
  handleSubmit: UseFormHandleSubmit<FilterType>;
  submitHandler: SubmitHandler<FilterType>;
  register: UseFormRegister<FilterType>;
  watch: UseFormWatch<FilterType>;
  clearFilterHandler: () => void;
  filterName: Path<FilterType>;
  filterBtnShowHandler: () => void;
  showFilter: boolean;
  hideFilterBtn?: boolean;
  setValue: UseFormSetValue<FilterType>;
  isCloseIconNeeded?: boolean;
  hideSearchBtn?: boolean;
}

const Filter = <FilterType extends Record<string, any>>({
  register,
  searchInputName,
  className = "",
  options,
  control,
  handleSubmit,
  submitHandler,
  watch,
  clearFilterHandler,
  filterName,
  filterBtnShowHandler,
  showFilter,
  hideFilterBtn,
  setValue,
  isCloseIconNeeded,
  hideSearchBtn,
}: IProps<FilterType>) => {
  const watchValue =
    typeof watch(filterName) !== "undefined" ? watch(filterName) : {};

  const totalCount = Object.keys(
    removedUndefinedValuesFromObject(watchValue)
  )?.length;

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className={`flex gap-5 flex-wrap justify-center ${className}`}
    >
      {hideSearchBtn ? null : (
        <Input
          name={searchInputName}
          register={register}
          hideError
          label={"Search"}
          className="w-[200px]"
          setValue={setValue}
          isCloseIconNeeded={isCloseIconNeeded}
        />
      )}
      {hideFilterBtn ? null : (
        <button
          type="button"
          onClick={filterBtnShowHandler}
          className="outline-none relative min-w-[110px] lg:min-w-[120px] bg-cornflower-blue text-sm h-[45px] px-2 rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-cornflower-blue text-white"
        >
          <Fragment>
            <span>Filter</span>
            {totalCount > 0 && (
              <span className="ml-2 lg:absolute top-3.5 right-5 w-4 h-4 text-xs inline-flex justify-center items-center rounded-full bg-valentine-red text-white">
                {totalCount}
              </span>
            )}
          </Fragment>

          {totalCount === 0 ? null : (
            <span
              onClick={(e) => {
                e.stopPropagation();
                clearFilterHandler();
              }}
              className="cursor-pointer w-5 h-5 inline-flex justify-center items-center rounded-full absolute -top-2 -right-2 bg-valentine-red"
            >
              <MdClose className="w-5 h-5 " />
            </span>
          )}
        </button>
      )}
      {showFilter && (
        <FilterPopUp
          filterBtnShowHandler={filterBtnShowHandler}
          options={options}
          control={control}
          register={register}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          filterName={filterName}
          setValue={setValue}
        />
      )}
    </form>
  );
};

export default Filter;
