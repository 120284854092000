import { useState, ReactNode, useRef, Fragment, FC } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  useClick,
  arrow,
  useFloatingNodeId,
  FloatingNode,
  safePolygon,
  hide,
  Placement,
} from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import { TTrigger } from "global/types/type";

interface IProps {
  initialOpen?: boolean;
  children: ReactNode;
  render: string | number | ((data: { close: () => void }) => ReactNode);
  placement?: Placement;
  className?: string;
  arrow?: boolean;
  classForArrow?: {
    arrowVisibility?: number;
    arrowStyle?: string;
  };
  offsetNumber?: number;
  trigger?: TTrigger[];
  interaction?: boolean;
  classNameForParent?: string;
  hideToolTip?: boolean;
}

const Tooltip: FC<IProps> = ({
  initialOpen = false,
  placement = "top",
  trigger = ["hover"],
  children,
  classForArrow = {
    arrowStyle: "",
    arrowVisibility: 2,
  },
  classNameForParent,
  arrow: isArrowNeed = false,
  render,
  className,
  interaction,
  offsetNumber,
  hideToolTip,
}) => {
  const [open, setOpen] = useState<boolean>(initialOpen);
  const [arrowState, setArrowState] = useState<HTMLDivElement>(null!);

  const arrowReference = useRef<HTMLDivElement>(null!);
  const nodeId = useFloatingNodeId();
  const {
    context,
    middlewareData,
    placement: floatingUiPlacement,
    refs,
    strategy,
    x,
    y,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetNumber ? offsetNumber : 10),
      flip({
        fallbackAxisSideDirection: "start",
        fallbackPlacements: ["left", "right", "bottom", "top"],
      }),
      shift({
        padding: 20,
      }),
      hide({
        strategy: "referenceHidden",
        padding: 10,
      }),
      arrow({ element: arrowState ? arrowState : arrowReference }),
    ],
    nodeId,
  });

  const hover = useHover(context, {
    enabled: trigger?.includes("hover"),
    handleClose: interaction ? safePolygon() : undefined,
    delay: 300,
  });
  const focus = useFocus(context, {
    enabled: trigger?.includes("focus"),
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const click = useClick(context, {
    enabled: trigger?.includes("click"),
  });

  const staticSide = {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[floatingUiPlacement?.split("-")[0]];

  arrowState?.style &&
    Object.assign(arrowState?.style, {
      left:
        middlewareData?.arrow?.x != null ? `${middlewareData?.arrow?.x}px` : "",
      top:
        middlewareData?.arrow?.y != null ? `${middlewareData?.arrow?.y}px` : "",
      right: "",
      bottom: "",
      [staticSide!]: classForArrow?.arrowVisibility
        ? `-${classForArrow?.arrowVisibility}px`
        : "-2px",
    });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    click,
  ]);

  const arrowRef = useMergeRefs([setArrowState, arrowReference]);

  return (
    <Fragment>
      <div
        ref={refs?.setReference}
        {...getReferenceProps()}
        className={classNameForParent}
        onClick={(e) => {
          if (trigger?.includes("click")) {
            e.stopPropagation();
            setOpen(!open);
          }
        }}
      >
        {children}
      </div>
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <AnimatePresence>
            {open && !hideToolTip && (
              <motion.div
                ref={refs?.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.8,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                {...getFloatingProps()}
                className={`${
                  className
                    ? className
                    : "bg-storm-dust text-white rounded min-w-[70px] text-center text-xs"
                } ${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                }  absolute top-0 left-0 z-[100] p-2 max-w-[300px] break-words rounded`}
              >
                {typeof render === "string" || typeof render === "number"
                  ? render?.toString()
                  : render({
                      close: () => {
                        setOpen(false);
                      },
                    })}
                {isArrowNeed && (
                  <div
                    ref={arrowRef}
                    className={`${
                      classForArrow?.arrowStyle
                        ? classForArrow?.arrowStyle
                        : "w-3 h-3"
                    } absolute rotate-45 bg-inherit -z-50`}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    </Fragment>
  );
};

export default Tooltip;
