import { gql } from "@apollo/client";

export const PROFILE_IMAGE_FRAGMENT = gql`
  fragment profileImageFields on UserProfileImage {
    id
    type
    documents {
      file
    }
    moderation {
      filePath
    }
  }
`;

export const PERSONAL_INFORMATION_FRAGMENT = gql`
  fragment PersonalInformationFields on PersonalInformation {
    id
    name
    personalEmail
    isdCode
    mobile
    actualDateOfBirth
    officialDateOfBirth
    languagesKnown
    gender
    bloodGroup
    maritalStatus
    weddingDate
    moderation {
      name
      personalEmail
      mobile
      actualDateOfBirth
      officialDateOfBirth
      languagesKnown
      gender
      bloodGroup
      maritalStatus
      weddingDate
    }
  }
`;

export const BANK_ACCOUNT_FRAGMENT = gql`
  fragment BankAccountFields on BankAccount {
    id
    bankName
    accountNumber
    accountHolderName
    branch
    ifsc
    swiftCode
    moderation {
      bankName
      accountNumber
      branch
      accountHolderName
      ifsc
      swiftCode
    }
  }
`;

export const ID_PROOF_FRAGMENT = gql`
  fragment IdProofFields on UserIdProof {
    id
    idProofType
    idProofValue
    documents {
      file
    }
    moderation {
      idProofType
      idProofValue
      filePath
    }
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment addressFields on Address {
    id
    addressLine1
    addressLine2
    addressLine3
    city
    pinCode
    country
    state
    type
    moderation {
      addressLine1
      addressLine2
      addressLine3
      city
      pinCode
      country
      state
    }
  }
`;

export const EMERGENCY_CONTACT_FRAGMENT = gql`
  fragment emergencyContactFields on EmergencyContact {
    id
    name
    relationship
    isdCode
    mobile
    position
    moderation {
      name
      relationship
      isdCode
      mobile
    }
  }
`;

export const ACADEMIC_FRAGMENT = gql`
  fragment academicDetailFields on UserAcademicDetail {
    id
    instituteName
    affiliatedTo
    degreeType
    department
    startMonth
    startYear
    endMonth
    endYear
    percentage
    isCurrentlyPursuing
    additionalDetails
    userAcademicCertificates {
      id
      certificateName
      filePath
    }
    moderation {
      instituteName
      affiliatedTo
      degreeType
      department
      startMonth
      startYear
      endMonth
      endYear
      percentage
      isCurrentlyPursuing
      additionalDetails
      userAcademicCertificates {
        certificateId
        moderationId: moderation
      }
    }
  }
`;

export const SKILL_FRAGMENT = gql`
  fragment skillFields on UserSkill {
    id
    skill {
      id
      name
      type
    }
    userSkillCertificates {
      id
      certificateName
      documents {
        file
      }
      moderation {
        certificateName
        filePath
      }
    }
    moderation {
      skill
    }
  }
`;

export const SKILL_CERTIFICATE_FRAGMENT = gql`
  fragment skillCertificateFields on UserSkillCertificates {
    id
    certificateName
    documents {
      file
    }
    moderation {
      certificateName
      filePath
    }
  }
`;

export const FAMILY_MEMBER_FRAGMENT = gql`
  fragment familyMemberFields on FamilyMember {
    id
    relationshipType
    name
    dateOfBirth
    isdCode
    mobile
    aadhaar
    occupation
    documents {
      aadhaarFile
    }
    moderation {
      relationshipType
      name
      dateOfBirth
      isdCode
      mobile
      aadhaar
      occupation
      aadhaarFile
    }
  }
`;

export const WORK_INFORMATION_FRAGMENT = gql`
  fragment workInformationFields on EmployeeDetail {
    id
    employeeID: employeeId
    officialEmail
    employeeDesignation
    workLocation
    joiningDate
    employmentType
    isFlexibleTiming
    workStartTime
    workEndTime
    probationStartDate
    probationEndDate
    productivityRatio
    status
    relievingDate
    sourceOfHire
    canAutoApproveLeaves
  }
`;

export const WORK_EXPERIENCE_FRAGMENT = gql`
  fragment workExperienceFields on UserWorkExperience {
    id
    industry
    designation
    companyName
    employmentType
    startDate
    endDate
    additionalDetails
    isCurrent
    location
    moderation {
      designation
      companyName
      industry
      employmentType
      startDate
      endDate
      isCurrent
      location
    }
  }
`;

export const PROFILE_MODERATION_FRAGMENT = gql`
  fragment moderationFields on UserProfileModeration {
    id
    label: field
    previousValue
    updatedValue
  }
`;
