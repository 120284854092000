import { FC } from "react";
import { FieldError } from "react-hook-form";

import { cn } from "global/helpers/tailwind-utils";
import { SupportedTextAndErrorMessage as ISupportedTextAndErrorMessage } from "./UpdatedFormAgry/types";


interface Props {
	supportedTextAndErrorMessage: ISupportedTextAndErrorMessage | undefined;
	error: FieldError | undefined;
}

const SupportedTextAndErrorMessage: FC<Props> = ({
	supportedTextAndErrorMessage,
	error,
}) => {
	return supportedTextAndErrorMessage?.hide ? null : (
		<p
			className={cn(
				"w-full px-4 text-xs py-1",
				error?.message
					? "text-error visible"
					: supportedTextAndErrorMessage?.text
					  ? "visible text-on-surface-variant"
					  : "invisible",
				supportedTextAndErrorMessage?.className,
			)}
		>
			{error?.message || supportedTextAndErrorMessage?.text || "Helper text"}
		</p>
	);
};

export default SupportedTextAndErrorMessage;
