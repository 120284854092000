import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import decode from "jwt-decode";

import { SingleActionPopup } from "components/forms";
import FilePreview from "components/UI/UpdateUIAgry/FilePreview";
import FilePreviewModalUpdated from "components/FilePreviewModal/FilePreviewModalUpdated";

import {
  userContextData,
  getAllowedAccess,
  toastNotify,
  userQueryProps,
  allowedAccessVar,
  setTitle,
  getAllowedAccessUpdated,
  setShowFileOpenModal,
  filePreview,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import { clearCookies, client } from "global/services/config";
import {
  findAllowedACLIds,
  findAllowedACLIdsUpdated,
} from "global/helpers/FindACLIds";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import Header from "layouts/header/Header";
import Sidebar from "layouts/nav/Sidebar/Sidebar";
import Footer from "layouts/Footer";
import { navData } from "layouts/nav/Sidebar/NavData";
import Title from "layouts/header/Title/Title";
import Notifications from "layouts/header/TopLinks/Notification/Notifications";
import { REMOVE_DEVICE_CREDENTIAL } from "layouts/header/TopLinks/Notification/services/mutations";

import Logout from "modules/User/Logout";
import { GET_USER_DETAILS } from "modules/Employee/services/queries";
import { ACL } from "modules/Settings/services/queries";

export interface ResourcesType {
  id: string;
  isAllowed: boolean;
  title: string;
  childNodes: ResourcesType[] | [];
}

const isAuthenticated = () => {
  const sessionToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
  const token: any = localStorage.getItem("token") || sessionToken;
  // const refreshToken: any = localStorage.getItem("refreshToken");
  try {
    decode(token);
  } catch (error) {
    window.location.replace("/");
    localStorage.clear();
    clearCookies();
  }
};

const PrivateLayout = () => {
  isAuthenticated();

  const { data: aclTreeData, loading: aclTreeLoader } = useQuery(ACL, {
    variables: {
      isMyRole: true,
    },
  });

  const allowedIds = findAllowedACLIds(
    aclTreeData?.aclTree?.resourceIds?.resourceId || []
  );
  const allowedIdsUpdated = findAllowedACLIdsUpdated(
    aclTreeData?.aclTree?.resourceIds?.resourceId || []
  );

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [userDataList, setUserDataList] = useState<any>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [logoutStatus, setLogoutStatus] = useState(false);

  const [removeDeviceCredential] = useMutation(REMOVE_DEVICE_CREDENTIAL);

  const [fetchOtherUserDetails, { data: otherUser }] = useLazyQuery(
    GET_USER_DETAILS,
    {
      variables: {
        id: +sessionStorage?.otherUserId,
      },
    }
  );

  useEffect(() => {
    if (sessionStorage?.otherUserId) {
      userQueryProps({
        variables: {
          id: +sessionStorage?.otherUserId,
        },
      });
    } else {
      userQueryProps({
        variables: {
          isMyProfile: true,
        },
      });
    }
  });

  useEffect(() => {
    if (sessionStorage?.otherUserId && !otherUser?.users?.dataCollection[0]) {
      fetchOtherUserDetails({
        variables: {
          id: +sessionStorage?.otherUserId,
        },
      }).then((response: any) => {
        setUserDataList(response?.data?.users?.dataCollection[0]);
      });
    } else {
      setUserDataList(null);
    }
  }, [otherUser, fetchOtherUserDetails]);

  const path = useLocation();
  let pathName = path.pathname;

  const titleFromReactiveVar = useReactiveVar(setTitle);
  const fileData = useReactiveVar(setShowFileOpenModal);
  const filePreviewValues = useReactiveVar(filePreview);

  let title:
    | string
    | {
        name: string;
        link: string;
      }[] = "";

  const isModerationPath = pathName?.slice(0, 20) === "/settings/moderation";
  const isActionLogPath = pathName?.slice(0, 21) === "/settings/action-logs";
  const isMyProjectOrAllProjectEditPath = pathName?.split("/")[6] === "issues";
  const isMyLogs = pathName?.split("/")[2] === "my-logs";


  switch (
    `/${pathName?.split("/")?.filter((filterData: string) => filterData)[0]}`
  ) {
    case "/profile":
      if (+sessionStorage?.otherUserId) {
        title = userDataList?.personalInformation?.name
          ? `Organization / ${userDataList?.personalInformation?.name}`
          : "Organization";
      } else {
        title = "My Profile";
      }
      break;

    case "/change-password":
      title = "Change Password";
      break;

    case "/notification":
      title = "Notification";
      break;

    case "/leaves":
      const pathNamesArray = pathName?.split("/");
      title = `Leave / ${Capitalize(
        pathNamesArray?.filter((filterData: string) => filterData)[1]
      )}`;
      break;

    case "/projects":
      title = titleFromReactiveVar || "";
      break;

    default:
      navData?.map((menuName) =>
        pathName?.includes(`${menuName?.link}`) ? (title = menuName.name) : null
      );
      break;
  }
  const [hasRole, sethHasRole] = useState(false);
  const {
    data: userData,
    loading,
    refetch: refetchData,
  } = useQuery(GET_USER_DETAILS, {
    variables: {
      isMyProfile: true,
    },
    onError: (error: any) => {
      if (
        error?.networkError?.result?.message ===
        "Access denied, Role not assigned"
      ) {
        sethHasRole(true);
      } else if (error?.message?.includes("401")) {
        setSessionExpired(true);
      } else {
        toastNotify(errorMessageNotify(error));
      }
    },
  });

  useEffect(() => {
    if (sessionStorage?.otherUserId) {
      navData.map((menuName) => {
        if (pathName?.includes(`${menuName?.link}`)) {
          if (
            menuName?.link?.slice(0, 8) !== "/profile" ||
            menuName?.link?.slice(0, 10) !== "/organization"
          ) {
            sessionStorage.removeItem("otherUserId");
          }
        }
        return <></>;
      });
    }
  }, [pathName]);

  useEffect(() => {
    userContextData({
      user: userData?.users?.dataCollection[0],
      refetchData: refetchData,
    });

    getAllowedAccess({
      allowedResources: allowedIds,
    });

    getAllowedAccessUpdated(allowedIdsUpdated);

    allowedAccessVar(allowedIds || []);
  }, [allowedIds, refetchData, userData, allowedIdsUpdated]);

  let mobileNav = () => {
    setShowMenu((prevState) => !prevState);
  };

  useEffect(() => {
    if (logoutStatus) {
      const sessionNotificationId: string | any = document.cookie
        .split("; ")
        .find((row) => row.startsWith("notificationId="))
        ?.split("=")[1];
      if (localStorage?.notificationId || sessionNotificationId) {
        removeDeviceCredential({
          variables: {
            id: +localStorage?.notificationId || +sessionNotificationId,
          },
        })
          .then(() => {
            window.location.replace("/");
            localStorage.clear();
            sessionStorage.clear();
            client.clearStore();
            clearCookies();
          })
          .catch((error) => {
            window.location.replace("/");
            localStorage.clear();
            sessionStorage.clear();
            client.clearStore();
            toastNotify(errorMessageNotify(error));
            clearCookies();
          });
      } else {
        Logout();
      }
    }
  }, [logoutStatus, removeDeviceCredential]);

  const [logOutTimeCount, setLogoutTimeCount] = useState<number>(3);

  useEffect(() => {
    let interval: any;
    if (sessionExpired) {
      interval = setInterval(() => {
        setLogoutTimeCount((prevCount: number) => prevCount - 1);
      }, 1000);

      setTimeout(() => {
        Logout();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [sessionExpired]);

  return (
    <div className="bg-white md:bg-[#FAFAFB]">
      {loading || aclTreeLoader ? (
        <div className="min-h-[720px] bg-white flex justify-center items-center">
          <div className="loader-design" />
        </div>
      ) : (
        <div className="flex flex-col min-h-screen">
          <Notifications />
          <header className="sticky top-0 z-50">
            <Header
              mobileNav={mobileNav}
              setShowMenu={setShowMenu}
              showMenu={showMenu}
            />
          </header>
          <main className="lg:mt-[15px] mb-[15px]">
            {showMenu && (
              <div
                className="xl:hidden fixed inset-0 bg-transparent z-40"
                onClick={() => setShowMenu(!showMenu)}
              />
            )}
            <aside
              className={`${
                showMenu ? "ml-0" : "-ml-96 xl:ml-0"
              }  transition-all duration-1000 fixed z-50`}
            >
              <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
            </aside>

            <div className="lg:hidden block py-[10px]">
              <Title title={title} />
            </div>
            <section
              className={`${
                showMenu ? "xl:ml-[85px]" : "xl:ml-[250px]"
              } mx-auto`}
            >
              <div
                className={`${
                
                    isModerationPath ||
                      isMyProjectOrAllProjectEditPath ||
                      isMyLogs ||
                      isActionLogPath
                    ? "2xl:max-w-[1600px]"
                    : showMenu ? "2xl:w-[1400px]"
                    : "2xl:w-[1200px]"
                }  mx-auto px-2 md:px-3 lg:px-3`}
              >
                <Outlet />
              </div>
            </section>
          </main>
          {fileData?.data && fileData?.data?.length > 100 && (
            <FilePreview data={fileData} />
          )}
          {filePreviewValues?.data && (
            <FilePreviewModalUpdated data={filePreviewValues} />
          )}
          <footer className="mt-auto relative z-50">
            <Footer />
          </footer>
        </div>
      )}
      {hasRole ? (
        <SingleActionPopup
          header="Access Denied."
          onExecute={() => {
            sethHasRole(false);
          }}
          message="You are not assigned to any role. Please contact HR."
          hideButton={true}
        />
      ) : null}
      {sessionExpired ? (
        <SingleActionPopup
          header="Session Expired"
          onExecute={() => {
            setSessionExpired(false);
            setLogoutStatus(true);
          }}
          message={`Your session is expired. You will be redirected to login page in ${logOutTimeCount} seconds.`}
          buttonName="Logout"
        />
      ) : null}
    </div>
  );
};

export default PrivateLayout;
