import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { Filled, Select, Text } from "components/forms";
import { toastNotify, userQueryProps } from "global/helpers/Cache";
import { ViewDateFormat } from "global/helpers/DateFormatter";
import { RailwayTimeFormat } from "global/helpers/TimeFormat";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import { GET_WORK_INFORMATION } from "modules/Employee/services/queries";
import {
  CREATE_APPLY_LEAVE,
  UPDATE_APPLY_LEAVE,
} from "modules/Leave/services/mutations";
import {
  GET_AVAILED_RESTRICTED_LEAVES,
  HOLIDAYS_COLLECTION,
} from "modules/Leave/services/queries";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  leaveType: {
    remaining: number;
    leaveType: { id: number; isRestrictedLeave: boolean; name: string };
    year: number;
  }[];
  leaveDashboardLoader: boolean;
}

interface IRestrictedHoliday {
  leaveDate: string;
  reason: string;
}

const Restricted: React.FC<IProps> = ({ leaveType, leaveDashboardLoader }) => {
  const resourceAccess = useGetAllowedResources("ApplyLeave");
  const navigate = useNavigate();
  const { state } = useLocation();

  const leaveYear = state?.leaveYear
    ? state?.leaveYear
    : +new Date().getFullYear();

  const { data: restrictedHolidays, loading: restrictedHolidayLoading } =
    useQuery(HOLIDAYS_COLLECTION, {
      variables: {
        type: "restricted",
        holidayYear: leaveYear,
      },
    });

  const [
    fetchAvailedRestrictedLeave,
    {
      data: getAvailedRestrictedLeave,
      loading: getAvailedRestrictedLeaveLoading,
    },
  ] = useLazyQuery(GET_AVAILED_RESTRICTED_LEAVES);

  const restrictedLeaveType:
    | {
        id: number;
        isRestrictedHolidayLeave: boolean;
        name: string;
      }
    | any = leaveType && leaveType?.length > 0 && leaveType[0].leaveType;

  const remainingLeave: number | any = leaveType?.find(
    (restrictedLeave: { year: number }) => restrictedLeave?.year === leaveYear
  )?.remaining;

  const queryProps = useReactiveVar(userQueryProps);

  const { data: userDetails } = useQuery(GET_WORK_INFORMATION, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const optionalHolidays = restrictedHolidays?.holidays?.dataCollection;

  const [createApplyLeave, { loading: createApplyLeaveLoading }] =
    useMutation(CREATE_APPLY_LEAVE);
  const [updateApplyLeave, { loading: updateApplyLeaveLoading }] =
    useMutation(UPDATE_APPLY_LEAVE);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IRestrictedHoliday>();

  const convertHolidayDateFormat = (
    year: number,
    month: number,
    date: number
  ) => {
    return new Date(
      `${year}-${`${month}`?.padStart(2, "0")}-${`${date}`?.padStart(2, "0")}`
    );
  };

  const [holidayIdsAndReasons, setHolidayIdsAndReasons] = useState<any>();

  useEffect(() => {
    if (remainingLeave <= 0 && leaveType?.length > 0) {
      fetchAvailedRestrictedLeave({
        variables: {
          leaveType: leaveType[0]?.leaveType?.id,
          status: ["Approved"],
        },
      });
    }
  }, [remainingLeave, leaveType, fetchAvailedRestrictedLeave]);

  /* Getting the holiday dates which are not passed yet.
  (Dates that are greater than today && time greater than today's work time) */

  useEffect(() => {
    setHolidayIdsAndReasons(
      optionalHolidays
        ?.filter(
          (holiday: {
            holidayYear: number;
            holidayMonth: number;
            holidayDate: number;
          }) => {
            const holidayDate = convertHolidayDateFormat(
              holiday?.holidayYear,
              holiday?.holidayMonth,
              holiday?.holidayDate
            );

            let workStartTime: string =
              (userDetails?.users?.dataCollection[0] &&
                userDetails?.users?.dataCollection[0]?.employeeDetail
                  ?.workStartTime) ||
              null;

            const workHour = RailwayTimeFormat(workStartTime)?.slice(0, 2);
            const workMinutes = +workStartTime?.slice(3, 5);
            const currentHour = +new Date().getHours().toString();
            const currentMinutes = +new Date().getMinutes().toString();

            return (
              holidayDate > new Date() ||
              (new Date(holidayDate).toString()?.slice(0, 15) ===
                new Date().toString().slice(0, 15) &&
                (+workHour > currentHour ||
                  (+workHour === currentHour && workMinutes >= currentMinutes)))
            );
          }
        )
        ?.map(
          (holiday: {
            id: number;
            reason: string;
            holidayYear: number;
            holidayMonth: number;
            holidayDate: number;
            weekday: string;
          }) => {
            return {
              id: holiday?.id,
              name: holiday?.reason,
              holidayYear: holiday?.holidayYear,
              holidayMonth: holiday?.holidayMonth,
              holidayDate: holiday?.holidayDate,
              weekday: holiday?.weekday,
            };
          }
        )
    );
  }, [optionalHolidays, userDetails]);

  const defaultOptionalHoliday =
    holidayIdsAndReasons &&
    holidayIdsAndReasons.length > 0 &&
    holidayIdsAndReasons[0];

  const defaultOptionalHolidayValue: {
    date: string | undefined;
    name: string;
  } = {
    date: ViewDateFormat(
      convertHolidayDateFormat(
        defaultOptionalHoliday?.holidayYear,
        defaultOptionalHoliday?.holidayMonth,
        defaultOptionalHoliday?.holidayDate
      )
    ),
    name: defaultOptionalHoliday?.name,
  };

  const [holidayReason, setHolidayReason] = useState<string>(
    defaultOptionalHolidayValue?.name
  );

  useEffect(() => {
    setHolidayReason(
      state?.reason
        ? state?.reason
        : defaultOptionalHoliday && defaultOptionalHoliday?.name
    );
  }, [defaultOptionalHoliday, state?.reason]);

  useEffect(() => {
    setValue("reason", holidayReason);

    const selectedLeave = holidayIdsAndReasons?.filter(
      (holiday: { name: string }) => holiday?.name === holidayReason
    );

    const selectedLeaveDate =
      selectedLeave &&
      ViewDateFormat(
        convertHolidayDateFormat(
          selectedLeave[0]?.holidayYear,
          selectedLeave[0]?.holidayMonth,
          selectedLeave[0]?.holidayDate
        )
      );
    setValue(
      "leaveDate",
      selectedLeaveDate
        ? `${selectedLeaveDate} ${`(${
            selectedLeave &&
            selectedLeave?.length > 0 &&
            selectedLeave[0]?.weekday
          })`}`
        : ""
    );
  }, [holidayReason, setValue, holidayIdsAndReasons]);

  const restrictedLeaveReasons =
    getAvailedRestrictedLeave?.filterMyLeaveRequests?.dataCollection
      ?.map((leave: { reason: string }) => leave?.reason)
      ?.join() || "";

  const createLeaveHandler: SubmitHandler<IRestrictedHoliday> = ({
    leaveDate,
    reason,
  }) => {
    if (!createApplyLeaveLoading) {
      createApplyLeave({
        variables: {
          leaveRequestDates: [
            {
              leaveType: restrictedLeaveType?.id,
              leaveDate: leaveDate?.slice(0, 11),
              session: "Full Day",
            },
          ],
          reason: reason,
        },
      })
        .then(() => {
          navigate("/leaves/requests/my-requests");
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  const upadateLeaveHandler: SubmitHandler<IRestrictedHoliday> = ({
    leaveDate,
    reason,
  }) => {
    if (!updateApplyLeaveLoading) {
      updateApplyLeave({
        variables: {
          id: state && state?.id,
          leaveRequestDates: [
            {
              id: state?.dates[0]?.id,
              leaveType: restrictedLeaveType?.id,
              leaveDate: leaveDate?.slice(0, 11),
            },
          ],
          reason: reason,
        },
      })
        .then(() => {
          navigate("/leaves/requests/my-requests");
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <div className="mx-auto py-[40px] px-3 sm:px-0">
      {restrictedHolidayLoading ||
      leaveDashboardLoader ||
      getAvailedRestrictedLeaveLoading ? (
        <div className="min-h-[500px] flex justify-center items-center">
          <div className="loader-design" />
        </div>
      ) : holidayIdsAndReasons?.length === 0 ? (
        <div className="w-full h-[68vh] flex justify-center items-center border">
          <p className="text-sm text-warm-gray">
            No more restricted holidays available for this year.
          </p>
        </div>
      ) : remainingLeave <= 0 || !restrictedLeaveType ? (
        <div className="w-full h-[68vh] flex justify-center items-center border">
          <p className="text-sm text-warm-gray">
            {`${
              remainingLeave <= 0
                ? `${
                    restrictedLeaveReasons
                      ? `You have already availed all restricted holidays (${restrictedLeaveReasons}) for this year.`
                      : "You have already availed all restricted holidays for this year."
                  }`
                : "You are not assigned to any restricted leave. Please contact HR."
            }`}
          </p>
        </div>
      ) : (
        <div>
          <form
            className="space-y-2"
            onSubmit={handleSubmit(
              state?.id ? upadateLeaveHandler : createLeaveHandler
            )}
          >
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">
                Leave Type
              </p>
              <Text
                register={register}
                name={"leaveType"}
                errors={errors}
                label={"Leave Type *"}
                defaultText={
                  state?.id
                    ? state?.dates && state?.dates[0]?.leaveType?.name
                    : leaveType
                    ? restrictedLeaveType?.name
                    : ""
                }
                disable={true}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">Holiday</p>
              <Select
                register={register}
                errors={errors}
                label="Holiday *"
                options={holidayIdsAndReasons?.map(
                  (holiday: { name: string }) => holiday?.name
                )}
                defaultSelectValue={defaultOptionalHolidayValue?.name}
                name="reason"
                onChange={(event: any) => {
                  setHolidayReason(event.target.value);
                }}
                required={true}
                disableDefaultSelectOption
              />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-[150px_400px] items-center">
              <p className="text-sm text-ironside-gray sm:pb-[21px]">
                Leave Date
              </p>
              <Text
                register={register}
                name={"leaveDate"}
                errors={errors}
                label={"Leave Date"}
                defaultText={
                  state?.id ? "" : defaultOptionalHolidayValue?.date || ""
                }
                disable={true}
              />
            </div>

            {(resourceAccess?.canCreate || resourceAccess?.canUpdate) && (
              <Filled
                className={"mt-8 sm:ml-[166px]"}
                buttonType="submit"
                buttonName={"Submit"}
                loading={createApplyLeaveLoading}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Restricted;
