import { gql } from "@apollo/client";

export const CREATE_ROLE = gql`
  mutation createRole(
    $roleName: String!
    $description: String
    $isActive: Boolean!
    $allowedResourceIds: [String!]
  ) {
    createRole(
      roleName: $roleName
      description: $description
      isActive: $isActive
      allowedResourceIds: $allowedResourceIds
    ) {
      roleName
      description
      isActive
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole(
    $id: Int!
    $roleName: String
    $description: String
    $isActive: Boolean
    $allowedResourceIds: [String]
  ) {
    updateRole(
      id: $id
      roleName: $roleName
      description: $description
      isActive: $isActive
      allowedResourceIds: $allowedResourceIds
    ) {
      id
      roleName
      description
      isActive
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($id: Int!) {
    deleteRole(id: $id)
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($userId: Int!, $roleId: Int!) {
    updateUserRole(userId: $userId, roleId: $roleId)
  }
`;

export const UPDATE_MULTIPLE_USER_PROFILE_MODERATIONS = gql`
  mutation updateMultipleUserProfileModerations(
    $ids: [Int]!
    $status: String!
    $declineReason: String
  ) {
    updateMultipleUserProfileModerations(
      ids: $ids
      status: $status
      declineReason: $declineReason
    ) {
      id
      updatedAt
      field
      previousValue
      updatedValue
      status
      declineReason
    }
  }
`;
