import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Role from "./ACL/Role/Role";
import RoleResources from "./ACL/Role/RoleResources";
import EditRole from "./ACL/Role/EditRole";
import RoleAllocation from "./ACL/RoleAllocation/RoleAllocation";
import { useReactiveVar } from "@apollo/client";
import { getAllowedAccess } from "global/helpers/Cache";
import ActionLogs from "./ActionLogs/ActionLogs";
import ModerationRouteControl from "../Routes/ModerationRouteControl";
import { useRef } from "react";

const Settings = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const { pathname: pathName } = useLocation();

  return (
    <div className="min-h-[760px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-2 md:px-[23px] bg-white">
      <div
        ref={slider}
        onMouseDown={(e) => {
          isDown = true;
          startX = e?.pageX - slider.current?.offsetLeft;
          scrollLeft = slider?.current?.scrollLeft;
        }}
        onMouseLeave={() => {
          isDown = false;
        }}
        onMouseUp={() => {
          isDown = false;
        }}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider?.current?.offsetLeft;
          const walk = (x - startX) * 1;
          slider.current.scrollLeft = scrollLeft - walk;
        }}
        className="tab-nav"
      >
        {allowedResources?.includes("Role") &&
        allowedResources?.includes("ReadRole") ? (
          <NavLink
            className={({ isActive }) =>
              `tab-nav-item ${
                (isActive && pathName === "/settings/role") ||
                pathName?.slice(0, 19) === "/settings/role/edit"
                  ? "active"
                  : ""
              }`
            }
            to={"/settings/role"}
          >
            Roles
          </NavLink>
        ) : null}
        {allowedResources?.includes("RoleAllocation") ? (
          <NavLink
            className={({ isActive }) =>
              `tab-nav-item ${isActive ? "active" : ""}`
            }
            to={"/settings/role-allocation"}
          >
            Role Allocations
          </NavLink>
        ) : null}
        {allowedResources?.includes("UserProfileModeration") &&
        allowedResources?.includes("ReadUserProfileModeration") ? (
          <NavLink
            className={({ isActive }) =>
              `tab-nav-item ${isActive ? "active" : ""}`
            }
            to={"/settings/moderation"}
          >
            Moderations
          </NavLink>
        ) : null}

        {allowedResources?.includes("ActionLog") &&
        allowedResources?.includes("ReadActionLog") ? (
          <NavLink
            className={({ isActive }) =>
              `tab-nav-item ${isActive ? "active" : ""}`
            }
            to={"/settings/action-logs"}
          >
            Action Logs
          </NavLink>
        ) : null}
      </div>

      <Routes>
        <Route
          path="role"
          element={<Role allowedResources={allowedResources} />}
        />
        <Route path="role/edit-role" element={<EditRole />} />
        <Route
          path="role/edit-role/role-resources"
          element={<RoleResources />}
        />
        <Route
          path="role-allocation"
          element={<RoleAllocation allowedResources={allowedResources} />}
        />
        <Route path="moderation/*" element={<ModerationRouteControl />} />
        <Route path="action-logs" element={<ActionLogs />} />
      </Routes>
    </div>
  );
};

export default Settings;
