import { useReactiveVar } from "@apollo/client";

import { allowedAccessVar } from "global/helpers/Cache";

const useAllowedResources = () => {
  const allowedResources: any = useReactiveVar(allowedAccessVar);

  return allowedResources;
};

export default useAllowedResources;
