import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Filled, Modal, Outlined } from "components/forms";

import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import {
  IProjectVaultCredentialTypeFields,
  IProjectVaultCredentialTypeFieldsForm,
} from "modules/Project/types/project";
import { CREATE_OR_UPDATE_VAULT_CREDENTIAL_TYPE_FIELD } from "modules/Project/services/mutations";
import Input from "components/forms/UpdatedForm/Input";
import Select from "components/forms/UpdatedForm/Select";

const EditVaulCredentialTypeFields: React.FC<{
  editProjectVaultCredentialTypeField: IProjectVaultCredentialTypeFields | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectVaultCredentialTypeFields: Function;
}> = ({
  editProjectVaultCredentialTypeField,
  setShowModal,
  loading,
  refetchProjectVaultCredentialTypeFields,
}) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);

  const allowedResources = allowedResourcesList?.allowedResources || [];

  const createUpdateAccess = allowedResources?.includes(
    "SaveProjectVaultCredentialType"
  );
  const [
    saveProjectCredentialTypeField,
    { loading: projectVaultCredentialTypeFieldLoading },
  ] = useMutation(CREATE_OR_UPDATE_VAULT_CREDENTIAL_TYPE_FIELD);

  const { id } = useParams();

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IProjectVaultCredentialTypeFieldsForm>();

  const createOrUpdateProjectVaultCredentialTypeField: SubmitHandler<
    IProjectVaultCredentialTypeFieldsForm
  > = ({ name, fieldType }) => {
    if (!projectVaultCredentialTypeFieldLoading) {
      saveProjectCredentialTypeField({
        variables: {
          projectCredentialInput: {
            id: editProjectVaultCredentialTypeField?.id || undefined,
            type: Number(id),
            name,
            fieldType,
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          refetchProjectVaultCredentialTypeFields();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectVaultCredentialTypeField?.id
                  ? editProjectVaultCredentialTypeField?.name
                  : response?.data?.saveProjectCredentialTypeField?.name
              } credential type has been successfully ${
                editProjectVaultCredentialTypeField?.id ? "updated" : "added"
              } `,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  useEffect(() => {
    if (editProjectVaultCredentialTypeField) {
      setValue("name", editProjectVaultCredentialTypeField?.name || "");
    }
  }, [editProjectVaultCredentialTypeField, setValue]);

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">
            Vault Credential Type Field
          </h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(
                createOrUpdateProjectVaultCredentialTypeField
              )}
            >
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  required
                  defaultValue={editProjectVaultCredentialTypeField?.name}
                  setValue={setValue}
                />
                <Select
                  control={control}
                  errors={errors}
                  label="Field Type *"
                  name="fieldType"
                  options={["Text", "URL", "File", "Text Area", "Password"]}
                  setValue={setValue}
                  defaultValue={editProjectVaultCredentialTypeField?.fieldType}
                  required
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={
                    editProjectVaultCredentialTypeField?.id ? "Update" : "Add"
                  }
                  loading={projectVaultCredentialTypeFieldLoading}
                  disabled={!createUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditVaulCredentialTypeFields;
