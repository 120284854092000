import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!
    $personalEmail: String!
    $isdCode: String
    $mobile: String!
    $password: String!
    $userType: String!
  ) {
    createUser(
      name: $name
      personalEmail: $personalEmail
      isdCode: $isdCode
      mobile: $mobile
      password: $password
      userType: $userType
    ) {
      id
      personalInformation {
        id
        name
        personalEmail
        isEmailVerified
        isdCode
        mobile
        isMobileVerified
        isActive
        status
        isBlocked
      }
      userType
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`;

export const OTP_REQUEST = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

export const OTP_VERIFICATION = gql`
  mutation userVerify($email: String!, $otp: Int!) {
    userVerify(email: $email, otp: $otp) {
      confirmationCode
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation resetPassword(
    $email: String!
    $confirmationCode: String!
    $newPassword: String!
  ) {
    resetPassword(
      email: $email
      confirmationCode: $confirmationCode
      newPassword: $newPassword
    )
  }
`;
