import { useState, FC } from "react";
import { HiOutlinePaperClip } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import Tooltip from "components/UI/ToolTip";

import { toastNotify } from "global/helpers/Cache";
import {
  fileToBase64,
  getFileExtensionUsingName,
} from "global/helpers/FileConverter";
import { ISetStateType } from "global/types/type";

import { IFileProperties } from "modules/Employee/types/academic";
import { SAVE_PROJECT_TASK_FILE_ATTACHMENTS } from "modules/Project/services/mutations";
import Button from "components/forms/Button/Button";

interface IProps {
  setRefetchFileAttachments: ISetStateType<boolean>;
}

const AddTaskFileAttachment: FC<IProps> = ({ setRefetchFileAttachments }) => {
  const [
    saveProjectTaskFileAttachments,
    { loading: projectTaskFileAttachmentLoading },
  ] = useMutation(SAVE_PROJECT_TASK_FILE_ATTACHMENTS);

  const {
    register: registerTaskAttachments,
    handleSubmit: handleSubmitTaskAttachments,
  } = useForm<{
    taskTitle: string;
    file: IFileProperties;
  }>();

  const { id, issueId } = useParams();

  const [file, setFile] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<IFileProperties | null>(null);

  const onUploadFileChange = (event: any) => {
    if (!event?.target?.validity?.valid) {
      return toastNotify([
        {
          messageType: "error",
          message: "Please upload a valid file.",
        },
      ]);
    } else {
      fileToBase64(event.target.files[0], (err: any, result: any) => {
        if (result) {
          setFile(result);
          setFileDetails(event.target.files[0]);
          event.target.value = "";
        }
      });
    }
  };

  const cacelButtonHandler = () => {
    setFile(null);
    setFileDetails(null);
  };

  const addFileHanlder = () => {
    if (
      !projectTaskFileAttachmentLoading &&
      issueId &&
      id &&
      !isNaN(+issueId) &&
      !isNaN(+id)
    ) {
      saveProjectTaskFileAttachments({
        variables: {
          projectTaskInput: {
            id: +issueId || undefined,
            projectId: +id,
            projectTaskAttachments: file && [
              {
                filePath: file?.split(";base64,")[1],
                name: fileDetails?.name,
                extension:
                  getFileExtensionUsingName(fileDetails?.name || "")?.length > 0
                    ? "." + getFileExtensionUsingName(fileDetails?.name || "")
                    : undefined,
                mimeType: fileDetails?.type
                  ? fileDetails?.type?.toString()?.length > 0
                    ? fileDetails?.type
                    : "text/plain"
                  : "text/plain",
              },
            ],
          },
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "File added successfully.",
            },
          ]);
          setRefetchFileAttachments(true);
          setFile(null);
          setFileDetails(null);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  return (
    <form
      className="mb-4"
      onSubmit={handleSubmitTaskAttachments(addFileHanlder)}
    >
      <label htmlFor={"file"} className="flex w-min items-center gap-3">
        <Tooltip
          render="Attach"
          arrow
          trigger={["hover"]}
          classNameForParent="w-min"
        >
          <div
            className={`cursor-pointer flex w-[90px] ${
              file ? "mb-5" : "mb-8"
            } items-center gap-1 px-2 py-1 bg-[#f6f6f6]`}
          >
            <HiOutlinePaperClip className="w-4 h-4" />
            <span>Attach</span>
          </div>
        </Tooltip>
        <div className="mb-5 truncate cursor-pointer">
          <Tooltip render={fileDetails?.name || ""} arrow>
            {fileDetails?.name}
          </Tooltip>
        </div>
      </label>
      <input
        type="file"
        id={"file"}
        className="hidden"
        {...registerTaskAttachments("file", {
          onChange: (event: any) => {
            onUploadFileChange(event);
          },
        })}
      />
      {file && (
        <div className="flex gap-3 mb-4">
          <Button
            className="min-w-[100px] whitespace-nowrap min-h-[42px]"
            variant="monochrome"
            onClick={cacelButtonHandler}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="min-w-[100px] whitespace-nowrap min-h-[42px]"
            variant="primary"
            loading={projectTaskFileAttachmentLoading}
            disabled={!file}
          >
            Save
          </Button>
        </div>
      )}
    </form>
  );
};

export default AddTaskFileAttachment;
