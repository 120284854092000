import { FC } from "react";
import { useForm } from "react-hook-form";
import { HiOutlinePaperClip } from "react-icons/hi";

import { toastNotify } from "global/helpers/Cache";
import { fileToBase64 } from "global/helpers/FileConverter";

interface IProps {
  setFileList: Function;
}

const AddCommentFile: FC<IProps> = ({ setFileList }) => {
  const { register } = useForm();

  const onUploadFileChange = (event: any) => {
    if (event?.target?.files[0]?.size > 100e6) {
      return toastNotify([
        {
          messageType: "error",
          message: "Maximum file size limit is 2 MB.",
        },
      ]);
    } else if (!event?.target?.validity?.valid) {
      return toastNotify([
        {
          messageType: "error",
          message: "Please upload a valid file.",
        },
      ]);
    } else {
      fileToBase64(event.target.files[0], (err: any, result: any) => {
        if (result) {
          setFileList((prev: any) => [
            ...prev,
            { fileDetails: event.target.files[0], file: result },
          ]);
          event.target.value = "";
        }
      });
    }
  };

  return (
    <div>
      <label htmlFor="fileInput">
        <HiOutlinePaperClip className={`w-[22px] h-[22px] cursor-pointer`} />
      </label>
      <input
        type="file"
        id="fileInput"
        className="hidden"
        {...register("fileInput", {
          onChange: (event: any) => {
            onUploadFileChange(event);
          },
        })}
      />
    </div>
  );
};

export default AddCommentFile;
