import { useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { Filled, Modal, Outlined } from "components/forms";

import { toastNotify, userContextData } from "global/helpers/Cache";

import {
  IProjectTaskLabel,
  IProjectTaskLabelForm,
} from "modules/Project/types/project";
import { CREATE_OR_UPDATE_PROJECT_TASK_LABEL } from "modules/Project/services/mutations";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";

const EditTaskLabel: React.FC<{
  editProjectStatus: IProjectTaskLabel | null;
  setShowModal: Function;
  loading: boolean;
  refetchProjectTaskStatus: Function;
  allowedResourcesForProject: string[];
  editProject: { id: number; name: string };
  isManager: boolean;
}> = ({
  editProjectStatus,
  setShowModal,
  loading,
  refetchProjectTaskStatus,
  allowedResourcesForProject,
  editProject,
  isManager,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;
  const createOrUpdateAccess =
    allowedResourcesForProject?.includes("SaveProjectTask") ||
    isSuperAdmin ||
    isManager;

  const [saveProjectTaskLabel, { loading: projecTaskStatusLoading }] =
    useMutation(CREATE_OR_UPDATE_PROJECT_TASK_LABEL);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IProjectTaskLabelForm>();

  const createOrUpdateProjectTaskStatus: SubmitHandler<
    IProjectTaskLabelForm
  > = ({ name, description }) => {
    if (!projecTaskStatusLoading) {
      saveProjectTaskLabel({
        variables: {
          projectTaskLabelInput: {
            id: editProjectStatus?.id || undefined,
            name,
            projectId: editProject?.id,
            description: editProjectStatus?.id
              ? description
              : description || undefined,
          },
        },
      })
        .then((response) => {
          setShowModal(false);
          refetchProjectTaskStatus();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `Project label ${
                editProjectStatus?.id
                  ? editProjectStatus?.name
                  : response?.data?.saveProjectTaskStatus?.name
              } has been successfully ${
                editProjectStatus?.id ? "updated" : "added"
              } `,
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Task Label</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectTaskStatus)}>
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Input
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  required
                  defaultValue={editProjectStatus?.name}
                  setValue={setValue}
                />
                <TextArea
                  errors={errors}
                  label="Description "
                  name="description"
                  register={register}
                  setValue={setValue}
                  defaultValue={editProjectStatus?.description}
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={editProjectStatus?.id ? "Update" : "Add"}
                  loading={projecTaskStatusLoading}
                  disabled={!createOrUpdateAccess}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditTaskLabel;
