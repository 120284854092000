import { useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import DataCard from "components/App/Card/DataCard";

import { userQueryProps } from "global/helpers/Cache";

import EditWorkInfo from "modules/Employee/Pages/Profile/Work/WorkInformation/EditWorkInformation";
import { GET_WORK_INFORMATION } from "modules/Employee/services/queries";
import { makeDataModelArray } from "modules/Employee/helpers/make-data-model-array";
import { Filled } from "components/forms";
import useAllowedResources from "global/hooks/useAllowedResources";

const WorkInformation = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const { loading, data: userDetails } = useQuery(GET_WORK_INFORMATION, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const employeeDetails =
    (userDetails?.users?.dataCollection[0] &&
      userDetails?.users?.dataCollection[0]?.employeeDetail) ||
    null;

  const allowedResources = useAllowedResources();
  const createAccess = allowedResources?.includes(
    "CreateEmployeeWorkInformation"
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  const removeInformationModelItems = ["id", "moderation", "__typename"];

  if (!employeeDetails?.relievingDate) {
    removeInformationModelItems.push("relievingDate");
  }

  const employeeInformationModel = makeDataModelArray(
    employeeDetails,
    removeInformationModelItems
  );

  return (
    <div className="h-min">
      <DataCard
        loading={loading}
        resourceName="EmployeeWorkInformation"
        setShowModal={setShowModal}
        title="Work Information"
        dataModel={employeeInformationModel}
        showPencilIcon={
          !createAccess || employeeInformationModel?.length === 0 ? false : true
        }
        children={
          employeeInformationModel?.length === 0 && createAccess ? (
            <div className="w-full min-h-[300px] grid place-content-center">
              <Filled
                buttonName={"Add"}
                buttonType="button"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          ) : (
            employeeInformationModel?.length === 0 && (
              <p className="text-sm text-black/60 w-full inline-flex justify-center items-center min-h-[300px]">
                Please contact HR to add work information.
              </p>
            )
          )
        }
      />

      {showModal && (
        <EditWorkInfo
          employeeDetails={employeeDetails}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default WorkInformation;
