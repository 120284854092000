import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { Route, Routes, useLocation } from "react-router-dom";

import { getAllowedAccess } from "global/helpers/Cache";
import {
	findAllowedACLIds,
	findAllowedACLIdsUpdated,
} from "global/helpers/FindACLIds";

import VaultCredentialTypeFields from "modules/Project/Pages/VaultCredentialTypesAndFields/VaultCredentialTypeFields/VaultCredentialTypeFields";
import ProjectSettingsRouterControl from "modules/Project/Pages/ProjectSettingsRouterControl";
import { PROJECT_ACL } from "modules/Project/services/queries";
import EditIssues from "modules/Project/Pages/EditIssues/EditIssues";
import IssueLogs from "modules/Project/Pages/Issues/IssueLogs/IssueLogs";
import ProjectGroups from "modules/Project/Pages/Projects/ProjectGroups/ProjectGroups";
import MyProjectAllProjectRouteGroupBugTasksRouteControlForProjectGroup from "modules/Project/Pages/MyProjectAllProjectRouteGroupBugTasksRouteControlForProjectGroup";
import MyProjectAllProjectRouteGroupBugTasksRouteControl from "modules/Project/Pages/MyProjectAllProjectRouteGroupBugTasksRouteControl";
import ProjectsGeneralAndVaultRouterControlForProjectGroups from "./ProjectsGeneralAndVaultRouterControlForProjectGroups";
import ProjectsGeneralAndVaultRouterControl from "./ProjectsGeneralAndVaultRouterControl";

const ProjectsRouterControl = () => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];

	const { pathname } = useLocation();
	const isMyProjectPathName = pathname?.split("/")[3];

	const [loadProjectRoleItemQuery, { data: projectRoleItemQuery }] =
		useLazyQuery(PROJECT_ACL, {
			fetchPolicy: "no-cache",
		});

	const allowedResourcesForProject = findAllowedACLIds(
		projectRoleItemQuery?.projectAclTree?.resourceIds?.resourceId || [],
	);

	const allowedResourcesForProjectUpdated = findAllowedACLIdsUpdated(
		projectRoleItemQuery?.projectAclTree?.resourceIds?.resourceId || [],
	);

	return (
		<Routes>
			<Route path="/groups" element={<ProjectGroups />} />
			<Route
				path=":groupId/*"
				element={
					<MyProjectAllProjectRouteGroupBugTasksRouteControlForProjectGroup
						allowedResources={allowedResources}
					/>
				}
			/>
			<Route
				path="projects/*"
				element={
					<MyProjectAllProjectRouteGroupBugTasksRouteControl
						allowedResources={allowedResources}
					/>
				}
			/>
			<Route
				path=":groupId/:projectType/edit/:id/*"
				element={
					<ProjectsGeneralAndVaultRouterControlForProjectGroups
						loadProjectRoleItemQuery={loadProjectRoleItemQuery}
						allowedResourcesForProject={allowedResourcesForProject || []}
						allowedResources={allowedResources}
						allowedResourcesForProjectUpdated={
							allowedResourcesForProjectUpdated
						}
					/>
				}
			/>
			<Route
				path="projects/:projectType/edit/:id/*"
				element={
					<ProjectsGeneralAndVaultRouterControl
						loadProjectRoleItemQuery={loadProjectRoleItemQuery}
						allowedResourcesForProject={allowedResourcesForProject || []}
						allowedResources={allowedResources}
						allowedResourcesForProjectUpdated={
							allowedResourcesForProjectUpdated
						}
					/>
				}
			/>
			<Route path="settings/*" element={<ProjectSettingsRouterControl />} />
			<Route
				path="/edit/:id/vault-types-input/*"
				element={<VaultCredentialTypeFields />}
			/>
			<Route
				path={`:groupId/${isMyProjectPathName}/edit/:id/issues/:issueId`}
				element={<EditIssues />}
			/>
			<Route
				path={`:groupId/${isMyProjectPathName}/edit/:id/backlog/:backlogId`}
				element={<EditIssues />}
			/>
			<Route
				path={`projects/${isMyProjectPathName}/edit/:id/issues/:issueId`}
				element={<EditIssues />}
			/>
			<Route
				path={`:groupId/${isMyProjectPathName}/edit/:id/issue-details/:issueId`}
				element={<IssueLogs />}
			/>
			<Route
				path={`projects/${isMyProjectPathName}/edit/:id/issue-details/:issueId`}
				element={<IssueLogs />}
			/>
		</Routes>
	);
};

export default ProjectsRouterControl;
