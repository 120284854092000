import { FC, Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SubMenuDetails from "./SubMenuDetails";
import NavLinkDetails from "./NavLinkDetails";
import { removeDuplicates } from "global/helpers/ArrayMethods";

interface IProps {
  index: number;
  menu: any;
  navLink: string | null;
  title: string;
  showMenu: any;
  resources: string[];
  activeLink: number | null;
  setActiveLink: Function;
  setShowMenu: Function;
}

const MenuDetails: FC<IProps> = ({
  index,
  menu,
  navLink,
  title,
  showMenu,
  resources,
  activeLink,
  setActiveLink,
  setShowMenu,
}) => {
  const { pathname } = useLocation();
  const [showSubMenu, setShowSubMenu] = useState(
    pathname.split("/")[1] === "leaves" ? true : false
  );

  const handleActiveLink = (index: number) => {
    setActiveLink(index);
  };

  useEffect(() => {
    if (!showMenu) {
      setShowSubMenu(false);
    }
  }, [showMenu]);

  return (
    <Fragment>
      <div
        className="relative"
        onClick={() => {
          handleActiveLink(index);
        }}
      >
        {navLink === null ? (
          <div className="cursor-pointer">
            <NavLinkDetails
              menu={menu}
              title={title}
              showSubMenu={showSubMenu}
              setShowSubMenu={setShowSubMenu}
              showMenu={showMenu}
              activeLink={activeLink}
              index={index}
              setShowMenu={setShowMenu}
            />
          </div>
        ) : (
          <NavLink to={navLink}>
            <NavLinkDetails
              menu={menu}
              title={title}
              showSubMenu={showSubMenu}
              setShowSubMenu={setShowSubMenu}
              showMenu={showMenu}
              activeLink={activeLink}
              index={index}
              setShowMenu={setShowMenu}
            />
          </NavLink>
        )}
      </div>
      {showSubMenu && menu?.subMenus?.length > 0 ? (
        <div
          className={`flex flex-col relative overflow-hidden ${
            showSubMenu
              ? "h-auto [transition:max-height_1.5s_ease-in-out]"
              : "h-0 [transition:max-height_1s_cubic-bezier(0,1,0,1)]"
          } ${
            showMenu ? "2xl:hidden sm:block" : "2xl:block lg:block sm:hidden"
          } xl:group-hover:block w-full`}
        >
          {menu?.subMenus?.map((subMenu: any, index: number) => {
            const allowedSubMenuArray: string[] = [];

            if (subMenu.subMenuIDs && subMenu?.subMenuIDs?.length > 0) {
              for (let i = 0; i < subMenu?.subMenuIDs?.length; i++) {
                if (resources?.includes(subMenu?.subMenuIDs[i])) {
                  allowedSubMenuArray.push(
                    ...allowedSubMenuArray,
                    subMenu?.subMenuName
                  );
                }
              }
            } else {
              allowedSubMenuArray.push(
                ...allowedSubMenuArray,
                subMenu?.subMenuName
              );
            }

            return (
              <SubMenuDetails
                key={index}
                allowedSubMenuArray={removeDuplicates(allowedSubMenuArray)}
                subMenu={subMenu}
                setShowMenu={setShowMenu}
                menu={menu}
                title={title}
                showMenu={showMenu}
              />
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );
};

export default MenuDetails;
