import { FC } from "react";
import { Control } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { HiPencil } from "react-icons/hi";

import DataCard from "components/App/UpdatedAgryApp/DataCard/DataCard";
import PaginationUpdated from "components/Pagination/UpdatedPaginationAgry/PaginationUpdated";
import { IDataModal } from "components/App/UpdatedAgryApp/DataCard/types/data-card";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

import { FilterFormType } from "global/types/type";

import {
  ProjectGroup,
  ProjectGroupEdge,
  ProjectGroupFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
  projectGroupEdges: ProjectGroupEdge[];
  canUpdate: boolean;
  canDelete: boolean;
  onEdit: (
    type: "create" | "update" | "delete",
    projectGroup?: ProjectGroup | null
  ) => void;
  cardOrTableClickHandler: (projectGroupId: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  control: Control<FilterFormType<ProjectGroupFilterFormType>, any>;
  totalCount: number;
  canReadProjectsOrGroupBugsOrTasks: boolean;
}

const ProjectGroupCard: FC<Props> = ({
  projectGroupEdges,
  canDelete,
  canUpdate,
  onEdit,
  cardOrTableClickHandler,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  nextDisabled,
  prevDisabled,
  control,
  totalCount,
  canReadProjectsOrGroupBugsOrTasks,
}) => {
  const getDataModal = (projectGroup: ProjectGroup): IDataModal[] => {
    const { name, description } = projectGroup;
    return [
      {
        label: "Name",
        value: [
          {
            content: name,
          },
        ],
      },
      {
        label: "Description",
        value: [
          {
            content: description,
          },
        ],
      },
    ];
  };

  return (
    <div className="md:hidden mx-auto mt-6">
      <div className="min-h-[80vh] space-y-3">
        {projectGroupEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <DataCard
                key={index}
                cardClass="group border"
                dataModal={getDataModal(edge?.node)}
                classNameForRow={`grid grid-cols-[110px_auto] items-center gap-2 ${
                  canReadProjectsOrGroupBugsOrTasks
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                cardClick={() => {
                  edge?.node?.id &&
                    canReadProjectsOrGroupBugsOrTasks &&
                    cardOrTableClickHandler(edge?.node?.id);
                }}
                otherOption={
                  ((canDelete || canUpdate) && (
                    <div className="flex justify-center items-center gap-2">
                      {canUpdate && (
                        <ToolTip
                          render={() => "Edit"}
                          arrow
                          className={"text-center bg-[#616161] text-white"}
                        >
                          <HiPencil
                            onClick={(e) => {
                              e.stopPropagation();
                              edge?.node && onEdit("update", edge?.node);
                            }}
                            className="w-5 h-5 cursor-pointer text-ironside-gray/70"
                          />
                        </ToolTip>
                      )}
                      {canDelete && (
                        <ToolTip
                          render={() => "Delete"}
                          arrow
                          className={"text-center bg-[#616161] text-white"}
                        >
                          <BiTrash
                            className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              edge?.node && onEdit("delete", edge?.node);
                            }}
                          />
                        </ToolTip>
                      )}
                    </div>
                  )) ||
                  undefined
                }
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <PaginationUpdated
        onPageSizeChange={onPageSizeChange}
        nextDisabled={nextDisabled}
        onNextPageHandler={onNextPageHandler}
        onPrevPageHandler={onPrevPageHandler}
        prevDisabled={prevDisabled}
        control={control}
        pageSizeName={"pageSize"}
        totalCount={totalCount}
      />
    </div>
  );
};

export default ProjectGroupCard;
