import { DateFormat } from "global/helpers/DateFormatter";

export const getDateArray = (start: any, end: any) => {
  const arr: any = [];
  const startDate: Date | any = start ? new Date(start) : null;
  if (end && startDate) {
    while (startDate <= end) {
      arr.push(new Date(startDate).toString().substring(0, 15));
      startDate.setDate(startDate.getDate() + 1);
    }
  }
  return arr;
};

// ["YYYY-MM-DD"] -  input: (new Data(), new Date())
export const getDateFormatArray = (start: any, end: any) => {
  const arr: any = [];
  const dt = new Date(start);
  while (dt <= end) {
    arr.push(DateFormat(new Date(dt).toString().substring(0, 15)));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

export const prepareDateArray = (dtArr: any) => {
  const arr: any[] = [];
  for (let i = 0; i < dtArr?.length; i++) {
    arr.push(new Date(dtArr[i]).toString().substring(0, 15));
  }
  return arr;
};

export const getMonths = (fromDate: any, toDate: any) => {
  const fromYear = fromDate.getFullYear();
  const fromMonth = fromDate.getMonth();
  const toYear = toDate.getFullYear();
  const toMonth = toDate.getMonth();
  const months: any[] = [];
  for (let year = fromYear; year <= toYear; year++) {
    let month = year === fromYear ? fromMonth : 0;
    const monthLimit = year === toYear ? toMonth : 11;
    for (; month <= monthLimit; month++) {
      months.push({ year, month });
    }
  }
  return months;
};

export const getSaturdays = (year: any, month: any) => {
  let day, date;
  let saturdays: any[] = [];
  day = 1;
  date = new Date(year, month, day);
  while (date.getMonth() === month) {
    if (date.getDay() === 6) {
      saturdays.push(new Date(year, month, day));
    }
    day += 1;
    date = new Date(year, month, day);
  }
  return saturdays;
};

export const datehash: any = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const stateDateHash: any = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};
