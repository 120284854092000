import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import {
  Filled,
  Mobile,
  Modal,
  Name,
  Outlined,
  Select,
} from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import {
  createSuccessMessage,
  createSuccessMessageWithAwaiting,
  updateSuccessMessage,
  updateSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
  GET_EMERGENCY_CONTACTS,
  EMERGENCY_CONTACT_RELATIONSHIP,
} from "modules/Employee/services/queries";
import {
  CREATE_EMERGENCY_CONTACT,
  UPDATE_EMERGENCY_CONTACT,
} from "modules/Employee/services/mutations";
import {
  IEmergencyContactForm,
  IEmergencyContact,
} from "modules/Employee/types/contacts";
import ModerationField from "modules/Employee/Pages/Profile/Moderation/ModerationField";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  sortedEmergencyContact: IEmergencyContact[];
  editID: number | null;
  setEditID: Function;
  setShowModal: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetchEmergencyContatcts: Function;
}

const EditEmergencyContact: FC<IProps> = ({
  sortedEmergencyContact,
  editID,
  setEditID,
  setShowModal,
  inProgress,
  setInProgress,
  refetchEmergencyContatcts,
}) => {
  const emergencyContacts = sortedEmergencyContact?.filter(
    (contact: IEmergencyContact) => contact?.id === editID
  )[0];

  const { data: relationships, loading: relationshipsLoader } = useQuery(
    EMERGENCY_CONTACT_RELATIONSHIP
  );

  const moderationFieldProps = {
    refetchQuery: refetchEmergencyContatcts,
  };

  const moderationObj = emergencyContacts?.moderation || null;

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IEmergencyContactForm>();

  useEffect(() => {
    if (emergencyContacts?.name) {
      setValue("name", emergencyContacts?.name);
    }
    if (emergencyContacts?.relationship) {
      setValue("relationship", emergencyContacts?.relationship);
    }
    if (emergencyContacts?.mobile) {
      setValue("mobile", +emergencyContacts?.mobile);
    }
  });

  const [createUserEmergencyContact] = useMutation(CREATE_EMERGENCY_CONTACT);
  const [updateUserEmergencyContact] = useMutation(UPDATE_EMERGENCY_CONTACT);

  const isUnderModeration = useIsUnderModeration();

  const createActionSuccesMessage = isUnderModeration
    ? createSuccessMessage("Emergency contact")
    : createSuccessMessageWithAwaiting("Emergency contact");

  const updateActionSuccessMessage = isUnderModeration
    ? updateSuccessMessage("Emergency Contact")
    : updateSuccessMessageWithAwaiting("Emergency contact");

  const createHandler = (data: IEmergencyContactForm) => {
    if (!inProgress) {
      createUserEmergencyContact({
        variables: {
          userId: sessionStorage?.otherUserId
            ? +sessionStorage?.otherUserId
            : undefined,
          name: data.name.trim() || null,
          isdCode: data.country || null,
          relationship: data.relationship || null,
          mobile: data.mobile.toString() || null,
        },
        update: (cache, { data: { createUserEmergencyContact } }) => {
          const exEmergencyContact: any = cache.readQuery({
            query: GET_EMERGENCY_CONTACTS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });

          const updatedEmergencyContacts = [
            ...exEmergencyContact?.users?.dataCollection[0]
              ?.userEmergencyContacts,
            createUserEmergencyContact,
          ];

          cache.writeQuery({
            query: GET_EMERGENCY_CONTACTS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exEmergencyContact?.users?.dataCollection[0],
                    userEmergencyContacts: updatedEmergencyContacts,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(createActionSuccesMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: IEmergencyContactForm) => {
    if (!inProgress) {
      updateUserEmergencyContact({
        variables: {
          id: emergencyContacts?.id,
          name: data.name.trim() || null,
          isdCode: data.country || null,
          relationship: data.relationship || null,
          mobile: data.mobile.toString() || null,
        },
        update: (cache, { data: { updateUserEmergencyContact } }) => {
          const exEmergencyContact: any = cache.readQuery({
            query: GET_EMERGENCY_CONTACTS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
          });

          const updatedEmergencyContacts =
            exEmergencyContact?.users?.dataCollection[0]?.userEmergencyContacts?.map(
              (emergencyContact: IEmergencyContact) => {
                if (emergencyContact?.id === emergencyContacts?.id) {
                  return {
                    ...emergencyContact,
                    isdCode: updateUserEmergencyContact?.isdCode,
                    mobile: updateUserEmergencyContact?.mobile,
                    moderation: updateUserEmergencyContact?.moderation,
                    name: updateUserEmergencyContact?.name,
                    position: updateUserEmergencyContact?.position,
                    relationship: updateUserEmergencyContact?.relationship,
                  };
                }
                return emergencyContact;
              }
            );

          cache.writeQuery({
            query: GET_EMERGENCY_CONTACTS,
            variables: {
              isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
              id: +sessionStorage?.otherUserId
                ? +sessionStorage?.otherUserId
                : undefined,
            },
            data: {
              users: {
                dataCollection: [
                  {
                    ...exEmergencyContact?.users?.dataCollection[0],
                    userEmergencyContacts: updatedEmergencyContacts,
                  },
                ],
              },
            },
          });
        },
      })
        .then(() => {
          moduleCloseHandler();
          toastNotify(updateActionSuccessMessage);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify(errorMessageNotify(error));
        });
      setInProgress(!inProgress);
    }
  };

  const moduleCloseHandler = () => {
    setInProgress(false);
    setShowModal(false);
    reset();
    setEditID(null);
  };

  return (
    <Modal
      onClick={() => {
        reset();
        setEditID(null);
      }}
    >
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px] boder-hit-gray">
          <h2 className="edit-modal-heading">Emergency Contacts</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={moduleCloseHandler}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[14px] h-[14px]" />
          </div>
        </div>
        <div>
          {relationshipsLoader ? (
            <div className="min-h-[344px] lg:min-h-[350px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              onSubmit={
                emergencyContacts?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="pr-[35px] form-div lg:pr-[45px]">
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.name}
                >
                  <Name
                    register={register}
                    errors={errors}
                    label="Name *"
                    name="name"
                    required={true}
                    defaultName={emergencyContacts?.name}
                    disable={!!moderationObj?.name}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.relationship}
                >
                  <Select
                    register={register}
                    errors={errors}
                    label="Relationship *"
                    name="relationship"
                    options={relationships?.getEmergencyContactRelationships}
                    required={true}
                    defaultSelectValue={emergencyContacts?.relationship}
                    disable={!!moderationObj?.relationship}
                  />
                </ModerationField>
                <ModerationField
                  {...moderationFieldProps}
                  moderationId={moderationObj?.mobile}
                >
                  <Mobile
                    register={register}
                    errors={errors}
                    defaultMobile={+emergencyContacts?.mobile || undefined}
                    defaultCountryCode={emergencyContacts?.isdCode}
                    required={true}
                    label={"Mobile *"}
                    disabled={!!moderationObj?.mobile}
                  />
                </ModerationField>
              </div>
              <div className="w-full border-t border-hit-gray mt-[6px]" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined buttonName="Cancel" onClick={moduleCloseHandler} />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : emergencyContacts?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditEmergencyContact;
