import { useNavigate, Link } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

const SignupAs = () => {
  const navigate = useNavigate();

  const clientClickHandler = () => {
    navigate("/signup", { state: { selectUser: false } });
  };

  const candidateClickHandler = () => {
    navigate("/signup", { state: { selectUser: true } });
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="p-10 rounded-lg md:shadow-[0px_0px_34px_#9BBCFF29] flex flex-col items-center gap-10">
        <h2 className="text-lg text-black/70">Sign up as?</h2>
        <div className="flex items-center gap-10">
          <button
            type="submit"
            className="btn-signup px-[30px]"
            onClick={candidateClickHandler}
          >
            Candidate
          </button>
          <button
            type="submit"
            className="btn-signup px-[42px]"
            onClick={clientClickHandler}
          >
            Client
          </button>
        </div>
        <p className="text-black/70 text-sm">
          Already have an account?{" "}
          <Link to={"/"} className="text-cornflower-blue">
            Log In
          </Link>
        </p>
      </div>
      <HiArrowNarrowLeft
        className="back-arrow-icon"
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default SignupAs;
