import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { getToken } from "firebase-config";
import { CREATE_DEVICE_CREDENTIAL } from "layouts/header/TopLinks/Notification/services/mutations";


const Notifications = () => {
  const [createDeviceCredential] = useMutation(CREATE_DEVICE_CREDENTIAL);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notificationToken, setNotificationToken] = useState(null);

  useEffect(() => {
    let currentToken;

    const tokenFunc = async () => {
      currentToken = await getToken(setTokenFound);
      if (currentToken || isTokenFound) {
        setNotificationToken(currentToken);
      }
      return currentToken;
    };
    tokenFunc();
  }, [isTokenFound, setTokenFound]);



  useEffect(() => {
    const sessionNotificationId = document.cookie
    .split("; ")
    .find((row) => row.startsWith("notificationId="))
    ?.split("=")[1];
    if (
      notificationToken &&
      (!localStorage?.notificationId && !sessionNotificationId)
    ) {
      const sessionId = document.cookie
      .split("; ")
      .find((row) => row.startsWith("id="))
      ?.split("=")[1];
    
      createDeviceCredential({
        variables: {
          userId: +localStorage.id || +sessionId,
          token: notificationToken ? notificationToken : "",
        },
      })
        .then((response) => {
          if (+localStorage.id) {
            localStorage.notificationId =
              response?.data?.createDeviceCredential?.id;
          } else if (+sessionId) {
            document.cookie = `notificationId=${response?.data?.createDeviceCredential?.id}; Secure;`
          }
        })
        .catch((error) => console.error("Error:", error.message));
    }
  }, [notificationToken, createDeviceCredential]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
