import MyTeamMembersDetails from "../MyTeamInfo/MyTeamMembersDetails";

interface IProps {
  teamMembers: any;
  userId: number | undefined;
  setUserId: Function;
}

const MyTeamsDetailsGrid: React.FC<IProps> = ({
  teamMembers,
  userId,
  setUserId,
}) => {
  return (
    <tr className="hover:bg-white-smoke border-b border-white-smoke text-sm last:border-none even:bg-slate-200/20">
      <td className="py-5 px-3 max-w-[200px] truncate">{teamMembers?.name}</td>
      <td className="py-5 px-3 max-w-[200px] truncate">{teamMembers?.code}</td>
      <td className="px-3 max-w-[200px] truncate">
        {teamMembers?.members?.map((memberDetail: any, index: number) => (
          <MyTeamMembersDetails
            key={index}
            className="-left-24 sm:left-0"
            index={index}
            teamMembers={teamMembers}
            memberDetails={memberDetail}
            userId={userId}
            setUserId={setUserId}
          />
        ))}
      </td>
    </tr>
  );
};

export default MyTeamsDetailsGrid;
