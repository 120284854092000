import { FC, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { Control } from "react-hook-form";

import TableContent from "components/App/UpdatedAgryApp/Table/TableContent";
import TableWithCursorPagination from "components/App/UpdatedAgryApp/Table/TableWithCursorPagination";
import { TTableContent } from "components/App/UpdatedAgryApp/Table/types/Table";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

import { FilterFormType } from "global/types/type";

import { ProjectGroupBugSource, ProjectGroupBugSourceEdge, ProjectGroupBugSourceFilterFormType } from "modules/Project/Pages/ProjectGroupBugSource/types";

interface Props {
  canUpdate: boolean;
  canDelete: boolean;
  nextDisabled: boolean;
  control: Control<FilterFormType<ProjectGroupBugSourceFilterFormType>, any>;
  prevDisabled: boolean;
  totalCount: number;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  cardOrTableClickHandler: (edge: ProjectGroupBugSourceEdge) => void;
  projectGroupBugSourceEdges: ProjectGroupBugSourceEdge[];
  onEdit: (
    type: "create" | "update" | "delete",
    projectGroupBugSource?: ProjectGroupBugSource | null
  ) => void;
}

const tableCommonHeadings = [
  "Name",
  "Project group",
  "Description",
];

const ProjectGroupBugSourceTable: FC<Props> = ({
  canDelete,
  canUpdate,
  nextDisabled,
  control,
  prevDisabled,
  totalCount,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  cardOrTableClickHandler,
  projectGroupBugSourceEdges,
  onEdit,
}) => {
  const tableHeadings = canDelete
    ? [...tableCommonHeadings, "Action"]
    : tableCommonHeadings;

  const tableRef = useRef<HTMLDivElement>(null);

  const getTableContent = (
    projectGroupBugSource: ProjectGroupBugSource
  ): TTableContent[] => {

    const { name, description, projectGroup } =
      projectGroupBugSource;

    return [
      { value: name },
      { value: projectGroup && projectGroup?.length > 0 ? projectGroup?.map((projectGroup) => projectGroup?.name)?.join(", ") :  "-" },
      {
        value: description,
      },
      {
        value: (
          <div className="flex justify-center">
            <ToolTip
              render={() => "Delete"}
              arrow
              classNameForParent="truncate"
            >
              <BiTrash
                className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  projectGroupBugSource && onEdit("delete", projectGroupBugSource);
                }}
              />
            </ToolTip>
          </div>
        ),
        hideContent: !canDelete,
      },
    ];
  };

  return (
    <div className="hidden md:block mt-6">
      <TableWithCursorPagination
        className="min-h-[80vh]"
        onPagination={{
          control: control,
          nextDisabled,
          onNextPageHandler,
          onPageSizeChange,
          onPrevPageHandler,
          pageSizeName: "pageSize",
          prevDisabled,
          totalCount,
        }}
        tableHeadings={{
          tableString: tableHeadings,
        }}
        ref={tableRef}
        isCenterLastHeading={canDelete ? true : false}
      >
        {projectGroupBugSourceEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <TableContent
                key={index}
                tableRef={tableRef}
                tableContent={getTableContent(edge?.node)}
                tableRowClick={() => {
                  cardOrTableClickHandler(edge);
                }}
                classForTableRow={`${
                  canUpdate ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              />
            );
          } else {
            return null;
          }
        })}
      </TableWithCursorPagination>
    </div>
  );
};

export default ProjectGroupBugSourceTable;
