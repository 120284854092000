import { gql } from "@apollo/client";

import {
  PERSONAL_INFORMATION_FRAGMENT,
  BANK_ACCOUNT_FRAGMENT,
  ID_PROOF_FRAGMENT,
  ADDRESS_FRAGMENT,
  EMERGENCY_CONTACT_FRAGMENT,
  PROFILE_MODERATION_FRAGMENT,
  ACADEMIC_FRAGMENT,
  SKILL_FRAGMENT,
  SKILL_CERTIFICATE_FRAGMENT,
  FAMILY_MEMBER_FRAGMENT,
  WORK_INFORMATION_FRAGMENT,
  WORK_EXPERIENCE_FRAGMENT,
  PROFILE_IMAGE_FRAGMENT,
} from "modules/Employee/services/fragments";

export const UPDATE_PERSONAL_INFO = gql`
  ${PERSONAL_INFORMATION_FRAGMENT}

  mutation updatePersonalInfo(
    $id: Int
    $name: String
    $personalEmail: String
    $isdCode: String
    $mobile: String
    $actualDateOfBirth: String
    $officialDateOfBirth: String
    $languagesKnown: [String]
    $gender: String
    $bloodGroup: String
    $maritalStatus: String
    $weddingDate: String
  ) {
    updateUser(
      id: $id
      name: $name
      personalEmail: $personalEmail
      isdCode: $isdCode
      mobile: $mobile
      actualDateOfBirth: $actualDateOfBirth
      officialDateOfBirth: $officialDateOfBirth
      languagesKnown: $languagesKnown
      gender: $gender
      bloodGroup: $bloodGroup
      maritalStatus: $maritalStatus
      weddingDate: $weddingDate
    ) {
      id
      personalInformation {
        ...PersonalInformationFields
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  ${BANK_ACCOUNT_FRAGMENT}

  mutation createUserBankAccount(
    $userId: Int
    $bankName: String
    $accountNumber: String
    $branch: String
    $accountHolderName: String
    $ifsc: String
    $swiftCode: String
  ) {
    createUserBankAccount(
      userId: $userId
      bankName: $bankName
      accountNumber: $accountNumber
      branch: $branch
      accountHolderName: $accountHolderName
      ifsc: $ifsc
      swiftCode: $swiftCode
    ) {
      ...BankAccountFields
    }
  }
`;

export const UPDATE_BANK_ACCOUNT = gql`
  ${BANK_ACCOUNT_FRAGMENT}

  mutation updateUserBankAccount(
    $id: Int!
    $bankName: String
    $accountNumber: String
    $branch: String
    $accountHolderName: String
    $ifsc: String
    $swiftCode: String
  ) {
    updateUserBankAccount(
      id: $id
      bankName: $bankName
      accountNumber: $accountNumber
      branch: $branch
      accountHolderName: $accountHolderName
      ifsc: $ifsc
      swiftCode: $swiftCode
    ) {
      ...BankAccountFields
    }
  }
`;

export const CREATE_ID_PROOF = gql`
  ${ID_PROOF_FRAGMENT}

  mutation createUserIdProof(
    $userId: Int
    $idProofType: String
    $idProofValue: String
    $documents: [createUserIdProofDocument]
  ) {
    createUserIdProof(
      userId: $userId
      idProofType: $idProofType
      idProofValue: $idProofValue
      documents: $documents
    ) {
      ...IdProofFields
    }
  }
`;

export const UPDATE_ID_PROOF = gql`
  ${ID_PROOF_FRAGMENT}
  mutation updateUserIdProof(
    $id: Int!
    $idProofValue: String
    $documents: [updateUserIdProofDocument]
  ) {
    updateUserIdProof(
      id: $id
      idProofValue: $idProofValue
      documents: $documents
    ) {
      ...IdProofFields
    }
  }
`;

export const DELETE_ID_PROOF = gql`
  mutation deleteUserIdProof($id: Int!) {
    deleteUserIdProof(id: $id)
  }
`;

export const CREATE_ADDRESS = gql`
  ${ADDRESS_FRAGMENT}

  mutation createUserAddress(
    $userId: Int
    $stateId: Int
    $countryId: Int
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $city: String
    $pinCode: String
    $type: String!
  ) {
    createUserAddress(
      userId: $userId
      stateId: $stateId
      countryId: $countryId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      pinCode: $pinCode
      type: $type
    ) {
      ...addressFields
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  ${ADDRESS_FRAGMENT}

  mutation updateUserAddress(
    $id: Int!
    $stateId: Int
    $countryId: Int
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $city: String
    $pinCode: String
    $type: String
  ) {
    updateUserAddress(
      id: $id
      stateId: $stateId
      countryId: $countryId
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      pinCode: $pinCode
      type: $type
    ) {
      ...addressFields
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation deleteUserAddress($id: Int!) {
    deleteUserAddress(id: $id)
  }
`;

export const CREATE_EMERGENCY_CONTACT = gql`
  ${EMERGENCY_CONTACT_FRAGMENT}

  mutation createUserEmergencyContact(
    $userId: Int
    $name: String
    $relationship: String
    $isdCode: String
    $mobile: String
  ) {
    createUserEmergencyContact(
      userId: $userId
      name: $name
      relationship: $relationship
      isdCode: $isdCode
      mobile: $mobile
    ) {
      ...emergencyContactFields
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  ${EMERGENCY_CONTACT_FRAGMENT}

  mutation updateUserEmergencyContact(
    $id: Int!
    $name: String
    $relationship: String
    $isdCode: String
    $mobile: String
  ) {
    updateUserEmergencyContact(
      id: $id
      name: $name
      relationship: $relationship
      isdCode: $isdCode
      mobile: $mobile
    ) {
      ...emergencyContactFields
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT_POSITION = gql`
  ${EMERGENCY_CONTACT_FRAGMENT}

  mutation updateEmergencyContactPosition($id: Int!, $position: Int!) {
    updateEmergencyContactPosition(id: $id, position: $position) {
      ...emergencyContactFields
    }
  }
`;

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation deleteUserEmergencyContact($id: Int!) {
    deleteUserEmergencyContact(id: $id)
  }
`;

export const CREATE_ACADEMIC_DETAILS = gql`
  ${ACADEMIC_FRAGMENT}

  mutation createUserAcademicDetail(
    $userId: Int
    $instituteName: String
    $affiliatedTo: String
    $isCurrentlyPursuing: Boolean
    $degreeType: String
    $department: String
    $startMonth: String
    $startYear: Int
    $endMonth: String
    $endYear: Int
    $percentage: Float
    $additionalDetails: String
    $userAcademicCertificates: [createUserAcademicCertificates]
  ) {
    createUserAcademicDetail(
      userId: $userId
      instituteName: $instituteName
      affiliatedTo: $affiliatedTo
      isCurrentlyPursuing: $isCurrentlyPursuing
      degreeType: $degreeType
      department: $department
      startMonth: $startMonth
      startYear: $startYear
      endMonth: $endMonth
      endYear: $endYear
      percentage: $percentage
      additionalDetails: $additionalDetails
      userAcademicCertificates: $userAcademicCertificates
    ) {
      ...academicDetailFields
    }
  }
`;

export const UPDATE_ACADEMIC_DETAILS = gql`
  ${ACADEMIC_FRAGMENT}

  mutation updateUserAcademicDetail(
    $id: Int!
    $instituteName: String
    $affiliatedTo: String
    $degreeType: String
    $department: String
    $startMonth: String
    $startYear: Int
    $endMonth: String
    $endYear: Int
    $percentage: Float
    $additionalDetails: String
    $userAcademicCertificates: [updateUserAcademicCertificates]
    $isCurrentlyPursuing: Boolean
    $userAcademicCertificateIdsToDelete: [Int]
  ) {
    updateUserAcademicDetail(
      id: $id
      instituteName: $instituteName
      affiliatedTo: $affiliatedTo
      degreeType: $degreeType
      department: $department
      startMonth: $startMonth
      startYear: $startYear
      endMonth: $endMonth
      endYear: $endYear
      percentage: $percentage
      additionalDetails: $additionalDetails
      userAcademicCertificates: $userAcademicCertificates
      isCurrentlyPursuing: $isCurrentlyPursuing
      userAcademicCertificateIdsToDelete: $userAcademicCertificateIdsToDelete
    ) {
      ...academicDetailFields
    }
  }
`;

export const DELETE_ACADEMIC_DETAILS = gql`
  mutation deleteUserAcademicDetail($id: Int!) {
    deleteUserAcademicDetail(id: $id)
  }
`;

export const CREATE_SKILL = gql`
  ${SKILL_FRAGMENT}

  mutation createUserSkill($userId: Int, $skillIds: [Int]) {
    createUserSkill(userId: $userId, skillIds: $skillIds) {
      ...skillFields
    }
  }
`;

export const UPDATE_SKILL = gql`
  ${SKILL_FRAGMENT}

  mutation updateUserSkill(
    $userId: Int
    $skillIdsToAdd: [Int]
    $skillIdsToRemove: [Int]
  ) {
    updateUserSkill(
      userId: $userId
      skillIdsToAdd: $skillIdsToAdd
      skillIdsToRemove: $skillIdsToRemove
    ) {
      ...skillFields
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation deleteUserSkill($userId: Int, $skillIds: [Int], $type: String!) {
    deleteUserSkill(userId: $userId, skillIds: $skillIds, type: $type)
  }
`;

export const CREATE_SKILL_CERTIFICATE = gql`
  ${SKILL_CERTIFICATE_FRAGMENT}

  mutation createUserSkillCertificate(
    $userSkillId: Int!
    $certificateName: String
    $documents: [createUserSkillCertificateDocument]
  ) {
    createUserSkillCertificate(
      userSkillId: $userSkillId
      certificateName: $certificateName
      documents: $documents
    ) {
      ...skillCertificateFields
    }
  }
`;

export const UPDATE_SKILL_CERTIFICATE = gql`
  ${SKILL_CERTIFICATE_FRAGMENT}

  mutation updateUserSkillCertificate(
    $id: Int!
    $userSkillId: Int
    $certificateName: String
    $documents: [updateUserSkillCertificateDocument]
  ) {
    updateUserSkillCertificate(
      id: $id
      userSkillId: $userSkillId
      certificateName: $certificateName
      documents: $documents
    ) {
      ...skillCertificateFields
    }
  }
`;

export const DELETE_SKILL_CERTIFICATE = gql`
  mutation deleteUserSkillCertificate($id: Int!) {
    deleteUserSkillCertificate(id: $id)
  }
`;

export const CREATE_FAMILY_MEMBER = gql`
  ${FAMILY_MEMBER_FRAGMENT}

  mutation createUserFamilyMember(
    $userId: Int
    $relationshipType: String
    $name: String
    $dateOfBirth: String
    $isdCode: String
    $mobile: String
    $aadhaar: String
    $occupation: String
    $documents: [createUserFamilyMemberDocument]
  ) {
    createUserFamilyMember(
      userId: $userId
      relationshipType: $relationshipType
      name: $name
      dateOfBirth: $dateOfBirth
      isdCode: $isdCode
      mobile: $mobile
      aadhaar: $aadhaar
      occupation: $occupation
      documents: $documents
    ) {
      ...familyMemberFields
    }
  }
`;

export const UPDATE_FAMILY_MEMBER = gql`
  ${FAMILY_MEMBER_FRAGMENT}

  mutation updateUserFamilyMember(
    $id: Int!
    $relationshipType: String
    $name: String
    $dateOfBirth: String
    $isdCode: String
    $mobile: String
    $aadhaar: String
    $occupation: String
    $documents: [updateUserFamilyMemberDocument]
  ) {
    updateUserFamilyMember(
      id: $id
      relationshipType: $relationshipType
      name: $name
      dateOfBirth: $dateOfBirth
      isdCode: $isdCode
      mobile: $mobile
      aadhaar: $aadhaar
      occupation: $occupation
      documents: $documents
    ) {
      ...familyMemberFields
    }
  }
`;

export const DELETE_FAMILY_MEMBER = gql`
  mutation deleteUserFamilyMember($id: Int!) {
    deleteUserFamilyMember(id: $id)
  }
`;

export const CREATE_EMPLOYEE_DETAILS = gql`
  ${WORK_INFORMATION_FRAGMENT}

  mutation createEmployeeDetail(
    $userId: Int!
    $officialEmail: String!
    $status: String!
    $workLocation: String
    $isFlexibleTiming: Boolean
    $workStartTime: String!
    $workEndTime: String!
    $joiningDate: String!
    $employmentType: String!
    $probationStartDate: String!
    $probationEndDate: String!
    $productivityRatio: Float!
    $canAutoApproveLeaves: Boolean
    $employeeDesignation: String!
    $sourceOfHire: String!
  ) {
    createEmployeeDetail(
      userId: $userId
      officialEmail: $officialEmail
      status: $status
      workLocation: $workLocation
      isFlexibleTiming: $isFlexibleTiming
      workStartTime: $workStartTime
      workEndTime: $workEndTime
      joiningDate: $joiningDate
      employmentType: $employmentType
      probationStartDate: $probationStartDate
      probationEndDate: $probationEndDate
      productivityRatio: $productivityRatio
      canAutoApproveLeaves: $canAutoApproveLeaves
      employeeDesignation: $employeeDesignation
      sourceOfHire: $sourceOfHire
    ) {
      ...workInformationFields
    }
  }
`;

export const UPDATE_EMPLOYEE_DETAILS = gql`
  ${WORK_INFORMATION_FRAGMENT}

  mutation updateEmployeeDetail(
    $id: Int!
    $officialEmail: String
    $status: String
    $workLocation: String
    $isFlexibleTiming: Boolean
    $workStartTime: String
    $workEndTime: String
    $joiningDate: String
    $employmentType: String
    $probationStartDate: String
    $probationEndDate: String
    $productivityRatio: Float
    $canAutoApproveLeaves: Boolean
    $employeeDesignation: String
    $sourceOfHire: String
    $relievingDate: String
  ) {
    updateEmployeeDetail(
      id: $id
      officialEmail: $officialEmail
      status: $status
      workLocation: $workLocation
      isFlexibleTiming: $isFlexibleTiming
      workStartTime: $workStartTime
      workEndTime: $workEndTime
      joiningDate: $joiningDate
      employmentType: $employmentType
      probationStartDate: $probationStartDate
      probationEndDate: $probationEndDate
      productivityRatio: $productivityRatio
      canAutoApproveLeaves: $canAutoApproveLeaves
      employeeDesignation: $employeeDesignation
      sourceOfHire: $sourceOfHire
      relievingDate: $relievingDate
    ) {
      ...workInformationFields
    }
  }
`;

export const CREATE_EMPLOYEE_WORK_EXPERIENCE = gql`
  ${WORK_EXPERIENCE_FRAGMENT}

  mutation createUserWorkExperience(
    $userId: Int
    $designation: String
    $companyName: String
    $industry: String
    $employmentType: String
    $startDate: String
    $endDate: String
    $additionalDetails: String
    $isCurrent: Boolean
    $location: String
  ) {
    createUserWorkExperience(
      userId: $userId
      designation: $designation
      companyName: $companyName
      industry: $industry
      employmentType: $employmentType
      startDate: $startDate
      endDate: $endDate
      additionalDetails: $additionalDetails
      isCurrent: $isCurrent
      location: $location
    ) {
      ...workExperienceFields
    }
  }
`;

export const UPDATE_EMPLOYEE_WORK_EXPERIENCE = gql`
  ${WORK_EXPERIENCE_FRAGMENT}

  mutation updateUserWorkExperience(
    $id: Int!
    $designation: String
    $companyName: String
    $industry: String
    $employmentType: String
    $startDate: String
    $endDate: String
    $additionalDetails: String
    $isCurrent: Boolean
    $location: String
  ) {
    updateUserWorkExperience(
      id: $id
      designation: $designation
      companyName: $companyName
      industry: $industry
      employmentType: $employmentType
      startDate: $startDate
      endDate: $endDate
      additionalDetails: $additionalDetails
      isCurrent: $isCurrent
      location: $location
    ) {
      ...workExperienceFields
    }
  }
`;

export const DELETE_EMPLOYEE_WORK_EXPERIENCE = gql`
  mutation deleteUserWorkExperience($id: Int!) {
    deleteUserWorkExperience(id: $id)
  }
`;

export const CREATE_PROFILE_AND_COVER_IMAGE = gql`
  ${PROFILE_IMAGE_FRAGMENT}

  mutation createUserProfileImage(
    $userId: Int
    $documents: [createUserProfileImageDocument]
    $type: String!
  ) {
    createUserProfileImage(
      userId: $userId
      type: $type
      documents: $documents
    ) {
      ...profileImageFields
    }
  }
`;

export const UPDATE_PROFILE_AND_COVER_IMAGE = gql`
  ${PROFILE_IMAGE_FRAGMENT}

  mutation updateUserProfileImage(
    $id: Int!
    $documents: [updateUserProfileImageDocument]
  ) {
    updateUserProfileImage(id: $id, documents: $documents) {
      ...profileImageFields
    }
  }
`;

export const DELETE_PROFILE_AND_COVER_IMAGE = gql`
  mutation deleteUserProfileImage($id: Int!) {
    deleteUserProfileImage(id: $id)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $userId: Int!
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(
      userId: $userId
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;

export const UPDATE_PROFILE_MODERATION_STATUS = gql`
  ${PROFILE_MODERATION_FRAGMENT}

  mutation updateProfileModerationStatus(
    $ids: [Int]!
    $status: String!
    $declineReason: String
  ) {
    updateMultipleUserProfileModerations(
      ids: $ids
      status: $status
      declineReason: $declineReason
    ) {
      ...moderationFields
    }
  }
`;
