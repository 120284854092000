import React from "react";
import { HiPlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const DashboardView: React.FC<{
  leaveDetails: any;
  allowedResources: string[];
  leaveYear: number;
}> = ({ leaveDetails, allowedResources, leaveYear }) => {
  const createAccess = allowedResources?.includes("CreateApplyLeave");

  const navigate = useNavigate();

  const buttonHandler = () => {
    if (leaveDetails?.leaveType?.isSpecialLeave) {
      navigate("/leaves/apply/special", {
        state: {
          leaveTypeId: leaveDetails?.leaveType?.id,
          isEditing: false,
        },
      });
    } else if (leaveDetails?.leaveType?.isRestrictedHolidayLeave) {
      navigate("/leaves/apply/restricted", {
        state: {
          leaveYear: leaveYear,
        },
      });
    } else {
      navigate("/leaves/apply/regular", {
        state: {
          leaveTypeId: leaveDetails?.leaveType?.id,
          isEditing: false,
          name: leaveDetails?.leaveType?.name,
        },
      });
    }
  };

  let strengthPercentage = (leaveDetails.remaining / leaveDetails.total) * 100;
  let progress = 400 - 400 * (strengthPercentage / 100);

  return (
    <div className="h-[280px] p-[24px] md:grid-cols-3 gap-x-1 shadow-[0px_0px_45px_#C4C4C433]">
      <h2 className="text-[#212224] text-sm text-center lg:text-left">
        {leaveDetails.leaveType?.name}
      </h2>
      <div className="flex mt-[20px] justify-around lg:justify-between items-center">
        <div className="w-[160px] h-[160px] relative flex justify-center items-center">
          <div
            className={`${
              leaveDetails?.leaveType?.name === "Casual Leave"
                ? "bg-[#ECF1FE]"
                : leaveDetails?.leaveType?.name === "Sick Leave"
                ? "bg-[#6391F4]"
                : leaveDetails?.leaveType?.name === "Marriage Leave"
                ? "bg-[#FFE3AE]"
                : leaveDetails?.leaveType?.name === "Maternity Leave"
                ? "bg-[#ECF1FE]"
                : leaveDetails?.leaveType?.name === "Paternity Leave"
                ? "bg-[#ECF1FE]"
                : leaveDetails?.leaveType?.name === "Medical Leave"
                ? "bg-[#FFD9E0] "
                : leaveDetails?.leaveType?.name === "Privileged Leave"
                ? "bg-[#D8FFE8]"
                : leaveDetails?.leaveType?.name === "Compensatory Leave"
                ? "bg-[#6391F4]"
                : leaveDetails?.leaveType?.name === "Compensation Leave"
                ? "bg-[#6391F4]"
                : leaveDetails?.leaveType?.name === "Restricted Holiday"
                ? "bg-[#86EFAC]"
                : leaveDetails?.leaveType?.name === "Emergency Leave"
                ? "bg-[#FCA5A5]"
                : "bg-[#D8FFE8]"
            } h-[140px] w-[140px] rounded-full p-[20px]`}
          >
            <div className="h-[100px] w-[100px] rounded-full bg-white  flex items-center justify-center">
              <div className="text-black text-center py-[2px]">
                <p className="text-xs text-ironside-gray/50 font-bold;">
                  Total Leave
                </p>
                <p className="mt-2 text-sm text-black">{leaveDetails.total}</p>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="140px"
            height="140px"
            className="absolute top-[10px] left-[10px]"
          >
            <defs>
              <linearGradient
                id={
                  leaveDetails?.leaveType?.name === "Casual Leave"
                    ? "casual"
                    : leaveDetails?.leaveType?.name === "Sick Leave"
                    ? "sick"
                    : leaveDetails?.leaveType?.name === "Marriage Leave"
                    ? "marriage"
                    : leaveDetails?.leaveType?.name === "Maternity Leave"
                    ? "maternity"
                    : leaveDetails?.leaveType?.name === "Paternity Leave"
                    ? "paternity"
                    : leaveDetails?.leaveType?.name === "Medical Leave"
                    ? "medical"
                    : leaveDetails?.leaveType?.name === "Privileged Leave"
                    ? "privileged"
                    : leaveDetails?.leaveType?.name === "Compensatory Leave"
                    ? "compensatory"
                    : leaveDetails?.leaveType?.name === "Compensation Leave"
                    ? "compensation"
                    : leaveDetails?.leaveType?.name === "Restricted Holiday"
                    ? "restricted"
                    : leaveDetails?.leaveType?.name === "Emergency Leave"
                    ? "emergency"
                    : ""
                }
              >
                <stop
                  offset="100%"
                  stopColor={
                    leaveDetails?.leaveType?.name === "Casual Leave"
                      ? "#6391F4"
                      : leaveDetails?.leaveType?.name === "Sick Leave"
                      ? "#2A53A9"
                      : leaveDetails?.leaveType?.name === "Marriage Leave"
                      ? "#FFB100"
                      : leaveDetails?.leaveType?.name === "Maternity Leave"
                      ? "#C5D6FB"
                      : leaveDetails?.leaveType?.name === "Paternity Leave"
                      ? "#C5D6FB"
                      : leaveDetails?.leaveType?.name === "Medical Leave"
                      ? "#FF647C"
                      : leaveDetails?.leaveType?.name === "Privileged Leave"
                      ? "#3BC171"
                      : leaveDetails?.leaveType?.name === "Compensatory Leave"
                      ? "#2A53A9"
                      : leaveDetails?.leaveType?.name === "Compensation Leave"
                      ? "#2A53A9"
                      : leaveDetails?.leaveType?.name === "Restricted Holiday"
                      ? "#16A34A"
                      : leaveDetails?.leaveType?.name === "Emergency Leave"
                      ? "#F87171"
                      : "#3BC171"
                  }
                />
              </linearGradient>
            </defs>
            <circle
              cx="70"
              cy="70"
              r="60"
              style={{ strokeDashoffset: `${progress}` }}
              className={`
                [fill:none] ${
                  leaveDetails?.leaveType?.name === "Casual Leave"
                    ? "[stroke:url(#casual)]"
                    : leaveDetails?.leaveType?.name === "Sick Leave"
                    ? "[stroke:url(#sick)]"
                    : leaveDetails?.leaveType?.name === "Marriage Leave"
                    ? "[stroke:url(#marriage)]"
                    : leaveDetails?.leaveType?.name === "Maternity Leave"
                    ? "[stroke:url(#maternity)]"
                    : leaveDetails?.leaveType?.name === "Paternity Leave"
                    ? "[stroke:url(#paternity)]"
                    : leaveDetails?.leaveType?.name === "Medical Leave"
                    ? "[stroke:url(#medical)]"
                    : leaveDetails?.leaveType?.name === "Privileged Leave"
                    ? "[stroke:url(#privileged)]"
                    : leaveDetails?.leaveType?.name === "Compensatory Leave"
                    ? "[stroke:url(#compensatory)]"
                    : leaveDetails?.leaveType?.name === "Compensation Leave"
                    ? "[stroke:url(#compensation)]"
                    : leaveDetails?.leaveType?.name === "Restricted Holiday"
                    ? "[stroke:url(#restricted)]"
                    : leaveDetails?.leaveType?.name === "Emergency Leave"
                    ? "[stroke:url(#emergency)]"
                    : "[stroke:url(#privileged)]"
                } [stroke-width:20px] [strokeDashoffset:400] [stroke-dasharray:400]  animate-[leave-animation_2s_linear_reverse]
              `}
            />
          </svg>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <div className="w-[7px] h-[7px] rounded-full bg-gray-400" />
              <p className="text-ironside-gray/80 text-xs">Availed</p>
            </div>
            <p className="text-sm text-center">
              {leaveDetails?.leaveType?.name !== "Loss Of Pay"
                ? leaveDetails?.availed
                : leaveDetails.availed > 0
                ? `- ${leaveDetails.availed}`
                : `${leaveDetails.availed}`}
            </p>
          </div>
          {leaveDetails?.leaveType?.name !== "Loss Of Pay" && (
            <div className="flex flex-col">
              <div className="flex items-center space-x-2">
                <div
                  className={`${
                    leaveDetails.leaveType.name === "Casual Leave"
                      ? "bg-cornflower-blue"
                      : leaveDetails.leaveType.name === "Compensatory Leave"
                      ? "bg-[#2A53A9]"
                      : leaveDetails.leaveType.name === "Marriage Leave"
                      ? "bg-[#FFB100]"
                      : leaveDetails.leaveType.name === "Maternity Leave"
                      ? "bg-[#C5D6FB]"
                      : leaveDetails.leaveType.name === "Paternity Leave"
                      ? "bg-[#C5D6FB]"
                      : leaveDetails.leaveType.name === "Medical Leave"
                      ? "bg-[#FF647C]"
                      : leaveDetails.leaveType.name === "Privileged Leave"
                      ? "bg-[#3BC171]"
                      : leaveDetails.leaveType.name === "Sick Leave"
                      ? "bg-[#2A53A9]"
                      : leaveDetails.leaveType.name === "Compensation Leave"
                      ? "bg-[#2A53A9]"
                      : leaveDetails?.leaveType?.name === "Restricted Holiday"
                      ? "bg-[#16A34A]"
                      : leaveDetails?.leaveType?.name === "Emergency Leave"
                      ? "bg-[#FCA5A5]"
                      : "bg-[#3BC171]"
                  } w-[7px] h-[7px] rounded-full`}
                />
                <p className="text-ironside-gray/80 text-xs">Remaining</p>
              </div>
              <p className="text-sm text-center">{leaveDetails.remaining}</p>
            </div>
          )}
          {createAccess ? (
            <div>
              <button
                className="w-[70px] h-[25px] text-[9px] rounded-full flex justify-center items-center bg-zinc-100 mt-4"
                onClick={buttonHandler}
              >
                <HiPlus className="w-[10px] h-[10px] cursor-pointer" />
                <span className="ml-[2px] text-ironside-gray/80 text-xs">
                  Apply
                </span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DashboardView;
