import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import { Filled } from "components/forms";

import { paginationDefaultValue } from "global/helpers/StaticData";
import { setBackNavigationURL, setTitle } from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
	useCustomSearchParams,
	useEffectOnce,
	useSearch,
	useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import useAllowedResources from "global/hooks/useAllowedResources";

import { IProjectFilterForm } from "modules/Project/types/vault";
import {
	ProjectGroup,
	ProjectGroupFilterForm,
} from "modules/Project/Pages/Projects/ProjectGroups/types";
import { ProjectBackLogFilterForm } from "modules/Project/Pages/Backlogs/types";
import { FILTER_PROJECT_TASKS } from "modules/Project/services/queries";
import { IProjectTask } from "modules/Project/types/project";

interface Props {
	projectGroup?: ProjectGroup | null;
	editProject: { id: number; name: string };
}

const tableHeadings = [
	{ name: "Title", APIName: "title" },
	{ name: "Priority", APIName: "title" },
	{ name: "Description", APIName: "title" },
];

const ProjectBacklogs: FC<Props> = ({ projectGroup, editProject }) => {
	const allowedResources = useAllowedResources();

	const canCreate = allowedResources?.includes("CreateProjectBacklog");

	const [searchParams, setSearchParams] = useCustomSearchParams();
	const { pathname, search: searchQuery } = useLocation();

	const { register, watch, control, handleSubmit, setValue, reset } =
		useForm<ProjectBackLogFilterForm>({
			defaultValues: {
				search: searchParams?.search ? searchParams?.search : "",
			},
		});

	useEffect(() => {
		if (pathname && searchQuery) {
			setBackNavigationURL(pathname + searchQuery);
		}
	}, [pathname, searchQuery]);
	const search = useSearch(watch("search"));

	const { id, projectType } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (editProject?.name && projectGroup?.id) {
			setTitle([
				{
					name: "Project",
					link: "/projects/groups",
				},
				{ name: "Groups", link: "/projects/groups" },
				{
					name: projectGroup?.name || "",
					link: `/projects/${projectGroup?.id}/${projectType}`,
				},
				{
					name: `${editProject?.name}`,
					link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
				},
				{
					name: "Backlogs",
					link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/backlogs`,
				},
			]);
		} else {
			setTitle([
				{
					name: "Project",
					link: `/projects/projects/${projectType}`,
				},
				{
					name: projectGroup?.name || "",
					link: `/projects/projects/${projectType}`,
				},
				{
					name: `${editProject?.name}`,
					link: `/projects/projects/${projectType}/edit/${id}/general`,
				},
				{
					name: "Backlogs",
					link: `projects/projects/${projectType}/edit/${id}/backlogs`,
				},
			]);
		}
	}, [
		editProject?.name,
		projectType,
		projectGroup?.id,
		projectGroup?.name,
		id,
	]);

	const [currentPage, setCurrentPage] = useState<number>(
		!Number.isNaN(+searchParams?.page) ? +searchParams?.page : 1,
	);
	const [pageSize, setPageSize] = useState<number>(
		!Number.isNaN(+searchParams?.limit)
			? +searchParams?.limit
			: paginationDefaultValue,
	);

	useEffectOnce(() => {
		setSearchParams({
			...searchParams,
			page: `${currentPage}`,
			limit: `${pageSize}`,
		});
	});

	useUpdateEffect(() => {
		if (searchParams?.page && !Number.isNaN(+searchParams?.page)) {
			setCurrentPage(+searchParams?.page);
		}
		if (searchParams?.limit && !Number.isNaN(+searchParams?.limit)) {
			setPageSize(+searchParams?.limit);
		}
		setValue("search", searchParams?.search || "");
	}, [searchParams?.page, searchParams?.limit, searchParams?.search]);

	const { data: filterProjectTasks, loading } = useQuery(FILTER_PROJECT_TASKS, {
		nextFetchPolicy: "no-cache",
		fetchPolicy: "network-only",
		skip: id && Number.isFinite(+id) ? false : true,
		variables: {
			filters: {
				projectId: Number(id),
				isComesUnderBacklog: true,
			},
			globalSearch: search || undefined,
			page: currentPage,
			limit: pageSize,
			isProjectTaskDescriptionNeed: true,
			isProjectTaskPriorityNeed: true,
		},
	});

	const projectBacklogsList =
		filterProjectTasks?.projectTasks?.dataCollection || [];
	const totalCount = filterProjectTasks?.projectTasks?.totalNumberOfItems || 0;

	const noOfItems = projectBacklogsList?.length || 0;

	const [filterValues, setFilterValue] = useState<object | null>(null);

	const tableRef = useRef<HTMLDivElement>(null);

	const addBtnHandler = () => {
		navigate(
			`/projects/${
				projectGroup?.id ? `${projectGroup?.id}` : "projects"
			}/${projectType}/edit/${id}/backlogs/new`,
		);
	};

	const onCardClick = (projectBackLog: IProjectTask) => {
		if (canCreate) {
			navigate(
				`/projects/${
					projectGroup?.id ? `${projectGroup?.id}` : "projects"
				}/${projectType}/edit/${id}/backlog/${projectBackLog?.id}`,
			);
		}
	};

	const getDataModal = (projectBackLog: IProjectTask): IDataModal[] => [
		{
			label: "Title",
			value: [
				{
					content: projectBackLog?.title
						? Capitalize(projectBackLog?.title)
						: "",
				},
			],
		},
		{
			label: "Priority",
			value: [
				{
					content: projectBackLog?.priority,
				},
			],
		},
		{
			label: "Description",
			value: [
				{
					content: projectBackLog?.description,
				},
			],
		},
	];
	const getTableContent = (projectBackLog: IProjectTask): ITableContent[] => [
		{ value: projectBackLog?.title, className: "truncate max-w-[300px]" },
		{ value: projectBackLog?.priority },
		{
			value: projectBackLog?.description,
			className: "truncate max-w-[300px]",
		},
	];

	const [showFilter, setShowFilter] = useState<boolean>(false);

	const clearFilterHandler = () => {
		reset();
		setFilterValue(null);
	};
	const filterBtnShowHandler = () => {
		setShowFilter(false);
	};

	const filterFormOption: TFilterOption<ProjectGroupFilterForm> = [];

	const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
		setFilterValue(getFilterFormValues(data, filterFormOption));
		filterBtnShowHandler();
	};
	const noDataFoundMessage = (
		<p
			children={"Sorry we couldn't found any backlogs."}
			className="w-full min-h-[80vh] inline-flex border mb-2 rounded-md shadow justify-center items-center text-sm text-ironside-gray"
		/>
	);

	return (
		<div className="mt-5">
			<div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
				<Filter
					control={control}
					handleSubmit={handleSubmit}
					watch={watch}
					clearFilterHandler={clearFilterHandler}
					searchInputName="search"
					filterName="filter"
					options={filterFormOption}
					register={register}
					submitHandler={submitHandler}
					filterBtnShowHandler={filterBtnShowHandler}
					showFilter={showFilter}
					setValue={setValue}
					isCloseIconNeeded={true}
					hideFilterBtn
				/>
				{canCreate && (
					<div className="flex items-center justify-center lg:mt-0 mb-3 lg:mb-0">
						<Filled buttonName="Add" onClick={addBtnHandler} />
					</div>
				)}
			</div>
			{!loading ? (
				<Fragment>
					{noOfItems !== 0 ? (
						<Fragment>
							<div className="md:hidden mx-auto">
								<div className="min-h-[80vh] space-y-3">
									{projectBacklogsList?.map((projectBackLog) => {
										return (
											<DataCard
												key={projectBackLog?.id}
												dataModal={getDataModal(projectBackLog)}
												classForULlist={`${
													canCreate ? "cursor-pointer" : "cursor-default"
												}`}
												classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
												disableClickToViewToolTip={true}
												cardClick={() => {
													canCreate && onCardClick(projectBackLog);
												}}
											/>
										);
									})}
								</div>
								<Pagination
									totalCount={totalCount}
									currentPage={currentPage}
									onPageChange={(page: number) => {
										setSearchParams({
											...searchParams,
											page: `${page}`,
										});
										setCurrentPage(page);
									}}
									onRowPerPageChange={(rowPerPage) => {
										setSearchParams({
											...searchParams,
											limit: `${rowPerPage}`,
										});
									}}
									pageSize={pageSize}
									setPageSize={setPageSize}
									siblingCount={1}
									noOfItem={noOfItems}
								/>
							</div>
							<div className="hidden md:block ">
								<Table
									loading={loading}
									className="min-h-[80vh]"
									onPagination={{
										totalCount,
										currentPage: currentPage,
										setCurrentPage: setCurrentPage,
										pageSize: pageSize,
										setPageSize: setPageSize,
										onPageChange: (page) => {
											setSearchParams({
												...searchParams,
												page: `${page}`,
											});
										},
										onRowPerPageChange: (rowPerPage) => {
											setSearchParams({
												...searchParams,
												limit: `${rowPerPage}`,
											});
										},
										noOfItems,
									}}
									tableHeadings={{
										tableObject: tableHeadings?.map(
											(heading: { name: string; APIName?: string }) => {
												return {
													name: heading?.name,
													center: heading?.name === "Action" ? true : false,
													APIName: heading?.APIName,
												};
											},
										),
									}}
									ref={tableRef}
									isCenterLastHeading={false}
									isSortOrderNeeded={false}
								>
									{projectBacklogsList?.map((projectBackLog) => {
										return (
											<TableContent
												key={projectBackLog?.id}
												tableRef={tableRef}
												classForTableRow={`${
													canCreate
														? "cursor-pointer hover:bg-white-smoke"
														: "cursor-default hover:bg-white-smoke"
												}`}
												tableContent={getTableContent(projectBackLog)}
												tableRowClick={() => {
													canCreate && onCardClick(projectBackLog);
												}}
											/>
										);
									})}
								</Table>
							</div>
						</Fragment>
					) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
						noDataFoundMessage
					) : (
						noDataFoundMessage
					)}
				</Fragment>
			) : (
				<Loading className="min-h-[90vh]" />
			)}
		</div>
	);
};

export default ProjectBacklogs;
