export const Capitalize = (input: string) => {
  input = input?.toLowerCase();
  input = input?.replace("." || "-", " ");
  let words = input?.split(" ");
  let CapitalizedWords: Array<string> = [];
  words?.forEach((element: string) => {
    CapitalizedWords.push(
      element[0]?.toUpperCase() + element?.slice(1, element.length)
    );
  });
  return CapitalizedWords.join(" ");
};

export const camelToTitleCase = (str: string) => {
  return str
    .replace(/[0-9]{2,}/g, (match) => ` ${match} `)
    .replace(/[^A-Z0-9][A-Z]/g, (match) => `${match[0]} ${match[1]}`)
    .replace(
      /[A-Z][A-Z][^A-Z0-9]/g,
      (match) => `${match[0]} ${match[1]}${match[2]}`
    )
    .replace(/[ ]{2,}/g, () => " ")
    .replace(/\s./g, (match) => match.toUpperCase())
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

export const ProfileName = (input: string) => {
  input = input?.toLowerCase();
  input = input?.replace("-" || "_", " ");
  let words = input?.split(" ");
  let CapitalizedWords: Array<string> = [];
  words?.forEach((element: string) => {
    CapitalizedWords.push(element?.slice(0, 1).toUpperCase());
  });
  return CapitalizedWords;
};
