import { Route, Routes } from "react-router-dom";
import Absentees from "./Absentees/Absentees";
import ApplyRouteControl from "./Apply/ApplyRouteControl";
import Dashboard from "./Dashboard/Dashboard";
import Holiday from "./Holiday/Holiday";
import RequestRouteControl from "./Requests/RequestRouteControl";
import SettingsRouteControl from "./Settings/SettingsRouteControl";
import TransactionsRouteControl from "./Transactions/TransactionsRouteControl";

const index = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="apply/*" element={<ApplyRouteControl />} />
      <Route path="requests/*" element={<RequestRouteControl />} />
      <Route path="transactions/*" element={<TransactionsRouteControl />} />
      <Route path="holidays" element={<Holiday />} />
      <Route path="absentees" element={<Absentees />} />
      <Route path="settings/*" element={<SettingsRouteControl />} />
    </Routes>
  );
};

export default index;
