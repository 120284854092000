import { FC, ReactNode } from "react";

interface IProps {
  onClick?: () => void;
  children?: ReactNode;
}

const Modal: FC<IProps> = ({ onClick, children }) => {
  return (
    <div
      onClick={onClick}
      className="fixed inset-0 flex justify-center items-center z-[55] h-full w-full bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40"
    >
      {children}
    </div>
  );
};

export default Modal;
