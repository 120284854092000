import { Fragment, useRef, useState } from "react";

import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import UserDetails from "components/UserDetails/UserDetails";
import OopsNoData from "components/UI/OopsNoData";
import { ITableContent } from "components/App/Table/types/table";

const tableHeadings = [
  "Name",
  "Email",
  "Emp ID",
  "Session",
  "Date",
  "Start Time",
  "End Time",
];

const AbsenteesDetails: React.FC<{
  leaveTransactionData: any;
  setPageSize: any;
  setCurrentPage: any;
  pageSize: any;
  currentPage: any;
  totalItems: any;
  loading: boolean;
}> = ({
  leaveTransactionData,
  currentPage,
  pageSize,
  setPageSize,
  setCurrentPage,
  totalItems,
  loading,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const [userId, setUserId] = useState<number | undefined>();

  const getDataModal = (absenteesDetails: any) => {
    return [
      {
        lable: "Employee Name",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() =>
                    setUserId(
                      absenteesDetails?.leaveRequest?.requestedByUser
                        ?.personalInformation?.id
                    )
                  }
                >
                  {
                    absenteesDetails?.leaveRequest?.requestedByUser
                      ?.personalInformation?.name
                  }
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Email",
        value: [
          {
            content:
              absenteesDetails?.leaveRequest?.requestedByUser?.employeeDetail
                ?.officialEmail ||
              absenteesDetails?.leaveRequest?.requestedByUser
                ?.personalInformation?.personalEmail,
          },
        ],
      },
      {
        lable: "Employee Id",
        value: [
          {
            content:
              absenteesDetails?.leaveRequest?.requestedByUser?.employeeDetail
                ?.employeeId,
          },
        ],
      },
      {
        lable: "Session",
        value: [
          {
            content: absenteesDetails?.session,
          },
        ],
      },
      {
        lable: "Date",
        value: [
          {
            content: absenteesDetails?.leaveDate,
          },
        ],
      },
      {
        lable: "Start Time",
        value: [
          {
            content: absenteesDetails?.startTime,
          },
        ],
      },
      {
        lable: "End Time",
        value: [
          {
            content: absenteesDetails?.endTime,
          },
        ],
      },
    ];
  };
  return (
    <Fragment>
      <div className="hidden md:block rounded-[4px] bg-white border-[0.5px] md:border-none border-light-gray md:mt-[5px]">
        <div className="flex flex-col bg-white justify-between min-h-[560px]">
          <Table
            noOfItems={leaveTransactionData?.length}
            ref={parentRef}
            className="py-5 min-h-[640px]"
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setPageSize={setPageSize}
            totalCount={totalItems || 0}
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: name === "Name" || name === "Email" ? false : true,
              };
            })}
          >
            {leaveTransactionData?.map((absenteesDetails: any) => {
              let tableContent: ITableContent[] = [
                {
                  value: (
                    <div className="group">
                      <p
                        onMouseEnter={() =>
                          setUserId(
                            absenteesDetails?.leaveRequest?.requestedByUser
                              ?.personalInformation?.id
                          )
                        }
                      >
                        {
                          absenteesDetails?.leaveRequest?.requestedByUser
                            ?.personalInformation?.name
                        }
                      </p>
                      <UserDetails userId={userId} />
                    </div>
                  ),
                },
                {
                  value:
                    absenteesDetails?.leaveRequest?.requestedByUser
                      ?.employeeDetail?.officialEmail,
                },
                {
                  value:
                    absenteesDetails?.leaveRequest?.requestedByUser
                      ?.employeeDetail?.employeeId,
                  center: true,
                },
                {
                  value: absenteesDetails?.session,
                  center: true,
                },
                {
                  value: absenteesDetails?.leaveDate,
                  center: true,
                },
                {
                  value: absenteesDetails?.startTime,
                  center: true,
                },
                {
                  value: absenteesDetails?.endTime,
                  center: true,
                },
              ];
              return (
                <TableContent
                  key={absenteesDetails?.id}
                  id={absenteesDetails?.id}
                  parentRef={parentRef}
                  tableContent={tableContent}
                />
              );
            })}
          </Table>
        </div>
      </div>
      <div className="md:hidden lg:px-[15px] rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] bg-white md:border-none border-light-gray md:mt-[30px]">
        <div className="flex flex-col bg-white">
          {loading ? (
            <div className="min-h-[400px] flex justify-center items-center">
              <div className="loader-design" />
            </div>
          ) : leaveTransactionData?.length > 0 ? (
            <Fragment>
              <div className="w-full overflow-x-auto min-h-[412px]">
                {leaveTransactionData?.map(
                  (absenteesDetails: any, index: number) => {
                    return (
                      <SMDataCard
                        key={absenteesDetails?.id}
                        title={`Absentees ${index + 1}`}
                        loading={loading}
                        dataModal={getDataModal(absenteesDetails)}
                      />
                    );
                  }
                )}
              </div>
              <Pagination
                totalCount={totalItems ? totalItems : 0}
                noOfItem={leaveTransactionData?.length}
                currentPage={currentPage}
                setPageSize={setPageSize}
                siblingCount={1}
                pageSize={pageSize}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </Fragment>
          ) : (
            <OopsNoData />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AbsenteesDetails;
