import { Fragment, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";

import DataCard from "components/App/Card/DataCard";

import { userQueryProps } from "global/helpers/Cache";

import EditBankInfo from "modules/Employee/Pages/Profile/Personal/BankInformation/EditBankInformation";
import { IBankInformation } from "modules/Employee/types/personal";
import { makeDataModelArray } from "modules/Employee/helpers/make-data-model-array";
import { GET_BANK_INFORMATION } from "modules/Employee/services/queries";
import { Filled } from "components/forms";

const BankInformation = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchBankInformation,
  } = useQuery(GET_BANK_INFORMATION, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const userBankDetails: IBankInformation =
    (userDetails?.users?.dataCollection &&
      userDetails?.users?.dataCollection[0]?.userBankAccount) ||
    null;

  const [showModal, setShowModal] = useState<boolean>(false);

  const removeInformationModelItems = ["id", "moderation", "__typename"];

  const bankInformationModel = makeDataModelArray(
    userBankDetails,
    removeInformationModelItems
  );

  return (
    <Fragment>
      <DataCard
        loading={loading}
        resourceName="EmployeeBankInformation"
        setShowModal={setShowModal}
        title="Bank Information"
        dataModel={bankInformationModel}
        refetchQuery={refetchBankInformation}
        showPencilIcon={bankInformationModel?.length === 0 ? false : true}
        children={
          bankInformationModel?.length === 0 ? (
            <div className="w-full min-h-[390px] grid place-content-center">
              <Filled
                buttonName={"Add"}
                buttonType="button"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          ) : undefined
        }
      />
      {showModal && (
        <EditBankInfo
          userBankDetails={userBankDetails}
          setShowModal={setShowModal}
          refetchBankInformation={refetchBankInformation}
        />
      )}
    </Fragment>
  );
};

export default BankInformation;
