import { useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { Select } from "components/forms";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import { paginationDefaultValue } from "global/helpers/StaticData";
import { DateFormat } from "global/helpers/DateFormatter";

import { FILTER_MY_COMPENSATION } from "modules/Leave/services/queries";
import ViewPage from "modules/Leave/Pages/Requests/MyCompOff/ViewPage";
import MyCompOffDetails from "modules/Leave/Pages/Requests/MyCompOff/MyCompOffDetails";
import { myCompensationTypes } from "modules/Leave/types/leave";

export interface RequestDetailsType {
  createdAt: string | any;
  updatedAt: string;
  dates: any;
  declineMessage: undefined | string;
  id: number;
  reason: string;
  status:
    | "Pending"
    | "Approved"
    | "Declined"
    | "Partially Approved"
    | "Cancelled";
}

const MyCompOff = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm();

  const viewPageDetails: any = "";
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const status = watch("status");
  const fromDate =
    dateRange[0]?.startDate && DateFormat(dateRange[0]?.startDate?.toString());
  const toDate =
    dateRange[0]?.endDate && DateFormat(dateRange[0]?.endDate?.toString());

  const [
    fetchMyCompensationRequests,
    { data: filterMyCompensationRequests, loading },
  ] = useLazyQuery(FILTER_MY_COMPENSATION, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    fetchMyCompensationRequests({
      variables: {
        status: status ? [status] : undefined,
        from: fromDate ? fromDate : undefined,
        to: toDate ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
      },
    });
  }, [
    pageSize,
    currentPage,
    status,
    fromDate,
    toDate,
    fetchMyCompensationRequests,
  ]);

  const [filteredRequest, setFilteredRequest] = useState<
    myCompensationTypes | []
  >([]);
  useEffect(() => {
    setFilteredRequest(
      filterMyCompensationRequests?.filterMyCompensationLeave?.dataCollection?.map(
        (request: myCompensationTypes) => {
          return {
            createdAt: request?.createdAt,
            updatedAt: request?.updatedAt,
            reason: request?.reason,
            declineMessage: request?.declineMessage,
            id: request.id,
            requestedToUser: request?.requestedToUser,
            dates: request.compensationLeaveDates?.map((data) => {
              return {
                id: data?.id,
                leaveDate: data?.leaveDate,
                session: data?.session,
                declineMessage: data?.declineMessage,
                status: data?.status,
                startTime: data?.startTime,
                endTime: data?.endTime,
                project: data?.project,
              };
            }),
          };
        }
      )
    );
  }, [filterMyCompensationRequests]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] sm:gap-5 xl:grid-cols-[repeat(4,220px)] 2xl:grid-cols-[repeat(4,250px)] justify-center items-center xl:justify-start">
                  <Select
                    register={register}
                    errors={errors}
                    options={[
                      "Approved",
                      "Declined",
                      "Partially Approved",
                      "Pending",
                    ]}
                    label="Status"
                    name="status"
                    required={true}
                    defaultSelectValue="Pending"
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                </div>
              </div>
              <MyCompOffDetails
                requestData={filteredRequest}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={
                  filterMyCompensationRequests?.filterMyCompensationLeave
                    ?.totalNumberOfItems
                }
                loading={loading}
                filterMyCompensationRequests={filterMyCompensationRequests}
              />
            </div>
          }
        />
        <Route path={`:${viewPageDetails?.id}`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default MyCompOff;
