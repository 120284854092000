import {
  Filled,
  ToggleSwitch,
  Text,
  Modal,
  Number,
  Select,
  Outlined,
  TextArea,
} from "components/forms";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useEffect } from "react";
import { DepartmentType } from "../Department/EditEmployeeDepartment";
import { toastNotify } from "global/helpers/Cache";
import {
  CREATE_DESIGNATION,
  UPDATE_DESIGNATION,
} from "modules/Organization/services/mutations";

interface employeeDetails {
  name: string;
  employeeDepartment: any;
  description: string;
  isUnderHiring: boolean;
  isActive: boolean;
  maxExpYears: any;
  minExpYears: any;
}

export interface DesignationType {
  id: number;
  name: string;
  description: string;
  isUnderHiring: boolean;
  isActive: boolean;
  maxExpYears: number;
  minExpYears: number;
  employeeDepartment: DepartmentType;
}

const EditEmployeeDesignation: React.FC<{
  employeeDepartments: any;
  employeeDesignation: any;
  setShowModal: Function;
  loading: boolean;
  inProgress: boolean;
  setInProgress: Function;
  refetch: Function;
}> = ({
  employeeDepartments,
  employeeDesignation,
  setShowModal,
  loading,
  inProgress,
  setInProgress,
  refetch,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<employeeDetails>();

  const [createDesignation] = useMutation(CREATE_DESIGNATION);
  const [updateDesignation] = useMutation(UPDATE_DESIGNATION);

  const employeeDepartment =
    employeeDepartments?.employeeDepartments?.dataCollection.map(
      (department: any) => department.name
    ) || [];

  const createHandler = (data: employeeDetails) => {
    const value: {
      id: number;
      name: string;
    }[] = employeeDepartments?.employeeDepartments?.dataCollection?.filter(
      (department: { id: number; name: string }) => {
        return department.name === data.employeeDepartment;
      }
    );
    if (!inProgress) {
      createDesignation({
        variables: {
          name: data.name,
          employeeDepartment: +value[0].id,
          description: data.description,
          isUnderHiring: data.isUnderHiring,
          isActive: data.isActive,
          maxExpYears: parseInt(data.maxExpYears),
          minExpYears: parseInt(data.minExpYears),
        },
      })
        .then((response) => {
          const designationName = response?.data?.createDesignation?.name;
          setInProgress(false);
          setShowModal(false);
          refetch();
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                designationName
                  ? `${designationName} designation`
                  : "Designation"
              } has been created successfully.`,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: employeeDetails) => {
    const value: {
      id: number;
      name: string;
    }[] = employeeDepartments?.employeeDepartments?.dataCollection?.filter(
      (department: { id: number; name: string }) => {
        return department.name === data.employeeDepartment;
      }
    );
    if (!inProgress) {
      updateDesignation({
        variables: {
          id: +employeeDesignation?.id,
          name: data.name,
          employeeDepartment: +value[0]?.id,
          description: data.description,
          isUnderHiring: data.isUnderHiring,
          isActive: data.isActive,
          maxExpYears: parseInt(data.maxExpYears),
          minExpYears: parseInt(data.minExpYears),
        },
      })
        .then((response) => {
          const designationName = employeeDesignation?.name;
          setInProgress(false);
          setShowModal(false);
          refetch();
          refetch();
          toastNotify([
            {
              messageType: "success",
              message: `${
                designationName
                  ? `${designationName} designation`
                  : "Designation"
              } has been updated successfully.`,
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  useEffect(() => {
    if (employeeDesignation) {
      setValue(
        "employeeDepartment",
        employeeDesignation ? employeeDesignation?.employeeDepartment?.name : ""
      );
    }
  }, [employeeDesignation, setValue]);

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Designation</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setInProgress(false);
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form
              onSubmit={
                employeeDesignation?.id
                  ? handleSubmit(updateHandler)
                  : handleSubmit(createHandler)
              }
            >
              <div className="form-div pr-[18px] lg:pr-[45px]">
                <Text
                  register={register}
                  errors={errors}
                  label="Name *"
                  name="name"
                  required={true}
                  defaultText={employeeDesignation?.name}
                />

                <Select
                  register={register}
                  errors={errors}
                  label="Employee Department *"
                  name="employeeDepartment"
                  options={employeeDepartment}
                  defaultSelectValue={
                    employeeDesignation?.employeeDepartment?.name
                  }
                  required={true}
                />
                <TextArea
                  register={register}
                  name="description"
                  errors={errors}
                  label="Description"
                  defaultText={employeeDesignation?.description}
                  maximumLength={50}
                />
                <Number
                  register={register}
                  errors={errors}
                  label="Maximum Experience Years*"
                  name="maxExpYears"
                  required={true}
                  defaultNumber={employeeDesignation?.maxExpYears}
                />
                <Number
                  register={register}
                  errors={errors}
                  label="Minimum Experience Years *"
                  name="minExpYears"
                  required={true}
                  defaultNumber={employeeDesignation?.minExpYears}
                />
                <div className="flex text-center h-[52px] justify-end">
                  <p className="flex text-gray-400">isActive?</p>
                  <div className="ml-2">
                    <ToggleSwitch
                      label={"toggleisActive"}
                      register={register}
                      name={"isActive"}
                      defaultValue={employeeDesignation?.isActive}
                    />
                  </div>
                </div>
                <div className="flex h-[52px] justify-end">
                  <p className="flex text-gray-400">isUnderHiring?</p>
                  <div className="ml-2">
                    <ToggleSwitch
                      label={"toggleisUnderHiring"}
                      register={register}
                      name={"isUnderHiring"}
                      defaultValue={employeeDesignation?.isUnderHiring}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setInProgress(false);
                    setShowModal(false);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={
                    inProgress ? (
                      <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                    ) : employeeDesignation?.id ? (
                      "Update"
                    ) : (
                      "Add"
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditEmployeeDesignation;
