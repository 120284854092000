import { FC, useEffect, useState } from "react";
import { useEditor, EditorContent, ReactRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import MenuBar from "./MenuBar";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle, { TextStyleOptions } from "@tiptap/extension-text-style";
import Mention from "@tiptap/extension-mention";
import { Fragment } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SuggestionProps } from "@tiptap/suggestion";
import { TbTrashFilled } from "react-icons/tb";
import { AiOutlineFile } from "react-icons/ai";

import { Filled, Outlined } from "components/forms";

import { ISetStateType } from "global/types/type";

import { toastNotify } from "global/helpers/Cache";
import { removeDuplicates } from "global/helpers/ArrayMethods";
import { detectMimeType } from "global/helpers/mimeType";
import {
	fileToBase64,
	getFileExtensionUsingName,
} from "global/helpers/FileConverter";

import MentionList from "modules/Project/Pages/Issues/IssueLogs/Activity/Comments/TipTap/MentionList";
import { IProjectTaskComment } from "modules/Project/types/project";
import { CREATE_OR_UPDATE_PROJECT_TASK_COMMENT } from "modules/Project/services/mutations";
import { testJSON } from "global/helpers/AllowedResourceHelper";

interface IProps {
	editComment: IProjectTaskComment | null;
	setShowComment: ISetStateType<boolean>;
	setEditComment: ISetStateType<IProjectTaskComment | null>;
	setComments: ISetStateType<IProjectTaskComment[]>;
	refetchComments: Function;
	membersList: { id: number; name: string }[] | [];
}

const TipTap: FC<IProps> = ({
	editComment,
	setShowComment,
	setEditComment,
	setComments,
	refetchComments,
	membersList,
}) => {
	const editor = useEditor({
		extensions: [
			Color.configure({ types: [TextStyle.name, ListItem.name] }),
			TextStyle.configure({
				types: [ListItem.name],
			} as unknown as TextStyleOptions),
			StarterKit.configure({
				bulletList: {
					keepMarks: true,
					keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
				},
				orderedList: {
					keepMarks: true,
					keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
				},
			}),
			Mention.configure({
				HTMLAttributes: { class: "mentionNode" },
				suggestion: {
					items: ({ query }) => {
						return membersList
							?.filter((item: { id: number; name: string }) =>
								item?.name?.toLowerCase().startsWith(query.toLowerCase()),
							)
							.slice(0, 5);
					},
					render: () => {
						let component: any;
						return {
							onStart: (props: SuggestionProps) => {
								component = new ReactRenderer(MentionList, {
									props,
									editor: props.editor,
								});

								if (!props.clientRect) {
									return;
								}
							},

							onUpdate(props: any) {
								component.updateProps(props);

								if (!props.clientRect) {
									return;
								}
							},

							onKeyDown(props: any) {
								if (props.event.key === "Escape") {
									return true;
								}

								return component.ref?.onKeyDown(props);
							},

							onExit() {
								component.destroy();
							},
						};
					},
				},
			}),
		],
	});

	useEffect(() => {
		if (editComment?.message && testJSON(editComment?.message)) {
			editor?.commands.setContent(JSON.parse(editComment?.message));
		}
	}, [editComment, editor]);

	const [saveProjectTaskComment, { loading: saveProjectTaskCommentLoading }] =
		useMutation(CREATE_OR_UPDATE_PROJECT_TASK_COMMENT);

	const { issueId } = useParams();
	const { reset } = useForm();

	const [fileList, setFileList] = useState<any>([]);
	const [updatedFiles, setUpdatedFiles] = useState<any>([]);

	const currentFiles: any = [];

	fileList?.map((fileDetails: any, index: number) => {
		if (fileDetails?.fileDetails?.size < 2e6)
			fileToBase64(fileDetails?.fileDetails, (err: any, result: any) => {
				if (result) {
					currentFiles[index] = {
						filePath: result?.split(";base64,")[1],
						name: fileDetails?.fileDetails?.name,
						extension:
							getFileExtensionUsingName(fileDetails?.fileDetails?.name || "")
								?.length > 0
								? `.${getFileExtensionUsingName(
										fileDetails?.fileDetails?.name || "",
								  )}`
								: undefined,
						mimeType: fileDetails?.fileDetails?.type
							? fileDetails?.fileDetails?.type?.toString()?.length > 0
								? fileDetails?.fileDetails?.type
								: "text/plain"
							: "text/plain",
					};
				}
			});
		return <></>;
	});

	useEffect(() => {
		if (editComment && editComment?.projectTaskCommentAttachments?.length > 0) {
			setUpdatedFiles(
				editComment?.projectTaskCommentAttachments?.map(
					({ id, filePath, mimeType, extension, name }) => {
						return {
							id,
							filePath,
							mimeType,
							extension,
							name,
						};
					},
				),
			);
		} else {
			setUpdatedFiles([]);
		}
	}, [editComment]);

	const cacelButtonHandler = () => {
		setShowComment(false);
		if (editComment) {
			setEditComment(null);
		} else {
			setFileList([]);
			reset();
		}
	};

	const createOrUpdateProjectTaskComment = () => {
		const editFilesIds =
			updatedFiles?.length > 0
				? updatedFiles?.map((file: { id: number }) => file?.id)
				: [];

		const removedFiles =
			editComment && editComment?.projectTaskCommentAttachments?.length > 0
				? editComment?.projectTaskCommentAttachments?.filter(
						({ id }) => !editFilesIds.includes(id),
				  ) || []
				: [];

		const mentionNodes =
			(editor?.getJSON()?.content &&
				// biome-ignore lint/suspicious/noExtraNonNullAssertion: <explanation>
				editor?.getJSON()?.content!?.length > 0 &&
				editor
					?.getJSON()
					?.content![0]?.content?.filter(
						(filter) => filter.type === "mention",
					)) ||
			[];

		const mentionedPeopleIds =
			mentionNodes?.map((node) => node?.attrs?.id) || [];

		saveProjectTaskComment({
			variables: {
				projectTaskCommentInput: {
					id: editComment?.id || undefined,
					projectTaskId: Number(issueId),
					message: JSON.stringify({
						...editor?.getJSON(),
						quoteUsers: removeDuplicates(mentionedPeopleIds),
					}),
					projectTaskCommentAttachments:
						currentFiles?.length > 0
							? currentFiles?.map(
									(file: {
										filePath: string;
										name: string;
										extension: string;
										mimeType: string;
									}) => {
										return {
											filePath: file?.filePath,
											name: file?.name,
											extension: file?.extension,
											mimeType: file?.mimeType,
										};
									},
							  )
							: undefined,
					projectTaskCommentAttachmentToRemove:
						removedFiles?.length > 0
							? removedFiles?.map(({ id }) => id)
							: undefined,
					messageText: editor?.getText(),
				},
			},
		})
			.then((res) => {
				if (!editComment?.id) {
					setComments((prevComments: IProjectTaskComment[]) => {
						const newComments = [...prevComments];
						newComments?.unshift(res?.data?.saveProjectTaskComment);
						return newComments;
					});
				}

				if (editComment?.id) {
					setComments((prevComments: IProjectTaskComment[]) => {
						const newComments = [...prevComments];
						const { index } = editComment;
						newComments[index] = res?.data?.saveProjectTaskComment;

						return newComments;
					});
				}

				setEditComment(null);
				setShowComment(false);
				toastNotify([
					{
						messageType: "success",
						message: `Comment ${
							editComment?.id ? "Updated" : "Added"
						} successfully`,
					},
				]);
				reset();
				refetchComments();
			})
			.catch((error) => {
				toastNotify([
					{
						messageType: "error",
						message: `${error?.message}`,
					},
				]);
			});
	};

	return (
		<Fragment>
			{editor ? (
				<Fragment>
					<div className="w-full">
						<div className="border border-[#cfcfd0] min-h-[200px] mb-1 grid grid-cols-1">
							<MenuBar editor={editor} setFileList={setFileList} />
							<EditorContent
								editor={editor}
								className="flex-1 grid grid-cols-1"
							/>
							<div className="space-y-3 ml-[10px] pb-3">
								{updatedFiles?.map((file: any, index: number) => {
									return (
										<div className="flex gap-1">
											<div key={index}>
												{file?.mimeType?.includes("image") ? (
													<img
														className="object-fit max-w-[300px] max-j-[300px]"
														src={`data:image/${
															file?.extension === "svg"
																? "svg+xml"
																: file?.extension
														};base64,${file?.filePath}`}
														alt="No File"
													/>
												) : file?.mimeType === "application/pdf" ? (
													<object
														className={`${
															file?.mimeType === "application/pdf"
																? "w-full h-full"
																: "w-[250px] h-[250px]"
														}  rounded-md transition-all duration-300 image-pdf-modal`}
														data={file?.filePath}
													>
														<div className="w-full h-full flex justify-center items-center">
															<a
																href={file?.filePath}
																download={file?.filePath}
																className="cursor-pointer"
															>
																Click to download
															</a>
														</div>
													</object>
												) : (
													<div className="border shadow-md max-w-[250px] ">
														<p className="w-[250px] h-[100px] bg-[#f3f4f6] flex items-center justify-center">
															<AiOutlineFile className="w-6" />
														</p>
														<p className="bg-white p-2 font-medium text-black whitespace-pre-wrap">
															{file?.name}
														</p>
													</div>
												)}
											</div>
											<TbTrashFilled
												className="w-4 h-4 cursor-pointer"
												onClick={() => {
													setUpdatedFiles(
														updatedFiles?.filter(
															(fileList: { id: number }) =>
																fileList?.id !== file?.id,
														),
													);
												}}
											/>
										</div>
									);
								})}
							</div>
							<div className="space-y-3 ml-[10px] pb-3">
								{fileList?.map((file: any, index: number) => {
									return (
										<div className="flex gap-1">
											<div key={index}>
												{file?.fileDetails?.type?.includes("image") ? (
													<img
														className="object-fit w-[250px] h-[250px]"
														src={file?.file}
														alt="No File"
													/>
												) : file?.fileDetails?.type === "application/pdf" ? (
													<object
														className={`${
															detectMimeType(file?.fileDetails?.type) ===
															"application/pdf"
																? "w-full h-full"
																: "w-[250px] h-[250px]"
														}  rounded-md transition-all duration-300 image-pdf-modal`}
														data={file?.file}
													>
														<div className="w-full h-full flex justify-center items-center">
															<a
																href={file?.file}
																download={file?.file}
																className="cursor-pointer"
															>
																Click to download
															</a>
														</div>
													</object>
												) : (
													<div className="border shadow-md max-w-[250px] ">
														<p className="w-[250px] h-[100px] bg-[#f3f4f6] flex items-center justify-center">
															<AiOutlineFile className="w-6" />
														</p>
														<p className="bg-white p-2 font-medium text-black whitespace-pre-wrap">
															{file?.fileDetails?.name}
														</p>
													</div>
												)}
											</div>
											<TbTrashFilled
												className="w-4 h-4 cursor-pointer"
												onClick={() => {
													setFileList(
														fileList?.filter(
															(fileList: { fileDetails: { name: string } }) =>
																fileList?.fileDetails?.name !==
																file?.fileDetails?.name,
														),
													);
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
						<div className="flex gap-3 pt-3">
							<Outlined buttonName="Cancel" onClick={cacelButtonHandler} />
							<Filled
								buttonName={`${editComment?.id ? "Update" : "Add"}`}
								disabled={
									saveProjectTaskCommentLoading ||
									(editor?.getText()?.trim().length === 0 &&
										fileList?.length === 0)
								}
								loading={saveProjectTaskCommentLoading}
								onClick={createOrUpdateProjectTaskComment}
							/>
						</div>
					</div>
				</Fragment>
			) : null}
		</Fragment>
	);
};

export default TipTap;
