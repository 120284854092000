import { FC } from "react";
import { Routes, Route } from "react-router-dom";

import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import EditProjectGroupTaskRouteControl from "modules/Project/Pages/Projects/ProjectGroupTasks/EditProjectGroupTaskRouteControl";
import ProjectGroupTasks from "modules/Project/Pages/Projects/ProjectGroupTasks/ProjectGroupTasks";

interface Props {
	allowedResources: string[];
	projectGroup: ProjectGroup | null;
}

const ProjectGroupTasksRouteControl: FC<Props> = ({
	allowedResources,
	projectGroup,
}) => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<ProjectGroupTasks
						allowedResources={allowedResources}
						projectGroup={projectGroup}
					/>
				}
			/>
			<Route
				path=":projectGroupTaskId/*"
				element={<EditProjectGroupTaskRouteControl />}
			/>
		</Routes>
	);
};

export default ProjectGroupTasksRouteControl;
