import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { TextAreaProps } from "components/forms/UpdatedFormAgry/TextArea/types";

const TextArea = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  className = "",
  name,
  control,
  defaultValue,
  maxLength,
  minLength,
  onChange,
  disabled,
  label,
  hideLabel,
  hideError,
  classNameForError = "",
  shouldUnregister = true,
  required,
  classForTextArea,
  placeholder,
}: TextAreaProps<TFieldValues, Name> &
  (FieldPathValue<TFieldValues, Name> extends string | null | undefined
    ? {
        helperType?: never;
        invalidType?: never;
      }
    : {
        helperType?: undefined;
        invalidType: "Type must be string | null | undefined";
      })) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        onChange: (e: any) => {
          if (onChange) {
            onChange(e.target?.value);
          }
        },
        required:
          typeof required === "boolean" && required === true
            ? "This is required field."
            : required
            ? required
            : false,
      }}
      render={({ field: { value, ...rest }, fieldState: { error } }) => {
        const errorMessage = error?.message;
        return (
          <div className="w-full">
            <div
              className={twMerge(
                "w-full relative border rounded flex justify-center items-center min-h-[126px]",
                disabled
                  ? "cursor-not-allowed bg-surface-disabled border-disabled/50"
                  : errorMessage
                  ? "bg-white border-error focus-within:border-error"
                  : "bg-white border focus-within:border-primary",
                className
              )}
            >
              <textarea
                {...rest}
                value={value || ""}
                id={name}
                placeholder={placeholder ? placeholder : "Enter..."}
                maxLength={maxLength || undefined}
                minLength={minLength || undefined}
                className={twMerge(
                  "w-full rounded-[inherit] min-h-[inherit] peer appearance-none focus:outline-none bg-inherit px-4 py-2 resize-none",
                  disabled ? "cursor-not-allowed text-disabled" : "",
                  label ? "placeholder-transparent" : "",
                  classForTextArea
                )}
              />
              {!hideLabel && label && (
                <label
                  htmlFor={name}
                  className={twMerge(
                    "px-0.5 bg-inherit absolute max-w-[calc(100%-10%)] truncate -top-2 left-3.5 text-xs peer-placeholder-shown:top-2 peer-placeholder-shown:text-sm transition-all duration-150 peer-focus:-top-2 peer-focus:text-xs ",
                    disabled
                      ? "text-disabled cursor-not-allowed"
                      : errorMessage
                      ? "text-error peer-focus:text-error"
                      : "peer-focus:text-primary text-warm-gray/70"
                  )}
                >
                  {label}
                </label>
              )}
            </div>
            {hideError ? null : (
              <div className={`w-[inherit] h-min ${classNameForError}`}>
                <span
                  className={`text-error text-xs ${
                    errorMessage ? "visible" : "invisible"
                  } `}
                >
                  {errorMessage ? errorMessage : "Helper Text"}
                </span>
              </div>
            )}
          </div>
        );
      }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default TextArea;
