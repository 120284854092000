import { FC, useCallback, useEffect, useRef, useState } from "react";
import { RiCloseLine } from "react-icons/ri";

import { IToastedNotification } from "components/UI/ToastNotification/type";

import { ISetStateType } from "global/types/type";
import { toastNotify } from "global/helpers/Cache";

type TProps = IToastedNotification & {
  setNotificationArray: ISetStateType<IToastedNotification[]>;
};

const ToastNotification: FC<TProps> = ({
  message,
  messageType,
  setNotificationArray,
}) => {
  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>(null!);
  const paseTimer = useRef(true);
  const handleStartTimer = () => {
    paseTimer.current = true;
    const id = setInterval(() => {
      if (paseTimer.current) {
        setWidth((prev) => {
          if (prev < 100) {
            return prev + 0.5;
          }
          clearInterval(id);
          return prev;
        });
      }
    }, 15);
    setIntervalID(id);
  };

  const handlePauseTimer = useCallback(() => {
    clearInterval(intervalID);
    paseTimer.current = false;
  }, [intervalID]);

  const handleCloseNotification = useCallback(() => {
    handlePauseTimer();
    setExit(true);
    setTimeout(() => {
      setNotificationArray([]);
      toastNotify([]);
    }, 200);
  }, [handlePauseTimer, setNotificationArray]);

  useEffect(() => {
    if (width === 100) {
      handleCloseNotification();
    }
  }, [width, handleCloseNotification]);

  useEffect(() => {
    handleStartTimer();
  }, []);

  return (
    <div
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      className={`toast-notification-item ${
        messageType === "success" ? "toast-success" : "toast-error"
      } ${exit ? "toast-exit" : ""} bg-white`}
    >
      <div className="w-full flex items-center px-1 gap-1">
        <p className="text-ironside-gray text-sm flex-1">{message}</p>
        <span
          onClick={handleCloseNotification}
          className="w-6 h-6 rounded-full bg-slate-200 flex justify-center items-center cursor-pointer"
        >
          <RiCloseLine className="w-5 h-5" />
        </span>
      </div>
      <div className={"toast-bar"} style={{ width: `${width}%` }} />
    </div>
  );
};

export default ToastNotification;
