import { FC, useState } from "react";

import { TbCopy } from "react-icons/tb";

import { Base64toObject } from "global/helpers/FileConverter";
import { ISetStateType } from "global/types/type";

import { IProjectVault } from "modules/Project/types/project";
import { TVaultCredentialTypeFields } from "modules/Project/types/vault";
import ShowVaultPassword from "modules/Project/Pages/Vault/ShowVaultPassword";
import ToolTip from "components/UI/ToolTip";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { downloadPDFUsingFunction } from "global/helpers/getFileExtension";
import { HiExternalLink } from "react-icons/hi";
import { BiTrash } from "react-icons/bi";

interface IProps {
  credential: IProjectVault;
  createOrUpdateAccess: boolean;
  canDeleteVault: boolean;
  setShowModal: ISetStateType<boolean>;
  setEditProjectVaultCredential: ISetStateType<IProjectVault | null>;
  setProjectVaultCredentialID: ISetStateType<number | null>;
  setDeleteVerification: ISetStateType<boolean>;
}

const VaultCredential: FC<IProps> = ({
  credential,
  canDeleteVault,
  createOrUpdateAccess,
  setEditProjectVaultCredential,
  setProjectVaultCredentialID,
  setShowModal,
  setDeleteVerification,
}) => {
  const [showCredentials, setShowCredentials] = useState(true);
  const showCredentialsHandler = () => {
    setShowCredentials(!showCredentials);
  };
  const [copyText, setCopyText] = useState(false);

  return (
    <div className="space-y-3 pb-3">
      <div
        onClick={showCredentialsHandler}
        className="p-3 bg-gray-200 cursor-pointer rounded-md flex justify-between"
      >
        <span className="text-sm">{credential?.environment?.name}</span>
      </div>
      {showCredentials && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {credential?.environment?.type?.map(
            (
              credentialType: {
                id: number;
                name: string;
                credentials: any;
              },
              index: number
            ) => {
              const credentials: any[] = credentialType?.credentials?.reduce(
                (results: any, org: any) => {
                  (results[org?.credentialId] =
                    results[org?.credentialId] || []).push(org);
                  return results;
                },
                {}
              )
                ? Object.values(
                    credentialType?.credentials?.reduce(
                      (results: any, org: any) => {
                        (results[org?.credentialId] =
                          results[org?.credentialId] || []).push(org);
                        return results;
                      },
                      {}
                    )
                  )
                : [];

              return credentials?.map(
                (
                  credentialField: {
                    credentialId: number;
                    field: {
                      id: number;
                      name: string;
                      fieldType: TVaultCredentialTypeFields;
                    };
                    id: number;
                    value: string;
                    extension?: string;
                    mimeType?: string;
                    fileName?: string;
                  }[],
                  index
                ) => {
                  const titelField =
                    credentialField?.filter(
                      (credential: { field: { name: string } }) =>
                        credential?.field?.name === "Title"
                    ) || [];

                  const title =
                    credentialField?.length > 0 && titelField[0]?.value;

                  return (
                    <div
                      key={index}
                      className="p-6  rounded-md shadow-lg border overflow-x-auto group"
                    >
                      <div className="flex mb-3">
                        <h2 className="text-[#212224] text-base capitalize">
                          {`${credentialType?.name} `}
                          {title || ""}
                        </h2>
                        {(createOrUpdateAccess || canDeleteVault) && (
                          <div className="flex flex-1 justify-end items-center gap-3">
                            {createOrUpdateAccess && (
                              <HiExternalLink
                                className="w-5 h-5 text-ironside-gray cursor-pointer"
                                onClick={() => {
                                  setShowModal(true);
                                  setEditProjectVaultCredential(credential);
                                  setProjectVaultCredentialID(
                                    credentialField[0]?.credentialId
                                  );
                                }}
                              />
                            )}
                            {canDeleteVault && (
                              <BiTrash
                                className="w-5 h-5 text-ironside-gray cursor-pointer"
                                onClick={() => {
                                  setProjectVaultCredentialID(
                                    credentialField[0]?.credentialId
                                  );
                                  setDeleteVerification(true);
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        key={index}
                        className="border-b last:border-none first:mt-5 flex items-center gap-10 odd:bg-slate-50 rounded-md p-1"
                      >
                        <div>
                          {credentialField?.map((credential, index) => {
                            return credential?.value &&
                              credential?.field?.name !== "Title" ? (
                              <div
                                key={index}
                                className="grid grid-cols-[100px_auto] gap-5 py-2 items-center"
                              >
                                <p className="truncate min-w-[100px] max-w-[150px] flex justify-between text-ironside-gray">
                                  <span className="truncate">
                                    {credential?.field?.name}
                                  </span>
                                  <span>:</span>
                                </p>
                                <div>
                                  {(credential?.field?.fieldType === "Text" &&
                                    credential?.field?.name !== "Title") ||
                                  credential?.field?.fieldType ===
                                    "Text Area" ? (
                                    <div className="max-w-[250px] flex justify-between items-center gap-5">
                                      <span className="truncate">
                                        {credential?.value}
                                      </span>
                                      <ToolTip
                                        render={() =>
                                          copyText ? "Copied" : "Copy"
                                        }
                                        arrow
                                        className={
                                          "text-center bg-[#616161] text-white"
                                        }
                                      >
                                        <TbCopy
                                          className="min-w-[20px] min-h-[20px] cursor-pointer invisible group-hover:visible"
                                          onMouseEnter={() => {
                                            setCopyText(false);
                                          }}
                                          onClick={() => {
                                            setCopyText(true);
                                            navigator.clipboard.writeText(
                                              credential?.value
                                            );
                                          }}
                                        />
                                      </ToolTip>
                                    </div>
                                  ) : credential?.field?.fieldType === "URL" ? (
                                    <div className="max-w-[250px] flex justify-between gap-5">
                                      <a
                                        href={credential?.value}
                                        target={"_blank"}
                                        className="text-sm hover:underline text-cornflower-blue truncate"
                                        rel="noreferrer"
                                      >
                                        {credential?.value}
                                      </a>
                                      <ToolTip
                                        render={() =>
                                          copyText ? "Copied" : "Copy"
                                        }
                                        arrow
                                        className={
                                          "text-center bg-[#616161] text-white"
                                        }
                                      >
                                        <TbCopy
                                          className="min-w-[20px] min-h-[20px] invisible group-hover:visible cursor-pointer"
                                          onMouseEnter={() => {
                                            setCopyText(false);
                                          }}
                                          onClick={() => {
                                            setCopyText(true);
                                            navigator.clipboard.writeText(
                                              credential?.value
                                            );
                                          }}
                                        />
                                      </ToolTip>
                                    </div>
                                  ) : credential?.field?.fieldType ===
                                    "Password" ? (
                                    credential?.value && (
                                      <ShowVaultPassword
                                        value={credential?.value}
                                      />
                                    )
                                  ) : credential?.field?.fieldType === "File" &&
                                    credential?.extension ? (
                                    [".pdf", ".jpg", ".jpeg", ".png"].includes(
                                      credential?.extension
                                    ) ? (
                                      <span>
                                        {credential?.value?.length <= 100 ? (
                                          <span className="text-gray-400">
                                            No File
                                          </span>
                                        ) : (
                                          <span className="flex gap-5">
                                            <a
                                              href={
                                                credential?.value &&
                                                Base64toObject(
                                                  credential?.value
                                                )
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                              className="flex items-center"
                                            >
                                              <HiExternalLink className="w-5 h-5 cursor-pointer text-cornflower-blue" />
                                            </a>
                                            <ToolTip
                                              render={() => "Download File"}
                                              arrow
                                              classNameForParent="w-min text-center"
                                              className="text-center bg-[#616161] text-white"
                                            >
                                              <span className="w-min">
                                                <MdOutlineDownloadForOffline
                                                  className="w-5 h-5 cursor-pointer text-cornflower-blue"
                                                  onClick={() => {
                                                    if (
                                                      credential?.extension &&
                                                      credential?.mimeType
                                                    ) {
                                                      downloadPDFUsingFunction(
                                                        credential?.value,
                                                        credential?.fileName
                                                          ? `${credential?.fileName}`
                                                          : `${new Date().toISOString()}${
                                                              credential?.extension
                                                            }`,
                                                        credential?.mimeType
                                                      );
                                                    }
                                                  }}
                                                />
                                              </span>
                                            </ToolTip>
                                          </span>
                                        )}
                                      </span>
                                    ) : credential?.extension &&
                                      credential?.mimeType ? (
                                      <ToolTip
                                        render={() => "Download File"}
                                        arrow
                                        classNameForParent="w-min text-center"
                                        className="text-center bg-[#616161] text-white"
                                      >
                                        <span className="w-min">
                                          <MdOutlineDownloadForOffline
                                            className="w-5 h-5 cursor-pointer text-cornflower-blue"
                                            onClick={() => {
                                              if (
                                                credential?.extension &&
                                                credential?.mimeType
                                              ) {
                                                downloadPDFUsingFunction(
                                                  credential?.value,
                                                  credential?.fileName
                                                    ? `${credential?.fileName}`
                                                    : `${new Date().toISOString()}${
                                                        credential?.extension
                                                      }`,
                                                  credential?.mimeType
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                      </ToolTip>
                                    ) : (
                                      <span className="text-gray-400">
                                        This file can't be downloaded.
                                      </span>
                                    )
                                  ) : null}
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default VaultCredential;
