import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";

import { Filled, Outlined, UnderReviewBtn } from "components/forms";
import Tree from "./Tree";
import { childNodesTypes } from "./Row";

import { toastNotify } from "global/helpers/Cache";

import { CREATE_ROLE, UPDATE_ROLE } from "modules/Settings/services/mutations";

const RoleResources = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const [inProgress, setInProgress] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const [createRole] = useMutation(CREATE_ROLE);
  const [updateRole] = useMutation(UPDATE_ROLE);

  const [aclTree, setAclTree] = useState<childNodesTypes[]>(state?.aclTree);

  const [changedACLTree, setChangedACLTree] = useState<childNodesTypes>();

  const changeIsAllowed = (aclTree: childNodesTypes[]) => {
    if (aclTree?.length === 0) return [];
    let checkbox = false;
    if (changedACLTree) {
      checkbox = changedACLTree.isAllowed;
    }
    return aclTree?.map((treeDetail) => {
      treeDetail.isAllowed = checkbox;
      treeDetail.isIndeterminateStatus = false;
      if (treeDetail?.childNodes?.length > 0) {
        changeIsAllowed(treeDetail?.childNodes);
      }
      return treeDetail;
    });
  };

  const setACLTreeStructure = (aclTree: childNodesTypes[]) => {
    let checkbox = false;
    if (changedACLTree) {
      checkbox = changedACLTree.isAllowed;
    }
    return aclTree?.map((treeDetail) => {
      if (changedACLTree?.id === treeDetail.id) {
        treeDetail.isAllowed = checkbox;
        treeDetail.childNodes = changeIsAllowed(treeDetail?.childNodes);
      }
      if (treeDetail?.childNodes?.length > 0) {
        setACLTreeStructure(treeDetail?.childNodes);
      }
      return treeDetail;
    });
  };

  setACLTreeStructure(aclTree);

  const setCheckBoxValueChildNode = useCallback((aclTree: any) => {
    if (aclTree?.length === 0) return [];

    return aclTree?.map((treeDetail: childNodesTypes, index: number) => {
      if (treeDetail?.childNodes?.length > 0) {
        const isAllowedArray: boolean[] = [];
        const getAllChildrenAllowedStatus = (treeData: childNodesTypes[]) => {
          treeData?.map((treeData) => {
            isAllowedArray.push(treeData?.isAllowed);
            if (treeData?.childNodes?.length > 0) {
              getAllChildrenAllowedStatus(treeData?.childNodes);
            }
            return null;
          });
        };

        getAllChildrenAllowedStatus([aclTree[index]]);
        if (isAllowedArray?.every((isAllowed: boolean) => isAllowed)) {
          return {
            ...treeDetail,
            isAllowed: true,
            isIndeterminateStatus: false,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        } else if (
          treeDetail?.childNodes?.some(
            (childNode: any) => childNode?.isAllowed === true
          )
        ) {
          return {
            ...treeDetail,
            isAllowed: true,
            isIndeterminateStatus: true,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        } else {
          return {
            ...treeDetail,
            isAllowed: false,
            isIndeterminateStatus: false,
            childNodes:
              treeDetail?.childNodes?.length > 0
                ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                : [],
          };
        }
      }
      return treeDetail;
    });
  }, []);

  const setCheckBoxValue = useCallback(
    (aclTree: childNodesTypes[]) => {
      return aclTree?.map((treeDetail: childNodesTypes, index: number) => {
        if (treeDetail?.childNodes?.length > 0) {
          const isAllowedArray: boolean[] = [];
          const getAllChildrenAllowedStatus = (treeData: childNodesTypes[]) => {
            treeData?.map((treeData) => {
              isAllowedArray.push(treeData?.isAllowed);
              if (treeData?.childNodes?.length > 0) {
                getAllChildrenAllowedStatus(treeData?.childNodes);
              }
              return null;
            });
          };

          getAllChildrenAllowedStatus([aclTree[index]]);

          if (isAllowedArray?.every((isAllowed: boolean) => isAllowed)) {
            return {
              ...treeDetail,
              isIndeterminateStatus: false,
              isAllowed: true,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          } else if (
            treeDetail?.childNodes?.some(
              (childNode: any) => childNode?.isAllowed === true
            )
          ) {
            return {
              ...treeDetail,
              isAllowed: true,
              isIndeterminateStatus: true,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          } else {
            return {
              ...treeDetail,
              isAllowed: false,
              isIndeterminateStatus: false,
              childNodes:
                treeDetail?.childNodes?.length > 0
                  ? setCheckBoxValueChildNode(treeDetail?.childNodes)
                  : [],
            };
          }
        } else {
        }
        return treeDetail;
      });
    },
    [setCheckBoxValueChildNode]
  );

  const setAclTreeRef = useRef(true);

  const onClickHandler = () => {
    setAclTree(setACLTreeStructure(aclTree));
    setAclTreeRef.current = true;
  };
  useEffect(() => {
    if (setAclTreeRef.current) {
      setAclTree(setCheckBoxValue(setCheckBoxValue(setCheckBoxValue(aclTree))));
      setAclTreeRef.current = false;
    }
  }, [aclTree, setCheckBoxValue, setAclTreeRef]);

  let allowedResourceIds: string[] = [];

  const getAllowedResourceIds = (treeData: childNodesTypes[]) => {
    treeData?.map((treeData) => {
      if (treeData.isAllowed) {
        allowedResourceIds.push(treeData?.id);
      }
      if (treeData?.childNodes?.length > 0) {
        getAllowedResourceIds(treeData?.childNodes);
      }
      return treeData;
    });
  };

  getAllowedResourceIds(aclTree);

  const roleCreateHandler = () => {
    if (!inProgress) {
      createRole({
        variables: {
          roleName: state.roleName,
          description: state.description,
          isActive: state.isActive,
          allowedResourceIds: allowedResourceIds,
        },
      })
        .then((response) => {
          setInProgress(false);
          navigate("/settings/role");
          toastNotify([
            {
              messageType: "success",
              message: "Your new entry has been successfully created.",
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const roleUpdateHanlder = () => {
    if (!inProgress) {
      updateRole({
        variables: {
          id: +state?.id,
          roleName: state.roleName ? state.roleName : undefined,
          description: state.description ? state.description : undefined,
          isActive: state.isActive,
          allowedResourceIds: allowedResourceIds,
        },
      })
        .then((response) => {
          setInProgress(false);
          navigate("/settings/role");
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div
      className={` ${
        state?.loading ? "flex justify-center items-center" : ""
      } min-h-[600px] overflow-x-auto py-[30px]`}
    >
      {state?.loading ? (
        <div className="loader-design" />
      ) : (
        <React.Fragment>
          <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-16">
            <p className="text-ironside-gray flex justify-between h-min md:w-[136.52px] md:text-right">
              <span>Resources</span>
              <span className="block md:hidden">
                {expandAll ? (
                  <UnderReviewBtn
                    onClick={() => setExpandAll(false)}
                    buttonName={"Collapse All"}
                  />
                ) : (
                  <UnderReviewBtn
                    onClick={() => setExpandAll(true)}
                    buttonName={"Expand All"}
                  />
                )}
              </span>
            </p>
            <div className="320:w-[270px] md:min-w-[300px] lg:min-w-[415px]">
              <Tree
                aclTree={aclTree}
                level={0}
                setChangedACLTree={setChangedACLTree}
                onClickHandler={onClickHandler}
                expandAll={expandAll}
              />
            </div>
            <div className="hidden md:block flex-col text-right right-0">
              {expandAll ? (
                <UnderReviewBtn
                  onClick={() => setExpandAll(false)}
                  buttonName={"Collapse All"}
                />
              ) : (
                <UnderReviewBtn
                  onClick={() => setExpandAll(true)}
                  buttonName={"Expand All"}
                />
              )}
            </div>
          </div>
          <div className="320:w-[250px] md:w-[400px] button-div md:ml-16">
            <Outlined
              onClick={() => {
                setInProgress(false);
                navigate("/settings/role");
              }}
              buttonName={"Cancel"}
            />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : state?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
              onClick={() => {
                if (state?.id) {
                  roleUpdateHanlder();
                } else {
                  roleCreateHandler();
                }
              }}
            />
          </div>
          <HiArrowNarrowLeft
            className="back-arrow-icon mx-auto"
            onClick={() => {
              navigate("/settings/role/edit-role", {
                state: state,
              });
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default RoleResources;
