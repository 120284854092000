import { TypedDocumentNode, gql } from "@apollo/client";
import { PAGINATION_INFO } from "global/services";

import { PROJECT_GROUP_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroups/services/fragments";
import {
  FilterProjectGroupArgs,
  FilterProjectGroupReturnType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";

export const FILTER_PROJECT_GROUPS: TypedDocumentNode<
  FilterProjectGroupReturnType,
  FilterProjectGroupArgs
> = gql`
  ${PROJECT_GROUP_FRAGMENT}
  ${PAGINATION_INFO}
  query FilterProjectGroup(
    $filters: ProjectGroupFilterInput
    $globalSearch: String
    $pagination: Pagination
    $orderBy: OrderByField
    $isDescriptionNeed: Boolean = false
  ) {
    filterProjectGroups: filterProjectGroup(
      filters: $filters
      globalSearch: $globalSearch
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...projectGroupFields
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;

export const GET_PROJECT_GROUP_NAMES = gql`
  ${PAGINATION_INFO}
  query getProjectGroupNames($search: String, $pagination: Pagination) {
    getDropDownField: filterProjectGroup(
      filters: { name: { contains: $search } }
      pagination: $pagination
    ) {
      edges {
        node {
          id: name
          name
        }
      }
      pageInfo {
        ...paginationInfoFields
      }
    }
  }
`;
