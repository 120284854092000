import { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";

import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import { SearchField } from "components/forms";
import Pagination from "components/Pagination/Pagination";

import { paginationDefaultValue } from "global/helpers/StaticData";

import { ACTION_LOGS } from "modules/Settings/services/queries";
import OopsNoData from "components/UI/OopsNoData";
import Loading from "components/Loader/Loading";

export interface ActionLogsType {
  actionType: string;
  attributeCode: string;
  entityId: number;
  entityType: string;
  id: string;
  performedAt: string;
  performedBy: string;
  previousValue: string;
  updatedValue: string;
}
export const tableHeadings = [
  "Entity Type",
  "Action Type",
  "Attribute Code",
  "Performed At",
  "Performed By",
  "Previous Value",
  "Updated Value",
];
const ActionLogs = () => {
  const { register, watch, setValue } = useForm();
  const [fetchActionLogs, { data: actionLogsData, loading }] = useLazyQuery(
    ACTION_LOGS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [actionLogs, setActionLogs] = useState<ActionLogsType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const employee = watch("employee");
  const [search, setSearch] = useState(employee || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(employee?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [employee]);

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      fetchActionLogs({
        variables: {
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
        },
      });
    }, 300);

    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [currentPage, search, pageSize, fetchActionLogs]);

  useEffect(() => {
    setActionLogs(actionLogsData?.actionLogs?.dataCollection);
  }, [actionLogsData]);
  const parentRef = useRef<HTMLDivElement>(null);
  const getDataModal = (actionLog: ActionLogsType) => [
    {
      lable: "Entity Type",
      value: [
        {
          content: actionLog?.entityType,
        },
      ],
    },
    {
      lable: "Action Type",
      value: [
        {
          content: actionLog?.actionType,
        },
      ],
    },
    {
      lable: "Attribute Code",
      value: [
        {
          content: actionLog?.attributeCode,
        },
      ],
    },
    {
      lable: "Performed At",
      value: [
        {
          content: actionLog?.performedAt,
        },
      ],
    },
    {
      lable: "Performed By",
      value: [
        {
          content: actionLog?.performedBy,
        },
      ],
    },
    {
      lable: "Previous Value",
      value: [
        {
          content: actionLog?.previousValue,
        },
      ],
    },
    {
      lable: "Updated Value",
      value: [
        {
          content: actionLog?.updatedValue?.startsWith("data:application")
            ? "File Upload"
            : actionLog?.updatedValue,
        },
      ],
    },
  ];
  return (
    <div className={"min-h-[70vh]"}>
      <Fragment>
        <div className="w-full flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row py-[25px]">
          <SearchField
            register={register}
            name="employee"
            label="Employee"
            onChange={() => {
              setCurrentPage(1);
            }}
            setValue={setValue}
          />
        </div>
        <div className="md:hidden mx-auto">
          {loading ? (
            <Loading className="min-h-[70vh]" />
          ) : (
            <div className="w-full flex flex-col justify-center mt-5">
              {actionLogs?.length > 0 ? (
                <div>
                  {actionLogs?.map(
                    (actionLog: ActionLogsType, index: number) => {
                      return (
                        <SMDataCard
                          key={actionLog?.id}
                          title={`Log ${index + 1}`}
                          loading={loading}
                          dataModal={getDataModal(actionLog)}
                        />
                      );
                    }
                  )}
                </div>
              ) : (
                actionLogsData &&
                actionLogs?.length === 0 && (
                  <OopsNoData className="min-h-[70vh]" />
                )
              )}
              {actionLogs?.length > 0 && (
                <Pagination
                  totalCount={
                    actionLogsData?.actionLogs?.totalNumberOfItems || 0
                  }
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page: number) => {
                    setCurrentPage(page);
                  }}
                  noOfItem={actionLogs?.length}
                />
              )}
            </div>
          )}
        </div>
        <div className="hidden md:flex flex-col justify-between w-full min-h-[68vh]">
          <Table
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center:
                  name === "Performed By" || name === "Entity Type"
                    ? false
                    : true,
              };
            })}
            noOfItems={actionLogsData && actionLogs?.length}
            ref={parentRef}
            currentPage={currentPage}
            loading={loading}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            totalCount={
              actionLogsData
                ? actionLogsData?.actionLogs?.totalNumberOfItems
                : 0
            }
            setPageSize={setPageSize}
          >
            {actionLogs?.map((actionLog) => {
              let tableContent = [
                { value: actionLog?.entityType },
                { value: actionLog?.actionType, center: true },
                { value: actionLog?.attributeCode, center: true },
                { value: actionLog?.performedAt, center: true },
                { value: actionLog?.performedBy },
                { value: actionLog?.previousValue, center: true },
                { value: actionLog?.updatedValue, center: true },
              ];
              return (
                <TableContent
                  key={+actionLog?.id}
                  id={+actionLog?.id}
                  parentRef={parentRef}
                  tableContent={tableContent}
                />
              );
            })}
          </Table>
        </div>
      </Fragment>
    </div>
  );
};

export default ActionLogs;
