import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { toastNotify } from "global/helpers/Cache";

import { SAVE_PROJECT_TASK_TITLE } from "modules/Project/services/mutations";

interface IProps {
  projectTasktitle: string;
  canSaveTitle: boolean;
}

const Title: FC<IProps> = ({ projectTasktitle, canSaveTitle }) => {
  const [saveProjectTaskTitle, { loading: projectTaskTitleLoading }] =
    useMutation(SAVE_PROJECT_TASK_TITLE);

  const { id, issueId } = useParams();

  const headingRef = useRef<HTMLHeadingElement | any>(null!);
  const [editProjectTaskTitle, setEditProjectTaskTitle] =
    useState<string>(projectTasktitle);

  const projectTaskVariables = {
    id: Number(issueId),
    projectId: Number(id),
  };

  useEffect(() => {
    setEditProjectTaskTitle(projectTasktitle);
  }, [projectTasktitle]);

  const projectTaskTitleHandler = () => {
    if (
      !projectTaskTitleLoading &&
      id &&
      !isNaN(+id) &&
      issueId &&
      !isNaN(+issueId) &&
      projectTasktitle !== headingRef?.current?.textContent?.trim() &&
      canSaveTitle
    ) {
      saveProjectTaskTitle({
        variables: {
          projectTaskInput: projectTaskVariables,
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "Task title is changed successfully.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  const onBlurHandler = () => {
    if (headingRef?.current?.textContent?.trim()?.length <= 0) {
      toastNotify([
        {
          messageType: "error",
          message: "Please enter a title for the task",
        },
      ]);
      headingRef?.current?.focus();
      return;
    }

    if (
      !projectTaskTitleLoading &&
      id &&
      !isNaN(+id) &&
      issueId &&
      !isNaN(+issueId) &&
      projectTasktitle !== headingRef?.current?.textContent?.trim() &&
      canSaveTitle
    ) {
      saveProjectTaskTitle({
        variables: {
          projectTaskInput: {
            ...projectTaskVariables,
            title: headingRef?.current?.textContent?.trim(),
          },
        },
      })
        .then(() => {
          toastNotify([
            {
              messageType: "success",
              message: "Task title is changed successfully.",
            },
          ]);
        })
        .catch((error) => {
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
    }
  };

  return (
    <h1
      ref={headingRef}
      suppressContentEditableWarning={true}
      contentEditable={canSaveTitle ? "true" : "false"}
      onBlur={onBlurHandler}
      className={`text-[#212224] text-[19px] mb-6 mt-2 p-2 ${
        canSaveTitle ? "cursor-pointer" : ""
      } w-full relative right-4 pl-[8px] leading-5 mb-3 pt-2 -translate-x-2 min-h-[50px] resize-none ml-4 ${
        canSaveTitle
          ? "hover:bg-[#f2f2f2] outline-none focus:border-2 focus:border-cornflower-blue"
          : "outline-none"
      }`}
      onKeyDown={(e: KeyboardEvent<HTMLHeadingElement>) => {
        if (e.key === "Enter") {
          e.preventDefault();
          projectTaskTitleHandler();
        }
      }}
    >
      {editProjectTaskTitle}
    </h1>
  );
};

export default Title;
