import { FC } from "react";

import Loading from "components/Loader/Loading";

interface IProps {
  className?: string;
  buttonName: string;
  onClick?: () => void;
  buttonType?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
}

const Filled: FC<IProps> = ({
  buttonName,
  buttonType,
  className,
  disabled,
  loading,
  onClick,
}) => {
  return (
    <button
      type={buttonType || "submit"}
      className={`${
        className ? className : "min-w-[110px] lg:min-w-[120px] px-2"
      }  h-[45px] text-sm ${
        loading || disabled
          ? disabled
            ? "cursor-not-allowed border border-gray-300 text-hit-gray bg-light-gray/80"
            : "cursor-not-allowed bg-cornflower-blue/90 ring-offset-2 focus:outline-none focus:ring-2 ring-cornflower-blue focus:border-cornflower-blue"
          : "cursor-pointer hover:bg-cornflower-blue/80 bg-cornflower-blue text-white ring-offset-2 focus:outline-none focus:ring-2 ring-cornflower-blue focus:border-cornflower-blue"
      } rounded text-sm`}
      onClick={loading || disabled ? undefined : onClick}
      disabled={disabled ? true : false}
    >
      {loading ? (
        disabled ? (
          buttonName
        ) : (
          <Loading smallLoading whiteColor />
        )
      ) : (
        buttonName
      )}
    </button>
  );
};

export default Filled;
