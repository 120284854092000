import { Tab } from "@headlessui/react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { IoMdClose } from "react-icons/io";

import { FilterOption } from "components/FilterUpdated/types";
import { notEmpty } from "global/helpers/ArrayMethods";

interface Props<TFieldValues extends FieldValues = FieldValues> {
  option: {
    name: string;
    fieldOption: FilterOption<TFieldValues>;
  };
  control: Control<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
}

const FilterTab = <TFieldValues extends FieldValues = FieldValues>({
  option,
  control,
  setValue,
}: Props<TFieldValues>) => {
  const filterValue = useWatch({
    control,
    name:
      option.fieldOption.type === "checkbox"
        ? [option.fieldOption.name]
        : option.fieldOption.type === "date"
        ? [option.fieldOption.name]
        : option.fieldOption.type === "string"
        ? [option.fieldOption.name]
        : option.fieldOption.type === "number"
        ? "nameFrom" in option.fieldOption
          ? [option.fieldOption.nameFrom, option.fieldOption.nameTo]
          : [option.fieldOption.name]
        : option.fieldOption.type === "textArea"
        ? [option.fieldOption.name]
        : ([] as any),
    defaultValue: null as unknown as PathValue<TFieldValues, any>,
  });

  const count = filterValue
    ? option.fieldOption.type === "checkbox"
      ? option.fieldOption.multiple
        ? Array.isArray(filterValue[0]) && filterValue[0]?.length > 0
          ? filterValue[0]?.length > 0
            ? filterValue[0]?.length
            : 0
          : 0
        : filterValue[0]
        ? 1
        : 0
      : option.fieldOption.type === "date"
      ? filterValue[0]
        ? 1
        : 0
      : option.fieldOption.type === "string"
      ? option.fieldOption.multiple
        ? Array.isArray(filterValue[0]) && filterValue[0]?.length > 0
          ? filterValue[0]?.length > 0
            ? filterValue[0]?.length
            : 0
          : filterValue[0]?.length
          ? 1
          : 0
        : filterValue[0]
        ? 1
        : 0
      : option.fieldOption.type === "number"
      ? "nameFrom" in option.fieldOption
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ? filterValue && filterValue?.filter(notEmpty)?.length > 0
          ? 1
          : 0
        : filterValue[0] || filterValue[0] === 0
        ? 1
        : 0
      : option.fieldOption.type === "textArea"
      ? filterValue[0] &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        filterValue[0]?.toString() &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        filterValue[0]?.toString()?.trim()
        ? 1
        : 0
      : 0
    : 0;

  return (
    <Tab
      as={"li"}
      className={({ selected }) =>
        twMerge(
          "pl-4 py-4 pr-2 cursor-pointer outline-none border-b border-l-4 border-b-outline-variant flex justify-between",
          selected ? "border-l-primary bg-surface" : "border-l-transparent"
        )
      }
    >
      <p className="text-start text-sm font-normal text-black truncate">
        {option.name}
      </p>

      <div className="flex gap-1">
        {count && count > 0 ? (
          <IoMdClose
            onClick={() => {
              if (option.fieldOption.type === "checkbox") {
                setValue(
                  option.fieldOption.name,
                  null as PathValue<TFieldValues, Path<TFieldValues>>
                );
              } else if (option.fieldOption.type === "date") {
                setValue(
                  option.fieldOption.name,
                  null as PathValue<TFieldValues, Path<TFieldValues>>
                );
              } else if (option.fieldOption.type === "string") {
                setValue(
                  option.fieldOption.name,
                  null as PathValue<TFieldValues, Path<TFieldValues>>
                );
              } else if (option.fieldOption.type === "number") {
                if ("nameFrom" in option.fieldOption) {
                  setValue(
                    option.fieldOption.nameFrom,
                    null as PathValue<TFieldValues, Path<TFieldValues>>
                  );
                  setValue(
                    option.fieldOption.nameTo,
                    null as PathValue<TFieldValues, Path<TFieldValues>>
                  );
                } else {
                  setValue(
                    option.fieldOption.name,
                    null as PathValue<TFieldValues, Path<TFieldValues>>
                  );
                }
              } else if (option.fieldOption.type === "textArea") {
                setValue(
                  option.fieldOption.name,
                  null as PathValue<TFieldValues, Path<TFieldValues>>
                );
              }
            }}
            className={`h-6 w-6 text-gray-400 p-1 cursor-pointer hover:bg-white-smoke rounded-full`}
          />
        ) : null}
        <span
          className={`flex text-sm font-normal text-white px-2 py-0.5 rounded-full bg-tertiary justify-center items-center ${
            count && count > 0 ? "visible" : "invisible"
          }`}
        >
          {count}
        </span>
      </div>
    </Tab>
  );
};

export default FilterTab;
