import { gql } from "@apollo/client";

export const GET_CURRENT_TIME = gql`
  query getCurrentTime {
    getCurrentTime  
  }
`;

export const PAGINATION_INFO = gql`
  fragment paginationInfoFields on PaginationInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalNumberOfItems
  }
`;
