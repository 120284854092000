import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import SignupAs from "./SignupAs";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import OTP from "./OTP";
import ResetSuccess from "./ResetSuccess";
import NotFound from "layouts/NotFound";
import PublicLayout from "layouts/PublicLayout";

const Account = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/signup-as" element={<SignupAs />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/reset-success" element={<ResetSuccess />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Account;
