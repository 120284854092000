import { TypedDocumentNode, gql } from "@apollo/client";

import {
  CreateProjectBugSourceArgs,
  CreateProjectBugSourceReturnType,
  DeleteProjectBugSourceArgs,
  DeleteProjectBugSourceReturnType,
  UpdateProjectBugSourceArgs,
  UpdateProjectBugSourceReturnType,
} from "modules/Project/Pages/ProjectGroupBugSource/types";
import { PROJECT_GROUP_BUG_SOURCE_FRAGMENT } from "modules/Project/Pages/ProjectGroupBugSource/services/fragments";

export const CREATE_PROJECT_GROUP_BUG_SOURCE: TypedDocumentNode<
  CreateProjectBugSourceReturnType,
  CreateProjectBugSourceArgs
> = gql`
  ${PROJECT_GROUP_BUG_SOURCE_FRAGMENT}
  mutation CreateProjectBugSource(
    $projectBugSourceCreateInput: ProjectBugSourceCreateInput
    $isProjectGroupBugSourceDescriptionNeed: Boolean = false
    $isProjectGroupBugSourceProjectGroupNeed: Boolean = false
  ) {
    createProjectBugSource(
      projectBugSourceCreateInput: $projectBugSourceCreateInput
    ) {
      ...projectBugSourceFields
    }
  }
`;

export const UPDATE_PROJECT_GROUP_BUG_SOURCE: TypedDocumentNode<
  UpdateProjectBugSourceReturnType,
  UpdateProjectBugSourceArgs
> = gql`
  ${PROJECT_GROUP_BUG_SOURCE_FRAGMENT}
  mutation UpdateProjectBugSource(
    $projectBugSourceUpdateInput: ProjectBugSourceUpdateInput
    $isProjectGroupBugSourceDescriptionNeed: Boolean = false
    $isProjectGroupBugSourceProjectGroupNeed: Boolean = false
  ) {
    updateProjectBugSource(
      projectBugSourceUpdateInput: $projectBugSourceUpdateInput
    ) {
      ...projectBugSourceFields
    }
  }
`;

export const DELETE_PROJECT_GROUP_BUG_SOURCE: TypedDocumentNode<
  DeleteProjectBugSourceReturnType,
  DeleteProjectBugSourceArgs
> = gql`
  mutation DeleteProjectBugSource($id: Int!) {
    deleteProjectBugSource(id: $id)
  }
`;
