import { useState, KeyboardEvent, useEffect, ChangeEvent } from "react";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { HiOutlineCalendar, HiX } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";

import { TInputProps } from "components/forms/UpdatedForm/types";
import { Path, PathValue } from "react-hook-form";

const Input = <TForm extends Record<string, string | number | any>>({
  errors,
  inputType,
  className = "",
  name,
  register,
  defaultValue,
  disabled,
  label,
  onChange,
  required,
  validationDeps,
  maximumNumber,
  minimumNumber,
  maxLength,
  minLength,
  pattern,
  placeholder,
  hideLabel,
  hideError = false,
  tabIndex,
  disablePasswordAutoComplete = false,
  shouldUnregister = true,
  classNameForError = "",
  fractionalValue = false,
  setValue,
  valueAsNumber,
  isPasswordValidationNeeded = true,
  isCloseIconNeeded = false,
}: TInputProps<TForm>) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };
  const errorMessage = errors
    ? typeof errors === "string"
      ? errors
      : errors[name]?.message?.toString()
    : undefined;

  const inputFieldType = inputType
    ? inputType === "password"
      ? showPassword
        ? "text"
        : "password"
      : inputType === "email"
      ? "text"
      : inputType === "aadhaar"
      ? "text"
      : inputType
    : "text";

  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      (inputType === "number" || inputType === "aadhaar") &&
      event?.currentTarget?.value?.length === maxLength &&
      /[0-9.+-:]/.test(event.key)
    ) {
      event.preventDefault();
    }
  };

  const inputFieldPattern =
    inputType === "email"
      ? {
          value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/,
          message: "Please enter a valid email.",
        }
      : inputType === "password" && isPasswordValidationNeeded
      ? {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
          message:
            "Password must have Special Characters,1 caps & minimum 8 characters.",
        }
      : inputType === "aadhaar"
      ? {
          value: /^\d{4}\d{4}\d{4}$/,
          message: "Please enter a valid aadhaar number.",
        }
      : pattern;

  useEffect(() => {
    if (defaultValue && setValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  return (
    <div
      className={`w-full relative text-ironside-gray ${
        disabled ? "cursor-not-allowed" : ""
      } ${className}`}
    >
      <input
        id={name}
        type={inputFieldType}
        placeholder={placeholder || "Enter..."}
        onKeyDown={keyDownHandler}
        autoComplete={disablePasswordAutoComplete ? "new-password" : "off"}
        max={
          maximumNumber
            ? inputType === "number"
              ? maximumNumber
              : undefined
            : undefined
        }
        tabIndex={tabIndex ? -1 : 0}
        min={
          minimumNumber
            ? inputType === "number" ||
              inputType === "date" ||
              inputType === "datetime-local"
              ? minimumNumber
              : undefined
            : undefined
        }
        disabled={disabled ? true : false}
        inputMode={
          inputFieldType === "number" && fractionalValue ? "decimal" : undefined
        }
        step={inputFieldType === "number" ? "any" : undefined}
        maxLength={
          inputType === "aadhaar" ? 12 : maxLength ? maxLength : undefined
        }
        className={`peer w-full h-[45px] bg-white border ${
          isCloseIconNeeded ? "pr-8" : ""
        }  ${
          errorMessage
            ? "border-valentine-red/70 peer-focus:border-valentine-red/70"
            : "focus:border-cornflower-blue"
        } ${inputType === "password" ? "pl-3 pr-8" : "px-3"} ${
          disabled ? "cursor-not-allowed text-warm-gray/80" : ""
        } placeholder-transparent rounded shadow text-sm text-ironside-gray outline-none py-2  appearance-none`}
        {...register(name, {
          required:
            typeof required === "boolean" && required === true
              ? "This is required field."
              : required
              ? required
              : false,
          shouldUnregister,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(e);
            setSearchValue(e.target.value);
          },
          max: maximumNumber
            ? inputType === "number"
              ? {
                  message: `Maximum number should be ${maximumNumber}`,
                  value: maximumNumber,
                }
              : undefined
            : undefined,
          min: minimumNumber
            ? inputType === "number"
              ? {
                  value: minimumNumber,
                  message: `Minimum number should be ${minimumNumber}`,
                }
              : undefined
            : undefined,
          maxLength:
            inputType === "aadhaar"
              ? {
                  value: 12,
                  message: `Maximum Length should be 12`,
                }
              : maxLength
              ? {
                  value: maxLength,
                  message: `Maximum Length should be ${maxLength}`,
                }
              : undefined,
          minLength:
            inputType === "aadhaar"
              ? {
                  value: 12,
                  message: `Maximum Length should be 12`,
                }
              : minLength
              ? {
                  value: minLength,
                  message: `Minimum Length should be ${minLength}`,
                }
              : undefined,
          pattern:
            inputType === "number" ? undefined : (inputFieldPattern as any),
          valueAsNumber: inputType === "number" ? true : valueAsNumber,
          deps: validationDeps
            ? Array.isArray(validationDeps)
              ? validationDeps
              : [validationDeps]
            : undefined,
        })}
      />

      {hideLabel ? null : (
        <label
          htmlFor={name}
          className={`absolute flex justify-between items-center whitespace-nowrap peer-placeholder-shown:top-3.5 bg-white peer-focus:w-min peer-placeholder-shown:w-[93%] peer-placeholder-shown:pb-1 placeholder:pr-4 pointer-events-none cursor-text peer-placeholder-shown:text-xs peer-focus:text-xxs  peer-focus:-top-1.5 text-xxs -top-1.5 transition-all duration-100 ease-in left-2  px-1 ${
            errorMessage
              ? "text-valentine-red peer-focus:text-valentine-red"
              : "text-ironside-gray/70 peer-focus:text-cornflower-blue"
          } ${disabled ? "cursor-not-allowed text-warm-gray/40" : ""}`}
        >
          {label}
        </label>
      )}
      {isCloseIconNeeded && searchValue?.trim()?.length > 0 && (
        <HiX
          className="w-5 h-5 absolute top-3 right-2 cursor-pointer"
          onClick={() => {
            setValue && setValue(name, "" as PathValue<TForm, Path<TForm>>);
            setSearchValue("");
          }}
        />
      )}

      {(inputType === "date" || inputType === "datetime-local") && (
        <label
          htmlFor={name}
          className="absolute top-3.5 right-3 bg-white pointer-events-none"
        >
          <HiOutlineCalendar className="w-4 h-4 text-ironside-gray/70 hover:cursor-pointer" />
        </label>
      )}

      {inputType === "time" && (
        <label
          htmlFor={name}
          className="absolute top-3.5 right-3.5 bg-white pointer-events-none"
        >
          <AiOutlineClockCircle className="w-5 h-5 text-ironside-gray hover:cursor-pointer" />
        </label>
      )}

      {inputType === "password" && (
        <div
          className="cursor-pointer text-light-gray absolute top-4 right-3"
          onClick={showPasswordHandler}
        >
          {showPassword ? (
            <AiFillEye className="w-3.5 h-3.5" />
          ) : (
            <AiFillEyeInvisible className="w-3.5 h-3.5" />
          )}
        </div>
      )}

      {hideError ? null : (
        <div className={`w-[inherit] h-min ${classNameForError}`}>
          <span
            className={`text-valentine-red text-xs ${
              errorMessage ? "visible" : "invisible"
            } `}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
    </div>
  );
};

export default Input;
