import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useForm } from "react-hook-form";

import { SearchField, Select } from "components/forms";
import SelectWithId from "components/forms/Select/SelectWithId";
import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { stateDateHash } from "components/forms/DatePicker/DateFiltering";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import OopsNoData from "components/UI/OopsNoData";
import { ITableContent } from "components/App/Table/types/table";

import { Capitalize } from "global/helpers/Capitalize";
import { monthsWithID, weekDays } from "global/helpers/StaticData";
import { getAllowedAccess, toastNotify } from "global/helpers/Cache";

import EditHoliday, {
  HolidayType,
} from "modules/Leave/Pages/Holiday/EditHoliday";
import { DELETE_HOLIDAY } from "modules/Leave/services/mutations";
import { HOLIDAYS_COLLECTION } from "modules/Leave/services/queries";

const Holiday = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);

  const allowedResources = useMemo(
    () => allowedResourcesList?.allowedResources || [],
    [allowedResourcesList]
  );

  const [createAccess, setCreateAccess] = useState<boolean>();

  const [fetchHolidays, { data: holidays, loading, refetch: refetchHolidays }] =
    useLazyQuery(HOLIDAYS_COLLECTION, {
      fetchPolicy: "cache-and-network",
    });

  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const reason = watch("reason");
  const [search, setSearch] = useState(reason);
  const watchMonth = watch("month");
  const watchYear = watch("year");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setCreateAccess(allowedResources?.includes("CreateHoliday"));
      setSearch(reason?.trim());
    }, 300);

    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [reason, allowedResources]);

  useEffect(() => {
    if (search !== undefined) {
      fetchHolidays({
        variables: {
          reason: search || undefined,
          holidayMonth: +watchMonth ? +watchMonth : undefined,
          holidayYear: +watchYear ? +watchYear : undefined,
          limit: pageSize,
          page: currentPage,
        },
      });
    }
  }, [pageSize, currentPage, search, fetchHolidays, watchMonth, watchYear]);

  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [holiday, setHoliday] = useState<any>();
  const [list, setList] = useState<any>();
  const [date, setDate] = useState<any>();

  let dates = holidays?.holidays?.dataCollection?.map((date: any) => {
    return {
      date: `${date.holidayYear}-${
        date.holidayMonth > 9 ? date.holidayMonth : "0" + date.holidayMonth
      }-${date.holidayDate > 9 ? date.holidayDate : "0" + date.holidayDate}`,
      id: date.id,
      isOptional: date.isOptional,
      reason: date.reason,
      company: date?.company || [],
      type: Capitalize(`${date?.type}` || ""),
    };
  });

  const sortFunction = (a: any, b: any) => {
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  };

  dates = dates?.sort(sortFunction);

  let yearOptions: string[] = [];
  const maxYearRange: number = new Date()?.getFullYear() + 1;

  for (let minYear = 2010; minYear <= maxYearRange; minYear++) {
    yearOptions?.push(minYear?.toString());
  }

  const tableHeadings = ["Date", "Day", "Reason", "Type"];
  const parentRef = useRef<HTMLDivElement>(null);
  const updateAccess = allowedResources?.includes("UpdateHoliday");
  const deleteAccess = allowedResources?.includes("DeleteHoliday");
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [deleteHoliday] = useMutation(DELETE_HOLIDAY);

  const deleteHandler = () => {
    deleteHoliday({
      variables: {
        id: +holiday?.id,
      },
      update: (cache) => {
        const exHolidays: {
          holidays: { dataCollection: HolidayType[] };
        } | null = cache.readQuery({
          query: HOLIDAYS_COLLECTION,
        });

        const updatedHolidays = exHolidays?.holidays?.dataCollection?.filter(
          (holiday: HolidayType) => holiday?.id !== +holiday?.id
        );

        cache.writeQuery({
          query: HOLIDAYS_COLLECTION,
          data: {
            holidays: { dataCollection: updatedHolidays },
          },
        });
      },
    })
      .then(() => {
        setList(null);
        setDeleteVerification(false);
        toastNotify([
          {
            messageType: "success",
            message: "Holiday has been deleted successfully.",
          },
        ]);
        refetchHolidays();
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };
  const plusIconClickHandler = () => {
    setShowModal(true);
    setHoliday(null);
    setDate(null);
  };
  const kebabMenuEditHandler = (date: Date | string) => {
    if (updateAccess) {
      setShowModal(true);
      setDate(new Date(`${date}`));
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };

  const kebabMenuClickHandler = (holidayDate: any) => {
    setHoliday(holidayDate);
    setList({
      id: holidayDate?.id,
      date: `${holidayDate?.date}`,
      reason: holidayDate?.reason,
      company: holidayDate?.company,
    });
  };

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] md:p-2 lg:px-[23px] bg-white py-[10px] overflow-hidden min-h-[600px] md:min-h-[760px] lg:min-h-[824px]">
      <div className="mt-8">
        <div className="grid grid-cols-[repeat(1,260px)] gap-2 sm:grid-cols-[repeat(2,260px)] sm:gap-5 xl:grid-cols-[repeat(4,260px)] 2xl:grid-cols-[repeat(4,250px)] justify-center items-center xl:justify-start">
          <div className="pb-6">
            <SearchField
              register={register}
              name="reason"
              label="Reason"
              onChange={() => {
                setCurrentPage(1);
              }}
              setValue={setValue}
            />
          </div>
          <SelectWithId
            register={register}
            name={"month"}
            errors={errors}
            label={"Month"}
            options={monthsWithID}
            enableDefaultSelectOption={true}
          />
          <Select
            register={register}
            name={"year"}
            errors={errors}
            label={"Year"}
            options={yearOptions ? yearOptions?.reverse() : []}
            defaultSelectValue={new Date()?.getFullYear()}
          />
        </div>
      </div>
      {dates?.length === 0 && !watchMonth && !search && createAccess ? (
        <div className="flex justify-center items-center min-h-[500px]">
          <button
            className="w-[150px] lg:w-[120px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 leading-[20px] rounded-[4px]"
            onClick={() => {
              setShowModal(true);
              setHoliday(null);
              setDate(null);
            }}
          >
            Add Holiday
          </button>
        </div>
      ) : (
        dates?.length === 0 && <OopsNoData className="min-h-[70vh]" />
      )}
      {dates?.length > 0 && (
        <div className="hidden md:block overflow-x-auto">
          <Table
            className="py-5 min-h-[612px]"
            noOfItems={dates?.length}
            createAccess={createAccess}
            ref={parentRef}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            loading={loading}
            onPlusIconClick={plusIconClickHandler}
            pageSize={pageSize}
            totalCount={holidays?.holidays?.totalNumberOfItems || 0}
            tableHeadingsObj={tableHeadings?.map((name: string) => {
              return {
                name,
                center: true,
              };
            })}
          >
            {dates?.map((holidayDate: any) => {
              let holidayDateFormatted = holidayDate?.date;
              let dateSplit = holidayDateFormatted.split("-");
              holidayDateFormatted =
                dateSplit[2] +
                "-" +
                stateDateHash[dateSplit[1]] +
                "-" +
                dateSplit[0];
              let tableContent: ITableContent[] = [
                {
                  value: holidayDateFormatted,
                  center: true,
                },
                {
                  value: weekDays[new Date(holidayDate?.date)?.getDay()],
                  center: true,
                },
                {
                  value: holidayDate?.reason,
                  className: "min-w-[130px]",
                  center: true,
                },
                {
                  value: holidayDate?.type,
                  center: true,
                },
                // {
                //   value:
                //     holidayDate?.company && holidayDate?.company?.length > 0
                //       ? holidayDate?.company
                //           ?.map((company) => company?.name)
                //           ?.join(", ")
                //       : "-",
                //   center: true,
                // },
              ];
              return (
                <TableContent
                  key={holidayDate?.id}
                  id={holidayDate?.id}
                  parentRef={parentRef}
                  tableContent={tableContent}
                  editAccess={updateAccess || deleteAccess}
                  showModal={showModal || deleteVerification}
                  onMenuClick={() => {
                    setHoliday(holidayDate);
                    setList({
                      id: holidayDate?.id,
                      date: `${holidayDate?.date}`,
                      reason: holidayDate?.reason,
                      company: holidayDate?.company,
                    });
                  }}
                  onEdit={() => {
                    kebabMenuEditHandler(holidayDate?.date);
                  }}
                  onDelete={kebabMenuDeleteHandler}
                />
              );
            })}
          </Table>
        </div>
      )}
      <div className="md:hidden overflow-x-auto min-h-[412px]">
        <div className="lg:mx-0 lg:px-[45px] bg-white m-[8px] mt-[40px] md:mt-[30px]">
          {loading ? (
            <div className="min-h-[500px] flex justify-center items-center">
              <div className="loader-design" />
            </div>
          ) : (
            <div>
              {dates?.map((holidayDate: any, index: number) => {
                let holidayDateFormatted = holidayDate?.date;
                let dateSplit = holidayDateFormatted.split("-");
                holidayDateFormatted =
                  dateSplit[2] +
                  "-" +
                  stateDateHash[dateSplit[1]] +
                  "-" +
                  dateSplit[0];

                return (
                  <SMDataCard
                    key={holidayDate?.id}
                    title={`Holiday ${index + 1}`}
                    loading={loading}
                    showModal={showModal || deleteVerification}
                    dataModal={[
                      {
                        lable: "Holiday Date",
                        value: [{ content: holidayDateFormatted }],
                      },
                      {
                        lable: "Days",
                        value: [
                          {
                            content:
                              weekDays[new Date(holidayDate?.date)?.getDay()],
                          },
                        ],
                      },
                      {
                        lable: "Reason",
                        value: [{ content: holidayDate?.reason }],
                      },
                      {
                        lable: "Type",
                        value: [{ content: holidayDate?.type }],
                      },
                      // {
                      //   lable: "Companies",
                      //   value: [
                      //     {
                      //       content:
                      //         holidayDate?.company &&
                      //         holidayDate?.company?.length > 0
                      //           ? holidayDate?.company
                      //               ?.map((company) => company?.name)
                      //               ?.join(", ")
                      //           : "-",
                      //     },
                      //   ],
                      // },
                    ]}
                    onKebabIcon={{
                      onclick: () => {
                        kebabMenuClickHandler(holidayDate);
                      },
                      kebabMenu: {
                        onEdit: () => {
                          kebabMenuEditHandler(holidayDate?.date);
                        },
                        onDelete: kebabMenuDeleteHandler,
                      },
                    }}
                  />
                );
              })}
            </div>
          )}
          {dates?.length !== 0 && (
            <Pagination
              totalCount={
                holidays?.holidays ? holidays?.holidays?.totalNumberOfItems : 0
              }
              currentPage={currentPage}
              setPageSize={setPageSize}
              siblingCount={1}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
              noOfItem={dates?.length}
            />
          )}
        </div>
      </div>
      {showModal && (
        <EditHoliday
          setDate={setDate}
          holiday={holiday}
          list={list}
          date={date}
          setList={setList}
          showModal={showModal}
          setShowModal={setShowModal}
          inProgress={inProgress}
          setInProgress={setInProgress}
          refetchHolidays={refetchHolidays}
        />
      )}
      {deleteVerification ? (
        <ConfirmModal
          header={"Holiday"}
          onCancel={() => {
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={() => deleteHandler()}
        />
      ) : null}
    </div>
  );
};
export default Holiday;
