import { ElementRef, useRef } from "react";
import { DateValue, FocusScope, useDatePicker } from "react-aria";
import { useDatePickerState } from "react-stately";
import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  getLocalTimeZone,
  now,
  today,
} from "@internationalized/date";
import { FieldPath, FieldValues, PathValue } from "react-hook-form";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import { Transition } from "@headlessui/react";
import { debounce } from "lodash";
import { twMerge } from "tailwind-merge";
import { IoMdClose } from "react-icons/io";

import { DateProps } from "components/forms/UpdatedFormAgry/Date/Date/types";
import DateField from "components/forms/UpdatedFormAgry/Date/Date/DateField";
import { CalendarButton } from "components/forms/UpdatedFormAgry/Date/Calendar/CalendarButton";
import Calendar from "components/forms/UpdatedFormAgry/Date/Calendar/Calendar";
import TimeField from "components/forms/UpdatedFormAgry/Date/Date/TimeField";

import { ReactComponent as CalendarIcon } from "global/assets/images/calendar_month.svg";

const DatePicker = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoFocus,
  defaultOpen,
  defaultValue,
  description,
  errorMessage,
  granularity,
  isDateUnavailable,
  disabled,
  isOpen,
  isReadOnly,
  isRequired,
  label,
  maxValue,
  minValue,
  onBlur,
  onChangeHookForm,
  onFocus,
  onFocusChange,
  onKeyDown,
  onKeyUp,
  onOpenChange,
  placeholderValue,
  validationState,
  className,
  classNameForError,
  hideError,
  value,
  type = "date",
  pageBehavior,
  shouldCloseOnSelect,
  shouldForceLeadingZeros,
  notClearable,
  isInvalid,
}: Omit<
  DateProps<TFieldValues, Name>,
  | "name"
  | "control"
  | "shouldUnregister"
  | "maxGranularity"
  | "type"
  | "allowsNonContiguousRanges"
  | "required"
  | "onChange"
> & {
  onChangeHookForm: (...event: any[]) => void;
  placeholderValue?: CalendarDate | CalendarDateTime | ZonedDateTime;
  type?: "date" | "date-time";
  isRequired?: boolean;
  errorMessage?: string;
  value: PathValue<TFieldValues, Name>;
}) => {
  const ref = useRef<ElementRef<"div">>(null);
  const parentRef = useRef<ElementRef<"div">>(null);

  const state = useDatePickerState({
    autoFocus,
    defaultOpen,
    defaultValue:
      type === "date" ? today(getLocalTimeZone()) : now(getLocalTimeZone()),
    description,
    errorMessage,
    granularity: granularity
      ? granularity
      : type === "date-time"
      ? "second"
      : undefined,
    hideTimeZone: true,
    hourCycle: 12,
    isDateUnavailable,
    isDisabled: disabled,
    isOpen,
    isReadOnly,
    isRequired,
    label,
    maxValue,
    minValue,
    onBlur,
    onChange: onChangeHookForm,
    onFocus,
    onFocusChange,
    onKeyDown,
    onKeyUp,
    onOpenChange,
    placeholderValue: placeholderValue
      ? placeholderValue
      : type === "date-time"
      ? now(getLocalTimeZone())
      : today(getLocalTimeZone()),
    validationState,
    value: value as DateValue,
    pageBehavior,
    shouldCloseOnSelect,
    shouldForceLeadingZeros,
  });
  const {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps,
    calendarProps,
    dialogProps,
  } = useDatePicker(
    {
      autoFocus,
      defaultOpen,
      defaultValue: defaultValue
        ? defaultValue
        : type === "date"
        ? today(getLocalTimeZone())
        : now(getLocalTimeZone()),
      description,
      errorMessage,
      granularity: granularity
        ? granularity
        : type === "date-time"
        ? "second"
        : undefined,
      hideTimeZone: true,
      isDateUnavailable,
      hourCycle: 12,
      isDisabled: disabled,
      isOpen,
      isReadOnly,
      isRequired,
      label,
      maxValue,
      minValue,
      onBlur,
      onChange: onChangeHookForm,
      onFocus,
      onFocusChange,
      onKeyDown,
      onKeyUp,
      onOpenChange,
      placeholderValue: placeholderValue
        ? placeholderValue
        : type === "date-time"
        ? now(getLocalTimeZone())
        : undefined,
      isInvalid,
      value: value as DateValue,
      pageBehavior,
      shouldForceLeadingZeros,
    },
    state,
    ref
  );

  const { x, y, strategy, refs, middlewareData, context } =
    useFloating<HTMLDivElement>({
      middleware: [
        offset(10),
        flip({
          fallbackPlacements: ["bottom", "top"],
        }),
        hide({
          strategy: "referenceHidden",
        }),
        shift({
          padding: 20,
        }),
        size({
          apply: debounce(({ availableHeight, elements }) => {
            Object.assign(elements.floating.style, {
              width: "max-content",
              "max-width": "100vw",
              maxHeight: `${availableHeight}px`,
            });
          }, 1),
        }),
      ],
      placement: "bottom-end",
      whileElementsMounted: autoUpdate,
    });

  const reference = useMergeRefs([ref, refs?.setReference]);

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: dialogProps.role });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    dismiss,
    click,
    role,
  ]);

  return (
    <div
      className={twMerge("flex flex-col w-full bg-background", className)}
      ref={parentRef}
    >
      <div
        {...groupProps}
        ref={reference}
        {...getReferenceProps()}
        className={twMerge(
          "relative flex peer border border-on-surface-variant rounded bg-inherit justify-between items-center h-[56px] p-2 px-4 active:outline-none outline-none focus:outline-none focus-within:outline-none",
          state?.isOpen
            ? errorMessage
              ? "border-error"
              : disabled
              ? "text-outline text-opacity-[0.38] cursor-not-allowed border-outline border-opacity-[0.38]"
              : "border-primary"
            : errorMessage
            ? "border-error"
            : disabled
            ? "text-outline text-opacity-[0.38] cursor-not-allowed border-outline border-opacity-[0.38]"
            : "focus-within:border-primary"
        )}
      >
        <DateField {...fieldProps} label={label} />
        {label && (
          <label
            {...labelProps}
            style={{
              maxWidth: parentRef?.current
                ? (
                    parentRef?.current?.getBoundingClientRect()?.width - 20
                  )?.toString() + "px"
                : undefined,
            }}
            className={twMerge(
              "absolute -top-2 left-3.5 bg-inherit px-1 text-xs truncate cursor-default text-on-surface-variant max-w-[50%] select-none",
              errorMessage
                ? "text-error"
                : disabled
                ? "text-outline text-opacity-[0.38]"
                : "text-primary peer-focus-within:text-primary",
              errorMessage
                ? "text-error"
                : disabled
                ? "text-outline text-opacity-[0.38]"
                : "text-on-surface-variant peer-focus-within:text-primary"
            )}
          >
            {label}
          </label>
        )}
        <div className="flex gap-1">
          {value && !disabled && !notClearable && (
            <IoMdClose
              onClick={() => {
                if (!disabled) {
                  onChangeHookForm(null);
                }
              }}
              className={
                "h-6 w-6 p-0.5 cursor-pointer text-ironside-gray bg-inherit rounded-full hover:text-primary"
              }
            />
          )}
          <CalendarButton
            {...buttonProps}
            onPress={() => {
              state?.setOpen(!state?.isOpen);
            }}
            isDisabled={disabled}
            className={"p-0.5 group"}
          >
            <CalendarIcon
              className={`w-5 h-5 ${
                errorMessage
                  ? "text-error"
                  : disabled
                  ? "text-outline text-opacity-[0.38] cursor-not-allowed"
                  : "group-hover:text-primary text-ironside-gray group-focus:text-primary"
              } `}
            />
          </CalendarButton>
        </div>
      </div>
      {hideError ? null : (
        <div className={`w-[inherit] h-min px-4 ${classNameForError || ""}`}>
          <span
            className={`text-error text-xs ${
              errorMessage ? "visible" : "invisible"
            } `}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
      <FloatingPortal>
        <Transition
          show={state?.isOpen}
          ref={refs?.setFloating}
          {...getFloatingProps()}
          style={{
            position: strategy,
            zIndex: 66,
            top: y ?? 0,
            left: x ?? 0,
          }}
          as={"div"}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={`${
            middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
          } bg-background border rounded-md shadow-md`}
          onKeyDown={(e) => {
            if (e.key === "Escape") state.close();
          }}
        >
          <FocusScope autoFocus restoreFocus contain>
            <div
              className="fixed inset-0 z-[100]"
              onClick={() => state?.close()}
            />
            <Calendar
              {...calendarProps}
              className="relative z-[101] rounded-[inherit]"
            />
            {type === "date-time" && (
              <div className="pb-3 px-3">
                <TimeField
                  label={"Time"}
                  className="mt-2 z-[101]"
                  value={state?.timeValue}
                  onChange={(e) => state?.setTimeValue(e)}
                  hideError
                  granularity={
                    state?.granularity as
                      | "hour"
                      | "minute"
                      | "second"
                      | undefined
                  }
                  hourCycle={12}
                />
              </div>
            )}
          </FocusScope>
        </Transition>
      </FloatingPortal>
    </div>
  );
};

export default DatePicker;
