import { FC } from "react";
import { HiPencil, HiTrash } from "react-icons/hi";
import { SiIconify } from "react-icons/si";

interface IProps {
  onEdit?: () => void | undefined;
  onDelete?: () => void | undefined;
  otherOptions?: {
    name: string;
    onClick?: () => void | undefined;
    Icon: any;
    classForIcon?: string;
    classForDiv?: string;
  }[];
}

const KebabMenu: FC<IProps> = ({ onEdit, onDelete, otherOptions }) => {
  return (
    <div
      className={`absolute flex flex-col drop-shadow-[0px_0px_10px_#ABABAB29] rounded bg-white after:content-[''] after:absolute after:-ml-[10px] after:border-[10px] after:-top-5 ${
        otherOptions ? "after:left-[84%]" : "after:left-[90%]"
      } after:border-[transparent_transparent_white_transparent]`}
    >
      {onEdit && (
        <div
          className="cursor-pointer py-[6px] flex group items-center hover:bg-white-smoke mt-[12px]"
          onClick={onEdit}
        >
          <div className="px-5 flex gap-3">
            <HiPencil className="w-5 h-5 text-[#A4A4A5] cursor-pointer" />
            <span className="pl-[8px] text-[14px] leading-[17px] text-ironside-gray">
              Edit
            </span>
          </div>
        </div>
      )}
      {onDelete && (
        <div
          className="cursor-pointer py-[6px] flex group items-center hover:bg-white-smoke mb-[12px]"
          onClick={onDelete}
        >
          <div className="px-5 flex items-center gap-3">
            <HiTrash className="w-5 h-5 text-[#A4A4A5] cursor-pointer" />
            <span className="pl-[8px] text-[14px] leading-[17px] text-ironside-gray">
              Delete
            </span>
          </div>
        </div>
      )}
      {otherOptions &&
        otherOptions?.map(
          ({ onClick, name, Icon, classForIcon, classForDiv }, index) => {
            return (
              <div
                key={index}
                className={`cursor-pointer py-[6px] flex group items-center hover:bg-white-smoke last:mb-[12px] ${
                  classForDiv || ""
                }`}
                onClick={onClick}
              >
                <div className={`px-5 flex items-center gap-3`}>
                  {Icon ? (
                    <Icon
                      className={`text-[#A4A4A5] cursor-pointer ${
                        classForIcon ? classForIcon : "w-[13px] h-[18px]"
                      }`}
                    />
                  ) : (
                    <SiIconify className="w-5 h-5 text-[#A4A4A5] cursor-pointer" />
                  )}
                  <span className="pl-[8px] text-[14px] leading-[17px] text-ironside-gray">
                    {name || "-"}
                  </span>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

export default KebabMenu;
