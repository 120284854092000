import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { RxAvatar } from "react-icons/rx";

import { SearchField } from "components/forms";
import Tooltip from "components/UI/ToolTip";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";

import { IIdAndName, IUserProfileImage } from "global/types/type";
import { getFileExtension } from "global/helpers/getFileExtension";
import { getAllowedAccess, setTitle } from "global/helpers/Cache";

import Board from "modules/Project/Pages/Issues/Board/Board";
import {
	PROJECT_MEMBERS_PROFILES,
	SPRINTS_FOR_DROP_DOWN,
} from "modules/Project/services/queries";
import { checkHasAnyAccess, saveProjectTaskACLS } from "modules/Project/helper";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface IProps {
	allowedResourcesForProject: { [key: string]: boolean };
	editProject: {
		id: number;
		name: string;
	};
	projectGroup?: ProjectGroup | null;
}

const Issues: FC<IProps> = ({
	allowedResourcesForProject = {},
	editProject,
	projectGroup,
}) => {
	const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
	const allowedResources = allowedResourcesList?.allowedResources || [];
	const canReadUserProfileImage = allowedResources?.includes(
		"ReadUserProfileImage",
	);

	const [fetchProjectMembers, { data: getProjectMembers }] = useLazyQuery(
		PROJECT_MEMBERS_PROFILES,
	);

	const [fetchSprints, { data: allSprints }] = useLazyQuery(
		SPRINTS_FOR_DROP_DOWN,
		{
			fetchPolicy: "cache-and-network",
		},
	);

	const { id, groupId } = useParams();
	const { pathname } = useLocation();

	const projectType = pathname?.split("/")[3];

	const {
		register,
		watch,
		control,
		setValue,
		formState: { errors },
		resetField,
	} = useForm();
	const watchSearch = watch("filterTasks");
	const watchSprint = watch("sprint");
	const [search, setSearch] = useState<string | undefined>();
	const [filterAssignee, setFilterAssignee] = useState<number[] | null>(null);

	const projectMembersList = getProjectMembers?.projectMembers?.dataCollection;

	useEffect(() => {
		if (editProject?.name) {
			setTitle(
				groupId
					? [
							{ name: "Project", link: "/projects/groups" },
							{ name: "Groups", link: "/projects/groups" },
							{
								name: projectGroup?.name || "",
								link: `/projects/${projectGroup?.id}/${projectType}`,
							},
							{
								name: editProject?.name || "",
								link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
							},
							{
								name: "Board",
								link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/issues`,
							},
					  ]
					: [
							{ name: "Project", link: "/projects/projects/my-projects" },
							{
								name: editProject?.name || "",
								link: `/projects/projects/${projectType}/edit/${id}/general`,
							},
							{
								name: "Board",
								link: `/projects/projects/${projectType}/edit/${id}/issues`,
							},
					  ],
			);
		}
	}, [
		editProject?.name,
		projectType,
		id,
		projectGroup?.id,
		projectGroup?.name,
		groupId,
	]);

	useEffect(() => {
		const preventRequestOnEachKeystroke = setTimeout(() => {
			setSearch(watchSearch?.trim());
		}, 300);

		return () => {
			clearTimeout(preventRequestOnEachKeystroke);
		};
	}, [watchSearch]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchProjectMembers({
				variables: {
					filters: {
						projectId: +id,
					},
					type: canReadUserProfileImage ? "Profile" : undefined,
					isProfileImageNeeded: canReadUserProfileImage,
				},
			}).catch((error) => {
				if (error.name === "AbortError") return;
			});
		}
	}, [id, fetchProjectMembers, canReadUserProfileImage]);

	useEffect(() => {
		if (id && !Number.isNaN(+id)) {
			fetchSprints({
				variables: {
					filters: {
						projectId: +id,
					},
				},
			});
		}
	}, [fetchSprints, id]);

	const sprintsList: { id: number; name: string; status: string }[] = useMemo(
		() =>
			allSprints?.projectSprints?.dataCollection?.map(
				(projectSprint: IIdAndName & { status: string }) => {
					return {
						id: projectSprint?.id,
						name: projectSprint?.name,
						status: projectSprint?.status,
					};
				},
			) || [],
		[allSprints?.projectSprints?.dataCollection],
	);

	useEffect(() => {
		const activeSprints =
			sprintsList?.filter((sprint) => sprint?.status === "Active") || [];

		const activeSprint =
			activeSprints && activeSprints?.length > 0
				? { id: activeSprints[0]?.id, name: activeSprints[0]?.name }
				: { id: 0, name: "All Sprints" };

		setValue("sprint", activeSprint);
	}, [sprintsList, setValue]);

	const hasAllAccess = checkHasAnyAccess(
		saveProjectTaskACLS,
		allowedResourcesForProject,
	);
	const hasAnyAccess = checkHasAnyAccess(
		[...saveProjectTaskACLS, "RemoveProjectTaskAttachment"],
		allowedResourcesForProject,
	);

	const hasDeleteAccess = allowedResourcesForProject?.DeleteProjectTask;

	const resetFilterHandler = () => {
		resetField("filterTasks");
		resetField("sprint");
		setFilterAssignee(null);
	};

	return (
		<div className="min-h-[calc(100vh-22vh)] flex flex-col justify-center">
			<div className="flex justify-between flex-col lg:flex-row lg:gap-0 gap-2">
				<div className="flex flex-col lg:flex-row lg:gap-0 items-center gap-2">
					<div className=" ml-3 mr-3 mt-5 lg:mt-0">
						<SearchField
							register={register}
							name="filterTasks"
							label="Search"
							setValue={setValue}
						/>
					</div>
					<AutoComplete
						control={control}
						label="Sprints *"
						name={"sprint"}
						options={[{ id: 0, name: "All Sprints" }, ...sprintsList]}
						errors={errors}
						setValue={setValue}
						required
						className="mt-5 max-w-[230px] lg:mr-3"
						classForInput="min-h-[49px]"
					/>
					<div className="flex">
						{projectMembersList?.map(
							(
								projectMember: {
									user: {
										personalInformation: IIdAndName;
										userProfileImages: IUserProfileImage[];
									};
								},
								index: number,
							) => {
								const profileImage =
									(projectMember?.user?.userProfileImages?.length > 0 &&
										projectMember?.user?.userProfileImages[0]?.documents
											?.length > 0 &&
										projectMember?.user?.userProfileImages[0]?.documents[0]
											?.file) ||
									"";
								return (
									<div
										key={index}
										className="hover:-translate-y-2 cursor-pointer transition-all duration-200 mr-[-16px] h-min"
										onClick={() => {
											setFilterAssignee((prev) => {
												const oldSelectedMembers =
													prev && prev?.length > 0 ? [...prev] : [];

												if (
													oldSelectedMembers &&
													oldSelectedMembers?.length > 0 &&
													oldSelectedMembers?.includes(
														projectMember?.user?.personalInformation?.id,
													)
												) {
													return oldSelectedMembers?.filter(
														(memberId) =>
															memberId !==
															projectMember?.user?.personalInformation?.id,
													);
												} else {
													return [
														...oldSelectedMembers,
														projectMember?.user?.personalInformation?.id,
													];
												}
											});
										}}
									>
										<Tooltip
											render={
												projectMember?.user?.personalInformation?.name || ""
											}
											arrow
											placement={"bottom"}
											trigger={["hover"]}
										>
											{profileImage?.length > 100 ? (
												<img
													className={`min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] border-[3px] border-solid ${
														filterAssignee?.includes(
															projectMember?.user?.personalInformation?.id,
														)
															? "border-cornflower-blue"
															: "border-[#fdf2e9]"
													}  rounded-[50%] object-fit`}
													src={`data:image/${
														getFileExtension(profileImage) === "svg"
															? "svg+xml"
															: getFileExtension(profileImage)
													};base64,${profileImage}`}
													alt="No File"
												/>
											) : (
												<RxAvatar
													className={`w-[40px] h-[40px] bg-gray-400 text-white rounded-[50%] object-fit cursor-pointer ${
														filterAssignee?.includes(
															projectMember?.user?.personalInformation?.id,
														)
															? "border-[3px] border-solid border-cornflower-blue"
															: ""
													}`}
												/>
											)}
										</Tooltip>
									</div>
								);
							},
						)}
					</div>
					{/* <button type="button" onClick={resetFilterHandler}>
						Clear filter
					</button> */}
				</div>
			</div>
			<Board
				hasAllAccess={hasAllAccess}
				hasDeleteAccess={hasDeleteAccess}
				hasAnyAccess={hasAnyAccess}
				search={search}
				watchSprint={+watchSprint?.id}
				disabledColumns={!allowedResourcesForProject?.ChangeColumnPosition}
				filterAssignee={filterAssignee}
			/>
		</div>
	);
};

export default Issues;
