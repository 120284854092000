import { Fragment, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import SMCardContent from "components/App/Card/SMCard/SMCardContent";
import Loading from "components/Loader/Loading";

import { toastNotify } from "global/helpers/Cache";
import { Base64toObject } from "global/helpers/FileConverter";
import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { getFileExtension } from "global/helpers/getFileExtension";

import { UPDATE_NOTIFICATION_STATUS } from "layouts/header/TopLinks/Notification/services/mutations";

import { USER_PROFILE_MODERATIONS } from "modules/Employee/services/queries";
import ViewPageButtons from "modules/Settings/Pages/Moderation/ViewPageButtons";
import DeclinePage from "modules/Settings/Pages/Moderation/DeclinePage";
import { UPDATE_MULTIPLE_USER_PROFILE_MODERATIONS } from "modules/Settings/services/mutations";
import ViewPageBackArrowBtn from "modules/Settings/Pages/Moderation/ViewPageBackArrowBtn";
import { GET_NOTIFICATION_IDS } from "modules/Leave/services/queries";

const ViewPage = () => {
  const [fetchNotifications, { data: getNotifications }] = useLazyQuery(
    GET_NOTIFICATION_IDS,
    {
      fetchPolicy: "network-only",
    }
  );
  const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const moderationId: any = pathname?.split("/")?.at(-1);

  const [
    fetchModeration,
    { data: getUserProfileModeration, refetch, loading },
  ] = useLazyQuery(USER_PROFILE_MODERATIONS);

  useEffect(() => {
    fetchNotifications({
      variables: {
        isRead: false,
        search: pathname,
      },
    });
  }, [fetchNotifications, pathname]);

  const unreadNotications = getNotifications?.notifications?.dataCollection;

  useEffect(() => {
    if (moderationId) {
      fetchModeration({
        variables: {
          ids: [+moderationId],
        },
      });
    }
  }, [fetchModeration, moderationId]);

  const profileModeration =
    getUserProfileModeration?.userProfileModerations?.dataCollection;

  const moderationObj = profileModeration && profileModeration[0];

  const getPreviousOrUpdatedValue = (
    isFile: boolean,
    isProfileImageFile: boolean,
    value: string
  ) => {
    if (isFile) {
      return (
        value?.length > 100 && (
          <span className="flex w-[80px] h-[35px]">
            <a href={Base64toObject(value)} target="_blank" rel="noreferrer">
              <img src={filePdf} alt="PDF" className="w-[30px] h-[30px]" />
            </a>
          </span>
        )
      );
    } else if (isProfileImageFile) {
      return (
        (value?.length > 100 && (
          <img
            className="w-[80px] h-[50px] object-fill"
            src={`data:image/${
              getFileExtension(value) === "svg"
                ? "svg+xml"
                : getFileExtension(value)
            };base64,${value}`}
            alt="No File"
          />
        )) ||
        value
      );
    }
  };

  const [updateProfileModeration] = useMutation(
    UPDATE_MULTIPLE_USER_PROFILE_MODERATIONS
  );

  const [showDeclinePage, setShowDeclinePage] = useState<boolean>(false);

  const btnAndFieldProvisionObj: { name: string; value: string } | null =
    { name: "status", value: "pending" } || null;

  const hasBtnAndFieldProvision =
    moderationObj &&
    moderationObj[btnAndFieldProvisionObj?.name]?.toLowerCase() ===
      btnAndFieldProvisionObj?.value;

  const declineHandler = () => {
    setShowDeclinePage(!showDeclinePage);
  };

  const approveHandler = () => {
    updateProfileModeration({
      variables: {
        ids: moderationObj?.id,
        status: "approved",
      },
    })
      .then(() => {
        refetch();
        navigate("/settings/moderation");
        if (unreadNotications?.length > 0) {
          updateNotificationStatus({
            variables: {
              id: [unreadNotications[0]?.id],
              isRead: true,
            },
          });
        }
        toastNotify([
          {
            messageType: "success",
            message: "Your modifications have been successfully updated.",
          },
        ]);
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };
  const profileImage =
    moderationObj?.moderatedByUser?.userProfileImages?.filter(
      (userImage: any) => userImage?.type === "Profile"
    )[0];

  const isProfileImageFile =
    moderationObj && moderationObj?.action === "Delete"
      ? moderationObj?.section === "User profile images"
      : moderationObj?.section === "User profile images" &&
        moderationObj?.field === "File path";

  const isFile =
    !isProfileImageFile &&
    (moderationObj?.field === "File path" ||
      moderationObj?.field === "Aadhaar file");

  const previousValue =
    (isProfileImageFile || isFile) &&
    getPreviousOrUpdatedValue(
      isFile,
      isProfileImageFile,
      moderationObj?.previousValue
    );

  const updatedValue =
    (isProfileImageFile || isFile) &&
    getPreviousOrUpdatedValue(
      isFile,
      isProfileImageFile,
      moderationObj?.updatedValue
    );

  const fileOrImagePreviousValue =
    (isProfileImageFile || isFile) && previousValue;

  const fielOrImageUpdatedValue =
    (isProfileImageFile || isFile) && updatedValue;

  const dataModal = !hasBtnAndFieldProvision
    ? [
        {
          lable: "ID",
          value: [
            {
              content: moderationObj?.id,
            },
          ],
        },
        {
          lable: "Employee Name",
          value: [
            {
              content:
                moderationObj?.moderatedByUser?.personalInformation?.name,
              profileDetails: {
                profileImage:
                  profileImage &&
                  profileImage?.documents &&
                  profileImage?.documents[0]?.file,
                name: moderationObj?.moderatedByUser?.personalInformation?.name,
                email:
                  moderationObj?.moderatedByUser?.personalInformation
                    ?.personalEmail,
              },
            },
          ],
        },
        {
          lable: "Updated At",
          value: [
            {
              content: moderationObj?.updatedAt,
            },
          ],
        },
        {
          lable: "Session",
          value: [
            {
              content: moderationObj?.section,
            },
          ],
        },
        {
          lable: "Action",
          value: [
            {
              content: moderationObj?.action,
            },
          ],
        },
        {
          lable: "Field",
          value: [
            {
              content: moderationObj?.field,
            },
          ],
        },
        {
          lable: "Previous Value",
          value: [
            {
              content: fileOrImagePreviousValue || moderationObj?.previousValue,
            },
          ],
        },
        {
          lable: "Updated Value",
          value: [
            {
              content: fielOrImageUpdatedValue || moderationObj?.updatedValue,
            },
          ],
        },
        {
          lable: "Status",
          value: [
            {
              content: moderationObj?.status,
            },
          ],
        },
        {
          lable: "Decline Reason",
          value: [
            {
              content: moderationObj?.declineReason,
            },
          ],
        },
      ]
    : [
        {
          lable: "ID",
          value: [
            {
              content: moderationObj?.id,
            },
          ],
        },
        {
          lable: "Employee Name",
          value: [
            {
              content:
                moderationObj?.moderatedByUser?.personalInformation?.name,
              profileDetails: {
                profileImage:
                  profileImage &&
                  profileImage?.documents &&
                  profileImage?.documents[0]?.file,
                name: moderationObj?.moderatedByUser?.personalInformation?.name,
                email:
                  moderationObj?.moderatedByUser?.personalInformation
                    ?.personalEmail,
              },
            },
          ],
        },
        {
          lable: "Updated At",
          value: [
            {
              content: moderationObj?.updatedAt,
            },
          ],
        },
        {
          lable: "Session",
          value: [
            {
              content: moderationObj?.section,
            },
          ],
        },
        {
          lable: "Action",
          value: [
            {
              content: moderationObj?.action,
            },
          ],
        },
        {
          lable: "Field",
          value: [
            {
              content: moderationObj?.field,
            },
          ],
        },
        {
          lable: "Previous Value",
          value: [
            {
              content: fileOrImagePreviousValue || moderationObj.previousValue,
            },
          ],
        },
        {
          lable: "Updated Value",
          value: [
            {
              content: fielOrImageUpdatedValue || moderationObj.updatedValue,
            },
          ],
        },
        {
          lable: "Status",
          value: [
            {
              content: moderationObj?.status,
            },
          ],
        },
      ];
  return (
    <div>
      {loading ? (
        <Loading className="min-h-[600px]" />
      ) : (
        <Fragment>
          <div className="border border-hit-gray rounded mt-5 min-h-[70vh] p-5">
            <SMCardContent dataModel={dataModal} />
            {(!!hasBtnAndFieldProvision && (
              <ViewPageButtons
                declineHandler={declineHandler}
                approveHandler={approveHandler}
              />
            )) ||
              null}
          </div>
          {showDeclinePage && (
            <DeclinePage
              setShowDeclinePage={setShowDeclinePage}
              updateProfileModeration={updateProfileModeration}
              employeeId={moderationObj?.id}
              refetchUserProfileModeration={refetch}
              navPagestring={"/settings/moderation"}
              unReadNotificationID={
                unreadNotications?.length > 0 ? unreadNotications[0]?.id : 0
              }
            />
          )}
          <ViewPageBackArrowBtn />
        </Fragment>
      )}
    </div>
  );
};

export default ViewPage;
