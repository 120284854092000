import { FC, useEffect } from "react";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { useParams } from "react-router-dom";

import Input from "components/forms/UpdatedForm/Input";
import Select from "components/forms/UpdatedForm/Select";

import { IIdAndName } from "global/types/type";

import { PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES } from "modules/Project/services/queries";
import { IProjectIssueForm } from "modules/Project/types/project";
import { getAllowedAccess } from "global/helpers/Cache";

interface IProps {
  register: UseFormRegister<IProjectIssueForm>;
  fields: FieldArrayWithId<
    IProjectIssueForm,
    "projectTaskApprovedHours",
    "id"
  >[];
  append: UseFieldArrayAppend<IProjectIssueForm, "projectTaskApprovedHours">;
  errors: FieldErrors<IProjectIssueForm>;
  setValue: UseFormSetValue<IProjectIssueForm>;
  control: Control<IProjectIssueForm, any>;
  remove: UseFieldArrayRemove;
  item: FieldArrayWithId<IProjectIssueForm, "projectTaskApprovedHours", "id">;
  index: number;
  projectTaskApprovedHour: {
    assigneeUserId: number | null;
    actualApprovedHours: number | null;
    allocatedApprovedHours: number | null;
    projectRoleId: number | null;
  };
}

const AssigneeForm: FC<IProps> = ({
  register,
  remove,
  setValue,
  item,
  index,
  control,
  errors,
  projectTaskApprovedHour,
  fields,
}) => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];
  const canReadUserProfileImage = allowedResources?.includes(
    "ReadUserProfileImage"
  );
  const canReadUserEmployeeDetails = allowedResources?.includes(
    "ReadEmployeeWorkInformation"
  );

  const { id } = useParams();
  const [
    fetchProjectMembers,
    { data: getprojectMembers, loading: memberLoading },
  ] = useLazyQuery(PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (id && !isNaN(+id)) {
      fetchProjectMembers({
        variables: {
          filters: {
            projectId: +id,
          },
          type: canReadUserProfileImage ? "Profile" : undefined,
          isProfileImageNeeded: canReadUserProfileImage,
          isEmployeeDetailsNeeded: canReadUserEmployeeDetails,
        },
      }).catch((error) => {
        if (error.name === "AbortError") return;
      });
    }
  }, [
    id,
    fetchProjectMembers,
    canReadUserProfileImage,
    canReadUserEmployeeDetails,
  ]);

  const projectMembersList =
    getprojectMembers?.projectMembers?.dataCollection?.map(
      (member: { user: { personalInformation: IIdAndName } }) => {
        return {
          id: member?.user?.personalInformation?.id,
          name: member?.user?.personalInformation?.name,
        };
      }
    );

  const assigneeUser = getprojectMembers?.projectMembers?.dataCollection?.find(
    (member: { user: { personalInformation: { id: number } } }) =>
      member?.user?.personalInformation?.id ===
      projectTaskApprovedHour?.assigneeUserId
  );

  useEffect(() => {
    setValue(
      `projectTaskApprovedHours.${index}.projectRoleId`,
      +assigneeUser?.projectRole?.id
    );

    setValue(
      `projectTaskApprovedHours.${index}.allocatedApprovedHours`,
      projectTaskApprovedHour?.actualApprovedHours
        ? +(
            projectTaskApprovedHour?.actualApprovedHours /
            assigneeUser?.user?.employeeDetail?.productivityRatio
          )?.toFixed(2)
        : null
    );
  }, [
    projectTaskApprovedHour?.assigneeUserId,
    projectTaskApprovedHour?.actualApprovedHours,
    projectTaskApprovedHour?.allocatedApprovedHours,
    assigneeUser?.projectRole?.id,
    setValue,
    index,
    assigneeUser?.user?.employeeDetail?.productivityRatio,
  ]);

  return (
    <div
      key={item?.id}
      className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 border p-3 rounded-md shadow relative overflow-visible"
    >
      <IoMdClose
        onClick={() => {
          remove(index);
        }}
        className={`cursor-pointer w-6 h-6 md:w-8 md:h-8 p-1 absolute bg-white rounded-full -right-2 sm:-right-4 -top-4 border shadow-md `}
      />
      <Select
        control={control}
        errors={errors}
        label="Assignee *"
        name={`projectTaskApprovedHours.${index}.assigneeUserId`}
        className=""
        options={projectMembersList}
        setValue={setValue}
        loading={memberLoading}
      />
      <Input
        register={register}
        errors={errors}
        label="Approved hours *"
        name={`projectTaskApprovedHours.${index}.actualApprovedHours`}
        required
        setValue={setValue}
        inputType="number"
        fractionalValue
        maxLength={7}
        className=""
      />
      <input
        type="text"
        hidden
        {...register(
          `projectTaskApprovedHours.${index}.allocatedApprovedHours`
        )}
      />
      <input
        type="text"
        hidden
        {...register(`projectTaskApprovedHours.${index}.projectRoleId`)}
      />
      <p
        className={`pl-1 max-w-[200px] truncate ${
          index === fields?.length - 1 ? "pb-0" : "pb-0"
        }`}
      >{`Role: ${assigneeUser?.projectRole?.name || ""}`}</p>

      <div
        className={`pl-1 max-w-[200px] truncate  ${
          index === fields?.length - 1 ? "pb-0" : "pb-0"
        }`}
      >
        <p
          className={`pl-0 max-w-[200px] truncate ${
            index === fields?.length - 1 ? "pb-0" : "pb-4"
          }`}
        >{`Allocated hours: ${
          projectTaskApprovedHour?.actualApprovedHours && assigneeUser
            ? (
                projectTaskApprovedHour?.actualApprovedHours /
                assigneeUser?.user?.employeeDetail?.productivityRatio
              )?.toFixed(2) || ""
            : ""
        }`}</p>
      </div>
    </div>
  );
};

export default AssigneeForm;
