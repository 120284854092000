import { FC, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
	AutoComplete,
	Input,
	Select,
	TextArea,
} from "components/forms/UpdatedFormAgry";
import FileTriggerComponent from "components/forms/UpdatedFormAgry/File/FileTrigger";
import { Filled, Outlined } from "components/forms";
import Loading from "components/Loader/Loading";

import { toastNotify } from "global/helpers/Cache";
import { IDAndName } from "global/types/type";
import useAllowedResources from "global/hooks/useAllowedResources";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";
import {
	bugAndTaskPlatforms,
	bugAndTaskPriorities,
	imageURLAsBase64,
} from "global/helpers/utils";
import { notEmpty } from "global/helpers/ArrayMethods";

import { ProjectGroupBugFormType } from "modules/Project/Pages/Projects/ProjectGroupBugs/types";
import {
	CREATE_PROJECT_GROUP_BUG,
	FILTER_PROJECT_GROUP_BUGS,
	UPDATE_PROJECT_GROUP_BUG,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/services";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import { PROJECTS_FOR_DROP_DOWN } from "modules/Project/services/queries";
import { IProject } from "modules/Project/types/project";
import ProjectBugSourceField from "modules/Project/Pages/ProjectGroupBugSource/helpers/ProjectBugSourceField";
import TitleAndBackNavigation from "components/UI/TitleAndBackNavigation";

interface Props {
	projectGroup: ProjectGroup | null;
}

const EditProjectGroupBug: FC<Props> = ({ projectGroup }) => {
	const allowedResources = useAllowedResources();

	const canCreate = allowedResources?.includes("CreateProjectGroupBug");
	const canUpdate = allowedResources?.includes("UpdateProjectGroupBug");

	const { groupId, projectGroupBugId } = useParams();
	const navigate = useNavigate();

	const canReadAllProjects = allowedResources?.includes("AllProject");
	const canReadMyProject = allowedResources?.includes("MyProject");

	const [createProjectGroupBug, { loading: createProjectGroupBugLoading }] =
		useMutation(CREATE_PROJECT_GROUP_BUG);

	const [updateProjectGroupBug, { loading: updateProjectGroupBugLoading }] =
		useMutation(UPDATE_PROJECT_GROUP_BUG);

	const [fetchFilterProjectGroupBugs, { loading }] = useLazyQuery(
		FILTER_PROJECT_GROUP_BUGS,
		{
			fetchPolicy: "cache-and-network",
		},
	);

	const [imageLoading, setImageLoading] = useState(true);

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ProjectGroupBugFormType>({
		defaultValues: async () => {
			const projectGroupBug =
				projectGroupBugId && Number.isFinite(+projectGroupBugId)
					? await fetchFilterProjectGroupBugs({
							variables: {
								filters: {
									id: {
										number: +projectGroupBugId,
									},
								},
							},
					  }).then(({ data }) =>
							data?.filterProjectGroupBugs?.edges &&
							data?.filterProjectGroupBugs?.edges?.length > 0
								? data?.filterProjectGroupBugs?.edges[0]?.node
								: undefined,
					  )
					: undefined;

			const projects =
				projectGroupBug?.projectBacklogs?.dataCollection &&
				projectGroupBug?.projectBacklogs?.dataCollection?.length > 0
					? projectGroupBug?.projectBacklogs?.dataCollection?.reduce(
							(acc: IDAndName<number, "label">[], projectGroupBug) => {
								if (
									projectGroupBug?.project?.id &&
									projectGroupBug?.project?.name
								) {
									acc.push({
										id: projectGroupBug?.project?.id,
										label: projectGroupBug?.project?.name,
									});
									return acc;
								}
								return acc;
							},
							[],
					  )
					: [];
			const filePromises =
				projectGroupBug?.attachments && projectGroupBug?.attachments?.length > 0
					? projectGroupBug?.attachments?.map(async (item) => {
							const file = item?.fileUrl
								? await imageURLAsBase64(item?.fileUrl)
								: null;
							return {
								id: item?.id,
								file: file?.file,
							};
					  })
					: [];
			const existingFiles = await Promise.all(filePromises);
			setImageLoading(false);
			return {
				title: projectGroupBug?.title || (null as unknown as string),
				platform: projectGroupBug?.platform || (null as unknown as string[]),
				priority: projectGroupBug?.priority || (null as unknown as string),
				description: projectGroupBug?.description,
				status: projectGroupBug?.status,
				source:
					projectGroupBug?.source && projectGroupBug?.source?.length > 0
						? projectGroupBug?.source
								?.map((source) => {
									if (source?.id) {
										return {
											id: source?.id,
											label: source?.name || "N/A",
										};
									} else {
										return null;
									}
								})
								?.filter(notEmpty)
						: null,
				projects,
				attachments: existingFiles,
			};
		},
	});

	const [watchStatus, watchProjects] = useWatch({
		control,
		name: ["status", "projects"],
	});

	const { data: filterProjects, loading: filterProjectsLoading } = useQuery(
		PROJECTS_FOR_DROP_DOWN,
		{
			skip:
				watchStatus !== "Moved" ||
				!projectGroupBugId ||
				(!canReadAllProjects && !canReadMyProject) ||
				(groupId && !Number.isFinite(+groupId))
					? true
					: false,
			variables: {
				filters: groupId
					? {
							projectGroupId: +groupId,
					  }
					: undefined,
			},
		},
	);

	const projects: IDAndName<number, "label">[] =
		filterProjects?.projects?.dataCollection?.reduce(
			(acc: IDAndName<number, "label">[], project: IProject) => {
				if (project?.id && project?.name) {
					acc.push({ id: project?.id, label: project?.name });
					return acc;
				}
				return acc;
			},
			[],
		) || [];

	const submitHandler: SubmitHandler<ProjectGroupBugFormType> = ({
		description,
		title,
		platform,
		priority,
		status,
		projects,
		source,
		attachments,
	}) => {
		if (projectGroupBugId && Number.isFinite(+projectGroupBugId)) {
			updateProjectGroupBug({
				variables: {
					input: {
						id: +projectGroupBugId,
						title: title || undefined,
						description: description?.trim() || undefined,
						platform:
							platform?.map((platform) => platform) ||
							(null as unknown as string[]),
						priority: priority || (null as unknown as string),
						status: status || undefined,
						projectGroupName: projectGroup?.name || (null as unknown as string),
						projectIds:
							projects && projects?.length > 0
								? projects?.map((project) => project?.id)
								: [],
						attachments:
							attachments && attachments?.length > 0
								? attachments
										?.map((attachment) => {
											if (attachment?.file) {
												return attachment?.file;
											} else {
												return null;
											}
										})
										.filter(notEmpty)
								: [],
						sourceIds:
							source && source?.length > 0
								? source?.map((source) => source?.id)
								: [],
					},
				},
			})
				.then(() => {
					navigate(`/projects/${projectGroup?.id}/bugs`);
					toastNotify([
						{
							messageType: "success",
							message: "Bug updated successfully",
						},
					]);
				})
				.catch((error) => toastNotify(errorMessageNotify(error)));
		} else {
			createProjectGroupBug({
				variables: {
					input: {
						title: title || (null as unknown as string),
						description: description?.trim() || undefined,
						platform: platform || (null as unknown as string[]),
						priority: priority || (null as unknown as string),
						status: status || undefined,
						projectGroupName: projectGroup?.name || (null as unknown as string),
						sourceIds:
							source && source?.length > 0
								? source?.map((source) => source?.id)
								: undefined,
						attachments:
							attachments && attachments?.length > 0
								? attachments
										?.map((attachment) => {
											if (attachment?.file) {
												return attachment?.file;
											} else {
												return null;
											}
										})
										.filter(notEmpty)
								: undefined,
					},
				},
			})
				.then(({ data }) => {
					if (data?.createProjectGroupBug) {
						navigate(`/projects/${projectGroup?.id}/bugs`);
						toastNotify([
							{
								messageType: "success",
								message: "Bug added successfully",
							},
						]);
					}
				})
				.catch((error) => toastNotify(errorMessageNotify(error)));
		}
	};

	return loading || imageLoading ? (
		<Loading className="min-h-[700px]" />
	) : (
		<div className="flex justify-center min-h-[75vh]">
			<form onSubmit={handleSubmit(submitHandler)}>
				<TitleAndBackNavigation
					title={{
						children: `${
							projectGroupBugId && Number.isFinite(+projectGroupBugId)
								? "Edit bug"
								: "Add bug"
						}`,
					}}
					navigation={{
						onClick: () => {
							navigate(`/projects/${projectGroup?.id}/bugs`);
						},
					}}
					className="text-sm mb-3"
				/>
				<div className="max-w-[500px] grid grid-cols-1 h-min gap-20">
					<div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4 gap-1 h-min">
						<Input control={control} label="Title *" name="title" required />
						<div className="col-span-2 sm:col-span-1">
							<Select
								control={control}
								label="Priority *"
								name="priority"
								options={bugAndTaskPriorities}
								className="min-h-[45px]"
							/>
						</div>
						<div className="col-span-2 sm:col-span-1">
							<Select
								control={control}
								label="Platform *"
								name="platform"
								options={bugAndTaskPlatforms}
								multiple
								className="min-h-[45px]"
							/>
						</div>
						<ProjectBugSourceField
							name="source"
							control={control}
							label="Source"
							multiple
							className="bg-white"
						/>
						<div className="col-span-2">
							{projectGroupBugId && Number.isFinite(+projectGroupBugId) && (
								<Select
									control={control}
									label="Status"
									name="status"
									options={[
										"Moved",
										"Abandoned",
										"Accepted",
										"Duplicate",
										"Pending",
									]}
									className="bg-white"
								/>
							)}
						</div>
						{projectGroupBugId && watchStatus === "Moved" && (
							<AutoComplete
								control={control}
								label="Projects *"
								name="projects"
								options={projects}
								multiple
								loading={filterProjectsLoading}
								className="bg-white col-span-2"
							/>
						)}
						<div className="col-span-2">
							<TextArea
								label="Description"
								name="description"
								control={control}
							/>
						</div>
						<FileTriggerComponent
							control={control}
							name="attachments"
							className="bg-white pb-6 col-span-2"
						/>
						<div className="flex justify-between sm:col-span-2 mb-3">
							<Outlined
								buttonName={"Cancel"}
								onClick={() => navigate(`/projects/${groupId}/bugs`)}
							/>
							<Filled
								disabled={
									(watchStatus?.toLowerCase() === "moved" &&
										watchProjects?.length === 0) ||
									isValid
										? projectGroupBugId && Number.isFinite(+projectGroupBugId)
											? !canUpdate
											: !canCreate
										: true
								}
								buttonName={
									projectGroupBugId && Number.isFinite(+projectGroupBugId)
										? "Update"
										: "Add"
								}
								loading={
									createProjectGroupBugLoading || updateProjectGroupBugLoading
								}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EditProjectGroupBug;
