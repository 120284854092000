import useWindowSize from "global/hooks/useWindowSize";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";

const SubMenuDetails: React.FC<{
	allowedSubMenuArray: string[];
	subMenu: any;
	menu: any;
	title: string;
	showMenu: Function;
	setShowMenu: Function;
}> = ({
	allowedSubMenuArray,
	subMenu,
	menu,
	title,
	setShowMenu,
	showMenu,
}: any) => {
	const [width] = useWindowSize();

	return (
		<Fragment>
			{allowedSubMenuArray?.length > 0 ? (
				<NavLink
					to={subMenu?.subMenuLink}
					className={`w-full inline-block py-[16px] text-[14px] leading-[20px] text-ironside-gray pl-[80px] whitespace-nowrap ${
						`${menu.name} / ${subMenu?.subMenuName}` === title
							? "bg-[#ECF1FE] hover:bg-[#ECF1FE]"
							: "hover:bg-white-smoke"
					}`}
					onClick={() => {
						if (showMenu && width < 1280) {
							setShowMenu(false);
						}
					}}
				>
					<div
						className={`text-[14px] leading-[20px] ${
							`${menu.name} / ${subMenu?.subMenuName}` === title
								? "text-cornflower-blue"
								: title?.includes("Projects") &&
									  title !== "Projects / Settings" &&
									  title !== "Projects / Projects" &&
									  subMenu?.subMenuName === "Groups" &&
									  subMenu?.subMenuName !== "Projects"
								  ? "text-cornflower-blue"
								  : "text-ironside-gray"
						}`}
					>
						{allowedSubMenuArray[0]}
					</div>
				</NavLink>
			) : null}
		</Fragment>
	);
};

export default SubMenuDetails;
