import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import Filled from "components/forms/Button/Filled";
import OopsNoData from "components/UI/OopsNoData";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { SearchField } from "components/forms";
import Pagination from "components/Pagination/Pagination";
import Table from "components/App/Table/Table";
import Loading from "components/Loader/Loading";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import AllTeamsDetailsGrid from "modules/Teams/Pages/AllTeams/AllTeamsDetailsGrid";

import { paginationDefaultValue } from "global/helpers/StaticData";
import { toastNotify } from "global/helpers/Cache";

import { DELETE_TEAM } from "modules/Teams/services/mutations";
import { ALL_TEAMS } from "modules/Teams/services/queries";
import EditAllTeams, {
  TeamsType,
} from "modules/Teams/Pages/AllTeams/EditAllTeams";
import UserDetails from "components/UserDetails/UserDetails";

const tableHeadings = ["Team Name", "Team Code", "Team Lead", "Team Members"];

interface IProps {
  allowedResources: string[];
}

const AllTeams: FC<IProps> = ({ allowedResources }) => {
  const createAccess = allowedResources?.includes("CreateEmployeeTeam");

  const [
    fetchAllTeams,
    { data: employeeTeams, loading, refetch: refetchTeams },
  ] = useLazyQuery(ALL_TEAMS, {
    fetchPolicy: "cache-and-network",
  });

  const { register, watch, setValue } = useForm();

  const team = watch("team");

  const [userId, setUserId] = useState<number | undefined>();
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editAllTeamsDetails, setEditAllTeamsDetails] = useState<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const [search, setSearch] = useState(team || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(team?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [team]);

  useEffect(() => {
    fetchAllTeams({
      variables: {
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [currentPage, fetchAllTeams, pageSize, search]);

  const parentRef = useRef<HTMLDivElement>(null);

  const [deleteEmployeeTeam] = useMutation(DELETE_TEAM);
  const deleteHandler = () => {
    deleteEmployeeTeam({
      variables: {
        id: editAllTeamsDetails?.id,
      },
      update: (cache) => {
        const exTeams: {
          employeeTeams: TeamsType[] | any;
        } | null = cache.readQuery({
          query: ALL_TEAMS,
        });

        const updatedTeams = exTeams?.employeeTeams?.filter(
          (teamDetails: TeamsType) =>
            +teamDetails?.id !== +editAllTeamsDetails?.id
        );

        cache.writeQuery({
          query: ALL_TEAMS,
          data: {
            employeeTeams: updatedTeams,
          },
        });
      },
    })
      .then(() => {
        setDeleteVerification(false);
        setEditAllTeamsDetails(null);
        toastNotify([
          {
            messageType: "success",
            message: "You have successfully deleted your record.",
          },
        ]);
        refetchTeams();
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };

  const kebabMenuClickHandler = (teamMembers: any) => {
    setEditAllTeamsDetails(teamMembers);
  };
  const updateAccess = allowedResources?.includes("UpdateEmployeeTeam");
  const deleteAccess = allowedResources?.includes("DeleteEmployeeTeam");
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const kebabMenuEditHandler = () => {
    if (updateAccess) {
      setShowModal(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };

  const getDataModal = (teamMembers: any) => {
    return [
      {
        lable: "Team Name",
        value: [
          {
            content: teamMembers?.name,
          },
        ],
      },
      {
        lable: "Team Code",
        value: [
          {
            content: teamMembers?.code,
          },
        ],
      },
      {
        lable: "Team Lead",
        value: [
          {
            content: (
              <div className="group">
                <p
                  onMouseEnter={() => setUserId(teamMembers?.teamLeadUser?.id)}
                >
                  {teamMembers?.teamLeadUser?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          },
        ],
      },
      {
        lable: "Team Members",
        value: teamMembers.members?.map((teamMember: any) => {
          return {
            content: (
              <div className="group">
                <p onMouseEnter={() => setUserId(teamMember?.id)}>
                  {teamMember?.personalInformation?.name}
                </p>
                <UserDetails userId={userId} />
              </div>
            ),
          };
        }),
      },
    ];
  };

  return (
    <div>
      <div className="flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row md:pb-0 pt-5">
        <SearchField
          register={register}
          name="team"
          label="Team"
          onChange={() => {
            setCurrentPage(1);
          }}
          setValue={setValue}
        />
      </div>
      {loading ? (
        <Loading className="min-h-[70vh]" />
      ) : (
        <Fragment>
          {employeeTeams?.employeeTeams?.dataCollection?.length === 0 &&
          search?.length <= 0 ? (
            <div className="md:min-h-[678px] lg:min-h-[694px] min-h-[440px]">
              <div className="flex justify-center items-center h-[400px]">
                {createAccess && (
                  <Filled
                    buttonName={"Create Team"}
                    onClick={() => {
                      setEditAllTeamsDetails(null);
                      setShowModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div>
              {employeeTeams?.employeeTeams?.dataCollection?.length === 0 ? (
                <OopsNoData className="min-h-[70vh]" />
              ) : (
                <div className="md:min-h-[658px] lg:min-h-[674] min-h-[440px]">
                  <div className="hidden md:block">
                    <Table
                      noOfItems={
                        employeeTeams?.employeeTeams?.dataCollection?.length
                      }
                      tableHeadingsObj={tableHeadings?.map((name: string) => {
                        return {
                          name,
                          center: false,
                        };
                      })}
                      createAccess={createAccess}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      loading={loading}
                      pageSize={pageSize}
                      ref={parentRef}
                      setPageSize={setPageSize}
                      className="py-5 min-h-[600px]"
                      totalCount={
                        employeeTeams?.employeeTeams?.totalNumberOfItems || 0
                      }
                      onPlusIconClick={() => {
                        setEditAllTeamsDetails(null);
                        setShowModal(true);
                      }}
                    >
                      {employeeTeams?.employeeTeams?.dataCollection?.map(
                        (teamMembers: any) => {
                          return (
                            <AllTeamsDetailsGrid
                              allowedResources={allowedResources}
                              key={teamMembers?.id}
                              teamMembers={teamMembers}
                              setShowModal={setShowModal}
                              editAllTeamsDetails={editAllTeamsDetails}
                              setEditAllTeamsDetails={setEditAllTeamsDetails}
                              refetchTeams={refetchTeams}
                              parentRef={parentRef}
                              userId={userId}
                              setUserId={setUserId}
                            />
                          );
                        }
                      )}
                    </Table>
                  </div>
                  <div className="md:hidden">
                    <div className="w-full flex justify-center mt-[30px]">
                      {createAccess ? (
                        <button
                          className="w-[250px] lg:w-[120px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Create Team
                        </button>
                      ) : null}
                    </div>
                    <div className="lg:px-[45px] bg-white mt-[20px] md:mt-[30px]">
                      {employeeTeams?.employeeTeams?.dataCollection?.map(
                        (teamMembers: any, index: any) => {
                          return (
                            <SMDataCard
                              key={teamMembers?.id}
                              title={`Team ${index + 1}`}
                              loading={loading}
                              dataModal={getDataModal(teamMembers)}
                              onKebabIcon={{
                                onclick: () => {
                                  kebabMenuClickHandler(teamMembers);
                                },
                                kebabMenu: {
                                  onEdit: kebabMenuEditHandler,
                                  onDelete: kebabMenuDeleteHandler,
                                },
                              }}
                            />
                          );
                        }
                      )}
                      <Pagination
                        totalCount={
                          employeeTeams?.employeeTeams
                            ? employeeTeams?.employeeTeams?.totalNumberOfItems
                            : 0
                        }
                        noOfItem={employeeTeams?.length}
                        currentPage={currentPage}
                        setPageSize={setPageSize}
                        siblingCount={1}
                        pageSize={pageSize}
                        onPageChange={(page: number) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {showModal ? (
            <EditAllTeams
              setShowModal={setShowModal}
              editAllTeamsDetails={editAllTeamsDetails}
              setEditAllTeamsDetails={setEditAllTeamsDetails}
              inProgress={inProgress}
              setInProgress={setInProgress}
              refetchTeams={refetchTeams}
            />
          ) : null}
          {deleteVerification ? (
            <ConfirmModal
              header={"All Team"}
              onCancel={() => {
                setDeleteVerification(false);
              }}
              onXIcon={() => {
                setDeleteVerification(false);
              }}
              onExecute={() => deleteHandler()}
            />
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

export default AllTeams;
