import { FC, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Filled } from "components/forms";
import DataCard from "components/App/Card/DataCard";
import ConfirmModal from "components/forms/Modal/ConfirmModal";

import { toastNotify } from "global/helpers/Cache";
import { removeDuplicates } from "global/helpers/ArrayMethods";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import TechnicalSkills from "modules/Employee/Pages/Profile/Skills/SkillsDetails/TechnicalSkills";
import NonTechnicalSkills from "modules/Employee/Pages/Profile/Skills/SkillsDetails/NonTechnicalSkills";
import EditSkillDetails from "modules/Employee/Pages/Profile/Skills/SkillsDetails/EditSkillDetails";
import { SKILLS, GET_SKILLS_DETAILS } from "modules/Employee/services/queries";
import { DELETE_SKILL } from "modules/Employee/services/mutations";
import {
  IEditSkill,
  ISkill,
  ISkillDetail,
  ISkillsList,
} from "modules/Employee/types/skills";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  loading: boolean;
  userSkills: ISkillDetail[];
  refetchSkillDetails: Function;
}

const SkillsDetails: FC<IProps> = ({
  loading,
  userSkills,
  refetchSkillDetails,
}) => {
  const resourceAccess = useGetAllowedResources("EmployeeSkill");

  const [fetchSkills, { data: skills, loading: skillsLoader }] =
    useLazyQuery(SKILLS);

  const isUnderModeration = useIsUnderModeration();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editSkill, setEditSkill] = useState<IEditSkill | null>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [deleteUserSkill] = useMutation(DELETE_SKILL);

  useEffect(() => {
    if (showModal) fetchSkills();
  }, [fetchSkills, showModal]);

  const technicalSkills: ISkillsList[] = [],
    nonTechnicalSkills: ISkillsList[] = [];
  const userTechnicalSkills: string[] = [],
    userTechnicalSkillsId: number[] = [],
    userTechnicalSkillsList: ISkillsList[] = [],
    userTechnicalSkillsModerationIds: number[] = [],
    userNonTechnicalSkills: string[] = [],
    userNonTechnicalSkillsId: number[] = [],
    userNonTechnicalSkillsList: ISkillsList[] = [],
    userNonTechnicalSkillsModerationIds: number[] = [];

  const skillsList = skills?.skills?.map((skill: ISkill) => skill?.type);
  const skillTypes: string[] = removeDuplicates(skillsList);

  skills?.skills?.map((skill: ISkill) => {
    if (skill?.type === "Technical")
      technicalSkills.push({ id: skill?.id, name: skill?.name });
    if (skill?.type === "Non Technical")
      nonTechnicalSkills.push({ id: skill?.id, name: skill?.name });
    return { technicalSkills, nonTechnicalSkills };
  });

  const userSkillTypes = removeDuplicates(
    userSkills?.map((userSkill: ISkillDetail) => userSkill?.skill?.type)
  );

  userSkills?.map((userSkill: ISkillDetail) => {
    if (userSkill?.skill?.type === "Technical") {
      userTechnicalSkills.push(userSkill?.skill?.name);
      userTechnicalSkillsId.push(userSkill?.skill?.id);
      userTechnicalSkillsList.push({
        id: userSkill?.skill?.id,
        name: userSkill?.skill?.name,
      });
      userSkill?.moderation?.skill &&
        userTechnicalSkillsModerationIds?.push(userSkill?.moderation?.skill);
    }
    if (userSkill?.skill?.type === "Non Technical") {
      userNonTechnicalSkills.push(userSkill?.skill?.name);
      userNonTechnicalSkillsId.push(userSkill?.skill?.id);
      userNonTechnicalSkillsList.push({
        id: userSkill?.skill?.id,
        name: userSkill?.skill?.name,
      });
      userSkill?.moderation?.skill &&
        userNonTechnicalSkillsModerationIds.push(userSkill?.moderation?.skill);
    }
    return { userTechnicalSkills, userNonTechnicalSkills };
  });

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Skill")
    : deleteSuccessMessageWithAwaiting("Skill");

  const deleteHandler = () => {
    const sessionId: string | any = document.cookie
      .split("; ")
      .find((row) => row.startsWith("id="))
      ?.split("=")[1];
    deleteUserSkill({
      variables: {
        userId: sessionStorage?.otherUserId
          ? +sessionStorage?.otherUserId
          : +localStorage?.id || +sessionId,
        skillIds: editSkill?.skills?.ids,
        type: editSkill?.type,
      },
      update: (cache) => {
        const exSkills: any = cache.readQuery({
          query: GET_SKILLS_DETAILS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
        });

        const updatedSkills =
          exSkills?.users?.dataCollection[0]?.userSkills?.filter(
            (skill: ISkillDetail) =>
              editSkill?.skills?.ids?.indexOf(skill?.skill.id) !== -1
                ? null
                : skill
          );

        cache.writeQuery({
          query: GET_SKILLS_DETAILS,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
          data: {
            users: {
              dataCollection: [
                {
                  ...exSkills?.users?.dataCollection[0],
                  userSkills: updatedSkills,
                },
              ],
            },
          },
        });
      },
    })
      .then(() => {
        refetchSkillDetails();
        setEditSkill(null);
        setDeleteVerification(false);
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<
    (number | null)[]
  >([]);

  const moderationProps = {
    setChangedInformation: setChangedInformation,
    setShowUnderReview: setShowUnderReview,
  };

  const parentRef = useRef<HTMLDivElement>(null);

  const userSkillTypesList =
    (userSkillTypes &&
      userSkillTypes?.filter((skillType: string[]) => skillType)) ||
    [];

  const newSkillsModeration =
    userSkills?.filter(
      (skill) => skill?.id && !skill?.skill && skill?.moderation?.skill
    ) || [];

  const moderationPopupClickHandler = (moderationIds: (number | null)[]) => {
    const filteredModerationIds = moderationIds?.filter(
      (moderationId: number | null) => moderationId
    );

    filteredModerationIds?.length > 0 && setShowUnderReview(true);

    setChangedInformation(filteredModerationIds);
  };

  return (
    <div>
      <DataCard
        loading={loading}
        title="Skills"
        showPlusIcon={
          resourceAccess?.canCreate &&
          userSkills?.length > 0 &&
          userSkillTypesList?.length !== 2
            ? true
            : false
        }
        resourceName={"EmployeeSkill"}
        setShowModal={setShowModal}
      >
        {resourceAccess?.canCreate && userSkills?.length === 0 ? (
          <div className="w-full min-h-[390px] grid place-content-center">
            <Filled
              buttonName={"Add"}
              buttonType="button"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        ) : (
          <div
            className="w-full overflow-x-auto flex flex-col gap-9 pt-6 min-h-[387px]"
            ref={parentRef}
          >
            {userSkillTypes?.map(
              (userSkillType: string) => userSkillType === "Technical"
            ) && (
              <TechnicalSkills
                userSkills={userSkills}
                setEditSkill={setEditSkill}
                userTechnicalSkills={userTechnicalSkills}
                userTechnicalSkillsId={userTechnicalSkillsId}
                userTechnicalSkillsList={userTechnicalSkillsList}
                setShowModal={setShowModal}
                setDeleteVerification={setDeleteVerification}
                resourceAccess={resourceAccess}
                parentRef={parentRef}
                userTechnicalSkillsModerationIds={
                  userTechnicalSkillsModerationIds
                }
                {...moderationProps}
              />
            )}

            {userSkillTypes?.map(
              (userSkillType: string) => userSkillType === "Non Technical"
            ) && (
              <NonTechnicalSkills
                userSkills={userSkills}
                setEditSkill={setEditSkill}
                userNonTechnicalSkills={userNonTechnicalSkills}
                userNonTechnicalSkillsId={userNonTechnicalSkillsId}
                userNonTechnicalSkillsList={userNonTechnicalSkillsList}
                setShowModal={setShowModal}
                setDeleteVerification={setDeleteVerification}
                resourceAccess={resourceAccess}
                parentRef={parentRef}
                userNonTechnicalSkillsModerationIds={
                  userNonTechnicalSkillsModerationIds
                }
                {...moderationProps}
              />
            )}

            {newSkillsModeration?.length > 0 &&
              newSkillsModeration?.map((newSkillModeration) => {
                return (
                  <div key={newSkillModeration?.id}>
                    <p
                      className="bg-yellow-100 py-2 cursor-pointer"
                      onClick={() =>
                        moderationPopupClickHandler([
                          newSkillModeration?.moderation?.skill,
                        ])
                      }
                    >
                      {(newSkillModeration?.skill &&
                        `${newSkillModeration?.skill}`) ||
                        "Eg.Skill name"}
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </DataCard>

      {showModal && (
        <EditSkillDetails
          userSkillTypes={userSkillTypes}
          skillTypes={skillTypes}
          technicalSkills={technicalSkills}
          nonTechnicalSkills={nonTechnicalSkills}
          setShowModal={setShowModal}
          editSkill={editSkill}
          setEditSkill={setEditSkill}
          loading={!!skillsLoader}
          userTechnicalSkillsModerationIds={userTechnicalSkillsModerationIds}
          userNonTechnicalSkillsModerationIds={
            userNonTechnicalSkillsModerationIds
          }
          refetchSkillDetails={refetchSkillDetails}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"Skills"}
          onCancel={() => {
            setEditSkill(null);
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={deleteHandler}
        />
      )}

      {showUnderReview && (
        <UnderReviewModal
          {...moderationProps}
          changedInformation={changedInformation}
          refetchQuery={refetchSkillDetails}
        />
      )}
    </div>
  );
};

export default SkillsDetails;
