import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import EditEmployeeDepartment, {
  DepartmentType,
} from "./EditEmployeeDepartment";
import Filled from "components/forms/Button/Filled";
import { SearchField } from "components/forms";
import Table from "components/App/Table/Table";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import TableContent from "components/App/Table/TableContent";
import Pagination from "components/Pagination/Pagination";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import Loading from "components/Loader/Loading";
import OopsNoData from "components/UI/OopsNoData";

import { paginationDefaultValue } from "global/helpers/StaticData";
import { toastNotify } from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";

import { DEPARTMENTS } from "modules/Organization/services/queries";
import { DELETE_DEPARTMENT } from "modules/Organization/services/mutations";
import { IAllowedResources } from "modules/Organization/types/organization";

const Department: FC<IAllowedResources> = ({ allowedResources }) => {
  const createAccess = allowedResources?.includes("CreateEmployeeDepartment");
  const updateAccess = allowedResources?.includes("UpdateEmployeeDepartment");
  const deleteAccess = allowedResources?.includes("DeleteEmployeeDepartment");

  const [fetchDepartments, { data: employeeDepartments, loading, refetch }] =
    useLazyQuery(DEPARTMENTS, {
      fetchPolicy: "cache-and-network",
    });

  const { register, watch, setValue } = useForm();

  const department = watch("department");

  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [employeeDepartment, setEmployeeDepartment] = useState<any>();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const [search, setSearch] = useState(department || "");

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(department?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [department]);

  useEffect(() => {
    fetchDepartments({
      variables: {
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [currentPage, fetchDepartments, pageSize, search]);

  const parentRef = useRef<HTMLDivElement>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [deleteEmployeeDepartment] = useMutation(DELETE_DEPARTMENT);
  const deleteHandler = () => {
    if (employeeDepartment?.id) {
      deleteEmployeeDepartment({
        variables: {
          id: +employeeDepartment?.id,
        },
        update: (cache) => {
          const exDepartments: {
            employeeDepartments: DepartmentType[] | any;
          } | null = cache.readQuery({
            query: DEPARTMENTS,
          });

          const updatedDeparments =
            exDepartments?.employeeDepartments?.dataCollection?.filter(
              (departmentDetails: DepartmentType) =>
                +departmentDetails?.id !== +employeeDepartment?.id
            );

          cache.writeQuery({
            query: DEPARTMENTS,
            data: {
              employeeDepartments: { dataCollection: updatedDeparments },
            },
          });
        },
      })
        .then(() => {
          const departmentName = employeeDepartment?.name;
          setDeleteVerification(false);
          refetch();
          toastNotify([
            {
              messageType: "success",
              message: `${
                departmentName ? `${departmentName} department` : "Department"
              } has been deleted successfully.`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };
  const plusIconClickHandler = () => {
    setShowModal(true);
    setEmployeeDepartment(null);
  };
  const kebabMenuClickHandler = (employeeDepartment: any) => {
    setEmployeeDepartment(employeeDepartment);
  };
  const kebabMenuEditHandler = () => {
    if (updateAccess) {
      setShowModal(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row md:pb-0 pt-5">
        <SearchField
          register={register}
          name="department"
          label="Department"
          onChange={() => {
            setCurrentPage(1);
          }}
          setValue={setValue}
        />
      </div>
      <div>
        {loading ? (
          <Loading className="min-h-[70vh]" />
        ) : employeeDepartments?.employeeDepartments?.dataCollection?.length ===
            0 &&
          !showModal &&
          search?.length <= 0 ? (
          <div className="min-h-[440px] md:min-h-[715px] lg:min-h-[688px]">
            <div className="flex justify-center items-center h-[500px]">
              {createAccess ? (
                <Filled
                  buttonName={"Add"}
                  onClick={() => {
                    setShowModal(true);
                    setEmployeeDepartment(null);
                  }}
                />
              ) : null}
            </div>
          </div>
        ) : search?.length > 0 ? (
          <OopsNoData className="min-h-[70vh]" />
        ) : (
          <div className="min-h-[440px] md:min-h-[658px] lg:min-h-[674px]">
            <div className="hidden md:block">
              <Table
                loading={loading}
                noOfItems={
                  employeeDepartments?.employeeDepartments?.dataCollection
                    ?.length
                }
                tableHeadingsObj={[{ name: "Department", center: false }]}
                ref={parentRef}
                isCenterlastHeading={true}
                className="py-5 min-h-[590px]"
                currentPage={currentPage}
                pageSize={pageSize}
                onPlusIconClick={plusIconClickHandler}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                totalCount={
                  employeeDepartments?.employeeDepartments
                    ? employeeDepartments?.employeeDepartments
                        ?.totalNumberOfItems
                    : 0
                }
                createAccess={createAccess}
              >
                {employeeDepartments?.employeeDepartments?.dataCollection?.map(
                  (employeeDepartment: any) => {
                    let tableContent = [{ value: employeeDepartment?.name }];
                    return (
                      <TableContent
                        key={employeeDepartment?.id}
                        id={employeeDepartment?.id}
                        tableContent={tableContent}
                        editAccess={updateAccess || deleteAccess}
                        onEdit={kebabMenuEditHandler}
                        showModal={showModal || deleteVerification}
                        onDelete={kebabMenuDeleteHandler}
                        onMenuClick={() => {
                          kebabMenuClickHandler(employeeDepartment);
                        }}
                        parentRef={parentRef}
                      />
                    );
                  }
                )}
              </Table>
            </div>
            <div className="md:hidden">
              <div className="w-full">
                {createAccess ? (
                  <button
                    className="w-[150px] lg:w-[120px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 leading-[20px] rounded-[4px]"
                    onClick={() => {
                      setShowModal(true);
                      setEmployeeDepartment(null);
                    }}
                  >
                    Add
                  </button>
                ) : null}
              </div>
              <div className="lg:px-[45px] bg-white mt-[20px] md:mt-[30px] min-h-[412px]">
                {employeeDepartments?.employeeDepartments?.dataCollection?.map(
                  (employeeDepartment: any, index: number) => {
                    return (
                      <SMDataCard
                        key={employeeDepartment?.id}
                        title={`Department ${index + 1}`}
                        showModal={deleteVerification || showModal}
                        loading={loading}
                        dataModal={[
                          {
                            lable: "Department",
                            value: [
                              {
                                content: Capitalize(employeeDepartment?.name),
                              },
                            ],
                          },
                        ]}
                        onKebabIcon={{
                          onclick: () => {
                            kebabMenuClickHandler(employeeDepartment);
                          },
                          kebabMenu: {
                            onEdit: kebabMenuEditHandler,
                            onDelete: kebabMenuDeleteHandler,
                          },
                        }}
                      />
                    );
                  }
                )}
                <Pagination
                  totalCount={
                    employeeDepartments?.employeeDepartments
                      ? employeeDepartments?.employeeDepartments
                          ?.totalNumberOfItems
                      : 0
                  }
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page: number) => setCurrentPage(page)}
                  noOfItem={
                    employeeDepartments?.employeeDepartments?.dataCollection
                      ?.length
                  }
                />
              </div>
            </div>
          </div>
        )}

        {showModal ? (
          <EditEmployeeDepartment
            employeeDepartment={employeeDepartment}
            setShowModal={setShowModal}
            inProgress={inProgress}
            setInProgress={setInProgress}
            refetch={refetch}
          />
        ) : null}
        {deleteVerification ? (
          <ConfirmModal
            header={"Department"}
            onCancel={() => {
              setDeleteVerification(false);
            }}
            onXIcon={() => {
              setDeleteVerification(false);
            }}
            onExecute={deleteHandler}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default Department;
