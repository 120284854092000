const CheckBox: React.FC<{
  name: string;
  defaultCheckboxValue?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: Function | any;
}> = ({ name, defaultCheckboxValue, disabled, required, onChange }) => {
  return (
    <div>
      <input
        type="checkbox"
        className="cursor-pointer"
        id={name}
        checked={defaultCheckboxValue}
        disabled={disabled}
        required={required ? required : false}
        onChange={onChange}
      />
    </div>
  );
};

export default CheckBox;
