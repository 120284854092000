import { FC } from "react";

import Loading from "components/Loader/Loading";

interface IProps {
  className?: string;
  buttonName: string;
  onClick?: () => void;
  buttonType?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
}

const Outlined: FC<IProps> = ({
  buttonName,
  buttonType,
  className,
  disabled,
  loading,
  onClick,
}) => {
  return (
    <button
      type={buttonType ? buttonType : "button"}
      className={`${
        className ? className : ""
      } min-w-[110px] lg:min-w-[120px] h-[45px] text-sm border bg-white rounded  ${
        loading || disabled
          ? disabled
            ? "cursor-not-allowed border-hit-gray/60 text-hit-gray/60"
            : "cursor-not-allowed border-cornflower-blue ring-offset-2 focus:outline-none focus:ring-2 ring-cornflower-blue"
          : "cursor-pointer text-ironside-gray hover:text-cornflower-blue hover:border-cornflower-blue ring-offset-2 focus:outline-none focus:ring-2 ring-cornflower-blue"
      }`}
      onClick={onClick}
      disabled={disabled ? true : false}
    >
      {loading ? disabled ? buttonName : <Loading smallLoading /> : buttonName}
    </button>
  );
};

export default Outlined;
