import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useMutation } from "@apollo/client";

import {
  Filled,
  Text,
  Modal,
  Outlined,
  ToggleSwitch,
  Select,
} from "components/forms";

import { toastNotify } from "global/helpers/Cache";
import { IDAndName } from "global/types/type";

import {
  CREATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
} from "modules/Leave/services/mutations";
import { LEAVE_TYPES } from "modules/Leave/services/queries";
import CompanyField from "modules/Company/helpers/CompanyField";

export interface LeaveType {
  id: number;
  name: string;
  isSupportingDocNeeded: boolean;
  leaveTypesdata: any;
  isSpecialLeave: boolean;
  leaveType: string;
  isRestrictedHolidayLeave: boolean;
  noOfRestrictedHolidays: number;
  availedNoOfRestrictedHolidays: number;
  leaveSettlementType: string;
  companies: IDAndName<number, "label">[];
}

interface IProps {
  leaveTypesdata: any;
  setShowModal: Function;
  refetchLeaveTypes: Function;
}

const EditLeavetype: FC<IProps> = ({
  leaveTypesdata,
  setShowModal,
  refetchLeaveTypes,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<LeaveType>({
    defaultValues: {
      companies:
        leaveTypesdata?.company && leaveTypesdata?.company?.length > 0
          ? leaveTypesdata?.company?.map(
              (company: IDAndName<number, "name">) => ({
                id: company?.id,
                label: company?.name,
              })
            )
          : [],
    },
  });

  const [createLeaveType] = useMutation(CREATE_LEAVE_TYPE);
  const [updateLeaveType] = useMutation(UPDATE_LEAVE_TYPE);

  const [inProgress, setInProgress] = useState(false);
  const [restrictedLeaveTransition, setRestrictedLeaveTransition] =
    useState<boolean>(false);

  const leaveType = watch("leaveType");
  const isRestrictedLeave = leaveType === "Restricted" ? true : false;

  useEffect(() => {
    setValue(
      "leaveType",
      leaveTypesdata?.isRestrictedHolidayLeave
        ? "Restricted"
        : leaveTypesdata?.isSpecialLeave
        ? "Special"
        : "Regular"
    );

    setValue("leaveSettlementType", leaveTypesdata?.leaveSettlementType);
  }, [leaveTypesdata, setValue]);

  const createHandler = (data: LeaveType) => {
    if (!inProgress) {
      createLeaveType({
        variables: {
          name: data.name,
          isSupportingDocNeeded: data.isSupportingDocNeeded,
          isSpecialLeave: leaveType === "Special" ? true : undefined,
          isRestrictedHolidayLeave: isRestrictedLeave ? true : undefined,
          noOfRestrictedHolidays: isRestrictedLeave
            ? +data?.noOfRestrictedHolidays
            : undefined,
          availedNoOfRestrictedHolidays: isRestrictedLeave
            ? +data?.availedNoOfRestrictedHolidays
            : undefined,
          leaveSettlementType: data.leaveSettlementType,
          companyIds:
            data?.companies && data?.companies?.length > 0
              ? data?.companies?.map((company) => company?.id)
              : undefined,
        },
      })
        .then(() => {
          setInProgress(false);
          setShowModal(false);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: "Leave type has been created successfully.",
            },
          ]);
          refetchLeaveTypes();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: LeaveType) => {
    if (!inProgress) {
      updateLeaveType({
        variables: {
          id: +leaveTypesdata?.id,
          name: data.name,
          isSupportingDocNeeded: data.isSupportingDocNeeded,
          isSpecialLeave: leaveType === "Special" ? true : false,
          isRestrictedHolidayLeave: isRestrictedLeave ? true : false,
          noOfRestrictedHolidays: isRestrictedLeave
            ? +data?.noOfRestrictedHolidays
            : null,
          availedNoOfRestrictedHolidays: isRestrictedLeave
            ? +data?.availedNoOfRestrictedHolidays
            : null,
          leaveSettlementType: data.leaveSettlementType,
          companyIds:
            data?.companies && data?.companies?.length > 0
              ? data?.companies?.map((company) => company?.id)
              : [],
        },
        update: (cache, { data: { updateLeaveType } }) => {
          const exLeaveTypes: {
            leaveTypes: LeaveType[] | any;
          } | null = cache.readQuery({
            query: LEAVE_TYPES,
          });

          const updatedLeaveTypes =
            exLeaveTypes?.leaveTypes?.dataCollection?.map(
              (leaveType: LeaveType) => {
                if (+leaveType?.id === +leaveTypesdata?.id) {
                  return {
                    ...leaveType,
                    id: updateLeaveType?.id,
                    name: updateLeaveType?.name,
                    isSupportingDocNeeded:
                      updateLeaveType?.isSupportingDocNeeded,
                    isSpecialLeave: updateLeaveType?.isSpecialLeave,
                  };
                }
                return leaveType;
              }
            );

          cache.writeQuery({
            query: LEAVE_TYPES,
            data: {
              leaveTypes: {
                dataCollection: updatedLeaveTypes,
              },
            },
          });
        },
      })
        .then((response) => {
          setInProgress(false);
          setShowModal(false);
          toastNotify([
            {
              messageType: "success",
              message: "Leave type has been updated successfully.",
            },
          ]);
          refetchLeaveTypes();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <Modal>
      <div className="w-[350px] md:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
        <div className="edit-leave-modal-header-div px-[18px] md:px-[45px] boder-hit-gray">
          <h2 className="text-base text-ironside-gray ">Leave Type</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setInProgress(false);
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <form
          onSubmit={
            leaveTypesdata?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
        >
          <div className="px-[45px] py-5 border-b space-y-2">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-[150px_auto] items-center">
              <p className="text-sm text-ironside-gray pb-2 md:pb-[21px]">
                Leave Type
              </p>
              <Text
                register={register}
                errors={errors}
                label=" Enter Leave Type *"
                name="name"
                required={true}
                defaultText={leaveTypesdata?.name}
              />
            </div>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-[150px_auto] items-center">
              <p className="text-sm text-ironside-gray pb-2 md:pb-[21px]">
                Leave Category
              </p>
              <Select
                register={register}
                defaultSelectValue={leaveTypesdata?.isSupportingDocNeeded}
                errors={errors}
                label="Choose Leave Category"
                options={["Regular", "Special", "Restricted"]}
                name="leaveType"
                onChange={() => {
                  setRestrictedLeaveTransition(true);
                }}
                disableDefaultSelectOption
              />
            </div>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-[150px_auto] items-center">
              <p className="text-sm text-ironside-gray pb-2 md:pb-[21px]">
                Companies
              </p>
              <CompanyField
                control={control}
                name="companies"
                label="Companies"
                multiple
                className="bg-white"
              />
            </div>

            {leaveType !== "Special" && (
              <div className="grid grid-cols-1 gap-2 md:grid-cols-[150px_auto] items-center">
                <p className="text-sm text-ironside-gray pb-2 md:pb-[21px]">
                  Leave Settlement
                </p>
                <Select
                  register={register}
                  defaultSelectValue={leaveTypesdata?.remainingLeaveOption}
                  errors={errors}
                  label="Choose Leave Settlement Type *"
                  options={["Carry Forward", "Encashment", "Lapse"]}
                  name="leaveSettlementType"
                  required
                />
              </div>
            )}
            <div
              className={`overflow-hidden ${
                isRestrictedLeave
                  ? `max-h-[2000px] pt-1 ${
                      restrictedLeaveTransition
                        ? "[transition:max-height_1.5s_linear]"
                        : ""
                    } `
                  : "max-h-[0px] [transition:max-height_1s_cubic-bezier(0,1,0,1)]"
              }`}
            >
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="text-sm text-ironside-gray pt-3">Count</p>
                <Text
                  register={register}
                  errors={errors}
                  label=" Enter no of restricted holidays *"
                  name="noOfRestrictedHolidays"
                  required={isRestrictedLeave ? true : false}
                  defaultText={leaveTypesdata?.noOfRestrictedHolidays}
                  minimumLength={1}
                />
              </div>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto]">
                <p className="text-sm text-ironside-gray pt-3">
                  Days availability
                </p>
                <Text
                  register={register}
                  errors={errors}
                  label=" Enter no of days can be availed *"
                  name="availedNoOfRestrictedHolidays"
                  required={isRestrictedLeave ? true : false}
                  defaultText={leaveTypesdata?.availedNoOfRestrictedHolidays}
                  minimumLength={1}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-[150px_auto] items-center">
              <p className="text-sm text-ironside-gray">Is Proofs Required ?</p>
              <ToggleSwitch
                label={"toggleDocuments"}
                register={register}
                name={"isSupportingDocNeeded"}
                defaultValue={leaveTypesdata?.isSupportingDocNeeded}
              />
            </div>
          </div>
          <div className="button-div px-[18px] md:px-[45px]">
            <Outlined
              buttonName="Cancel"
              onClick={() => {
                setInProgress(false);
                setShowModal(false);
              }}
            />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : leaveTypesdata?.id ? (
                  "Update"
                ) : (
                  "Create"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditLeavetype;
