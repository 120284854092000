import { gql } from "@apollo/client";

export const GET_COMPENSATION_LEAVE_APPROVING_USERS_FRAGMENT = gql`
  fragment compensationLeaveApprovingUserFields on User {
    id
    personalInformation {
      id
      name
    }
  }
`;

export const GET_MY_COMPENSATION_LEAVE_FRAGMENT = gql`
  fragment myCompensationFields on LeaveRequest {
    id
    createdAt
    updatedAt
    reason
    declineMessage
    requestedToUser {
      personalInformation {
        id
        name
      }
    }
    compensationLeaveDates {
      id
      leaveDate
      session
      declineMessage
      status
      startTime
      endTime
      project {
        id
        name
      }
    }
  }
`;

export const GET_TEAM_OR_ALL_COMPENSATION_FRAGMENT = gql`
  fragment teamOrAllCompensationFields on LeaveRequest {
    id
    createdAt
    updatedAt
    reason
    declineMessage
    requestedToUser {
      personalInformation {
        id
        name
      }
    }
    requestedByUser {
      personalInformation {
        id
        name
      }
    }
    compensationLeaveDates {
      id
      leaveDate
      session
      declineMessage
      status
      startTime
      endTime
      project {
        id
        name
      }
    }
  }
`;
