import TabLink from "components/App/TabLink/TabLink";

import VaultEnvironments from "modules/Project/Pages/VaultEnvironments/VaultEnvironments";
import VaultTypes from "modules/Project/Pages/VaultCredentialTypesAndFields/VaultCredentialTypes";
import ProjectRoleResources from "modules/Project/Pages/Role/ProjectRoleResources";
import EditProjectRole from "modules/Project/Pages/Role/EditProjectRole";
import ProjectRoles from "modules/Project/Pages/Role/ProjectRoles";
import SettingsTaskLabel from "modules/Project/Pages/SettingsTaskLabel/SettingsTaskLabel";
import SettingsColumns from "modules/Project/Pages/SettingsColumns/SettingsColumns";
import ProjectBugSources from "modules/Project/Pages/ProjectGroupBugSource/ProjectBugSources";

const ProjectSettingsRouterControl = () => {
  const tabLinks = [
    {
      name: "Roles",
      link: `/projects/settings/roles`,
      allowedResourceIds: ["ReadProjectRole"],
    },
    {
      name: "Vault Environments",
      link: `/projects/settings/vault-environments`,
      allowedResourceIds: ["ReadProjectVaultEnvironment"],
    },
    {
      name: "Vault Types",
      link: `/projects/settings/vault-types`,
      allowedResourceIds: ["ReadProjectVaultCredentialType"],
    },
    {
      name: "Task Label",
      link: "/projects/settings/task-label",
      allowedResourceIds: ["ReadProjectLabel"],
    },
    {
      name: "Column",
      link: "/projects/settings/columns",
      allowedResourceIds: ["ReadColumn"],
    },
    {
      name: "Bug source",
      link: "/projects/settings/bug-source",
      allowedResourceIds: ["ReadProjectBugSource"],
    },
  ];

  const routes = [
    {
      path: "roles",
      element: <ProjectRoles />,
    },
    {
      path: "roles/edit-role",
      element: <EditProjectRole />,
    },
    {
      path: "roles/edit-role/role-resources",
      element: <ProjectRoleResources />,
    },
    {
      path: "vault-environments",
      element: <VaultEnvironments />,
    },
    {
      path: "vault-types",
      element: <VaultTypes />,
    },
    {
      path: "task-label",
      element: <SettingsTaskLabel />,
    },
    {
      path: "columns",
      element: <SettingsColumns />,
    },
    {
      path: "bug-source",
      element: <ProjectBugSources />
    }
  ];

  return (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh-22vh)] bg-white">
      <TabLink tabLinks={tabLinks} routes={routes} />
    </div>
  );
};

export default ProjectSettingsRouterControl;
