import { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { notFoundMessage } from "global/helpers/action-success-error-messages";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  getAllowedAccess,
  toastNotify,
  setBackNavigationURL,
  setTitle,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";

import { GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPES } from "modules/Project/services/queries";
import { DELETE_PROJECT_VAULT_CREDENTIAL_TYPES } from "modules/Project/services/mutations";
import { IProjectVaultCredentialTypes } from "modules/Project/types/project";
import { IProjectFilterForm } from "modules/Project/types/vault";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";
import EditVaultCredentialTypes from "./EditVaultCredentialTypes";

const tableCommonHeadings = [
  { name: "Name", APIName: "name" },
  { name: "Description", APIName: "description" },
];

const VaultCredentialTypes = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const canRead = allowedResources.includes("ReadProjectVaultCredentialType");
  const canCreateOrUpdate = allowedResources.includes(
    "SaveProjectVaultCredentialType"
  );
  const canDelete = allowedResources.includes(
    "DeleteProjectVaultCredentialType"
  );

  const canReadInputFields = allowedResources?.includes(
    "ReadProjectCredentialTypeField"
  );

  const tableHeadings =
    canDelete && canReadInputFields
      ? [...tableCommonHeadings, { name: "Input Fields" }, { name: "Action" }]
      : canDelete
      ? [...tableCommonHeadings, { name: "Action" }]
      : canReadInputFields
      ? [...tableCommonHeadings, { name: "Input Fields" }]
      : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });

  const [
    deleteProjectVaultCredentialType,
    { loading: deleteProjectVaultCredentialTypeLoading },
  ] = useMutation(DELETE_PROJECT_VAULT_CREDENTIAL_TYPES);

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    setTitle([
      { name: "Project", link: "/projects/projects/my-projects" },
      {
        name: "Vault Credential Type",
        link: "/projects/settings/vault-types",
      },
    ]);
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const [
    fetchAllProjectVaultCredentialTypes,
    {
      data: getAllProjectVaultCredentialTypes,
      loading,
      refetch: refetchProjectVaultCredentialTypes,
    },
  ] = useLazyQuery(GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPES, {
    fetchPolicy: "cache-and-network",
  });

  const projectVaultCredentialTypesList =
    getAllProjectVaultCredentialTypes?.projectVaultCredentialType || [];

  const totalCount = projectVaultCredentialTypesList?.length || 0;

  const noOfItems =
    getAllProjectVaultCredentialTypes &&
    projectVaultCredentialTypesList?.length;

  useEffect(() => {
    fetchAllProjectVaultCredentialTypes({
      variables: {
        limit: pageSize,
        page: currentPage,
        search: search || undefined,
        orderBy: sortOrder
          ? {
              field: sortOrder?.field,
              orderType: sortOrder?.orderType,
            }
          : undefined,
      },
    });
  }, [
    currentPage,
    fetchAllProjectVaultCredentialTypes,
    pageSize,
    search,
    sortOrder,
  ]);

  const [editProjectVaultCredentialType, setEditProjectVaultCredentialType] =
    useState<IProjectVaultCredentialTypes | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectVaultCredentialType?.id) {
      deleteProjectVaultCredentialType({
        variables: {
          id: +editProjectVaultCredentialType?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectVaultCredentialTypes();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectVaultCredentialType?.name
                  ? `${editProjectVaultCredentialType?.name} credential type`
                  : "Credential Type"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (projectVaultCredentialType: IProjectVaultCredentialTypes) => {
    if (canCreateOrUpdate) {
      setEditProjectVaultCredentialType(projectVaultCredentialType);
      setShowModal(true);
    }
  };

  const onDelete = (
    projectVaultCredentialType: IProjectVaultCredentialTypes
  ) => {
    setEditProjectVaultCredentialType(projectVaultCredentialType);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectVaultCredentialType(null);
  };

  const getDataModal = (
    projectVaultCredentialType: IProjectVaultCredentialTypes
  ): IDataModal[] => {
    const commonContent = [
      {
        label: "Name",
        value: [
          {
            content: Capitalize(projectVaultCredentialType?.name),
          },
        ],
      },
      {
        label: "Description",
        value: [
          {
            content: projectVaultCredentialType?.description,
          },
        ],
      },
    ];

    return canReadInputFields
      ? [
          ...commonContent,
          {
            label: "Input Fields",
            value: [
              {
                content: (
                  <span
                    className="cursor-pointer hover:text-cornflower-blue hover:underline"
                    onClick={() => {
                      navigate(
                        `/projects/edit/${projectVaultCredentialType?.id}/vault-types-input`,
                        {
                          state: {
                            name: projectVaultCredentialType?.name,
                          },
                        }
                      );
                    }}
                  >
                    View
                  </span>
                ),
              },
            ],
          },
        ]
      : commonContent;
  };

  const getTableContent = (
    projectVaultCredentialType: IProjectVaultCredentialTypes
  ): ITableContent[] => {
    const commonContent = [
      {
        value: projectVaultCredentialType?.name,
      },
      {
        value: projectVaultCredentialType?.description,
      },
    ];

    const trashIcon = {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(projectVaultCredentialType);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    };

    return canReadInputFields
      ? [
          ...commonContent,
          {
            value: (
              <span
                className="cursor-pointer hover:text-cornflower-blue hover:underline"
                onClick={() =>
                  navigate(
                    `/projects/edit/${projectVaultCredentialType?.id}/vault-types-input`,
                    {
                      state: {
                        name: projectVaultCredentialType?.name,
                      },
                    }
                  )
                }
              >
                View
              </span>
            ),
          },
          trashIcon,
        ]
      : [...commonContent, trashIcon];
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />

        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreateOrUpdate && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectVaultCredentialTypesList?.map(
                    (
                      projectVaultCredentialType: IProjectVaultCredentialTypes
                    ) => {
                      return (
                        <DataCard
                          key={projectVaultCredentialType?.id}
                          dataModal={getDataModal(projectVaultCredentialType)}
                          classForULlist={`${
                            canCreateOrUpdate
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          cardClick={() => {
                            canCreateOrUpdate &&
                              onEdit(projectVaultCredentialType);
                          }}
                          classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                          otherOption={
                            (canDelete && (
                              <div className="flex justify-center">
                                <ToolTip
                                  render={() => "Delete"}
                                  arrow
                                  className={
                                    "text-center bg-[#616161] text-white"
                                  }
                                >
                                  <BiTrash
                                    className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDelete(projectVaultCredentialType);
                                    }}
                                  />
                                </ToolTip>
                              </div>
                            )) ||
                            undefined
                          }
                        />
                      );
                    }
                  )}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  isSortOrderNeeded
                >
                  {projectVaultCredentialTypesList?.map(
                    (
                      projectVaultCredentialType: IProjectVaultCredentialTypes
                    ) => {
                      return (
                        <TableContent
                          key={projectVaultCredentialType?.id}
                          tableRef={tableRef}
                          classForTableRow={`${
                            canRead
                              ? "cursor-pointer hover:bg-white-smoke"
                              : "cursor-default hover:bg-white-smoke"
                          }`}
                          tableRowClick={() => {
                            canRead && onEdit(projectVaultCredentialType);
                          }}
                          tableContent={getTableContent(
                            projectVaultCredentialType
                          )}
                        />
                      );
                    }
                  )}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditVaultCredentialTypes
          refetchProjectVaultCredentialTypes={
            refetchProjectVaultCredentialTypes
          }
          editProjectVaultCredentialType={editProjectVaultCredentialType}
          setShowModal={setShowModal}
          loading={loading}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Project"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectVaultCredentialTypeLoading}
        />
      )}
    </div>
  );
};

export default VaultCredentialTypes;
