import { twMerge } from "tailwind-merge";
import { TypedDocumentNode, gql, useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { FC, Fragment } from "react";
import { Checkbox, CheckboxGroup } from "react-aria-components";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import CustomCheckbox from "components/forms/UpdatedFormAgry/CheckBox/CustomCheckBox";

type Props<
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: Name;
  multiple?: boolean;
  optionOrQuery:
    | { query: TypedDocumentNode<{ getCheckBoxOptions?: string[] | null }> }
    | { options: string[] };
};
const dummyQuery: TypedDocumentNode<{
  getCheckBoxOptions?: string[] | null;
}> = gql`
  query Query {
    getCheckBoxOptions: getAddressTypes
  }
`;

const CheckBoxField: FC<Props> = ({
  control,
  name,
  multiple,
  optionOrQuery,
}) => {
  const { data, loading } = useQuery(
    "query" in optionOrQuery ? optionOrQuery?.query : dummyQuery,
    {
      fetchPolicy: "cache-first",
      skip: "options" in optionOrQuery ? true : false,
    }
  );

  const options =
    "options" in optionOrQuery
      ? optionOrQuery.options
      : data?.getCheckBoxOptions || [];

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister={false}
      render={({ field: { value, onChange } }) => {
        return multiple ? (
          <CheckboxGroup
            value={value || []}
            onChange={onChange}
            aria-label=""
            aria-labelledby=""
            className={"grid grid-cols-1 gap-2 truncate"}
          >
            {loading && "query" in optionOrQuery
              ? [1, 2, 3, 4]?.map((value) => (
                  <div key={value} className="flex gap-1 items-center">
                    <span className="w-8 h-8 bg-gray-300 rounded-full" />
                    <span className="w-44 h-3 bg-gray-300 rounded-md" />
                  </div>
                ))
              : options?.map((option, index) => {
                  return (
                    <Checkbox value={option} key={index} className={"truncate"}>
                      {({ isSelected }) => {
                        return (
                          <div
                            className={
                              "flex gap-2 items-center cursor-pointer truncate"
                            }
                          >
                            <CustomCheckbox
                              isChecked={isSelected}
                              className="m-[11px]"
                            />
                            <p className="text-sm font-normal text-on-surface truncate max-w-min">
                              {option}
                            </p>
                          </div>
                        );
                      }}
                    </Checkbox>
                  );
                })}
          </CheckboxGroup>
        ) : (
          <RadioGroup
            onChange={onChange}
            value={value}
            className={"flex flex-col gap-2"}
          >
            {options?.map((option) => {
              return (
                <RadioGroup.Option value={option} key={option} as={Fragment}>
                  {({ checked }) => {
                    return (
                      <RadioGroup.Label className="flex items-center gap-2 cursor-pointer group max-w-min">
                        <div className="relative group">
                          <span className="block w-8 h-8 bg-[#1B1D201F] invisible group-hover:visible border rounded-full" />
                          <span
                            className={twMerge(
                              "block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-inherit border-2  rounded-full",
                              checked
                                ? "border-primary"
                                : "border-on-surface-variant"
                            )}
                          />
                          {checked && (
                            <span
                              className={
                                "block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  w-2 h-2 bg-primary rounded-full"
                              }
                            />
                          )}
                        </div>
                        <p className="text-sm font-normal text-on-surface truncate">
                          {option}
                        </p>
                      </RadioGroup.Label>
                    );
                  }}
                </RadioGroup.Option>
              );
            })}
          </RadioGroup>
        );
      }}
    />
  );
};

export default CheckBoxField;
