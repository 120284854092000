import { TypedDocumentNode, gql } from "@apollo/client";
import {
	PROJECT_COLUMN_FRAGMENT,
	PROJECT_SPRINT_FRAGMENT,
	PROJECT_TASK_FRAGMENT,
	PROJECT_TASK_LABEL_FRAGMENT,
	PROJECT_WORK_LOG_FRAGMENT,
	PROJECTS_FRAGMENT,
	PROJECT_ROLES_FRAGMENT,
	PROJECT_VAULT_ENVIRONMENTS_FRAGMENT,
	PROJECT_VAULT_CREDENTIAL_TYPES_FRAGMENT,
	PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS_FRAGMENT,
	PROJECT_VAULT_CREDENTIAL_FRAGMENT,
	RUNNING_PROJECT_WORK_LOG_FRAGMENT,
	PROJECT_TASK_HISTORY_FRAGMENT,
	PROJECT_TASK_ATTACHEMENT_FRAGMENT,
	WORK_LOG_TOTAL_HOURS_FRAGMENT,
	PROJECT_TASK_FRAGMENT_UPDATED,
} from "modules/Project/services/fragments";
import {
	FilterProjectsArgs,
	FilterProjectsReturnType,
} from "modules/Project/types/project";
import {
	FilterProjectTasksArgs,
	FilterProjectTasksReturnType,
} from "modules/Project/types/project";

export const GET_ALL_PROJECTS = gql`
  ${PROJECTS_FRAGMENT}

  query getAllProjects($limit: Int, $page: Int, $search: String) {
    projects(limit: $limit, page: $page, search: $search) {
      dataCollection {
        ...projectFields
      }
      totalNumberOfItems
    }
  }
`;

export const GET_PROJECT = gql`
  ${PROJECTS_FRAGMENT}

  query getProject($filters: ProjectFilter) {
    projects(filters: $filters) {
      dataCollection {
        ...projectFields
      }
      totalNumberOfItems
    }
  }
`;

export const GET_ALL_PROJECT_ROLES = gql`
  ${PROJECT_ROLES_FRAGMENT}

  query projectRoles(
    $filters: ProjectRoleFilter
    $search: String
    $limit: Int
    $page: Int
    $orderBy: OrderBy
  ) {
    projectRoles(
      filters: $filters
      search: $search
      limit: $limit
      page: $page
      orderBy: $orderBy
    ) {
      dataCollection {
        ...projectRoleFields
      }
      totalNumberOfItems
    }
  }
`;

export const GET_ALL_PROJECT_ROLES_FOR_DROP_DOWN = gql`
  query getAllProjectRolesForDropDown {
    projectRoles {
      dataCollection {
        id
        name
        isActive
      }
    }
  }
`;

export const GET_ALL_PROJECT_MEMBERS = gql`
  query projectsMembers(
    $filters: ProjectMembersFilter
    $limit: Int
    $page: Int
    $search: String
    $orderBy: OrderBy
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    projectMembers(
      filters: $filters
      limit: $limit
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      dataCollection {
        id
        user {
          userType
          personalInformation {
            id
            name
          }
          userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
            documents {
              file
            }
            type
          }
        }
        projectRole {
          id
          name
        }
        status
      }
      totalNumberOfItems
    }
  }
`;

export const GET_ALL_PROJECT_VAULT_CREDENTIALS = gql`
  ${PROJECT_VAULT_CREDENTIAL_FRAGMENT}

  query projectVaultCredentials($projectId: Int!) {
    projectVaultCredentials(projectId: $projectId) {
      ...projectVaultCredentialFields
    }
  }
`;

export const GET_ALL_PROJECT_VAULT_ENVIRONMENTS = gql`
  ${PROJECT_VAULT_ENVIRONMENTS_FRAGMENT}

  query projectVaultEnvironment(
    $id: Int
    $name: String
    $search: String
    $orderBy: OrderBy
  ) {
    projectVaultEnvironment(
      id: $id
      name: $name
      search: $search
      orderBy: $orderBy
    ) {
      ...vaultEnvironmentFields
    }
  }
`;

export const GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPES = gql`
  ${PROJECT_VAULT_CREDENTIAL_TYPES_FRAGMENT}

  query projectVaultCredentialType(
    $id: Int
    $name: String
    $search: String
    $orderBy: OrderBy
  ) {
    projectVaultCredentialType(
      id: $id
      name: $name
      search: $search
      orderBy: $orderBy
    ) {
      ...projectVaultCredentialTypeFields
    }
  }
`;

export const GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS = gql`
  ${PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS_FRAGMENT}

  query projectCredentialTypeField($id: Int, $type: Int!, $orderBy: OrderBy) {
    projectCredentialTypeField(id: $id, type: $type, orderBy: $orderBy) {
      ...projectVaultCredentialTypeFieldFields
    }
  }
`;

export const PROJECT_ACL = gql`
  query projectAclTree($projectId: Int) {
    projectAclTree(projectId: $projectId) {
      resourceIds {
        resourceId {
          id
          title
          isAllowed
          childNodes {
            id
            title
            isAllowed
            childNodes {
              id
              title
              isAllowed
              childNodes {
                id
                title
                isAllowed
                childNodes {
                  id
                  title
                  isAllowed
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_VAULT_CREDENTIAL = gql`
  query projectCredential($id: Int!) {
    projectCredential(id: $id) {
      id
      environment {
        id
        name
      }
      type {
        id
        name
      }
      credentials {
        id
        credentialId
        field {
          fieldType
          id
          name
        }
        value
        extension
        mimeType
        fileName
      }
      rolePrivileges {
        role {
          id
          name
        }
        rolePrivilege
      }
    }
  }
`;

export const GET_ALL_CLIENTS_DROP_DOWN = gql`
  query getAllClientsForDropDown {
    getAllClientsForDropDown {
      id
      personalInformation {
        id
        name
      }
    }
  }
`;

export const PROJECTS = gql`
  ${PROJECTS_FRAGMENT}
  query allProjects(
    $filters: ProjectFilter
    $search: String
    $limit: Int
    $page: Int
    $orderBy: OrderBy
  ) {
    projects(
      filters: $filters
      search: $search
      limit: $limit
      page: $page
      orderBy: $orderBy
    ) {
      dataCollection {
        ...projectFields
      }
      totalNumberOfItems
    }
  }
`;

export const PROJECTS_FOR_DROP_DOWN = gql`
  query projectsForDropDown($filters: ProjectFilter) {
    projects(filters: $filters) {
      dataCollection {
        id
        name
      }
    }
  }
`;

export const SPRINTS = gql`
  ${PROJECT_SPRINT_FRAGMENT}
  query projectSprints(
    $filters: ProjectFilter
    $limit: Int
    $page: Int
    $search: String
    $orderBy: OrderBy
  ) {
    projects(filters: $filters) {
      dataCollection {
        sprints(
          limit: $limit
          page: $page
          search: $search
          orderBy: $orderBy
        ) {
          dataCollection {
            ...projectSprintFields
          }
          totalNumberOfItems
        }
      }
    }
  }
`;

export const COLUMNS = gql`
  ${PROJECT_COLUMN_FRAGMENT}
  query allprojectColumns(
    $filters: ProjectFilter
    $limit: Int
    $page: Int
    $search: String
    $orderBy: OrderBy
  ) {
    projects(filters: $filters) {
      dataCollection {
        columns(
          limit: $limit
          page: $page
          search: $search
          orderBy: $orderBy
        ) {
          dataCollection {
            ...projectColumnFields
          }
          totalNumberOfItems
        }
      }
    }
  }
`;

export const TASK_LABEL = gql`
  ${PROJECT_TASK_LABEL_FRAGMENT}

  query projectTaskLabel(
    $filters: ProjectTaskLabelFilterInput
    $limit: Int
    $page: Int
    $search: String
    $orderBy: OrderBy
  ) {
    projectTaskLabel(
      filters: $filters
      limit: $limit
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      dataCollection {
        ...projectTaskStatusFields
      }
    }
  }
`;

export const SPRINTS_FOR_DROP_DOWN = gql`
  query projectSprintsForDropDown($filters: SprintFilter) {
    projectSprints(filters: $filters) {
      dataCollection {
        id
        name
        status
      }
    }
  }
`;

export const TASK_LABEL_FOR_DROP_DOWN = gql`
  query projectTaskLabelForDropDown($filters: ProjectTaskLabelFilterInput) {
    projectTaskLabel(filters: $filters) {
      dataCollection {
        id
        name
      }
    }
  }
`;

export const COLUMNS_FOR_DROP_DOWN = gql`
  query projectColumnsForDropDown($filters: ProjectColumnsFilter) {
    projectColumns(filters: $filters) {
      dataCollection {
        id
        name
      }
    }
  }
`;

export const COLUMNS_AND_TASKS = gql`
  query projectColumnsAndTasks(
    $filters: ProjectColumnsFilter
    $search: String
    $sprintId: [Int]
    $assigneeUserIds: [Int]
    $isComesUnderBacklog: Boolean
  ) {
    projectColumns(filters: $filters) {
      dataCollection {
        id
        name
        colour
        position
        projectTasks(
          search: $search
          sprintId: $sprintId
          assigneeUserIds: $assigneeUserIds
          isComesUnderBacklog: $isComesUnderBacklog
        ) {
          id
          project {
            projectKey
          }
          projectTaskLabel {
            name
          }
          incharge {
            personalInformation {
              id
            }
          }
          title
          keyId
          dueDate
          isNotifyUser
        }
      }
      totalNumberOfItems
    }
  }
`;

export const TASK = gql`
  ${PROJECT_TASK_FRAGMENT}

  query projectTask($filters: ProjectTaskFilter, $limit: Int, $page: Int) {
    projectTasks(filters: $filters, limit: $limit, page: $page) {
      dataCollection {
        ...projectTaskFields
      }
    }
  }
`;

export const ISSUE_LOG_PROJECT_TASK = gql`
  query projectTasks(
    $filters: ProjectFilter
    $projectTaskFilter: ProjectTaskFilter
  ) {
    projects(filters: $filters) {
      dataCollection {
        tasks(filters: $projectTaskFilter) {
          dataCollection {
            id
            project {
              id
              name
              projectKey
            }
            projectColumn {
              id
              name
            }
            sprint {
              id
              name
            }
            incharge {
              personalInformation {
                id
                name
              }
            }
            projectTaskLabel {
              id
              name
            }
            projectTaskApprovedHours {
              allocatedApprovedHours
              assigneeUser {
                user {
                  id
                }
              }
            }
            title
            priority
            description
            startDate
            dueDate
            billingStatus
            totalApprovedHours
            createdAt
            keyId
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_ROLE_TYPES = gql`
  query projectRoleTypes($filters: ProjectFilter) {
    projects(filters: $filters) {
      dataCollection {
        members {
          dataCollection {
            projectRole {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_TASK_COMMENTS = gql`
  query projectTaskComments(
    $filters: ProjectTaskCommentFilter
    $limit: Int
    $page: Int
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    projectTaskComments(filters: $filters, limit: $limit, page: $page) {
      dataCollection {
        id
        message
        createdAt
        createdByUser {
          personalInformation {
            id
            name
          }
          userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
            documents {
              file
            }
            type
          }
        }
        projectTaskCommentAttachments {
          id
          filePath
          mimeType
          extension
          name
        }
      }
      totalNumberOfItems
    }
  }
`;

export const PROJECT_WORK_LOGS = gql`
  ${PROJECT_WORK_LOG_FRAGMENT}

  query projectWorkLog(
    $filters: ProjectWorkLogFilter
    $limit: Int
    $page: Int
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    projectWorkLog(filters: $filters, limit: $limit, page: $page) {
      dataCollection {
        ...worklogfields
      }
      totalNumberOfItems
    }
  }
`;

export const RUNNING_PROJECT_WORK_LOG = gql`
  ${RUNNING_PROJECT_WORK_LOG_FRAGMENT}

  query runningProjectWorkLog($projectTaskId: Int) {
    runningProjectWorkLog(projectTaskId: $projectTaskId) {
      ...runningProjectWorkLogFields
    }
  }
`;

export const PROJECT_TASK_HISTORIES = gql`
  ${PROJECT_TASK_HISTORY_FRAGMENT}

  query projectTaskHistories(
    $filters: ProjectTaskHistoryInput
    $limit: Int
    $page: Int
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    projectTaskHistories(filters: $filters, limit: $limit, page: $page) {
      dataCollection {
        ...taskHistoryFields
      }
      totalNumberOfItems
    }
  }
`;

export const PROJECT_TASK_ATTACHMENTS = gql`
  ${PROJECT_TASK_ATTACHEMENT_FRAGMENT}

  query projectTaskAttachments(
    $filters: ProjectFilter
    $taskFilter: ProjectTaskFilter
  ) {
    projects(filters: $filters) {
      dataCollection {
        tasks(filters: $taskFilter) {
          dataCollection {
            projectTaskAttachments {
              ...projectTaskAttachmentFields
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_TASK_ATTACHMENT_LENGTH = gql`
  query projectTaskAttachmentsLength(
    $filters: ProjectFilter
    $taskFilter: ProjectTaskFilter
  ) {
    projects(filters: $filters) {
      dataCollection {
        tasks(filters: $taskFilter) {
          dataCollection {
            projectTaskAttachments {
              id
            }
          }
        }
      }
    }
  }
`;

export const PROJECT_WORK_LOG_HOURS = gql`
  ${WORK_LOG_TOTAL_HOURS_FRAGMENT}

  query projectWorkLogHours($filters: ProjectWorkLogFilter, $limit: Int) {
    projectWorkLog(filters: $filters, limit: $limit) {
      dataCollection {
        ...workLogFields
      }
    }
  }
`;

export const PROJECT_LABELS = gql`
  query projectLabels(
    $limit: Int
    $page: Int
    $search: String
    $orderBy: OrderBy
  ) {
    projectLabels(
      limit: $limit
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      dataCollection {
        id
        name
        description
      }
    }
  }
`;

export const PROJECT_COLUMNS = gql`
  query projectColumns {
    projectColumns {
      dataCollection {
        id
        name
        description
      }
    }
  }
`;

export const PROJECT_SETTINGS_COLUMNS = gql`
  query columns($limit: Int, $page: Int, $search: String, $orderBy: OrderBy) {
    columns(limit: $limit, page: $page, search: $search, orderBy: $orderBy) {
      dataCollection {
        id
        name
        description
        colour
        position
      }
    }
  }
`;

export const PROJECT_MEMBERS_PROFILES = gql`
  query projectMembers(
    $filters: ProjectMembersFilter
    $type: String
    $isProfileImageNeeded: Boolean!
  ) {
    projectMembers(filters: $filters) {
      dataCollection {
        user {
          personalInformation {
            id
            name
          }
          userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
            documents {
              file
            }
            type
          }
        }
      }
    }
  }
`;

export const PROJECT_MEMBERS_WITH_PROFILES_AND_ROLES = gql`
  query projectMembersAndRoles(
    $filters: ProjectMembersFilter
    $type: String
    $isProfileImageNeeded: Boolean!
    $isEmployeeDetailsNeeded: Boolean!
  ) {
    projectMembers(filters: $filters) {
      dataCollection {
        projectRole {
          id
          name
        }
        user {
          personalInformation {
            id
            name
          }
          userProfileImages(type: $type) @include(if: $isProfileImageNeeded) {
            documents {
              file
            }
          }
          employeeDetail @include(if: $isEmployeeDetailsNeeded) {
            productivityRatio
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_MANAGER = gql`
  query getProjectManager($filters: ProjectFilter) {
    projects(filters: $filters) {
      dataCollection {
        manager {
          personalInformation {
            id
            name
          }
        }
      }
    }
  }
`;

export const PROJECTS_FOR_MY_LOGS: TypedDocumentNode<
	FilterProjectsReturnType,
	FilterProjectsArgs
> = gql`
  query ProjectsAndTasksForMyAndAllProjectLogs(
    $filters: ProjectFilter
    $tasksFilters2: ProjectTaskFilter
    $taskPage: Int
    $taskLimit: Int
  ) {
    projects(filters: $filters) {
      dataCollection {
        tasks(filters: $tasksFilters2, page: $taskPage, limit: $taskLimit) {
          dataCollection {
            title
            id
          }
        }
        id
        name
      }
    }
  }
`;

export const FILTER_PROJECT_TASKS: TypedDocumentNode<
	FilterProjectTasksReturnType,
	FilterProjectTasksArgs
> = gql`
  ${PROJECT_TASK_FRAGMENT_UPDATED}
  query FilterProjectTasks(
    $filters: ProjectTaskFilter
    $limit: Int
    $page: Int
    $globalSearch:String
    $isProjectTaskProjectTaskAttachmentsNeed: Boolean = false
    $isProjectTaskDescriptionNeed: Boolean = false
    $isProjectTaskPriorityNeed: Boolean = false
  ) {
    projectTasks(filters: $filters, limit: $limit, page: $page globalSearch:$globalSearch) {
      dataCollection {
        ...projectTaskFragment
      }
      totalNumberOfItems
    }
  }
`;
