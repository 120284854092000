import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import { Filled, Modal, Outlined } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import SMCardContent from "components/App/Card/SMCard/SMCardContent";

import filePdf from "global/assets/images/file-pdf-solid-240.png";
import { Base64toObject } from "global/helpers/FileConverter";
import { toastNotify } from "global/helpers/Cache";

import { UPDATE_NOTIFICATION_STATUS } from "layouts/header/TopLinks/Notification/services/mutations";

import {
  FILTER_ALL_LEAVE_REQUESTS,
  FILTER_TEAM_LEAVE_REQUESTS,
  GET_NOTIFICATION_IDS,
} from "modules/Leave/services/queries";
import {
  UPDATE_CONSENT_REQUEST,
  UPDATE_LEAVE_REQUEST,
} from "modules/Leave/services/mutations";
import DatesDetails from "modules/Leave/Pages/Requests/AllRequests/DatesDetails";
import { ILeaveRequestDates } from "global/types/type";

const ViewPage = () => {
  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const [fetchNotifications, { data: getNotifications }] = useLazyQuery(
    GET_NOTIFICATION_IDS,
    {
      fetchPolicy: "network-only",
    }
  );
  const [updateNotificationStatus] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const location: any = useLocation();
  const navigate = useNavigate();

  const [fetchLeaveRequests, { data: filterLeaveRequest }] = useLazyQuery(
    FILTER_ALL_LEAVE_REQUESTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const leaveRequestId = +location?.pathname?.split("/").at(-1);
  const viewPageDetails =
    filterLeaveRequest?.filterAllLeaveRequests?.dataCollection[0] &&
    filterLeaveRequest?.filterAllLeaveRequests?.dataCollection[0];

  const requestedToUser = viewPageDetails?.requestedToUser?.personalInformation;
  const userId = +localStorage?.id || +sessionId;
  const flattenConsentRequests =
    viewPageDetails?.leaveRequestDates
      ?.map((leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents)
      ?.flat() || [];

  const consentUserIds =
    flattenConsentRequests?.map(
      (leave: { consentedByUser: { personalInformation: { id: number } } }) =>
        leave?.consentedByUser?.personalInformation?.id
    ) || [];

  const isTeamLead = viewPageDetails?.isConsentRequest
    ? consentUserIds?.includes(+userId)
    : requestedToUser?.id === +sessionId ||
      requestedToUser?.id === +localStorage?.id;

  useEffect(() => {
    const unreadUpdatedAndWithdrawnNotifications =
      getNotifications?.notifications?.dataCollection?.filter(
        (notification: { message: string }) =>
          notification?.message?.includes("updated") ||
          notification?.message?.includes("withdraw")
      );

    if (unreadUpdatedAndWithdrawnNotifications?.length > 0) {
      updateNotificationStatus({
        variables: {
          id: unreadUpdatedAndWithdrawnNotifications?.map(
            (notification: { id: number }) => notification?.id
          ),
          isRead: true,
        },
      });
    }
  }, [
    getNotifications?.notifications?.dataCollection,
    updateNotificationStatus,
  ]);

  useEffect(() => {
    if (isTeamLead) {
      fetchNotifications({
        variables: {
          isRead: false,
          search: location?.pathname,
        },
      });
    }
  }, [fetchNotifications, location?.pathname, isTeamLead]);

  useEffect(() => {
    if (leaveRequestId) {
      fetchLeaveRequests({
        variables: {
          id: +leaveRequestId,
        },
      });
    }
  }, [leaveRequestId, fetchLeaveRequests]);

  const [approveVerification, setApproveVerification] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEditOption, setShowEditOption] = useState<boolean>(false);

  const [declineSingleDate, setDeclineSingleDate] = useState<{
    leaveRequestDateId: number | undefined;
    status: string | undefined;
  }>();

  const [approveSingleDate, setApproveSingleDate] = useState<{
    leaveRequestDateId: number | undefined;
    status: string | undefined;
  }>();

  let editRef: any = useRef();

  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const [updateLeaveRequest] = useMutation(UPDATE_LEAVE_REQUEST, {
    refetchQueries: [FILTER_ALL_LEAVE_REQUESTS],
  });

  const [updateConsentRequest] = useMutation(UPDATE_CONSENT_REQUEST, {
    refetchQueries: [FILTER_TEAM_LEAVE_REQUESTS],
  });

  const { register, watch } = useForm();
  const reason = watch("reason");

  const sortFunction = (a: any, b: any) => {
    const dateA = new Date(a?.leaveDate)?.getTime();
    const dateB = new Date(b?.leaveDate)?.getTime();
    return dateA > dateB ? 1 : -1;
  };

  let dates: any = viewPageDetails?.leaveRequestDates;
  dates = dates?.slice()?.sort(sortFunction);

  const areAllLeavesSame = (arr: any) => {
    if (dates && dates?.length > 0 && dates[0]?.leaveType?.isSpecialLeave) {
      return false;
    }

    if (dates && dates?.length > 0) {
      for (let i = 1; i < arr?.length; i++) {
        if (arr[i]?.leaveType?.id !== dates[0]?.leaveType?.id) {
          return false;
        }
      }
    }

    return true;
  };

  const hasPendingStatus = viewPageDetails?.isConsentRequest
    ? flattenConsentRequests
        ?.filter(
          (leave: {
            consentedByUser: { personalInformation: { id: number } };
          }) => leave?.consentedByUser?.personalInformation?.id === userId
        )
        ?.map((leave: { status: string }) => leave?.status)
        ?.includes("Pending")
    : viewPageDetails?.leaveRequestDates
        ?.map((date: any) => date?.status)
        .includes("Pending");

  const [modalTitle, setModalTitle] = useState("");

  const modalTitleHandler = () => {
    const sessions = viewPageDetails?.leaveRequestDates?.map(
      (leave: { session: string }) => leave?.session
    );

    const isAllSessionArePermissions = sessions?.every(
      (session: string) => session === "2 Hours"
    );

    if (isAllSessionArePermissions) {
      setModalTitle("Permission");
    } else {
      setModalTitle("Leave Request");
    }
  };

  const consentorsLeaveStatusses = dates?.map((date: ILeaveRequestDates) => {
    const filterConsentor: any = date?.leaveRequestDateConsents?.filter(
      (date: { consentedByUser: { personalInformation: { id: number } } }) =>
        date?.consentedByUser?.personalInformation?.id === +userId
    );

    return {
      id: +date?.id,
      status: filterConsentor?.length > 0 && filterConsentor[0]?.status,
    };
  });

  const commonVariablesForApproval = (
    id: number,
    isConsentRequest: boolean
  ) => {
    return {
      leaveRequestDate: approveSingleDate?.leaveRequestDateId
        ? [approveSingleDate]
        : isConsentRequest
        ? consentorsLeaveStatusses
            ?.filter(
              (request: { status: string }) => request?.status === "Pending"
            )
            ?.map((leave: { id: number }) => {
              return {
                leaveRequestDateId: +leave?.id,
                status: "Agreed",
              };
            })
        : dates?.map((date: { id: number }) => {
            return {
              leaveRequestDateId: date?.id,
              status: "Approved",
            };
          }),
      leaveRequestId: +id,
      userId,
      isAllApprove:
        approveSingleDate?.leaveRequestDateId && !isConsentRequest
          ? undefined
          : true,
      isAllAgreed:
        approveSingleDate?.leaveRequestDateId && isConsentRequest
          ? undefined
          : true,
    };
  };

  const commonVariableForDecline = (id: number, isConsentRequest: boolean) => {
    return {
      leaveRequestDate: declineSingleDate?.leaveRequestDateId
        ? [declineSingleDate]
        : isConsentRequest
        ? consentorsLeaveStatusses
            ?.filter(
              (request: { status: string }) => request?.status === "Pending"
            )
            ?.map((leave: { id: number }) => {
              return {
                leaveRequestDateId: +leave?.id,
                status: "Disagreed",
                declineMessage: reason,
              };
            })
        : dates?.map((date: { id: number }) => {
            return {
              leaveRequestDateId: +date?.id,
              status: "Declined",
            };
          }),
      leaveRequestId: +id,
      userId,
      declineMessage: reason,
      isAllDecline:
        declineSingleDate?.leaveRequestDateId && !isConsentRequest
          ? undefined
          : true,
      isAllDisagreed:
        declineSingleDate?.leaveRequestDateId && isConsentRequest
          ? undefined
          : true,
    };
  };

  const confirmHandler = (id: number) => {
    if (!inProgress) {
      updateLeaveRequest({
        variables: commonVariablesForApproval(id, false),
      })
        .then((response: any) => {
          setApproveSingleDate({
            leaveRequestDateId: undefined,
            status: undefined,
          });
          setInProgress(false);
          setApproveVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);

          const leaveDatesStatusses =
            response?.data?.updateLeaveRequest?.leaveRequestDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-requests");
          }
        })
        .catch((error: any) => {
          setInProgress(false);
          setApproveSingleDate({
            leaveRequestDateId: undefined,
            status: undefined,
          });
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const confirmConsentHandler = (id: number) => {
    if (!inProgress) {
      updateConsentRequest({
        variables: commonVariablesForApproval(id, true),
      })
        .then((response: any) => {
          setApproveSingleDate({
            leaveRequestDateId: undefined,
            status: undefined,
          });
          setInProgress(false);
          setApproveVerification(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);

          const flattenConsentRequests =
            response?.data?.updateLeaveRequestConsent?.leaveRequestDates
              ?.map(
                (leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents
              )
              ?.flat() || [];

          const leaveDatesStatusses = flattenConsentRequests
            ?.filter(
              (leave: {
                consentedByUser: { personalInformation: { id: number } };
              }) => leave?.consentedByUser?.personalInformation?.id === userId
            )
            ?.map((leave: { status: string }) => leave?.status);

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-requests");
          }
        })
        .catch((error: any) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const declineHandler = (id: number) => {
    if (!inProgress) {
      updateLeaveRequest({
        variables: commonVariableForDecline(id, false),
      })
        .then((response) => {
          setInProgress(false);
          setShowModal(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          const leaveDatesStatusses =
            response?.data?.updateLeaveRequest?.leaveRequestDates?.map(
              (leaveDate: { status: string }) => leaveDate?.status
            );

          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-requests");
          }
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const declineConsentHandler = (id: number) => {
    if (!inProgress) {
      updateConsentRequest({
        variables: commonVariableForDecline(id, true),
      })
        .then((response) => {
          setInProgress(false);
          setShowModal(false);
          toastNotify([
            {
              messageType: "success",
              message: "Your modifications have been successfully updated.",
            },
          ]);
          setDeclineSingleDate({
            leaveRequestDateId: undefined,
            status: undefined,
          });

          const flattenConsentRequests =
            response?.data?.updateLeaveRequestConsent?.leaveRequestDates
              ?.map(
                (leave: ILeaveRequestDates) => leave?.leaveRequestDateConsents
              )
              ?.flat() || [];

          const leaveDatesStatusses = flattenConsentRequests
            ?.filter(
              (leave: {
                consentedByUser: { personalInformation: { id: number } };
              }) => leave?.consentedByUser?.personalInformation?.id === userId
            )
            ?.map((leave: { status: string }) => leave?.status);
          if (!leaveDatesStatusses?.includes("Pending")) {
            navigate("/leaves/requests/all-requests");
          }
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
          setDeclineSingleDate({
            leaveRequestDateId: undefined,
            status: undefined,
          });
        });
      setInProgress(!inProgress);
    }
  };

  const createdAt = viewPageDetails?.createdAt || (
    <span className="text-gray-400">{"Eg.YYYY-MM-DD"}</span>
  );
  const updatedAt = viewPageDetails?.updatedAt || createdAt;

  const userProfileImage =
    viewPageDetails?.requestedByUser?.userProfileImages?.filter(
      (userProfileImage: any) => userProfileImage.type === "Profile"
    )[0] || null;

  const profileImage =
    userProfileImage?.documents && userProfileImage?.documents[0]?.file;

  const profileDetails = {
    profileImage: profileImage,
    name: viewPageDetails?.requestedByUser?.personalInformation?.name,
    email: viewPageDetails?.requestedByUser?.employeeDetail?.officialEmail,
    designation:
      viewPageDetails?.requestedByUser?.employeeDetail?.employeeDesignation,
  };

  let values: {
    lable: string;
    hidden?: boolean | undefined;
    value: {
      content: string | Element;
      profileDetails?:
        | {
            profileImage?: string | undefined;
            name: string;
            email: string;
            designation: string;
          }
        | undefined;
      color?: string | undefined;
    }[];
  }[] =
    dates?.map((date: any) => {
      return {
        lable: "Date",
        value: [
          {
            content: (
              <div>
                <DatesDetails
                  date={date}
                  setApproveSingleDate={setApproveSingleDate}
                  setApproveVerification={setApproveVerification}
                  setDeclineSingleDate={setDeclineSingleDate}
                  setShowModal={setShowModal}
                  setModalTitle={setModalTitle}
                  isTeamLead={isTeamLead}
                  requestedToUser={viewPageDetails?.requestedToUser}
                  reason={viewPageDetails?.reason}
                  isConsentRequest={viewPageDetails?.isConsentRequest}
                  showLeaveType={areAllLeavesSame(dates) ? false : true}
                />
              </div>
            ),
          },
        ],
      };
    }) || [];

  const getDataModal = () => [
    {
      lable: "ID",

      value: [
        {
          content: viewPageDetails?.id,
        },
      ],
    },
    {
      lable: "Created At",
      value: [
        {
          content: createdAt,
        },
      ],
    },
    {
      lable: "Updated At",
      value: [
        {
          content: updatedAt,
        },
      ],
    },
    {
      lable: "Requested By",
      value: [
        {
          content: (
            <>
              <span>
                {viewPageDetails?.requestedByUser?.personalInformation?.name}
              </span>
              {viewPageDetails && (
                <span className="ml-3 bg-[#FFE3AE] p-1">
                  {viewPageDetails?.isConsentRequest
                    ? "Project Member"
                    : "Team Member"}
                </span>
              )}
            </>
          ),
          profileDetails: profileDetails,
        },
      ],
    },
    {
      lable: "Type",
      hidden: areAllLeavesSame(dates) ? undefined : true,
      value: [
        {
          content: dates && dates?.length > 0 ? dates[0]?.leaveType?.name : "",
        },
      ],
    },
    ...values,
    {
      lable: "Reason",
      value: [
        {
          content: viewPageDetails?.reason,
        },
      ],
    },
    {
      lable: "Supporting Documents",
      hidden:
        viewPageDetails?.leaveRequestAttachments.length !== 0
          ? undefined
          : true,
      value: [
        {
          content: (
            <div className="flex">
              {viewPageDetails?.leaveRequestAttachments?.map(
                (files: any, index: number) => {
                  return (
                    <div key={index} className="space-x-2">
                      <a
                        href={Base64toObject(files.filePath)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={filePdf} alt="PDF" className="w-[40px]" />
                        <p className="invisible overflow-hidden max-w-[40px]">
                          Helper text
                        </p>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Fragment>
      <div className="border border-hit-gray rounded-[4px] mt-[20px] px-5 py-4 min-h-[68vh]">
        <SMCardContent dataModel={getDataModal()} />
        {isTeamLead && (
          <>
            {hasPendingStatus ? (
              <div className="flex flex-wrap gap-4 pt-5 justify-center items-center">
                <Outlined
                  buttonName={
                    viewPageDetails?.isConsentRequest ? "Disagree" : "Decline"
                  }
                  onClick={() => {
                    setShowModal(true);
                    modalTitleHandler();
                  }}
                />
                {(flattenConsentRequests
                  ?.map((leave: { status: string }) => leave?.status)
                  ?.every((status: string) => status === "Agreed") ||
                  viewPageDetails?.isConsentRequest) && (
                  <Filled
                    buttonName={
                      viewPageDetails?.isConsentRequest ? "Agree" : "Approve"
                    }
                    onClick={() => {
                      setShowModal(false);
                      setApproveVerification(true);
                      modalTitleHandler();
                    }}
                    buttonType={"button"}
                  />
                )}
              </div>
            ) : null}
            {showModal ? (
              <Modal>
                <div className="w-[350px] lg:w-[500px] bg-white shadow-[0px_-3px_6px_#00000029] rounded-[12px]">
                  <div className="edit-modal-header-div pl-[18px] pr-[20px] lg:pl-[28px] lg:pr-[57px] boder-hit-gray">
                    <h2 className="text-base text-ironside-gray">
                      {modalTitle}{" "}
                      {viewPageDetails?.isConsentRequest
                        ? "Disagree"
                        : "Decline"}
                    </h2>
                    <div
                      className="edit-modal-close-icon-div"
                      onClick={() => {
                        setInProgress(false);
                        setShowModal(false);
                        setDeclineSingleDate({
                          leaveRequestDateId: undefined,
                          status: undefined,
                        });
                      }}
                    >
                      <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
                    </div>
                  </div>
                  <div className="w-full py-[20px] px-[20px]">
                    <textarea
                      className="w-full h-[200px] border border-gray-200 focus:outline-none p-4"
                      placeholder="Type your reason here"
                      {...register("reason")}
                    />
                  </div>
                  <div className="w-full border-b" />
                  <div className="flex items-center justify-center p-6">
                    <button
                      type="button"
                      onClick={() => {
                        viewPageDetails?.isConsentRequest
                          ? declineConsentHandler(viewPageDetails?.id)
                          : declineHandler(viewPageDetails?.id);
                        viewPageDetails?.isConsentRequest
                          ? declineConsentHandler(viewPageDetails?.id)
                          : declineHandler(viewPageDetails?.id);
                      }}
                      className="w-[110px] lg:w-[130px] h-[35px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 rounded hover:shadow-[0_1px_2px_0_#366AD9]"
                    >
                      {inProgress ? (
                        <div className="btn-spinner" />
                      ) : viewPageDetails?.isConsentRequest ? (
                        "Disagree"
                      ) : (
                        "Decline"
                      )}
                    </button>
                  </div>
                </div>
              </Modal>
            ) : null}
            {approveVerification ? (
              <ConfirmModal
                header={modalTitle}
                keyValue={
                  viewPageDetails?.isConsentRequest ? "agree" : "approve"
                }
                onCancel={() => {
                  setInProgress(false);
                  setApproveVerification(false);
                  setModalTitle("");
                  setApproveSingleDate({
                    leaveRequestDateId: undefined,
                    status: undefined,
                  });
                }}
                onXIcon={() => {
                  setInProgress(false);
                  setApproveVerification(false);
                  setModalTitle("");
                  setApproveSingleDate({
                    leaveRequestDateId: undefined,
                    status: undefined,
                  });
                }}
                onExecute={() =>
                  viewPageDetails?.isConsentRequest
                    ? confirmConsentHandler(viewPageDetails?.id)
                    : confirmHandler(viewPageDetails?.id)
                }
                loading={inProgress}
              />
            ) : null}
          </>
        )}
      </div>
      <div className="flex justify-center items-center">
        <HiArrowNarrowLeft
          className="back-arrow-icon"
          onClick={() => {
            setInProgress(false);
            navigate("/leaves/requests/all-requests");
          }}
        />
      </div>
    </Fragment>
  );
};

export default ViewPage;
