import { RadioGroup } from "@headlessui/react";
import { Fragment } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { BsCheck } from "react-icons/bs";

interface IProps<TFilterFormBoolean extends FieldValues> {
  name: Path<TFilterFormBoolean>;
  control: Control<TFilterFormBoolean>;
  defaultValue?: PathValue<TFilterFormBoolean, Path<TFilterFormBoolean>>;
  options?: string[];
}

const FilterFormBoolean = <TFilterFormBoolean extends Record<string, any>>({
  name,
  control,
  defaultValue,
  options,
}: IProps<TFilterFormBoolean>) => {
  const booleanOption = options ? options : [null, true, false];
  return (
    <div className="w-full grid justify-center mt-5 ">
      <Controller
        name={name}
        control={control}
        defaultValue={options ? defaultValue : (null as any)}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            value={value}
            onChange={onChange}
            defaultValue={options ? defaultValue : (null as any)}
          >
            <RadioGroup.Label className="sr-only">{name}</RadioGroup.Label>
            <div className="space-y-5">
              {booleanOption?.map((value, index) => (
                <RadioGroup.Option
                  key={index}
                  value={value}
                  as="div"
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-cornflower-blue ring-opacity-60 ring-offset-2 ring-offset-"
                        : ""
                    }
                  ${
                    checked
                      ? "bg-cornflower-blue text-white"
                      : "bg-white border border-cornflower-blue shadow"
                  }
                relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none items-center justify-between min-w-[250px] max-w-xs`
                  }
                >
                  {({ checked }) => (
                    <Fragment>
                      <span className="text-sm">
                        {value === null
                          ? "UnSelect"
                          : value === true
                          ? "True"
                          : value === false
                          ? "False"
                          : value}
                      </span>
                      {checked && (
                        <span className="shrink-0 text-white">
                          <BsCheck className="h-6 w-6" />
                        </span>
                      )}
                    </Fragment>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default FilterFormBoolean;
