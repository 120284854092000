import { FC, Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import { BiReset, BiZoomIn, BiZoomOut } from "react-icons/bi";
import { MdCenterFocusStrong, MdDownloadForOffline } from "react-icons/md";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import ToolTip from "components/UI/UpdateUIAgry/ToolTip";
import ImageComponent from "components/UI/UpdateUIAgry/Image";
import Modal from "components/forms/Modal/UpdatedModalAgry/Modal";

import { Base64toObject } from "global/helpers/FileConverter";
import { setShowFileOpenModal } from "global/helpers/Cache";
import { isUrl } from "global/helpers/utils";
import { IFilePreviewModal } from "global/types/type";
import { detectMimeType } from "global/helpers/mimeType";

interface IProps {
  data: IFilePreviewModal;
}

const FilePreview: FC<IProps> = ({ data }) => {
  const { data: base64String, mimeType } = data;
  return (
    <Modal
      onClose={() => {
        setShowFileOpenModal(null);
      }}
    >
      <div
        className={`bg-white rounded-md m-5 ${
          detectMimeType(base64String) === "application/pdf" ||
          mimeType === "application/pdf"
            ? "w-[80vw] min-h-[80%]"
            : "min-w-[300px] max-w-[1000px]"
        }`}
      >
        <div className="p-5 flex justify-between items-center border-b">
          <p className="text-base text-ironside-gray max-w-[300px] truncate">
            {data?.title ? data?.title : "File"}
          </p>
          <button
            onClick={() => {
              setShowFileOpenModal(null);
            }}
            className="w-6 h-6 flex justify-center items-center rounded-full hover:bg-white-smoke cursor-pointer text-warm-gray hover:text-warm-gray/70 focus:ring-2 focus:bg-white-smoke focus:ring-warm-gray focus:outline-none"
          >
            <IoMdClose className="w-5 h-5" />
          </button>
        </div>
        <div className="p-5">
          {
            <div
              className={`${
                detectMimeType(base64String) === "application/pdf" ||
                mimeType === "application/pdf"
                  ? "w-full h-[calc(100vh-30vh)]"
                  : "max-w-[80vw] max-h-[70vh] flex justify-center items-center relative"
              }`}
            >
              {data?.mimeType === "image/png" ||
              data?.mimeType === "image/jpg" ||
              data?.mimeType === "image/jpeg" ||
              data?.mimeType === "image/gif" ||
              detectMimeType(data?.data) === "image/png" ||
              detectMimeType(data?.data) === "image/jpg" ||
              detectMimeType(data?.data) === "image/jpeg" ||
              detectMimeType(data?.data) === "image/gif" ? (
                <TransformWrapper>
                  {({ zoomIn, zoomOut, centerView, resetTransform }) => (
                    <Fragment>
                      <div className="absolute top-0 z-10 left-0 right-0 flex gap-5 flex-wrap justify-center items-center p-3">
                        <ToolTip render={"Zoom In"} arrow>
                          <BiZoomIn
                            onClick={() => {
                              zoomIn();
                            }}
                            className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-ironside-gray hover:text-irish-green"
                          />
                        </ToolTip>
                        <ToolTip render={"Zoom Out"} arrow>
                          <BiZoomOut
                            onClick={() => {
                              zoomOut();
                            }}
                            className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-ironside-gray hover:text-irish-green"
                          />
                        </ToolTip>
                        <ToolTip render={"Center"} arrow>
                          <MdCenterFocusStrong
                            onClick={() => {
                              centerView();
                            }}
                            className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-ironside-gray hover:text-irish-green"
                          />
                        </ToolTip>
                        <ToolTip render={"Reset"} arrow>
                          <BiReset
                            className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-ironside-gray hover:text-irish-green"
                            onClick={() => {
                              resetTransform();
                            }}
                          />
                        </ToolTip>
                        <ToolTip render={"Download"} arrow>
                          <MdDownloadForOffline
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const anchorElement = document.createElement("a");
                              anchorElement.href = data?.mimeType
                                ? `data:${data?.mimeType};base64,${data?.data}`
                                : Base64toObject(data?.data);
                              anchorElement.download = data?.name
                                ? data?.name
                                : `${
                                    data?.title
                                      ? data?.title
                                      : "Image " + new Date().toISOString()
                                  }.${
                                    data?.mimeType?.split("/")[1] ||
                                    detectMimeType(data?.data)?.split("/")[1] ||
                                    "jpeg"
                                  }`;
                              anchorElement.click();
                            }}
                            className="cursor-pointer w-6 h-6 sm:w-8 sm:h-8 p-0.5 sm:p-1 bg-white border shadow-md rounded-full text-ironside-gray hover:text-irish-green"
                          />
                        </ToolTip>
                      </div>
                      <TransformComponent>
                        <ImageComponent
                          src={
                            isUrl(data?.data)
                              ? data?.data
                              : data?.mimeType
                              ? `data:${data?.mimeType};base64,${data?.data}`
                              : Base64toObject(data?.data)
                          }
                          alt={
                            isUrl(data?.data)
                              ? data?.data
                              : data?.mimeType
                              ? `data:${data?.mimeType};base64,${data?.data}`
                              : Base64toObject(data?.data)
                          }
                          className="object-cover sm:object-contain rounded-md shadow-md max-h-[calc(100vh-30vh)] overflow-hidden"
                        />
                      </TransformComponent>
                    </Fragment>
                  )}
                </TransformWrapper>
              ) : (
                <object
                  className={`${
                    detectMimeType(base64String) === "application/pdf" ||
                    mimeType === "application/pdf"
                      ? "w-full h-full"
                      : "w-[300px] h-[500px] sm:w-[500px] sm:h-[600px] md:w-[600px] lg:w-[700px] 2xl:w-[800px]"
                  }  rounded-[inherit] transition-all duration-300 image-pdf-modal`}
                  data={
                    mimeType
                      ? `data:${mimeType};base64,${base64String}`
                      : Base64toObject(base64String)
                  }
                >
                  <div className="w-full h-full flex justify-center items-center">
                    <a
                      href={
                        mimeType
                          ? `data:${mimeType};base64,${base64String}`
                          : Base64toObject(base64String)
                      }
                      download={
                        data?.name
                          ? data?.name
                          : `${
                              data?.title
                                ? data?.title
                                : "Image " + new Date().toISOString()
                            }.${
                              data?.mimeType?.split("/")[1] ||
                              detectMimeType(data?.data)?.split("/")[1] ||
                              "jpeg"
                            }`
                      }
                      className="cursor-pointer"
                    >
                      Click to download
                    </a>
                  </div>
                </object>
              )}
            </div>
          }
        </div>
      </div>
    </Modal>
  );
};

export default FilePreview;
