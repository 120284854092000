/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ElementRef, Fragment, useMemo, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { FieldPath, FieldValues, PathValue } from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  hide,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";

import { ComboBoxProps } from "components/forms/UpdatedFormAgry/AutoComplete/types";
import {
  ObjectOption,
  OptionType,
} from "components/forms/UpdatedFormAgry/types";

import { useSearch } from "global/UpdatedHooks/hooks";
import useUpdateEffect1 from "global/UpdatedHooks/hooks/useUpdateEffect1";

const ComboBox = <
  TFieldValues extends FieldValues = FieldValues,
  Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  options,
  value,
  multiple,
  onChange,
  label,
  disabled,
  loading,
  defaultValue,
  renderedOption,
  errorMessage,
  onInputChange,
  searchKeys,
  observe,
  placeholder,
  notClearable,
  classNameForComboBox,
}: ComboBoxProps<TFieldValues, Name>) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const finalOptions = useMemo(
    () =>
      Array.isArray(options)
        ? onInputChange
          ? options
          : searchQuery && searchQuery?.length > 0
          ? options?.filter((option) => {
              if (typeof option === "object") {
                return searchKeys
                  ? searchKeys?.filter((searchKey) =>
                      option[searchKey]
                        ?.toSting()
                        ?.toLowerCase()
                        ?.replace(/\s+/g, "")
                        ?.includes(
                          searchQuery.toLowerCase().replace(/\s+/g, "")
                        )
                    )
                  : option?.id
                      ?.toString()
                      ?.toLowerCase()
                      .replace(/\s+/g, "")
                      .includes(
                        searchQuery.toLowerCase().replace(/\s+/g, "")
                      ) ||
                      option?.label
                        .toLowerCase()
                        .replace(/\s+/g, "")
                        .includes(
                          searchQuery.toLowerCase().replace(/\s+/g, "")
                        );
              } else {
                option
                  ?.toString()
                  ?.toLowerCase()
                  .replace(/\s+/g, "")
                  .includes(searchQuery.toLowerCase().replace(/\s+/g, ""));
              }
            })
          : options
        : options,

    [onInputChange, options, searchKeys, searchQuery]
  );

  const { x, y, strategy, refs, middlewareData } = useFloating({
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ["bottom", "top", "right-start", "left-start"],
      }),
      hide({
        strategy: "referenceHidden",
      }),
      shift({
        padding: 20,
      }),
    ],
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const isValue = multiple
    ? value && Array.isArray(value) && value?.length > 0
    : value
    ? true
    : false;

  const overflowRef = useRef<ElementRef<"ul">>(null);

  const by =
    finalOptions &&
    finalOptions?.length > 0 &&
    typeof finalOptions[0] === "object"
      ? "id"
      : undefined;

  const searchValue = useSearch(searchQuery || "");

  useUpdateEffect1(() => {
    onInputChange && onInputChange(searchValue);
  }, [searchValue]);

  return (
    <Combobox
      value={
        multiple
          ? ((value ? value : []) as OptionType[])
          : (value as OptionType[])
      }
      onChange={onChange}
      disabled={disabled}
      multiple={multiple ? (true as any) : (false as any)}
      by={by}
      defaultValue={
        defaultValue
          ? (defaultValue as unknown as OptionType[])
          : (null as PathValue<TFieldValues, Name>)
      }
    >
      {({ open, disabled, value }) => {
        return (
          <Fragment>
            <div
              ref={refs?.setReference}
              className={twMerge(
                "w-full min-h-[45px] rounded relative flex bg-inherit border items-center",
                disabled
                  ? "cursor-not-allowed  border border-opacity-[0.38]"
                  : errorMessage
                  ? "border-error focus-within:border-error"
                  : "border focus-within:border-cornflower-blue",
                classNameForComboBox
              )}
            >
              <div className="flex-1 bg-inherit rounded-[inherit] min-h-[40px]">
                {multiple &&
                  value &&
                  Array.isArray(value) &&
                  value?.length > 0 && (
                    <div className="flex flex-wrap pt-2.5 px-2 gap-3 max-h-[250px] overflow-y-auto">
                      {value?.map((result, index) => {
                        return (
                          <p
                            key={index}
                            className="flex gap-3 items-center text-xs whitespace-pre-wrap rounded-full bg-cornflower-blue/20 px-2 py-1 max-w-[125px] sm:max-w-full"
                          >
                            <span className="text-cornflower-blue max-w-[50px] md:max-w-[120px] truncate">
                              {typeof result === "object"
                                ? result?.label?.toString()
                                : result?.toString()}
                            </span>
                            {!notClearable && !disabled && (
                              <span className="w-5 h-5 rounded-full inline-flex hover:bg-white-smoke justify-center items-center">
                                <IoCloseSharp
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onChange(
                                      value?.filter((option) => {
                                        if (
                                          typeof option === "object" &&
                                          typeof result === "object"
                                        ) {
                                          return option?.id !== result.id;
                                        } else {
                                          return (
                                            option.toString() !==
                                            result?.toString()
                                          );
                                        }
                                      }) || []
                                    );
                                  }}
                                  className="w-4 h-4 cursor-pointer text-base text-cornflower-blue"
                                />
                              </span>
                            )}
                          </p>
                        );
                      })}
                    </div>
                  )}
                <Combobox.Input
                  placeholder={
                    errorMessage
                      ? ""
                      : placeholder
                      ? placeholder
                      : "Enter something...."
                  }
                  className={({ disabled }) =>
                    twMerge(
                      "w-full h-[inherit] min-h-[inherit] peer focus:outline-none bg-inherit rounded px-4 py-2 text-sm",
                      disabled
                        ? "cursor-not-allowed placeholder-outline placeholder-opacity-[0.38] select-none text-outline text-opacity-[0.38]"
                        : "",
                      errorMessage
                        ? "placeholder:text-error focus:placeholder:text-error"
                        : "",
                      label
                        ? multiple
                          ? isValue
                            ? ""
                            : "placeholder-transparent focus:placeholder-inherit"
                          : "placeholder-transparent focus:placeholder-inherit"
                        : ""
                    )
                  }
                  displayValue={(
                    displayValue: string | number | ObjectOption
                  ) =>
                    multiple
                      ? ""
                      : displayValue
                      ? typeof displayValue === "object"
                        ? displayValue?.label?.toString()
                        : displayValue?.toString()
                      : ""
                  }
                  autoComplete={"off"}
                  onChange={(e) => {
                    setSearchQuery(e?.target?.value || "");
                  }}
                />
                {label && (
                  <Combobox.Label
                    htmlFor={name}
                    className={twMerge(
                      "transition-all max-w-[90%] select-none truncate bg-inherit px-1 text-warm-gray/70 absolute left-3 -top-2.5 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:top-[9px] peer-focus:-top-2.5 peer-focus:text-[10px]",
                      disabled
                        ? "text-outline text-opacity-[0.38] cursor-not-allowed"
                        : errorMessage
                        ? "text-error focus:text-error peer-focus:text-error cursor-text"
                        : "peer-focus:text-cornflower-blue cursor-text",
                      isValue ? "!-top-2.5 !text-xxs" : ""
                    )}
                  >
                    {label}
                  </Combobox.Label>
                )}
              </div>
              <div className="flex justify-center items-center p-2 bg-inherit rounded-[inherit]">
                {!disabled && isValue && (
                  <IoMdClose
                    onClick={() => {
                      if (!disabled) {
                        onChange(null);
                      }
                    }}
                    className={`h-6 w-6 min-w-[24px] min-h-[24px]  p-1 ${
                      disabled
                        ? "text-outline text-opacity-[0.38]"
                        : "text-gray-400 cursor-pointer hover:bg-white-smoke"
                    }  rounded-full`}
                  />
                )}
                <Combobox.Button
                  className={`w-6 h-6 min-w-[24px] min-h-[24px] inline-flex justify-center items-center rounded-full ${
                    disabled
                      ? "cursor-not-allowed"
                      : "cursor-pointer hover:bg-white-smoke"
                  }`}
                >
                  <BsFillCaretDownFill
                    className={`h-6 w-6 p-1.5 ${
                      disabled
                        ? "text-outline text-opacity-[0.38]"
                        : "text-gray-400"
                    }  ${open ? "rotate-180" : ""}`}
                  />
                </Combobox.Button>
              </div>
            </div>
            <FloatingPortal>
              <Transition
                show={open}
                ref={refs?.setFloating}
                as={"div"}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 60,
                  maxWidth: `${
                    refs?.reference?.current?.getBoundingClientRect()?.width
                      ? `${
                          refs?.reference?.current?.getBoundingClientRect()
                            ?.width
                        }px`
                      : "300px"
                  }`,
                  minWidth: `${
                    refs?.reference?.current?.getBoundingClientRect()?.width
                      ? `${
                          refs?.reference?.current?.getBoundingClientRect()
                            ?.width
                        }px`
                      : "300px"
                  }`,
                }}
                className={`${
                  middlewareData?.hide?.referenceHidden ? "hidden" : "visible"
                } max-h-[300px] overflow-y-auto shadow rounded border bg-white  ${
                  overflowRef?.current
                    ? overflowRef?.current?.getBoundingClientRect()?.height >
                      300
                      ? "py-1 pl-2"
                      : "p-2"
                    : "p-2"
                }`}
                afterLeave={() => {
                  setSearchQuery("");
                  observe && observe(null);
                }}
              >
                <Combobox.Options ref={overflowRef} as={"ul"}>
                  {!loading && finalOptions?.length === 0 ? (
                    <li className="w-full text-sm text-ironside-gray py-1">
                      Nothing Found.
                    </li>
                  ) : finalOptions[0] === "You return invalid type." ? (
                    <li className="w-full text-sm text-ironside-gray py-1">
                      Error
                    </li>
                  ) : finalOptions?.length > 0 ? (
                    finalOptions?.map((option, index) => {
                      return renderedOption ? (
                        <Combobox.Option
                          key={index}
                          value={option}
                          as={"li"}
                          disabled={
                            typeof option === "object"
                              ? option?.disabled
                              : false
                          }
                          ref={
                            finalOptions?.length - 1 === index
                              ? (ref) => {
                                  observe && observe(ref);
                                }
                              : undefined
                          }
                        >
                          {({ active, disabled, selected }) => {
                            return (
                              <Fragment>
                                {renderedOption(
                                  option as PathValue<TFieldValues, Name>,
                                  {
                                    active,
                                    disabled,
                                    selected,
                                  }
                                )}
                              </Fragment>
                            );
                          }}
                        </Combobox.Option>
                      ) : (
                        <Combobox.Option
                          key={index}
                          value={option}
                          as={"li"}
                          disabled={
                            typeof option === "object"
                              ? option?.disabled
                              : false
                          }
                          className={({ active, disabled, selected }) =>
                            `p-2 text-sm rounded transition-all duration-100 ${
                              disabled
                                ? "cursor-default text-disabled bg-gray-400/20"
                                : active
                                ? "cursor-pointer text-white bg-cornflower-blue"
                                : "text-ironside-gray"
                            } ${selected ? "font-medium" : "font-normal"} `
                          }
                          ref={
                            finalOptions?.length - 1 === index
                              ? (ref) => {
                                  observe && observe(ref);
                                }
                              : undefined
                          }
                        >
                          {({ active, selected, disabled }) => (
                            <div className="flex justify-between items-center gap-1">
                              <span className="capitalize flex-1 truncate">
                                {typeof option === "object"
                                  ? option?.label?.toString()
                                  : option?.toString()}
                              </span>
                              {selected && (
                                <BiCheck
                                  className={`h-5 w-5 ${
                                    active && !disabled
                                      ? "text-white"
                                      : disabled
                                      ? "text-text-disabled"
                                      : "text-cornflower-blue"
                                  }`}
                                />
                              )}
                            </div>
                          )}
                        </Combobox.Option>
                      );
                    })
                  ) : null}
                  {loading && (
                    <div className="grid gap-3">
                      {[1, 2, 3]?.map((value, index) => (
                        <div key={index} className="w-full h-[40px]">
                          <span className="block bg-gray-300 w-full h-full rounded animate-pulse" />
                        </div>
                      ))}
                    </div>
                  )}
                </Combobox.Options>
              </Transition>
            </FloatingPortal>
          </Fragment>
        );
      }}
    </Combobox>
  );
};

export default ComboBox;
