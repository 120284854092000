import {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, Path, PathValue } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";

import {
  autoUpdate,
  flip,
  FloatingPortal,
  hide,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";

import { TSelectProps } from "components/forms/UpdatedForm/types";

import { findArrayValueType } from "global/helpers/ArrayMethods";

const Select = <TForm extends Record<string, string | number | any>>({
  control,
  name,
  loading,
  multiple,
  options,
  onChange,
  required,
  shouldUnregister,
  label,
  disabled,
  enableSelect = !required,
  className,
  defaultValue: defaultValueForForm,
  hideError,
  errors,
  setValue,
  classNameForError = "",
}: TSelectProps<TForm>) => {
  const errorMessage = errors
    ? typeof errors === "string"
      ? errors
      : errors[name]?.message?.toString()
    : undefined;

  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context, middlewareData } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      flip({
        fallbackPlacements: ["bottom", "top"],
      }),
      hide(),
    ],
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: true }),
    useDismiss(context),
  ]);
  const defaultValue = useMemo(
    () => defaultValueForForm,
    [defaultValueForForm]
  );

  const parentRef = useRef<HTMLDivElement | null>(null);
  const [isValueSet, setIsValueSet] = useState(true);
  useEffect(() => {
    if (setValue && defaultValue && options?.length > 0 && isValueSet) {
      setValue(name, defaultValue);
      setIsValueSet(false);
    }
  }, [defaultValue, name, options?.length, setValue, isValueSet]);

  const optionElement = (
    name: string | number,
    index: number,
    onRemove: () => void
  ) => (
    <p
      key={index}
      className="flex gap-3 items-center text-xs whitespace-pre-wrap rounded-full bg-cornflower-blue/20 px-2 py-1"
    >
      <span className="text-kelly-green">{name}</span>
      <span className="w-5 h-5 rounded-full inline-flex hover:bg-white-smoke justify-center items-center">
        <IoCloseSharp
          onClick={onRemove}
          className="w-4 h-4 cursor-pointer text-base text-cornflower-blue"
        />
      </span>
    </p>
  );
  const multipleValueArray = (
    value: PathValue<TForm, Path<TForm>>,
    onChange: (e: ChangeEvent<any>) => void
  ) => {
    if (
      !findArrayValueType<string>(options, "string") &&
      !findArrayValueType<number>(options, "number") &&
      findArrayValueType<string>(value, "string")
    ) {
      const optionArray = options?.filter(
        (option) =>
          typeof option?.id === "string" && value?.includes(option?.id)
      );
      return optionArray && optionArray?.length > 0
        ? optionArray?.map((option, index) => {
            return optionElement(option?.name, index, () => {
              if (typeof option?.id === "string") {
                onChange(
                  value.filter((option1: string) => option1 !== option?.id)
                );
              }
            });
          })
        : null;
    }
    if (
      !findArrayValueType<string>(options, "string") &&
      !findArrayValueType<number>(options, "number") &&
      findArrayValueType<string>(value, "number")
    ) {
      const optionArray = options?.filter(
        (option) =>
          typeof option?.id === "number" && value?.includes(option?.id)
      );
      return optionArray && optionArray?.length > 0
        ? optionArray?.map((option, index) => {
            return optionElement(option?.name, index, () => {
              if (typeof option?.id === "number") {
                onChange(
                  value.filter((option1: number) => option1 !== option?.id)
                );
              }
            });
          })
        : null;
    }
    if (findArrayValueType<string>(options, "string")) {
      const optionArray = options?.filter((option) => value?.includes(option));
      return optionArray && optionArray?.length > 0
        ? optionArray?.map((option, index) => {
            return optionElement(option, index, () => {
              onChange(value.filter((option1: string) => option1 !== option));
            });
          })
        : null;
    }
    if (findArrayValueType<number>(options, "number")) {
      const optionArray = options?.filter((option) => value?.includes(option));
      return optionArray && optionArray?.length > 0
        ? optionArray?.map((option, index) => {
            return optionElement(option, index, () => {
              onChange(value.filter((option1: number) => option1 !== option));
            });
          })
        : null;
    }
  };

  return (
    <div className={`${className ? className : "w-full"}`}>
      <Controller
        name={name}
        control={control}
        rules={{
          required:
            typeof required === "boolean" && required === true
              ? "This is required field."
              : required
              ? required
              : false,
          shouldUnregister: shouldUnregister,
          onChange: onChange,
        }}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => {
          return (
            <Listbox
              value={
                multiple
                  ? Array.isArray(value)
                    ? [...value]
                    : []
                  : value || "Select"
              }
              onChange={(e: any) => {
                onChange(e);
              }}
              multiple={multiple}
              disabled={disabled}
              defaultValue={defaultValue}
            >
              {({ open, disabled }) => (
                <div
                  className={`relative  text-sm bg-white`}
                  ref={refs?.setReference}
                  {...getReferenceProps()}
                >
                  <div
                    className={`relative border group-[select-parent] w-full bg-white ${
                      multiple ? "min-h-[45px]" : "h-[45px]"
                    }  rounded shadow ${disabled ? "cursor-not-allowed" : ""} ${
                      open
                        ? "border-cornflower-blue focus:border-cornflower-blue focus-within:border-cornflower-blue"
                        : errorMessage
                        ? "border-valentine-red focus:border-valentine-red focus-within:border-valentine-red text-valentine-red"
                        : "focus:border-cornflower-blue focus-within:border-cornflower-blue"
                    } ${
                      errorMessage
                        ? "border-valentine-red focus:border-valentine-red focus-within:border-valentine-red text-valentine-red"
                        : "text-ironside-gray"
                    }`}
                    ref={parentRef}
                  >
                    {multiple && Array.isArray(value) && value?.length > 0 ? (
                      <div
                        className={`${
                          multiple && Array.isArray(value) && value?.length > 0
                            ? "flex"
                            : "hidden"
                        } max-w-[270px] px-4 pt-4 pb-1 gap-2 flex-wrap`}
                      >
                        {multipleValueArray(value, onChange)}
                      </div>
                    ) : null}
                    <Listbox.Button
                      id={name}
                      className={`peer relative w-full text-left min-h-[45px] pl-3 pr-7 focus:outline-none ${
                        disabled
                          ? "cursor-not-allowed text-warm-gray"
                          : "cursor-pointer"
                      }`}
                    >
                      <span className={`block truncate`}>
                        {loading
                          ? "Loading..."
                          : multiple
                          ? "Select"
                          : (!isNaN(value) &&
                              options &&
                              !findArrayValueType<string>(options, "string") &&
                              !findArrayValueType<number>(options, "number") &&
                              options?.filter(
                                (option) => option?.id === +value
                              )[0]?.name) ||
                            value ||
                            value}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <BsFillCaretDownFill
                          className={`h-3 w-3 text-gray-400 hover:bg-white-smoke  ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </span>
                    </Listbox.Button>
                    <Listbox.Label
                      htmlFor={name}
                      className={`absolute whitespace-nowrap left-3 bg-white px-0.5 transition-all duration-200  ${
                        disabled
                          ? value
                            ? "text-xxs -top-[6px] text-warm-gray/60"
                            : "text-warm-gray/60 text-xs top-3"
                          : open
                          ? errorMessage
                            ? "text-xxs -top-[6px] text-valentine-red peer-focus-within:text-valentine-red peer-focus:text-valentine-red"
                            : "text-xxs -top-[6px] text-cornflower-blue peer-focus-within:text-cornflower-blue peer-focus:text-cornflower-blue"
                          : value
                          ? errorMessage
                            ? "text-xxs -top-[6px] text-valentine-red peer-focus-within:text-valentine-red peer-focus:text-valentine-red"
                            : "text-xxs -top-[6px] text-warm-gray peer-focus:text-cornflower-blue"
                          : errorMessage
                          ? "text-valentine-red peer-focus-within:text-valentine-red peer-focus:text-valentine-red text-xs top-3"
                          : "text-warm-gray text-xs top-3 peer-focus:text-cornflower-blue"
                      }`}
                    >
                      {label}
                    </Listbox.Label>
                  </div>
                  <FloatingPortal>
                    <Transition
                      show={open}
                      ref={refs?.setFloating}
                      style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                      }}
                      as={"div"}
                      appear
                      {...getFloatingProps()}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className={`${
                        middlewareData?.hide?.referenceHidden
                          ? "hidden"
                          : "visible"
                      }`}
                    >
                      <Listbox.Options
                        style={{
                          maxWidth: `${
                            parentRef.current?.getBoundingClientRect()?.width
                          }px`,
                          minWidth: `${
                            parentRef.current?.getBoundingClientRect()?.width
                          }px`,
                        }}
                        className={`relative max-h-60 z-[999] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                      >
                        {loading ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-sm text-ironside-gray">
                            Loading...
                          </div>
                        ) : !options || options?.length === 0 ? (
                          <div className="relative cursor-default select-none py-2 px-4 text-sm text-ironside-gray">
                            Nothing found...
                          </div>
                        ) : (
                          <Fragment>
                            {findArrayValueType<string>(options, "string")
                              ? ["Select", ...options].map((option, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active, disabled }) =>
                                      `relative text-sm ${
                                        option === "Select"
                                          ? "cursor-default"
                                          : "cursor-pointer"
                                      } select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-cornflower-blue text-white"
                                          : "text-ironside-gray"
                                      } ${
                                        disabled
                                          ? active
                                            ? "cursor-default text-white"
                                            : "cursor-default text-warm-gray/60"
                                          : "cursor-pointer"
                                      }`
                                    }
                                    value={
                                      (option === "Select" ? null : option) ||
                                      null
                                    }
                                    disabled={
                                      option === "Select"
                                        ? enableSelect
                                          ? false
                                          : true
                                        : false
                                    }
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate text-sm ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {option || ""}
                                        </span>
                                        {selected && (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? "text-white"
                                                : "text-cornflower-blue"
                                            }`}
                                          >
                                            <BiCheck
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))
                              : findArrayValueType<number>(options, "number")
                              ? ["Select", ...options].map((option, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({ active, disabled }) =>
                                      `relative text-sm ${
                                        option === "Select"
                                          ? "cursor-default"
                                          : "cursor-pointer"
                                      } select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-cornflower-blue text-white"
                                          : "text-ironside-gray"
                                      } ${
                                        disabled
                                          ? active
                                            ? "cursor-default text-white"
                                            : "cursor-default text-warm-gray/60"
                                          : "cursor-pointer"
                                      }`
                                    }
                                    value={
                                      (option === "Select" ? null : option) ||
                                      null
                                    }
                                    disabled={
                                      option === "Select"
                                        ? enableSelect
                                          ? false
                                          : true
                                        : false
                                    }
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate text-sm ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {option?.toString() || ""}
                                        </span>
                                        {selected && (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? "text-white"
                                                : "text-cornflower-blue"
                                            }`}
                                          >
                                            <BiCheck
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))
                              : [
                                  {
                                    id: "Select",
                                    name: "Select",
                                    disabled: enableSelect ? false : true,
                                  },
                                  ...options,
                                ]?.map((option, index) => (
                                  <Listbox.Option
                                    key={index}
                                    className={({
                                      active,
                                      selected,
                                      disabled,
                                    }) =>
                                      `relative text-sm select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-cornflower-blue text-white"
                                          : "text-ironside-gray"
                                      } ${
                                        disabled
                                          ? selected
                                            ? "text-white"
                                            : "text-warm-gray/60"
                                          : ""
                                      } ${
                                        disabled
                                          ? active
                                            ? "cursor-default text-white"
                                            : "cursor-default text-warm-gray/60"
                                          : "cursor-pointer"
                                      }`
                                    }
                                    value={
                                      option?.id === "Select"
                                        ? null
                                        : option?.id
                                    }
                                    disabled={
                                      option?.id === "Select"
                                        ? enableSelect
                                          ? false
                                          : true
                                        : option?.disabled
                                        ? true
                                        : false
                                    }
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate text-sm ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {option?.name || "Select"}
                                        </span>
                                        {selected && (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                              active
                                                ? "text-white"
                                                : "text-cornflower-blue"
                                            }`}
                                          >
                                            <BiCheck
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                          </Fragment>
                        )}
                      </Listbox.Options>
                    </Transition>
                  </FloatingPortal>
                </div>
              )}
            </Listbox>
          );
        }}
      />

      {hideError ? null : (
        <div className={`w-[inherit] h-min ${classNameForError}`}>
          <span
            className={`text-valentine-red text-xs ${
              errorMessage ? "visible" : "invisible"
            } `}
          >
            {errorMessage ? errorMessage : "Helper Text"}
          </span>
        </div>
      )}
    </div>
  );
};

export default Select;
