import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation, useReactiveVar } from "@apollo/client";

import Password from "components/forms/Password/Password";

import { toastNotify, userContextData } from "global/helpers/Cache";
import avatar from "global/assets/images/dummy-avatar.svg";

import { CHANGE_PASSWORD } from "modules/Employee/services/mutations";

interface ChangePasswordType {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordType>();
  const userDataList: any = useReactiveVar(userContextData);
  const navigate = useNavigate();
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const [inProgress, setInProgress] = useState(false);

  const profileImages =
    userDataList?.user?.userProfileImages?.filter(
      (profileImage: any) => profileImage?.type === "Profile"
    )[0] || null;

  const employeeProfileImage =
    profileImages?.documents && profileImages?.documents[0]?.file?.length > 100
      ? `data:image/jpeg;base64,${
          profileImages?.documents && profileImages?.documents[0]?.file
        }`
      : avatar;

  const onsubmit = (editPassword: any) => {
    if (editPassword.newPassword !== editPassword.confirmPassword) {
      toastNotify([
        {
          messageType: "error",
          message: "Confirm Password does not match with New Password.",
        },
      ]);
    } else if (editPassword.currentPassword === editPassword.newPassword) {
      toastNotify([
        {
          messageType: "error",
          message: "New Password should not be same as Current Password.",
        },
      ]);
    } else if (!inProgress) {
      const sessionId: string | any = document.cookie
        .split("; ")
        .find((row) => row.startsWith("id="))
        ?.split("=")[1];
      changePassword({
        variables: {
          userId: +localStorage?.id || +sessionId,
          oldPassword: editPassword.currentPassword,
          newPassword: editPassword.newPassword,
        },
      })
        .then(() => {
          setInProgress(false);
          userDataList?.refetchData();
          navigate("/profile/personal");
          toastNotify([
            {
              messageType: "success",
              message: "Password has been updated successfully.",
            },
          ]);
        })
        .catch((error: { message: string }) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <div className="flex flex-col items-center gap-5">
      <div className="bg-[#FAFAFB] md:bg-white rounded-full w-[115px] h-[115px] flex justify-center items-center">
        <img
          src={employeeProfileImage}
          alt="user"
          className="w-[110px] h-[110px] rounded-full"
        />
      </div>
      <p className="text-ironside-gray text-base mt-[20px] text-center flex flex-col">
        <span className="truncate text-base max-w-[400px]">
          {userDataList?.user?.personalInformation?.name}
        </span>

        {userDataList?.user?.employeeDetail?.employeeDesignation && (
          <span className="text-sm truncate max-w-[400px]">
            {userDataList?.user?.employeeDetail?.employeeDesignation}
          </span>
        )}
      </p>
      <div className="md:bg-white md:shadow-[0px_15px_30px_#AEAEAE29] rounded flex flex-col items-center p-10 gap-5 min-w-[400px]">
        <form onSubmit={handleSubmit(onsubmit)} className="w-full space-y-3">
          <Password
            register={register}
            errors={errors}
            label="Current Password *"
            name="currentPassword"
          />
          <Password
            register={register}
            errors={errors}
            label="New Password *"
            name="newPassword"
          />
          <Password
            register={register}
            errors={errors}
            label="Confirm Password *"
            name="confirmPassword"
          />
          <button
            type="submit"
            className="w-full h-[52px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 leading-[20px] rounded border border-cornflower-blue hover:shadow-lg"
          >
            {inProgress ? <div className="btn-spinner" /> : "Change Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
