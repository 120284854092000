import { FC, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { Control } from "react-hook-form";
import { HiPencil } from "react-icons/hi";

import TableContent from "components/App/UpdatedAgryApp/Table/TableContent";
import TableWithCursorPagination from "components/App/UpdatedAgryApp/Table/TableWithCursorPagination";
import { TTableContent } from "components/App/UpdatedAgryApp/Table/types/Table";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

import { FilterFormType } from "global/types/type";

import {
  ProjectGroupEdge,
  ProjectGroup,
  ProjectGroupFilterFormType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
  canUpdate: boolean;
  canDelete: boolean;
  canReadProjectsOrGroupBugsOrTasks: boolean;
  onEdit: (
    type: "create" | "update" | "delete",
    projectGroup?: ProjectGroup | null
  ) => void;
  nextDisabled: boolean;
  control: Control<FilterFormType<ProjectGroupFilterFormType>, any>;
  prevDisabled: boolean;
  totalCount: number;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  cardOrTableClickHandler: (projectGroupId: number) => void;
  projectGroupEdges: ProjectGroupEdge[];
}

const tableCommonHeadings = ["Name", "Description"];

const ProjectGroupTable: FC<Props> = ({
  canDelete,
  canUpdate,
  onEdit,
  nextDisabled,
  control,
  prevDisabled,
  totalCount,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  cardOrTableClickHandler,
  projectGroupEdges,
  canReadProjectsOrGroupBugsOrTasks,
}) => {
  const tableHeadings =
    canDelete || canUpdate
      ? [...tableCommonHeadings, "Action"]
      : tableCommonHeadings;

  const tableRef = useRef<HTMLDivElement>(null);

  const onKebabIconClick = (e) => {
    e.stopPropagation();
  };

  const onKebabEdit = (e, projectGroup: ProjectGroup) => {
    e.stopPropagation();
    if (projectGroup) {
      onEdit("update", projectGroup);
    }
  };

  const onKebabDelete = (e, projectGroup: ProjectGroup) => {
    e.stopPropagation();
    if (projectGroup) {
      onEdit("delete", projectGroup);
    }
  };

  const getTableContent = (projectGroup: ProjectGroup): TTableContent[] => {
    const commonContent = [
      { value: projectGroup?.name },
      {
        value: projectGroup?.description,
      },
    ];

    return !canUpdate && !canDelete
      ? [...commonContent]
      : [
          ...commonContent,
          {
            value: (
              <div className="flex justify-center items-center gap-3">
                {canUpdate && (
                  <ToolTip
                    render={() => "Edit"}
                    arrow
                    classNameForParent="truncate"
                  >
                    <HiPencil
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit("create", projectGroup);
                      }}
                      className="w-6 h-6 cursor-pointer text-ironside-gray/70"
                    />
                  </ToolTip>
                )}
                {canDelete && (
                  <ToolTip
                    render={() => "Delete"}
                    arrow
                    classNameForParent="truncate"
                  >
                    <BiTrash
                      className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit("delete", projectGroup);
                      }}
                    />
                  </ToolTip>
                )}
              </div>
            ),
            hideContent: canDelete && canUpdate,
          },
        ];
  };

  return (
    <div className="hidden md:block mt-6">
      <TableWithCursorPagination
        className="min-h-[80vh]"
        onPagination={{
          control: control,
          nextDisabled,
          onNextPageHandler,
          onPageSizeChange,
          onPrevPageHandler,
          pageSizeName: "pageSize",
          prevDisabled,
          totalCount,
        }}
        tableHeadings={{
          tableString: tableHeadings,
        }}
        ref={tableRef}
        isCenterLastHeading={canUpdate || canDelete ? true : false}
      >
        {projectGroupEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <TableContent
                key={index}
                tableRef={tableRef}
                tableContent={getTableContent(edge?.node)}
                tableRowClick={() => {
                  if (edge?.node?.id) {
                    cardOrTableClickHandler(edge?.node?.id);
                  }
                }}
                classForTableRow={`${
                  canReadProjectsOrGroupBugsOrTasks
                    ? "cursor-pointer hover:bg-white-smoke"
                    : "cursor-not-allowed hover:bg-white-smoke"
                }`}
                onKebabIcon={
                  canUpdate && canDelete
                    ? {
                        editAccess: true,
                        onEdit: (e) => {
                          edge?.node && onKebabEdit(e, edge?.node);
                        },
                        onDelete: (e) => {
                          edge?.node && onKebabDelete(e, edge?.node);
                        },
                        onKebabIconClick: (e) => {
                          onKebabIconClick(e);
                        },
                      }
                    : undefined
                }
              />
            );
          } else {
            return null;
          }
        })}
      </TableWithCursorPagination>
    </div>
  );
};

export default ProjectGroupTable;
