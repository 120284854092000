import { Time } from "@internationalized/date";

export const setTime = (timeString: string) => {
  const [time, period] = timeString.split(" ");
  const [hours, minutes, seconds] = time.split(":").map(Number);

  let railwayHours = hours;
  if (period === "PM" && hours !== 12) {
    railwayHours = hours + 12;
  } else if (period === "AM" && hours === 12) {
    railwayHours = 0;
  }
  

  return new Time(railwayHours, minutes, seconds);
};
