import React, { useEffect, useRef } from "react";
interface MentionItemProps extends React.ComponentPropsWithoutRef<"div"> {
  isActive: boolean;
}
const MentionItem = ({
  isActive,
  ...props
}: MentionItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: "nearest" });
    }
  }, [isActive]);



  return (
    <div
      ref={ref}
      className={`p-1 capitalize ${isActive?"bg-white-smoke cursor-pointer":""}`}
      {...props}
    />
  );
};

export default MentionItem;
