import { TypedDocumentNode, gql } from "@apollo/client";

import { PROJECT_GROUP_FRAGMENT } from "modules/Project/Pages/Projects/ProjectGroups/services/fragments";
import {
  CreateProjectGroupArgs,
  CreateProjectGroupReturnType,
  DeleteProjectGroupArgs,
  DeleteProjectGroupReturnType,
  UpdateProjectGroupArgs,
  UpdateProjectGroupReturnType,
} from "modules/Project/Pages/Projects/ProjectGroups/types";

export const CREATE_PROJECT_GROUP: TypedDocumentNode<
  CreateProjectGroupReturnType,
  CreateProjectGroupArgs
> = gql`
  ${PROJECT_GROUP_FRAGMENT}
  mutation createProjectGroup(
    $projectGroupCreateInput: ProjectGroupCreateInput
    $isDescriptionNeed: Boolean = false
  ) {
    createProjectGroup(projectGroupCreateInput: $projectGroupCreateInput) {
      ...projectGroupFields
    }
  }
`;

export const UPDATE_PROJECT_GROUP: TypedDocumentNode<
  UpdateProjectGroupReturnType,
  UpdateProjectGroupArgs
> = gql`
  ${PROJECT_GROUP_FRAGMENT}
  mutation updateProjectGroup(
    $projectGroupUpdateInput: ProjectGroupUpdateInput
    $isDescriptionNeed: Boolean = false
  ) {
    updateProjectGroup(projectGroupUpdateInput: $projectGroupUpdateInput) {
      ...projectGroupFields
    }
  }
`;

export const DELETE_PROJECT_GROUP: TypedDocumentNode<
  DeleteProjectGroupReturnType,
  DeleteProjectGroupArgs
> = gql`
  mutation deleteProjectGroup($id: Int!) {
    deleteProjectGroup(id: $id)
  }
`;
