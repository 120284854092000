import { FC } from "react";

import { ReactComponent as NoResults } from "global/assets/images/no-results.svg";

const OopsNoData: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={`flex justify-center items-center flex-col  w-full ${
        className ? className : "min-h-[400px]"
      }`}
    >
      <NoResults />
      <p className="text-cornflower-blue">
        Sorry, we couldn't find any results.
      </p>
    </div>
  );
};

export default OopsNoData;
