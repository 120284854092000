import { Filled, Text, Modal, Outlined } from "components/forms";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";

import { toastNotify } from "global/helpers/Cache";

import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "modules/Organization/services/mutations";
import { DEPARTMENTS } from "modules/Organization/services/queries";

export interface DepartmentType {
  id: number;
  name: string;
}

const EditEmployeeDepartment: React.FC<{
  employeeDepartment: any;
  setShowModal: Function;
  inProgress: boolean;
  setInProgress: Function;
  refetch: Function;
}> = ({
  employeeDepartment,
  setShowModal,
  inProgress,
  setInProgress,
  refetch,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DepartmentType>();

  const [createEmployeeDepartment] = useMutation(CREATE_DEPARTMENT);
  const [updateEmployeeDepartment] = useMutation(UPDATE_DEPARTMENT);

  const createHandler = (data: DepartmentType) => {
    if (!inProgress) {
      createEmployeeDepartment({
        variables: {
          name: data.name,
        },
      })
        .then((response) => {
          const departmentName = response?.data?.createEmployeeDepartment?.name;
          setInProgress(false);
          setShowModal(false);
          reset();
          toastNotify([
            {
              messageType: "success",
              message: `${
                departmentName ? `${departmentName} department` : "Department"
              } has been created successfully.`,
            },
          ]);
          refetch();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  const updateHandler = (data: DepartmentType) => {
    if (!inProgress) {
      updateEmployeeDepartment({
        variables: {
          id: +employeeDepartment.id,
          name: data.name,
        },
        update: (cache, { data: { updateEmployeeDepartment } }) => {
          const exDepartments: {
            employeeDepartments: DepartmentType[] | any;
          } | null = cache.readQuery({
            query: DEPARTMENTS,
          });

          const updatedDeparments =
            exDepartments?.employeeDepartments?.dataCollection?.map(
              (departmentDetails: DepartmentType) => {
                if (+departmentDetails?.id === +employeeDepartment?.id) {
                  return {
                    ...departmentDetails,
                    id: updateEmployeeDepartment?.id,
                    name: updateEmployeeDepartment?.name,
                  };
                }
                return departmentDetails;
              }
            );

          cache.writeQuery({
            query: DEPARTMENTS,
            data: {
              employeeDepartments: { dataCollection: updatedDeparments },
            },
          });
        },
      })
        .then((response) => {
          const departmentName = employeeDepartment?.name;
          setInProgress(false);
          setShowModal(false);
          toastNotify([
            {
              messageType: "success",
              message: `${
                departmentName ? `${departmentName} department` : "Department"
              } has been updated successfully.`,
            },
          ]);
          refetch();
        })
        .catch((error) => {
          setInProgress(false);
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ]);
        });
      setInProgress(!inProgress);
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div">
        <div className="edit-leave-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Department</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setInProgress(false);
              setShowModal(false);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <form
          onSubmit={
            employeeDepartment?.id
              ? handleSubmit(updateHandler)
              : handleSubmit(createHandler)
          }
        >
          <div className="form-div pr-[18px] lg:pr-[45px] py-[12px]">
            <Text
              register={register}
              errors={errors}
              label="Department *"
              name="name"
              required={true}
              defaultText={employeeDepartment?.name}
            />
          </div>
          <div className="w-full border-b" />
          <div className="button-div px-[18px] lg:px-[45px]">
            <Outlined
              onClick={() => {
                setInProgress(false);
                setShowModal(false);
              }}
              buttonName="Cancel"
            />
            <Filled
              buttonName={
                inProgress ? (
                  <div className="w-5 h-5 border-4 border-t-transparent mx-auto border-white border-solid rounded-full animate-spin" />
                ) : employeeDepartment?.id ? (
                  "Update"
                ) : (
                  "Add"
                )
              }
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditEmployeeDepartment;
