import { TypedDocumentNode, gql } from "@apollo/client";

import { COMPANY_FRAGMENT } from "modules/Company/services/fragments";

import {
  CreateCompanyArgs,
  CreateCompanyReturnType,
  DeleteCompanyArgs,
  DeleteCompanyReturnType,
  UpdateCompanyArgs,
  UpdateCompanyReturnType,
} from "modules/Company/types";

export const CREATE_COMPANY: TypedDocumentNode<
  CreateCompanyReturnType,
  CreateCompanyArgs
> = gql`
  ${COMPANY_FRAGMENT}
  mutation CreateCompany(
    $name: String!
    $legalName: String
    $companyType: String!
    $isdCode: Int
    $mobile: String
    $altIsdCode: Int
    $altMobile: String
    $email: String
    $cin: String
    $gstin: String
    $pan: String
    $status: String!
    $companyLogo: Upload
    $isEmailVerified: Boolean
    $isPanVerified: Boolean
    $isCinVerified: Boolean
    $isGstinVerified: Boolean
    $openingTime: String
    $closingTime: String
    $address: String
    $companyOwnerIds: [Int]
    $companyManagerIds: [Int]
    $isAddressNeed: Boolean = false
    $isAltIsdCode: Boolean = false
    $isAltMobileNeed: Boolean = false
    $isCINNeed: Boolean = false
    $isClosingTimeNeed: Boolean = false
    $isCompanyLogoNeed: Boolean = false
    $isCompanyManagersNeed: Boolean = false
    $isCompanyOwnersNeed: Boolean = false
    $isCompanyTypeNeed: Boolean = false
    $isCreatedAtNeed: Boolean = false
    $isEmailNeed: Boolean = false
    $isGstinNeed: Boolean = false
    $isCinVerifiedNeed: Boolean = false
    $isEmailVerifiedNeed: Boolean = false
    $isGstinVerifiedNeed: Boolean = false
    $isPanVerifiedNeed: Boolean = false
    $isIsdCodeNeed: Boolean = false
    $isJoinedAtNeed: Boolean = false
    $isLegalNameNeed: Boolean = false
    $isMobileNeed: Boolean = false
    $isOpeningTimeNeed: Boolean = false
    $isPanNeed: Boolean = false
    $isStatusNeed: Boolean = false
    $isUpdatedAtNeed: Boolean = false
  ) {
    createCompany(
      name: $name
      legalName: $legalName
      companyType: $companyType
      isdCode: $isdCode
      mobile: $mobile
      altIsdCode: $altIsdCode
      altMobile: $altMobile
      email: $email
      cin: $cin
      gstin: $gstin
      pan: $pan
      status: $status
      companyLogo: $companyLogo
      isEmailVerified: $isEmailVerified
      isPanVerified: $isPanVerified
      isCinVerified: $isCinVerified
      isGstinVerified: $isGstinVerified
      openingTime: $openingTime
      closingTime: $closingTime
      address: $address
      companyOwnerIds: $companyOwnerIds
      companyManagerIds: $companyManagerIds
    ) {
      ...companyFields
    }
  }
`;

export const UPDATE_COMPANY: TypedDocumentNode<
  UpdateCompanyReturnType,
  UpdateCompanyArgs
> = gql`
  ${COMPANY_FRAGMENT}
  mutation UpdateCompany(
    $id: Int!
    $name: String
    $legalName: String
    $companyType: String
    $isdCode: Int
    $mobile: String
    $altIsdCode: Int
    $altMobile: String
    $email: String
    $cin: String
    $gstin: String
    $pan: String
    $status: String
    $companyLogo: Upload
    $isEmailVerified: Boolean
    $isPanVerified: Boolean
    $isCinVerified: Boolean
    $isGstinVerified: Boolean
    $openingTime: String
    $closingTime: String
    $address: String
    $companyOwnerIds: [Int]
    $companyManagerIds: [Int]
    $isAddressNeed: Boolean = false
    $isAltIsdCode: Boolean = false
    $isAltMobileNeed: Boolean = false
    $isCINNeed: Boolean = false
    $isClosingTimeNeed: Boolean = false
    $isCompanyLogoNeed: Boolean = false
    $isCompanyManagersNeed: Boolean = false
    $isCompanyOwnersNeed: Boolean = false
    $isCompanyTypeNeed: Boolean = false
    $isCreatedAtNeed: Boolean = false
    $isEmailNeed: Boolean = false
    $isGstinNeed: Boolean = false
    $isCinVerifiedNeed: Boolean = false
    $isEmailVerifiedNeed: Boolean = false
    $isGstinVerifiedNeed: Boolean = false
    $isPanVerifiedNeed: Boolean = false
    $isIsdCodeNeed: Boolean = false
    $isJoinedAtNeed: Boolean = false
    $isLegalNameNeed: Boolean = false
    $isMobileNeed: Boolean = false
    $isOpeningTimeNeed: Boolean = false
    $isPanNeed: Boolean = false
    $isStatusNeed: Boolean = false
    $isUpdatedAtNeed: Boolean = false
  ) {
    updateCompany(
      id: $id
      name: $name
      legalName: $legalName
      companyType: $companyType
      isdCode: $isdCode
      mobile: $mobile
      altIsdCode: $altIsdCode
      altMobile: $altMobile
      email: $email
      cin: $cin
      gstin: $gstin
      pan: $pan
      status: $status
      companyLogo: $companyLogo
      isEmailVerified: $isEmailVerified
      isPanVerified: $isPanVerified
      isCinVerified: $isCinVerified
      isGstinVerified: $isGstinVerified
      openingTime: $openingTime
      closingTime: $closingTime
      address: $address
      companyOwnerIds: $companyOwnerIds
      companyManagerIds: $companyManagerIds
    ) {
      ...companyFields
    }
  }
`;

export const DELETE_COMPANY: TypedDocumentNode<
  DeleteCompanyReturnType,
  DeleteCompanyArgs
> = gql`
  mutation DeleteCompany($id: Int!) {
    deleteCompany(id: $id)
  }
`;
