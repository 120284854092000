import Logo from "layouts/header/Logo/Logo";
import ProfilePic from "layouts/header/ProfilePic/ProfilePic";
import Title from "layouts/header/Title/Title";
import TopLinks from "layouts/header/TopLinks/TopLinks";
import HamburgerMenu from "layouts/header/HamburgerMenu";
import { useLocation } from "react-router-dom";
import { navData } from "../nav/Sidebar/NavData";
import { useEffect, useState } from "react";
import { useApolloClient, useReactiveVar } from "@apollo/client";
import { Capitalize } from "global/helpers/Capitalize";
import { GET_USER_DETAILS } from "modules/Employee/services/queries";
import useWindowSize from "global/hooks/useWindowSize";
import { setTitle } from "global/helpers/Cache";

const Header: React.FC<{
  mobileNav: Function;
  setShowMenu: Function;
  showMenu: boolean;
}> = ({ mobileNav, setShowMenu, showMenu }) => {
  const [userDataList, setUserDataList] = useState<any>(null);

  const client = useApolloClient();
  const isOtherUser = sessionStorage?.otherUserId ? true : false;

  useEffect(() => {
    if (isOtherUser) {
      client
        .query({
          query: GET_USER_DETAILS,
          variables: {
            id: +sessionStorage?.otherUserId,
          },
        })
        .then((response: any) => {
          setUserDataList(response?.data?.users?.dataCollection[0]);
        });
    }

    return () => {
      setUserDataList(null);
    };
  }, [client, isOtherUser]);

  const path = useLocation();
  let pathName = path.pathname;
  const titleFromReactiveVar = useReactiveVar(setTitle);
  let title:
    | string
    | {
        name: string;
        link: string;
      }[] = "";

  switch (
    `/${pathName?.split("/")?.filter((filterData: string) => filterData)[0]}`
  ) {
    case "/profile":
      if (sessionStorage?.otherUserId) {
        title = userDataList?.personalInformation?.name
          ? `Organization / ${userDataList?.personalInformation?.name}`
          : "Organization";
      } else {
        title = "My Profile";
      }
      break;

    case "/change-password":
      title = "Change Password";
      break;

    case "/notification":
      title = "Notification";
      break;

    case "/leaves":
      const pathNamesArray = pathName?.split("/");
      title = `Leave / ${Capitalize(
        pathNamesArray?.filter((filterData: string) => filterData)[1]
      )}`;
      break;

    case "/projects":
      title = titleFromReactiveVar || "";
      break;

    default:
      navData?.map((menuName) =>
        pathName?.includes(`${menuName?.link}`) ? (title = menuName.name) : null
      );
      break;
  }

  const [isClickedOutsideHeader, setIsClickedOutsideHeader] =
    useState<boolean>(false);
  const [hamburgerMenuClicked, setHamburgerMenuClicked] =
    useState<boolean>(false);
  const [width] = useWindowSize();

  useEffect(() => {
    if (!showMenu) {
      setIsClickedOutsideHeader(false);
      setHamburgerMenuClicked(false);
    }
  }, [showMenu]);

  useEffect(() => {
    if (width < 1280 && isClickedOutsideHeader) {
      setShowMenu(false);
    }
  }, [isClickedOutsideHeader, width, setShowMenu]);

  return (
    <div
      onClick={
        showMenu
          ? () => {
              if (hamburgerMenuClicked) {
                setIsClickedOutsideHeader(true);
              }
            }
          : undefined
      }
    >
      <div className="3xl:w-[1900px] w-full shadow-[0px_4px_12px_#AEAEAE29] bg-white">
        <div className="flex justify-between items-center py-[12px] lg:py-[10px]">
          <div className="flex items-center">
            <HamburgerMenu
              mobileNav={mobileNav}
              setHamburgerMenuClicked={setHamburgerMenuClicked}
            />
            <Logo />
          </div>
          <div className="lg:block hidden">
            <Title title={title} />
          </div>
          <div className="flex mr-[15px] items-center">
            <TopLinks />
            <ProfilePic />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
