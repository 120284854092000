import { FC, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { Control } from "react-hook-form";

import TableContent from "components/App/UpdatedAgryApp/Table/TableContent";
import TableWithCursorPagination from "components/App/UpdatedAgryApp/Table/TableWithCursorPagination";
import { TTableContent } from "components/App/UpdatedAgryApp/Table/types/Table";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

import { formatTimeWithAMAndPM } from "global/helpers/date-methods";
import { FilterFormType } from "global/types/type";

import { Company, CompanyEdge, CompanyFilterForm } from "modules/Company/types";

interface Props {
  canUpdate: boolean;
  canDelete: boolean;
  onEdit: (type: "create" | "delete", company?: Company | null) => void;
  nextDisabled: boolean;
  control: Control<FilterFormType<CompanyFilterForm>, any>;
  prevDisabled: boolean;
  totalCount: number;
  onPageSizeChange: (pageSize: number) => void;
  onNextPageHandler: () => void;
  onPrevPageHandler: () => void;
  cardOrTableClickHandler: (companyId: number) => void;
  companyEdges: CompanyEdge[];
}

const tableCommonHeadings = [
  "Name",
  "Type",
  "Status",
  "Opening time",
  "Closing time",
  "Owners",
];

const CompanyTable: FC<Props> = ({
  canDelete,
  canUpdate,
  onEdit,
  nextDisabled,
  control,
  prevDisabled,
  totalCount,
  onPageSizeChange,
  onNextPageHandler,
  onPrevPageHandler,
  cardOrTableClickHandler,
  companyEdges,
}) => {
  const tableHeadings = canDelete
    ? [...tableCommonHeadings, "Action"]
    : tableCommonHeadings;

  const tableRef = useRef<HTMLDivElement>(null);

  const getTableContent = (company: Company): TTableContent[] => {
    const {
      name,
      companyType,
      status,
      openingTime,
      closingTime,
      companyOwners,
    } = company;

    return [
      {
        value: name,
      },
      {
        value: companyType,
      },
      {
        value: status,
      },
      {
        value: openingTime ? formatTimeWithAMAndPM(openingTime, "hh:mm a") : "",
      },
      {
        value: closingTime ? formatTimeWithAMAndPM(closingTime, "hh:mm a") : "",
      },
      {
        value:
          companyOwners && companyOwners?.length > 0
            ? companyOwners
                ?.map((owner) => owner?.personalInformation?.name)
                ?.join(", ")
            : "-",
      },
      {
        value: (
          <div className="flex justify-center">
            <ToolTip render="Delete" arrow classNameForParent="truncate">
              <BiTrash
                className="w-6 h-6 text-ironside-gray/70 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit("delete", company);
                }}
              />
            </ToolTip>
          </div>
        ),
        hideContent: !canDelete,
      },
    ];
  };

  return (
    <div className="hidden md:block mt-6">
      <TableWithCursorPagination
        className="min-h-[80vh]"
        onPagination={{
          control: control,
          nextDisabled,
          onNextPageHandler,
          onPageSizeChange,
          onPrevPageHandler,
          pageSizeName: "pageSize",
          prevDisabled,
          totalCount,
        }}
        tableHeadings={{
          tableString: tableHeadings,
        }}
        ref={tableRef}
        isCenterLastHeading
      >
        {companyEdges?.map((edge, index) => {
          if (edge && edge?.node) {
            return (
              <TableContent
                key={index}
                tableRef={tableRef}
                tableContent={getTableContent(edge?.node)}
                tableRowClick={() => {
                  if (edge?.node?.id) {
                    cardOrTableClickHandler(edge?.node?.id);
                  }
                }}
                classForTableRow={`${
                  canUpdate ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              />
            );
          } else {
            return null;
          }
        })}
      </TableWithCursorPagination>
    </div>
  );
};

export default CompanyTable;
