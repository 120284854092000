import { camelToTitleCase } from "global/helpers/Capitalize";

export const makeDataModelArray = (inputObj: any, removeItems?: string[]) => {
  const moderationObj: any = inputObj?.moderation || null;

  const removeItemsArray: string[] = removeItems || [];

  const dataModel =
    (inputObj &&
      Object.entries(inputObj)
        .map((inputObjItem) => {
          if (!removeItemsArray?.includes(inputObjItem[0])) {
            return {
              label: camelToTitleCase(inputObjItem[0]),
              value:
                inputObjItem[1] === false
                  ? "No"
                  : inputObjItem[1] === true
                  ? "YES"
                  : inputObjItem[1],
              moderationId:
                (moderationObj && moderationObj[inputObjItem[0]]) || null,
            };
          }
          return null;
        })
        ?.filter((dataModal) => dataModal)) ||
    [];

  return dataModel;
};
