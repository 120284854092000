import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useForm } from "react-hook-form";

import { Select } from "components/forms";
import Loading from "components/Loader/Loading";

import { ReactComponent as NoResults } from "global/assets/images/no-results.svg";
import { getAllowedAccess } from "global/helpers/Cache";

import { LEAVE_DASHBOARD } from "modules/Leave/services/queries";
import DashboardView from "modules/Leave/Pages/Dashboard/DashboardView";

const Dashboard = () => {
  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const sessionId: string | any = document.cookie
    .split("; ")
    .find((row) => row.startsWith("id="))
    ?.split("=")[1];

  const { data: leaveDashboard, loading } = useQuery(LEAVE_DASHBOARD, {
    variables: {
      userId: +localStorage?.id || +sessionId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [showRegularLeaves, setShowRegularLeaves] = useState<boolean>(true);
  const [showSpecialLeaves, setShowSpecialLeaves] = useState<boolean>(true);

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const currentYear = new Date()?.getFullYear()?.toString();

  useEffect(() => {
    if (currentYear) {
      setValue("defaultYear", currentYear);
    }
  }, [currentYear, setValue]);

  const defaultYear = watch("defaultYear");

  const leaveLengthValidation =
    leaveDashboard && leaveDashboard?.leaveDashboard?.length > 0;

  const regularLeavesLengthValidation =
    leaveDashboard?.leaveDashboard?.filter(
      (leave: any) => leave?.year === +defaultYear
    )?.length > 0;

  const specialLeavesLengthValidation =
    leaveDashboard?.leaveDashboard?.filter((leave: any) => !leave.year)
      ?.length > 0;

  return (
    <div className="rounded shadow-[0px_0px_45px_#C4C4C433] md:p-2 lg:px-[23px] bg-white py-[10px] overflow-hidden min-h-[600px] md:min-h-[795px] lg:min-h-[824px]">
      {loading ? (
        <Loading className="min-h-[500px]" />
      ) : (
        <div>
          {leaveLengthValidation ? (
            <div>
              <div>
                <div className="flex w-full justify-end pt-[20px]">
                  <span className="w-[100px]">
                    <Select
                      register={register}
                      name={"defaultYear"}
                      errors={errors}
                      label={"Year"}
                      options={[currentYear, `${+currentYear + 1}`]}
                      defaultSelectValue={currentYear}
                    />
                  </span>
                </div>
                <div
                  className={`${
                    showRegularLeaves ? "" : "mb-5"
                  } p-3 bg-gray-200 rounded-md cursor-pointer`}
                  onClick={() => {
                    setShowRegularLeaves(!showRegularLeaves);
                  }}
                >
                  <p className="flex justify-between items-center">
                    <span className="text-sm">Regular Leaves</span>
                  </p>
                </div>
                {showRegularLeaves ? (
                  <div>
                    {regularLeavesLengthValidation ? (
                      <div className="md:grid gap-6 md:grid-cols-2 lg:grid-cols-3 my-3">
                        {showRegularLeaves &&
                          leaveDashboard?.leaveDashboard?.map(
                            (leave: {
                              availed: number;
                              id: number;
                              leaveType: any;
                              remaining: number;
                              total: number;
                              year: number;
                            }) => {
                              if (
                                leave?.year === +defaultYear &&
                                !leave?.leaveType?.isSpecialLeave
                              ) {
                                return (
                                  <DashboardView
                                    leaveDetails={leave}
                                    key={leave?.id}
                                    allowedResources={allowedResources}
                                    leaveYear={+defaultYear}
                                  />
                                );
                              }
                              return null;
                            }
                          )}
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center my-[92px]">
                        <NoResults />
                        <p className="text-cornflower-blue">
                          Sorry, we couldn't find any available leaves.
                        </p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              {specialLeavesLengthValidation ? (
                <div>
                  <div
                    className="p-3 bg-gray-200 rounded-md cursor-pointer"
                    onClick={() => {
                      setShowSpecialLeaves(!showSpecialLeaves);
                    }}
                  >
                    <p className="flex justify-between items-center">
                      <span className="text-sm">Special Leaves</span>
                    </p>
                  </div>
                  {showSpecialLeaves ? (
                    <div className="md:grid gap-6 md:grid-cols-2 lg:grid-cols-3 my-3">
                      {leaveDashboard?.leaveDashboard?.map(
                        (leave: {
                          availed: number;
                          id: number;
                          leaveType: any;
                          remaining: number;
                          total: number;
                          year: number;
                        }) => {
                          if (leave?.leaveType?.isSpecialLeave) {
                            return (
                              <DashboardView
                                leaveDetails={leave}
                                key={leave?.id}
                                allowedResources={allowedResources}
                                leaveYear={+defaultYear}
                              />
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center min-h-[800px]">
              <p className="text-sm text-warm-gray">
                You are not assigned to any leave. Please contact HR.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
