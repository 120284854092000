import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { Filled, SearchField } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";
import OopsNoData from "components/UI/OopsNoData";
import { ITableContent } from "components/App/Table/types/table";

import { toastNotify } from "global/helpers/Cache";
import { paginationDefaultValue } from "global/helpers/StaticData";

import { DELETE_ROLE } from "modules/Settings/services/mutations";
import { GET_ROLES } from "modules/Settings/services/queries";

export interface RoleCollectionType {
  allowedResourceIds: string[];
  description: string;
  id: number;
  isActive: boolean;
  roleName: string;
}

const Role: FC<{ allowedResources: string[] }> = ({ allowedResources }) => {
  const createAccess = allowedResources?.includes("CreateRole");
  const updateAccess = allowedResources?.includes("UpdateRole");
  const deleteAccess = allowedResources?.includes("DeleteRole");

  const navigate = useNavigate();
  const { register, watch, setValue } = useForm();
  const searchRole = watch("role");

  const [deleteRole] = useMutation(DELETE_ROLE);
  const [
    FilteringRoleCollection,
    { data: roleCollectionFilter, refetch, loading },
  ] = useLazyQuery(GET_ROLES, {
    fetchPolicy: "cache-and-network",
  });

  const [roleCollectionList, setRoleCollectionList] = useState<
    RoleCollectionType[] | undefined
  >();
  const [editList, setEditList] = useState<any>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);
  const [searchRoleName, setSearchRoleName] = useState<string>(
    searchRole || ""
  );

  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearchRoleName(searchRole?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [searchRole]);

  useEffect(() => {
    FilteringRoleCollection({
      variables: {
        limit: pageSize,
        page: currentPage,
        search: searchRoleName || undefined,
      },
    });
  }, [currentPage, pageSize, FilteringRoleCollection, searchRoleName]);

  useEffect(() => {
    setRoleCollectionList(
      roleCollectionFilter?.roles?.dataCollection
        ? roleCollectionFilter?.roles?.dataCollection
        : ""
    );
  }, [roleCollectionFilter, pageSize, currentPage]);

  const deleteHandler = () => {
    deleteRole({
      variables: {
        id: +editList?.id,
      },
    })
      .then((response) => {
        setDeleteVerification(false);
        refetch();
        toastNotify([
          {
            messageType: "success",
            message: `${editList?.roleName || ""} ${
              response?.data?.deleteRole
            }`,
          },
        ]);

        if (
          response?.data?.deleteRole ===
          "This role is assigned for some users you cannot delete this role"
        )
          return setCurrentPage(currentPage);
        else setCurrentPage(1);
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };

  const parentRef = useRef<HTMLDivElement>(null);
  const onDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuClickHandler = (roleDetails: RoleCollectionType) => {
    setEditList(roleDetails);
  };
  const kebabMenuEditHandler = (roleDetails: RoleCollectionType) => {
    if (updateAccess) {
      navigate("edit-role", {
        state: roleDetails,
      });
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  return (
    <div className={"min-h-[70vh]"}>
      <Fragment>
        <div className="w-full bg-white flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row py-[25px]">
          <SearchField
            register={register}
            name="role"
            label="Role"
            onChange={() => {
              setCurrentPage(1);
            }}
            setValue={setValue}
          />
        </div>
        <Fragment>
          {loading ? (
            <Loading className="min-h-[68vh]" />
          ) : roleCollectionList?.length !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="w-full flex justify-center">
                  {createAccess ? (
                    <button
                      className="w-[150px] lg:w-[120px] px-3 h-[42px] text-base bg-cornflower-blue text-white hover:bg-bright-blue/90 leading-[20px] rounded"
                      onClick={() => {
                        setEditList(null);
                        navigate("edit-role");
                      }}
                    >
                      Add Role
                    </button>
                  ) : null}
                </div>
                <div className="w-full flex flex-col justify-center mt-5">
                  {roleCollectionList?.map(
                    (roleDetails: RoleCollectionType, index: number) => {
                      const dataModal = [
                        {
                          lable: "Role",
                          value: [
                            {
                              content: roleDetails?.roleName,
                            },
                          ],
                        },
                        {
                          lable: "Description",
                          value: [
                            {
                              content: roleDetails?.description,
                            },
                          ],
                        },
                      ];
                      return (
                        <SMDataCard
                          key={roleDetails?.id}
                          title={`Role ${index + 1}`}
                          showModal={deleteVerification}
                          loading={loading}
                          dataModal={dataModal}
                          onKebabIcon={{
                            onclick: () => {
                              kebabMenuClickHandler(roleDetails);
                            },
                            kebabMenu: {
                              onEdit: () => {
                                kebabMenuEditHandler(roleDetails);
                              },
                              onDelete: kebabMenuDeleteHandler,
                            },
                          }}
                        />
                      );
                    }
                  )}
                </div>
                <Pagination
                  totalCount={
                    roleCollectionFilter?.roles?.totalNumberOfItems || 0
                  }
                  noOfItem={roleCollectionList?.length}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page: number) => {
                    setCurrentPage(page);
                    FilteringRoleCollection({
                      variables: {
                        limit: pageSize,
                        page: page,
                        search: searchRole || undefined,
                      },
                    });
                  }}
                />
              </div>
              <div className="hidden md:flex flex-col justify-between w-full">
                <Table
                  ref={parentRef}
                  tableHeadingsObj={[
                    { name: "Name", center: false },
                    { name: "Description", center: true },
                  ]}
                  noOfItems={roleCollectionFilter && roleCollectionList?.length}
                  createAccess={createAccess}
                  loading={loading}
                  onPlusIconClick={() => {
                    setEditList(null);
                    navigate("edit-role");
                  }}
                  className="py-5 min-h-[550px]"
                  pageSize={pageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  totalCount={
                    roleCollectionFilter?.roles
                      ? roleCollectionFilter?.roles?.totalNumberOfItems
                      : 0
                  }
                >
                  {roleCollectionList?.map((role: RoleCollectionType) => {
                    let tableContent: ITableContent[] = [
                      { value: role.roleName },
                      { value: role.description, center: true },
                    ];
                    return (
                      <TableContent
                        id={role?.id}
                        key={role?.id}
                        parentRef={parentRef}
                        tableContent={tableContent}
                        onMenuClick={() => {
                          setEditList(role);
                        }}
                        editAccess={updateAccess || deleteAccess}
                        onEdit={() => {
                          if (updateAccess) {
                            navigate("edit-role", {
                              state: role,
                            });
                          } else {
                            toastNotify([
                              {
                                messageType: "error",
                                message: "Access denied.",
                              },
                            ]);
                          }
                        }}
                        onDelete={onDeleteHandler}
                        showModal={deleteVerification}
                      />
                    );
                  })}
                </Table>
              </div>
            </Fragment>
          ) : roleCollectionFilter && searchRoleName?.length > 0 ? (
            <OopsNoData className="min-h-[68vh]" />
          ) : (
            <div className="w-full h-[500px] grid place-content-center">
              {createAccess ? (
                <Filled
                  buttonName="Add Role"
                  onClick={() => {
                    setEditList(null);
                    navigate("edit-role");
                  }}
                />
              ) : null}
            </div>
          )}
        </Fragment>
        {deleteVerification ? (
          <ConfirmModal
            header={"Role"}
            onCancel={() => {
              setDeleteVerification(false);
            }}
            onXIcon={() => {
              setDeleteVerification(false);
            }}
            onExecute={deleteHandler}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default Role;
