interface Props {
  buttonName: string;
  isConfirmModal?: React.ReactNode;
  onClick?: () => void;
}

const RoundedOutlined: React.FC<Props> = ({
  buttonName,
  onClick,
  isConfirmModal,
}) => {
  return (
    <button
      className={`${
        isConfirmModal ? "w-[97px] h-[32px]" : "w-[152px] h-[42px]"
      } text-sm bg-white text-cornflower-blue leading-[20px] rounded-[22px] border border-cornflower-blue hover:shadow-lg`}
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default RoundedOutlined;
