import { FC } from "react";

interface IProps {
  isImageAnimationNeeded?: boolean;
}

const ActivityLoading: FC<IProps> = ({ isImageAnimationNeeded = true }) => {
  return (
    <div className="w-full h-[100px]">
      <div className="animate-pulse px-[26px] py-[15px] flex space-x-4">
        {isImageAnimationNeeded && (
          <div className="rounded-full bg-slate-200 h-[60px] w-[60px] mt-[2px]"></div>
        )}
        <div className="flex-1 space-y-[18px] py-1">
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-200 rounded col-span-2"></div>
              <div className="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLoading;
