const timeFunction = (inputTime: string) => {
  let hours: string | number = +inputTime?.slice(0, 2);
  let minutes: string = inputTime?.slice(3, 5);
  let seconds: string = inputTime?.slice(6, 8);
  let amPM: string = inputTime?.slice(9, 11) ? inputTime?.slice(9, 11) : "AM";
  if (hours > 11) {
    hours += -12;
    amPM = "PM";
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return { hours, minutes, seconds, amPM };
};

// 09:00 AM
export const TwelveHoursTimeFormat = (inputTime: string) => {
  const timeComponents = timeFunction(inputTime);
  return inputTime
    ? `${timeComponents?.hours}:${timeComponents?.minutes}${
        timeComponents?.seconds !== "00" ? `:${timeComponents?.seconds}` : ""
      } ${timeComponents?.amPM}`
    : "";
};

// 09:00:00 AM
export const TwelveHoursFormat = (inputTime: string) => {
  const timeComponents = timeFunction(inputTime);
  return inputTime
    ? `${timeComponents?.hours}:${timeComponents?.minutes} ${timeComponents?.amPM}`
    : "";
};

// 23:00
export const RailwayTimeFormat = (inputTime: string) => {
  let hours: string | number = +inputTime?.slice(0, 2);
  let minutes: string = inputTime?.slice(3, 5);
  let amPM: string = inputTime?.slice(6, 8);

  if (amPM === "PM") {
    hours += +12;
    if (hours > 23) {
      hours += -24;
    }
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return inputTime ? `${hours}:${minutes}` : "";
};

//Input type examples 0,12,1,3. Values that are got by getHours() method on date object

export const convertHoursToTwelveHour = (hour: number) => {
  const hourInString = hour?.toString();

  const railwayTimeHours: any = {
    "0": 12,
    "12": 12,
    "13": 1,
    "14": 2,
    "15": 3,
    "16": 4,
    "17": 5,
    "18": 6,
    "19": 7,
    "20": 8,
    "21": 9,
    "22": 10,
    "23": 11,
  };

  return hour > 0 && hour < 12
    ? hourInString?.padStart(2, "0")
    : railwayTimeHours[hourInString]?.toString()?.padStart(2, "0");
};
