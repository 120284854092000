import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { HiTrash } from "react-icons/hi";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useReactiveVar } from "@apollo/client";
import { MdOutlineClose } from "react-icons/md";

import AutoComplete from "components/forms/UpdatedFormAgry/AutoComplete/AutoComplete";
import { CalendarField } from "components/forms";
import Input from "components/forms/UpdatedFormAgry/Input/Input";
import Time from "components/forms/UpdatedFormAgry/Time/Time";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { setTime } from "components/forms/UpdatedFormAgry/Time/helpers/set-time";

import { ViewDateFormat, formatTime } from "global/helpers/DateFormatter";
import {
	FetchMoreType,
	ISetStateType,
	RefetchQueryType,
} from "global/types/type";
import { toastNotify, userContextData } from "global/helpers/Cache";
import { convertHoursToTwelveHour } from "global/helpers/TimeFormat";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import {
	FilterProjectsArgs,
	FilterProjectsReturnType,
	IProject,
	IProjectWorkLog,
} from "modules/Project/types/project";
import {
	FilterAllProjectWorkLogsArgs,
	FilterAllProjectWorkLogsReturnType,
	ProjectWorkLogTimerForm,
} from "modules/Timer/WorkLogs/types";
import {
	CREATE_OR_UPDATE_PROJECT_WORK_LOG,
	DELETE_PROJECT_WORK_LOG,
} from "modules/Project/services/mutations";

interface Props {
	workLog: IProjectWorkLog;
	projects: IProject[];
	refetchWorkLogs: RefetchQueryType<
		FilterAllProjectWorkLogsReturnType,
		FilterAllProjectWorkLogsArgs
	>;
	isEditing: boolean;
	setIsEditing: ISetStateType<boolean>;
	fetchMoreProjects: FetchMoreType<
		FilterProjectsReturnType,
		FilterProjectsArgs
	>;
	projectsLoading?: boolean;
}

const MyProjectWorkLog: FC<Props> = ({
	workLog,
	projects,
	refetchWorkLogs,
	isEditing,
	setIsEditing,
	fetchMoreProjects,
	projectsLoading,
}) => {
	const loggedInUser = useReactiveVar(userContextData);

	const [createProjectWorkLog, { loading: createProjectWorkLogLoading }] =
		useMutation(CREATE_OR_UPDATE_PROJECT_WORK_LOG);

	const [deleteProjectWorkLog, { loading: deleteProjectWorkLogLoading }] =
		useMutation(DELETE_PROJECT_WORK_LOG);

	const [showEditWorkLog, setShowEditWorkLog] = useState(false);
	const [editWorkLog, setEditWorkLog] = useState<IProjectWorkLog | null>(null);
	const [workLogDate, setWorkLogDate] = useState<Date | null | undefined>(
		new Date(),
	);
	const [deleteVerification, setDeleteVerification] = useState(false);

	const currentDate = new Date();
	const minDate = new Date(
		currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1),
	)
		.toISOString()
		.slice(0, 10);

	const {
		control,
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<ProjectWorkLogTimerForm>();

	useEffect(() => {
		if (showEditWorkLog) {
			const startDateTime = editWorkLog?.startTime
				? new Date(editWorkLog?.startTime)
				: new Date();

			const endDateTime = editWorkLog?.endTime
				? new Date(editWorkLog?.endTime)
				: new Date();

			setValue(
				"startTime",
				setTime(`${startDateTime?.getHours()}:${startDateTime?.getMinutes()}`),
			);
			setValue(
				"endTime",
				setTime(`${endDateTime?.getHours()}:${endDateTime?.getMinutes()}`),
			);
		}
	}, [editWorkLog, showEditWorkLog, setValue]);

	const [watchProject] = useWatch({
		control,
		name: ["project", "task", "description"],
	});

	useEffect(() => {
		if (editWorkLog?.startTime) {
			setWorkLogDate(
				new Date(editWorkLog?.startTime?.slice(0, 18)?.replaceAll("-", " ")),
			);
		}
	}, [editWorkLog?.startTime]);

	const showEditWorkLogHandler = () => {
		setShowEditWorkLog((prev) => !prev);
	};

	const editWorkLogHandler = (workLog: IProjectWorkLog) => {
		setEditWorkLog(workLog);
	};

	const projectsList =
		projects?.reduce((acc: { id: number; label: string }[], project) => {
			if (project?.id && project?.name) {
				acc.push({ id: project?.id, label: project?.name });
				return acc;
			}
			return acc;
		}, []) || [];

	const selectedProjectTasks = useMemo(
		() =>
			projects?.filter((project) => project?.id === watchProject?.id)[0]?.tasks
				?.dataCollection || [],
		[projects, watchProject?.id],
	);

	const projectTaskList = useMemo(
		() =>
			selectedProjectTasks?.reduce(
				(acc: { id: number; label: string }[], task) => {
					if (task?.id && task?.title) {
						acc.push({ id: task?.id, label: task?.title });
						return acc;
					}
					return acc;
				},
				[],
			) || [],
		[selectedProjectTasks],
	);

	const [projectTaskOptions, setProjectTaskOptions] = useState(projectTaskList);

	useEffect(() => {
		setProjectTaskOptions(projectTaskList);
	}, [projectTaskList]);

	const onDelete = (workLog: IProjectWorkLog) => {
		setEditWorkLog(workLog);
		setDeleteVerification((prev) => !prev);
	};

	const confirmModalCloseHandler = () => {
		setEditWorkLog(null);
		setDeleteVerification(false);
	};

	const deleteHandler = () => {
		if (editWorkLog?.id && !deleteProjectWorkLogLoading) {
			deleteProjectWorkLog({
				variables: {
					id: editWorkLog?.id,
				},
			})
				.then(() => {
					refetchWorkLogs();
					toastNotify([
						{
							messageType: "success",
							message: "Work Log deleted successfully",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]);
				})
				.finally(() => {
					setDeleteVerification(false);
				});
		}
	};

	const submitHandler: SubmitHandler<ProjectWorkLogTimerForm> = ({
		description,
		task,
		startTime,
		endTime,
	}) => {
		const logDate =
			(workLogDate && ViewDateFormat(new Date(workLogDate))) || "";

		const startTimeFormatted =
			startTime !== null && startTime !== undefined
				? `${convertHoursToTwelveHour(startTime?.hour)}:${
						startTime?.minute <= 9 ? `0${startTime?.minute}` : startTime?.minute
				  } ${startTime?.hour > 11 ? "PM" : "AM"}`
				: null;

		const endTimeFormatted =
			endTime !== null && endTime !== undefined
				? `${convertHoursToTwelveHour(endTime?.hour)}:${
						endTime?.minute <= 9 ? `0${endTime?.minute}` : endTime?.minute
				  } ${endTime?.hour > 11 ? "PM" : "AM"}`
				: null;

		if (!createProjectWorkLogLoading && task?.id) {
			createProjectWorkLog({
				variables: {
					createProjectWorkLogInput: {
						id: editWorkLog?.id || undefined,
						userId: loggedInUser?.user?.id,
						projectTaskId: +task?.id,
						startTime: `${logDate} ${startTimeFormatted}`,
						endTime: `${logDate} ${endTimeFormatted}`,
						description,
					},
					isProfileImageNeeded: false,
				},
			})
				.then(() => {
					refetchWorkLogs();
					setIsEditing(false);
					setShowEditWorkLog(false);
					toastNotify([
						{
							messageType: "success",
							message: "Work log updated successfully",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error.message,
						},
					]);
				});
		}
	};

	return (
		<form
			onSubmit={handleSubmit(submitHandler)}
			className={`grid  ${
				showEditWorkLog
					? "2xl:grid-cols-[1fr,1fr,1fr,1fr,1fr,auto] py-2"
					: "xl:grid-cols-[1fr,1fr,2fr,1fr,1fr,auto]"
			}  gap-3 px-6 border-b pb-2 md:grid-cols-3`}
		>
			{showEditWorkLog ? (
				<Fragment>
					<AutoComplete
						control={control}
						name="project"
						options={projectsList}
						label="Select project"
						className="bg-white md:max-w-[300px] col-span-2 md:col-span-1"
						classNameForComboBox="min-h-[42px]"
						hideError
						defaultValue={
							workLog?.projectTask?.project?.id &&
							workLog?.projectTask?.project?.name
								? {
										id: workLog?.projectTask?.project?.id,
										label: workLog?.projectTask?.project?.name,
								  }
								: undefined
						}
					/>
					<AutoComplete
						control={control}
						name="task"
						options={projectTaskOptions}
						label="Select task"
						className="bg-white md:max-w-[400px] col-span-2 md:col-span-1"
						classNameForComboBox="min-h-[42px]"
						hideError
						defaultValue={
							workLog?.projectTask?.id && workLog?.projectTask?.title
								? {
										id: workLog?.projectTask?.id,
										label: workLog?.projectTask?.title,
								  }
								: undefined
						}
						onInputChange={(value) => {
							if (
								!projectsLoading &&
								fetchMoreProjects &&
								watchProject?.id &&
								loggedInUser?.user?.id
							) {
								fetchMoreProjects({
									variables: {
										filters: {
											isMyProject: true,
											id: watchProject?.id,
										},
										taskPage: 1,
										taskLimit: 30,
										tasksFilters2: {
											assigneeUserId: [+loggedInUser?.user?.id],
											title: value || undefined,
										},
									},
								})
									.then(({ data }) => {
										const project =
											data?.projects &&
											data?.projects?.dataCollection?.length > 0
												? data?.projects?.dataCollection[0]
												: null;

										const projectTasks =
											project?.tasks &&
											project?.tasks?.dataCollection?.length > 0
												? project?.tasks?.dataCollection
												: [];

										const projectTaskOptions = projectTasks?.reduce(
											(acc: { id: number; label: string }[], projectTask) => {
												if (projectTask?.id && projectTask?.title) {
													acc?.push({
														id: projectTask?.id,
														label: projectTask?.title,
													});
													return acc;
												}
												return acc;
											},
											[],
										);

										setProjectTaskOptions(projectTaskOptions);
									})
									.catch((error) => {
										toastNotify(errorMessageNotify(error));
									});
							}
						}}
					/>
					<Input
						control={control}
						name="description"
						label="What have you done?"
						className="md:max-w-[450px] col-span-2 md:col-span-1"
						classNameForInputParentDiv={"min-h-[42px]"}
						hideError
						defaultValue={workLog?.description}
					/>
					<div className="flex flex-wrap sm:flex-nowrap gap-3 col-span-2">
						<Time
							control={control}
							label="From"
							name="startTime"
							hideError
							classForTimeField="min-h-[42px] min-w-[30px]"
						/>
						<CalendarField
							register={register}
							errors={errors}
							name="workLogDate"
							date={workLogDate}
							setDate={setWorkLogDate}
							className="h-[42px] min-w-[170px] sm:max-w-[200px] shadow-none"
							label="Start Date *"
							maxDate={new Date()}
							minDate={new Date(minDate)}
							hideError
						/>
						<Time
							control={control}
							label="To"
							name="endTime"
							hideError
							classForTimeField="min-h-[42px] min-w-[30px]"
						/>
					</div>
					<div className="flex items-center">
						{createProjectWorkLogLoading ? (
							<div className="w-5 h-5 border-4 border-t-transparent mx-auto border-blue-500 border-solid rounded-full animate-spin" />
						) : (
							<button type="submit">
								<BiSave className="min-w-[24px] min-h-[24px] bg-none" />
							</button>
						)}
						<MdOutlineClose
							className="text-ironside-gray cursor-pointer min-w-[24px] min-h-[24px]"
							onClick={() => {
								showEditWorkLogHandler();
								setIsEditing(false);
							}}
						/>
					</div>
					{/*  Edit state end  */}
				</Fragment>
			) : (
				<Fragment>
					{/*  Read state start  */}
					<div>{workLog?.projectTask?.project?.name || "-"}</div>
					<div>{workLog?.projectTask?.title || "-"}</div>
					<div>{workLog?.description || "-"}</div>
					<div className="grid grid-cols-3 whitespace-nowrap xl:col-start-4 xl:col-end-6">
						<span>
							{workLog?.startTime?.slice(
								workLog?.startTime?.indexOf(")") + 2,
								workLog?.startTime?.indexOf(")") + 7,
							)}{" "}
							{workLog?.startTime?.slice(-2)}
						</span>
						<span>
							{workLog?.endTime?.slice(
								workLog?.endTime?.indexOf(")") + 2,
								workLog?.endTime?.indexOf(")") + 7,
							)}{" "}
							{workLog?.endTime?.slice(-2)}
						</span>
						<span>
							{(workLog?.durationInSeconds &&
								formatTime(workLog?.durationInSeconds)?.replaceAll(" ", "")) ||
								"00:00:00"}
						</span>
					</div>
					<div className="flex items-center gap-2">
						{/* <HiOutlineDocumentDuplicate className="min-w-[20px] min-h-[20px] cursor-pointer" /> */}
						<FaRegEdit
							className={`min-w-[20px] min-h-[20px] ${
								isEditing ? "cursor-not-allowed" : "cursor-pointer"
							}`}
							onClick={() => {
								if (!isEditing) {
									setIsEditing(true);
									editWorkLogHandler(workLog);
									showEditWorkLogHandler();
								}
							}}
						/>
						<HiTrash
							className="min-w-[20px] min-h-[20px] cursor-pointer"
							onClick={() => {
								onDelete(workLog);
							}}
						/>
					</div>
					{/*  Read state end  */}
				</Fragment>
			)}
			{deleteVerification && (
				<ConfirmModal
					header="Work log"
					onCancel={confirmModalCloseHandler}
					onXIcon={confirmModalCloseHandler}
					onExecute={deleteHandler}
					loading={deleteProjectWorkLogLoading}
				/>
			)}
		</form>
	);
};

export default MyProjectWorkLog;
