import { FC, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";

import { isAllowedResource } from "global/helpers/AllowedResourceHelper";
import useAllowedResources from "global/hooks/useAllowedResources";
import { ITabLink } from "components/App/TabLink/types/tab-links";
import { IRoute } from "components/App/CustomRoutes/types/custom-routes";
import CustomRoutes from "components/App/CustomRoutes/CustomRoutes";

interface IProps {
  tabLinks: ITabLink[];
  routes: IRoute[];
}

const TabLink: FC<IProps> = ({ tabLinks, routes }) => {
  const allowedResources = useAllowedResources();
  const slider = useRef<any>(null);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;
  return (
    <Fragment>
      <div
        ref={slider}
        onMouseDown={(e) => {
          isDown = true;
          startX = e?.pageX - slider.current?.offsetLeft;
          scrollLeft = slider?.current?.scrollLeft;
        }}
        onMouseLeave={() => {
          isDown = false;
        }}
        onMouseUp={() => {
          isDown = false;
        }}
        onMouseMove={(e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider?.current?.offsetLeft;
          const walk = (x - startX) * 1;
          slider.current.scrollLeft = scrollLeft - walk;
        }}
        className="tab-nav"
      >
        {tabLinks?.map(
          (tabLink: ITabLink, index: number) =>
            (isAllowedResource(allowedResources, tabLink?.allowedResourceIds) ||
              tabLink?.showTab) && (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  `tab-nav-item ${isActive ? "active" : ""}`
                }
                to={tabLink?.link}
              >
                {tabLink?.name}
              </NavLink>
            )
        )}
      </div>

      <CustomRoutes routes={routes} />
    </Fragment>
  );
};

export default TabLink;
