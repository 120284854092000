import { FC, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { FileDragAndDrop, Filled, Outlined } from "components/forms";
import TitleAndBackNavigation from "components/UI/TitleAndBackNavigation";
import { Input, Select, TextArea } from "components/forms/UpdatedFormAgry";

import { setTitle, toastNotify } from "global/helpers/Cache";
import { bugAndTaskPriorities } from "global/helpers/utils";
import useAllowedResources from "global/hooks/useAllowedResources";

import { ProjectGroupBacklogFormType } from "modules/Project/Pages/Backlogs/types";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";
import { SAVE_PROJECT_TASK } from "modules/Project/services/mutations";
import {
	fileToBase64,
	getFileExtensionUsingName,
} from "global/helpers/FileConverter";
import { COLUMNS_FOR_DROP_DOWN } from "modules/Project/services/queries";

interface Props {
	projectGroup?: ProjectGroup | null;
	editProject: { id: number; name: string };
}

const AddOrEditProjectBacklog: FC<Props> = ({ projectGroup, editProject }) => {
	const allowedResources = useAllowedResources();

	const canCreate = allowedResources?.includes("CreateProjectBacklog");
	const canUpdate = allowedResources?.includes("UpdateProjectBacklog");

	const { groupId, backlogId, projectType, id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (editProject?.name && projectGroup?.id) {
			setTitle([
				{
					name: "Project",
					link: "/projects/groups",
				},
				{ name: "Groups", link: "/projects/groups" },
				{
					name: projectGroup?.name || "",
					link: `/projects/${projectGroup?.id}/${projectType}`,
				},
				{
					name: `${editProject?.name}`,
					link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
				},
				{
					name: "Backlogs",
					link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/backlogs`,
				},
			]);
		} else {
			setTitle([
				{
					name: "Project",
					link: `/projects/projects/${projectType}`,
				},
				{
					name: projectGroup?.name || "",
					link: `/projects/projects/${projectType}`,
				},
				{
					name: `${editProject?.name}`,
					link: `/projects/projects/${projectType}/edit/${id}/general`,
				},
				{
					name: "Backlogs",
					link: `projects/projects/${projectType}/edit/${id}/backlogs`,
				},
			]);
		}
	}, [
		editProject?.name,
		projectType,
		projectGroup?.id,
		projectGroup?.name,
		id,
	]);

	const [saveProjectTask, { loading: saveProjectTaskLoading }] =
		useMutation(SAVE_PROJECT_TASK);

	const { data: allColumns, loading: columnsLoading } = useQuery(
		COLUMNS_FOR_DROP_DOWN,
		{
			skip: id ? false : true,
			fetchPolicy: "cache-and-network",
			variables: {
				filters: {
					projectId: Number(id),
				},
			},
		},
	);

	const {
		reset,
		handleSubmit,
		control,
		formState: { isValid },
		setValue,
	} = useForm<ProjectGroupBacklogFormType>({
		defaultValues: {
			priority: "Low",
		},
	});

	const columnNamesLowerCased: { id: number; label: string }[] = [];
	const columnList: { id: number; label: string }[] = useMemo(
		() =>
			allColumns?.projectColumns?.dataCollection?.map(
				(projectSprint: { id: number; name: string }) => {
					if (projectSprint?.name?.toLowerCase() === "to do") {
						columnNamesLowerCased?.push({
							id: projectSprint?.id,
							label: projectSprint?.name,
						});
					}

					return {
						id: projectSprint?.id,
						label: projectSprint?.name,
					};
				},
			) || [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allColumns?.projectColumns?.dataCollection],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (columnList && columnList?.length > 0) {
			setValue("column", {
				id: columnList[0]?.id,
				label: columnList[0]?.label,
			});
		}
	}, [columnList, setValue]);

	const [fileList, setFileList] = useState<any>([]);
	const [updatedFiles, setUpdatedFiles] = useState<any>([]);

	const currentFiles: any = [];

	fileList?.map((fileDetails: any, index: number) => {
		if (fileDetails?.size < 2e6)
			fileToBase64(fileDetails, (_err: any, result: any) => {
				if (result) {
					currentFiles[index] = {
						filePath: result?.split(";base64,")[1],
						name: fileDetails?.name,
						extension:
							getFileExtensionUsingName(fileDetails?.name || "")?.length > 0
								? `.${getFileExtensionUsingName(fileDetails?.name || "")}`
								: undefined,
						mimeType: fileDetails?.type
							? fileDetails?.type?.toString()?.length > 0
								? fileDetails?.type
								: "text/plain"
							: "text/plain",
					};
				}
			});
		return <></>;
	});

	const projectGroupTaskSubmitHandler: SubmitHandler<
		ProjectGroupBacklogFormType
	> = ({ title, description, priority, column }) => {
		if (!saveProjectTaskLoading && id && Number.isFinite(+id)) {
			const currentFilesList =
				currentFiles?.length > 0
					? currentFiles?.map(
							(file: {
								filePath: string;
								name: string;
								extension: string;
								mimeType: string;
							}) => {
								return {
									filePath: file?.filePath,
									name: file?.name,
									extension: file?.extension,
									mimeType: file?.mimeType,
								};
							},
					  ) || []
					: [];

			saveProjectTask({
				variables: {
					projectTaskInput: {
						title,
						projectId: +id,
						description: description?.trim() || undefined,
						priority: priority || undefined,
						projectColumnId: column?.id,
						projectTaskAttachments:
							currentFilesList?.length > 0 ? currentFilesList : undefined,
					},
				},
			})
				.then((_response) => {
					navigate(
						`/projects/${
							groupId ? `${groupId}` : "projects"
						}/${projectType}/edit/${id}/backlogs`,
					);
					reset();
					toastNotify([
						{
							messageType: "success",
							message: "Project back log created successfully.",
						},
					]);
				})
				.catch((error) => {
					toastNotify([
						{
							messageType: "error",
							message: error?.message,
						},
					]);
				});
		}
	};

	return (
		<div className="flex justify-center min-h-[75vh]">
			<form onSubmit={handleSubmit(projectGroupTaskSubmitHandler)}>
				<TitleAndBackNavigation
					title={{
						children: `${
							backlogId && Number.isFinite(+backlogId)
								? "Edit backlog"
								: "Add backlog"
						}`,
					}}
					navigation={{
						onClick: () => {
							navigate(
								`/projects/${
									groupId ? `${groupId}` : "projects"
								}/${projectType}/edit/${id}/backlogs`,
							);
						},
					}}
					className="text-sm mb-3"
				/>
				<div className=" min-w-[330px] sm:min-w-[370px] flex flex-col h-min">
					<Input control={control} label="Title *" name="title" required />
					<div className="">
						<Select
							control={control}
							label="Priority"
							name="priority"
							options={bugAndTaskPriorities}
							className="min-h-[45px]"
						/>
					</div>
					<div className="">
						<Select
							control={control}
							label="Column"
							name="column"
							options={columnList}
							className="min-h-[45px]"
							loading={columnsLoading}
						/>
					</div>
					<div className="col-span-2">
						<TextArea
							label="Description"
							name="description"
							control={control}
						/>
					</div>
					<FileDragAndDrop
						fileList={fileList}
						setFileList={setFileList}
						updatedFiles={updatedFiles}
						setUpdatedFiles={setUpdatedFiles}
						setAlert={toastNotify}
						isFileSupportMessageNeeded={false}
						supportedMimeType=""
						isFileLimitNeed={false}
						className="mb-8"
					/>
					<div className="flex justify-between sm:col-span-2 mb-3">
						<Outlined
							buttonName={"Cancel"}
							onClick={() => navigate(`/projects/${groupId}/bugs`)}
						/>
						<Filled
							disabled={
								isValid
									? backlogId && Number.isFinite(+backlogId)
										? !canUpdate
										: !canCreate
									: true
							}
							buttonName={
								backlogId && Number.isFinite(+backlogId) ? "Update" : "Add"
							}
							loading={saveProjectTaskLoading}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};
export default AddOrEditProjectBacklog;
