import { FC, useState, Fragment, useRef } from "react";
import { useMutation } from "@apollo/client";

import { Filled } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import DataCard from "components/App/Card/DataCard";

import { toastNotify } from "global/helpers/Cache";
import { removeDuplicates } from "global/helpers/ArrayMethods";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import CertificateDetails from "modules/Employee/Pages/Profile/Skills/Certificates/CertificateDetails";
import EditCertificates from "modules/Employee/Pages/Profile/Skills/Certificates/EditCertificates";
import { GET_SKILLS_DETAILS } from "modules/Employee/services/queries";
import { DELETE_SKILL_CERTIFICATE } from "modules/Employee/services/mutations";
import {
  IEditSkillCertifcate,
  ISkillCertificate,
  ISkillDetail,
  ISkillsList,
} from "modules/Employee/types/skills";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

interface IProps {
  loading: boolean;
  userSkills: ISkillDetail[];
  refetchSkillDetails: Function;
}

const Certifications: FC<IProps> = ({
  loading,
  userSkills,
  refetchSkillDetails,
}) => {
  const resourceAccess = useGetAllowedResources("EmployeeSkillCertificate");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [editCertificate, setEditCertificate] =
    useState<IEditSkillCertifcate | null>(null);

  const [deleteUserSkillCertificate] = useMutation(DELETE_SKILL_CERTIFICATE, {
    refetchQueries: [GET_SKILLS_DETAILS],
  });

  const userTechnicalSkills: string[] = [],
    userTechnicalSkillsId: number[] = [],
    userTechnicalSkillsList: ISkillsList[] = [],
    userNonTechnicalSkills: string[] = [],
    userNonTechnicalSkillsId: number[] = [],
    userNonTechnicalSkillsList: ISkillsList[] = [];

  const userSkillTypes = removeDuplicates(
    userSkills?.map((userSkill: ISkillDetail) => userSkill?.skill?.type)
  );

  userSkills?.map((userSkill: ISkillDetail) => {
    if (userSkill?.skill?.type === "Technical") {
      userTechnicalSkills.push(userSkill?.skill?.name);
      userTechnicalSkillsId.push(userSkill?.skill?.id);
      userTechnicalSkillsList.push({
        id: userSkill?.id,
        name: userSkill?.skill?.name,
      });
    }
    if (userSkill?.skill?.type === "Non Technical") {
      userNonTechnicalSkills.push(userSkill?.skill?.name);
      userNonTechnicalSkillsId.push(userSkill?.skill?.id);
      userNonTechnicalSkillsList.push({
        id: userSkill?.id,
        name: userSkill?.skill?.name,
      });
    }
    return { userTechnicalSkills, userNonTechnicalSkills };
  });

  const userSkillCertificateLength: number[] = [];

  userSkills?.map((userSkill: ISkillDetail) => {
    userSkillCertificateLength.push(userSkill?.userSkillCertificates?.length);
    return userSkill;
  });

  const isUnderModeration = useIsUnderModeration();

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Certificate")
    : deleteSuccessMessageWithAwaiting("Certificate");

  const deleteHandler = () => {
    deleteUserSkillCertificate({
      variables: {
        id:
          editCertificate?.userSkillCertificate &&
          +editCertificate?.userSkillCertificate?.id,
      },
    })
      .then(() => {
        setEditCertificate(null);
        setDeleteVerification(false);
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  const moderationProps = {
    setChangedInformation: setChangedInformation,
    setShowUnderReview: setShowUnderReview,
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <DataCard
        loading={loading}
        title="Certifications"
        showPlusIcon={
          resourceAccess?.canCreate &&
          userSkillCertificateLength &&
          Math?.max(...userSkillCertificateLength) > 0
            ? true
            : false
        }
        resourceName={"EmployeeSkillCertificate"}
        setShowModal={setShowModal}
      >
        {(resourceAccess?.canCreate &&
          userSkillCertificateLength &&
          Math.max(...userSkillCertificateLength) === 0) ||
        (resourceAccess?.canCreate &&
          userSkillCertificateLength &&
          Math.max(...userSkillCertificateLength) === -Infinity) ? (
          <div className="w-full min-h-[390px] grid place-content-center">
            <Filled
              buttonName={"Add"}
              buttonType="button"
              onClick={() => {
                if (userSkills?.length === 0) {
                  toastNotify([
                    {
                      messageType: "error",
                      message: "Add Skills.",
                    },
                  ]);
                } else if (
                  userSkills?.filter(
                    (userSKill: { skill: { name: string } }) =>
                      userSKill?.skill?.name
                  ).length < 1
                ) {
                  toastNotify([
                    {
                      messageType: "error",
                      message: "Skill details are under review.",
                    },
                  ]);
                } else {
                  setShowModal(true);
                  setEditCertificate(null);
                }
              }}
            />
          </div>
        ) : (
          <div
            className="w-full overflow-x-auto space-y-4 pt-5 min-h-[387px]"
            ref={parentRef}
          >
            {userSkills?.map((userSkill: ISkillDetail, index: number) => {
              return (
                <Fragment key={index}>
                  {userSkill?.userSkillCertificates?.length > 0 && (
                    <div className={"w-full grid grid-cols-1 gap-4"}>
                      <p className="text-sm text-warm-gray flex justify-between">
                        {userSkill?.skill?.name}
                      </p>

                      {userSkill?.userSkillCertificates?.map(
                        (
                          userSkillCertificate: ISkillCertificate,
                          index: number
                        ) => {
                          return (
                            <CertificateDetails
                              key={index}
                              userSkillCertificate={userSkillCertificate}
                              userSkill={userSkill}
                              setDeleteVerification={setDeleteVerification}
                              setEditCertificate={setEditCertificate}
                              setShowModal={setShowModal}
                              parentRef={parentRef}
                              resourceAccess={resourceAccess}
                              {...moderationProps}
                            />
                          );
                        }
                      )}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </DataCard>
      {showModal && (
        <EditCertificates
          editCertificate={editCertificate}
          setEditCertificate={setEditCertificate}
          userSkillTypes={userSkillTypes}
          userTechnicalSkillsList={userTechnicalSkillsList}
          userNonTechnicalSkillsList={userNonTechnicalSkillsList}
          setShowModal={setShowModal}
          refetchSkillDetails={refetchSkillDetails}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"Skills"}
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
        />
      )}

      {showUnderReview && (
        <UnderReviewModal
          {...moderationProps}
          changedInformation={changedInformation}
          refetchQuery={refetchSkillDetails}
        />
      )}
    </div>
  );
};

export default Certifications;
