import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { CheckBox, Email, Password } from "components/forms";
import { toastNotify } from "global/helpers/Cache";
import { LOGIN_QUERY } from "modules/User/services/queries";

interface LoginDataType {
	email: string;
	password: string;
}

const Login = () => {
	const navigate = useNavigate();

	const [inProgress, setInProgress] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);

	const [loginQuery] = useLazyQuery(LOGIN_QUERY, {
		onCompleted: (data) => {
			setInProgress(false);
			if (rememberMe) {
				localStorage.id = data.login.id;
				localStorage.token = data.login.token;
			} else {
				document.cookie = `token=${data.login.token}; Secure;`;
				document.cookie = `id=${data.login.id}; Secure;`;
			}
			navigate(
				data?.login?.userType === 2
					? "/projects/projects/my-projects"
					: "/profile/personal",
			);
		},
		onError: (error) => {
			setInProgress(false);
			toastNotify([
				{
					messageType: "error",
					message: error.message,
				},
			]);
		},
	});

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<LoginDataType>();

	useEffect(() => {
		if (
			localStorage.token ||
			document.cookie
				.split("; ")
				.find((row) => row.startsWith("token="))
				?.split("=")[1]
		) {
			navigate("/profile/personal");
		}
	}, [navigate]);

	const onSubmit = (data: LoginDataType) => {
		if (!inProgress) {
			loginQuery({
				variables: {
					email: data.email,
					password: data.password,
				},
			});
			setInProgress(!inProgress);
		}
	};

	const clickHandler = () => {
		navigate("/signup-as");
	};

	return (
		<div className="form-design md:px-[42px] pt-[30px]">
			<h2 className="heading mb-[24px]">Log In</h2>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="space-y-[5px] mb-[24px] w-full"
			>
				<Email
					register={register}
					errors={errors}
					label="Email *"
					name="email"
					required={true}
				/>

				<Password
					register={register}
					errors={errors}
					label="Password *"
					name="password"
				/>

				<button type="submit" className="button-design">
					{inProgress ? <div className="btn-spinner" /> : "Log In"}
				</button>
			</form>
			<div className="flex w-[300px] md:w-[356px] justify-between items-center border-b border-[#E5E5E5] pb-[30px] mb-[30px]">
				<div className="flex items-center gap-2">
					<CheckBox
						name={"remember-me"}
						onChange={() => setRememberMe(!rememberMe)}
					/>
					<label htmlFor="remember-me" className="text-sm text-ironside-gray">
						Remember Me
					</label>
				</div>
				<Link className="text-cornflower-blue text-sm" to={"/forgot-password"}>
					Forgot Password?
				</Link>
			</div>
			<button
				type="submit"
				onClick={clickHandler}
				className="btn-signup w-full mb-[30px]"
			>
				Create Account
			</button>
		</div>
	);
};

export default Login;
