import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      id
      userType
    }
  }
`;
