import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { BiTrash } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { FileUpload, Filled, Modal, Outlined } from "components/forms";

import { toastNotify } from "global/helpers/Cache";

import { CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIALS } from "modules/Project/services/mutations";
import {
  GET_ALL_PROJECT_ROLES_FOR_DROP_DOWN,
  GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPES,
  GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS,
  GET_ALL_PROJECT_VAULT_ENVIRONMENTS,
  GET_PROJECT_VAULT_CREDENTIAL,
} from "modules/Project/services/queries";

import { IProjectVaultCredential } from "modules/Project/types/project";
import {
  blobToFile,
  dataURLtoBlobForFileUpload,
} from "global/helpers/mimeType";

import {
  fileToBase64,
  getFileExtensionUsingName,
} from "global/helpers/FileConverter";
import { TVaultCredentialTypeFields } from "modules/Project/types/vault";
import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import Input from "components/forms/UpdatedForm/Input";
import TextArea from "components/forms/UpdatedForm/TextArea";

const EditVault: React.FC<{
  editProjectVaultCredential: IProjectVaultCredential | any;
  setShowModal: Function;
  loading: boolean;
  refetchProjectVaultCredentials: Function;
  projectVaultCredentialID: number | any;
  setEditProjectVaultCredential: Function;
  setProjectVaultCredentialID: Function;
  setEditCredentialStatus: Function;
}> = ({
  editProjectVaultCredential,
  setShowModal,
  loading,
  refetchProjectVaultCredentials,
  projectVaultCredentialID,
  setEditProjectVaultCredential,
  setProjectVaultCredentialID,
  setEditCredentialStatus,
}) => {
  const { data: getAllProjectVaultEnvironments } = useQuery(
    GET_ALL_PROJECT_VAULT_ENVIRONMENTS
  );

  const { data: getAllProjectVaultCredentialTypes } = useQuery(
    GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPES
  );

  const [
    fetchAllProjectVaultCredentialTypeFields,
    {
      data: getAllProjectVaultCredentialTypeFields,
      loading: projectVaultCredentialTypeFieldLoading,
    },
  ] = useLazyQuery(GET_ALL_PROJECT_VAULT_CREDENTIAL_TYPE_FIELDS, {
    fetchPolicy: "cache-and-network",
  });

  const [
    fetchProjectVaultCredential,
    { data: projectVaultCredential, loading: credentialLoading },
  ] = useLazyQuery(GET_PROJECT_VAULT_CREDENTIAL, {
    fetchPolicy: "cache-and-network",
  });

  const vaultEnvironmentsLists =
    getAllProjectVaultEnvironments?.projectVaultEnvironment || [];

  const projectVaultCredentialTypesList =
    getAllProjectVaultCredentialTypes?.projectVaultCredentialType || [];

  const { data: getAllProjectRolesForDropDown } = useQuery(
    GET_ALL_PROJECT_ROLES_FOR_DROP_DOWN
  );

  const [
    saveProjectVaultCredential,
    { loading: projectVaultCredentialLoading },
  ] = useMutation(CREATE_OR_UPDATE_PROJECT_VAULT_CREDENTIALS);

  const {
    watch,
    register,
    unregister,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const type = watch("type");

  const [file, setFile] = useState<string | null>(null);
  const [fileDetails, setFileDetails] = useState<any>(null);

  const credentialRoles: { id: number; name: string }[] = useMemo(
    () =>
      projectVaultCredential?.projectCredential?.rolePrivileges?.map(
        (vaultRole: { role: { id: string; name: string } }) => {
          return {
            id: vaultRole?.role?.id,
            name: vaultRole?.role?.name,
          };
        }
      ) || [],
    [projectVaultCredential?.projectCredential?.rolePrivileges]
  );

  useEffect(() => {
    if (projectVaultCredentialID) {
      fetchProjectVaultCredential({
        variables: {
          id: projectVaultCredentialID,
        },
      });
    }
  }, [projectVaultCredentialID, fetchProjectVaultCredential]);
  const roleOption: { id: number; name: string }[] = useMemo(() => {
    return getAllProjectRolesForDropDown?.projectRoles?.dataCollection?.length >
      0
      ? getAllProjectRolesForDropDown?.projectRoles?.dataCollection
          ?.filter(
            (projectRole: { id: number; name: string; isActive: boolean }) =>
              projectRole?.isActive
          )
          ?.map((projectRole: { id: number; name: string }) => ({
            id: projectRole?.id,
            name: projectRole?.name,
          }))
      : [];
  }, [getAllProjectRolesForDropDown?.projectRoles?.dataCollection]);

  useEffect(() => {
    unregister("credentials");
  }, [type, unregister]);

  useEffect(() => {
    if (
      projectVaultCredential?.projectCredential?.credentials &&
      projectVaultCredential?.projectCredential?.credentials?.length > 0
    ) {
      if (projectVaultCredential?.projectCredential?.type?.id === +type?.id) {
        projectVaultCredential?.projectCredential?.credentials?.map(
          (credential: {
            credentialId: number;
            field: {
              fieldType: TVaultCredentialTypeFields;
              id: number;
              name: string;
            };
            id: number;
            value: string;
            extension?: string;
            mimeType?: string;
            fileName?: string;
          }) => {
            setValue(
              `credentials.${credential?.field?.name}.credentialFieldId`,
              credential?.id
            );
            setValue(
              `credentials.${credential?.field?.name}.value`,
              credential?.value || ""
            );
            setValue(
              `credentials.${credential?.field?.name}.fieldType`,
              credential?.field.fieldType || ""
            );
            setValue(
              `credentials.${credential?.field?.name}.field`,
              credential?.field?.id || ""
            );

            if (
              credential.field.fieldType === "File" &&
              credential?.value &&
              credential?.value !== "File missing."
            ) {
              if (
                blobToFile(
                  dataURLtoBlobForFileUpload(
                    credential?.value,
                    credential?.mimeType
                  ),

                  `${credential?.fileName}` ||
                    `${new Date().toISOString()}${credential?.extension}`
                )
              ) {
                setValue(
                  `credentials.${credential?.field?.name}.value`,
                  credential?.value
                );
                setFile(
                  `data:${credential?.mimeType};base64,${credential?.value}`
                );
                setFileDetails(
                  blobToFile(
                    dataURLtoBlobForFileUpload(
                      credential?.value,
                      credential?.mimeType
                    ),
                    credential?.fileName
                      ? `${credential?.fileName}`
                      : `${new Date().toISOString()}${credential?.extension}`
                  )
                );
              }
            }

            return null;
          }
        );
      } else {
        unregister("credentials");
        setValue(`credentials`, undefined);
        setFile(null);
        setFileDetails(null);
      }
    }
  }, [
    type,
    projectVaultCredential?.projectCredential?.credentials,
    projectVaultCredential?.projectCredential?.type?.id,
    setValue,
    saveProjectVaultCredential,
    unregister,
  ]);

  useEffect(() => {
    if (
      (type?.id && !isNaN(+type?.id)) ||
      editProjectVaultCredential?.environment?.type?.length > 0
    ) {
      fetchAllProjectVaultCredentialTypeFields({
        variables: {
          type:
            +type?.id || editProjectVaultCredential?.environment?.type[0]?.id,
        },
      }).catch((error) => {
        if (error.name === "AbortError") return;

        toastNotify([
          {
            messageType: "error",
            message: error?.message,
          },
        ]);
      });
    }
  }, [
    fetchAllProjectVaultCredentialTypeFields,
    type,
    editProjectVaultCredential?.environment?.type,
  ]);

  const [projectCredentialTypeFieldLists, setProjectCredentialTypeFieldLists] =
    useState<any>(
      getAllProjectVaultCredentialTypeFields?.projectCredentialTypeField || []
    );

  useEffect(() => {
    setProjectCredentialTypeFieldLists(
      getAllProjectVaultCredentialTypeFields?.projectCredentialTypeField || []
    );
  }, [getAllProjectVaultCredentialTypeFields?.projectCredentialTypeField]);

  let credentialArray: {
    id?: number;
    field: number;
    value: string | undefined;
    extension?: string;
    mimeType?: string;
    fileName?: string;
  }[] = [];

  const createOrUpdateProjectVaultCredential = (data: any) => {
    if (data?.credentials) {
      projectCredentialTypeFieldLists?.map(
        (projectCredentialTypeField: {
          fieldType: string;
          id: number;
          name: string;
        }) => {
          if (data?.credentials[projectCredentialTypeField?.name]) {
            if (
              data?.credentials[projectCredentialTypeField?.name]?.fieldType ===
                "File" &&
              data?.credentials[projectCredentialTypeField?.name]?.value &&
              data?.credentials[projectCredentialTypeField?.name]?.value[0] &&
              data?.credentials[projectCredentialTypeField?.name]?.value[0]
                ?.name &&
              getFileExtensionUsingName(
                data?.credentials[projectCredentialTypeField?.name]?.value[0]
                  ?.name
              )
            ) {
              if (
                data?.credentials[projectCredentialTypeField?.name]?.value[0]
                  .size > 2e6
              ) {
                return toastNotify([
                  {
                    messageType: "error",
                    message: "Maximum file size limit is 2 MB.",
                  },
                ]);
              } else {
                fileToBase64(
                  data?.credentials[projectCredentialTypeField?.name]?.value[0],
                  (err: any, result: string) => {
                    if (result) {
                      if (result && result?.split(";base64,")?.length > 0) {
                        if (result?.split(";base64,")[1]) {
                          credentialArray.push({
                            id: data?.credentials[
                              projectCredentialTypeField?.name
                            ]?.credentialFieldId
                              ? data?.credentials[
                                  projectCredentialTypeField?.name
                                ]?.credentialFieldId
                              : undefined,
                            field:
                              data?.credentials[
                                projectCredentialTypeField?.name
                              ]?.field,
                            value: result?.split(";base64,")[1],
                            extension:
                              getFileExtensionUsingName(
                                data?.credentials[
                                  projectCredentialTypeField?.name
                                ]?.value[0]?.name
                              )?.length > 0
                                ? "." +
                                  getFileExtensionUsingName(
                                    data?.credentials[
                                      projectCredentialTypeField?.name
                                    ]?.value[0]?.name
                                  )
                                : undefined,
                            fileName:
                              data?.credentials[
                                projectCredentialTypeField?.name
                              ]?.value[0]?.name,
                            mimeType: data?.credentials[
                              projectCredentialTypeField?.name
                            ]?.value[0]?.type
                              ? data?.credentials[
                                  projectCredentialTypeField?.name
                                ]?.value[0]?.type?.toString()?.length > 0
                                ? data?.credentials[
                                    projectCredentialTypeField?.name
                                  ]?.value[0]?.type
                                : "text/plain"
                              : "text/plain",
                          });
                        }
                      }
                    }
                  }
                );
              }
            } else if (
              data?.credentials[projectCredentialTypeField?.name]?.fieldType !==
                "File" &&
              (data?.credentials[projectCredentialTypeField?.name]
                ?.fieldType === "Text" ||
                data?.credentials[projectCredentialTypeField?.name]
                  ?.fieldType === "URL" ||
                data?.credentials[projectCredentialTypeField?.name]
                  ?.fieldType === "Password" ||
                data?.credentials[projectCredentialTypeField?.name]
                  ?.fieldType === "Text Area")
            ) {
              credentialArray.push({
                id: data?.credentials[projectCredentialTypeField?.name]
                  ?.credentialFieldId
                  ? data?.credentials[projectCredentialTypeField?.name]
                      ?.credentialFieldId
                  : undefined,
                field:
                  data?.credentials[projectCredentialTypeField?.name]?.field,
                value:
                  data?.credentials[projectCredentialTypeField?.name]?.value,
              });
            }
          }
          return null;
        }
      );
    }

    const credentialRoleIds = credentialRoles?.map(
      (credentialRole: { id: number }) => credentialRole?.id
    );

    const dataRolesIds = data?.roles?.map(
      (dataRole: { id: number }) => dataRole?.id
    );

    let removeRoleIDs =
      credentialRoleIds?.filter(
        (role: number) => !dataRolesIds?.includes(role)
      ) || [];

    if (!projectVaultCredentialLoading) {
      const commonVariables = {
        id: projectVaultCredentialID || undefined,
        environment: +data?.environment?.id,
        type: +type?.id,
        projectId: Number(id),
        credentials: credentialArray?.length > 0 ? credentialArray : [],
        rolePrivilege: data.roles?.map((role: { id: number }) => {
          return {
            roleId: role?.id,
          };
        }),
        removeRole:
          removeRoleIDs && removeRoleIDs?.length > 0
            ? removeRoleIDs?.map((removeRoleID: number) => removeRoleID)
            : undefined,
      };
      setTimeout(() => {
        saveProjectVaultCredential({
          variables: {
            projectVaultCredentialInput: commonVariables,
          },
        })
          .then((response) => {
            setShowModal(false);
            setEditProjectVaultCredential(null);
            setProjectVaultCredentialID(null);
            refetchProjectVaultCredentials();
            reset();
            // refetch();
            toastNotify([
              {
                messageType: "success",
                message: "Credential successfully created",
              },
            ]);
          })
          .catch((error) => {
            toastNotify([
              {
                messageType: "error",
                message: error?.message,
              },
            ]);
          });
      }, 100);
    }
  };

  return (
    <Modal>
      <div className="edit-modal-div ">
        <div className="edit-modal-header-div px-[18px] lg:px-[45px]">
          <h2 className="edit-leave-modal-heading">Vault Credential</h2>
          <div
            className="edit-modal-close-icon-div"
            onClick={() => {
              setShowModal(false);
              setEditProjectVaultCredential(null);
              setProjectVaultCredentialID(null);
            }}
          >
            <MdOutlineClose className="text-ironside-gray cursor-pointer w-[22px] h-[22px]" />
          </div>
        </div>
        <div>
          {loading ? (
            <div className="min-h-[612px] lg:min-h-[618px] bg-white flex justify-center items-center border rounded-b-[12px]">
              <div className="loader-design" />
            </div>
          ) : (
            <form onSubmit={handleSubmit(createOrUpdateProjectVaultCredential)}>
              <div className="form-div pr-[18px] lg:pr-[45px] max-h-[400px] overflow-y-auto">
                <AutoComplete
                  control={control}
                  label="Environment *"
                  name={"environment"}
                  options={vaultEnvironmentsLists}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={
                    projectVaultCredential && {
                      id: projectVaultCredential?.projectCredential?.environment
                        ?.id,
                      name: projectVaultCredential?.projectCredential
                        ?.environment?.name,
                    }
                  }
                  required
                />
                <AutoComplete
                  control={control}
                  label="Type *"
                  name={"type"}
                  options={projectVaultCredentialTypesList}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={
                    projectVaultCredential && {
                      id: projectVaultCredential?.projectCredential?.type?.id,
                      name: projectVaultCredential?.projectCredential?.type
                        ?.name,
                    }
                  }
                  required
                />
                {projectVaultCredentialTypeFieldLoading || credentialLoading ? (
                  <div className="w-[330px] h-[100px]">
                    <div className="animate-pulse px-[10px] py-[6px] flex space-x-4">
                      <div className="flex-1 space-y-[18px] py-1">
                        <div className="h-2 bg-slate-200 rounded"></div>
                        <div className="space-y-3">
                          <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                          </div>
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  projectCredentialTypeFieldLists?.map(
                    (
                      projectCredentialTypeField: {
                        fieldType: string;
                        id: number;
                        name: string;
                      },
                      index: number
                    ) => {
                      return (
                        <div key={index} className="flex gap-3 w-full">
                          <div className="w-full">
                            {projectCredentialTypeField?.fieldType ===
                            "Text" ? (
                              <Input
                                register={register}
                                errors={errors}
                                label={`${projectCredentialTypeField?.name}`}
                                name={`credentials.${projectCredentialTypeField?.name}.value`}
                                setValue={setValue}
                              />
                            ) : projectCredentialTypeField?.fieldType ===
                              "URL" ? (
                              <Input
                                register={register}
                                errors={errors}
                                label={`${projectCredentialTypeField?.name}`}
                                name={`credentials.${projectCredentialTypeField?.name}.value`}
                                setValue={setValue}
                                inputType={"url"}
                              />
                            ) : projectCredentialTypeField?.fieldType ===
                              "File" ? (
                              <FileUpload
                                register={register}
                                errors={errors}
                                name={`credentials.${projectCredentialTypeField?.name}.value`}
                                labelName={`${projectCredentialTypeField?.name}`}
                                file={file || ""}
                                setFile={setFile}
                                fileDetails={fileDetails}
                                setFileDetails={setFileDetails}
                                supportedMimeType={"*"}
                              />
                            ) : projectCredentialTypeField?.fieldType ===
                              "Text Area" ? (
                              <TextArea
                                errors={errors}
                                label={`${projectCredentialTypeField?.name}`}
                                name={`credentials.${projectCredentialTypeField?.name}.value`}
                                register={register}
                                setValue={setValue}
                              />
                            ) : projectCredentialTypeField?.fieldType ===
                              "Password" ? (
                              <Input
                                register={register}
                                errors={errors}
                                label={`${projectCredentialTypeField?.name}`}
                                name={`credentials.${projectCredentialTypeField?.name}.value`}
                                setValue={setValue}
                                inputType={"password"}
                                isPasswordValidationNeeded={false}
                                disablePasswordAutoComplete
                              />
                            ) : null}
                            <input
                              type="text"
                              {...register(
                                `credentials.${projectCredentialTypeField?.name}.fieldType`
                              )}
                              value={projectCredentialTypeField?.fieldType}
                              hidden
                            />
                            <input
                              type="text"
                              {...register(
                                `credentials.${projectCredentialTypeField?.name}.credentialFieldId`
                              )}
                              value={""}
                              hidden
                            />
                            <input
                              type="text"
                              {...register(
                                `credentials.${projectCredentialTypeField?.name}.field`,
                                {
                                  valueAsNumber: true,
                                }
                              )}
                              value={projectCredentialTypeField?.id}
                              hidden
                            />
                          </div>
                          <BiTrash
                            className="w-6 h-6 mt-2.5 text-gray-300  hover:text-red-400 cursor-pointer"
                            onClick={() => {
                              setValue(
                                `credentials.${projectCredentialTypeField?.name}`,
                                undefined
                              );
                              let credentialvalue: {
                                credentialId: number;
                                field: {
                                  fieldType: "Text" | "File";
                                  id: number;
                                  name: string;
                                };
                                id: number;
                                value: string;
                              } | null = null;
                              if (
                                projectVaultCredential?.projectCredential
                                  ?.credentials &&
                                projectVaultCredential?.projectCredential
                                  ?.credentials?.length > 0
                              ) {
                                credentialvalue =
                                  projectVaultCredential?.projectCredential?.credentials?.filter(
                                    (credential: {
                                      credentialId: number;
                                      field: {
                                        fieldType: "Text" | "File";
                                        id: number;
                                        name: string;
                                      };
                                      id: number;
                                      value: string;
                                    }) =>
                                      credential?.field?.id ===
                                      projectCredentialTypeField?.id
                                  )?.length > 0
                                    ? projectVaultCredential?.projectCredential?.credentials?.filter(
                                        (credential: {
                                          credentialId: number;
                                          field: {
                                            fieldType: "Text" | "File";
                                            id: number;
                                            name: string;
                                          };
                                          id: number;
                                          value: string;
                                        }) =>
                                          credential?.field?.id ===
                                          projectCredentialTypeField?.id
                                      )[0]
                                    : null;

                                projectVaultCredential?.projectCredential?.credentials?.filter(
                                  (credential: {
                                    credentialId: number;
                                    field: {
                                      fieldType: "Text" | "File";
                                      id: number;
                                      name: string;
                                    };
                                    id: number;
                                    value: string;
                                  }) =>
                                    credential?.field?.id ===
                                    projectCredentialTypeField?.id
                                );
                              }

                              if (credentialvalue?.credentialId) {
                                saveProjectVaultCredential({
                                  variables: {
                                    projectVaultCredentialInput: {
                                      id: +projectVaultCredentialID,
                                      removeCredential: [credentialvalue?.id],
                                    },
                                  },
                                })
                                  .then(() => {
                                    setProjectCredentialTypeFieldLists(
                                      projectCredentialTypeFieldLists?.filter(
                                        (data: any) =>
                                          data?.id !==
                                          projectCredentialTypeField?.id
                                      )
                                    );
                                    refetchProjectVaultCredentials();
                                    setEditCredentialStatus(true);
                                  })
                                  .catch((error) => {
                                    toastNotify([
                                      {
                                        messageType: "error",
                                        message: error?.message,
                                      },
                                    ]);
                                  });
                              } else {
                                setProjectCredentialTypeFieldLists(
                                  projectCredentialTypeFieldLists?.filter(
                                    (data: any) =>
                                      data?.id !==
                                      projectCredentialTypeField?.id
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      );
                    }
                  )
                )}
                <AutoComplete
                  control={control}
                  label="Roles  *"
                  name={"roles"}
                  options={roleOption}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={credentialRoles}
                  required
                  multiple
                />
              </div>
              <div className="w-full border-b" />
              <div className="button-div px-[18px] lg:px-[45px]">
                <Outlined
                  onClick={() => {
                    setShowModal(false);
                    setEditProjectVaultCredential(null);
                    setProjectVaultCredentialID(null);
                  }}
                  buttonName="Cancel"
                />
                <Filled
                  buttonName={projectVaultCredentialID ? "Update" : "Add"}
                  loading={projectVaultCredentialLoading}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditVault;
