import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLazyQuery } from "@apollo/client";

import { SearchField, Select } from "components/forms";
import RangeSelector from "components/forms/DatePicker/RangeSelector";
import { getDateArray } from "components/forms/DatePicker/DateFiltering";

import { DateFormat } from "global/helpers/DateFormatter";
import { paginationDefaultValue } from "global/helpers/StaticData";

import { FILTER_ALL_COMPENSATION } from "modules/Leave/services/queries";
import ViewPage from "modules/Leave/Pages/Requests/AllCompOffs/ViewPage";
import AllCompOffDetails from "modules/Leave/Pages/Requests/AllCompOffs/AllCompOffDetails";
import { teamOrAllCompensationTypes } from "modules/Leave/types/leave";

const AllCompOffs = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(paginationDefaultValue);

  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const employee = watch("employee");
  const [search, setSearch] = useState(employee || "");

  const viewPageDetails: any = "";

  const defaultDateRangeStartDate = new Date();
  const defaultDateRangeEndDate = new Date(
    +`${new Date()?.getMonth() + 5}` > 12
      ? `${new Date()?.getFullYear() + 1}-${`${
          new Date()?.getMonth() + 5 - 12
        }`?.padStart(2, "0")}-01`
      : `${new Date()?.getFullYear()}-${new Date()?.getMonth() + 5}-01`
  );

  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: defaultDateRangeStartDate,
      endDate: defaultDateRangeEndDate,
      key: "selection",
    },
  ]);

  const selectedDates = getDateArray(
    dateRange[0]?.startDate,
    dateRange[0]?.endDate
  );

  const status = watch("status");
  const fromDate = DateFormat(dateRange[0]?.startDate?.toString());
  const toDate = DateFormat(dateRange[0]?.endDate?.toString());
  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(employee?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [employee]);

  const [
    fetchAllCompensationRequests,
    { data: filterAllCompensationRequests, loading },
  ] = useLazyQuery(FILTER_ALL_COMPENSATION);

  useEffect(() => {
    fetchAllCompensationRequests({
      variables: {
        status: status ? status : undefined,
        from: fromDate ? fromDate : undefined,
        to: toDate ? toDate : undefined,
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [
    pageSize,
    currentPage,
    status,
    fromDate,
    toDate,
    search,
    fetchAllCompensationRequests,
  ]);

  const [filteredRequest, setFilteredRequest] = useState([]);
  useEffect(() => {
    setFilteredRequest(
      filterAllCompensationRequests?.filterAllCompensationLeaveRequest?.dataCollection?.map(
        (request: teamOrAllCompensationTypes) => {
          return {
            createdAt: request.createdAt,
            reason: request?.reason,
            declineMessage: request?.declineMessage,
            id: request.id,
            requestedByUser: request?.requestedByUser,
            requestedToUser: request?.requestedToUser,
            dates: request.compensationLeaveDates?.map((data: any) => {
              return {
                id: data?.id,
                leaveDate: data?.leaveDate,
                session: data?.session,
                declineMessage: data?.declineMessage,
                status: data?.status,
                leaveType: {
                  id: data?.leaveType?.id,
                  name: data?.leaveType?.name,
                },
                startTime: data?.startTime,
                endTime: data?.endTime,
                project: data?.project,
              };
            }),
          };
        }
      )
    );
  }, [filterAllCompensationRequests]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="mt-8">
                <div className="grid grid-cols-[repeat(1,250px)] gap-2 sm:grid-cols-[repeat(2,250px)] lg:grid-cols-[repeat(3,250px)] sm:gap-x-3 sm:gap-y-0 2xl:grid-cols-[repeat(5,216px)] justify-center items-center 2xl:justify-start">
                  <div className="pb-5">
                    <SearchField
                      register={register}
                      name="employee"
                      label="Employee"
                      onChange={() => {
                        setCurrentPage(1);
                      }}
                      setValue={setValue}
                    />
                  </div>
                  <Select
                    register={register}
                    errors={errors}
                    options={[
                      "Approved",
                      "Declined",
                      "Partially Approved",
                      "Pending",
                    ]}
                    label="Status"
                    name="status"
                    required={true}
                  />
                  <div className="mb-5">
                    <RangeSelector
                      setRangeFocus={() => null}
                      workingDates={selectedDates}
                      setDateRange={setDateRange}
                      selectedDates={selectedDates}
                      label={"From and To Date"}
                      minDate={new Date(new Date()?.getFullYear() - 1, 0, 1)}
                      maxDate={new Date(new Date()?.getFullYear() + 1, 11, 31)}
                      classNameForRangeSelector={"min-w-[240px]"}
                      classNameForDateRange="w-full min-w-[248px] shadow border rounded-[4px] flex items-center justify-between px-2 h-[50px] relative"
                    />
                  </div>
                </div>
              </div>

              <AllCompOffDetails
                requestData={filteredRequest && filteredRequest}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalItems={
                  filterAllCompensationRequests
                    ?.filterAllCompensationLeaveRequest?.totalNumberOfItems
                }
                loading={loading}
                filterAllCompensationRequests={filterAllCompensationRequests}
              />
            </div>
          }
        />
        <Route path={`:${viewPageDetails?.id}`} element={<ViewPage />} />
      </Routes>
    </div>
  );
};

export default AllCompOffs;
