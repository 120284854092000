import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { BiTrash } from "react-icons/bi";

import ConfirmModal from "components/forms/Modal/ConfirmModal";
import { getFilterFormValues } from "components/Filter/getFilterFormValues";
import { Filled } from "components/App/Buttons";
import DataCard from "components/App/UpdatedAppComponents/DataCard/DataCard";
import ToolTip from "components/UI/ToolTip";
import Pagination from "components/App/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import { IDataModal } from "components/App/UpdatedAppComponents/DataCard/types/data-card";
import { ITableContent } from "components/App/UpdatedAppComponents/Table/types/table";
import Filter from "components/Filter/Filter";
import { TFilterOption } from "components/Filter/types";
import Table from "components/App/UpdatedAppComponents/Table/Table";
import TableContent from "components/App/UpdatedAppComponents/Table/TableContent";

import { paginationDefaultValue } from "global/helpers/StaticData";
import {
  getAllowedAccess,
  toastNotify,
  setBackNavigationURL,
  userContextData,
  setTitle,
} from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";
import {
  useCustomSearchParams,
  useEffectOnce,
  useSearch,
  useUpdateEffect,
} from "global/UpdatedHooks/hooks";
import { ISortOrder } from "global/types/type";
import { notFoundMessage } from "global/helpers/action-success-error-messages";

import { DELETE_PROJECT_TASK_LABEL } from "modules/Project/services/mutations";
import { IProjectTaskLabel } from "modules/Project/types/project";
import EditTaskStatus from "modules/Project/Pages/TaskLabel/EditTaskLabel";
import { IProjectFilterForm } from "modules/Project/types/vault";
import { TASK_LABEL } from "modules/Project/services/queries";
import { ProjectGroup } from "modules/Project/Pages/Projects/ProjectGroups/types";

interface Props {
  allowedResourcesForProject: string[];
  editProject: { id: number; name: string };
  isManager: boolean;
  projectGroup?: ProjectGroup | null;
}

const tableCommonHeadings = [
  { name: "Name", APIName: "name" },
  { name: "Description", APIName: "description" },
];

const TaskLabel: FC<Props> = ({
  allowedResourcesForProject,
  editProject,
  isManager,
  projectGroup,
}) => {
  const userDataList: any = useReactiveVar(userContextData);
  const isSuperAdmin = userDataList?.user?.role?.isSuperAdmin;

  const allowedResourcesList: any = useReactiveVar(getAllowedAccess);
  const allowedResources = allowedResourcesList?.allowedResources || [];

  const canRead = isSuperAdmin || isManager;
  const canCreateOrUpdate =
    allowedResources.includes("SaveProjectTask") || isSuperAdmin || isManager;
  const canDelete =
    allowedResources.includes("DeleteProjectTask") || isSuperAdmin || isManager;
  const tableHeadings = canDelete
    ? [...tableCommonHeadings, { name: "Action" }]
    : tableCommonHeadings;

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const [sortOrder, setSortOrder] = useState<ISortOrder | null>(null);

  const { register, watch, control, handleSubmit, setValue, reset } =
    useForm<IProjectFilterForm>({
      defaultValues: {
        search: searchParams?.search ? searchParams?.search : "",
      },
    });
  const { id, projectType } = useParams();

  const [deleteProjectTaskLabel, { loading: deleteProjectTaskStatusLoading }] =
    useMutation(DELETE_PROJECT_TASK_LABEL);

  useEffect(() => {
    if (pathname && searchQuery) {
      setBackNavigationURL(pathname + searchQuery);
    }
  }, [pathname, searchQuery]);
  const search = useSearch(watch("search"));

  useEffect(() => {
    if (editProject?.name) {
      setTitle(
        projectGroup?.id
          ? [
              {
                name: "Project",
                link: "/projects/groups",
              },
              { name: "Groups", link: "/projects/groups" },

              {
                name: projectGroup?.name || "",
                link: `/projects/${projectGroup?.id}/${projectType}`,
              },
              {
                name: `${editProject?.name}`,
                link: `/projects/${projectGroup?.id}/${projectType}/edit/${id}/general`,
              },
              {
                name: "Task Label",
                link: `projects/${projectGroup?.id}/${projectType}/edit/${id}/task-label`,
              },
            ]
          : [
              { name: "Project", link: "/projects/projects/my-projects" },
              {
                name: `${editProject?.name}`,
                link: `/projects/projects/${projectType}/edit/${id}/general`,
              },
              {
                name: "Task Label",
                link: `projects/projects/${projectType}/edit/${id}/task-label`,
              },
            ]
      );
    }
  }, [
    editProject?.name,
    projectType,
    projectGroup?.id,
    projectGroup?.name,
    id,
  ]);

  const [currentPage, setCurrentPage] = useState<number>(
    !isNaN(+searchParams?.page) ? +searchParams?.page : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    !isNaN(+searchParams?.limit) ? +searchParams?.limit : paginationDefaultValue
  );

  useEffectOnce(() => {
    setSearchParams({
      ...searchParams,
      page: `${currentPage}`,
      limit: `${pageSize}`,
    });
  });

  useUpdateEffect(() => {
    if (searchParams?.page && !isNaN(+searchParams?.page)) {
      setCurrentPage(+searchParams?.page);
    }
    if (searchParams?.limit && !isNaN(+searchParams?.limit)) {
      setPageSize(+searchParams?.limit);
    }
    setValue("search", searchParams?.search || "");
  }, [searchParams?.page, searchParams?.limit, searchParams?.search]);

  const [
    fetchAllProjectTaskLabel,
    { data: getAllProjectTaskLabel, loading, refetch: refetchProjectTaskLabel },
  ] = useLazyQuery(TASK_LABEL, {
    fetchPolicy: "cache-and-network",
  });

  const projectTaskLabelList =
    getAllProjectTaskLabel?.projectTaskLabel?.dataCollection || [];

  const totalCount = projectTaskLabelList?.length || 0;

  const noOfItems = getAllProjectTaskLabel && projectTaskLabelList?.length;

  useEffect(() => {
    if (id && !isNaN(+id)) {
      fetchAllProjectTaskLabel({
        variables: {
          filters: {
            projectId: +id,
          },
          orderBy: sortOrder
            ? {
                field: sortOrder?.field,
                orderType: sortOrder?.orderType,
              }
            : undefined,
          limit: pageSize,
          page: currentPage,
          search: search || undefined,
        },
      });
    }
  }, [currentPage, fetchAllProjectTaskLabel, pageSize, search, id, sortOrder]);

  const [editProjectStatus, setEditProjectStatus] =
    useState<IProjectTaskLabel | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [filterValues, setFilterValue] = useState<object | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const deleteHandler = () => {
    if (editProjectStatus?.id) {
      deleteProjectTaskLabel({
        variables: {
          id: +editProjectStatus?.id,
        },
      })
        .then(() => {
          setDeleteVerification(false);
          refetchProjectTaskLabel();
          toastNotify([
            {
              messageType: "success",
              message: `${
                editProjectStatus?.name
                  ? `Project label ${editProjectStatus?.name}`
                  : "Project label"
              } has been deleted successfully`,
            },
          ]);
        })
        .catch((error) =>
          toastNotify([
            {
              messageType: "error",
              message: error?.message,
            },
          ])
        );
    }
  };

  const onEdit = (taskStatus: IProjectTaskLabel) => {
    if (canCreateOrUpdate) {
      setEditProjectStatus(taskStatus);
      setShowModal(true);
    }
  };

  const onDelete = (taskStatus: IProjectTaskLabel) => {
    setEditProjectStatus(taskStatus);
    setDeleteVerification(true);
  };

  const addBtnHandler = () => {
    setShowModal(true);
    setEditProjectStatus(null);
  };

  const getDataModal = (taskStatus: IProjectTaskLabel): IDataModal[] => [
    {
      label: "Name",
      value: [
        {
          content: Capitalize(taskStatus?.name),
        },
      ],
    },
    {
      label: "Description",
      value: [
        {
          content: taskStatus?.description,
        },
      ],
    },
  ];

  const getTableContent = (taskStatus: IProjectTaskLabel): ITableContent[] => [
    {
      value: taskStatus?.name,
    },
    {
      value: taskStatus?.description,
    },
    {
      value: (
        <div className="flex justify-center">
          <ToolTip render={() => "Delete"} arrow classNameForParent="truncate">
            <BiTrash
              className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(taskStatus);
              }}
            />
          </ToolTip>
        </div>
      ),
      hideContent: !canDelete,
    },
  ];

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const clearFilterHandler = () => {
    reset();
    setFilterValue(null);
  };
  const filterBtnShowHandler = () => {
    setShowFilter(false);
  };

  const filterFormOption: TFilterOption<IProjectFilterForm> = [];

  const submitHandler: SubmitHandler<IProjectFilterForm> = (data) => {
    setFilterValue(getFilterFormValues(data, filterFormOption));
    filterBtnShowHandler();
  };
  const noDataFoundMessage = (
    <p
      children={notFoundMessage}
      className="w-full min-h-[80vh] inline-flex border rounded-md shadow justify-center items-center text-sm text-ironside-gray"
    />
  );

  return (
    <div className="mt-5">
      <div className="flex gap-5 flex-wrap justify-center md:justify-end pb-5">
        <Filter
          control={control}
          handleSubmit={handleSubmit}
          watch={watch}
          clearFilterHandler={clearFilterHandler}
          searchInputName="search"
          filterName="filter"
          options={filterFormOption}
          register={register}
          submitHandler={submitHandler}
          filterBtnShowHandler={filterBtnShowHandler}
          showFilter={showFilter}
          hideFilterBtn
          setValue={setValue}
          isCloseIconNeeded={true}
        />

        {search?.length === 0 && noOfItems === 0
          ? null
          : (canCreateOrUpdate && (
              <Filled buttonName="Add" onClick={addBtnHandler} />
            )) ||
            null}
      </div>

      {!loading ? (
        <Fragment>
          {noOfItems !== 0 ? (
            <Fragment>
              <div className="md:hidden mx-auto">
                <div className="min-h-[80vh] space-y-3">
                  {projectTaskLabelList?.map(
                    (taskStatus: IProjectTaskLabel) => {
                      return (
                        <DataCard
                          key={taskStatus?.id}
                          dataModal={getDataModal(taskStatus)}
                          classForULlist={`${
                            canCreateOrUpdate
                              ? "cursor-pointer"
                              : "cursor-default"
                          }`}
                          cardClick={() => {
                            canCreateOrUpdate && onEdit(taskStatus);
                          }}
                          classNameForRow="grid grid-cols-[100px_auto] items-center gap-2"
                          otherOption={
                            (canDelete && (
                              <div className="flex justify-center">
                                <ToolTip
                                  render={() => "Delete"}
                                  arrow
                                  className={
                                    "text-center bg-[#616161] text-white"
                                  }
                                >
                                  <BiTrash
                                    className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDelete(taskStatus);
                                    }}
                                  />
                                </ToolTip>
                              </div>
                            )) ||
                            undefined
                          }
                        />
                      );
                    }
                  )}
                </div>
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={(page: number) => {
                    setSearchParams({
                      ...searchParams,
                      page: `${page}`,
                    });
                    setCurrentPage(page);
                  }}
                  onRowPerPageChange={(rowPerPage) => {
                    setSearchParams({
                      ...searchParams,
                      limit: `${rowPerPage}`,
                    });
                  }}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  noOfItem={noOfItems}
                />
              </div>

              <div className="hidden md:block">
                <Table
                  loading={loading}
                  className="min-h-[80vh]"
                  onPagination={{
                    totalCount,
                    currentPage: currentPage,
                    setCurrentPage: setCurrentPage,
                    pageSize: pageSize,
                    setPageSize: setPageSize,
                    onPageChange: (page) => {
                      setSearchParams({
                        ...searchParams,
                        page: `${page}`,
                      });
                    },
                    onRowPerPageChange: (rowPerPage) => {
                      setSearchParams({
                        ...searchParams,
                        limit: `${rowPerPage}`,
                      });
                    },
                    noOfItems,
                  }}
                  tableHeadings={{
                    tableObject: tableHeadings?.map(
                      (heading: { name: string; APIName?: string }) => {
                        return {
                          name: heading?.name,
                          center: false,
                          APIName: heading?.APIName,
                        };
                      }
                    ),
                  }}
                  ref={tableRef}
                  isCenterLastHeading={canDelete ? true : false}
                  isSortOrderNeeded
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                >
                  {projectTaskLabelList?.map(
                    (taskStatus: IProjectTaskLabel) => {
                      return (
                        <TableContent
                          key={taskStatus?.id}
                          tableRef={tableRef}
                          classForTableRow={`${
                            canRead
                              ? "cursor-pointer hover:bg-white-smoke"
                              : "cursor-default hover:bg-white-smoke"
                          }`}
                          tableRowClick={() => {
                            canRead && onEdit(taskStatus);
                          }}
                          tableContent={getTableContent(taskStatus)}
                        />
                      );
                    }
                  )}
                </Table>
              </div>
            </Fragment>
          ) : (search?.length > 0 && noOfItems === 0) || filterValues ? (
            noDataFoundMessage
          ) : canCreateOrUpdate ? (
            <div className="w-full h-[82vh] grid place-content-center">
              <Filled buttonName="Add" onClick={addBtnHandler} />
            </div>
          ) : (
            noDataFoundMessage
          )}
        </Fragment>
      ) : (
        <Loading className="min-h-[90vh]" />
      )}

      {showModal && (
        <EditTaskStatus
          refetchProjectTaskStatus={refetchProjectTaskLabel}
          editProjectStatus={editProjectStatus}
          setShowModal={setShowModal}
          loading={loading}
          editProject={editProject}
          allowedResourcesForProject={allowedResourcesForProject}
          isManager={isManager}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header="Task Label"
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
          loading={deleteProjectTaskStatusLoading}
        />
      )}
    </div>
  );
};

export default TaskLabel;
