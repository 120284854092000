import AutoComplete from "components/forms/UpdatedForm/AutoComplete/AutoComplete";
import {
  Control,
  FieldPath,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";

interface IProps<TFilterForm extends FieldValues> {
  options:
    | string[]
    | number[]
    | { id: string | number; name: string; disabled?: boolean | undefined }[];
  multiple?: true;
  control: Control<TFilterForm>;
  label: string;
  name: FieldPath<TFilterForm>;
  loading?: boolean;
  setValue: UseFormSetValue<TFilterForm>;
}

const FilterFormString = <TFilterForm extends Record<string, any>>({
  options,
  multiple,
  control,
  label,
  name,
  loading,
  setValue,
}: IProps<TFilterForm>) => {
  return (
    <div className="flex justify-center mt-5">
      <AutoComplete
        control={control}
        label={label}
        name={name}
        options={options}
        hideError
        className="w-[200px] lg:w-[300px]"
        multiple={multiple}
        loading={loading}
        setValue={setValue}
      />
    </div>
  );
};

export default FilterFormString;
