import { FC } from "react";

const Loader: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={`${className} min-h-[539px] lg:min-h-[639px] bg-white flex justify-center items-center`}
    >
      <div className="loader-design" />
    </div>
  );
};

export default Loader;
