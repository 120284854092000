import { Route, Routes } from "react-router-dom";

import Companies from "modules/Company/Companies";
import AccountRouterControl from "modules/Company/Account/AccountRouterControl";

const CompaniesRouteControl = () => {
  return (
    <Routes>
      <Route path="/" element={<Companies />} />
      <Route path=":companyId/*" element={<AccountRouterControl />} />
    </Routes>
  );
};

export default CompaniesRouteControl;
