import { Routes, Route } from "react-router-dom";

import EditProjectGroupTask from "modules/Project/Pages/Projects/ProjectGroupTasks/EditProjectGroupTask";

const EditProjectGroupTaskRouteControl = () => {
	return (
		<Routes>
			<Route path="/" element={<EditProjectGroupTask />} />
		</Routes>
	);
};

export default EditProjectGroupTaskRouteControl;
