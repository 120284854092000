import { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { Filled } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import DataCard from "components/App/Card/DataCard";

import { toastNotify, userQueryProps } from "global/helpers/Cache";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

import EditEducationDetails from "modules/Employee/Pages/Profile/Education/EditEducationDetails";
import EducationDetails from "modules/Employee/Pages/Profile/Education/EducationDetails";
import { GET_ACADEMIC_DETAILS } from "modules/Employee/services/queries";
import { DELETE_ACADEMIC_DETAILS } from "modules/Employee/services/mutations";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import { IAcademicDetail } from "modules/Employee/types/academic";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";

const Education = () => {
  const resourceAccess = useGetAllowedResources("EmployeeAcademicDetail");

  const [inProgress, setInProgress] = useState<boolean>(false);

  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchAcademicDetails,
  } = useQuery(GET_ACADEMIC_DETAILS, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const [academicDetails, setAcademicDetails] = useState<IAcademicDetail[]>([]);
  const isUnderModeration = useIsUnderModeration();

  useEffect(() => {
    setAcademicDetails(
      userDetails?.users?.dataCollection[0]?.userAcademicDetails || []
    );
  }, [userDetails?.users?.dataCollection]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);

  const [deleteUserAcademicDetail] = useMutation(DELETE_ACADEMIC_DETAILS);

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Education detail")
    : deleteSuccessMessageWithAwaiting("Education detail");

  const deleteHandler = () => {
    deleteUserAcademicDetail({
      variables: {
        id: editID,
      },
    })
      .then(() => {
        setEditID(null);
        setDeleteVerification(false);
        refetchAcademicDetails();
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error) => toastNotify(errorMessageNotify(error)));
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
  };

  const moderationProps = {
    setShowUnderReview: setShowUnderReview,
    setChangedInformation: setChangedInformation,
  };

  return (
    <Fragment>
      <div className="max-w-[500px]">
        <DataCard
          loading={loading}
          title="Educational Details"
          showPlusIcon={
            resourceAccess?.canCreate && !academicDetails[0]?.id ? false : true
          }
          resourceName={
            resourceAccess?.canCreate && !academicDetails[0]?.id
              ? undefined
              : "EmployeeAcademicDetail"
          }
          addOrEditHandler={() => {
            setEditID(null);
          }}
          setShowModal={setShowModal}
        >
          <div className="pb-5">
            {resourceAccess?.canCreate && !academicDetails[0]?.id ? (
              <div className="flex justify-center items-center h-[300px]">
                <Filled
                  buttonName="Add"
                  onClick={() => setShowModal(!showModal)}
                />
              </div>
            ) : (
              academicDetails?.map(
                (academicDetail: IAcademicDetail, index: number) => {
                  return (
                    <EducationDetails
                      key={academicDetail?.id}
                      index={index}
                      academicDetail={academicDetail}
                      setEditID={setEditID}
                      setDeleteVerification={setDeleteVerification}
                      setShowModal={setShowModal}
                      academicDetails={academicDetails}
                      resourceAccess={resourceAccess}
                      {...moderationProps}
                    />
                  );
                }
              )
            )}
          </div>
        </DataCard>
      </div>

      {showModal && (
        <EditEducationDetails
          academicDetails={academicDetails}
          editID={editID}
          setShowModal={setShowModal}
          setEditID={setEditID}
          inProgress={inProgress}
          setInProgress={setInProgress}
          refetchAcademicDetails={refetchAcademicDetails}
        />
      )}

      {showUnderReview && (
        <UnderReviewModal
          changedInformation={changedInformation}
          refetchQuery={refetchAcademicDetails}
          {...moderationProps}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"Educational Details"}
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
        />
      )}
    </Fragment>
  );
};

export default Education;
