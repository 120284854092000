import { FC, Fragment, useEffect, useRef, useState } from "react";

import { KebabMenu } from "components/forms";

import { ReactComponent as KebabIcons } from "global/assets/images/Kebab-Icon.svg";
import { toastNotify } from "global/helpers/Cache";
import { accessDeniedMessage } from "global/helpers/action-success-error-messages";
import { IResourceAccess } from "global/types/acl";

import { IEmergencyContact } from "modules/Employee/types/contacts";

interface IProps {
  emergencyContactInfoData: IEmergencyContact;
  setDeleteVerification: Function;
  setEditID: Function;
  setShowModal: Function;
  resourceAccess: IResourceAccess;
  setChangedInformation: Function;
  setShowUnderReview: Function;
  tableRef: any;
}

const EmergencyContactDetails: FC<IProps> = ({
  emergencyContactInfoData,
  setDeleteVerification,
  setEditID,
  setShowModal,
  resourceAccess,
  setChangedInformation,
  setShowUnderReview,
  tableRef,
}) => {
  const emergencyContactModeration =
    emergencyContactInfoData?.moderation || null;

  const [showEditOption, setShowEditOption] = useState<boolean>(false);
  const editRef: any = useRef();

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!editRef.current?.contains(event.target)) {
        setShowEditOption(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showEditOption]);

  const kebabIconRef = useRef<HTMLDivElement>(null);

  const [top, setTop] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().top
  );
  const [left, setLeft] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().left
  );
  const [Right, setRight] = useState<number | undefined>(
    kebabIconRef.current?.getBoundingClientRect().right
  );

  const getPosition = () => {
    setTop(
      kebabIconRef.current?.getBoundingClientRect().top &&
        kebabIconRef.current?.getBoundingClientRect().top + 32
    );
    setLeft(
      kebabIconRef.current?.getBoundingClientRect().left &&
        kebabIconRef.current?.getBoundingClientRect().left - 92
    );
    setRight(kebabIconRef.current?.getBoundingClientRect().right);
  };

  useEffect(() => {
    const eventRef = kebabIconRef.current;
    const tableEventRef = tableRef?.current;
    window.addEventListener("resize", getPosition);
    window.addEventListener("scroll", getPosition);
    eventRef && eventRef?.addEventListener("click", getPosition);
    tableEventRef && tableEventRef?.addEventListener("scroll", getPosition);

    return () => {
      window.removeEventListener("resize", getPosition);
      window.removeEventListener("scroll", getPosition);
      eventRef && eventRef?.removeEventListener("click", getPosition);
      tableEventRef &&
        tableEventRef?.removeEventListener("scroll", getPosition);
    };
  }, [tableRef]);

  const kebabIconClickHandler = () => {
    setEditID(emergencyContactInfoData?.id);
    setShowEditOption(!showEditOption);
  };

  const onDeleteHandler = () => {
    if (resourceAccess?.canDelete) {
      setDeleteVerification(true);
      setShowEditOption(false);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const onEditHandler = () => {
    if (resourceAccess?.canUpdate) {
      setShowModal(true);
      setShowEditOption(false);
    } else {
      toastNotify(accessDeniedMessage);
    }
  };

  const moderationPopUpHandler = (moderationId: number | null) => {
    moderationId && setShowUnderReview(true);
    setChangedInformation([moderationId]);
  };

  return (
    <Fragment>
      <td
        className={`${
          emergencyContactModeration?.name && "bg-yellow-100 cursor-pointer"
        } whitespace-nowrap tracking-normal p-3`}
        onClick={() => {
          moderationPopUpHandler(emergencyContactModeration?.name);
        }}
      >
        {emergencyContactInfoData?.name?.trim()}
      </td>

      <td
        className={`${
          emergencyContactModeration?.relationship &&
          "bg-yellow-100 cursor-pointer"
        } whitespace-nowrap tracking-normal p-3`}
        onClick={() => {
          moderationPopUpHandler(emergencyContactModeration?.relationship);
        }}
      >
        {emergencyContactInfoData?.relationship}
      </td>
      <td
        className={`${
          emergencyContactModeration?.mobile && "bg-yellow-100 cursor-pointer"
        } whitespace-nowrap tracking-normal p-3`}
        onClick={() => {
          moderationPopUpHandler(emergencyContactModeration?.mobile);
        }}
      >
        {emergencyContactInfoData?.isdCode &&
        emergencyContactInfoData?.mobile ? (
          `${emergencyContactInfoData?.isdCode} - ${emergencyContactInfoData?.mobile}`
        ) : (
          <span className="invisible">Helper Text</span>
        )}
      </td>

      {(resourceAccess?.canUpdate || resourceAccess?.canDelete) && (
        <td className="relative whitespace-nowrap tracking-normal">
          <div className="w-full flex justify-center items-center">
            <div ref={editRef} className="w-min">
              <div ref={kebabIconRef}>
                <KebabIcons
                  className={`w-[22px] h-[22px] cursor-pointer text-ironside-gray/70 rounded-full hover:bg-white-smoke rotate-90 ${
                    showEditOption ? "bg-white-smoke" : null
                  }`}
                  onClick={kebabIconClickHandler}
                />
              </div>

              {showEditOption && (
                <div
                  className="fixed z-[100]"
                  style={{
                    top: `${top}px`,
                    left: `${left}px`,
                    right: `${Right}px`,
                  }}
                >
                  <KebabMenu
                    onDelete={onDeleteHandler}
                    onEdit={onEditHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </td>
      )}
    </Fragment>
  );
};

export default EmergencyContactDetails;
