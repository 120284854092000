import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { IRoute } from "components/App/CustomRoutes/types/custom-routes";
import TabLink from "components/App/TabLink/TabLink";
import { ITabLink } from "components/App/TabLink/types/tab-links";
import Loading from "components/Loader/UpdatedLoaderAgry/Loading";
import NoDataFoundMessage from "components/UI/NoDataFoundMessage";

import useAllowedResources from "global/hooks/useAllowedResources";

import Users from "modules/Organization/Pages/Users/Users";
import { FILTER_COMPANIES } from "modules/Company/services";
import General from "modules/Company/Account/General/General";

const AccountRouterControl = () => {
  const allowedResources = useAllowedResources();

  const { companyId } = useParams();

  const { data: filterCompanies, loading } = useQuery(FILTER_COMPANIES, {
    skip: companyId ? false : true,
    fetchPolicy: "cache-and-network",
    variables: {
      filters: companyId
        ? {
            id: {
              number: +companyId,
            },
          }
        : undefined,
      isAddressNeed: true,
      isAltIsdCode: true,
      isAltMobileNeed: true,
      isCINNeed: true,
      isCinVerifiedNeed: true,
      isClosingTimeNeed: true,
      isCompanyLogoNeed: true,
      isCompanyManagersNeed: true,
      isCompanyOwnersNeed: true,
      isCompanyTypeNeed: true,
      isCreatedAtNeed: true,
      isEmailNeed: true,
      isEmailVerifiedNeed: true,
      isGstinNeed: true,
      isGstinVerifiedNeed: true,
      isIsdCodeNeed: true,
      isJoinedAtNeed: true,
      isLegalNameNeed: true,
      isMobileNeed: true,
      isOpeningTimeNeed: true,
      isPanNeed: true,
      isPanVerifiedNeed: true,
      isStatusNeed: true,
      isUpdatedAtNeed: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const company =
    filterCompanies?.filterCompanies?.edges &&
    filterCompanies?.filterCompanies?.edges?.length > 0 &&
    filterCompanies?.filterCompanies?.edges[0]?.node
      ? filterCompanies?.filterCompanies?.edges[0]?.node
      : null;

  const tabLinks: ITabLink[] = [
    {
      name: "General",
      link: `/companies/${companyId}/general`,
      allowedResourceIds: [],
      showTab: true,
    },
    {
      name: "Users",
      link: `/companies/${companyId}/users`,
      allowedResourceIds: [],
      showTab: true,
    },
  ];

  const routes: IRoute[] = [
    {
      path: "general",
      element: <General company={company} />,
    },
    {
      path: "users",
      element: <Users allowedResources={allowedResources} />,
    },
  ];

  return loading ? (
    <Loading className="min-h-[calc(100vh-25vh)]" />
  ) : (
    <div className="rounded-[4px] shadow-[0px_0px_45px_#C4C4C433] p-1 sm:px-[23px] min-h-[calc(100vh - 22vh)] bg-white">
      {company ? (
        <TabLink tabLinks={tabLinks} routes={routes} />
      ) : (
        <NoDataFoundMessage message="Company not found" />
      )}
    </div>
  );
};

export default AccountRouterControl;
