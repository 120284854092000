import { useState } from "react";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";

import { Filled } from "components/forms";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import DataCard from "components/App/Card/DataCard";

import { toastNotify, userQueryProps } from "global/helpers/Cache";
import useGetAllowedResources from "global/hooks/useGetAllowedResources";
import {
  deleteSuccessMessage,
  deleteSuccessMessageWithAwaiting,
} from "global/helpers/action-success-error-messages";

import WorkExperienceDetails from "modules/Employee/Pages/Profile/Work/WorkExperience/WorkExperienceDetails";
import UnderReviewModal from "modules/Employee/Pages/Profile/Moderation/UnderReviewModal";
import EditWorkExperience from "modules/Employee/Pages/Profile/Work/WorkExperience/EditWorkExperience";
import { GET_WORK_EXPERIENCE } from "modules/Employee/services/queries";
import { DELETE_EMPLOYEE_WORK_EXPERIENCE } from "modules/Employee/services/mutations";
import { IWorkExperience } from "modules/Employee/types/work";
import useIsUnderModeration from "modules/Employee/hooks/useIsUnderModeration";
import { errorMessageNotify } from "global/helpers/action-success-error-messages";

const WorkExperience = () => {
  const queryProps = useReactiveVar(userQueryProps);

  const {
    loading,
    data: userDetails,
    refetch: refetchWorkExperience,
  } = useQuery(GET_WORK_EXPERIENCE, {
    ...queryProps,
    fetchPolicy: "network-only",
  });

  const workExperienceDetails =
    (userDetails?.users?.dataCollection &&
      userDetails?.users?.dataCollection[0]?.userWorkExperiences) ||
    [];

  const isUnderModeration = useIsUnderModeration();

  const resourceAccess = useGetAllowedResources("EmployeeWorkExperience");

  const [inProgress, setInProgress] = useState(false);

  const [editID, setEditID] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteVerification, setDeleteVerification] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(
    workExperienceDetails?.isCurrent ? workExperienceDetails?.isCurrent : true
  );
  const [expStartDate, setExpStartDate] = useState<string | Date | null>(null);
  const [expEndDate, setExpEndDate] = useState<string | Date | null>(null);

  const [deleteWorkExperience] = useMutation(DELETE_EMPLOYEE_WORK_EXPERIENCE);

  const deleteActionSuccessMessage = isUnderModeration
    ? deleteSuccessMessage("Work experience")
    : deleteSuccessMessageWithAwaiting("Work experience");

  const deleteHandler = () => {
    deleteWorkExperience({
      variables: {
        id: editID,
      },
      update: (cache) => {
        const exWorkExperience: any = cache.readQuery({
          query: GET_WORK_EXPERIENCE,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
        });

        const updatedWorkExperience =
          exWorkExperience?.users?.dataCollection &&
          exWorkExperience?.users?.dataCollection[0]?.userWorkExperiences?.filter(
            (experience: IWorkExperience) => +experience?.id !== editID
          );

        cache.writeQuery({
          query: GET_WORK_EXPERIENCE,
          variables: {
            isMyProfile: +sessionStorage?.otherUserId ? undefined : true,
            id: +sessionStorage?.otherUserId
              ? +sessionStorage?.otherUserId
              : undefined,
          },
          data: {
            users: {
              dataCollection: [
                {
                  ...exWorkExperience?.users?.dataCollection[0],
                  userWorkExperiences: updatedWorkExperience,
                },
              ],
            },
          },
        });
      },
    })
      .then(() => {
        refetchWorkExperience();
        setEditID(null);
        setDeleteVerification(false);
        setExpStartDate(null);
        setExpEndDate(null);
        toastNotify(deleteActionSuccessMessage);
      })
      .catch((error: { message: string }) =>
        toastNotify(errorMessageNotify(error))
      );
  };

  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [changedInformation, setChangedInformation] = useState<number[]>([]);

  const filledBtnClickHandler = () => {
    setShowModal(true);
    setExpStartDate(null);
    setExpEndDate(null);
  };

  const plusIconClickHandler = () => {
    setEditID(null);
    filledBtnClickHandler();
  };

  const confirmModalCloseHandler = () => {
    setDeleteVerification(false);
    setEditID(null);
  };

  const moderationProps = {
    setShowUnderReview: setShowUnderReview,
    setChangedInformation: setChangedInformation,
  };

  return (
    <div className="min-h-[700px]">
      <DataCard
        loading={loading}
        title="Work Experience"
        showPlusIcon={
          resourceAccess?.canCreate && workExperienceDetails?.length !== 0
            ? true
            : false
        }
        resourceName={"EmployeeWorkExperience"}
        addOrEditHandler={plusIconClickHandler}
        setShowModal={setShowModal}
      >
        {resourceAccess?.canCreate && workExperienceDetails?.length === 0 ? (
          <div className="flex justify-center items-center h-[300px]">
            <Filled buttonName="Add" onClick={filledBtnClickHandler} />
          </div>
        ) : (
          <div className="w-full py-5">
            {workExperienceDetails?.map(
              (employeeWorkExperience: IWorkExperience, index: number) => {
                return (
                  <WorkExperienceDetails
                    key={employeeWorkExperience?.id}
                    employeeWorkExperience={employeeWorkExperience}
                    setCheckbox={setCheckbox}
                    setDeleteVerification={setDeleteVerification}
                    setShowModal={setShowModal}
                    setEditID={setEditID}
                    workExperienceDetails={workExperienceDetails}
                    index={index}
                    resourceAccess={resourceAccess}
                    {...moderationProps}
                  />
                );
              }
            )}
          </div>
        )}
      </DataCard>
      {showUnderReview && (
        <UnderReviewModal
          changedInformation={changedInformation}
          refetchQuery={refetchWorkExperience}
          {...moderationProps}
        />
      )}

      {deleteVerification && (
        <ConfirmModal
          header={"Work Experience"}
          onCancel={confirmModalCloseHandler}
          onXIcon={confirmModalCloseHandler}
          onExecute={deleteHandler}
        />
      )}
      {showModal && (
        <EditWorkExperience
          workExperienceDetails={workExperienceDetails}
          editID={editID}
          setEditID={setEditID}
          inProgress={inProgress}
          setInProgress={setInProgress}
          setShowModal={setShowModal}
          setAlert={toastNotify}
          expStartDate={expStartDate}
          setExpStartDate={setExpStartDate}
          expEndDate={expEndDate}
          setExpEndDate={setExpEndDate}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
          refetchWorkExperience={refetchWorkExperience}
        />
      )}
    </div>
  );
};

export default WorkExperience;
