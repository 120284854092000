import { useNavigate } from "react-router-dom";
import { Fragment } from "react";

import { IBreadCrumbs } from "components/App/BreadCrumbs/types/types";

const Title: React.FC<{
  title: string | IBreadCrumbs[];
}> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center">
      {typeof title === "string" ? (
        <h1 className="text-lg text-ironside-gray max-w-[400px] truncate">
          {title?.toLowerCase() === "company" ? "Companies" : title}
        </h1>
      ) : (
        title?.length > 0 && (
          <div className="text-lg text-ironside-gray max-w-[400px] truncate cursor-pointer">
            {title?.map((title, index, arr) => (
              <Fragment key={index}>
                {title?.name && (
                  <span
                    className="hover:underline cursor-pointer"
                    onClick={() => {
                      navigate(title?.link);
                    }}
                  >
                    {title?.name}
                  </span>
                )}
                {title?.name && arr?.length !== index + 1 && (
                  <span>{` / `}</span>
                )}
              </Fragment>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default Title;
