import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useReactiveVar } from "@apollo/client";

import ToastNotification from "components/UI/ToastNotification/ToastNotification";
import { IToastedNotification } from "components/UI/ToastNotification/type";

import { toastNotify } from "global/helpers/Cache";

interface IProps {
  children: ReactNode;
}

const ToastedNotificationProvider: FC<IProps> = ({ children }) => {
  const notifications: IToastedNotification[] = useReactiveVar<
    IToastedNotification[] | any
  >(toastNotify);

  const [notificationArray, setNotificationArray] = useState<
    IToastedNotification[]
  >([]);

  const optimizedNotificationArray = useMemo(() => {
    return notifications;
  }, [notifications]);

  useEffect(() => {
    setNotificationArray((prevState) => [
      ...prevState,
      ...optimizedNotificationArray,
    ]);
  }, [optimizedNotificationArray]);

  return (
    <Fragment>
      <div className={"toast-notification-wrapper"}>
        {notificationArray.map((notification, index) => {
          return (
            <ToastNotification
              key={index}
              messageType={notification?.messageType}
              message={notification?.message}
              setNotificationArray={setNotificationArray}
            />
          );
        })}
      </div>
      {children}
    </Fragment>
  );
};

export default ToastedNotificationProvider;
