import { FC, useEffect, useState } from "react";
import { HiDuplicate } from "react-icons/hi";
import { useLazyQuery } from "@apollo/client";

import Tooltip from "components/UI/ToolTip";

import avatar from "global/assets/images/dummy-avatar.svg";

import { GET_USER_PROFILE_IMAGE } from "modules/Employee/services/queries";

interface IProps {
  className?: string;
  userId?: number | undefined;
}

const UserDetails: FC<IProps> = ({ className, userId }) => {
  const [copyEmail, setCopyEmail] = useState(false);

  const [fetchUserProfileImage, { data: userDetails, loading }] = useLazyQuery(
    GET_USER_PROFILE_IMAGE
  );

  const hasUserDetail =
    userDetails && userDetails?.users?.dataCollection?.length > 0;

  const profileImage =
    (hasUserDetail &&
      userDetails?.users?.dataCollection[0]?.userProfileImages?.filter(
        (userProfileImage: any) => userProfileImage.type === "Profile"
      )[0]) ||
    null;

  const profileDetails = {
    profileImage:
      (profileImage?.documents && profileImage?.documents[0]?.file) || "",
    name:
      hasUserDetail &&
      userDetails?.users?.dataCollection[0]?.personalInformation?.name,
    email:
      hasUserDetail &&
      userDetails?.users?.dataCollection[0]?.employeeDetail?.officialEmail,
    designation:
      hasUserDetail &&
      userDetails?.users?.dataCollection[0]?.employeeDetail
        ?.employeeDesignation,
  };

  useEffect(() => {
    if (userId && !isNaN(userId)) {
      fetchUserProfileImage({
        variables: {
          id: +userId,
        },
      });
    }
  }, [userId, fetchUserProfileImage]);

  return (
    <div
      className={`${
        className ? className : ""
      } absolute hidden group-hover:opacity-100 group-hover:block bg-white rounded-[12px] shadow-[0px_0px_1px_1px_#00000029] transition-all opacity-0 z-10`}
    >
      {loading ? (
        <div className="w-[300px] h-[100px]">
          <div className="animate-pulse px-[26px] py-[15px] flex space-x-4">
            <div className="rounded-full bg-slate-200 h-[60px] w-[60px] mt-[2px]"></div>
            <div className="flex-1 space-y-[18px] py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-between py-[10px] px-[5px] sm:py-[15px] sm:px-[26px]">
          <div className="flex flex-row justify-center items-center">
            <div className="mr-3 w-[70px] h-[50px]">
              <img
                src={
                  profileDetails?.profileImage?.length > 100
                    ? `data:image/jpeg;base64,${profileDetails?.profileImage}`
                    : avatar
                }
                alt="userImage"
                className="w-[50px] h-[50px] rounded-full object-fill bg-white truncate"
              />
            </div>
            <div className="w-[150px] md:w-full flex flex-col gap-1">
              <p className="max-w-[300px] truncate text-sm font-semibold">
                {profileDetails?.name}
              </p>
              <p className="max-w-[300px] truncate text-sm">
                {profileDetails?.designation}
              </p>
              {profileDetails?.email ? (
                <div className="flex items-center gap-3">
                  <span className="truncate max-w-[300px] text-sm">
                    {profileDetails?.email}
                  </span>
                  <Tooltip render={copyEmail ? "copied" : "copy"} arrow>
                    <span className="hover:rounded-full hover:bg-gray-100">
                      <HiDuplicate
                        className="w-5 h-5 cursor-pointer  hover:text-[#6391f4]"
                        onMouseEnter={() => {
                          setCopyEmail(false);
                        }}
                        onClick={(e) => {
                          setCopyEmail(true);
                          navigator.clipboard.writeText(profileDetails?.email);
                          e.stopPropagation();
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
