import { FC, useRef } from "react";
import { BiTrash } from "react-icons/bi";
import { Control } from "react-hook-form";

import TableContent from "components/App/UpdatedAgryApp/Table/TableContent";
import TableWithCursorPagination from "components/App/UpdatedAgryApp/Table/TableWithCursorPagination";
import { TTableContent } from "components/App/UpdatedAgryApp/Table/types/Table";
import ToolTip from "components/UI/UpdateUIAgry/ToolTip";

import { FilterFormType } from "global/types/type";

import {
	ProjectGroupBugEdge,
	ProjectGroupBugFilterFormType,
	ProjectGroupBug,
} from "modules/Project/Pages/Projects/ProjectGroupBugs/types";

interface Props {
	canUpdate: boolean;
	canDelete: boolean;
	nextDisabled: boolean;
	control: Control<FilterFormType<ProjectGroupBugFilterFormType>, any>;
	prevDisabled: boolean;
	totalCount: number;
	onPageSizeChange: (pageSize: number) => void;
	onNextPageHandler: () => void;
	onPrevPageHandler: () => void;
	cardOrTableClickHandler: (bugId: number) => void;
	projectGroupBugEdges: ProjectGroupBugEdge[];
	onEdit: (
		type: "create" | "update" | "delete",
		projectGroupBug?: ProjectGroupBug | null,
	) => void;
}

const tableCommonHeadings = [
	"Title",
	"Projects",
	"Platform",
	"Priority",
	"Source",
	"Status",
	"Description",
];

const ProjectGroupBugTable: FC<Props> = ({
	canDelete,
	canUpdate,
	nextDisabled,
	control,
	prevDisabled,
	totalCount,
	onPageSizeChange,
	onNextPageHandler,
	onPrevPageHandler,
	cardOrTableClickHandler,
	projectGroupBugEdges,
	onEdit,
}) => {
	const tableHeadings = canDelete
		? [...tableCommonHeadings, "Action"]
		: tableCommonHeadings;

	const tableRef = useRef<HTMLDivElement>(null);

	const getTableContent = (
		projectGroupBug: ProjectGroupBug,
	): TTableContent[] => {
		const projectNames =
			projectGroupBug?.projectBacklogs?.dataCollection &&
			projectGroupBug?.projectBacklogs?.dataCollection?.length > 0
				? projectGroupBug?.projectBacklogs?.dataCollection
						?.map((project) => project?.project?.name)
						?.filter((project) => project)
						?.join(", ")
				: "";

		const { title, platform, priority, source, status, description } =
			projectGroupBug;

		return [
			{ value: title },
			{ value: projectNames, className: "max-w-[150px]" },
			{
				value:
					platform && platform?.length > 0
						? platform?.map((platform) => platform)?.join(", ")
						: "-",
			},
			{ value: priority },
			{
				value:
					source && source?.length > 0
						? source?.map((source) => source?.name)?.join(", ")
						: "-",
			},
			{ value: status },
			{
				value: description,
			},
			{
				value: (
					<div className="flex justify-center">
						<ToolTip
							render={() => "Delete"}
							arrow
							classNameForParent="truncate"
						>
							<BiTrash
								className="w-5 h-5 text-ironside-gray/70 cursor-pointer"
								onClick={(e) => {
									e.stopPropagation();
									projectGroupBug && onEdit("delete", projectGroupBug);
								}}
							/>
						</ToolTip>
					</div>
				),
				hideContent: !canDelete,
			},
		];
	};

	return (
		<div className="hidden md:block mt-6">
			<TableWithCursorPagination
				className="min-h-[80vh]"
				onPagination={{
					control: control,
					nextDisabled,
					onNextPageHandler,
					onPageSizeChange,
					onPrevPageHandler,
					pageSizeName: "pageSize",
					prevDisabled,
					totalCount,
				}}
				tableHeadings={{
					tableString: tableHeadings,
				}}
				ref={tableRef}
				isCenterLastHeading={canDelete ? true : false}
			>
				{projectGroupBugEdges?.map((edge, index) => {
					if (edge?.node) {
						return (
							<TableContent
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								key={index}
								tableRef={tableRef}
								tableContent={getTableContent(edge?.node)}
								tableRowClick={() => {
									edge?.node?.id && cardOrTableClickHandler(edge?.node?.id);
								}}
								classForTableRow={`${
									canUpdate ? "cursor-pointer" : "cursor-not-allowed"
								}`}
							/>
						);
					} else {
						return null;
					}
				})}
			</TableWithCursorPagination>
		</div>
	);
};

export default ProjectGroupBugTable;
