import { CompanyFilterForm } from "modules/Company/types";

export const companyTypes = [
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership",
  "Private Limited",
  "Public Limited, One-Person Company",
  "Joint-Venture",
  "Non-Government",
  "Organization",
  "Cooperatives",
];

export const companyStatuses = [
  "Active",
  "Inactive",
  "Blocked",
  "Suspended",
  "Under Review",
  "Declined",
];

export const filterSubmitValues = (filter: CompanyFilterForm) => {
  return {
    name:
      filter?.names && filter?.names?.length > 0
        ? {
            inArray: filter?.names?.map((name) => name?.id?.toString()),
          }
        : undefined,
    type:
      filter?.types && filter?.types?.length > 0
        ? {
            inArray: filter?.types?.map((type) => type?.id?.toString()),
          }
        : undefined,
    status:
      filter?.status && filter?.status?.length > 0
        ? {
            inArray: filter?.status?.map((status) => status),
          }
        : undefined,
    address:
      filter?.types && filter?.types?.length > 0
        ? {
            inArray: filter?.types?.map((address) => address?.id?.toString()),
          }
        : undefined,
    mobile:
      filter?.mobile && filter?.mobile?.length > 0
        ? {
            inArray: filter?.mobile?.map((mobile) => mobile?.id?.toString()),
          }
        : undefined,
    altMobile:
      filter?.altMobile && filter?.altMobile?.length > 0
        ? {
            inArray: filter?.altMobile?.map((altMobile) =>
              altMobile?.id?.toString()
            ),
          }
        : undefined,
    email:
      filter?.email && filter?.email?.length > 0
        ? {
            inArray: filter?.email?.map((email) => email?.id?.toString()),
          }
        : undefined,
    owners:
      filter?.owners && filter?.owners?.length > 0
        ? {
            inArray: filter?.owners?.map((owner) => owner?.id?.toString()),
          }
        : undefined,
    managers:
      filter?.managers && filter?.managers?.length > 0
        ? {
            inArray: filter?.managers?.map((manager) =>
              manager?.id?.toString()
            ),
          }
        : undefined,
    cin:
      filter?.cin && filter?.cin?.length > 0
        ? {
            inArray: filter?.cin?.map((cin) => cin?.id?.toString()),
          }
        : undefined,
    gstin:
      filter?.gstin && filter?.gstin?.length > 0
        ? {
            inArray: filter?.gstin?.map((gstin) => gstin?.id?.toString()),
          }
        : undefined,
    pan:
      filter?.pan && filter?.pan?.length > 0
        ? {
            inArray: filter?.pan?.map((pan) => pan?.id?.toString()),
          }
        : undefined,
    isCinVerified: filter?.isCinVerified ? true : false,
    isEmailVerified: filter?.isEmailVerified ? true : false,
    isPanVerified: filter?.isPanVerified ? true : false,
    isGstinVerified: filter?.isGstinVerified ? true : false,
  };
};
