import { ChangeEvent } from "react";

import Loading from "components/Loader/Loading";
import ToggleSwitch from "components/forms/UpdatedForm/ToggleSwitch/ToggleSwitch";
import {
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

interface IProps<TForm extends FieldValues> {
  register: UseFormRegister<TForm>;
  name: FieldPath<TForm>;
  label: string;
  defaultValue?: PathValue<TForm, Path<TForm>>;
  loading?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hideLabel?: boolean;
  disabled?: boolean;
  className?: string;
  changeLabelStyle?: string;
  errors?: FieldErrorsImpl<TForm>;
  setValue: UseFormSetValue<TForm>;
}

const ToggleSwitchWithLabel = <
  TForm extends Record<string, string | number | any>
>({
  register,
  name,
  label,
  defaultValue,
  loading,
  onChange,
  hideLabel,
  disabled,
  className,
  changeLabelStyle,
  errors,
  setValue,
}: IProps<TForm>) => {
  return (
    <div
      className={`flex justify-between items-center py-2 ${
        className ? className : "w-full"
      }`}
    >
      {!hideLabel && (
        <label
          htmlFor={name}
          className={`${
            changeLabelStyle ? changeLabelStyle : "text-ironside-gray text-sm"
          } select-none cursor-pointer`}
        >
          {label}
        </label>
      )}

      {loading ? (
        <Loading smallLoading className="mr-5 p-[5px]" />
      ) : (
        <ToggleSwitch
          register={register}
          name={name}
          label={label}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={(disabled && disabled) || false}
          errors={errors}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default ToggleSwitchWithLabel;
