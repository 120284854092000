import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

import { SearchField } from "components/forms";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loader/Loading";
import ConfirmModal from "components/forms/Modal/ConfirmModal";
import Table from "components/App/Table/Table";
import TableContent from "components/App/Table/TableContent";
import OopsNoData from "components/UI/OopsNoData";
import SMDataCard from "components/App/Card/SMCard/SMDataCard";

import { toastNotify } from "global/helpers/Cache";
import { Capitalize } from "global/helpers/Capitalize";

import { DELETE_LEAVE_TYPE } from "modules/Leave/services/mutations";
import { LEAVE_TYPES } from "modules/Leave/services/queries";
import EditLeavetype, {
  LeaveType,
} from "modules/Leave/Pages/Settings/LeaveType/EditLeavetype";

const tableHeadings = [
  { name: "Leave Type", center: false },
  { name: "Is Proofs Required?", center: true },
  { name: "Is Special Leave?", center: true },
  { name: "Is Restricted Leave?", center: true },
  { name: "Companies", center: true },
];

const Leavetype: React.FC<{ allowedResources: string[] }> = ({
  allowedResources,
}) => {
  const createAccess = allowedResources?.includes("CreateLeaveType");

  const [
    fetchLeaveTypes,
    { data: leaveTypes, loading, refetch: refetchLeaveTypes },
  ] = useLazyQuery(LEAVE_TYPES, {
    fetchPolicy: "cache-and-network",
  });

  const { register, watch, setValue } = useForm();

  const leaveType = watch("leaveType");
  const [search, setSearch] = useState(leaveType || "");
  const [leaveTypesdata, setLeaveTypesdata] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(10);
  useEffect(() => {
    const preventRequestOnEachKeystroke = setTimeout(() => {
      setSearch(leaveType?.trim());
    }, 300);
    return () => {
      clearTimeout(preventRequestOnEachKeystroke);
    };
  }, [leaveType]);

  useEffect(() => {
    fetchLeaveTypes({
      variables: {
        limit: pageSize,
        page: currentPage,
        name: search || undefined,
      },
    });
  }, [currentPage, fetchLeaveTypes, pageSize, search]);

  const parentRef = useRef<HTMLDivElement>(null);
  const updateAccess = allowedResources?.includes("UpdateLeaveType");
  const deleteAccess = allowedResources?.includes("DeleteLeaveType");
  const [deleteVerification, setDeleteVerification] = useState<boolean>();
  const [deleteLeaveType] = useMutation(DELETE_LEAVE_TYPE);

  const deleteHandler = () => {
    deleteLeaveType({
      variables: {
        id: +leaveTypesdata?.id,
      },
      update: (cache) => {
        const exLeaveTypes: {
          leaveTypes: LeaveType[] | any;
        } | null = cache.readQuery({
          query: LEAVE_TYPES,
        });

        const updatedLeaveTypes =
          exLeaveTypes?.leaveTypes?.dataCollection?.filter(
            (leaveTypeDetails: LeaveType) =>
              leaveTypeDetails?.id !== +leaveTypesdata?.id
          );
        cache.writeQuery({
          query: LEAVE_TYPES,
          data: {
            leaveTypes: { dataCollection: updatedLeaveTypes },
          },
        });
      },
    })
      .then(() => {
        setDeleteVerification(false);
        toastNotify([
          {
            messageType: "success",
            message: "Leave type has been deleted successfully.",
          },
        ]);
        refetchLeaveTypes();
      })
      .catch((error) =>
        toastNotify([
          {
            messageType: "error",
            message: error.message,
          },
        ])
      );
  };
  const plusIconClickHandler = () => {
    setShowModal(true);
    setLeaveTypesdata(null);
  };
  const kebabMenuEditHandler = () => {
    if (updateAccess) {
      setShowModal(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuDeleteHandler = () => {
    if (deleteAccess) {
      setDeleteVerification(true);
    } else {
      toastNotify([
        {
          messageType: "error",
          message: "Access denied.",
        },
      ]);
    }
  };
  const kebabMenuClickHandler = (leaveType: any) => {
    setLeaveTypesdata(leaveType);
  };
  return (
    <div>
      <div className="flex justify-between items-center flex-col space-y-4 md:space-y-0 md:flex-row lg:items-start md:pb-0 pt-5">
        <SearchField
          register={register}
          name="leaveType"
          label="Leave Type"
          onChange={() => {
            setCurrentPage(1);
          }}
          setValue={setValue}
        />
      </div>
      {leaveTypes?.leaveTypes?.dataCollection?.length === 0 &&
      !leaveType &&
      createAccess ? (
        <div className="absolute top-[60%] left-[40%] md:top-[50%] md:left-[50%] ml-[-50px] mt-[-50px]">
          <button
            className="w-[150px] lg:w-[150px] px-3 h-[42px] text-sm bg-cornflower-blue text-white hover:bg-bright-blue/80 leading-[20px] rounded-[4px]"
            onClick={() => {
              setShowModal(true);
              setLeaveTypesdata(null);
            }}
          >
            Add Leave Type
          </button>
        </div>
      ) : (
        leaveTypes?.leaveTypes?.dataCollection?.length === 0 && (
          <OopsNoData className="min-h-[70vh]" />
        )
      )}
      {leaveTypes?.leaveTypes?.dataCollection?.length > 0 && (
        <div className="hidden md:block overflow-x-auto min-h-[412px]">
          <Table
            createAccess={createAccess}
            noOfItems={leaveTypes?.leaveTypes?.dataCollection?.length}
            tableHeadingsObj={tableHeadings}
            className="py-5 min-h-[612px]"
            loading={loading}
            pageSize={pageSize}
            currentPage={currentPage}
            ref={parentRef}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            totalCount={leaveTypes?.leaveTypes?.totalNumberOfItems || 0}
            onPlusIconClick={plusIconClickHandler}
          >
            {leaveTypes?.leaveTypes?.dataCollection?.map((leaveType: any) => {
              let tableContent = [
                { value: leaveType?.name, center: false },
                {
                  value: leaveType?.isSupportingDocNeeded ? "Yes" : "No",
                  center: true,
                },
                {
                  value: leaveType?.isSpecialLeave ? "Yes" : "No",
                  center: true,
                },
                {
                  value: leaveType?.isRestrictedHolidayLeave ? "Yes" : "No",
                  center: true,
                },
                {
                  value:
                    leaveType?.company && leaveType?.company?.length > 0
                      ? leaveType?.company
                          ?.map((company) => company?.name)
                          ?.join(", ")
                      : "-",
                  center: true,
                },
              ];
              return (
                <TableContent
                  key={leaveType?.id}
                  id={leaveType?.id}
                  tableContent={tableContent}
                  parentRef={parentRef}
                  editAccess={updateAccess && deleteAccess}
                  showModal={showModal || deleteVerification}
                  onEdit={kebabMenuEditHandler}
                  onMenuClick={() => {
                    kebabMenuClickHandler(leaveType);
                  }}
                  onDelete={kebabMenuDeleteHandler}
                />
              );
            })}
          </Table>
        </div>
      )}
      <div className="md:hidden overflow-x-auto min-h-[412px] mt-[40px]">
        <div className="lg:mx-0 lg:px-[45px] bg-white m-[8px] mt-[40px]">
          {loading ? (
            <Loading className="min-h-[400px]" />
          ) : (
            <Fragment>
              <div>
                {leaveTypes?.leaveTypes?.dataCollection?.map(
                  (leaveType: any, index: number) => {
                    return (
                      <SMDataCard
                        key={leaveType?.id}
                        title={`Leave Type ${index + 1}`}
                        showModal={deleteVerification || showModal}
                        loading={loading}
                        dataModal={[
                          {
                            lable: "Leave Type",
                            value: [
                              {
                                content: Capitalize(leaveType?.name),
                              },
                            ],
                          },
                          {
                            lable: "Is Proofs Required?",
                            value: [
                              {
                                content: leaveType?.isSupportingDocNeeded
                                  ? "Yes"
                                  : "No",
                              },
                            ],
                          },
                          {
                            lable: "Is Speical Leave?",
                            value: [
                              {
                                content: leaveType?.isSpecialLeave
                                  ? "Yes"
                                  : "No",
                              },
                            ],
                          },
                          {
                            lable: "Is Restricted Leave?",
                            value: [
                              {
                                content: leaveType?.isRestrictedHolidayLeave
                                  ? "Yes"
                                  : "No",
                              },
                            ],
                          },
                          {
                            lable: "Companies",
                            value: [
                              {
                                content:
                                  leaveType?.company &&
                                  leaveType?.company?.length > 0
                                    ? leaveType?.company
                                        ?.map((company) => company?.name)
                                        ?.join(", ")
                                    : "-",
                              },
                            ],
                          },
                        ]}
                        onKebabIcon={{
                          onclick: () => {
                            kebabMenuClickHandler(leaveType);
                          },
                          kebabMenu: {
                            onEdit: kebabMenuEditHandler,
                            onDelete: kebabMenuDeleteHandler,
                          },
                        }}
                      />
                    );
                  }
                )}
              </div>
              {leaveTypes?.leaveTypes?.dataCollection?.length > 0 && (
                <Pagination
                  totalCount={
                    leaveTypes?.leaveTypes
                      ? leaveTypes?.leaveTypes?.totalNumberOfItems
                      : 0
                  }
                  noOfItem={leaveTypes?.leaveTypes?.dataCollection?.length}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  siblingCount={1}
                  pageSize={pageSize}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
      {showModal ? (
        <EditLeavetype
          leaveTypesdata={leaveTypesdata}
          setShowModal={setShowModal}
          refetchLeaveTypes={refetchLeaveTypes}
        />
      ) : null}
      {deleteVerification ? (
        <ConfirmModal
          header={"Leave Types"}
          onCancel={() => {
            setDeleteVerification(false);
          }}
          onXIcon={() => {
            setDeleteVerification(false);
          }}
          onExecute={() => deleteHandler()}
        />
      ) : null}
    </div>
  );
};

export default Leavetype;
