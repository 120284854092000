import { Fragment, FC } from "react";

import {
  FetchMoreType,
  ISetStateType,
  RefetchQueryType,
} from "global/types/type";
import { formatTime } from "global/helpers/DateFormatter";

import {
  FilterProjectsArgs,
  FilterProjectsReturnType,
  IProject,
  IProjectWorkLog,
} from "modules/Project/types/project";
import MyProjectWorkLog from "modules/Timer/WorkLogs/MyProjectWorkLogs/MyProjectWorkLog/MyProjectWorkLog";
import {
  FilterAllProjectWorkLogsArgs,
  FilterAllProjectWorkLogsReturnType,
} from "modules/Timer/WorkLogs/types";

interface Props {
  date: string;
  dateWiseWorkLogs: IProjectWorkLog[];
  projects: IProject[];
  refetchWorkLogs: RefetchQueryType<
    FilterAllProjectWorkLogsReturnType,
    FilterAllProjectWorkLogsArgs
  >;
  isEditing: boolean;
  setIsEditing: ISetStateType<boolean>;
  fetchMoreProjects: FetchMoreType<
    FilterProjectsReturnType,
    FilterProjectsArgs
  >;
  projectsLoading?: boolean;
}

const MyProjectWorkLogMain: FC<Props> = ({
  dateWiseWorkLogs,
  date,
  projects,
  refetchWorkLogs,
  isEditing,
  setIsEditing,
  fetchMoreProjects,
  projectsLoading,
}) => {
  const dayShort = date
    ? date?.slice(date?.indexOf("(") + 1, date?.indexOf(")"))?.toLowerCase()
    : "";

  let daylong;

  switch (dayShort) {
    case "sun":
      daylong = "Sunday";
      break;
    case "mon":
      daylong = "Monday";
      break;
    case "tue":
      daylong = "Tuesday";
      break;
    case "wed":
      daylong = "Wednesday";
      break;
    case "thu":
      daylong = "Thursday";
      break;
    case "fri":
      daylong = "Friday";
      break;
    case "sat":
      daylong = "Saturday";
      break;
    default:
      daylong = "";
  }

  const totalTimeInSeconds =
    dateWiseWorkLogs?.reduce(
      (acc, workLog) => acc + workLog?.durationInSeconds,
      0
    ) || 0;

  return (
    <Fragment>
      {dateWiseWorkLogs?.length > 0 ? (
        <div className="grid grid-cols-1 gap-2">
          <div className="flex justify-between items-center">
            <p className="text-sm text-[#333] font-bold py-1 px-3 rounded w-min whitespace-nowrap">
              {date && daylong ? `${daylong} (${date?.slice(0, 11)})` : ""}
            </p>
            <p className="text-sm text-[#333] font-bold py-1 px-3 rounded w-min whitespace-nowrap">
              {formatTime(totalTimeInSeconds)}
            </p>
          </div>
          {dateWiseWorkLogs?.map((workLog: IProjectWorkLog, index: number) => {
            return (
              <MyProjectWorkLog
                key={index}
                workLog={workLog}
                projects={projects}
                refetchWorkLogs={refetchWorkLogs}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                fetchMoreProjects={fetchMoreProjects}
                projectsLoading={projectsLoading}
              />
            );
          })}
        </div>
      ) : (
        <p className="text-base inline-flex justify-center items-center min-h-[76vh] w-full text-ironside-gray/70">
          No work logs
        </p>
      )}
    </Fragment>
  );
};

export default MyProjectWorkLogMain;
