import { Controller, FieldPath, FieldValues } from "react-hook-form";

import { SelectProps } from "components/forms/UpdatedFormAgry/Select/types";
import ListBox from "components/forms/UpdatedFormAgry/Select/ListBox";

const Select = <
	TFieldValues extends FieldValues = FieldValues,
	Name extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	control,
	name,
	loading,
	multiple,
	options,
	onChange,
	shouldUnregister,
	label,
	disabled,
	className,
	renderedOption,
	observe,
	nullable,
	supportedTextAndErrorMessage,
}: SelectProps<TFieldValues, Name>) => {
	return (
		<Controller
			name={name}
			control={control}
			shouldUnregister={shouldUnregister}
			rules={{
				onChange: (e) => {
					onChange?.(e?.target?.value);
				},
			}}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<ListBox
						name={name as any}
						options={options}
						multiple={multiple}
						disabled={disabled}
						loading={loading}
						label={label}
						onChange={onChange}
						value={value}
						renderedOption={renderedOption}
						observe={observe}
						className={className}
						nullable={nullable}
						supportedTextAndErrorMessage={supportedTextAndErrorMessage}
						error={error}
					/>
				);
			}}
		/>
	);
};

export default Select;
